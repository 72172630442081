import ToggleButton from '@material-ui/lab/ToggleButton';
import React from 'react';
import Tooltip from '@tippyjs/react';
import {connect} from 'react-redux';
import {convertToRaw} from 'draft-js';
import {pinResults, unpinResults} from '../redux/actions/actions';
import {calculateReadability} from '../utils/regex';
import PushPinRounded from './icons/PushPinRounded';

const ResultsPin = ({buttonProps, pin, unpin, editorState, isPinned, currentResults}) => {

    const togglePinResults = () => {
        if (isPinned) {
            if (window.confirm('Are you sure you want to reset your pinned results?')) {
                unpin();
            }
        } else {
            const contentState = editorState.getCurrentContent();
            const readability = calculateReadability(contentState).score;
            const rawContentState = convertToRaw(contentState);
            pin({rawContentState, readability, ...currentResults});
        }
    };

    return <Tooltip content={
        <div className={'p-3'}>
            {isPinned ? 'Reset your pinned results.' : 'Pin the results from the current version of the text so that you can see how much the text improves as you make changes.'}
        </div>
    }>
        <ToggleButton
            selected={isPinned}
            size={'small'}
            onChange={togglePinResults}
            value={'pin'}
            {...buttonProps}>
            <PushPinRounded color={'primary'}/>
        </ToggleButton>
    </Tooltip>;
};

const mapStateToProps = ({pinnedResults, ...currentResults}) => {
    return {
        isPinned: pinnedResults.pinned,
        currentResults
    };
};

const mapDispatchToProps = dispatch => {
    return {
        pin: stats => dispatch(pinResults(stats)),
        unpin: () => dispatch(unpinResults())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPin);

