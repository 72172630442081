import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import {connect} from 'react-redux';
import {convertToRaw} from 'draft-js';
import {saveAs} from 'file-saver';
import {formatDate} from '../utils/common';

const ExportMenuSaveAll = ({dispatch, handleClose, editorState, ...state}) => {

    const onSave = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const blob = new Blob([
            JSON.stringify({...state, rawContentState})
        ], {type: 'application/json'});

        const {date} = formatDate(new Date());
        saveAs(blob, `SHeLL-Editor_${date}.shll`);
        handleClose();
    };

    return <MenuItem onClick={onSave}>
        <SaveIcon className={'mr-2'}/> Project File
    </MenuItem>;
};


const mapStateToProps = ({counts, ...state}) => state;

export default connect(mapStateToProps)(ExportMenuSaveAll);
