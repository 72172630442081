import React from 'react';
import SimpleDecorator from 'draft-js-simpledecorator';
import HighlightedSpan from '../components/HighlightedSpan';

/**
 * Creates a decorator (just a strategy combined with a jsx component)
 * @param strategy = function that looks for specific text, returns callback with range of text to be styled
 * @param decoratorProps = props from decorator strategy file
 * @returns decorator
 */
export const createDecorator = (strategy, decoratorProps) => {
    const component = componentProps => {
        return <HighlightedSpan
            {...componentProps}
            {...decoratorProps}
        />;
    };

    return new SimpleDecorator(strategy, component);
};
