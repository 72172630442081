import {Typography} from '@material-ui/core';
import React from 'react';

const Reference = () => {
    return <>
        <Typography variant={'h6'} className={'mt-1'}>
            Please reference use of the editor with the following citation:
        </Typography>
        <div>
            Ayre J., Bonner C., Muscat D.M., Dunn A.G., Harrison E., Dalmazzo J., Mouwad D., Aslani P., Shepherd H.L.,
            McCaffery K.J. (2023).
        </div>
        <div>
            Automated Health Literacy Assessments of Written Health Information: Development of the SHeLL (Sydney
            Health Literacy Lab) Health Literacy Editor v1. JMIR Formative Research. e40645.
        </div>
        <div>DOI: <a href={'https://doi.org/10.2196/40645'}>10.2196/40645</a></div>
    </>;
};

export default Reference;
