import AssessIcon from '@material-ui/icons/ReportProblemRounded';
import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {NEW_USER_TIMER, REFRESH_GLOW_REMINDER, REFRESH_MODAL_REMINDER} from 'constants/vars';
import {Modal} from 'react-bootstrap';

const useStyles = makeStyles({
    button: {
        background: '#dc3545',
        color: 'white',
        '&:hover': {
            background: '#ff0000'
        }
    },
    reminderModal: {
        maxWidth: 600,
        top: '45%',
        transform: 'translateY(-50%) !important'
    }
});


const RefreshButton = ({changesMade, ...props}) => {
    const classes = useStyles();
    const [refreshGlowing, setRefreshGlowing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const seenTutorial = localStorage.getItem('seenTutorial');

    /**
     * Make the check text button glow for "new users" after backend decorators not triggered for 30 seconds
     * A "new user" is anyone who closed the tutorial for the first time in the last 120 seconds
     */
    useEffect(() => {
        const date = new Date(seenTutorial);
        let timer;
        if (changesMade && date.getTime() > (Date.now() - (NEW_USER_TIMER * 1000))) {
            timer = setTimeout(() => setRefreshGlowing(true), REFRESH_GLOW_REMINDER * 1000);
        } else if (!changesMade) {
            setRefreshGlowing(false);
        }
        return () => clearTimeout(timer);
    }, [changesMade, seenTutorial]);

    /**
     * Display reminder popup when backend decorators have not been triggered in the past 5 minutes
     */
    useEffect(() => {
        if (sessionStorage.getItem('disableCheckReminder')) return;

        let timer;
        if (changesMade) {
            timer = setTimeout(() => setModalOpen(true), REFRESH_MODAL_REMINDER * 1000);
        }
        return () => clearTimeout(timer);
    }, [changesMade]);

    const closeModal = () => setModalOpen(false);
    const handleModalCheckbox = e => sessionStorage.setItem('disableCheckReminder', e.target.checked);

    return <>
        <Button
            startIcon={<AssessIcon/>}
            variant={'contained'}
            disabled={!changesMade}
            className={`${classes.button} ${(refreshGlowing && !props.disabled) ? 'glowing' : ''}`}
            data-tour={'step-five'}
            {...props}
        >
            Check my text
        </Button>
        <Modal show={modalOpen} onHide={closeModal} dialogClassName={classes.reminderModal}>
            <Modal.Header>
                <Modal.Title>
                    You have not checked your text in a while
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Remember to click <Button
                startIcon={<AssessIcon/>}
                variant={'contained'}
                className={classes.button}
                style={{cursor: 'initial', pointerEvents: 'none'}}>Check my text</Button> to see updated results.
            </Modal.Body>
            <Modal.Footer>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleModalCheckbox}
                            color={'primary'}
                        />
                    }
                    label={'Don\'t remind me again this session'}
                    style={{margin: '0 2rem'}}
                />
                <Button variant={'outlined'} color={'primary'} onClick={closeModal}>Close</Button>

            </Modal.Footer>
        </Modal>
    </>;
};

export default RefreshButton;
