import decorators from 'decorators';


/**
 * Sum all highlight counts for each block into a new object with just highlights per ACTIVE decorator
 * @param highlightsPerBlock = highlights in redux store
 * @param decoratorState = decoratorState in redux store
 * @returns {{}}
 */
export const getHighlightCountPerDecorator = (highlightsPerBlock, decoratorState) => {
    if (!decoratorState.enabled) {
        return {};
    }

    const highlightsPerDecorator = {};
    const blockKeys = Object.keys(highlightsPerBlock);
    blockKeys.forEach(key => {
        const highlights = highlightsPerBlock[key];
        const decoratorTypes = Object.keys(highlights);
        decoratorTypes.forEach(type => {
            if (decoratorState.decorators[type]) {
                highlightsPerDecorator[type] = (highlightsPerDecorator[type] || 0) + highlights[type];
            }
        });
    });
    return highlightsPerDecorator;
};

/**
 * Sum highlight counts for each block into count of highlights FOR A SINGLE DECORATOR (active or inactive)
 * @param highlightsPerBlock = highlights in redux store
 * @param decoratorName = decorators name (from strategy files)
 * @returns number
 */
export const getHighlightCount = (highlightsPerBlock, decoratorName) => {
    let highlightCount = 0;
    const blockKeys = Object.keys(highlightsPerBlock);
    blockKeys.forEach(key => {
        const highlights = highlightsPerBlock[key];
        const decoratorTypes = Object.keys(highlights);
        decoratorTypes.forEach(type => {
            if (type === decoratorName) {
                highlightCount += highlights[type];
            }
        });
    });
    return highlightCount;
};

/**
 * Calculate type token ratio
 * @param counts = redux counts object (has words and uniqueWords values)
 * @param round = round to 2 dp if true
 * @returns {number}
 */
export const calculateTTR = (counts, round = false) => {
    const ttr = counts.words ? counts.uniqueWords / counts.words : 0;
    if (round) {
        return roundToDP(ttr, 2);
    }
    return ttr;
};


/**
 * Get all decorators from a category and sort them by sidebarPriority
 * @param decoratorState = redux decorator state object, not required if activeOnly is false
 * @param category = category (i.e. structured, readability, passiveVoice, etc.)
 * @param activeOnly = only return active decorators if true, otherwise return all decorators
 * @returns list of decorators
 */
export const getOrderedDecoratorsByCategory = (decoratorState, category, activeOnly = true) => {
    const allDecoratorNames = Object.keys(decorators);
    let result = [];
    allDecoratorNames.forEach(name => {
        if (
            // Skip if not in this category
            decorators[name].category !== category ||
            // Also skip if we only want active ones and this decorator is not active or all decorators are disabled
            (activeOnly && (!decoratorState.enabled || !decoratorState.decorators[name]))) {
            return;
        }

        // Insert into sorted array
        let index = 0;
        result.forEach((item, i) => {
            if (item.sidebarPriority > decorators[name].sidebarPriority) {
                return;
            }
            index = i + 1;
        });
        result = [...result.slice(0, index), decorators[name], ...result.slice(index)];

    });

    return result;
};


/**
 * Round a number to an amount of decimal places, for display purposes
 * @param num
 * @param dp
 * @returns {number}
 */
export const roundToDP = (num, dp) => {
    const zeros = Math.pow(10, dp);
    return Math.round(num * zeros) / zeros;
};


/**
 * Make a date into a pretty string (returns date and time parts)
 * @param date
 * @returns {{date: string, time: string}}
 */
export const formatDate = date => {
    return {
        date: new Intl.DateTimeFormat('en-AU', {
            year: 'numeric',
            month: 'numeric',
            day: '2-digit'
        }).format(new Date(date)),
        time: new Intl.DateTimeFormat('en-AU', {
            hour: 'numeric',
            minute: 'numeric'
        }).format(new Date(date))
    };
};


/**
 * Useful when conditionally inserting items into an array
 * e.g.
 * [
 *      'a',
 *      'b',
 *      ...insertIf(true, 'c'),
 *      ...insertIf(false, 'd'),
 *      'e'
 * ]
 * would equal ['a', 'b', 'c', 'e']
 * @param condition {any} if truthy, returns items in an array, otherwise an empty array
 * @param item {any}
 * @returns {any[]}
 */
export const insertIf = (condition, item) => {
    if (condition) {
        return Array.isArray(item) ? item : [item];
    }
    return [];
};

