import axios from 'axios';
import React, {useRef, useState, useEffect} from 'react';
import {
    convertToRaw,
    EditorState,
    ContentState,
    Editor,
    Modifier,
    SelectionState,
    RichUtils
} from 'draft-js';
import {Box} from '@material-ui/core';
import {connect} from 'react-redux';
import {removeBlockHighlightCounts, dispatchDecoratorState, setLastServerResponse} from 'redux/actions/actions';
import {decoratorState} from 'constants/state';
import {AUTO_IGNORE, BACKEND_URL, MAX_WORDS} from 'constants/vars';
import {createMultiDecorator, createReadabilityDecorator} from 'decorators';
import {wordsInString} from 'utils/regex';
import Footer from './Footer';
import Header from './Header';
import InteractiveTutorial from './InteractiveTutorial';
import LoadingOverlay from './LoadingOverlay';
import MultiTextInput from './MultiTextInput';
import Toolbar from './Toolbar';
import Sidebar from './Sidebar';

import 'draft-js/dist/Draft.css';
import './styles/editor.css';

// Initial editor decorations and content
const initialState = EditorState.createEmpty(
    createMultiDecorator(decoratorState)
);

// User has closed tutorial before?
const seenTutorial = Boolean(localStorage.getItem('seenTutorial'));


const App = props => {
    const [editorState, updateEditorState] = useState(initialState);
    const [loading, setLoading] = useState(null);
    const [readView, setReadView] = useState(false);
    const [changesMade, setChangesMade] = useState(true);
    const [showTour, setShowTour] = useState(!seenTutorial);

    const editorRef = useRef();
    const editorStateRef = useRef(editorState);

    /**
     * Keep editorState in a ref to prevent stale state in closures
     * @param newState = editorState to set
     */
    const setEditorState = newState => {
        editorStateRef.current = newState;
        updateEditorState(newState);
    };

    /**
     * When opening the tutorial
     */
    const openTour = () => {
        setShowTour(true);

        // Make sure "full text editor" view is active
        if (readView) {
            onChangeView(false);
        }
    };


    /**
     * Reactivate the refresh button if excluded words were changed
     */
    useEffect(() => {
        if (!changesMade) {
            setChangesMade(true);
        }
        // todo: shouldnt use useEffect to subscribe to a variable
        // eslint-disable-next-line
    }, [props.excluded]);


    /**
     * Focus editor on page load (if tutorial is not shown) and when tutorial closes
     */
    useEffect(() => {
        if (!showTour) {
            editorRef.current.focus();
        }
    }, [showTour]);


    /**
     * Toggle between none/INCLUDED/IGNORED entity on a span of text within a block
     * No entity and sentence word length <= constants.vars.AUTO_IGNORE === set included
     * No entity and sentence word length > constants.vars.AUTO_IGNORE === set ignored
     * Entity is already ignored === set included
     * Entity is already included === set ignored
     * @param blockKey = key of block to toggle entity
     * @param start = start index of text
     * @param end = end index of text
     */
    const toggleEntity = (blockKey, start, end) => {
        const contentState = editorStateRef.current.getCurrentContent();

        // Check if sentence is already an entity
        const block = contentState.getBlockForKey(blockKey);
        const existingEntityKey = block.getEntityAt(start);
        const existingEntity = existingEntityKey ? contentState.getEntity(existingEntityKey) : null;

        // Count words in the span of text
        const str = block.getText().slice(start, end);
        const wordCount = wordsInString(str, true).length;

        // Determine its current ignored/included state
        let alreadyIgnored = false;
        if (existingEntity?.getType() === 'IGNORED' || (!existingEntity && wordCount.length <= AUTO_IGNORE)) {
            alreadyIgnored = true;
        }

        // Apply the correct entity type
        const contentStateWithEntity = contentState.createEntity(alreadyIgnored ? 'INCLUDED' : 'IGNORED', 'MUTABLE');
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const selectionState = SelectionState.createEmpty(blockKey)
            .set('anchorOffset', start)
            .set('focusOffset', end);

        const contentStateWithLink = Modifier.applyEntity(
            contentStateWithEntity,
            selectionState,
            entityKey
        );

        const newEditorState = EditorState.push(
            editorStateRef.current,
            contentStateWithLink,
            'apply-entity'
        );
        setEditorState(newEditorState);
        setChangesMade(true);
    };

    /**
     * Triggered on any editor change events. i.e. typing, deleting, focus + blur
     */
    const onChange = state => {
        // Check for addition or removal of entry in undo stack
        // Assume editor content has changed if different
        const lastStackSize = editorState.getUndoStack().size;
        const stackSize = state.getUndoStack().size;
        if (stackSize !== lastStackSize) {
            setChangesMade(true);
        }

        // Decorators only run on blocks if they exist and have at least 1 character.
        // Actions that involve removing blocks or creating new empty blocks will therefore not
        // run redux actions on those blocks and the highlight counters will be wrong.
        // We need to make sure deleted and empty blocks are not contributing to decorator counters
        const lastChangeType = state.getLastChangeType();
        const contentState = state.getCurrentContent();
        const prevContentState = editorState.getCurrentContent();

        // Check for situations that can cause incorrect highlight counts, i.e. deleting blocks:
        if (
            // Changing number of blocks catches most situations
            contentState.getBlockMap().size !== prevContentState.getBlockMap().size ||

            // Pasting/deleting large sections of text might not change block count but can delete blocks
            // Also handles selecting a fragment of text and dragging it into another block
            ['insert-fragment', 'remove-range'].includes(lastChangeType) ||

            // undo/redo can include one of the previous change type(s), so we need to handle them
            ['undo', 'redo'].includes(lastChangeType)
        ) {
            // Scan all blocks for ones that are empty or dont exist, remove them from the highlight counters store
            const storedBlockKeys = Object.keys(props.highlights);
            const blocksToRemove = [];
            storedBlockKeys.forEach(key => {
                const block = contentState.getBlockForKey(key);
                if (!block || !block.getText()) {
                    blocksToRemove.push(key);
                }
            });
            props.removeBlockHighlightCounts(blocksToRemove);
        }

        setEditorState(state);
    };


    /**
     * When triggered, gets all server-calculated highlights for the active decorators
     */
    const getBackendDecorations = async () => {
        setLoading(true);
        const rawContentState = convertToRaw(editorState.getCurrentContent());

        // Set which decorators (flags) are active
        const flags = {...props.decoratorState.decorators};
        if (!props.decoratorState.enabled) {
            Object.keys(flags).forEach(flag => {
                flags[flag] = false;
            });
        }

        // editor content
        // key = block(paragraph) id
        // text = block text
        // type = block styling type, e.g. header-one, header-two, unordered-list, etc.
        // entityRanges = spans of text within block that are marked as entities
        const blocks = rawContentState.blocks.map(({key, text, type, entityRanges: entities}) => {
            const entityRanges = entities.map(entity => {
                return {
                    offset: entity.offset,
                    length: entity.length,
                    type: rawContentState.entityMap[entity.key].type
                };
            });
            return {key, text, type, entityRanges};
        });

        const body = {
            counts: props.counts,
            flags,
            excluded: props.excluded,
            blocks
        };

        // Query the server for extra highlights
        let response;
        try {
            response = await axios.post(BACKEND_URL, body, {
                timeout: 29000
            });
        } catch (err) {
            console.log(err);
            setLoading(null);
            if (err.code === 'ECONNABORTED') {
                alert('Sorry! Our server is unable to handle large amounts of text, please split your content into smaller parts and try again.');
            } else {
                alert('An error occurred when connecting to the server.');
            }
            return;
        }


        const {data} = response;

        // Reformat block response as an object for quicker access
        data.blocksObj = {};
        data.blocks.forEach(block => {
            data.blocksObj[block.key] = block;
        });

        props.setLastServerResponse(data);

        // Maintain previous readview/mainview state
        // (and force triggers the active decorators)
        onChangeView(readView);

        setChangesMade(false);
        setLoading(false);
    };


    /**
     * Set the active decorators in the editorstate and redux store
     * Optionally update content state at the same time
     */
    const setDecoratorState = (decoratorState, updatedContent = null) => {
        setChangesMade(true);
        // Update contentstate simultaneously (optional)
        const currentEditorState = updatedContent ? EditorState.createWithContent(updatedContent) : editorState;
        setEditorState(EditorState.set(currentEditorState, {decorator: createMultiDecorator(decoratorState)}));
        props.dispatchDecoratorState(decoratorState);
    };

    /**
     * triggered when swapping between text prep and full text views
     * essentially just toggling between all active decorators and the ones under decorators/strategies/readabilityView/
     */
    const onChangeView = readViewActive => {
        setReadView(readViewActive);
        if (readViewActive) {
            setEditorState(EditorState.set(editorState, {
                decorator: createReadabilityDecorator({onClick: toggleEntity})
            }));
        } else {
            setEditorState(EditorState.set(editorState, {decorator: createMultiDecorator(props.decoratorState)}));
        }
    };

    /**
     * Handles ctrl modifiers for setting bold (+b), italic (+i) and underline (+u)
     * @param command = string of command name, examples: bold, italic, underline, code, delete, etc..
     * @returns {string}
     */
    const handleKeyCommand = command => {
        if (['bold', 'italic', 'underline'].includes(command)) {
            const newEditorState = RichUtils.handleKeyCommand(editorState, command);
            setEditorState(newEditorState);
            return 'handled';
        }
        return 'not-handled';
    };

    /**
     * Ran prior to formatting text when it is pasted into the editor
     * @param text = raw text with no styles
     * @param html? = includes styling information if available
     * @param state = editorState
     * @returns {boolean} true if handled manually, false if draftjs should continue to handle the default way
     */
    const handlePastedText = (text, html, state) => {

        // If there is no html info or the html is from a microsoft app (e.g. ms word)/shell editor, fallback to default formatting
        // TODO: better way of distinguishing between good (microsoft + shell editor) and other sources
        const snippet = html?.slice(0, 100);
        if (!snippet || snippet.includes('microsoft') || snippet.includes('data-block') || snippet.includes('data-offset-key')) {
            return false;
        }

        // For all other pastes with html info, ignore the html and insert the raw text to prevent line break issues
        const pastedBlocks = ContentState.createFromText(text).blockMap;
        const newState = Modifier.replaceWithFragment(
            state.getCurrentContent(),
            state.getSelection(),
            pastedBlocks
        );
        const newEditorState = EditorState.push(state, newState, 'insert-fragment');
        onChange(newEditorState);
        return true;
    };

    return (
        <>
            <Header/>
            <Box display={'flex'} overflow={'hidden'} flex={1} margin={'auto'} maxWidth={1400}>
                <div className={'editorContainerOuter'} data-tour={'step-one'}>
                    <Toolbar
                        readView={readView}
                        editorState={editorState}
                        setEditorState={setEditorState}
                        openTour={openTour}
                        getBackendDecorations={getBackendDecorations}
                        changesMade={changesMade}
                        disableTextCheck={props.counts.words > MAX_WORDS}
                    />

                    <Editor
                        editorState={editorState}
                        ref={editorRef}
                        placeholder={readView ? '' : 'Welcome to the SHeLL Editor. Start typing here...'}
                        onChange={onChange}
                        readOnly={loading || readView}
                        handleKeyCommand={handleKeyCommand}
                        handlePastedText={handlePastedText}
                    />


                </div>
                <Sidebar
                    setDecoratorState={setDecoratorState}
                    getBackendDecorations={getBackendDecorations}
                    readView={readView}
                    onChangeView={onChangeView}
                    editorState={editorState}
                    changesMade={changesMade}
                />
                <InteractiveTutorial
                    open={showTour}
                    setOpen={setShowTour}
                />
            </Box>
            <Footer/>
            <LoadingOverlay active={loading}/>
            <MultiTextInput/>
        </>
    );
};

const mapStateToProps = ({highlights, counts, decoratorState, excluded, response}) => {
    return {highlights, counts, decoratorState, excluded, response};
};

const mapDispatchToProps = dispatch => {
    return {
        setLastServerResponse: response => dispatch(setLastServerResponse(response)),
        dispatchDecoratorState: decoratorState => dispatch(dispatchDecoratorState(decoratorState)),
        removeBlockHighlightCounts: blocksToRemove => dispatch(removeBlockHighlightCounts(blocksToRemove))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
