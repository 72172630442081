import React from 'react';
import {connect} from 'react-redux';
import {calculateTTR} from 'utils/common';

/**
 * Set colour of Score: x text based on readability score (see cutoffs below)
 * @param value = lex density
 * @returns {string} = css colour string
 */
const gradeColour = value => {
    if (value == null) return 'black';

    value = Number(value);
    if (value >= 5) { // inclusive 5-inf
        return 'red';
    }
    if (value >= 3) { // inclusive 3-5 exclusive
        return 'orange';
    }
    if (value >= 0) { // inclusive 0-3 exclusive
        return 'green';
    }
    return 'black'; // NaN
};


const LexicalDensityScore = ({lexicalDensity, mtld, counts}) => {
    const renderLexicalDensity = () => {
        if (typeof lexicalDensity !== 'undefined') {
            return lexicalDensity;
        }
        if (counts.words < 200) {
            return <small>Not available for texts less than 200 words.</small>;
        }
        return 'N/A';
    };

    const renderMtld = () => {
        if (typeof mtld !== 'undefined') {
            return mtld;
        }
        return 'N/A';
    };

    const typeTokenRatio = calculateTTR(counts, true);

    return <>
        <p className={'my-0'}>
            Lexical density:{' '}
            <span style={{color: gradeColour(lexicalDensity)}}>
                {renderLexicalDensity()}
            </span>
        </p>
        <p className={'my-0'}>
            Type-token ratio:{' '}
            <span>
                {typeTokenRatio}
            </span>
        </p>
        <p className={'my-0'}>
            Standardised type-token ratio:{' '}
            <span>
                {renderMtld()}
            </span>
        </p>
    </>;
};

const mapStateToProps = ({response: {lexicalDensity, mtld}, counts}) => {

    return {
        lexicalDensity: lexicalDensity?.toFixed(1),
        mtld: mtld?.toFixed(2),
        counts
    };
};

export default connect(mapStateToProps)(LexicalDensityScore);
