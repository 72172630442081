import React from 'react';
import PriorityIcon from '@material-ui/icons/ReportProblemRounded';
import Tooltip from '@tippyjs/react';
import CollapsibleInfo from './CollapsibleInfo';
import SidebarDecorator from './SidebarDecorator';


import './styles/sidebar.css';

const NoneActivated = () => <small>No active highlights for this category.</small>;

/**
 * Whole decorator category section on sidebar
 */
const SidebarCategory = props => {


    const onRefreshClick = e => {
        // Do nothing if no changes have been made since last refresh
        if (!props.changesMade) return;

        e.preventDefault();
        e.stopPropagation();

        props.getBackendDecorations();
    };

    // Render a warning refresh button for eligible categories
    const showRefreshWarning = props.sidebarOnly || (props.decorators.length > 0 && props.backend);
    const refreshWarning = <Tooltip
        content={
            <div className={'p-3 text-center'}>
                {props.changesMade ?
                    <>Text assessment is not up to date. <br/>Click to update.</> :
                    <>Text assessment is up to date.</>}
            </div>
        }>
            <span data-tour={'step-six'}>
                <PriorityIcon
                    disabled={!props.changesMade}
                    onClick={onRefreshClick}
                    color={props.changesMade ? 'error' : 'disabled'}
                />

            </span>
    </Tooltip>;


    return <div className={'decorators-section'} id={`sidebar-${props.name}`} data-tour={props['data-tour']}>

        <CollapsibleInfo
            title={props.longName}
            titleAdornmentRight={showRefreshWarning && refreshWarning}
            content={<>
                {props.description &&
                <div className={'mb-1'}>
                    {props.description}
                </div>}
                <a href={props.notes} target={'_blank'}>Tips and technical notes.</a>
            </>}
            uncollapsedContent={<>
                {props.content &&
                <div className={'mb-1'}>
                    {props.content}
                </div>}
            </>}
        />

        {!props.sidebarOnly && (<>
            {props.decorators.length ? null : <NoneActivated/>}
            {props.decorators.map(decorator => <SidebarDecorator key={decorator.name} {...decorator}/>)}
        </>)}

    </div>;
};


export default SidebarCategory;
