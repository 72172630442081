export const SET_HIGHLIGHT_COUNT = 'SET_HIGHLIGHT_COUNT';
export const SET_HIGHLIGHTS = 'SET_HIGHLIGHTS';
export const REMOVE_BLOCK_HIGHLIGHT_COUNTS = 'REMOVE_BLOCK_HIGHLIGHT_COUNTS';

export const UPDATE_COUNTER = 'UPDATE_COUNTER';

export const SET_DECORATOR_STATE = 'SET_DECORATOR_STATE';
export const SET_HOVERED_DECORATOR = 'SET_HOVERED_DECORATOR';

export const SET_LAST_SERVER_RESPONSE = 'SET_LAST_SERVER_RESPONSE';

export const SET_EXCLUDED_WORDS = 'SET_EXCLUDED_WORDS';

export const PIN_RESULTS = 'PIN_RESULTS';
export const UNPIN_RESULTS = 'UNPIN_RESULTS';
export const IMPORT_PINNED_RESULTS = 'IMPORT_PINNED_RESULTS';
