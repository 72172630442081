import {createDecorator} from 'decorators/decoratorCreator';
import {sentencesInString, wordsInString} from 'utils/regex';
import {AUTO_IGNORE} from 'constants/vars';


const showIgnored = {
    name: 'showIgnored',
    className: 'readability-sentence highlight-ignore'
};

const strategy = (contentBlock, callback, contentState) => {
    const text = contentBlock.getText();
    if (!text) return;

    // Auto-ignore sentences <= AUTO_IGNORE words (non entity sentences only)
    const sentences = sentencesInString(text, true);
    sentences.forEach(sentence => {
        const entityKey = contentBlock.getEntityAt(sentence.index);
        if (!entityKey) {
            const wordCount = wordsInString(sentence.text, true).length;
            if (wordCount <= AUTO_IGNORE) {
                callback(sentence.index, sentence.text.length + sentence.index);
            }
        }
    });


    // Manually ignored entities
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey) {
            const entityType = contentState.getEntity(entityKey).getType();
            return entityType === 'IGNORED';
        }

    }, callback);

};

showIgnored.createDecorator = decoratorProps => createDecorator(strategy, {...decoratorProps, ...showIgnored});
export default showIgnored;


