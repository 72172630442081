import {Box} from '@material-ui/core';
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import './styles/counter.css';

/**
 * Displayed when the editor is waiting on a response from backend
 */
const LoadingOverlay = ({active}) => {

    return <>
        {active &&
        <div className={'loaderContainer'}>
            <Box width={'75%'}>
                <span>Processing...</span>
                <LinearProgress style={{margin: '1rem 0'}}/>
                <span>This may take some time depending on the amount of text and how many highlight features are active.</span>
            </Box>
        </div>}


    </>;
};


export default LoadingOverlay;

