import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const legalTerms = {
    name: 'legalTerms',
    longName: 'Legal Terms',
    className: 'highlight-purple',
    category: 'complex',
    postText: count => count === 1 ?
        'legal term.' :
        'legal terms.',
    sidebarPriority: 2,
    tooltip: {
        text: 'There may be a simpler alternative if this language is used in a legal context.'
    }
};

const strategy = serverStrategyCreator(legalTerms.name);
const pinnedStrategy = serverStrategyCreator(legalTerms.name, true);

legalTerms.decorator = createDecorator(strategy, legalTerms);
legalTerms.pinnedDecorator = createDecorator(pinnedStrategy, legalTerms);
export default legalTerms;
