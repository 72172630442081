import {ExpandMore as DownArrow, ListAlt} from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import React, {useState} from 'react';
import {convertFromRaw} from 'draft-js';
import {Button, Menu, MenuItem} from '@material-ui/core';
import {connect} from 'react-redux';
import {importPinnedResults, setExcludedWords, setHighlights, setLastServerResponse} from '../redux/actions/actions';
import MultiTextInput from './MultiTextInput';

const ImportSave = ({setDecoratorState, setLastServerResponse, setExcludedWords, setPinnedResults, setHighlights}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [showMultiText, setShowMultiText] = useState(false);

    const handleOpen = ({currentTarget}) => setAnchorEl(currentTarget);
    const handleClose = () => setAnchorEl(null);

    const onImport = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = () => {
            const {
                decoratorState,
                response,
                excluded,
                pinnedResults,
                rawContentState,
                highlights
            } = JSON.parse(reader.result);

            if (response) {
                setLastServerResponse(response);
            }

            if (excluded) {
                setExcludedWords(excluded);
            }

            if (pinnedResults) {
                setPinnedResults(pinnedResults);
            }

            if (highlights) {
                setHighlights(highlights);
            }

            if (decoratorState && rawContentState) {
                const contentState = convertFromRaw(rawContentState);
                setDecoratorState(decoratorState, contentState);
            }

        };

        reader.onerror = () => console.log(reader.error);
    };

    const onReset = e => {
        e.target.value = '';
    };

    const onClickMultiTextButton = () => {
        setShowMultiText(true);
        handleClose();
    };

    return <>
        <Button variant={'outlined'} onClick={handleOpen} data-tour={'step-nine'} style={{paddingRight: 8}}
                className={'mr-1'}>
            Import <DownArrow/>
        </Button>
        <Menu
            id={'simple-menu'}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <MenuItem component={'label'}>
                <SaveIcon className={'mr-2'}/> Project File
                <input type={'file'} onClick={onReset} onChange={onImport} accept={'.shll'} hidden/>
            </MenuItem>
            <MenuItem onClick={onClickMultiTextButton}>
                <ListAlt className={'mr-2'}/> Multiple Texts
            </MenuItem>
        </Menu>
        <MultiTextInput show={showMultiText} setShow={setShowMultiText}/>
    </>;
};


const mapDispatchToProps = dispatch => ({
    setLastServerResponse: response => dispatch(setLastServerResponse(response)),
    setExcludedWords: words => dispatch(setExcludedWords(words)),
    setPinnedResults: stats => dispatch(importPinnedResults(stats)),
    setHighlights: highlights => dispatch(setHighlights(highlights))
});

export default connect(null, mapDispatchToProps)(ImportSave);
