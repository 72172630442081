import {ENABLE_PAID_FEATURES} from './vars';

/**
 * Default decorator state on page load
 */
export const decoratorState = {
    enabled: true,
    hovered: null,
    decorators: {
        acronym: true,
        ...(ENABLE_PAID_FEATURES && {cancer: false}),
        ...(ENABLE_PAID_FEATURES && {chronicPain: false}),
        complexLanguage: true,
        ...(ENABLE_PAID_FEATURES && {complexSentence: false}),
        ...(ENABLE_PAID_FEATURES && {dementia: false}),
        ...(ENABLE_PAID_FEATURES && {diabetes: false}),
        ...(ENABLE_PAID_FEATURES && {financialTerms: false}),
        ...(ENABLE_PAID_FEATURES && {legalTerms: false}),
        ...(ENABLE_PAID_FEATURES && {longParagraph: false}),
        longSentence: true,
        ...(ENABLE_PAID_FEATURES && {dotPoints: true}),
        longWord: true,
        ...(ENABLE_PAID_FEATURES && {mentalHealth: false}),
        passiveVoice: true,
        uncommonWord: true,
        ...(ENABLE_PAID_FEATURES && {recovery: false})
    }
};

/**
 * Default editor content on page load
 */
/*
export const contentState = {
    blocks:
        [
            {
                key: '71pfn',
                text: 'Welcome to the SHeLL editor!',
                type: 'header-one',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                data: {}
            },
            {
                key: 'cg232',
                text: 'The SHeLL editor helps you make health information easy to understand.',
                type: 'unstyled',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                data: {}
            },
            {
                key: '6715o',
                text: 'Tips',
                type: 'header-two',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                data: {}
            },
            {
                key: '8gpl7',
                text: 'Each colour tells you about an aspect that may make your text harder to understand.',
                type: 'unordered-list-item',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                data: {}
            },
            {
                key: 'fv7b2',
                text: 'Click \'Check my text\' to get results for the Complex Language and Passive Voice features.',
                type: 'unordered-list-item',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [{offset: 6, length: 16, style: 'BOLD'}],
                data: {}
            },
            {
                key: 'aitiu',
                text: 'Make changes to the text. Then click \'Check my text\' again. This will update Complex Language and Passive Voice.',
                type: 'unordered-list-item',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [{offset: 37, length: 16, style: 'BOLD'}],
                data: {}
            },
            {
                key: '9l4jb',
                text: 'In most cases your final text will still have highlighted words. This is ok! Sometimes longer sentences or complex language are needed. Aim to keep the text as simple as you can.',
                type: 'unordered-list-item',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                data: {}
            },
            {
                key: 'c1vu4',
                text: 'Let\'s get started! Delete the text above and start typing here.',
                type: 'header-three',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                data: {}
            }
        ],
    entityMap: {}
};

*/
