import {makeStyles} from '@material-ui/core';
import React from 'react';
import {SHLL_URL} from '../constants/vars';
import logo from './img/SHLL Colour RGB.png';

const useStyles = makeStyles({
    root: {
        padding: '.6rem',
        textAlign: 'center'
    },
    logo: {
        width: 100,
        marginRight: '1rem'
    }
});


const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h1>
                <a href={SHLL_URL} target={'_blank'}><img alt={'Logo'} src={logo} className={classes.logo}/></a>Health Literacy Editor
            </h1>
        </div>
    );
};


export default Header;
