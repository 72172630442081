import {
    REMOVE_BLOCK_HIGHLIGHT_COUNTS,
    SET_HIGHLIGHT_COUNT,
    SET_HIGHLIGHTS
} from 'redux/actions/actionTypes';


export const initialState = {};

const highlights = (state = initialState, action) => {

    switch (action.type) {
        case SET_HIGHLIGHT_COUNT: {
            const {decoratorType, blockKey, amount} = action.payload;
            return {
                ...state,
                [blockKey]: {
                    ...state[blockKey],
                    [decoratorType]: amount
                }
            };
        }
        case SET_HIGHLIGHTS: {
            const {highlights} = action.payload;
            return highlights;
        }
        case REMOVE_BLOCK_HIGHLIGHT_COUNTS: {
            const {blockKeys} = action.payload;
            let newState = {...state};
            blockKeys.forEach(key => {
                const {[key]: _, ...remainder} = newState;
                newState = remainder;
            });
            return newState;
        }

        default:
            return state;
    }
};

export default highlights;
