import {createDecorator} from 'decorators/decoratorCreator';
import {nanoid} from 'nanoid';
import {commaCountInString, sentencesInString} from 'utils/regex';
import store from 'redux/store';
import {setHighlightCount} from 'redux/actions/actions';
import {DOT_POINT_COMMAS} from 'constants/vars';


const dotPoints = {
    name: 'dotPoints',
    longName: 'Dot Points',
    className: 'highlight-darkPurple',
    category: 'readability',
    postText: count => count === 1 ?
        'sentence may contain a list.' :
        'sentences may contain a list.',
    subText: 'Use dot points for lists of more than 4 things.',
    sidebarPriority: 5,
    tooltip: {
        text: 'Is this a list? Try using bullet points to make it easier to read.'
    }
};


const strategy = (contentBlock, callback) => {
    const text = contentBlock.getText();
    const sentences = sentencesInString(text, true);

    // For each sentence, determine number of commas and flag for suggestion if greater than amount
    let storeAmount = 0;
    sentences.forEach(sentence => {
        const commaCount = commaCountInString(sentence.text);
        if (commaCount >= DOT_POINT_COMMAS) {
            storeAmount += 1;
            callback(sentence.index, sentence.index + sentence.text.length, {instance: nanoid()});
        }
    });

    store.dispatch(setHighlightCount(dotPoints.name, contentBlock.getKey(), storeAmount));
};
dotPoints.decorator = createDecorator(strategy, dotPoints);

export default dotPoints;
