import {Document, Packer, Paragraph, TextRun} from 'docx';
import {saveAs} from 'file-saver';
import {formatDate} from './common';

const documentConfig = {
    title: 'SHeLL Editor Contents',
    styles: {
        paragraphStyles: [ // blockStyles
            {
                id: 'Normal',
                name: 'Normal',
                run: {
                    font: 'Segoe UI'
                }
            },
            {
                id: 'header',
                basedOn: 'Normal',
                paragraph: {
                    spacing: {
                        after: 150
                    }
                },
                run: {
                    bold: true
                }
            },
            {
                id: 'header-one',
                basedOn: 'header',
                run: {
                    size: 40
                }
            },
            {
                id: 'header-two',
                basedOn: 'header',
                run: {
                    size: 32
                }
            },
            {
                id: 'header-three',
                basedOn: 'header',
                run: {
                    size: 28
                }
            },
            {
                id: 'ListParagraph',
                basedOn: 'Normal',
                paragraph: {
                    spacing: {
                        after: 150,
                        before: 150
                    },
                    contextualSpacing: true
                }
            },
            {
                id: 'unordered-list-item',
                basedOn: 'ListParagraph',
                paragraph: {
                    bullet: {level: 0}
                }
            },
            {
                id: 'ordered-list-item',
                basedOn: 'ListParagraph'
            }
        ]
    },
    numbering: {
        config: [
            {
                levels: [
                    {
                        level: 0,
                        format: 'decimal',
                        text: '%1.',
                        style: {
                            paragraph: {
                                indent: {
                                    left: 720, // left = left - hanging
                                    hanging: 360 // hanging = hanging
                                }
                            }
                        }
                    }
                ],
                reference: 'numbers'
            }
        ]
    }
};

export default class DocumentCreator {

    /**
     * Saves a docx doc to local file with specified file name
     * @param doc
     */
    save(doc) {
        const {date} = formatDate(new Date());
        Packer.toBlob(doc).then(blob => (
            saveAs(blob, `SHeLL-Editor_${date}.docx`)
        ));
    }

    /**
     * Create a docx doc from draftjs editorstate
     * Doc should imitate the appearance of the editor content as closely as possible (minus decorator highlights)
     * @param editorState
     * @returns {Document}
     */
    create(editorState) {
        const doc = new Document(documentConfig);

        const contentBlocks = editorState.getCurrentContent().getBlocksAsArray();

        const paragraphs = [];
        contentBlocks.forEach(block => {

            const blockType = block.getType();
            const styledText = this.getStyledText(block);

            paragraphs.push(this.createBlock(styledText, blockType));
        });


        doc.addSection({children: paragraphs});
        return doc;
    }

    /**
     * Create the docx equivalent of a draftjs block (a Paragraph object)
     * @param styledText
     * @param blockType
     * @returns {Paragraph}
     */
    createBlock(styledText, blockType) {
        const options = {children: styledText, style: blockType};
        if (blockType === 'ordered-list-item') {
            options.numbering = {level: 0, reference: 'numbers'};
        }
        return new Paragraph(options);
    }

    /**
     * Convert all draftjs style ranges (consecutive characters with the same style) to docx TextRuns with
     * the equivalent styling configuration
     * @param block
     * @returns {[]}
     */
    getStyledText(block) {
        const blockText = block.getText();
        const styledText = [];
        block.findStyleRanges(
            () => true, // get every range including unstyled
            (start, end) => {
                const styles = block.getInlineStyleAt(start);

                // Styled ranges add an options parameter with each style set to true
                const options = {};
                styles.forEach(style => {
                    const converted = this.convertDraftStyleToDocX(style);
                    if (!converted) {
                        return console.log('unsupported draftjs style:', style);
                    }
                    options[converted] = true;
                });
                styledText.push(new TextRun({
                    text: blockText.slice(start, end),
                    ...options
                }));
            }
        );
        return styledText;
    }

    /**
     * Convert supported draftjs style literals to docx equivalent
     * @param style = draftjs style to convert
     * @returns string|undefined
     */
    convertDraftStyleToDocX(style) {
        return {
            BOLD: 'bold',
            ITALIC: 'italics',
            UNDERLINE: 'underline'
        }[style];
    }


}
