import {EditorState, convertToRaw} from 'draft-js';
import {IMPORT_PINNED_RESULTS, PIN_RESULTS, UNPIN_RESULTS} from 'redux/actions/actionTypes';
import {decoratorState as initialDecoratorState} from '../../constants/state';
import {initialState as initialCountsState} from './counts';
import {initialState as initialExcludedState} from './excluded';
import {initialState as initialHighlightsState} from './highlights';
import {initialState as initialResponseState} from './response';

const initialState = {
    pinned: false,
    results: {
        readability: null,
        counts: initialCountsState,
        decoratorState: initialDecoratorState,
        excluded: initialExcludedState,
        highlights: initialHighlightsState,
        response: initialResponseState,
        rawContentState: convertToRaw(EditorState.createEmpty().getCurrentContent())
    }
};

const pinnedResults = (state = initialState, action) => {

    switch (action.type) {
        case PIN_RESULTS: {
            return {
                pinned: true,
                results: action.payload
            };
        }

        case UNPIN_RESULTS: {
            return initialState;
        }

        case IMPORT_PINNED_RESULTS: {
            return action.payload;
        }

        default:
            return state;
    }

};

export default pinnedResults;
