import {MenuItem} from '@material-ui/core';
import React, {useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import DownArrow from '@material-ui/icons/ExpandMore';
import {connect} from 'react-redux';
import {getOrderedDecoratorsByCategory} from 'utils/common';
import decorators from 'decorators';
import {categories} from '../constants/content';

import './styles/toggle.css';


// Count the most decorators that can be enabled at a time
// (person-centred category has max 1 enabled so we only count that once if found)
let personCentredFound = false;
const maxPossibleEnabledDecorators = Object.keys(decorators).reduce((acc, current) => {
    if (decorators[current].category === 'person-centred') {
        if (personCentredFound) {
            return acc;
        } else {
            personCentredFound = true;
            return acc + 1;
        }
    }
    return acc + 1;
}, 0);


/**
 * The dropdown that allows toggling of each decorator
 */
const DecoratorToggle = ({decoratorState, setDecoratorState}) => {

    const [enabled, setEnabled] = useState(decoratorState.enabled);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [checked, setChecked] = useState(decoratorState.decorators);

    /**
     * When enabling/disabling ALL highlights
     * @param e = event
     */
    const onToggleAll = e => {
        e.preventDefault();
        e.stopPropagation();

        setEnabled(!enabled);
    };

    /**
     * When closing the toggle menu
     */
    const onClose = () => {
        setMenuAnchor(null);
        setDecoratorState({enabled, decorators: checked});
    };

    /**
     * When opening the toggle menu
     * @param e = event
     */
    const onOpen = e => {
        setEnabled(decoratorState.enabled);
        setChecked(decoratorState.decorators);
        setMenuAnchor(e.currentTarget);
    };

    /**
     * When checking an individual decorator on/off
     * @param decorator = decorator object exported from decorators/strategies folder, for the clicked decorator
     */
    const onToggleDecorator = decorator => e => {
        e.preventDefault();
        e.stopPropagation();

        // Are we checking this box?
        const checking = !checked[decorator.name];

        const nextState = {
            ...checked,
            [decorator.name]: checking
        };

        // Special case for max = 1 enabled (i.e. custom radio logic, currently just person-centred)
        if (decorator.category === 'person-centred' && checking) {
            // Look for any same-category decorators uncheck them
            for (const name in decorators) {
                if (decorators[name].category === decorator.category && checked[name]) {
                    nextState[name] = false;
                }
            }
        }

        setChecked(nextState);
    };

    /**
     * When checking a "toggle all" checkbox for a category
     * @param category = category object {key, name} that was clicked
     */
    const onToggleCategory = category => e => {
        e.preventDefault();
        e.stopPropagation();

        const [isChecked] = getCategoryCheckState(category);

        const nextState = {...checked};
        for (let name in decorators) {
            if (decorators[name].category === category.name) {
                // Annoying custom "max 1" logic
                if (category.name === 'person-centred') {
                    nextState[name] = false;
                    continue;
                }
                nextState[name] = !isChecked;
            }
        }
        setChecked(nextState);
    };

    /**
     * Gets the checked and indeterminate state of a category checkbox
     * Basically checked = at least 1 category's decorator enabled, indeterminate = at least 1 but less than all
     * @param category
     * @returns {[boolean, boolean]} = [checked, indeterminate]
     */
    const getCategoryCheckState = category => {
        let enabledCount = 0;
        let totalCount = 0;
        for (let name in decorators) {
            if (decorators[name].category === category.name) {
                if (checked[name]) {
                    enabledCount++;
                }
                totalCount++;
            }
        }
        return [enabledCount > 0, enabledCount > 0 && enabledCount !== totalCount];
    };


    /**
     * Render a toggle button label for a single decorator
     * @param decorator = full decorator item (object returned by decorators/strategies/<name>.js)
     */
    const renderDecoratorLabel = decorator => {
        return <MenuItem onClick={onToggleDecorator(decorator)} disabled={!enabled}>
            <FormControlLabel
                key={decorator.name}
                control={<Checkbox
                    checked={checked[decorator.name]}
                    color={'primary'}
                    name={decorator.name}
                    className={'decorator-list-checkbox'}
                    disabled={!enabled}
                />}
                label={decorator.longName}
                className={'decoratorToggle'}
                disabled={!enabled}
            />

        </MenuItem>;
    };

    /**
     * Render a toggle whole category button label for a category
     * @param category = category name
     */
    const renderToggleCategory = category => {
        const [isChecked, isIndeterminate] = getCategoryCheckState(category);

        return <MenuItem onClick={onToggleCategory(category)} disabled={!enabled}>
            <FormControlLabel
                control={<Checkbox
                    checked={isChecked}
                    color={'primary'}
                    indeterminate={isIndeterminate}
                    name={category.name}
                    className={'decorator-list-checkbox'}
                    disabled={!enabled}
                />}
                label={<b>{category.longName}{category.postText}</b>}
                className={'categoryToggle'}
                disabled={!enabled}
            />
        </MenuItem>;
    };

    const activeDecoratorCount = Object.keys(decoratorState.decorators).filter(name => decoratorState.decorators[name]).length;

    return <div className={'flex-center-column'} data-tour={'step-seven'}>

        <Button
            onClick={onOpen}
            variant={'outlined'}
            style={{paddingRight: 8}}
        >
            {enabled ? activeDecoratorCount : 0} of {maxPossibleEnabledDecorators} features active <DownArrow/>
        </Button>
        <Menu
            id="decorator-menu"
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={onClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <div className={'toggle-container'}>
                <MenuItem onClick={onToggleAll}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={enabled}
                            color={'primary'}
                            className={'decorator-list-checkbox'}
                        />}
                        label={<b>Enable Highlights</b>}
                        className={'categoryToggle'}
                    />
                </MenuItem>
                <Divider className={'my-2'}/>
            </div>

            {categories.map((category, i) => {
                if (category.sidebarOnly || category.disable) return null;

                return [
                    i > 0 && <Divider className={'my-2'}/>,
                    renderToggleCategory(category),
                    getOrderedDecoratorsByCategory(decoratorState, category.name, false).map(renderDecoratorLabel)
                ];
            })}
        </Menu>
    </div>;
};

const mapPropsToState = ({decoratorState}) => ({decoratorState});


export default connect(mapPropsToState)(DecoratorToggle);

