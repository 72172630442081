import {createDecorator} from 'decorators/decoratorCreator';
import {nanoid} from 'nanoid';
import {sentencesInString, syllableCountInWord, wordsInString} from 'utils/regex';
import store from 'redux/store';
import {setHighlightCount} from 'redux/actions/actions';
import {AUTO_IGNORE, POLYSYLLABLE_THRESHOLD} from 'constants/vars';

const longWord = {
    name: 'longWord',
    longName: 'Long Words',
    className: 'highlight-yellow',
    category: 'readability',
    postText: count => count === 1 ?
        'word is long (more than 2 syllables).' :
        'words are long (more than 2 syllables).',
    sidebarPriority: 0
};


const strategy = (contentBlock, callback, contentState) => {
    const amount = count(contentBlock, callback, contentState);
    store.dispatch(setHighlightCount(longWord.name, contentBlock.getKey(), amount));
};

/**
 * Used in the /src/MultiTextInput.js calculation, so exporting the counting separately for this strategy
 * @param contentBlock
 * @param callback
 * @param contentState
 */
export const count = (contentBlock, callback, contentState) => {
    const text = contentBlock.getText();

    const words = wordsInString(text, true);
    const sentences = sentencesInString(text, true);
    // For each sentence, determine number of words and flag as too long if reaches threshold
    let amount = 0;
    words.forEach(word => {
        const wordText = word[0];

        // Do not highlight ignored words
        const entityKey = contentBlock.getEntityAt(word.index);
        let entityType = '';
        if (entityKey) {
            const entity = contentState.getEntity(entityKey);
            entityType = entity.getType();
        }

        let sentenceWordCount = AUTO_IGNORE + 1;
        for (let sentence of sentences) {
            // If the word is in this sentence (i.e. its index is between the start and end of the sentence)
            if (word.index >= sentence.index && word.index < (sentence.index + sentence.text.length)) {
                sentenceWordCount = wordsInString(sentence.text, true).length;
                break;
            }
        }

        if (entityType === 'IGNORED' || (entityType !== 'INCLUDED' && sentenceWordCount <= AUTO_IGNORE)) {
            return;
        }


        const syllableCount = syllableCountInWord(wordText);
        if (!syllableCount) return;
        if (syllableCount >= POLYSYLLABLE_THRESHOLD) {
            amount += 1;
            callback && callback(word.index, word.index + wordText.length, {instance: nanoid()});
        }
    });

    return amount;
};

longWord.decorator = createDecorator(strategy, longWord);
export default longWord;
