import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const financialTerms = {
    name: 'financialTerms',
    longName: 'Financial Terms',
    className: 'highlight-lightPurple',
    category: 'complex',
    postText: count => count === 1 ?
        'financial term.' :
        'financial terms.',
    sidebarPriority: 3,
    tooltip: {
        text: 'There may be a simpler alternative if this language is used in a financial context.'
    }
};

const strategy = serverStrategyCreator(financialTerms.name);
const pinnedStrategy = serverStrategyCreator(financialTerms.name, true);

financialTerms.decorator = createDecorator(strategy, financialTerms);
financialTerms.pinnedDecorator = createDecorator(pinnedStrategy, financialTerms);
export default financialTerms;
