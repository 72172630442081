/**
 * @AUTHOR: https://github.com/SamyPesse/draft-js-multidecorators
 * Modified to pass contentState to strategies
 */


import {List} from 'immutable';

var KEY_SEPARATOR = '-';

function MultiDecorator(decorators) {
    this.decorators = List(decorators);
}

/**
 * Return list of decoration IDs per character
 * @param block
 * @param contentState
 * @return {List<String>}
 */
MultiDecorator.prototype.getDecorations = function (block, contentState) {
    var decorations = Array(block.getText().length).fill(null);

    this.decorators.forEach(function (decorator, i) {
        var _decorations = decorator.getDecorations(block, contentState);

        _decorations.forEach(function (key, offset) {
            if (!key) {
                return;
            }

            key = i + KEY_SEPARATOR + key;

            decorations[offset] = key;
        });
    });

    return List(decorations);
};

/**
 Return component to render a decoration

 @return {Function}
 * @param key
 */
MultiDecorator.prototype.getComponentForKey = function (key) {
    var decorator = this.getDecoratorForKey(key);
    return decorator.getComponentForKey(
        this.getInnerKey(key)
    );
};

/**
 Return props to render a decoration

 @return {Object}
 * @param key
 */
MultiDecorator.prototype.getPropsForKey = function (key) {
    var decorator = this.getDecoratorForKey(key);
    return decorator.getPropsForKey(
        this.getInnerKey(key)
    );
};

/**
 Return a decorator for a specific key

 @return {Decorator}
 * @param key
 */
MultiDecorator.prototype.getDecoratorForKey = function (key) {
    var parts = key.split(KEY_SEPARATOR);
    var index = Number(parts[0]);

    return this.decorators.get(index);
};

/**
 Return inner key for a decorator

 @return {String}
 * @param key
 */
MultiDecorator.prototype.getInnerKey = function (key) {
    var parts = key.split(KEY_SEPARATOR);
    return parts.slice(1).join(KEY_SEPARATOR);
};

export default MultiDecorator;
