/**
 * Syllable dictionary for most words
 */

const syllables = {
    'a': 1,
    'aaa': 3,
    'aaberg': 2,
    'aachen': 2,
    'aachener': 3,
    'aaker': 2,
    'aalseth': 2,
    'aamodt': 2,
    'aancor': 2,
    'aardema': 3,
    'aardvark': 2,
    'aaron': 2,
    'aarons': 2,
    'aaronson': 3,
    'aarti': 2,
    'aase': 1,
    'aasen': 2,
    'ab': 1,
    'ababa': 3,
    'abacha': 3,
    'aback': 2,
    'abaco': 3,
    'abacus': 3,
    'abad': 2,
    'abadaka': 4,
    'abadi': 3,
    'abadie': 3,
    'abair': 2,
    'abalkin': 3,
    'abalone': 4,
    'abalos': 3,
    'abandon': 3,
    'abandoned': 3,
    'abandoning': 4,
    'abandonment': 4,
    'abandonments': 4,
    'abandons': 3,
    'abanto': 3,
    'abarca': 3,
    'abare': 3,
    'abascal': 3,
    'abash': 2,
    'abashed': 2,
    'abate': 2,
    'abated': 3,
    'abatement': 3,
    'abatements': 3,
    'abates': 2,
    'abating': 3,
    'abba': 2,
    'abbado': 3,
    'abbas': 2,
    'abbasi': 3,
    'abbate': 2,
    'abbatiello': 5,
    'abbe': 2,
    'abbenhaus': 3,
    'abbett': 2,
    'abbeville': 2,
    'abbey': 2,
    'abbie': 2,
    'abbitt': 2,
    'abbot': 2,
    'abbotstown': 3,
    'abbott': 2,
    'abbottstown': 3,
    'abboud': 2,
    'abbreviate': 4,
    'abbreviated': 5,
    'abbreviates': 4,
    'abbreviating': 5,
    'abbreviation': 5,
    'abbreviations': 5,
    'abbruzzese': 4,
    'abbs': 1,
    'abby': 2,
    'abco': 2,
    'abcotek': 3,
    'abdalla': 3,
    'abdallah': 3,
    'abdel': 2,
    'abdella': 3,
    'abdicate': 3,
    'abdicated': 4,
    'abdicates': 3,
    'abdicating': 4,
    'abdication': 4,
    'abdnor': 2,
    'abdo': 2,
    'abdollah': 3,
    'abdomen': 3,
    'abdominal': 4,
    'abduct': 2,
    'abducted': 3,
    'abductee': 3,
    'abductees': 3,
    'abducting': 3,
    'abduction': 3,
    'abductions': 3,
    'abductor': 3,
    'abductors': 3,
    'abducts': 2,
    'abdul': 2,
    'abdulaziz': 4,
    'abdulla': 3,
    'abdullah': 3,
    'abe': 1,
    'abed': 2,
    'abedi': 3,
    'abee': 2,
    'abel': 2,
    'abela': 3,
    'abelard': 3,
    'abele': 2,
    'abeles': 2,
    'abell': 2,
    'abella': 3,
    'abeln': 2,
    'abelow': 3,
    'abels': 2,
    'abelson': 3,
    'abend': 2,
    'abendroth': 3,
    'aber': 2,
    'abercrombie': 4,
    'aberdeen': 3,
    'aberford': 3,
    'aberg': 2,
    'aberle': 3,
    'abermin': 3,
    'abernathy': 4,
    'abernethy': 4,
    'aberrant': 3,
    'aberration': 4,
    'aberrational': 5,
    'aberrations': 4,
    'abert': 2,
    'abet': 2,
    'abetted': 3,
    'abetting': 3,
    'abex': 2,
    'abeyance': 3,
    'abeyta': 3,
    'abhor': 2,
    'abhorred': 2,
    'abhorrence': 3,
    'abhorrent': 3,
    'abhors': 2,
    'abiam': 3,
    'abid': 2,
    'abide': 2,
    'abided': 3,
    'abides': 2,
    'abiding': 3,
    'abidjan': 3,
    'abie': 2,
    'abigail': 3,
    'abila': 3,
    'abilene': 3,
    'abilities': 4,
    'ability': 4,
    'abimael': 3,
    'abimaels': 3,
    'abingdon': 3,
    'abington': 3,
    'abio': 3,
    'abiola': 4,
    'abiomed': 4,
    'abiquiu': 3,
    'abitibi': 4,
    'abitz': 2,
    'abject': 2,
    'abkhazia': 3,
    'abkhazian': 4,
    'abkhazians': 4,
    'ablaze': 2,
    'able': 2,
    'abled': 2,
    'abler': 3,
    'ables': 2,
    'ablest': 2,
    'abloom': 2,
    'ably': 2,
    'abner': 2,
    'abney': 2,
    'abnormal': 3,
    'abnormalities': 5,
    'abnormality': 5,
    'abnormally': 4,
    'abo': 2,
    'aboard': 2,
    'abode': 2,
    'abohalima': 5,
    'abolish': 3,
    'abolished': 3,
    'abolishes': 4,
    'abolishing': 4,
    'abolition': 4,
    'abolitionism': 6,
    'abolitionist': 5,
    'abolitionists': 5,
    'abominable': 5,
    'abomination': 5,
    'abood': 2,
    'aboodi': 3,
    'abor': 2,
    'aboriginal': 5,
    'aborigine': 5,
    'aborigines': 5,
    'aborn': 2,
    'abort': 2,
    'aborted': 3,
    'abortifacient': 5,
    'abortifacients': 5,
    'aborting': 3,
    'abortion': 3,
    'abortionist': 4,
    'abortionists': 4,
    'abortions': 3,
    'abortive': 3,
    'aborts': 2,
    'abott': 2,
    'abou': 2,
    'aboud': 2,
    'abouhalima': 5,
    'abound': 2,
    'abounded': 3,
    'abounding': 3,
    'abounds': 2,
    'about': 2,
    'above': 2,
    'aboveboard': 3,
    'abplanalp': 3,
    'abra': 2,
    'abracadabra': 5,
    'abraham': 3,
    'abrahamian': 5,
    'abrahams': 3,
    'abrahamsen': 4,
    'abrahamson': 4,
    'abram': 2,
    'abramczyk': 3,
    'abramo': 3,
    'abramovitz': 4,
    'abramowicz': 4,
    'abramowitz': 4,
    'abrams': 2,
    'abramson': 3,
    'abrasion': 3,
    'abrasions': 3,
    'abrasive': 3,
    'abrasives': 3,
    'abraxa': 3,
    'abraxas': 3,
    'abreast': 2,
    'abrego': 3,
    'abreu': 2,
    'abridge': 2,
    'abridged': 2,
    'abridgement': 3,
    'abridges': 3,
    'abridging': 3,
    'abril': 2,
    'abroad': 2,
    'abrogate': 3,
    'abrogated': 4,
    'abrogating': 4,
    'abrogation': 4,
    'abrol': 2,
    'abron': 2,
    'abrupt': 2,
    'abruptly': 3,
    'abruptness': 3,
    'abrutyn': 3,
    'abruzzese': 4,
    'abruzzo': 3,
    'abs': 3,
    'absalom': 3,
    'absaraka': 4,
    'abscam': 2,
    'abscess': 2,
    'abscond': 2,
    'absconded': 3,
    'absconding': 3,
    'absconds': 2,
    'absecon': 3,
    'absence': 2,
    'absences': 3,
    'absent': 2,
    'absentee': 3,
    'absenteeism': 5,
    'absentees': 3,
    'absentia': 3,
    'absher': 2,
    'abshier': 3,
    'abshire': 2,
    'absinthe': 2,
    'abso': 2,
    'absolom': 3,
    'absolut': 3,
    'absolute': 3,
    'absolutely': 4,
    'absoluteness': 4,
    'absolutes': 3,
    'absolution': 4,
    'absolutism': 5,
    'absolutist': 4,
    'absolve': 2,
    'absolved': 2,
    'absolves': 2,
    'absolving': 3,
    'absorb': 2,
    'absorbed': 2,
    'absorbency': 4,
    'absorbent': 3,
    'absorber': 3,
    'absorbers': 3,
    'absorbing': 3,
    'absorbs': 2,
    'absorption': 3,
    'abstain': 2,
    'abstained': 2,
    'abstaining': 3,
    'abstention': 3,
    'abstentions': 3,
    'abstinence': 3,
    'abstinent': 3,
    'abston': 2,
    'abstract': 2,
    'abstracted': 3,
    'abstraction': 3,
    'abstractions': 3,
    'abstracts': 2,
    'abstruse': 2,
    'absurd': 2,
    'absurdist': 3,
    'absurdities': 4,
    'absurdity': 4,
    'absurdly': 3,
    'abt': 1,
    'abts': 1,
    'abu': 2,
    'abudrahm': 3,
    'abuellah': 3,
    'abuladze': 4,
    'abundance': 3,
    'abundant': 3,
    'abundantly': 4,
    'aburto': 3,
    'abuse': 2,
    'abused': 2,
    'abuser': 3,
    'abusers': 3,
    'abuses': 3,
    'abusing': 3,
    'abusive': 3,
    'abut': 2,
    'abuts': 2,
    'abutted': 3,
    'abutting': 3,
    'abuzz': 2,
    'abysmal': 3,
    'abysmally': 4,
    'abyss': 2,
    'abyssinia': 4,
    'abyssinian': 4,
    'abzug': 2,
    'ac': 2,
    'aca': 2,
    'acacia': 3,
    'academe': 3,
    'academia': 5,
    'academic': 4,
    'academically': 5,
    'academician': 5,
    'academicians': 5,
    'academics': 4,
    'academies': 4,
    'academy': 4,
    'acadia': 4,
    'acampo': 3,
    'acampora': 4,
    'acantha': 3,
    'acapulco': 4,
    'acary': 3,
    'accardi': 3,
    'accardo': 3,
    'accede': 2,
    'acceded': 3,
    'accedes': 2,
    'acceding': 3,
    'accel': 2,
    'accelerant': 4,
    'accelerants': 4,
    'accelerate': 4,
    'accelerated': 5,
    'accelerates': 4,
    'accelerating': 5,
    'acceleration': 5,
    'accelerator': 5,
    'accelerators': 5,
    'accelerometer': 6,
    'accelerometers': 6,
    'accent': 2,
    'accented': 3,
    'accenting': 3,
    'accents': 2,
    'accentuate': 4,
    'accentuated': 5,
    'accentuates': 4,
    'accentuating': 5,
    'accept': 2,
    'acceptability': 6,
    'acceptable': 4,
    'acceptably': 4,
    'acceptance': 3,
    'acceptances': 4,
    'accepted': 3,
    'accepting': 3,
    'accepts': 2,
    'access': 2,
    'accessed': 2,
    'accessibility': 6,
    'accessible': 4,
    'accessing': 3,
    'accession': 3,
    'accessories': 4,
    'accessorize': 4,
    'accessorized': 4,
    'accessory': 4,
    'accetta': 3,
    'accident': 3,
    'accidental': 4,
    'accidentally': 5,
    'accidently': 4,
    'accidents': 3,
    'accion': 3,
    'accival': 3,
    'acclaim': 2,
    'acclaimed': 2,
    'acclaiming': 3,
    'acclaims': 2,
    'acclamation': 4,
    'acclimate': 3,
    'acclimated': 4,
    'acclimation': 4,
    'acco': 2,
    'accokeek': 3,
    'accola': 3,
    'accolade': 3,
    'accolades': 3,
    'accomando': 4,
    'accommodate': 4,
    'accommodated': 5,
    'accommodates': 4,
    'accommodating': 5,
    'accommodation': 5,
    'accommodations': 5,
    'accommodative': 5,
    'accompanied': 4,
    'accompanies': 4,
    'accompaniment': 4,
    'accompaniments': 4,
    'accompanist': 4,
    'accompany': 4,
    'accompanying': 5,
    'accompli': 3,
    'accomplice': 3,
    'accomplices': 4,
    'accomplish': 3,
    'accomplished': 3,
    'accomplishes': 4,
    'accomplishing': 4,
    'accomplishment': 4,
    'accomplishments': 4,
    'accor': 2,
    'accord': 2,
    'accordance': 3,
    'accorded': 3,
    'according': 3,
    'accordingly': 4,
    'accordion': 4,
    'accordions': 4,
    'accords': 2,
    'accost': 2,
    'accosted': 3,
    'accosting': 3,
    'account': 2,
    'accountability': 6,
    'accountable': 4,
    'accountancy': 4,
    'accountant': 3,
    'accountants': 3,
    'accounted': 3,
    'accountemp': 3,
    'accountemps': 3,
    'accounting': 3,
    'accounts': 2,
    'accouterment': 4,
    'accouterments': 4,
    'accoutrement': 4,
    'accoutrements': 4,
    'accredit': 3,
    'accreditation': 5,
    'accreditations': 5,
    'accredited': 4,
    'accrediting': 4,
    'accreted': 3,
    'accretion': 3,
    'accrual': 3,
    'accruals': 3,
    'accrue': 2,
    'accrued': 2,
    'accrues': 2,
    'accruing': 3,
    'accu': 2,
    'accuhealth': 3,
    'accumulate': 4,
    'accumulated': 5,
    'accumulates': 4,
    'accumulating': 5,
    'accumulation': 5,
    'accumulations': 5,
    'accumulative': 5,
    'accumulatively': 6,
    'accumulator': 5,
    'accumulators': 5,
    'accuracies': 4,
    'accuracy': 4,
    'accurate': 3,
    'accurately': 4,
    'accuray': 3,
    'accuride': 3,
    'accurso': 3,
    'accusation': 4,
    'accusations': 4,
    'accusative': 4,
    'accusatory': 5,
    'accuse': 2,
    'accused': 2,
    'accuser': 3,
    'accusers': 3,
    'accuses': 3,
    'accusing': 3,
    'accusingly': 4,
    'accustom': 3,
    'accustomed': 3,
    'accutane': 3,
    'ace': 1,
    'acecomm': 2,
    'aced': 1,
    'acer': 2,
    'acerbic': 3,
    'acero': 3,
    'acerra': 3,
    'aces': 2,
    'acetaminophen': 6,
    'acetate': 3,
    'acetic': 3,
    'aceto': 3,
    'acetochlor': 4,
    'acetone': 3,
    'acetosyringone': 6,
    'acetylcholine': 5,
    'acetylene': 4,
    'acevedo': 4,
    'aceves': 3,
    'acey': 2,
    'achaean': 3,
    'achatz': 2,
    'ache': 1,
    'achebe': 3,
    'ached': 1,
    'achee': 2,
    'achenbach': 3,
    'achenbaum': 3,
    'aches': 1,
    'acheson': 3,
    'achesons': 3,
    'achey': 2,
    'achieva': 3,
    'achievable': 4,
    'achieve': 2,
    'achieved': 2,
    'achievement': 3,
    'achievements': 3,
    'achiever': 3,
    'achievers': 3,
    'achieves': 2,
    'achieving': 3,
    'achille': 3,
    'achilles': 3,
    'aching': 2,
    'achingly': 3,
    'achmed': 2,
    'achoa': 3,
    'achor': 2,
    'achord': 2,
    'achorn': 2,
    'achtenberg': 3,
    'achterberg': 3,
    'achy': 2,
    'acid': 2,
    'acidic': 3,
    'acidification': 6,
    'acidified': 4,
    'acidifies': 4,
    'acidify': 4,
    'acidity': 4,
    'acidly': 3,
    'acidosis': 4,
    'acids': 2,
    'aciduria': 5,
    'acierno': 3,
    'ack': 1,
    'acker': 2,
    'ackerley': 3,
    'ackerly': 3,
    'ackerman': 3,
    'ackermann': 3,
    'ackermanville': 4,
    'ackerson': 3,
    'ackert': 2,
    'ackhouse': 2,
    'ackland': 2,
    'ackles': 2,
    'ackley': 2,
    'acklin': 2,
    'ackman': 2,
    'acknowledge': 3,
    'acknowledgeable': 5,
    'acknowledged': 3,
    'acknowledgement': 4,
    'acknowledgements': 4,
    'acknowledges': 4,
    'acknowledging': 4,
    'acknowledgment': 4,
    'ackroyd': 2,
    'acmat': 2,
    'acme': 2,
    'acne': 2,
    'acocella': 4,
    'acoff': 2,
    'acog': 2,
    'acolyte': 3,
    'acolytes': 3,
    'acord': 2,
    'acordia': 4,
    'acorn': 2,
    'acorns': 2,
    'acosta': 3,
    'acott': 2,
    'acoustic': 3,
    'acoustical': 4,
    'acoustically': 4,
    'acoustics': 3,
    'acquaint': 2,
    'acquaintance': 3,
    'acquaintances': 4,
    'acquaintanceship': 4,
    'acquainted': 3,
    'acquaviva': 4,
    'acquiesce': 3,
    'acquiesced': 3,
    'acquiescence': 4,
    'acquiescing': 4,
    'acquire': 3,
    'acquired': 3,
    'acquirer': 4,
    'acquirers': 4,
    'acquires': 3,
    'acquiring': 3,
    'acquisition': 4,
    'acquisitions': 4,
    'acquisitive': 4,
    'acquit': 2,
    'acquitaine': 3,
    'acquits': 2,
    'acquittal': 3,
    'acquittals': 3,
    'acquitted': 3,
    'acquitting': 3,
    'acre': 2,
    'acreage': 3,
    'acree': 2,
    'acres': 2,
    'acrey': 2,
    'acri': 2,
    'acrid': 2,
    'acrimonious': 5,
    'acrimony': 4,
    'acro': 2,
    'acrobat': 3,
    'acrobatic': 4,
    'acrobatics': 4,
    'acrobats': 3,
    'acrolein': 3,
    'acronym': 3,
    'acronyms': 3,
    'acropolis': 4,
    'across': 2,
    'acrylic': 3,
    'acrylics': 3,
    'act': 1,
    'actava': 3,
    'actavas': 3,
    'acted': 2,
    'actel': 2,
    'actigall': 3,
    'actin': 2,
    'acting': 2,
    'actinide': 3,
    'actinidia': 5,
    'actinomycosis': 6,
    'action': 2,
    'actionable': 4,
    'actions': 2,
    'activase': 3,
    'activate': 3,
    'activated': 4,
    'activates': 3,
    'activating': 4,
    'activation': 4,
    'activator': 4,
    'active': 2,
    'actively': 3,
    'actives': 2,
    'activision': 4,
    'activism': 4,
    'activist': 3,
    'activists': 3,
    'activities': 4,
    'activity': 4,
    'actmedia': 4,
    'actodine': 3,
    'acton': 2,
    'actor': 2,
    'actors': 2,
    'actress': 2,
    'actresses': 3,
    'acts': 1,
    'actual': 3,
    'actuality': 5,
    'actualize': 4,
    'actually': 4,
    'actuarial': 5,
    'actuaries': 4,
    'actuary': 4,
    'actuate': 3,
    'actuator': 4,
    'actuators': 4,
    'actus': 2,
    'acuff': 2,
    'acuity': 4,
    'acumen': 3,
    'acuna': 3,
    'acupuncture': 4,
    'acura': 3,
    'acuras': 3,
    'acuson': 3,
    'acustar': 3,
    'acusyst': 3,
    'acute': 2,
    'acutely': 3,
    'acuteness': 3,
    'acyclovir': 4,
    'ad': 1,
    'ada': 2,
    'adabel': 3,
    'adabelle': 3,
    'adachi': 3,
    'adage': 2,
    'adagio': 4,
    'adah': 2,
    'adair': 2,
    'adaire': 2,
    'adak': 2,
    'adalah': 3,
    'adalia': 4,
    'adam': 2,
    'adamant': 3,
    'adamantly': 4,
    'adamcik': 3,
    'adamczak': 3,
    'adamczyk': 3,
    'adame': 3,
    'adamec': 3,
    'adamek': 3,
    'adames': 2,
    'adami': 3,
    'adamik': 3,
    'adamina': 4,
    'adamkus': 3,
    'adamo': 3,
    'adamowicz': 4,
    'adams': 2,
    'adamski': 3,
    'adamson': 3,
    'adamstown': 3,
    'adan': 2,
    'adapso': 3,
    'adapt': 2,
    'adaptability': 6,
    'adaptable': 4,
    'adaptaplex': 4,
    'adaptation': 4,
    'adaptations': 4,
    'adaptec': 3,
    'adapted': 3,
    'adapter': 3,
    'adapters': 3,
    'adapting': 3,
    'adaptive': 3,
    'adaptor': 3,
    'adaptors': 3,
    'adapts': 2,
    'adar': 2,
    'adarand': 3,
    'adas': 2,
    'aday': 2,
    'adaza': 3,
    'adcock': 2,
    'adcox': 2,
    'add': 1,
    'addair': 2,
    'addams': 2,
    'added': 2,
    'addendum': 3,
    'addendums': 3,
    'addeo': 3,
    'adder': 2,
    'adderley': 3,
    'addicks': 2,
    'addict': 2,
    'addicted': 3,
    'addicting': 3,
    'addiction': 3,
    'addictions': 3,
    'addictive': 3,
    'addicts': 2,
    'addidas': 3,
    'addidases': 4,
    'addie': 2,
    'adding': 2,
    'addington': 3,
    'addis': 2,
    'addison': 3,
    'addition': 3,
    'additional': 4,
    'additionally': 5,
    'additions': 3,
    'additive': 3,
    'additives': 3,
    'addle': 2,
    'addled': 2,
    'addleman': 3,
    'address': 2,
    'addressable': 4,
    'addressed': 2,
    'addressee': 3,
    'addresses': 3,
    'addressing': 3,
    'adds': 1,
    'adduci': 3,
    'adduct': 2,
    'addwest': 2,
    'addy': 2,
    'addyston': 3,
    'ade': 1,
    'adee': 2,
    'adel': 2,
    'adela': 3,
    'adelaar': 3,
    'adelaide': 3,
    'adelanto': 4,
    'adelbert': 3,
    'adele': 2,
    'adeline': 3,
    'adelizzi': 4,
    'adell': 2,
    'adelle': 2,
    'adelman': 3,
    'adelmann': 3,
    'adelpha': 3,
    'adelphi': 3,
    'adelphia': 4,
    'adelsberger': 4,
    'adelson': 3,
    'adelstein': 3,
    'aden': 2,
    'adena': 3,
    'adenauer': 3,
    'adenine': 3,
    'adenoid': 3,
    'adenoids': 3,
    'adenoscan': 4,
    'adenosine': 4,
    'adenovirus': 5,
    'adept': 2,
    'adequacy': 4,
    'adequate': 3,
    'adequately': 4,
    'ader': 2,
    'aderhold': 3,
    'aderholt': 3,
    'aderman': 3,
    'ades': 1,
    'adey': 2,
    'adger': 2,
    'adham': 2,
    'adhere': 2,
    'adhered': 2,
    'adherence': 3,
    'adherent': 3,
    'adherents': 3,
    'adheres': 2,
    'adhering': 3,
    'adhesion': 3,
    'adhesive': 3,
    'adhesives': 3,
    'adia': 3,
    'adid': 2,
    'adidas': 3,
    'adieu': 2,
    'adin': 2,
    'adina': 3,
    'adine': 3,
    'adinolfi': 4,
    'adios': 3,
    'adipose': 3,
    'adirondack': 4,
    'adisq': 2,
    'adjacent': 3,
    'adjani': 3,
    'adjective': 3,
    'adjectives': 3,
    'adjoin': 2,
    'adjoining': 3,
    'adjoins': 2,
    'adjourn': 2,
    'adjourned': 2,
    'adjourning': 3,
    'adjournment': 3,
    'adjourns': 2,
    'adjudge': 2,
    'adjudged': 2,
    'adjudicate': 4,
    'adjudicated': 5,
    'adjudicating': 5,
    'adjudication': 5,
    'adjunct': 2,
    'adjuncts': 2,
    'adjust': 2,
    'adjustable': 4,
    'adjustables': 4,
    'adjusted': 3,
    'adjuster': 3,
    'adjusters': 3,
    'adjusting': 3,
    'adjustment': 3,
    'adjustments': 3,
    'adjusts': 2,
    'adjutant': 3,
    'adkins': 2,
    'adkinson': 3,
    'adkison': 3,
    'adkisson': 3,
    'adlai': 3,
    'adler': 2,
    'adlers': 2,
    'adley': 2,
    'adlon': 2,
    'adlong': 2,
    'adman': 2,
    'admen': 2,
    'administer': 4,
    'administered': 4,
    'administering': 5,
    'administers': 4,
    'administrate': 4,
    'administrating': 5,
    'administration': 5,
    'administrations': 5,
    'administrative': 5,
    'administratively': 6,
    'administrator': 5,
    'administrators': 5,
    'admira': 3,
    'admirable': 4,
    'admirably': 4,
    'admiral': 3,
    'admirals': 3,
    'admiralty': 4,
    'admiration': 4,
    'admirations': 4,
    'admire': 2,
    'admired': 3,
    'admirer': 3,
    'admirers': 3,
    'admires': 2,
    'admiring': 3,
    'admiringly': 4,
    'admissibility': 6,
    'admissible': 4,
    'admission': 3,
    'admissions': 3,
    'admit': 2,
    'admits': 2,
    'admittance': 3,
    'admitted': 3,
    'admittedly': 4,
    'admitting': 3,
    'admonish': 3,
    'admonished': 3,
    'admonishes': 4,
    'admonishing': 4,
    'admonishment': 4,
    'admonition': 4,
    'admonitions': 4,
    'adnan': 2,
    'adney': 2,
    'ado': 2,
    'adobe': 3,
    'adolescence': 4,
    'adolescent': 4,
    'adolescents': 4,
    'adolf': 2,
    'adolfo': 3,
    'adolph': 2,
    'adolpha': 3,
    'adolphson': 3,
    'adolphus': 3,
    'adon': 2,
    'adonia': 4,
    'adonis': 3,
    'adopt': 2,
    'adoptable': 4,
    'adopted': 3,
    'adoptee': 3,
    'adoptees': 3,
    'adopter': 3,
    'adopters': 3,
    'adopting': 3,
    'adoption': 3,
    'adoptions': 3,
    'adoptive': 3,
    'adopts': 2,
    'ador': 2,
    'adora': 3,
    'adorabelle': 4,
    'adorable': 4,
    'adoration': 4,
    'adore': 2,
    'adored': 2,
    'adoree': 3,
    'adorees': 3,
    'adores': 2,
    'adoring': 3,
    'adorn': 2,
    'adorna': 3,
    'adorned': 2,
    'adorning': 3,
    'adornment': 3,
    'adorno': 3,
    'adorns': 2,
    'adra': 2,
    'adragna': 3,
    'adrda': 3,
    'adrea': 3,
    'adrenal': 3,
    'adrenalin': 4,
    'adrenaline': 4,
    'adria': 3,
    'adrian': 3,
    'adriana': 4,
    'adriance': 3,
    'adriano': 4,
    'adriatic': 4,
    'adriel': 2,
    'adrienne': 3,
    'adrift': 2,
    'adroit': 2,
    'adroitly': 3,
    'ads': 1,
    'adsit': 2,
    'adss': 1,
    'adstar': 2,
    'adsteam': 2,
    'adtec': 2,
    'adulate': 3,
    'adulation': 4,
    'aduliadae': 5,
    'adult': 2,
    'adulterate': 4,
    'adulterated': 5,
    'adulterates': 4,
    'adulteration': 5,
    'adulterer': 4,
    'adulterers': 4,
    'adulterous': 4,
    'adultery': 4,
    'adulthood': 3,
    'adults': 2,
    'adusdur': 3,
    'advacare': 3,
    'advalue': 3,
    'advance': 2,
    'advanced': 2,
    'advancement': 3,
    'advancements': 3,
    'advancer': 3,
    'advancers': 3,
    'advances': 3,
    'advancing': 3,
    'advani': 3,
    'advanta': 3,
    'advantage': 3,
    'advantaged': 3,
    'advantageous': 4,
    'advantages': 4,
    'advantest': 3,
    'advection': 3,
    'advent': 2,
    'adventist': 3,
    'adventists': 3,
    'adventure': 3,
    'adventurer': 4,
    'adventurers': 4,
    'adventures': 3,
    'adventuresome': 4,
    'adventurism': 5,
    'adventurous': 4,
    'adverb': 2,
    'adverbial': 4,
    'adverbs': 2,
    'adversarial': 5,
    'adversaries': 4,
    'adversary': 4,
    'adverse': 2,
    'adversely': 3,
    'adversity': 4,
    'advertise': 3,
    'advertised': 3,
    'advertisement': 4,
    'advertisements': 4,
    'advertiser': 4,
    'advertisers': 4,
    'advertises': 4,
    'advertising': 4,
    'advertorial': 5,
    'advertorials': 5,
    'advest': 2,
    'advice': 2,
    'advil': 2,
    'advisability': 6,
    'advisable': 4,
    'advise': 2,
    'advised': 2,
    'advisedly': 4,
    'advisement': 3,
    'adviser': 3,
    'advisers': 3,
    'advises': 3,
    'advising': 3,
    'advisor': 3,
    'advisories': 4,
    'advisors': 3,
    'advisory': 4,
    'advo': 2,
    'advocacy': 4,
    'advocate': 3,
    'advocated': 4,
    'advocates': 3,
    'advocating': 4,
    'advocation': 4,
    'adweek': 2,
    'adwell': 2,
    'ady': 2,
    'adz': 1,
    'ae': 1,
    'aegean': 3,
    'aegerter': 3,
    'aegis': 2,
    'aegon': 2,
    'aeltus': 2,
    'aeneas': 3,
    'aeneid': 3,
    'aequitron': 3,
    'aer': 1,
    'aerial': 3,
    'aerials': 3,
    'aerie': 2,
    'aerien': 3,
    'aeriens': 3,
    'aeritalia': 4,
    'aero': 2,
    'aerobatic': 4,
    'aerobatics': 4,
    'aerobic': 3,
    'aerobically': 4,
    'aerobics': 3,
    'aerodrome': 3,
    'aerodromes': 3,
    'aerodynamic': 5,
    'aerodynamically': 6,
    'aerodynamicist': 6,
    'aerodynamicists': 6,
    'aerodynamics': 5,
    'aerodyne': 3,
    'aeroflot': 3,
    'aerogenosa': 5,
    'aerojet': 3,
    'aerojets': 3,
    'aerolift': 3,
    'aerolifts': 3,
    'aerolineas': 5,
    'aeromar': 3,
    'aeromexico': 5,
    'aeronautic': 4,
    'aeronautical': 5,
    'aeronauticas': 5,
    'aeronautics': 4,
    'aeroperu': 4,
    'aeroquip': 3,
    'aerosmith': 3,
    'aerosol': 3,
    'aerosols': 3,
    'aerospace': 3,
    'aerospatiale': 5,
    'aerostar': 3,
    'aerostars': 3,
    'aerostat': 3,
    'aerostats': 3,
    'aerotech': 3,
    'aerovias': 4,
    'aerts': 1,
    'aeschelus': 3,
    'aeschliman': 3,
    'aesop': 2,
    'aesthenopia': 4,
    'aesthete': 2,
    'aesthetes': 2,
    'aesthetic': 3,
    'aesthetically': 4,
    'aesthetics': 3,
    'aetna': 2,
    'afanasyev': 5,
    'afar': 2,
    'affable': 3,
    'affably': 3,
    'affair': 2,
    'affairs': 2,
    'affect': 2,
    'affectation': 4,
    'affected': 3,
    'affecting': 3,
    'affection': 3,
    'affectionate': 4,
    'affectionately': 5,
    'affections': 3,
    'affective': 3,
    'affectively': 4,
    'affects': 2,
    'affeldt': 2,
    'afferent': 3,
    'affiant': 3,
    'affidavit': 4,
    'affidavits': 4,
    'affiliate': 4,
    'affiliated': 5,
    'affiliates': 4,
    'affiliating': 5,
    'affiliation': 5,
    'affiliations': 5,
    'affine': 2,
    'affined': 2,
    'affines': 2,
    'affining': 3,
    'affinities': 4,
    'affinity': 4,
    'affirm': 2,
    'affirmation': 4,
    'affirmations': 4,
    'affirmative': 4,
    'affirmatively': 5,
    'affirmed': 2,
    'affirming': 3,
    'affirms': 2,
    'affix': 2,
    'affixed': 2,
    'affixes': 3,
    'affixing': 3,
    'affleck': 2,
    'afflerbach': 3,
    'afflict': 2,
    'afflicted': 3,
    'afflicting': 3,
    'affliction': 3,
    'afflictions': 3,
    'afflicts': 2,
    'afflik': 2,
    'affluence': 3,
    'affluent': 3,
    'affolter': 3,
    'afford': 2,
    'affordability': 6,
    'affordable': 4,
    'affordably': 4,
    'afforded': 3,
    'affording': 3,
    'affords': 2,
    'affricate': 3,
    'affricates': 3,
    'affrication': 4,
    'affront': 2,
    'affronted': 3,
    'affronting': 3,
    'affronts': 2,
    'affymax': 3,
    'afghan': 2,
    'afghani': 3,
    'afghanis': 3,
    'afghanistan': 4,
    'afghanistans': 4,
    'afghans': 2,
    'afheldt': 2,
    'aficionado': 5,
    'aficionados': 5,
    'afield': 2,
    'afire': 2,
    'aflame': 2,
    'aflatoxin': 4,
    'afloat': 2,
    'aflutter': 3,
    'afmed': 2,
    'afonso': 3,
    'afoot': 2,
    'aforementioned': 4,
    'aforesaid': 3,
    'aforethought': 3,
    'afoul': 2,
    'afraid': 2,
    'afresh': 2,
    'africa': 3,
    'african': 3,
    'africanist': 4,
    'africanize': 4,
    'africanized': 4,
    'africans': 3,
    'afridi': 3,
    'afrikaans': 3,
    'afrikaner': 4,
    'afrikanerdom': 5,
    'afrikaners': 4,
    'afro': 2,
    'afroamerican': 6,
    'afroamericans': 6,
    'afrocentric': 4,
    'afsane': 3,
    'afshar': 2,
    'aft': 1,
    'after': 2,
    'afterall': 3,
    'afterburner': 4,
    'afterburners': 4,
    'aftereffect': 4,
    'aftereffects': 4,
    'afterglow': 3,
    'afterglows': 3,
    'afterhours': 4,
    'afterimage': 4,
    'afterimages': 5,
    'afterlife': 3,
    'afterlives': 3,
    'aftermarket': 4,
    'aftermarkets': 4,
    'aftermath': 3,
    'aftermaths': 3,
    'afternoon': 3,
    'afternoons': 3,
    'aftershave': 3,
    'aftershaves': 3,
    'aftershock': 3,
    'aftershocks': 3,
    'aftertaste': 3,
    'aftertastes': 3,
    'aftertax': 3,
    'afterthought': 3,
    'afterthoughts': 3,
    'afterward': 3,
    'afterwards': 3,
    'afula': 3,
    'ag': 1,
    'aga': 2,
    'agache': 2,
    'agaches': 3,
    'again': 2,
    'against': 2,
    'agamemnon': 4,
    'agan': 2,
    'agana': 3,
    'aganbegyan': 4,
    'agans': 2,
    'agape': 2,
    'agar': 2,
    'agard': 2,
    'agarwal': 3,
    'agasp': 2,
    'agassi': 3,
    'agassiz': 3,
    'agate': 2,
    'agates': 2,
    'agatha': 3,
    'agawam': 3,
    'agco': 2,
    'age': 1,
    'aged': 1,
    'agee': 2,
    'ageless': 2,
    'agematsu': 4,
    'agence': 2,
    'agencies': 3,
    'agency': 3,
    'agenda': 3,
    'agendas': 3,
    'agent': 2,
    'agents': 2,
    'ager': 2,
    'ageratum': 4,
    'ageratums': 4,
    'agers': 2,
    'agerton': 3,
    'ages': 2,
    'agfa': 2,
    'aggarwal': 3,
    'aggie': 2,
    'aggies': 2,
    'agglomerate': 4,
    'agglomeration': 5,
    'agglutinate': 4,
    'aggrandize': 3,
    'aggrandizement': 4,
    'aggrandizing': 4,
    'aggravate': 3,
    'aggravated': 4,
    'aggravates': 3,
    'aggravating': 4,
    'aggravation': 4,
    'aggregate': 3,
    'aggregated': 4,
    'aggregates': 3,
    'aggress': 2,
    'aggression': 3,
    'aggressions': 3,
    'aggressive': 3,
    'aggressively': 4,
    'aggressiveness': 4,
    'aggressivity': 5,
    'aggressor': 3,
    'aggressors': 3,
    'aggrey': 2,
    'aggrieve': 2,
    'aggrieved': 2,
    'aggy': 2,
    'aghast': 2,
    'aghazadeh': 4,
    'agie': 2,
    'agildo': 3,
    'agile': 2,
    'agilis': 3,
    'agility': 4,
    'agin': 2,
    'agincourt': 3,
    'aging': 2,
    'agins': 2,
    'agip': 2,
    'agitate': 3,
    'agitated': 4,
    'agitating': 4,
    'agitation': 4,
    'agitator': 4,
    'agitators': 4,
    'agitprop': 3,
    'agius': 3,
    'agleam': 2,
    'agler': 2,
    'aglitter': 3,
    'aglo': 2,
    'aglow': 2,
    'agna': 2,
    'agne': 2,
    'agnella': 3,
    'agnelli': 3,
    'agnellis': 3,
    'agnello': 3,
    'agner': 2,
    'agnes': 2,
    'agneta': 3,
    'agnew': 2,
    'agnico': 3,
    'agnor': 2,
    'agnos': 2,
    'agnosio': 4,
    'agnostic': 3,
    'agnostics': 3,
    'ago': 2,
    'agog': 2,
    'agoglia': 4,
    'agonies': 3,
    'agonist': 3,
    'agonists': 3,
    'agonize': 3,
    'agonized': 3,
    'agonizes': 4,
    'agonizing': 4,
    'agonizingly': 5,
    'agony': 3,
    'agora': 3,
    'agoraphobia': 6,
    'agosta': 3,
    'agostinelli': 5,
    'agostini': 4,
    'agostino': 4,
    'agosto': 3,
    'agoura': 3,
    'agouron': 3,
    'agra': 2,
    'agrarian': 4,
    'agrarianism': 6,
    'agrawal': 3,
    'agree': 2,
    'agreeable': 4,
    'agreed': 2,
    'agreeing': 3,
    'agreement': 3,
    'agreements': 3,
    'agrees': 2,
    'agregable': 4,
    'agregious': 4,
    'agresource': 3,
    'agresta': 3,
    'agresti': 3,
    'agrexco': 3,
    'agri': 2,
    'agribank': 3,
    'agribusiness': 4,
    'agrico': 3,
    'agricola': 4,
    'agricole': 3,
    'agricultural': 5,
    'agriculturalist': 6,
    'agriculturally': 6,
    'agriculture': 4,
    'agrifuel': 3,
    'agrifuels': 3,
    'agrigenetics': 5,
    'agrippa': 3,
    'agrivisor': 4,
    'agro': 2,
    'agrochemical': 5,
    'agrochemicals': 5,
    'agroindustrial': 6,
    'agrokomerc': 4,
    'agronomist': 4,
    'agronomists': 4,
    'agrosiand': 4,
    'aground': 2,
    'agrusa': 3,
    'agua': 2,
    'aguacate': 3,
    'aguadilla': 4,
    'aguado': 3,
    'aguanga': 3,
    'aguascalientes': 5,
    'aguayo': 3,
    'agudelo': 4,
    'aguero': 3,
    'aguiar': 3,
    'aguila': 3,
    'aguilar': 3,
    'aguilera': 4,
    'aguillar': 3,
    'aguillard': 3,
    'aguillon': 3,
    'aguilo': 3,
    'aguinaga': 5,
    'aguirra': 3,
    'aguirre': 3,
    'agustin': 3,
    'ah': 1,
    'aha': 2,
    'ahab': 2,
    'ahah': 2,
    'aharon': 3,
    'ahart': 2,
    'ahasuerus': 5,
    'ahaulsie': 3,
    'ahead': 2,
    'ahearn': 2,
    'aherin': 3,
    'ahern': 2,
    'aherne': 2,
    'ahh': 1,
    'ahl': 1,
    'ahlberg': 2,
    'ahlborn': 2,
    'ahlen': 2,
    'ahlers': 2,
    'ahles': 2,
    'ahlf': 1,
    'ahlgren': 2,
    'ahlgrim': 2,
    'ahlin': 2,
    'ahlman': 2,
    'ahlquist': 2,
    'ahlquists': 2,
    'ahlstrand': 2,
    'ahlstrom': 2,
    'ahluwalia': 4,
    'ahmad': 2,
    'ahmadi': 3,
    'ahmann': 2,
    'ahmanson': 3,
    'ahmansons': 3,
    'ahmed': 2,
    'ahmeek': 2,
    'ahmoudi': 3,
    'ahn': 1,
    'ahnell': 2,
    'ahner': 2,
    'aho': 2,
    'ahola': 3,
    'ahold': 2,
    'aholds': 2,
    'ahonen': 3,
    'ahoskie': 3,
    'ahoy': 2,
    'ahr': 1,
    'ahranat': 3,
    'ahren': 2,
    'ahrendt': 2,
    'ahrens': 2,
    'ahs': 1,
    'ahsahka': 3,
    'ahuja': 3,
    'ahumada': 4,
    'ahwahnee': 3,
    'ai': 1,
    'aichele': 2,
    'aicher': 2,
    'aichi': 3,
    'aickin': 2,
    'aid': 1,
    'aida': 3,
    'aidan': 2,
    'aide': 1,
    'aided': 2,
    'aides': 1,
    'aidid': 2,
    'aiding': 2,
    'aids': 1,
    'aiello': 3,
    'aiesha': 2,
    'aigner': 2,
    'aiguebelle': 3,
    'aiken': 2,
    'aikens': 2,
    'aikey': 2,
    'aikin': 2,
    'aikins': 2,
    'aikman': 2,
    'aikoff': 2,
    'ail': 1,
    'ailee': 2,
    'aileen': 2,
    'aileron': 3,
    'ailerons': 3,
    'ailes': 1,
    'ailey': 2,
    'ailing': 2,
    'ailment': 2,
    'ailments': 2,
    'ailor': 2,
    'ails': 1,
    'ailsa': 2,
    'ailurophile': 4,
    'aim': 1,
    'aimal': 2,
    'aiman': 2,
    'aime': 1,
    'aimed': 1,
    'aimee': 2,
    'aimetti': 3,
    'aiming': 2,
    'aimless': 2,
    'aimlessly': 3,
    'aimone': 2,
    'aims': 1,
    'aine': 1,
    'ainge': 1,
    'ainley': 2,
    'ainsberg': 2,
    'ainsley': 2,
    'ainslie': 2,
    'ainsworth': 2,
    'aipac': 2,
    'air': 1,
    'airbag': 2,
    'airbags': 2,
    'airbase': 2,
    'airbases': 3,
    'airboat': 2,
    'airboats': 2,
    'airborne': 2,
    'airbus': 2,
    'aircal': 2,
    'airco': 2,
    'aircoa': 3,
    'aircondition': 4,
    'airconditioned': 4,
    'airconditioner': 5,
    'airconditioning': 5,
    'airconditions': 4,
    'aircraft': 2,
    'aircrafts': 2,
    'aircrew': 2,
    'aird': 1,
    'airdrop': 2,
    'airdrops': 2,
    'aired': 1,
    'airedale': 2,
    'airedales': 2,
    'aires': 2,
    'airey': 2,
    'airfare': 2,
    'airfares': 2,
    'airfield': 2,
    'airfields': 2,
    'airflow': 2,
    'airflows': 2,
    'airfoil': 2,
    'airfoils': 2,
    'airfone': 2,
    'airfones': 2,
    'airforce': 2,
    'airforces': 3,
    'airframe': 2,
    'airframes': 2,
    'airfreight': 2,
    'airgas': 2,
    'airglow': 2,
    'airhart': 2,
    'airhead': 2,
    'airheadz': 2,
    'airing': 2,
    'airington': 3,
    'airless': 2,
    'airlia': 3,
    'airlie': 2,
    'airlift': 2,
    'airlifted': 3,
    'airlifter': 3,
    'airlifters': 3,
    'airlifting': 3,
    'airlifts': 2,
    'airline': 2,
    'airliner': 3,
    'airliners': 3,
    'airlines': 2,
    'airlink': 2,
    'airlinks': 2,
    'airlock': 2,
    'airlocks': 2,
    'airmail': 2,
    'airman': 2,
    'airmen': 2,
    'airmotive': 3,
    'airplane': 2,
    'airplanes': 2,
    'airport': 2,
    'airporter': 3,
    'airporters': 3,
    'airports': 2,
    'airpower': 3,
    'airs': 1,
    'airshare': 2,
    'airship': 2,
    'airships': 2,
    'airspace': 2,
    'airspaces': 3,
    'airspeed': 2,
    'airstrike': 2,
    'airstrikes': 2,
    'airstrip': 2,
    'airstrips': 2,
    'airtight': 2,
    'airtime': 2,
    'airtouch': 2,
    'airtran': 2,
    'airwave': 2,
    'airwaves': 2,
    'airway': 2,
    'airways': 2,
    'airwing': 2,
    'airwings': 2,
    'airworthiness': 4,
    'airworthy': 3,
    'airy': 2,
    'ais': 1,
    'aisa': 3,
    'aisle': 1,
    'aisles': 1,
    'aisling': 2,
    'aislinn': 2,
    'aist': 1,
    'aitchison': 3,
    'aithne': 1,
    'aitken': 2,
    'aiton': 3,
    'aiwa': 2,
    'aix': 1,
    'aja': 2,
    'ajaj': 2,
    'ajami': 3,
    'ajar': 2,
    'ajax': 2,
    'ajay': 2,
    'ajinomoto': 5,
    'ajito': 3,
    'aka': 2,
    'akai': 2,
    'akaka': 3,
    'akali': 3,
    'akamine': 3,
    'akana': 3,
    'akao': 3,
    'akard': 2,
    'akashi': 3,
    'akbar': 2,
    'akbash': 2,
    'akc': 1,
    'ake': 1,
    'akebono': 4,
    'akel': 2,
    'akella': 3,
    'aken': 2,
    'akens': 2,
    'aker': 2,
    'akerley': 3,
    'akerman': 3,
    'akers': 2,
    'akerson': 3,
    'akey': 2,
    'akhtar': 2,
    'aki': 2,
    'akiba': 3,
    'akihito': 4,
    'akiko': 3,
    'akimbo': 3,
    'akin': 2,
    'akins': 2,
    'akio': 3,
    'akira': 3,
    'akita': 3,
    'akiva': 3,
    'akiyama': 4,
    'akkadian': 4,
    'akkerman': 3,
    'akre': 2,
    'akridge': 2,
    'akron': 2,
    'aksamit': 3,
    'aksler': 2,
    'akst': 1,
    'akyanama': 5,
    'akzo': 2,
    'akzos': 2,
    'al': 1,
    'ala': 2,
    'alabama': 4,
    'alabaman': 4,
    'alabamans': 4,
    'alabaster': 4,
    'alachlor': 3,
    'alachua': 4,
    'alacrity': 4,
    'aladdin': 3,
    'alafi': 3,
    'alagappan': 4,
    'alagem': 3,
    'alagna': 3,
    'alagoas': 3,
    'alai': 2,
    'alaimo': 3,
    'alain': 2,
    'alaine': 2,
    'alair': 2,
    'alam': 2,
    'alamance': 3,
    'alamco': 3,
    'alameda': 4,
    'alamein': 3,
    'alamillo': 4,
    'alamito': 4,
    'alamitos': 4,
    'alamo': 3,
    'alamodome': 4,
    'alamos': 3,
    'alamosa': 4,
    'alamoud': 3,
    'alamoudi': 4,
    'alan': 2,
    'alana': 3,
    'alanco': 3,
    'aland': 2,
    'alane': 2,
    'alanis': 3,
    'alaniz': 3,
    'alann': 2,
    'alanna': 3,
    'alar': 2,
    'alarcon': 3,
    'alard': 2,
    'alaric': 3,
    'alarica': 4,
    'alarice': 3,
    'alarid': 3,
    'alarie': 3,
    'alarm': 2,
    'alarmed': 2,
    'alarming': 3,
    'alarmingly': 4,
    'alarmist': 3,
    'alarmists': 3,
    'alarms': 2,
    'alaron': 3,
    'alas': 2,
    'alaska': 3,
    'alaskamen': 4,
    'alaskan': 3,
    'alaskans': 3,
    'alastair': 3,
    'alaster': 3,
    'alatas': 3,
    'alatorre': 4,
    'alayne': 2,
    'alba': 2,
    'albach': 2,
    'albacore': 3,
    'alban': 2,
    'albanese': 4,
    'albani': 3,
    'albania': 4,
    'albanian': 4,
    'albanians': 4,
    'albank': 2,
    'albano': 3,
    'albans': 2,
    'albany': 3,
    'albarado': 4,
    'albarran': 3,
    'albashaun': 3,
    'albatross': 3,
    'albatrosses': 4,
    'albaugh': 2,
    'albea': 3,
    'albee': 2,
    'albeit': 3,
    'albemarle': 3,
    'alben': 2,
    'albendazole': 5,
    'alber': 2,
    'alberding': 3,
    'alberg': 2,
    'albergo': 3,
    'alberico': 4,
    'albern': 2,
    'albers': 2,
    'alberson': 3,
    'albert': 2,
    'alberta': 3,
    'alberthal': 3,
    'alberti': 3,
    'albertina': 4,
    'albertine': 3,
    'albertini': 4,
    'alberto': 3,
    'alberts': 2,
    'albertsen': 3,
    'albertson': 3,
    'albertus': 3,
    'albertville': 3,
    'alberty': 3,
    'albie': 2,
    'albin': 2,
    'albina': 3,
    'albini': 3,
    'albinia': 4,
    'albino': 3,
    'albion': 3,
    'albiric': 3,
    'albo': 2,
    'albrecht': 2,
    'albright': 2,
    'albrightsville': 3,
    'albritton': 3,
    'albro': 2,
    'albukhari': 4,
    'album': 2,
    'albumin': 3,
    'albums': 2,
    'albunex': 3,
    'albuquerque': 4,
    'albury': 3,
    'albus': 2,
    'albuterol': 4,
    'alcala': 3,
    'alcan': 2,
    'alcantar': 3,
    'alcantara': 4,
    'alcaraz': 3,
    'alcasa': 3,
    'alcatel': 3,
    'alcatraz': 3,
    'alcazar': 3,
    'alcee': 2,
    'alcester': 3,
    'alchemically': 4,
    'alchemist': 3,
    'alchemy': 3,
    'alcide': 2,
    'alcids': 2,
    'alcina': 3,
    'alco': 2,
    'alcoa': 3,
    'alcocer': 3,
    'alcock': 2,
    'alcohol': 3,
    'alcoholic': 4,
    'alcoholics': 4,
    'alcoholism': 5,
    'alcon': 2,
    'alcorn': 2,
    'alcorta': 3,
    'alcott': 2,
    'alcove': 2,
    'alcoves': 2,
    'alda': 2,
    'aldaco': 3,
    'aldama': 3,
    'aldana': 3,
    'aldape': 3,
    'alday': 2,
    'aldebaran': 4,
    'alden': 2,
    'aldenville': 3,
    'alder': 2,
    'aldercy': 3,
    'alderete': 3,
    'alderfer': 3,
    'alderidge': 3,
    'alderman': 3,
    'aldermen': 3,
    'alderson': 3,
    'alderton': 3,
    'aldi': 2,
    'aldicarb': 3,
    'aldila': 3,
    'aldin': 2,
    'aldinger': 3,
    'aldis': 2,
    'aldo': 2,
    'aldora': 3,
    'aldous': 2,
    'aldred': 2,
    'aldredge': 2,
    'aldrete': 2,
    'aldric': 2,
    'aldrich': 2,
    'aldridge': 2,
    'aldrin': 2,
    'aldus': 2,
    'aldwin': 2,
    'aldwyn': 2,
    'aldys': 2,
    'ale': 1,
    'aleatory': 5,
    'alec': 2,
    'aleda': 3,
    'aledo': 3,
    'aleen': 2,
    'alegre': 3,
    'alegrett': 3,
    'alegria': 4,
    'alehouse': 2,
    'aleichem': 3,
    'alejandre': 4,
    'alejandro': 4,
    'alejo': 3,
    'alejos': 3,
    'aleksander': 4,
    'aleksandr': 4,
    'alem': 2,
    'aleman': 2,
    'alena': 3,
    'alendrin': 3,
    'alene': 2,
    'alenia': 4,
    'aleo': 3,
    'aleph': 2,
    'aleria': 4,
    'aleron': 3,
    'alert': 2,
    'alerted': 3,
    'alerting': 3,
    'alertly': 3,
    'alertness': 3,
    'alerts': 2,
    'ales': 1,
    'aleshire': 4,
    'alesi': 3,
    'alessandra': 4,
    'alessandrini': 5,
    'alessandro': 4,
    'alessi': 3,
    'alessio': 4,
    'aleta': 3,
    'aletha': 3,
    'alethea': 4,
    'aletti': 3,
    'aleutian': 3,
    'aleutians': 3,
    'aleve': 2,
    'alewife': 2,
    'alewine': 2,
    'alewives': 2,
    'alex': 2,
    'alexa': 3,
    'alexander': 4,
    'alexanders': 4,
    'alexandra': 4,
    'alexandre': 4,
    'alexandria': 5,
    'alexandrine': 4,
    'alexandrines': 4,
    'alexandrov': 4,
    'alexei': 3,
    'alexi': 3,
    'alexia': 4,
    'alexine': 3,
    'alexis': 3,
    'alexopoulos': 5,
    'alexs': 3,
    'alexy': 3,
    'aley': 2,
    'alf': 1,
    'alfa': 2,
    'alfalfa': 3,
    'alfano': 3,
    'alfareda': 4,
    'alfaro': 3,
    'alfavilli': 4,
    'alfie': 2,
    'alfieri': 3,
    'alfiero': 4,
    'alfin': 2,
    'alfono': 3,
    'alfons': 2,
    'alfonse': 2,
    'alfonsin': 3,
    'alfonsine': 4,
    'alfonso': 3,
    'alfonzo': 3,
    'alford': 2,
    'alfre': 2,
    'alfred': 2,
    'alfredo': 3,
    'alfredson': 3,
    'alfredsson': 3,
    'alfrey': 2,
    'algae': 2,
    'algal': 2,
    'algamaa': 3,
    'algar': 2,
    'algarin': 3,
    'algata': 3,
    'algebra': 3,
    'algebraic': 4,
    'algebraically': 5,
    'algemene': 3,
    'algeo': 3,
    'alger': 2,
    'algeria': 4,
    'algerian': 4,
    'algerians': 4,
    'algernon': 3,
    'algie': 2,
    'algiers': 2,
    'algodones': 4,
    'algol': 2,
    'algom': 2,
    'algoma': 3,
    'algonquian': 4,
    'algonquin': 3,
    'algorithm': 4,
    'algorithms': 4,
    'alguire': 3,
    'algy': 2,
    'alhadeff': 3,
    'alhambra': 3,
    'alhausie': 3,
    'ali': 2,
    'aliano': 4,
    'alianza': 4,
    'alias': 3,
    'aliases': 4,
    'aliberti': 4,
    'alibi': 3,
    'alibis': 3,
    'alibrandi': 4,
    'alice': 2,
    'alicea': 4,
    'alices': 3,
    'alicia': 3,
    'alida': 3,
    'alie': 2,
    'alien': 3,
    'alienate': 3,
    'alienated': 5,
    'alienates': 4,
    'alienating': 5,
    'alienation': 5,
    'aliens': 3,
    'aliff': 2,
    'alig': 2,
    'alight': 2,
    'align': 2,
    'aligned': 2,
    'aligning': 3,
    'alignment': 3,
    'alignments': 3,
    'aligns': 2,
    'alihan': 3,
    'alija': 3,
    'alike': 2,
    'alikes': 2,
    'alima': 3,
    'alimenies': 4,
    'alimentary': 5,
    'alimony': 4,
    'alina': 3,
    'aline': 2,
    'alines': 2,
    'alioto': 4,
    'aliquippa': 4,
    'aliquippas': 4,
    'alire': 3,
    'alirio': 4,
    'alisa': 3,
    'alisha': 3,
    'alisky': 3,
    'alislamiyya': 5,
    'alison': 3,
    'alissa': 3,
    'alistair': 3,
    'alister': 3,
    'alita': 3,
    'alitalia': 5,
    'alithia': 4,
    'alito': 3,
    'alive': 2,
    'alix': 2,
    'aliyah': 3,
    'alizac': 3,
    'alka': 2,
    'alkahest': 3,
    'alkali': 3,
    'alkalies': 3,
    'alkaline': 3,
    'alkalinity': 5,
    'alkaloid': 3,
    'alkaloidal': 4,
    'alkaloids': 3,
    'alkanes': 2,
    'alkema': 3,
    'alkene': 2,
    'alkenes': 2,
    'alkermes': 3,
    'alkire': 2,
    'all': 1,
    'alla': 2,
    'allah': 2,
    'allain': 2,
    'allaire': 2,
    'allais': 2,
    'allamuchy': 4,
    'allan': 2,
    'allante': 3,
    'allar': 2,
    'allard': 2,
    'allardt': 2,
    'allay': 2,
    'allayed': 2,
    'allaying': 3,
    'allays': 2,
    'allbaugh': 2,
    'allbee': 2,
    'allbright': 2,
    'allbritten': 3,
    'allbritton': 3,
    'allcock': 2,
    'allcorn': 2,
    'allday': 2,
    'allderdice': 3,
    'alldredge': 2,
    'allebach': 3,
    'alleco': 3,
    'allee': 2,
    'allegation': 4,
    'allegations': 4,
    'allege': 2,
    'alleged': 2,
    'allegedly': 4,
    'alleges': 3,
    'alleghany': 4,
    'alleghenies': 4,
    'allegheny': 4,
    'allegiance': 3,
    'allegiances': 5,
    'alleging': 3,
    'allegis': 3,
    'allegorical': 5,
    'allegories': 4,
    'allegory': 4,
    'allegra': 3,
    'allegretti': 4,
    'allegro': 3,
    'allele': 3,
    'alleles': 3,
    'allelic': 3,
    'alleman': 2,
    'allemand': 3,
    'allen': 2,
    'allenbaugh': 3,
    'allendale': 3,
    'allende': 3,
    'allender': 4,
    'allendorf': 3,
    'allene': 2,
    'allenhurst': 3,
    'allens': 2,
    'allensbach': 3,
    'allensworth': 3,
    'allenton': 3,
    'allentown': 3,
    'allenwood': 3,
    'aller': 2,
    'allergan': 3,
    'allergen': 3,
    'allergens': 3,
    'allergic': 3,
    'allergies': 3,
    'allergist': 3,
    'allergists': 3,
    'allergy': 3,
    'allers': 2,
    'allert': 2,
    'allerton': 3,
    'alles': 1,
    'alleva': 3,
    'alleviate': 4,
    'alleviated': 5,
    'alleviates': 4,
    'alleviating': 5,
    'alleviation': 5,
    'alley': 2,
    'alleyne': 2,
    'alleyoop': 3,
    'alleys': 2,
    'alleyway': 3,
    'alleyways': 3,
    'allgaier': 3,
    'allgeier': 3,
    'allgemeine': 3,
    'allgemeines': 3,
    'allgeyer': 3,
    'allgood': 2,
    'alli': 2,
    'alliance': 3,
    'alliances': 4,
    'alliant': 3,
    'allianz': 3,
    'allick': 2,
    'allie': 2,
    'allied': 2,
    'alliedsignal': 4,
    'allies': 2,
    'alligator': 4,
    'alligators': 4,
    'alligood': 3,
    'allin': 2,
    'allinder': 3,
    'alling': 2,
    'allinger': 3,
    'allingham': 3,
    'allington': 3,
    'allinson': 3,
    'allis': 2,
    'allison': 3,
    'allister': 3,
    'alliston': 3,
    'alliteration': 5,
    'alliterative': 5,
    'allman': 2,
    'allmendinger': 4,
    'allmon': 2,
    'allmond': 2,
    'allnet': 2,
    'allnutt': 2,
    'allocate': 3,
    'allocated': 4,
    'allocates': 3,
    'allocating': 4,
    'allocation': 4,
    'allocations': 4,
    'allocator': 4,
    'allocators': 4,
    'allocca': 3,
    'allocco': 3,
    'allograph': 3,
    'allographs': 3,
    'allomorph': 3,
    'allomorphs': 3,
    'allophone': 3,
    'allophones': 3,
    'allophonic': 4,
    'allor': 2,
    'allot': 2,
    'alloted': 3,
    'allotment': 3,
    'allotments': 3,
    'allotrope': 3,
    'allotropes': 3,
    'allots': 2,
    'allotted': 3,
    'allotting': 3,
    'allow': 2,
    'allowable': 4,
    'allowance': 3,
    'allowances': 4,
    'alloway': 3,
    'allowed': 2,
    'allowing': 3,
    'allows': 2,
    'alloy': 2,
    'alloys': 2,
    'allphin': 2,
    'allport': 2,
    'allred': 2,
    'allright': 2,
    'alls': 1,
    'allsbrook': 2,
    'allset': 2,
    'allshouse': 2,
    'allsop': 2,
    'allsopp': 2,
    'allspice': 2,
    'allstate': 2,
    'allston': 2,
    'allsup': 2,
    'alltel': 2,
    'alltime': 2,
    'allton': 2,
    'alltop': 2,
    'allude': 2,
    'alluded': 3,
    'alludes': 2,
    'alluding': 3,
    'allum': 2,
    'allums': 2,
    'allure': 2,
    'allured': 2,
    'alluring': 3,
    'allusion': 3,
    'allusions': 3,
    'allusive': 3,
    'alluvial': 4,
    'alluvium': 4,
    'allwaste': 2,
    'allweiss': 2,
    'ally': 2,
    'allying': 3,
    'allyn': 2,
    'allys': 2,
    'allyson': 3,
    'alm': 1,
    'alma': 2,
    'almada': 3,
    'almadani': 4,
    'almaden': 3,
    'almaguer': 3,
    'almal': 2,
    'alman': 2,
    'almanac': 3,
    'almand': 2,
    'almanza': 3,
    'almanzar': 3,
    'almaraz': 3,
    'almas': 2,
    'almasy': 3,
    'almay': 2,
    'almazan': 3,
    'almeda': 3,
    'almeida': 3,
    'almelund': 3,
    'almendarez': 4,
    'almer': 2,
    'almeria': 4,
    'almgren': 2,
    'almighty': 3,
    'almira': 3,
    'almo': 2,
    'almodovar': 4,
    'almon': 2,
    'almond': 2,
    'almonds': 2,
    'almoner': 3,
    'almonte': 3,
    'almos': 2,
    'almost': 2,
    'almquist': 2,
    'alms': 1,
    'almy': 2,
    'almys': 2,
    'alodie': 3,
    'aloe': 2,
    'aloft': 2,
    'aloha': 3,
    'aloi': 2,
    'aloia': 3,
    'alois': 2,
    'aloisa': 3,
    'aloisi': 3,
    'aloisia': 4,
    'aloisio': 4,
    'aloka': 3,
    'alomar': 3,
    'alon': 2,
    'alone': 2,
    'along': 2,
    'alonge': 2,
    'alongi': 3,
    'alongs': 2,
    'alongside': 3,
    'aloni': 3,
    'alonso': 3,
    'alonza': 3,
    'alonzo': 3,
    'aloof': 2,
    'aloofness': 3,
    'alot': 2,
    'aloud': 2,
    'aloys': 2,
    'aloyse': 2,
    'aloysia': 4,
    'aloysius': 4,
    'alpa': 2,
    'alpaca': 3,
    'alpaugh': 2,
    'alper': 2,
    'alperin': 3,
    'alpern': 2,
    'alpers': 2,
    'alpert': 2,
    'alpex': 2,
    'alpha': 2,
    'alphabet': 3,
    'alphabetic': 4,
    'alphabetical': 5,
    'alphabetically': 5,
    'alphabetization': 6,
    'alphabetize': 4,
    'alphabetized': 4,
    'alphabetizes': 5,
    'alphabetizing': 5,
    'alphagraphics': 4,
    'alphametric': 4,
    'alphametrics': 4,
    'alphandery': 4,
    'alphanumeric': 5,
    'alphaphotographic': 6,
    'alpharel': 3,
    'alpharetta': 4,
    'alphin': 2,
    'alphonse': 2,
    'alphonsine': 3,
    'alphonso': 3,
    'alpin': 2,
    'alpine': 2,
    'alpirez': 3,
    'alpo': 2,
    'alps': 1,
    'alquist': 2,
    'alread': 2,
    'already': 3,
    'alred': 2,
    'alright': 2,
    'alrosa': 3,
    'alroy': 2,
    'als': 1,
    'alsace': 2,
    'alsatian': 3,
    'alsbrook': 2,
    'alsbrooks': 2,
    'alsbury': 3,
    'alsdorf': 2,
    'alsgaard': 2,
    'alshelhah': 3,
    'alsip': 2,
    'also': 2,
    'alsobrook': 3,
    'alsobrooks': 3,
    'alsop': 2,
    'alspach': 2,
    'alspaugh': 2,
    'alsthom': 2,
    'alston': 2,
    'alsup': 2,
    'alt': 1,
    'alta': 2,
    'altadena': 4,
    'altai': 2,
    'altaic': 3,
    'altair': 2,
    'altamira': 4,
    'altamirano': 5,
    'altamont': 3,
    'altamuro': 4,
    'altar': 2,
    'altarpiece': 3,
    'altars': 2,
    'altavista': 4,
    'altay': 2,
    'altemose': 3,
    'altemus': 3,
    'altenburg': 3,
    'altenhofen': 4,
    'alteon': 3,
    'alter': 2,
    'altera': 3,
    'alteration': 4,
    'alterations': 4,
    'altercation': 4,
    'altercations': 4,
    'altered': 2,
    'altergott': 3,
    'altering': 3,
    'alterman': 3,
    'alternacare': 4,
    'alternate': 3,
    'alternated': 4,
    'alternately': 4,
    'alternates': 3,
    'alternating': 4,
    'alternation': 4,
    'alternative': 4,
    'alternatively': 5,
    'alternatives': 4,
    'alternator': 4,
    'alters': 2,
    'altfest': 2,
    'althaus': 2,
    'althaver': 3,
    'althea': 3,
    'altherr': 2,
    'althoff': 2,
    'althorp': 2,
    'although': 2,
    'althouse': 2,
    'altice': 2,
    'altier': 3,
    'altieri': 3,
    'altima': 3,
    'altimeter': 4,
    'altimetry': 4,
    'altimorano': 5,
    'altiplano': 4,
    'altitude': 3,
    'altitudes': 3,
    'altizer': 3,
    'altland': 2,
    'altman': 2,
    'altmann': 2,
    'altmeyer': 3,
    'alto': 2,
    'altobelli': 4,
    'altogether': 4,
    'altoid': 2,
    'altom': 2,
    'altomare': 4,
    'alton': 2,
    'altoona': 3,
    'altos': 2,
    'altra': 2,
    'altron': 2,
    'altruism': 4,
    'altruistic': 4,
    'altschiller': 3,
    'altschul': 2,
    'altschuler': 3,
    'altshuler': 3,
    'altucher': 3,
    'alturas': 3,
    'altus': 2,
    'altvater': 3,
    'altzheimer': 3,
    'alu': 2,
    'alum': 2,
    'alumax': 3,
    'alumbaugh': 3,
    'alumina': 4,
    'aluminio': 5,
    'aluminium': 4,
    'aluminize': 4,
    'aluminized': 4,
    'aluminosilicate': 7,
    'aluminum': 4,
    'alumna': 3,
    'alumni': 3,
    'alumnus': 3,
    'alums': 2,
    'alun': 2,
    'alura': 3,
    'alusuisse': 3,
    'alva': 2,
    'alvah': 2,
    'alvan': 2,
    'alvarado': 4,
    'alvardo': 3,
    'alvare': 2,
    'alvarenga': 4,
    'alvares': 3,
    'alvarez': 3,
    'alvaro': 3,
    'alvear': 2,
    'alveolar': 4,
    'alveoli': 4,
    'alverez': 3,
    'alvero': 3,
    'alverson': 3,
    'alverton': 3,
    'alves': 2,
    'alvey': 2,
    'alvidrez': 3,
    'alvin': 2,
    'alvina': 3,
    'alvino': 3,
    'alvis': 2,
    'alvita': 3,
    'alvite': 2,
    'alvord': 2,
    'alwaleed': 3,
    'alward': 2,
    'always': 2,
    'alwin': 2,
    'alwine': 2,
    'alwood': 2,
    'alwyn': 2,
    'aly': 2,
    'alyce': 2,
    'alyea': 3,
    'alyeska': 4,
    'alys': 2,
    'alysheba': 4,
    'alysia': 4,
    'alyssa': 3,
    'alza': 2,
    'alzado': 3,
    'alzena': 3,
    'alzene': 2,
    'alzheimer': 3,
    'alzona': 3,
    'am': 1,
    'amabel': 3,
    'amabelle': 3,
    'amabile': 3,
    'amacher': 3,
    'amacker': 3,
    'amadea': 4,
    'amadeo': 4,
    'amadeus': 4,
    'amado': 3,
    'amadon': 3,
    'amador': 3,
    'amadou': 3,
    'amagansett': 4,
    'amaker': 3,
    'amakudari': 5,
    'amal': 2,
    'amalea': 4,
    'amalfitano': 5,
    'amalgam': 3,
    'amalgamate': 4,
    'amalgamated': 5,
    'amalgamates': 4,
    'amalgamating': 5,
    'amalgamation': 5,
    'amalgams': 3,
    'amalia': 3,
    'amalie': 3,
    'amalya': 3,
    'aman': 2,
    'amana': 3,
    'amanda': 3,
    'amandas': 3,
    'amandime': 3,
    'amann': 2,
    'amano': 3,
    'amanpour': 3,
    'amante': 3,
    'amar': 2,
    'amara': 3,
    'amaral': 3,
    'amarante': 4,
    'amaranth': 3,
    'amaretto': 4,
    'amargosa': 4,
    'amari': 3,
    'amarilla': 4,
    'amarillo': 4,
    'amarillos': 4,
    'amarin': 3,
    'amaris': 3,
    'amaro': 3,
    'amaryllis': 4,
    'amaryllises': 5,
    'amasa': 3,
    'amason': 3,
    'amass': 2,
    'amassed': 2,
    'amasses': 3,
    'amassing': 3,
    'amateur': 3,
    'amateurish': 4,
    'amateurism': 5,
    'amateurs': 3,
    'amati': 3,
    'amatil': 3,
    'amato': 3,
    'amauligak': 4,
    'amax': 2,
    'amaya': 3,
    'amaze': 2,
    'amazed': 2,
    'amazement': 3,
    'amazes': 3,
    'amazing': 3,
    'amazingly': 4,
    'amazon': 3,
    'amazonia': 5,
    'amazonian': 5,
    'amazonians': 5,
    'amazons': 3,
    'ambac': 2,
    'ambase': 2,
    'ambassador': 4,
    'ambassadorial': 6,
    'ambassadors': 4,
    'ambassadorship': 5,
    'ambassadorships': 5,
    'ambassadress': 4,
    'amber': 2,
    'amberg': 2,
    'amberger': 3,
    'ambergris': 3,
    'ambers': 2,
    'amberson': 3,
    'ambery': 3,
    'ambiance': 3,
    'ambidextrous': 4,
    'ambience': 3,
    'ambient': 3,
    'ambiguities': 5,
    'ambiguity': 5,
    'ambiguous': 4,
    'ambisone': 3,
    'ambition': 3,
    'ambitions': 3,
    'ambitious': 3,
    'ambitiously': 4,
    'ambivalence': 4,
    'ambivalent': 4,
    'amble': 2,
    'ambled': 2,
    'ambler': 2,
    'ambles': 2,
    'amblin': 2,
    'ambling': 3,
    'amborn': 2,
    'amboy': 2,
    'ambra': 2,
    'ambriano': 4,
    'ambrit': 2,
    'ambriz': 2,
    'ambrogio': 4,
    'ambrose': 2,
    'ambrosia': 3,
    'ambrosial': 3,
    'ambrosian': 3,
    'ambrosiano': 5,
    'ambrosine': 4,
    'ambrosini': 4,
    'ambrosino': 4,
    'ambrosio': 4,
    'ambrosius': 4,
    'ambs': 1,
    'ambuehl': 2,
    'ambulance': 3,
    'ambulances': 4,
    'ambulatory': 5,
    'amburgey': 3,
    'amburn': 2,
    'ambush': 2,
    'ambushed': 2,
    'ambushes': 3,
    'ambushing': 3,
    'amc': 3,
    'amca': 2,
    'amcast': 2,
    'amcole': 2,
    'amcor': 2,
    'amcore': 2,
    'amdahl': 2,
    'amdec': 2,
    'amdek': 2,
    'amdur': 2,
    'amdura': 3,
    'ame': 1,
    'ameche': 3,
    'amedco': 3,
    'amedee': 3,
    'ameen': 2,
    'amelia': 3,
    'amelinda': 4,
    'ameline': 4,
    'amelio': 4,
    'ameliorate': 4,
    'ameliorated': 6,
    'amelioration': 5,
    'amelita': 4,
    'amell': 2,
    'amen': 2,
    'amenable': 4,
    'amenaces': 4,
    'amend': 2,
    'amendable': 4,
    'amended': 3,
    'amending': 3,
    'amendment': 3,
    'amendments': 3,
    'amendola': 4,
    'amends': 2,
    'amenities': 4,
    'amenity': 4,
    'ament': 2,
    'amenta': 3,
    'amer': 2,
    'amerada': 4,
    'amerco': 3,
    'amerford': 3,
    'ameri': 3,
    'ameribanc': 4,
    'america': 4,
    'american': 4,
    'americana': 5,
    'americanas': 5,
    'americanism': 6,
    'americanization': 7,
    'americanize': 5,
    'americanized': 5,
    'americano': 5,
    'americanos': 5,
    'americans': 4,
    'americar': 4,
    'americare': 4,
    'americares': 4,
    'americas': 4,
    'americo': 4,
    'americold': 4,
    'americorp': 4,
    'americorps': 4,
    'americus': 4,
    'ameridata': 5,
    'amerifirst': 4,
    'amerigas': 4,
    'amerigo': 4,
    'amerihost': 4,
    'amerika': 4,
    'amerindian': 5,
    'amerine': 4,
    'ameritech': 4,
    'ameritrust': 4,
    'amerman': 3,
    'amero': 3,
    'ameron': 3,
    'amerongen': 4,
    'amersham': 3,
    'amerson': 3,
    'amery': 3,
    'ames': 1,
    'amesville': 2,
    'ametek': 3,
    'amethyst': 3,
    'amev': 2,
    'amex': 2,
    'amexco': 3,
    'amey': 2,
    'amezcua': 4,
    'amezquita': 4,
    'amfac': 2,
    'amfesco': 3,
    'amgen': 2,
    'amherst': 2,
    'amherstdale': 3,
    'amhoist': 2,
    'ami': 2,
    'amiability': 6,
    'amiable': 4,
    'amiably': 4,
    'amicable': 4,
    'amicably': 4,
    'amick': 2,
    'amico': 3,
    'amicone': 3,
    'amicus': 3,
    'amid': 2,
    'amidi': 3,
    'amidon': 3,
    'amidships': 3,
    'amidst': 2,
    'amie': 2,
    'amiga': 3,
    'amigo': 3,
    'amigos': 3,
    'amilia': 4,
    'amin': 2,
    'amino': 3,
    'aminta': 3,
    'amiot': 3,
    'amiprilose': 4,
    'amir': 2,
    'amiram': 3,
    'amiran': 3,
    'amirault': 3,
    'amirav': 3,
    'amiri': 3,
    'amis': 2,
    'amish': 2,
    'amison': 3,
    'amiss': 2,
    'amistad': 3,
    'amit': 2,
    'amitai': 3,
    'amity': 3,
    'amityville': 4,
    'amman': 2,
    'ammann': 2,
    'ammeen': 2,
    'ammerman': 3,
    'ammeter': 3,
    'ammeters': 3,
    'ammirati': 4,
    'ammo': 2,
    'ammon': 2,
    'ammonia': 3,
    'ammonite': 3,
    'ammonites': 3,
    'ammonium': 4,
    'ammons': 2,
    'ammunition': 4,
    'ammunitions': 4,
    'amnesia': 3,
    'amnesiac': 4,
    'amnesties': 3,
    'amnesty': 3,
    'amnio': 3,
    'amniocentesis': 6,
    'amniotic': 4,
    'amo': 2,
    'amoco': 3,
    'amoeba': 3,
    'amoebas': 3,
    'amoebic': 3,
    'amok': 2,
    'amon': 2,
    'among': 2,
    'amongst': 2,
    'amoolya': 3,
    'amor': 2,
    'amoral': 3,
    'amore': 2,
    'amorette': 3,
    'amorist': 3,
    'amorita': 4,
    'amorosi': 4,
    'amoroso': 4,
    'amorous': 3,
    'amorphous': 3,
    'amortization': 5,
    'amortize': 3,
    'amortized': 3,
    'amortizing': 4,
    'amoruso': 4,
    'amory': 3,
    'amos': 2,
    'amoskeag': 3,
    'amoss': 2,
    'amount': 2,
    'amounted': 3,
    'amounting': 3,
    'amounts': 2,
    'amoxicillin': 5,
    'amp': 1,
    'ampad': 2,
    'ampal': 2,
    'amparan': 3,
    'ampato': 3,
    'ampco': 2,
    'amperage': 3,
    'ampersand': 3,
    'ampex': 2,
    'amphenol': 3,
    'amphetamine': 4,
    'amphetamines': 4,
    'amphibian': 4,
    'amphibians': 4,
    'amphibious': 4,
    'amphibole': 3,
    'amphitheater': 5,
    'amphitheaters': 5,
    'amphitheatre': 5,
    'amphora': 3,
    'amphorae': 3,
    'ample': 2,
    'amplicons': 3,
    'amplification': 5,
    'amplifications': 5,
    'amplified': 3,
    'amplifier': 4,
    'amplifiers': 4,
    'amplifies': 3,
    'amplify': 3,
    'amplifying': 4,
    'ampligen': 3,
    'amplitude': 3,
    'amplocore': 3,
    'amply': 2,
    'ampol': 2,
    'amputate': 3,
    'amputated': 4,
    'amputation': 4,
    'amputations': 4,
    'amputee': 3,
    'amputees': 3,
    'amr': 2,
    'amraam': 2,
    'amre': 2,
    'amrein': 2,
    'amrep': 2,
    'amrhein': 3,
    'amrine': 2,
    'amritsar': 3,
    'amro': 2,
    'ams': 1,
    'amsbaugh': 2,
    'amsco': 2,
    'amsden': 2,
    'amsler': 2,
    'amsouth': 2,
    'amspacher': 3,
    'amstar': 2,
    'amster': 2,
    'amsterdam': 3,
    'amstrad': 2,
    'amstutz': 2,
    'amtech': 2,
    'amtrack': 2,
    'amtrak': 2,
    'amuck': 2,
    'amulet': 3,
    'amulets': 3,
    'amundsen': 3,
    'amundson': 3,
    'amuse': 2,
    'amused': 2,
    'amusement': 3,
    'amusements': 3,
    'amuses': 3,
    'amusing': 3,
    'amusingly': 4,
    'amvest': 2,
    'amvestor': 3,
    'amvestors': 3,
    'amway': 2,
    'amy': 2,
    'amygdala': 4,
    'amylin': 3,
    'amyloid': 3,
    'amyotrophic': 5,
    'amyx': 2,
    'an': 1,
    'ana': 2,
    'anabaptist': 4,
    'anable': 3,
    'anabolic': 4,
    'anac': 2,
    'anachronism': 5,
    'anachronisms': 5,
    'anachronistic': 5,
    'anacin': 3,
    'anacker': 3,
    'anacomp': 3,
    'anaconda': 4,
    'anacortes': 4,
    'anacostia': 5,
    'anadarko': 4,
    'anaerobe': 3,
    'anaerobes': 3,
    'anaerobic': 4,
    'anaesthesia': 4,
    'anafranil': 4,
    'anagnos': 3,
    'anagram': 3,
    'anaheim': 3,
    'anal': 2,
    'analgesic': 4,
    'analgesics': 4,
    'analog': 3,
    'analogic': 4,
    'analogies': 4,
    'analogous': 4,
    'analogue': 3,
    'analogy': 4,
    'analyses': 4,
    'analysis': 4,
    'analyst': 3,
    'analysts': 3,
    'analytic': 4,
    'analytical': 5,
    'analytically': 5,
    'analyticity': 6,
    'analytics': 4,
    'analyzable': 5,
    'analyze': 3,
    'analyzed': 3,
    'analyzer': 4,
    'analyzers': 4,
    'analyzes': 4,
    'analyzing': 4,
    'anamaria': 5,
    'anand': 2,
    'anandale': 3,
    'anandeep': 3,
    'anania': 4,
    'anantha': 3,
    'anaphora': 4,
    'anaphylaxis': 5,
    'anarchic': 3,
    'anarchical': 4,
    'anarchist': 3,
    'anarchists': 3,
    'anarchy': 3,
    'anaren': 3,
    'anas': 2,
    'anasazi': 4,
    'anasquan': 3,
    'anast': 2,
    'anastas': 3,
    'anastasi': 4,
    'anastasia': 4,
    'anastasio': 5,
    'anastos': 3,
    'anathema': 4,
    'anatola': 4,
    'anatole': 3,
    'anatoli': 4,
    'anatolia': 5,
    'anatolian': 5,
    'anatoly': 4,
    'anatomical': 5,
    'anatomically': 5,
    'anatomist': 4,
    'anatomy': 4,
    'anawalt': 3,
    'anaya': 3,
    'anbari': 3,
    'ancel': 2,
    'ancell': 2,
    'ancestor': 3,
    'ancestors': 3,
    'ancestral': 3,
    'ancestry': 3,
    'ancheta': 3,
    'ancho': 2,
    'anchondo': 3,
    'anchor': 2,
    'anchorage': 3,
    'anchored': 2,
    'anchoring': 3,
    'anchorman': 3,
    'anchormen': 3,
    'anchors': 2,
    'anchovies': 3,
    'anchovy': 3,
    'ancient': 2,
    'ancients': 2,
    'ancillary': 4,
    'ancira': 3,
    'ancona': 3,
    'ancrum': 2,
    'anctil': 2,
    'and': 1,
    'andal': 2,
    'andalusia': 4,
    'andalusian': 4,
    'andante': 3,
    'andantino': 4,
    'andean': 3,
    'andel': 2,
    'ander': 2,
    'andera': 3,
    'anderberg': 3,
    'anderegg': 3,
    'anderle': 3,
    'anderlini': 4,
    'anderman': 3,
    'anders': 2,
    'andersen': 3,
    'anderson': 3,
    'andersons': 3,
    'andersonville': 4,
    'andersson': 3,
    'andert': 2,
    'anderton': 3,
    'andes': 2,
    'andesite': 3,
    'andie': 2,
    'anding': 2,
    'andino': 3,
    'andiron': 3,
    'andis': 2,
    'andler': 2,
    'ando': 2,
    'andolina': 4,
    'andonian': 4,
    'andoras': 3,
    'andorra': 3,
    'andover': 3,
    'andrada': 3,
    'andrade': 2,
    'andrae': 2,
    'andraki': 3,
    'andras': 2,
    'andre': 2,
    'andrea': 3,
    'andreae': 3,
    'andreana': 3,
    'andreani': 3,
    'andreano': 3,
    'andreas': 3,
    'andreasen': 3,
    'andreason': 3,
    'andreassen': 3,
    'andree': 2,
    'andreen': 2,
    'andrei': 2,
    'andreini': 4,
    'andrej': 2,
    'andren': 3,
    'andreoli': 4,
    'andreoni': 4,
    'andreotti': 4,
    'andreozzi': 4,
    'andrepont': 3,
    'andres': 2,
    'andresen': 3,
    'andreski': 3,
    'andress': 2,
    'andretti': 3,
    'andreu': 2,
    'andrew': 2,
    'andrews': 2,
    'andrey': 2,
    'andrezak': 3,
    'andria': 3,
    'andriana': 4,
    'andriano': 4,
    'andric': 2,
    'andrich': 2,
    'andrick': 2,
    'andries': 2,
    'andriessen': 3,
    'andringa': 3,
    'andriola': 4,
    'andrist': 2,
    'androgynous': 4,
    'androgyny': 4,
    'android': 2,
    'androids': 2,
    'andromeda': 4,
    'andropov': 3,
    'andros': 2,
    'andrus': 2,
    'andruskevich': 4,
    'andry': 2,
    'andrzej': 2,
    'andrzejewski': 4,
    'ands': 1,
    'andujar': 3,
    'andy': 2,
    'ane': 1,
    'anecdotal': 4,
    'anecdotally': 5,
    'anecdote': 3,
    'anecdotes': 3,
    'anello': 3,
    'anemia': 4,
    'anemias': 4,
    'anemic': 3,
    'anemometer': 5,
    'anemone': 3,
    'anencephalic': 5,
    'anencephaly': 5,
    'anestachio': 5,
    'anesthesia': 4,
    'anesthesiologist': 7,
    'anesthesiologists': 7,
    'anesthesiology': 7,
    'anesthetic': 4,
    'anesthetics': 4,
    'anesthetist': 4,
    'anette': 2,
    'aneurism': 4,
    'anew': 2,
    'anfal': 5,
    'anfinson': 3,
    'ang': 1,
    'ange': 1,
    'angel': 2,
    'angela': 3,
    'angeleno': 4,
    'angelenos': 4,
    'angeles': 3,
    'angeletti': 4,
    'angelfish': 3,
    'angeli': 3,
    'angelic': 3,
    'angelica': 4,
    'angelically': 4,
    'angelicas': 4,
    'angelico': 4,
    'angelika': 4,
    'angelillo': 4,
    'angelina': 4,
    'angeline': 3,
    'angelini': 4,
    'angelino': 4,
    'angelinos': 4,
    'angelita': 4,
    'angell': 2,
    'angelle': 2,
    'angello': 3,
    'angelo': 3,
    'angeloff': 3,
    'angelone': 4,
    'angeloni': 4,
    'angelos': 3,
    'angelou': 3,
    'angeloz': 3,
    'angels': 2,
    'angelucci': 4,
    'anger': 2,
    'angered': 2,
    'angerer': 3,
    'angering': 3,
    'angerman': 3,
    'angermeier': 4,
    'angers': 2,
    'angert': 2,
    'angevine': 3,
    'angi': 2,
    'angie': 2,
    'angier': 3,
    'angina': 3,
    'angio': 3,
    'angiogram': 4,
    'angiomedic': 5,
    'angiomedics': 5,
    'angioplasty': 5,
    'angiotensin': 5,
    'angle': 2,
    'angled': 2,
    'anglemyer': 4,
    'anglen': 3,
    'angler': 2,
    'anglers': 2,
    'angles': 2,
    'angleton': 3,
    'angley': 2,
    'anglia': 3,
    'anglican': 3,
    'anglicize': 3,
    'anglicized': 3,
    'anglim': 2,
    'anglin': 2,
    'angling': 2,
    'anglo': 2,
    'anglophile': 3,
    'anglos': 2,
    'angola': 3,
    'angolan': 3,
    'angolans': 3,
    'angora': 3,
    'angoras': 3,
    'angotti': 3,
    'angove': 3,
    'angrier': 3,
    'angriest': 3,
    'angrily': 3,
    'angry': 2,
    'angst': 1,
    'angstadt': 2,
    'angstrom': 2,
    'angstroms': 2,
    'anguiano': 4,
    'anguilla': 3,
    'anguish': 2,
    'anguished': 2,
    'anguishing': 3,
    'angular': 3,
    'angulate': 3,
    'angulated': 4,
    'angulo': 3,
    'angus': 2,
    'angy': 2,
    'anhalt': 2,
    'anheuser': 3,
    'anhydride': 3,
    'aniak': 3,
    'anibal': 3,
    'anikst': 2,
    'aniline': 3,
    'animal': 3,
    'animals': 3,
    'animate': 3,
    'animated': 4,
    'animates': 3,
    'animation': 4,
    'animations': 4,
    'animator': 4,
    'animators': 4,
    'animism': 4,
    'animist': 3,
    'animists': 3,
    'animosities': 5,
    'animosity': 5,
    'animous': 3,
    'animus': 3,
    'aninat': 3,
    'anise': 2,
    'aniseed': 3,
    'anisette': 3,
    'anita': 3,
    'anitec': 3,
    'anitole': 3,
    'anixter': 3,
    'anja': 2,
    'anjelica': 4,
    'ankara': 3,
    'ankeney': 3,
    'ankeny': 3,
    'anker': 2,
    'ankerium': 4,
    'ankh': 1,
    'anklam': 2,
    'ankle': 2,
    'anklebone': 3,
    'ankles': 2,
    'anklet': 2,
    'anklets': 2,
    'ankney': 2,
    'ankrom': 2,
    'ankrum': 2,
    'ann': 1,
    'anna': 2,
    'annabel': 3,
    'annabella': 4,
    'annabelle': 3,
    'annable': 3,
    'annal': 2,
    'annals': 2,
    'annamese': 3,
    'annan': 2,
    'annandale': 3,
    'annapolis': 4,
    'annas': 2,
    'annaud': 2,
    'anne': 1,
    'anneal': 2,
    'annealing': 3,
    'annelid': 3,
    'annelids': 3,
    'annen': 2,
    'annenberg': 3,
    'annese': 3,
    'annett': 2,
    'annette': 2,
    'annex': 2,
    'annexation': 4,
    'annexed': 2,
    'annexes': 3,
    'annexing': 3,
    'annick': 2,
    'annie': 2,
    'annihilate': 4,
    'annihilated': 5,
    'annihilating': 5,
    'annihilation': 5,
    'annis': 2,
    'anniston': 3,
    'anniversaries': 5,
    'anniversary': 5,
    'anno': 2,
    'annotate': 3,
    'annotated': 4,
    'annotates': 3,
    'annotating': 4,
    'annotation': 4,
    'annotations': 4,
    'announce': 2,
    'announced': 2,
    'announcement': 3,
    'announcements': 3,
    'announcer': 3,
    'announcers': 3,
    'announces': 3,
    'announcing': 3,
    'announcment': 3,
    'annoy': 2,
    'annoyance': 3,
    'annoyances': 4,
    'annoyed': 2,
    'annoying': 3,
    'annoys': 2,
    'annual': 3,
    'annualize': 4,
    'annualized': 4,
    'annually': 4,
    'annuals': 3,
    'annuit': 3,
    'annuities': 4,
    'annuity': 4,
    'annul': 2,
    'annular': 3,
    'annulled': 2,
    'annulment': 3,
    'annum': 2,
    'annunciata': 4,
    'annunziata': 5,
    'annunziato': 5,
    'annunzio': 4,
    'anny': 2,
    'ano': 2,
    'anode': 2,
    'anodes': 2,
    'anoint': 2,
    'anointed': 3,
    'anomalies': 4,
    'anomalous': 4,
    'anomaly': 4,
    'anomie': 3,
    'anona': 3,
    'anonymity': 5,
    'anonymous': 4,
    'anonymously': 5,
    'anora': 3,
    'anorak': 3,
    'anorexia': 5,
    'anorexic': 4,
    'anorexics': 4,
    'anorthite': 3,
    'another': 3,
    'anreder': 3,
    'anrig': 2,
    'ansa': 2,
    'ansaid': 2,
    'ansaldo': 3,
    'ansang': 2,
    'ansari': 3,
    'ansbacher': 3,
    'anschel': 2,
    'anschluss': 2,
    'anschutz': 2,
    'anscom': 2,
    'anscomb': 2,
    'anse': 1,
    'ansel': 2,
    'ansell': 2,
    'anselm': 2,
    'anselma': 3,
    'anselmi': 3,
    'anselmo': 3,
    'ansett': 2,
    'ansgar': 2,
    'anshan': 2,
    'ansin': 2,
    'ansley': 2,
    'ansohn': 2,
    'anson': 2,
    'ansonia': 4,
    'anspach': 2,
    'anspaugh': 2,
    'anstead': 2,
    'anstett': 2,
    'anstey': 2,
    'anstice': 2,
    'anstine': 3,
    'anstiss': 2,
    'answer': 2,
    'answerable': 4,
    'answered': 2,
    'answering': 3,
    'answers': 2,
    'ant': 1,
    'antacid': 3,
    'antacids': 3,
    'antagonism': 5,
    'antagonisms': 5,
    'antagonist': 4,
    'antagonistic': 5,
    'antagonists': 4,
    'antagonize': 4,
    'antagonized': 4,
    'antagonizing': 5,
    'antal': 2,
    'antar': 2,
    'antarctic': 3,
    'antarctica': 4,
    'antares': 3,
    'antaya': 3,
    'antczak': 2,
    'ante': 2,
    'anteater': 3,
    'anteaters': 3,
    'antebellum': 4,
    'antec': 2,
    'antecedent': 4,
    'antecedents': 4,
    'anted': 2,
    'antell': 2,
    'antelope': 3,
    'antemeridian': 6,
    'antemortem': 4,
    'antenna': 3,
    'antennae': 3,
    'antennas': 3,
    'antenne': 2,
    'antenucci': 4,
    'anterior': 4,
    'anteriormost': 5,
    'antes': 2,
    'anthea': 3,
    'anthem': 2,
    'anthems': 2,
    'anthes': 2,
    'anthiel': 3,
    'anthill': 2,
    'anthills': 2,
    'anthis': 2,
    'anthologies': 4,
    'anthology': 4,
    'anthon': 2,
    'anthony': 3,
    'anthracite': 3,
    'anthrax': 2,
    'anthrobot': 3,
    'anthropocentric': 5,
    'anthropogenic': 5,
    'anthropological': 6,
    'anthropologist': 5,
    'anthropologists': 5,
    'anthropology': 5,
    'anthropomorphic': 5,
    'anthropomorphism': 6,
    'anti': 2,
    'antiabortion': 5,
    'antiaircraft': 4,
    'antibacterial': 6,
    'antiballistic': 5,
    'antibiotic': 5,
    'antibioticos': 6,
    'antibiotics': 5,
    'antibodies': 4,
    'antibody': 4,
    'antic': 2,
    'anticancer': 4,
    'anticipate': 4,
    'anticipated': 5,
    'anticipates': 4,
    'anticipating': 5,
    'anticipation': 5,
    'anticipations': 5,
    'anticipatory': 6,
    'anticlimactic': 5,
    'anticline': 3,
    'antico': 3,
    'anticommunist': 5,
    'anticompetitive': 6,
    'anticorruption': 5,
    'anticrime': 3,
    'antics': 2,
    'antidepressant': 5,
    'antidepressants': 5,
    'antidiscrimination': 7,
    'antidisestablishmentarianism': 12,
    'antidote': 3,
    'antidrug': 3,
    'antidumping': 4,
    'antifraud': 3,
    'antifreeze': 3,
    'antifungal': 4,
    'antigay': 3,
    'antigen': 3,
    'antigens': 3,
    'antigone': 4,
    'antigones': 4,
    'antigovernment': 5,
    'antigua': 3,
    'antihistamine': 5,
    'antihistamines': 5,
    'antiknock': 3,
    'antill': 2,
    'antilla': 3,
    'antilles': 3,
    'antilock': 3,
    'antimatter': 4,
    'antimissile': 4,
    'antioch': 3,
    'antiochus': 4,
    'antioxidant': 5,
    'antioxidants': 5,
    'antipathies': 4,
    'antipathy': 4,
    'antipersonell': 5,
    'antiphon': 3,
    'antiphons': 3,
    'antipodal': 4,
    'antipoverty': 5,
    'antiquate': 3,
    'antiquated': 4,
    'antique': 2,
    'antiques': 2,
    'antiquities': 4,
    'antiquity': 4,
    'antireformer': 5,
    'antireformers': 5,
    'antis': 2,
    'antisense': 3,
    'antiseptic': 4,
    'antismoking': 4,
    'antisocial': 4,
    'antisubmarine': 5,
    'antitakeover': 5,
    'antitank': 3,
    'antitax': 3,
    'antitheft': 3,
    'antithesis': 4,
    'antithetical': 5,
    'antitoxin': 4,
    'antitoxins': 4,
    'antitrust': 3,
    'antiviral': 4,
    'antiwar': 3,
    'antkowiak': 4,
    'antle': 2,
    'antler': 2,
    'antlered': 2,
    'antlers': 2,
    'antley': 2,
    'antoine': 2,
    'antoinette': 3,
    'antol': 2,
    'antolik': 3,
    'antolini': 4,
    'anton': 2,
    'antonacci': 4,
    'antone': 3,
    'antonelli': 4,
    'antonellis': 4,
    'antonetti': 4,
    'antoni': 3,
    'antonia': 4,
    'antonin': 3,
    'antonini': 4,
    'antonio': 4,
    'antonios': 4,
    'antoniou': 4,
    'antoniu': 4,
    'antonius': 4,
    'antonopoulos': 5,
    'antonovich': 4,
    'antonsen': 3,
    'antonson': 3,
    'antonucci': 4,
    'antony': 3,
    'antoon': 2,
    'antos': 2,
    'antosh': 2,
    'antril': 2,
    'antrim': 2,
    'antrobus': 3,
    'ants': 1,
    'antsy': 2,
    'anttila': 3,
    'antuna': 3,
    'antunes': 3,
    'antunez': 3,
    'antwerp': 2,
    'antwine': 2,
    'anus': 2,
    'anvil': 2,
    'anwar': 2,
    'anway': 2,
    'anwell': 2,
    'anwyl': 2,
    'anwyll': 2,
    'anxieties': 4,
    'anxiety': 4,
    'anxious': 2,
    'anxiously': 3,
    'any': 2,
    'anybody': 4,
    'anyhow': 3,
    'anymore': 3,
    'anyon': 3,
    'anyone': 3,
    'anyplace': 3,
    'anything': 3,
    'anytime': 3,
    'anyway': 3,
    'anyways': 3,
    'anywhere': 3,
    'anza': 2,
    'anzaldua': 4,
    'anzalone': 3,
    'anzelmo': 3,
    'anzio': 3,
    'anzus': 2,
    'aoi': 2,
    'aoki': 3,
    'aol': 3,
    'aon': 2,
    'aorta': 3,
    'aortic': 3,
    'aoshima': 3,
    'aoun': 2,
    'aouzou': 3,
    'aoyama': 3,
    'apace': 2,
    'apache': 3,
    'apaches': 3,
    'apacs': 2,
    'apalachicola': 6,
    'aparicio': 5,
    'apart': 2,
    'apartheid': 3,
    'apartment': 3,
    'apartments': 3,
    'apathetic': 4,
    'apathy': 3,
    'apatite': 3,
    'apatites': 3,
    'apc': 3,
    'apcar': 2,
    'ape': 1,
    'apec': 2,
    'apel': 2,
    'apelike': 2,
    'apennine': 3,
    'aperture': 3,
    'apes': 1,
    'apex': 2,
    'apfel': 2,
    'apfelbaum': 3,
    'apgar': 2,
    'aphasia': 3,
    'aphid': 2,
    'aphids': 2,
    'aphorism': 4,
    'aphorisms': 4,
    'aphrodisiac': 5,
    'aphrodite': 4,
    'aphrodites': 4,
    'apicella': 4,
    'apiece': 2,
    'aping': 2,
    'aplenty': 3,
    'aplin': 2,
    'aplomb': 2,
    'apnea': 3,
    'apocalypse': 4,
    'apocalyptic': 5,
    'apocryphal': 4,
    'apodaca': 4,
    'apogee': 3,
    'apolitical': 5,
    'apolline': 3,
    'apollinian': 5,
    'apollo': 3,
    'apollonian': 5,
    'apologetic': 5,
    'apologetically': 6,
    'apologies': 4,
    'apologist': 4,
    'apologists': 4,
    'apologize': 4,
    'apologized': 4,
    'apologizes': 5,
    'apologizing': 5,
    'apology': 4,
    'aponte': 3,
    'apopa': 3,
    'apopka': 3,
    'apoplectic': 4,
    'apoplexy': 4,
    'apostate': 3,
    'apostle': 3,
    'apostles': 3,
    'apostol': 3,
    'apostolopoul': 5,
    'apostrophe': 4,
    'apothecary': 5,
    'apotheosis': 5,
    'app': 1,
    'appalachia': 5,
    'appalachian': 4,
    'appalachians': 4,
    'appall': 2,
    'appalled': 2,
    'appalling': 3,
    'appallingly': 4,
    'appalls': 2,
    'apparatchik': 4,
    'apparatchiks': 4,
    'apparatus': 4,
    'apparatuses': 5,
    'apparel': 3,
    'apparent': 3,
    'apparently': 4,
    'apparition': 4,
    'apparitions': 4,
    'appeal': 2,
    'appealable': 4,
    'appealed': 2,
    'appealing': 3,
    'appealingly': 4,
    'appeals': 2,
    'appear': 2,
    'appearance': 3,
    'appearances': 4,
    'appeared': 2,
    'appearing': 3,
    'appears': 2,
    'appease': 2,
    'appeased': 2,
    'appeasement': 3,
    'appeasing': 3,
    'appel': 2,
    'appelbaum': 3,
    'appelhans': 3,
    'appell': 2,
    'appellate': 3,
    'appellation': 4,
    'appellations': 4,
    'appelman': 3,
    'appelt': 2,
    'append': 2,
    'appendage': 3,
    'appendages': 4,
    'appendectomies': 5,
    'appendectomy': 5,
    'appended': 3,
    'appendix': 3,
    'appendixes': 4,
    'appenzeller': 4,
    'apperson': 3,
    'appert': 2,
    'appetite': 3,
    'appetites': 3,
    'appetito': 4,
    'appetizer': 4,
    'appetizers': 4,
    'appetizing': 4,
    'appia': 3,
    'appian': 3,
    'applaud': 2,
    'applauded': 3,
    'applauding': 3,
    'applauds': 2,
    'applause': 2,
    'apple': 2,
    'appleans': 2,
    'applebaum': 3,
    'applebee': 3,
    'appleberry': 4,
    'appleby': 3,
    'applegarth': 3,
    'applegate': 3,
    'appleman': 3,
    'applequist': 3,
    'apples': 2,
    'applesauce': 3,
    'appleseed': 3,
    'appleshare': 3,
    'applet': 2,
    'appleton': 3,
    'applewhite': 3,
    'appleyard': 3,
    'appliance': 3,
    'appliances': 4,
    'applicability': 6,
    'applicable': 4,
    'applicant': 3,
    'applicants': 3,
    'application': 4,
    'applications': 4,
    'applicator': 4,
    'applicators': 4,
    'applied': 2,
    'applies': 2,
    'applin': 2,
    'appling': 3,
    'apply': 2,
    'applying': 3,
    'appoint': 2,
    'appointed': 3,
    'appointee': 3,
    'appointees': 3,
    'appointing': 3,
    'appointive': 3,
    'appointment': 3,
    'appointments': 3,
    'appoints': 2,
    'apportion': 3,
    'apportioned': 3,
    'apportioning': 4,
    'apportionment': 4,
    'appraisal': 3,
    'appraisals': 3,
    'appraise': 2,
    'appraised': 2,
    'appraiser': 3,
    'appraisers': 3,
    'appraises': 3,
    'appraising': 3,
    'appreciable': 4,
    'appreciably': 4,
    'appreciate': 4,
    'appreciated': 5,
    'appreciates': 4,
    'appreciating': 5,
    'appreciation': 5,
    'appreciative': 5,
    'appreciatively': 6,
    'apprehend': 3,
    'apprehended': 4,
    'apprehending': 4,
    'apprehension': 4,
    'apprehensions': 4,
    'apprehensive': 4,
    'apprentice': 3,
    'apprenticed': 3,
    'apprentices': 4,
    'apprenticeship': 4,
    'apprenticeships': 4,
    'apprise': 2,
    'apprised': 2,
    'appro': 2,
    'approach': 2,
    'approachable': 4,
    'approached': 2,
    'approaches': 3,
    'approaching': 3,
    'approbation': 4,
    'appropriate': 4,
    'appropriated': 5,
    'appropriately': 5,
    'appropriateness': 5,
    'appropriates': 4,
    'appropriating': 5,
    'appropriation': 5,
    'appropriations': 5,
    'appropriator': 5,
    'appropriators': 5,
    'approvable': 4,
    'approval': 3,
    'approvals': 3,
    'approve': 2,
    'approved': 2,
    'approves': 2,
    'approving': 3,
    'approvingly': 4,
    'approximate': 4,
    'approximated': 5,
    'approximately': 5,
    'approximates': 4,
    'approximating': 5,
    'approximation': 5,
    'approximations': 5,
    'apps': 1,
    'apra': 2,
    'aprea': 3,
    'apricot': 3,
    'apricots': 3,
    'april': 2,
    'aprile': 2,
    'apriori': 4,
    'aprodiva': 4,
    'apron': 2,
    'aprons': 2,
    'apropos': 3,
    'apsell': 2,
    'apsey': 2,
    'apso': 2,
    'apstar': 2,
    'apt': 1,
    'apter': 2,
    'aptitude': 3,
    'aptitudes': 3,
    'aptiva': 3,
    'aptly': 2,
    'apuzzo': 3,
    'aqaba': 3,
    'aqazadeh': 4,
    'aqua': 2,
    'aquaculture': 4,
    'aqualung': 3,
    'aquamarine': 4,
    'aquanaut': 3,
    'aquanauts': 3,
    'aquarist': 3,
    'aquarists': 3,
    'aquarium': 4,
    'aquariums': 4,
    'aquarius': 4,
    'aquatic': 3,
    'aqueduct': 3,
    'aqueducts': 3,
    'aqueous': 3,
    'aquifer': 3,
    'aquifers': 3,
    'aquila': 3,
    'aquilar': 3,
    'aquilina': 4,
    'aquilino': 4,
    'aquinas': 3,
    'aquino': 3,
    'aquirre': 2,
    'aquitaine': 3,
    'ar': 1,
    'ara': 2,
    'arab': 2,
    'arabe': 3,
    'arabella': 4,
    'arabia': 4,
    'arabian': 4,
    'arabians': 4,
    'arabic': 3,
    'arabica': 4,
    'arabichon': 4,
    'arabicon': 4,
    'arabie': 3,
    'arable': 3,
    'arabs': 2,
    'arachnid': 3,
    'arachnids': 3,
    'arafat': 3,
    'aragon': 3,
    'aragona': 4,
    'arai': 3,
    'araiza': 3,
    'arakaki': 4,
    'arakawa': 4,
    'arakelian': 4,
    'araki': 3,
    'aral': 2,
    'arambula': 4,
    'aramco': 3,
    'aramid': 3,
    'araminta': 4,
    'aramis': 3,
    'aramony': 4,
    'aran': 2,
    'arana': 3,
    'aranda': 3,
    'arango': 3,
    'aransas': 3,
    'arant': 2,
    'arantza': 3,
    'aranyos': 3,
    'arapaho': 4,
    'arapahoe': 4,
    'ararat': 3,
    'araskog': 3,
    'arata': 3,
    'arau': 2,
    'araujo': 3,
    'aravind': 2,
    'araya': 3,
    'arb': 1,
    'arba': 2,
    'arbat': 2,
    'arbaugh': 2,
    'arbed': 2,
    'arbeiter': 3,
    'arbel': 2,
    'arbella': 3,
    'arbenz': 2,
    'arbiter': 3,
    'arbiters': 3,
    'arbitrage': 3,
    'arbitrager': 4,
    'arbitragers': 4,
    'arbitrageur': 4,
    'arbitrageurs': 4,
    'arbitraging': 4,
    'arbitrarily': 5,
    'arbitrariness': 5,
    'arbitrary': 4,
    'arbitrate': 3,
    'arbitrated': 4,
    'arbitrates': 3,
    'arbitrating': 4,
    'arbitration': 4,
    'arbitrations': 4,
    'arbitrator': 4,
    'arbitrators': 4,
    'arbitron': 3,
    'arbo': 2,
    'arbogast': 3,
    'arboleda': 4,
    'arbor': 2,
    'arboreal': 4,
    'arboretum': 4,
    'arborville': 3,
    'arbothnott': 3,
    'arbour': 2,
    'arbs': 1,
    'arbuckle': 3,
    'arbuckles': 3,
    'arbuthnot': 3,
    'arby': 2,
    'arc': 1,
    'arcade': 2,
    'arcades': 2,
    'arcadia': 4,
    'arcadian': 4,
    'arcadians': 4,
    'arcana': 3,
    'arcand': 2,
    'arcane': 2,
    'arcanum': 3,
    'arcaro': 3,
    'arcata': 3,
    'arce': 1,
    'arcement': 3,
    'arceneaux': 3,
    'arceo': 3,
    'arch': 1,
    'archaeological': 6,
    'archaeologist': 5,
    'archaeologists': 5,
    'archaeology': 5,
    'archaic': 3,
    'archambault': 3,
    'archambeau': 3,
    'archambeault': 3,
    'archangel': 3,
    'archangels': 3,
    'archard': 2,
    'archbishop': 3,
    'archbold': 2,
    'archdiocese': 4,
    'archduke': 2,
    'arched': 1,
    'archenemy': 4,
    'archeological': 6,
    'archeology': 5,
    'archer': 2,
    'archerd': 2,
    'archery': 3,
    'arches': 2,
    'archetypal': 3,
    'archetype': 3,
    'archetypes': 3,
    'archey': 2,
    'archibald': 3,
    'archibeque': 3,
    'archie': 2,
    'archimedes': 4,
    'arching': 2,
    'archipelago': 5,
    'architect': 3,
    'architects': 3,
    'architectural': 5,
    'architecturally': 6,
    'architecture': 4,
    'architectures': 4,
    'architrave': 3,
    'architraves': 3,
    'archival': 3,
    'archive': 2,
    'archives': 2,
    'archivist': 3,
    'archivists': 3,
    'archly': 2,
    'archrival': 3,
    'archrivals': 3,
    'archuleta': 4,
    'archuletta': 4,
    'archway': 2,
    'arcidiacono': 6,
    'arcieri': 3,
    'arciniega': 4,
    'arco': 2,
    'arcola': 3,
    'arcos': 2,
    'arcosanti': 4,
    'arcs': 1,
    'arctic': 2,
    'arcturus': 3,
    'arcuri': 3,
    'ard': 1,
    'arda': 2,
    'ardath': 2,
    'ardeen': 2,
    'ardelia': 4,
    'ardella': 3,
    'ardelle': 2,
    'arden': 2,
    'ardene': 2,
    'ardent': 2,
    'ardently': 3,
    'ardin': 2,
    'ardine': 3,
    'ardis': 2,
    'ardith': 2,
    'ardito': 3,
    'ardley': 2,
    'ardmore': 2,
    'ardoin': 2,
    'ardolino': 4,
    'ardolph': 2,
    'ardor': 2,
    'ardoyne': 2,
    'ardra': 2,
    'ardrey': 2,
    'ardshiel': 2,
    'ardsley': 2,
    'arduini': 4,
    'arduous': 3,
    'are': 1,
    'area': 3,
    'areas': 3,
    'arebalo': 4,
    'arechiga': 4,
    'arehart': 3,
    'arel': 2,
    'arellanes': 3,
    'arellano': 4,
    'aren': 2,
    'arena': 3,
    'arenaball': 4,
    'arenas': 3,
    'arencibia': 5,
    'arend': 2,
    'arends': 2,
    'arendsee': 3,
    'arendt': 2,
    'arenella': 4,
    'arens': 2,
    'arenson': 3,
    'arent': 2,
    'arentz': 2,
    'arenz': 2,
    'ares': 1,
    'areta': 3,
    'aretha': 3,
    'aretina': 4,
    'aretsky': 3,
    'aretta': 3,
    'arevalo': 4,
    'arey': 2,
    'argabright': 3,
    'argall': 2,
    'arganbright': 3,
    'argenbright': 3,
    'argent': 2,
    'argenta': 3,
    'argentieri': 4,
    'argentina': 4,
    'argentinas': 4,
    'argentine': 3,
    'argentines': 3,
    'argentinian': 5,
    'argentinians': 5,
    'argento': 3,
    'argiro': 3,
    'argo': 2,
    'argon': 2,
    'argonaut': 3,
    'argonauts': 3,
    'argonne': 2,
    'argosy': 3,
    'argosystem': 4,
    'argosystems': 4,
    'argot': 2,
    'arguable': 4,
    'arguably': 4,
    'argue': 2,
    'argued': 2,
    'arguelles': 3,
    'arguello': 3,
    'argues': 2,
    'argueta': 3,
    'arguijo': 3,
    'arguing': 3,
    'argument': 3,
    'argumentation': 5,
    'argumentative': 5,
    'arguments': 3,
    'argus': 2,
    'argyle': 2,
    'argyll': 2,
    'argyropoulos': 5,
    'ari': 2,
    'aria': 3,
    'ariadne': 4,
    'arian': 3,
    'ariana': 4,
    'ariane': 3,
    'arianespace': 4,
    'arianism': 5,
    'arianna': 4,
    'arias': 3,
    'aric': 2,
    'arico': 3,
    'arid': 2,
    'arida': 3,
    'arie': 2,
    'ariel': 3,
    'ariella': 4,
    'aries': 2,
    'arington': 3,
    'ariola': 4,
    'aris': 2,
    'arise': 2,
    'arisen': 3,
    'arises': 3,
    'arising': 3,
    'arison': 3,
    'arispe': 3,
    'arista': 3,
    'aristech': 3,
    'aristede': 3,
    'aristide': 3,
    'aristocracy': 5,
    'aristocrat': 4,
    'aristocratic': 5,
    'aristocrats': 4,
    'aristophanes': 5,
    'aristotelian': 6,
    'aristotle': 4,
    'arithmetic': 4,
    'ariza': 3,
    'arizmendi': 4,
    'arizona': 4,
    'arizonan': 4,
    'arizonans': 4,
    'arjo': 2,
    'ark': 1,
    'arkadelphia': 5,
    'arkadi': 3,
    'arkady': 3,
    'arkansan': 3,
    'arkansans': 3,
    'arkansas': 3,
    'arkarow': 3,
    'arkin': 2,
    'arkla': 2,
    'arko': 2,
    'arkose': 2,
    'arkwright': 2,
    'arlana': 3,
    'arlauskas': 3,
    'arledge': 2,
    'arleen': 2,
    'arlen': 2,
    'arlena': 3,
    'arlene': 2,
    'arleta': 3,
    'arlette': 2,
    'arley': 2,
    'arlie': 2,
    'arlin': 2,
    'arlinda': 3,
    'arline': 2,
    'arlington': 3,
    'arlt': 1,
    'arlyne': 2,
    'arm': 1,
    'armacost': 3,
    'armada': 3,
    'armadillo': 4,
    'armadillos': 4,
    'armageddon': 4,
    'armagost': 3,
    'armament': 3,
    'armaments': 3,
    'arman': 2,
    'armand': 2,
    'armando': 3,
    'armandt': 2,
    'armani': 3,
    'armantrout': 3,
    'armaro': 3,
    'armas': 2,
    'armato': 3,
    'armband': 2,
    'armbands': 2,
    'armbrister': 4,
    'armbrust': 2,
    'armbruster': 3,
    'armchair': 2,
    'armchairs': 2,
    'armco': 2,
    'armed': 1,
    'armel': 2,
    'armellino': 4,
    'armen': 2,
    'armendarez': 4,
    'armendariz': 4,
    'armenia': 4,
    'armenian': 4,
    'armenians': 4,
    'arment': 2,
    'armenta': 3,
    'armentor': 3,
    'armentrout': 3,
    'armer': 2,
    'armes': 1,
    'armested': 3,
    'armetta': 3,
    'armey': 2,
    'armfield': 2,
    'armida': 3,
    'armies': 2,
    'armiger': 3,
    'armijo': 3,
    'armilla': 3,
    'armin': 2,
    'armina': 3,
    'armine': 3,
    'arming': 2,
    'armington': 3,
    'arminie': 3,
    'armistead': 3,
    'armistice': 3,
    'armitage': 3,
    'armoire': 2,
    'armoires': 2,
    'armold': 2,
    'armon': 2,
    'armond': 2,
    'armonk': 2,
    'armor': 2,
    'armored': 2,
    'armories': 3,
    'armory': 3,
    'armour': 2,
    'armpit': 2,
    'armpits': 2,
    'armrest': 2,
    'armrests': 2,
    'arms': 1,
    'armstead': 2,
    'armstrad': 2,
    'armstrong': 2,
    'armtec': 2,
    'armtek': 2,
    'armwood': 2,
    'army': 2,
    'arn': 1,
    'arnal': 2,
    'arnalda': 3,
    'arnaldo': 3,
    'arnall': 2,
    'arnatt': 2,
    'arnaud': 2,
    'arnault': 2,
    'arnaz': 2,
    'arndt': 1,
    'arne': 1,
    'arnell': 2,
    'arnelle': 2,
    'arner': 2,
    'arnesen': 3,
    'arneson': 3,
    'arnett': 2,
    'arnette': 2,
    'arney': 2,
    'arnhold': 2,
    'arnholt': 2,
    'arnie': 2,
    'arnn': 1,
    'arno': 2,
    'arnold': 2,
    'arnoldi': 3,
    'arnolds': 2,
    'arnoldy': 3,
    'arnolphe': 3,
    'arnone': 3,
    'arnot': 2,
    'arnott': 2,
    'arnotts': 2,
    'arnow': 2,
    'arnox': 2,
    'arns': 1,
    'arnst': 1,
    'arnstein': 2,
    'arntson': 2,
    'arntz': 1,
    'arntzen': 2,
    'arnulfo': 3,
    'arnwine': 2,
    'arnzen': 2,
    'aro': 2,
    'arocha': 3,
    'arocho': 3,
    'aroma': 3,
    'aromas': 3,
    'aromatherapy': 6,
    'aromatic': 4,
    'aron': 2,
    'arone': 2,
    'aronhalt': 3,
    'aronoff': 3,
    'aronow': 3,
    'aronowitz': 4,
    'arons': 2,
    'aronson': 3,
    'aronstein': 3,
    'arora': 3,
    'aros': 2,
    'arose': 2,
    'around': 2,
    'arousal': 3,
    'arouse': 2,
    'aroused': 2,
    'arouses': 3,
    'arousing': 3,
    'arp': 1,
    'arpa': 2,
    'arpanet': 3,
    'arpin': 2,
    'arpino': 3,
    'arps': 1,
    'arquette': 2,
    'arquilla': 3,
    'arraign': 2,
    'arraigned': 2,
    'arraignment': 3,
    'arraignments': 3,
    'arrange': 2,
    'arranged': 2,
    'arrangement': 3,
    'arrangements': 3,
    'arranger': 3,
    'arranges': 3,
    'arranging': 3,
    'arrant': 2,
    'arrants': 2,
    'arrasmith': 3,
    'array': 2,
    'arrayed': 2,
    'arraying': 3,
    'arrays': 2,
    'arrearage': 3,
    'arrearages': 4,
    'arrears': 2,
    'arredondo': 4,
    'arreguin': 3,
    'arrendondo': 4,
    'arreola': 4,
    'arrest': 2,
    'arrested': 3,
    'arresting': 3,
    'arrests': 2,
    'arrhythmia': 4,
    'arrhythmias': 4,
    'arriaga': 4,
    'arrick': 2,
    'arrieta': 3,
    'arrigo': 3,
    'arrigoni': 4,
    'arrington': 3,
    'arriola': 4,
    'arriortua': 5,
    'arris': 2,
    'arrival': 3,
    'arrivals': 3,
    'arrive': 2,
    'arrived': 2,
    'arrives': 2,
    'arriving': 3,
    'arrogance': 3,
    'arrogant': 3,
    'arrogantly': 4,
    'arrogate': 3,
    'arrow': 2,
    'arrowhead': 3,
    'arrowheads': 3,
    'arrowood': 3,
    'arrowroot': 3,
    'arrows': 2,
    'arrowsmith': 3,
    'arroyo': 3,
    'arruda': 3,
    'arrupe': 2,
    'arsdale': 2,
    'arsenal': 3,
    'arsenals': 3,
    'arsenault': 3,
    'arseneau': 3,
    'arseneault': 3,
    'arsenic': 3,
    'arsenide': 3,
    'arsenio': 4,
    'arslanian': 4,
    'arson': 2,
    'arsonist': 3,
    'arsonists': 3,
    'arsons': 2,
    'arsov': 2,
    'art': 1,
    'artale': 3,
    'artcarve': 2,
    'artcarved': 2,
    'arteaga': 3,
    'artemas': 3,
    'artemus': 3,
    'arter': 2,
    'arterberry': 4,
    'arterburn': 3,
    'arterial': 4,
    'arteries': 3,
    'arteriosclerosis': 7,
    'artery': 3,
    'artes': 2,
    'artesian': 3,
    'artful': 2,
    'artfully': 3,
    'arth': 1,
    'arthotec': 3,
    'arthritic': 3,
    'arthritis': 3,
    'arthropod': 3,
    'arthropods': 3,
    'arthur': 2,
    'arthurian': 4,
    'arthurs': 2,
    'artichoke': 3,
    'artichokes': 3,
    'article': 3,
    'articles': 3,
    'articulate': 4,
    'articulated': 5,
    'articulates': 4,
    'articulating': 5,
    'articulation': 5,
    'articulatory': 6,
    'artie': 2,
    'artifact': 3,
    'artifacts': 3,
    'artifice': 3,
    'artificial': 4,
    'artificially': 5,
    'artillery': 4,
    'artino': 3,
    'artiodactyl': 5,
    'artiodactyls': 5,
    'artis': 2,
    'artisan': 3,
    'artisans': 3,
    'artist': 2,
    'artistic': 3,
    'artistically': 4,
    'artistry': 3,
    'artists': 2,
    'artley': 2,
    'artman': 2,
    'artois': 2,
    'arton': 2,
    'artra': 2,
    'artrip': 2,
    'arts': 1,
    'artsy': 2,
    'artur': 2,
    'arturo': 3,
    'artus': 2,
    'artwork': 2,
    'artworks': 2,
    'arty': 2,
    'artz': 1,
    'artzt': 1,
    'aruba': 3,
    'arum': 2,
    'arunachalam': 5,
    'arundel': 3,
    'arup': 2,
    'arva': 2,
    'arvad': 2,
    'arval': 2,
    'arvanitis': 4,
    'arvay': 2,
    'arve': 1,
    'arvel': 2,
    'arvey': 2,
    'arvid': 2,
    'arvida': 3,
    'arvidson': 3,
    'arvin': 2,
    'arvind': 2,
    'arvizu': 3,
    'arwen': 2,
    'arwood': 2,
    'arx': 1,
    'ary': 2,
    'aryan': 3,
    'arzama': 3,
    'arzamas': 3,
    'arzate': 2,
    'arzola': 3,
    'arzt': 1,
    'as': 1,
    'asa': 2,
    'asaf': 2,
    'asahan': 3,
    'asahara': 4,
    'asahi': 3,
    'asamera': 4,
    'asap': 4,
    'asarco': 3,
    'asaro': 3,
    'asat': 2,
    'asato': 3,
    'asay': 2,
    'asbell': 2,
    'asberry': 3,
    'asbestec': 3,
    'asbestos': 3,
    'asbestosis': 4,
    'asbill': 2,
    'asbridge': 2,
    'asbury': 3,
    'asby': 2,
    'ascap': 2,
    'ascencio': 4,
    'ascend': 2,
    'ascendance': 3,
    'ascendancy': 4,
    'ascendant': 3,
    'ascended': 3,
    'ascendency': 4,
    'ascending': 3,
    'ascends': 2,
    'ascension': 3,
    'ascent': 2,
    'ascertain': 3,
    'ascertained': 3,
    'ascertaining': 4,
    'ascetic': 3,
    'asch': 1,
    'asche': 1,
    'aschenbach': 3,
    'aschenbrenner': 4,
    'ascher': 2,
    'aschoff': 2,
    'ascii': 2,
    'asclad': 2,
    'ascorbic': 3,
    'ascot': 2,
    'ascott': 2,
    'ascribe': 2,
    'ascribed': 2,
    'ascribes': 2,
    'asda': 2,
    'asea': 3,
    'asean': 3,
    'aselma': 3,
    'aseltine': 4,
    'asencio': 4,
    'aseritis': 4,
    'asexual': 4,
    'ash': 1,
    'asha': 2,
    'ashaji': 3,
    'ashame': 2,
    'ashamed': 2,
    'ashare': 2,
    'ashbaugh': 2,
    'ashbrook': 2,
    'ashburn': 2,
    'ashbury': 3,
    'ashby': 2,
    'ashcraft': 2,
    'ashcreek': 2,
    'ashcroft': 2,
    'ashdown': 2,
    'ashe': 1,
    'asheboro': 3,
    'ashen': 2,
    'ashenberg': 3,
    'ashenfelter': 4,
    'asher': 2,
    'ashes': 2,
    'asheville': 2,
    'ashey': 2,
    'ashford': 2,
    'ashis': 2,
    'ashish': 2,
    'ashkenazi': 4,
    'ashkenazis': 4,
    'ashland': 2,
    'ashley': 2,
    'ashlin': 2,
    'ashline': 2,
    'ashlock': 2,
    'ashman': 2,
    'ashmead': 2,
    'ashmore': 2,
    'ashok': 2,
    'ashore': 2,
    'ashrawi': 3,
    'ashtec': 2,
    'ashton': 2,
    'ashtray': 2,
    'ashtrays': 2,
    'ashur': 2,
    'ashurst': 2,
    'ashville': 2,
    'ashwell': 2,
    'ashwood': 2,
    'ashworth': 2,
    'ashy': 2,
    'asia': 2,
    'asiain': 3,
    'asiamerica': 6,
    'asian': 2,
    'asians': 2,
    'asiatic': 4,
    'asiaweek': 3,
    'asic': 2,
    'asics': 2,
    'aside': 2,
    'asides': 2,
    'asiel': 3,
    'asimov': 3,
    'asimow': 3,
    'asinine': 3,
    'ask': 1,
    'aska': 2,
    'askance': 2,
    'asked': 1,
    'asker': 2,
    'askers': 2,
    'askew': 2,
    'askey': 2,
    'askin': 2,
    'asking': 2,
    'askington': 3,
    'askins': 2,
    'asko': 2,
    'askoldov': 3,
    'askren': 3,
    'asks': 1,
    'aslanian': 4,
    'asleep': 2,
    'asleson': 3,
    'aslin': 2,
    'asman': 2,
    'asmara': 3,
    'asmaras': 3,
    'asmodeus': 4,
    'asmus': 2,
    'asmussen': 3,
    'asner': 2,
    'asp': 1,
    'asparagus': 4,
    'aspartame': 3,
    'aspect': 2,
    'aspects': 2,
    'aspen': 2,
    'aspens': 2,
    'asper': 2,
    'asperity': 4,
    'aspersion': 3,
    'aspersions': 3,
    'asphalt': 2,
    'asphyxiate': 4,
    'asphyxiated': 5,
    'asphyxiates': 4,
    'asphyxiating': 5,
    'aspin': 2,
    'aspinall': 3,
    'aspinwall': 3,
    'aspirant': 3,
    'aspirants': 3,
    'aspirate': 3,
    'aspirated': 4,
    'aspirates': 3,
    'aspiration': 4,
    'aspirations': 4,
    'aspire': 2,
    'aspired': 2,
    'aspires': 3,
    'aspirin': 2,
    'aspiring': 3,
    'asplund': 2,
    'aspnes': 2,
    'asquith': 2,
    'ass': 1,
    'assad': 2,
    'assaf': 2,
    'assail': 2,
    'assailant': 3,
    'assailants': 3,
    'assailed': 2,
    'assailing': 3,
    'assails': 2,
    'assante': 3,
    'assassin': 3,
    'assassinate': 4,
    'assassinated': 5,
    'assassinating': 5,
    'assassination': 5,
    'assassinations': 5,
    'assassins': 3,
    'assault': 2,
    'assaulted': 3,
    'assaulting': 3,
    'assaultive': 3,
    'assaults': 2,
    'assay': 2,
    'assayer': 3,
    'assed': 1,
    'asselin': 3,
    'asselstine': 3,
    'assemblage': 3,
    'assemble': 3,
    'assembled': 3,
    'assembler': 3,
    'assemblers': 3,
    'assembles': 3,
    'assemblies': 3,
    'assembling': 4,
    'assembly': 3,
    'assemblyman': 4,
    'assemblymen': 4,
    'assemblywoman': 5,
    'assent': 2,
    'assert': 2,
    'asserted': 3,
    'assertedly': 4,
    'asserting': 3,
    'assertion': 3,
    'assertions': 3,
    'assertive': 3,
    'assertively': 4,
    'assertiveness': 4,
    'asserts': 2,
    'asses': 2,
    'assess': 2,
    'assessed': 2,
    'assesses': 3,
    'assessing': 3,
    'assessment': 3,
    'assessments': 3,
    'assessor': 3,
    'assessors': 3,
    'asset': 2,
    'assets': 2,
    'asshole': 2,
    'assicurazioni': 7,
    'assiduous': 3,
    'assiduously': 4,
    'assign': 2,
    'assigned': 2,
    'assigning': 3,
    'assignment': 3,
    'assignments': 3,
    'assigns': 2,
    'assimilate': 4,
    'assimilated': 5,
    'assimilating': 5,
    'assimilation': 5,
    'assira': 3,
    'assisi': 3,
    'assist': 2,
    'assistance': 3,
    'assistant': 3,
    'assistants': 3,
    'assisted': 3,
    'assisting': 3,
    'assists': 2,
    'assocation': 4,
    'associate': 4,
    'associated': 5,
    'associates': 4,
    'associating': 5,
    'association': 5,
    'associations': 5,
    'associes': 3,
    'assort': 2,
    'assorted': 3,
    'assortment': 3,
    'assuage': 2,
    'assuaged': 2,
    'assubel': 3,
    'assume': 2,
    'assumed': 2,
    'assumes': 2,
    'assuming': 3,
    'assumption': 3,
    'assumptions': 3,
    'assurance': 3,
    'assurances': 4,
    'assuras': 3,
    'assure': 2,
    'assured': 2,
    'assuredly': 4,
    'assures': 2,
    'assuring': 3,
    'assyria': 4,
    'assyrian': 4,
    'assyrians': 4,
    'ast': 1,
    'asta': 2,
    'astaire': 2,
    'aster': 2,
    'asterisk': 3,
    'asteroid': 3,
    'asteroids': 3,
    'asters': 2,
    'asthma': 2,
    'asthmatic': 3,
    'asthmatics': 3,
    'astigmatism': 5,
    'astin': 2,
    'astle': 2,
    'astley': 2,
    'aston': 2,
    'astonish': 3,
    'astonished': 3,
    'astonishing': 4,
    'astonishingly': 5,
    'astonishment': 4,
    'astor': 2,
    'astore': 2,
    'astorga': 3,
    'astoria': 4,
    'astorino': 4,
    'astound': 2,
    'astounded': 3,
    'astounding': 3,
    'astoundingly': 4,
    'astounds': 2,
    'astra': 2,
    'astraddle': 3,
    'astrakhan': 3,
    'astral': 2,
    'astray': 2,
    'astred': 2,
    'astrid': 2,
    'astride': 2,
    'astringent': 3,
    'astringents': 3,
    'astro': 2,
    'astrodome': 3,
    'astrogeology': 6,
    'astrologer': 4,
    'astrologers': 4,
    'astrological': 5,
    'astrology': 4,
    'astronaut': 3,
    'astronautic': 4,
    'astronautical': 5,
    'astronautics': 4,
    'astronauts': 3,
    'astronomer': 4,
    'astronomers': 4,
    'astronomical': 5,
    'astronomically': 5,
    'astronomy': 4,
    'astrophotography': 6,
    'astrophysicist': 5,
    'astrophysics': 4,
    'astros': 2,
    'astrotech': 3,
    'astroturf': 3,
    'astute': 2,
    'astutely': 3,
    'astuteness': 3,
    'asuncion': 3,
    'asunder': 3,
    'aswin': 2,
    'asylum': 3,
    'asymmetrical': 5,
    'asymmetries': 4,
    'asymmetry': 4,
    'asymptomatic': 5,
    'asymptote': 3,
    'asymptotes': 3,
    'asynchronous': 4,
    'at': 1,
    'ata': 2,
    'atalanta': 4,
    'atalaya': 4,
    'atalia': 4,
    'atalie': 3,
    'atamian': 4,
    'atari': 3,
    'ataturk': 3,
    'atavism': 4,
    'atavistic': 4,
    'ataxia': 4,
    'atcheson': 3,
    'atchinson': 3,
    'atchison': 3,
    'atchley': 2,
    'atco': 2,
    'atcor': 2,
    'ate': 1,
    'atek': 2,
    'aten': 2,
    'atencio': 4,
    'ater': 2,
    'ates': 1,
    'atha': 2,
    'athalia': 4,
    'athanas': 3,
    'athanassiou': 5,
    'athans': 2,
    'athas': 2,
    'athearn': 2,
    'atheism': 3,
    'atheist': 3,
    'atheistic': 4,
    'atheists': 3,
    'athena': 3,
    'athenaeum': 4,
    'athenaios': 4,
    'atheneum': 3,
    'athenian': 4,
    'athenians': 4,
    'athens': 2,
    'atherosclerosis': 6,
    'atherton': 3,
    'athey': 2,
    'athie': 2,
    'athlete': 2,
    'athletes': 2,
    'athletic': 3,
    'athletically': 4,
    'athleticism': 4,
    'athletics': 3,
    'athlone': 2,
    'athwart': 2,
    'atico': 3,
    'atienza': 3,
    'atilano': 4,
    'atither': 3,
    'atkerson': 3,
    'atkin': 2,
    'atkins': 2,
    'atkinson': 3,
    'atkison': 3,
    'atkisson': 3,
    'atla': 2,
    'atlan': 2,
    'atlanta': 3,
    'atlantan': 3,
    'atlantans': 3,
    'atlantic': 3,
    'atlantica': 4,
    'atlantico': 4,
    'atlantis': 3,
    'atlas': 2,
    'atlases': 3,
    'atler': 2,
    'atley': 2,
    'atmel': 2,
    'atmosphere': 3,
    'atmospheric': 4,
    'atmospherics': 4,
    'atnip': 2,
    'atocha': 3,
    'atoll': 2,
    'atom': 2,
    'atomic': 3,
    'atomizer': 4,
    'atoms': 2,
    'aton': 2,
    'atonal': 3,
    'atone': 2,
    'atonement': 3,
    'atop': 2,
    'ator': 2,
    'atorino': 4,
    'atp': 3,
    'atra': 2,
    'atria': 3,
    'atrium': 3,
    'atriums': 3,
    'atrocious': 3,
    'atrocities': 4,
    'atrocity': 4,
    'atrophied': 3,
    'atrophies': 3,
    'atrophy': 3,
    'atsushi': 3,
    'atta': 2,
    'attaboy': 3,
    'attaboys': 3,
    'attach': 2,
    'attache': 3,
    'attached': 2,
    'attaches': 3,
    'attaching': 3,
    'attachment': 3,
    'attachments': 3,
    'attack': 2,
    'attacked': 2,
    'attacker': 3,
    'attackers': 3,
    'attacking': 3,
    'attacks': 2,
    'attain': 2,
    'attainable': 4,
    'attainder': 3,
    'attained': 2,
    'attaining': 3,
    'attainment': 3,
    'attains': 2,
    'attali': 3,
    'attalla': 3,
    'attanasio': 5,
    'attar': 2,
    'attard': 2,
    'attardo': 3,
    'attaway': 3,
    'atteberry': 3,
    'attebery': 4,
    'attebury': 3,
    'attempt': 2,
    'attempted': 3,
    'attempting': 3,
    'attempts': 2,
    'attenborough': 4,
    'attend': 2,
    'attendance': 3,
    'attendant': 3,
    'attendants': 3,
    'attended': 3,
    'attendee': 3,
    'attendees': 3,
    'attending': 3,
    'attends': 2,
    'attention': 3,
    'attentions': 3,
    'attentive': 3,
    'attentively': 4,
    'attentiveness': 4,
    'attenuate': 4,
    'attenuated': 5,
    'attenuates': 4,
    'atterberry': 4,
    'atterbury': 4,
    'attermann': 3,
    'attest': 2,
    'attested': 3,
    'attesting': 3,
    'attests': 2,
    'attic': 2,
    'attica': 3,
    'attics': 2,
    'atticus': 3,
    'attie': 2,
    'attila': 3,
    'attire': 3,
    'attired': 2,
    'attitude': 3,
    'attitudes': 3,
    'attitudinal': 5,
    'attkisson': 3,
    'attleboro': 4,
    'attlee': 2,
    'attorney': 3,
    'attorneys': 3,
    'attract': 2,
    'attracted': 3,
    'attracting': 3,
    'attraction': 3,
    'attractions': 3,
    'attractive': 3,
    'attractively': 4,
    'attractiveness': 4,
    'attracts': 2,
    'attributable': 5,
    'attribute': 3,
    'attributed': 4,
    'attributes': 3,
    'attributing': 4,
    'attribution': 4,
    'attridge': 2,
    'attrition': 3,
    'attune': 2,
    'attuned': 2,
    'attwood': 2,
    'attwoods': 2,
    'atwater': 3,
    'atwell': 2,
    'atwood': 2,
    'atworth': 2,
    'atx': 3,
    'atypical': 4,
    'atz': 1,
    'au': 1,
    'aube': 1,
    'aubel': 2,
    'auber': 2,
    'auberry': 3,
    'aubert': 2,
    'aubin': 2,
    'auble': 2,
    'aubrey': 2,
    'aubry': 2,
    'aubuchon': 3,
    'auburn': 2,
    'auch': 1,
    'auchter': 2,
    'auckland': 2,
    'auclair': 2,
    'aucoin': 2,
    'aucott': 2,
    'auction': 2,
    'auctioned': 2,
    'auctioneer': 3,
    'auctioneering': 4,
    'auctioneers': 3,
    'auctioning': 3,
    'auctions': 2,
    'aud': 1,
    'audacious': 3,
    'audacity': 4,
    'audas': 2,
    'auden': 2,
    'auderburn': 3,
    'audet': 2,
    'audette': 2,
    'audi': 2,
    'audia': 3,
    'audible': 3,
    'audibles': 3,
    'audibly': 3,
    'audience': 3,
    'audiences': 4,
    'audino': 3,
    'audio': 3,
    'audiophile': 4,
    'audiophiles': 4,
    'audiotape': 4,
    'audiotapes': 4,
    'audiotex': 4,
    'audiotronic': 5,
    'audiotronics': 5,
    'audiovisual': 6,
    'audiovox': 4,
    'audis': 2,
    'audit': 2,
    'audited': 3,
    'auditing': 3,
    'audition': 3,
    'auditioned': 3,
    'auditioning': 4,
    'auditions': 3,
    'auditor': 3,
    'auditorium': 5,
    'auditoriums': 5,
    'auditors': 3,
    'auditory': 4,
    'audits': 2,
    'audran': 2,
    'audrey': 2,
    'audric': 2,
    'audrie': 3,
    'audris': 2,
    'audry': 2,
    'audubon': 3,
    'audwin': 2,
    'aue': 1,
    'auel': 2,
    'auen': 2,
    'auer': 2,
    'auerbach': 3,
    'auerback': 2,
    'aufderheide': 3,
    'aufhauser': 3,
    'aufiero': 3,
    'aug': 2,
    'augat': 2,
    'auge': 1,
    'augello': 3,
    'augenstein': 3,
    'auger': 2,
    'augers': 2,
    'aughenbaugh': 3,
    'augment': 2,
    'augmentable': 4,
    'augmentation': 4,
    'augmented': 3,
    'augmenting': 3,
    'augsburg': 2,
    'augsburger': 3,
    'augspurger': 3,
    'augur': 2,
    'auguring': 3,
    'augurs': 2,
    'august': 2,
    'augusta': 3,
    'auguste': 2,
    'augustin': 3,
    'augustine': 3,
    'augustinian': 5,
    'augustinians': 5,
    'augusto': 3,
    'augustson': 3,
    'augustus': 3,
    'augustyn': 3,
    'augustyniak': 5,
    'auker': 2,
    'aukerman': 3,
    'aul': 1,
    'aulana': 3,
    'aulander': 3,
    'auld': 1,
    'aulds': 1,
    'auletta': 3,
    'aull': 1,
    'ault': 1,
    'aultman': 2,
    'auman': 2,
    'aumann': 2,
    'aument': 2,
    'aumiller': 3,
    'aune': 1,
    'aung': 1,
    'aungst': 1,
    'aunt': 1,
    'auntie': 2,
    'aunts': 1,
    'aupperle': 3,
    'aura': 2,
    'aural': 2,
    'aurand': 2,
    'aurea': 3,
    'aurelio': 4,
    'aureole': 3,
    'auria': 3,
    'aurich': 2,
    'auriemma': 3,
    'aurora': 3,
    'auroral': 3,
    'aurum': 2,
    'aus': 1,
    'ausbrooks': 2,
    'ausburn': 2,
    'auschwitz': 2,
    'ausherman': 3,
    'ausimont': 3,
    'auslander': 3,
    'ausley': 2,
    'ausman': 2,
    'ausmus': 2,
    'auspey': 2,
    'auspice': 2,
    'auspices': 3,
    'auspicious': 3,
    'aussie': 2,
    'aust': 1,
    'austad': 2,
    'austat': 2,
    'austell': 2,
    'austen': 2,
    'auster': 2,
    'austere': 2,
    'austerely': 3,
    'austerity': 4,
    'austill': 2,
    'austin': 2,
    'austine': 2,
    'auston': 2,
    'austral': 2,
    'australasia': 4,
    'australia': 3,
    'australian': 3,
    'australians': 3,
    'australopithecine': 6,
    'australopithecus': 6,
    'australs': 2,
    'austrasia': 3,
    'austria': 3,
    'austrian': 3,
    'austrians': 3,
    'austro': 2,
    'austroasiatic': 6,
    'austron': 2,
    'auten': 2,
    'autenrieth': 4,
    'autery': 3,
    'auth': 1,
    'authement': 2,
    'authentic': 3,
    'authentically': 4,
    'authenticate': 4,
    'authenticated': 5,
    'authenticating': 5,
    'authentication': 5,
    'authentications': 5,
    'authenticity': 5,
    'auther': 2,
    'authier': 3,
    'author': 2,
    'authored': 2,
    'authoring': 3,
    'authoritarian': 6,
    'authoritarianism': 8,
    'authoritative': 5,
    'authoritatively': 6,
    'authorities': 4,
    'authority': 4,
    'authorization': 5,
    'authorizations': 5,
    'authorize': 3,
    'authorized': 3,
    'authorizes': 4,
    'authorizing': 4,
    'authors': 2,
    'authorship': 3,
    'autism': 3,
    'autistic': 3,
    'auto': 2,
    'autobahn': 3,
    'autobiographer': 6,
    'autobiographical': 7,
    'autobiographies': 6,
    'autobiography': 6,
    'autobon': 3,
    'autoclave': 3,
    'autocracy': 4,
    'autocrat': 3,
    'autocratic': 4,
    'autodesk': 3,
    'autodie': 3,
    'autoeurope': 4,
    'autofact': 3,
    'autofacts': 3,
    'autograph': 3,
    'autographed': 3,
    'autographs': 3,
    'autoimmune': 3,
    'autolatina': 5,
    'automaker': 4,
    'automakers': 4,
    'automap': 3,
    'automata': 4,
    'automate': 3,
    'automated': 4,
    'automates': 3,
    'automatic': 4,
    'automatically': 5,
    'automatics': 4,
    'automating': 4,
    'automation': 4,
    'automatix': 4,
    'automaton': 4,
    'automobile': 4,
    'automobiles': 4,
    'automobili': 5,
    'automotive': 4,
    'automoviles': 5,
    'auton': 2,
    'autonomic': 4,
    'autonomous': 4,
    'autonomously': 5,
    'autonomy': 4,
    'autopacific': 5,
    'autopart': 3,
    'autoparts': 3,
    'autopilot': 4,
    'autopsied': 3,
    'autopsies': 3,
    'autopsy': 3,
    'autorad': 3,
    'autoradiograph': 6,
    'autorads': 3,
    'autos': 2,
    'autospa': 3,
    'autotote': 3,
    'autotrophic': 4,
    'autoworker': 4,
    'autoworkers': 4,
    'autrey': 2,
    'autry': 2,
    'autumn': 2,
    'autumnal': 3,
    'auvil': 2,
    'aux': 1,
    'auxier': 3,
    'auxiliary': 4,
    'auxton': 2,
    'auyeung': 2,
    'auzenne': 2,
    'ava': 2,
    'avail': 2,
    'availabilities': 6,
    'availability': 6,
    'available': 4,
    'availed': 2,
    'avakian': 4,
    'avalanche': 3,
    'avalanches': 4,
    'avallone': 3,
    'avalon': 3,
    'avalos': 3,
    'avana': 3,
    'avanex': 3,
    'avant': 2,
    'avantek': 3,
    'avanti': 3,
    'avants': 2,
    'avarice': 3,
    'avasso': 3,
    'avatar': 3,
    'avatars': 3,
    'avco': 2,
    'avdel': 2,
    'ave': 3,
    'avedisian': 4,
    'avedon': 3,
    'avelar': 3,
    'aveline': 4,
    'avella': 3,
    'avellino': 4,
    'avemco': 3,
    'aven': 3,
    'avena': 3,
    'avenall': 3,
    'avendano': 4,
    'avenel': 3,
    'avenell': 3,
    'avenge': 2,
    'avenged': 2,
    'avenger': 3,
    'avenging': 3,
    'aveni': 3,
    'avent': 2,
    'aventura': 4,
    'avenue': 3,
    'avenues': 3,
    'aver': 2,
    'avera': 3,
    'average': 3,
    'averaged': 2,
    'averages': 3,
    'averaging': 3,
    'averbeck': 3,
    'averell': 3,
    'averett': 3,
    'averette': 3,
    'averil': 3,
    'averill': 3,
    'averitt': 3,
    'averof': 3,
    'avers': 2,
    'aversa': 3,
    'aversano': 4,
    'averse': 2,
    'aversion': 3,
    'aversions': 3,
    'aversive': 3,
    'avert': 2,
    'averted': 3,
    'averting': 3,
    'averts': 2,
    'avery': 3,
    'averyl': 3,
    'avey': 2,
    'avi': 2,
    'avia': 3,
    'aviacion': 4,
    'aviall': 3,
    'avian': 3,
    'aviano': 4,
    'aviaries': 4,
    'aviary': 4,
    'aviation': 4,
    'aviator': 4,
    'aviators': 4,
    'aviazione': 6,
    'avice': 2,
    'avid': 2,
    'avidly': 3,
    'avignon': 3,
    'avila': 3,
    'aviles': 3,
    'avilez': 3,
    'avilla': 3,
    'avina': 3,
    'avinger': 3,
    'avionic': 4,
    'avionics': 4,
    'avions': 3,
    'avis': 2,
    'avitabile': 4,
    'avital': 3,
    'avitia': 3,
    'aviv': 2,
    'avmark': 2,
    'avner': 2,
    'avnet': 2,
    'avocado': 4,
    'avocados': 4,
    'avocation': 4,
    'avocet': 3,
    'avodon': 3,
    'avoid': 2,
    'avoidable': 4,
    'avoidance': 3,
    'avoided': 3,
    'avoiding': 3,
    'avoids': 2,
    'avolio': 4,
    'avon': 2,
    'avondale': 3,
    'avow': 2,
    'avowed': 2,
    'avowedly': 4,
    'avraham': 3,
    'avram': 2,
    'avrett': 2,
    'avril': 2,
    'avrim': 2,
    'avtex': 2,
    'avtovaz': 3,
    'avuncular': 4,
    'aw': 1,
    'awacs': 2,
    'awad': 2,
    'await': 2,
    'awaited': 3,
    'awaiting': 3,
    'awaits': 2,
    'awake': 2,
    'awaken': 3,
    'awakened': 3,
    'awakening': 4,
    'awakens': 3,
    'awalt': 2,
    'awan': 2,
    'award': 2,
    'awarded': 3,
    'awarding': 3,
    'awards': 2,
    'aware': 2,
    'awareness': 3,
    'awash': 2,
    'away': 2,
    'aways': 2,
    'awb': 1,
    'awbrey': 2,
    'awe': 1,
    'awed': 1,
    'aweida': 3,
    'awesome': 2,
    'awesomely': 3,
    'awestruck': 2,
    'awful': 2,
    'awfully': 2,
    'awfulness': 3,
    'awhile': 2,
    'awkward': 2,
    'awkwardly': 3,
    'awkwardness': 3,
    'awning': 2,
    'awoke': 2,
    'awry': 2,
    'awtrey': 2,
    'ax': 1,
    'axa': 2,
    'axe': 1,
    'axed': 1,
    'axel': 2,
    'axelrad': 3,
    'axelrod': 3,
    'axelsen': 3,
    'axelson': 3,
    'axes': 2,
    'axford': 2,
    'axial': 3,
    'axid': 2,
    'axilrod': 3,
    'axiom': 3,
    'axiomatic': 5,
    'axioms': 3,
    'axis': 2,
    'axle': 2,
    'axles': 2,
    'axley': 2,
    'axline': 2,
    'axlon': 2,
    'axon': 2,
    'axons': 2,
    'axsom': 2,
    'axt': 1,
    'axtell': 2,
    'axtman': 2,
    'axton': 2,
    'ay': 1,
    'ayacucho': 4,
    'ayako': 3,
    'ayala': 3,
    'ayars': 2,
    'ayatollah': 4,
    'ayatollahs': 4,
    'aybar': 2,
    'aycock': 2,
    'aydar': 2,
    'aydelott': 3,
    'aydelotte': 3,
    'aydin': 2,
    'aydt': 1,
    'aye': 1,
    'ayende': 3,
    'ayer': 2,
    'ayers': 2,
    'ayerst': 2,
    'ayes': 1,
    'aygen': 2,
    'aykroyd': 2,
    'ayler': 2,
    'ayles': 1,
    'aylesworth': 2,
    'ayling': 2,
    'aylmer': 2,
    'aylor': 2,
    'aylsworth': 2,
    'aylward': 2,
    'aylwin': 2,
    'aylworth': 2,
    'aymond': 2,
    'ayn': 1,
    'aynes': 1,
    'ayo': 2,
    'ayodhya': 3,
    'ayon': 2,
    'ayotte': 2,
    'ayoub': 2,
    'ayr': 1,
    'ayre': 1,
    'ayres': 1,
    'ayscue': 2,
    'aytes': 1,
    'ayuso': 3,
    'ayyad': 2,
    'ayyash': 2,
    'azactam': 3,
    'azalea': 3,
    'azaleas': 3,
    'azalia': 4,
    'azar': 2,
    'azarow': 3,
    'azbell': 2,
    'azbill': 2,
    'azcarraga': 4,
    'azcona': 3,
    'azcuenaga': 4,
    'azelia': 4,
    'azerbaijan': 4,
    'azerbaijani': 5,
    'azerbaijanis': 5,
    'azeri': 3,
    'azevedo': 4,
    'azhar': 2,
    'azides': 2,
    'azidothymidine': 6,
    'azinger': 3,
    'aziz': 2,
    'azmeer': 2,
    'azoff': 2,
    'azores': 2,
    'azpurua': 4,
    'aztar': 2,
    'aztec': 2,
    'azteca': 3,
    'aztecs': 2,
    'azuka': 3,
    'azura': 3,
    'azure': 2,
    'azzara': 3,
    'azzarello': 4,
    'azzaro': 3,
    'azzato': 3,
    'azzopardi': 4,
    'b': 1,
    'baab': 1,
    'baack': 1,
    'baade': 1,
    'baalbek': 2,
    'baalbeq': 2,
    'baar': 1,
    'baars': 1,
    'baas': 1,
    'baasch': 1,
    'baatz': 1,
    'bab': 1,
    'baba': 2,
    'babangida': 4,
    'babar': 2,
    'babb': 1,
    'babbage': 2,
    'babbington': 3,
    'babbio': 3,
    'babbit': 2,
    'babbitt': 2,
    'babbitts': 2,
    'babble': 2,
    'babbled': 2,
    'babbler': 2,
    'babblers': 2,
    'babbling': 3,
    'babbs': 1,
    'babcock': 2,
    'babe': 1,
    'babel': 2,
    'baber': 2,
    'babers': 2,
    'babes': 1,
    'babette': 2,
    'babiak': 3,
    'babiarz': 3,
    'babic': 2,
    'babich': 2,
    'babies': 2,
    'babik': 2,
    'babin': 2,
    'babineau': 3,
    'babineaux': 3,
    'babington': 3,
    'babino': 3,
    'babinski': 3,
    'babish': 2,
    'babita': 3,
    'babka': 2,
    'babler': 3,
    'baboon': 2,
    'baboons': 2,
    'babs': 1,
    'babson': 2,
    'babu': 2,
    'babula': 3,
    'babushka': 3,
    'babushkas': 3,
    'baby': 2,
    'babyak': 3,
    'babyhood': 3,
    'babyish': 3,
    'babylon': 3,
    'babylonian': 5,
    'babylonians': 5,
    'babysat': 3,
    'babysit': 3,
    'babysitter': 4,
    'babysitters': 4,
    'babysitting': 4,
    'baca': 2,
    'bacall': 2,
    'bacardi': 3,
    'baccalaureate': 5,
    'baccarat': 3,
    'baccari': 3,
    'bacchanal': 3,
    'bacchanalia': 4,
    'bacchi': 2,
    'bacchus': 2,
    'bacci': 2,
    'baccus': 2,
    'bach': 1,
    'bacha': 2,
    'bachailian': 4,
    'bachand': 2,
    'bachar': 2,
    'bacharach': 3,
    'bache': 1,
    'bachelder': 3,
    'bacheller': 3,
    'bachelor': 3,
    'bachelors': 2,
    'bacher': 2,
    'bachera': 3,
    'bachert': 2,
    'bachler': 2,
    'bachman': 2,
    'bachmann': 2,
    'bachmeier': 3,
    'bachner': 2,
    'bachrach': 2,
    'bachtel': 2,
    'bachtell': 2,
    'bachus': 2,
    'bacigalupi': 5,
    'bacigalupo': 5,
    'bacik': 2,
    'bacillus': 3,
    'bacino': 3,
    'back': 1,
    'backache': 2,
    'backaches': 2,
    'backbite': 2,
    'backbiting': 3,
    'backboard': 2,
    'backboards': 2,
    'backbone': 2,
    'backbones': 2,
    'backdate': 2,
    'backdated': 3,
    'backdates': 2,
    'backdating': 3,
    'backdoor': 2,
    'backdoors': 2,
    'backdrop': 2,
    'backdrops': 2,
    'backe': 1,
    'backed': 1,
    'backend': 2,
    'backends': 2,
    'backer': 2,
    'backers': 2,
    'backes': 1,
    'backfield': 2,
    'backfields': 2,
    'backfire': 2,
    'backfired': 3,
    'backfires': 3,
    'backfiring': 4,
    'backgammon': 3,
    'background': 2,
    'backgrounds': 2,
    'backhand': 2,
    'backhanded': 3,
    'backhaus': 2,
    'backhoe': 2,
    'backhoes': 2,
    'backing': 2,
    'backlash': 2,
    'backlashes': 3,
    'backless': 2,
    'backlight': 2,
    'backlights': 2,
    'backlist': 2,
    'backlisted': 3,
    'backlog': 2,
    'backlogged': 2,
    'backlogs': 2,
    'backlund': 2,
    'backman': 2,
    'backpack': 2,
    'backpacks': 2,
    'backpedal': 3,
    'backpedaled': 3,
    'backpedaling': 4,
    'backpedals': 3,
    'backrest': 2,
    'backrests': 2,
    'backroom': 2,
    'backrooms': 2,
    'backs': 1,
    'backsaw': 2,
    'backsaws': 2,
    'backseat': 2,
    'backseats': 2,
    'backset': 2,
    'backside': 2,
    'backsides': 2,
    'backslap': 2,
    'backslapping': 3,
    'backslash': 2,
    'backslashes': 3,
    'backslide': 2,
    'backsliding': 3,
    'backspin': 2,
    'backstage': 2,
    'backstairs': 2,
    'backstitch': 2,
    'backstitches': 3,
    'backstop': 2,
    'backstroke': 2,
    'backstrom': 2,
    'backtrack': 2,
    'backtracked': 2,
    'backtracking': 3,
    'backtracks': 2,
    'backup': 2,
    'backups': 2,
    'backus': 2,
    'backward': 2,
    'backwardation': 4,
    'backwardness': 3,
    'backwards': 2,
    'backwash': 2,
    'backwater': 3,
    'backwaters': 3,
    'backwoods': 2,
    'backwoodsman': 3,
    'backyard': 2,
    'backyards': 2,
    'bacon': 2,
    'bacorn': 2,
    'bacot': 2,
    'bacote': 2,
    'bacteria': 4,
    'bacterial': 4,
    'bacteriology': 6,
    'bacterium': 4,
    'bacus': 2,
    'bad': 1,
    'bada': 2,
    'badal': 2,
    'badalamenti': 5,
    'badami': 3,
    'badavatz': 3,
    'badders': 2,
    'bade': 1,
    'badeah': 3,
    'badeau': 2,
    'badeaux': 2,
    'badelain': 3,
    'baden': 2,
    'bader': 2,
    'badertscher': 3,
    'badge': 1,
    'badger': 2,
    'badgered': 2,
    'badgering': 3,
    'badgers': 2,
    'badges': 2,
    'badgett': 2,
    'badgley': 2,
    'badia': 3,
    'badillo': 3,
    'badland': 2,
    'badlands': 2,
    'badley': 2,
    'badly': 2,
    'badman': 2,
    'badminton': 3,
    'badmintons': 3,
    'badness': 2,
    'badolato': 4,
    'badour': 2,
    'badu': 2,
    'badura': 3,
    'bady': 2,
    'bae': 1,
    'baechle': 2,
    'baecker': 2,
    'baedeker': 3,
    'baehr': 1,
    'baek': 1,
    'baer': 1,
    'baerwald': 2,
    'baeten': 2,
    'baetjer': 2,
    'baetz': 1,
    'baez': 2,
    'baeza': 2,
    'baffa': 2,
    'baffle': 2,
    'baffled': 2,
    'baffles': 2,
    'baffling': 3,
    'bafokeng': 3,
    'bag': 1,
    'bagan': 2,
    'bagby': 2,
    'bagdad': 2,
    'bagdasarian': 5,
    'bagdikian': 4,
    'bagdon': 2,
    'bagdonas': 3,
    'bagel': 2,
    'bageland': 3,
    'bagels': 2,
    'bagent': 2,
    'bagful': 2,
    'bagg': 1,
    'baggage': 2,
    'bagge': 1,
    'bagged': 1,
    'baggerly': 3,
    'baggett': 2,
    'baggie': 2,
    'bagginess': 3,
    'bagging': 2,
    'baggio': 3,
    'baggott': 2,
    'baggs': 1,
    'baggy': 2,
    'baghdad': 2,
    'baghli': 2,
    'baginski': 3,
    'bagley': 2,
    'baglio': 3,
    'bagman': 2,
    'bagnall': 2,
    'bagnato': 3,
    'bagnell': 2,
    'bagpipe': 2,
    'bagpiper': 3,
    'bagpipes': 2,
    'bags': 1,
    'bagsby': 2,
    'bagshaw': 2,
    'bagwell': 2,
    'bah': 1,
    'baham': 2,
    'bahama': 3,
    'bahamas': 3,
    'bahamian': 4,
    'bahan': 2,
    'bahe': 1,
    'bahena': 3,
    'bahia': 3,
    'bahir': 2,
    'bahl': 1,
    'bahler': 2,
    'bahls': 1,
    'bahm': 1,
    'bahman': 2,
    'bahn': 1,
    'bahner': 2,
    'bahnsen': 2,
    'bahr': 1,
    'bahrain': 2,
    'bahraini': 3,
    'bahrainian': 4,
    'bahrainis': 3,
    'bahre': 1,
    'bahrenburg': 3,
    'baht': 1,
    'baidoa': 3,
    'baidoan': 3,
    'baidoans': 3,
    'baidoas': 3,
    'baie': 2,
    'baier': 2,
    'baig': 1,
    'baikonur': 3,
    'bail': 1,
    'baila': 2,
    'bailard': 2,
    'bailby': 2,
    'baile': 1,
    'bailed': 1,
    'bailer': 2,
    'bailes': 1,
    'bailey': 2,
    'baileys': 2,
    'bailie': 2,
    'bailiff': 2,
    'bailiffs': 2,
    'bailin': 2,
    'bailing': 2,
    'bailiwick': 3,
    'baillargeon': 3,
    'baillie': 2,
    'bailly': 2,
    'bailon': 2,
    'bailor': 2,
    'bailout': 2,
    'bailouts': 2,
    'bails': 1,
    'baily': 2,
    'bailyn': 2,
    'baim': 1,
    'bain': 1,
    'bainbridge': 2,
    'baine': 1,
    'bainerd': 2,
    'baines': 1,
    'bains': 1,
    'bainter': 2,
    'bainton': 2,
    'baio': 3,
    'bair': 1,
    'baird': 1,
    'bairnco': 2,
    'baisch': 1,
    'baisden': 2,
    'baise': 1,
    'baisley': 2,
    'bait': 1,
    'baited': 2,
    'baiting': 2,
    'baits': 1,
    'baity': 2,
    'baiul': 2,
    'baize': 1,
    'baja': 2,
    'bajarin': 3,
    'bajema': 3,
    'bajorek': 3,
    'bak': 1,
    'baka': 2,
    'bakalar': 3,
    'bake': 1,
    'baked': 1,
    'bakelman': 3,
    'bakeman': 2,
    'baker': 2,
    'bakeries': 3,
    'bakers': 2,
    'bakersfield': 3,
    'bakery': 3,
    'bakes': 1,
    'bakeware': 2,
    'bakewell': 2,
    'bakey': 2,
    'baking': 2,
    'bakke': 1,
    'bakken': 2,
    'bakker': 2,
    'bakkers': 2,
    'bakley': 2,
    'bakos': 2,
    'bakowski': 3,
    'bakrewali': 4,
    'bakrie': 2,
    'bakshi': 2,
    'bakst': 1,
    'baku': 2,
    'bakula': 3,
    'bal': 1,
    'bala': 2,
    'balaban': 3,
    'balaguer': 3,
    'balakumar': 4,
    'balan': 2,
    'balance': 2,
    'balanced': 2,
    'balancer': 3,
    'balances': 3,
    'balanchine': 3,
    'balancing': 3,
    'balart': 2,
    'balas': 2,
    'balash': 2,
    'balata': 3,
    'balazs': 2,
    'balbach': 2,
    'balbi': 2,
    'balbina': 3,
    'balbo': 2,
    'balboa': 3,
    'balboni': 3,
    'balbriggan': 3,
    'balbriggans': 3,
    'balcer': 2,
    'balcerowicz': 4,
    'balcerzak': 3,
    'balch': 1,
    'balchunas': 3,
    'balcom': 2,
    'balconies': 3,
    'balcony': 3,
    'balcor': 2,
    'bald': 1,
    'baldassare': 4,
    'baldassari': 4,
    'baldassarre': 4,
    'baldauf': 2,
    'baldelli': 3,
    'baldemar': 3,
    'balder': 2,
    'balderas': 3,
    'balderdash': 3,
    'balderrama': 4,
    'balderson': 3,
    'balderston': 3,
    'baldev': 2,
    'baldez': 2,
    'baldi': 2,
    'balding': 2,
    'baldinger': 3,
    'baldini': 3,
    'baldino': 3,
    'baldly': 2,
    'baldner': 2,
    'baldness': 2,
    'baldo': 2,
    'baldock': 2,
    'baldonado': 4,
    'baldoni': 3,
    'baldor': 2,
    'baldree': 2,
    'baldric': 2,
    'baldridge': 2,
    'baldrige': 2,
    'baldry': 2,
    'balducci': 3,
    'baldur': 2,
    'baldus': 2,
    'baldwin': 2,
    'baldyga': 3,
    'bale': 1,
    'baleen': 2,
    'baleful': 2,
    'balentine': 4,
    'baler': 2,
    'bales': 1,
    'balestra': 3,
    'balestrieri': 4,
    'baley': 2,
    'balfanz': 2,
    'balfe': 1,
    'balfour': 2,
    'bali': 2,
    'balian': 3,
    'balicki': 3,
    'balik': 2,
    'balilan': 3,
    'baliles': 3,
    'balin': 2,
    'balinese': 3,
    'baling': 2,
    'balinski': 3,
    'balint': 2,
    'balis': 2,
    'balistreri': 4,
    'balk': 1,
    'balkan': 2,
    'balkanization': 5,
    'balkanize': 3,
    'balkanized': 3,
    'balkans': 2,
    'balkcom': 2,
    'balke': 1,
    'balked': 1,
    'balkin': 2,
    'balking': 2,
    'balko': 2,
    'balks': 1,
    'balky': 2,
    'ball': 1,
    'balla': 2,
    'ballad': 2,
    'ballade': 2,
    'ballads': 2,
    'balladur': 3,
    'ballance': 2,
    'ballantine': 3,
    'ballantrae': 3,
    'ballantyne': 3,
    'ballard': 2,
    'ballas': 2,
    'ballast': 2,
    'ballasts': 2,
    'ballclub': 2,
    'ballcock': 2,
    'ballcocks': 2,
    'balle': 1,
    'balled': 1,
    'ballengee': 3,
    'ballenger': 3,
    'ballentine': 4,
    'baller': 2,
    'ballerina': 4,
    'ballerinas': 4,
    'balles': 1,
    'ballester': 3,
    'ballesteros': 4,
    'ballet': 2,
    'ballets': 2,
    'balletto': 3,
    'ballew': 2,
    'ballgame': 2,
    'balli': 2,
    'balliet': 3,
    'balliett': 2,
    'ballin': 2,
    'balling': 2,
    'ballinger': 3,
    'ballistic': 3,
    'ballistics': 3,
    'balliwick': 3,
    'ballman': 2,
    'ballmer': 2,
    'ballo': 2,
    'ballon': 2,
    'balloon': 2,
    'ballooned': 2,
    'ballooning': 3,
    'balloonist': 3,
    'balloonists': 3,
    'balloons': 2,
    'ballor': 2,
    'ballot': 2,
    'balloting': 3,
    'ballots': 2,
    'ballou': 2,
    'ballow': 2,
    'ballowe': 2,
    'ballpark': 2,
    'ballparks': 2,
    'ballplayer': 3,
    'ballplayers': 3,
    'ballpoint': 2,
    'ballpoints': 2,
    'ballroom': 2,
    'ballrooms': 2,
    'balls': 1,
    'ballweg': 2,
    'ballwig': 2,
    'bally': 2,
    'ballyhoo': 3,
    'ballyhooed': 3,
    'balm': 1,
    'balmer': 2,
    'balmes': 2,
    'balmoral': 3,
    'balmorals': 3,
    'balms': 1,
    'balmy': 2,
    'balodia': 4,
    'balog': 2,
    'baloga': 3,
    'balogh': 2,
    'balon': 2,
    'baloney': 3,
    'balow': 2,
    'balsa': 2,
    'balsam': 2,
    'balsamo': 3,
    'balsbaugh': 2,
    'balser': 2,
    'balsiger': 3,
    'balsley': 2,
    'balster': 2,
    'baltazar': 3,
    'balter': 2,
    'baltes': 1,
    'balthasar': 3,
    'balthaser': 3,
    'balthazar': 3,
    'balthazor': 3,
    'balthrop': 2,
    'baltic': 2,
    'baltica': 3,
    'baltics': 2,
    'baltierra': 3,
    'baltimore': 3,
    'balto': 2,
    'baltodano': 4,
    'baltsa': 2,
    'baltz': 1,
    'baltzell': 2,
    'baltzer': 2,
    'baluchi': 3,
    'baluja': 3,
    'balukas': 3,
    'balyeat': 3,
    'balz': 1,
    'balzano': 3,
    'balzarini': 4,
    'balzer': 2,
    'bam': 1,
    'bamako': 3,
    'bamba': 2,
    'bambach': 2,
    'bambenek': 3,
    'bamber': 2,
    'bamberg': 2,
    'bamberger': 3,
    'bambi': 2,
    'bambino': 3,
    'bambinos': 3,
    'bamboo': 2,
    'bambrick': 2,
    'bamburg': 2,
    'bame': 1,
    'bamford': 2,
    'bamut': 2,
    'ban': 1,
    'banacci': 3,
    'banach': 2,
    'banal': 2,
    'banalities': 4,
    'banality': 4,
    'banamex': 3,
    'banana': 3,
    'bananas': 3,
    'banas': 2,
    'banasiak': 4,
    'banaszak': 3,
    'banbury': 3,
    'banc': 1,
    'banca': 2,
    'bancaire': 2,
    'bancario': 4,
    'banco': 2,
    'bancoklahoma': 5,
    'bancomer': 3,
    'bancor': 2,
    'bancorp': 2,
    'bancorporation': 5,
    'bancroft': 2,
    'bancserve': 2,
    'bancshares': 2,
    'banctec': 2,
    'banctexas': 3,
    'band': 1,
    'banda': 2,
    'bandag': 2,
    'bandage': 2,
    'bandaged': 2,
    'bandages': 3,
    'bandai': 2,
    'bandaid': 2,
    'bandana': 3,
    'bandanas': 3,
    'bandar': 2,
    'banded': 2,
    'bandel': 2,
    'bandemer': 3,
    'bander': 2,
    'banderas': 3,
    'bandicoot': 3,
    'bandicoots': 3,
    'bandied': 2,
    'banding': 2,
    'bandit': 2,
    'banditry': 3,
    'bandits': 2,
    'bandleader': 3,
    'bandleaders': 3,
    'bandolier': 3,
    'bandoliers': 3,
    'bandow': 2,
    'bands': 1,
    'bandshell': 2,
    'bandstand': 2,
    'bandt': 1,
    'banducci': 3,
    'bandwagon': 3,
    'bandwidth': 2,
    'bandy': 2,
    'bane': 1,
    'banegas': 3,
    'banerjee': 3,
    'banes': 1,
    'banesto': 3,
    'banet': 2,
    'baney': 2,
    'banff': 1,
    'banfield': 2,
    'banford': 2,
    'bang': 1,
    'bangala': 3,
    'bangalor': 3,
    'bangalore': 3,
    'bange': 1,
    'banged': 1,
    'bangee': 2,
    'bangemann': 3,
    'banger': 2,
    'bangers': 2,
    'bangert': 2,
    'bangerter': 3,
    'banghart': 2,
    'banging': 2,
    'bangish': 2,
    'bangkok': 2,
    'bangladesh': 3,
    'bangladeshi': 4,
    'bangladeshis': 4,
    'bangle': 2,
    'bangles': 2,
    'bango': 2,
    'bangor': 2,
    'bangs': 1,
    'banh': 1,
    'bania': 3,
    'banick': 2,
    'banik': 2,
    'banis': 2,
    'banish': 2,
    'banished': 2,
    'banishing': 3,
    'banishment': 3,
    'banister': 3,
    'banja': 2,
    'banjo': 2,
    'bank': 1,
    'bankable': 3,
    'bankamerica': 5,
    'bankatlantic': 4,
    'bankcard': 2,
    'bankcards': 2,
    'bankcorp': 2,
    'banke': 1,
    'bankeast': 2,
    'banked': 1,
    'banken': 2,
    'banker': 2,
    'bankers': 2,
    'bankert': 2,
    'bankes': 1,
    'bankey': 2,
    'bankhead': 2,
    'bankholding': 3,
    'banking': 2,
    'banknote': 2,
    'banknotes': 2,
    'banko': 2,
    'bankowski': 3,
    'bankroll': 2,
    'bankrolled': 2,
    'bankrolling': 3,
    'bankrolls': 2,
    'bankrupcty': 3,
    'bankrupt': 2,
    'bankruptcies': 3,
    'bankruptcy': 3,
    'bankrupted': 3,
    'bankrupting': 3,
    'banks': 1,
    'bankshare': 2,
    'bankshares': 2,
    'bankson': 2,
    'bankston': 2,
    'bankverein': 3,
    'bankvermont': 3,
    'bankworcester': 4,
    'bann': 1,
    'bannan': 2,
    'banned': 1,
    'banner': 2,
    'bannerman': 3,
    'banners': 2,
    'bannick': 2,
    'banning': 2,
    'bannings': 2,
    'bannister': 3,
    'bannon': 2,
    'banos': 2,
    'banoun': 2,
    'banpais': 2,
    'banponce': 2,
    'banque': 1,
    'banquet': 2,
    'banquets': 2,
    'bans': 1,
    'bansal': 2,
    'banshee': 2,
    'banta': 2,
    'bantam': 2,
    'bantams': 2,
    'banter': 2,
    'bantered': 2,
    'bantering': 3,
    'bantle': 2,
    'banton': 2,
    'bantu': 2,
    'bantz': 1,
    'banuelos': 3,
    'banville': 2,
    'banwart': 2,
    'banxquote': 2,
    'banya': 2,
    'banyaluca': 4,
    'banyan': 2,
    'banyas': 2,
    'banyu': 2,
    'banzhaf': 2,
    'bao': 1,
    'baoguang': 3,
    'bapley': 2,
    'bapna': 2,
    'baptism': 3,
    'baptismal': 3,
    'baptisms': 3,
    'baptist': 2,
    'baptista': 3,
    'baptiste': 2,
    'baptistery': 3,
    'baptists': 2,
    'baptize': 2,
    'baptized': 2,
    'bar': 1,
    'bara': 2,
    'barabar': 3,
    'barach': 2,
    'baraff': 2,
    'baragi': 3,
    'barahona': 4,
    'barajas': 3,
    'barak': 2,
    'barakat': 3,
    'baram': 2,
    'baran': 2,
    'baranek': 3,
    'baranoski': 4,
    'baranowski': 4,
    'baranski': 3,
    'barany': 3,
    'barasch': 2,
    'barash': 2,
    'barath': 2,
    'baratta': 3,
    'barb': 1,
    'barba': 2,
    'barbados': 3,
    'barbagallo': 4,
    'barbakow': 3,
    'barbanel': 3,
    'barbano': 3,
    'barbara': 3,
    'barbaree': 3,
    'barbarian': 4,
    'barbarians': 4,
    'barbaric': 3,
    'barbarino': 4,
    'barbarism': 4,
    'barbarity': 4,
    'barbaro': 3,
    'barbarous': 3,
    'barbary': 3,
    'barbash': 2,
    'barbato': 3,
    'barbe': 1,
    'barbeau': 2,
    'barbecue': 3,
    'barbecued': 3,
    'barbecueing': 4,
    'barbecues': 3,
    'barbed': 1,
    'barbee': 2,
    'barbell': 2,
    'barbella': 3,
    'barbells': 2,
    'barbels': 2,
    'barbeque': 3,
    'barbequed': 3,
    'barbequeing': 4,
    'barbeques': 3,
    'barber': 2,
    'barbera': 3,
    'barberi': 3,
    'barberio': 4,
    'barberis': 3,
    'barbero': 3,
    'barbers': 2,
    'barbershop': 3,
    'barbette': 2,
    'barbian': 3,
    'barbie': 2,
    'barbier': 3,
    'barbieri': 3,
    'barbies': 2,
    'barbin': 2,
    'barbiturate': 4,
    'barbiturates': 4,
    'barbo': 2,
    'barbone': 2,
    'barbosa': 3,
    'barbour': 2,
    'barboursville': 3,
    'barboza': 3,
    'barbra': 2,
    'barbre': 2,
    'barbs': 1,
    'barbuda': 3,
    'barby': 2,
    'barca': 2,
    'barcella': 3,
    'barcellos': 3,
    'barcelo': 3,
    'barcelona': 4,
    'barcenas': 3,
    'barch': 1,
    'barchefsky': 3,
    'barcia': 2,
    'barclay': 2,
    'barclays': 2,
    'barclift': 2,
    'barco': 2,
    'barcomb': 2,
    'barcroft': 2,
    'barcus': 2,
    'barczak': 2,
    'bard': 1,
    'bardell': 2,
    'barden': 2,
    'bardera': 3,
    'bardin': 2,
    'bardo': 2,
    'bardolf': 2,
    'bardolph': 2,
    'bardon': 2,
    'bardrick': 2,
    'bardsley': 2,
    'bardulf': 2,
    'bardulph': 2,
    'bardwell': 2,
    'bare': 1,
    'bared': 1,
    'barefield': 3,
    'barefoot': 2,
    'bareis': 2,
    'barela': 3,
    'barely': 2,
    'barenboim': 3,
    'barens': 2,
    'barentine': 4,
    'barents': 2,
    'bares': 1,
    'barest': 2,
    'barey': 2,
    'barfield': 2,
    'barfknecht': 3,
    'barfoot': 2,
    'barfuss': 2,
    'barg': 1,
    'barga': 2,
    'bargain': 2,
    'bargained': 2,
    'bargainer': 3,
    'bargainers': 3,
    'bargaining': 3,
    'bargains': 2,
    'barganier': 4,
    'bargar': 2,
    'bargas': 2,
    'barge': 1,
    'barged': 1,
    'barger': 2,
    'bargeron': 3,
    'barges': 2,
    'barging': 2,
    'bargman': 2,
    'bargmann': 2,
    'bargo': 2,
    'barham': 2,
    'barhorst': 2,
    'bari': 2,
    'baribeau': 3,
    'barich': 2,
    'baril': 2,
    'barile': 2,
    'barillari': 4,
    'barillo': 3,
    'barinco': 3,
    'baring': 2,
    'baringer': 3,
    'barings': 2,
    'baris': 2,
    'barish': 2,
    'barite': 2,
    'barites': 2,
    'baritone': 3,
    'baritones': 3,
    'barium': 3,
    'bark': 1,
    'barkai': 2,
    'barkalow': 3,
    'barkan': 2,
    'barkdoll': 2,
    'barkdull': 2,
    'barke': 1,
    'barked': 1,
    'barkeley': 2,
    'barker': 2,
    'barkers': 2,
    'barkes': 1,
    'barkett': 2,
    'barkey': 2,
    'barkhurst': 2,
    'barkin': 2,
    'barking': 2,
    'barkley': 2,
    'barklow': 2,
    'barkman': 2,
    'barko': 2,
    'barkocy': 3,
    'barkow': 2,
    'barks': 1,
    'barksdale': 2,
    'barkshire': 2,
    'barkus': 2,
    'barlage': 2,
    'barlett': 2,
    'barletta': 3,
    'barlettesville': 3,
    'barley': 2,
    'barloon': 2,
    'barlow': 2,
    'barlowe': 2,
    'barman': 2,
    'barmore': 2,
    'barn': 1,
    'barna': 2,
    'barnabas': 3,
    'barnaby': 3,
    'barnacle': 3,
    'barnacles': 3,
    'barnard': 2,
    'barnards': 2,
    'barnas': 2,
    'barndt': 1,
    'barnell': 2,
    'barner': 2,
    'barnes': 1,
    'barnet': 2,
    'barnett': 2,
    'barnette': 2,
    'barnevik': 3,
    'barney': 2,
    'barneys': 2,
    'barnfield': 2,
    'barnhard': 2,
    'barnhardt': 2,
    'barnhart': 2,
    'barnhill': 2,
    'barnhouse': 2,
    'barnick': 2,
    'barnicle': 3,
    'barnish': 2,
    'barno': 2,
    'barnowski': 3,
    'barns': 1,
    'barnstorm': 2,
    'barnstorming': 3,
    'barnum': 2,
    'barnwell': 2,
    'barny': 2,
    'barnyard': 2,
    'baro': 2,
    'baroid': 2,
    'barometer': 4,
    'barometers': 4,
    'barometric': 4,
    'baron': 2,
    'barone': 2,
    'baroness': 3,
    'baronet': 3,
    'baronets': 3,
    'baroni': 3,
    'barons': 2,
    'baroody': 3,
    'baroque': 2,
    'baros': 2,
    'barovic': 3,
    'barovsky': 3,
    'barr': 1,
    'barra': 2,
    'barrack': 2,
    'barracks': 2,
    'barraclough': 3,
    'barraco': 3,
    'barracuda': 4,
    'barradino': 4,
    'barragan': 3,
    'barrage': 2,
    'barraged': 2,
    'barrages': 3,
    'barranco': 3,
    'barras': 2,
    'barrasso': 3,
    'barratt': 2,
    'barraza': 3,
    'barre': 2,
    'barreca': 3,
    'barred': 1,
    'barreda': 3,
    'barreiro': 3,
    'barrel': 2,
    'barreled': 2,
    'barreling': 3,
    'barrell': 2,
    'barrels': 2,
    'barren': 2,
    'barrens': 2,
    'barrentine': 3,
    'barrera': 3,
    'barreras': 3,
    'barres': 1,
    'barresi': 3,
    'barret': 2,
    'barreto': 3,
    'barrett': 2,
    'barretta': 3,
    'barrette': 2,
    'barretto': 3,
    'barricada': 4,
    'barricade': 3,
    'barricaded': 4,
    'barricades': 3,
    'barrick': 2,
    'barricklow': 3,
    'barrickman': 3,
    'barrie': 2,
    'barrientes': 2,
    'barrientez': 3,
    'barrientos': 3,
    'barrier': 3,
    'barriere': 3,
    'barriers': 3,
    'barriga': 3,
    'barriger': 3,
    'barrilleaux': 3,
    'barrineau': 3,
    'barring': 2,
    'barringer': 3,
    'barrings': 2,
    'barrington': 3,
    'barrio': 3,
    'barrios': 3,
    'barris': 2,
    'barrish': 2,
    'barrister': 3,
    'barristers': 3,
    'barritt': 2,
    'barro': 2,
    'barron': 2,
    'barrone': 2,
    'barroom': 2,
    'barros': 2,
    'barroso': 3,
    'barrow': 2,
    'barrowman': 3,
    'barrows': 2,
    'barrs': 1,
    'barrus': 2,
    'barry': 2,
    'barrymore': 3,
    'bars': 1,
    'barsamian': 4,
    'barsanti': 3,
    'barsch': 1,
    'barschel': 2,
    'barsh': 1,
    'barshefsky': 3,
    'barsky': 2,
    'barsness': 2,
    'barson': 2,
    'barsotti': 3,
    'barstad': 2,
    'barstow': 2,
    'barszcz': 1,
    'bart': 1,
    'barta': 2,
    'bartak': 2,
    'bartee': 2,
    'bartek': 2,
    'bartel': 2,
    'bartell': 2,
    'bartels': 2,
    'bartelson': 3,
    'bartelt': 2,
    'barten': 2,
    'bartender': 3,
    'bartenders': 3,
    'barter': 2,
    'bartered': 2,
    'bartering': 3,
    'barth': 1,
    'bartha': 2,
    'barthel': 2,
    'barthelemy': 4,
    'barthelme': 2,
    'barthes': 1,
    'barthold': 2,
    'bartholdy': 3,
    'bartholomay': 4,
    'bartholomew': 4,
    'bartholow': 3,
    'barthram': 2,
    'bartko': 2,
    'bartkowiak': 4,
    'bartkowski': 3,
    'bartkus': 2,
    'bartl': 2,
    'bartle': 2,
    'bartlebaugh': 3,
    'bartles': 2,
    'bartleson': 3,
    'bartlestein': 3,
    'bartlesville': 3,
    'bartlett': 2,
    'bartley': 2,
    'bartling': 2,
    'bartlow': 2,
    'bartman': 2,
    'bartmess': 2,
    'bartnick': 2,
    'bartnicki': 3,
    'bartnik': 2,
    'barto': 2,
    'bartok': 2,
    'bartol': 2,
    'bartoletti': 4,
    'bartoli': 3,
    'bartolini': 4,
    'bartolo': 3,
    'bartolomei': 5,
    'bartolomeo': 5,
    'bartolucci': 4,
    'barton': 2,
    'bartone': 3,
    'bartoo': 2,
    'bartos': 2,
    'bartosch': 2,
    'bartosh': 2,
    'bartosiewicz': 4,
    'bartosik': 3,
    'bartoszek': 3,
    'bartow': 2,
    'bartram': 2,
    'barts': 1,
    'bartsch': 1,
    'bartunek': 3,
    'bartus': 2,
    'bartz': 1,
    'baruch': 2,
    'barwick': 2,
    'baryshnikov': 4,
    'barz': 1,
    'basa': 2,
    'basal': 2,
    'basaldua': 4,
    'basalt': 2,
    'basalts': 2,
    'basam': 2,
    'basara': 3,
    'basayev': 3,
    'bascay': 2,
    'basch': 1,
    'basco': 2,
    'bascom': 2,
    'basden': 2,
    'base': 1,
    'baseball': 2,
    'baseballs': 2,
    'baseboard': 2,
    'baseboards': 2,
    'based': 1,
    'basehore': 3,
    'basel': 2,
    'baseless': 2,
    'baseline': 2,
    'baselines': 2,
    'baseman': 2,
    'basement': 2,
    'basements': 2,
    'basenji': 3,
    'baser': 2,
    'bases': 2,
    'basey': 2,
    'basford': 2,
    'basgall': 2,
    'bash': 1,
    'basha': 2,
    'basham': 2,
    'bashar': 2,
    'bashaw': 2,
    'bashed': 1,
    'basher': 2,
    'bashers': 2,
    'bashes': 2,
    'bashford': 2,
    'bashful': 2,
    'bashing': 2,
    'bashir': 2,
    'bashor': 2,
    'bashore': 2,
    'basi': 2,
    'basic': 2,
    'basically': 3,
    'basich': 2,
    'basics': 2,
    'basie': 2,
    'basil': 2,
    'basile': 2,
    'basilia': 4,
    'basilica': 4,
    'basilio': 4,
    'basim': 2,
    'basin': 2,
    'basing': 2,
    'basinger': 3,
    'basins': 2,
    'basinski': 3,
    'basis': 2,
    'basista': 3,
    'basix': 2,
    'bask': 1,
    'basked': 1,
    'baskerville': 3,
    'basket': 2,
    'basketball': 3,
    'basketballs': 3,
    'basketmaker': 4,
    'basketmaking': 4,
    'basketry': 3,
    'baskets': 2,
    'baskett': 2,
    'baskette': 2,
    'baskin': 2,
    'basking': 2,
    'baskins': 2,
    'basks': 1,
    'basle': 2,
    'basler': 3,
    'basner': 2,
    'basnett': 2,
    'basnight': 2,
    'bason': 2,
    'basore': 3,
    'basque': 1,
    'basques': 1,
    'basquez': 2,
    'basra': 2,
    'bass': 1,
    'bassa': 2,
    'bassam': 2,
    'basse': 1,
    'basses': 2,
    'basset': 2,
    'bassett': 2,
    'bassette': 2,
    'bassetti': 3,
    'bassford': 2,
    'bassham': 2,
    'bassi': 2,
    'bassin': 2,
    'bassinger': 3,
    'bassir': 2,
    'bassist': 2,
    'bassists': 2,
    'bassler': 2,
    'bassman': 2,
    'basso': 2,
    'bassoon': 2,
    'bast': 1,
    'basta': 2,
    'bastarache': 3,
    'bastard': 2,
    'bastards': 2,
    'baste': 1,
    'basted': 2,
    'bastedo': 3,
    'basten': 2,
    'bastian': 2,
    'bastien': 2,
    'bastille': 2,
    'bastin': 2,
    'basting': 2,
    'bastion': 2,
    'bastions': 2,
    'bastogne': 2,
    'baston': 2,
    'bastone': 2,
    'bastow': 2,
    'bastyr': 2,
    'basu': 2,
    'basulto': 3,
    'basurto': 3,
    'baswell': 2,
    'basye': 2,
    'bat': 1,
    'bataan': 2,
    'batan': 2,
    'batat': 2,
    'batavia': 4,
    'batboy': 2,
    'batboys': 2,
    'batch': 1,
    'batchelder': 3,
    'batcheller': 3,
    'batchelor': 3,
    'batches': 2,
    'batdorf': 2,
    'bate': 1,
    'bated': 2,
    'bateman': 2,
    'bates': 1,
    'bateson': 3,
    'batesville': 2,
    'batey': 2,
    'bath': 1,
    'bathe': 1,
    'bathed': 1,
    'bather': 2,
    'bathers': 2,
    'bathes': 1,
    'bathgate': 2,
    'bathing': 2,
    'bathke': 1,
    'batholith': 3,
    'batholiths': 3,
    'bathrick': 2,
    'bathrobe': 2,
    'bathrobes': 2,
    'bathroom': 2,
    'bathrooms': 2,
    'baths': 1,
    'bathsheba': 3,
    'bathtub': 2,
    'bathtubs': 2,
    'bathurst': 2,
    'batibot': 3,
    'batie': 2,
    'batignolles': 4,
    'batik': 2,
    'batiks': 2,
    'batista': 3,
    'batiste': 3,
    'batley': 2,
    'batman': 2,
    'batogowski': 4,
    'baton': 2,
    'batons': 2,
    'bator': 2,
    'batra': 2,
    'batres': 2,
    'bats': 1,
    'batson': 2,
    'batt': 1,
    'battaglia': 4,
    'battaglini': 4,
    'battalion': 3,
    'battalions': 3,
    'batte': 1,
    'batted': 2,
    'battelle': 2,
    'batten': 2,
    'battenberg': 3,
    'battenfield': 3,
    'battening': 3,
    'batter': 2,
    'battered': 2,
    'batterer': 3,
    'batterers': 3,
    'batteries': 3,
    'battering': 3,
    'batterman': 3,
    'batters': 2,
    'battersby': 3,
    'batterson': 3,
    'battery': 3,
    'batterymarch': 4,
    'battey': 2,
    'battiato': 4,
    'batticaloa': 5,
    'battin': 2,
    'batting': 2,
    'battipaglia': 5,
    'battis': 2,
    'battison': 3,
    'battista': 3,
    'battiste': 3,
    'battisti': 3,
    'battistoni': 4,
    'battle': 2,
    'battled': 2,
    'battlefield': 3,
    'battlefields': 3,
    'battlefront': 3,
    'battleground': 3,
    'battlegrounds': 3,
    'battlement': 3,
    'battlements': 3,
    'battles': 2,
    'battleship': 3,
    'battleships': 3,
    'battling': 3,
    'batton': 2,
    'batts': 1,
    'batty': 2,
    'batuigas': 4,
    'batus': 2,
    'baty': 2,
    'batz': 1,
    'batzel': 2,
    'batzer': 2,
    'bauble': 2,
    'baubles': 2,
    'baublitz': 2,
    'bauch': 1,
    'baucom': 2,
    'baucum': 2,
    'baucus': 2,
    'baud': 1,
    'baudendistel': 4,
    'bauder': 2,
    'baudoin': 3,
    'baudouin': 3,
    'bauer': 2,
    'bauerle': 3,
    'bauerlein': 3,
    'bauermeister': 4,
    'bauernfeind': 3,
    'bauers': 2,
    'baugh': 1,
    'baughan': 2,
    'baugher': 2,
    'baughman': 2,
    'baughn': 1,
    'bauguess': 2,
    'baugus': 2,
    'bauknecht': 2,
    'bauknight': 2,
    'baum': 1,
    'bauman': 2,
    'baumann': 2,
    'baumbach': 2,
    'baumberger': 3,
    'baumeister': 3,
    'baumel': 2,
    'baumer': 2,
    'baumert': 2,
    'baumgard': 2,
    'baumgardner': 3,
    'baumgardt': 2,
    'baumgarner': 3,
    'baumgart': 2,
    'baumgarten': 3,
    'baumgartner': 3,
    'baumhardt': 2,
    'baumhauer': 3,
    'baumholder': 3,
    'baumler': 3,
    'baumstark': 2,
    'baun': 1,
    'baur': 1,
    'baus': 1,
    'bausch': 1,
    'bauserman': 3,
    'bausman': 2,
    'bautch': 1,
    'bautista': 3,
    'bautz': 1,
    'bautzer': 2,
    'bauwens': 2,
    'bauxite': 2,
    'bauza': 2,
    'bava': 2,
    'bavadra': 3,
    'bavaria': 4,
    'bavarian': 4,
    'bavaro': 3,
    'baver': 2,
    'bavis': 2,
    'bawd': 1,
    'bawden': 2,
    'bawdy': 2,
    'bawer': 2,
    'bawl': 1,
    'bawled': 1,
    'bax': 1,
    'baxa': 2,
    'baxendale': 3,
    'baxley': 2,
    'baxt': 1,
    'baxter': 2,
    'bay': 1,
    'bayanjou': 3,
    'bayar': 2,
    'bayard': 2,
    'baybank': 2,
    'baybanks': 2,
    'baydhabo': 3,
    'baye': 1,
    'bayed': 1,
    'bayer': 2,
    'bayerische': 3,
    'bayers': 2,
    'bayes': 1,
    'bayh': 1,
    'baying': 2,
    'bayle': 1,
    'baylee': 2,
    'baylen': 2,
    'bayles': 1,
    'bayless': 2,
    'bayley': 2,
    'bayliff': 2,
    'bayliner': 3,
    'baylis': 2,
    'bayliss': 2,
    'baylon': 2,
    'baylor': 2,
    'bayly': 2,
    'bayman': 2,
    'baynard': 2,
    'bayne': 1,
    'baynes': 1,
    'baynham': 2,
    'bayog': 2,
    'bayonet': 3,
    'bayonets': 3,
    'bayonne': 2,
    'bayou': 2,
    'bayous': 2,
    'bayouth': 2,
    'bayreuth': 2,
    'bays': 1,
    'bayse': 1,
    'bayside': 2,
    'baysinger': 3,
    'baytown': 2,
    'baytree': 2,
    'bayuk': 2,
    'bayul': 2,
    'bayus': 2,
    'baywatch': 2,
    'baz': 1,
    'bazaar': 2,
    'bazaars': 2,
    'bazan': 2,
    'bazar': 2,
    'baze': 1,
    'bazemore': 2,
    'bazhan': 2,
    'bazile': 2,
    'bazinet': 3,
    'bazooka': 3,
    'bazookas': 3,
    'bazzano': 3,
    'bazzell': 2,
    'bazzle': 2,
    'bbc': 3,
    'bbq': 3,
    'be': 1,
    'bea': 1,
    'beaber': 2,
    'beabout': 2,
    'beach': 1,
    'beacham': 2,
    'beachball': 2,
    'beachballs': 2,
    'beachcomber': 3,
    'beachcombers': 3,
    'beached': 1,
    'beachem': 2,
    'beacher': 2,
    'beaches': 2,
    'beachfront': 2,
    'beachfronts': 2,
    'beachhead': 2,
    'beachheads': 2,
    'beaching': 2,
    'beachler': 3,
    'beachum': 2,
    'beachy': 2,
    'beacom': 2,
    'beacon': 2,
    'beacons': 2,
    'bead': 1,
    'beaded': 2,
    'beadle': 2,
    'beadles': 2,
    'beadling': 2,
    'beads': 1,
    'beadwork': 2,
    'beady': 2,
    'beagan': 2,
    'beagen': 2,
    'beagle': 2,
    'beagles': 2,
    'beagley': 2,
    'beahan': 2,
    'beahm': 1,
    'beaird': 1,
    'beak': 1,
    'beaker': 2,
    'beakley': 2,
    'beaklike': 2,
    'beakman': 2,
    'beal': 1,
    'beale': 1,
    'bealer': 2,
    'beales': 1,
    'bealeton': 2,
    'beall': 1,
    'beals': 1,
    'beam': 1,
    'beaman': 2,
    'beamed': 1,
    'beamer': 2,
    'beaming': 2,
    'beamish': 2,
    'beamon': 2,
    'beams': 1,
    'bean': 1,
    'beanblossom': 3,
    'beane': 1,
    'beans': 1,
    'beantown': 2,
    'bear': 1,
    'beara': 2,
    'bearable': 3,
    'bearably': 3,
    'bearce': 1,
    'beard': 1,
    'bearded': 2,
    'bearden': 2,
    'beardmore': 2,
    'beards': 1,
    'beardslee': 2,
    'beardsley': 2,
    'beardstown': 2,
    'beare': 1,
    'bearer': 2,
    'bearers': 2,
    'bearfield': 2,
    'bearing': 2,
    'bearings': 2,
    'bearish': 2,
    'bearishly': 3,
    'bearishness': 3,
    'bearman': 2,
    'bears': 1,
    'bearse': 1,
    'bearss': 1,
    'bearup': 2,
    'beary': 2,
    'beas': 1,
    'beasley': 2,
    'beason': 2,
    'beast': 1,
    'beastie': 2,
    'beaston': 2,
    'beasts': 1,
    'beat': 1,
    'beata': 3,
    'beaten': 2,
    'beater': 2,
    'beaters': 2,
    'beatie': 2,
    'beatified': 4,
    'beatify': 4,
    'beating': 2,
    'beatings': 2,
    'beatle': 2,
    'beatles': 2,
    'beatnik': 2,
    'beatniks': 2,
    'beato': 3,
    'beaton': 2,
    'beatrice': 3,
    'beatrix': 2,
    'beats': 1,
    'beatson': 2,
    'beattie': 2,
    'beatty': 2,
    'beaty': 2,
    'beau': 1,
    'beaubien': 2,
    'beaucage': 2,
    'beauce': 1,
    'beauchaine': 2,
    'beauchamp': 2,
    'beauchemin': 3,
    'beauchene': 2,
    'beauchesne': 2,
    'beaudet': 2,
    'beaudette': 2,
    'beaudin': 2,
    'beaudoin': 2,
    'beaudreau': 2,
    'beaudry': 2,
    'beauford': 2,
    'beaufort': 2,
    'beaufrere': 2,
    'beaujolais': 3,
    'beaulac': 2,
    'beaulieu': 2,
    'beaumier': 3,
    'beaumont': 2,
    'beaupre': 2,
    'beauregard': 3,
    'beausoleil': 3,
    'beautician': 3,
    'beauties': 2,
    'beautiful': 3,
    'beautifullest': 4,
    'beautifully': 3,
    'beautify': 3,
    'beauty': 2,
    'beauvais': 2,
    'beaux': 1,
    'beavan': 2,
    'beaven': 2,
    'beaver': 2,
    'beavers': 2,
    'beaverson': 3,
    'beaverton': 3,
    'beavin': 2,
    'beavis': 2,
    'beazer': 2,
    'beazley': 2,
    'bebb': 1,
    'bebber': 2,
    'bebbits': 2,
    'bebe': 2,
    'bebear': 2,
    'bebeau': 2,
    'bebee': 2,
    'beber': 2,
    'bebitch': 2,
    'bebop': 2,
    'bebout': 2,
    'bec': 1,
    'becalm': 2,
    'becalmed': 2,
    'became': 2,
    'because': 2,
    'becca': 2,
    'becerra': 3,
    'becerril': 3,
    'bechard': 2,
    'becher': 2,
    'becherer': 3,
    'bechler': 2,
    'becht': 1,
    'bechtel': 2,
    'bechtelsville': 3,
    'bechthold': 2,
    'bechtle': 2,
    'bechtol': 2,
    'bechtold': 2,
    'beck': 1,
    'becka': 2,
    'beckel': 2,
    'becker': 2,
    'beckerman': 3,
    'beckers': 2,
    'beckert': 2,
    'becket': 2,
    'beckett': 2,
    'beckford': 2,
    'beckham': 2,
    'becki': 2,
    'beckie': 2,
    'beckius': 3,
    'beckler': 2,
    'beckles': 2,
    'beckley': 2,
    'becklund': 2,
    'beckman': 2,
    'beckmann': 2,
    'beckmeyer': 3,
    'becknell': 2,
    'beckner': 2,
    'beckom': 2,
    'beckon': 2,
    'beckoned': 2,
    'beckoning': 3,
    'beckons': 2,
    'becks': 1,
    'beckstead': 2,
    'beckstrand': 2,
    'beckstrom': 2,
    'beckum': 2,
    'beckwith': 2,
    'beckwitt': 2,
    'beckworth': 2,
    'becky': 2,
    'becnel': 2,
    'beco': 2,
    'become': 2,
    'becomes': 2,
    'becoming': 3,
    'becor': 2,
    'becraft': 2,
    'becton': 2,
    'becvar': 2,
    'bed': 1,
    'beda': 2,
    'bedard': 2,
    'bedbug': 2,
    'bedbugs': 2,
    'bedchamber': 3,
    'bedded': 2,
    'bedding': 2,
    'beddingfield': 3,
    'beddoe': 2,
    'beddors': 2,
    'beddow': 2,
    'bede': 1,
    'bedeck': 2,
    'bedecked': 2,
    'bedel': 2,
    'bedell': 2,
    'bedenbaugh': 3,
    'bedevil': 3,
    'bedeviled': 3,
    'bedfellow': 3,
    'bedfellows': 3,
    'bedford': 2,
    'bedgood': 2,
    'bedient': 2,
    'bedinger': 3,
    'bedingfield': 3,
    'bedke': 2,
    'bedlam': 2,
    'bedlington': 3,
    'bedminster': 3,
    'bednar': 2,
    'bednarczyk': 3,
    'bednarek': 3,
    'bednarik': 3,
    'bednarski': 3,
    'bednarz': 2,
    'bedner': 2,
    'bednorz': 2,
    'bedolla': 3,
    'bedore': 2,
    'bedouin': 3,
    'bedouins': 3,
    'bedoya': 3,
    'bedpan': 2,
    'bedpans': 2,
    'bedraggle': 3,
    'bedraggled': 3,
    'bedridden': 3,
    'bedrock': 2,
    'bedroom': 2,
    'bedrooms': 2,
    'bedrosian': 3,
    'beds': 1,
    'bedsaul': 2,
    'bedside': 2,
    'bedsole': 2,
    'bedspread': 2,
    'bedstraw': 2,
    'bedtime': 2,
    'bedwell': 2,
    'bee': 1,
    'beebe': 2,
    'beebes': 2,
    'beebower': 3,
    'beeby': 2,
    'beech': 1,
    'beecham': 2,
    'beechcraft': 2,
    'beecher': 2,
    'beechler': 2,
    'beechwood': 2,
    'beechy': 2,
    'beeck': 1,
    'beecroft': 2,
    'beede': 1,
    'beedie': 2,
    'beedle': 2,
    'beedy': 2,
    'beef': 1,
    'beefeater': 3,
    'beefed': 1,
    'beeferman': 3,
    'beefing': 2,
    'beefs': 1,
    'beefsteak': 2,
    'beefy': 2,
    'beeghly': 2,
    'beegle': 2,
    'beehive': 2,
    'beehler': 2,
    'beek': 1,
    'beekeeper': 3,
    'beekeepers': 3,
    'beekeeping': 3,
    'beeker': 2,
    'beekman': 2,
    'beeks': 1,
    'beel': 1,
    'beeler': 2,
    'beem': 1,
    'beeman': 2,
    'beemer': 2,
    'been': 1,
    'beene': 1,
    'beeney': 2,
    'beenken': 2,
    'beens': 1,
    'beep': 1,
    'beeped': 1,
    'beeper': 2,
    'beepers': 2,
    'beeping': 2,
    'beeps': 1,
    'beer': 1,
    'beerbower': 3,
    'beerman': 2,
    'beers': 1,
    'beery': 2,
    'bees': 1,
    'beese': 1,
    'beesley': 2,
    'beeson': 2,
    'beet': 1,
    'beethoven': 3,
    'beethovenian': 5,
    'beetle': 2,
    'beetlejuice': 3,
    'beetles': 2,
    'beeton': 2,
    'beets': 1,
    'beever': 2,
    'beevers': 2,
    'beezley': 2,
    'befall': 2,
    'befallen': 3,
    'befalls': 2,
    'befalto': 3,
    'befell': 2,
    'beffa': 2,
    'befit': 2,
    'befits': 2,
    'befitting': 3,
    'before': 2,
    'beforehand': 3,
    'befort': 2,
    'befriend': 2,
    'befriended': 3,
    'befriending': 3,
    'befriends': 2,
    'befuddle': 3,
    'befuddled': 3,
    'befuddles': 3,
    'beg': 1,
    'begajah': 3,
    'begala': 3,
    'began': 2,
    'begat': 2,
    'begay': 2,
    'begel': 2,
    'begelman': 3,
    'begeman': 2,
    'beger': 2,
    'beget': 2,
    'begets': 2,
    'begetting': 3,
    'begg': 1,
    'beggar': 2,
    'beggars': 2,
    'begged': 1,
    'begging': 2,
    'beggs': 1,
    'beghin': 2,
    'begin': 2,
    'beginner': 3,
    'beginners': 3,
    'beginning': 3,
    'beginnings': 3,
    'begins': 2,
    'begleiter': 3,
    'begley': 2,
    'begnaud': 2,
    'begnoche': 3,
    'begonia': 3,
    'begonias': 3,
    'begot': 2,
    'begotten': 3,
    'begrudge': 2,
    'begs': 1,
    'begue': 1,
    'beguelin': 3,
    'beguile': 2,
    'beguiled': 2,
    'beguiling': 3,
    'begun': 2,
    'behalf': 2,
    'behan': 2,
    'behar': 2,
    'behave': 2,
    'behaved': 2,
    'behaves': 2,
    'behaving': 3,
    'behavior': 3,
    'behavioral': 4,
    'behaviorally': 5,
    'behaviorist': 4,
    'behaviorists': 4,
    'behaviors': 3,
    'behead': 2,
    'beheaded': 3,
    'beheading': 3,
    'beheadings': 3,
    'beheler': 3,
    'behemoth': 3,
    'behemoths': 3,
    'behest': 2,
    'behind': 2,
    'behinds': 2,
    'behl': 1,
    'behle': 2,
    'behler': 2,
    'behling': 2,
    'behlke': 1,
    'behm': 1,
    'behmer': 2,
    'behn': 1,
    'behne': 1,
    'behner': 2,
    'behney': 2,
    'behning': 2,
    'behnke': 1,
    'behnken': 2,
    'behof': 2,
    'behoff': 2,
    'behold': 2,
    'beholden': 3,
    'beholder': 3,
    'beholding': 3,
    'behoove': 2,
    'behooves': 2,
    'behr': 1,
    'behrend': 2,
    'behrends': 2,
    'behrendt': 2,
    'behrens': 2,
    'behring': 2,
    'behringer': 3,
    'behringwerke': 3,
    'behrle': 2,
    'behrman': 2,
    'behrmann': 2,
    'behrns': 1,
    'behunin': 3,
    'behymer': 3,
    'beidaihe': 3,
    'beidleman': 3,
    'beidler': 3,
    'beier': 2,
    'beierle': 3,
    'beiersdorf': 2,
    'beige': 1,
    'beigel': 2,
    'beiges': 2,
    'beighley': 2,
    'beightol': 2,
    'beijer': 1,
    'beijing': 2,
    'beil': 1,
    'beilenson': 3,
    'beiler': 2,
    'beilfuss': 2,
    'beilin': 2,
    'beilke': 1,
    'beilman': 2,
    'beim': 1,
    'bein': 1,
    'beine': 1,
    'being': 2,
    'beings': 2,
    'beiny': 2,
    'beira': 2,
    'beirne': 1,
    'beirut': 2,
    'beisel': 2,
    'beiser': 2,
    'beisner': 2,
    'beissel': 2,
    'beiswenger': 3,
    'beit': 1,
    'beitel': 2,
    'beiter': 2,
    'beitler': 3,
    'beitz': 1,
    'beitzel': 2,
    'bejar': 2,
    'bejarano': 4,
    'bekaa': 2,
    'bekaert': 2,
    'beker': 2,
    'bekins': 2,
    'bekker': 2,
    'bekki': 2,
    'bel': 1,
    'bela': 2,
    'belabor': 3,
    'belabored': 3,
    'belabores': 3,
    'belaboring': 4,
    'belabors': 3,
    'beladur': 3,
    'belafonte': 4,
    'belair': 2,
    'belaire': 2,
    'belak': 2,
    'beland': 2,
    'belanger': 3,
    'belanoff': 3,
    'belarus': 3,
    'belasco': 3,
    'belate': 2,
    'belated': 3,
    'belatedly': 4,
    'belcastro': 3,
    'belch': 1,
    'belched': 1,
    'belcher': 2,
    'belching': 2,
    'belcourt': 2,
    'belden': 2,
    'beldin': 2,
    'belding': 2,
    'beldock': 2,
    'beldon': 2,
    'beleaguer': 3,
    'beleaguered': 3,
    'beleaguering': 4,
    'belefiville': 4,
    'belen': 2,
    'belet': 2,
    'belew': 2,
    'belfast': 2,
    'belfer': 2,
    'belfield': 2,
    'belfiore': 4,
    'belflower': 3,
    'belford': 2,
    'belfry': 2,
    'belgacom': 3,
    'belgard': 2,
    'belgarde': 3,
    'belge': 2,
    'belger': 2,
    'belgian': 2,
    'belgians': 2,
    'belgique': 2,
    'belgium': 2,
    'belgo': 2,
    'belgrade': 2,
    'belgrave': 2,
    'beli': 2,
    'belich': 2,
    'belie': 2,
    'belied': 2,
    'belief': 2,
    'beliefs': 2,
    'belier': 2,
    'belies': 2,
    'believability': 6,
    'believable': 4,
    'believe': 2,
    'believed': 2,
    'believer': 3,
    'believers': 3,
    'believes': 2,
    'believing': 3,
    'beligerence': 4,
    'beligerent': 4,
    'belin': 2,
    'belinda': 3,
    'belinsky': 3,
    'belisle': 3,
    'belittle': 3,
    'belittled': 3,
    'belittles': 3,
    'belittling': 4,
    'belitz': 2,
    'beliveau': 3,
    'belize': 2,
    'belk': 1,
    'belka': 2,
    'belkacem': 3,
    'belke': 1,
    'belkin': 2,
    'belknap': 2,
    'belko': 2,
    'bell': 1,
    'bella': 2,
    'bellah': 2,
    'bellamy': 3,
    'bellanca': 3,
    'belland': 2,
    'bellanger': 3,
    'bellante': 3,
    'bellantoni': 4,
    'bellar': 2,
    'bellard': 2,
    'bellas': 2,
    'bellavance': 3,
    'bellavia': 4,
    'bellavista': 4,
    'bellcore': 2,
    'belle': 1,
    'belleau': 2,
    'bellefeuille': 4,
    'bellemare': 3,
    'beller': 2,
    'bellerose': 3,
    'belles': 1,
    'belleville': 2,
    'bellevue': 2,
    'bellew': 2,
    'bellflower': 3,
    'bellflowers': 3,
    'bellhop': 2,
    'bellhops': 2,
    'belli': 2,
    'bellicose': 3,
    'bellied': 2,
    'bellies': 2,
    'belligerence': 4,
    'belligerent': 4,
    'belligerents': 4,
    'bellin': 2,
    'bellina': 3,
    'belling': 2,
    'bellinger': 3,
    'bellingham': 3,
    'bellinghausen': 4,
    'bellini': 3,
    'bellino': 3,
    'bellis': 2,
    'bellissimo': 4,
    'belliveau': 3,
    'bellizzi': 3,
    'bellm': 1,
    'bellman': 2,
    'bellmon': 2,
    'bellmore': 2,
    'bello': 2,
    'bellomo': 3,
    'bellomy': 3,
    'bellon': 2,
    'bellone': 3,
    'bellotti': 3,
    'bellow': 2,
    'bellowed': 2,
    'bellowing': 3,
    'bellows': 2,
    'bellrose': 2,
    'bells': 1,
    'bellsouth': 2,
    'bellucci': 3,
    'bellum': 2,
    'belluomini': 4,
    'bellville': 2,
    'bellwether': 3,
    'bellwethers': 3,
    'bellwood': 2,
    'belly': 2,
    'bellyache': 3,
    'belman': 2,
    'belmont': 2,
    'belmonte': 3,
    'belmore': 2,
    'belnap': 2,
    'belnick': 2,
    'belo': 2,
    'beloff': 2,
    'beloit': 2,
    'belong': 2,
    'belonged': 2,
    'belongia': 3,
    'belonging': 3,
    'belongings': 3,
    'belongs': 2,
    'belote': 3,
    'belous': 2,
    'belov': 2,
    'belove': 2,
    'beloved': 2,
    'below': 2,
    'bels': 1,
    'belser': 2,
    'belshaw': 2,
    'belshe': 1,
    'belsito': 3,
    'belsky': 2,
    'belson': 2,
    'belt': 1,
    'belted': 2,
    'belter': 2,
    'belth': 1,
    'belting': 2,
    'belton': 2,
    'beltram': 2,
    'beltran': 2,
    'belts': 1,
    'beltsville': 2,
    'beltway': 2,
    'beltz': 1,
    'belue': 2,
    'beluga': 3,
    'belushi': 3,
    'belva': 2,
    'belveal': 2,
    'belvedere': 3,
    'belvia': 3,
    'belvidere': 3,
    'belville': 2,
    'belvin': 2,
    'belvoir': 2,
    'bely': 2,
    'belyea': 3,
    'belyeu': 3,
    'belying': 3,
    'belz': 1,
    'belzberg': 2,
    'belzbergs': 2,
    'belzer': 2,
    'bem': 1,
    'beman': 2,
    'bembenek': 3,
    'bembry': 2,
    'bement': 2,
    'bemidji': 3,
    'bemis': 2,
    'bemiss': 2,
    'bemoan': 2,
    'bemoaned': 2,
    'bemoaning': 3,
    'bemoans': 2,
    'bemuse': 2,
    'bemused': 2,
    'bemusement': 3,
    'ben': 1,
    'bena': 2,
    'benackova': 4,
    'benak': 2,
    'benami': 3,
    'benanty': 3,
    'benard': 2,
    'benassi': 3,
    'benasuli': 4,
    'benatar': 3,
    'benavente': 4,
    'benavides': 4,
    'benavidez': 4,
    'benazir': 3,
    'benbow': 2,
    'benbrook': 2,
    'bence': 1,
    'bench': 1,
    'bencher': 2,
    'benchers': 2,
    'benches': 2,
    'benchley': 2,
    'benchmark': 2,
    'benchmarks': 2,
    'bencivenga': 4,
    'bencomo': 3,
    'bencsik': 2,
    'bend': 1,
    'benda': 2,
    'bendall': 2,
    'bendectin': 3,
    'bended': 2,
    'bendel': 2,
    'bendele': 2,
    'bender': 2,
    'benders': 2,
    'bendick': 2,
    'bendickson': 3,
    'bendig': 2,
    'bending': 2,
    'bendix': 2,
    'bendixen': 3,
    'bendler': 2,
    'bendorf': 2,
    'bends': 1,
    'bendt': 1,
    'bendure': 3,
    'bene': 2,
    'beneath': 2,
    'benecke': 2,
    'benedek': 3,
    'benedetti': 4,
    'benedetto': 4,
    'benedick': 3,
    'benedict': 3,
    'benedicta': 4,
    'benedictine': 4,
    'benedictines': 4,
    'benediction': 4,
    'benedikt': 3,
    'benedix': 3,
    'benefactor': 4,
    'benefactors': 4,
    'beneficence': 4,
    'beneficent': 4,
    'beneficial': 4,
    'beneficially': 5,
    'beneficiaries': 6,
    'beneficiary': 6,
    'benefiel': 3,
    'benefield': 3,
    'benefit': 3,
    'benefited': 4,
    'benefiting': 4,
    'benefits': 3,
    'benefitted': 4,
    'benefitting': 4,
    'beneke': 2,
    'benel': 2,
    'benelux': 3,
    'benenati': 4,
    'benequity': 4,
    'benes': 2,
    'benesch': 2,
    'benesh': 2,
    'benet': 2,
    'benetti': 3,
    'benetton': 3,
    'benevento': 4,
    'benevides': 4,
    'benevolence': 4,
    'benevolences': 5,
    'benevolent': 4,
    'benezra': 3,
    'benfer': 2,
    'benfield': 2,
    'benford': 2,
    'bengal': 2,
    'bengali': 3,
    'bengalis': 3,
    'bengals': 2,
    'benge': 1,
    'bengel': 2,
    'bengoechea': 5,
    'bengston': 2,
    'bengt': 1,
    'bengtson': 2,
    'benguet': 2,
    'benham': 2,
    'benhamou': 3,
    'beni': 2,
    'benight': 2,
    'benighted': 3,
    'benighting': 3,
    'benights': 2,
    'benign': 2,
    'benigna': 3,
    'benignly': 3,
    'benigno': 3,
    'benihana': 4,
    'benin': 2,
    'beninati': 4,
    'benincasa': 4,
    'bening': 2,
    'benish': 2,
    'benita': 3,
    'benites': 2,
    'benitez': 3,
    'benito': 3,
    'benja': 2,
    'benjamin': 3,
    'benjimen': 3,
    'benjy': 2,
    'benke': 1,
    'benker': 2,
    'benkert': 2,
    'benko': 2,
    'benlate': 2,
    'benlox': 2,
    'benn': 1,
    'bennardo': 3,
    'benne': 1,
    'bennefield': 3,
    'benner': 2,
    'bennet': 2,
    'bennett': 2,
    'bennette': 2,
    'bennetts': 2,
    'benney': 2,
    'bennick': 2,
    'bennie': 2,
    'bennigan': 3,
    'benning': 2,
    'benninger': 3,
    'benningfield': 3,
    'benninghoff': 3,
    'bennington': 3,
    'bennink': 2,
    'bennion': 2,
    'bennis': 2,
    'bennison': 3,
    'bennitt': 2,
    'benno': 2,
    'benny': 2,
    'beno': 2,
    'benoist': 2,
    'benoit': 2,
    'benoni': 3,
    'benotti': 3,
    'benowitz': 3,
    'benoy': 2,
    'bens': 1,
    'bensalem': 3,
    'bensch': 1,
    'benscoter': 3,
    'bensel': 2,
    'bensen': 2,
    'bensenyore': 3,
    'benshoof': 2,
    'bensing': 2,
    'bensinger': 3,
    'benskin': 2,
    'bensley': 2,
    'bensman': 2,
    'benson': 2,
    'bensonhurst': 3,
    'benstock': 2,
    'bent': 1,
    'bente': 1,
    'benten': 2,
    'benter': 2,
    'benthall': 2,
    'bentivegna': 4,
    'bentler': 2,
    'bentley': 2,
    'bently': 2,
    'bento': 2,
    'benton': 2,
    'bentonite': 3,
    'bentonville': 3,
    'bentsen': 2,
    'bentson': 2,
    'bentz': 1,
    'bentzel': 2,
    'bentzen': 2,
    'benveniste': 4,
    'benvenuti': 4,
    'benvenuto': 4,
    'benware': 2,
    'benway': 2,
    'benyamin': 3,
    'benyo': 2,
    'benz': 1,
    'benzel': 2,
    'benzene': 2,
    'benzes': 2,
    'benzie': 2,
    'benziger': 3,
    'benzine': 2,
    'benzing': 2,
    'benzinger': 3,
    'benzodiazepine': 6,
    'beougher': 2,
    'beowulf': 3,
    'bequeath': 2,
    'bequeathed': 2,
    'bequest': 2,
    'bequests': 2,
    'bequette': 2,
    'bera': 2,
    'beran': 2,
    'beranek': 3,
    'berard': 2,
    'berardi': 3,
    'berardinelli': 5,
    'berardino': 4,
    'berardo': 3,
    'berarducci': 4,
    'berate': 2,
    'berated': 3,
    'berating': 3,
    'berber': 2,
    'berberian': 4,
    'berberich': 3,
    'berbick': 2,
    'bercaw': 2,
    'berch': 1,
    'berchenall': 3,
    'berchtesgaden': 4,
    'berchtold': 2,
    'bercier': 3,
    'bercor': 2,
    'berdahl': 2,
    'berdan': 2,
    'berdine': 3,
    'bere': 1,
    'berea': 3,
    'bereave': 2,
    'bereaved': 2,
    'bereavement': 3,
    'bereft': 2,
    'beregovoy': 4,
    'berend': 2,
    'berends': 2,
    'berendt': 2,
    'berendzen': 3,
    'berens': 2,
    'berenson': 3,
    'berent': 2,
    'beres': 1,
    'beresford': 2,
    'beret': 2,
    'berets': 2,
    'beretta': 3,
    'berettas': 3,
    'berezine': 3,
    'berg': 1,
    'bergamini': 4,
    'bergamo': 3,
    'bergan': 2,
    'bergdahl': 2,
    'bergdoll': 2,
    'bergdorf': 2,
    'berge': 1,
    'bergeman': 2,
    'bergemann': 2,
    'bergen': 2,
    'bergendahl': 3,
    'bergenfield': 3,
    'bergenthal': 3,
    'berger': 2,
    'bergerman': 3,
    'bergeron': 3,
    'bergerson': 3,
    'berges': 2,
    'bergeson': 3,
    'berget': 2,
    'bergevin': 3,
    'bergey': 2,
    'bergfeld': 2,
    'berggren': 2,
    'bergh': 1,
    'berghof': 2,
    'berghoff': 2,
    'berghuis': 3,
    'bergin': 2,
    'bergland': 2,
    'bergling': 2,
    'berglund': 2,
    'bergman': 2,
    'bergmann': 2,
    'bergner': 2,
    'bergquist': 2,
    'bergren': 2,
    'bergs': 1,
    'bergschneider': 3,
    'bergsma': 2,
    'bergstedt': 2,
    'bergstein': 2,
    'bergsten': 2,
    'bergstrand': 2,
    'bergstrausser': 3,
    'bergstresser': 3,
    'bergstrom': 2,
    'bergthold': 2,
    'bergum': 2,
    'berhow': 2,
    'beria': 3,
    'bering': 2,
    'beringer': 3,
    'berisford': 3,
    'berish': 2,
    'berjaya': 3,
    'berk': 1,
    'berka': 2,
    'berke': 1,
    'berkebile': 3,
    'berkel': 2,
    'berkeley': 2,
    'berkelman': 3,
    'berkemeier': 4,
    'berken': 2,
    'berkery': 3,
    'berkes': 1,
    'berkey': 2,
    'berkheimer': 3,
    'berkland': 2,
    'berklee': 2,
    'berkley': 2,
    'berkline': 2,
    'berkman': 2,
    'berko': 2,
    'berkoff': 2,
    'berkovitz': 3,
    'berkowitz': 3,
    'berkshire': 2,
    'berkshires': 2,
    'berkson': 2,
    'berkstresser': 3,
    'berlack': 2,
    'berland': 2,
    'berlanga': 3,
    'berlascone': 4,
    'berle': 1,
    'berles': 1,
    'berlet': 2,
    'berlex': 2,
    'berlin': 2,
    'berliner': 3,
    'berliners': 3,
    'berling': 2,
    'berlinger': 3,
    'berlioz': 3,
    'berlitz': 2,
    'berlottes': 2,
    'berls': 1,
    'berlusconi': 4,
    'berm': 1,
    'berman': 2,
    'bermans': 2,
    'bermea': 3,
    'bermel': 2,
    'berms': 1,
    'bermuda': 3,
    'bermudas': 3,
    'bermudes': 2,
    'bermudez': 3,
    'bern': 1,
    'berna': 2,
    'bernabe': 2,
    'bernabei': 3,
    'bernacki': 3,
    'bernadene': 3,
    'bernadette': 3,
    'bernadin': 3,
    'bernadine': 3,
    'bernadino': 4,
    'bernal': 2,
    'bernama': 3,
    'bernard': 2,
    'bernardi': 3,
    'bernardin': 3,
    'bernardini': 4,
    'bernardino': 4,
    'bernardo': 3,
    'bernards': 2,
    'bernardy': 3,
    'bernas': 2,
    'bernasconi': 4,
    'bernat': 2,
    'bernath': 2,
    'bernauer': 3,
    'bernay': 2,
    'bernbach': 2,
    'bernd': 1,
    'berndt': 1,
    'berne': 1,
    'berneice': 2,
    'berner': 2,
    'bernet': 2,
    'bernett': 2,
    'berney': 2,
    'bernhagen': 3,
    'bernhard': 2,
    'bernhardt': 2,
    'bernhart': 2,
    'bernheim': 2,
    'bernheimer': 3,
    'berni': 2,
    'bernia': 3,
    'bernice': 2,
    'bernick': 2,
    'bernie': 2,
    'bernier': 3,
    'berning': 2,
    'berninger': 3,
    'bernita': 3,
    'bernoulli': 3,
    'berns': 1,
    'bernsen': 2,
    'bernson': 2,
    'bernstein': 2,
    'bernsteins': 2,
    'bernt': 1,
    'berntsen': 2,
    'berntson': 2,
    'berny': 2,
    'bero': 2,
    'beron': 2,
    'berquist': 2,
    'berra': 2,
    'berrard': 2,
    'berres': 1,
    'berreth': 2,
    'berrett': 2,
    'berrey': 2,
    'berri': 2,
    'berrian': 3,
    'berridge': 2,
    'berrie': 2,
    'berrien': 3,
    'berrier': 3,
    'berries': 2,
    'berrigan': 3,
    'berrill': 2,
    'berringer': 3,
    'berrios': 3,
    'berris': 2,
    'berrong': 2,
    'berry': 2,
    'berryhill': 3,
    'berrylike': 3,
    'berryman': 3,
    'bersch': 1,
    'berserk': 2,
    'bershad': 2,
    'berson': 2,
    'berst': 1,
    'berstein': 2,
    'bert': 1,
    'berta': 2,
    'bertch': 1,
    'berte': 1,
    'bertelli': 3,
    'bertels': 2,
    'bertelsen': 3,
    'bertelsmann': 3,
    'bertelson': 3,
    'berth': 1,
    'bertha': 2,
    'berthelot': 3,
    'berthelsen': 3,
    'berthiaume': 4,
    'berthold': 2,
    'bertholf': 2,
    'berths': 1,
    'berti': 2,
    'bertie': 2,
    'bertil': 2,
    'bertilde': 2,
    'bertin': 2,
    'bertini': 3,
    'bertino': 3,
    'bertinotti': 4,
    'bertke': 2,
    'bertling': 3,
    'bertold': 2,
    'bertoldi': 3,
    'bertoli': 3,
    'bertolini': 4,
    'bertolino': 4,
    'bertolotti': 4,
    'bertolucci': 4,
    'berton': 2,
    'bertone': 3,
    'bertoni': 3,
    'bertrade': 2,
    'bertram': 2,
    'bertran': 2,
    'bertrand': 2,
    'bertsch': 1,
    'bertsche': 1,
    'bertucci': 3,
    'berty': 2,
    'bertz': 1,
    'berube': 2,
    'berudi': 3,
    'berumen': 3,
    'berwald': 2,
    'berwanger': 3,
    'berwick': 2,
    'berwyn': 2,
    'beryl': 2,
    'beryllium': 4,
    'berzin': 2,
    'berzins': 2,
    'bes': 1,
    'besancon': 3,
    'besant': 2,
    'besaw': 2,
    'besch': 1,
    'beschloss': 2,
    'besecker': 3,
    'beseech': 2,
    'beseeched': 2,
    'beseeching': 3,
    'beseiged': 2,
    'besemer': 3,
    'beset': 2,
    'besetting': 3,
    'beshara': 3,
    'besharov': 3,
    'beshears': 2,
    'beshloss': 2,
    'beshore': 2,
    'beside': 2,
    'besides': 2,
    'besiege': 2,
    'besieged': 2,
    'besieging': 3,
    'beske': 1,
    'besler': 3,
    'besner': 2,
    'bespeak': 2,
    'bespeaks': 2,
    'bespectacle': 4,
    'bespectacled': 4,
    'bess': 1,
    'besse': 1,
    'bessemer': 3,
    'bessent': 2,
    'besser': 2,
    'bessert': 2,
    'bessette': 2,
    'bessey': 2,
    'bessie': 2,
    'bessinger': 3,
    'bessire': 3,
    'bessler': 2,
    'besso': 2,
    'besson': 2,
    'bessy': 2,
    'best': 1,
    'beste': 1,
    'bested': 2,
    'bester': 2,
    'bestial': 2,
    'bestiality': 4,
    'besting': 2,
    'bestow': 2,
    'bestowed': 2,
    'bestowing': 3,
    'bestows': 2,
    'bestrode': 2,
    'bestseller': 3,
    'bestsellers': 3,
    'bestselling': 3,
    'bestul': 2,
    'bestwick': 2,
    'beswick': 2,
    'bet': 1,
    'beta': 2,
    'betabia': 4,
    'betake': 2,
    'betakeren': 4,
    'betamax': 3,
    'betancourt': 3,
    'betancur': 3,
    'betar': 2,
    'betas': 2,
    'betaseron': 4,
    'betavon': 3,
    'betcha': 2,
    'betcher': 2,
    'betel': 2,
    'betelgeuse': 3,
    'beteta': 3,
    'beth': 1,
    'bethany': 3,
    'bethard': 2,
    'bethards': 2,
    'bethea': 3,
    'bethel': 2,
    'bethell': 2,
    'bethesda': 3,
    'bethke': 1,
    'bethlehem': 3,
    'bethpage': 2,
    'bethune': 2,
    'betide': 2,
    'betke': 2,
    'betker': 2,
    'betley': 2,
    'betray': 2,
    'betrayal': 3,
    'betrayals': 3,
    'betrayed': 2,
    'betraying': 3,
    'betrays': 2,
    'bets': 1,
    'betsch': 1,
    'betschart': 2,
    'betsey': 2,
    'betsill': 2,
    'betsy': 2,
    'bett': 1,
    'betta': 2,
    'bettcher': 2,
    'bette': 2,
    'betten': 2,
    'bettenberg': 3,
    'bettencourt': 3,
    'bettendorf': 3,
    'bettenhausen': 4,
    'better': 2,
    'bettered': 2,
    'betteridge': 3,
    'bettering': 3,
    'betterment': 3,
    'betters': 2,
    'betterton': 3,
    'bettes': 2,
    'betti': 2,
    'bettin': 2,
    'bettina': 3,
    'betting': 2,
    'bettinger': 3,
    'bettini': 3,
    'bettino': 3,
    'bettis': 2,
    'bettman': 2,
    'bettner': 2,
    'betton': 2,
    'bettor': 2,
    'bettors': 2,
    'betts': 1,
    'betty': 2,
    'between': 2,
    'betweens': 2,
    'betwixt': 2,
    'betz': 1,
    'betzer': 2,
    'betzler': 2,
    'betzold': 2,
    'beu': 1,
    'beucler': 3,
    'beukema': 3,
    'beula': 2,
    'beulah': 2,
    'beumer': 3,
    'beury': 2,
    'beutel': 2,
    'beuthin': 2,
    'beutler': 3,
    'beuys': 2,
    'bev': 1,
    'bevacqua': 3,
    'bevalaqua': 4,
    'bevan': 2,
    'bevans': 2,
    'bevaqua': 3,
    'bevard': 2,
    'bevel': 2,
    'bevelled': 2,
    'beven': 2,
    'bevens': 2,
    'bever': 2,
    'beverage': 3,
    'beverages': 3,
    'beveridge': 3,
    'beverley': 3,
    'beverlin': 3,
    'beverly': 3,
    'bevers': 2,
    'bevier': 3,
    'bevil': 2,
    'bevilacqua': 4,
    'bevill': 2,
    'beville': 2,
    'bevin': 2,
    'bevington': 3,
    'bevins': 2,
    'bevis': 2,
    'bevmark': 2,
    'bevy': 2,
    'beware': 2,
    'bewilder': 3,
    'bewildered': 3,
    'bewildering': 4,
    'bewilderment': 4,
    'bewilders': 3,
    'bewitch': 2,
    'bewitched': 2,
    'bewley': 2,
    'bexley': 2,
    'bey': 1,
    'beyer': 2,
    'beyerle': 2,
    'beyerlein': 2,
    'beyers': 2,
    'beyersdorf': 2,
    'beyl': 1,
    'beymer': 2,
    'beynon': 2,
    'beyond': 2,
    'beytout': 2,
    'bezaire': 2,
    'bezanson': 3,
    'bezdek': 2,
    'bezek': 2,
    'bezner': 2,
    'bezold': 2,
    'bhagwan': 2,
    'bhakta': 2,
    'bhangra': 2,
    'bharata': 3,
    'bhatia': 2,
    'bhatt': 1,
    'bhattacharjy': 4,
    'bhatti': 2,
    'bhiksha': 2,
    'bhirud': 2,
    'bhopal': 2,
    'bhosle': 2,
    'bhutan': 2,
    'bhutto': 2,
    'bi': 1,
    'biaggi': 3,
    'biaggini': 4,
    'biagi': 3,
    'biagini': 4,
    'biagioni': 5,
    'bialas': 3,
    'bialecki': 4,
    'bialek': 3,
    'bialik': 3,
    'bialkin': 3,
    'bialkowski': 4,
    'bialy': 3,
    'biamby': 3,
    'biamonte': 4,
    'biana': 3,
    'bianca': 3,
    'bianchi': 3,
    'bianchini': 4,
    'bianco': 3,
    'bianconi': 4,
    'bianculli': 4,
    'biannual': 4,
    'bias': 2,
    'biased': 2,
    'biases': 3,
    'biasi': 3,
    'biaxial': 4,
    'biaz': 2,
    'bib': 1,
    'biba': 2,
    'bibb': 1,
    'bibbee': 2,
    'bibbins': 2,
    'bibbo': 2,
    'bibbs': 1,
    'bibby': 2,
    'bibeau': 2,
    'bibeault': 2,
    'bibee': 2,
    'biber': 2,
    'bibi': 2,
    'bible': 2,
    'bibler': 3,
    'bibles': 2,
    'biblical': 3,
    'bibliographies': 5,
    'bibliography': 5,
    'bibs': 1,
    'biby': 2,
    'bic': 1,
    'bicarbonate': 4,
    'bice': 1,
    'bicentenary': 5,
    'bicentennial': 5,
    'biceps': 2,
    'bichler': 3,
    'bichsel': 2,
    'bick': 1,
    'bickel': 2,
    'bickell': 2,
    'bicker': 2,
    'bickered': 2,
    'bickering': 3,
    'bickers': 2,
    'bickerstaff': 3,
    'bickert': 2,
    'bickerton': 3,
    'bickett': 2,
    'bickford': 2,
    'bickham': 2,
    'bickhart': 2,
    'bicking': 2,
    'bickle': 2,
    'bickler': 2,
    'bickley': 2,
    'bickmore': 2,
    'bicknell': 2,
    'bickner': 2,
    'bicksler': 2,
    'bickwit': 2,
    'bicoastal': 3,
    'bicuspid': 3,
    'bicuspids': 3,
    'bicycle': 3,
    'bicycled': 3,
    'bicycles': 3,
    'bicycling': 4,
    'bicyclist': 3,
    'bicyclists': 3,
    'bid': 1,
    'bidco': 2,
    'bidcos': 2,
    'biddable': 3,
    'bidden': 2,
    'bidder': 2,
    'bidders': 2,
    'biddie': 2,
    'bidding': 2,
    'biddinger': 3,
    'biddison': 3,
    'biddix': 2,
    'biddle': 2,
    'biddy': 2,
    'bide': 1,
    'biden': 2,
    'biderman': 3,
    'bidgood': 2,
    'biding': 2,
    'bidinger': 3,
    'bidlack': 2,
    'bidlo': 2,
    'bids': 1,
    'bidwell': 2,
    'bidwill': 2,
    'biebel': 2,
    'bieber': 2,
    'bieda': 2,
    'biederman': 3,
    'biedermann': 3,
    'biedermeier': 3,
    'biedrzycki': 3,
    'biegel': 2,
    'biegler': 3,
    'biehl': 1,
    'biehle': 2,
    'biehler': 2,
    'biehn': 1,
    'bieker': 2,
    'biel': 1,
    'biela': 2,
    'bielak': 2,
    'bielanski': 3,
    'bielat': 2,
    'bielawski': 3,
    'bielby': 2,
    'bielecki': 3,
    'bielefeld': 3,
    'bielefeldt': 3,
    'bielen': 2,
    'bielenberg': 3,
    'bieler': 2,
    'bielicki': 3,
    'bielinski': 3,
    'bielke': 1,
    'bielski': 2,
    'bien': 1,
    'bieniek': 3,
    'bienkowski': 3,
    'biennale': 3,
    'biennial': 4,
    'bienstock': 2,
    'bienvenu': 3,
    'bienvenue': 3,
    'bier': 1,
    'bierbarror': 3,
    'bierbauer': 3,
    'bierbaum': 2,
    'bierbusse': 2,
    'bierce': 1,
    'bierer': 2,
    'bieri': 2,
    'bierlein': 2,
    'bierley': 2,
    'bierly': 2,
    'bierma': 2,
    'bierman': 2,
    'biermann': 2,
    'biernacki': 3,
    'biernat': 2,
    'biers': 1,
    'bierwirth': 2,
    'biery': 2,
    'bies': 1,
    'biesecker': 3,
    'bieser': 2,
    'bietz': 1,
    'biev': 1,
    'biever': 2,
    'bifano': 3,
    'biff': 1,
    'biffle': 2,
    'bifida': 3,
    'bifocal': 3,
    'bifocals': 3,
    'bifulco': 3,
    'bifurcate': 3,
    'bifurcated': 4,
    'bifurcation': 4,
    'big': 1,
    'bigan': 2,
    'bigbee': 2,
    'bigbie': 2,
    'bigby': 2,
    'bigelow': 3,
    'bigeyes': 2,
    'bigfoot': 2,
    'bigford': 2,
    'biggar': 2,
    'bigger': 2,
    'biggers': 2,
    'biggerstaff': 3,
    'biggest': 2,
    'biggie': 2,
    'biggies': 2,
    'biggins': 2,
    'biggio': 3,
    'biggs': 1,
    'bigham': 2,
    'bighorn': 2,
    'bighorns': 2,
    'bight': 1,
    'bigler': 3,
    'bigley': 2,
    'biglin': 2,
    'biglow': 2,
    'bignell': 2,
    'bigness': 2,
    'bigos': 2,
    'bigot': 2,
    'bigoted': 3,
    'bigotry': 3,
    'bigots': 2,
    'bigram': 2,
    'bigs': 1,
    'bigsby': 2,
    'bigtime': 2,
    'bigwig': 2,
    'bigwigs': 2,
    'bigwood': 2,
    'bihac': 2,
    'bihari': 3,
    'bihl': 1,
    'bihm': 1,
    'bihn': 1,
    'bijac': 2,
    'bijan': 2,
    'bijeljina': 4,
    'bijur': 2,
    'bike': 1,
    'biked': 1,
    'biker': 2,
    'bikers': 2,
    'bikes': 1,
    'bikin': 2,
    'biking': 2,
    'bikini': 3,
    'bikinis': 3,
    'biko': 2,
    'bil': 1,
    'bila': 2,
    'bilateral': 4,
    'bilaterally': 5,
    'bilbao': 2,
    'bilbaoan': 3,
    'bilbaoans': 3,
    'bilbo': 2,
    'bilbrey': 2,
    'bilbro': 2,
    'bilby': 2,
    'bild': 1,
    'bilderback': 3,
    'bildner': 2,
    'bildt': 1,
    'bile': 1,
    'bilek': 2,
    'bilello': 3,
    'biles': 1,
    'bilger': 2,
    'bilicki': 3,
    'bilings': 2,
    'bilingual': 3,
    'bilingualism': 5,
    'bilinski': 3,
    'bilious': 3,
    'bilirakis': 4,
    'bilk': 1,
    'bilka': 2,
    'bilked': 1,
    'bilking': 2,
    'bilko': 2,
    'bill': 1,
    'billable': 3,
    'billancourt': 3,
    'billard': 2,
    'billboard': 2,
    'billboards': 2,
    'bille': 1,
    'billed': 1,
    'biller': 2,
    'billerica': 4,
    'billes': 1,
    'billet': 2,
    'billeter': 3,
    'billets': 2,
    'billett': 2,
    'billfold': 2,
    'billiad': 3,
    'billiard': 2,
    'billiards': 2,
    'billick': 2,
    'billie': 2,
    'billig': 2,
    'billing': 2,
    'billinger': 3,
    'billingham': 3,
    'billings': 2,
    'billingslea': 3,
    'billingsley': 3,
    'billingsly': 3,
    'billington': 3,
    'billion': 2,
    'billionaire': 3,
    'billionaires': 3,
    'billions': 2,
    'billionth': 2,
    'billionths': 2,
    'billiot': 3,
    'billips': 2,
    'billiter': 3,
    'billiton': 3,
    'billman': 2,
    'billmeyer': 3,
    'billon': 2,
    'billow': 2,
    'billowed': 2,
    'billowing': 3,
    'billows': 2,
    'bills': 1,
    'billup': 2,
    'billups': 2,
    'billy': 2,
    'bilodeau': 3,
    'bilotta': 3,
    'bilotti': 3,
    'bilow': 2,
    'biloxi': 3,
    'bilski': 2,
    'bilsky': 2,
    'bilson': 2,
    'biltmore': 2,
    'bilton': 2,
    'biltz': 1,
    'bily': 2,
    'bilyeu': 3,
    'bilyk': 2,
    'bilzerian': 4,
    'bima': 2,
    'bimbo': 2,
    'bimbos': 2,
    'bimini': 3,
    'bimiodal': 3,
    'bimonthly': 3,
    'bin': 1,
    'bina': 2,
    'binary': 3,
    'binational': 4,
    'binchy': 2,
    'bind': 1,
    'bindel': 2,
    'binder': 2,
    'binders': 2,
    'binding': 2,
    'bindle': 2,
    'bindles': 2,
    'bindley': 2,
    'binds': 1,
    'binegar': 3,
    'bines': 1,
    'binette': 2,
    'binetti': 3,
    'binfield': 2,
    'binford': 2,
    'bing': 1,
    'binga': 2,
    'bingaman': 3,
    'binge': 1,
    'binged': 1,
    'bingel': 2,
    'bingenheimer': 4,
    'binger': 2,
    'binges': 2,
    'bingham': 2,
    'binghamton': 3,
    'binging': 2,
    'bingle': 2,
    'bingley': 2,
    'bingman': 2,
    'bingo': 2,
    'binion': 2,
    'bink': 1,
    'binkley': 2,
    'binkowski': 3,
    'binn': 1,
    'binner': 2,
    'binney': 2,
    'binnie': 2,
    'binning': 2,
    'binns': 1,
    'binocular': 4,
    'binoculars': 4,
    'binomial': 4,
    'bins': 1,
    'binstock': 2,
    'bintz': 1,
    'binz': 1,
    'bio': 2,
    'biochem': 3,
    'biochemical': 5,
    'biochemist': 4,
    'biochemistry': 5,
    'biocine': 3,
    'biocontrol': 4,
    'biocraft': 3,
    'biodegradable': 6,
    'biodiverse': 4,
    'biodiversity': 6,
    'bioengineer': 5,
    'bioengineered': 5,
    'bioengineering': 6,
    'bioethics': 4,
    'biofeedback': 4,
    'bioflavonoid': 5,
    'bioflavonoids': 5,
    'biogen': 3,
    'biographer': 4,
    'biographers': 4,
    'biographical': 5,
    'biographies': 4,
    'biography': 4,
    'biohazard': 4,
    'biohazards': 4,
    'biologic': 4,
    'biological': 5,
    'biologically': 5,
    'biologicals': 5,
    'biologics': 4,
    'biologist': 4,
    'biologists': 4,
    'biology': 4,
    'biomass': 3,
    'biomaterial': 6,
    'biomaterials': 6,
    'biome': 2,
    'biomed': 3,
    'biomedical': 5,
    'biomedicals': 5,
    'biomes': 2,
    'biomet': 3,
    'biondi': 3,
    'biondo': 3,
    'biondolillo': 5,
    'bionetic': 4,
    'bionetics': 4,
    'biopharm': 3,
    'biopharmaceutical': 7,
    'biopharmacy': 5,
    'biophysics': 4,
    'biopsies': 3,
    'biopsy': 3,
    'bios': 2,
    'biosafety': 4,
    'bioscience': 4,
    'biosciences': 5,
    'biosensor': 4,
    'biosensors': 4,
    'biosis': 3,
    'biosphere': 3,
    'biospheres': 3,
    'biospherian': 5,
    'biospherians': 5,
    'biosys': 3,
    'biosystem': 4,
    'biosystems': 4,
    'biotech': 3,
    'biotechnica': 5,
    'biotechnological': 7,
    'biotechnologies': 6,
    'biotechnology': 6,
    'biotechs': 3,
    'biotherapeutic': 6,
    'biotherapeutics': 6,
    'biotin': 3,
    'biotite': 3,
    'biovest': 3,
    'bip': 1,
    'bipartisan': 4,
    'bipartisanship': 5,
    'biphenyl': 3,
    'biphenyls': 3,
    'biplane': 2,
    'bipolar': 3,
    'bippus': 2,
    'bir': 1,
    'biracial': 3,
    'birch': 1,
    'birchall': 2,
    'birchard': 2,
    'bircher': 2,
    'birchett': 2,
    'birchfield': 2,
    'birchler': 3,
    'birchmeier': 3,
    'birckhead': 2,
    'bird': 1,
    'birdbrain': 2,
    'birdcage': 2,
    'birddog': 2,
    'birden': 2,
    'birder': 2,
    'birders': 2,
    'birdfeather': 3,
    'birdfeathers': 3,
    'birdfeeder': 3,
    'birdfeeders': 3,
    'birdfinder': 3,
    'birdhouse': 2,
    'birdhouses': 3,
    'birdie': 2,
    'birdied': 2,
    'birdies': 2,
    'birdlife': 2,
    'birdlike': 2,
    'birdman': 2,
    'birds': 1,
    'birdsall': 2,
    'birdseed': 2,
    'birdsell': 2,
    'birdseye': 2,
    'birdsong': 2,
    'birdwatcher': 3,
    'birdwatchers': 3,
    'birdwatching': 3,
    'birdwell': 2,
    'birdy': 2,
    'bireme': 2,
    'biremes': 2,
    'birenbaum': 3,
    'bires': 1,
    'birge': 1,
    'birinyi': 3,
    'birk': 1,
    'birkedal': 3,
    'birkel': 2,
    'birkeland': 2,
    'birkenau': 3,
    'birkes': 1,
    'birkett': 2,
    'birkey': 2,
    'birkhead': 2,
    'birkhimer': 3,
    'birkholz': 2,
    'birkland': 2,
    'birkner': 2,
    'birks': 1,
    'birky': 2,
    'birle': 1,
    'birley': 2,
    'birman': 2,
    'birmid': 2,
    'birmingham': 3,
    'birnbaum': 2,
    'birney': 2,
    'birnie': 2,
    'biro': 2,
    'biron': 2,
    'biros': 2,
    'birr': 1,
    'birren': 2,
    'birt': 1,
    'birtcher': 2,
    'birth': 1,
    'birthday': 2,
    'birthdays': 2,
    'birthing': 2,
    'birthmark': 2,
    'birthmarks': 2,
    'birthplace': 2,
    'birthplaces': 3,
    'birthrate': 2,
    'birthrates': 2,
    'birthright': 2,
    'birthrights': 2,
    'births': 1,
    'birtle': 2,
    'birtley': 2,
    'bis': 1,
    'bisaillon': 3,
    'bisbee': 2,
    'bisbing': 2,
    'biscardi': 3,
    'biscayne': 2,
    'bisceglia': 4,
    'bisch': 1,
    'bischel': 2,
    'bischof': 2,
    'bischofberger': 4,
    'bischoff': 2,
    'biscoe': 2,
    'biscotti': 3,
    'biscuit': 2,
    'biscuits': 2,
    'bise': 1,
    'bisek': 2,
    'bisel': 2,
    'biser': 2,
    'bisesi': 3,
    'bisexual': 4,
    'bisexuality': 6,
    'bisexuals': 4,
    'bish': 1,
    'bisher': 2,
    'bishoff': 2,
    'bishop': 2,
    'bishoprics': 3,
    'bishops': 2,
    'bishopsgate': 3,
    'bisiani': 4,
    'bisig': 2,
    'bisignano': 4,
    'biskup': 2,
    'bismarck': 2,
    'bismark': 2,
    'bismuth': 2,
    'bison': 2,
    'bisping': 2,
    'bisque': 1,
    'biss': 1,
    'bissell': 2,
    'bissen': 2,
    'bisset': 2,
    'bissett': 2,
    'bissette': 2,
    'bissey': 2,
    'bissinger': 3,
    'bisso': 2,
    'bisson': 2,
    'bissonette': 3,
    'bissonnette': 3,
    'bistline': 2,
    'bistodeau': 3,
    'bistro': 2,
    'bisuteki': 4,
    'bit': 1,
    'bitar': 2,
    'bitch': 1,
    'bitches': 2,
    'bitchiness': 3,
    'bitchy': 2,
    'bite': 1,
    'biter': 2,
    'bites': 1,
    'bitesize': 2,
    'bither': 2,
    'biting': 2,
    'bitler': 3,
    'bitner': 2,
    'bitney': 2,
    'bits': 1,
    'bitsy': 2,
    'bittel': 2,
    'bitten': 2,
    'bittenbender': 4,
    'bitter': 2,
    'bitterest': 3,
    'bitterly': 3,
    'bitterman': 3,
    'bittermann': 3,
    'bitterness': 3,
    'bitterroot': 3,
    'bitters': 2,
    'bittersweet': 3,
    'bittick': 2,
    'bitting': 2,
    'bittinger': 3,
    'bittle': 2,
    'bittman': 2,
    'bittner': 2,
    'bitton': 2,
    'bitty': 2,
    'bitumen': 3,
    'bituminous': 4,
    'bitz': 1,
    'bitzer': 2,
    'bivalve': 2,
    'bivalves': 2,
    'biven': 2,
    'bivens': 2,
    'biviano': 4,
    'bivin': 2,
    'bivins': 2,
    'bivona': 3,
    'bivouac': 2,
    'biweekly': 3,
    'bix': 1,
    'bixby': 2,
    'bixel': 2,
    'bixler': 2,
    'biz': 1,
    'bizango': 3,
    'bizarre': 2,
    'bizet': 2,
    'bizmart': 2,
    'bizub': 2,
    'bizzaro': 3,
    'bizzell': 2,
    'bizzy': 2,
    'bjelasnica': 4,
    'bjelland': 2,
    'bjerke': 1,
    'bjoern': 1,
    'bjorge': 1,
    'bjork': 1,
    'bjorklund': 2,
    'bjorkman': 2,
    'bjorn': 1,
    'bjornstad': 2,
    'blachly': 2,
    'black': 1,
    'blackard': 2,
    'blackball': 2,
    'blackberries': 3,
    'blackberry': 3,
    'blackbird': 2,
    'blackbirds': 2,
    'blackboard': 2,
    'blackboards': 2,
    'blackburn': 2,
    'blacked': 1,
    'blacken': 2,
    'blackened': 2,
    'blackening': 3,
    'blackens': 2,
    'blacker': 2,
    'blackerby': 3,
    'blackest': 2,
    'blacketer': 3,
    'blackett': 2,
    'blackfoot': 2,
    'blackford': 2,
    'blackham': 2,
    'blackhawk': 2,
    'blackhawks': 2,
    'blackheath': 2,
    'blackhurst': 2,
    'blackie': 2,
    'blackjack': 2,
    'blackledge': 2,
    'blackley': 2,
    'blacklist': 2,
    'blacklisted': 3,
    'blacklisting': 3,
    'blacklock': 2,
    'blackmail': 2,
    'blackmailed': 2,
    'blackmailing': 3,
    'blackman': 2,
    'blackmer': 2,
    'blackmon': 2,
    'blackmore': 2,
    'blackmun': 2,
    'blackness': 2,
    'blackout': 2,
    'blackouts': 2,
    'blackpool': 2,
    'blackrock': 2,
    'blacks': 1,
    'blacksburg': 2,
    'blackshear': 2,
    'blacksher': 2,
    'blackshire': 2,
    'blacksmith': 2,
    'blackson': 2,
    'blackstock': 2,
    'blackston': 2,
    'blackstone': 2,
    'blacktop': 2,
    'blackwelder': 3,
    'blackwell': 2,
    'blackwood': 2,
    'bladder': 2,
    'bladders': 2,
    'blade': 1,
    'bladed': 2,
    'bladen': 2,
    'blades': 1,
    'blading': 2,
    'bladow': 2,
    'blaese': 1,
    'blaesing': 2,
    'blagden': 2,
    'blagg': 1,
    'blah': 1,
    'blaha': 2,
    'blahnik': 2,
    'blahut': 2,
    'blaich': 1,
    'blaikie': 2,
    'blain': 1,
    'blaine': 1,
    'blair': 1,
    'blais': 1,
    'blaisdell': 2,
    'blaise': 1,
    'blaiser': 2,
    'blaize': 1,
    'blake': 1,
    'blakeley': 2,
    'blakely': 2,
    'blakeman': 2,
    'blakemore': 2,
    'blakeney': 2,
    'blakenham': 3,
    'blakenship': 3,
    'blaker': 2,
    'blakes': 1,
    'blakeslee': 2,
    'blakesley': 2,
    'blakey': 2,
    'blakley': 2,
    'blakney': 2,
    'blalack': 2,
    'blalock': 2,
    'blame': 1,
    'blamed': 1,
    'blameless': 2,
    'blames': 1,
    'blaming': 2,
    'blampied': 2,
    'blan': 1,
    'blanc': 1,
    'blanca': 2,
    'blancett': 2,
    'blanch': 1,
    'blanchard': 2,
    'blanchards': 2,
    'blanche': 1,
    'blanchet': 2,
    'blanchett': 2,
    'blanchette': 2,
    'blanchfield': 2,
    'blanck': 1,
    'blanco': 2,
    'bland': 1,
    'blanda': 2,
    'blander': 2,
    'blandford': 2,
    'blandin': 2,
    'blanding': 2,
    'blandino': 3,
    'blandishment': 3,
    'blandishments': 3,
    'blandly': 2,
    'blandness': 2,
    'blando': 2,
    'blandon': 2,
    'blane': 1,
    'blaney': 2,
    'blanford': 2,
    'blank': 1,
    'blanke': 1,
    'blanked': 1,
    'blanken': 2,
    'blankenbaker': 4,
    'blankenbeckler': 4,
    'blankenburg': 3,
    'blankenhorn': 3,
    'blankenship': 3,
    'blanket': 2,
    'blanketed': 3,
    'blanketing': 3,
    'blankets': 2,
    'blanking': 2,
    'blankinship': 3,
    'blankley': 2,
    'blankly': 2,
    'blanks': 1,
    'blann': 1,
    'blanquita': 3,
    'blansett': 2,
    'blanton': 2,
    'blare': 1,
    'blared': 1,
    'blares': 1,
    'blaring': 2,
    'blas': 1,
    'blaschke': 1,
    'blasco': 2,
    'blasdel': 2,
    'blasdell': 2,
    'blase': 1,
    'blaser': 2,
    'blasi': 2,
    'blasia': 3,
    'blasier': 3,
    'blasing': 2,
    'blasingame': 4,
    'blasini': 3,
    'blasius': 3,
    'blaske': 1,
    'blasko': 2,
    'blasphemous': 3,
    'blasphemy': 3,
    'blass': 1,
    'blassie': 2,
    'blassingame': 4,
    'blast': 1,
    'blastdown': 2,
    'blasted': 2,
    'blaster': 2,
    'blastfurnace': 3,
    'blasting': 2,
    'blastoderm': 3,
    'blastoff': 2,
    'blasts': 1,
    'blaszak': 2,
    'blaszczyk': 2,
    'blatant': 2,
    'blatantly': 3,
    'blatchford': 2,
    'blatchley': 2,
    'blather': 2,
    'blatnik': 2,
    'blatt': 1,
    'blatter': 2,
    'blattner': 2,
    'blatz': 1,
    'blau': 1,
    'blauch': 1,
    'blauer': 1,
    'blaum': 1,
    'blauser': 2,
    'blaustein': 2,
    'blauvelt': 2,
    'blay': 1,
    'blaydes': 1,
    'blaydon': 2,
    'blaylock': 2,
    'blayne': 1,
    'blayney': 2,
    'blayze': 1,
    'blaze': 1,
    'blazed': 1,
    'blazejewski': 4,
    'blazek': 2,
    'blazer': 2,
    'blazers': 2,
    'blazes': 2,
    'blazier': 3,
    'blazina': 3,
    'blazing': 2,
    'blea': 1,
    'bleach': 1,
    'bleached': 1,
    'bleacher': 2,
    'bleachers': 2,
    'bleaching': 2,
    'bleak': 1,
    'bleaker': 2,
    'bleakest': 2,
    'bleakley': 2,
    'bleakness': 2,
    'bleakney': 2,
    'bleam': 1,
    'bleary': 2,
    'bleau': 1,
    'blech': 1,
    'blecha': 2,
    'blecher': 2,
    'blechley': 2,
    'blechman': 2,
    'bleck': 1,
    'blecker': 2,
    'bled': 1,
    'bledsoe': 2,
    'bleecker': 2,
    'bleed': 1,
    'bleeding': 2,
    'bleeds': 1,
    'bleeker': 2,
    'bleep': 1,
    'bleeping': 2,
    'blegen': 2,
    'blehm': 1,
    'bleich': 1,
    'bleicher': 2,
    'bleier': 2,
    'bleil': 1,
    'bleiler': 2,
    'bleilers': 2,
    'blelloch': 2,
    'blemish': 2,
    'blemished': 2,
    'blemishes': 3,
    'blend': 1,
    'blenda': 2,
    'blendax': 2,
    'blended': 2,
    'blender': 2,
    'blenders': 2,
    'blending': 2,
    'blends': 1,
    'blenheim': 2,
    'blepharisma': 4,
    'bleser': 2,
    'bless': 1,
    'blessed': 1,
    'blessedly': 3,
    'blessedness': 3,
    'blesses': 2,
    'blessing': 2,
    'blessinger': 3,
    'blessings': 2,
    'blessington': 3,
    'blest': 1,
    'blethen': 2,
    'bleu': 1,
    'blevens': 2,
    'blevins': 2,
    'blew': 1,
    'blewett': 2,
    'blewitt': 2,
    'bley': 1,
    'blick': 1,
    'blickenstaff': 3,
    'blida': 2,
    'bligh': 1,
    'blight': 1,
    'blighted': 2,
    'bliley': 2,
    'blimp': 1,
    'blimps': 1,
    'blincoe': 2,
    'blind': 1,
    'blinded': 2,
    'blinder': 2,
    'blinders': 2,
    'blindfold': 2,
    'blindfolded': 3,
    'blinding': 2,
    'blindly': 2,
    'blindness': 2,
    'blinds': 1,
    'blindside': 2,
    'blindsided': 3,
    'blink': 1,
    'blinked': 1,
    'blinking': 2,
    'blinks': 1,
    'blinn': 1,
    'blip': 1,
    'blips': 1,
    'bliscoll': 2,
    'blish': 1,
    'bliss': 1,
    'blissett': 2,
    'blissful': 2,
    'blissfully': 3,
    'blister': 2,
    'blistered': 2,
    'blistering': 3,
    'blisters': 2,
    'blitch': 1,
    'blithe': 1,
    'blithely': 2,
    'blitstein': 2,
    'blitz': 1,
    'blitzed': 1,
    'blitzen': 2,
    'blitzer': 2,
    'blitzes': 2,
    'blitzkrieg': 2,
    'bliven': 2,
    'blixt': 1,
    'blizard': 2,
    'blizzard': 2,
    'blizzards': 2,
    'bloat': 1,
    'bloated': 2,
    'bloatedness': 3,
    'bloating': 2,
    'bloats': 1,
    'blob': 1,
    'blobby': 2,
    'blobs': 1,
    'bloc': 1,
    'bloch': 1,
    'blocher': 2,
    'block': 1,
    'blockade': 2,
    'blockaded': 3,
    'blockader': 3,
    'blockaders': 3,
    'blockades': 2,
    'blockading': 3,
    'blockage': 2,
    'blockages': 3,
    'blockbuster': 3,
    'blockbusters': 3,
    'blocked': 1,
    'blocker': 2,
    'blockers': 2,
    'blockhead': 2,
    'blockheads': 2,
    'blockhouse': 2,
    'blockhouses': 3,
    'blocking': 2,
    'blocks': 1,
    'blocs': 1,
    'blodgett': 2,
    'bloedel': 2,
    'bloedorn': 2,
    'bloem': 1,
    'bloemer': 2,
    'bloemker': 2,
    'blohm': 1,
    'blok': 1,
    'bloke': 1,
    'blokes': 1,
    'blom': 1,
    'blomberg': 2,
    'blome': 1,
    'blomgren': 2,
    'blomquist': 2,
    'blomstrom': 2,
    'blond': 1,
    'blonde': 1,
    'blondell': 2,
    'blonder': 2,
    'blondes': 1,
    'blondest': 2,
    'blondie': 2,
    'blondin': 2,
    'blonds': 1,
    'blood': 1,
    'bloodbath': 2,
    'blooded': 2,
    'bloodgood': 2,
    'bloodhound': 2,
    'bloodhounds': 2,
    'bloodied': 2,
    'bloodier': 3,
    'bloodiest': 3,
    'bloodless': 2,
    'bloodletting': 3,
    'bloodline': 2,
    'bloodlines': 2,
    'bloods': 1,
    'bloodshed': 2,
    'bloodstain': 2,
    'bloodstained': 2,
    'bloodstains': 2,
    'bloodstone': 2,
    'bloodstream': 2,
    'bloodsucker': 3,
    'bloodsuckers': 3,
    'bloodsucking': 3,
    'bloodsworth': 2,
    'bloodthirsty': 3,
    'bloodworth': 2,
    'bloody': 2,
    'bloom': 1,
    'bloomberg': 2,
    'bloomed': 1,
    'bloomer': 2,
    'bloomers': 2,
    'bloomfield': 2,
    'blooming': 2,
    'bloomingdale': 3,
    'bloomingdales': 3,
    'bloomington': 3,
    'bloomquist': 2,
    'blooms': 1,
    'blooper': 2,
    'bloopers': 2,
    'bloor': 1,
    'blose': 1,
    'bloss': 1,
    'blosser': 2,
    'blossom': 2,
    'blossomed': 2,
    'blossoming': 3,
    'blossoms': 2,
    'blot': 1,
    'blotch': 1,
    'blotched': 1,
    'blotches': 2,
    'blotchier': 3,
    'blotchiest': 3,
    'blotching': 2,
    'blotchy': 2,
    'blotnick': 2,
    'blots': 1,
    'blotted': 2,
    'blotter': 2,
    'blotting': 2,
    'blouch': 1,
    'blough': 1,
    'blouin': 1,
    'blount': 1,
    'blouse': 1,
    'blouses': 2,
    'blow': 1,
    'blowdried': 2,
    'blowdries': 2,
    'blowdry': 2,
    'blowdryer': 3,
    'blowdryers': 3,
    'blowdrying': 3,
    'blowe': 1,
    'blowed': 1,
    'blower': 2,
    'blowers': 2,
    'blowfish': 2,
    'blowgun': 2,
    'blowguns': 2,
    'blowhard': 2,
    'blowhards': 2,
    'blowhole': 2,
    'blowholes': 2,
    'blowier': 3,
    'blowiest': 3,
    'blowing': 2,
    'blown': 1,
    'blowout': 2,
    'blowouts': 2,
    'blowpipe': 2,
    'blowpipes': 2,
    'blows': 1,
    'blowtorch': 2,
    'blowtorches': 3,
    'blowup': 2,
    'blowy': 2,
    'bloxham': 2,
    'bloxom': 2,
    'bloxsom': 2,
    'bloyd': 1,
    'bloyer': 2,
    'blubaugh': 2,
    'blubber': 2,
    'bludgeon': 2,
    'bludgeoned': 2,
    'bludgeoning': 3,
    'blue': 1,
    'bluebeard': 2,
    'bluebell': 2,
    'bluebells': 2,
    'blueberries': 3,
    'blueberry': 3,
    'bluebird': 2,
    'bluebirds': 2,
    'blueblood': 2,
    'blueblooded': 3,
    'bluebloods': 2,
    'bluebonnet': 3,
    'bluebonnets': 3,
    'bluebottle': 3,
    'bluebottles': 3,
    'bluechip': 2,
    'bluecollar': 3,
    'blued': 1,
    'blueeyed': 2,
    'bluefield': 2,
    'bluegrass': 2,
    'blueing': 2,
    'blueish': 2,
    'bluejay': 2,
    'bluejeans': 2,
    'bluelaw': 2,
    'bluemel': 2,
    'blueness': 2,
    'bluepencil': 3,
    'bluepencilled': 3,
    'bluepencilling': 4,
    'bluepencils': 3,
    'blueprint': 2,
    'blueprints': 2,
    'bluer': 2,
    'blues': 1,
    'bluest': 2,
    'bluestein': 2,
    'bluestine': 2,
    'bluestone': 2,
    'bluesy': 2,
    'bluett': 1,
    'bluey': 2,
    'bluff': 1,
    'bluffed': 1,
    'bluffer': 2,
    'bluffing': 2,
    'bluffs': 1,
    'bluford': 2,
    'bluhdorn': 2,
    'bluhm': 1,
    'bluing': 2,
    'bluish': 2,
    'bluitt': 1,
    'blum': 1,
    'blumberg': 2,
    'blume': 1,
    'blumenberg': 3,
    'blumenfeld': 3,
    'blumenschein': 3,
    'blumenshine': 3,
    'blumenstein': 3,
    'blumenstock': 3,
    'blumenthal': 3,
    'blumer': 2,
    'blumquist': 2,
    'blumstein': 2,
    'blunck': 1,
    'blundall': 2,
    'blundell': 2,
    'blunder': 2,
    'blundered': 2,
    'blundering': 3,
    'blunders': 2,
    'blunk': 1,
    'blunt': 1,
    'blunted': 2,
    'blunter': 2,
    'bluntest': 2,
    'blunting': 2,
    'bluntly': 2,
    'bluntness': 2,
    'blunts': 1,
    'blur': 1,
    'blurb': 1,
    'blurbs': 1,
    'blurred': 1,
    'blurring': 2,
    'blurry': 2,
    'blurs': 1,
    'blurt': 1,
    'blurted': 2,
    'blurton': 2,
    'blurts': 1,
    'blush': 1,
    'blushed': 1,
    'blushes': 2,
    'blushing': 2,
    'blust': 1,
    'bluster': 2,
    'blustering': 3,
    'blustery': 3,
    'bluth': 1,
    'blvd': 3,
    'bly': 1,
    'blye': 1,
    'blyler': 2,
    'blystone': 2,
    'blyth': 1,
    'blythe': 1,
    'bo': 1,
    'boa': 2,
    'boak': 1,
    'boake': 1,
    'boal': 1,
    'boals': 1,
    'boan': 1,
    'boar': 1,
    'board': 1,
    'boarda': 2,
    'boardbent': 2,
    'boarded': 2,
    'boarder': 2,
    'boarders': 2,
    'boardgame': 2,
    'boardgames': 2,
    'boarding': 2,
    'boardinghouse': 3,
    'boardinghouses': 4,
    'boardingpass': 3,
    'boardingpasses': 4,
    'boardings': 2,
    'boardman': 2,
    'boardroom': 2,
    'boardrooms': 2,
    'boards': 1,
    'boardwalk': 2,
    'boardwalks': 2,
    'boarman': 2,
    'boart': 1,
    'boas': 2,
    'boase': 1,
    'boast': 1,
    'boasted': 2,
    'boastful': 2,
    'boasting': 2,
    'boasts': 1,
    'boat': 1,
    'boated': 2,
    'boaters': 2,
    'boathouse': 2,
    'boating': 2,
    'boatlift': 2,
    'boatlifts': 2,
    'boatload': 2,
    'boatloads': 2,
    'boatman': 2,
    'boatner': 2,
    'boatright': 2,
    'boats': 1,
    'boatswain': 2,
    'boatwright': 2,
    'boatyard': 2,
    'boaz': 2,
    'bob': 1,
    'boback': 2,
    'bobadilla': 4,
    'bobak': 2,
    'bobb': 1,
    'bobber': 2,
    'bobbett': 2,
    'bobbette': 2,
    'bobbi': 2,
    'bobbie': 2,
    'bobbin': 2,
    'bobbing': 2,
    'bobbinger': 3,
    'bobbitt': 2,
    'bobbitts': 2,
    'bobble': 2,
    'bobbled': 2,
    'bobbles': 2,
    'bobbling': 2,
    'bobbo': 2,
    'bobby': 2,
    'bobcat': 2,
    'bobcats': 2,
    'bobe': 1,
    'bobeck': 2,
    'bobek': 2,
    'bober': 2,
    'boberg': 2,
    'bobick': 2,
    'bobier': 3,
    'bobinski': 3,
    'boblitt': 2,
    'bobo': 2,
    'bobolas': 3,
    'bobrow': 2,
    'bobrowski': 3,
    'bobsled': 2,
    'bobst': 1,
    'bobzien': 2,
    'boca': 2,
    'bocanegra': 4,
    'bocce': 2,
    'bocchino': 3,
    'boccia': 2,
    'boccio': 3,
    'boccuzzi': 3,
    'bocek': 2,
    'boch': 1,
    'bocharov': 3,
    'bochco': 2,
    'boche': 1,
    'bochenek': 3,
    'boches': 2,
    'bochicchio': 4,
    'bochner': 2,
    'bochram': 2,
    'bocian': 2,
    'bock': 1,
    'bockelman': 3,
    'bockius': 3,
    'bockman': 2,
    'bockus': 2,
    'bocock': 2,
    'bocook': 2,
    'bocuse': 2,
    'boda': 2,
    'bodamer': 3,
    'bodanis': 3,
    'bodden': 2,
    'boddie': 2,
    'boddington': 3,
    'boddy': 2,
    'bode': 1,
    'bodega': 3,
    'bodegas': 3,
    'bodeguita': 4,
    'bodell': 2,
    'boden': 2,
    'bodenhamer': 4,
    'bodenheimer': 4,
    'bodenstein': 3,
    'bodensteiner': 4,
    'bodes': 1,
    'bodey': 2,
    'bodhisattvas': 4,
    'bodi': 2,
    'bodice': 2,
    'bodices': 3,
    'bodie': 2,
    'bodied': 2,
    'bodies': 2,
    'bodikova': 4,
    'bodily': 3,
    'bodin': 2,
    'bodine': 3,
    'bodkin': 2,
    'bodkins': 2,
    'bodle': 2,
    'bodley': 2,
    'bodman': 2,
    'bodmer': 2,
    'bodnar': 2,
    'bodner': 2,
    'bodo': 2,
    'bodwell': 2,
    'body': 2,
    'bodyguard': 3,
    'bodyguards': 3,
    'bodziak': 3,
    'boe': 1,
    'boeck': 1,
    'boeckel': 2,
    'boecker': 2,
    'boeckman': 2,
    'boeckmann': 2,
    'boedecker': 3,
    'boedeker': 3,
    'boeder': 2,
    'boeding': 2,
    'boege': 1,
    'boeger': 2,
    'boeh': 1,
    'boehl': 1,
    'boehle': 2,
    'boehler': 2,
    'boehlert': 2,
    'boehlke': 1,
    'boehm': 1,
    'boehme': 1,
    'boehmer': 2,
    'boehmke': 2,
    'boehne': 1,
    'boehner': 2,
    'boehning': 2,
    'boehnke': 1,
    'boehnlein': 2,
    'boehringer': 3,
    'boeing': 2,
    'boeke': 1,
    'boeker': 2,
    'boelens': 2,
    'boelkow': 2,
    'boelman': 2,
    'boelter': 2,
    'boen': 1,
    'boenig': 2,
    'boening': 3,
    'boepple': 2,
    'boer': 1,
    'boerger': 2,
    'boerman': 2,
    'boerner': 2,
    'boers': 1,
    'boersma': 2,
    'boes': 1,
    'boesak': 2,
    'boesch': 1,
    'boeschenstein': 3,
    'boese': 1,
    'boesel': 2,
    'boesen': 2,
    'boesky': 2,
    'boeskys': 2,
    'boetcher': 2,
    'boettcher': 2,
    'boettger': 2,
    'boettner': 2,
    'boeve': 1,
    'boff': 1,
    'boffa': 2,
    'boffo': 2,
    'bofors': 2,
    'bog': 1,
    'bogacki': 3,
    'bogacz': 2,
    'bogan': 2,
    'bogar': 2,
    'bogard': 2,
    'bogardus': 3,
    'bogart': 2,
    'bogdan': 2,
    'bogdanoff': 3,
    'bogdanowicz': 4,
    'bogdanski': 3,
    'bogden': 2,
    'bogdon': 2,
    'boge': 1,
    'bogelsong': 3,
    'bogen': 2,
    'bogenschutz': 3,
    'boger': 2,
    'bogert': 2,
    'bogey': 2,
    'bogeyed': 2,
    'bogeyman': 3,
    'bogeymen': 3,
    'boggan': 2,
    'bogged': 1,
    'boggess': 2,
    'bogging': 2,
    'boggio': 3,
    'boggle': 2,
    'boggled': 2,
    'boggles': 2,
    'boggling': 3,
    'boggs': 1,
    'boggus': 2,
    'boghosian': 3,
    'bogie': 2,
    'bogin': 2,
    'bogle': 2,
    'bognar': 2,
    'bogner': 2,
    'bogor': 2,
    'bogosian': 3,
    'bogota': 3,
    'bogs': 1,
    'bogucki': 3,
    'bogue': 1,
    'bogus': 2,
    'boguslavskaya': 5,
    'bogusz': 2,
    'bohac': 2,
    'bohall': 2,
    'bohan': 2,
    'bohanan': 3,
    'bohannan': 3,
    'bohannon': 3,
    'bohanon': 3,
    'bohart': 2,
    'boheme': 3,
    'bohemia': 4,
    'bohemian': 4,
    'bohen': 2,
    'bohl': 1,
    'bohland': 2,
    'bohlander': 3,
    'bohle': 2,
    'bohlen': 2,
    'bohler': 2,
    'bohlin': 2,
    'bohling': 2,
    'bohlinger': 3,
    'bohlke': 1,
    'bohlken': 2,
    'bohlman': 2,
    'bohlmann': 2,
    'bohm': 1,
    'bohman': 2,
    'bohmer': 2,
    'bohn': 1,
    'bohne': 1,
    'bohnen': 2,
    'bohnenkamp': 3,
    'bohner': 2,
    'bohnert': 2,
    'bohnet': 2,
    'bohnhoff': 2,
    'bohning': 2,
    'bohnsack': 2,
    'bohon': 2,
    'bohr': 1,
    'bohren': 2,
    'bohrer': 2,
    'boice': 1,
    'boies': 1,
    'boik': 1,
    'boike': 1,
    'boil': 1,
    'boilard': 2,
    'boileau': 2,
    'boiled': 1,
    'boiler': 2,
    'boilermaker': 4,
    'boilerplate': 3,
    'boilerplates': 3,
    'boilers': 2,
    'boiling': 2,
    'boils': 1,
    'boink': 1,
    'boipatong': 3,
    'bois': 1,
    'boisclair': 2,
    'boise': 2,
    'boisen': 2,
    'boisi': 2,
    'boisjoly': 3,
    'boisseau': 2,
    'boissonneault': 3,
    'boisterous': 3,
    'boisvert': 2,
    'boitano': 3,
    'boitnott': 2,
    'boivin': 2,
    'bojanowski': 4,
    'bojarski': 3,
    'bojenka': 3,
    'bojorquez': 3,
    'bok': 1,
    'bokassa': 3,
    'boker': 2,
    'bokor': 2,
    'boksen': 2,
    'bol': 1,
    'bola': 2,
    'bolam': 2,
    'bolan': 2,
    'bolancik': 3,
    'boland': 2,
    'bolander': 3,
    'bolanger': 3,
    'bolanos': 3,
    'bolar': 2,
    'bolch': 1,
    'bolcom': 2,
    'bold': 1,
    'bolda': 2,
    'bolden': 2,
    'bolder': 2,
    'boldest': 2,
    'boldface': 2,
    'boldin': 2,
    'bolding': 2,
    'boldly': 2,
    'boldman': 2,
    'boldness': 2,
    'boldon': 2,
    'bolds': 1,
    'boldt': 1,
    'bolduc': 2,
    'bole': 1,
    'bolek': 2,
    'bolen': 2,
    'bolenbaugh': 3,
    'bolender': 3,
    'boler': 2,
    'bolerjack': 3,
    'bolero': 3,
    'boles': 1,
    'boleware': 2,
    'boley': 2,
    'boleyn': 2,
    'bolf': 1,
    'bolger': 2,
    'bolich': 2,
    'bolick': 2,
    'boliden': 3,
    'bolin': 2,
    'boline': 2,
    'boling': 2,
    'bolinger': 3,
    'bolio': 3,
    'bolitho': 3,
    'bolivar': 3,
    'bolivars': 3,
    'bolivia': 4,
    'bolivian': 4,
    'bolivians': 4,
    'bolker': 2,
    'bolkow': 2,
    'boll': 1,
    'bolla': 2,
    'bolland': 2,
    'bollapragada': 5,
    'bollard': 2,
    'bolle': 1,
    'bollen': 2,
    'bollenbach': 3,
    'bollenbacher': 4,
    'boller': 2,
    'bolles': 1,
    'bollettieri': 5,
    'bollier': 3,
    'bollig': 2,
    'bolliger': 3,
    'bollin': 2,
    'bolling': 2,
    'bollinger': 3,
    'bollman': 2,
    'bollmann': 2,
    'bollore': 2,
    'bolls': 1,
    'bologna': 3,
    'bolognese': 4,
    'bolognesi': 4,
    'bolon': 2,
    'bolotin': 3,
    'bolsa': 2,
    'bolser': 2,
    'bolshevik': 3,
    'bolsheviks': 3,
    'bolshevism': 4,
    'bolshevist': 3,
    'bolshoi': 2,
    'bolson': 2,
    'bolstad': 2,
    'bolster': 2,
    'bolstered': 2,
    'bolstering': 3,
    'bolsters': 2,
    'bolt': 1,
    'bolte': 1,
    'bolted': 2,
    'bolten': 2,
    'bolter': 2,
    'bolthouse': 2,
    'bolting': 2,
    'bolton': 2,
    'bolts': 1,
    'boltz': 1,
    'boltzmanns': 2,
    'bolus': 2,
    'bolyard': 2,
    'bolz': 1,
    'bom': 1,
    'bomag': 2,
    'boman': 2,
    'bomar': 2,
    'bomb': 1,
    'bomba': 2,
    'bombard': 2,
    'bombarded': 3,
    'bombardier': 3,
    'bombardiers': 3,
    'bombarding': 3,
    'bombardment': 3,
    'bombardments': 3,
    'bombast': 2,
    'bombastic': 3,
    'bombay': 2,
    'bombeck': 2,
    'bombed': 1,
    'bomber': 2,
    'bomberg': 2,
    'bomberger': 3,
    'bombers': 2,
    'bombing': 2,
    'bombings': 2,
    'bombmaker': 3,
    'bombmaking': 3,
    'bombs': 1,
    'bombshell': 2,
    'bombshells': 2,
    'bomer': 2,
    'bomgardner': 3,
    'bommarito': 4,
    'bommer': 2,
    'bon': 1,
    'bona': 2,
    'bonacci': 3,
    'bonadio': 4,
    'bonadonna': 4,
    'bonaduce': 4,
    'bonafide': 3,
    'bonanni': 3,
    'bonanno': 3,
    'bonanza': 3,
    'bonaparte': 3,
    'bonar': 2,
    'bonasera': 4,
    'bonaventura': 5,
    'bonaventure': 4,
    'bonavia': 4,
    'bonavita': 4,
    'bonawitz': 3,
    'bonczek': 2,
    'bond': 1,
    'bondage': 2,
    'bondar': 2,
    'bonde': 1,
    'bonded': 2,
    'bonderman': 3,
    'bondholder': 3,
    'bondholders': 3,
    'bondi': 2,
    'bonding': 2,
    'bondra': 2,
    'bonds': 1,
    'bondt': 1,
    'bondurant': 3,
    'bondy': 2,
    'bone': 1,
    'bonebrake': 2,
    'bonecrusher': 3,
    'bonecutter': 3,
    'boned': 1,
    'boneless': 2,
    'bonelli': 3,
    'bonello': 3,
    'bonenberger': 4,
    'bonenfant': 2,
    'boner': 2,
    'bones': 1,
    'bonesteel': 2,
    'bonet': 1,
    'bonetti': 3,
    'boney': 2,
    'bonfanti': 3,
    'bonfield': 2,
    'bonfiglio': 4,
    'bonfire': 3,
    'bonfires': 3,
    'bong': 1,
    'bongard': 2,
    'bongarten': 3,
    'bongers': 2,
    'bongiorno': 3,
    'bongiovanni': 4,
    'bongjin': 2,
    'bongo': 2,
    'bongos': 2,
    'bonham': 2,
    'boni': 2,
    'bonica': 3,
    'bonier': 2,
    'bonifacio': 5,
    'bonifas': 3,
    'bonifay': 3,
    'bonifield': 3,
    'bonilla': 3,
    'bonin': 2,
    'bonine': 3,
    'boning': 2,
    'bonini': 3,
    'bonino': 3,
    'bonior': 3,
    'bonita': 3,
    'bonito': 3,
    'bonjour': 2,
    'bonk': 1,
    'bonker': 2,
    'bonkers': 2,
    'bonkowski': 3,
    'bonn': 1,
    'bonne': 1,
    'bonneau': 2,
    'bonnell': 2,
    'bonnema': 3,
    'bonner': 2,
    'bonnes': 1,
    'bonnet': 2,
    'bonnett': 2,
    'bonnette': 2,
    'bonneville': 3,
    'bonnevilles': 3,
    'bonney': 2,
    'bonnibel': 3,
    'bonnibelle': 3,
    'bonnie': 2,
    'bonnin': 2,
    'bonnor': 2,
    'bonny': 2,
    'bono': 2,
    'bonobo': 3,
    'bonobos': 3,
    'bonomo': 3,
    'bonsai': 2,
    'bonsall': 2,
    'bonser': 2,
    'bonsignore': 4,
    'bonta': 2,
    'bonte': 1,
    'bontempo': 3,
    'bontrager': 3,
    'bonum': 2,
    'bonura': 3,
    'bonus': 2,
    'bonuses': 3,
    'bonvillain': 3,
    'bonville': 2,
    'bonwell': 2,
    'bonwit': 2,
    'bony': 2,
    'bonzo': 2,
    'boo': 1,
    'boob': 1,
    'boobs': 1,
    'booby': 2,
    'boock': 1,
    'boodle': 2,
    'boodles': 2,
    'boody': 2,
    'booe': 2,
    'booed': 1,
    'booee': 2,
    'boogie': 2,
    'booher': 2,
    'booing': 2,
    'book': 1,
    'bookbinder': 3,
    'bookbinders': 3,
    'bookcase': 2,
    'bookcases': 3,
    'bookcellar': 3,
    'bookcellars': 3,
    'booked': 1,
    'bookend': 2,
    'bookends': 2,
    'booker': 2,
    'bookers': 2,
    'bookie': 2,
    'bookies': 2,
    'bookin': 2,
    'booking': 2,
    'bookings': 2,
    'bookish': 2,
    'bookkeeper': 3,
    'bookkeepers': 3,
    'bookkeeping': 3,
    'booklet': 2,
    'booklets': 2,
    'bookmaker': 3,
    'bookmakers': 3,
    'bookmaking': 3,
    'bookman': 2,
    'bookmark': 2,
    'bookmarks': 2,
    'bookmobile': 3,
    'bookmobiles': 3,
    'booknote': 2,
    'booknotes': 2,
    'bookout': 2,
    'books': 1,
    'bookseller': 3,
    'booksellers': 3,
    'bookshelf': 2,
    'bookshelves': 2,
    'bookshop': 2,
    'bookshops': 2,
    'booksmith': 2,
    'bookstand': 2,
    'bookstands': 2,
    'bookstop': 2,
    'bookstops': 2,
    'bookstore': 2,
    'bookstores': 2,
    'bookwalter': 3,
    'bookworm': 2,
    'boole': 1,
    'boolean': 3,
    'boom': 1,
    'boombox': 2,
    'boomboxes': 3,
    'boomed': 1,
    'boomer': 2,
    'boomerang': 3,
    'boomeranged': 3,
    'boomers': 2,
    'boomershine': 3,
    'boomhower': 3,
    'booming': 2,
    'boomlet': 2,
    'booms': 1,
    'boomsma': 2,
    'boomtown': 2,
    'boon': 1,
    'boondocks': 2,
    'boondoggle': 3,
    'boondoggles': 3,
    'boone': 1,
    'boons': 1,
    'boonstra': 2,
    'boonton': 2,
    'boonville': 2,
    'boop': 1,
    'boor': 1,
    'booras': 2,
    'boord': 1,
    'boorda': 2,
    'boorish': 2,
    'boorishness': 3,
    'boorman': 2,
    'boors': 1,
    'boorstin': 2,
    'boortz': 1,
    'boos': 1,
    'boose': 1,
    'boost': 1,
    'boosted': 2,
    'booster': 2,
    'boosterism': 4,
    'boosters': 2,
    'boosting': 2,
    'boosts': 1,
    'boot': 1,
    'boote': 1,
    'booted': 2,
    'booten': 2,
    'booth': 1,
    'boothby': 2,
    'boothe': 1,
    'boothman': 2,
    'boothroyd': 2,
    'booths': 1,
    'bootie': 2,
    'booties': 2,
    'booting': 2,
    'bootleg': 2,
    'bootlegger': 3,
    'bootleggers': 3,
    'bootlegging': 3,
    'bootlegs': 2,
    'booton': 2,
    'boots': 1,
    'bootstrap': 2,
    'bootstraps': 2,
    'booty': 2,
    'bootz': 1,
    'booz': 1,
    'booze': 1,
    'boozer': 2,
    'boozier': 3,
    'boozing': 2,
    'boozy': 2,
    'bop': 1,
    'bopeep': 2,
    'bopera': 3,
    'bopha': 2,
    'bophuthatswana': 5,
    'bopp': 1,
    'bopper': 2,
    'boppers': 2,
    'boquist': 2,
    'bora': 2,
    'borah': 2,
    'borak': 2,
    'boral': 2,
    'borawski': 3,
    'borax': 2,
    'borba': 2,
    'borchard': 2,
    'borchardt': 2,
    'borchelt': 2,
    'borcherding': 3,
    'borchers': 2,
    'borchert': 2,
    'borck': 1,
    'borda': 2,
    'bordallo': 3,
    'bordas': 2,
    'bordeau': 2,
    'bordeaux': 2,
    'bordello': 3,
    'bordelon': 3,
    'borden': 2,
    'bordenave': 3,
    'border': 2,
    'bordered': 2,
    'bordering': 3,
    'borderline': 3,
    'borders': 2,
    'bordes': 1,
    'bordman': 2,
    'bordner': 2,
    'bordonaro': 4,
    'bordwell': 2,
    'bore': 1,
    'borealis': 4,
    'bored': 1,
    'boredom': 2,
    'borek': 2,
    'borel': 2,
    'borell': 2,
    'borella': 3,
    'borelli': 3,
    'borello': 3,
    'boren': 2,
    'borenstein': 3,
    'borer': 2,
    'bores': 1,
    'borg': 1,
    'borge': 1,
    'borgelt': 2,
    'borgen': 2,
    'borger': 2,
    'borgerding': 3,
    'borgert': 2,
    'borges': 2,
    'borgeson': 3,
    'borgess': 2,
    'borghi': 2,
    'borgia': 2,
    'borgman': 2,
    'borgmann': 2,
    'borgmeyer': 3,
    'borgstrom': 2,
    'borgwardt': 2,
    'boric': 2,
    'borin': 2,
    'boring': 2,
    'boris': 2,
    'borja': 2,
    'borjas': 2,
    'bork': 1,
    'borkenhagen': 4,
    'borkowski': 3,
    'borland': 2,
    'borman': 2,
    'bormann': 2,
    'born': 1,
    'borne': 1,
    'borneman': 2,
    'bornemann': 2,
    'borneo': 3,
    'borner': 2,
    'bornholdt': 2,
    'bornhorst': 2,
    'bornman': 2,
    'borns': 1,
    'bornstein': 2,
    'borntrager': 3,
    'boro': 2,
    'borocce': 3,
    'boroff': 2,
    'boroian': 3,
    'boron': 2,
    'boros': 2,
    'borosage': 3,
    'boroski': 3,
    'borough': 2,
    'boroughs': 2,
    'borowiak': 4,
    'borowicz': 3,
    'borowiec': 3,
    'borowski': 3,
    'borowsky': 3,
    'borowy': 3,
    'borquez': 2,
    'borras': 2,
    'borre': 1,
    'borrego': 3,
    'borrell': 2,
    'borrelli': 3,
    'borrello': 3,
    'borrero': 3,
    'borris': 2,
    'borroff': 2,
    'borror': 2,
    'borrow': 2,
    'borrowed': 2,
    'borrower': 3,
    'borrowers': 3,
    'borrowing': 3,
    'borrowings': 3,
    'borrowman': 3,
    'borrows': 2,
    'borruso': 3,
    'bors': 1,
    'borsch': 1,
    'borscht': 1,
    'borse': 1,
    'borseth': 2,
    'borski': 2,
    'borst': 1,
    'borsuk': 2,
    'bort': 1,
    'bortel': 2,
    'borten': 2,
    'borth': 1,
    'borthwick': 2,
    'bortle': 2,
    'bortner': 2,
    'bortnick': 2,
    'borton': 2,
    'bortz': 1,
    'boruch': 2,
    'borucki': 3,
    'boruff': 2,
    'borum': 2,
    'borunda': 3,
    'borup': 2,
    'borwn': 2,
    'borys': 2,
    'bos': 1,
    'bosak': 2,
    'bosarge': 2,
    'boscarino': 4,
    'bosch': 1,
    'boschee': 2,
    'boschen': 2,
    'boschert': 2,
    'boschwitz': 2,
    'boscia': 2,
    'bosco': 2,
    'bose': 1,
    'boseman': 2,
    'bosendorfer': 4,
    'boser': 2,
    'bosh': 1,
    'boshart': 2,
    'boshears': 2,
    'boshell': 2,
    'bosher': 2,
    'boshers': 2,
    'boskin': 2,
    'bosko': 2,
    'boskovich': 3,
    'boslego': 3,
    'bosler': 3,
    'bosley': 2,
    'bosma': 2,
    'bosman': 2,
    'bosnia': 3,
    'bosnian': 3,
    'bosnians': 3,
    'bosnias': 3,
    'boso': 2,
    'bosom': 2,
    'bosqi': 2,
    'bosquez': 2,
    'boss': 1,
    'bossard': 2,
    'bossart': 2,
    'bosse': 1,
    'bossed': 1,
    'bossen': 2,
    'bosserman': 3,
    'bossert': 2,
    'bosses': 2,
    'bosshardt': 2,
    'bosshart': 2,
    'bossi': 2,
    'bossidy': 3,
    'bossie': 2,
    'bossier': 3,
    'bossler': 3,
    'bossman': 2,
    'bosso': 2,
    'bossy': 2,
    'bost': 1,
    'bostelman': 3,
    'boster': 2,
    'bostian': 2,
    'bostic': 2,
    'bostick': 2,
    'bostock': 2,
    'boston': 2,
    'bostonian': 4,
    'bostonians': 4,
    'bostra': 2,
    'bostrom': 2,
    'bostwick': 2,
    'boswell': 2,
    'bosworth': 2,
    'botanic': 3,
    'botanical': 4,
    'botanically': 5,
    'botanist': 3,
    'botanists': 3,
    'botany': 3,
    'botch': 1,
    'botched': 1,
    'boteler': 3,
    'botelho': 3,
    'botello': 3,
    'botero': 3,
    'botfly': 2,
    'both': 1,
    'botha': 2,
    'botham': 2,
    'bothe': 1,
    'bothell': 2,
    'bother': 2,
    'bothered': 2,
    'bothering': 3,
    'bothers': 2,
    'bothersome': 3,
    'bothun': 2,
    'bothwell': 2,
    'botin': 2,
    'botkin': 2,
    'botkins': 2,
    'botner': 2,
    'botolf': 2,
    'boton': 2,
    'botos': 2,
    'botrytis': 3,
    'botsford': 2,
    'botshabelo': 4,
    'botswana': 3,
    'bott': 1,
    'botta': 2,
    'bottari': 3,
    'bottcher': 2,
    'bottecelli': 4,
    'bottel': 2,
    'botten': 2,
    'bottenfield': 3,
    'bottger': 2,
    'botting': 2,
    'bottini': 3,
    'bottino': 3,
    'bottle': 2,
    'bottled': 2,
    'bottleneck': 3,
    'bottlenecks': 3,
    'bottler': 2,
    'bottlers': 2,
    'bottles': 2,
    'bottling': 3,
    'bottom': 2,
    'bottomed': 2,
    'bottomfish': 3,
    'bottoming': 3,
    'bottomless': 3,
    'bottomley': 3,
    'bottoms': 2,
    'bottone': 3,
    'bottorf': 2,
    'bottorff': 2,
    'bottrell': 2,
    'botts': 1,
    'botulism': 4,
    'botz': 1,
    'bouch': 1,
    'bouchard': 2,
    'bouche': 1,
    'boucher': 2,
    'bouchey': 2,
    'bouchie': 2,
    'bouchillon': 3,
    'bouck': 1,
    'boudin': 2,
    'boudoin': 2,
    'boudoir': 2,
    'boudreau': 2,
    'boudreaux': 2,
    'bouffard': 2,
    'bougainville': 3,
    'bougainvillea': 5,
    'bough': 1,
    'boughan': 2,
    'bougher': 2,
    'boughman': 2,
    'boughner': 2,
    'boughs': 1,
    'bought': 1,
    'boughten': 2,
    'boughter': 2,
    'boughton': 2,
    'bougie': 2,
    'bouie': 2,
    'bouillabaise': 3,
    'bouillon': 3,
    'bouknight': 2,
    'boulais': 2,
    'boulalas': 3,
    'boulanger': 3,
    'boulangerie': 4,
    'boulay': 2,
    'boulden': 2,
    'boulder': 2,
    'boulders': 2,
    'boule': 1,
    'bouler': 2,
    'boulet': 2,
    'boulette': 2,
    'boulevard': 3,
    'boulevards': 3,
    'bouley': 2,
    'boulez': 2,
    'boulier': 3,
    'boullion': 2,
    'boulos': 2,
    'boulter': 2,
    'boultinghouse': 3,
    'boulton': 2,
    'boulware': 2,
    'bouma': 2,
    'bouman': 2,
    'bounce': 1,
    'bounced': 1,
    'bouncer': 2,
    'bouncers': 2,
    'bounces': 2,
    'bounciness': 3,
    'bouncing': 2,
    'bouncy': 2,
    'bound': 1,
    'boundaries': 3,
    'boundary': 3,
    'bounded': 2,
    'bounder': 2,
    'bounding': 2,
    'boundless': 2,
    'bounds': 1,
    'boundy': 2,
    'bounties': 2,
    'bountiful': 3,
    'bounty': 2,
    'bouquet': 2,
    'bouquets': 2,
    'bour': 1,
    'bourassa': 3,
    'bourbeau': 2,
    'bourbon': 2,
    'bourbons': 2,
    'bourcier': 3,
    'bourdeau': 2,
    'bourg': 1,
    'bourgault': 2,
    'bourgeois': 2,
    'bourgeoisie': 3,
    'bourget': 2,
    'bourgoin': 2,
    'bourguiba': 3,
    'bourguignon': 3,
    'bourke': 1,
    'bourland': 2,
    'bourn': 1,
    'bourne': 1,
    'bournewood': 2,
    'bournias': 3,
    'bournonville': 3,
    'bourque': 1,
    'bourquin': 2,
    'bourret': 2,
    'bourse': 1,
    'bourses': 2,
    'boursicot': 3,
    'bouse': 1,
    'bouska': 2,
    'bousman': 2,
    'bousquet': 2,
    'boussac': 2,
    'boustany': 3,
    'bout': 1,
    'boutell': 2,
    'boutelle': 2,
    'bouthillier': 4,
    'boutilier': 4,
    'boutin': 2,
    'boutique': 2,
    'boutiques': 2,
    'bouton': 2,
    'boutros': 2,
    'bouts': 1,
    'boutte': 1,
    'boutwell': 2,
    'bouvier': 3,
    'bouwens': 2,
    'bouwkamp': 2,
    'bouwman': 2,
    'bouwsma': 2,
    'bouyer': 2,
    'bouygues': 2,
    'bouza': 2,
    'bova': 2,
    'bovard': 2,
    'bove': 1,
    'bovee': 2,
    'boven': 2,
    'bovenzi': 3,
    'boveri': 3,
    'bovespa': 2,
    'bovey': 2,
    'bovik': 2,
    'bovin': 2,
    'bovina': 3,
    'bovine': 2,
    'bovino': 3,
    'bow': 1,
    'bowar': 2,
    'boward': 2,
    'bowater': 3,
    'bowcan': 2,
    'bowden': 2,
    'bowdish': 2,
    'bowditch': 2,
    'bowdle': 2,
    'bowdoin': 2,
    'bowe': 1,
    'bowed': 1,
    'bowel': 2,
    'bowell': 2,
    'bowels': 2,
    'bowen': 2,
    'bowens': 2,
    'bower': 2,
    'bowerman': 3,
    'bowermaster': 4,
    'bowers': 2,
    'bowersock': 3,
    'bowersox': 3,
    'bowery': 3,
    'bowes': 1,
    'bowick': 2,
    'bowie': 2,
    'bowing': 2,
    'bowker': 2,
    'bowl': 1,
    'bowland': 2,
    'bowlby': 2,
    'bowlds': 1,
    'bowled': 1,
    'bowlen': 2,
    'bowler': 2,
    'bowlers': 2,
    'bowles': 1,
    'bowley': 2,
    'bowlin': 2,
    'bowline': 2,
    'bowling': 2,
    'bowls': 1,
    'bowlus': 2,
    'bowman': 2,
    'bowmen': 2,
    'bowmer': 2,
    'bown': 1,
    'bownds': 1,
    'bowne': 1,
    'bowring': 2,
    'bowron': 2,
    'bows': 1,
    'bowse': 1,
    'bowsed': 1,
    'bowser': 2,
    'bowses': 2,
    'bowsher': 2,
    'bowsing': 2,
    'bowyer': 2,
    'box': 1,
    'boxberger': 3,
    'boxcar': 2,
    'boxcars': 2,
    'boxed': 1,
    'boxell': 2,
    'boxer': 2,
    'boxers': 2,
    'boxes': 2,
    'boxing': 2,
    'boxley': 2,
    'boxwell': 2,
    'boxwood': 2,
    'boxx': 1,
    'boxy': 2,
    'boy': 1,
    'boyack': 2,
    'boyajian': 4,
    'boyan': 2,
    'boyar': 2,
    'boyce': 1,
    'boycott': 2,
    'boycotted': 3,
    'boycotting': 3,
    'boycotts': 2,
    'boyd': 1,
    'boyde': 1,
    'boyden': 2,
    'boydston': 2,
    'boydstun': 2,
    'boye': 1,
    'boyea': 3,
    'boyer': 2,
    'boyers': 2,
    'boyertown': 3,
    'boyes': 1,
    'boyett': 2,
    'boyette': 2,
    'boyfriend': 2,
    'boyfriends': 2,
    'boyhood': 2,
    'boyington': 3,
    'boyish': 2,
    'boykin': 2,
    'boykins': 2,
    'boyko': 2,
    'boylan': 2,
    'boyland': 2,
    'boyle': 1,
    'boylen': 2,
    'boyles': 1,
    'boyleston': 2,
    'boylls': 1,
    'boylston': 2,
    'boyne': 1,
    'boynton': 2,
    'boys': 1,
    'boysel': 2,
    'boysen': 2,
    'boyson': 2,
    'boyt': 1,
    'boyte': 1,
    'boyter': 2,
    'boyum': 2,
    'boyz': 1,
    'boza': 2,
    'bozard': 2,
    'bozarth': 2,
    'boze': 1,
    'bozek': 2,
    'bozell': 2,
    'bozeman': 2,
    'bozian': 3,
    'bozic': 2,
    'bozich': 2,
    'bozman': 2,
    'bozo': 2,
    'bozos': 2,
    'boztepe': 2,
    'bozza': 2,
    'bozzi': 2,
    'bozzo': 2,
    'bra': 1,
    'braaksma': 2,
    'braasch': 1,
    'braaten': 3,
    'braatz': 1,
    'brabant': 2,
    'brabec': 2,
    'brabender': 3,
    'brabham': 2,
    'brabson': 2,
    'brac': 1,
    'bracamonte': 4,
    'bracamontes': 4,
    'bracci': 2,
    'bracco': 2,
    'brace': 1,
    'braced': 1,
    'bracelet': 2,
    'bracelets': 2,
    'bracer': 2,
    'bracero': 3,
    'braces': 2,
    'bracewell': 2,
    'bracey': 2,
    'brach': 1,
    'bracher': 2,
    'brachfeld': 2,
    'brachiopod': 4,
    'brachiopods': 4,
    'bracht': 1,
    'bracing': 2,
    'bracingly': 3,
    'brack': 1,
    'brackbill': 2,
    'brackeen': 2,
    'bracken': 2,
    'brackenbury': 4,
    'brackens': 2,
    'bracker': 2,
    'bracket': 2,
    'brackets': 2,
    'brackett': 2,
    'brackin': 2,
    'brackins': 2,
    'brackish': 2,
    'brackman': 2,
    'bracknell': 2,
    'brackney': 2,
    'bracy': 2,
    'brad': 1,
    'bradberry': 3,
    'bradburn': 2,
    'bradbury': 3,
    'bradco': 2,
    'braddock': 2,
    'braddy': 2,
    'bradeen': 2,
    'brademas': 3,
    'braden': 2,
    'bradenton': 3,
    'brader': 2,
    'bradfield': 2,
    'bradford': 2,
    'bradfords': 2,
    'bradham': 2,
    'bradish': 2,
    'bradlee': 2,
    'bradlees': 2,
    'bradley': 2,
    'bradleys': 2,
    'bradner': 2,
    'bradney': 2,
    'bradshaw': 2,
    'bradsher': 2,
    'bradstreet': 2,
    'bradt': 1,
    'bradtke': 2,
    'bradtmiller': 3,
    'bradway': 2,
    'bradwell': 2,
    'brady': 2,
    'bradycardia': 5,
    'bradykinin': 4,
    'bradys': 2,
    'brae': 1,
    'braendstroem': 2,
    'braer': 1,
    'braff': 1,
    'brafford': 2,
    'brag': 1,
    'braga': 2,
    'bragan': 2,
    'braganza': 3,
    'bragdon': 2,
    'brager': 2,
    'bragg': 1,
    'braggadocio': 5,
    'bragged': 1,
    'bragger': 2,
    'braggers': 2,
    'bragging': 2,
    'braggiotti': 4,
    'braggs': 1,
    'brags': 1,
    'braham': 2,
    'brahm': 1,
    'brahmin': 2,
    'brahms': 1,
    'braid': 1,
    'braided': 2,
    'braiding': 2,
    'braids': 1,
    'braidwood': 2,
    'brailey': 2,
    'braille': 1,
    'brailles': 1,
    'brailsford': 2,
    'brain': 1,
    'brainard': 2,
    'brainchild': 2,
    'braindead': 2,
    'brained': 1,
    'brainer': 2,
    'brainerd': 2,
    'brainless': 2,
    'brainpower': 3,
    'brains': 1,
    'brainstorm': 2,
    'brainstormed': 2,
    'brainstorming': 3,
    'brainstorms': 2,
    'braintree': 2,
    'brainwash': 2,
    'brainwashed': 2,
    'brainwashes': 3,
    'brainwashing': 3,
    'brainy': 2,
    'braise': 1,
    'braised': 1,
    'braithwaite': 2,
    'brajdas': 2,
    'brajovic': 3,
    'brake': 1,
    'brakebill': 2,
    'braked': 1,
    'brakefield': 2,
    'brakeman': 2,
    'brakemen': 2,
    'braker': 2,
    'brakes': 1,
    'braking': 2,
    'brakke': 1,
    'braley': 2,
    'bralley': 2,
    'brallier': 3,
    'bralorne': 2,
    'braly': 2,
    'bram': 1,
    'bramah': 2,
    'bramalea': 4,
    'braman': 2,
    'brambila': 3,
    'bramble': 2,
    'brambles': 2,
    'bramblett': 2,
    'brame': 1,
    'bramel': 2,
    'bramer': 2,
    'bramhall': 2,
    'bramlage': 2,
    'bramlet': 2,
    'bramlett': 2,
    'bramlette': 2,
    'bramley': 2,
    'brammeier': 3,
    'brammer': 2,
    'brampton': 2,
    'brams': 1,
    'bramson': 2,
    'bramwell': 2,
    'bran': 1,
    'branagan': 3,
    'branagh': 2,
    'branam': 2,
    'branaman': 3,
    'branan': 2,
    'branca': 2,
    'brancaccio': 4,
    'brancato': 3,
    'branch': 1,
    'branche': 1,
    'brancheau': 2,
    'branched': 1,
    'branches': 2,
    'branching': 2,
    'branchlet': 2,
    'branchlets': 2,
    'branco': 2,
    'brand': 1,
    'branda': 2,
    'brandau': 2,
    'brande': 1,
    'brandeberry': 3,
    'branded': 2,
    'brandeis': 2,
    'brandel': 2,
    'branden': 2,
    'brandenberg': 3,
    'brandenberger': 4,
    'brandenburg': 3,
    'brandenburger': 4,
    'brandenstein': 3,
    'brander': 2,
    'brandes': 1,
    'brandford': 2,
    'brandhorst': 2,
    'brandi': 2,
    'branding': 2,
    'brandis': 2,
    'brandish': 2,
    'brandished': 2,
    'brandishes': 3,
    'brandishing': 3,
    'brandl': 2,
    'brandle': 2,
    'brandname': 2,
    'brandner': 2,
    'brando': 2,
    'brandon': 2,
    'brandow': 2,
    'brands': 1,
    'brandstetter': 3,
    'brandt': 1,
    'brandtner': 2,
    'brandvold': 2,
    'brandwein': 2,
    'brandy': 2,
    'brandywine': 3,
    'braner': 2,
    'branford': 2,
    'branham': 2,
    'brani': 2,
    'braniff': 2,
    'branigan': 3,
    'branillo': 3,
    'branin': 2,
    'branislov': 3,
    'branitzky': 3,
    'brank': 1,
    'branki': 2,
    'branko': 2,
    'branks': 1,
    'brann': 1,
    'branna': 2,
    'brannam': 2,
    'brannan': 2,
    'brannen': 2,
    'branner': 2,
    'brannick': 2,
    'brannigan': 3,
    'branning': 2,
    'brannock': 2,
    'brannon': 2,
    'brannum': 2,
    'branon': 2,
    'branscom': 2,
    'branscomb': 2,
    'branscome': 2,
    'branscum': 2,
    'bransfield': 2,
    'bransford': 2,
    'bransom': 2,
    'branson': 2,
    'branstad': 2,
    'branstetter': 3,
    'brant': 1,
    'brantley': 2,
    'brantly': 2,
    'brantner': 2,
    'branton': 2,
    'branum': 2,
    'branyon': 2,
    'branz': 1,
    'braque': 1,
    'bras': 1,
    'brascade': 2,
    'brascan': 2,
    'brasch': 1,
    'brasco': 2,
    'brase': 1,
    'brasel': 2,
    'braselton': 3,
    'brasfield': 2,
    'brash': 1,
    'brashear': 2,
    'brashears': 2,
    'brasher': 2,
    'brashers': 2,
    'brashier': 3,
    'brashness': 2,
    'brasil': 2,
    'brasilia': 3,
    'brasington': 3,
    'brasow': 2,
    'brass': 1,
    'brassard': 2,
    'brasseaux': 2,
    'brassell': 2,
    'brasserie': 3,
    'brasses': 2,
    'brasseur': 2,
    'brassfield': 2,
    'brasso': 2,
    'brassy': 2,
    'braswell': 2,
    'brat': 1,
    'bratcher': 2,
    'braton': 2,
    'brats': 1,
    'bratsch': 1,
    'bratt': 1,
    'brattain': 2,
    'brattaslava': 4,
    'bratten': 2,
    'brattin': 2,
    'brattle': 2,
    'bratton': 2,
    'bratwurst': 2,
    'bratz': 1,
    'brau': 1,
    'brauch': 1,
    'braucher': 2,
    'brauchli': 2,
    'braud': 1,
    'braude': 1,
    'brauer': 2,
    'braughton': 2,
    'brauhau': 2,
    'brault': 1,
    'braun': 1,
    'braund': 1,
    'braune': 1,
    'brauner': 2,
    'brauns': 1,
    'braunschweig': 2,
    'braunstein': 2,
    'braunwald': 2,
    'brause': 1,
    'brautigam': 3,
    'brautigams': 3,
    'bravado': 3,
    'brave': 1,
    'braved': 1,
    'braveheart': 2,
    'bravely': 2,
    'braver': 2,
    'braverman': 3,
    'bravery': 3,
    'braves': 1,
    'bravest': 2,
    'braving': 2,
    'bravo': 2,
    'bravura': 3,
    'brawer': 2,
    'brawl': 1,
    'brawley': 2,
    'brawling': 2,
    'brawls': 1,
    'brawn': 1,
    'brawner': 2,
    'brawny': 2,
    'braxton': 2,
    'bray': 1,
    'brayboy': 2,
    'brayer': 2,
    'brayfield': 2,
    'brayman': 2,
    'brayton': 2,
    'braz': 1,
    'brazda': 2,
    'brazeal': 2,
    'brazeau': 2,
    'brazee': 2,
    'brazel': 2,
    'brazell': 2,
    'brazelton': 3,
    'brazen': 2,
    'brazenly': 3,
    'brazenness': 3,
    'braziel': 2,
    'brazier': 3,
    'braziers': 2,
    'brazil': 2,
    'brazile': 2,
    'brazilian': 3,
    'brazilians': 3,
    'brazill': 2,
    'brazos': 2,
    'brazzaville': 3,
    'brazzel': 2,
    'brazzell': 2,
    'brca': 2,
    'brcko': 2,
    'brea': 1,
    'breach': 1,
    'breached': 1,
    'breaches': 2,
    'breaching': 2,
    'bread': 1,
    'breadbasket': 3,
    'breadbox': 2,
    'breaded': 2,
    'breadfruit': 2,
    'breading': 2,
    'breads': 1,
    'breadth': 1,
    'breadwinner': 3,
    'breadwinners': 3,
    'bready': 2,
    'break': 1,
    'breakable': 3,
    'breakage': 2,
    'breakaway': 3,
    'breakdown': 2,
    'breakdowns': 2,
    'breaker': 2,
    'breakers': 2,
    'breakeven': 3,
    'breakey': 2,
    'breakfast': 2,
    'breakfasts': 2,
    'breakfield': 2,
    'breaking': 2,
    'breakmate': 2,
    'breakneck': 2,
    'breakout': 2,
    'breakouts': 2,
    'breaks': 1,
    'breakthrough': 2,
    'breakthroughs': 2,
    'breakup': 2,
    'breakups': 2,
    'breakwater': 3,
    'breaky': 2,
    'bream': 1,
    'brean': 1,
    'brearley': 2,
    'breast': 1,
    'breasted': 2,
    'breastfed': 2,
    'breastfeed': 2,
    'breastfeeding': 3,
    'breastfeeds': 2,
    'breasting': 2,
    'breastplate': 2,
    'breasts': 1,
    'breath': 1,
    'breathe': 1,
    'breathed': 1,
    'breather': 2,
    'breathes': 1,
    'breathing': 2,
    'breathless': 2,
    'breathlessly': 3,
    'breaths': 1,
    'breathtaking': 3,
    'breathtakingly': 4,
    'breathy': 2,
    'breau': 1,
    'breault': 1,
    'breaux': 1,
    'breazeale': 2,
    'brebach': 2,
    'brechbill': 2,
    'brecheen': 2,
    'brecheisen': 3,
    'brecher': 2,
    'brecht': 1,
    'brechtel': 2,
    'breck': 1,
    'breckenridge': 3,
    'brecker': 2,
    'breco': 2,
    'bred': 1,
    'breda': 2,
    'brede': 1,
    'breden': 2,
    'bredeson': 3,
    'bree': 1,
    'breece': 1,
    'breech': 1,
    'breeching': 2,
    'breed': 1,
    'breeden': 2,
    'breeder': 2,
    'breeders': 2,
    'breeding': 2,
    'breedlove': 2,
    'breeds': 1,
    'breeland': 2,
    'breen': 1,
    'brees': 1,
    'breese': 1,
    'breeze': 1,
    'breezed': 1,
    'breezes': 2,
    'breezeway': 2,
    'breezy': 2,
    'brege': 1,
    'breger': 2,
    'breglio': 3,
    'bregman': 2,
    'breguet': 2,
    'brehm': 1,
    'brehmer': 2,
    'breidenbach': 3,
    'breidenstein': 3,
    'breier': 2,
    'breighner': 2,
    'breighton': 2,
    'breiner': 2,
    'breining': 2,
    'breininger': 3,
    'breisch': 1,
    'breit': 1,
    'breitbach': 2,
    'breitbarth': 2,
    'breitenbach': 3,
    'breitenstein': 3,
    'breithaupt': 2,
    'breitkreutz': 2,
    'breitling': 3,
    'breitman': 2,
    'breitschwerdt': 2,
    'breitweiser': 3,
    'breitzman': 2,
    'brekke': 1,
    'breland': 2,
    'brelsford': 2,
    'brem': 1,
    'bremen': 2,
    'bremer': 2,
    'bremmer': 2,
    'bremner': 2,
    'brems': 1,
    'bren': 1,
    'brenda': 2,
    'brendan': 2,
    'brendel': 2,
    'brenden': 2,
    'brender': 2,
    'brendle': 2,
    'brendlinger': 4,
    'brendon': 2,
    'brendor': 2,
    'brendsel': 2,
    'breneman': 2,
    'brener': 2,
    'brenes': 1,
    'brengle': 2,
    'brenizer': 3,
    'brenn': 1,
    'brenna': 2,
    'brennan': 2,
    'brennans': 2,
    'brennecke': 2,
    'brenneke': 3,
    'brenneman': 2,
    'brennen': 2,
    'brenner': 2,
    'brenning': 2,
    'brensinger': 3,
    'brent': 1,
    'brentano': 3,
    'brentanos': 3,
    'brentlinger': 4,
    'brenton': 2,
    'brents': 1,
    'brentwood': 2,
    'breon': 2,
    'bresca': 2,
    'brescia': 2,
    'bresee': 2,
    'bresette': 2,
    'breshears': 2,
    'breslaw': 2,
    'bresler': 2,
    'breslin': 2,
    'breslow': 2,
    'bresnahan': 3,
    'bresnan': 2,
    'bresnick': 2,
    'bress': 1,
    'bresse': 1,
    'bresseau': 2,
    'bresser': 2,
    'bressette': 2,
    'bressler': 2,
    'bressman': 2,
    'bresson': 2,
    'brest': 1,
    'brester': 2,
    'bret': 1,
    'breth': 1,
    'brethauer': 3,
    'brethen': 2,
    'brethren': 2,
    'breton': 2,
    'bretschneider': 3,
    'brett': 1,
    'bretthauer': 3,
    'bretton': 2,
    'bretts': 1,
    'brettschneider': 3,
    'bretz': 1,
    'breuer': 2,
    'breunig': 2,
    'breuninger': 3,
    'brevard': 2,
    'brevets': 2,
    'brevig': 2,
    'brevik': 2,
    'brevity': 3,
    'brew': 1,
    'brewbaker': 3,
    'brewed': 1,
    'brewer': 2,
    'breweries': 3,
    'brewers': 2,
    'brewery': 3,
    'brewing': 2,
    'brewington': 3,
    'brewry': 2,
    'brews': 1,
    'brewster': 2,
    'brewton': 2,
    'brey': 1,
    'breyer': 2,
    'breyfogle': 3,
    'breza': 2,
    'brezhnev': 2,
    'brezina': 3,
    'brezinski': 3,
    'bria': 2,
    'brian': 2,
    'briana': 3,
    'briancon': 3,
    'briand': 2,
    'brianna': 3,
    'briant': 2,
    'briar': 2,
    'briarcliff': 2,
    'bribe': 1,
    'bribed': 1,
    'bribery': 3,
    'bribes': 1,
    'bribing': 2,
    'bric': 1,
    'briccetti': 3,
    'bricco': 2,
    'brice': 1,
    'briceno': 3,
    'brick': 1,
    'brickbat': 2,
    'brickbats': 2,
    'brickel': 2,
    'brickell': 2,
    'bricker': 2,
    'brickey': 2,
    'brickhouse': 2,
    'bricklayer': 3,
    'bricklayers': 3,
    'brickle': 2,
    'brickler': 2,
    'brickley': 2,
    'bricklin': 2,
    'brickman': 2,
    'brickner': 2,
    'bricks': 1,
    'brickyard': 2,
    'bricom': 2,
    'bridal': 2,
    'bride': 1,
    'brideau': 2,
    'bridegroom': 2,
    'briden': 2,
    'bridenbaugh': 3,
    'bridenstine': 3,
    'brides': 1,
    'bridesburg': 2,
    'bridesmaid': 2,
    'bridesmaids': 2,
    'bridge': 1,
    'bridged': 1,
    'bridgeford': 2,
    'bridgeforth': 2,
    'bridgehead': 2,
    'bridgeman': 2,
    'bridgeport': 2,
    'bridger': 2,
    'bridgers': 2,
    'bridges': 2,
    'bridgestone': 2,
    'bridget': 2,
    'bridgeton': 2,
    'bridgetown': 2,
    'bridgett': 2,
    'bridgette': 2,
    'bridgewater': 3,
    'bridgham': 2,
    'bridging': 2,
    'bridgman': 2,
    'bridie': 2,
    'bridle': 2,
    'bridled': 2,
    'bridwell': 2,
    'brie': 1,
    'brieant': 2,
    'brief': 1,
    'briefcase': 2,
    'briefcases': 3,
    'briefed': 1,
    'briefer': 2,
    'briefers': 2,
    'briefest': 2,
    'briefing': 2,
    'briefings': 2,
    'briefly': 2,
    'briefs': 1,
    'briegel': 2,
    'brieger': 2,
    'briel': 1,
    'brien': 2,
    'brienza': 2,
    'brier': 2,
    'briere': 1,
    'brierley': 3,
    'brierly': 3,
    'briese': 1,
    'brietzke': 2,
    'brig': 1,
    'brigade': 2,
    'brigadeer': 3,
    'brigades': 2,
    'brigadier': 3,
    'brigance': 2,
    'brigandi': 3,
    'brigante': 3,
    'briganti': 3,
    'briggs': 1,
    'briggstone': 2,
    'brigham': 2,
    'brighams': 2,
    'bright': 1,
    'brightbill': 2,
    'brighten': 2,
    'brightened': 2,
    'brightening': 3,
    'brightens': 2,
    'brighter': 2,
    'brightest': 2,
    'brightly': 2,
    'brightman': 2,
    'brightness': 2,
    'brighton': 2,
    'brightwell': 2,
    'brigitte': 2,
    'brigman': 2,
    'brignac': 2,
    'brigner': 2,
    'brigode': 3,
    'briguglio': 4,
    'briles': 1,
    'briley': 2,
    'brill': 1,
    'brillhart': 2,
    'brilliance': 2,
    'brilliant': 2,
    'brilliantly': 3,
    'brillstein': 2,
    'brim': 1,
    'brimberry': 3,
    'brimelow': 3,
    'brimer': 2,
    'brimhall': 2,
    'brimm': 1,
    'brimmed': 1,
    'brimmer': 2,
    'brimming': 2,
    'brimstone': 2,
    'brin': 1,
    'brindel': 2,
    'brindle': 2,
    'brindley': 2,
    'brine': 1,
    'brinegar': 3,
    'briner': 2,
    'brines': 1,
    'briney': 2,
    'bring': 1,
    'bringhurst': 2,
    'bringing': 2,
    'bringle': 2,
    'bringman': 2,
    'brings': 1,
    'brington': 2,
    'brining': 2,
    'brink': 1,
    'brinker': 2,
    'brinkerhoff': 3,
    'brinkley': 2,
    'brinkly': 2,
    'brinkman': 2,
    'brinkmann': 2,
    'brinkmanship': 3,
    'brinkmeier': 3,
    'brinkmeyer': 3,
    'brinks': 1,
    'brinksmanship': 3,
    'brinlee': 2,
    'brinley': 2,
    'brinn': 1,
    'brinner': 2,
    'brinser': 2,
    'brinsfield': 2,
    'brinson': 2,
    'brintec': 2,
    'brinton': 2,
    'brio': 2,
    'brioche': 2,
    'briody': 3,
    'brion': 2,
    'briones': 3,
    'briquemont': 2,
    'brisbane': 2,
    'brisbin': 2,
    'brisbois': 2,
    'brisbon': 2,
    'brisburg': 2,
    'brisco': 2,
    'briscoe': 2,
    'brisendine': 3,
    'briseno': 3,
    'brisk': 1,
    'brisker': 2,
    'brisket': 2,
    'briskey': 2,
    'briski': 2,
    'briskin': 2,
    'briskly': 2,
    'brisky': 2,
    'brislin': 2,
    'brison': 2,
    'brissette': 2,
    'brissey': 2,
    'brisson': 2,
    'brister': 2,
    'bristle': 2,
    'bristled': 2,
    'bristles': 2,
    'bristling': 3,
    'bristol': 2,
    'bristow': 2,
    'brit': 1,
    'brita': 2,
    'britain': 2,
    'britains': 2,
    'britannia': 4,
    'britannica': 4,
    'britcher': 2,
    'britches': 2,
    'brite': 1,
    'britian': 2,
    'british': 2,
    'britnell': 2,
    'brito': 2,
    'britoil': 2,
    'briton': 2,
    'britons': 2,
    'brits': 1,
    'britsch': 1,
    'britt': 1,
    'brittain': 2,
    'brittan': 2,
    'brittania': 4,
    'brittany': 3,
    'britten': 2,
    'brittenham': 3,
    'brittian': 3,
    'brittin': 2,
    'brittingham': 3,
    'brittle': 2,
    'brittler': 2,
    'brittlest': 2,
    'britton': 2,
    'britts': 1,
    'britz': 1,
    'brix': 1,
    'brixey': 2,
    'brixius': 3,
    'brizendine': 4,
    'brizill': 2,
    'brizola': 3,
    'brizzi': 2,
    'brizzolara': 4,
    'bro': 1,
    'broach': 1,
    'broached': 1,
    'broaches': 2,
    'broaching': 2,
    'broad': 1,
    'broadacre': 3,
    'broadaway': 3,
    'broadband': 2,
    'broadbase': 2,
    'broadbased': 2,
    'broadbeach': 2,
    'broadbent': 2,
    'broadcast': 2,
    'broadcaster': 3,
    'broadcasters': 3,
    'broadcasting': 3,
    'broadcasts': 2,
    'broaddus': 2,
    'broaden': 2,
    'broadened': 2,
    'broadening': 3,
    'broadens': 2,
    'broader': 2,
    'broadest': 2,
    'broadhead': 2,
    'broadhurst': 2,
    'broadie': 2,
    'broadley': 2,
    'broadly': 2,
    'broadnax': 2,
    'broadpfoot': 2,
    'broadrick': 2,
    'broadside': 2,
    'broadsided': 3,
    'broadstreet': 2,
    'broadsword': 2,
    'broadus': 2,
    'broadview': 2,
    'broadway': 2,
    'broadwell': 2,
    'broady': 2,
    'brobdingnagian': 5,
    'brobdingnagians': 5,
    'brobeck': 2,
    'broberg': 2,
    'brobst': 1,
    'broc': 1,
    'brocade': 2,
    'brocades': 2,
    'brocato': 3,
    'brocco': 2,
    'broccoli': 3,
    'broce': 1,
    'brochu': 2,
    'brochure': 2,
    'brochures': 2,
    'brocious': 2,
    'brock': 1,
    'brockbank': 2,
    'brockel': 2,
    'brocker': 2,
    'brockert': 2,
    'brockett': 2,
    'brockhaus': 2,
    'brockhoff': 2,
    'brockhouse': 2,
    'brockie': 2,
    'brockington': 3,
    'brocklehurst': 3,
    'brockley': 2,
    'brocklin': 2,
    'brockman': 2,
    'brockmann': 2,
    'brockmeier': 3,
    'brockmeyer': 3,
    'brockner': 2,
    'brocksmith': 2,
    'brockton': 2,
    'brockway': 2,
    'brockwell': 2,
    'brod': 1,
    'broda': 2,
    'brodbeck': 2,
    'brode': 1,
    'broden': 2,
    'broder': 2,
    'broderbund': 3,
    'broderick': 3,
    'brodersen': 3,
    'brodersohn': 3,
    'broderson': 3,
    'brodeur': 2,
    'brodhead': 2,
    'brodie': 2,
    'brodin': 2,
    'brodkin': 2,
    'brodman': 2,
    'brodnax': 2,
    'brodowski': 3,
    'brodrick': 2,
    'brodry': 2,
    'brodsky': 2,
    'brodt': 1,
    'brody': 2,
    'brodzinski': 3,
    'broe': 1,
    'broecker': 2,
    'broeker': 2,
    'broer': 2,
    'broerman': 3,
    'broers': 2,
    'brogan': 2,
    'brogden': 2,
    'brogdon': 2,
    'brogna': 2,
    'broich': 1,
    'broil': 1,
    'broiled': 1,
    'broiler': 2,
    'broilers': 2,
    'broiling': 2,
    'broin': 1,
    'brok': 1,
    'brokaw': 2,
    'broke': 1,
    'broken': 2,
    'broker': 2,
    'brokerage': 3,
    'brokerages': 4,
    'brokered': 2,
    'brokering': 3,
    'brokers': 2,
    'broking': 2,
    'brokini': 3,
    'brolin': 2,
    'brolly': 2,
    'brom': 1,
    'broman': 2,
    'bromberg': 2,
    'bromfield': 2,
    'bromide': 2,
    'bromides': 2,
    'bromine': 2,
    'bromley': 2,
    'bromm': 1,
    'brommer': 2,
    'bromont': 2,
    'brompheril': 3,
    'bromwell': 2,
    'bromwich': 2,
    'bronaugh': 2,
    'bronc': 1,
    'bronchial': 3,
    'bronchitis': 3,
    'bronchoscope': 3,
    'bronco': 2,
    'broncos': 2,
    'bronder': 2,
    'broner': 2,
    'bronfman': 2,
    'bronfmans': 2,
    'bronk': 1,
    'bronkema': 3,
    'bronn': 1,
    'bronner': 2,
    'brons': 1,
    'bronson': 2,
    'bronstein': 2,
    'bronston': 2,
    'bronte': 2,
    'brontosaurus': 4,
    'bronwen': 2,
    'bronwyn': 2,
    'bronx': 1,
    'bronze': 1,
    'bronzed': 1,
    'bronzen': 2,
    'bronzes': 2,
    'brooch': 1,
    'brood': 1,
    'brooded': 2,
    'brooding': 2,
    'broody': 2,
    'brook': 1,
    'brookbank': 2,
    'brooke': 1,
    'brookehill': 2,
    'brooken': 2,
    'brookens': 2,
    'brooker': 2,
    'brookes': 1,
    'brookfield': 2,
    'brookhart': 2,
    'brookhaven': 3,
    'brookhurst': 2,
    'brooking': 2,
    'brookings': 2,
    'brookins': 2,
    'brookline': 2,
    'brooklyn': 2,
    'brookman': 2,
    'brookner': 2,
    'brookover': 3,
    'brooks': 1,
    'brookshier': 3,
    'brookshire': 2,
    'brookside': 2,
    'brookstone': 2,
    'brooksville': 2,
    'broom': 1,
    'broomall': 2,
    'broome': 1,
    'broomfield': 2,
    'brooms': 1,
    'broomstick': 2,
    'broomsticks': 2,
    'brophy': 2,
    'brosch': 1,
    'brose': 1,
    'brosh': 1,
    'broshears': 2,
    'brosious': 3,
    'brosius': 3,
    'broski': 2,
    'brosky': 2,
    'brosnahan': 3,
    'brosnan': 2,
    'bross': 1,
    'brossard': 2,
    'brossart': 2,
    'brosseau': 2,
    'brosser': 2,
    'brossette': 2,
    'brossman': 2,
    'brost': 1,
    'brostrom': 2,
    'brosz': 1,
    'broten': 2,
    'broth': 1,
    'brothel': 2,
    'brothels': 2,
    'brother': 2,
    'brotherhood': 3,
    'brotherly': 3,
    'brothers': 2,
    'brotherson': 3,
    'brotherton': 3,
    'broths': 1,
    'brotman': 2,
    'brott': 1,
    'brotzman': 2,
    'broucek': 2,
    'broudy': 2,
    'brough': 1,
    'brougham': 2,
    'brougher': 2,
    'broughman': 2,
    'brought': 1,
    'broughton': 2,
    'brouhaha': 3,
    'brouhard': 3,
    'brouillard': 2,
    'brouillet': 2,
    'brouillette': 2,
    'broun': 1,
    'brountas': 2,
    'brouse': 1,
    'broussard': 2,
    'brousseau': 2,
    'brousset': 2,
    'brouwer': 2,
    'brow': 1,
    'broward': 2,
    'browbeat': 2,
    'browder': 2,
    'browe': 1,
    'browed': 1,
    'brower': 2,
    'brown': 1,
    'brownback': 2,
    'browne': 1,
    'browned': 1,
    'brownell': 2,
    'browner': 2,
    'brownest': 2,
    'brownfield': 2,
    'brownfields': 2,
    'brownie': 2,
    'brownies': 2,
    'browning': 2,
    'brownish': 2,
    'brownlee': 2,
    'brownley': 2,
    'brownlie': 2,
    'brownlow': 2,
    'brownmiller': 3,
    'brownout': 2,
    'brownouts': 2,
    'brownrigg': 2,
    'browns': 1,
    'brownson': 2,
    'brownstein': 2,
    'brownstone': 2,
    'brownsville': 2,
    'brows': 1,
    'browse': 1,
    'browsed': 1,
    'browser': 2,
    'browsers': 2,
    'browsing': 2,
    'brox': 1,
    'broxson': 2,
    'broxterman': 3,
    'broxton': 2,
    'broy': 1,
    'broyard': 2,
    'broyhill': 2,
    'broyles': 1,
    'broz': 1,
    'brozek': 2,
    'brozman': 2,
    'brozovich': 3,
    'brozowski': 3,
    'brubaker': 3,
    'brubeck': 2,
    'brucato': 3,
    'bruce': 1,
    'bruch': 1,
    'bruchhausen': 3,
    'brucie': 2,
    'brucite': 2,
    'bruck': 1,
    'brucker': 2,
    'bruckheimer': 3,
    'bruckman': 2,
    'bruckner': 2,
    'brucks': 1,
    'bruder': 2,
    'brue': 1,
    'bruecher': 2,
    'brueck': 1,
    'brueckner': 2,
    'bruegge': 2,
    'brueggeman': 2,
    'brueggemann': 2,
    'brueggen': 2,
    'bruegger': 2,
    'bruehl': 1,
    'bruella': 3,
    'bruemmer': 2,
    'bruen': 1,
    'bruening': 2,
    'bruer': 2,
    'bruff': 1,
    'bruford': 2,
    'bruges': 2,
    'bruggeman': 2,
    'brugger': 2,
    'brugh': 1,
    'brugman': 2,
    'bruha': 2,
    'bruhl': 1,
    'bruhn': 1,
    'bruin': 2,
    'bruington': 3,
    'bruins': 2,
    'bruinsma': 3,
    'bruise': 1,
    'bruised': 1,
    'bruises': 2,
    'bruising': 2,
    'brule': 1,
    'bruley': 2,
    'brum': 1,
    'brumbach': 2,
    'brumback': 2,
    'brumbaugh': 2,
    'brumbelow': 3,
    'brumer': 2,
    'brumett': 2,
    'brumfield': 2,
    'brumit': 2,
    'brumitt': 2,
    'brumley': 2,
    'brumlow': 2,
    'brumm': 1,
    'brummel': 2,
    'brummell': 2,
    'brummer': 2,
    'brummet': 2,
    'brummett': 2,
    'brummitt': 2,
    'brummond': 2,
    'brun': 1,
    'bruna': 2,
    'brunch': 1,
    'brunches': 2,
    'bruncor': 2,
    'brundage': 2,
    'brundidge': 2,
    'brundige': 2,
    'brundtland': 2,
    'brune': 1,
    'bruneau': 2,
    'brunei': 2,
    'brunell': 2,
    'brunella': 3,
    'brunelle': 2,
    'brunelli': 3,
    'bruner': 2,
    'brunet': 2,
    'brunett': 2,
    'brunetta': 3,
    'brunette': 2,
    'brunettes': 2,
    'brunetti': 3,
    'brunetto': 3,
    'bruney': 2,
    'brungard': 2,
    'brungardt': 2,
    'brunger': 2,
    'brunhilda': 3,
    'bruni': 2,
    'brunick': 2,
    'bruning': 2,
    'brunjes': 2,
    'brunk': 1,
    'brunke': 1,
    'brunken': 2,
    'brunker': 2,
    'brunkhorst': 2,
    'brunkow': 2,
    'brunn': 1,
    'brunner': 2,
    'brunnhilde': 2,
    'bruno': 2,
    'bruns': 1,
    'brunskill': 2,
    'brunsman': 2,
    'brunson': 2,
    'brunsvold': 2,
    'brunswick': 2,
    'brunswig': 2,
    'brunt': 1,
    'bruntjen': 2,
    'brunton': 2,
    'brunty': 2,
    'bruntz': 1,
    'brus': 1,
    'brusca': 2,
    'bruschi': 2,
    'brusco': 2,
    'bruse': 1,
    'brusett': 2,
    'brusette': 2,
    'brush': 1,
    'brushaber': 3,
    'brushed': 1,
    'brushes': 2,
    'brushfire': 2,
    'brushfires': 2,
    'brushing': 2,
    'brushwork': 2,
    'brushy': 2,
    'bruske': 1,
    'bruski': 2,
    'brusky': 2,
    'bruso': 2,
    'brusque': 1,
    'brusquely': 2,
    'bruss': 1,
    'brusseau': 2,
    'brussel': 2,
    'brusselmans': 3,
    'brussels': 2,
    'brust': 1,
    'bruster': 2,
    'brustoloni': 4,
    'brut': 1,
    'brutal': 2,
    'brutalities': 4,
    'brutality': 4,
    'brutalization': 5,
    'brutalize': 3,
    'brutalized': 3,
    'brutalizes': 4,
    'brutalizing': 4,
    'brutally': 3,
    'brute': 1,
    'bruting': 2,
    'brutish': 2,
    'bruton': 2,
    'brutsche': 2,
    'brutus': 2,
    'bruun': 1,
    'bruxelles': 3,
    'bruyette': 2,
    'bruynes': 2,
    'bruzzese': 3,
    'bryan': 2,
    'bryans': 2,
    'bryant': 2,
    'bryars': 2,
    'bryce': 1,
    'bryden': 2,
    'brydges': 2,
    'brydie': 2,
    'brydon': 2,
    'brye': 1,
    'bryen': 2,
    'bryer': 2,
    'bryk': 1,
    'brymer': 2,
    'bryn': 1,
    'bryna': 2,
    'bryne': 1,
    'bryner': 2,
    'bryngelson': 3,
    'bryon': 2,
    'brys': 1,
    'bryson': 2,
    'brzezinski': 3,
    'brzoska': 2,
    'brzozowski': 3,
    'brzycki': 2,
    'bt': 2,
    'bta': 3,
    'bua': 2,
    'bub': 1,
    'bubar': 2,
    'bubb': 1,
    'bubba': 2,
    'bubble': 2,
    'bubbled': 2,
    'bubbles': 2,
    'bubbling': 3,
    'bubbly': 2,
    'bubeck': 2,
    'bubel': 2,
    'bubier': 3,
    'bubinga': 3,
    'bubka': 2,
    'bublitz': 2,
    'buboltz': 2,
    'bubolz': 2,
    'bubonic': 3,
    'buc': 1,
    'bucaro': 3,
    'bucase': 2,
    'buccal': 2,
    'buccaneer': 3,
    'buccaneers': 3,
    'buccellato': 4,
    'buccheri': 3,
    'bucci': 2,
    'bucciarelli': 4,
    'buccieri': 3,
    'buccino': 3,
    'bucco': 2,
    'buccola': 3,
    'bucek': 2,
    'bucey': 2,
    'buch': 1,
    'buchalter': 3,
    'buchan': 2,
    'buchanan': 3,
    'buchananism': 5,
    'buchanans': 3,
    'buchannan': 3,
    'buchanon': 3,
    'bucharest': 3,
    'buchberger': 3,
    'buchbinder': 3,
    'buche': 1,
    'buchen': 2,
    'buchenwald': 3,
    'bucher': 2,
    'buchert': 2,
    'buchheit': 2,
    'buchholtz': 2,
    'buchholz': 2,
    'buchi': 2,
    'buchinger': 3,
    'buchko': 2,
    'buchler': 3,
    'buchman': 2,
    'buchmann': 2,
    'buchmiller': 3,
    'buchner': 2,
    'bucholtz': 2,
    'bucholz': 2,
    'buchs': 1,
    'buchsbaum': 2,
    'buchta': 2,
    'buchter': 2,
    'buchwald': 2,
    'buck': 1,
    'buckalew': 3,
    'buckbee': 2,
    'bucked': 1,
    'buckel': 2,
    'buckelew': 3,
    'buckels': 2,
    'bucket': 2,
    'buckets': 2,
    'buckey': 2,
    'buckeye': 2,
    'buckeyes': 2,
    'buckhantz': 2,
    'buckholtz': 2,
    'buckholz': 2,
    'buckhorn': 2,
    'bucking': 2,
    'buckingham': 3,
    'buckland': 2,
    'buckle': 2,
    'buckled': 2,
    'buckler': 3,
    'buckles': 2,
    'bucklew': 2,
    'buckley': 2,
    'bucklin': 2,
    'buckling': 2,
    'buckman': 2,
    'buckmaster': 3,
    'buckminster': 3,
    'bucknam': 2,
    'bucknell': 2,
    'buckner': 2,
    'bucko': 2,
    'bucks': 1,
    'buckshot': 2,
    'buckskin': 2,
    'buckskins': 2,
    'buckstein': 2,
    'buckthorn': 2,
    'buckwalter': 3,
    'buckwheat': 2,
    'bucky': 2,
    'bucoba': 3,
    'bucolic': 3,
    'bucs': 1,
    'bucy': 2,
    'buczek': 2,
    'buczkowski': 3,
    'buczynski': 3,
    'bud': 1,
    'budai': 3,
    'budapest': 3,
    'buday': 2,
    'budd': 1,
    'budde': 1,
    'budden': 2,
    'buddenbrooks': 3,
    'buddenhagen': 4,
    'buddha': 2,
    'buddhism': 3,
    'buddhist': 2,
    'buddhists': 2,
    'buddie': 2,
    'buddier': 3,
    'buddiers': 3,
    'buddies': 2,
    'buddin': 2,
    'budding': 2,
    'buddy': 2,
    'buder': 2,
    'budge': 1,
    'budged': 1,
    'budget': 2,
    'budgetary': 4,
    'budgeted': 3,
    'budgeteer': 3,
    'budgeteers': 3,
    'budgeting': 3,
    'budgets': 2,
    'budging': 2,
    'budick': 2,
    'budiman': 3,
    'budin': 2,
    'budinger': 3,
    'budka': 2,
    'budke': 2,
    'budlong': 2,
    'budner': 2,
    'budney': 2,
    'budnick': 2,
    'budnik': 2,
    'budny': 2,
    'budreau': 2,
    'budrow': 2,
    'buds': 1,
    'budson': 2,
    'budvar': 2,
    'budweiser': 3,
    'budyonnovsk': 3,
    'budz': 1,
    'budzinski': 3,
    'budzyn': 2,
    'budzynski': 3,
    'bue': 1,
    'bueche': 1,
    'buechel': 2,
    'buechele': 2,
    'buechler': 3,
    'buechner': 2,
    'buege': 1,
    'buegler': 2,
    'buehl': 1,
    'buehler': 2,
    'buehner': 2,
    'buehrer': 2,
    'buehring': 2,
    'buehrle': 2,
    'bueker': 2,
    'buel': 1,
    'buell': 2,
    'buelow': 2,
    'buena': 2,
    'buendia': 3,
    'buenger': 2,
    'buening': 2,
    'bueno': 2,
    'buenos': 2,
    'buenrostro': 3,
    'buentello': 3,
    'buer': 2,
    'buerge': 1,
    'buerger': 2,
    'buerkle': 2,
    'buerry': 2,
    'buescher': 2,
    'buesing': 2,
    'bueter': 2,
    'buetow': 2,
    'buettner': 2,
    'bufano': 3,
    'bufe': 1,
    'bufete': 2,
    'buff': 1,
    'buffa': 2,
    'buffalo': 3,
    'buffalos': 3,
    'buffer': 2,
    'buffered': 2,
    'bufferin': 3,
    'buffering': 3,
    'buffers': 2,
    'buffet': 2,
    'buffeted': 2,
    'buffeting': 3,
    'buffets': 2,
    'buffett': 2,
    'buffin': 2,
    'buffington': 3,
    'buffkin': 2,
    'buffo': 2,
    'buffone': 3,
    'buffoon': 2,
    'bufford': 2,
    'buffs': 1,
    'buffton': 2,
    'buffum': 2,
    'buffy': 2,
    'bufkin': 2,
    'buford': 2,
    'bug': 1,
    'buga': 2,
    'bugaboo': 3,
    'bugai': 2,
    'bugaj': 2,
    'bugarin': 3,
    'bugatti': 3,
    'bugay': 2,
    'bugbee': 2,
    'bugeye': 2,
    'bugeyed': 2,
    'bugg': 1,
    'bugge': 1,
    'bugged': 1,
    'bugger': 2,
    'buggers': 2,
    'buggies': 2,
    'bugging': 2,
    'buggs': 1,
    'buggy': 2,
    'bugh': 1,
    'bugher': 2,
    'bugle': 2,
    'bugles': 2,
    'bugling': 3,
    'bugliosi': 4,
    'bugojno': 3,
    'bugs': 1,
    'bugsy': 2,
    'buhl': 1,
    'buhler': 2,
    'buhman': 2,
    'buhr': 1,
    'buhrman': 2,
    'buhrow': 2,
    'bui': 1,
    'buice': 1,
    'buick': 2,
    'buicks': 2,
    'buie': 2,
    'buikema': 3,
    'buil': 2,
    'build': 1,
    'buildable': 3,
    'builder': 2,
    'builders': 2,
    'building': 2,
    'buildings': 2,
    'builds': 1,
    'buildup': 2,
    'buildups': 2,
    'built': 1,
    'buis': 1,
    'buisson': 2,
    'buist': 2,
    'buitoni': 3,
    'buitrago': 3,
    'buitron': 2,
    'bujak': 2,
    'bujumbura': 4,
    'bukar': 2,
    'bukavu': 3,
    'buker': 2,
    'bukharin': 3,
    'bukovsky': 3,
    'bukowski': 3,
    'bula': 2,
    'bulat': 2,
    'bulb': 1,
    'bulbous': 2,
    'bulbs': 1,
    'bulemia': 4,
    'bulemic': 3,
    'bulen': 2,
    'bulent': 2,
    'buley': 2,
    'bulfinch': 2,
    'bulgaria': 4,
    'bulgarian': 4,
    'bulgarians': 4,
    'bulge': 1,
    'bulged': 1,
    'bulger': 2,
    'bulges': 2,
    'bulging': 2,
    'bulgrin': 2,
    'bulimia': 4,
    'bulimic': 3,
    'bulin': 2,
    'bulk': 1,
    'bulkeley': 3,
    'bulkhead': 2,
    'bulkheads': 2,
    'bulkier': 3,
    'bulkley': 2,
    'bulky': 2,
    'bull': 1,
    'bulla': 2,
    'bullard': 2,
    'bulldog': 2,
    'bulldogs': 2,
    'bulldoze': 2,
    'bulldozed': 2,
    'bulldozer': 3,
    'bulldozers': 3,
    'bulldozes': 3,
    'bulldozing': 3,
    'bullen': 2,
    'buller': 2,
    'bullet': 2,
    'bulletin': 3,
    'bulletins': 3,
    'bulletproof': 3,
    'bullets': 2,
    'bullfight': 2,
    'bullfighter': 3,
    'bullfighting': 3,
    'bullfights': 2,
    'bullfrog': 2,
    'bullfrogs': 2,
    'bullhead': 2,
    'bullheads': 2,
    'bullhorn': 2,
    'bullhorns': 2,
    'bullied': 2,
    'bullies': 2,
    'bullinger': 3,
    'bullington': 3,
    'bullins': 2,
    'bullion': 2,
    'bullis': 2,
    'bullish': 2,
    'bullishly': 3,
    'bullishness': 3,
    'bullitt': 2,
    'bullman': 2,
    'bulloch': 2,
    'bullock': 2,
    'bullocks': 2,
    'bullpen': 2,
    'bulls': 1,
    'bullseye': 2,
    'bullshit': 2,
    'bulluck': 2,
    'bullwinkle': 3,
    'bully': 2,
    'bullying': 3,
    'bulman': 2,
    'bulmer': 2,
    'bulova': 3,
    'bulow': 2,
    'bulrush': 2,
    'bulson': 2,
    'bult': 1,
    'bultema': 3,
    'bulthuis': 3,
    'bultman': 2,
    'bulwark': 2,
    'bulwinkle': 3,
    'bum': 1,
    'bumann': 2,
    'bumbalough': 3,
    'bumbarger': 3,
    'bumbaugh': 2,
    'bumble': 2,
    'bumbling': 3,
    'bumbly': 2,
    'bumgardner': 3,
    'bumgarner': 3,
    'bumiputra': 4,
    'bummed': 1,
    'bummer': 2,
    'bump': 1,
    'bumpas': 2,
    'bumpass': 2,
    'bumped': 1,
    'bumper': 2,
    'bumpers': 2,
    'bumpersticker': 4,
    'bumperstickers': 4,
    'bumping': 2,
    'bumpings': 2,
    'bumpkin': 2,
    'bumps': 1,
    'bumpus': 2,
    'bumpy': 2,
    'bums': 1,
    'bumstead': 2,
    'bun': 1,
    'bunce': 1,
    'bunch': 1,
    'bunche': 1,
    'bunched': 1,
    'bunches': 2,
    'bunching': 2,
    'bunchy': 2,
    'bund': 1,
    'bunda': 2,
    'bunde': 1,
    'bundesbank': 3,
    'bundespost': 3,
    'bundesrat': 3,
    'bundestag': 3,
    'bundeswehr': 3,
    'bundick': 2,
    'bundle': 2,
    'bundled': 2,
    'bundles': 2,
    'bundling': 3,
    'bundren': 3,
    'bundrick': 2,
    'bunds': 1,
    'bundschuh': 2,
    'bundy': 2,
    'bundys': 2,
    'bung': 1,
    'bungalow': 3,
    'bungalows': 3,
    'bungard': 2,
    'bunge': 1,
    'bungee': 2,
    'bunger': 2,
    'bungert': 2,
    'bungey': 2,
    'bungled': 2,
    'bungler': 2,
    'bunglers': 2,
    'bungling': 3,
    'bunk': 1,
    'bunke': 1,
    'bunker': 2,
    'bunkers': 2,
    'bunkley': 2,
    'bunks': 1,
    'bunn': 1,
    'bunnell': 2,
    'bunner': 2,
    'bunney': 2,
    'bunni': 2,
    'bunnie': 2,
    'bunnies': 2,
    'bunning': 2,
    'bunny': 2,
    'bunol': 2,
    'bunowski': 3,
    'buns': 1,
    'bunt': 1,
    'buntain': 2,
    'bunte': 1,
    'bunten': 2,
    'buntin': 2,
    'bunting': 2,
    'bunton': 2,
    'buntrock': 2,
    'buntstrock': 2,
    'buntyn': 2,
    'buntz': 1,
    'bunyan': 2,
    'bunyard': 2,
    'bunzl': 2,
    'buol': 1,
    'buonanno': 3,
    'buonicontis': 4,
    'buono': 2,
    'buonocore': 4,
    'buonomo': 3,
    'buoy': 2,
    'buoyancy': 3,
    'buoyant': 2,
    'buoyed': 2,
    'buoying': 2,
    'buoys': 2,
    'bupkus': 2,
    'bupp': 1,
    'buprenorphine': 4,
    'bur': 1,
    'burack': 2,
    'burak': 2,
    'buran': 2,
    'burandt': 2,
    'buras': 2,
    'burba': 2,
    'burbach': 2,
    'burback': 2,
    'burbage': 2,
    'burbank': 2,
    'burbidge': 2,
    'burble': 2,
    'burbled': 2,
    'burbles': 2,
    'burbling': 2,
    'burbridge': 2,
    'burby': 2,
    'burch': 1,
    'burcham': 2,
    'burchard': 2,
    'burchell': 2,
    'burcher': 2,
    'burchett': 2,
    'burchette': 2,
    'burchfield': 2,
    'burchill': 2,
    'burciaga': 4,
    'burck': 1,
    'burckhard': 2,
    'burckhardt': 2,
    'burd': 1,
    'burda': 2,
    'burdell': 2,
    'burden': 2,
    'burdened': 2,
    'burdening': 3,
    'burdens': 2,
    'burdensome': 3,
    'burdett': 2,
    'burdette': 2,
    'burdge': 1,
    'burdi': 2,
    'burdick': 2,
    'burdin': 2,
    'burdine': 2,
    'burdines': 2,
    'burditt': 2,
    'burdo': 2,
    'burdon': 2,
    'burdsall': 2,
    'bureau': 2,
    'bureaucracies': 4,
    'bureaucracy': 4,
    'bureaucrat': 3,
    'bureaucratese': 4,
    'bureaucratic': 4,
    'bureaucrats': 3,
    'bureaus': 2,
    'burek': 2,
    'burel': 2,
    'burell': 2,
    'buren': 2,
    'bures': 1,
    'buresh': 2,
    'burfeind': 2,
    'burfield': 2,
    'burford': 2,
    'burg': 1,
    'burgamy': 3,
    'burgan': 2,
    'burgard': 2,
    'burgdorf': 2,
    'burge': 1,
    'burgee': 2,
    'burgener': 3,
    'burgeon': 2,
    'burgeoned': 2,
    'burgeoning': 3,
    'burger': 2,
    'burgers': 2,
    'burgert': 2,
    'burges': 1,
    'burgeson': 3,
    'burgess': 2,
    'burget': 2,
    'burgett': 2,
    'burggraf': 2,
    'burghardt': 2,
    'burghart': 2,
    'burgher': 2,
    'burghley': 2,
    'burgin': 2,
    'burgio': 3,
    'burglar': 2,
    'burglaries': 3,
    'burglarize': 3,
    'burglarized': 3,
    'burglars': 2,
    'burglary': 3,
    'burgling': 2,
    'burgman': 2,
    'burgmaster': 3,
    'burgner': 2,
    'burgo': 2,
    'burgomaster': 4,
    'burgomasters': 4,
    'burgoon': 2,
    'burgos': 2,
    'burgoyne': 2,
    'burgundian': 4,
    'burgundians': 4,
    'burgundies': 3,
    'burgundy': 3,
    'burgy': 2,
    'burham': 2,
    'burhans': 2,
    'buri': 2,
    'burial': 3,
    'burials': 3,
    'burian': 3,
    'burich': 2,
    'buried': 2,
    'buries': 2,
    'burk': 1,
    'burkard': 2,
    'burkart': 2,
    'burke': 1,
    'burkeen': 2,
    'burkel': 2,
    'burkemper': 3,
    'burkert': 2,
    'burkes': 1,
    'burket': 2,
    'burkett': 2,
    'burkey': 2,
    'burkhalter': 3,
    'burkhammer': 3,
    'burkhard': 2,
    'burkhardt': 2,
    'burkhart': 2,
    'burkhead': 2,
    'burkholder': 3,
    'burkina': 3,
    'burkins': 2,
    'burkitt': 2,
    'burkland': 2,
    'burkle': 2,
    'burkley': 2,
    'burklow': 2,
    'burklund': 2,
    'burkman': 2,
    'burks': 1,
    'burkus': 2,
    'burl': 1,
    'burland': 2,
    'burlap': 2,
    'burlapped': 2,
    'burlatsky': 3,
    'burleigh': 2,
    'burleson': 3,
    'burlesque': 2,
    'burlew': 2,
    'burley': 2,
    'burling': 2,
    'burlingame': 3,
    'burlingham': 3,
    'burlington': 3,
    'burlison': 3,
    'burly': 2,
    'burma': 2,
    'burmah': 2,
    'burman': 2,
    'burmans': 2,
    'burmaster': 3,
    'burmeister': 3,
    'burmese': 2,
    'burmester': 3,
    'burn': 1,
    'burnable': 3,
    'burnaby': 3,
    'burnam': 2,
    'burnap': 2,
    'burnard': 2,
    'burndy': 2,
    'burne': 1,
    'burned': 1,
    'burnell': 2,
    'burner': 2,
    'burners': 2,
    'burnes': 1,
    'burness': 2,
    'burnet': 2,
    'burnett': 2,
    'burnette': 2,
    'burney': 2,
    'burnham': 2,
    'burning': 2,
    'burningham': 3,
    'burnings': 2,
    'burnish': 2,
    'burnished': 2,
    'burnley': 2,
    'burnout': 2,
    'burns': 1,
    'burnsed': 1,
    'burnside': 2,
    'burnstein': 2,
    'burnsworth': 2,
    'burnt': 1,
    'burnup': 2,
    'burnworth': 2,
    'buroker': 3,
    'burow': 2,
    'burp': 1,
    'burpee': 2,
    'burpees': 2,
    'burping': 2,
    'burpo': 2,
    'burr': 1,
    'burrage': 2,
    'burrell': 2,
    'burrer': 2,
    'burres': 1,
    'burress': 2,
    'burri': 2,
    'burridge': 2,
    'burrier': 3,
    'burright': 2,
    'burrill': 2,
    'burrington': 3,
    'burris': 2,
    'burriss': 3,
    'burrito': 3,
    'burritos': 3,
    'burritt': 2,
    'burro': 2,
    'burrola': 3,
    'burros': 2,
    'burrough': 2,
    'burroughs': 2,
    'burrous': 2,
    'burrow': 2,
    'burrower': 3,
    'burrowers': 3,
    'burrowes': 2,
    'burrowing': 3,
    'burrows': 2,
    'burrus': 2,
    'burruss': 2,
    'burry': 2,
    'bursch': 1,
    'burse': 1,
    'bursey': 2,
    'bursley': 2,
    'burson': 2,
    'burst': 1,
    'burstein': 2,
    'bursting': 2,
    'burston': 2,
    'bursts': 1,
    'burt': 1,
    'burtch': 1,
    'burtis': 2,
    'burtner': 2,
    'burtness': 2,
    'burtnett': 2,
    'burton': 2,
    'burts': 1,
    'burtt': 1,
    'burundi': 3,
    'burwell': 2,
    'burwick': 2,
    'bury': 2,
    'burying': 3,
    'burzynski': 3,
    'bus': 1,
    'busa': 2,
    'busalacchi': 4,
    'busam': 2,
    'busbee': 2,
    'busbey': 2,
    'busbin': 2,
    'busboom': 2,
    'busboy': 2,
    'busboys': 2,
    'busby': 2,
    'buscaglia': 4,
    'buscemi': 3,
    'busch': 1,
    'buschbaum': 2,
    'busche': 1,
    'buscher': 2,
    'buschman': 2,
    'buschmann': 2,
    'buse': 1,
    'bused': 1,
    'buseman': 2,
    'busenbark': 3,
    'buser': 2,
    'buses': 2,
    'busey': 2,
    'bush': 1,
    'busha': 2,
    'bushard': 2,
    'bushart': 2,
    'bushaw': 2,
    'bushby': 2,
    'bushee': 2,
    'bushel': 2,
    'bushell': 2,
    'bushels': 2,
    'busher': 2,
    'bushes': 2,
    'bushey': 2,
    'bushings': 2,
    'bushkin': 2,
    'bushman': 2,
    'bushmen': 2,
    'bushnell': 2,
    'bushong': 2,
    'bushway': 2,
    'bushwhack': 2,
    'bushwhacked': 2,
    'bushwhacking': 3,
    'bushwhacks': 2,
    'bushy': 2,
    'busic': 2,
    'busick': 2,
    'busied': 2,
    'busier': 3,
    'busiest': 3,
    'busily': 3,
    'business': 2,
    'businesses': 3,
    'businessland': 3,
    'businesslike': 3,
    'businessman': 3,
    'businessmen': 3,
    'businesspeople': 4,
    'businessperson': 4,
    'businessphone': 3,
    'businessphones': 3,
    'businesswoman': 4,
    'businesswomen': 4,
    'busing': 2,
    'busk': 1,
    'buske': 1,
    'busker': 2,
    'buskey': 2,
    'buskirk': 2,
    'buslease': 2,
    'busler': 3,
    'busload': 2,
    'busloads': 2,
    'buspar': 2,
    'buss': 1,
    'bussa': 2,
    'bussard': 2,
    'busse': 1,
    'bussed': 1,
    'bussell': 2,
    'bussen': 2,
    'busser': 2,
    'bussert': 2,
    'busses': 2,
    'bussey': 2,
    'bussi': 2,
    'bussie': 2,
    'bussiere': 3,
    'bussing': 2,
    'bussinger': 3,
    'bussman': 2,
    'bust': 1,
    'busta': 2,
    'bustamante': 4,
    'bustard': 2,
    'busted': 2,
    'buster': 2,
    'busters': 2,
    'busti': 2,
    'bustier': 2,
    'bustillo': 3,
    'bustillos': 3,
    'bustin': 2,
    'busting': 2,
    'bustle': 2,
    'bustling': 3,
    'busto': 2,
    'bustos': 2,
    'busts': 1,
    'busty': 2,
    'busulaki': 4,
    'buswell': 2,
    'busy': 2,
    'but': 1,
    'butala': 3,
    'butane': 2,
    'butare': 3,
    'butch': 1,
    'butchart': 2,
    'butcher': 2,
    'butchered': 2,
    'butchering': 3,
    'butchers': 2,
    'butchery': 3,
    'butchko': 2,
    'bute': 1,
    'buteau': 2,
    'buteco': 3,
    'butenhoff': 3,
    'butera': 3,
    'buterbaugh': 3,
    'buth': 1,
    'buthelezi': 4,
    'butka': 2,
    'butkiewicz': 3,
    'butkovich': 3,
    'butkus': 2,
    'butler': 2,
    'butlers': 2,
    'butman': 2,
    'butner': 2,
    'butorac': 3,
    'butrick': 2,
    'butros': 2,
    'buts': 1,
    'butsch': 1,
    'butson': 2,
    'butt': 1,
    'buttacavoli': 5,
    'buttafuoco': 4,
    'buttars': 2,
    'butte': 1,
    'butter': 2,
    'butterball': 3,
    'butterballs': 3,
    'butterbaugh': 3,
    'buttercup': 3,
    'buttercups': 3,
    'buttered': 2,
    'butterfat': 3,
    'butterfield': 3,
    'butterfields': 3,
    'butterflies': 3,
    'butterfly': 3,
    'butterick': 3,
    'buttering': 3,
    'buttermilk': 3,
    'buttermore': 3,
    'butters': 2,
    'butterscotch': 3,
    'butterworth': 3,
    'buttery': 3,
    'butthead': 2,
    'butting': 2,
    'buttitta': 3,
    'buttke': 2,
    'buttler': 2,
    'buttner': 2,
    'buttock': 2,
    'buttocks': 2,
    'button': 2,
    'buttoned': 2,
    'buttonhole': 3,
    'buttonholed': 3,
    'buttonholes': 3,
    'buttons': 2,
    'buttonville': 3,
    'buttram': 2,
    'buttress': 2,
    'buttressed': 2,
    'buttresses': 3,
    'buttressing': 3,
    'buttrey': 2,
    'buttrick': 2,
    'buttrum': 2,
    'buttry': 2,
    'butts': 1,
    'butulesi': 4,
    'butyl': 2,
    'butz': 1,
    'butzberger': 3,
    'butzen': 2,
    'butzer': 2,
    'butzin': 2,
    'buus': 1,
    'buxbaum': 2,
    'buxom': 2,
    'buxton': 2,
    'buy': 1,
    'buyback': 2,
    'buybacks': 2,
    'buyer': 2,
    'buyers': 2,
    'buying': 2,
    'buyout': 2,
    'buyouts': 2,
    'buys': 1,
    'buysse': 1,
    'buza': 2,
    'buzan': 2,
    'buzard': 2,
    'buzbee': 2,
    'buzby': 2,
    'buzek': 2,
    'buzz': 1,
    'buzzard': 2,
    'buzzards': 2,
    'buzze': 1,
    'buzzed': 1,
    'buzzell': 2,
    'buzzelli': 3,
    'buzzer': 2,
    'buzzes': 2,
    'buzzetta': 3,
    'buzzing': 2,
    'buzzword': 2,
    'buzzwords': 2,
    'buzzy': 2,
    'by': 1,
    'byam': 2,
    'byard': 1,
    'byars': 2,
    'byas': 2,
    'byassee': 3,
    'bybee': 2,
    'bye': 1,
    'byelorussia': 5,
    'byer': 2,
    'byerlein': 2,
    'byerley': 3,
    'byerly': 3,
    'byers': 2,
    'byfield': 2,
    'byford': 2,
    'bygone': 2,
    'bygones': 2,
    'byham': 2,
    'byington': 3,
    'byker': 2,
    'bykowski': 3,
    'byland': 2,
    'bylaw': 2,
    'bylaws': 2,
    'byler': 2,
    'byles': 1,
    'byline': 2,
    'bylines': 2,
    'bylsma': 2,
    'bylund': 2,
    'byner': 2,
    'bynes': 1,
    'bynoe': 2,
    'bynum': 2,
    'bypass': 2,
    'bypassed': 2,
    'bypasses': 3,
    'bypassing': 3,
    'byproduct': 3,
    'byproducts': 3,
    'byram': 2,
    'byrd': 1,
    'byrer': 2,
    'byrge': 1,
    'byrle': 2,
    'byrn': 1,
    'byrne': 1,
    'byrnes': 1,
    'byrns': 1,
    'byrom': 2,
    'byron': 2,
    'byrum': 2,
    'bystander': 3,
    'bystanders': 3,
    'bystrom': 2,
    'byte': 1,
    'bytes': 1,
    'byu': 3,
    'byun': 1,
    'byus': 2,
    'bywater': 3,
    'byway': 2,
    'byways': 2,
    'byword': 2,
    'byzantine': 3,
    'byzantium': 4,
    'c': 1,
    'ca': 1,
    'caan': 1,
    'cab': 1,
    'caba': 2,
    'cabal': 2,
    'caballero': 4,
    'caban': 2,
    'cabana': 3,
    'cabanas': 3,
    'cabaniss': 3,
    'cabaret': 3,
    'cabarets': 3,
    'cabbage': 2,
    'cabbages': 3,
    'cabbie': 2,
    'cabbies': 2,
    'cabby': 2,
    'cabdriver': 3,
    'cabdrivers': 3,
    'cabe': 1,
    'cabell': 2,
    'cabello': 3,
    'cabernet': 3,
    'cabernets': 3,
    'cabey': 2,
    'cabezas': 3,
    'cabin': 2,
    'cabinda': 3,
    'cabiness': 3,
    'cabinet': 3,
    'cabinetry': 3,
    'cabinets': 3,
    'cabins': 2,
    'cable': 2,
    'cablec': 2,
    'cablecomm': 3,
    'cablecomms': 3,
    'cabled': 2,
    'cablegram': 3,
    'cableone': 3,
    'cabler': 3,
    'cables': 2,
    'cablesystem': 4,
    'cablesystems': 4,
    'cabletron': 3,
    'cablevision': 4,
    'cabo': 2,
    'cabok': 2,
    'caboodle': 3,
    'caboose': 2,
    'cabooses': 3,
    'cabot': 2,
    'cabotage': 3,
    'cabral': 2,
    'cabrales': 3,
    'cabrall': 2,
    'cabranes': 2,
    'cabrera': 3,
    'cabrini': 3,
    'cabriolet': 4,
    'cabs': 1,
    'cac': 1,
    'cacace': 2,
    'cacaci': 3,
    'cacao': 3,
    'caccamise': 3,
    'caccamo': 3,
    'caccavale': 4,
    'caccia': 2,
    'cacciatore': 4,
    'cacciola': 3,
    'caceres': 3,
    'cache': 1,
    'cached': 1,
    'caches': 2,
    'cachet': 2,
    'caching': 2,
    'cacho': 2,
    'cacioppo': 3,
    'cacique': 2,
    'cackle': 2,
    'cackling': 3,
    'cacld': 2,
    'cacophony': 4,
    'cacti': 2,
    'cactus': 2,
    'cad': 1,
    'cada': 2,
    'cadam': 2,
    'cadaver': 3,
    'cadavers': 3,
    'cadbury': 3,
    'cadby': 2,
    'caddell': 2,
    'cadden': 2,
    'caddick': 2,
    'caddies': 2,
    'caddock': 2,
    'caddy': 2,
    'caddyshack': 3,
    'cade': 1,
    'cadell': 2,
    'cadena': 3,
    'cadence': 2,
    'cadences': 3,
    'cadenhead': 3,
    'cadet': 2,
    'cadets': 2,
    'cadiddlehopper': 5,
    'cadieux': 3,
    'cadillac': 3,
    'cadillacs': 3,
    'cadiz': 2,
    'cadle': 2,
    'cadman': 2,
    'cadmium': 3,
    'cadmus': 2,
    'cadnetix': 3,
    'cadogan': 3,
    'cadorette': 3,
    'cadotte': 2,
    'cadre': 2,
    'cadres': 2,
    'cadrone': 2,
    'cads': 1,
    'cadwalader': 4,
    'cadwallader': 4,
    'cadwell': 2,
    'cady': 2,
    'caen': 1,
    'caesar': 2,
    'caesarea': 4,
    'caesarean': 3,
    'caesareans': 3,
    'caesars': 2,
    'caetano': 3,
    'cafarella': 4,
    'cafarelli': 4,
    'cafaro': 3,
    'cafe': 2,
    'cafes': 2,
    'cafeteria': 5,
    'cafeterias': 5,
    'cafetizer': 4,
    'caffee': 2,
    'caffeinate': 3,
    'caffeinated': 4,
    'caffeine': 2,
    'cafferty': 3,
    'caffery': 3,
    'caffey': 2,
    'caffrey': 2,
    'cafiero': 4,
    'caftan': 2,
    'caftans': 2,
    'cagan': 2,
    'cage': 1,
    'caged': 1,
    'cages': 2,
    'cagey': 2,
    'caggiano': 4,
    'cagle': 2,
    'cagley': 2,
    'cagliari': 4,
    'cagney': 2,
    'caguas': 2,
    'cahalan': 3,
    'cahall': 2,
    'cahan': 2,
    'cahasa': 3,
    'cahill': 2,
    'cahn': 1,
    'cahners': 2,
    'cahoon': 2,
    'cahoot': 2,
    'cahoots': 2,
    'cahora': 3,
    'cahouet': 2,
    'cai': 1,
    'caiazzo': 3,
    'caicedo': 3,
    'cail': 1,
    'caillebotte': 2,
    'caillier': 3,
    'caillouet': 2,
    'cain': 1,
    'caine': 1,
    'caines': 1,
    'caiola': 3,
    'caire': 1,
    'cairn': 1,
    'cairnes': 1,
    'cairns': 1,
    'cairo': 2,
    'caison': 2,
    'caisse': 1,
    'caissie': 2,
    'caisson': 2,
    'caissons': 2,
    'caitlin': 2,
    'caito': 2,
    'cajole': 2,
    'cajoled': 2,
    'cajoling': 3,
    'cajun': 2,
    'cajuns': 2,
    'cake': 1,
    'caked': 1,
    'cakes': 1,
    'cal': 1,
    'cala': 2,
    'calabasas': 4,
    'calabrese': 4,
    'calabria': 4,
    'calabro': 3,
    'caladiums': 4,
    'calaf': 2,
    'calahan': 3,
    'calais': 2,
    'calamander': 4,
    'calamari': 4,
    'calame': 3,
    'calamia': 4,
    'calamine': 3,
    'calamities': 4,
    'calamitous': 4,
    'calamity': 4,
    'calan': 2,
    'calandra': 3,
    'calandro': 3,
    'calantha': 3,
    'calarco': 3,
    'calaveras': 4,
    'calaway': 3,
    'calbert': 2,
    'calbos': 2,
    'calcagni': 3,
    'calcagno': 3,
    'calcaterra': 4,
    'calcified': 3,
    'calcify': 3,
    'calcite': 2,
    'calcium': 3,
    'calcomp': 2,
    'calcote': 3,
    'calculate': 3,
    'calculated': 4,
    'calculates': 3,
    'calculating': 4,
    'calculation': 4,
    'calculations': 4,
    'calculator': 4,
    'calculators': 4,
    'calculus': 3,
    'calcutt': 2,
    'calcutta': 3,
    'caldeira': 3,
    'calder': 2,
    'caldera': 3,
    'calderaro': 4,
    'calderon': 3,
    'calderone': 4,
    'calderwood': 3,
    'caldor': 2,
    'caldrello': 3,
    'caldwell': 2,
    'cale': 1,
    'caleb': 2,
    'calebs': 2,
    'caleca': 3,
    'caledonia': 5,
    'caledonian': 5,
    'calef': 2,
    'calemburg': 3,
    'calendar': 3,
    'calendars': 3,
    'calendula': 4,
    'caler': 2,
    'calero': 3,
    'cales': 1,
    'caley': 2,
    'calf': 1,
    'calfed': 1,
    'calfee': 2,
    'calgary': 3,
    'calgene': 2,
    'calgon': 2,
    'calgroup': 2,
    'calhoon': 2,
    'calhoun': 2,
    'cali': 2,
    'calia': 3,
    'caliber': 3,
    'calibrate': 3,
    'calibrated': 4,
    'calibration': 4,
    'calico': 3,
    'calida': 3,
    'caliendo': 4,
    'calif': 4,
    'califano': 4,
    'califf': 2,
    'california': 4,
    'californian': 4,
    'californians': 4,
    'caligiuri': 4,
    'calill': 2,
    'calills': 2,
    'caliper': 3,
    'calipers': 3,
    'caliph': 2,
    'caliri': 3,
    'calise': 2,
    'calista': 3,
    'calisthenic': 4,
    'calisthenics': 4,
    'calite': 2,
    'calix': 2,
    'calk': 1,
    'calkin': 2,
    'calkins': 2,
    'call': 1,
    'calla': 2,
    'callable': 3,
    'callace': 2,
    'callaghan': 3,
    'callaham': 3,
    'callahan': 3,
    'callais': 2,
    'callan': 2,
    'callanan': 3,
    'calland': 2,
    'callander': 3,
    'callari': 3,
    'callas': 2,
    'callaway': 3,
    'calle': 1,
    'callebs': 2,
    'called': 1,
    'callegari': 4,
    'calleja': 3,
    'callejas': 3,
    'callen': 2,
    'callender': 3,
    'callens': 2,
    'caller': 2,
    'callers': 2,
    'callery': 3,
    'calles': 1,
    'calley': 2,
    'callicoat': 3,
    'callicott': 3,
    'callicutt': 3,
    'callie': 2,
    'callier': 3,
    'callies': 2,
    'calligan': 3,
    'calligrapher': 4,
    'calligraphic': 4,
    'calligraphy': 4,
    'callihan': 3,
    'callinan': 3,
    'calling': 2,
    'calliope': 4,
    'calliopes': 4,
    'callis': 2,
    'callison': 3,
    'callister': 3,
    'callous': 2,
    'calloused': 2,
    'callously': 3,
    'callousness': 3,
    'callow': 2,
    'calloway': 3,
    'calls': 1,
    'callula': 3,
    'callum': 2,
    'callus': 2,
    'cally': 2,
    'calm': 1,
    'calma': 2,
    'calmaquip': 3,
    'calmar': 2,
    'calmark': 2,
    'calmart': 2,
    'calmat': 2,
    'calmed': 1,
    'calmer': 2,
    'calmes': 2,
    'calmest': 2,
    'calming': 2,
    'calmly': 2,
    'calmness': 2,
    'calms': 1,
    'calnan': 2,
    'calny': 2,
    'calo': 2,
    'calogero': 4,
    'calor': 2,
    'caloric': 3,
    'calorie': 3,
    'calories': 3,
    'caloway': 3,
    'calpers': 2,
    'calpis': 2,
    'caltabiano': 5,
    'caltagirone': 5,
    'caltex': 2,
    'caltha': 2,
    'calton': 2,
    'caltrans': 2,
    'calumet': 3,
    'calumny': 3,
    'calutzi': 3,
    'caluzu': 3,
    'caluzzi': 3,
    'calvani': 3,
    'calvano': 3,
    'calvaries': 3,
    'calvary': 3,
    'calveras': 3,
    'calverley': 3,
    'calvert': 2,
    'calvery': 3,
    'calves': 1,
    'calvet': 2,
    'calvey': 2,
    'calvi': 2,
    'calvillo': 3,
    'calvin': 2,
    'calvina': 3,
    'calving': 2,
    'calvinist': 3,
    'calvino': 3,
    'calvo': 2,
    'calypso': 3,
    'calypsos': 3,
    'calyx': 2,
    'calzada': 3,
    'cam': 1,
    'cama': 2,
    'camacho': 3,
    'camaguey': 3,
    'caman': 2,
    'camara': 3,
    'camaraderie': 5,
    'camarata': 4,
    'camarena': 4,
    'camargo': 3,
    'camarillo': 4,
    'camaro': 3,
    'camaros': 3,
    'cambell': 2,
    'camber': 2,
    'cambex': 2,
    'cambior': 3,
    'cambodia': 4,
    'cambodian': 4,
    'cambodians': 4,
    'cambra': 2,
    'cambre': 2,
    'cambria': 3,
    'cambrian': 3,
    'cambridge': 2,
    'cambridgeport': 3,
    'cambridgeside': 3,
    'cambron': 2,
    'camburn': 2,
    'camby': 2,
    'camco': 2,
    'camcorder': 3,
    'camcorders': 3,
    'camden': 2,
    'camdessus': 3,
    'came': 1,
    'cameco': 3,
    'camejo': 3,
    'camel': 2,
    'camelot': 3,
    'camels': 2,
    'cameo': 3,
    'cameos': 3,
    'camera': 3,
    'cameraman': 4,
    'cameramen': 4,
    'cameras': 3,
    'camerer': 3,
    'camero': 3,
    'cameron': 3,
    'camerons': 3,
    'cameroon': 3,
    'camfield': 2,
    'camhi': 2,
    'camilla': 3,
    'camille': 2,
    'camilleri': 4,
    'camilli': 3,
    'camillo': 3,
    'caminiti': 4,
    'camino': 3,
    'camire': 3,
    'camm': 1,
    'cammack': 2,
    'cammarano': 4,
    'cammarata': 4,
    'cammarota': 4,
    'cammer': 2,
    'cammermeyer': 4,
    'cammie': 2,
    'cammisa': 3,
    'cammon': 2,
    'cammy': 2,
    'camorra': 3,
    'camouflage': 3,
    'camouflaged': 3,
    'camouflaging': 4,
    'camp': 1,
    'campa': 2,
    'campaign': 2,
    'campaigned': 2,
    'campaigner': 3,
    'campaigners': 3,
    'campaigning': 3,
    'campaigns': 2,
    'campana': 3,
    'campanale': 4,
    'campanaro': 4,
    'campanella': 4,
    'campanelli': 4,
    'campanis': 3,
    'campau': 2,
    'campbell': 2,
    'campbells': 2,
    'campeau': 2,
    'camped': 1,
    'campen': 2,
    'camper': 2,
    'campers': 2,
    'campesinos': 4,
    'campfield': 2,
    'campfire': 3,
    'campfires': 3,
    'campground': 2,
    'campgrounds': 2,
    'campi': 2,
    'camping': 2,
    'campion': 3,
    'campione': 4,
    'campise': 2,
    'campisi': 3,
    'campo': 2,
    'campobasso': 4,
    'campofrio': 4,
    'campoli': 3,
    'campopiano': 5,
    'campos': 2,
    'camps': 1,
    'campsite': 2,
    'campsites': 2,
    'campton': 2,
    'campus': 2,
    'campuses': 3,
    'campuzano': 4,
    'campy': 2,
    'camro': 2,
    'camry': 2,
    'camrys': 2,
    'camshaft': 2,
    'camshafts': 2,
    'camus': 2,
    'camuso': 3,
    'can': 1,
    'cana': 2,
    'canaan': 2,
    'canaanite': 3,
    'canace': 3,
    'canada': 3,
    'canadair': 3,
    'canaday': 3,
    'canadian': 4,
    'canadians': 4,
    'canadienne': 4,
    'canady': 3,
    'canal': 2,
    'canale': 3,
    'canales': 2,
    'canals': 2,
    'canam': 2,
    'canan': 2,
    'canandaigua': 4,
    'cananea': 4,
    'canard': 2,
    'canariensis': 5,
    'canaries': 3,
    'canary': 3,
    'canas': 2,
    'canasta': 3,
    'canavan': 3,
    'canaveral': 4,
    'canberra': 3,
    'canby': 2,
    'cancan': 2,
    'cancel': 2,
    'canceled': 2,
    'canceling': 3,
    'cancellation': 4,
    'cancellations': 4,
    'cancelled': 2,
    'cancelling': 3,
    'cancels': 2,
    'cancer': 2,
    'cancerous': 3,
    'cancerphobia': 5,
    'cancers': 2,
    'canchola': 3,
    'cancienne': 3,
    'cancilla': 3,
    'cancino': 3,
    'cancio': 3,
    'cancom': 2,
    'cancro': 2,
    'cancun': 2,
    'candace': 2,
    'candee': 2,
    'candela': 3,
    'candelabra': 4,
    'candelaria': 5,
    'candelario': 5,
    'candella': 3,
    'candellin': 3,
    'candia': 3,
    'candice': 2,
    'candid': 2,
    'candida': 3,
    'candidacies': 4,
    'candidacy': 4,
    'candidate': 3,
    'candidates': 3,
    'candidly': 3,
    'candido': 3,
    'candie': 2,
    'candied': 2,
    'candies': 2,
    'candilin': 3,
    'candiotti': 4,
    'candle': 2,
    'candlelight': 3,
    'candlemaker': 4,
    'candler': 3,
    'candles': 2,
    'candlestick': 3,
    'candlesticks': 3,
    'candlish': 2,
    'candor': 2,
    'candy': 2,
    'candyman': 3,
    'cane': 1,
    'caned': 1,
    'canedo': 3,
    'canedy': 3,
    'canelo': 3,
    'canepa': 3,
    'canes': 1,
    'canevari': 4,
    'canez': 2,
    'canfield': 2,
    'canfor': 2,
    'cangelosi': 4,
    'cangemi': 3,
    'cangialosi': 4,
    'cangiano': 4,
    'canham': 2,
    'canida': 3,
    'caniglia': 4,
    'canilles': 3,
    'canin': 2,
    'canine': 2,
    'canines': 2,
    'caning': 2,
    'canings': 2,
    'canino': 3,
    'canion': 2,
    'canipe': 3,
    'canister': 3,
    'canisters': 3,
    'canker': 2,
    'cankers': 2,
    'cann': 1,
    'cannabis': 3,
    'cannaday': 3,
    'cannady': 3,
    'cannan': 2,
    'cannata': 3,
    'cannavino': 4,
    'cannavo': 3,
    'canned': 1,
    'cannedy': 3,
    'cannell': 2,
    'cannella': 3,
    'cannelton': 3,
    'canner': 2,
    'canneries': 3,
    'cannery': 3,
    'cannes': 1,
    'canney': 2,
    'cannibal': 3,
    'cannibalism': 5,
    'cannibalization': 6,
    'cannibalize': 4,
    'cannibalizing': 5,
    'cannibals': 3,
    'canniff': 2,
    'canning': 2,
    'cannister': 3,
    'cannisters': 3,
    'cannistraro': 4,
    'cannizzaro': 4,
    'cannizzo': 3,
    'cannon': 2,
    'cannonball': 3,
    'cannondale': 3,
    'cannone': 3,
    'cannons': 2,
    'cannonsburg': 3,
    'cannot': 2,
    'canny': 2,
    'cano': 2,
    'canoe': 2,
    'canoed': 2,
    'canoeing': 3,
    'canoeist': 3,
    'canoes': 2,
    'canoga': 3,
    'canola': 3,
    'canon': 2,
    'canonic': 3,
    'canonical': 4,
    'canonico': 4,
    'canonie': 3,
    'canonization': 5,
    'canonize': 3,
    'canonized': 3,
    'canons': 2,
    'canopy': 3,
    'canosa': 3,
    'canova': 3,
    'canoy': 2,
    'canrad': 2,
    'canron': 2,
    'cans': 1,
    'canseco': 3,
    'cansler': 3,
    'canso': 2,
    'canson': 2,
    'canstar': 2,
    'cant': 1,
    'cantaloupe': 3,
    'cantaloupes': 3,
    'cantalupo': 4,
    'cantankerous': 4,
    'cantara': 3,
    'cantata': 3,
    'canteen': 2,
    'canteens': 2,
    'cantel': 2,
    'canter': 2,
    'canterbury': 4,
    'cantero': 3,
    'canterra': 3,
    'cantey': 2,
    'cantin': 2,
    'cantina': 3,
    'cantle': 2,
    'cantley': 2,
    'cantlin': 2,
    'cantlon': 2,
    'canto': 2,
    'canton': 2,
    'cantonal': 3,
    'cantone': 3,
    'cantonese': 3,
    'cantons': 2,
    'cantor': 2,
    'cantore': 2,
    'cantors': 2,
    'cantrall': 2,
    'cantrell': 2,
    'cantrelle': 2,
    'cantu': 2,
    'cantv': 3,
    'cantwell': 2,
    'canty': 2,
    'canuck': 2,
    'canucks': 2,
    'canup': 2,
    'canupp': 2,
    'canvas': 2,
    'canvases': 3,
    'canvass': 2,
    'canvassed': 2,
    'canvassers': 3,
    'canvasses': 3,
    'canvassing': 3,
    'canwest': 2,
    'canyon': 2,
    'canyons': 2,
    'canzano': 3,
    'canzoneri': 4,
    'cao': 1,
    'caouette': 2,
    'cap': 1,
    'capabilities': 5,
    'capability': 5,
    'capable': 3,
    'capacious': 3,
    'capacitance': 4,
    'capacities': 4,
    'capacitor': 4,
    'capacitors': 4,
    'capacity': 4,
    'capalbo': 3,
    'capaldi': 3,
    'capaldo': 3,
    'capano': 3,
    'capasso': 3,
    'capcom': 2,
    'cape': 1,
    'capece': 2,
    'capeci': 3,
    'caped': 1,
    'capehart': 2,
    'capek': 2,
    'capel': 2,
    'capell': 2,
    'capella': 3,
    'capelle': 2,
    'capelli': 3,
    'capello': 3,
    'capen': 2,
    'caper': 2,
    'capernaum': 4,
    'capers': 2,
    'caperton': 3,
    'capes': 1,
    'capetillo': 4,
    'capetown': 2,
    'capillaries': 4,
    'capillary': 4,
    'capistrano': 4,
    'capita': 3,
    'capital': 3,
    'capitalism': 5,
    'capitalist': 4,
    'capitalistic': 5,
    'capitalists': 4,
    'capitalization': 6,
    'capitalizations': 6,
    'capitalize': 4,
    'capitalized': 4,
    'capitalizes': 5,
    'capitalizing': 5,
    'capitals': 3,
    'capitan': 3,
    'capitano': 4,
    'capito': 3,
    'capitol': 3,
    'capitoline': 4,
    'capitols': 3,
    'capitulate': 4,
    'capitulated': 5,
    'capitulation': 5,
    'capizzi': 3,
    'caplan': 2,
    'caple': 2,
    'caples': 2,
    'capley': 2,
    'caplin': 2,
    'caplinger': 4,
    'capo': 2,
    'capobianco': 5,
    'capon': 2,
    'capone': 2,
    'caponi': 3,
    'caponigro': 4,
    'caporale': 4,
    'caporaso': 4,
    'capote': 2,
    'capoten': 3,
    'capozzi': 3,
    'capozzoli': 4,
    'capp': 1,
    'cappa': 2,
    'cappadona': 4,
    'capparelli': 4,
    'capped': 1,
    'cappel': 2,
    'cappella': 3,
    'cappelletti': 4,
    'cappelli': 3,
    'cappello': 3,
    'capper': 2,
    'cappetta': 3,
    'cappiello': 4,
    'capping': 2,
    'cappo': 2,
    'capps': 1,
    'cappuccino': 4,
    'cappuccio': 4,
    'cappy': 2,
    'capra': 2,
    'capraro': 3,
    'capri': 2,
    'capriati': 4,
    'caprice': 2,
    'caprices': 3,
    'capricious': 3,
    'capriciously': 4,
    'capricorn': 3,
    'caprio': 3,
    'capriotti': 4,
    'capron': 2,
    'caps': 1,
    'capsaicin': 3,
    'capshaw': 2,
    'capsize': 2,
    'capsized': 2,
    'capstan': 2,
    'capstick': 2,
    'capstone': 2,
    'capsule': 2,
    'capsules': 2,
    'captain': 2,
    'captaining': 3,
    'captains': 2,
    'caption': 2,
    'captioned': 2,
    'captioning': 3,
    'captions': 2,
    'captivate': 3,
    'captivated': 4,
    'captivates': 3,
    'captivating': 4,
    'captive': 2,
    'captives': 2,
    'captivity': 4,
    'captor': 2,
    'captors': 2,
    'capture': 2,
    'captured': 2,
    'captures': 2,
    'capturing': 3,
    'capua': 3,
    'capuano': 4,
    'caput': 2,
    'caputi': 3,
    'caputo': 3,
    'capwell': 2,
    'caq': 1,
    'car': 1,
    'cara': 2,
    'carabajal': 4,
    'caraballo': 4,
    'carabello': 4,
    'caracara': 4,
    'caracas': 3,
    'caracci': 3,
    'caracciolo': 4,
    'caradine': 4,
    'caradonna': 4,
    'carafate': 3,
    'carafe': 2,
    'caraher': 3,
    'caraker': 3,
    'caramandi': 4,
    'caramel': 3,
    'caramelize': 4,
    'caramelized': 4,
    'carangelo': 4,
    'carano': 3,
    'caras': 2,
    'carat': 2,
    'carats': 2,
    'carava': 3,
    'caravan': 3,
    'caravans': 3,
    'caravel': 3,
    'caravella': 4,
    'caravelle': 3,
    'caravello': 4,
    'caraveo': 4,
    'carawan': 3,
    'caraway': 3,
    'carbajal': 3,
    'carballo': 3,
    'carbary': 3,
    'carbaugh': 2,
    'carberry': 3,
    'carbide': 2,
    'carbin': 2,
    'carbine': 2,
    'carbo': 2,
    'carbohydrate': 4,
    'carbohydrates': 4,
    'carbon': 2,
    'carbonara': 4,
    'carbonaro': 4,
    'carbonate': 3,
    'carbonated': 4,
    'carbonates': 3,
    'carbondale': 3,
    'carbone': 2,
    'carbonell': 3,
    'carbonetto': 4,
    'carboni': 3,
    'carboniferous': 5,
    'carbonize': 3,
    'carbonized': 3,
    'carbonneau': 3,
    'carbons': 2,
    'carborundum': 4,
    'carboxylic': 4,
    'carboy': 2,
    'carburete': 3,
    'carbureted': 4,
    'carburetion': 4,
    'carburetor': 4,
    'carburetors': 4,
    'carby': 2,
    'carcass': 2,
    'carcasses': 3,
    'carcinogen': 4,
    'carcinogenic': 5,
    'carcinogens': 4,
    'carcinoma': 4,
    'carcione': 3,
    'card': 1,
    'carda': 2,
    'cardarelli': 4,
    'cardboard': 2,
    'cardboards': 2,
    'carded': 2,
    'cardell': 2,
    'cardella': 3,
    'cardello': 3,
    'carden': 2,
    'cardenas': 3,
    'carder': 2,
    'cardholder': 3,
    'cardholders': 3,
    'cardia': 3,
    'cardiac': 3,
    'cardiel': 2,
    'cardiff': 2,
    'cardigan': 3,
    'cardigans': 3,
    'cardiges': 3,
    'cardillo': 3,
    'cardin': 2,
    'cardinal': 3,
    'cardinale': 4,
    'cardinali': 4,
    'cardinals': 3,
    'cardio': 3,
    'cardiologist': 5,
    'cardiologists': 5,
    'cardiology': 5,
    'cardiopulmonary': 7,
    'cardiovascular': 6,
    'cardis': 2,
    'cardiss': 2,
    'cardizem': 3,
    'cardoen': 3,
    'cardon': 2,
    'cardona': 3,
    'cardone': 3,
    'cardoni': 3,
    'cardoon': 2,
    'cardosa': 3,
    'cardosi': 3,
    'cardoso': 3,
    'cardoza': 3,
    'cardozo': 3,
    'cards': 1,
    'carducci': 3,
    'cardwell': 2,
    'cardy': 2,
    'care': 1,
    'cared': 1,
    'careen': 2,
    'careened': 2,
    'careening': 3,
    'careens': 2,
    'career': 2,
    'careercom': 3,
    'careering': 3,
    'careerism': 4,
    'careerist': 3,
    'careerists': 3,
    'careers': 2,
    'carefree': 2,
    'careful': 2,
    'carefully': 3,
    'caregiver': 3,
    'caregivers': 3,
    'caregiving': 3,
    'carel': 2,
    'careless': 2,
    'carelessly': 3,
    'carelessness': 3,
    'carella': 3,
    'carelli': 3,
    'carello': 3,
    'carelock': 3,
    'caremark': 2,
    'carena': 3,
    'careplus': 2,
    'carer': 2,
    'cares': 1,
    'caress': 2,
    'caresse': 2,
    'caressed': 2,
    'caresses': 3,
    'caressing': 3,
    'caretaker': 3,
    'caretakers': 3,
    'careunit': 3,
    'careunits': 3,
    'carew': 2,
    'carey': 2,
    'carfagno': 3,
    'carfora': 3,
    'cargal': 2,
    'cargile': 2,
    'cargill': 2,
    'cargo': 2,
    'cargoes': 2,
    'cargonoff': 3,
    'cargos': 2,
    'carhart': 2,
    'caribbean': 4,
    'caribe': 2,
    'caribou': 3,
    'caricature': 4,
    'caricatured': 4,
    'caricatures': 4,
    'caricaturist': 5,
    'carico': 3,
    'caricom': 3,
    'caridad': 3,
    'caridi': 3,
    'carie': 2,
    'cariello': 4,
    'carignan': 3,
    'cariker': 3,
    'carilla': 3,
    'carillo': 3,
    'carillon': 3,
    'carilou': 3,
    'carin': 2,
    'carine': 3,
    'caring': 2,
    'carini': 3,
    'carinii': 3,
    'carino': 3,
    'carioca': 4,
    'caris': 2,
    'carisbrook': 3,
    'carissa': 3,
    'carita': 3,
    'carithers': 3,
    'carjack': 2,
    'carjacked': 2,
    'carjacker': 3,
    'carjackers': 3,
    'carjacking': 3,
    'carjackings': 3,
    'carjacks': 2,
    'carkhuff': 2,
    'carl': 1,
    'carla': 2,
    'carlan': 2,
    'carland': 2,
    'carlberg': 2,
    'carle': 2,
    'carleen': 2,
    'carlen': 2,
    'carlene': 2,
    'carles': 1,
    'carleton': 2,
    'carley': 2,
    'carli': 2,
    'carlile': 2,
    'carlin': 2,
    'carliner': 3,
    'carling': 2,
    'carlini': 3,
    'carlino': 3,
    'carlisi': 3,
    'carlisle': 2,
    'carlita': 3,
    'carlito': 3,
    'carll': 1,
    'carlo': 2,
    'carload': 2,
    'carloading': 3,
    'carloadings': 3,
    'carloads': 2,
    'carlock': 2,
    'carlon': 2,
    'carlone': 3,
    'carloni': 3,
    'carlos': 2,
    'carlotta': 3,
    'carlough': 2,
    'carlow': 2,
    'carls': 1,
    'carlsbad': 2,
    'carlsberg': 2,
    'carlsen': 2,
    'carlson': 2,
    'carlsson': 2,
    'carlstadt': 2,
    'carlston': 2,
    'carlstrom': 2,
    'carlton': 2,
    'carlucci': 3,
    'carly': 2,
    'carlyle': 2,
    'carlyon': 3,
    'carlzon': 2,
    'carma': 2,
    'carmack': 2,
    'carmaker': 3,
    'carmakers': 3,
    'carman': 2,
    'carmany': 3,
    'carmean': 3,
    'carmel': 2,
    'carmela': 3,
    'carmelita': 4,
    'carmelite': 3,
    'carmelo': 3,
    'carmen': 2,
    'carmer': 2,
    'carmical': 3,
    'carmichael': 3,
    'carmickle': 3,
    'carmie': 2,
    'carmike': 2,
    'carmine': 2,
    'carmita': 3,
    'carmody': 3,
    'carmon': 2,
    'carmona': 3,
    'carmony': 3,
    'carmouche': 2,
    'carmoy': 2,
    'carmy': 2,
    'carn': 1,
    'carnage': 2,
    'carnahan': 3,
    'carnal': 2,
    'carnathan': 3,
    'carnation': 3,
    'carnations': 3,
    'carnauba': 3,
    'carnaud': 2,
    'carne': 1,
    'carneal': 2,
    'carnegie': 3,
    'carnegies': 3,
    'carneiro': 3,
    'carnell': 2,
    'carner': 2,
    'carnes': 1,
    'carnett': 2,
    'carnevale': 4,
    'carney': 2,
    'carnicero': 4,
    'carnine': 3,
    'carnival': 3,
    'carnivals': 3,
    'carnivore': 3,
    'carnivores': 3,
    'carnivorous': 4,
    'carnley': 2,
    'carns': 1,
    'caro': 2,
    'carob': 2,
    'caroche': 2,
    'carol': 2,
    'carolan': 3,
    'carolco': 3,
    'carole': 2,
    'carolina': 4,
    'carolinas': 4,
    'caroline': 3,
    'carolingian': 5,
    'carolinian': 5,
    'carolinians': 5,
    'carollan': 3,
    'carollo': 3,
    'carols': 2,
    'carolus': 3,
    'carolyn': 3,
    'carolyne': 3,
    'caron': 2,
    'carone': 2,
    'caronna': 3,
    'carosella': 4,
    'caroselli': 4,
    'carosi': 3,
    'carota': 3,
    'carotene': 3,
    'carotenes': 3,
    'carotenuto': 5,
    'carothers': 3,
    'carotid': 3,
    'carouse': 2,
    'carousel': 3,
    'carousing': 3,
    'carow': 2,
    'carozza': 3,
    'carp': 1,
    'carpal': 2,
    'carpencic': 3,
    'carpenito': 4,
    'carpenter': 3,
    'carpenters': 3,
    'carpentier': 4,
    'carpentieri': 4,
    'carpentry': 3,
    'carper': 2,
    'carpet': 2,
    'carpetbagger': 4,
    'carpetbaggers': 4,
    'carpeted': 3,
    'carpeting': 3,
    'carpets': 2,
    'carpinelli': 4,
    'carping': 2,
    'carpino': 3,
    'carpio': 3,
    'carpool': 2,
    'carport': 2,
    'carports': 2,
    'carr': 1,
    'carra': 2,
    'carragher': 3,
    'carraher': 3,
    'carranco': 3,
    'carrano': 3,
    'carranza': 3,
    'carrara': 3,
    'carras': 2,
    'carrasco': 3,
    'carrasquel': 3,
    'carrasquillo': 4,
    'carraway': 3,
    'carrawell': 3,
    'carre': 1,
    'carreira': 3,
    'carreiro': 3,
    'carreker': 3,
    'carrel': 2,
    'carrell': 2,
    'carreno': 3,
    'carreon': 3,
    'carrera': 3,
    'carreras': 3,
    'carrere': 3,
    'carrero': 3,
    'carretta': 3,
    'carrey': 2,
    'carriage': 2,
    'carriages': 3,
    'carrian': 3,
    'carrick': 2,
    'carrico': 3,
    'carrie': 2,
    'carried': 2,
    'carrier': 3,
    'carriere': 3,
    'carriero': 3,
    'carriers': 3,
    'carries': 2,
    'carrig': 2,
    'carrigan': 3,
    'carriger': 3,
    'carrigg': 2,
    'carriker': 3,
    'carrillo': 3,
    'carringer': 3,
    'carrington': 3,
    'carrino': 3,
    'carrion': 3,
    'carris': 2,
    'carrisyn': 3,
    'carrithers': 3,
    'carriveau': 3,
    'carrizales': 4,
    'carro': 2,
    'carrol': 2,
    'carroll': 2,
    'carrollton': 3,
    'carron': 2,
    'carrot': 2,
    'carrothers': 3,
    'carrots': 2,
    'carrousel': 3,
    'carrow': 2,
    'carrozza': 3,
    'carrubba': 3,
    'carruth': 2,
    'carruthers': 3,
    'carry': 2,
    'carryanne': 3,
    'carryforward': 4,
    'carryforwards': 4,
    'carrying': 3,
    'carryover': 3,
    'cars': 1,
    'carse': 1,
    'carsey': 2,
    'carsick': 2,
    'carson': 2,
    'carstarphen': 3,
    'carsten': 2,
    'carstens': 2,
    'carstensen': 3,
    'carswell': 2,
    'cart': 1,
    'carta': 2,
    'cartagena': 4,
    'cartaya': 3,
    'carte': 1,
    'carted': 2,
    'cartee': 2,
    'cartel': 2,
    'cartels': 2,
    'carter': 2,
    'cartera': 3,
    'carteret': 3,
    'carters': 2,
    'cartersville': 3,
    'cartesian': 3,
    'carthage': 2,
    'carthaginian': 5,
    'cartier': 3,
    'cartilage': 3,
    'carting': 2,
    'cartland': 2,
    'cartledge': 2,
    'cartlidge': 2,
    'cartmell': 2,
    'cartmill': 2,
    'cartner': 2,
    'carton': 2,
    'cartoneros': 4,
    'cartons': 2,
    'cartoon': 2,
    'cartooning': 3,
    'cartoonist': 3,
    'cartoonists': 3,
    'cartoons': 2,
    'cartrette': 2,
    'cartridge': 2,
    'cartridges': 3,
    'carts': 1,
    'cartusciello': 5,
    'cartwheel': 2,
    'cartwheels': 2,
    'cartwright': 2,
    'carty': 2,
    'caruana': 4,
    'carucci': 3,
    'caruso': 3,
    'carusone': 4,
    'caruth': 2,
    'caruthers': 3,
    'carvajal': 3,
    'carvalho': 3,
    'carve': 1,
    'carved': 1,
    'carvel': 2,
    'carvell': 2,
    'carver': 2,
    'carvers': 2,
    'carves': 1,
    'carvey': 2,
    'carville': 2,
    'carvin': 2,
    'carving': 2,
    'carvings': 2,
    'carwell': 2,
    'carwile': 2,
    'cary': 2,
    'caryl': 2,
    'caryn': 2,
    'cas': 1,
    'casa': 2,
    'casablanca': 4,
    'casad': 2,
    'casada': 3,
    'casado': 3,
    'casados': 3,
    'casady': 3,
    'casagrande': 4,
    'casal': 2,
    'casale': 3,
    'casali': 3,
    'casalino': 4,
    'casals': 2,
    'casamento': 4,
    'casanova': 4,
    'casares': 3,
    'casarez': 3,
    'casas': 2,
    'casassa': 3,
    'casaus': 2,
    'casavant': 3,
    'casazza': 3,
    'casbah': 2,
    'cascade': 2,
    'cascaded': 3,
    'cascades': 2,
    'cascading': 3,
    'casciano': 4,
    'casciato': 3,
    'cascio': 3,
    'casco': 2,
    'cascone': 3,
    'casdorph': 2,
    'case': 1,
    'casebeer': 3,
    'casebier': 4,
    'casebolt': 2,
    'casein': 2,
    'casella': 3,
    'caselli': 3,
    'caseload': 2,
    'caseloads': 3,
    'casera': 3,
    'caserta': 3,
    'cases': 2,
    'casesa': 3,
    'casework': 2,
    'caseworker': 3,
    'caseworkers': 3,
    'casey': 2,
    'caseze': 3,
    'casgrain': 2,
    'cash': 1,
    'cashatt': 2,
    'cashdollar': 3,
    'cashed': 1,
    'cashen': 2,
    'casher': 2,
    'cashes': 2,
    'cashew': 2,
    'cashews': 2,
    'cashflow': 2,
    'cashflows': 2,
    'cashier': 2,
    'cashiered': 2,
    'cashiers': 2,
    'cashin': 2,
    'cashing': 2,
    'cashion': 2,
    'cashless': 2,
    'cashman': 2,
    'cashmere': 2,
    'cashmeres': 2,
    'cashmore': 2,
    'cashon': 2,
    'cashway': 2,
    'cashways': 2,
    'cashwell': 2,
    'casiano': 4,
    'casias': 3,
    'casillas': 3,
    'casillo': 3,
    'casimir': 3,
    'casimiro': 4,
    'casing': 2,
    'casings': 2,
    'casino': 3,
    'casinos': 3,
    'casio': 3,
    'cask': 1,
    'casket': 2,
    'caskets': 2,
    'caskey': 2,
    'casks': 1,
    'casler': 3,
    'caslin': 2,
    'casner': 2,
    'caso': 2,
    'casola': 3,
    'cason': 2,
    'caspar': 2,
    'casper': 2,
    'caspers': 2,
    'caspersen': 3,
    'casperson': 3,
    'caspian': 3,
    'cass': 1,
    'cassada': 3,
    'cassaday': 3,
    'cassady': 3,
    'cassandra': 3,
    'cassandras': 3,
    'cassani': 3,
    'cassano': 3,
    'cassar': 2,
    'cassara': 3,
    'cassarino': 4,
    'cassaro': 3,
    'cassata': 3,
    'cassatt': 2,
    'cassavetes': 4,
    'casseb': 2,
    'cassedy': 3,
    'cassel': 2,
    'casselberry': 4,
    'cassell': 2,
    'cassella': 3,
    'cassells': 2,
    'casselman': 3,
    'cassels': 2,
    'cassens': 2,
    'casserly': 3,
    'casserole': 3,
    'cassese': 3,
    'cassetta': 3,
    'cassette': 2,
    'cassettes': 2,
    'cassetty': 3,
    'cassia': 3,
    'cassiday': 3,
    'cassidy': 3,
    'cassie': 2,
    'cassin': 2,
    'cassinelli': 4,
    'cassini': 3,
    'cassino': 3,
    'cassis': 2,
    'cassity': 3,
    'casso': 2,
    'cassock': 2,
    'casson': 2,
    'cassone': 3,
    'cassoni': 3,
    'cassowary': 4,
    'casstevens': 4,
    'cast': 1,
    'casta': 2,
    'castagna': 3,
    'castagno': 3,
    'castagnola': 4,
    'castaldi': 3,
    'castaldo': 3,
    'castaneda': 4,
    'castano': 3,
    'castanon': 3,
    'castaway': 3,
    'castaways': 3,
    'caste': 1,
    'casteel': 2,
    'casteen': 2,
    'castel': 2,
    'castell': 2,
    'castellana': 4,
    'castellani': 4,
    'castellano': 4,
    'castellanos': 4,
    'castellaw': 3,
    'castelli': 3,
    'castello': 3,
    'castellon': 3,
    'castellucci': 4,
    'castelluccio': 5,
    'castellvi': 3,
    'castelo': 3,
    'casten': 2,
    'caster': 2,
    'casterline': 3,
    'casters': 2,
    'castes': 1,
    'castigate': 3,
    'castigated': 4,
    'castigates': 3,
    'castigating': 4,
    'castiglia': 4,
    'castiglione': 5,
    'castilla': 3,
    'castille': 2,
    'castilleja': 4,
    'castillo': 3,
    'castillon': 3,
    'castine': 3,
    'casting': 2,
    'castings': 2,
    'castio': 3,
    'castle': 2,
    'castleberry': 4,
    'castleman': 3,
    'castles': 2,
    'castner': 2,
    'casto': 2,
    'castoff': 2,
    'castoffs': 2,
    'caston': 2,
    'castonguay': 3,
    'castor': 2,
    'castorena': 3,
    'castoro': 3,
    'castpart': 2,
    'castparts': 2,
    'castrate': 2,
    'castrated': 3,
    'castrates': 2,
    'castrating': 3,
    'castration': 3,
    'castrations': 3,
    'castrejon': 3,
    'castrillon': 3,
    'castro': 2,
    'castrol': 2,
    'castronova': 4,
    'castronovo': 4,
    'castrucci': 3,
    'castruita': 3,
    'casts': 1,
    'casual': 3,
    'casually': 4,
    'casualness': 4,
    'casuals': 3,
    'casualties': 4,
    'casualty': 4,
    'caswell': 2,
    'cat': 1,
    'cataclysm': 4,
    'cataclysmic': 4,
    'catacomb': 3,
    'catacombs': 3,
    'catacosinos': 5,
    'catain': 2,
    'catalan': 3,
    'catalano': 4,
    'catalanotto': 5,
    'cataldi': 3,
    'cataldo': 3,
    'catalfamo': 4,
    'catalina': 4,
    'catalog': 3,
    'cataloged': 3,
    'cataloger': 4,
    'catalogers': 4,
    'cataloging': 4,
    'catalogs': 3,
    'catalogue': 3,
    'catalogued': 3,
    'catalogues': 3,
    'cataloguing': 4,
    'catalonia': 4,
    'catalonian': 4,
    'catalyst': 3,
    'catalysts': 3,
    'catalytic': 4,
    'catalyze': 3,
    'catalyzed': 3,
    'catamaran': 4,
    'catamarans': 4,
    'catamount': 3,
    'catanese': 4,
    'catania': 3,
    'catano': 3,
    'catanzarite': 4,
    'catanzaro': 4,
    'catapano': 4,
    'catapult': 3,
    'catapulted': 4,
    'catapulting': 4,
    'catapults': 3,
    'cataract': 3,
    'cataracts': 3,
    'catastrophe': 4,
    'catastrophes': 4,
    'catastrophic': 4,
    'catatonic': 4,
    'catawba': 3,
    'catbird': 2,
    'catcall': 2,
    'catcalls': 2,
    'catch': 1,
    'catchacan': 3,
    'catchall': 2,
    'catcher': 2,
    'catchers': 2,
    'catches': 2,
    'catching': 2,
    'catchings': 2,
    'catchment': 2,
    'catchup': 2,
    'catchword': 2,
    'catchy': 2,
    'cate': 1,
    'catechism': 4,
    'categorical': 5,
    'categorically': 5,
    'categories': 4,
    'categorization': 6,
    'categorize': 4,
    'categorized': 4,
    'categorizes': 5,
    'categorizing': 5,
    'category': 4,
    'catelli': 3,
    'catena': 3,
    'cater': 2,
    'catered': 2,
    'caterer': 3,
    'caterers': 3,
    'catering': 3,
    'caterino': 4,
    'caterpillar': 4,
    'caterpillars': 4,
    'caterpiller': 4,
    'caters': 2,
    'caterwaul': 3,
    'cates': 1,
    'catfish': 2,
    'catharine': 2,
    'catharines': 2,
    'catharsis': 3,
    'cathartic': 3,
    'cathay': 2,
    'cathcart': 2,
    'cathedral': 3,
    'cathedrals': 3,
    'cathell': 2,
    'cather': 2,
    'catherina': 4,
    'catherine': 3,
    'catherines': 2,
    'catherman': 3,
    'cathers': 2,
    'catherwood': 3,
    'catheter': 3,
    'catheters': 3,
    'cathey': 2,
    'cathie': 2,
    'cathleen': 2,
    'cathmor': 3,
    'cathode': 2,
    'cathodes': 2,
    'catholic': 2,
    'catholicism': 5,
    'catholics': 2,
    'cathy': 2,
    'catija': 3,
    'catino': 3,
    'catkins': 2,
    'catledge': 2,
    'catlett': 2,
    'catlike': 2,
    'catlin': 2,
    'catnap': 2,
    'catnip': 2,
    'cato': 2,
    'catoe': 2,
    'catolica': 4,
    'caton': 2,
    'catone': 2,
    'catrambone': 3,
    'catrett': 2,
    'catron': 2,
    'cats': 1,
    'catskill': 2,
    'catskills': 2,
    'catsup': 2,
    'catt': 1,
    'cattanach': 3,
    'cattaneo': 4,
    'cattani': 3,
    'cattell': 2,
    'catterall': 3,
    'catterson': 3,
    'catterton': 3,
    'cattle': 2,
    'cattlemen': 3,
    'catto': 2,
    'cattolica': 4,
    'catton': 2,
    'catty': 2,
    'catwalk': 2,
    'catwoman': 3,
    'cau': 1,
    'cauble': 2,
    'caucasian': 3,
    'caucasians': 3,
    'caucasus': 3,
    'caucus': 2,
    'caucuses': 3,
    'caudal': 2,
    'caudell': 2,
    'caudill': 2,
    'caudillo': 3,
    'caudle': 2,
    'cauffman': 2,
    'caufield': 2,
    'caughey': 2,
    'caughlin': 2,
    'caughman': 2,
    'caughron': 2,
    'caught': 1,
    'caul': 1,
    'caulder': 2,
    'cauldron': 2,
    'cauley': 2,
    'caulfield': 2,
    'cauliflower': 4,
    'caulk': 1,
    'caulking': 2,
    'caulkins': 2,
    'causal': 2,
    'causalities': 4,
    'causality': 4,
    'causation': 3,
    'causative': 3,
    'causby': 2,
    'cause': 1,
    'caused': 1,
    'causer': 2,
    'causes': 2,
    'causeway': 2,
    'causeways': 2,
    'causey': 2,
    'causing': 2,
    'caustic': 2,
    'cauterization': 5,
    'cauterize': 3,
    'cauterized': 3,
    'cauterizes': 4,
    'cauterizing': 4,
    'cauthen': 2,
    'cauthon': 2,
    'cauthorn': 2,
    'caution': 2,
    'cautionary': 4,
    'cautioned': 2,
    'cautioning': 3,
    'cautions': 2,
    'cautious': 2,
    'cautiously': 3,
    'cautiousness': 3,
    'cava': 2,
    'cavaco': 3,
    'cavagnaro': 4,
    'cavalcade': 3,
    'cavalier': 3,
    'cavaliere': 4,
    'cavalieri': 4,
    'cavalierly': 4,
    'cavaliers': 3,
    'cavallaro': 4,
    'cavallero': 4,
    'cavalli': 3,
    'cavallo': 3,
    'cavalry': 3,
    'cavan': 2,
    'cavanagh': 3,
    'cavanah': 3,
    'cavanaugh': 3,
    'cavaness': 3,
    'cavataio': 5,
    'cavazos': 3,
    'cave': 1,
    'caveat': 3,
    'caveats': 3,
    'caved': 1,
    'cavell': 2,
    'caveman': 2,
    'caven': 2,
    'cavenaugh': 3,
    'cavender': 3,
    'cavendish': 3,
    'caver': 2,
    'caverly': 3,
    'cavern': 2,
    'cavernous': 3,
    'caverns': 2,
    'cavers': 2,
    'caves': 1,
    'cavett': 2,
    'cavey': 2,
    'caviar': 3,
    'cavin': 2,
    'caviness': 3,
    'caving': 2,
    'cavins': 2,
    'cavities': 3,
    'cavitt': 2,
    'cavity': 3,
    'cavness': 2,
    'cavort': 2,
    'cavorting': 3,
    'caw': 1,
    'cawley': 2,
    'cawood': 2,
    'cawsl': 2,
    'cawthon': 2,
    'cawthorn': 2,
    'cawthorne': 2,
    'caxton': 2,
    'cay': 1,
    'caya': 2,
    'cayce': 1,
    'cayenne': 2,
    'cayer': 2,
    'cayes': 1,
    'caylin': 2,
    'caylor': 2,
    'cayman': 2,
    'caymans': 2,
    'cayne': 1,
    'cayson': 2,
    'cayton': 2,
    'cayuses': 3,
    'caywood': 2,
    'cazares': 3,
    'cazenove': 3,
    'cazier': 3,
    'cbs': 3,
    'cc': 2,
    'ccs': 3,
    'cd': 2,
    'cdc': 3,
    'cdebaca': 4,
    'cdrom': 3,
    'cdroms': 3,
    'cds': 2,
    'cea': 3,
    'ceara': 2,
    'cearley': 2,
    'ceasar': 2,
    'cease': 1,
    'ceased': 1,
    'ceasefire': 3,
    'ceasefires': 3,
    'ceaseless': 2,
    'ceaselessly': 3,
    'ceaser': 2,
    'ceases': 2,
    'ceasing': 2,
    'ceaucescu': 3,
    'ceausescu': 3,
    'ceballos': 3,
    'cebu': 2,
    'cebula': 3,
    'cebulski': 3,
    'cecala': 3,
    'ceccarelli': 4,
    'cecchi': 2,
    'cecchini': 3,
    'cecconi': 3,
    'cece': 1,
    'cecelia': 3,
    'cecere': 3,
    'cech': 1,
    'ceci': 2,
    'cecil': 2,
    'cecile': 2,
    'cecilia': 3,
    'cecin': 2,
    'ceco': 2,
    'cecola': 3,
    'cecos': 2,
    'cedar': 2,
    'cedars': 2,
    'cede': 1,
    'ceded': 2,
    'cedeno': 3,
    'ceder': 2,
    'cederberg': 3,
    'cedergren': 3,
    'cederholm': 3,
    'cederquist': 3,
    'cedes': 1,
    'cedillo': 3,
    'ceding': 2,
    'cedras': 2,
    'cedric': 2,
    'cedrone': 3,
    'cees': 1,
    'cefalo': 3,
    'cefalu': 3,
    'cegielski': 3,
    'ceiling': 2,
    'ceilings': 2,
    'ceja': 2,
    'cejka': 2,
    'cel': 1,
    'cela': 2,
    'celadon': 3,
    'celandine': 3,
    'celanese': 3,
    'celani': 3,
    'celano': 3,
    'celaya': 3,
    'cele': 1,
    'celeb': 2,
    'celebate': 3,
    'celebrant': 3,
    'celebrants': 3,
    'celebrate': 3,
    'celebrated': 4,
    'celebrates': 3,
    'celebrating': 4,
    'celebration': 4,
    'celebrations': 4,
    'celebratory': 5,
    'celebre': 3,
    'celebrities': 4,
    'celebrity': 4,
    'celebs': 2,
    'celena': 3,
    'celene': 2,
    'celentano': 4,
    'celerity': 4,
    'celeron': 3,
    'celery': 3,
    'celesta': 3,
    'celeste': 2,
    'celestial': 3,
    'celestin': 3,
    'celestina': 4,
    'celestine': 4,
    'celestino': 4,
    'celestory': 4,
    'celia': 2,
    'celibacy': 4,
    'celibate': 3,
    'celica': 3,
    'celie': 2,
    'celimene': 3,
    'celina': 3,
    'celinda': 3,
    'celine': 2,
    'celio': 3,
    'celis': 2,
    'cell': 1,
    'cella': 2,
    'cellar': 2,
    'cellars': 2,
    'celled': 1,
    'celli': 2,
    'cellini': 3,
    'cellio': 3,
    'cellist': 2,
    'cellmark': 2,
    'cellnet': 2,
    'cello': 2,
    'cellophane': 3,
    'cellphone': 2,
    'cellpro': 2,
    'cells': 1,
    'cellstar': 2,
    'cellucci': 3,
    'cellular': 3,
    'celluloid': 3,
    'cellulosa': 4,
    'cellulose': 3,
    'celmer': 2,
    'celnik': 2,
    'celo': 2,
    'celona': 3,
    'celosia': 4,
    'cels': 1,
    'celsius': 3,
    'celso': 2,
    'celt': 1,
    'celtic': 2,
    'celtics': 2,
    'celts': 1,
    'cement': 2,
    'cemented': 3,
    'cementing': 3,
    'cementos': 3,
    'cemeteries': 4,
    'cemetery': 4,
    'cemex': 2,
    'cemp': 1,
    'cencall': 2,
    'cenci': 2,
    'cencor': 2,
    'cendejas': 3,
    'cenergy': 3,
    'ceniceros': 4,
    'cenith': 2,
    'cenozoic': 4,
    'censer': 2,
    'censor': 2,
    'censored': 2,
    'censoring': 3,
    'censors': 2,
    'censorship': 3,
    'censure': 2,
    'censured': 2,
    'census': 2,
    'censuses': 3,
    'cent': 1,
    'centanni': 3,
    'centaur': 2,
    'centauri': 3,
    'centaurs': 2,
    'centavos': 3,
    'centel': 2,
    'centenarian': 5,
    'centenarians': 5,
    'centenary': 4,
    'centennial': 4,
    'centeno': 3,
    'center': 2,
    'centerbanc': 3,
    'centerbank': 3,
    'centered': 2,
    'centerfielder': 4,
    'centerfold': 3,
    'centering': 3,
    'centerior': 4,
    'centerpiece': 3,
    'centerre': 2,
    'centers': 2,
    'centerville': 3,
    'centex': 2,
    'centigrade': 3,
    'centigram': 3,
    'centime': 2,
    'centimes': 2,
    'centimeter': 4,
    'centimeters': 4,
    'centipede': 3,
    'centner': 2,
    'centocor': 3,
    'centofanti': 4,
    'centola': 3,
    'centoxin': 3,
    'central': 2,
    'centrale': 2,
    'centralia': 4,
    'centralism': 4,
    'centralists': 3,
    'centrality': 4,
    'centralization': 5,
    'centralize': 3,
    'centralized': 3,
    'centralizing': 4,
    'centrally': 3,
    'centram': 2,
    'centre': 2,
    'centrella': 3,
    'centres': 2,
    'centrex': 2,
    'centrifugal': 4,
    'centrifuge': 3,
    'centrifuges': 4,
    'centrist': 2,
    'centrists': 2,
    'centro': 2,
    'centromin': 3,
    'centronics': 3,
    'centrust': 2,
    'cents': 1,
    'centum': 2,
    'centuri': 3,
    'centuries': 3,
    'centurion': 4,
    'century': 3,
    'cenvill': 2,
    'cep': 1,
    'cepeda': 3,
    'cepero': 3,
    'cephalon': 3,
    'cephalopod': 4,
    'cephalosporin': 5,
    'cephas': 2,
    'cephus': 2,
    'cera': 2,
    'ceraceous': 3,
    'ceradyne': 3,
    'cerami': 3,
    'ceramic': 3,
    'ceramics': 3,
    'cerankosky': 4,
    'cerasoli': 4,
    'ceravolo': 4,
    'cerbone': 2,
    'cercone': 3,
    'cerda': 2,
    'cereal': 3,
    'cereals': 3,
    'cerebral': 3,
    'cerebrally': 4,
    'ceredase': 3,
    'cereghino': 4,
    'cerelia': 4,
    'ceremonial': 5,
    'ceremonies': 4,
    'ceremony': 4,
    'cereno': 3,
    'ceres': 2,
    'cerezo': 3,
    'cerf': 1,
    'ceridian': 4,
    'cerino': 3,
    'cerio': 3,
    'cerise': 2,
    'cermak': 2,
    'cern': 1,
    'cerna': 2,
    'cerney': 2,
    'cerniglia': 4,
    'cernuda': 3,
    'cerny': 2,
    'cerone': 3,
    'cerra': 2,
    'cerrato': 3,
    'cerreta': 3,
    'cerrito': 3,
    'cerritos': 3,
    'cerro': 2,
    'cerrone': 3,
    'cerruti': 3,
    'cerska': 2,
    'certain': 2,
    'certainly': 3,
    'certainteed': 3,
    'certainties': 3,
    'certainty': 3,
    'certificate': 4,
    'certificates': 4,
    'certification': 5,
    'certifications': 5,
    'certified': 3,
    'certifies': 3,
    'certify': 3,
    'certifying': 4,
    'certitude': 3,
    'certo': 2,
    'certron': 2,
    'cerulli': 3,
    'cerullo': 3,
    'cerus': 2,
    'cerutti': 3,
    'cervantes': 3,
    'cervantez': 3,
    'cerveceria': 5,
    'cervenka': 3,
    'cerveny': 3,
    'cervera': 3,
    'cervesato': 4,
    'cerveza': 3,
    'cervi': 2,
    'cervical': 3,
    'cervini': 3,
    'cervix': 2,
    'cervone': 3,
    'ceryl': 2,
    'cesar': 2,
    'cesare': 3,
    'cesario': 4,
    'cesaro': 3,
    'cesarz': 2,
    'cesena': 3,
    'cesium': 3,
    'cespedes': 3,
    'cessation': 3,
    'cessna': 2,
    'cesspool': 2,
    'cestaro': 3,
    'ceta': 2,
    'cetacean': 3,
    'cetec': 2,
    'cetera': 3,
    'cetus': 2,
    'cevallos': 3,
    'cevaxs': 3,
    'ceylon': 2,
    'cezanne': 2,
    'cha': 1,
    'chablis': 2,
    'chabon': 2,
    'chabot': 2,
    'chacabuco': 4,
    'chace': 1,
    'chachere': 2,
    'chachi': 2,
    'chacin': 2,
    'chacko': 2,
    'chacon': 2,
    'chad': 1,
    'chadbourne': 2,
    'chadd': 1,
    'chadderdon': 3,
    'chaddock': 2,
    'chadel': 2,
    'chadian': 3,
    'chadick': 2,
    'chadli': 2,
    'chadron': 2,
    'chadwell': 2,
    'chadwick': 2,
    'chae': 1,
    'chaebol': 2,
    'chafe': 1,
    'chafed': 1,
    'chafee': 2,
    'chafes': 1,
    'chafete': 2,
    'chaff': 1,
    'chaffee': 2,
    'chaffin': 2,
    'chaffins': 2,
    'chaffy': 2,
    'chafin': 2,
    'chafing': 2,
    'chagall': 2,
    'chagnon': 2,
    'chagrin': 2,
    'chagrined': 2,
    'chai': 1,
    'chaidez': 3,
    'chaiken': 2,
    'chaikin': 2,
    'chaim': 2,
    'chain': 1,
    'chained': 1,
    'chaining': 2,
    'chains': 1,
    'chainsaw': 2,
    'chainsaws': 2,
    'chair': 1,
    'chaired': 1,
    'chaires': 1,
    'chairez': 3,
    'chairing': 2,
    'chairman': 2,
    'chairmanship': 3,
    'chairmanships': 3,
    'chairmen': 2,
    'chairpeople': 3,
    'chairperson': 3,
    'chairs': 1,
    'chairwoman': 3,
    'chairwomen': 3,
    'chaise': 1,
    'chaisson': 2,
    'chait': 1,
    'chajet': 2,
    'chalabi': 3,
    'chalasani': 4,
    'chalcedony': 4,
    'chalet': 2,
    'chalets': 2,
    'chalfant': 2,
    'chalfin': 2,
    'chalice': 2,
    'chalifoux': 3,
    'chalk': 1,
    'chalked': 1,
    'chalker': 2,
    'chalking': 2,
    'chalkley': 2,
    'chalks': 1,
    'challender': 3,
    'challenge': 2,
    'challenged': 2,
    'challenger': 3,
    'challengers': 3,
    'challengery': 4,
    'challenges': 3,
    'challenging': 3,
    'challis': 2,
    'chalmers': 2,
    'chalmette': 2,
    'chaloupka': 3,
    'chaloux': 2,
    'chalsty': 2,
    'chalupa': 3,
    'cham': 1,
    'chamber': 2,
    'chambered': 2,
    'chamberlain': 3,
    'chamberland': 3,
    'chamberlayne': 3,
    'chamberlin': 3,
    'chambers': 2,
    'chamblee': 2,
    'chambless': 2,
    'chamblin': 2,
    'chambliss': 2,
    'chambon': 2,
    'chameleon': 4,
    'chamlee': 2,
    'chamlong': 2,
    'chamness': 2,
    'chamonix': 3,
    'chamorro': 3,
    'champ': 1,
    'champa': 2,
    'champagne': 2,
    'champagnes': 2,
    'champaign': 2,
    'champeau': 2,
    'champine': 2,
    'champion': 3,
    'championed': 3,
    'championing': 4,
    'champions': 3,
    'championship': 4,
    'championships': 4,
    'champlain': 2,
    'champlin': 2,
    'champney': 2,
    'champoux': 2,
    'champs': 1,
    'chan': 1,
    'chana': 2,
    'chance': 1,
    'chancellor': 3,
    'chancellors': 3,
    'chancery': 3,
    'chances': 2,
    'chancey': 2,
    'chancy': 2,
    'chand': 1,
    'chanda': 2,
    'chandelier': 3,
    'chandeliers': 3,
    'chandler': 2,
    'chandley': 2,
    'chandon': 2,
    'chandra': 2,
    'chandrika': 3,
    'chandross': 2,
    'chanel': 2,
    'chaney': 2,
    'chang': 1,
    'changchun': 2,
    'change': 1,
    'changeable': 3,
    'changed': 1,
    'changeover': 3,
    'changeovers': 3,
    'changer': 2,
    'changers': 2,
    'changes': 2,
    'changing': 2,
    'changsho': 2,
    'chanin': 2,
    'chanishiva': 4,
    'chanley': 2,
    'channel': 2,
    'channeled': 2,
    'channeling': 3,
    'channell': 2,
    'channels': 2,
    'channing': 2,
    'channon': 2,
    'chanos': 2,
    'chant': 1,
    'chantal': 2,
    'chanted': 2,
    'chantilly': 3,
    'chanting': 2,
    'chants': 1,
    'chanukah': 3,
    'chany': 2,
    'chao': 1,
    'chaos': 2,
    'chaotic': 3,
    'chap': 1,
    'chapa': 2,
    'chaparral': 3,
    'chaparro': 3,
    'chapas': 2,
    'chapdelaine': 3,
    'chapek': 2,
    'chapel': 2,
    'chapell': 2,
    'chapelle': 2,
    'chaperone': 3,
    'chaperones': 3,
    'chaperoning': 4,
    'chapin': 2,
    'chaplain': 2,
    'chaplains': 2,
    'chaplan': 2,
    'chaplin': 2,
    'chapman': 2,
    'chapnick': 2,
    'chapoton': 3,
    'chapp': 1,
    'chappaquiddick': 4,
    'chappel': 2,
    'chappelear': 3,
    'chappell': 2,
    'chappelle': 2,
    'chappie': 2,
    'chapple': 2,
    'chappuis': 3,
    'chaps': 1,
    'chapter': 2,
    'chapters': 2,
    'chaput': 2,
    'char': 1,
    'chara': 2,
    'character': 3,
    'characteristic': 5,
    'characteristically': 6,
    'characteristics': 5,
    'characterization': 6,
    'characterizations': 6,
    'characterize': 4,
    'characterized': 4,
    'characterizes': 5,
    'characterizing': 5,
    'characters': 3,
    'charade': 2,
    'charades': 2,
    'charalambos': 4,
    'charasse': 2,
    'charboneau': 3,
    'charbonneau': 3,
    'charbonnet': 3,
    'charcoal': 2,
    'charcoals': 2,
    'chard': 1,
    'chardonnay': 3,
    'chardonnays': 3,
    'charen': 2,
    'charest': 3,
    'charette': 2,
    'charge': 1,
    'chargeable': 3,
    'charged': 1,
    'charger': 2,
    'chargers': 2,
    'charges': 2,
    'chargeurs': 2,
    'charging': 2,
    'chargit': 2,
    'chargois': 2,
    'chariot': 3,
    'chariots': 3,
    'charisma': 3,
    'charismatic': 4,
    'charismatics': 4,
    'charissa': 3,
    'charita': 3,
    'charitable': 4,
    'charitably': 4,
    'charities': 3,
    'charity': 3,
    'charla': 2,
    'charland': 2,
    'charlatan': 3,
    'charlatans': 3,
    'charlayne': 2,
    'charle': 1,
    'charlebois': 3,
    'charleen': 2,
    'charlemagne': 3,
    'charlene': 2,
    'charles': 1,
    'charleston': 2,
    'charlestown': 2,
    'charlesworth': 3,
    'charlet': 2,
    'charlevoix': 3,
    'charley': 2,
    'charlie': 2,
    'charlier': 3,
    'charline': 2,
    'charlot': 2,
    'charlotte': 2,
    'charlottesville': 3,
    'charlottetown': 3,
    'charlotteville': 3,
    'charls': 1,
    'charlson': 2,
    'charlton': 2,
    'charm': 1,
    'charmain': 2,
    'charmaine': 2,
    'charmed': 1,
    'charmer': 2,
    'charmers': 2,
    'charmian': 3,
    'charming': 2,
    'charmingly': 3,
    'charmion': 3,
    'charms': 1,
    'charney': 2,
    'charnley': 2,
    'charnock': 2,
    'charon': 2,
    'charpentier': 3,
    'charpie': 2,
    'charred': 1,
    'charren': 2,
    'charrette': 2,
    'charrier': 3,
    'charring': 2,
    'charron': 2,
    'charry': 2,
    'chart': 1,
    'charted': 2,
    'charter': 2,
    'chartered': 2,
    'charterhouse': 3,
    'chartering': 3,
    'charters': 2,
    'chartier': 3,
    'charting': 2,
    'chartist': 2,
    'chartists': 2,
    'chartrand': 2,
    'chartres': 2,
    'chartreuse': 2,
    'charts': 1,
    'chartwell': 2,
    'charvat': 2,
    'charwoman': 3,
    'charwomen': 3,
    'chary': 2,
    'charyl': 2,
    'chas': 1,
    'chase': 1,
    'chased': 1,
    'chasen': 2,
    'chaser': 2,
    'chasers': 2,
    'chases': 2,
    'chasin': 2,
    'chasing': 2,
    'chaska': 2,
    'chasm': 2,
    'chason': 2,
    'chasse': 1,
    'chassis': 2,
    'chastain': 2,
    'chaste': 1,
    'chasteen': 2,
    'chasten': 2,
    'chastened': 2,
    'chastise': 2,
    'chastised': 2,
    'chastises': 3,
    'chastising': 3,
    'chastity': 3,
    'chat': 1,
    'chataqua': 3,
    'chateau': 2,
    'chateaux': 2,
    'chatfield': 2,
    'chatham': 2,
    'chatichai': 3,
    'chatihachi': 4,
    'chatman': 2,
    'chatmon': 2,
    'chato': 2,
    'chats': 1,
    'chatswood': 2,
    'chatsworth': 2,
    'chattahoochee': 4,
    'chattanooga': 4,
    'chattanuga': 4,
    'chatted': 2,
    'chattel': 2,
    'chatter': 2,
    'chattering': 3,
    'chatterjee': 3,
    'chatterton': 3,
    'chattin': 2,
    'chatting': 2,
    'chatty': 2,
    'chatwal': 2,
    'chatwin': 2,
    'chatz': 1,
    'chau': 1,
    'chaucer': 2,
    'chaudhry': 2,
    'chaudoin': 2,
    'chauffeur': 2,
    'chauffeured': 2,
    'chauffeurs': 2,
    'chaumet': 2,
    'chaunce': 1,
    'chauncey': 2,
    'chauncy': 2,
    'chaus': 1,
    'chausse': 1,
    'chaussee': 2,
    'chautauqua': 3,
    'chautauquan': 3,
    'chautauquans': 3,
    'chauvin': 2,
    'chauvinism': 4,
    'chauvinist': 3,
    'chauvinistic': 4,
    'chauvinists': 3,
    'chavalit': 3,
    'chavarria': 4,
    'chavers': 2,
    'chaves': 1,
    'chavez': 2,
    'chavin': 2,
    'chavira': 3,
    'chavis': 2,
    'chavitz': 2,
    'chavous': 2,
    'chaw': 1,
    'chawla': 2,
    'chayon': 2,
    'chaz': 1,
    'chazanoff': 3,
    'chazen': 2,
    'chazov': 2,
    'chazz': 1,
    'che': 1,
    'chea': 1,
    'cheadle': 2,
    'cheane': 1,
    'cheaney': 2,
    'cheap': 1,
    'cheapen': 2,
    'cheapened': 2,
    'cheapening': 3,
    'cheapens': 2,
    'cheaper': 2,
    'cheapest': 2,
    'cheaply': 2,
    'cheapness': 2,
    'cheapo': 2,
    'cheapskate': 2,
    'cheat': 1,
    'cheated': 2,
    'cheater': 2,
    'cheaters': 2,
    'cheatham': 2,
    'cheating': 2,
    'cheats': 1,
    'cheatum': 2,
    'cheatwood': 2,
    'chebrikov': 3,
    'checchi': 2,
    'chechen': 2,
    'chechens': 2,
    'chechnya': 3,
    'chechnyan': 3,
    'chechnyans': 3,
    'check': 1,
    'checkbook': 2,
    'checkbooks': 2,
    'checked': 1,
    'checker': 2,
    'checkerboard': 3,
    'checkerboarding': 4,
    'checkerboards': 3,
    'checkered': 2,
    'checkers': 2,
    'checkerspot': 3,
    'checketts': 2,
    'checking': 2,
    'checklist': 2,
    'checklists': 2,
    'checkmate': 2,
    'checkmates': 2,
    'checkoff': 2,
    'checkout': 2,
    'checkouts': 2,
    'checkpoint': 2,
    'checkpoints': 2,
    'checks': 1,
    'checkup': 2,
    'checkups': 2,
    'cheddar': 2,
    'cheddars': 2,
    'chedester': 3,
    'chee': 1,
    'cheech': 1,
    'cheek': 1,
    'cheekbone': 2,
    'cheekbones': 2,
    'cheeked': 1,
    'cheeks': 1,
    'cheektowaga': 4,
    'cheeky': 2,
    'cheely': 2,
    'cheer': 1,
    'cheered': 1,
    'cheerful': 2,
    'cheerfully': 3,
    'cheerier': 3,
    'cheeriest': 3,
    'cheering': 2,
    'cheerio': 3,
    'cheerios': 3,
    'cheerleader': 3,
    'cheerleaders': 3,
    'cheerleading': 3,
    'cheers': 1,
    'cheery': 2,
    'cheese': 1,
    'cheeseburger': 3,
    'cheeseburgers': 3,
    'cheesecake': 2,
    'cheeseman': 2,
    'cheeser': 2,
    'cheeses': 2,
    'cheesier': 3,
    'cheesiest': 3,
    'cheesman': 2,
    'cheesy': 2,
    'cheetah': 2,
    'cheetahs': 2,
    'cheetal': 2,
    'cheetan': 2,
    'cheetham': 2,
    'cheever': 2,
    'chef': 1,
    'chefitz': 2,
    'chefs': 1,
    'cheil': 1,
    'cheimi': 2,
    'chek': 1,
    'chekhov': 2,
    'chelan': 2,
    'chelette': 2,
    'chelf': 1,
    'chell': 1,
    'chellis': 2,
    'chelmsford': 2,
    'chelsea': 2,
    'chelyabinsk': 3,
    'chem': 1,
    'chema': 2,
    'chemania': 3,
    'chemclear': 2,
    'chemdesign': 3,
    'chemed': 2,
    'chemel': 2,
    'chemerinsky': 4,
    'chemetron': 3,
    'chemfix': 2,
    'chemical': 3,
    'chemically': 3,
    'chemicals': 3,
    'chemie': 2,
    'chemins': 2,
    'chemise': 2,
    'chemist': 2,
    'chemistry': 3,
    'chemists': 2,
    'chemlawn': 2,
    'chemo': 2,
    'chemotherapy': 5,
    'chemyr': 2,
    'chen': 1,
    'chenard': 2,
    'chenault': 2,
    'chenette': 2,
    'chenevert': 3,
    'cheney': 2,
    'cheng': 1,
    'chengdu': 2,
    'chengxiang': 2,
    'chenier': 3,
    'chennai': 2,
    'chennault': 2,
    'chenoweth': 3,
    'chenowitz': 3,
    'chenxiang': 2,
    'cheong': 2,
    'cher': 1,
    'cheramie': 3,
    'cherbourg': 2,
    'cheri': 2,
    'cherico': 3,
    'cherie': 2,
    'cherish': 2,
    'cherished': 2,
    'cherishes': 3,
    'cherishing': 3,
    'chermak': 2,
    'chern': 1,
    'cherne': 1,
    'chernenko': 3,
    'cherney': 2,
    'chernick': 2,
    'chernin': 2,
    'chernobyl': 3,
    'chernoff': 2,
    'chernomyrdin': 4,
    'chernow': 2,
    'cherny': 2,
    'cherokee': 3,
    'cherokees': 3,
    'cherrapunji': 4,
    'cherrier': 3,
    'cherries': 2,
    'cherrington': 3,
    'cherry': 2,
    'cherrystone': 3,
    'cherrystones': 3,
    'chert': 1,
    'chertkow': 2,
    'chertoff': 2,
    'cherts': 1,
    'cherub': 2,
    'cherubic': 3,
    'cherubini': 4,
    'cherubs': 2,
    'cherumirdan': 4,
    'chervenak': 3,
    'chery': 2,
    'cheryl': 2,
    'ches': 1,
    'chesapeake': 3,
    'chesbro': 2,
    'chesebro': 3,
    'chesebrough': 3,
    'chesher': 2,
    'cheshier': 3,
    'cheshire': 2,
    'chesler': 3,
    'chesley': 2,
    'cheslock': 2,
    'chesney': 2,
    'chesnut': 2,
    'chesnutt': 2,
    'chess': 1,
    'chessboard': 2,
    'chessboards': 2,
    'chesser': 2,
    'chessher': 2,
    'chesshir': 2,
    'chessman': 2,
    'chesson': 2,
    'chest': 1,
    'chestang': 2,
    'chested': 2,
    'chester': 2,
    'chesterfield': 3,
    'chesterman': 3,
    'chesters': 2,
    'chesterson': 3,
    'chesterton': 3,
    'chestman': 2,
    'chestnut': 2,
    'chestnuts': 2,
    'chestnutt': 2,
    'cheston': 2,
    'chests': 1,
    'chet': 1,
    'chetniks': 2,
    'chetwin': 2,
    'cheung': 1,
    'cheuse': 1,
    'cheuvront': 2,
    'chevalier': 3,
    'cheverly': 3,
    'cheves': 1,
    'chevette': 2,
    'chevies': 2,
    'chevik': 2,
    'cheviot': 3,
    'cheviots': 3,
    'chevis': 2,
    'chevrette': 2,
    'chevrier': 4,
    'chevrolet': 3,
    'chevrolets': 3,
    'chevron': 2,
    'chevy': 2,
    'chevys': 2,
    'chew': 1,
    'chewed': 1,
    'chewer': 2,
    'chewers': 2,
    'chewing': 2,
    'chewning': 2,
    'chews': 1,
    'chewy': 2,
    'cheyenne': 2,
    'cheyennes': 2,
    'cheyne': 1,
    'cheyney': 2,
    'chez': 1,
    'chi': 1,
    'chia': 2,
    'chianese': 4,
    'chiang': 1,
    'chianti': 3,
    'chiapas': 3,
    'chiapparone': 4,
    'chiappetta': 4,
    'chiappone': 4,
    'chiara': 2,
    'chiaramonte': 5,
    'chiarella': 4,
    'chiarelli': 4,
    'chiarello': 4,
    'chiarenza': 4,
    'chiarnim': 3,
    'chiaro': 3,
    'chiasso': 3,
    'chiasson': 3,
    'chiat': 2,
    'chiavetta': 4,
    'chiba': 2,
    'chic': 1,
    'chicago': 3,
    'chicagoan': 3,
    'chicagoans': 4,
    'chicanery': 4,
    'chicano': 3,
    'chicanos': 3,
    'chichauha': 3,
    'chichester': 3,
    'chichi': 2,
    'chick': 1,
    'chickasaw': 3,
    'chickasaws': 3,
    'chicked': 1,
    'chicken': 2,
    'chickened': 2,
    'chickening': 3,
    'chickens': 2,
    'chickering': 3,
    'chicks': 1,
    'chico': 2,
    'chicoine': 2,
    'chicots': 2,
    'chide': 1,
    'chided': 2,
    'chides': 1,
    'chidester': 3,
    'chideya': 3,
    'chiding': 2,
    'chidsey': 2,
    'chief': 1,
    'chiefdom': 2,
    'chieffo': 2,
    'chiefly': 2,
    'chiefs': 1,
    'chieftain': 2,
    'chieftains': 2,
    'chien': 1,
    'chiengmai': 2,
    'chiesa': 2,
    'chiffon': 2,
    'chiggers': 2,
    'chigney': 2,
    'chihuahua': 3,
    'chikane': 3,
    'chikatilo': 4,
    'chikos': 2,
    'chilblain': 2,
    'chilblains': 2,
    'chilcoat': 2,
    'chilcote': 2,
    'chilcott': 2,
    'chilcutt': 2,
    'child': 1,
    'childbearing': 3,
    'childbirth': 2,
    'childcare': 2,
    'childcraft': 2,
    'childe': 1,
    'childener': 2,
    'childers': 2,
    'childhood': 2,
    'childhoods': 2,
    'childish': 2,
    'childless': 2,
    'childlike': 2,
    'childraising': 3,
    'childree': 2,
    'children': 2,
    'childrens': 2,
    'childres': 2,
    'childress': 2,
    'childrey': 2,
    'childs': 1,
    'chile': 2,
    'chilean': 3,
    'chileans': 3,
    'chiles': 1,
    'chili': 2,
    'chilies': 2,
    'chilis': 2,
    'chill': 1,
    'chilled': 1,
    'chillemi': 3,
    'chiller': 2,
    'chillicothe': 3,
    'chillier': 3,
    'chillies': 2,
    'chilliest': 3,
    'chilling': 2,
    'chillingly': 3,
    'chills': 1,
    'chilly': 2,
    'chilmark': 2,
    'chilson': 2,
    'chilton': 2,
    'chimayo': 3,
    'chime': 1,
    'chimed': 1,
    'chimenti': 3,
    'chimento': 3,
    'chimera': 3,
    'chimerical': 4,
    'chimerine': 3,
    'chimes': 1,
    'chimicles': 3,
    'chimie': 2,
    'chimney': 2,
    'chimneys': 2,
    'chimp': 1,
    'chimpanzee': 3,
    'chimpanzees': 3,
    'chimps': 1,
    'chimurenga': 4,
    'chin': 1,
    'china': 2,
    'chinatown': 3,
    'chinchilla': 3,
    'chinen': 2,
    'chinese': 2,
    'ching': 1,
    'chinh': 1,
    'chink': 1,
    'chinks': 1,
    'chinn': 1,
    'chinnici': 3,
    'chinnock': 2,
    'chino': 2,
    'chinook': 2,
    'chinooks': 2,
    'chinoy': 2,
    'chintung': 2,
    'chintzy': 2,
    'chiodo': 3,
    'chip': 1,
    'chipboard': 2,
    'chipcom': 2,
    'chipello': 3,
    'chipetas': 3,
    'chipita': 3,
    'chipley': 2,
    'chipmaker': 3,
    'chipmakers': 3,
    'chipman': 2,
    'chipote': 2,
    'chipped': 1,
    'chipper': 2,
    'chippewa': 3,
    'chipping': 2,
    'chipps': 1,
    'chippy': 2,
    'chips': 1,
    'chipsoft': 2,
    'chiquita': 3,
    'chirac': 2,
    'chirco': 2,
    'chirico': 3,
    'chiron': 2,
    'chiropractic': 4,
    'chiropractor': 4,
    'chiropractors': 4,
    'chirp': 1,
    'chirping': 2,
    'chirps': 1,
    'chirpy': 2,
    'chisam': 2,
    'chisel': 2,
    'chiseled': 2,
    'chiseling': 3,
    'chisels': 2,
    'chisenhall': 3,
    'chisholm': 2,
    'chisler': 3,
    'chism': 2,
    'chisman': 2,
    'chismar': 2,
    'chisolm': 2,
    'chisom': 2,
    'chissano': 3,
    'chisum': 2,
    'chiswick': 2,
    'chit': 1,
    'chita': 2,
    'chitchat': 2,
    'chitinous': 3,
    'chitlin': 2,
    'chitlins': 2,
    'chitra': 2,
    'chitrao': 2,
    'chits': 1,
    'chittenden': 3,
    'chitter': 2,
    'chitterchatter': 4,
    'chitterlings': 3,
    'chittick': 2,
    'chittum': 2,
    'chitty': 2,
    'chitwood': 2,
    'chiu': 1,
    'chiusano': 4,
    'chivalry': 3,
    'chivas': 2,
    'chivers': 2,
    'chives': 1,
    'chiyoda': 3,
    'chizek': 2,
    'chizmar': 2,
    'chlamydia': 4,
    'chlebowski': 3,
    'chloe': 2,
    'chlorate': 2,
    'chlordane': 2,
    'chloride': 2,
    'chlorinate': 3,
    'chlorinated': 4,
    'chlorinating': 4,
    'chlorine': 2,
    'chloris': 2,
    'chlorofluorocarbon': 6,
    'chlorofluorocarbons': 6,
    'chloroform': 3,
    'chlorophyll': 3,
    'chloroplast': 3,
    'chloroplasts': 3,
    'chloroprene': 3,
    'chmiel': 2,
    'chmielewski': 4,
    'chmura': 3,
    'cho': 1,
    'choat': 1,
    'choate': 1,
    'chock': 1,
    'chocolat': 2,
    'chocolate': 2,
    'chocolates': 2,
    'chocolatology': 5,
    'chodorow': 3,
    'choe': 1,
    'choi': 1,
    'choice': 1,
    'choicer': 2,
    'choicers': 2,
    'choices': 2,
    'choicest': 2,
    'choiniere': 3,
    'choinski': 2,
    'choir': 2,
    'choirs': 1,
    'chojnacki': 3,
    'chojnowski': 3,
    'chok': 1,
    'choke': 1,
    'choked': 1,
    'chokehold': 2,
    'chokes': 1,
    'choking': 2,
    'cholera': 3,
    'choleric': 3,
    'cholesterol': 4,
    'cholestyramine': 5,
    'cholet': 2,
    'cholewa': 3,
    'cholla': 2,
    'choma': 2,
    'chombiono': 4,
    'chomp': 1,
    'chomping': 2,
    'chon': 1,
    'chong': 1,
    'chongqing': 2,
    'chonko': 2,
    'chontales': 2,
    'choo': 1,
    'chook': 1,
    'choon': 1,
    'choong': 1,
    'choose': 1,
    'chooses': 2,
    'choosing': 2,
    'choosy': 2,
    'chop': 1,
    'choper': 2,
    'chopin': 2,
    'choplin': 2,
    'chopp': 1,
    'chopped': 1,
    'chopper': 2,
    'choppers': 2,
    'chopping': 2,
    'choppy': 2,
    'chopra': 2,
    'chops': 1,
    'chopstick': 2,
    'chopsticks': 2,
    'chopsuey': 3,
    'choquette': 2,
    'choral': 2,
    'chorale': 2,
    'chorals': 2,
    'chorba': 2,
    'chord': 1,
    'chordates': 2,
    'chords': 1,
    'chore': 1,
    'choreograph': 4,
    'choreographed': 4,
    'choreographer': 5,
    'choreographers': 5,
    'choreographic': 5,
    'choreographing': 5,
    'choreography': 5,
    'chores': 1,
    'choric': 2,
    'chorney': 2,
    'chortle': 2,
    'chortled': 2,
    'chortles': 2,
    'chortling': 3,
    'chorus': 2,
    'choruses': 3,
    'chose': 1,
    'chosen': 2,
    'chosun': 2,
    'chotilla': 3,
    'chou': 1,
    'chouinard': 2,
    'chovan': 2,
    'chovanec': 3,
    'chow': 1,
    'chowder': 2,
    'chowdhury': 3,
    'chowning': 2,
    'chows': 1,
    'choy': 1,
    'choyce': 1,
    'chrest': 1,
    'chrestman': 2,
    'chretien': 2,
    'chriboniko': 4,
    'chriptosporidium': 6,
    'chris': 1,
    'chrisco': 2,
    'chriscoe': 2,
    'chrisman': 2,
    'chrismer': 4,
    'chrismon': 2,
    'chrisp': 1,
    'chriss': 1,
    'chrissie': 2,
    'chrissy': 2,
    'christ': 1,
    'christa': 2,
    'christabelle': 3,
    'christakos': 3,
    'christal': 2,
    'christchurch': 2,
    'christel': 2,
    'christen': 2,
    'christenberry': 4,
    'christenbury': 4,
    'christendom': 3,
    'christened': 2,
    'christening': 3,
    'christensen': 3,
    'christenson': 3,
    'christeson': 3,
    'christi': 2,
    'christiaan': 3,
    'christian': 2,
    'christiana': 4,
    'christiane': 3,
    'christianity': 5,
    'christianization': 5,
    'christianize': 3,
    'christianized': 3,
    'christianna': 4,
    'christianne': 3,
    'christiano': 4,
    'christians': 2,
    'christiansen': 3,
    'christianson': 3,
    'christiansted': 3,
    'christic': 2,
    'christie': 2,
    'christies': 2,
    'christina': 3,
    'christine': 2,
    'christison': 3,
    'christlieb': 2,
    'christman': 2,
    'christmann': 2,
    'christmas': 2,
    'christmases': 3,
    'christmastime': 3,
    'christner': 2,
    'christo': 2,
    'christoff': 2,
    'christoffel': 3,
    'christoffersen': 4,
    'christofferson': 4,
    'christon': 2,
    'christoph': 2,
    'christophe': 2,
    'christophel': 3,
    'christopher': 3,
    'christophersen': 4,
    'christopherson': 4,
    'christopoulos': 4,
    'christy': 2,
    'chriswell': 2,
    'chrobak': 2,
    'chromakalim': 4,
    'chromalloy': 3,
    'chromatogram': 4,
    'chromatograms': 4,
    'chromatography': 5,
    'chrome': 1,
    'chrominance': 3,
    'chromium': 3,
    'chromosome': 3,
    'chromosomes': 3,
    'chronar': 2,
    'chronic': 2,
    'chronically': 4,
    'chronicle': 3,
    'chronicled': 3,
    'chronicler': 3,
    'chroniclers': 3,
    'chronicles': 3,
    'chronicling': 3,
    'chronis': 2,
    'chronister': 3,
    'chronological': 5,
    'chronologically': 5,
    'chronologies': 4,
    'chronology': 4,
    'chronowitz': 3,
    'chrostowski': 3,
    'chrusciel': 2,
    'chrysalis': 3,
    'chrysanthemum': 4,
    'chrysanthemums': 4,
    'chryseis': 2,
    'chrysler': 2,
    'chryslers': 2,
    'chryst': 1,
    'chrystal': 2,
    'chrzan': 2,
    'chrzanowski': 4,
    'chseing': 1,
    'chu': 1,
    'chua': 2,
    'chuah': 2,
    'chuan': 2,
    'chuang': 1,
    'chuba': 2,
    'chubais': 2,
    'chubb': 1,
    'chubbuck': 2,
    'chubby': 2,
    'chubu': 2,
    'chuck': 1,
    'chucked': 1,
    'chuckie': 2,
    'chucking': 2,
    'chuckle': 2,
    'chuckled': 2,
    'chuckles': 2,
    'chuckling': 2,
    'chudler': 2,
    'chudy': 2,
    'chudzik': 2,
    'chudzinski': 3,
    'chug': 1,
    'chugai': 2,
    'chugged': 1,
    'chugging': 2,
    'chugoku': 3,
    'chui': 2,
    'chujitsuya': 4,
    'chukchi': 2,
    'chul': 1,
    'chula': 2,
    'chum': 1,
    'chumbley': 2,
    'chumley': 2,
    'chummy': 2,
    'chumney': 2,
    'chump': 1,
    'chums': 1,
    'chun': 1,
    'chung': 1,
    'chunk': 1,
    'chunks': 1,
    'chunky': 2,
    'chunn': 1,
    'chunnel': 2,
    'chupp': 1,
    'chura': 2,
    'church': 1,
    'churches': 2,
    'churchgoer': 3,
    'churchgoers': 3,
    'churchgoing': 3,
    'churchhouse': 2,
    'churchill': 2,
    'churchman': 2,
    'churchmen': 2,
    'churchwell': 2,
    'churchyard': 2,
    'churilla': 3,
    'churkin': 2,
    'churlish': 2,
    'churn': 1,
    'churned': 1,
    'churning': 2,
    'churns': 1,
    'churry': 2,
    'chuse': 1,
    'chusmir': 2,
    'chustz': 1,
    'chute': 1,
    'chutes': 1,
    'chutney': 2,
    'chutzpah': 2,
    'chykatka': 3,
    'chynoweth': 3,
    'chyron': 2,
    'cia': 3,
    'ciaccia': 4,
    'ciaccio': 3,
    'ciampa': 2,
    'ciampi': 2,
    'cian': 1,
    'cianci': 2,
    'ciancio': 3,
    'cianciola': 3,
    'cianciolo': 3,
    'cianciulli': 3,
    'ciani': 2,
    'ciano': 3,
    'ciao': 1,
    'ciaobella': 3,
    'ciaramella': 4,
    'ciaramitaro': 5,
    'ciaravino': 4,
    'ciardi': 2,
    'ciarlo': 2,
    'ciavarella': 4,
    'ciba': 2,
    'ciborowski': 4,
    'cibro': 2,
    'cibula': 3,
    'cicada': 3,
    'cicadas': 3,
    'cicala': 3,
    'cicalese': 4,
    'ciccarelli': 4,
    'ciccarello': 4,
    'ciccarone': 3,
    'cicco': 2,
    'ciccone': 3,
    'cicely': 3,
    'cicero': 3,
    'cicerone': 3,
    'cichocki': 3,
    'cichon': 2,
    'cichowski': 3,
    'cichy': 2,
    'cicily': 3,
    'cicio': 3,
    'cicippio': 4,
    'cid': 1,
    'cider': 2,
    'cie': 1,
    'ciel': 1,
    'ciera': 2,
    'cieri': 2,
    'ciesielski': 3,
    'ciesla': 2,
    'cieslak': 2,
    'cieslewicz': 3,
    'cieslik': 2,
    'cieslinski': 3,
    'cifelli': 3,
    'cifra': 2,
    'cifuentes': 3,
    'ciga': 2,
    'cigar': 2,
    'cigarette': 3,
    'cigarettes': 3,
    'cigars': 2,
    'cigna': 2,
    'cihak': 2,
    'cihlar': 2,
    'cilag': 2,
    'cilantro': 3,
    'cilcorp': 2,
    'cilento': 3,
    'ciliates': 3,
    'ciliberto': 4,
    'cilicia': 3,
    'ciller': 2,
    'cilley': 2,
    'cillo': 2,
    'cilluffo': 3,
    'cilva': 2,
    'cima': 2,
    'cimaglia': 4,
    'cimarron': 3,
    'ciments': 2,
    'ciminero': 4,
    'cimini': 3,
    'cimino': 3,
    'cimmino': 3,
    'cimo': 2,
    'cimorelli': 4,
    'cina': 2,
    'cinch': 1,
    'cinched': 1,
    'cincinnati': 4,
    'cinco': 2,
    'cincotta': 3,
    'cinder': 2,
    'cinderella': 4,
    'cinders': 2,
    'cindie': 2,
    'cindric': 2,
    'cindy': 2,
    'cinelli': 3,
    'cinema': 3,
    'cinemark': 3,
    'cinemas': 3,
    'cinematic': 4,
    'cinematographer': 6,
    'cinematography': 6,
    'cinemax': 3,
    'cineplex': 3,
    'cinergy': 3,
    'cini': 2,
    'cinnabar': 3,
    'cinnabon': 3,
    'cinnaminson': 4,
    'cinnamon': 3,
    'cinnamonson': 4,
    'cino': 2,
    'cinq': 1,
    'cinque': 1,
    'cinquemani': 4,
    'cinram': 2,
    'cinthie': 2,
    'cintron': 2,
    'ciocca': 2,
    'cioffi': 3,
    'ciolek': 3,
    'ciolino': 3,
    'ciotti': 2,
    'cipher': 2,
    'cipolla': 3,
    'cipollone': 3,
    'cipri': 2,
    'cipriani': 4,
    'cipriano': 4,
    'cira': 2,
    'ciraulo': 3,
    'circa': 2,
    'circadian': 4,
    'circle': 2,
    'circled': 2,
    'circles': 2,
    'circling': 3,
    'circon': 2,
    'circuit': 2,
    'circuited': 3,
    'circuitous': 4,
    'circuitry': 3,
    'circuits': 2,
    'circular': 3,
    'circularize': 4,
    'circularizing': 5,
    'circularly': 4,
    'circulars': 3,
    'circulate': 3,
    'circulated': 4,
    'circulates': 3,
    'circulating': 4,
    'circulation': 4,
    'circulations': 4,
    'circulatory': 5,
    'circumcise': 3,
    'circumcised': 3,
    'circumcision': 4,
    'circumference': 3,
    'circumnavigate': 5,
    'circumscribe': 3,
    'circumscribed': 3,
    'circumspect': 3,
    'circumspection': 4,
    'circumstance': 3,
    'circumstances': 4,
    'circumstantial': 4,
    'circumstantially': 5,
    'circumvene': 3,
    'circumvent': 3,
    'circumvented': 4,
    'circumventing': 4,
    'circumvention': 4,
    'circumvents': 3,
    'circus': 2,
    'circuses': 3,
    'cirelli': 3,
    'ciresi': 3,
    'ciriaco': 4,
    'ciriello': 4,
    'cirigliano': 5,
    'cirillo': 3,
    'cirincione': 4,
    'cirino': 3,
    'cirkin': 2,
    'cirone': 2,
    'ciros': 2,
    'cirque': 1,
    'cirrhosis': 3,
    'cirrincione': 4,
    'cirrus': 2,
    'cisar': 2,
    'cisco': 2,
    'cisek': 2,
    'cisewski': 3,
    'ciskei': 2,
    'cisler': 3,
    'cislo': 2,
    'cisneros': 3,
    'cisney': 2,
    'cissell': 2,
    'cissie': 2,
    'cissna': 2,
    'cist': 1,
    'cistercian': 3,
    'cistern': 2,
    'cisterns': 2,
    'ciszek': 2,
    'ciszewski': 3,
    'citadel': 3,
    'citation': 3,
    'citations': 3,
    'cite': 1,
    'cited': 2,
    'cites': 1,
    'citgo': 2,
    'citi': 2,
    'citibank': 3,
    'citic': 2,
    'citicorp': 3,
    'citicorps': 3,
    'cities': 2,
    'citing': 2,
    'citisteel': 3,
    'citizen': 3,
    'citizenry': 4,
    'citizens': 3,
    'citizenship': 4,
    'citrano': 3,
    'citric': 2,
    'citrin': 2,
    'citrine': 2,
    'citro': 2,
    'citroen': 2,
    'citron': 2,
    'citronella': 4,
    'citrosuco': 4,
    'citrucel': 3,
    'citrus': 2,
    'cittadino': 4,
    'city': 2,
    'cityfed': 3,
    'cityplace': 3,
    'cityside': 3,
    'citytrust': 3,
    'citywide': 3,
    'ciucci': 2,
    'ciudad': 3,
    'ciulla': 2,
    'ciullo': 2,
    'civet': 2,
    'civic': 2,
    'civics': 2,
    'civil': 2,
    'civiletti': 4,
    'civilian': 3,
    'civilians': 3,
    'civility': 4,
    'civilization': 5,
    'civilizations': 5,
    'civilize': 3,
    'civilized': 3,
    'civilly': 3,
    'civitello': 4,
    'cizek': 2,
    'cizik': 2,
    'cizneros': 3,
    'claar': 1,
    'claassen': 2,
    'clabaugh': 2,
    'clabir': 2,
    'clabo': 2,
    'claborn': 2,
    'clabough': 2,
    'clack': 1,
    'clackamas': 3,
    'clad': 1,
    'claddagh': 2,
    'cladification': 5,
    'claes': 1,
    'claeys': 1,
    'claffey': 2,
    'claflin': 2,
    'clagett': 2,
    'clagg': 1,
    'claggett': 2,
    'clague': 1,
    'claiborn': 2,
    'claiborne': 2,
    'claim': 1,
    'claimant': 2,
    'claimants': 2,
    'claimed': 1,
    'claiming': 2,
    'claims': 1,
    'clair': 1,
    'claire': 1,
    'clairmont': 2,
    'clairol': 2,
    'clairson': 2,
    'clairvoyance': 3,
    'clairvoyant': 3,
    'clam': 1,
    'clamber': 2,
    'clambered': 2,
    'clamen': 2,
    'clammed': 1,
    'clammy': 2,
    'clamor': 2,
    'clamored': 2,
    'clamoring': 3,
    'clamp': 1,
    'clampdown': 2,
    'clamped': 1,
    'clampett': 2,
    'clamping': 2,
    'clampitt': 2,
    'clamps': 1,
    'clams': 1,
    'clamshell': 2,
    'clan': 1,
    'clancey': 2,
    'clancy': 2,
    'clandestine': 3,
    'clandestinely': 4,
    'clang': 1,
    'clanging': 2,
    'clanin': 2,
    'clank': 1,
    'clanking': 2,
    'clannish': 2,
    'clans': 1,
    'clanton': 2,
    'clap': 1,
    'clapboard': 2,
    'clapboards': 2,
    'clapham': 2,
    'clapman': 2,
    'clapp': 1,
    'clapped': 1,
    'clapper': 2,
    'clapping': 2,
    'claps': 1,
    'clapsaddle': 3,
    'clapton': 2,
    'clar': 1,
    'clara': 2,
    'clarabelle': 3,
    'claramae': 3,
    'claran': 2,
    'clarcor': 2,
    'clardy': 2,
    'clare': 1,
    'claremont': 2,
    'claren': 2,
    'clarence': 2,
    'clarendon': 3,
    'claresta': 3,
    'clarette': 2,
    'clarey': 2,
    'claribel': 3,
    'clarice': 2,
    'clarida': 3,
    'claridge': 2,
    'claridges': 3,
    'claridon': 3,
    'clarification': 5,
    'clarifications': 5,
    'clarified': 3,
    'clarifies': 3,
    'clarify': 3,
    'clarifying': 4,
    'clarimond': 3,
    'clarinda': 3,
    'clarine': 2,
    'clarinet': 3,
    'clarinetist': 4,
    'clarinetists': 4,
    'clarins': 2,
    'clarion': 3,
    'claris': 2,
    'clarissa': 3,
    'clarisse': 2,
    'clarita': 3,
    'claritin': 3,
    'clarity': 3,
    'clark': 1,
    'clarke': 1,
    'clarken': 2,
    'clarkin': 2,
    'clarks': 1,
    'clarksburg': 2,
    'clarkson': 2,
    'clarkston': 2,
    'clarksville': 2,
    'claro': 2,
    'clarostat': 3,
    'clarridge': 2,
    'clarrisse': 2,
    'clary': 2,
    'clasby': 2,
    'clasen': 2,
    'clash': 1,
    'clashed': 1,
    'clashes': 2,
    'clashing': 2,
    'clason': 2,
    'clasp': 1,
    'clasped': 1,
    'class': 1,
    'classaction': 3,
    'classactions': 3,
    'classed': 1,
    'classen': 2,
    'classes': 2,
    'classic': 2,
    'classical': 3,
    'classically': 3,
    'classicism': 4,
    'classicist': 3,
    'classics': 2,
    'classier': 3,
    'classifiable': 5,
    'classification': 5,
    'classifications': 5,
    'classified': 3,
    'classifies': 3,
    'classify': 3,
    'classifying': 4,
    'classing': 2,
    'classless': 2,
    'classman': 2,
    'classmate': 2,
    'classmates': 2,
    'classmen': 2,
    'classon': 2,
    'classroom': 2,
    'classrooms': 2,
    'classy': 2,
    'clatter': 2,
    'clattering': 3,
    'claud': 1,
    'claude': 1,
    'claudet': 2,
    'claudette': 2,
    'claudia': 3,
    'claudian': 3,
    'claudie': 2,
    'claudina': 3,
    'claudine': 2,
    'claudio': 3,
    'claudius': 3,
    'claudson': 2,
    'claunch': 1,
    'claus': 1,
    'clause': 1,
    'clausell': 2,
    'clausen': 2,
    'clauser': 2,
    'clauses': 2,
    'clausing': 2,
    'clauson': 2,
    'clauss': 1,
    'claussen': 2,
    'claustrophobia': 5,
    'claustrophobic': 4,
    'clavette': 2,
    'clavichord': 3,
    'clavicle': 3,
    'clavin': 2,
    'claw': 1,
    'clawed': 1,
    'clawing': 2,
    'claws': 1,
    'clawson': 2,
    'claxon': 2,
    'claxton': 2,
    'clay': 1,
    'claybaugh': 2,
    'clayborn': 2,
    'clayborne': 2,
    'claybourne': 2,
    'claybrook': 2,
    'claybrooks': 2,
    'clayburn': 2,
    'claycomb': 2,
    'clayey': 2,
    'clayman': 2,
    'claymation': 3,
    'claymont': 2,
    'claymore': 2,
    'claypool': 2,
    'claypoole': 2,
    'clays': 1,
    'clayson': 2,
    'clayton': 2,
    'claytor': 2,
    'claywell': 2,
    'cleah': 2,
    'clean': 1,
    'cleaned': 1,
    'cleaner': 2,
    'cleaners': 2,
    'cleanest': 2,
    'cleaning': 2,
    'cleanliness': 3,
    'cleanly': 2,
    'cleanness': 2,
    'cleans': 1,
    'cleanse': 1,
    'cleansed': 1,
    'cleanser': 2,
    'cleansers': 2,
    'cleansing': 2,
    'cleantha': 2,
    'cleanup': 2,
    'cleanups': 2,
    'clear': 1,
    'clearance': 2,
    'clearances': 3,
    'clearcut': 2,
    'clearcuts': 2,
    'clearcutting': 3,
    'cleared': 1,
    'clearer': 2,
    'clearest': 2,
    'clearing': 2,
    'clearinghouse': 3,
    'clearinghouses': 4,
    'clearly': 2,
    'clearman': 2,
    'clears': 1,
    'clearwater': 3,
    'cleary': 2,
    'cleat': 1,
    'cleats': 1,
    'cleavage': 2,
    'cleave': 1,
    'cleaveland': 2,
    'cleavenger': 3,
    'cleaver': 2,
    'cleaves': 1,
    'cleckler': 2,
    'cleckley': 2,
    'cleckner': 2,
    'cleek': 1,
    'cleere': 1,
    'cleese': 1,
    'cleeton': 2,
    'clef': 1,
    'cleft': 1,
    'clegg': 1,
    'cleghorn': 2,
    'cleland': 2,
    'clell': 1,
    'clelland': 2,
    'clem': 1,
    'clemans': 2,
    'clematis': 3,
    'clemen': 2,
    'clemence': 2,
    'clemency': 3,
    'clemens': 2,
    'clemensen': 3,
    'clemenson': 3,
    'clement': 2,
    'clemente': 3,
    'clementes': 3,
    'clementi': 3,
    'clementia': 3,
    'clementine': 3,
    'clements': 2,
    'clementson': 3,
    'clementz': 2,
    'clemmer': 2,
    'clemmie': 2,
    'clemmons': 2,
    'clemmy': 2,
    'clemo': 2,
    'clemons': 2,
    'clemson': 2,
    'clench': 1,
    'clenched': 1,
    'clencher': 2,
    'clenches': 2,
    'clendaniel': 3,
    'clendenen': 3,
    'clendenin': 3,
    'clendening': 3,
    'clendenning': 3,
    'clenney': 2,
    'clenwar': 2,
    'cleo': 2,
    'cleopatra': 4,
    'clephane': 2,
    'clepper': 2,
    'clerc': 1,
    'clercq': 1,
    'clergy': 2,
    'clergyman': 3,
    'clergymen': 3,
    'cleric': 2,
    'clerical': 3,
    'clerics': 2,
    'clerissa': 3,
    'clerk': 1,
    'clerkin': 2,
    'clerks': 1,
    'clermont': 2,
    'cleva': 2,
    'cleve': 1,
    'cleveland': 2,
    'clevelander': 3,
    'clevelanders': 3,
    'cleven': 2,
    'clevenger': 3,
    'clever': 2,
    'cleverly': 3,
    'cleverness': 3,
    'clevetrust': 2,
    'clevie': 2,
    'clevinger': 3,
    'clevite': 2,
    'clewell': 2,
    'clewis': 2,
    'clewiston': 3,
    'clews': 1,
    'cliantha': 3,
    'cliburn': 2,
    'cliche': 2,
    'cliched': 2,
    'cliches': 2,
    'click': 1,
    'clicked': 1,
    'clicker': 2,
    'clicking': 2,
    'clickner': 2,
    'clicks': 1,
    'client': 2,
    'clientele': 3,
    'clients': 2,
    'cliett': 1,
    'clif': 1,
    'cliff': 1,
    'cliffe': 1,
    'cliffhanger': 3,
    'cliffhangers': 3,
    'clifford': 2,
    'cliffs': 1,
    'clift': 1,
    'clifton': 2,
    'cliggott': 2,
    'clim': 1,
    'climaco': 3,
    'climactic': 3,
    'climate': 2,
    'climates': 2,
    'climatic': 3,
    'climatologist': 5,
    'climatologists': 5,
    'climax': 2,
    'climaxed': 2,
    'climaxes': 3,
    'climb': 1,
    'climbed': 1,
    'climber': 2,
    'climbers': 2,
    'climbing': 2,
    'climbs': 1,
    'climer': 2,
    'climes': 1,
    'clinard': 2,
    'clinch': 1,
    'clinched': 1,
    'clincher': 2,
    'clinches': 2,
    'clinching': 2,
    'cline': 1,
    'clines': 1,
    'cling': 1,
    'clingan': 2,
    'clingenpeel': 3,
    'clinger': 2,
    'clingerman': 3,
    'clinging': 2,
    'clingman': 2,
    'clings': 1,
    'clini': 2,
    'clinic': 2,
    'clinical': 3,
    'clinically': 4,
    'clinician': 3,
    'clinicians': 3,
    'clinics': 2,
    'clink': 1,
    'clinkenbeard': 3,
    'clinkscale': 2,
    'clinkscales': 2,
    'clint': 1,
    'clinton': 2,
    'clintonite': 3,
    'clintonites': 3,
    'clintonomics': 4,
    'clintons': 2,
    'clio': 2,
    'clip': 1,
    'clipboard': 2,
    'clippard': 2,
    'clipped': 1,
    'clipper': 2,
    'clippers': 2,
    'clipping': 2,
    'clippinger': 3,
    'clippings': 2,
    'clips': 1,
    'clique': 1,
    'cliques': 1,
    'clites': 1,
    'clitoris': 3,
    'clive': 1,
    'cliver': 2,
    'cloak': 1,
    'cloaked': 1,
    'cloaking': 2,
    'cloakroom': 2,
    'clobber': 2,
    'clobbered': 2,
    'clobbering': 3,
    'clock': 1,
    'clocked': 1,
    'clocker': 2,
    'clockers': 2,
    'clocking': 2,
    'clocks': 1,
    'clockwise': 2,
    'clockwork': 2,
    'clodfelter': 3,
    'clodoveo': 4,
    'cloe': 1,
    'cloer': 2,
    'cloey': 2,
    'clog': 1,
    'clogged': 1,
    'clogging': 2,
    'clogs': 1,
    'clogston': 2,
    'cloherty': 3,
    'clohessy': 3,
    'cloister': 2,
    'cloistered': 2,
    'cloisters': 2,
    'cloke': 1,
    'clomipramine': 4,
    'clonch': 1,
    'clone': 1,
    'cloned': 1,
    'clones': 1,
    'clonidine': 3,
    'cloning': 2,
    'cloninger': 3,
    'clonts': 1,
    'clontz': 1,
    'cloonan': 2,
    'clooney': 2,
    'clootie': 2,
    'clopper': 2,
    'clopton': 2,
    'clore': 1,
    'clorinda': 3,
    'clorox': 2,
    'clos': 1,
    'close': 1,
    'closed': 1,
    'closedown': 2,
    'closedowns': 2,
    'closely': 2,
    'closeness': 2,
    'closer': 2,
    'closes': 2,
    'closest': 2,
    'closet': 2,
    'closeted': 3,
    'closets': 2,
    'closeup': 2,
    'closeups': 2,
    'closing': 2,
    'closings': 2,
    'closs': 1,
    'closser': 2,
    'closson': 2,
    'closure': 2,
    'closures': 2,
    'clot': 1,
    'clotfelter': 3,
    'cloth': 1,
    'clothe': 1,
    'clothed': 1,
    'clothes': 1,
    'clotheshorse': 2,
    'clothestime': 2,
    'clothier': 2,
    'clothiers': 2,
    'clothilda': 3,
    'clothilde': 2,
    'clothing': 2,
    'cloths': 1,
    'clotilda': 3,
    'clots': 1,
    'clott': 1,
    'clotted': 2,
    'clotting': 2,
    'cloture': 2,
    'clouatre': 2,
    'cloud': 1,
    'cloudburst': 2,
    'clouded': 2,
    'cloudiness': 3,
    'clouding': 2,
    'cloudless': 2,
    'clouds': 1,
    'cloudy': 2,
    'clough': 1,
    'clougherty': 3,
    'clouse': 1,
    'clouser': 2,
    'clout': 1,
    'clouthier': 3,
    'cloutier': 3,
    'clover': 2,
    'cloverleaf': 3,
    'cloves': 1,
    'clovis': 2,
    'clow': 1,
    'cloward': 2,
    'clowdus': 2,
    'clower': 2,
    'clowers': 2,
    'clowes': 1,
    'clown': 1,
    'clowney': 2,
    'clowning': 2,
    'clowns': 1,
    'cloy': 1,
    'cloyd': 1,
    'cloying': 2,
    'clozapine': 3,
    'club': 1,
    'clubb': 1,
    'clubbed': 1,
    'clubbing': 2,
    'clubby': 2,
    'clubhouse': 2,
    'clubhouses': 3,
    'clubs': 1,
    'clucas': 2,
    'cluck': 1,
    'cluckey': 2,
    'clucking': 2,
    'clucks': 1,
    'clue': 1,
    'clued': 1,
    'clueless': 2,
    'clues': 1,
    'cluett': 2,
    'cluff': 1,
    'clugston': 2,
    'cluj': 1,
    'clukey': 2,
    'clum': 1,
    'clump': 1,
    'clumping': 2,
    'clumps': 1,
    'clumpy': 2,
    'clumsily': 3,
    'clumsiness': 3,
    'clumsy': 2,
    'clune': 1,
    'clung': 1,
    'clunk': 1,
    'clunker': 2,
    'clunkers': 2,
    'clunks': 1,
    'clunky': 2,
    'cluny': 2,
    'cluster': 2,
    'clustered': 2,
    'clustering': 3,
    'clusters': 2,
    'clutch': 1,
    'clutched': 1,
    'clutches': 2,
    'clutching': 2,
    'clute': 1,
    'clutter': 2,
    'cluttered': 2,
    'cluttering': 3,
    'clutts': 1,
    'clyatt': 2,
    'clyburn': 2,
    'clyde': 1,
    'clydesdale': 2,
    'clymene': 2,
    'clymer': 2,
    'clyne': 1,
    'clyte': 1,
    'clytie': 2,
    'clyve': 1,
    'cmos': 2,
    'cmu': 3,
    'cmx': 2,
    'cnn': 3,
    'cnnfn': 5,
    'co': 1,
    'coach': 1,
    'coached': 1,
    'coaches': 2,
    'coaching': 2,
    'coachman': 2,
    'coad': 1,
    'coady': 2,
    'coagulate': 4,
    'coagulating': 5,
    'coagulation': 5,
    'coakley': 2,
    'coal': 1,
    'coale': 1,
    'coalesce': 3,
    'coalesced': 3,
    'coalescence': 4,
    'coalesces': 4,
    'coalescing': 4,
    'coalition': 4,
    'coalitions': 4,
    'coals': 1,
    'coalson': 2,
    'coan': 1,
    'coar': 1,
    'coarse': 1,
    'coarsening': 3,
    'coarser': 2,
    'coarticulate': 5,
    'coarticulated': 6,
    'coarticulates': 5,
    'coarticulating': 6,
    'coarticulation': 6,
    'coast': 1,
    'coastal': 2,
    'coastamerica': 5,
    'coasted': 2,
    'coaster': 2,
    'coasters': 2,
    'coasting': 2,
    'coastline': 2,
    'coastlines': 2,
    'coasts': 1,
    'coat': 1,
    'coate': 2,
    'coated': 2,
    'coates': 2,
    'coatesville': 2,
    'coating': 2,
    'coatings': 2,
    'coatney': 2,
    'coats': 1,
    'coattail': 2,
    'coattails': 2,
    'coauthor': 3,
    'coauthored': 3,
    'coauthoring': 4,
    'coauthors': 3,
    'coax': 1,
    'coaxed': 1,
    'coaxes': 2,
    'coaxial': 4,
    'coaxing': 2,
    'coaxum': 2,
    'cob': 1,
    'cobain': 2,
    'cobaine': 2,
    'cobalt': 2,
    'cobaugh': 2,
    'cobb': 1,
    'cobbett': 2,
    'cobbins': 2,
    'cobble': 2,
    'cobbled': 2,
    'cobbler': 2,
    'cobblers': 2,
    'cobblestone': 3,
    'cobblestones': 3,
    'cobbs': 1,
    'cobe': 1,
    'coben': 2,
    'cobepa': 3,
    'coberly': 3,
    'cobern': 2,
    'cobert': 2,
    'cobey': 2,
    'cobia': 3,
    'cobian': 3,
    'cobin': 2,
    'coble': 2,
    'cobleigh': 2,
    'coblentz': 2,
    'cobler': 3,
    'cobliner': 3,
    'cobo': 2,
    'cobol': 2,
    'cobos': 2,
    'cobra': 2,
    'cobras': 2,
    'cobre': 2,
    'cobrin': 2,
    'cobs': 1,
    'coburn': 2,
    'cobweb': 2,
    'cobwebs': 2,
    'coby': 2,
    'coca': 2,
    'cocaine': 2,
    'cocanino': 4,
    'cocanougher': 4,
    'cocca': 2,
    'coccaro': 3,
    'cocchi': 2,
    'coccia': 2,
    'cocco': 2,
    'coccus': 2,
    'cochairman': 3,
    'cochenour': 3,
    'cochin': 2,
    'cochlea': 3,
    'cochlear': 3,
    'cochles': 2,
    'cochran': 2,
    'cochrane': 2,
    'cochranton': 3,
    'cock': 1,
    'cockamamie': 4,
    'cockatoo': 3,
    'cockatoos': 3,
    'cockburn': 2,
    'cocke': 1,
    'cocked': 1,
    'cocker': 2,
    'cockerell': 3,
    'cockerham': 3,
    'cockerill': 3,
    'cockey': 2,
    'cockeyed': 2,
    'cockfield': 2,
    'cockiness': 3,
    'cocking': 2,
    'cockle': 2,
    'cockles': 2,
    'cocklin': 2,
    'cockman': 2,
    'cockney': 2,
    'cockpit': 2,
    'cockpits': 2,
    'cockran': 2,
    'cockrell': 2,
    'cockrill': 2,
    'cockroach': 2,
    'cockroaches': 3,
    'cockroft': 2,
    'cockrum': 2,
    'cocks': 1,
    'cocktail': 2,
    'cocktails': 2,
    'cockwell': 2,
    'cocky': 2,
    'coco': 2,
    'cocoa': 2,
    'cocoanuts': 3,
    'cocom': 2,
    'coconino': 4,
    'coconut': 3,
    'coconuts': 3,
    'cocoon': 2,
    'cocooning': 3,
    'cocoons': 2,
    'cocos': 2,
    'cocozza': 3,
    'cocuzza': 3,
    'cod': 1,
    'coda': 2,
    'codag': 2,
    'coday': 2,
    'codd': 1,
    'codding': 2,
    'coddington': 3,
    'coddle': 2,
    'coddled': 2,
    'coddling': 3,
    'code': 1,
    'codebreaker': 3,
    'codebreakers': 3,
    'coded': 2,
    'codelco': 3,
    'coder': 2,
    'coderre': 2,
    'codes': 1,
    'codesa': 3,
    'codification': 5,
    'codified': 3,
    'codifies': 3,
    'codify': 3,
    'codifying': 4,
    'coding': 2,
    'codispoti': 4,
    'codlin': 2,
    'codner': 2,
    'codrescu': 3,
    'cody': 2,
    'coe': 1,
    'coeburn': 2,
    'coed': 2,
    'coeds': 2,
    'coeducational': 6,
    'coefficient': 4,
    'coefficients': 4,
    'coehlo': 2,
    'coelacanth': 3,
    'coelho': 3,
    'coello': 3,
    'coen': 2,
    'coenen': 3,
    'coenzyme': 3,
    'coequal': 3,
    'coerce': 2,
    'coerced': 2,
    'coercing': 3,
    'coercion': 3,
    'coercive': 3,
    'coeur': 1,
    'coexist': 3,
    'coexisted': 4,
    'coexistence': 4,
    'coexisting': 4,
    'coey': 2,
    'cofer': 2,
    'coffaro': 3,
    'coffee': 2,
    'coffeehouse': 3,
    'coffeehouses': 4,
    'coffeen': 2,
    'coffees': 2,
    'coffel': 2,
    'coffelt': 2,
    'coffer': 2,
    'coffers': 2,
    'coffey': 2,
    'coffield': 2,
    'coffin': 2,
    'coffing': 2,
    'coffins': 2,
    'cofflin': 2,
    'coffman': 2,
    'cofide': 2,
    'cofield': 2,
    'cofounder': 3,
    'cog': 1,
    'cogan': 2,
    'cogar': 2,
    'cogbill': 2,
    'cogburn': 2,
    'cogdell': 2,
    'cogdill': 2,
    'cogeco': 3,
    'cogema': 2,
    'cogency': 3,
    'cogenerate': 4,
    'cogenerated': 5,
    'cogeneration': 5,
    'cogenerator': 5,
    'cogenerators': 5,
    'cogent': 2,
    'coger': 2,
    'coggeshall': 3,
    'coggin': 2,
    'coggins': 2,
    'coghill': 2,
    'coghlan': 2,
    'cogitate': 3,
    'cogitation': 4,
    'cogley': 2,
    'coglianese': 5,
    'cogliano': 4,
    'cognac': 2,
    'cognetics': 3,
    'cognex': 2,
    'cognition': 3,
    'cognitive': 3,
    'cognizance': 3,
    'cognizant': 3,
    'cognoscenti': 4,
    'cogswell': 2,
    'cohabit': 3,
    'cohabitation': 5,
    'cohabiting': 4,
    'cohan': 2,
    'cohasset': 3,
    'cohea': 3,
    'cohee': 2,
    'cohen': 2,
    'cohenour': 3,
    'cohentannoudji': 5,
    'cohere': 2,
    'cohered': 2,
    'coherence': 3,
    'coherent': 3,
    'coherently': 4,
    'coheres': 2,
    'cohesion': 3,
    'cohesive': 3,
    'cohesively': 4,
    'cohesiveness': 4,
    'cohiba': 3,
    'cohick': 2,
    'cohill': 2,
    'cohn': 1,
    'coho': 2,
    'cohoon': 2,
    'cohort': 2,
    'cohorts': 2,
    'cohost': 2,
    'cohosts': 2,
    'cohr': 1,
    'cohron': 2,
    'cohrs': 1,
    'coia': 2,
    'coiffe': 1,
    'coiffed': 1,
    'coil': 1,
    'coile': 1,
    'coiled': 1,
    'coils': 1,
    'coin': 1,
    'coinage': 2,
    'coincide': 3,
    'coincided': 4,
    'coincidence': 4,
    'coincidences': 5,
    'coincident': 4,
    'coincidental': 5,
    'coincidentally': 6,
    'coincides': 3,
    'coinciding': 4,
    'coined': 1,
    'coiner': 2,
    'coining': 2,
    'coins': 1,
    'coinsurance': 4,
    'cointreau': 2,
    'coipa': 2,
    'coiro': 2,
    'coit': 1,
    'coitsville': 2,
    'coitus': 2,
    'cojimar': 3,
    'cojuangco': 3,
    'coke': 1,
    'coker': 2,
    'cokes': 1,
    'cokie': 2,
    'coking': 2,
    'cokley': 2,
    'cola': 2,
    'colab': 2,
    'colabella': 4,
    'colaianni': 4,
    'colaizzi': 3,
    'colaluca': 4,
    'colan': 2,
    'colander': 3,
    'colanders': 3,
    'colangelo': 4,
    'colantonio': 5,
    'colantuono': 4,
    'colao': 2,
    'colarusso': 4,
    'colas': 2,
    'colasanti': 4,
    'colasurdo': 4,
    'colavito': 4,
    'colaw': 2,
    'colbath': 2,
    'colbaugh': 2,
    'colbeck': 2,
    'colberg': 2,
    'colbern': 2,
    'colbert': 2,
    'colborn': 2,
    'colborne': 2,
    'colburn': 2,
    'colby': 2,
    'colbys': 2,
    'colchicine': 3,
    'colclasure': 3,
    'colclough': 2,
    'colcord': 2,
    'cold': 1,
    'colden': 2,
    'colder': 2,
    'coldest': 2,
    'coldiron': 3,
    'coldly': 2,
    'coldness': 2,
    'coldren': 3,
    'colds': 1,
    'coldwater': 3,
    'coldwell': 2,
    'cole': 1,
    'colebank': 2,
    'colebreath': 2,
    'colebrook': 2,
    'coleco': 3,
    'colee': 2,
    'coleen': 2,
    'colegrove': 2,
    'colella': 3,
    'colello': 3,
    'coleman': 2,
    'colen': 2,
    'coler': 2,
    'coleridge': 2,
    'coles': 1,
    'coleslaw': 2,
    'coleson': 3,
    'colestipol': 3,
    'colestock': 2,
    'coletta': 3,
    'colette': 2,
    'coletti': 3,
    'coleus': 3,
    'coleville': 2,
    'coley': 2,
    'colfer': 2,
    'colford': 2,
    'colgan': 2,
    'colgate': 2,
    'colgin': 2,
    'colglazier': 4,
    'colgrove': 2,
    'coli': 2,
    'colicchio': 4,
    'colier': 3,
    'coliform': 3,
    'coliforms': 3,
    'colin': 2,
    'colina': 3,
    'colinas': 3,
    'coline': 3,
    'colino': 3,
    'colis': 2,
    'coliseum': 4,
    'coll': 1,
    'colla': 2,
    'collaborate': 4,
    'collaborated': 5,
    'collaborating': 5,
    'collaboration': 5,
    'collaborationist': 6,
    'collaborations': 5,
    'collaborative': 5,
    'collaborator': 5,
    'collaborators': 5,
    'collado': 3,
    'collage': 2,
    'collagen': 3,
    'collages': 3,
    'collamore': 3,
    'collapse': 2,
    'collapsed': 2,
    'collapses': 3,
    'collapsible': 4,
    'collapsing': 3,
    'collar': 2,
    'collarbone': 3,
    'collard': 2,
    'collards': 2,
    'collared': 2,
    'collars': 2,
    'collate': 2,
    'collateral': 4,
    'collateralize': 5,
    'collateralized': 5,
    'collazo': 3,
    'colle': 1,
    'colleague': 2,
    'colleagues': 2,
    'collect': 2,
    'collected': 3,
    'collectibility': 6,
    'collectible': 4,
    'collectibles': 4,
    'collecting': 3,
    'collection': 3,
    'collections': 3,
    'collective': 3,
    'collectively': 4,
    'collectives': 3,
    'collectivism': 5,
    'collectivist': 4,
    'collectivization': 6,
    'collectivize': 4,
    'collectivized': 4,
    'collector': 3,
    'collectors': 3,
    'collects': 2,
    'colledge': 2,
    'colleen': 2,
    'college': 2,
    'colleges': 3,
    'collegeville': 3,
    'collegial': 4,
    'collegiality': 6,
    'collegian': 3,
    'collegians': 3,
    'collegiate': 3,
    'collen': 2,
    'collender': 3,
    'coller': 2,
    'colleran': 3,
    'collet': 2,
    'collett': 2,
    'colletta': 3,
    'collette': 2,
    'colletti': 3,
    'colleville': 2,
    'colley': 2,
    'colli': 2,
    'collick': 2,
    'collide': 2,
    'collided': 3,
    'collider': 3,
    'collides': 2,
    'colliding': 3,
    'collie': 2,
    'collier': 2,
    'colliers': 2,
    'collies': 2,
    'colligan': 3,
    'collignon': 3,
    'collin': 2,
    'colling': 2,
    'collinge': 2,
    'collings': 2,
    'collingsworth': 3,
    'collingwood': 3,
    'collins': 2,
    'collinson': 3,
    'collinsworth': 3,
    'collis': 2,
    'collision': 3,
    'collisional': 4,
    'collisions': 3,
    'collison': 3,
    'collister': 3,
    'colliver': 3,
    'collman': 2,
    'collocation': 4,
    'collodion': 4,
    'colloid': 2,
    'colloidal': 3,
    'collom': 2,
    'collomb': 2,
    'collopy': 3,
    'colloquial': 4,
    'colloquium': 4,
    'colloquy': 3,
    'collor': 2,
    'collosio': 4,
    'collude': 2,
    'colluded': 3,
    'colluding': 3,
    'collum': 2,
    'collums': 2,
    'collura': 3,
    'collusion': 3,
    'collusive': 3,
    'collver': 2,
    'colly': 2,
    'collyer': 3,
    'colm': 1,
    'colman': 2,
    'colmenero': 4,
    'colmer': 2,
    'colo': 2,
    'colodny': 3,
    'cologne': 2,
    'coloma': 3,
    'colomb': 2,
    'colombari': 4,
    'colombe': 3,
    'colombia': 4,
    'colombian': 4,
    'colombians': 4,
    'colombo': 3,
    'colon': 2,
    'colonel': 2,
    'colonels': 2,
    'colonia': 4,
    'colonial': 4,
    'colonialism': 6,
    'colonialist': 5,
    'colonialists': 5,
    'colonials': 4,
    'colonies': 3,
    'colonist': 3,
    'colonists': 3,
    'colonization': 5,
    'colonize': 3,
    'colonized': 3,
    'colonizer': 4,
    'colonizers': 4,
    'colonna': 3,
    'colonnade': 3,
    'colonus': 3,
    'colony': 3,
    'colopy': 3,
    'color': 2,
    'coloradan': 4,
    'coloradans': 4,
    'colorado': 4,
    'coloration': 4,
    'coloratura': 5,
    'colorblind': 3,
    'colorcraft': 3,
    'colorectal': 4,
    'colored': 2,
    'coloreds': 3,
    'colorfast': 3,
    'colorful': 3,
    'colorfully': 3,
    'coloring': 3,
    'coloristic': 4,
    'colorization': 5,
    'colorize': 3,
    'colorized': 3,
    'colorizing': 4,
    'colorless': 3,
    'colorocs': 3,
    'coloroll': 3,
    'colors': 2,
    'colorwatch': 3,
    'colosi': 3,
    'colosimo': 4,
    'colosio': 4,
    'colossal': 3,
    'colossally': 4,
    'colosseum': 4,
    'colossus': 3,
    'coloured': 2,
    'colpepper': 3,
    'colpitts': 2,
    'colquitt': 2,
    'colson': 2,
    'colston': 2,
    'colstrip': 2,
    'colt': 1,
    'coltec': 2,
    'colter': 2,
    'coltharp': 2,
    'coltie': 2,
    'colton': 2,
    'coltrain': 2,
    'coltrane': 2,
    'coltrin': 2,
    'colts': 1,
    'coltsfoot': 2,
    'colucci': 3,
    'coluccio': 4,
    'columba': 3,
    'columbia': 4,
    'columbian': 4,
    'columbians': 4,
    'columbine': 3,
    'columbines': 3,
    'columbo': 3,
    'columbus': 3,
    'column': 2,
    'columned': 2,
    'columnist': 3,
    'columnists': 3,
    'columns': 2,
    'colunga': 3,
    'colussy': 3,
    'colvard': 2,
    'colver': 2,
    'colvert': 2,
    'colville': 2,
    'colvin': 2,
    'colwell': 2,
    'colyer': 3,
    'com': 1,
    'coma': 2,
    'comair': 2,
    'coman': 2,
    'comanche': 3,
    'comanches': 3,
    'comandante': 4,
    'comandantes': 4,
    'comandeer': 3,
    'comandeered': 3,
    'comandeers': 3,
    'comarco': 3,
    'comas': 2,
    'comatose': 3,
    'comb': 1,
    'combat': 2,
    'combatant': 3,
    'combatants': 3,
    'combating': 3,
    'combative': 3,
    'combativeness': 4,
    'combats': 2,
    'combatting': 3,
    'combe': 1,
    'combed': 1,
    'combee': 2,
    'comber': 2,
    'combes': 1,
    'combest': 2,
    'combinability': 6,
    'combinable': 4,
    'combination': 4,
    'combinations': 4,
    'combine': 2,
    'combined': 2,
    'combines': 2,
    'combing': 2,
    'combining': 3,
    'combo': 2,
    'combs': 1,
    'combust': 2,
    'combustable': 4,
    'combustion': 3,
    'comby': 2,
    'comcast': 2,
    'comdata': 3,
    'comden': 2,
    'comdex': 2,
    'comdisco': 3,
    'come': 1,
    'comeau': 2,
    'comeaux': 2,
    'comeback': 2,
    'comebacks': 2,
    'comecon': 3,
    'comedian': 4,
    'comedians': 4,
    'comedic': 3,
    'comedienne': 4,
    'comedies': 3,
    'comedown': 2,
    'comedy': 3,
    'comegys': 3,
    'comella': 3,
    'comely': 2,
    'comer': 2,
    'comerford': 3,
    'comerica': 4,
    'comers': 2,
    'comes': 1,
    'comet': 2,
    'cometary': 4,
    'cometh': 2,
    'cometra': 3,
    'comets': 2,
    'comeuppance': 3,
    'comex': 2,
    'comfed': 2,
    'comfinance': 3,
    'comfort': 2,
    'comfortable': 4,
    'comfortably': 4,
    'comforted': 3,
    'comforter': 3,
    'comforters': 3,
    'comforting': 3,
    'comforts': 2,
    'comfrey': 2,
    'comfy': 2,
    'comic': 2,
    'comical': 3,
    'comically': 4,
    'comicopia': 5,
    'comics': 2,
    'cominco': 3,
    'coming': 2,
    'comings': 2,
    'comino': 3,
    'comins': 2,
    'cominsky': 3,
    'comiskey': 3,
    'comitatus': 4,
    'comito': 3,
    'comity': 3,
    'comley': 2,
    'comly': 2,
    'comma': 2,
    'commack': 2,
    'command': 2,
    'commandant': 3,
    'commanded': 3,
    'commandeer': 3,
    'commandeered': 3,
    'commander': 3,
    'commanders': 3,
    'commanding': 3,
    'commandment': 3,
    'commandments': 3,
    'commando': 3,
    'commandos': 3,
    'commands': 2,
    'commas': 2,
    'commemorate': 4,
    'commemorated': 5,
    'commemorates': 4,
    'commemorating': 5,
    'commemoration': 5,
    'commemorations': 5,
    'commemorative': 4,
    'commence': 2,
    'commenced': 2,
    'commencement': 3,
    'commences': 3,
    'commencing': 3,
    'commend': 2,
    'commendable': 4,
    'commendation': 4,
    'commended': 3,
    'commending': 3,
    'commends': 2,
    'commensurate': 4,
    'commensurately': 5,
    'comment': 2,
    'commentaries': 4,
    'commentary': 4,
    'commentator': 4,
    'commentators': 4,
    'commented': 3,
    'commenter': 3,
    'commenters': 3,
    'commenting': 3,
    'comments': 2,
    'commerce': 2,
    'commercebancorp': 4,
    'commercial': 3,
    'commerciale': 4,
    'commercialism': 5,
    'commercialization': 6,
    'commercialize': 4,
    'commercialized': 4,
    'commercializing': 5,
    'commercially': 4,
    'commercials': 3,
    'commerford': 3,
    'commerical': 3,
    'commerzbank': 3,
    'commie': 2,
    'commies': 2,
    'commingle': 3,
    'commingled': 3,
    'commingling': 4,
    'commins': 2,
    'commiserate': 4,
    'commish': 2,
    'commision': 3,
    'commissar': 3,
    'commissaries': 4,
    'commissars': 3,
    'commissary': 4,
    'commission': 3,
    'commissioned': 3,
    'commissioner': 4,
    'commissioners': 4,
    'commissioning': 4,
    'commissions': 3,
    'commisso': 3,
    'commit': 2,
    'commitee': 3,
    'commitment': 3,
    'commitments': 3,
    'commits': 2,
    'committal': 3,
    'committed': 3,
    'committee': 3,
    'committeeman': 4,
    'committees': 3,
    'committing': 3,
    'commode': 2,
    'commodious': 4,
    'commodities': 4,
    'commodity': 4,
    'commodore': 3,
    'common': 2,
    'commonalities': 5,
    'commonality': 5,
    'commoner': 3,
    'commoners': 3,
    'commonly': 3,
    'commonplace': 3,
    'commons': 2,
    'commonsense': 3,
    'commonsensical': 5,
    'commonwealth': 3,
    'commotion': 3,
    'communal': 3,
    'commune': 2,
    'communes': 2,
    'communicable': 5,
    'communicate': 4,
    'communicated': 5,
    'communicates': 4,
    'communicating': 5,
    'communication': 5,
    'communications': 5,
    'communicative': 5,
    'communicator': 5,
    'communicators': 5,
    'communing': 3,
    'communion': 3,
    'communique': 4,
    'communiques': 4,
    'communism': 4,
    'communist': 3,
    'communists': 3,
    'communities': 4,
    'community': 4,
    'communitywide': 5,
    'communization': 5,
    'communize': 3,
    'commutate': 3,
    'commutation': 4,
    'commute': 2,
    'commuted': 3,
    'commuter': 3,
    'commuters': 3,
    'commutes': 2,
    'commuting': 3,
    'como': 2,
    'comp': 1,
    'compact': 2,
    'compacted': 3,
    'compactness': 3,
    'compactor': 3,
    'compacts': 2,
    'compagnie': 3,
    'companero': 4,
    'companhia': 4,
    'compania': 4,
    'companies': 3,
    'companion': 3,
    'companions': 3,
    'companionship': 4,
    'companionway': 4,
    'company': 3,
    'companywide': 4,
    'compaore': 4,
    'compaq': 2,
    'comparability': 6,
    'comparable': 4,
    'comparably': 4,
    'comparative': 4,
    'comparatively': 5,
    'comparator': 4,
    'compare': 2,
    'compared': 2,
    'compares': 2,
    'comparing': 3,
    'comparison': 4,
    'comparisons': 4,
    'compartment': 3,
    'compartmental': 4,
    'compartmentalize': 5,
    'compartmentalized': 5,
    'compartments': 3,
    'compas': 2,
    'compass': 2,
    'compassion': 3,
    'compassionate': 4,
    'compassionately': 5,
    'compatibility': 6,
    'compatible': 4,
    'compatibles': 4,
    'compatriot': 4,
    'compatriots': 4,
    'compean': 2,
    'compeau': 2,
    'compel': 2,
    'compelled': 2,
    'compelling': 3,
    'compellingly': 4,
    'compels': 2,
    'compendium': 4,
    'compensable': 4,
    'compensate': 3,
    'compensated': 4,
    'compensates': 3,
    'compensating': 4,
    'compensation': 4,
    'compensations': 4,
    'compensatory': 5,
    'compete': 2,
    'competed': 3,
    'competence': 3,
    'competencies': 4,
    'competency': 4,
    'competent': 3,
    'competently': 4,
    'competes': 2,
    'competing': 3,
    'competition': 4,
    'competitions': 4,
    'competitive': 4,
    'competitively': 5,
    'competitiveness': 5,
    'competitor': 4,
    'competitors': 4,
    'compher': 2,
    'compilation': 4,
    'compilations': 4,
    'compile': 2,
    'compiled': 2,
    'compiler': 3,
    'compilers': 3,
    'compiles': 2,
    'compiling': 3,
    'complacency': 4,
    'complacent': 3,
    'complacently': 4,
    'complain': 2,
    'complainant': 3,
    'complainants': 3,
    'complained': 2,
    'complainer': 3,
    'complainers': 3,
    'complaining': 3,
    'complains': 2,
    'complaint': 2,
    'complaints': 2,
    'complaisant': 3,
    'complement': 3,
    'complementary': 4,
    'complemented': 4,
    'complementing': 4,
    'complements': 3,
    'complete': 2,
    'completed': 3,
    'completely': 3,
    'completeness': 3,
    'completes': 2,
    'completing': 3,
    'completion': 3,
    'completions': 3,
    'complex': 2,
    'complexes': 3,
    'complexion': 3,
    'complexions': 3,
    'complexities': 4,
    'complexity': 4,
    'compliance': 3,
    'compliant': 3,
    'complicate': 3,
    'complicated': 4,
    'complicates': 3,
    'complicating': 4,
    'complication': 4,
    'complications': 4,
    'complicit': 3,
    'complicities': 4,
    'complicity': 4,
    'complied': 2,
    'complies': 2,
    'compliment': 3,
    'complimentary': 5,
    'complimented': 4,
    'complimenting': 4,
    'compliments': 3,
    'comply': 2,
    'complying': 3,
    'compo': 2,
    'component': 3,
    'components': 3,
    'comport': 2,
    'comported': 3,
    'comportment': 3,
    'compose': 2,
    'composed': 2,
    'composer': 3,
    'composers': 3,
    'composes': 3,
    'composing': 3,
    'composite': 3,
    'composites': 3,
    'composition': 4,
    'compositional': 5,
    'compositions': 4,
    'compost': 2,
    'composting': 3,
    'composure': 3,
    'compote': 2,
    'compound': 2,
    'compounded': 3,
    'compounding': 3,
    'compounds': 2,
    'comprehend': 3,
    'comprehended': 4,
    'comprehending': 4,
    'comprehends': 3,
    'comprehensibility': 7,
    'comprehensible': 5,
    'comprehension': 4,
    'comprehensive': 4,
    'comprehensively': 5,
    'compress': 2,
    'compressed': 2,
    'compresses': 3,
    'compressing': 3,
    'compression': 3,
    'compressor': 3,
    'compressors': 3,
    'comprint': 2,
    'comprise': 2,
    'comprised': 2,
    'comprises': 3,
    'comprising': 3,
    'compromise': 3,
    'compromised': 3,
    'compromiser': 4,
    'compromises': 4,
    'compromising': 4,
    'compston': 2,
    'comptek': 2,
    'compton': 2,
    'comptroller': 3,
    'comptronix': 3,
    'compu': 2,
    'compuadd': 3,
    'compuchem': 3,
    'compudyne': 3,
    'compufund': 3,
    'compugraphic': 4,
    'compulsion': 3,
    'compulsions': 3,
    'compulsive': 3,
    'compulsively': 4,
    'compulsory': 4,
    'compumat': 3,
    'compunction': 3,
    'compus': 2,
    'compusa': 4,
    'compuserve': 3,
    'computalog': 4,
    'computation': 4,
    'computational': 5,
    'computations': 4,
    'compute': 2,
    'computed': 3,
    'computer': 3,
    'computercraft': 4,
    'computerization': 6,
    'computerize': 4,
    'computerized': 4,
    'computerizing': 5,
    'computerland': 4,
    'computerlike': 4,
    'computers': 3,
    'computervision': 5,
    'computerworld': 4,
    'computes': 2,
    'computing': 3,
    'computrac': 3,
    'compuware': 3,
    'comrade': 2,
    'comrades': 2,
    'comrie': 3,
    'coms': 1,
    'comsat': 2,
    'comstock': 2,
    'comtois': 2,
    'comtrex': 2,
    'comunale': 4,
    'con': 1,
    'cona': 2,
    'conable': 3,
    'conagra': 3,
    'conahan': 3,
    'conair': 2,
    'conal': 2,
    'conan': 2,
    'conant': 2,
    'conard': 2,
    'conasupo': 4,
    'conatser': 3,
    'conaty': 3,
    'conaway': 3,
    'conboy': 2,
    'conca': 2,
    'concannon': 3,
    'concatenate': 4,
    'concatenated': 5,
    'concatenates': 4,
    'concatenating': 5,
    'concatenation': 5,
    'concave': 2,
    'conceal': 2,
    'concealed': 2,
    'concealing': 3,
    'concealment': 3,
    'conceals': 2,
    'concede': 2,
    'conceded': 3,
    'concedes': 2,
    'conceding': 3,
    'conceit': 2,
    'conceited': 3,
    'conceivable': 4,
    'conceivably': 4,
    'conceive': 2,
    'conceived': 2,
    'conceiving': 3,
    'concentrate': 3,
    'concentrated': 4,
    'concentrates': 3,
    'concentrating': 4,
    'concentration': 4,
    'concentrations': 4,
    'concentric': 3,
    'concepcion': 4,
    'concept': 2,
    'conception': 3,
    'conceptions': 3,
    'concepts': 2,
    'conceptual': 4,
    'conceptualization': 6,
    'conceptually': 5,
    'concern': 2,
    'concerned': 2,
    'concerning': 3,
    'concerns': 2,
    'concert': 2,
    'concerted': 3,
    'concerti': 3,
    'concertina': 4,
    'concertmaster': 4,
    'concerto': 3,
    'concertos': 3,
    'concerts': 2,
    'concession': 3,
    'concessionaire': 4,
    'concessional': 4,
    'concessionary': 5,
    'concessions': 3,
    'conch': 1,
    'concha': 2,
    'conchemco': 3,
    'conchita': 3,
    'conchoid': 2,
    'concierge': 3,
    'conciliation': 5,
    'conciliator': 5,
    'conciliatory': 6,
    'concise': 2,
    'concisely': 3,
    'conclave': 2,
    'conclude': 2,
    'concluded': 3,
    'concludes': 2,
    'concluding': 3,
    'conclusion': 3,
    'conclusions': 3,
    'conclusive': 3,
    'conclusively': 4,
    'concoct': 2,
    'concocted': 3,
    'concocting': 3,
    'concoction': 3,
    'concoctions': 3,
    'concomitant': 4,
    'concomitantly': 5,
    'concord': 2,
    'concorde': 2,
    'concourse': 2,
    'concourses': 3,
    'concrete': 2,
    'concretely': 3,
    'concubinage': 4,
    'concubine': 3,
    'concubines': 3,
    'concur': 2,
    'concurred': 2,
    'concurrence': 3,
    'concurrent': 3,
    'concurrently': 4,
    'concurring': 3,
    'concurs': 2,
    'concussion': 3,
    'concussions': 3,
    'conde': 1,
    'condello': 3,
    'condemn': 2,
    'condemnation': 4,
    'condemnations': 4,
    'condemned': 2,
    'condemning': 3,
    'condemns': 2,
    'condensate': 3,
    'condensates': 3,
    'condensation': 4,
    'condense': 2,
    'condensed': 2,
    'condenser': 3,
    'condensing': 3,
    'conder': 2,
    'condescend': 3,
    'condescending': 4,
    'condescension': 4,
    'condie': 2,
    'condiment': 3,
    'condiments': 3,
    'condit': 2,
    'condition': 3,
    'conditional': 4,
    'conditionality': 6,
    'conditionally': 5,
    'conditioned': 3,
    'conditioner': 4,
    'conditioners': 4,
    'conditioning': 4,
    'conditions': 3,
    'conditt': 2,
    'condo': 2,
    'condolence': 3,
    'condolences': 4,
    'condom': 2,
    'condominium': 5,
    'condominiums': 5,
    'condoms': 2,
    'condon': 2,
    'condone': 2,
    'condoned': 2,
    'condones': 2,
    'condoning': 3,
    'condor': 2,
    'condors': 2,
    'condos': 2,
    'condra': 2,
    'condracky': 3,
    'condray': 2,
    'condren': 3,
    'condrey': 2,
    'condron': 2,
    'condry': 3,
    'conducive': 3,
    'conduct': 2,
    'conducted': 3,
    'conducting': 3,
    'conduction': 3,
    'conductive': 3,
    'conductivity': 5,
    'conductor': 3,
    'conductors': 3,
    'conducts': 2,
    'conduit': 3,
    'conduits': 3,
    'cone': 1,
    'coneflower': 3,
    'conehead': 2,
    'coneheads': 2,
    'conely': 2,
    'conerly': 3,
    'conery': 3,
    'cones': 1,
    'conestoga': 4,
    'coney': 2,
    'confabulation': 5,
    'confair': 2,
    'confect': 2,
    'confection': 3,
    'confectionaries': 5,
    'confectionary': 5,
    'confectioner': 4,
    'confectioners': 4,
    'confectionery': 5,
    'confections': 3,
    'confederacy': 5,
    'confederate': 4,
    'confederation': 5,
    'confer': 2,
    'conferee': 3,
    'conferees': 3,
    'conference': 3,
    'conferences': 4,
    'conferencing': 3,
    'conferred': 2,
    'conferring': 3,
    'confers': 2,
    'confess': 2,
    'confessed': 2,
    'confesses': 3,
    'confessing': 3,
    'confession': 3,
    'confessional': 4,
    'confessionals': 4,
    'confessions': 3,
    'confetti': 3,
    'confidant': 3,
    'confidante': 3,
    'confidants': 3,
    'confide': 2,
    'confided': 3,
    'confidence': 3,
    'confidences': 4,
    'confident': 3,
    'confidential': 4,
    'confidentiality': 7,
    'confidentially': 5,
    'confidently': 4,
    'confides': 2,
    'confiding': 3,
    'configuration': 5,
    'configurations': 5,
    'configure': 3,
    'configured': 3,
    'configuring': 4,
    'confindustria': 5,
    'confine': 2,
    'confined': 2,
    'confinement': 3,
    'confines': 2,
    'confining': 3,
    'confirm': 2,
    'confirmable': 4,
    'confirmation': 4,
    'confirmations': 4,
    'confirmatory': 5,
    'confirmed': 2,
    'confirming': 3,
    'confirms': 2,
    'confiscate': 3,
    'confiscated': 4,
    'confiscating': 4,
    'confiscation': 4,
    'confiscatory': 5,
    'confiterias': 5,
    'conflagration': 4,
    'conflate': 2,
    'conflates': 2,
    'conflation': 3,
    'conflict': 2,
    'conflicted': 3,
    'conflicting': 3,
    'conflicts': 2,
    'confluence': 3,
    'confluent': 3,
    'conform': 2,
    'conformance': 3,
    'conformational': 5,
    'conformed': 2,
    'conforming': 3,
    'conformist': 3,
    'conformists': 3,
    'conformity': 4,
    'conforms': 2,
    'conforti': 3,
    'confound': 2,
    'confounded': 3,
    'confounding': 3,
    'confounds': 2,
    'confrere': 2,
    'confreres': 2,
    'confront': 2,
    'confrontation': 4,
    'confrontational': 5,
    'confrontations': 4,
    'confronted': 3,
    'confronting': 3,
    'confronts': 2,
    'confucian': 3,
    'confucianism': 5,
    'confucius': 3,
    'confuse': 2,
    'confused': 2,
    'confuses': 3,
    'confusing': 3,
    'confusingly': 4,
    'confusion': 3,
    'confusions': 3,
    'cong': 1,
    'conga': 2,
    'congdon': 2,
    'congeal': 2,
    'congealed': 2,
    'congenial': 3,
    'congeniality': 6,
    'congenital': 4,
    'conger': 2,
    'congest': 2,
    'congested': 3,
    'congestion': 3,
    'congestive': 3,
    'congleton': 3,
    'conglomerate': 4,
    'conglomerates': 4,
    'conglomeration': 5,
    'congo': 2,
    'congolese': 3,
    'congratulate': 4,
    'congratulated': 5,
    'congratulating': 5,
    'congratulation': 5,
    'congratulations': 5,
    'congratulatory': 6,
    'congregant': 3,
    'congregants': 3,
    'congregate': 3,
    'congregated': 4,
    'congregation': 4,
    'congregational': 5,
    'congregations': 4,
    'congress': 2,
    'congresses': 3,
    'congressional': 4,
    'congressionally': 5,
    'congressman': 3,
    'congressmen': 3,
    'congresspeople': 4,
    'congressperson': 4,
    'congresspersons': 4,
    'congresswoman': 4,
    'congresswomen': 4,
    'congrove': 2,
    'congruence': 3,
    'congruity': 4,
    'conic': 2,
    'conical': 3,
    'conics': 2,
    'conifer': 3,
    'coniferous': 4,
    'conifers': 3,
    'conigliaro': 5,
    'coniglio': 4,
    'coniston': 3,
    'conjecture': 3,
    'conjectures': 3,
    'conjoin': 2,
    'conjoined': 2,
    'conjugal': 3,
    'conjugate': 3,
    'conjugated': 4,
    'conjugates': 3,
    'conjugation': 4,
    'conjugations': 4,
    'conjul': 2,
    'conjunction': 3,
    'conjunctions': 3,
    'conjunctiva': 4,
    'conjure': 2,
    'conjured': 2,
    'conjures': 2,
    'conjuring': 3,
    'conjuror': 3,
    'conk': 1,
    'conkel': 2,
    'conkey': 2,
    'conkin': 2,
    'conkle': 2,
    'conklin': 2,
    'conkling': 2,
    'conkright': 2,
    'conlan': 2,
    'conlee': 2,
    'conley': 2,
    'conlin': 2,
    'conlon': 2,
    'conlow': 2,
    'conly': 2,
    'conn': 1,
    'connally': 3,
    'connan': 2,
    'connaught': 2,
    'connaughton': 3,
    'connaway': 3,
    'connect': 2,
    'connected': 3,
    'connecter': 3,
    'connecticut': 4,
    'connecting': 3,
    'connection': 3,
    'connections': 3,
    'connective': 3,
    'connectivity': 5,
    'connector': 3,
    'connectors': 3,
    'connects': 2,
    'conned': 1,
    'conneely': 3,
    'connell': 2,
    'connelley': 3,
    'connelly': 3,
    'connely': 2,
    'conner': 2,
    'connerly': 3,
    'conners': 2,
    'connery': 3,
    'connett': 2,
    'connick': 2,
    'connie': 2,
    'conniff': 2,
    'conning': 2,
    'conniption': 3,
    'connivance': 3,
    'connive': 2,
    'conniving': 3,
    'connoisseur': 3,
    'connoisseurs': 3,
    'connole': 2,
    'connolly': 3,
    'connon': 2,
    'connor': 2,
    'connors': 2,
    'connotation': 4,
    'connotational': 5,
    'connotations': 4,
    'connote': 2,
    'connotes': 2,
    'conny': 2,
    'conoco': 3,
    'conolly': 3,
    'conoly': 3,
    'conover': 3,
    'conquer': 2,
    'conquered': 2,
    'conquering': 3,
    'conqueror': 3,
    'conquerors': 3,
    'conquers': 2,
    'conquest': 2,
    'conquests': 2,
    'conrac': 2,
    'conrad': 2,
    'conrades': 2,
    'conradi': 3,
    'conradine': 3,
    'conrads': 2,
    'conradt': 2,
    'conrady': 3,
    'conrail': 2,
    'conran': 2,
    'conrath': 2,
    'conrey': 2,
    'conroe': 2,
    'conrow': 2,
    'conroy': 2,
    'conry': 2,
    'cons': 1,
    'consalvo': 3,
    'conscience': 2,
    'consciences': 3,
    'conscientious': 4,
    'conscientiously': 5,
    'conscious': 2,
    'consciously': 3,
    'consciousness': 3,
    'conscript': 2,
    'conscripted': 3,
    'conscription': 3,
    'conscripts': 2,
    'conseco': 3,
    'consecrate': 3,
    'consecrated': 4,
    'consecration': 4,
    'consecrations': 4,
    'consecutive': 4,
    'consecutively': 5,
    'consensual': 4,
    'consensus': 3,
    'consent': 2,
    'consented': 3,
    'consenting': 3,
    'consentino': 4,
    'consents': 2,
    'consequence': 3,
    'consequences': 4,
    'consequent': 3,
    'consequential': 4,
    'consequently': 4,
    'conser': 2,
    'conservancy': 4,
    'conservation': 4,
    'conservationist': 5,
    'conservationists': 5,
    'conservatism': 5,
    'conservative': 4,
    'conservatively': 5,
    'conservatives': 4,
    'conservativism': 6,
    'conservator': 4,
    'conservatories': 5,
    'conservators': 4,
    'conservatorship': 5,
    'conservatory': 5,
    'conserve': 2,
    'conserved': 2,
    'conserving': 3,
    'conshohocken': 4,
    'consider': 3,
    'considerable': 5,
    'considerably': 5,
    'considerate': 4,
    'consideration': 5,
    'considerations': 5,
    'considered': 3,
    'considering': 4,
    'considers': 3,
    'considine': 3,
    'consiglio': 4,
    'consign': 2,
    'consigned': 2,
    'consignment': 3,
    'consis': 2,
    'consist': 2,
    'consisted': 3,
    'consistence': 3,
    'consistency': 4,
    'consistent': 3,
    'consistently': 4,
    'consisting': 3,
    'consists': 2,
    'consob': 2,
    'consol': 2,
    'consolable': 4,
    'consolably': 4,
    'consolata': 4,
    'consolation': 4,
    'consolations': 4,
    'console': 2,
    'consoled': 2,
    'consoles': 2,
    'consoli': 3,
    'consolidate': 4,
    'consolidated': 5,
    'consolidates': 4,
    'consolidating': 5,
    'consolidation': 5,
    'consolidations': 5,
    'consolidator': 5,
    'consolidators': 5,
    'consoling': 3,
    'consolo': 3,
    'consonant': 3,
    'consonantal': 4,
    'consonants': 3,
    'consort': 2,
    'consortia': 3,
    'consorting': 3,
    'consortium': 4,
    'consortiums': 3,
    'conspicuous': 4,
    'conspicuously': 5,
    'conspiracies': 4,
    'conspiracy': 4,
    'conspirator': 4,
    'conspiratorial': 6,
    'conspiratorially': 7,
    'conspirators': 4,
    'conspire': 3,
    'conspired': 3,
    'conspiring': 3,
    'constable': 3,
    'constables': 3,
    'constabulary': 5,
    'constance': 2,
    'constancy': 3,
    'constant': 2,
    'constanta': 3,
    'constantin': 3,
    'constantina': 4,
    'constantine': 3,
    'constantinides': 5,
    'constantino': 4,
    'constantinople': 5,
    'constantinos': 4,
    'constantly': 3,
    'constants': 2,
    'constar': 2,
    'constellation': 4,
    'constellations': 4,
    'consternation': 4,
    'constipate': 3,
    'constipated': 4,
    'constipates': 3,
    'constipation': 4,
    'constituencies': 5,
    'constituency': 5,
    'constituent': 4,
    'constituents': 4,
    'constitute': 3,
    'constituted': 4,
    'constitutes': 3,
    'constituting': 4,
    'constitution': 4,
    'constitutional': 5,
    'constitutionality': 7,
    'constitutionally': 6,
    'constitutionist': 5,
    'constitutionists': 5,
    'constitutions': 4,
    'constrain': 2,
    'constrained': 2,
    'constraining': 3,
    'constrains': 2,
    'constraint': 2,
    'constraints': 2,
    'constrict': 2,
    'constricted': 3,
    'constricting': 3,
    'constriction': 3,
    'constrictions': 3,
    'constrictor': 3,
    'constrictors': 3,
    'construcciones': 5,
    'construct': 2,
    'constructed': 3,
    'constructing': 3,
    'construction': 3,
    'constructionist': 4,
    'constructions': 3,
    'constructive': 3,
    'constructively': 4,
    'constructor': 3,
    'constructors': 3,
    'constructs': 2,
    'construe': 2,
    'construed': 2,
    'consuela': 3,
    'consul': 2,
    'consular': 3,
    'consulate': 3,
    'consulates': 3,
    'consulship': 3,
    'consult': 2,
    'consultancy': 4,
    'consultant': 3,
    'consultants': 3,
    'consultation': 4,
    'consultations': 4,
    'consultative': 4,
    'consulted': 3,
    'consulting': 3,
    'consults': 2,
    'consumable': 4,
    'consume': 2,
    'consumed': 2,
    'consumer': 3,
    'consumerism': 5,
    'consumerist': 4,
    'consumerists': 4,
    'consumers': 3,
    'consumes': 2,
    'consuming': 3,
    'consummate': 3,
    'consummated': 4,
    'consummates': 3,
    'consummating': 4,
    'consummation': 4,
    'consumption': 3,
    'contac': 2,
    'contact': 2,
    'contacted': 3,
    'contacting': 3,
    'contacts': 2,
    'contadora': 4,
    'contagion': 3,
    'contagious': 3,
    'contagiousness': 4,
    'contain': 2,
    'contained': 2,
    'container': 3,
    'containerboard': 4,
    'containerize': 4,
    'containerized': 4,
    'containers': 3,
    'containing': 3,
    'containment': 3,
    'contains': 2,
    'contaminant': 4,
    'contaminants': 4,
    'contaminate': 4,
    'contaminated': 5,
    'contaminates': 4,
    'contaminating': 5,
    'contamination': 5,
    'contant': 2,
    'conte': 1,
    'contel': 2,
    'contemplate': 3,
    'contemplated': 4,
    'contemplates': 3,
    'contemplating': 4,
    'contemplation': 4,
    'contemplative': 4,
    'contempo': 3,
    'contemporaneous': 6,
    'contemporaneously': 7,
    'contemporaries': 5,
    'contemporary': 5,
    'contempt': 2,
    'contemptible': 4,
    'contemptuous': 4,
    'contemptuously': 4,
    'contend': 2,
    'contended': 3,
    'contender': 3,
    'contenders': 3,
    'contending': 3,
    'contends': 2,
    'content': 2,
    'contented': 3,
    'contentedly': 4,
    'contention': 3,
    'contentioned': 3,
    'contentions': 3,
    'contentious': 3,
    'contentiousness': 4,
    'contentment': 3,
    'contento': 3,
    'contents': 2,
    'contest': 2,
    'contestable': 4,
    'contestant': 3,
    'contestants': 3,
    'contested': 3,
    'contesting': 3,
    'contests': 2,
    'context': 2,
    'contexts': 2,
    'conti': 2,
    'contibel': 3,
    'conticommodity': 6,
    'contiguous': 4,
    'continent': 3,
    'continental': 4,
    'continentally': 5,
    'continentals': 4,
    'continents': 3,
    'contingencies': 4,
    'contingency': 4,
    'contingent': 3,
    'contingents': 3,
    'contini': 3,
    'contino': 3,
    'continual': 4,
    'continually': 5,
    'continuance': 4,
    'continuances': 5,
    'continuation': 5,
    'continue': 3,
    'continued': 3,
    'continues': 3,
    'continuing': 4,
    'continuity': 5,
    'continuous': 4,
    'continuously': 5,
    'continuum': 4,
    'contois': 2,
    'contort': 2,
    'contorted': 3,
    'contortion': 3,
    'contortionist': 4,
    'contortions': 3,
    'contorts': 2,
    'contos': 2,
    'contour': 2,
    'contoured': 2,
    'contours': 2,
    'contra': 2,
    'contraband': 3,
    'contrabassoon': 4,
    'contraception': 4,
    'contraceptive': 4,
    'contraceptives': 4,
    'contract': 2,
    'contracted': 3,
    'contracting': 3,
    'contraction': 3,
    'contractionary': 5,
    'contractions': 3,
    'contractor': 3,
    'contractors': 3,
    'contracts': 2,
    'contractual': 4,
    'contractually': 5,
    'contradict': 3,
    'contradicted': 4,
    'contradicting': 4,
    'contradiction': 4,
    'contradictions': 4,
    'contradictorily': 6,
    'contradictory': 5,
    'contradicts': 3,
    'contran': 2,
    'contraption': 3,
    'contraptions': 3,
    'contrarian': 4,
    'contrarians': 4,
    'contrariness': 4,
    'contrary': 3,
    'contras': 2,
    'contrast': 2,
    'contrasted': 3,
    'contrasting': 3,
    'contrasts': 2,
    'contravene': 3,
    'contravention': 4,
    'contreras': 3,
    'contretemps': 3,
    'contribute': 3,
    'contributed': 4,
    'contributes': 3,
    'contributing': 4,
    'contribution': 4,
    'contributions': 4,
    'contributor': 4,
    'contributors': 4,
    'contributory': 5,
    'contrite': 2,
    'contrition': 3,
    'contrivance': 3,
    'contrivances': 4,
    'contrive': 2,
    'contrived': 2,
    'control': 2,
    'controladora': 5,
    'controllable': 4,
    'controlled': 2,
    'controller': 3,
    'controllers': 3,
    'controlling': 3,
    'controls': 2,
    'controversial': 4,
    'controversies': 4,
    'controversy': 4,
    'contura': 3,
    'contusion': 3,
    'contusions': 3,
    'conundrum': 3,
    'conus': 2,
    'convair': 2,
    'convalesce': 3,
    'convalescence': 4,
    'convalescent': 4,
    'convection': 3,
    'convective': 3,
    'convene': 2,
    'convened': 2,
    'convenes': 2,
    'convenience': 3,
    'conveniences': 4,
    'convenient': 3,
    'conveniently': 4,
    'convening': 3,
    'convent': 2,
    'convention': 3,
    'conventional': 4,
    'conventionally': 5,
    'conventioneer': 4,
    'conventioneers': 4,
    'conventions': 3,
    'convents': 2,
    'converge': 2,
    'converged': 2,
    'convergence': 3,
    'convergent': 3,
    'converging': 3,
    'conversant': 3,
    'conversation': 4,
    'conversational': 5,
    'conversationalist': 6,
    'conversations': 4,
    'converse': 2,
    'conversed': 2,
    'conversely': 3,
    'converses': 3,
    'conversing': 3,
    'conversion': 3,
    'conversions': 3,
    'convert': 2,
    'converted': 3,
    'converter': 3,
    'converters': 3,
    'convertibility': 6,
    'convertible': 4,
    'convertibles': 4,
    'converting': 3,
    'converts': 2,
    'convery': 3,
    'convex': 2,
    'convey': 2,
    'conveyance': 3,
    'conveyed': 2,
    'conveyer': 3,
    'conveying': 3,
    'conveyor': 3,
    'conveys': 2,
    'convict': 2,
    'convicted': 3,
    'convicting': 3,
    'conviction': 3,
    'convictions': 3,
    'convicts': 2,
    'conville': 2,
    'convince': 2,
    'convinced': 2,
    'convinces': 3,
    'convincing': 3,
    'convincingly': 4,
    'convivial': 4,
    'convocation': 4,
    'convolute': 3,
    'convoluted': 4,
    'convolution': 4,
    'convoy': 2,
    'convoys': 2,
    'convulsant': 3,
    'convulsants': 3,
    'convulsion': 3,
    'convulsions': 3,
    'convulsive': 3,
    'conway': 2,
    'conwell': 2,
    'conyer': 2,
    'conyers': 2,
    'coo': 1,
    'coochie': 2,
    'coody': 2,
    'coogan': 2,
    'coogle': 2,
    'coogler': 3,
    'cooing': 2,
    'cook': 1,
    'cookbook': 2,
    'cookbooks': 2,
    'cooke': 1,
    'cooked': 1,
    'cooker': 2,
    'cookers': 2,
    'cookie': 2,
    'cookies': 2,
    'cooking': 2,
    'cookingham': 3,
    'cookman': 2,
    'cookout': 2,
    'cookouts': 2,
    'cooks': 1,
    'cooksey': 2,
    'cookson': 2,
    'cookston': 2,
    'cookware': 2,
    'cool': 1,
    'coolant': 2,
    'coolants': 2,
    'coolbaugh': 2,
    'coole': 1,
    'cooled': 1,
    'cooler': 2,
    'coolers': 2,
    'coolest': 2,
    'cooley': 2,
    'coolidge': 2,
    'cooling': 2,
    'coolio': 3,
    'coolly': 2,
    'coolman': 2,
    'coolness': 2,
    'cools': 1,
    'coombe': 1,
    'coombes': 1,
    'coombs': 1,
    'coomer': 2,
    'coomes': 1,
    'coon': 1,
    'coonan': 2,
    'coonce': 1,
    'coone': 1,
    'cooner': 2,
    'coones': 1,
    'cooney': 2,
    'coonradt': 2,
    'coonrod': 2,
    'coons': 1,
    'coontz': 1,
    'coop': 1,
    'cooped': 1,
    'cooper': 2,
    'cooperage': 3,
    'cooperate': 4,
    'cooperated': 5,
    'cooperates': 4,
    'cooperating': 5,
    'cooperation': 5,
    'cooperations': 5,
    'cooperative': 5,
    'cooperatively': 5,
    'cooperatives': 4,
    'cooperider': 4,
    'cooperman': 3,
    'cooperrider': 4,
    'coopers': 2,
    'coopersmith': 3,
    'cooperstein': 3,
    'cooperstown': 3,
    'coopervision': 4,
    'coopman': 2,
    'cooprider': 3,
    'coordinate': 4,
    'coordinated': 5,
    'coordinates': 4,
    'coordinating': 5,
    'coordination': 5,
    'coordinator': 5,
    'coordinators': 5,
    'coors': 1,
    'coos': 1,
    'coosa': 2,
    'coot': 1,
    'coote': 1,
    'cooter': 2,
    'coots': 1,
    'coover': 2,
    'coovick': 2,
    'cop': 1,
    'copa': 2,
    'copacabana': 5,
    'copartner': 3,
    'copartners': 3,
    'copas': 2,
    'copayment': 3,
    'copayments': 3,
    'copco': 2,
    'cope': 1,
    'coped': 1,
    'copelan': 3,
    'copeland': 2,
    'copelco': 3,
    'copelin': 3,
    'copeman': 2,
    'copen': 2,
    'copenhagen': 4,
    'copenhaver': 4,
    'copernican': 4,
    'copernicus': 4,
    'copes': 1,
    'copetas': 3,
    'copher': 2,
    'copiague': 3,
    'copied': 2,
    'copier': 3,
    'copiers': 3,
    'copies': 2,
    'copilot': 3,
    'copilots': 3,
    'coping': 2,
    'copious': 3,
    'copiously': 3,
    'coplan': 2,
    'copland': 2,
    'coplen': 3,
    'copley': 2,
    'copleys': 2,
    'coplin': 2,
    'copolla': 3,
    'copp': 1,
    'coppa': 2,
    'coppage': 2,
    'copped': 1,
    'coppedge': 2,
    'coppee': 2,
    'coppel': 2,
    'coppens': 2,
    'copper': 2,
    'copperfield': 3,
    'copperfields': 3,
    'copperhead': 3,
    'copperheads': 3,
    'copperman': 3,
    'coppernoll': 3,
    'coppers': 2,
    'coppersmith': 3,
    'copperweld': 3,
    'coppery': 3,
    'coppess': 2,
    'coppin': 2,
    'copping': 2,
    'coppinger': 3,
    'coppins': 2,
    'copple': 2,
    'coppock': 2,
    'coppola': 3,
    'coppolino': 4,
    'copps': 1,
    'copra': 2,
    'coprocessor': 4,
    'coprocessors': 4,
    'cops': 1,
    'copsey': 2,
    'coptic': 2,
    'copulate': 3,
    'copus': 2,
    'copy': 2,
    'copycat': 3,
    'copycats': 3,
    'copycatting': 4,
    'copycode': 3,
    'copycodes': 3,
    'copying': 3,
    'copyreader': 4,
    'copyreaders': 4,
    'copyright': 3,
    'copyrightable': 5,
    'copyrighted': 4,
    'copyrights': 3,
    'copytele': 4,
    'copywrite': 3,
    'copywrited': 4,
    'copywriter': 4,
    'copywriters': 4,
    'cor': 1,
    'cora': 2,
    'corabelle': 3,
    'coracle': 3,
    'coral': 2,
    'coralie': 3,
    'coraline': 3,
    'corallo': 3,
    'corals': 2,
    'coram': 2,
    'corath': 2,
    'corazon': 3,
    'corban': 2,
    'corbeil': 2,
    'corbel': 2,
    'corbell': 2,
    'corbelled': 2,
    'corbello': 3,
    'corbet': 2,
    'corbett': 2,
    'corbin': 2,
    'corbit': 2,
    'corbitt': 2,
    'corbo': 2,
    'corbridge': 2,
    'corbu': 2,
    'corbusier': 3,
    'corby': 2,
    'corcap': 2,
    'corcoran': 3,
    'cord': 1,
    'corda': 2,
    'cordage': 2,
    'cordaro': 3,
    'cordasco': 3,
    'corday': 2,
    'corded': 2,
    'cordeiro': 3,
    'cordell': 2,
    'corder': 2,
    'cordero': 3,
    'cordes': 1,
    'cordesman': 3,
    'cordial': 2,
    'cordially': 3,
    'cordials': 2,
    'cordiant': 3,
    'cordie': 2,
    'cordier': 3,
    'cordill': 2,
    'cording': 2,
    'cordis': 2,
    'cordle': 2,
    'cordless': 2,
    'cordner': 2,
    'cordoba': 3,
    'cordobas': 3,
    'cordon': 2,
    'cordone': 2,
    'cordoned': 2,
    'cordova': 3,
    'cordover': 3,
    'cordovez': 3,
    'cordray': 2,
    'cordrey': 2,
    'cordry': 3,
    'cords': 1,
    'cordts': 1,
    'corduroy': 3,
    'cordy': 2,
    'core': 1,
    'corea': 3,
    'corel': 2,
    'corell': 2,
    'corella': 3,
    'coren': 2,
    'cores': 1,
    'corespondent': 4,
    'corestate': 2,
    'corestates': 2,
    'coretech': 2,
    'coretta': 3,
    'corette': 2,
    'corexit': 3,
    'corey': 2,
    'corgi': 2,
    'coria': 3,
    'coriander': 4,
    'coriell': 2,
    'corier': 3,
    'corigliano': 5,
    'corina': 3,
    'corinn': 2,
    'corinna': 3,
    'corinne': 2,
    'corinth': 2,
    'corinthian': 4,
    'corinto': 3,
    'corio': 3,
    'corisa': 3,
    'corissa': 3,
    'cork': 1,
    'corken': 2,
    'corker': 2,
    'corkern': 2,
    'corkery': 3,
    'corkill': 2,
    'corking': 2,
    'corkins': 2,
    'corkran': 2,
    'corks': 1,
    'corkscrew': 2,
    'corkum': 2,
    'corkwood': 2,
    'corky': 2,
    'corl': 1,
    'corle': 2,
    'corleone': 3,
    'corless': 2,
    'corlett': 2,
    'corlew': 2,
    'corley': 2,
    'corliss': 2,
    'cormac': 2,
    'cormack': 2,
    'corman': 2,
    'cormany': 3,
    'cormen': 2,
    'cormican': 3,
    'cormick': 2,
    'cormier': 3,
    'cormorant': 3,
    'cormorants': 3,
    'corn': 1,
    'corna': 2,
    'cornacchia': 4,
    'cornall': 2,
    'cornblume': 2,
    'cornbread': 2,
    'corncob': 2,
    'corncrib': 2,
    'corne': 1,
    'cornea': 3,
    'corneal': 2,
    'corneas': 3,
    'cornejo': 3,
    'cornel': 2,
    'cornela': 3,
    'cornelia': 3,
    'cornelio': 4,
    'cornelious': 4,
    'cornelison': 4,
    'cornelius': 3,
    'corneliuson': 5,
    'cornell': 2,
    'cornelle': 2,
    'corner': 2,
    'cornerback': 3,
    'cornered': 2,
    'cornering': 3,
    'corners': 2,
    'cornerstone': 3,
    'cornerstones': 3,
    'cornet': 2,
    'cornett': 2,
    'cornette': 2,
    'cornettist': 3,
    'corney': 2,
    'cornfeld': 2,
    'cornfield': 2,
    'cornfields': 2,
    'cornflower': 3,
    'cornflowers': 3,
    'cornforth': 2,
    'cornhusk': 2,
    'cornhusking': 3,
    'cornhusks': 2,
    'cornia': 3,
    'corniche': 2,
    'cornick': 2,
    'cornie': 2,
    'corniel': 2,
    'corning': 2,
    'cornish': 2,
    'cornman': 2,
    'cornmeal': 2,
    'corns': 1,
    'cornstalk': 2,
    'cornstalks': 2,
    'cornstarch': 2,
    'cornucopia': 5,
    'cornwall': 2,
    'cornwallis': 3,
    'cornwell': 2,
    'corny': 2,
    'corolla': 3,
    'corollaries': 4,
    'corollary': 4,
    'corologis': 4,
    'corona': 3,
    'coronado': 4,
    'coronary': 4,
    'coronation': 4,
    'coroner': 3,
    'coroners': 3,
    'coronet': 3,
    'coronets': 3,
    'corp': 1,
    'corpening': 3,
    'corpora': 3,
    'corporacion': 5,
    'corporal': 3,
    'corporate': 3,
    'corporates': 3,
    'corporatewatch': 3,
    'corporatewide': 4,
    'corporation': 4,
    'corporations': 4,
    'corporatism': 5,
    'corporatist': 4,
    'corps': 1,
    'corpse': 1,
    'corpses': 2,
    'corpsman': 2,
    'corpulant': 3,
    'corpus': 2,
    'corpuz': 2,
    'corr': 1,
    'corradi': 3,
    'corradino': 4,
    'corrado': 3,
    'corral': 2,
    'corrales': 3,
    'corralled': 2,
    'corralling': 3,
    'corrao': 2,
    'correa': 3,
    'correale': 3,
    'correct': 2,
    'corrected': 3,
    'correcting': 3,
    'correction': 3,
    'correctional': 4,
    'corrections': 3,
    'corrective': 3,
    'correctly': 3,
    'correctness': 3,
    'corrects': 2,
    'corregidor': 4,
    'correia': 4,
    'correira': 3,
    'correlate': 3,
    'correlated': 4,
    'correlates': 3,
    'correlating': 4,
    'correlation': 4,
    'correlations': 4,
    'correll': 2,
    'correne': 2,
    'corrente': 3,
    'correnti': 3,
    'correspond': 3,
    'corresponded': 4,
    'correspondence': 4,
    'correspondent': 4,
    'correspondents': 4,
    'corresponding': 4,
    'correspondingly': 5,
    'corresponds': 3,
    'corrib': 2,
    'corrick': 2,
    'corridor': 3,
    'corridors': 3,
    'corrie': 2,
    'corriere': 2,
    'corrigan': 3,
    'corrigible': 4,
    'corriher': 3,
    'corrin': 2,
    'corrina': 3,
    'corrington': 3,
    'corriveau': 3,
    'corroborate': 4,
    'corroborated': 5,
    'corroborates': 4,
    'corroborating': 5,
    'corroboration': 5,
    'corroborative': 5,
    'corrode': 2,
    'corroded': 3,
    'corrodes': 2,
    'corron': 2,
    'corroon': 2,
    'corrosion': 3,
    'corrosive': 3,
    'corrow': 2,
    'corrugate': 3,
    'corrugated': 4,
    'corrupt': 2,
    'corrupted': 3,
    'corruptible': 4,
    'corrupting': 3,
    'corruption': 3,
    'corruptions': 3,
    'corruptive': 3,
    'corruptly': 3,
    'corrupts': 2,
    'corry': 2,
    'corsa': 2,
    'corsage': 2,
    'corsair': 2,
    'corsaro': 3,
    'corse': 1,
    'corsello': 3,
    'corsentino': 4,
    'corser': 2,
    'corset': 2,
    'corsetti': 3,
    'corsi': 2,
    'corsica': 3,
    'corsican': 3,
    'corsicas': 3,
    'corsiglia': 4,
    'corsini': 3,
    'corso': 2,
    'corson': 2,
    'cort': 1,
    'corte': 1,
    'cortege': 2,
    'corteland': 2,
    'cortelyou': 3,
    'corten': 2,
    'cortens': 2,
    'corter': 2,
    'cortes': 1,
    'cortese': 3,
    'cortesi': 3,
    'cortex': 2,
    'cortez': 2,
    'corteze': 2,
    'corti': 2,
    'cortical': 3,
    'corticosteroid': 5,
    'corticosteroids': 5,
    'cortin': 2,
    'cortina': 3,
    'cortinas': 3,
    'cortine': 2,
    'cortines': 2,
    'cortisol': 3,
    'cortisone': 3,
    'cortland': 2,
    'cortner': 2,
    'cortopassi': 4,
    'cortright': 2,
    'corum': 2,
    'corundum': 3,
    'corva': 2,
    'corvallis': 3,
    'corvette': 2,
    'corvettes': 2,
    'corvin': 2,
    'corvino': 3,
    'corvo': 2,
    'corvus': 2,
    'corwin': 2,
    'cory': 2,
    'corzine': 3,
    'corzo': 2,
    'cos': 1,
    'cosa': 2,
    'cosatu': 3,
    'cosby': 2,
    'coscia': 2,
    'cose': 1,
    'cosell': 2,
    'cosens': 2,
    'cosentino': 4,
    'cosenza': 3,
    'coseratz': 3,
    'cosette': 2,
    'cosey': 2,
    'cosgriff': 2,
    'cosgrove': 2,
    'cosi': 2,
    'cosic': 2,
    'cosima': 3,
    'cosio': 3,
    'coslett': 2,
    'cosma': 2,
    'cosmair': 2,
    'cosman': 2,
    'cosme': 1,
    'cosmetic': 3,
    'cosmetically': 4,
    'cosmetics': 3,
    'cosmetology': 5,
    'cosmic': 2,
    'cosmo': 2,
    'cosmodrome': 3,
    'cosmology': 4,
    'cosmonaut': 3,
    'cosmonauts': 3,
    'cosmopolitan': 5,
    'cosmopulos': 4,
    'cosmos': 2,
    'cosner': 2,
    'cosper': 2,
    'cosponsor': 3,
    'cosponsors': 3,
    'coss': 1,
    'cossa': 2,
    'cossack': 2,
    'cossacks': 2,
    'cossairt': 2,
    'cossat': 2,
    'cosset': 2,
    'cossette': 2,
    'cossey': 2,
    'cossiga': 3,
    'cossin': 2,
    'cossman': 2,
    'cost': 1,
    'costa': 2,
    'costabile': 3,
    'costain': 2,
    'costales': 2,
    'costano': 3,
    'costantini': 4,
    'costantino': 4,
    'costanza': 3,
    'costanzo': 3,
    'costar': 2,
    'costars': 2,
    'costas': 2,
    'costco': 2,
    'costcutting': 3,
    'coste': 1,
    'costed': 2,
    'costeira': 4,
    'costella': 3,
    'costello': 3,
    'costen': 2,
    'coster': 2,
    'costigan': 3,
    'costilla': 3,
    'costilow': 3,
    'costin': 2,
    'costing': 2,
    'costley': 2,
    'costlier': 3,
    'costliest': 3,
    'costlow': 2,
    'costly': 2,
    'costner': 2,
    'coston': 2,
    'costra': 2,
    'costs': 1,
    'costume': 2,
    'costumed': 2,
    'costumer': 3,
    'costumers': 3,
    'costumes': 2,
    'cosurtuh': 3,
    'cosy': 2,
    'cot': 1,
    'cota': 2,
    'cote': 1,
    'cotelle': 2,
    'coterie': 3,
    'cotes': 1,
    'cotham': 2,
    'cothern': 2,
    'cothran': 2,
    'cothren': 3,
    'cothron': 2,
    'cotija': 3,
    'cotilla': 3,
    'cotler': 3,
    'cotman': 2,
    'cotner': 2,
    'cotney': 2,
    'cotnoir': 2,
    'coto': 2,
    'cotret': 2,
    'cotrone': 3,
    'cotroneo': 4,
    'cots': 1,
    'cott': 1,
    'cotta': 2,
    'cottage': 2,
    'cottages': 3,
    'cottam': 2,
    'cottee': 2,
    'cotten': 2,
    'cotter': 2,
    'cotterill': 3,
    'cotterman': 3,
    'cottier': 3,
    'cottingham': 3,
    'cottle': 2,
    'cottman': 2,
    'cotto': 2,
    'cottom': 2,
    'cotton': 2,
    'cottone': 3,
    'cottoned': 2,
    'cottongin': 3,
    'cottonmouth': 3,
    'cottons': 2,
    'cottonseed': 3,
    'cottonwood': 3,
    'cottonwoods': 3,
    'cottrell': 2,
    'cottrill': 2,
    'cotty': 2,
    'cotugno': 3,
    'coty': 2,
    'cou': 1,
    'couch': 1,
    'couched': 1,
    'couches': 2,
    'couching': 2,
    'couchman': 2,
    'coudersport': 3,
    'coudert': 2,
    'couey': 2,
    'coufal': 2,
    'cougar': 2,
    'cougars': 2,
    'cough': 1,
    'coughed': 1,
    'coughenour': 3,
    'coughing': 2,
    'coughlan': 2,
    'coughlin': 2,
    'coughran': 2,
    'coughs': 1,
    'couillard': 2,
    'could': 1,
    'coulee': 2,
    'coull': 1,
    'coulombe': 3,
    'coulon': 2,
    'coulson': 2,
    'coulston': 2,
    'coultas': 2,
    'coulter': 2,
    'coulthard': 2,
    'counce': 1,
    'council': 2,
    'councilman': 3,
    'councilmen': 3,
    'councilor': 3,
    'councilors': 3,
    'councils': 2,
    'councilwoman': 4,
    'councilwomen': 4,
    'counihan': 3,
    'counsel': 2,
    'counseled': 2,
    'counseling': 3,
    'counsell': 2,
    'counsellor': 3,
    'counsellors': 3,
    'counselman': 3,
    'counselor': 3,
    'counselors': 3,
    'counsels': 2,
    'count': 1,
    'countable': 3,
    'countdown': 2,
    'countdowns': 2,
    'counted': 2,
    'countenance': 3,
    'countenanced': 3,
    'countenances': 4,
    'counter': 2,
    'counteract': 3,
    'counteracted': 4,
    'counteracting': 4,
    'counterattack': 4,
    'counterattacked': 4,
    'counterattacking': 5,
    'counterattacks': 4,
    'counterbalance': 4,
    'counterbalanced': 4,
    'counterbalances': 5,
    'counterbalancing': 5,
    'counterbid': 3,
    'counterbids': 3,
    'counterchallenge': 4,
    'counterchallenges': 5,
    'countercharge': 3,
    'countercharges': 4,
    'counterclaim': 3,
    'counterclaims': 3,
    'counterclockwise': 4,
    'countercultural': 5,
    'counterculture': 4,
    'countered': 2,
    'counterespionage': 6,
    'counterfeit': 3,
    'counterfeited': 4,
    'counterfeiter': 4,
    'counterfeiters': 4,
    'counterfeiting': 4,
    'counterfeits': 3,
    'counterforce': 3,
    'counterforces': 4,
    'countering': 3,
    'counterinsurgencies': 6,
    'counterinsurgency': 6,
    'counterintelligence': 6,
    'counterlife': 3,
    'counterman': 3,
    'countermeasure': 4,
    'countermeasures': 4,
    'countermove': 3,
    'countermoves': 3,
    'counteroffensive': 5,
    'counteroffensives': 5,
    'counteroffer': 4,
    'counteroffers': 4,
    'counterpart': 3,
    'counterparts': 3,
    'counterparty': 4,
    'counterpoint': 3,
    'counterpoints': 3,
    'counterproductive': 5,
    'counterproposal': 5,
    'counterproposals': 5,
    'counterpunch': 3,
    'counterrevolt': 4,
    'counterrevolution': 6,
    'counterrevolutionary': 8,
    'counters': 2,
    'countersue': 3,
    'countersued': 3,
    'countersuing': 4,
    'countersuit': 3,
    'countertenor': 4,
    'counterterrorism': 6,
    'counterterrorist': 5,
    'counterterrorists': 5,
    'countertop': 3,
    'countertops': 3,
    'countertrade': 3,
    'countervail': 3,
    'countervailing': 4,
    'counterweight': 3,
    'counterweights': 3,
    'countess': 2,
    'countesses': 3,
    'counties': 2,
    'counting': 2,
    'countless': 2,
    'countries': 2,
    'country': 2,
    'countryfolk': 3,
    'countryman': 3,
    'countrymen': 3,
    'countryside': 3,
    'countrywide': 3,
    'counts': 1,
    'county': 2,
    'coup': 1,
    'coupe': 1,
    'couper': 2,
    'coupes': 1,
    'coupland': 2,
    'couple': 2,
    'coupled': 2,
    'coupler': 2,
    'couplers': 2,
    'couples': 2,
    'coupley': 2,
    'coupling': 2,
    'couplings': 2,
    'coupon': 2,
    'couponing': 3,
    'couponite': 3,
    'couponites': 3,
    'coupons': 2,
    'coups': 1,
    'cour': 1,
    'courage': 2,
    'courageous': 3,
    'courageously': 4,
    'courant': 2,
    'courchaine': 2,
    'courchesne': 2,
    'couric': 2,
    'courier': 3,
    'couriers': 3,
    'courington': 3,
    'cournoyer': 3,
    'course': 1,
    'coursen': 2,
    'courser': 2,
    'courses': 2,
    'coursey': 2,
    'coursing': 2,
    'courson': 2,
    'court': 1,
    'courtade': 2,
    'courtaulds': 2,
    'courteau': 2,
    'courted': 2,
    'courtemanche': 2,
    'courtenay': 3,
    'courteous': 3,
    'courter': 2,
    'courtesies': 3,
    'courtesy': 3,
    'courthouse': 2,
    'courthouses': 3,
    'courtier': 3,
    'courtiers': 3,
    'courting': 2,
    'courtis': 2,
    'courtland': 2,
    'courtly': 2,
    'courtney': 2,
    'courtois': 2,
    'courtright': 2,
    'courtroom': 2,
    'courtrooms': 2,
    'courts': 1,
    'courtship': 2,
    'courtwright': 2,
    'courtyard': 2,
    'courtyards': 2,
    'courville': 2,
    'coury': 2,
    'cousar': 2,
    'couse': 1,
    'cousens': 2,
    'couser': 2,
    'cousin': 2,
    'cousineau': 3,
    'cousino': 3,
    'cousins': 2,
    'cousteau': 2,
    'cousy': 2,
    'coutant': 2,
    'coutee': 2,
    'couto': 2,
    'couts': 1,
    'coutts': 1,
    'coutu': 2,
    'couture': 2,
    'couturier': 4,
    'couvillion': 3,
    'couvillon': 3,
    'couzens': 2,
    'coval': 3,
    'covalt': 2,
    'covarrubias': 5,
    'covas': 2,
    'covatta': 3,
    'covault': 2,
    'cove': 1,
    'covel': 2,
    'covell': 2,
    'covelli': 3,
    'covello': 3,
    'coven': 2,
    'covenant': 3,
    'covenanter': 4,
    'covenanters': 4,
    'covenants': 3,
    'coveney': 3,
    'covent': 2,
    'coventry': 3,
    'cover': 2,
    'coverage': 3,
    'coverages': 4,
    'coverall': 3,
    'coveralls': 3,
    'coverdale': 3,
    'coverdell': 3,
    'covered': 2,
    'covering': 3,
    'coverings': 3,
    'covers': 2,
    'coverstone': 3,
    'covert': 2,
    'covertly': 3,
    'coverup': 3,
    'coverups': 3,
    'coves': 1,
    'covet': 2,
    'coveted': 3,
    'covets': 2,
    'covey': 2,
    'covia': 3,
    'coviello': 4,
    'covill': 2,
    'coville': 2,
    'covin': 2,
    'covina': 3,
    'covington': 3,
    'covino': 3,
    'covitz': 2,
    'covy': 2,
    'cow': 1,
    'cowabunga': 4,
    'cowan': 2,
    'cowans': 2,
    'coward': 2,
    'cowardice': 3,
    'cowardly': 3,
    'cowards': 2,
    'cowart': 2,
    'cowbell': 2,
    'cowbells': 2,
    'cowboy': 2,
    'cowboys': 2,
    'cowden': 2,
    'cowdery': 3,
    'cowdrey': 2,
    'cowed': 1,
    'coweda': 3,
    'cowell': 2,
    'cowen': 2,
    'cowens': 2,
    'cower': 2,
    'cowering': 3,
    'cowger': 2,
    'cowgill': 2,
    'cowgirl': 2,
    'cowgirls': 2,
    'cowher': 2,
    'cowherd': 2,
    'cowie': 2,
    'cowin': 2,
    'cowing': 2,
    'cowl': 1,
    'cowles': 2,
    'cowley': 2,
    'cowlick': 2,
    'cowlicks': 2,
    'cowling': 2,
    'cowlings': 2,
    'cowman': 2,
    'coworker': 3,
    'coworkers': 3,
    'cowper': 2,
    'cowperthwaite': 3,
    'cowries': 2,
    'cows': 1,
    'cowser': 2,
    'cowsert': 2,
    'cowslip': 2,
    'cox': 1,
    'coxe': 1,
    'coxen': 2,
    'coxon': 2,
    'coxwell': 2,
    'coy': 1,
    'coye': 1,
    'coyer': 2,
    'coykendall': 3,
    'coyle': 1,
    'coyly': 2,
    'coym': 1,
    'coyne': 1,
    'coyner': 2,
    'coyote': 3,
    'coyotes': 3,
    'coz': 1,
    'cozad': 2,
    'cozart': 2,
    'cozby': 2,
    'cozier': 3,
    'cozine': 3,
    'coziness': 3,
    'cozman': 2,
    'cozy': 2,
    'cozying': 3,
    'cozza': 2,
    'cozzens': 2,
    'cozzi': 2,
    'cozzolino': 4,
    'crab': 1,
    'crabb': 1,
    'crabbe': 1,
    'crabbed': 1,
    'crabbs': 1,
    'crabby': 2,
    'crabeater': 3,
    'crabill': 2,
    'crable': 2,
    'crabmeat': 2,
    'crabs': 1,
    'crabtree': 2,
    'cracchiolo': 4,
    'crace': 1,
    'crack': 1,
    'crackdown': 2,
    'crackdowns': 2,
    'cracked': 1,
    'crackel': 2,
    'cracker': 2,
    'crackerjack': 3,
    'crackers': 2,
    'cracking': 2,
    'crackle': 2,
    'crackled': 2,
    'crackles': 2,
    'crackling': 2,
    'crackpot': 2,
    'crackpots': 2,
    'cracks': 1,
    'cracraft': 2,
    'craddock': 2,
    'crader': 2,
    'cradic': 2,
    'cradle': 2,
    'cradles': 2,
    'cradling': 3,
    'cradock': 2,
    'crady': 2,
    'craft': 1,
    'crafted': 2,
    'crafting': 2,
    'crafton': 2,
    'crafts': 1,
    'craftsman': 2,
    'craftsmanship': 3,
    'craftsmen': 2,
    'craftspeople': 3,
    'crafty': 2,
    'crager': 2,
    'cragg': 1,
    'craggs': 1,
    'craggy': 2,
    'craghead': 2,
    'cragin': 2,
    'cragle': 2,
    'crago': 2,
    'cragun': 2,
    'craib': 1,
    'craig': 1,
    'craighead': 2,
    'craigie': 2,
    'craigo': 2,
    'crail': 1,
    'crain': 1,
    'craine': 1,
    'craker': 2,
    'crall': 1,
    'cram': 1,
    'cramblit': 2,
    'cramer': 2,
    'crames': 1,
    'crammed': 1,
    'crammer': 2,
    'cramming': 2,
    'cramp': 1,
    'cramped': 1,
    'cramping': 2,
    'crampon': 2,
    'crampons': 2,
    'cramps': 1,
    'crampton': 2,
    'crams': 1,
    'cramton': 2,
    'cranberries': 3,
    'cranberry': 3,
    'crance': 1,
    'crandall': 2,
    'crandell': 2,
    'crane': 1,
    'craned': 1,
    'craner': 2,
    'cranes': 1,
    'cranesbill': 2,
    'cranesbills': 2,
    'craney': 2,
    'cranfield': 2,
    'cranfill': 2,
    'cranford': 2,
    'cranial': 2,
    'cranium': 3,
    'crank': 1,
    'cranked': 1,
    'cranking': 2,
    'cranks': 1,
    'crankshaft': 2,
    'crankshafts': 2,
    'cranky': 2,
    'cranley': 2,
    'cranmer': 2,
    'cranmore': 2,
    'crannell': 2,
    'cranney': 2,
    'crannies': 2,
    'cranny': 2,
    'cranor': 2,
    'crans': 1,
    'cranshaw': 2,
    'cranston': 2,
    'crap': 1,
    'crapo': 2,
    'crappie': 2,
    'crapps': 1,
    'crappy': 2,
    'craps': 1,
    'crapser': 2,
    'crapshoot': 2,
    'crary': 3,
    'crase': 1,
    'crash': 1,
    'crashed': 1,
    'crashes': 2,
    'crashing': 2,
    'crasner': 2,
    'crasnianski': 4,
    'crass': 1,
    'crassweller': 3,
    'crate': 1,
    'crater': 2,
    'cratered': 2,
    'craters': 2,
    'crates': 1,
    'cratia': 2,
    'crating': 2,
    'craton': 2,
    'cratty': 2,
    'craugh': 1,
    'craun': 1,
    'cravath': 2,
    'crave': 1,
    'craved': 1,
    'craven': 2,
    'cravens': 2,
    'craver': 2,
    'craves': 1,
    'cravey': 2,
    'cravin': 2,
    'craving': 2,
    'cravings': 2,
    'craw': 1,
    'crawfish': 2,
    'crawford': 2,
    'crawfordsville': 3,
    'crawl': 1,
    'crawled': 1,
    'crawley': 2,
    'crawling': 2,
    'crawls': 1,
    'crawly': 2,
    'crawmer': 2,
    'crawshaw': 2,
    'craxi': 2,
    'cray': 1,
    'craycraft': 2,
    'crayfish': 2,
    'crayne': 1,
    'crayon': 2,
    'crayons': 2,
    'crays': 1,
    'crayton': 2,
    'craze': 1,
    'crazed': 1,
    'crazier': 3,
    'crazies': 2,
    'craziest': 3,
    'crazily': 3,
    'craziness': 3,
    'crazy': 2,
    'crea': 1,
    'creach': 1,
    'creager': 3,
    'creagh': 1,
    'creak': 1,
    'creaked': 1,
    'creaking': 2,
    'creaky': 2,
    'creal': 1,
    'cream': 1,
    'creamed': 1,
    'creamer': 2,
    'creamery': 3,
    'creamier': 3,
    'creamiest': 3,
    'creams': 1,
    'creamy': 2,
    'crean': 1,
    'crear': 1,
    'crease': 1,
    'creases': 2,
    'creasey': 2,
    'creasman': 2,
    'creason': 2,
    'creasy': 2,
    'create': 2,
    'created': 3,
    'creates': 2,
    'creath': 1,
    'creating': 3,
    'creation': 3,
    'creationism': 5,
    'creations': 3,
    'creative': 3,
    'creatively': 4,
    'creativeness': 4,
    'creativity': 5,
    'creatologist': 5,
    'creatologists': 5,
    'creator': 3,
    'creators': 3,
    'creature': 2,
    'creatures': 2,
    'crecelius': 4,
    'creche': 1,
    'creches': 2,
    'credence': 2,
    'credential': 3,
    'credentialed': 3,
    'credentials': 3,
    'credenza': 3,
    'credeur': 2,
    'credibility': 5,
    'credible': 3,
    'credibly': 3,
    'credit': 2,
    'creditable': 4,
    'creditably': 4,
    'creditanstalt': 4,
    'creditbank': 3,
    'credited': 3,
    'credithrift': 3,
    'crediting': 3,
    'credito': 3,
    'creditor': 3,
    'creditors': 3,
    'credits': 2,
    'creditwatch': 3,
    'creditworthiness': 5,
    'creditworthy': 4,
    'credle': 2,
    'credo': 2,
    'credulity': 4,
    'credulous': 3,
    'cree': 1,
    'creech': 1,
    'creecy': 2,
    'creed': 1,
    'creeden': 2,
    'creedon': 2,
    'creeds': 1,
    'creegan': 2,
    'creek': 1,
    'creekmore': 2,
    'creekmur': 2,
    'creeks': 1,
    'creel': 1,
    'creelman': 2,
    'creely': 2,
    'creep': 1,
    'creepiness': 3,
    'creeping': 2,
    'creeps': 1,
    'creepy': 2,
    'creer': 1,
    'crees': 1,
    'cref': 1,
    'cregan': 2,
    'cregar': 2,
    'creger': 2,
    'cregg': 1,
    'cregger': 2,
    'crego': 2,
    'crehan': 2,
    'creighton': 2,
    'creko': 2,
    'crellin': 2,
    'cremate': 2,
    'cremated': 3,
    'cremation': 3,
    'crematoria': 5,
    'crematorium': 5,
    'creme': 1,
    'cremeans': 2,
    'cremeens': 2,
    'cremer': 2,
    'cremin': 2,
    'crenelate': 3,
    'crenelated': 4,
    'crenshaw': 2,
    'crenwelge': 2,
    'creole': 2,
    'creoles': 2,
    'creolize': 3,
    'creolized': 3,
    'creosote': 3,
    'crepe': 1,
    'crepeau': 2,
    'crepes': 1,
    'creps': 1,
    'crept': 1,
    'creque': 1,
    'cresap': 2,
    'crescendo': 3,
    'crescent': 2,
    'crescents': 2,
    'crescenzi': 3,
    'crescenzo': 3,
    'cresci': 2,
    'crescott': 2,
    'creson': 2,
    'crespi': 2,
    'crespin': 2,
    'crespo': 2,
    'cress': 1,
    'cresses': 2,
    'cressey': 2,
    'cressler': 2,
    'cressman': 2,
    'cresson': 2,
    'cresswell': 2,
    'cressy': 2,
    'crest': 1,
    'crestar': 2,
    'crested': 2,
    'crestfallen': 3,
    'cresting': 2,
    'crestmont': 2,
    'crests': 1,
    'creswell': 2,
    'cretaceous': 3,
    'cretchen': 2,
    'crete': 1,
    'cretella': 3,
    'cretien': 2,
    'cretin': 2,
    'cretsinger': 3,
    'creutzfeldt': 2,
    'creveling': 3,
    'crevice': 2,
    'crevices': 3,
    'crevier': 3,
    'creviston': 3,
    'crew': 1,
    'crewe': 1,
    'crewel': 2,
    'crewes': 1,
    'crewman': 2,
    'crewmen': 2,
    'crews': 1,
    'crib': 1,
    'cribari': 3,
    'cribb': 1,
    'cribbage': 2,
    'cribbing': 2,
    'cribbs': 1,
    'cribs': 1,
    'crichlow': 2,
    'crichton': 2,
    'crick': 1,
    'cricket': 2,
    'crickets': 2,
    'criddle': 2,
    'crider': 2,
    'cried': 1,
    'crier': 2,
    'cries': 1,
    'criger': 2,
    'crigger': 2,
    'crigler': 2,
    'crihfield': 2,
    'crill': 1,
    'crilley': 2,
    'crilly': 2,
    'crim': 1,
    'crime': 1,
    'crimea': 3,
    'crimean': 2,
    'crimes': 1,
    'crimi': 2,
    'criminal': 3,
    'criminalist': 4,
    'criminalistics': 5,
    'criminalists': 4,
    'criminality': 5,
    'criminalization': 6,
    'criminalize': 4,
    'criminalized': 4,
    'criminalizing': 5,
    'criminally': 4,
    'criminals': 3,
    'criminologist': 5,
    'criminologists': 5,
    'criminology': 5,
    'crimm': 1,
    'crimmins': 2,
    'crimp': 1,
    'crimped': 1,
    'crimping': 2,
    'crimps': 1,
    'crimson': 2,
    'criner': 2,
    'cringe': 1,
    'cringed': 1,
    'cringes': 2,
    'cringing': 2,
    'crip': 1,
    'cripe': 1,
    'crippen': 2,
    'cripple': 2,
    'crippled': 2,
    'cripples': 2,
    'crippling': 3,
    'cripps': 1,
    'crips': 1,
    'cris': 1,
    'crisafi': 3,
    'crisafulli': 4,
    'crisanti': 3,
    'crisci': 2,
    'criscione': 3,
    'crisco': 2,
    'criscuolo': 3,
    'crise': 1,
    'crises': 2,
    'crishman': 2,
    'crisis': 2,
    'crisler': 3,
    'crislip': 2,
    'crisman': 2,
    'crismon': 2,
    'crisostomo': 4,
    'crisp': 1,
    'crispell': 2,
    'crispen': 2,
    'crisper': 2,
    'crispin': 2,
    'crispina': 3,
    'crispino': 3,
    'crisply': 2,
    'crispness': 2,
    'crispo': 2,
    'crispy': 2,
    'criss': 1,
    'crisscross': 2,
    'crisscrossed': 2,
    'crisscrossing': 3,
    'crissey': 2,
    'crissinger': 3,
    'crisslow': 2,
    'crissman': 2,
    'crist': 1,
    'crista': 2,
    'cristabel': 3,
    'cristal': 2,
    'cristiani': 4,
    'cristiano': 4,
    'cristina': 3,
    'cristo': 2,
    'cristobal': 3,
    'cristobel': 3,
    'criswell': 2,
    'critcher': 2,
    'critchfield': 2,
    'critchley': 2,
    'critchlow': 2,
    'critelli': 3,
    'criteria': 4,
    'criterion': 4,
    'crites': 1,
    'critic': 2,
    'critical': 3,
    'criticality': 5,
    'critically': 4,
    'criticism': 4,
    'criticisms': 4,
    'criticize': 3,
    'criticized': 3,
    'criticizes': 4,
    'criticizing': 4,
    'critics': 2,
    'critique': 2,
    'critiqued': 2,
    'critiques': 2,
    'critiquing': 3,
    'criton': 2,
    'critser': 2,
    'crittenden': 3,
    'crittendon': 3,
    'critter': 2,
    'critters': 2,
    'critz': 1,
    'critzer': 2,
    'crivaro': 3,
    'crivelli': 3,
    'crivello': 3,
    'criven': 2,
    'crnkovich': 3,
    'cro': 1,
    'croak': 1,
    'croaker': 2,
    'croaking': 2,
    'croat': 2,
    'croatia': 3,
    'croatian': 3,
    'croatians': 3,
    'croats': 2,
    'croc': 1,
    'crocco': 2,
    'croce': 2,
    'crochet': 2,
    'crocheted': 2,
    'crochetiere': 3,
    'crocheting': 3,
    'crock': 1,
    'crocker': 2,
    'crockery': 3,
    'crockett': 2,
    'crockford': 2,
    'crocodile': 3,
    'crocodiles': 3,
    'crocodilian': 4,
    'crocus': 2,
    'crocuses': 3,
    'croff': 1,
    'crofford': 2,
    'crofoot': 2,
    'croft': 1,
    'crofton': 2,
    'crofts': 1,
    'crogan': 2,
    'croghan': 2,
    'croissant': 2,
    'croissants': 2,
    'croitzer': 2,
    'croix': 1,
    'croke': 1,
    'croker': 2,
    'croley': 2,
    'croll': 1,
    'crom': 1,
    'cromartie': 3,
    'crombie': 2,
    'cromer': 2,
    'cromie': 2,
    'cromley': 2,
    'crompton': 2,
    'cromwell': 2,
    'cron': 1,
    'cronan': 2,
    'cronauer': 2,
    'cronce': 1,
    'crone': 1,
    'cronen': 2,
    'croner': 2,
    'croney': 2,
    'cronies': 2,
    'cronin': 2,
    'cronk': 1,
    'cronkhite': 2,
    'cronkite': 2,
    'cronkright': 2,
    'cronquist': 2,
    'cronus': 2,
    'crony': 2,
    'cronyism': 4,
    'crook': 1,
    'crooke': 1,
    'crooked': 2,
    'crookedness': 3,
    'crooker': 2,
    'crooklyn': 2,
    'crooks': 1,
    'crookshank': 2,
    'crookshanks': 2,
    'crookston': 2,
    'croom': 1,
    'crooms': 1,
    'croon': 1,
    'crooner': 2,
    'crooning': 2,
    'croons': 1,
    'crop': 1,
    'cropland': 2,
    'cropley': 2,
    'cropp': 1,
    'cropped': 1,
    'cropper': 2,
    'cropping': 2,
    'crops': 1,
    'cropsey': 2,
    'croquet': 2,
    'cros': 1,
    'crosbey': 2,
    'crosbie': 2,
    'crosby': 2,
    'crose': 1,
    'croshaw': 2,
    'croskey': 2,
    'crosland': 2,
    'crosley': 2,
    'croslin': 2,
    'croson': 2,
    'cross': 1,
    'crossan': 2,
    'crossbill': 2,
    'crossbills': 2,
    'crossbones': 2,
    'crossborder': 3,
    'crossbow': 2,
    'crossbows': 2,
    'crosscurrent': 3,
    'crosscurrents': 3,
    'crosse': 1,
    'crossed': 1,
    'crossen': 2,
    'crosser': 2,
    'crosses': 2,
    'crossett': 2,
    'crossfield': 2,
    'crossfire': 2,
    'crossin': 2,
    'crossing': 2,
    'crossings': 2,
    'crossland': 2,
    'crossley': 2,
    'crosslin': 2,
    'crossman': 2,
    'crossnine': 2,
    'crossno': 2,
    'crosson': 2,
    'crossover': 3,
    'crosspiece': 2,
    'crosspieces': 3,
    'crossroad': 2,
    'crossroads': 2,
    'crosstalk': 2,
    'crosstown': 2,
    'crossville': 2,
    'crosswalk': 2,
    'crosswalks': 2,
    'crosswhite': 2,
    'crosswise': 2,
    'crossword': 2,
    'crosswords': 2,
    'crosthwait': 2,
    'crosthwaite': 2,
    'croston': 2,
    'croswell': 2,
    'crotch': 1,
    'crotchety': 3,
    'croteau': 2,
    'crothers': 2,
    'crotonville': 3,
    'crotteau': 2,
    'crotts': 1,
    'crotty': 2,
    'crotwell': 2,
    'crotzer': 2,
    'crouch': 1,
    'crouched': 1,
    'croucher': 2,
    'crouching': 2,
    'crough': 1,
    'crounse': 1,
    'croup': 1,
    'crouse': 1,
    'crouser': 2,
    'crout': 1,
    'crouthamel': 3,
    'crovitz': 2,
    'crovl': 2,
    'crovls': 2,
    'crow': 1,
    'crowbar': 2,
    'crowborough': 3,
    'crowd': 1,
    'crowded': 2,
    'crowden': 2,
    'crowder': 2,
    'crowding': 2,
    'crowds': 1,
    'crowe': 1,
    'crowed': 1,
    'crowell': 2,
    'crowing': 2,
    'crowkeeper': 3,
    'crowkeepers': 3,
    'crowl': 1,
    'crowle': 1,
    'crowley': 2,
    'crown': 1,
    'crowne': 1,
    'crowned': 1,
    'crowner': 2,
    'crowning': 2,
    'crownlike': 2,
    'crownover': 3,
    'crowns': 1,
    'crownx': 2,
    'crows': 1,
    'crowson': 2,
    'crowther': 2,
    'crowthers': 2,
    'croxton': 2,
    'croy': 1,
    'croyle': 1,
    'crozier': 2,
    'cruce': 1,
    'cruces': 2,
    'crucial': 2,
    'crucially': 3,
    'crucible': 3,
    'crucified': 3,
    'crucifix': 3,
    'crucifixes': 4,
    'crucifixion': 4,
    'crucify': 3,
    'crud': 1,
    'cruddy': 2,
    'crude': 1,
    'crudele': 2,
    'crudely': 2,
    'crudes': 1,
    'crudup': 2,
    'cruea': 3,
    'cruel': 2,
    'cruelest': 2,
    'cruelly': 2,
    'cruelties': 2,
    'cruelty': 2,
    'cruey': 2,
    'cruger': 2,
    'cruickshank': 2,
    'cruikshank': 3,
    'cruise': 1,
    'cruised': 1,
    'cruiser': 2,
    'cruisers': 2,
    'cruises': 2,
    'cruising': 2,
    'crull': 1,
    'crum': 1,
    'crumb': 1,
    'crumbaugh': 2,
    'crumble': 2,
    'crumbled': 2,
    'crumbles': 2,
    'crumbley': 2,
    'crumbling': 3,
    'crumbs': 1,
    'crumby': 2,
    'crume': 1,
    'crumitie': 3,
    'crumley': 2,
    'crumly': 2,
    'crumm': 1,
    'crummett': 2,
    'crummey': 2,
    'crummy': 2,
    'crump': 1,
    'crumpacker': 3,
    'crumple': 2,
    'crumpled': 2,
    'crumpler': 3,
    'crumpton': 2,
    'crumrine': 2,
    'crunch': 1,
    'crunched': 1,
    'cruncher': 2,
    'crunchers': 2,
    'crunches': 2,
    'crunching': 2,
    'crunchy': 2,
    'crunk': 1,
    'crunkleton': 3,
    'crupi': 2,
    'crusade': 2,
    'crusader': 3,
    'crusaders': 3,
    'crusades': 2,
    'crusading': 3,
    'crusan': 2,
    'cruse': 1,
    'cruser': 2,
    'crush': 1,
    'crushed': 1,
    'crusher': 2,
    'crushers': 2,
    'crushes': 2,
    'crushing': 2,
    'crusoe': 2,
    'crust': 1,
    'crustacean': 3,
    'crustaceans': 3,
    'crustaceous': 3,
    'crustal': 2,
    'crusted': 2,
    'crusts': 1,
    'crusty': 2,
    'crutch': 1,
    'crutcher': 2,
    'crutches': 2,
    'crutchfield': 2,
    'crutchley': 2,
    'crute': 1,
    'cruthirds': 2,
    'crux': 1,
    'cruz': 1,
    'cruzado': 3,
    'cruzados': 3,
    'cruzan': 2,
    'cruze': 1,
    'cruzen': 2,
    'cry': 1,
    'cryan': 2,
    'crybaby': 3,
    'cryder': 2,
    'cryderman': 3,
    'crye': 1,
    'cryer': 2,
    'crying': 2,
    'crymes': 1,
    'cryogenic': 4,
    'cryogenics': 4,
    'cryolite': 3,
    'crypt': 1,
    'cryptic': 2,
    'crypto': 2,
    'cryptoclearance': 4,
    'cryptosporidium': 6,
    'crypts': 1,
    'crysler': 3,
    'crystal': 2,
    'crystalline': 3,
    'crystallize': 3,
    'crystallized': 3,
    'crystallizes': 4,
    'crystallizing': 4,
    'crystallographer': 5,
    'crystallography': 5,
    'crystals': 2,
    'cryster': 2,
    'crytzer': 2,
    'csar': 1,
    'csaszar': 3,
    'cspan': 2,
    'cspi': 4,
    'ct': 1,
    'cuadra': 3,
    'cuadrado': 4,
    'cuajone': 3,
    'cuauhtemoc': 4,
    'cub': 1,
    'cuba': 2,
    'cuban': 2,
    'cubans': 2,
    'cubbage': 2,
    'cubbies': 2,
    'cubbison': 3,
    'cubbyhole': 3,
    'cube': 1,
    'cubed': 1,
    'cubes': 1,
    'cubic': 2,
    'cubicle': 3,
    'cubicles': 3,
    'cubism': 3,
    'cubist': 2,
    'cubit': 2,
    'cubs': 1,
    'cuccaro': 3,
    'cucchi': 2,
    'cucchiara': 4,
    'cucci': 2,
    'cuccia': 2,
    'cuccio': 3,
    'cuckoo': 2,
    'cuckoos': 2,
    'cuco': 2,
    'cucumber': 3,
    'cucumbers': 3,
    'cudahy': 3,
    'cudd': 1,
    'cuddeback': 2,
    'cuddihy': 3,
    'cuddle': 2,
    'cuddled': 2,
    'cuddling': 2,
    'cuddly': 2,
    'cuddy': 2,
    'cude': 1,
    'cudgel': 2,
    'cudgels': 2,
    'cudmore': 2,
    'cudney': 2,
    'cudworth': 2,
    'cue': 1,
    'cued': 1,
    'cuellar': 2,
    'cuello': 3,
    'cuenca': 2,
    'cuero': 2,
    'cuervo': 2,
    'cues': 1,
    'cuesta': 2,
    'cueto': 2,
    'cueva': 2,
    'cuevas': 2,
    'cuff': 1,
    'cuffe': 1,
    'cuffed': 1,
    'cuffee': 2,
    'cuffs': 1,
    'cugini': 3,
    'cuhney': 2,
    'cuidado': 3,
    'cuing': 2,
    'cuisinart': 3,
    'cuisinarts': 3,
    'cuisine': 2,
    'cuisines': 2,
    'cul': 1,
    'culberson': 3,
    'culbert': 2,
    'culbertson': 3,
    'culbreath': 2,
    'culbreth': 2,
    'culbro': 2,
    'culex': 2,
    'culhane': 2,
    'culinary': 4,
    'culinova': 4,
    'culkin': 2,
    'cull': 1,
    'cullan': 2,
    'culled': 1,
    'cullen': 2,
    'cullens': 2,
    'culler': 2,
    'cullers': 2,
    'cullerton': 3,
    'cullet': 2,
    'culleton': 3,
    'culley': 2,
    'cullifer': 3,
    'culligan': 3,
    'cullimore': 3,
    'cullin': 2,
    'cullinan': 3,
    'cullinane': 3,
    'cullinet': 3,
    'culling': 2,
    'cullins': 2,
    'cullipher': 3,
    'cullison': 3,
    'cullman': 2,
    'cullom': 2,
    'cullop': 2,
    'cullud': 2,
    'cullum': 2,
    'cully': 2,
    'culmer': 2,
    'culminate': 3,
    'culminated': 4,
    'culminates': 3,
    'culminating': 4,
    'culmination': 4,
    'culp': 1,
    'culpa': 2,
    'culpability': 5,
    'culpable': 3,
    'culpepper': 3,
    'culprit': 2,
    'culprits': 2,
    'cult': 1,
    'cultic': 2,
    'cultist': 2,
    'cultists': 2,
    'cultivate': 3,
    'cultivated': 4,
    'cultivates': 3,
    'cultivating': 4,
    'cultivation': 4,
    'culton': 2,
    'cults': 1,
    'cultural': 3,
    'culturalism': 5,
    'culturally': 4,
    'culture': 2,
    'cultured': 2,
    'cultures': 2,
    'culturing': 3,
    'culvahouse': 3,
    'culver': 2,
    'culverhouse': 3,
    'culvert': 2,
    'culwell': 2,
    'cum': 1,
    'cumbee': 2,
    'cumber': 2,
    'cumberbatch': 3,
    'cumberland': 3,
    'cumberledge': 3,
    'cumbersome': 3,
    'cumbia': 3,
    'cumbias': 3,
    'cumbie': 2,
    'cumbo': 2,
    'cumby': 2,
    'cumin': 2,
    'cumings': 2,
    'cumming': 2,
    'cummings': 2,
    'cummington': 3,
    'cummins': 2,
    'cummiskey': 3,
    'cumpston': 2,
    'cumpton': 2,
    'cumulative': 4,
    'cumulatively': 5,
    'cunanan': 3,
    'cunard': 2,
    'cundall': 2,
    'cundari': 3,
    'cundiff': 2,
    'cundill': 2,
    'cundy': 2,
    'cuneiform': 4,
    'cuneo': 3,
    'cunha': 2,
    'cunliffe': 2,
    'cunnane': 2,
    'cunneen': 2,
    'cunniff': 2,
    'cunnilingus': 4,
    'cunning': 2,
    'cunningham': 3,
    'cunnington': 3,
    'cunt': 1,
    'cuny': 2,
    'cuoco': 2,
    'cuomo': 2,
    'cuong': 1,
    'cuozzo': 2,
    'cup': 1,
    'cupboard': 2,
    'cupboards': 2,
    'cupcake': 2,
    'cupcakes': 2,
    'cuperose': 3,
    'cupertino': 4,
    'cupid': 2,
    'cupidity': 4,
    'cupids': 2,
    'cupit': 2,
    'cupo': 2,
    'cupola': 3,
    'cupp': 1,
    'cuppett': 2,
    'cupples': 2,
    'cupps': 1,
    'cuppy': 2,
    'cups': 1,
    'cur': 1,
    'curable': 3,
    'curacao': 3,
    'curate': 2,
    'curative': 3,
    'curatolo': 4,
    'curator': 3,
    'curatorial': 5,
    'curators': 3,
    'curb': 1,
    'curbed': 1,
    'curbelo': 3,
    'curbing': 2,
    'curbow': 2,
    'curbs': 1,
    'curbside': 2,
    'curbstone': 2,
    'curby': 2,
    'curci': 2,
    'curcio': 3,
    'curcuru': 3,
    'curd': 1,
    'cure': 1,
    'cured': 1,
    'cures': 1,
    'cureton': 2,
    'curfew': 2,
    'curfews': 2,
    'curfman': 2,
    'curiale': 4,
    'curie': 2,
    'curiel': 2,
    'curing': 2,
    'curington': 3,
    'curio': 3,
    'curios': 3,
    'curiosities': 5,
    'curiosity': 5,
    'curious': 3,
    'curiouser': 4,
    'curiously': 4,
    'curitiba': 4,
    'curl': 1,
    'curle': 2,
    'curled': 1,
    'curlee': 2,
    'curler': 2,
    'curlers': 2,
    'curless': 2,
    'curlett': 2,
    'curley': 2,
    'curlin': 2,
    'curling': 2,
    'curls': 1,
    'curly': 2,
    'curmudgeon': 3,
    'curmudgeons': 3,
    'curnow': 2,
    'curnutt': 2,
    'curnutte': 2,
    'curragh': 2,
    'curran': 2,
    'curren': 2,
    'currence': 2,
    'currencies': 3,
    'currency': 3,
    'currencywatch': 4,
    'currens': 2,
    'current': 2,
    'currently': 3,
    'currents': 2,
    'curreri': 3,
    'currey': 2,
    'curricula': 4,
    'curricular': 4,
    'curriculum': 4,
    'curriculums': 4,
    'currie': 2,
    'curried': 2,
    'currier': 3,
    'curries': 2,
    'currin': 2,
    'currington': 3,
    'curro': 2,
    'curry': 2,
    'currying': 3,
    'currys': 2,
    'curse': 1,
    'cursed': 1,
    'curses': 2,
    'cursing': 2,
    'cursor': 2,
    'cursory': 3,
    'curt': 1,
    'curtail': 2,
    'curtailed': 2,
    'curtailing': 3,
    'curtailment': 3,
    'curtailments': 3,
    'curtails': 2,
    'curtain': 2,
    'curtains': 2,
    'curti': 2,
    'curtice': 2,
    'curtin': 2,
    'curtis': 2,
    'curtiss': 2,
    'curtly': 2,
    'curtner': 2,
    'curto': 2,
    'curtright': 2,
    'curts': 1,
    'curtsinger': 3,
    'curtsy': 2,
    'curvature': 3,
    'curve': 1,
    'curved': 1,
    'curves': 1,
    'curvin': 2,
    'curving': 2,
    'curvy': 2,
    'curzio': 3,
    'cus': 1,
    'cusack': 2,
    'cusano': 3,
    'cush': 1,
    'cushey': 2,
    'cushing': 2,
    'cushion': 2,
    'cushioned': 2,
    'cushioning': 3,
    'cushions': 2,
    'cushitic': 3,
    'cushman': 2,
    'cushy': 2,
    'cusiana': 4,
    'cusic': 2,
    'cusick': 2,
    'cusimano': 4,
    'cusip': 2,
    'cusk': 1,
    'cusmano': 3,
    'cuso': 2,
    'cusp': 1,
    'cuss': 1,
    'cussed': 1,
    'cussing': 2,
    'cusson': 2,
    'custard': 2,
    'custer': 2,
    'custis': 2,
    'custodial': 4,
    'custodian': 4,
    'custodians': 4,
    'custodio': 4,
    'custody': 3,
    'custom': 2,
    'customarily': 5,
    'customary': 4,
    'customer': 3,
    'customers': 3,
    'customize': 3,
    'customized': 3,
    'customizes': 4,
    'customizing': 4,
    'customs': 2,
    'cusumano': 4,
    'cut': 1,
    'cutaia': 3,
    'cutaway': 3,
    'cutback': 2,
    'cutbacks': 2,
    'cutbirth': 2,
    'cutchall': 2,
    'cutcher': 2,
    'cutchin': 2,
    'cutchins': 2,
    'cute': 1,
    'cuteness': 2,
    'cuter': 2,
    'cutesiness': 3,
    'cutest': 2,
    'cutesy': 2,
    'cuthbert': 2,
    'cuthbertson': 3,
    'cuthrell': 2,
    'cuticle': 3,
    'cutillo': 3,
    'cutlass': 2,
    'cutler': 2,
    'cutlery': 3,
    'cutlip': 2,
    'cutoff': 2,
    'cutoffs': 2,
    'cutout': 2,
    'cutouts': 2,
    'cutrale': 2,
    'cutrell': 2,
    'cutrer': 2,
    'cutright': 2,
    'cutrona': 3,
    'cutrone': 3,
    'cuts': 1,
    'cutsforth': 2,
    'cutshall': 2,
    'cutshaw': 2,
    'cutsinger': 3,
    'cutter': 2,
    'cutters': 2,
    'cutthroat': 2,
    'cutting': 2,
    'cuttings': 2,
    'cuttino': 3,
    'cuttlefish': 3,
    'cutts': 1,
    'cutty': 2,
    'cutugno': 3,
    'cutworm': 2,
    'cutworms': 2,
    'cuvelier': 3,
    'cuyahoga': 4,
    'cuyler': 2,
    'cuzzort': 2,
    'cwiertnia': 3,
    'cwik': 1,
    'cwikla': 2,
    'cwiklinski': 3,
    'cwynar': 2,
    'cxc': 3,
    'cy': 1,
    'cyacq': 2,
    'cyan': 2,
    'cyanamid': 4,
    'cyanazine': 4,
    'cyanide': 3,
    'cyanuric': 4,
    'cyb': 1,
    'cyber': 2,
    'cybercash': 3,
    'cyberporn': 3,
    'cybersex': 3,
    'cyberspace': 3,
    'cybertrend': 3,
    'cybertrends': 3,
    'cybill': 2,
    'cyborg': 2,
    'cybulski': 3,
    'cycads': 2,
    'cycare': 2,
    'cyclades': 3,
    'cycle': 2,
    'cycled': 2,
    'cycles': 2,
    'cyclic': 2,
    'cyclical': 3,
    'cyclicality': 5,
    'cyclicals': 3,
    'cycling': 3,
    'cyclist': 3,
    'cyclists': 3,
    'cyclohexane': 4,
    'cyclone': 2,
    'cyclones': 2,
    'cyclopean': 4,
    'cyclops': 2,
    'cyclosporine': 4,
    'cyclostome': 3,
    'cyclostomes': 3,
    'cyclotron': 3,
    'cycolor': 3,
    'cyd': 1,
    'cydonia': 4,
    'cydrome': 2,
    'cyert': 2,
    'cygan': 2,
    'cygne': 2,
    'cygnus': 2,
    'cyhexatin': 4,
    'cylinder': 3,
    'cylinders': 3,
    'cylindrical': 4,
    'cymbal': 2,
    'cymbals': 2,
    'cymrot': 2,
    'cynara': 3,
    'cyndi': 2,
    'cynic': 2,
    'cynical': 3,
    'cynically': 4,
    'cynicism': 4,
    'cynics': 2,
    'cynric': 2,
    'cynth': 1,
    'cynthia': 3,
    'cynthie': 2,
    'cynwyd': 2,
    'cypert': 2,
    'cypher': 2,
    'cyphers': 2,
    'cyphert': 2,
    'cypress': 2,
    'cyprian': 3,
    'cypriot': 3,
    'cypriots': 3,
    'cypris': 2,
    'cyprus': 2,
    'cyr': 1,
    'cyran': 2,
    'cyrano': 3,
    'cyrena': 3,
    'cyrenius': 4,
    'cyril': 2,
    'cyrilla': 3,
    'cyrillic': 3,
    'cyrix': 2,
    'cyrofin': 3,
    'cyrus': 2,
    'cyst': 1,
    'cystic': 2,
    'cysts': 1,
    'cytherea': 4,
    'cytogen': 3,
    'cytology': 4,
    'cytomegalovirus': 7,
    'cytoplasm': 4,
    'cytoplasmic': 4,
    'cytotech': 3,
    'cytotechs': 3,
    'cywinski': 3,
    'czaja': 2,
    'czajka': 2,
    'czajkowski': 3,
    'czapla': 2,
    'czaplewski': 3,
    'czaplicki': 3,
    'czar': 1,
    'czarist': 2,
    'czarnecki': 3,
    'czarnik': 2,
    'czarny': 2,
    'czars': 1,
    'czartoryski': 4,
    'czech': 1,
    'czechoslovak': 4,
    'czechoslovakia': 6,
    'czechoslovakian': 6,
    'czechoslovaks': 4,
    'czechowski': 3,
    'czechs': 1,
    'czekajewski': 4,
    'czepiel': 2,
    'czerniak': 3,
    'czerny': 2,
    'czerwinski': 3,
    'czerwonka': 3,
    'czeslaw': 2,
    'czyz': 1,
    'czyzewski': 3,
    'd': 1,
    'da': 1,
    'daane': 1,
    'daarmstadt': 2,
    'daas': 1,
    'dab': 1,
    'dabah': 2,
    'dabbing': 2,
    'dabble': 2,
    'dabbled': 2,
    'dabbles': 2,
    'dabbling': 3,
    'dabbs': 1,
    'dabchick': 2,
    'dabhol': 2,
    'dabkowski': 3,
    'dabney': 2,
    'dabrowski': 3,
    'dac': 1,
    'dace': 1,
    'dacey': 2,
    'dacha': 2,
    'dachau': 2,
    'dachshund': 2,
    'dachshunds': 2,
    'dacia': 3,
    'dack': 1,
    'dacosta': 3,
    'dacquisto': 3,
    'dacron': 2,
    'dacruz': 2,
    'dacs': 1,
    'dactyl': 2,
    'dacunha': 3,
    'dacus': 2,
    'dacy': 2,
    'dad': 1,
    'dada': 2,
    'dadamo': 3,
    'daddario': 4,
    'daddies': 2,
    'daddona': 3,
    'daddy': 2,
    'dade': 1,
    'dadeland': 2,
    'dadfar': 2,
    'dadisman': 3,
    'dado': 2,
    'dads': 1,
    'dady': 2,
    'dae': 1,
    'daedalus': 3,
    'daelim': 2,
    'daemon': 2,
    'daenzer': 2,
    'daewoo': 2,
    'daffern': 2,
    'daffin': 2,
    'daffodil': 3,
    'daffodils': 3,
    'daffron': 2,
    'daffy': 2,
    'daffynition': 4,
    'dafna': 2,
    'dafoe': 2,
    'dafsa': 2,
    'daft': 1,
    'dag': 1,
    'dagan': 2,
    'dagata': 3,
    'dagen': 2,
    'dagenais': 3,
    'dagenham': 3,
    'dagenhart': 3,
    'dager': 2,
    'dagestan': 3,
    'dagg': 1,
    'dagger': 2,
    'daggers': 2,
    'daggett': 2,
    'daggs': 1,
    'daggy': 2,
    'dagle': 2,
    'dagley': 2,
    'dagmar': 2,
    'dagon': 2,
    'dagostino': 4,
    'dague': 1,
    'dagwood': 2,
    'dah': 1,
    'daher': 2,
    'dahill': 2,
    'dahl': 1,
    'dahlberg': 2,
    'dahle': 2,
    'dahlem': 2,
    'dahlen': 2,
    'dahler': 2,
    'dahlgren': 2,
    'dahlheimer': 3,
    'dahlia': 2,
    'dahlin': 2,
    'dahlke': 1,
    'dahlman': 2,
    'dahlquist': 2,
    'dahlstrom': 2,
    'dahm': 1,
    'dahman': 2,
    'dahmen': 2,
    'dahmer': 2,
    'dahms': 1,
    'dahn': 1,
    'dahnke': 1,
    'dahrain': 2,
    'dai': 1,
    'daido': 2,
    'daidone': 2,
    'daiei': 2,
    'daigle': 2,
    'daigler': 2,
    'daignault': 2,
    'daigneault': 2,
    'daigre': 2,
    'daigrepont': 3,
    'daihatsu': 3,
    'daiichi': 3,
    'daikin': 2,
    'dail': 1,
    'daile': 1,
    'dailey': 2,
    'dailies': 2,
    'daily': 2,
    'daimler': 2,
    'daimones': 2,
    'dain': 1,
    'daines': 1,
    'daini': 2,
    'dainippon': 3,
    'dains': 1,
    'dainty': 2,
    'daiquiri': 3,
    'dairies': 2,
    'dairy': 2,
    'dairying': 3,
    'dairymen': 3,
    'dais': 1,
    'daise': 1,
    'daisey': 2,
    'daishowa': 3,
    'daisies': 2,
    'daisy': 2,
    'daiwa': 2,
    'dajun': 2,
    'dak': 1,
    'dakar': 2,
    'dake': 1,
    'dakin': 2,
    'dakota': 3,
    'dakotan': 3,
    'dakotans': 3,
    'dakotas': 3,
    'dal': 1,
    'dalafield': 3,
    'dalai': 2,
    'dalal': 2,
    'dalbar': 2,
    'dalbec': 2,
    'dalbert': 2,
    'dalbey': 2,
    'dalby': 2,
    'dale': 1,
    'daleiden': 3,
    'dalen': 2,
    'daleo': 3,
    'dales': 1,
    'dalesandro': 4,
    'dalesio': 4,
    'dalessandro': 4,
    'dalessio': 4,
    'daleth': 2,
    'daley': 2,
    'dalfen': 2,
    'dalfonso': 3,
    'dalfort': 2,
    'dalgety': 3,
    'dalgleish': 3,
    'dalhouse': 2,
    'dali': 2,
    'dalia': 2,
    'dalian': 3,
    'daliberti': 4,
    'dalila': 3,
    'dalis': 2,
    'dalke': 1,
    'dalkon': 2,
    'dall': 1,
    'dalla': 2,
    'dallaire': 2,
    'dallara': 3,
    'dallas': 2,
    'dalley': 2,
    'dallhold': 2,
    'dalliance': 3,
    'dallied': 2,
    'dallman': 2,
    'dallmann': 2,
    'dally': 2,
    'dalma': 2,
    'dalman': 2,
    'dalmatian': 3,
    'dalmatians': 3,
    'dalmo': 2,
    'dalo': 2,
    'daloia': 3,
    'daloisio': 4,
    'dalonzo': 3,
    'dalpe': 1,
    'dalpiaz': 3,
    'dalporto': 3,
    'dalrymple': 3,
    'dalto': 2,
    'dalton': 2,
    'daltons': 2,
    'daly': 2,
    'dalzell': 2,
    'dalziel': 2,
    'dam': 1,
    'dama': 2,
    'damaclean': 3,
    'damage': 2,
    'damaged': 2,
    'damages': 3,
    'damaging': 3,
    'daman': 2,
    'damaris': 3,
    'damas': 2,
    'damascus': 3,
    'damask': 2,
    'damasks': 2,
    'damato': 3,
    'dambach': 2,
    'dambacher': 3,
    'dambra': 2,
    'dambrosia': 4,
    'dambrosio': 4,
    'dame': 1,
    'damelio': 4,
    'damer': 2,
    'damerlin': 3,
    'dameron': 3,
    'dames': 1,
    'damewood': 2,
    'damgard': 2,
    'damian': 3,
    'damiani': 4,
    'damiano': 4,
    'damianos': 4,
    'damico': 3,
    'damien': 3,
    'damietta': 4,
    'daminozide': 4,
    'damita': 3,
    'damitz': 2,
    'damm': 1,
    'dammam': 2,
    'damman': 2,
    'dammann': 2,
    'damme': 1,
    'dammed': 1,
    'dammen': 2,
    'dammer': 2,
    'dammerman': 3,
    'damming': 2,
    'dammit': 2,
    'damn': 1,
    'damnation': 3,
    'damned': 1,
    'damning': 2,
    'damns': 1,
    'damocles': 3,
    'damon': 2,
    'damone': 2,
    'damons': 2,
    'damoose': 2,
    'damore': 2,
    'damour': 2,
    'damp': 1,
    'damped': 1,
    'dampen': 2,
    'dampened': 2,
    'dampening': 3,
    'damper': 2,
    'dampers': 2,
    'dampier': 3,
    'damping': 2,
    'dampness': 2,
    'damps': 1,
    'damron': 2,
    'damrow': 2,
    'dams': 1,
    'damsel': 2,
    'damson': 2,
    'damuth': 2,
    'dan': 1,
    'dana': 2,
    'danaher': 3,
    'danahy': 3,
    'danbury': 3,
    'danby': 2,
    'danca': 2,
    'dancanet': 3,
    'dance': 1,
    'danced': 1,
    'dancer': 2,
    'dancers': 2,
    'dances': 2,
    'dancey': 2,
    'dancing': 2,
    'dancsak': 2,
    'dancy': 2,
    'dandelion': 4,
    'dandelions': 4,
    'dandeneau': 3,
    'dando': 2,
    'dandrea': 3,
    'dandridge': 2,
    'dandruff': 2,
    'dandurand': 3,
    'dandy': 2,
    'dandyism': 4,
    'dane': 1,
    'daneel': 2,
    'danek': 2,
    'danell': 2,
    'danella': 3,
    'danelle': 2,
    'daner': 2,
    'danes': 1,
    'danese': 3,
    'daney': 2,
    'danford': 2,
    'danforth': 2,
    'dang': 1,
    'dangel': 2,
    'dangelo': 3,
    'danger': 2,
    'dangerfield': 3,
    'dangerous': 3,
    'dangerously': 4,
    'dangerousness': 4,
    'dangers': 2,
    'dangews': 2,
    'dangle': 2,
    'dangled': 2,
    'dangler': 3,
    'dangles': 2,
    'dangling': 3,
    'dango': 2,
    'dani': 2,
    'dania': 2,
    'danica': 3,
    'daniel': 2,
    'daniela': 3,
    'daniele': 2,
    'daniell': 2,
    'daniella': 3,
    'danielle': 3,
    'daniello': 4,
    'daniels': 2,
    'danielsen': 3,
    'danielski': 3,
    'danielson': 3,
    'daniloff': 3,
    'danis': 2,
    'danish': 2,
    'danju': 2,
    'danjub': 2,
    'danjube': 2,
    'danjus': 2,
    'dank': 1,
    'danker': 2,
    'dankert': 2,
    'dankner': 2,
    'danko': 2,
    'danks': 1,
    'danley': 2,
    'danly': 2,
    'dann': 1,
    'danna': 2,
    'dannelly': 3,
    'dannels': 2,
    'dannemeyer': 3,
    'dannemiller': 4,
    'dannen': 2,
    'dannenberg': 3,
    'danner': 2,
    'danners': 2,
    'dannie': 2,
    'dannunzio': 4,
    'danny': 2,
    'dano': 2,
    'danos': 2,
    'danowski': 3,
    'dansby': 2,
    'danser': 2,
    'dansereau': 3,
    'dansforth': 2,
    'dansie': 2,
    'danske': 1,
    'danskin': 2,
    'dansky': 2,
    'danso': 2,
    'danson': 2,
    'danstett': 2,
    'dansville': 2,
    'dant': 1,
    'dante': 2,
    'dantin': 2,
    'danton': 2,
    'dantoni': 3,
    'dantonio': 4,
    'dantrell': 2,
    'dantuono': 3,
    'dantzler': 2,
    'danube': 2,
    'danvers': 2,
    'danville': 2,
    'danvy': 2,
    'danylyszyn': 4,
    'danz': 1,
    'danza': 2,
    'danzer': 2,
    'danzig': 2,
    'danziger': 3,
    'danzy': 2,
    'dao': 1,
    'daoud': 1,
    'daoust': 1,
    'daphne': 2,
    'daphnis': 2,
    'dapice': 2,
    'dapolito': 4,
    'daponte': 3,
    'dapozzo': 3,
    'dapp': 1,
    'dapper': 2,
    'dapuzzo': 3,
    'daquila': 3,
    'dar': 1,
    'dara': 2,
    'darak': 2,
    'darblay': 2,
    'darbonne': 2,
    'darby': 2,
    'darbyshire': 3,
    'darcangelo': 4,
    'darcey': 2,
    'darcie': 2,
    'darco': 2,
    'darcy': 2,
    'dardar': 2,
    'darden': 2,
    'dardis': 2,
    'dare': 1,
    'dared': 1,
    'daredevil': 3,
    'daredevils': 3,
    'daremblum': 3,
    'daren': 2,
    'darensbourg': 3,
    'dares': 1,
    'daresay': 2,
    'darga': 2,
    'dargan': 2,
    'dargis': 2,
    'daria': 3,
    'darice': 2,
    'darien': 3,
    'darienzo': 3,
    'darin': 2,
    'daring': 2,
    'dario': 3,
    'darity': 3,
    'darius': 3,
    'darjeeling': 3,
    'dark': 1,
    'darke': 1,
    'darken': 2,
    'darkened': 2,
    'darkening': 3,
    'darker': 2,
    'darkest': 2,
    'darkie': 2,
    'darkly': 2,
    'darkness': 2,
    'darkroom': 2,
    'darla': 2,
    'darland': 2,
    'darleen': 2,
    'darlene': 2,
    'darley': 2,
    'darlin': 2,
    'darline': 2,
    'darling': 2,
    'darlings': 2,
    'darlington': 3,
    'darman': 2,
    'darmon': 2,
    'darmstadt': 2,
    'darn': 1,
    'darnall': 2,
    'darned': 1,
    'darnedest': 2,
    'darnel': 2,
    'darnell': 2,
    'darner': 2,
    'darnold': 2,
    'darns': 1,
    'darocha': 3,
    'darosa': 3,
    'darpa': 2,
    'darpino': 3,
    'darr': 1,
    'darragh': 2,
    'darrah': 2,
    'darrel': 2,
    'darrell': 2,
    'darrelle': 2,
    'darren': 2,
    'darrick': 2,
    'darrigo': 3,
    'darrin': 2,
    'darrington': 3,
    'darroch': 2,
    'darrough': 2,
    'darrow': 2,
    'darryl': 2,
    'darsey': 2,
    'darst': 1,
    'darsy': 2,
    'dart': 1,
    'dartboard': 2,
    'darted': 2,
    'darter': 2,
    'dartez': 2,
    'darth': 1,
    'darting': 2,
    'dartmouth': 2,
    'darton': 2,
    'darts': 1,
    'dartt': 1,
    'darty': 2,
    'darville': 2,
    'darwin': 2,
    'darwinian': 4,
    'darwinism': 4,
    'darwish': 2,
    'daryl': 2,
    'das': 1,
    'dasa': 2,
    'dasaro': 3,
    'dasburg': 2,
    'dascenzo': 3,
    'dasch': 1,
    'daschle': 2,
    'dascoli': 3,
    'dase': 1,
    'daseke': 3,
    'dash': 1,
    'dashboard': 2,
    'dashboards': 2,
    'dashed': 1,
    'dashel': 2,
    'dashell': 2,
    'dasher': 2,
    'dashes': 2,
    'dashiell': 2,
    'dashiki': 3,
    'dashing': 2,
    'dashnaw': 2,
    'dashner': 2,
    'dashville': 2,
    'dashwood': 2,
    'dasilva': 3,
    'daspin': 2,
    'daspit': 2,
    'dass': 1,
    'dassault': 2,
    'dassler': 2,
    'dassow': 2,
    'dastardly': 3,
    'dat': 1,
    'data': 2,
    'database': 3,
    'databases': 4,
    'datacard': 3,
    'datacards': 3,
    'datacomm': 3,
    'datacomp': 3,
    'datacopy': 4,
    'datagraphix': 4,
    'datametrics': 4,
    'datapoint': 3,
    'datapoints': 3,
    'datapower': 3,
    'dataproducts': 4,
    'dataquest': 3,
    'dataram': 3,
    'datarex': 3,
    'datas': 2,
    'date': 1,
    'dated': 2,
    'datek': 2,
    'dateline': 2,
    'datelines': 2,
    'dates': 1,
    'datext': 2,
    'dating': 2,
    'dato': 2,
    'datron': 2,
    'dats': 1,
    'datsun': 2,
    'dattilio': 4,
    'dattilo': 3,
    'datuk': 2,
    'datum': 2,
    'datura': 3,
    'datz': 1,
    'dau': 1,
    'daub': 1,
    'daube': 1,
    'daubed': 1,
    'daubenspeck': 3,
    'dauber': 2,
    'daubert': 2,
    'dauch': 1,
    'daudelin': 3,
    'dauenhauer': 4,
    'dauer': 2,
    'daufuskie': 3,
    'daughdrill': 2,
    'daughenbaugh': 3,
    'daugherty': 3,
    'daughety': 3,
    'daughney': 2,
    'daughter': 2,
    'daughters': 2,
    'daughtery': 3,
    'daughton': 2,
    'daughtrey': 2,
    'daughtridge': 2,
    'daughtry': 2,
    'daul': 1,
    'dault': 1,
    'daulton': 2,
    'daum': 1,
    'daun': 1,
    'daunt': 1,
    'daunted': 2,
    'daunting': 2,
    'dauphin': 2,
    'dauphinais': 3,
    'dauphinee': 3,
    'dauria': 3,
    'daus': 1,
    'dauster': 2,
    'dauterive': 3,
    'dauzat': 2,
    'davalos': 3,
    'davanzo': 3,
    'davao': 2,
    'davault': 2,
    'davco': 2,
    'dave': 1,
    'davee': 2,
    'davenport': 3,
    'davern': 2,
    'daversa': 3,
    'daves': 1,
    'davey': 2,
    'davi': 2,
    'davia': 3,
    'david': 2,
    'davida': 3,
    'davide': 3,
    'davidge': 2,
    'davidian': 4,
    'davidians': 4,
    'davidoff': 3,
    'davidow': 3,
    'davids': 2,
    'davidson': 3,
    'davie': 2,
    'davies': 2,
    'davignon': 3,
    'davila': 3,
    'davilla': 3,
    'davin': 2,
    'davina': 3,
    'davino': 3,
    'davir': 2,
    'davis': 2,
    'davison': 3,
    'davisson': 3,
    'davitt': 2,
    'davlin': 2,
    'davoli': 3,
    'davos': 2,
    'davox': 2,
    'davy': 2,
    'davydov': 3,
    'daw': 1,
    'dawdle': 2,
    'dawdling': 2,
    'dawdy': 2,
    'dawe': 1,
    'dawes': 1,
    'dawit': 2,
    'dawkins': 2,
    'dawley': 2,
    'dawn': 1,
    'dawned': 1,
    'dawning': 2,
    'dawns': 1,
    'daws': 1,
    'dawsey': 2,
    'dawson': 2,
    'dax': 1,
    'daxor': 2,
    'day': 1,
    'daya': 2,
    'dayan': 2,
    'daybreak': 2,
    'daycare': 2,
    'daycares': 2,
    'dayco': 2,
    'daydream': 2,
    'daydreamed': 2,
    'daydreaming': 3,
    'daydreams': 2,
    'daye': 1,
    'dayhoff': 2,
    'dayhuff': 2,
    'dayle': 1,
    'dayley': 2,
    'daylight': 2,
    'daylights': 2,
    'daylong': 2,
    'daynard': 2,
    'dayne': 1,
    'days': 1,
    'daytime': 2,
    'daytimes': 2,
    'dayton': 2,
    'daytona': 3,
    'daytop': 2,
    'daywalt': 2,
    'daze': 1,
    'dazed': 1,
    'dazey': 2,
    'dazs': 1,
    'dazzle': 2,
    'dazzled': 2,
    'dazzling': 3,
    'dazzo': 2,
    'dbase': 2,
    'ddt': 3,
    'de': 1,
    'dea': 1,
    'deacon': 2,
    'deaconess': 3,
    'deacons': 2,
    'deactivate': 4,
    'deactivated': 5,
    'dead': 1,
    'deadbeat': 2,
    'deadbeats': 2,
    'deadbolt': 2,
    'deaden': 2,
    'deadening': 3,
    'deader': 2,
    'deadhead': 2,
    'deadheads': 2,
    'deadhorse': 2,
    'deadlier': 3,
    'deadliest': 3,
    'deadline': 2,
    'deadlines': 2,
    'deadliness': 3,
    'deadlock': 2,
    'deadlocked': 2,
    'deadlocks': 2,
    'deadly': 2,
    'deadpan': 2,
    'deadweight': 2,
    'deadwood': 2,
    'deadwyler': 3,
    'deady': 2,
    'deaf': 1,
    'deafen': 2,
    'deafening': 3,
    'deafness': 2,
    'deahl': 1,
    'deak': 1,
    'deakin': 2,
    'deakins': 2,
    'deal': 1,
    'dealba': 3,
    'deale': 1,
    'dealer': 2,
    'dealerline': 3,
    'dealers': 2,
    'dealership': 3,
    'dealerships': 3,
    'dealey': 2,
    'dealfish': 2,
    'dealing': 2,
    'dealings': 2,
    'dealmaker': 3,
    'dealmakers': 3,
    'dealmaking': 3,
    'dealmeida': 3,
    'deals': 1,
    'dealt': 1,
    'dealy': 2,
    'deam': 1,
    'deamer': 2,
    'dean': 1,
    'deana': 3,
    'deanda': 3,
    'deandrade': 3,
    'deandrea': 3,
    'deane': 1,
    'deaner': 2,
    'deangelis': 4,
    'deangelo': 3,
    'deanna': 3,
    'deans': 1,
    'dear': 1,
    'dearborn': 2,
    'dearden': 2,
    'deardorff': 2,
    'deardourff': 2,
    'dearer': 2,
    'dearest': 2,
    'dearing': 2,
    'dearinger': 3,
    'dearly': 2,
    'dearman': 2,
    'dearmas': 2,
    'dearment': 2,
    'dearmon': 2,
    'dearmond': 2,
    'dearth': 1,
    'deary': 2,
    'deas': 1,
    'dease': 1,
    'deason': 2,
    'deasy': 2,
    'deater': 2,
    'death': 1,
    'deathbed': 2,
    'deatherage': 3,
    'deathly': 2,
    'deaths': 1,
    'deathshot': 2,
    'deathwatch': 2,
    'deatley': 2,
    'deaton': 2,
    'deatrick': 2,
    'deats': 1,
    'deaver': 2,
    'deavers': 2,
    'deavila': 3,
    'deb': 1,
    'debacker': 3,
    'debacle': 3,
    'debacles': 3,
    'debakey': 3,
    'debarment': 3,
    'debarr': 2,
    'debarros': 3,
    'debartolo': 4,
    'debartolos': 4,
    'debase': 2,
    'debased': 2,
    'debasement': 3,
    'debasing': 3,
    'debatable': 4,
    'debate': 2,
    'debated': 3,
    'debater': 3,
    'debaters': 3,
    'debates': 2,
    'debating': 3,
    'debator': 3,
    'debauche': 2,
    'debauchery': 4,
    'debaun': 2,
    'debbie': 2,
    'debby': 2,
    'debeer': 2,
    'debeers': 2,
    'debelak': 3,
    'debell': 2,
    'debella': 3,
    'debellis': 3,
    'debello': 3,
    'debenedetto': 5,
    'debenedictis': 5,
    'debenture': 3,
    'debentures': 3,
    'debernardi': 4,
    'deberry': 3,
    'debes': 1,
    'debevoise': 3,
    'debi': 2,
    'debiase': 4,
    'debilitate': 4,
    'debilitated': 5,
    'debilitating': 5,
    'debility': 4,
    'debit': 2,
    'deblanc': 2,
    'deblasio': 4,
    'deblock': 2,
    'deblois': 2,
    'deblum': 2,
    'debnam': 2,
    'debo': 2,
    'deboard': 2,
    'deboe': 2,
    'deboer': 3,
    'deboers': 3,
    'debold': 2,
    'debolt': 2,
    'debona': 3,
    'debonair': 3,
    'debonis': 3,
    'debono': 3,
    'debor': 2,
    'debora': 2,
    'deborah': 3,
    'debord': 2,
    'deborde': 2,
    'debose': 2,
    'debow': 2,
    'deboy': 2,
    'debra': 2,
    'debraudwick': 3,
    'debrecen': 3,
    'debrief': 2,
    'debriefed': 2,
    'debriefing': 3,
    'debriefs': 2,
    'debris': 2,
    'debrosse': 2,
    'debruhl': 2,
    'debruin': 3,
    'debruler': 3,
    'debruyn': 2,
    'debruyne': 2,
    'debs': 1,
    'debt': 1,
    'debtholder': 3,
    'debtholders': 3,
    'debtor': 2,
    'debtors': 2,
    'debts': 1,
    'debug': 2,
    'debugging': 3,
    'debuhr': 2,
    'debunk': 2,
    'debunked': 2,
    'debunking': 3,
    'debunks': 2,
    'debus': 2,
    'debusk': 2,
    'debussy': 3,
    'debut': 2,
    'debutante': 3,
    'debutantes': 3,
    'debuted': 2,
    'debuting': 3,
    'debuts': 2,
    'dec': 1,
    'decade': 2,
    'decadence': 3,
    'decadent': 3,
    'decadents': 3,
    'decades': 2,
    'decaf': 2,
    'decaffeinate': 4,
    'decaffeinated': 5,
    'decaffeinating': 5,
    'decaffeination': 5,
    'decaire': 2,
    'decals': 2,
    'decamillo': 4,
    'decamp': 2,
    'decamped': 2,
    'decandia': 4,
    'decant': 2,
    'decanting': 3,
    'decapitate': 4,
    'decapitated': 5,
    'decapitation': 5,
    'decapitations': 5,
    'decaprio': 4,
    'decapua': 4,
    'decarava': 4,
    'decarli': 3,
    'decarlo': 3,
    'decaro': 3,
    'decarolis': 4,
    'decastro': 3,
    'decathlete': 3,
    'decathlon': 3,
    'decato': 3,
    'decatur': 3,
    'decay': 2,
    'decayed': 2,
    'decaying': 3,
    'decays': 2,
    'decca': 2,
    'decease': 2,
    'deceased': 2,
    'dececco': 3,
    'decedent': 3,
    'decedents': 3,
    'deceit': 2,
    'deceitful': 3,
    'deceits': 2,
    'deceive': 2,
    'deceived': 2,
    'deceiving': 3,
    'decelerate': 4,
    'decelerated': 5,
    'decelerating': 5,
    'deceleration': 5,
    'decelle': 2,
    'decelles': 2,
    'december': 3,
    'decency': 3,
    'decennial': 4,
    'decent': 2,
    'decently': 3,
    'decentralization': 6,
    'decentralize': 4,
    'decentralized': 4,
    'decentralizing': 5,
    'deception': 3,
    'deceptions': 3,
    'deceptive': 3,
    'deceptively': 4,
    'decertification': 6,
    'decertified': 4,
    'decertify': 4,
    'decesare': 4,
    'decesaris': 4,
    'dech': 1,
    'dechant': 2,
    'dechellis': 3,
    'dechene': 2,
    'decherd': 2,
    'dechert': 2,
    'dechine': 2,
    'dechristopher': 4,
    'decibel': 3,
    'decibels': 3,
    'decicco': 3,
    'decide': 2,
    'decided': 3,
    'decidedly': 4,
    'decides': 2,
    'deciding': 3,
    'deciduous': 4,
    'deciliter': 4,
    'decima': 3,
    'decimal': 3,
    'decimals': 3,
    'decimate': 3,
    'decimated': 4,
    'decimating': 4,
    'decimation': 4,
    'decipher': 3,
    'deciphered': 3,
    'deciphering': 4,
    'decision': 3,
    'decisionmaker': 5,
    'decisionmaking': 5,
    'decisions': 3,
    'decisive': 3,
    'decisively': 4,
    'decisiveness': 4,
    'deck': 1,
    'deckard': 2,
    'decked': 1,
    'decker': 2,
    'deckert': 2,
    'decking': 2,
    'deckman': 2,
    'decks': 1,
    'declaim': 2,
    'declaimed': 2,
    'declarant': 3,
    'declaration': 4,
    'declarations': 4,
    'declaratory': 5,
    'declare': 2,
    'declared': 2,
    'declares': 2,
    'declaring': 3,
    'declassified': 4,
    'declassify': 4,
    'declerck': 2,
    'declercq': 2,
    'declerk': 2,
    'declerque': 2,
    'decline': 2,
    'declined': 2,
    'decliner': 3,
    'decliners': 3,
    'declines': 2,
    'declining': 3,
    'declue': 2,
    'deco': 2,
    'decode': 2,
    'decoded': 3,
    'decoder': 3,
    'decoders': 3,
    'decodes': 2,
    'decoding': 3,
    'decola': 3,
    'decom': 2,
    'decommission': 4,
    'decommissioned': 4,
    'decommissioning': 5,
    'decompose': 3,
    'decomposed': 3,
    'decomposes': 4,
    'decomposing': 4,
    'decomposition': 5,
    'decompression': 4,
    'deconcini': 4,
    'decongestant': 4,
    'decongestants': 4,
    'deconstruct': 3,
    'deconstruction': 4,
    'decontaminate': 5,
    'decontaminated': 6,
    'decontamination': 6,
    'decontrol': 3,
    'decontrolled': 3,
    'decook': 2,
    'decor': 2,
    'decorate': 3,
    'decorated': 4,
    'decorating': 4,
    'decoration': 4,
    'decorations': 4,
    'decorative': 3,
    'decorator': 4,
    'decorators': 4,
    'decordova': 4,
    'decorous': 3,
    'decorte': 3,
    'decorum': 3,
    'decosta': 3,
    'decoste': 3,
    'decoster': 3,
    'decoteau': 3,
    'decou': 2,
    'decouple': 3,
    'decoupling': 3,
    'decourcy': 3,
    'decoursey': 3,
    'decoy': 2,
    'decoys': 2,
    'decrane': 2,
    'decrease': 2,
    'decreased': 2,
    'decreases': 3,
    'decreasing': 3,
    'decree': 2,
    'decreed': 2,
    'decrees': 2,
    'decrepit': 3,
    'decrepitly': 4,
    'decrescenzo': 4,
    'decried': 2,
    'decries': 2,
    'decriminalization': 7,
    'decriminalize': 5,
    'decriminalizing': 6,
    'decristofaro': 5,
    'decry': 2,
    'decrying': 3,
    'decter': 2,
    'decuir': 2,
    'decurtis': 3,
    'decworld': 2,
    'dede': 1,
    'dedeaux': 2,
    'dedecker': 3,
    'dederichs': 2,
    'dederick': 3,
    'dedeurwaerder': 4,
    'dedham': 2,
    'dedic': 2,
    'dedicate': 3,
    'dedicated': 4,
    'dedicates': 3,
    'dedicating': 4,
    'dedication': 4,
    'dedios': 3,
    'dedman': 2,
    'dedmon': 2,
    'dedo': 2,
    'dedominicis': 5,
    'dedrick': 2,
    'deduce': 2,
    'deduced': 2,
    'deduct': 2,
    'deducted': 3,
    'deductibility': 6,
    'deductible': 4,
    'deductibles': 4,
    'deducting': 3,
    'deduction': 3,
    'deductions': 3,
    'deductive': 3,
    'deductively': 4,
    'deducts': 2,
    'dee': 1,
    'deeb': 1,
    'deed': 1,
    'deeded': 2,
    'deedee': 2,
    'deedrick': 2,
    'deeds': 1,
    'deedy': 2,
    'deeg': 1,
    'deegan': 2,
    'deehan': 2,
    'deel': 1,
    'deeley': 2,
    'deely': 2,
    'deem': 1,
    'deemed': 1,
    'deemer': 2,
    'deemphasize': 4,
    'deemphasizing': 5,
    'deems': 1,
    'deen': 1,
    'deener': 2,
    'deeney': 2,
    'deep': 1,
    'deepak': 2,
    'deepen': 2,
    'deepened': 2,
    'deepening': 3,
    'deepens': 2,
    'deeper': 2,
    'deepest': 2,
    'deeply': 2,
    'deepwater': 3,
    'deer': 1,
    'deerborne': 2,
    'deere': 1,
    'deerfield': 2,
    'deering': 2,
    'deerman': 2,
    'deerskin': 2,
    'deery': 2,
    'dees': 1,
    'deese': 1,
    'deet': 1,
    'deeter': 2,
    'deets': 1,
    'deetz': 1,
    'defabio': 4,
    'deface': 2,
    'defaced': 2,
    'defacing': 3,
    'defalco': 3,
    'defamation': 4,
    'defamatory': 5,
    'defame': 2,
    'defamed': 2,
    'defarges': 3,
    'default': 2,
    'defaulted': 3,
    'defaulter': 3,
    'defaulters': 3,
    'defaulting': 3,
    'defaults': 2,
    'defazio': 4,
    'defeasance': 3,
    'defeat': 2,
    'defeated': 3,
    'defeating': 3,
    'defeatism': 4,
    'defeatist': 3,
    'defeats': 2,
    'defect': 2,
    'defected': 3,
    'defecting': 3,
    'defection': 3,
    'defections': 3,
    'defective': 3,
    'defector': 3,
    'defectors': 3,
    'defects': 2,
    'defee': 2,
    'defelice': 3,
    'defenbaugh': 3,
    'defence': 2,
    'defend': 2,
    'defendant': 3,
    'defendants': 3,
    'defended': 3,
    'defender': 3,
    'defenders': 3,
    'defending': 3,
    'defends': 2,
    'defense': 2,
    'defenseless': 3,
    'defenses': 3,
    'defensible': 4,
    'defensive': 3,
    'defensively': 4,
    'defensiveness': 4,
    'defeo': 3,
    'defer': 2,
    'deference': 3,
    'deferential': 4,
    'deferment': 3,
    'deferments': 3,
    'deferral': 3,
    'deferrals': 3,
    'deferred': 2,
    'deferring': 3,
    'defers': 2,
    'defex': 2,
    'deffenbaugh': 3,
    'deffeyes': 2,
    'defiance': 3,
    'defiant': 3,
    'defiantly': 4,
    'defibaugh': 3,
    'defibrillator': 5,
    'defibrillators': 5,
    'deficiencies': 4,
    'deficiency': 4,
    'deficient': 3,
    'deficit': 3,
    'deficits': 3,
    'defied': 2,
    'defies': 2,
    'defilippis': 4,
    'defilippo': 4,
    'defina': 3,
    'definable': 4,
    'define': 2,
    'defined': 2,
    'defines': 2,
    'defining': 3,
    'definite': 3,
    'definitely': 4,
    'definition': 4,
    'definitions': 4,
    'definitive': 4,
    'definitively': 5,
    'defino': 3,
    'defiore': 4,
    'deflate': 2,
    'deflated': 3,
    'deflating': 3,
    'deflation': 3,
    'deflationary': 5,
    'deflator': 3,
    'deflect': 2,
    'deflected': 3,
    'deflecting': 3,
    'deflects': 2,
    'defleur': 2,
    'defoe': 2,
    'defoliant': 4,
    'defoliants': 4,
    'defollet': 3,
    'defollets': 3,
    'defoor': 2,
    'deford': 2,
    'defore': 2,
    'deforest': 3,
    'deforestation': 5,
    'deforge': 2,
    'deform': 2,
    'deformation': 4,
    'deformed': 2,
    'deformities': 4,
    'deformity': 4,
    'deforrest': 3,
    'defrain': 2,
    'defrance': 2,
    'defrancesco': 4,
    'defrancisco': 4,
    'defranco': 3,
    'defrank': 2,
    'defrates': 3,
    'defraud': 2,
    'defrauded': 3,
    'defrauding': 3,
    'defray': 2,
    'defrays': 2,
    'defrees': 2,
    'defreese': 2,
    'defreitas': 3,
    'defries': 2,
    'defrost': 2,
    'defrosting': 3,
    'deft': 1,
    'defterios': 4,
    'deftly': 2,
    'defunct': 2,
    'defund': 2,
    'defunding': 3,
    'defusco': 3,
    'defuse': 2,
    'defused': 2,
    'defusing': 3,
    'defy': 2,
    'defying': 3,
    'degaetano': 5,
    'degan': 2,
    'degarmo': 3,
    'degas': 2,
    'degaulle': 2,
    'degen': 2,
    'degener': 3,
    'degeneracy': 5,
    'degenerate': 4,
    'degenerated': 5,
    'degenerates': 4,
    'degenerating': 5,
    'degeneration': 5,
    'degenerative': 5,
    'degeneres': 4,
    'degenhardt': 3,
    'degenhart': 3,
    'degennaro': 4,
    'degeorge': 3,
    'deger': 2,
    'degiacomo': 4,
    'degidio': 4,
    'degirolamo': 5,
    'degler': 2,
    'degnan': 2,
    'degner': 2,
    'degood': 2,
    'degraaf': 2,
    'degrace': 3,
    'degradable': 4,
    'degradation': 4,
    'degradations': 4,
    'degrade': 2,
    'degraded': 3,
    'degrades': 2,
    'degrading': 3,
    'degraff': 2,
    'degraffenreid': 4,
    'degrand': 2,
    'degrange': 2,
    'degrasse': 3,
    'degrave': 3,
    'degraw': 2,
    'degray': 2,
    'degrazia': 4,
    'degree': 2,
    'degreed': 2,
    'degrees': 2,
    'degregorio': 5,
    'degregory': 4,
    'degroat': 2,
    'degroff': 2,
    'degroot': 2,
    'degroote': 2,
    'deguerin': 3,
    'deguire': 3,
    'degussa': 3,
    'degutare': 4,
    'deguzman': 3,
    'dehaan': 2,
    'dehaas': 2,
    'deharbe': 3,
    'dehart': 2,
    'dehaven': 3,
    'dehecq': 2,
    'dehere': 3,
    'deherrera': 4,
    'dehler': 2,
    'dehm': 1,
    'dehmer': 2,
    'dehn': 1,
    'dehne': 1,
    'dehner': 2,
    'dehnert': 2,
    'dehoff': 2,
    'dehoyos': 3,
    'dehumanization': 6,
    'dehumanize': 4,
    'dehumanized': 4,
    'dehumanizing': 5,
    'dehumidified': 5,
    'dehumidifier': 6,
    'dehumidifies': 5,
    'dehumidify': 5,
    'dehydrate': 3,
    'dehydrated': 4,
    'dehydration': 4,
    'deibel': 2,
    'deibert': 2,
    'deibler': 3,
    'deichert': 2,
    'deidre': 2,
    'deification': 5,
    'deified': 3,
    'deify': 3,
    'deighan': 2,
    'deighton': 2,
    'deign': 1,
    'deignan': 2,
    'deigned': 1,
    'deigning': 2,
    'deigns': 1,
    'deihl': 1,
    'deike': 1,
    'deikel': 2,
    'deily': 2,
    'deines': 1,
    'deininger': 3,
    'deinstitutionalization': 9,
    'deinstitutionalize': 7,
    'deion': 2,
    'deirdre': 2,
    'deis': 1,
    'deisher': 3,
    'deism': 3,
    'deiss': 1,
    'deist': 2,
    'deitch': 1,
    'deiter': 2,
    'deiters': 2,
    'deities': 3,
    'deitrich': 2,
    'deitrick': 2,
    'deitsch': 1,
    'deity': 3,
    'deitz': 1,
    'deja': 2,
    'dejager': 3,
    'dejarnett': 3,
    'dejarnette': 3,
    'dejean': 2,
    'deject': 2,
    'dejected': 3,
    'dejesus': 3,
    'dejohn': 2,
    'dejong': 2,
    'dejonge': 2,
    'dejongh': 2,
    'dejoseph': 3,
    'dejoy': 2,
    'dejulio': 4,
    'dekalb': 2,
    'dekay': 2,
    'dekeyser': 3,
    'dekker': 2,
    'dekle': 2,
    'deklerk': 2,
    'dekom': 2,
    'dekoning': 3,
    'dekroon': 2,
    'dekuyper': 3,
    'del': 1,
    'dela': 2,
    'delacerda': 4,
    'delacroix': 3,
    'delacruz': 3,
    'delacy': 3,
    'delafield': 3,
    'delafuente': 4,
    'delagarza': 4,
    'delage': 2,
    'delagrange': 3,
    'delahanty': 4,
    'delahoussaye': 4,
    'delahunt': 3,
    'delahunty': 4,
    'delaine': 2,
    'delair': 2,
    'delamar': 3,
    'delamater': 4,
    'delancey': 3,
    'delancy': 3,
    'deland': 2,
    'delane': 2,
    'delaney': 3,
    'delange': 2,
    'delano': 3,
    'delanoy': 3,
    'delany': 3,
    'delao': 2,
    'delap': 2,
    'delapaz': 3,
    'delapena': 4,
    'delapp': 2,
    'delara': 3,
    'delariva': 4,
    'delarosa': 4,
    'delashmit': 3,
    'delashmutt': 3,
    'delaski': 3,
    'delatorre': 4,
    'delatte': 2,
    'delauder': 3,
    'delaughter': 3,
    'delaune': 2,
    'delaura': 3,
    'delaurel': 3,
    'delaurentiis': 4,
    'delaurentis': 4,
    'delauter': 3,
    'delaval': 3,
    'delavan': 3,
    'delavega': 4,
    'delaware': 3,
    'delawarian': 5,
    'delawarians': 5,
    'delawder': 3,
    'delay': 2,
    'delayed': 2,
    'delaying': 3,
    'delays': 2,
    'delbarco': 3,
    'delbene': 3,
    'delbert': 2,
    'delbianco': 4,
    'delbosque': 2,
    'delbridge': 2,
    'delbuono': 3,
    'delcambre': 3,
    'delcamp': 2,
    'delcampo': 3,
    'delcarlo': 3,
    'delcastillo': 4,
    'delchamps': 2,
    'delcine': 3,
    'delco': 2,
    'delconte': 3,
    'delcor': 2,
    'delduca': 3,
    'delebarre': 3,
    'delectable': 4,
    'delee': 2,
    'deleeuw': 3,
    'delegate': 3,
    'delegated': 4,
    'delegates': 3,
    'delegating': 4,
    'delegation': 4,
    'delegations': 4,
    'delehanty': 4,
    'delellis': 3,
    'deleo': 3,
    'deleon': 3,
    'deleonardis': 5,
    'delete': 2,
    'deleted': 3,
    'deleterious': 5,
    'deletes': 2,
    'deleting': 3,
    'deletion': 3,
    'deletions': 3,
    'delfavero': 4,
    'delfin': 2,
    'delfina': 3,
    'delfine': 3,
    'delfino': 3,
    'delfosse': 2,
    'delfs': 1,
    'delft': 1,
    'delftware': 2,
    'delgadillo': 4,
    'delgadio': 3,
    'delgado': 3,
    'delgaudio': 4,
    'delgiorno': 3,
    'delgiudice': 3,
    'delgrande': 3,
    'delgreco': 3,
    'delgrosso': 3,
    'delguercio': 4,
    'delguidice': 3,
    'delhaize': 2,
    'delhi': 2,
    'deli': 2,
    'delia': 2,
    'delial': 3,
    'deliberate': 4,
    'deliberated': 5,
    'deliberately': 5,
    'deliberates': 4,
    'deliberating': 5,
    'deliberation': 5,
    'deliberations': 5,
    'deliberative': 5,
    'delicacies': 4,
    'delicacy': 4,
    'delicate': 3,
    'delicately': 4,
    'delicatessen': 5,
    'delicatessens': 5,
    'delich': 2,
    'delicia': 3,
    'delicious': 3,
    'deliciously': 4,
    'delight': 2,
    'delighted': 3,
    'delightful': 3,
    'delightfully': 4,
    'delighting': 3,
    'delights': 2,
    'delila': 3,
    'delilah': 3,
    'delillo': 3,
    'delima': 3,
    'deline': 3,
    'delineate': 4,
    'delineated': 5,
    'delineates': 4,
    'delineating': 5,
    'delineation': 5,
    'delinquencies': 4,
    'delinquency': 4,
    'delinquent': 3,
    'delinquents': 3,
    'delio': 3,
    'delirious': 4,
    'delirium': 4,
    'delisa': 3,
    'delise': 2,
    'delisi': 3,
    'delisio': 4,
    'delisle': 2,
    'delist': 2,
    'delisted': 3,
    'delisting': 3,
    'deliver': 3,
    'deliverable': 5,
    'deliverance': 4,
    'delivered': 3,
    'deliverer': 4,
    'deliverers': 4,
    'deliveries': 4,
    'delivering': 4,
    'delivers': 3,
    'delivery': 4,
    'delk': 1,
    'delker': 2,
    'dell': 1,
    'della': 2,
    'dellaert': 2,
    'dellapenna': 4,
    'dellaquila': 4,
    'dellarocco': 4,
    'dellavalle': 4,
    'dellavecchia': 5,
    'delle': 1,
    'deller': 2,
    'delleri': 3,
    'delligatti': 4,
    'delling': 2,
    'dellinger': 3,
    'dellis': 2,
    'dellolio': 4,
    'dellums': 2,
    'delma': 2,
    'delman': 2,
    'delmar': 2,
    'delmarva': 3,
    'delmas': 2,
    'delmastro': 3,
    'delmed': 2,
    'delmer': 2,
    'delmonaco': 4,
    'delmonico': 4,
    'delmont': 2,
    'delmonte': 3,
    'delmore': 2,
    'delnegro': 3,
    'delnero': 3,
    'delo': 2,
    'deloach': 2,
    'deloatch': 2,
    'deloitte': 2,
    'deloney': 3,
    'delong': 2,
    'delora': 3,
    'delore': 2,
    'delorean': 4,
    'delorenzo': 4,
    'delores': 3,
    'delorey': 3,
    'deloria': 4,
    'deloris': 3,
    'delorme': 3,
    'delors': 2,
    'delosh': 2,
    'delosreyes': 4,
    'delossantos': 4,
    'delouis': 3,
    'delozier': 4,
    'delp': 1,
    'delph': 1,
    'delphax': 2,
    'delphi': 2,
    'delphia': 3,
    'delphian': 2,
    'delphic': 2,
    'delphina': 3,
    'delphine': 3,
    'delpino': 3,
    'delpizzo': 3,
    'delponte': 3,
    'delpozo': 3,
    'delprete': 2,
    'delpriore': 4,
    'delray': 2,
    'delre': 1,
    'delreal': 2,
    'delrina': 3,
    'delrio': 3,
    'delrosario': 5,
    'delrossi': 3,
    'delrosso': 3,
    'delsanto': 3,
    'delsignore': 4,
    'delta': 2,
    'deltacorp': 3,
    'deltadromeus': 4,
    'deltaic': 3,
    'deltak': 2,
    'deltas': 2,
    'deltec': 2,
    'deltona': 3,
    'deltoro': 3,
    'deluca': 3,
    'delucas': 3,
    'delucca': 3,
    'delucchi': 3,
    'deluccia': 3,
    'delucia': 3,
    'delude': 2,
    'deluded': 3,
    'deludes': 2,
    'deluding': 3,
    'deluge': 2,
    'deluged': 2,
    'deluise': 3,
    'deluna': 3,
    'delusion': 3,
    'delusional': 4,
    'delusions': 3,
    'deluxe': 2,
    'delvalle': 3,
    'delve': 1,
    'delvecchio': 4,
    'delved': 1,
    'delves': 1,
    'delvin': 2,
    'delving': 2,
    'delwin': 2,
    'delwip': 2,
    'delwyn': 2,
    'delzell': 2,
    'delzer': 2,
    'demaggio': 4,
    'demagogic': 4,
    'demagogue': 3,
    'demagoguery': 5,
    'demagogues': 3,
    'demagoguing': 4,
    'demagogy': 4,
    'demain': 2,
    'demaio': 4,
    'deman': 2,
    'demand': 2,
    'demanded': 3,
    'demanding': 3,
    'demandingly': 4,
    'demands': 2,
    'demar': 2,
    'demarais': 3,
    'demaray': 3,
    'demarcation': 4,
    'demarcations': 4,
    'demarche': 2,
    'demarchi': 3,
    'demarco': 3,
    'demarcus': 3,
    'demaree': 3,
    'demarest': 4,
    'demaria': 4,
    'demarinis': 4,
    'demarino': 4,
    'demario': 4,
    'demaris': 3,
    'demark': 2,
    'demars': 2,
    'demarsh': 2,
    'demartin': 3,
    'demartini': 4,
    'demartino': 4,
    'demary': 3,
    'demarzo': 3,
    'demas': 2,
    'demasi': 3,
    'demasters': 3,
    'dematteis': 3,
    'dematteo': 4,
    'demattia': 3,
    'demauro': 3,
    'demay': 2,
    'demayo': 3,
    'dembeck': 2,
    'dembinski': 3,
    'dembowski': 3,
    'dembski': 2,
    'demby': 2,
    'demchak': 2,
    'demean': 2,
    'demeaned': 2,
    'demeaning': 3,
    'demeanor': 3,
    'demeanors': 3,
    'demeans': 2,
    'demel': 2,
    'demello': 3,
    'demelo': 3,
    'dement': 2,
    'demented': 3,
    'dementia': 4,
    'demeo': 3,
    'demeree': 3,
    'demerger': 3,
    'demerist': 3,
    'demerit': 3,
    'demerits': 3,
    'demeritt': 3,
    'demers': 2,
    'demerse': 2,
    'demery': 3,
    'demeter': 3,
    'demetre': 3,
    'demetria': 4,
    'demetrio': 4,
    'demetriou': 4,
    'demetrius': 4,
    'demeyer': 3,
    'demi': 2,
    'demicco': 3,
    'demichael': 3,
    'demichele': 3,
    'demick': 2,
    'demilio': 4,
    'demilitarization': 7,
    'demilitarize': 5,
    'demilitarized': 5,
    'demilitarizes': 6,
    'demilitarizing': 6,
    'demille': 3,
    'demilo': 3,
    'deming': 2,
    'demint': 2,
    'demirag': 3,
    'demirel': 3,
    'demirjian': 4,
    'demisch': 2,
    'demise': 2,
    'demish': 2,
    'demjanjuk': 3,
    'demko': 2,
    'demler': 2,
    'demma': 2,
    'demme': 1,
    'demmer': 2,
    'demming': 2,
    'demmon': 2,
    'demmons': 2,
    'demo': 2,
    'demobilization': 6,
    'demobilize': 4,
    'demobilized': 4,
    'demobilizes': 5,
    'demobilizing': 5,
    'democracies': 4,
    'democracy': 4,
    'democrat': 3,
    'democratic': 4,
    'democratica': 5,
    'democratically': 5,
    'democratics': 4,
    'democratization': 6,
    'democratize': 4,
    'democratized': 4,
    'democratizes': 5,
    'democratizing': 5,
    'democrats': 3,
    'demodulate': 4,
    'demodulated': 5,
    'demodulates': 4,
    'demodulation': 5,
    'demographer': 4,
    'demographers': 4,
    'demographic': 4,
    'demographically': 5,
    'demographics': 4,
    'demography': 4,
    'demolish': 3,
    'demolished': 3,
    'demolishes': 4,
    'demolishing': 4,
    'demolition': 4,
    'demon': 2,
    'demond': 2,
    'demonic': 3,
    'demonization': 5,
    'demonize': 3,
    'demonized': 3,
    'demonizer': 4,
    'demonizez': 4,
    'demonizing': 4,
    'demons': 2,
    'demonstrable': 4,
    'demonstrably': 4,
    'demonstrate': 3,
    'demonstrated': 4,
    'demonstrates': 3,
    'demonstrating': 4,
    'demonstration': 4,
    'demonstrations': 4,
    'demonstrative': 4,
    'demonstrator': 4,
    'demonstrators': 4,
    'demont': 2,
    'demonte': 3,
    'demopoulos': 4,
    'demoralization': 6,
    'demoralize': 4,
    'demoralized': 4,
    'demoralizing': 5,
    'demore': 2,
    'demorest': 3,
    'demory': 3,
    'demos': 2,
    'demoss': 2,
    'demote': 2,
    'demoted': 3,
    'demotion': 3,
    'demotions': 3,
    'demott': 2,
    'demoulin': 3,
    'demov': 2,
    'dempewolf': 3,
    'demps': 1,
    'dempsey': 2,
    'dempster': 2,
    'demski': 2,
    'demsky': 2,
    'demur': 2,
    'demure': 2,
    'demurely': 3,
    'demuro': 3,
    'demurred': 2,
    'demurring': 3,
    'demurs': 2,
    'demus': 2,
    'demuth': 2,
    'demyan': 2,
    'demystify': 4,
    'den': 1,
    'dena': 2,
    'denapoli': 4,
    'denard': 2,
    'denardo': 3,
    'denarii': 3,
    'denarius': 4,
    'denaro': 3,
    'denatale': 4,
    'denationalization': 7,
    'denationalizations': 7,
    'denationalize': 5,
    'denationalized': 5,
    'denationalizing': 6,
    'denature': 3,
    'denatured': 3,
    'denault': 2,
    'denbo': 2,
    'denboer': 3,
    'denbow': 2,
    'denby': 2,
    'denden': 2,
    'dendinger': 3,
    'dendritic': 3,
    'dendrochronology': 6,
    'dendy': 2,
    'deneau': 2,
    'deneault': 2,
    'deneen': 2,
    'deneke': 2,
    'denenberg': 3,
    'denes': 1,
    'deneuve': 2,
    'deneve': 2,
    'deng': 1,
    'dengel': 2,
    'dengler': 3,
    'dengue': 1,
    'denham': 2,
    'denhart': 2,
    'denhartog': 3,
    'denherder': 3,
    'denholm': 2,
    'deniability': 6,
    'denial': 3,
    'denials': 3,
    'denice': 2,
    'denicola': 4,
    'denied': 2,
    'denies': 2,
    'denigrate': 3,
    'denigrated': 4,
    'denigrating': 4,
    'denigris': 3,
    'denike': 2,
    'denim': 2,
    'denio': 3,
    'deniro': 3,
    'denis': 2,
    'denise': 2,
    'denison': 3,
    'deniston': 3,
    'deniz': 2,
    'denizen': 3,
    'denizens': 3,
    'denk': 1,
    'denker': 2,
    'denki': 2,
    'denkins': 2,
    'denko': 2,
    'denktas': 2,
    'denlea': 2,
    'denley': 2,
    'denlinger': 4,
    'denman': 2,
    'denmark': 2,
    'denmon': 2,
    'denn': 1,
    'dennard': 2,
    'denne': 1,
    'dennehy': 3,
    'dennen': 2,
    'denner': 2,
    'dennett': 2,
    'denney': 2,
    'dennie': 2,
    'dennin': 2,
    'denning': 2,
    'denninger': 3,
    'dennington': 3,
    'dennis': 2,
    'dennison': 3,
    'denniston': 3,
    'denno': 2,
    'denny': 2,
    'deno': 2,
    'denoble': 3,
    'denominate': 4,
    'denominated': 5,
    'denomination': 5,
    'denominational': 6,
    'denominations': 5,
    'denominator': 5,
    'denomme': 2,
    'denosse': 2,
    'denote': 2,
    'denoted': 3,
    'denotes': 2,
    'denoting': 3,
    'denouement': 3,
    'denounce': 2,
    'denounced': 2,
    'denounces': 3,
    'denouncing': 3,
    'denoyer': 3,
    'dens': 1,
    'densch': 1,
    'dense': 1,
    'densely': 2,
    'denser': 2,
    'densest': 2,
    'denshin': 2,
    'densities': 3,
    'densitometer': 5,
    'density': 3,
    'densley': 2,
    'denslow': 2,
    'densmore': 2,
    'denson': 2,
    'dent': 1,
    'dental': 2,
    'dentals': 2,
    'dente': 1,
    'dented': 2,
    'dentin': 2,
    'dentine': 2,
    'denting': 2,
    'dentino': 3,
    'dentist': 2,
    'dentistry': 3,
    'dentists': 2,
    'dentition': 3,
    'dentler': 2,
    'denton': 2,
    'dentremont': 3,
    'dents': 1,
    'dentsu': 2,
    'dentton': 2,
    'denture': 2,
    'dentures': 2,
    'dentzer': 2,
    'denucci': 3,
    'denuclearization': 7,
    'denuclearized': 5,
    'denude': 2,
    'denuded': 3,
    'denuding': 3,
    'denunciation': 5,
    'denunciations': 5,
    'denunzio': 4,
    'denver': 2,
    'denwa': 2,
    'deny': 2,
    'denying': 3,
    'denys': 2,
    'denyse': 2,
    'denz': 1,
    'denzel': 2,
    'denzer': 2,
    'denzil': 2,
    'denzler': 2,
    'deo': 2,
    'deodorant': 4,
    'deodorants': 4,
    'deoliveira': 5,
    'deon': 2,
    'deontological': 6,
    'deontology': 5,
    'deorbit': 3,
    'deoxyribonucleic': 8,
    'dep': 1,
    'depace': 3,
    'depalma': 3,
    'depalo': 3,
    'depaola': 3,
    'depaoli': 3,
    'depaolis': 3,
    'depaolo': 3,
    'depardieu': 3,
    'depart': 2,
    'departed': 3,
    'departing': 3,
    'department': 3,
    'departmental': 4,
    'departmentalize': 5,
    'departmentalized': 5,
    'departmentalizes': 6,
    'departmentalizing': 6,
    'departments': 3,
    'departs': 2,
    'departure': 3,
    'departures': 3,
    'depascale': 4,
    'depasquale': 4,
    'depass': 2,
    'depaul': 2,
    'depaula': 3,
    'depaulo': 3,
    'depauw': 2,
    'depaz': 2,
    'depend': 2,
    'dependability': 6,
    'dependable': 4,
    'depended': 3,
    'dependence': 3,
    'dependencies': 4,
    'dependency': 4,
    'dependent': 3,
    'dependents': 3,
    'depending': 3,
    'depends': 2,
    'depersonalize': 5,
    'depetro': 3,
    'depew': 2,
    'dephillips': 3,
    'depict': 2,
    'depicted': 3,
    'depicting': 3,
    'depiction': 3,
    'depictions': 3,
    'depicts': 2,
    'depietro': 3,
    'depilatory': 5,
    'depina': 3,
    'depinto': 3,
    'deplete': 2,
    'depleted': 3,
    'depleter': 3,
    'depleters': 3,
    'depletes': 2,
    'depleting': 3,
    'depletion': 3,
    'deplorable': 4,
    'deplore': 2,
    'deplored': 2,
    'deplores': 2,
    'deploring': 3,
    'deploy': 2,
    'deployable': 4,
    'deployed': 2,
    'deploying': 3,
    'deployment': 3,
    'deployments': 3,
    'deploys': 2,
    'depner': 2,
    'depo': 2,
    'depolo': 3,
    'deponte': 3,
    'depopulate': 4,
    'depopulation': 5,
    'deport': 2,
    'deportation': 4,
    'deportations': 4,
    'deported': 3,
    'deportee': 3,
    'deportees': 3,
    'deporting': 3,
    'deportment': 3,
    'depose': 2,
    'deposed': 2,
    'deposi': 3,
    'deposit': 3,
    'depositary': 5,
    'deposited': 4,
    'depositing': 4,
    'deposition': 4,
    'depositional': 5,
    'depositions': 4,
    'depositor': 4,
    'depositors': 4,
    'depository': 5,
    'deposits': 3,
    'depot': 2,
    'depots': 2,
    'depoy': 2,
    'depp': 1,
    'deppe': 1,
    'deppen': 2,
    'depravation': 4,
    'deprave': 2,
    'depraved': 2,
    'depravity': 4,
    'deprecate': 3,
    'deprecated': 4,
    'deprecates': 3,
    'deprecating': 4,
    'depreciable': 4,
    'depreciate': 4,
    'depreciated': 5,
    'depreciates': 4,
    'depreciating': 5,
    'depreciation': 5,
    'depreciations': 5,
    'depredation': 4,
    'depredations': 4,
    'depree': 2,
    'deprenyl': 3,
    'depress': 2,
    'depressant': 3,
    'depressants': 3,
    'depressed': 2,
    'depresses': 3,
    'depressing': 3,
    'depressingly': 4,
    'depression': 3,
    'depressions': 3,
    'depressive': 3,
    'depressurize': 4,
    'depressurized': 4,
    'depressurizes': 5,
    'depressurizing': 5,
    'deprey': 2,
    'deprez': 2,
    'depriest': 4,
    'deprivation': 4,
    'deprivations': 4,
    'deprive': 2,
    'deprived': 2,
    'deprives': 2,
    'depriving': 3,
    'deprogram': 3,
    'deprogramming': 4,
    'depth': 1,
    'depths': 1,
    'deptula': 3,
    'depue': 2,
    'deputies': 3,
    'deputize': 3,
    'deputized': 3,
    'deputy': 3,
    'depuy': 2,
    'dequeker': 3,
    'der': 1,
    'derail': 2,
    'derailed': 2,
    'derailing': 3,
    'derailment': 3,
    'derailments': 3,
    'derails': 2,
    'deramo': 3,
    'deramus': 3,
    'derange': 2,
    'deranged': 2,
    'derasmo': 3,
    'derby': 2,
    'derbyshire': 3,
    'derchin': 2,
    'dercole': 3,
    'derden': 2,
    'derderian': 4,
    'derecktor': 3,
    'deregt': 2,
    'deregulate': 4,
    'deregulated': 5,
    'deregulating': 5,
    'deregulation': 5,
    'deregulator': 5,
    'deregulators': 5,
    'deregulatory': 6,
    'derek': 2,
    'derelict': 3,
    'dereliction': 4,
    'derelicts': 3,
    'deremer': 3,
    'deren': 2,
    'derenzo': 3,
    'derflinger': 4,
    'derham': 2,
    'derhammer': 3,
    'derick': 2,
    'derickson': 3,
    'derida': 3,
    'deridder': 3,
    'deride': 2,
    'derided': 3,
    'derides': 2,
    'deriding': 3,
    'derienzo': 3,
    'derik': 2,
    'dering': 2,
    'deringer': 3,
    'derington': 3,
    'derise': 2,
    'derision': 3,
    'derisive': 3,
    'derisively': 4,
    'deriso': 3,
    'derivation': 4,
    'derivative': 4,
    'derivatives': 4,
    'derive': 2,
    'derived': 2,
    'derives': 2,
    'deriving': 3,
    'derk': 1,
    'derks': 1,
    'derksen': 2,
    'derleth': 2,
    'derma': 2,
    'dermagraph': 3,
    'dermal': 2,
    'derman': 2,
    'dermatological': 6,
    'dermatologist': 5,
    'dermatologists': 5,
    'dermatology': 5,
    'dermer': 2,
    'dermis': 2,
    'dermody': 3,
    'dermot': 2,
    'dermott': 2,
    'dern': 1,
    'derner': 2,
    'derobertis': 4,
    'derocco': 3,
    'deroche': 2,
    'derocher': 3,
    'derogatory': 5,
    'deroo': 2,
    'deroos': 2,
    'derosa': 3,
    'derose': 2,
    'derosia': 4,
    'derosier': 4,
    'derossett': 3,
    'derouen': 2,
    'derouin': 2,
    'derousse': 2,
    'deroy': 2,
    'derr': 1,
    'derrick': 2,
    'derrickson': 3,
    'derrico': 3,
    'derrig': 2,
    'derring': 2,
    'derringer': 3,
    'derrington': 3,
    'derrow': 2,
    'derry': 2,
    'derryberry': 4,
    'dersch': 1,
    'dershem': 2,
    'dershowitz': 3,
    'derstine': 2,
    'derthick': 2,
    'derubeis': 3,
    'deruiter': 3,
    'deruko': 3,
    'derus': 2,
    'derusha': 3,
    'deruyter': 3,
    'dervin': 2,
    'dervish': 2,
    'dervishich': 3,
    'derward': 2,
    'derwin': 2,
    'derwinski': 3,
    'dery': 2,
    'deryck': 2,
    'deryle': 2,
    'des': 1,
    'desai': 3,
    'desalination': 5,
    'desalinization': 6,
    'desalvo': 3,
    'desanctis': 3,
    'desanti': 3,
    'desantiago': 5,
    'desantis': 3,
    'desanto': 3,
    'desantos': 3,
    'desaulniers': 4,
    'desautel': 3,
    'desautels': 3,
    'descarpentries': 4,
    'descartes': 2,
    'descend': 2,
    'descendant': 3,
    'descendants': 3,
    'descended': 3,
    'descendent': 3,
    'descendents': 3,
    'descending': 3,
    'descends': 2,
    'descent': 2,
    'descents': 2,
    'descenza': 3,
    'desch': 1,
    'deschaine': 2,
    'deschamps': 2,
    'deschene': 2,
    'deschenes': 2,
    'deschepper': 3,
    'deschler': 3,
    'deschner': 2,
    'descoteaux': 3,
    'describable': 4,
    'describe': 2,
    'described': 2,
    'describes': 2,
    'describing': 3,
    'description': 3,
    'descriptions': 3,
    'descriptive': 3,
    'desecrate': 3,
    'desecrated': 4,
    'desecrates': 3,
    'desecration': 4,
    'desecrations': 4,
    'desegregate': 4,
    'desegregated': 5,
    'desegregation': 5,
    'desena': 3,
    'desensitize': 4,
    'desensitized': 4,
    'desensitizing': 5,
    'deseret': 3,
    'desert': 2,
    'deserted': 3,
    'deserter': 3,
    'deserters': 3,
    'deserting': 3,
    'desertion': 3,
    'desertions': 3,
    'deserts': 2,
    'deserve': 2,
    'deserved': 2,
    'deservedly': 4,
    'deserves': 2,
    'deserving': 3,
    'desha': 2,
    'deshaies': 2,
    'deshane': 2,
    'deshaw': 2,
    'deshazer': 3,
    'deshazo': 3,
    'deshields': 2,
    'deshler': 2,
    'deshon': 2,
    'deshong': 2,
    'deshotel': 3,
    'deshotels': 3,
    'desi': 2,
    'desiccate': 3,
    'desiccated': 4,
    'desiccates': 3,
    'desiccation': 4,
    'desiderata': 5,
    'desiderio': 5,
    'design': 2,
    'designate': 3,
    'designated': 4,
    'designates': 3,
    'designating': 4,
    'designation': 4,
    'designations': 4,
    'designcraft': 3,
    'designed': 2,
    'designee': 3,
    'designees': 3,
    'designer': 3,
    'designers': 3,
    'designing': 3,
    'designor': 3,
    'designs': 2,
    'desilets': 3,
    'desillers': 3,
    'desilva': 3,
    'desimone': 4,
    'desio': 3,
    'desir': 2,
    'desirability': 6,
    'desirable': 4,
    'desire': 3,
    'desired': 3,
    'desiree': 3,
    'desires': 3,
    'desiring': 4,
    'desirous': 3,
    'desist': 2,
    'desisto': 3,
    'desjardin': 3,
    'desjardins': 3,
    'desjarlais': 3,
    'desk': 1,
    'deskin': 2,
    'deskins': 2,
    'deskjet': 2,
    'deskpro': 2,
    'desks': 1,
    'desktop': 2,
    'desktops': 2,
    'deslatte': 2,
    'deslauriers': 4,
    'desma': 2,
    'desman': 2,
    'desmarais': 3,
    'desmet': 2,
    'desmids': 2,
    'desmith': 2,
    'desmona': 3,
    'desmond': 2,
    'desnoyers': 3,
    'desolate': 3,
    'desolation': 4,
    'desormeaux': 3,
    'desoto': 3,
    'desousa': 3,
    'desouza': 3,
    'despain': 2,
    'despair': 2,
    'despaired': 2,
    'despairing': 3,
    'despairs': 2,
    'desper': 2,
    'desperado': 4,
    'desperadoes': 4,
    'desperate': 2,
    'desperately': 4,
    'desperation': 4,
    'despicable': 4,
    'despise': 2,
    'despised': 2,
    'despises': 3,
    'despising': 3,
    'despite': 2,
    'despondency': 4,
    'despondent': 3,
    'desposito': 4,
    'despot': 2,
    'despotic': 3,
    'despotism': 4,
    'despres': 2,
    'desrochers': 3,
    'desroches': 3,
    'desrosier': 4,
    'desrosiers': 4,
    'dessauer': 2,
    'desselle': 2,
    'dessent': 2,
    'dessert': 2,
    'desserts': 2,
    'dest': 1,
    'destabilization': 6,
    'destabilize': 4,
    'destabilized': 4,
    'destabilizing': 5,
    'destec': 2,
    'destefanis': 4,
    'destefano': 4,
    'destin': 2,
    'destination': 4,
    'destinations': 4,
    'destined': 2,
    'destinies': 3,
    'destiny': 3,
    'destitute': 3,
    'destitution': 4,
    'destroy': 2,
    'destroyed': 2,
    'destroyer': 3,
    'destroyers': 3,
    'destroying': 3,
    'destroys': 2,
    'destruct': 2,
    'destructable': 4,
    'destructed': 3,
    'destructing': 3,
    'destruction': 3,
    'destructive': 3,
    'destructiveness': 4,
    'destructs': 2,
    'desultory': 4,
    'detach': 2,
    'detachable': 4,
    'detached': 2,
    'detaches': 3,
    'detachment': 3,
    'detail': 2,
    'detailed': 2,
    'detailee': 3,
    'detailer': 3,
    'detailing': 3,
    'details': 2,
    'detain': 2,
    'detained': 2,
    'detainee': 3,
    'detainees': 3,
    'detaining': 3,
    'detamore': 3,
    'detar': 2,
    'detect': 2,
    'detectable': 4,
    'detected': 3,
    'detecting': 3,
    'detection': 3,
    'detective': 3,
    'detectives': 3,
    'detector': 3,
    'detectors': 3,
    'detects': 2,
    'detemple': 3,
    'detente': 2,
    'detention': 3,
    'detentions': 3,
    'deter': 2,
    'deterding': 3,
    'detergent': 3,
    'detergents': 3,
    'deteriorate': 5,
    'deteriorated': 6,
    'deteriorates': 5,
    'deteriorating': 6,
    'deterioration': 6,
    'determan': 3,
    'determent': 3,
    'determinable': 5,
    'determinant': 4,
    'determinants': 4,
    'determinate': 4,
    'determination': 5,
    'determinations': 5,
    'determinative': 5,
    'determine': 3,
    'determined': 3,
    'determinedly': 5,
    'determines': 3,
    'determining': 4,
    'determinism': 5,
    'determinist': 4,
    'deterministic': 5,
    'deterred': 2,
    'deterrence': 3,
    'deterrent': 3,
    'deterrents': 3,
    'deterring': 3,
    'deters': 2,
    'detert': 2,
    'detest': 2,
    'detested': 3,
    'detherage': 3,
    'dethlefs': 2,
    'dethlefsen': 3,
    'dethloff': 2,
    'dethomas': 3,
    'dethrone': 2,
    'dethroned': 2,
    'detienne': 3,
    'detjen': 2,
    'detlefsen': 3,
    'detloff': 2,
    'detmer': 2,
    'detonate': 3,
    'detonated': 4,
    'detonating': 4,
    'detonation': 4,
    'detonations': 4,
    'detonator': 4,
    'detonators': 4,
    'detore': 3,
    'detour': 2,
    'detoured': 2,
    'detours': 2,
    'detox': 2,
    'detoxication': 5,
    'detoxification': 6,
    'detoxify': 4,
    'detract': 2,
    'detracted': 3,
    'detracting': 3,
    'detractor': 3,
    'detractors': 3,
    'detracts': 2,
    'detrich': 2,
    'detrick': 2,
    'detriment': 3,
    'detrimental': 4,
    'detritus': 3,
    'detro': 2,
    'detroit': 2,
    'detroiters': 3,
    'detter': 2,
    'dettinger': 3,
    'dettling': 2,
    'dettloff': 2,
    'dettman': 2,
    'dettmann': 2,
    'dettmer': 2,
    'dettore': 3,
    'detty': 2,
    'deturk': 2,
    'detweiler': 3,
    'detwiler': 3,
    'detzel': 2,
    'deubel': 2,
    'deubler': 3,
    'deuce': 1,
    'deuel': 2,
    'deukmejian': 4,
    'deupree': 2,
    'deuschle': 2,
    'deuser': 2,
    'deuss': 1,
    'deutch': 1,
    'deuterium': 4,
    'deuterons': 3,
    'deutsch': 1,
    'deutsche': 1,
    'deutschemark': 2,
    'deutschemarks': 2,
    'deutscher': 2,
    'deutschland': 2,
    'deutschman': 2,
    'deutz': 1,
    'deux': 1,
    'dev': 1,
    'deva': 2,
    'deval': 2,
    'devall': 2,
    'devalle': 2,
    'devaluate': 4,
    'devaluated': 5,
    'devaluation': 5,
    'devaluations': 5,
    'devalue': 3,
    'devalued': 3,
    'devaluing': 4,
    'devan': 2,
    'devane': 2,
    'devaney': 3,
    'devany': 3,
    'devastate': 3,
    'devastated': 4,
    'devastating': 4,
    'devastatingly': 5,
    'devastation': 4,
    'devaughn': 2,
    'devaul': 2,
    'devault': 2,
    'devaux': 2,
    'deveau': 2,
    'deveaux': 2,
    'develcon': 3,
    'develle': 2,
    'develop': 3,
    'developable': 5,
    'developed': 3,
    'developer': 4,
    'developers': 4,
    'developing': 4,
    'development': 4,
    'developmental': 5,
    'developmentally': 6,
    'developments': 4,
    'develops': 3,
    'devendorf': 3,
    'deveney': 3,
    'devenney': 3,
    'devenny': 3,
    'devenport': 3,
    'devens': 2,
    'deveny': 3,
    'dever': 2,
    'devera': 3,
    'deveraux': 3,
    'devere': 3,
    'devereaux': 3,
    'deverell': 3,
    'devereux': 3,
    'deveroy': 3,
    'devers': 2,
    'devey': 2,
    'deviance': 3,
    'deviancy': 4,
    'deviant': 3,
    'deviants': 3,
    'deviate': 3,
    'deviated': 4,
    'deviates': 3,
    'deviating': 4,
    'deviation': 4,
    'deviations': 4,
    'device': 2,
    'devices': 3,
    'devil': 2,
    'devilbiss': 3,
    'devilish': 2,
    'devilishly': 4,
    'deville': 2,
    'devillier': 3,
    'devils': 2,
    'devilwood': 3,
    'devin': 2,
    'devincent': 3,
    'devincentis': 4,
    'devincenzi': 4,
    'devincenzo': 4,
    'devine': 2,
    'deviney': 3,
    'devinney': 3,
    'devino': 3,
    'devins': 2,
    'devious': 3,
    'devise': 2,
    'devised': 2,
    'devises': 3,
    'devising': 3,
    'devita': 3,
    'devito': 3,
    'devitt': 2,
    'devivo': 3,
    'devlin': 2,
    'devoe': 2,
    'devoid': 2,
    'devol': 2,
    'devolder': 3,
    'devoll': 2,
    'devolution': 4,
    'devolve': 2,
    'devolved': 2,
    'devon': 2,
    'devona': 3,
    'devonian': 4,
    'devonshire': 3,
    'devor': 2,
    'devore': 2,
    'devos': 2,
    'devoss': 2,
    'devote': 2,
    'devoted': 3,
    'devotee': 3,
    'devotees': 3,
    'devotes': 2,
    'devoting': 3,
    'devotion': 3,
    'devotional': 4,
    'devoto': 3,
    'devour': 3,
    'devoured': 3,
    'devouring': 4,
    'devours': 3,
    'devout': 2,
    'devoutly': 3,
    'devoy': 2,
    'devries': 2,
    'devry': 2,
    'dew': 1,
    'dewaard': 2,
    'dewaele': 2,
    'dewald': 2,
    'dewall': 2,
    'dewalt': 2,
    'dewan': 2,
    'dewar': 2,
    'deware': 2,
    'dewarr': 2,
    'dewart': 2,
    'dewayne': 2,
    'dewberry': 3,
    'dewbre': 2,
    'dewclaw': 2,
    'deweerd': 2,
    'dewees': 2,
    'deweese': 2,
    'deweiss': 2,
    'dewell': 2,
    'dewey': 2,
    'dewhirst': 2,
    'dewhurst': 2,
    'dewilde': 2,
    'dewine': 2,
    'dewing': 2,
    'dewinter': 3,
    'dewire': 2,
    'dewit': 2,
    'dewitt': 2,
    'dewitte': 2,
    'dewitz': 2,
    'dewolf': 2,
    'dewolfe': 2,
    'dewoody': 3,
    'dews': 1,
    'dewulf': 2,
    'dewy': 2,
    'dex': 1,
    'dexfenfluramine': 5,
    'dexheimer': 3,
    'dexter': 2,
    'dexterity': 4,
    'dextra': 2,
    'dextran': 2,
    'dextrathoraphan': 5,
    'dextro': 2,
    'dextrose': 2,
    'dextrous': 2,
    'dey': 1,
    'deyo': 2,
    'deyoe': 2,
    'deyoung': 2,
    'deyton': 2,
    'dezarn': 2,
    'dezeeuw': 3,
    'dezern': 2,
    'deziel': 2,
    'dfw': 5,
    'dhabi': 2,
    'dhaharan': 2,
    'dhahran': 2,
    'dhaka': 2,
    'dhali': 2,
    'dhananjay': 3,
    'dharma': 2,
    'dhein': 1,
    'dhia': 2,
    'dhillon': 2,
    'dhiraj': 2,
    'dhlakama': 4,
    'dhole': 1,
    'dhondt': 1,
    'dhows': 1,
    'di': 1,
    'dia': 2,
    'diab': 2,
    'diabase': 3,
    'diabasic': 4,
    'diabetes': 4,
    'diabetic': 4,
    'diabetics': 4,
    'diablo': 3,
    'diabolical': 5,
    'diaconate': 4,
    'diacritic': 4,
    'diacritical': 5,
    'diadem': 3,
    'diagnose': 3,
    'diagnosed': 3,
    'diagnoses': 4,
    'diagnosing': 4,
    'diagnosis': 4,
    'diagnostek': 4,
    'diagnostic': 4,
    'diagnostics': 4,
    'diagonal': 4,
    'diagonally': 5,
    'diagonals': 4,
    'diagram': 3,
    'diagraming': 4,
    'diagrammatic': 5,
    'diagrammed': 3,
    'diagrams': 3,
    'diahann': 2,
    'dial': 2,
    'dialect': 3,
    'dialectic': 4,
    'dialectical': 5,
    'dialects': 3,
    'dialed': 2,
    'dialing': 3,
    'dialog': 3,
    'dialogue': 3,
    'dialogues': 3,
    'dials': 2,
    'dialtone': 3,
    'dialysis': 4,
    'diamagnetic': 5,
    'diamagnetism': 6,
    'diamandis': 4,
    'diamant': 3,
    'diamanta': 4,
    'diamante': 4,
    'diameter': 4,
    'diametrically': 6,
    'diamond': 2,
    'diamonds': 2,
    'diamono': 4,
    'dian': 2,
    'diana': 3,
    'diane': 2,
    'dianetics': 4,
    'diangelo': 4,
    'dianna': 3,
    'dianne': 2,
    'diantha': 3,
    'dianthe': 3,
    'dianthia': 4,
    'diantonio': 5,
    'diaper': 2,
    'diapering': 3,
    'diapers': 3,
    'diaphanous': 4,
    'diaphonia': 5,
    'diaphragm': 3,
    'diapsid': 3,
    'diaries': 3,
    'diario': 4,
    'diarrhea': 4,
    'diarrheas': 4,
    'diarrhoea': 4,
    'diary': 3,
    'dias': 2,
    'diasa': 3,
    'diasonic': 4,
    'diasonics': 4,
    'diaspora': 4,
    'diastase': 3,
    'diastole': 4,
    'diastolic': 4,
    'diastrophism': 5,
    'diathermy': 4,
    'diatomic': 4,
    'diatoms': 3,
    'diatonic': 4,
    'diatribe': 3,
    'diatribes': 3,
    'diaz': 2,
    'diazo': 3,
    'dibacco': 3,
    'dibari': 3,
    'dibartolo': 4,
    'dibartolomeo': 6,
    'dibattista': 4,
    'dibb': 1,
    'dibbern': 2,
    'dibble': 2,
    'dibbled': 2,
    'dibella': 3,
    'dibello': 3,
    'dibenedetto': 5,
    'dibernardo': 4,
    'dibert': 2,
    'dibiase': 4,
    'dibiasio': 5,
    'diblasi': 3,
    'diblasio': 4,
    'dible': 2,
    'diboll': 2,
    'dibona': 3,
    'dibrell': 2,
    'dibs': 1,
    'dibuono': 3,
    'dic': 1,
    'dicamba': 3,
    'dicamillo': 4,
    'dicaprio': 4,
    'dicarlo': 3,
    'dice': 1,
    'dicecco': 3,
    'diced': 1,
    'dicello': 3,
    'dicenso': 3,
    'dicenzo': 3,
    'diceon': 3,
    'dicesare': 4,
    'dicey': 2,
    'dichiara': 4,
    'dichotomy': 4,
    'dichroic': 3,
    'dichromate': 3,
    'dichter': 2,
    'dicicco': 3,
    'dicier': 3,
    'dicing': 2,
    'dicioccio': 4,
    'dick': 1,
    'dickard': 2,
    'dickason': 3,
    'dicke': 1,
    'dickel': 2,
    'dicken': 2,
    'dickens': 2,
    'dickensheets': 3,
    'dickensian': 4,
    'dickenson': 3,
    'dicker': 2,
    'dickered': 2,
    'dickering': 3,
    'dickerman': 3,
    'dickerson': 3,
    'dickert': 2,
    'dickes': 1,
    'dickeson': 3,
    'dickey': 2,
    'dickhaut': 2,
    'dickie': 2,
    'dickinson': 3,
    'dickison': 3,
    'dickler': 2,
    'dickman': 2,
    'dickmann': 2,
    'dickmeyer': 3,
    'dicks': 1,
    'dickson': 2,
    'dickstein': 2,
    'dicky': 2,
    'diclemente': 4,
    'dicocco': 3,
    'dicola': 3,
    'dicomed': 3,
    'dicostanzo': 4,
    'dicots': 2,
    'dicta': 2,
    'dictaphone': 3,
    'dictate': 2,
    'dictated': 3,
    'dictates': 2,
    'dictating': 3,
    'dictation': 3,
    'dictator': 3,
    'dictatorial': 5,
    'dictators': 3,
    'dictatorship': 4,
    'dictatorships': 4,
    'diction': 2,
    'dictionaries': 4,
    'dictionary': 4,
    'dictum': 2,
    'dicus': 2,
    'did': 1,
    'didactic': 3,
    'diddley': 2,
    'diddy': 2,
    'didemeyer': 4,
    'dideoxycytidine': 7,
    'didi': 2,
    'didier': 3,
    'didinium': 4,
    'didion': 3,
    'dido': 2,
    'didomenico': 5,
    'didonato': 4,
    'didonna': 3,
    'die': 1,
    'diebel': 2,
    'diebold': 2,
    'dieck': 1,
    'dieckman': 2,
    'dieckmann': 2,
    'died': 1,
    'diede': 1,
    'diederich': 3,
    'diedre': 2,
    'diedrich': 2,
    'diedrick': 2,
    'diefenbach': 3,
    'diefenderfer': 4,
    'diefendorf': 3,
    'dieffenbach': 3,
    'diegans': 2,
    'diegel': 2,
    'diego': 3,
    'dieguez': 2,
    'diehard': 2,
    'diehards': 2,
    'diehl': 1,
    'diehm': 1,
    'diekman': 2,
    'diekmann': 2,
    'diel': 1,
    'diem': 1,
    'diemer': 2,
    'diemert': 2,
    'diener': 2,
    'dienes': 2,
    'dienst': 1,
    'diep': 1,
    'dier': 2,
    'diercks': 1,
    'dieringer': 3,
    'dierker': 2,
    'dierkes': 1,
    'dierking': 3,
    'dierks': 1,
    'dierolf': 2,
    'diers': 2,
    'diery': 2,
    'dies': 1,
    'diesel': 2,
    'diesels': 2,
    'dieses': 2,
    'diesing': 2,
    'diet': 2,
    'dietary': 4,
    'dietel': 2,
    'dieter': 2,
    'dieterich': 3,
    'dieterle': 3,
    'dieters': 3,
    'dietetic': 4,
    'dietician': 4,
    'dieting': 3,
    'dietitian': 4,
    'dietitians': 4,
    'dietl': 2,
    'dietrich': 2,
    'dietrick': 2,
    'diets': 2,
    'dietsch': 1,
    'dietsche': 1,
    'dietz': 1,
    'dietze': 2,
    'dietzel': 2,
    'dietzen': 2,
    'dietzler': 2,
    'dietzman': 2,
    'dievler': 2,
    'diez': 2,
    'dif': 1,
    'difabio': 4,
    'difalco': 3,
    'difazio': 4,
    'difelice': 3,
    'diff': 1,
    'diffee': 2,
    'diffenderfer': 4,
    'differ': 2,
    'differed': 2,
    'difference': 3,
    'differences': 4,
    'different': 3,
    'differential': 4,
    'differentials': 4,
    'differentiate': 5,
    'differentiated': 6,
    'differentiates': 5,
    'differentiating': 6,
    'differentiation': 6,
    'differently': 3,
    'differing': 3,
    'differs': 2,
    'difficult': 3,
    'difficulties': 4,
    'difficultly': 4,
    'difficulty': 4,
    'diffid': 2,
    'diffin': 2,
    'diffley': 2,
    'diffract': 2,
    'diffraction': 3,
    'diffuse': 2,
    'diffused': 2,
    'diffuses': 3,
    'diffusing': 3,
    'diffusion': 3,
    'difilippo': 4,
    'difiore': 4,
    'difm': 1,
    'difonzo': 3,
    'difrancesco': 4,
    'difranco': 3,
    'dig': 1,
    'digaetano': 4,
    'digalakis': 4,
    'digangi': 3,
    'digate': 2,
    'digby': 2,
    'digennaro': 4,
    'digenova': 4,
    'digeronimo': 5,
    'digest': 2,
    'digested': 3,
    'digester': 3,
    'digestible': 4,
    'digesting': 3,
    'digestion': 3,
    'digestive': 3,
    'digests': 2,
    'digga': 2,
    'digger': 2,
    'diggers': 2,
    'digges': 1,
    'digging': 2,
    'diggins': 2,
    'diggle': 2,
    'diggs': 1,
    'digiacomo': 4,
    'digicon': 3,
    'digidyne': 3,
    'digilio': 4,
    'digioia': 3,
    'digiorgio': 4,
    'digiovanna': 4,
    'digiovanni': 4,
    'digirolamo': 5,
    'digit': 2,
    'digital': 3,
    'digitalis': 4,
    'digitally': 4,
    'digitech': 3,
    'digitize': 3,
    'digitized': 3,
    'digitizing': 4,
    'digits': 2,
    'digiulio': 4,
    'digiuseppe': 4,
    'digman': 2,
    'dignan': 2,
    'dignified': 3,
    'dignify': 3,
    'dignitaries': 4,
    'dignitary': 4,
    'dignity': 3,
    'digrazia': 4,
    'digregorio': 5,
    'digress': 2,
    'digressed': 2,
    'digresses': 3,
    'digressing': 3,
    'digression': 3,
    'digressions': 3,
    'digs': 1,
    'diguglielmo': 5,
    'diianni': 3,
    'diiorio': 4,
    'dijker': 2,
    'dijon': 2,
    'dike': 1,
    'dikeman': 2,
    'dikes': 1,
    'dilapidate': 4,
    'dilapidated': 5,
    'dilatation': 4,
    'dilate': 2,
    'dilated': 3,
    'dilation': 3,
    'dilatory': 4,
    'dilaura': 3,
    'dilauro': 3,
    'dilbeck': 2,
    'dilbert': 2,
    'dilday': 2,
    'dildine': 3,
    'dildy': 2,
    'dilella': 3,
    'dilello': 3,
    'dilemma': 3,
    'dilemmas': 3,
    'dilenschneider': 4,
    'dileo': 3,
    'dileonardo': 5,
    'diles': 1,
    'dilford': 2,
    'dilg': 1,
    'dilger': 2,
    'diliberto': 4,
    'diligence': 3,
    'diligent': 3,
    'diligently': 4,
    'dilip': 2,
    'dilithium': 4,
    'dilks': 1,
    'dill': 1,
    'dillahunt': 3,
    'dillahunty': 4,
    'dillan': 2,
    'dillard': 2,
    'dille': 1,
    'dillehay': 3,
    'dillen': 2,
    'dillenbeck': 3,
    'dillenburg': 3,
    'diller': 2,
    'dilletante': 3,
    'dilletantes': 3,
    'dilley': 2,
    'dillie': 2,
    'dillin': 2,
    'dilling': 2,
    'dillinger': 3,
    'dillingham': 3,
    'dillion': 2,
    'dillman': 2,
    'dillmore': 2,
    'dillon': 2,
    'dillow': 2,
    'dills': 1,
    'dillworth': 2,
    'dilly': 2,
    'dillydally': 4,
    'dilmore': 2,
    'dilophosaurus': 5,
    'dilorenzo': 4,
    'diloreto': 4,
    'dilorio': 4,
    'dils': 1,
    'dilsaver': 3,
    'dilson': 2,
    'diltiazem': 4,
    'dilts': 1,
    'diltz': 1,
    'dilullo': 3,
    'dilute': 2,
    'diluted': 3,
    'dilutes': 2,
    'diluting': 3,
    'dilution': 3,
    'dilutive': 3,
    'diluzio': 4,
    'dilworth': 2,
    'dim': 1,
    'dimaggio': 4,
    'dimaio': 4,
    'dimambro': 3,
    'dimarco': 3,
    'dimare': 3,
    'dimaria': 4,
    'dimarino': 4,
    'dimario': 4,
    'dimartino': 4,
    'dimarzio': 4,
    'dimarzo': 3,
    'dimas': 2,
    'dimascio': 4,
    'dimasi': 3,
    'dimatteo': 4,
    'dimauro': 3,
    'dime': 1,
    'dimeglio': 4,
    'dimenaci': 4,
    'dimension': 3,
    'dimensional': 4,
    'dimensionality': 6,
    'dimensioned': 3,
    'dimensions': 3,
    'dimeo': 3,
    'dimer': 2,
    'dimercurio': 5,
    'dimes': 1,
    'dimetapp': 3,
    'dimiceli': 4,
    'dimichele': 4,
    'dimick': 2,
    'diming': 2,
    'diminish': 3,
    'diminished': 3,
    'diminishes': 4,
    'diminishing': 4,
    'diminishment': 4,
    'dimino': 3,
    'diminution': 4,
    'diminutive': 4,
    'dimitri': 3,
    'dimitrios': 4,
    'dimitrius': 4,
    'dimitroff': 3,
    'dimitruk': 3,
    'dimly': 2,
    'dimmed': 1,
    'dimmer': 2,
    'dimmers': 2,
    'dimmick': 2,
    'dimming': 2,
    'dimmitt': 2,
    'dimock': 2,
    'dimon': 2,
    'dimona': 3,
    'dimond': 2,
    'dimorphic': 3,
    'dimorphism': 4,
    'dimperio': 4,
    'dimple': 2,
    'dimpled': 2,
    'dimples': 2,
    'dimry': 2,
    'dims': 1,
    'dimsdale': 2,
    'dimunition': 4,
    'dimuro': 3,
    'dimuzio': 4,
    'din': 1,
    'dina': 2,
    'dinah': 2,
    'dinan': 2,
    'dinapoli': 4,
    'dinar': 2,
    'dinardo': 3,
    'dinars': 2,
    'dinatale': 4,
    'dinda': 2,
    'dine': 1,
    'dined': 1,
    'dineen': 2,
    'dinehart': 2,
    'diner': 2,
    'diners': 2,
    'dines': 1,
    'dinesh': 2,
    'ding': 1,
    'dingee': 2,
    'dingel': 2,
    'dingell': 2,
    'dinger': 2,
    'dinges': 2,
    'dingess': 2,
    'dingham': 2,
    'dinghy': 2,
    'dingle': 2,
    'dingledine': 3,
    'dingler': 3,
    'dingley': 2,
    'dingman': 2,
    'dingo': 2,
    'dings': 1,
    'dingus': 2,
    'dingwall': 2,
    'dingy': 2,
    'dinh': 1,
    'dini': 2,
    'dinicola': 4,
    'dining': 2,
    'dinino': 3,
    'dinius': 3,
    'dink': 1,
    'dinkel': 2,
    'dinkins': 2,
    'dinky': 2,
    'dinmukhamed': 4,
    'dinneen': 2,
    'dinner': 2,
    'dinners': 2,
    'dinnertime': 3,
    'dinnerware': 3,
    'dinning': 2,
    'dino': 2,
    'dinosaur': 3,
    'dinosaurs': 3,
    'dinoseb': 3,
    'dinoto': 3,
    'dinotopia': 5,
    'dinovo': 3,
    'dinsa': 2,
    'dinsdale': 2,
    'dinse': 1,
    'dinsmore': 2,
    'dint': 1,
    'dinunzio': 4,
    'dinwiddie': 3,
    'diocesan': 4,
    'diocese': 3,
    'dioceses': 3,
    'diodati': 4,
    'diodato': 4,
    'diode': 2,
    'diodes': 2,
    'dioguardi': 4,
    'dion': 2,
    'diona': 3,
    'dione': 2,
    'dionisio': 5,
    'dionne': 2,
    'dionysian': 5,
    'dionysius': 5,
    'dior': 2,
    'diorio': 4,
    'diorite': 3,
    'dios': 2,
    'dioxide': 3,
    'dioxides': 3,
    'dioxin': 3,
    'dioxins': 3,
    'dip': 1,
    'dipalma': 3,
    'dipanjan': 3,
    'dipaola': 4,
    'dipaolo': 4,
    'dipasquale': 4,
    'diperna': 3,
    'diphtheria': 4,
    'diphthong': 2,
    'dipiazza': 4,
    'dipiero': 3,
    'dipierro': 3,
    'dipietro': 3,
    'dipinto': 3,
    'dipirro': 3,
    'diploma': 3,
    'diplomacy': 4,
    'diplomas': 3,
    'diplomat': 3,
    'diplomatic': 4,
    'diplomatically': 5,
    'diplomats': 3,
    'dipole': 2,
    'dipped': 1,
    'dippel': 2,
    'dipper': 2,
    'dippers': 2,
    'dipping': 2,
    'dipple': 2,
    'dippold': 2,
    'dippolito': 4,
    'dippy': 2,
    'diprima': 3,
    'dips': 1,
    'dipstick': 2,
    'dircks': 1,
    'dire': 1,
    'direct': 2,
    'directed': 3,
    'directing': 3,
    'direction': 3,
    'directional': 4,
    'directionless': 4,
    'directions': 3,
    'directive': 3,
    'directives': 3,
    'directly': 3,
    'directness': 3,
    'director': 3,
    'directoral': 4,
    'directorate': 4,
    'directorial': 5,
    'directories': 4,
    'directors': 3,
    'directorship': 4,
    'directorships': 4,
    'directory': 4,
    'directs': 2,
    'directv': 4,
    'direnzo': 3,
    'direst': 2,
    'dirge': 1,
    'dirhams': 2,
    'dirickson': 3,
    'dirienzo': 3,
    'dirk': 1,
    'dirkes': 1,
    'dirks': 1,
    'dirkse': 1,
    'dirksen': 2,
    'dirlam': 2,
    'dirocco': 3,
    'dirosa': 3,
    'dirr': 1,
    'dirt': 1,
    'dirtier': 3,
    'dirtiest': 3,
    'dirty': 2,
    'dirusso': 3,
    'dis': 1,
    'disa': 2,
    'disabatino': 5,
    'disabato': 4,
    'disabilities': 5,
    'disability': 5,
    'disable': 3,
    'disabled': 3,
    'disables': 3,
    'disabling': 4,
    'disabuse': 3,
    'disabused': 3,
    'disabuses': 4,
    'disadvantage': 4,
    'disadvantaged': 4,
    'disadvantageous': 5,
    'disadvantages': 5,
    'disaffect': 3,
    'disaffected': 4,
    'disaffection': 4,
    'disagree': 3,
    'disagreeable': 5,
    'disagreed': 3,
    'disagreeing': 4,
    'disagreement': 4,
    'disagreements': 4,
    'disagrees': 3,
    'disallow': 3,
    'disallowance': 4,
    'disallowances': 5,
    'disallowed': 3,
    'disallowing': 4,
    'disalvo': 3,
    'disano': 3,
    'disanti': 3,
    'disantis': 3,
    'disanto': 3,
    'disappear': 3,
    'disappearance': 4,
    'disappearances': 5,
    'disappeared': 3,
    'disappearing': 4,
    'disappears': 3,
    'disappoint': 3,
    'disappointed': 4,
    'disappointing': 4,
    'disappointingly': 5,
    'disappointment': 4,
    'disappointments': 4,
    'disappoints': 3,
    'disapproval': 4,
    'disapprove': 3,
    'disapproved': 3,
    'disapproves': 3,
    'disapproving': 4,
    'disarm': 2,
    'disarmament': 4,
    'disarmed': 2,
    'disarming': 3,
    'disarmingly': 4,
    'disarray': 3,
    'disassemble': 4,
    'disassembled': 4,
    'disassociate': 5,
    'disassociated': 6,
    'disaster': 3,
    'disasters': 3,
    'disastrous': 3,
    'disastrously': 4,
    'disavow': 3,
    'disavowed': 3,
    'disavowing': 4,
    'disband': 2,
    'disbanded': 3,
    'disbanding': 3,
    'disbar': 2,
    'disbarment': 3,
    'disbarred': 2,
    'disbelief': 3,
    'disbelieve': 3,
    'disbelieving': 4,
    'disbro': 2,
    'disbrow': 2,
    'disburse': 2,
    'disbursed': 2,
    'disbursement': 3,
    'disbursements': 3,
    'disbursing': 3,
    'disc': 1,
    'discard': 2,
    'discarded': 3,
    'discarding': 3,
    'discards': 2,
    'discenza': 3,
    'discern': 2,
    'discernably': 4,
    'discerned': 2,
    'discernible': 4,
    'discerning': 3,
    'discernment': 3,
    'discerns': 2,
    'disch': 1,
    'discharge': 2,
    'discharged': 2,
    'discharges': 3,
    'discharging': 3,
    'discher': 2,
    'dischinger': 3,
    'disciple': 3,
    'disciples': 3,
    'disciplinarian': 6,
    'disciplinarians': 6,
    'disciplinary': 5,
    'discipline': 3,
    'disciplined': 3,
    'disciplines': 3,
    'disciplining': 4,
    'disclaim': 2,
    'disclaimed': 2,
    'disclaimer': 3,
    'disclaimers': 3,
    'disclaiming': 3,
    'disclaims': 2,
    'discland': 2,
    'disclose': 2,
    'disclosed': 2,
    'discloses': 3,
    'disclosing': 3,
    'disclosure': 3,
    'disclosures': 3,
    'disco': 2,
    'discography': 4,
    'discolor': 3,
    'discoloration': 5,
    'discolorations': 5,
    'discolored': 3,
    'discolors': 3,
    'discombobulate': 5,
    'discombobulated': 6,
    'discombobulates': 5,
    'discombobulating': 6,
    'discomfort': 3,
    'disconcert': 3,
    'disconcerting': 4,
    'disconnect': 3,
    'disconnected': 4,
    'disconnecting': 4,
    'disconnection': 4,
    'disconnects': 3,
    'discontent': 3,
    'discontented': 4,
    'discontentedly': 5,
    'discontentedness': 5,
    'discontents': 3,
    'discontinuance': 5,
    'discontinuation': 6,
    'discontinue': 4,
    'discontinued': 4,
    'discontinuing': 5,
    'discontinuity': 6,
    'discord': 2,
    'discordant': 3,
    'discos': 2,
    'discotheque': 3,
    'discount': 2,
    'discountable': 4,
    'discounted': 3,
    'discounter': 3,
    'discounters': 3,
    'discounting': 3,
    'discounts': 2,
    'discourage': 3,
    'discouraged': 3,
    'discouragement': 4,
    'discourages': 4,
    'discouraging': 4,
    'discourse': 2,
    'discourses': 3,
    'discover': 3,
    'discoverable': 5,
    'discovered': 3,
    'discoveries': 4,
    'discovering': 4,
    'discovers': 3,
    'discovery': 4,
    'discredit': 3,
    'discredited': 4,
    'discrediting': 4,
    'discredits': 3,
    'discreet': 2,
    'discreetly': 3,
    'discrepancies': 4,
    'discrepancy': 4,
    'discrete': 2,
    'discretion': 3,
    'discretionary': 5,
    'discretions': 3,
    'discriminate': 4,
    'discriminated': 5,
    'discriminates': 4,
    'discriminating': 5,
    'discrimination': 5,
    'discriminative': 5,
    'discriminatory': 6,
    'discs': 1,
    'discursive': 3,
    'discus': 2,
    'discuss': 2,
    'discussed': 2,
    'discusses': 3,
    'discussing': 3,
    'discussion': 3,
    'discussions': 3,
    'disdain': 2,
    'disdained': 2,
    'disdainful': 3,
    'disdaining': 3,
    'disdains': 2,
    'dise': 1,
    'disease': 2,
    'diseased': 2,
    'diseases': 3,
    'disembark': 3,
    'disembarkation': 5,
    'disembodied': 4,
    'disembody': 4,
    'disenchanted': 4,
    'disenchantment': 4,
    'disenfranchise': 4,
    'disenfranchised': 4,
    'disenfranchisement': 5,
    'disengage': 3,
    'disengaged': 3,
    'disengagement': 4,
    'disengaging': 4,
    'disentangle': 4,
    'disequilibrium': 6,
    'disestablishment': 5,
    'disfavor': 3,
    'disfavoring': 4,
    'disfigure': 3,
    'disfigured': 3,
    'disfigurement': 4,
    'disfiguring': 4,
    'disgorge': 2,
    'disgorged': 2,
    'disgorgement': 3,
    'disgrace': 2,
    'disgraced': 2,
    'disgraceful': 3,
    'disgruntled': 3,
    'disgruntlement': 4,
    'disgruntling': 4,
    'disguise': 2,
    'disguised': 2,
    'disguises': 3,
    'disguising': 3,
    'disgust': 2,
    'disgusted': 3,
    'disgusting': 3,
    'disgusts': 2,
    'dish': 1,
    'disharmony': 4,
    'disharoon': 3,
    'dishaw': 2,
    'disheartened': 3,
    'disheartening': 4,
    'dished': 1,
    'disher': 2,
    'dishes': 2,
    'dishevel': 3,
    'disheveled': 3,
    'dishing': 2,
    'dishman': 2,
    'dishner': 2,
    'dishon': 2,
    'dishonest': 3,
    'dishonestly': 4,
    'dishonesty': 4,
    'dishong': 2,
    'dishonor': 3,
    'dishonorable': 5,
    'dishonored': 3,
    'dishwasher': 3,
    'dishwashers': 3,
    'dishwashing': 3,
    'disillusion': 4,
    'disillusioned': 4,
    'disillusioning': 5,
    'disillusionment': 5,
    'disilvestro': 4,
    'disimone': 4,
    'disincentive': 4,
    'disincentives': 4,
    'disinclination': 5,
    'disincline': 3,
    'disinclined': 3,
    'disinfect': 3,
    'disinfectant': 4,
    'disinfectants': 4,
    'disinfection': 4,
    'disinflate': 3,
    'disinflation': 4,
    'disinflationary': 6,
    'disinform': 3,
    'disinformation': 5,
    'disingenuous': 5,
    'disingenuousness': 6,
    'disintegrate': 4,
    'disintegrated': 5,
    'disintegrates': 4,
    'disintegrating': 5,
    'disintegration': 5,
    'disinterest': 4,
    'disinterested': 4,
    'disinvestment': 4,
    'disinvite': 3,
    'disinvited': 4,
    'disjoint': 2,
    'disjointed': 3,
    'disk': 1,
    'diskette': 2,
    'diskettes': 2,
    'diskin': 2,
    'diskless': 2,
    'disks': 1,
    'dislike': 2,
    'disliked': 2,
    'dislikes': 2,
    'disliking': 3,
    'dislocate': 3,
    'dislocated': 4,
    'dislocation': 4,
    'dislocations': 4,
    'dislodge': 2,
    'dislodged': 2,
    'dislodging': 3,
    'disloyal': 3,
    'disloyalty': 4,
    'dismal': 2,
    'dismally': 3,
    'dismantle': 3,
    'dismantled': 3,
    'dismantlement': 4,
    'dismantles': 3,
    'dismantling': 4,
    'dismay': 2,
    'dismayed': 2,
    'dismaying': 3,
    'dismays': 2,
    'dismember': 3,
    'dismembered': 3,
    'dismembering': 4,
    'dismemberment': 4,
    'dismiss': 2,
    'dismissal': 3,
    'dismissals': 3,
    'dismissed': 2,
    'dismisses': 3,
    'dismissing': 3,
    'dismissive': 3,
    'dismore': 2,
    'dismuke': 2,
    'dismukes': 2,
    'dismutase': 3,
    'disney': 2,
    'disneyland': 3,
    'disneyworld': 3,
    'disobedience': 5,
    'disobedient': 5,
    'disobey': 3,
    'disobeyed': 3,
    'disobeying': 4,
    'dison': 2,
    'disorder': 3,
    'disordered': 3,
    'disorderly': 4,
    'disorders': 3,
    'disorganization': 6,
    'disorganize': 4,
    'disorganized': 4,
    'disorient': 4,
    'disorientation': 6,
    'disoriented': 5,
    'disorienting': 5,
    'disown': 2,
    'disowned': 2,
    'dispair': 2,
    'disparage': 3,
    'disparaged': 3,
    'disparages': 4,
    'disparaging': 4,
    'disparagingly': 5,
    'disparate': 3,
    'disparities': 4,
    'disparity': 4,
    'dispassionate': 4,
    'dispassionately': 5,
    'dispatch': 2,
    'dispatched': 2,
    'dispatcher': 3,
    'dispatchers': 3,
    'dispatches': 3,
    'dispatching': 3,
    'dispel': 2,
    'dispell': 2,
    'dispelled': 2,
    'dispelling': 3,
    'dispels': 2,
    'dispensable': 4,
    'dispensary': 4,
    'dispensation': 4,
    'dispense': 2,
    'dispensed': 2,
    'dispenser': 3,
    'dispensers': 3,
    'dispenses': 3,
    'dispensing': 3,
    'dispenza': 3,
    'dispersal': 3,
    'dispersant': 3,
    'dispersants': 3,
    'disperse': 2,
    'dispersed': 2,
    'dispersing': 3,
    'dispersion': 3,
    'dispersive': 3,
    'dispirited': 4,
    'dispiriting': 4,
    'displace': 2,
    'displaced': 2,
    'displacement': 3,
    'displacements': 3,
    'displaces': 3,
    'displacing': 3,
    'display': 2,
    'displayed': 2,
    'displaying': 3,
    'displays': 2,
    'displaywrite': 3,
    'displease': 2,
    'displeased': 2,
    'displeasure': 3,
    'disposable': 4,
    'disposables': 4,
    'disposal': 3,
    'disposals': 3,
    'dispose': 2,
    'disposed': 2,
    'disposer': 3,
    'disposes': 3,
    'disposing': 3,
    'disposition': 4,
    'dispositions': 4,
    'dispositive': 4,
    'dispossess': 3,
    'dispossessed': 3,
    'disproportionate': 5,
    'disproportionately': 6,
    'disprove': 2,
    'disproved': 2,
    'disproven': 3,
    'disproves': 2,
    'disputable': 4,
    'disputation': 4,
    'dispute': 2,
    'disputed': 3,
    'disputes': 2,
    'disputing': 3,
    'disqualification': 6,
    'disqualified': 4,
    'disqualifies': 4,
    'disqualify': 4,
    'disqualifying': 5,
    'disque': 1,
    'disquiet': 3,
    'disquieting': 4,
    'disraeli': 3,
    'disregard': 3,
    'disregarded': 4,
    'disregarding': 4,
    'disregards': 3,
    'disrepair': 3,
    'disreputable': 5,
    'disrepute': 3,
    'disrespect': 3,
    'disrespected': 4,
    'disrespectful': 4,
    'disrespecting': 4,
    'disrespects': 3,
    'disrupt': 2,
    'disrupted': 3,
    'disrupting': 3,
    'disruption': 3,
    'disruptions': 3,
    'disruptive': 3,
    'disrupts': 2,
    'diss': 1,
    'dissatisfaction': 5,
    'dissatisfied': 4,
    'dissatisfy': 4,
    'dissect': 2,
    'dissected': 3,
    'dissecting': 3,
    'dissection': 3,
    'dissections': 3,
    'dissects': 2,
    'dissemble': 3,
    'dissembling': 3,
    'disseminate': 4,
    'disseminated': 5,
    'disseminates': 4,
    'disseminating': 5,
    'dissemination': 5,
    'dissension': 3,
    'dissent': 2,
    'dissented': 3,
    'dissenter': 3,
    'dissenters': 3,
    'dissenting': 3,
    'dissents': 2,
    'dissertation': 4,
    'disservice': 3,
    'dissidence': 3,
    'dissident': 3,
    'dissidents': 3,
    'dissimilar': 4,
    'dissimilarity': 6,
    'dissing': 2,
    'dissinger': 3,
    'dissipate': 3,
    'dissipated': 4,
    'dissipates': 3,
    'dissipating': 4,
    'dissipation': 4,
    'dissipative': 4,
    'dissociate': 4,
    'dissociation': 5,
    'dissolution': 4,
    'dissolve': 2,
    'dissolved': 2,
    'dissolver': 3,
    'dissolvers': 3,
    'dissolves': 2,
    'dissolving': 3,
    'dissonance': 3,
    'dissonant': 3,
    'dissuade': 2,
    'dissuaded': 3,
    'dissymmetric': 4,
    'dissymmetry': 4,
    'distad': 2,
    'distal': 2,
    'distance': 2,
    'distanced': 2,
    'distances': 3,
    'distancing': 3,
    'distant': 2,
    'distasi': 3,
    'distasio': 4,
    'distaste': 2,
    'distasteful': 3,
    'distefano': 4,
    'distel': 2,
    'distemper': 3,
    'distend': 2,
    'distended': 3,
    'distil': 2,
    'distill': 2,
    'distillate': 3,
    'distillates': 3,
    'distillation': 4,
    'distilled': 2,
    'distiller': 3,
    'distilleries': 4,
    'distillers': 3,
    'distillery': 4,
    'distilling': 3,
    'distills': 2,
    'distinct': 2,
    'distinction': 3,
    'distinctions': 3,
    'distinctive': 3,
    'distinctively': 4,
    'distinctiveness': 4,
    'distinctly': 3,
    'distinguish': 3,
    'distinguishable': 5,
    'distinguished': 3,
    'distinguishes': 4,
    'distinguishing': 4,
    'distler': 2,
    'distort': 2,
    'distorted': 3,
    'distorting': 3,
    'distortion': 3,
    'distortions': 3,
    'distorts': 2,
    'distract': 2,
    'distracted': 3,
    'distracting': 3,
    'distraction': 3,
    'distractions': 3,
    'distracts': 2,
    'distraught': 2,
    'distress': 2,
    'distressed': 2,
    'distresses': 3,
    'distressing': 3,
    'distressingly': 4,
    'distribute': 3,
    'distributed': 4,
    'distributes': 3,
    'distributing': 4,
    'distribution': 4,
    'distributions': 4,
    'distributive': 4,
    'distributor': 4,
    'distributors': 4,
    'distributorship': 5,
    'distributorships': 5,
    'district': 2,
    'districting': 3,
    'districts': 2,
    'distrigas': 3,
    'distron': 2,
    'distrust': 2,
    'distrusted': 3,
    'distrustful': 3,
    'distrusts': 2,
    'disturb': 2,
    'disturbance': 3,
    'disturbances': 4,
    'disturbed': 2,
    'disturbing': 3,
    'disturbingly': 4,
    'disturbs': 2,
    'disunion': 3,
    'disunity': 4,
    'disuse': 2,
    'ditch': 1,
    'ditched': 1,
    'ditches': 2,
    'ditching': 2,
    'dith': 1,
    'dither': 2,
    'dithering': 3,
    'dithers': 2,
    'dithyramb': 3,
    'ditka': 2,
    'ditlow': 2,
    'ditmars': 2,
    'ditmer': 2,
    'ditmore': 2,
    'ditomasso': 4,
    'ditommaso': 4,
    'ditsy': 2,
    'dittberner': 3,
    'dittemore': 3,
    'ditter': 2,
    'ditties': 2,
    'dittman': 2,
    'dittmann': 2,
    'dittmar': 2,
    'dittmer': 2,
    'ditto': 2,
    'ditton': 2,
    'dittrich': 2,
    'dittus': 2,
    'ditty': 2,
    'ditullio': 4,
    'ditzel': 2,
    'ditzler': 2,
    'diuretic': 4,
    'diuretics': 4,
    'diurnal': 3,
    'diurnally': 4,
    'diva': 2,
    'divad': 2,
    'divalent': 3,
    'divall': 2,
    'divan': 2,
    'divas': 2,
    'dive': 1,
    'dived': 1,
    'divelbiss': 3,
    'diveley': 3,
    'dively': 2,
    'diven': 2,
    'divens': 2,
    'diver': 2,
    'diverge': 2,
    'diverged': 2,
    'divergence': 3,
    'divergences': 4,
    'divergent': 3,
    'diverges': 3,
    'diverging': 3,
    'divers': 2,
    'diverse': 2,
    'diversicare': 4,
    'diversification': 6,
    'diversifications': 6,
    'diversified': 4,
    'diversify': 4,
    'diversifying': 5,
    'diversion': 3,
    'diversionary': 5,
    'diversions': 3,
    'diversity': 4,
    'divert': 2,
    'diverted': 3,
    'diverticula': 5,
    'diverticulum': 5,
    'divertimento': 5,
    'diverting': 3,
    'diverts': 2,
    'dives': 1,
    'divest': 2,
    'divested': 3,
    'divesting': 3,
    'divestiture': 4,
    'divestitures': 4,
    'divestment': 3,
    'divestments': 3,
    'divests': 2,
    'divi': 2,
    'divide': 2,
    'divided': 3,
    'dividend': 3,
    'dividends': 3,
    'divider': 3,
    'divides': 2,
    'dividing': 3,
    'divination': 4,
    'divincenzo': 4,
    'divine': 2,
    'divinely': 3,
    'diviney': 3,
    'diving': 2,
    'divining': 3,
    'divinitas': 4,
    'divinities': 4,
    'divinity': 4,
    'divirgilio': 5,
    'divis': 2,
    'divisible': 4,
    'division': 3,
    'divisional': 4,
    'divisions': 3,
    'divisive': 3,
    'divisiveness': 4,
    'divison': 3,
    'divisor': 3,
    'divita': 3,
    'divito': 3,
    'divorce': 2,
    'divorced': 2,
    'divorcee': 3,
    'divorces': 3,
    'divorcing': 3,
    'divulge': 2,
    'divulged': 2,
    'divulging': 3,
    'divvied': 2,
    'divvy': 2,
    'diwa': 2,
    'diwali': 3,
    'dix': 1,
    'dixie': 2,
    'dixieland': 3,
    'dixon': 2,
    'dixons': 2,
    'dixson': 2,
    'dixville': 2,
    'dixy': 2,
    'dizon': 2,
    'dizziness': 3,
    'dizzy': 2,
    'dizzying': 3,
    'djakarta': 3,
    'djibouti': 3,
    'djukanovic': 4,
    'djurdjevic': 3,
    'dk': 2,
    'dlouhy': 3,
    'dlugos': 3,
    'dlugosz': 3,
    'dmitri': 3,
    'dmz': 3,
    'dna': 3,
    'dnase': 3,
    'dnc': 3,
    'dniester': 3,
    'do': 1,
    'doable': 3,
    'doak': 1,
    'doan': 1,
    'doane': 1,
    'doanh': 1,
    'doanna': 2,
    'doar': 1,
    'dob': 1,
    'dobb': 1,
    'dobberstein': 3,
    'dobbie': 2,
    'dobbin': 2,
    'dobbins': 2,
    'dobbs': 1,
    'dobek': 2,
    'dober': 2,
    'doberman': 3,
    'doberstein': 3,
    'dobesh': 2,
    'dobey': 2,
    'dobias': 3,
    'dobie': 2,
    'dobies': 2,
    'dobis': 2,
    'dobkin': 2,
    'dobkins': 2,
    'doble': 2,
    'dobler': 3,
    'dobmeier': 3,
    'doboj': 2,
    'dobos': 2,
    'dobosz': 2,
    'dobransky': 3,
    'dobratz': 2,
    'dobrimir': 3,
    'dobrin': 2,
    'dobrinja': 3,
    'dobrins': 2,
    'dobrinski': 3,
    'dobrowolski': 4,
    'dobrowski': 3,
    'dobry': 2,
    'dobrynin': 3,
    'dobrzynski': 4,
    'dobson': 2,
    'dobsons': 2,
    'doby': 2,
    'dobyns': 2,
    'doc': 1,
    'docent': 2,
    'docents': 2,
    'docherty': 3,
    'dochow': 2,
    'dochterman': 3,
    'docie': 2,
    'docila': 3,
    'docile': 2,
    'docility': 4,
    'dock': 1,
    'docked': 1,
    'docken': 2,
    'dockendorf': 3,
    'docker': 2,
    'dockers': 2,
    'dockery': 3,
    'docket': 2,
    'dockets': 2,
    'dockham': 2,
    'dockiers': 2,
    'docking': 2,
    'dockings': 2,
    'dockins': 2,
    'dockland': 2,
    'docklands': 2,
    'docks': 1,
    'dockside': 2,
    'dockson': 2,
    'dockstader': 3,
    'dockter': 2,
    'dockworker': 3,
    'dockworkers': 3,
    'dockyard': 2,
    'doctor': 2,
    'doctoral': 3,
    'doctorate': 3,
    'doctorates': 3,
    'doctored': 2,
    'doctoring': 3,
    'doctors': 2,
    'doctrinaire': 3,
    'doctrinal': 3,
    'doctrine': 2,
    'doctrines': 2,
    'docudrama': 4,
    'document': 3,
    'documenta': 4,
    'documentaries': 5,
    'documentary': 5,
    'documentation': 5,
    'documented': 4,
    'documenting': 4,
    'documents': 3,
    'dodaro': 3,
    'dodd': 1,
    'doddering': 3,
    'doddington': 3,
    'doddridge': 2,
    'dodds': 1,
    'dodge': 1,
    'dodged': 1,
    'dodgen': 2,
    'dodger': 2,
    'dodgers': 2,
    'dodges': 2,
    'dodging': 2,
    'dodi': 2,
    'dodo': 2,
    'dodos': 2,
    'dodrill': 2,
    'dodson': 2,
    'dodsworth': 2,
    'doe': 1,
    'doebler': 3,
    'doeden': 2,
    'doege': 1,
    'doehring': 2,
    'doell': 1,
    'doenges': 2,
    'doepke': 1,
    'doepker': 2,
    'doer': 1,
    'doerfler': 3,
    'doerflinger': 4,
    'doering': 3,
    'doernberg': 2,
    'doerner': 2,
    'doerr': 1,
    'doers': 2,
    'doersam': 2,
    'does': 1,
    'doescher': 2,
    'doetsch': 1,
    'dofasco': 3,
    'doff': 1,
    'doffing': 2,
    'doffs': 1,
    'dog': 1,
    'dogan': 2,
    'dogbane': 2,
    'dogberry': 3,
    'doge': 1,
    'dogear': 2,
    'dogeared': 2,
    'dogearing': 3,
    'dogears': 2,
    'dogfight': 2,
    'dogfights': 2,
    'dogfish': 2,
    'dogged': 1,
    'doggedly': 3,
    'doggerel': 3,
    'doggett': 2,
    'doggie': 2,
    'doggies': 2,
    'dogging': 2,
    'doggone': 2,
    'doggy': 2,
    'doghouse': 2,
    'doghouses': 3,
    'dogle': 2,
    'doglike': 2,
    'dogma': 2,
    'dogmatic': 3,
    'dogmatically': 4,
    'dogmatism': 4,
    'dogs': 1,
    'dogwood': 2,
    'dogwoods': 2,
    'doh': 1,
    'doheny': 3,
    'doherty': 3,
    'dohm': 1,
    'dohman': 2,
    'dohme': 1,
    'dohmen': 2,
    'dohn': 1,
    'dohnanyi': 3,
    'dohner': 2,
    'dohr': 1,
    'dohrman': 2,
    'dohrmann': 2,
    'dohse': 1,
    'doi': 1,
    'doidge': 1,
    'doig': 1,
    'doilies': 2,
    'doily': 2,
    'doing': 2,
    'doings': 2,
    'doiron': 2,
    'doke': 1,
    'dokely': 2,
    'dokey': 2,
    'dokken': 2,
    'doktor': 2,
    'dol': 1,
    'dolak': 2,
    'dolan': 2,
    'doland': 2,
    'dolata': 3,
    'dolbow': 2,
    'dolby': 2,
    'dolce': 2,
    'dolch': 1,
    'dold': 1,
    'dolder': 2,
    'doldrum': 2,
    'doldrums': 2,
    'dole': 1,
    'dolecki': 3,
    'doled': 1,
    'doleful': 2,
    'dolen': 2,
    'dolence': 2,
    'doles': 1,
    'doley': 2,
    'dolezal': 3,
    'dolf': 1,
    'dolfi': 2,
    'dolgen': 2,
    'dolgin': 2,
    'dolin': 2,
    'dolinar': 3,
    'doling': 2,
    'dolinger': 3,
    'dolinski': 3,
    'dolinsky': 3,
    'doll': 1,
    'dollan': 2,
    'dollar': 2,
    'dollard': 2,
    'dollarhide': 3,
    'dollars': 2,
    'dolle': 1,
    'dolled': 1,
    'dollens': 2,
    'dolley': 2,
    'dollhouse': 2,
    'dollhouses': 3,
    'dollie': 2,
    'dollinger': 3,
    'dollins': 2,
    'dollison': 3,
    'dolliver': 3,
    'dolloff': 2,
    'dollop': 2,
    'dolls': 1,
    'dolly': 2,
    'dolman': 2,
    'dolney': 2,
    'dolomite': 3,
    'dolomites': 3,
    'dolores': 3,
    'dolorita': 4,
    'dolorosa': 4,
    'dolph': 1,
    'dolphin': 2,
    'dolphins': 2,
    'dols': 1,
    'dolson': 2,
    'dolton': 2,
    'dom': 1,
    'domagala': 4,
    'domagalski': 4,
    'domain': 2,
    'domaine': 2,
    'domains': 2,
    'doman': 2,
    'domangue': 2,
    'domanico': 4,
    'domanski': 3,
    'domas': 2,
    'dombeck': 2,
    'dombek': 2,
    'dombkowski': 3,
    'dombroski': 3,
    'dombrosky': 3,
    'dombrowski': 3,
    'dome': 1,
    'domecq': 2,
    'domed': 1,
    'domeier': 3,
    'domek': 2,
    'domenech': 3,
    'domenici': 4,
    'domenick': 3,
    'domenico': 4,
    'domeniconi': 5,
    'domer': 2,
    'domes': 1,
    'domestic': 3,
    'domestically': 4,
    'domesticate': 4,
    'domesticated': 5,
    'domesticates': 4,
    'domesticating': 5,
    'domestication': 5,
    'domesticity': 5,
    'domestics': 3,
    'domical': 3,
    'domico': 3,
    'domin': 2,
    'domina': 3,
    'dominance': 3,
    'dominant': 3,
    'dominate': 3,
    'dominated': 4,
    'dominates': 3,
    'dominating': 4,
    'domination': 4,
    'domine': 3,
    'dominee': 3,
    'domineer': 3,
    'domineering': 4,
    'dominelli': 4,
    'dominey': 3,
    'domingo': 3,
    'domingos': 3,
    'domingue': 2,
    'domingues': 3,
    'dominguez': 3,
    'domini': 3,
    'dominiak': 4,
    'dominic': 3,
    'dominica': 4,
    'dominican': 4,
    'dominicana': 5,
    'dominicans': 4,
    'dominici': 4,
    'dominick': 3,
    'dominik': 3,
    'dominion': 3,
    'dominique': 3,
    'domino': 3,
    'dominoes': 3,
    'dominos': 3,
    'dominquez': 3,
    'dominski': 3,
    'dominus': 3,
    'dominy': 3,
    'domke': 1,
    'dommer': 2,
    'dommie': 2,
    'domoling': 3,
    'domtar': 2,
    'domzalski': 3,
    'don': 1,
    'dona': 2,
    'donadio': 4,
    'donaghey': 3,
    'donaghue': 3,
    'donaghy': 3,
    'donahey': 3,
    'donaho': 3,
    'donahoe': 3,
    'donahoo': 3,
    'donahue': 3,
    'donais': 2,
    'donald': 2,
    'donalda': 3,
    'donaldo': 3,
    'donaldson': 3,
    'donaldsons': 3,
    'donalson': 3,
    'donapria': 4,
    'donar': 2,
    'donat': 2,
    'donata': 3,
    'donate': 2,
    'donated': 3,
    'donatelli': 4,
    'donates': 2,
    'donath': 2,
    'donathan': 3,
    'donati': 3,
    'donating': 3,
    'donation': 3,
    'donations': 3,
    'donatists': 3,
    'donato': 3,
    'donavan': 3,
    'donaway': 3,
    'donde': 1,
    'donder': 2,
    'dondero': 3,
    'dondlinger': 4,
    'done': 1,
    'donegan': 3,
    'donehoo': 3,
    'donelan': 3,
    'donella': 3,
    'donelson': 3,
    'doner': 2,
    'dones': 1,
    'doney': 2,
    'dong': 1,
    'dongen': 2,
    'dongmei': 2,
    'donham': 2,
    'donia': 3,
    'donica': 3,
    'donigan': 3,
    'doniger': 3,
    'donilon': 3,
    'donis': 2,
    'donizetti': 4,
    'donkey': 2,
    'donkeys': 2,
    'donlan': 2,
    'donley': 2,
    'donlin': 2,
    'donlon': 2,
    'donmoyer': 3,
    'donn': 1,
    'donna': 2,
    'donnan': 2,
    'donnas': 2,
    'donnay': 2,
    'donne': 1,
    'donned': 1,
    'donnell': 2,
    'donnellan': 3,
    'donnelley': 3,
    'donnelly': 3,
    'donner': 2,
    'donnie': 2,
    'donning': 2,
    'donny': 2,
    'donnybrook': 3,
    'donofrio': 4,
    'donoghue': 3,
    'donoho': 3,
    'donohoe': 3,
    'donohoo': 3,
    'donohue': 3,
    'donor': 2,
    'donors': 2,
    'donovan': 3,
    'dons': 1,
    'donsbach': 2,
    'donut': 2,
    'donuts': 2,
    'donvan': 2,
    'donze': 1,
    'doo': 1,
    'doodad': 2,
    'doodads': 2,
    'doodle': 2,
    'doodles': 2,
    'doody': 2,
    'doogie': 2,
    'doolan': 2,
    'doolen': 2,
    'dooley': 2,
    'doolin': 2,
    'dooling': 2,
    'doolittle': 3,
    'doom': 1,
    'doomed': 1,
    'dooming': 2,
    'dooms': 1,
    'doomsayer': 3,
    'doomsayers': 3,
    'doomsaying': 3,
    'doomsday': 2,
    'doonan': 2,
    'dooner': 2,
    'doonesbury': 3,
    'door': 1,
    'doorbell': 2,
    'doordarshan': 3,
    'doorenbos': 3,
    'doorkeeper': 3,
    'doorknob': 2,
    'doorknobs': 2,
    'doorman': 2,
    'doormat': 2,
    'doormats': 2,
    'doorn': 1,
    'doornail': 2,
    'doornbos': 2,
    'doors': 1,
    'doorsill': 2,
    'doorstep': 2,
    'doorsteps': 2,
    'doorway': 2,
    'doorways': 2,
    'doose': 1,
    'doozy': 2,
    'dopa': 2,
    'dopamine': 3,
    'dope': 1,
    'doped': 1,
    'dopey': 2,
    'dopp': 1,
    'doppler': 2,
    'dopson': 2,
    'dora': 2,
    'dorado': 3,
    'dorais': 2,
    'doral': 2,
    'doralin': 3,
    'doralynne': 3,
    'doran': 2,
    'doraville': 3,
    'dorazio': 4,
    'dorcas': 2,
    'dorch': 1,
    'dorchester': 3,
    'dordies': 2,
    'dore': 1,
    'dorea': 3,
    'doreen': 2,
    'dorelia': 4,
    'doremus': 3,
    'doren': 2,
    'dorena': 3,
    'dorene': 2,
    'dorer': 2,
    'dorette': 2,
    'doretti': 3,
    'dorey': 2,
    'dorf': 1,
    'dorff': 1,
    'dorfman': 2,
    'dorgan': 2,
    'dori': 2,
    'doria': 3,
    'dorian': 3,
    'dorians': 3,
    'doric': 2,
    'dorice': 2,
    'dorie': 2,
    'dorin': 2,
    'dorinda': 3,
    'dorine': 3,
    'doring': 2,
    'dorio': 3,
    'dorion': 3,
    'doris': 2,
    'dorise': 2,
    'doritos': 3,
    'dority': 3,
    'dorko': 2,
    'dorland': 2,
    'dorm': 1,
    'dorman': 2,
    'dormancy': 3,
    'dormant': 2,
    'dormer': 2,
    'dorminey': 3,
    'dormitories': 4,
    'dormitory': 4,
    'dormouse': 2,
    'dorms': 1,
    'dorn': 1,
    'dornak': 2,
    'dornam': 2,
    'dornan': 2,
    'dornbusch': 2,
    'dornbush': 2,
    'dorner': 2,
    'dorney': 2,
    'dornfeld': 2,
    'dornhens': 2,
    'dornier': 2,
    'dornin': 2,
    'doro': 2,
    'doron': 2,
    'dorotea': 3,
    'dorothea': 4,
    'dorothy': 3,
    'dorough': 2,
    'dorow': 2,
    'dorr': 1,
    'dorrance': 2,
    'dorrell': 2,
    'dorrie': 2,
    'dorries': 2,
    'dorris': 2,
    'dorroh': 2,
    'dorrough': 2,
    'dorsa': 2,
    'dorsal': 2,
    'dorsally': 3,
    'dorsch': 1,
    'dorset': 2,
    'dorsett': 2,
    'dorsey': 2,
    'dorsi': 2,
    'dorst': 1,
    'dort': 1,
    'dorta': 2,
    'dortch': 1,
    'dorthea': 3,
    'dorthy': 2,
    'dortmund': 2,
    'dorton': 2,
    'dorval': 2,
    'dorward': 2,
    'dorwart': 2,
    'dory': 2,
    'dos': 1,
    'dosage': 2,
    'dosages': 3,
    'dosch': 1,
    'doscher': 2,
    'dose': 1,
    'doser': 2,
    'doses': 2,
    'dosh': 1,
    'dosher': 2,
    'doshi': 2,
    'doshier': 3,
    'dosia': 3,
    'dosie': 2,
    'dosier': 3,
    'dosimeters': 4,
    'dosing': 2,
    'doskocil': 3,
    'dospasos': 3,
    'doss': 1,
    'dossantos': 3,
    'dossett': 2,
    'dossey': 2,
    'dossier': 2,
    'dossiers': 2,
    'dost': 1,
    'dostal': 2,
    'doster': 2,
    'dostie': 2,
    'dostoevski': 3,
    'dostoevsky': 3,
    'doswell': 2,
    'dot': 1,
    'dotan': 2,
    'dote': 1,
    'doten': 2,
    'doth': 1,
    'dothan': 2,
    'doting': 2,
    'doto': 2,
    'dots': 1,
    'dotson': 2,
    'dott': 1,
    'dottavio': 4,
    'dotted': 2,
    'dotter': 2,
    'dotterer': 3,
    'dottie': 2,
    'dotting': 2,
    'dotts': 1,
    'dotty': 2,
    'doty': 2,
    'dotzler': 2,
    'doub': 1,
    'doubek': 2,
    'doubet': 2,
    'double': 2,
    'doubled': 2,
    'doubleday': 3,
    'doubledecker': 4,
    'doubles': 2,
    'doublespeak': 3,
    'doublethink': 3,
    'doubletree': 3,
    'doubling': 3,
    'doubly': 2,
    'doubrava': 3,
    'doubt': 1,
    'doubted': 2,
    'doubter': 2,
    'doubters': 2,
    'doubtfire': 3,
    'doubtfires': 3,
    'doubtful': 2,
    'doubting': 2,
    'doubtless': 2,
    'doubts': 1,
    'doucet': 2,
    'doucette': 2,
    'douche': 1,
    'doud': 1,
    'doudna': 2,
    'douds': 1,
    'doug': 1,
    'dougal': 2,
    'dougall': 2,
    'dougan': 2,
    'dougans': 2,
    'dougens': 2,
    'douggins': 2,
    'dough': 1,
    'doughboy': 2,
    'dougher': 2,
    'dougherty': 3,
    'doughman': 2,
    'doughnut': 2,
    'doughnuts': 2,
    'doughten': 2,
    'doughtie': 2,
    'doughty': 2,
    'doughy': 2,
    'dougie': 2,
    'dougl': 2,
    'douglas': 2,
    'douglass': 3,
    'doukas': 2,
    'doulton': 2,
    'douma': 2,
    'doupe': 1,
    'dour': 2,
    'douse': 1,
    'doused': 1,
    'dousing': 2,
    'douthat': 2,
    'douthett': 2,
    'douthit': 2,
    'douthitt': 2,
    'doutt': 1,
    'douty': 2,
    'douville': 2,
    'douwe': 1,
    'dov': 1,
    'dove': 1,
    'dovel': 2,
    'dover': 2,
    'doverspike': 3,
    'doves': 1,
    'dovetail': 2,
    'dovetailed': 2,
    'dovetails': 2,
    'dovey': 2,
    'dovidio': 4,
    'dovish': 2,
    'dow': 1,
    'dowager': 3,
    'dowd': 1,
    'dowda': 2,
    'dowdall': 2,
    'dowdell': 2,
    'dowden': 2,
    'dowding': 2,
    'dowdle': 2,
    'dowds': 1,
    'dowdy': 2,
    'dowe': 1,
    'dowel': 2,
    'dowell': 2,
    'dowels': 2,
    'dowen': 2,
    'dower': 1,
    'dowers': 1,
    'dowie': 2,
    'dowis': 2,
    'dowland': 2,
    'dowlen': 2,
    'dowler': 2,
    'dowless': 2,
    'dowling': 2,
    'down': 1,
    'downard': 2,
    'downbeat': 2,
    'downcast': 2,
    'downdraft': 2,
    'downe': 1,
    'downed': 1,
    'downen': 2,
    'downer': 2,
    'downers': 2,
    'downes': 1,
    'downey': 2,
    'downfall': 2,
    'downgrade': 2,
    'downgraded': 3,
    'downgrades': 2,
    'downgrading': 3,
    'downgradings': 3,
    'downham': 2,
    'downhill': 2,
    'downie': 2,
    'downing': 2,
    'downingtown': 3,
    'download': 2,
    'downloaded': 3,
    'downloading': 3,
    'downloads': 2,
    'downpayment': 3,
    'downpayments': 3,
    'downplay': 2,
    'downplayed': 2,
    'downplaying': 3,
    'downplays': 2,
    'downpour': 2,
    'downpours': 2,
    'downrange': 2,
    'downright': 2,
    'downriver': 3,
    'downs': 1,
    'downscale': 2,
    'downside': 2,
    'downsides': 2,
    'downsize': 2,
    'downsized': 2,
    'downsizes': 3,
    'downsizing': 3,
    'downsizings': 3,
    'downstage': 2,
    'downstairs': 2,
    'downstate': 2,
    'downstream': 2,
    'downswing': 2,
    'downtime': 2,
    'downtown': 2,
    'downtowns': 2,
    'downtrend': 2,
    'downtrodden': 3,
    'downturn': 2,
    'downturns': 2,
    'downum': 2,
    'downward': 2,
    'downwardly': 3,
    'downwards': 2,
    'downwind': 2,
    'downy': 2,
    'dowry': 2,
    'dowse': 1,
    'dowsing': 2,
    'dowsman': 2,
    'dowty': 2,
    'doxey': 2,
    'doxie': 2,
    'doxologies': 4,
    'doxology': 4,
    'doxtater': 3,
    'doxy': 2,
    'doyal': 2,
    'doyel': 2,
    'doyen': 2,
    'doyle': 1,
    'doyon': 2,
    'doze': 1,
    'dozed': 1,
    'dozen': 2,
    'dozens': 2,
    'dozes': 2,
    'dozier': 3,
    'dozing': 2,
    'dqalpha': 4,
    'dr': 1,
    'drab': 1,
    'drabbed': 1,
    'drabble': 2,
    'drabek': 2,
    'drabenstott': 3,
    'drabik': 2,
    'drabinsky': 3,
    'drabkin': 2,
    'drabs': 1,
    'drace': 1,
    'drach': 1,
    'drachenberg': 3,
    'drachma': 2,
    'drachmas': 2,
    'drackett': 2,
    'draco': 2,
    'draconian': 4,
    'dracula': 3,
    'draeger': 2,
    'draffen': 2,
    'draft': 1,
    'drafted': 2,
    'draftee': 2,
    'draftees': 2,
    'drafter': 2,
    'drafters': 2,
    'drafting': 2,
    'drafts': 1,
    'draftsman': 2,
    'draftsmanship': 3,
    'draftsmen': 2,
    'drafty': 2,
    'drag': 1,
    'dragan': 2,
    'drage': 1,
    'drager': 2,
    'dragged': 1,
    'dragging': 2,
    'draggy': 2,
    'dragline': 2,
    'dragnet': 2,
    'dragnets': 2,
    'drago': 2,
    'dragon': 2,
    'dragonair': 3,
    'dragone': 2,
    'dragonhead': 3,
    'dragonheart': 3,
    'dragons': 2,
    'dragoo': 2,
    'dragovich': 3,
    'drags': 1,
    'draheim': 2,
    'drahos': 2,
    'drahuschak': 3,
    'drain': 1,
    'drainage': 2,
    'draine': 1,
    'drained': 1,
    'drainer': 2,
    'drainers': 2,
    'draining': 2,
    'drains': 1,
    'drake': 1,
    'drakeford': 2,
    'drakes': 1,
    'drakos': 2,
    'dralle': 1,
    'dram': 1,
    'drama': 2,
    'dramamine': 3,
    'dramas': 2,
    'dramatic': 3,
    'dramatically': 4,
    'dramatics': 3,
    'dramatist': 3,
    'dramatization': 5,
    'dramatizations': 5,
    'dramatize': 3,
    'dramatized': 3,
    'dramatizes': 4,
    'dramatizing': 4,
    'dramaturgy': 4,
    'drams': 1,
    'drane': 1,
    'draney': 2,
    'drang': 1,
    'drank': 1,
    'dransfield': 2,
    'drape': 1,
    'drapeau': 2,
    'draped': 1,
    'draper': 2,
    'draperies': 3,
    'drapery': 3,
    'drapes': 1,
    'draping': 2,
    'drapkin': 2,
    'drasner': 2,
    'drastic': 2,
    'drastically': 3,
    'draughn': 1,
    'draughon': 2,
    'draught': 1,
    'draughts': 1,
    'draus': 1,
    'dravecky': 3,
    'draves': 1,
    'dravis': 2,
    'dravo': 2,
    'draw': 1,
    'drawback': 2,
    'drawbacks': 2,
    'drawbaugh': 2,
    'drawbridge': 2,
    'drawdown': 2,
    'drawdowns': 2,
    'drawdy': 2,
    'drawer': 1,
    'drawers': 1,
    'drawing': 2,
    'drawings': 2,
    'drawl': 1,
    'drawled': 1,
    'drawls': 1,
    'drawn': 1,
    'draws': 1,
    'dray': 1,
    'drayer': 2,
    'drayton': 2,
    'dread': 1,
    'dreaded': 2,
    'dreadful': 2,
    'dreadfully': 3,
    'dreading': 2,
    'dreadnought': 2,
    'dreads': 1,
    'dream': 1,
    'dreamed': 1,
    'dreamer': 2,
    'dreamers': 2,
    'dreaming': 2,
    'dreamland': 2,
    'dreamlike': 2,
    'dreams': 1,
    'dreamt': 1,
    'dreamworks': 2,
    'dreamworld': 2,
    'dreamy': 2,
    'dreariness': 3,
    'dreary': 2,
    'drebsky': 2,
    'drechsel': 2,
    'drechsler': 3,
    'dred': 1,
    'dredd': 1,
    'dredge': 1,
    'dredged': 1,
    'dredges': 2,
    'dredging': 2,
    'drees': 1,
    'dreese': 1,
    'dreessen': 2,
    'dreger': 2,
    'dregs': 1,
    'dreher': 1,
    'dreibelbis': 3,
    'dreier': 2,
    'dreighton': 2,
    'dreiling': 2,
    'dreis': 1,
    'dreisbach': 2,
    'dreman': 2,
    'drench': 1,
    'drenched': 1,
    'drenches': 2,
    'drenching': 2,
    'drennan': 2,
    'drennen': 2,
    'drenning': 2,
    'drennon': 2,
    'drenth': 1,
    'drepung': 2,
    'dresbach': 2,
    'dresch': 1,
    'drescher': 2,
    'dresden': 2,
    'dresdner': 2,
    'dresen': 2,
    'dresher': 2,
    'dresner': 2,
    'dress': 1,
    'dressage': 2,
    'dressed': 1,
    'dressel': 2,
    'dressen': 2,
    'dresser': 2,
    'dressers': 2,
    'dresses': 2,
    'dressier': 3,
    'dressing': 2,
    'dressings': 2,
    'dressler': 2,
    'dressmaker': 3,
    'dressmakers': 3,
    'dressmaking': 3,
    'dressy': 2,
    'drew': 1,
    'drewery': 3,
    'drewes': 1,
    'drewett': 2,
    'drewry': 2,
    'drews': 1,
    'drexel': 2,
    'drexler': 2,
    'drey': 1,
    'dreyer': 2,
    'dreyfus': 2,
    'dreyfuss': 2,
    'dribbed': 1,
    'dribble': 2,
    'dribbled': 2,
    'dribbles': 2,
    'dribbling': 3,
    'dribs': 1,
    'dried': 1,
    'driehaus': 2,
    'drier': 2,
    'dries': 1,
    'driessen': 2,
    'driest': 2,
    'driever': 2,
    'drift': 1,
    'drifta': 2,
    'drifted': 2,
    'drifter': 2,
    'drifters': 2,
    'drifting': 2,
    'driftnet': 2,
    'drifts': 1,
    'driftwood': 2,
    'driggers': 2,
    'driggs': 1,
    'drill': 1,
    'drillbit': 2,
    'drilled': 1,
    'driller': 2,
    'drillers': 2,
    'drilling': 2,
    'drills': 1,
    'drina': 2,
    'drinas': 2,
    'dring': 1,
    'drink': 1,
    'drinkable': 3,
    'drinkard': 2,
    'drinker': 2,
    'drinkers': 2,
    'drinking': 2,
    'drinks': 1,
    'drinkwater': 3,
    'drinkwine': 2,
    'drinnon': 2,
    'drip': 1,
    'dripped': 1,
    'dripping': 2,
    'dripps': 1,
    'drips': 1,
    'driscoll': 2,
    'driskell': 2,
    'driskill': 2,
    'drivable': 3,
    'drive': 1,
    'drivel': 2,
    'driven': 2,
    'driver': 2,
    'driverless': 3,
    'drivers': 2,
    'drives': 1,
    'driveway': 2,
    'driveways': 2,
    'driving': 2,
    'drizzle': 2,
    'drizzling': 3,
    'drizzly': 2,
    'drobkov': 2,
    'drobny': 2,
    'droddy': 2,
    'droege': 1,
    'droessler': 3,
    'droge': 1,
    'drogoul': 2,
    'drogue': 1,
    'drohan': 2,
    'droke': 1,
    'drolet': 2,
    'droll': 1,
    'drollinger': 3,
    'dromedary': 4,
    'dromer': 2,
    'dromey': 2,
    'dromgoole': 2,
    'dromgooles': 2,
    'dromi': 2,
    'dromomania': 5,
    'dromon': 2,
    'drone': 1,
    'droned': 1,
    'drones': 1,
    'dronet': 2,
    'droney': 2,
    'droning': 2,
    'drool': 1,
    'drooling': 2,
    'droop': 1,
    'drooped': 1,
    'drooping': 2,
    'droopy': 2,
    'drop': 1,
    'dropkin': 2,
    'droplet': 2,
    'droplets': 2,
    'dropoff': 2,
    'dropoffs': 2,
    'dropout': 2,
    'dropouts': 2,
    'dropped': 1,
    'dropper': 2,
    'droppers': 2,
    'dropping': 2,
    'droppings': 2,
    'drops': 1,
    'dropsy': 2,
    'droser': 2,
    'dross': 1,
    'drost': 1,
    'droste': 1,
    'drotar': 2,
    'drought': 1,
    'droughts': 1,
    'drouillard': 2,
    'drouin': 1,
    'drove': 1,
    'drover': 2,
    'droves': 1,
    'drown': 1,
    'drowned': 1,
    'drowning': 2,
    'drownings': 2,
    'drowns': 1,
    'drowsiness': 3,
    'drowsy': 2,
    'droz': 1,
    'drozd': 1,
    'drozda': 2,
    'drozdowski': 3,
    'dru': 1,
    'drub': 1,
    'drubbed': 1,
    'drubbing': 2,
    'druce': 1,
    'drucie': 2,
    'druck': 1,
    'druckenmiller': 4,
    'drucker': 2,
    'druckman': 2,
    'drudge': 1,
    'drudgery': 3,
    'druella': 3,
    'drug': 1,
    'drugan': 2,
    'drugged': 1,
    'drugging': 2,
    'druggist': 2,
    'druggists': 2,
    'drugmaker': 3,
    'drugmakers': 3,
    'drugs': 1,
    'drugstore': 2,
    'drugstores': 2,
    'druid': 2,
    'druidism': 4,
    'druids': 2,
    'druisilla': 3,
    'drum': 1,
    'drumbeat': 2,
    'drumhead': 2,
    'drumheller': 3,
    'drumm': 1,
    'drummed': 1,
    'drummer': 2,
    'drummers': 2,
    'drummey': 2,
    'drumming': 2,
    'drummond': 2,
    'drummonds': 2,
    'drumright': 2,
    'drums': 1,
    'drumstick': 2,
    'drumwright': 2,
    'drunk': 1,
    'drunkard': 2,
    'drunkards': 2,
    'drunken': 2,
    'drunkenness': 3,
    'drunks': 1,
    'drupe': 1,
    'drupes': 1,
    'drury': 2,
    'drusa': 2,
    'druse': 1,
    'drusie': 2,
    'drusilla': 3,
    'druthers': 2,
    'druze': 1,
    'dry': 1,
    'dryclean': 2,
    'drycleaner': 3,
    'drycleaning': 3,
    'dryden': 2,
    'drye': 1,
    'dryer': 2,
    'dryers': 2,
    'drying': 2,
    'dryly': 2,
    'dryness': 2,
    'drypers': 2,
    'drypoint': 2,
    'drysdale': 2,
    'dryser': 2,
    'drywall': 2,
    'drzewiecki': 3,
    'dsouza': 3,
    'dss': 3,
    'dsv': 3,
    'du': 1,
    'dual': 2,
    'dualism': 4,
    'dualisms': 4,
    'dualist': 3,
    'dualistic': 4,
    'duality': 4,
    'duals': 2,
    'duan': 1,
    'duana': 3,
    'duane': 1,
    'duarte': 2,
    'dub': 1,
    'duba': 2,
    'dubach': 2,
    'dubai': 2,
    'dubarry': 3,
    'dubas': 2,
    'dubay': 2,
    'dubbed': 1,
    'dubberly': 3,
    'dubbing': 2,
    'dubbs': 1,
    'dubcek': 2,
    'dube': 1,
    'dubeau': 2,
    'duberstein': 3,
    'dubey': 2,
    'dubhi': 2,
    'dubicki': 3,
    'dubie': 2,
    'dubiel': 2,
    'dubilier': 3,
    'dubin': 2,
    'dubinin': 3,
    'dubinsky': 3,
    'dubious': 3,
    'dubis': 2,
    'dublin': 2,
    'duboff': 2,
    'dubois': 2,
    'duboise': 2,
    'dubord': 2,
    'dubose': 2,
    'dubow': 2,
    'dubrawski': 3,
    'dubray': 2,
    'dubree': 2,
    'dubreuil': 2,
    'dubroc': 2,
    'dubroff': 2,
    'dubrovnik': 3,
    'dubrovniks': 3,
    'dubrovsky': 3,
    'dubrow': 2,
    'dubs': 1,
    'dubuc': 2,
    'dubuisson': 3,
    'dubuque': 2,
    'duby': 2,
    'duc': 1,
    'duca': 2,
    'ducal': 2,
    'duce': 1,
    'ducey': 2,
    'duch': 1,
    'duchaine': 2,
    'duchamp': 2,
    'ducharme': 2,
    'duchemin': 3,
    'duchene': 2,
    'duchenne': 2,
    'duchesneau': 3,
    'duchess': 2,
    'duchin': 2,
    'duchon': 2,
    'duchossois': 3,
    'duchow': 2,
    'duchy': 2,
    'duck': 1,
    'ducked': 1,
    'ducker': 2,
    'duckett': 2,
    'ducking': 2,
    'duckling': 2,
    'ducklings': 2,
    'ducks': 1,
    'ducksworth': 2,
    'duckwall': 2,
    'duckweed': 2,
    'duckworth': 2,
    'duclos': 2,
    'ducommun': 3,
    'ducote': 2,
    'duct': 1,
    'ductile': 2,
    'ductility': 4,
    'ductless': 2,
    'ducts': 1,
    'dud': 1,
    'duda': 2,
    'dudack': 2,
    'dudar': 2,
    'dudas': 2,
    'dudash': 2,
    'dudayev': 3,
    'dudd': 1,
    'dudding': 2,
    'duddy': 2,
    'dude': 1,
    'dudeck': 2,
    'dudek': 2,
    'duden': 2,
    'dudenhoeffer': 4,
    'dudes': 1,
    'dudgeon': 2,
    'dudik': 2,
    'dudley': 2,
    'dudman': 2,
    'dudney': 2,
    'duds': 1,
    'dudziak': 3,
    'dudzik': 2,
    'dudzinski': 3,
    'due': 1,
    'duecker': 2,
    'dueitt': 2,
    'dueker': 2,
    'duel': 2,
    'dueled': 2,
    'dueling': 2,
    'duelist': 3,
    'duell': 1,
    'duels': 2,
    'duena': 3,
    'duenas': 3,
    'duenez': 2,
    'duenow': 2,
    'duensing': 2,
    'duer': 2,
    'duerksen': 2,
    'duerr': 1,
    'duerson': 2,
    'duerst': 1,
    'dues': 1,
    'duesberg': 2,
    'duesing': 2,
    'duesler': 3,
    'duesseldorf': 3,
    'duet': 2,
    'duets': 2,
    'duey': 2,
    'dufault': 2,
    'dufek': 2,
    'duff': 1,
    'duffala': 3,
    'duffee': 2,
    'duffek': 2,
    'duffel': 2,
    'duffell': 2,
    'duffer': 2,
    'duffett': 2,
    'duffey': 2,
    'duffie': 2,
    'duffield': 2,
    'duffin': 2,
    'duffner': 2,
    'dufford': 2,
    'duffour': 2,
    'duffus': 2,
    'duffy': 2,
    'dufner': 2,
    'duford': 2,
    'dufort': 2,
    'dufour': 2,
    'dufrane': 2,
    'dufrene': 2,
    'dufresne': 2,
    'dufur': 2,
    'dug': 1,
    'dugal': 2,
    'dugald': 2,
    'dugan': 2,
    'dugar': 2,
    'dugas': 2,
    'dugdale': 2,
    'duggan': 2,
    'duggar': 2,
    'dugger': 2,
    'duggin': 2,
    'duggins': 2,
    'dugo': 2,
    'dugout': 2,
    'dugouts': 2,
    'duguay': 2,
    'duguid': 2,
    'duh': 1,
    'duhaime': 2,
    'duhamel': 3,
    'duhart': 2,
    'duhe': 1,
    'duhon': 2,
    'duhr': 1,
    'dui': 3,
    'duignan': 2,
    'duis': 2,
    'duisburg': 2,
    'duitsman': 2,
    'duk': 1,
    'dukakis': 3,
    'dukakises': 4,
    'dukart': 2,
    'duke': 1,
    'duked': 1,
    'dukedom': 2,
    'dukeman': 2,
    'duker': 2,
    'dukes': 1,
    'duking': 2,
    'dula': 2,
    'dulac': 2,
    'dulak': 2,
    'dulaney': 3,
    'dulany': 3,
    'dulay': 2,
    'dulce': 1,
    'dulcea': 3,
    'dulcet': 2,
    'dulciana': 3,
    'dulcibelle': 3,
    'dulcie': 2,
    'dulcimer': 3,
    'dulcine': 2,
    'dulcinea': 4,
    'dule': 1,
    'duley': 2,
    'dulin': 2,
    'duling': 2,
    'dull': 1,
    'dulle': 1,
    'dullea': 3,
    'dulled': 1,
    'duller': 2,
    'dulles': 2,
    'dullest': 2,
    'dulling': 2,
    'dullness': 2,
    'dulmage': 2,
    'dulong': 2,
    'dulski': 2,
    'dulude': 2,
    'duluth': 2,
    'dulwich': 2,
    'dulworth': 2,
    'duly': 2,
    'dum': 1,
    'duma': 2,
    'dumaine': 2,
    'dumais': 2,
    'duman': 2,
    'dumas': 2,
    'dumb': 1,
    'dumbbell': 2,
    'dumbbells': 2,
    'dumber': 2,
    'dumbest': 2,
    'dumbfound': 2,
    'dumbfounded': 3,
    'dumbfounding': 3,
    'dumbfounds': 2,
    'dumbing': 2,
    'dumbo': 2,
    'dumbstruck': 2,
    'dumenil': 3,
    'dumez': 2,
    'dumford': 2,
    'dumire': 3,
    'dumke': 2,
    'dumlao': 2,
    'dumler': 2,
    'dumm': 1,
    'dummer': 2,
    'dummies': 2,
    'dummitt': 2,
    'dummy': 2,
    'dumond': 2,
    'dumont': 2,
    'dumoulin': 3,
    'dump': 1,
    'dumped': 1,
    'dumper': 2,
    'dumping': 2,
    'dumpling': 2,
    'dumplings': 2,
    'dumps': 1,
    'dumpster': 2,
    'dumpsters': 2,
    'dumptruck': 2,
    'dumptrucks': 2,
    'dumpty': 2,
    'dumpy': 2,
    'dun': 1,
    'duna': 2,
    'dunagan': 3,
    'dunahoo': 3,
    'dunaj': 2,
    'dunavan': 3,
    'dunavant': 3,
    'dunaway': 3,
    'dunay': 2,
    'dunbar': 2,
    'dunblane': 2,
    'dunc': 1,
    'duncan': 2,
    'duncanson': 3,
    'dunckel': 2,
    'duncombe': 2,
    'dundas': 2,
    'dundee': 2,
    'dundon': 2,
    'dundore': 2,
    'dune': 1,
    'dunedin': 3,
    'dunegan': 3,
    'dunes': 1,
    'dunfee': 2,
    'dunford': 2,
    'dung': 1,
    'dungan': 2,
    'dungeon': 2,
    'dungeons': 2,
    'dungey': 2,
    'dunham': 2,
    'dunhill': 2,
    'dunigan': 3,
    'dunite': 2,
    'dunivan': 3,
    'dunja': 2,
    'dunk': 1,
    'dunked': 1,
    'dunkel': 2,
    'dunkelberg': 3,
    'dunkelberger': 4,
    'dunker': 2,
    'dunkerley': 3,
    'dunkin': 2,
    'dunkirk': 2,
    'dunkle': 2,
    'dunkleberger': 4,
    'dunklee': 2,
    'dunkley': 2,
    'dunklin': 2,
    'dunks': 1,
    'dunlaevy': 3,
    'dunlap': 2,
    'dunlavey': 3,
    'dunlavy': 3,
    'dunlay': 2,
    'dunleavy': 3,
    'dunlevy': 3,
    'dunley': 2,
    'dunlop': 2,
    'dunman': 2,
    'dunmire': 3,
    'dunn': 1,
    'dunnagan': 3,
    'dunnam': 2,
    'dunnavant': 3,
    'dunnaway': 3,
    'dunne': 1,
    'dunned': 1,
    'dunnell': 2,
    'dunnett': 2,
    'dunnigan': 3,
    'dunning': 2,
    'dunnington': 3,
    'dunno': 2,
    'dunphy': 2,
    'duns': 1,
    'dunshee': 2,
    'dunsmoor': 2,
    'dunsmore': 2,
    'dunson': 2,
    'dunst': 1,
    'dunstan': 2,
    'dunster': 2,
    'dunston': 2,
    'dunsworth': 2,
    'dunton': 2,
    'dunwoody': 3,
    'dunworth': 2,
    'duo': 2,
    'duodenal': 4,
    'duodenum': 4,
    'duong': 2,
    'duopoly': 4,
    'dupay': 2,
    'dupe': 1,
    'duped': 1,
    'dupee': 2,
    'duper': 2,
    'dupes': 1,
    'dupin': 2,
    'duplantis': 3,
    'duplechain': 3,
    'duplechin': 3,
    'dupler': 3,
    'duplessis': 3,
    'duplex': 2,
    'duplicate': 3,
    'duplicated': 4,
    'duplicates': 3,
    'duplicating': 4,
    'duplication': 4,
    'duplications': 4,
    'duplicative': 4,
    'duplicitous': 4,
    'duplicity': 4,
    'dupont': 2,
    'dupras': 2,
    'dupre': 2,
    'dupree': 2,
    'duprey': 2,
    'dupriest': 4,
    'dupuis': 2,
    'dupuy': 2,
    'duque': 1,
    'duquesne': 2,
    'duquette': 2,
    'dura': 2,
    'durability': 5,
    'durable': 3,
    'durables': 3,
    'duracell': 3,
    'durakon': 3,
    'durall': 2,
    'duramed': 3,
    'duran': 2,
    'durand': 2,
    'durando': 3,
    'durang': 2,
    'durango': 3,
    'durant': 2,
    'durante': 3,
    'duration': 3,
    'durations': 3,
    'duray': 2,
    'durazo': 3,
    'durban': 2,
    'durben': 2,
    'durbin': 2,
    'durch': 1,
    'durchholz': 2,
    'durden': 2,
    'durdin': 2,
    'duree': 2,
    'durell': 2,
    'duren': 2,
    'durenberger': 4,
    'durene': 2,
    'durer': 2,
    'duress': 2,
    'durette': 2,
    'durfee': 2,
    'durfey': 2,
    'durflinger': 4,
    'durgan': 2,
    'durgin': 2,
    'durham': 2,
    'duri': 2,
    'durick': 2,
    'durie': 2,
    'during': 2,
    'durio': 3,
    'duriron': 3,
    'duris': 2,
    'durkee': 2,
    'durkheim': 2,
    'durkin': 2,
    'durland': 2,
    'durley': 2,
    'durling': 2,
    'durn': 1,
    'durnell': 2,
    'durney': 2,
    'durnil': 2,
    'durnin': 2,
    'durning': 2,
    'duro': 2,
    'durocher': 3,
    'duron': 2,
    'duross': 2,
    'durr': 1,
    'durrah': 2,
    'durrance': 2,
    'durrant': 2,
    'durrell': 2,
    'durrence': 2,
    'durrett': 2,
    'durso': 2,
    'durst': 1,
    'durum': 2,
    'durward': 2,
    'durwin': 2,
    'dury': 2,
    'duryea': 3,
    'duryee': 2,
    'dusch': 1,
    'dusek': 2,
    'dusenberry': 4,
    'dusenbery': 4,
    'dusenbury': 4,
    'dusett': 2,
    'dush': 1,
    'dushane': 2,
    'dushyanth': 3,
    'dusing': 2,
    'dusk': 1,
    'duskin': 2,
    'dussault': 2,
    'dusseau': 2,
    'dusseault': 2,
    'dusseldorf': 3,
    'dust': 1,
    'dustbin': 2,
    'dusted': 2,
    'duster': 2,
    'dusters': 2,
    'dustin': 2,
    'dusting': 2,
    'dustman': 2,
    'duston': 2,
    'dusts': 1,
    'dusty': 2,
    'dusza': 2,
    'duszynski': 3,
    'dutch': 1,
    'dutcher': 2,
    'dutchman': 2,
    'duthie': 2,
    'duties': 2,
    'dutiful': 3,
    'dutifully': 4,
    'dutil': 2,
    'dutka': 2,
    'dutkiewicz': 3,
    'dutko': 2,
    'dutoit': 2,
    'dutra': 2,
    'dutro': 2,
    'dutrow': 2,
    'dutson': 2,
    'dutt': 1,
    'dutter': 2,
    'dutton': 2,
    'duty': 2,
    'duva': 2,
    'duval': 2,
    'duvalier': 3,
    'duvaliers': 3,
    'duvall': 2,
    'duve': 1,
    'duvernay': 3,
    'duvrees': 2,
    'duwayne': 2,
    'duwe': 1,
    'dux': 1,
    'duzan': 2,
    'dvd': 3,
    'dvoracek': 3,
    'dvorak': 2,
    'dvorsky': 2,
    'dwan': 1,
    'dwana': 2,
    'dwarf': 1,
    'dwarfed': 1,
    'dwarfing': 2,
    'dwarfism': 3,
    'dwarfs': 1,
    'dwarves': 1,
    'dwayne': 1,
    'dweck': 1,
    'dweeb': 1,
    'dwell': 1,
    'dwelle': 1,
    'dwelled': 1,
    'dweller': 2,
    'dwellers': 2,
    'dwelley': 2,
    'dwelling': 2,
    'dwellings': 2,
    'dwells': 1,
    'dwelt': 1,
    'dwi': 5,
    'dwiggins': 2,
    'dwight': 1,
    'dwindle': 2,
    'dwindled': 2,
    'dwindles': 2,
    'dwindling': 3,
    'dwinell': 2,
    'dwire': 1,
    'dwivedi': 3,
    'dworak': 2,
    'dworin': 2,
    'dworkin': 2,
    'dworsky': 2,
    'dwyer': 2,
    'dyad': 2,
    'dyal': 2,
    'dyan': 2,
    'dyana': 3,
    'dyane': 2,
    'dyansen': 3,
    'dyar': 2,
    'dyas': 2,
    'dyatron': 3,
    'dyazide': 3,
    'dybas': 2,
    'dyce': 1,
    'dyche': 1,
    'dyches': 2,
    'dyck': 1,
    'dyckman': 2,
    'dyco': 2,
    'dycus': 2,
    'dye': 1,
    'dyed': 1,
    'dyeing': 2,
    'dyer': 2,
    'dyes': 1,
    'dyess': 2,
    'dyestuff': 2,
    'dyestuffs': 2,
    'dygert': 2,
    'dying': 2,
    'dyk': 1,
    'dykas': 2,
    'dyke': 1,
    'dykeman': 2,
    'dykes': 1,
    'dykhouse': 2,
    'dykman': 2,
    'dykstra': 2,
    'dylan': 2,
    'dylans': 2,
    'dylewski': 3,
    'dylex': 2,
    'dyloptisaurus': 5,
    'dymally': 3,
    'dymek': 2,
    'dyment': 2,
    'dymond': 2,
    'dynafac': 3,
    'dynalectric': 4,
    'dynalectron': 4,
    'dynamic': 3,
    'dynamics': 3,
    'dynamism': 4,
    'dynamite': 3,
    'dynamo': 3,
    'dynamometer': 5,
    'dynapac': 3,
    'dynascan': 3,
    'dynastic': 3,
    'dynasties': 3,
    'dynasty': 3,
    'dynatech': 3,
    'dyncorp': 2,
    'dyneer': 2,
    'dynes': 1,
    'dysan': 2,
    'dysart': 2,
    'dysentery': 4,
    'dysert': 2,
    'dysfunction': 3,
    'dysfunctional': 4,
    'dysfunctions': 3,
    'dysinger': 3,
    'dyslexia': 4,
    'dyslexic': 3,
    'dyson': 2,
    'dysphoric': 3,
    'dysplasia': 3,
    'dystrophin': 3,
    'dystrophy': 3,
    'dzerzhinsk': 2,
    'dzhambul': 2,
    'dzhirkvelov': 4,
    'dzhokhar': 2,
    'dziak': 2,
    'dzialo': 3,
    'dziedzic': 2,
    'dziekan': 2,
    'dzik': 1,
    'dzikowski': 3,
    'dziuba': 3,
    'dziuk': 2,
    'dzokhar': 2,
    'e': 1,
    'each': 1,
    'eachan': 2,
    'eachus': 2,
    'eaddy': 2,
    'eade': 1,
    'eader': 2,
    'eades': 1,
    'eadie': 2,
    'eads': 1,
    'eady': 2,
    'eagan': 2,
    'eagar': 2,
    'eagen': 2,
    'eager': 2,
    'eagerly': 3,
    'eagerness': 3,
    'eagle': 2,
    'eagleburger': 4,
    'eagles': 2,
    'eagleson': 3,
    'eagleton': 3,
    'eagleye': 3,
    'eaglin': 2,
    'eagon': 2,
    'eaken': 2,
    'eaker': 2,
    'eakes': 1,
    'eakin': 2,
    'eakins': 2,
    'eakle': 2,
    'eales': 1,
    'ealey': 2,
    'ealing': 2,
    'ealy': 2,
    'eamer': 2,
    'eames': 1,
    'eamon': 2,
    'eanes': 1,
    'eap': 3,
    'eappen': 2,
    'ear': 1,
    'eardley': 2,
    'eared': 1,
    'earfull': 2,
    'eargle': 2,
    'earhart': 2,
    'earl': 1,
    'earldom': 2,
    'earle': 1,
    'earlene': 2,
    'earles': 1,
    'earless': 2,
    'earley': 2,
    'earlie': 2,
    'earlier': 3,
    'earliest': 3,
    'earline': 2,
    'earll': 1,
    'earls': 1,
    'early': 2,
    'earlywine': 3,
    'earmark': 2,
    'earmarked': 2,
    'earmarking': 3,
    'earmarks': 2,
    'earmuff': 2,
    'earmuffs': 2,
    'earn': 1,
    'earned': 1,
    'earner': 2,
    'earners': 2,
    'earnest': 2,
    'earnestly': 3,
    'earnestness': 3,
    'earney': 2,
    'earnhardt': 2,
    'earnhart': 2,
    'earnheart': 2,
    'earning': 2,
    'earnings': 2,
    'earns': 1,
    'earnshaw': 2,
    'earp': 1,
    'earphone': 2,
    'earphones': 2,
    'earpiece': 2,
    'earpieces': 3,
    'earplug': 2,
    'earplugs': 2,
    'earring': 2,
    'earrings': 2,
    'ears': 1,
    'earshot': 2,
    'earth': 1,
    'eartha': 2,
    'earthbound': 2,
    'earthen': 2,
    'earthenware': 3,
    'earthling': 2,
    'earthlings': 2,
    'earthly': 2,
    'earthmoving': 3,
    'earthquake': 2,
    'earthquakes': 2,
    'earths': 1,
    'earthshaking': 3,
    'earthshine': 2,
    'earthstar': 2,
    'earthwork': 2,
    'earthworm': 2,
    'earthworms': 2,
    'earthy': 2,
    'earvin': 2,
    'earwax': 2,
    'earwood': 2,
    'eary': 2,
    'easco': 2,
    'ease': 1,
    'eased': 1,
    'easel': 2,
    'easement': 2,
    'eases': 2,
    'eash': 1,
    'easib': 2,
    'easier': 3,
    'easiest': 3,
    'easily': 3,
    'easing': 2,
    'easler': 2,
    'easley': 2,
    'easom': 2,
    'eason': 2,
    'east': 1,
    'eastbound': 2,
    'eastburn': 2,
    'eastdil': 2,
    'eastend': 2,
    'eastender': 3,
    'eastenders': 3,
    'eastep': 2,
    'easter': 2,
    'easterbrook': 3,
    'easterday': 3,
    'easterlin': 3,
    'easterling': 3,
    'easterly': 3,
    'eastern': 2,
    'easterner': 3,
    'easterners': 3,
    'easterwood': 3,
    'eastes': 1,
    'eastgroup': 2,
    'eastham': 2,
    'eastin': 2,
    'eastland': 2,
    'eastlick': 2,
    'eastman': 2,
    'eastmet': 2,
    'easton': 2,
    'eastover': 3,
    'eastpac': 2,
    'eastridge': 2,
    'eastward': 2,
    'eastwick': 2,
    'eastwind': 2,
    'eastwood': 2,
    'easudes': 3,
    'easy': 2,
    'easygoing': 4,
    'eat': 1,
    'eatable': 3,
    'eaten': 2,
    'eater': 2,
    'eateries': 3,
    'eaters': 2,
    'eatery': 3,
    'eatherly': 3,
    'eatherton': 3,
    'eating': 2,
    'eatman': 2,
    'eatmon': 2,
    'eaton': 2,
    'eats': 1,
    'eau': 1,
    'eaux': 1,
    'eave': 1,
    'eavenson': 3,
    'eaves': 1,
    'eavesdrop': 2,
    'eavesdropping': 3,
    'eb': 1,
    'eba': 2,
    'eban': 2,
    'ebanks': 2,
    'ebano': 3,
    'ebarb': 2,
    'ebasco': 3,
    'ebaugh': 2,
    'ebb': 1,
    'ebba': 2,
    'ebbed': 2,
    'ebben': 2,
    'ebbers': 2,
    'ebbert': 2,
    'ebbing': 2,
    'ebbs': 1,
    'ebby': 2,
    'ebel': 2,
    'ebeling': 3,
    'eben': 2,
    'ebeneezer': 4,
    'ebenezer': 4,
    'eber': 2,
    'eberhard': 3,
    'eberhardt': 3,
    'eberhart': 3,
    'eberl': 2,
    'eberle': 3,
    'eberlein': 3,
    'eberlin': 3,
    'eberling': 3,
    'eberly': 3,
    'ebers': 2,
    'ebersol': 3,
    'ebersold': 3,
    'ebersole': 3,
    'eberstadt': 3,
    'ebert': 2,
    'eberts': 2,
    'eberwein': 3,
    'ebey': 2,
    'ebinger': 3,
    'eble': 2,
    'eblen': 3,
    'eblin': 2,
    'ebling': 3,
    'ebner': 2,
    'ebola': 3,
    'ebonics': 3,
    'ebony': 3,
    'ebright': 2,
    'ebro': 2,
    'ebron': 2,
    'ebullience': 3,
    'ebullient': 3,
    'eby': 2,
    'ecad': 2,
    'eccentric': 3,
    'eccentricities': 5,
    'eccentricity': 5,
    'eccentrics': 3,
    'eccles': 2,
    'ecclesiastic': 5,
    'ecclesiastical': 6,
    'eccleston': 3,
    'ecevit': 3,
    'echard': 2,
    'echavarria': 5,
    'echelon': 3,
    'echelons': 3,
    'echemende': 3,
    'echenberg': 3,
    'echevarria': 5,
    'echeverria': 5,
    'echidna': 3,
    'echinacea': 5,
    'echlin': 2,
    'echo': 2,
    'echoed': 2,
    'echoes': 2,
    'echograph': 3,
    'echography': 4,
    'echohawk': 3,
    'echoing': 3,
    'echolocation': 5,
    'echols': 2,
    'echos': 2,
    'eck': 1,
    'eckard': 2,
    'eckardt': 2,
    'eckart': 2,
    'eckberg': 2,
    'eckel': 2,
    'eckelberry': 4,
    'eckels': 2,
    'eckenfelder': 4,
    'eckenrod': 3,
    'eckenrode': 3,
    'eckenroth': 3,
    'ecker': 2,
    'eckerd': 2,
    'eckerle': 3,
    'eckerman': 3,
    'eckersley': 3,
    'eckerson': 3,
    'eckert': 2,
    'eckes': 1,
    'eckford': 2,
    'eckhard': 2,
    'eckhardt': 2,
    'eckhart': 2,
    'eckhoff': 2,
    'eckl': 2,
    'eckland': 2,
    'eckler': 2,
    'eckles': 2,
    'eckley': 2,
    'ecklund': 2,
    'eckman': 2,
    'eckmann': 2,
    'eckrich': 2,
    'eckrote': 2,
    'eckroth': 2,
    'eckstein': 2,
    'eckstrom': 2,
    'eclectic': 3,
    'eclipse': 2,
    'eclipsed': 2,
    'eclipses': 3,
    'eclipsing': 3,
    'ecliptic': 3,
    'eco': 2,
    'ecogen': 3,
    'ecolab': 3,
    'ecolaire': 3,
    'ecole': 2,
    'ecological': 5,
    'ecologically': 5,
    'ecologist': 4,
    'ecologists': 4,
    'ecology': 4,
    'econ': 2,
    'econocom': 4,
    'econoline': 4,
    'econometric': 5,
    'econometrics': 5,
    'economic': 4,
    'economical': 5,
    'economically': 5,
    'economico': 5,
    'economics': 4,
    'economies': 4,
    'economist': 4,
    'economists': 4,
    'economize': 4,
    'economizes': 5,
    'economizing': 5,
    'economos': 4,
    'economou': 4,
    'economy': 4,
    'econships': 3,
    'ecosystem': 4,
    'ecosystems': 4,
    'ecru': 2,
    'ecstasy': 3,
    'ecstatic': 3,
    'ecstatically': 4,
    'ecton': 2,
    'ector': 2,
    'ecuador': 3,
    'ecuadoran': 4,
    'ecuadorean': 5,
    'ecuadorian': 5,
    'ecumena': 4,
    'ecumenical': 5,
    'eczema': 3,
    'ed': 1,
    'eda': 2,
    'edam': 2,
    'edan': 2,
    'edana': 3,
    'edberg': 2,
    'edbert': 2,
    'eddie': 2,
    'eddies': 2,
    'eddinger': 3,
    'eddings': 2,
    'eddington': 3,
    'eddins': 2,
    'eddleman': 3,
    'eddlemon': 3,
    'edds': 1,
    'eddy': 2,
    'ede': 1,
    'edel': 2,
    'edelen': 3,
    'edeline': 3,
    'edell': 2,
    'edelman': 3,
    'edelmann': 3,
    'edelmar': 3,
    'edelson': 3,
    'edelstein': 3,
    'edelweiss': 3,
    'edema': 3,
    'eden': 2,
    'edenfield': 3,
    'edens': 2,
    'edenton': 3,
    'eder': 2,
    'ederer': 3,
    'edes': 1,
    'edgar': 2,
    'edgcomb': 2,
    'edge': 1,
    'edgecomb': 2,
    'edgecombe': 2,
    'edged': 1,
    'edgell': 2,
    'edgemon': 2,
    'edger': 2,
    'edgerly': 3,
    'edgerton': 3,
    'edges': 2,
    'edgett': 2,
    'edgeway': 2,
    'edgeways': 2,
    'edgewise': 2,
    'edgewood': 2,
    'edgeworth': 2,
    'edgin': 2,
    'edginess': 3,
    'edging': 2,
    'edgington': 3,
    'edgley': 2,
    'edgmon': 2,
    'edgren': 2,
    'edgy': 2,
    'edi': 2,
    'edible': 3,
    'edibles': 3,
    'edick': 2,
    'edict': 2,
    'edicts': 2,
    'edie': 2,
    'edification': 5,
    'edifice': 3,
    'edify': 3,
    'edifying': 4,
    'ediger': 3,
    'edin': 2,
    'edina': 3,
    'edinboro': 4,
    'edinburgh': 4,
    'edinger': 3,
    'edington': 3,
    'edison': 3,
    'edisons': 3,
    'edisto': 3,
    'edit': 2,
    'edited': 3,
    'edith': 2,
    'editha': 3,
    'edithe': 2,
    'editing': 3,
    'edition': 3,
    'editions': 3,
    'editor': 3,
    'editorial': 5,
    'editorialist': 6,
    'editorialists': 6,
    'editorialize': 6,
    'editorialized': 6,
    'editorializing': 7,
    'editorially': 6,
    'editorials': 5,
    'editors': 3,
    'editorship': 4,
    'edits': 2,
    'ediva': 3,
    'edival': 3,
    'edizione': 5,
    'edleman': 3,
    'edler': 2,
    'edley': 2,
    'edlin': 2,
    'edling': 2,
    'edlund': 2,
    'edlyn': 2,
    'edman': 2,
    'edmands': 2,
    'edmar': 2,
    'edmark': 2,
    'edminster': 3,
    'edmison': 3,
    'edmisten': 3,
    'edmister': 3,
    'edmiston': 3,
    'edmond': 2,
    'edmonda': 3,
    'edmonds': 2,
    'edmondson': 3,
    'edmonson': 3,
    'edmonston': 3,
    'edmonton': 3,
    'edmund': 2,
    'edmunda': 3,
    'edmundo': 3,
    'edmunds': 2,
    'edmundson': 3,
    'edna': 2,
    'edney': 2,
    'edo': 2,
    'edoardo': 3,
    'edolf': 2,
    'edouard': 3,
    'edper': 2,
    'edquist': 2,
    'edra': 2,
    'edrea': 3,
    'edric': 2,
    'edrington': 4,
    'edris': 2,
    'edsall': 2,
    'edsel': 2,
    'edson': 2,
    'edstrom': 2,
    'edu': 2,
    'eduard': 2,
    'eduardo': 3,
    'educate': 3,
    'educated': 4,
    'educates': 3,
    'educating': 4,
    'education': 4,
    'educational': 5,
    'educationally': 6,
    'educations': 4,
    'educator': 4,
    'educators': 4,
    'edwald': 2,
    'edward': 2,
    'edwardian': 4,
    'edwardine': 3,
    'edwards': 2,
    'edwardson': 3,
    'edwin': 2,
    'edwina': 3,
    'edye': 2,
    'edyth': 2,
    'edythe': 2,
    'edzard': 2,
    'ee': 1,
    'eeg': 1,
    'eel': 1,
    'eelam': 2,
    'eelgrass': 2,
    'eellike': 2,
    'eelpout': 2,
    'eelpouts': 2,
    'eels': 1,
    'eeo': 3,
    'eerie': 2,
    'eerily': 3,
    'efaw': 2,
    'efecto': 3,
    'efface': 2,
    'effacing': 3,
    'effect': 2,
    'effected': 3,
    'effecting': 3,
    'effective': 3,
    'effectively': 4,
    'effectiveness': 4,
    'effects': 2,
    'effectuate': 4,
    'effeminate': 4,
    'efferent': 3,
    'efferson': 3,
    'effertz': 2,
    'effervescent': 4,
    'effete': 2,
    'efficacious': 4,
    'efficacy': 4,
    'efficiencies': 4,
    'efficiency': 4,
    'efficient': 3,
    'efficiently': 4,
    'effie': 2,
    'effigy': 3,
    'effinger': 3,
    'effingham': 3,
    'effler': 2,
    'effluence': 3,
    'effluent': 3,
    'efflux': 2,
    'effort': 2,
    'effortless': 3,
    'effortlessly': 4,
    'efforts': 2,
    'effron': 2,
    'effrontery': 4,
    'effusive': 3,
    'effusively': 4,
    'effy': 2,
    'efird': 2,
    'efron': 2,
    'efta': 2,
    'egaldey': 3,
    'egalitarian': 6,
    'egalitarianism': 8,
    'egan': 2,
    'egbert': 2,
    'egberta': 3,
    'egbertina': 4,
    'egbertine': 3,
    'egberts': 2,
    'ege': 1,
    'egeland': 3,
    'egeler': 3,
    'egelhoff': 3,
    'egelston': 3,
    'egelton': 3,
    'eger': 2,
    'egerer': 3,
    'egert': 2,
    'egerton': 3,
    'egg': 1,
    'eggcup': 2,
    'eggcups': 2,
    'egge': 1,
    'eggebrecht': 3,
    'egged': 1,
    'eggemeyer': 4,
    'eggen': 2,
    'eggenberger': 4,
    'egger': 2,
    'eggers': 2,
    'eggert': 2,
    'egghead': 2,
    'eggheads': 2,
    'egging': 2,
    'eggleston': 3,
    'eggleton': 3,
    'eggnog': 2,
    'eggnogs': 2,
    'eggplant': 2,
    'eggplants': 2,
    'eggs': 1,
    'eggshape': 2,
    'eggshaped': 2,
    'eggshell': 2,
    'eggshells': 2,
    'eggum': 2,
    'egland': 2,
    'eglantine': 3,
    'egle': 2,
    'egler': 2,
    'egleston': 3,
    'egley': 2,
    'egli': 2,
    'eglin': 2,
    'eglise': 2,
    'egloff': 2,
    'egly': 2,
    'egner': 2,
    'egnew': 2,
    'egnor': 2,
    'ego': 2,
    'egocentric': 4,
    'egoism': 4,
    'egolf': 2,
    'egomaniac': 5,
    'egon': 2,
    'egos': 2,
    'egotism': 4,
    'egotist': 3,
    'egotistical': 5,
    'egregious': 3,
    'egregiously': 4,
    'egress': 2,
    'egret': 2,
    'egypt': 2,
    'egyptair': 3,
    'egyptian': 3,
    'egyptians': 3,
    'egyptology': 5,
    'eh': 1,
    'ehinger': 3,
    'ehle': 1,
    'ehlen': 2,
    'ehler': 2,
    'ehlers': 2,
    'ehlert': 2,
    'ehlinger': 3,
    'ehlke': 1,
    'ehlmann': 2,
    'ehly': 2,
    'ehman': 2,
    'ehmann': 2,
    'ehmen': 2,
    'ehmke': 2,
    'ehren': 2,
    'ehrenberg': 3,
    'ehrenfeld': 3,
    'ehrenhalt': 3,
    'ehrenkrantz': 3,
    'ehrenreich': 3,
    'ehresman': 3,
    'ehret': 2,
    'ehrhard': 2,
    'ehrhardt': 2,
    'ehrhart': 2,
    'ehrich': 2,
    'ehrig': 2,
    'ehrke': 1,
    'ehrler': 2,
    'ehrlich': 2,
    'ehrlichman': 3,
    'ehrman': 2,
    'ehrmann': 2,
    'ehrsam': 2,
    'ehud': 2,
    'eiben': 2,
    'eich': 1,
    'eichberg': 2,
    'eichel': 2,
    'eichelberger': 4,
    'eichen': 2,
    'eichenbaum': 3,
    'eichenberg': 3,
    'eichenberger': 4,
    'eichenlaub': 3,
    'eicher': 2,
    'eichholz': 2,
    'eichhorn': 2,
    'eichhorst': 2,
    'eichinger': 3,
    'eichler': 3,
    'eichman': 2,
    'eichmann': 2,
    'eichner': 2,
    'eicholtz': 2,
    'eichorn': 2,
    'eichorst': 2,
    'eichstadt': 2,
    'eichstaedt': 2,
    'eick': 1,
    'eickhoff': 2,
    'eickholt': 2,
    'eickmeyer': 3,
    'eid': 1,
    'eide': 1,
    'eidem': 2,
    'eiden': 2,
    'eidson': 2,
    'eiermann': 2,
    'eifert': 2,
    'eiffel': 2,
    'eifler': 3,
    'eigen': 2,
    'eigenvalue': 4,
    'eigenvalues': 4,
    'eighmey': 2,
    'eighmy': 2,
    'eight': 1,
    'eighteen': 2,
    'eighteens': 2,
    'eighteenth': 2,
    'eightfold': 2,
    'eighth': 1,
    'eighths': 1,
    'eighties': 2,
    'eightieth': 3,
    'eights': 1,
    'eighty': 2,
    'eigner': 2,
    'eiichi': 3,
    'eiji': 2,
    'eike': 1,
    'eikenberry': 4,
    'eilan': 2,
    'eiland': 2,
    'eileen': 2,
    'eiler': 2,
    'eilerman': 3,
    'eilers': 2,
    'eilert': 2,
    'eiley': 2,
    'eilts': 1,
    'eimer': 2,
    'eimers': 2,
    'ein': 1,
    'einar': 2,
    'einbender': 3,
    'eindhoven': 3,
    'einhorn': 2,
    'einon': 2,
    'einspahr': 2,
    'einstein': 2,
    'eir': 1,
    'eirena': 3,
    'eirich': 2,
    'eis': 1,
    'eisa': 2,
    'eisai': 2,
    'eisaman': 3,
    'eischeid': 2,
    'eischen': 2,
    'eischens': 2,
    'eisel': 2,
    'eisele': 2,
    'eiseman': 2,
    'eisemann': 2,
    'eisen': 2,
    'eisenach': 3,
    'eisenbach': 3,
    'eisenbarth': 3,
    'eisenbeis': 3,
    'eisenberg': 3,
    'eisenberger': 4,
    'eisenbraun': 3,
    'eisenhardt': 3,
    'eisenhart': 3,
    'eisenhauer': 4,
    'eisenhour': 3,
    'eisenhower': 4,
    'eisenhowers': 4,
    'eisenhut': 3,
    'eisenhuth': 3,
    'eisenman': 3,
    'eisenmann': 3,
    'eisenmenger': 4,
    'eisenstadt': 3,
    'eisenstein': 3,
    'eiserman': 3,
    'eisert': 2,
    'eishi': 2,
    'eisin': 2,
    'eisinger': 3,
    'eisler': 2,
    'eisman': 2,
    'eisner': 2,
    'eison': 2,
    'eissler': 3,
    'eiszner': 2,
    'eitel': 2,
    'either': 2,
    'eitzen': 2,
    'eizenstat': 3,
    'ejaculate': 4,
    'ejaculation': 5,
    'eject': 2,
    'ejected': 3,
    'ejecting': 3,
    'ejection': 3,
    'ejects': 2,
    'ejup': 2,
    'ek': 1,
    'eka': 2,
    'ekaterina': 5,
    'ekberg': 2,
    'ekblad': 2,
    'ekco': 2,
    'ekdahl': 2,
    'eke': 1,
    'eked': 1,
    'ekern': 2,
    'ekeus': 2,
    'ekholm': 2,
    'eking': 2,
    'ekins': 2,
    'ekk': 3,
    'ekkehard': 2,
    'eklund': 2,
    'ekman': 2,
    'eko': 2,
    'ekofisk': 3,
    'eksportfinans': 4,
    'ekstrand': 2,
    'ekstrom': 2,
    'el': 1,
    'ela': 2,
    'elaborate': 3,
    'elaborated': 5,
    'elaborately': 4,
    'elaborates': 4,
    'elaborating': 5,
    'elaboration': 5,
    'elaina': 3,
    'elaine': 2,
    'elaire': 2,
    'elam': 2,
    'elamin': 3,
    'elan': 2,
    'eland': 2,
    'elane': 2,
    'elapse': 2,
    'elapsed': 2,
    'elardo': 3,
    'elastic': 3,
    'elasticity': 5,
    'elastomer': 4,
    'elastomers': 4,
    'elat': 2,
    'elata': 3,
    'elate': 2,
    'elated': 3,
    'elater': 3,
    'elaters': 3,
    'elates': 2,
    'elating': 3,
    'elation': 3,
    'elayne': 2,
    'elbaum': 2,
    'elbe': 1,
    'elber': 2,
    'elberson': 3,
    'elbert': 2,
    'elberta': 3,
    'elbertine': 3,
    'elbow': 2,
    'elbowed': 2,
    'elbowing': 3,
    'elbowroom': 3,
    'elbows': 2,
    'elbrick': 2,
    'elbrus': 2,
    'elchibey': 3,
    'elco': 2,
    'elcock': 2,
    'elcor': 2,
    'elcotel': 3,
    'eldar': 2,
    'elden': 2,
    'elder': 2,
    'elderkin': 3,
    'elderly': 3,
    'elders': 2,
    'eldest': 2,
    'eldin': 2,
    'eldon': 2,
    'eldora': 3,
    'eldorado': 4,
    'eldred': 2,
    'eldredge': 2,
    'eldreth': 2,
    'eldric': 2,
    'eldrida': 3,
    'eldridge': 2,
    'eldritch': 2,
    'eldwin': 2,
    'eleanor': 3,
    'eleanora': 4,
    'eleanore': 3,
    'eleazer': 3,
    'elect': 2,
    'electability': 6,
    'electable': 4,
    'elected': 3,
    'electing': 3,
    'election': 3,
    'electioneer': 4,
    'electioneering': 5,
    'electioneers': 4,
    'elections': 3,
    'elective': 3,
    'electives': 3,
    'electoral': 4,
    'electorate': 4,
    'electorates': 4,
    'electors': 3,
    'electra': 3,
    'electric': 3,
    'electrical': 4,
    'electrically': 5,
    'electricals': 4,
    'electricar': 4,
    'electrician': 4,
    'electricians': 4,
    'electricite': 5,
    'electricity': 5,
    'electrics': 3,
    'electrification': 6,
    'electrified': 4,
    'electrifies': 4,
    'electrify': 4,
    'electrifying': 5,
    'electrique': 3,
    'electro': 3,
    'electrobiology': 7,
    'electrocardiogram': 7,
    'electrocardiograms': 7,
    'electrochemical': 6,
    'electrocom': 4,
    'electrocute': 4,
    'electrocuted': 5,
    'electrocution': 5,
    'electrocutions': 5,
    'electrode': 3,
    'electrodes': 3,
    'electrodynamic': 6,
    'electrodynamics': 6,
    'electrolux': 4,
    'electrolysis': 5,
    'electrolytic': 5,
    'electromagnet': 5,
    'electromagnetic': 6,
    'electromagnetism': 7,
    'electromagnets': 5,
    'electromechanical': 7,
    'electromedics': 5,
    'electron': 3,
    'electronic': 4,
    'electronically': 6,
    'electronics': 4,
    'electrons': 3,
    'electrophoresis': 6,
    'electrophoretogram': 7,
    'electroplate': 4,
    'electroplating': 5,
    'electroshock': 4,
    'electrosound': 4,
    'electrospace': 4,
    'electrospray': 4,
    'electrostatic': 5,
    'elects': 2,
    'eledge': 2,
    'eleen': 2,
    'elefante': 4,
    'elegance': 3,
    'elegant': 3,
    'elegantly': 4,
    'elegy': 3,
    'elek': 2,
    'elektra': 3,
    'elektrisk': 3,
    'elektrizitaetswerk': 6,
    'elektron': 3,
    'element': 3,
    'elemental': 4,
    'elementary': 4,
    'elements': 3,
    'elena': 3,
    'elenbaas': 3,
    'elene': 2,
    'elenore': 3,
    'eleonore': 5,
    'elephant': 3,
    'elephantiasis': 6,
    'elephantine': 4,
    'elephants': 3,
    'eletr': 3,
    'eleuthera': 4,
    'elevate': 3,
    'elevated': 4,
    'elevates': 3,
    'elevating': 4,
    'elevation': 4,
    'elevations': 4,
    'elevator': 4,
    'elevators': 4,
    'eleven': 3,
    'elevens': 3,
    'eleventh': 3,
    'elexis': 3,
    'eley': 2,
    'elf': 1,
    'elfers': 2,
    'elfie': 2,
    'elfin': 2,
    'elfish': 2,
    'elfman': 2,
    'elford': 2,
    'elfreda': 3,
    'elfrida': 3,
    'elfrieda': 3,
    'elfrink': 2,
    'elfstrom': 2,
    'elg': 1,
    'elga': 2,
    'elgabrowny': 4,
    'elgar': 2,
    'elgart': 2,
    'elger': 2,
    'elgersma': 3,
    'elgie': 2,
    'elgin': 2,
    'eli': 2,
    'elia': 3,
    'eliades': 3,
    'elias': 3,
    'eliason': 4,
    'eliassen': 4,
    'elich': 2,
    'elicit': 3,
    'elicited': 4,
    'eliciting': 4,
    'elicits': 3,
    'elick': 2,
    'elicker': 3,
    'elie': 2,
    'eligaya': 4,
    'eligibility': 6,
    'eligible': 4,
    'elihu': 3,
    'elijah': 3,
    'eliminate': 4,
    'eliminated': 5,
    'eliminates': 4,
    'eliminating': 5,
    'elimination': 5,
    'eliminations': 5,
    'eline': 2,
    'elinor': 3,
    'elinore': 4,
    'elio': 3,
    'eliopoulos': 5,
    'eliot': 3,
    'eliott': 3,
    'elisa': 3,
    'elisabeth': 4,
    'elise': 2,
    'elish': 2,
    'elisha': 3,
    'elison': 3,
    'elissa': 3,
    'elite': 2,
    'elites': 2,
    'elitism': 4,
    'elitist': 3,
    'elitists': 3,
    'elixir': 3,
    'eliza': 3,
    'elizabeth': 4,
    'elizabethan': 5,
    'elizabethtown': 5,
    'elizalde': 4,
    'elizondo': 4,
    'eljer': 2,
    'elk': 1,
    'elkes': 1,
    'elkhart': 2,
    'elkhorn': 2,
    'elkin': 2,
    'elkind': 2,
    'elkington': 3,
    'elkins': 2,
    'elko': 2,
    'elks': 1,
    'elkton': 2,
    'ell': 1,
    'ella': 2,
    'ellamay': 3,
    'ellan': 2,
    'ellard': 2,
    'elle': 1,
    'elledge': 2,
    'ellefson': 3,
    'ellegood': 3,
    'ellemann': 3,
    'ellen': 2,
    'ellena': 3,
    'ellenbecker': 4,
    'ellenberg': 3,
    'ellenberger': 4,
    'ellenbogen': 4,
    'ellenburg': 3,
    'ellender': 3,
    'ellene': 2,
    'ellenpore': 3,
    'ellenson': 3,
    'ellenwood': 3,
    'eller': 2,
    'ellerbe': 2,
    'ellerbee': 3,
    'ellerbrock': 3,
    'ellerby': 3,
    'ellerey': 3,
    'ellerman': 3,
    'ellers': 2,
    'ellert': 2,
    'ellertson': 3,
    'ellery': 3,
    'ellesmere': 2,
    'ellesse': 2,
    'ellestad': 3,
    'ellett': 2,
    'ellette': 2,
    'elley': 2,
    'ellice': 2,
    'ellicott': 3,
    'ellie': 2,
    'elliff': 2,
    'ellijay': 3,
    'elliman': 3,
    'elling': 2,
    'ellinger': 3,
    'ellingsen': 3,
    'ellingson': 3,
    'ellingsworth': 3,
    'ellington': 3,
    'ellingwood': 3,
    'ellinwood': 3,
    'ellios': 2,
    'elliot': 3,
    'elliott': 3,
    'ellipse': 2,
    'ellipsoid': 3,
    'ellipsoids': 3,
    'elliptical': 4,
    'ellis': 2,
    'ellison': 3,
    'ellisor': 3,
    'elliston': 3,
    'ellithorpe': 3,
    'ellman': 2,
    'ellmann': 2,
    'ellner': 2,
    'ellroy': 2,
    'ells': 1,
    'ellsberg': 2,
    'ellsburg': 2,
    'ellsworth': 2,
    'ellwanger': 3,
    'ellwood': 2,
    'elly': 2,
    'ellyn': 2,
    'ellyson': 3,
    'ellzey': 2,
    'elm': 1,
    'elma': 2,
    'elman': 2,
    'elmendorf': 3,
    'elmer': 2,
    'elmes': 1,
    'elmhurst': 2,
    'elmira': 3,
    'elmo': 2,
    'elmootazbell': 4,
    'elmootazbellah': 5,
    'elmore': 2,
    'elmquist': 2,
    'elms': 1,
    'elmsford': 2,
    'elmwood': 2,
    'elna': 2,
    'elnora': 3,
    'elnore': 2,
    'elnozahy': 4,
    'elocution': 4,
    'elocutions': 4,
    'eloisa': 3,
    'eloise': 2,
    'elommal': 3,
    'elongate': 3,
    'elongated': 4,
    'elongation': 4,
    'elope': 2,
    'elopes': 2,
    'eloquence': 3,
    'eloquent': 3,
    'eloquently': 4,
    'elouise': 3,
    'eloy': 2,
    'elpers': 2,
    'elrica': 3,
    'elrich': 2,
    'elrick': 2,
    'elrod': 2,
    'elron': 2,
    'elroy': 2,
    'els': 1,
    'elsa': 2,
    'elsas': 2,
    'elsasser': 3,
    'elsberry': 3,
    'elsbury': 3,
    'elsdon': 2,
    'else': 1,
    'elsea': 3,
    'elsen': 2,
    'elser': 2,
    'elses': 2,
    'elsesser': 3,
    'elsevier': 3,
    'elsewhere': 2,
    'elsey': 2,
    'elsie': 2,
    'elsinore': 3,
    'elsner': 2,
    'elson': 2,
    'elstad': 2,
    'elster': 2,
    'elston': 2,
    'elswick': 2,
    'elsworth': 2,
    'elting': 2,
    'elton': 2,
    'eltringham': 3,
    'eltzroth': 2,
    'elucidate': 4,
    'elucidated': 5,
    'elucidative': 5,
    'elude': 2,
    'eluded': 3,
    'eludes': 2,
    'eluding': 3,
    'elusive': 3,
    'elusiveness': 4,
    'elva': 2,
    'elvera': 3,
    'elvers': 2,
    'elves': 1,
    'elvgren': 2,
    'elvia': 3,
    'elvie': 2,
    'elvin': 2,
    'elvina': 3,
    'elving': 2,
    'elvington': 3,
    'elvira': 3,
    'elvire': 2,
    'elvis': 2,
    'elvy': 2,
    'elway': 2,
    'elwell': 2,
    'elwin': 2,
    'elwood': 2,
    'elxsi': 2,
    'ely': 2,
    'elyn': 2,
    'elyria': 4,
    'elyse': 2,
    'elysee': 3,
    'elysees': 3,
    'elysia': 4,
    'elysium': 4,
    'elza': 2,
    'elzey': 2,
    'elzinga': 3,
    'elzy': 2,
    'em': 1,
    'ema': 2,
    'emaciate': 4,
    'emaciated': 5,
    'emaciates': 4,
    'emaciating': 5,
    'emad': 2,
    'email': 2,
    'emailed': 2,
    'emailing': 3,
    'emails': 2,
    'emanate': 3,
    'emanated': 4,
    'emanates': 3,
    'emanating': 4,
    'emanation': 4,
    'emanations': 4,
    'emancipate': 4,
    'emancipated': 5,
    'emancipates': 4,
    'emancipating': 5,
    'emancipation': 5,
    'emancipations': 5,
    'emanuel': 4,
    'emanuele': 5,
    'emanuelson': 4,
    'emard': 2,
    'emasculate': 4,
    'emasculated': 5,
    'embalm': 2,
    'embalmed': 2,
    'embalming': 3,
    'embankment': 3,
    'embankments': 3,
    'embarcadero': 5,
    'embargo': 3,
    'embargoed': 3,
    'embargoes': 3,
    'embark': 2,
    'embarkation': 4,
    'embarked': 2,
    'embarking': 3,
    'embarks': 2,
    'embarrass': 3,
    'embarrassed': 3,
    'embarrasses': 4,
    'embarrassing': 4,
    'embarrassingly': 5,
    'embarrassment': 4,
    'embarrassments': 4,
    'embassies': 3,
    'embassy': 3,
    'embattle': 3,
    'embattled': 3,
    'embayment': 3,
    'embed': 2,
    'embedded': 3,
    'embellish': 3,
    'embellished': 3,
    'embellishes': 4,
    'embellishing': 4,
    'embellishment': 4,
    'ember': 2,
    'embers': 2,
    'emberson': 3,
    'emberton': 3,
    'embezzle': 3,
    'embezzled': 3,
    'embezzlement': 4,
    'embezzler': 4,
    'embezzlers': 4,
    'embezzles': 3,
    'embezzling': 4,
    'embitter': 3,
    'embittered': 3,
    'emblazon': 3,
    'emblazoned': 3,
    'emblem': 2,
    'emblematic': 4,
    'emblems': 2,
    'embler': 2,
    'embleton': 3,
    'embodied': 3,
    'embodies': 3,
    'embodiment': 4,
    'embody': 3,
    'embodying': 4,
    'embolden': 3,
    'emboldened': 3,
    'embolism': 4,
    'embolisms': 4,
    'emboss': 2,
    'embossed': 2,
    'embrace': 2,
    'embraceable': 4,
    'embraced': 2,
    'embraces': 3,
    'embracing': 3,
    'embree': 2,
    'embrey': 2,
    'embroider': 3,
    'embroidered': 3,
    'embroiderer': 4,
    'embroiderers': 4,
    'embroideries': 4,
    'embroidering': 4,
    'embroidery': 4,
    'embroil': 2,
    'embroiled': 2,
    'embrose': 2,
    'embry': 2,
    'embryo': 3,
    'embryology': 5,
    'embryonic': 4,
    'embryos': 3,
    'embury': 3,
    'emcee': 2,
    'emch': 1,
    'emco': 2,
    'emde': 1,
    'emdr': 4,
    'emel': 2,
    'emelda': 3,
    'emelie': 3,
    'emelina': 4,
    'emeline': 3,
    'emelita': 4,
    'emelyne': 3,
    'emens': 2,
    'emera': 3,
    'emerald': 2,
    'emeralds': 2,
    'emerant': 3,
    'emeraude': 3,
    'emerge': 2,
    'emerged': 2,
    'emergence': 3,
    'emergencies': 4,
    'emergency': 4,
    'emergent': 3,
    'emerges': 3,
    'emerging': 3,
    'emerich': 3,
    'emerick': 3,
    'emerine': 3,
    'emeritus': 4,
    'emerse': 2,
    'emersed': 2,
    'emerson': 3,
    'emert': 2,
    'emerton': 3,
    'emery': 3,
    'emeryville': 4,
    'emetic': 3,
    'emfinger': 3,
    'emge': 1,
    'emhart': 2,
    'emick': 2,
    'emig': 2,
    'emigh': 2,
    'emigrant': 3,
    'emigrants': 3,
    'emigrate': 3,
    'emigrated': 4,
    'emigrates': 3,
    'emigrating': 4,
    'emigration': 4,
    'emigrations': 4,
    'emigre': 3,
    'emigres': 3,
    'emil': 2,
    'emile': 2,
    'emilia': 4,
    'emilie': 3,
    'emilio': 4,
    'emilo': 3,
    'emily': 3,
    'emina': 3,
    'eminase': 4,
    'eminence': 3,
    'eminences': 4,
    'eminent': 3,
    'eminently': 4,
    'emir': 2,
    'emirate': 3,
    'emirates': 3,
    'emison': 3,
    'emissaries': 4,
    'emissary': 4,
    'emission': 3,
    'emissions': 3,
    'emit': 2,
    'emits': 2,
    'emitted': 3,
    'emitter': 3,
    'emitting': 3,
    'emler': 2,
    'emley': 2,
    'emling': 2,
    'emlyn': 2,
    'emlynne': 2,
    'emma': 2,
    'emmaline': 4,
    'emmanuel': 4,
    'emmaus': 2,
    'emme': 1,
    'emmel': 2,
    'emmeline': 3,
    'emmendorfer': 4,
    'emmer': 2,
    'emmerich': 3,
    'emmerling': 3,
    'emmerson': 3,
    'emmert': 2,
    'emmery': 3,
    'emmet': 2,
    'emmett': 2,
    'emmi': 2,
    'emmick': 2,
    'emmie': 2,
    'emminger': 3,
    'emmis': 2,
    'emmit': 2,
    'emmitt': 2,
    'emmons': 2,
    'emmott': 2,
    'emmy': 2,
    'emmys': 2,
    'emogene': 3,
    'emond': 2,
    'emory': 3,
    'emoticon': 4,
    'emotion': 3,
    'emotional': 4,
    'emotionalism': 6,
    'emotionally': 4,
    'emotions': 3,
    'emotive': 3,
    'empanel': 3,
    'empaneled': 3,
    'empaneling': 4,
    'empath': 2,
    'empathetic': 4,
    'empathize': 3,
    'empathy': 3,
    'emperor': 3,
    'emperors': 3,
    'empey': 2,
    'emphases': 3,
    'emphasis': 3,
    'emphasize': 3,
    'emphasized': 3,
    'emphasizes': 4,
    'emphasizing': 4,
    'emphatic': 3,
    'emphatically': 4,
    'emphysema': 4,
    'empie': 2,
    'empire': 3,
    'empires': 3,
    'empirical': 4,
    'empirically': 5,
    'empiricism': 5,
    'empiricist': 4,
    'emplacement': 3,
    'emplacements': 3,
    'employ': 2,
    'employable': 4,
    'employed': 2,
    'employee': 3,
    'employees': 3,
    'employer': 3,
    'employers': 3,
    'employing': 3,
    'employment': 3,
    'employments': 3,
    'employs': 2,
    'emporia': 4,
    'emporium': 4,
    'empower': 3,
    'empowered': 3,
    'empowering': 4,
    'empowerment': 4,
    'empowers': 3,
    'empresa': 3,
    'empresas': 3,
    'empress': 2,
    'emprise': 2,
    'empson': 2,
    'empt': 1,
    'empted': 2,
    'emptied': 2,
    'emptier': 3,
    'empties': 2,
    'emptiness': 3,
    'empting': 2,
    'emption': 2,
    'emptive': 2,
    'emptor': 2,
    'empts': 1,
    'empty': 2,
    'emptying': 3,
    'emrich': 2,
    'emrick': 2,
    'emry': 2,
    'emslie': 3,
    'emswiler': 3,
    'emu': 2,
    'emuil': 3,
    'emulate': 3,
    'emulated': 4,
    'emulates': 3,
    'emulating': 4,
    'emulation': 4,
    'emulex': 3,
    'emulsifier': 5,
    'emulsion': 3,
    'en': 1,
    'ena': 2,
    'enable': 3,
    'enabled': 3,
    'enabler': 4,
    'enables': 3,
    'enabling': 4,
    'enact': 2,
    'enacted': 3,
    'enacting': 3,
    'enactment': 3,
    'enactments': 3,
    'enacts': 2,
    'enamel': 3,
    'enameled': 3,
    'enamels': 3,
    'enamor': 3,
    'enamored': 3,
    'enamoring': 4,
    'enamors': 3,
    'enasa': 3,
    'enberg': 2,
    'encamp': 2,
    'encamped': 2,
    'encampment': 3,
    'encampments': 3,
    'encapsulate': 4,
    'encapsulated': 5,
    'encapsulating': 5,
    'encarnacion': 5,
    'encarta': 3,
    'encase': 2,
    'encased': 2,
    'encata': 3,
    'encephalitis': 5,
    'encephalopathy': 6,
    'enchant': 2,
    'enchanted': 3,
    'enchanter': 3,
    'enchanters': 3,
    'enchanting': 3,
    'enchantment': 3,
    'enchilada': 4,
    'encinas': 3,
    'encinias': 4,
    'encino': 3,
    'encircle': 3,
    'encircled': 3,
    'encirclement': 4,
    'encircling': 4,
    'enciso': 3,
    'enck': 1,
    'enclave': 2,
    'enclaves': 2,
    'enclose': 2,
    'enclosed': 2,
    'enclosing': 3,
    'enclosure': 3,
    'enclosures': 3,
    'encode': 2,
    'encoded': 3,
    'encoding': 3,
    'encompass': 3,
    'encompassed': 3,
    'encompasses': 4,
    'encompassing': 4,
    'encor': 2,
    'encore': 2,
    'encores': 2,
    'encounter': 3,
    'encountered': 3,
    'encountering': 4,
    'encounters': 3,
    'encourage': 3,
    'encouraged': 3,
    'encouragement': 4,
    'encourages': 4,
    'encouraging': 4,
    'encroach': 2,
    'encroached': 2,
    'encroaches': 3,
    'encroaching': 3,
    'encroachment': 3,
    'encroachments': 3,
    'encrust': 2,
    'encrusted': 3,
    'encrusting': 3,
    'encrypt': 2,
    'encrypted': 3,
    'encryption': 3,
    'encumber': 3,
    'encumbered': 3,
    'encyclical': 4,
    'encyclicals': 4,
    'encyclopaedia': 6,
    'encyclopedia': 6,
    'encyclopedias': 6,
    'encyclopedic': 5,
    'encyclopedist': 5,
    'end': 1,
    'endaka': 3,
    'endanger': 3,
    'endangered': 3,
    'endangering': 4,
    'endangerment': 4,
    'endangers': 3,
    'endara': 3,
    'ende': 1,
    'endear': 2,
    'endeared': 2,
    'endearing': 3,
    'endearment': 3,
    'endeavor': 3,
    'endeavored': 3,
    'endeavoring': 4,
    'endeavors': 3,
    'endeavour': 3,
    'ended': 2,
    'endelman': 3,
    'endemic': 3,
    'ender': 2,
    'enderby': 3,
    'enderle': 3,
    'enderlin': 3,
    'enders': 2,
    'enderson': 3,
    'endevco': 3,
    'endgame': 2,
    'endgames': 2,
    'endicott': 3,
    'ending': 2,
    'endings': 2,
    'endive': 2,
    'endler': 2,
    'endless': 2,
    'endlessly': 3,
    'endlich': 2,
    'endo': 2,
    'endocrine': 3,
    'endocrinologist': 6,
    'endocrinologists': 6,
    'endocrinology': 6,
    'endodermal': 4,
    'endometrial': 5,
    'endometriosis': 6,
    'endorphin': 3,
    'endorphins': 3,
    'endorse': 2,
    'endorsed': 2,
    'endorsement': 3,
    'endorsements': 3,
    'endorser': 3,
    'endorsers': 3,
    'endorses': 3,
    'endorsing': 3,
    'endoscopic': 4,
    'endosperm': 3,
    'endothermic': 4,
    'endotronics': 4,
    'endow': 2,
    'endowed': 2,
    'endowing': 3,
    'endowment': 3,
    'endowments': 3,
    'endows': 2,
    'endpoint': 2,
    'endpoints': 2,
    'endproduct': 3,
    'endproducts': 3,
    'endres': 2,
    'endress': 2,
    'endrizzi': 3,
    'ends': 1,
    'endsley': 2,
    'endtimer': 3,
    'endtimers': 3,
    'endued': 2,
    'endurance': 3,
    'endure': 2,
    'endured': 2,
    'endures': 2,
    'enduring': 3,
    'enduser': 3,
    'endusers': 3,
    'endy': 2,
    'enea': 3,
    'enema': 3,
    'enemas': 3,
    'enemies': 3,
    'enemy': 3,
    'energas': 3,
    'energen': 3,
    'energetic': 4,
    'energetically': 5,
    'energies': 3,
    'energize': 3,
    'energized': 3,
    'energizer': 4,
    'energizes': 4,
    'energizing': 4,
    'energy': 3,
    'enerson': 3,
    'enex': 2,
    'enfant': 2,
    'enfeeble': 3,
    'enfeebled': 3,
    'enfield': 2,
    'enfinger': 3,
    'enfold': 2,
    'enfor': 2,
    'enforce': 2,
    'enforceability': 6,
    'enforceable': 4,
    'enforced': 2,
    'enforcement': 3,
    'enforcements': 3,
    'enforcer': 3,
    'enforcers': 3,
    'enforces': 3,
    'enforcing': 3,
    'enfranchise': 3,
    'enfranchised': 3,
    'enfranchises': 4,
    'eng': 1,
    'engage': 2,
    'engaged': 2,
    'engagement': 3,
    'engagements': 3,
    'engages': 3,
    'engaging': 3,
    'engberg': 2,
    'engdahl': 2,
    'enge': 1,
    'engebretsen': 4,
    'engebretson': 4,
    'engel': 2,
    'engelberg': 3,
    'engelbert': 3,
    'engelberta': 4,
    'engelbrecht': 3,
    'engeleiter': 4,
    'engelhard': 3,
    'engelhardt': 3,
    'engelhart': 3,
    'engelke': 2,
    'engelken': 3,
    'engelking': 3,
    'engelman': 3,
    'engelmann': 3,
    'engels': 2,
    'engelson': 3,
    'engelstad': 3,
    'engeman': 2,
    'engen': 2,
    'engender': 3,
    'engendered': 3,
    'engenders': 3,
    'enger': 2,
    'engert': 2,
    'engesser': 3,
    'engh': 1,
    'engholm': 2,
    'engine': 2,
    'engined': 2,
    'engineer': 3,
    'engineered': 3,
    'engineering': 4,
    'engineers': 3,
    'engines': 2,
    'england': 2,
    'englander': 3,
    'englanders': 3,
    'englands': 2,
    'englbred': 3,
    'engle': 2,
    'englebert': 3,
    'englehardt': 3,
    'englehart': 3,
    'engleman': 3,
    'engler': 3,
    'englert': 2,
    'englerth': 2,
    'engles': 2,
    'englewood': 3,
    'english': 2,
    'englishman': 3,
    'englishmen': 3,
    'englishwoman': 4,
    'englund': 2,
    'engman': 2,
    'engquist': 2,
    'engram': 2,
    'engrave': 2,
    'engraved': 2,
    'engraver': 3,
    'engraving': 3,
    'engravings': 3,
    'engross': 2,
    'engrossed': 2,
    'engrossing': 3,
    'engrossment': 3,
    'engstrand': 2,
    'engstrom': 2,
    'engulf': 2,
    'engulfed': 2,
    'engulfing': 3,
    'engwall': 2,
    'enhance': 2,
    'enhanced': 2,
    'enhancement': 3,
    'enhancements': 3,
    'enhancer': 3,
    'enhances': 3,
    'enhancing': 3,
    'enholm': 2,
    'enholme': 2,
    'eniac': 3,
    'enichem': 3,
    'enid': 2,
    'enigma': 3,
    'enigmatic': 4,
    'enimont': 3,
    'enis': 2,
    'enitt': 2,
    'enix': 2,
    'enjoin': 2,
    'enjoined': 2,
    'enjoining': 3,
    'enjoy': 2,
    'enjoyable': 4,
    'enjoyably': 4,
    'enjoyed': 2,
    'enjoying': 3,
    'enjoyment': 3,
    'enjoyments': 3,
    'enjoys': 2,
    'enke': 1,
    'enlarge': 2,
    'enlarged': 2,
    'enlargement': 3,
    'enlargements': 3,
    'enlarger': 3,
    'enlarges': 3,
    'enlarging': 3,
    'enlighten': 3,
    'enlightened': 3,
    'enlightening': 4,
    'enlightenment': 4,
    'enlightens': 3,
    'enlist': 2,
    'enlisted': 3,
    'enlistee': 3,
    'enlistees': 3,
    'enlisting': 3,
    'enlistment': 3,
    'enlists': 2,
    'enliven': 3,
    'enlivened': 3,
    'enlivening': 4,
    'enlivens': 3,
    'enlo': 2,
    'enloe': 2,
    'enlow': 2,
    'enman': 2,
    'enmesh': 2,
    'enmeshed': 2,
    'enmeshes': 3,
    'enmeshing': 3,
    'enmities': 3,
    'enmity': 3,
    'ennea': 3,
    'enneking': 3,
    'ennen': 2,
    'ennes': 1,
    'ennis': 2,
    'ennoble': 3,
    'ennobled': 3,
    'ennobles': 3,
    'ennobling': 3,
    'ennosuke': 3,
    'enns': 1,
    'ennui': 3,
    'eno': 2,
    'enoch': 2,
    'enochs': 2,
    'enojada': 4,
    'enola': 3,
    'enomoto': 4,
    'enormity': 4,
    'enormous': 3,
    'enormously': 4,
    'enos': 2,
    'enough': 2,
    'enqueso': 3,
    'enquire': 3,
    'enquirer': 3,
    'enquist': 2,
    'enrage': 2,
    'enraged': 2,
    'enrages': 3,
    'enraging': 3,
    'enrapture': 3,
    'enraptured': 3,
    'enrica': 3,
    'enrich': 2,
    'enriched': 2,
    'enriches': 3,
    'enriching': 3,
    'enrichment': 3,
    'enrico': 3,
    'enright': 2,
    'enrile': 2,
    'enrique': 3,
    'enriquez': 3,
    'enro': 2,
    'enroll': 2,
    'enrolled': 2,
    'enrollee': 3,
    'enrollees': 3,
    'enrolling': 3,
    'enrollment': 3,
    'enrollments': 3,
    'enrolls': 2,
    'enron': 2,
    'enroute': 2,
    'ensco': 2,
    'ensconce': 2,
    'ensconced': 2,
    'enseco': 3,
    'ensemble': 3,
    'ensembles': 3,
    'enserch': 2,
    'ensey': 2,
    'enshrine': 2,
    'enshrined': 2,
    'enshroud': 2,
    'enshrouded': 3,
    'ensign': 2,
    'ensing': 2,
    'ensinger': 3,
    'enskilda': 3,
    'enslave': 2,
    'enslaved': 2,
    'enslavement': 3,
    'enslen': 3,
    'ensley': 2,
    'enslin': 2,
    'enslow': 2,
    'ensminger': 4,
    'ensnare': 2,
    'ensnared': 2,
    'ensnares': 2,
    'ensnarl': 2,
    'ensnarled': 2,
    'ensor': 2,
    'ensource': 2,
    'ensrud': 2,
    'enstrom': 2,
    'ensue': 2,
    'ensued': 2,
    'ensues': 2,
    'ensuing': 3,
    'ensure': 2,
    'ensured': 2,
    'ensures': 2,
    'ensuring': 3,
    'ensz': 1,
    'ent': 1,
    'entail': 2,
    'entailed': 2,
    'entailing': 3,
    'entails': 2,
    'entangle': 3,
    'entangled': 3,
    'entanglement': 4,
    'entanglements': 4,
    'entangling': 3,
    'ente': 2,
    'entebbe': 3,
    'entendre': 3,
    'entenmann': 3,
    'enter': 2,
    'entered': 2,
    'entergy': 3,
    'entering': 3,
    'enteritidis': 5,
    'enterividous': 5,
    'enterline': 3,
    'enterococcus': 5,
    'enterology': 5,
    'enterprise': 3,
    'enterprises': 4,
    'enterprising': 4,
    'enterra': 3,
    'enters': 2,
    'entertain': 3,
    'entertained': 3,
    'entertainer': 4,
    'entertainers': 4,
    'entertaining': 4,
    'entertainment': 4,
    'entertainments': 4,
    'entertains': 3,
    'entex': 2,
    'enthral': 2,
    'enthralled': 2,
    'enthuse': 2,
    'enthused': 2,
    'enthusiasm': 5,
    'enthusiasms': 5,
    'enthusiast': 4,
    'enthusiastic': 5,
    'enthusiastically': 6,
    'enthusiasts': 4,
    'entice': 2,
    'enticed': 2,
    'enticement': 3,
    'enticements': 3,
    'enticing': 3,
    'entin': 2,
    'entire': 3,
    'entirely': 4,
    'entirety': 4,
    'entities': 3,
    'entitle': 3,
    'entitled': 3,
    'entitlement': 4,
    'entitlements': 4,
    'entitles': 3,
    'entitling': 4,
    'entity': 3,
    'entler': 2,
    'entoffen': 3,
    'entomb': 2,
    'entombed': 2,
    'entombment': 3,
    'entomologist': 5,
    'entomologists': 5,
    'entomology': 5,
    'entourage': 3,
    'entourages': 4,
    'entrail': 2,
    'entrails': 2,
    'entrance': 2,
    'entranced': 2,
    'entrances': 3,
    'entrant': 2,
    'entrants': 2,
    'entrap': 2,
    'entrapment': 3,
    'entrapped': 2,
    'entre': 2,
    'entreaties': 3,
    'entreaty': 3,
    'entree': 2,
    'entrees': 2,
    'entregrowth': 3,
    'entrekin': 3,
    'entrench': 2,
    'entrenched': 2,
    'entrenches': 3,
    'entrenching': 3,
    'entrenchment': 3,
    'entrepreneur': 4,
    'entrepreneurial': 6,
    'entrepreneurialism': 8,
    'entrepreneurs': 4,
    'entrepreneurship': 5,
    'entries': 2,
    'entrikin': 3,
    'entringer': 4,
    'entropy': 3,
    'entrust': 2,
    'entrusted': 3,
    'entrusting': 3,
    'entry': 2,
    'entryway': 3,
    'entsminger': 4,
    'entwine': 2,
    'entwined': 2,
    'entwisle': 3,
    'entwistle': 3,
    'entz': 1,
    'entzminger': 3,
    'enumerate': 4,
    'enumerated': 5,
    'enumerates': 4,
    'enumeration': 5,
    'enunciate': 4,
    'enunciated': 5,
    'enunciating': 5,
    'envelop': 3,
    'envelope': 3,
    'enveloped': 3,
    'envelopes': 3,
    'enveloping': 4,
    'envelops': 3,
    'enviable': 4,
    'envied': 2,
    'envious': 3,
    'enviously': 4,
    'enviro': 3,
    'envirodyne': 4,
    'environic': 4,
    'environics': 4,
    'environment': 4,
    'environmental': 5,
    'environmentalism': 7,
    'environmentalist': 6,
    'environmentalists': 6,
    'environmentally': 6,
    'environments': 4,
    'environs': 3,
    'enviropact': 4,
    'envirosafe': 4,
    'envirotest': 4,
    'envisage': 3,
    'envisaged': 3,
    'envisages': 4,
    'envision': 3,
    'envisioned': 3,
    'envisioning': 4,
    'envisions': 3,
    'envos': 2,
    'envoy': 2,
    'envoys': 2,
    'envy': 2,
    'enwright': 2,
    'enyart': 2,
    'enyeart': 2,
    'enz': 1,
    'enzo': 2,
    'enzon': 2,
    'enzor': 2,
    'enzymatic': 4,
    'enzyme': 2,
    'enzymes': 2,
    'eocene': 3,
    'eoff': 2,
    'eohippus': 4,
    'eolande': 4,
    'eon': 2,
    'eons': 2,
    'eos': 2,
    'epcot': 2,
    'epeda': 3,
    'epes': 1,
    'ephedrine': 3,
    'ephemeral': 4,
    'ephlin': 2,
    'ephraim': 2,
    'ephron': 2,
    'epic': 2,
    'epicenter': 4,
    'epics': 2,
    'epicure': 3,
    'epicurean': 5,
    'epidemic': 4,
    'epidemics': 4,
    'epidemiological': 8,
    'epidemiologically': 8,
    'epidemiologist': 7,
    'epidemiologists': 7,
    'epidemiology': 7,
    'epidermal': 4,
    'epidermis': 4,
    'epidural': 4,
    'epigenetic': 5,
    'epigram': 3,
    'epigrammatic': 5,
    'epigraphic': 4,
    'epilepsies': 4,
    'epilepsy': 4,
    'epileptic': 4,
    'epileptics': 4,
    'epilogue': 3,
    'epiphany': 4,
    'episcopal': 4,
    'episcopalian': 6,
    'episcopalians': 6,
    'episcopo': 4,
    'episode': 3,
    'episodes': 3,
    'episodic': 4,
    'episteme': 3,
    'epistemology': 6,
    'epistle': 3,
    'epistolary': 5,
    'epitaph': 3,
    'epitaphs': 3,
    'epithelial': 4,
    'epithet': 3,
    'epithets': 3,
    'epitome': 4,
    'epitomize': 4,
    'epitomized': 4,
    'epitomizes': 5,
    'epitope': 3,
    'epler': 2,
    'epley': 2,
    'eplin': 2,
    'epling': 2,
    'epoch': 2,
    'epochal': 3,
    'epochs': 2,
    'epogen': 3,
    'epoxy': 3,
    'epp': 1,
    'eppard': 2,
    'eppel': 2,
    'epperly': 3,
    'eppers': 2,
    'epperson': 3,
    'eppes': 1,
    'eppich': 2,
    'epping': 2,
    'eppinger': 3,
    'epple': 2,
    'eppler': 2,
    'eppley': 2,
    'eppner': 2,
    'eppolito': 4,
    'epps': 1,
    'eprom': 2,
    'eproms': 2,
    'epsco': 2,
    'epsilon': 3,
    'epsom': 2,
    'epsomite': 3,
    'epson': 2,
    'epstein': 2,
    'epting': 2,
    'equable': 3,
    'equal': 2,
    'equaled': 2,
    'equaling': 3,
    'equality': 4,
    'equalization': 5,
    'equalize': 3,
    'equalized': 3,
    'equalizer': 4,
    'equalizes': 4,
    'equalizing': 4,
    'equally': 3,
    'equals': 2,
    'equanimity': 5,
    'equate': 2,
    'equated': 3,
    'equates': 2,
    'equating': 3,
    'equation': 3,
    'equations': 3,
    'equator': 3,
    'equatorial': 5,
    'equators': 3,
    'equestrian': 4,
    'equiangular': 5,
    'equibank': 3,
    'equicor': 3,
    'equifax': 3,
    'equilibria': 5,
    'equilibrium': 5,
    'equilink': 3,
    'equimark': 3,
    'equine': 2,
    'equinox': 3,
    'equion': 3,
    'equip': 2,
    'equipment': 3,
    'equipments': 3,
    'equipped': 2,
    'equipping': 3,
    'equips': 2,
    'equitable': 4,
    'equitably': 4,
    'equitas': 3,
    'equitation': 4,
    'equitec': 3,
    'equitex': 3,
    'equiticorp': 4,
    'equities': 3,
    'equity': 3,
    'equivalence': 4,
    'equivalency': 5,
    'equivalent': 4,
    'equivalents': 4,
    'equivocal': 4,
    'equivocate': 4,
    'equivocating': 5,
    'equivocation': 5,
    'er': 1,
    'era': 2,
    'eradicable': 5,
    'eradicate': 4,
    'eradicated': 5,
    'eradicating': 5,
    'eradication': 5,
    'erakat': 3,
    'eralp': 2,
    'eramo': 3,
    'eranthe': 2,
    'erany': 3,
    'eras': 2,
    'erasable': 4,
    'erase': 2,
    'erased': 2,
    'eraser': 3,
    'erasers': 3,
    'erases': 3,
    'erasing': 3,
    'erasmus': 3,
    'erastus': 3,
    'erawan': 3,
    'erazo': 3,
    'erb': 1,
    'erbacher': 3,
    'erbamont': 3,
    'erbe': 1,
    'erber': 2,
    'erbes': 1,
    'erburu': 3,
    'erby': 2,
    'erceg': 2,
    'erck': 1,
    'ercole': 3,
    'ercros': 2,
    'erda': 2,
    'erdahl': 2,
    'erdman': 2,
    'erdmann': 2,
    'erdos': 2,
    'erdrich': 2,
    'ere': 1,
    'erect': 2,
    'erected': 3,
    'erecting': 3,
    'erection': 3,
    'erections': 3,
    'erector': 3,
    'erects': 2,
    'erena': 3,
    'erensel': 3,
    'ergle': 2,
    'ergo': 2,
    'ergonomic': 4,
    'ergonomically': 5,
    'ergonomics': 4,
    'ergot': 2,
    'ergotism': 4,
    'ergots': 2,
    'erhard': 2,
    'erhardt': 2,
    'erhart': 2,
    'eric': 2,
    'erica': 3,
    'erich': 2,
    'erichsen': 3,
    'erick': 2,
    'ericka': 3,
    'ericksen': 3,
    'erickson': 3,
    'ericson': 3,
    'ericsson': 3,
    'eridania': 5,
    'erie': 2,
    'erik': 2,
    'erika': 3,
    'eriksen': 3,
    'erikson': 3,
    'eriksson': 3,
    'erin': 2,
    'erina': 3,
    'erion': 3,
    'erisa': 3,
    'erisman': 3,
    'eritrea': 4,
    'eritrean': 4,
    'eritreans': 3,
    'erk': 1,
    'erker': 2,
    'erkkila': 3,
    'erl': 1,
    'erlach': 2,
    'erland': 2,
    'erlandson': 3,
    'erlangen': 3,
    'erlanger': 3,
    'erle': 2,
    'erlene': 2,
    'erler': 2,
    'erlich': 2,
    'erlichman': 3,
    'erlick': 2,
    'erline': 2,
    'erling': 2,
    'erly': 2,
    'erma': 2,
    'erman': 2,
    'ermer': 2,
    'ermin': 2,
    'ermina': 3,
    'ermine': 2,
    'erminia': 4,
    'erminie': 3,
    'ermis': 2,
    'erna': 2,
    'ernaline': 3,
    'erne': 1,
    'ernest': 2,
    'ernestine': 3,
    'ernesto': 3,
    'ernests': 2,
    'erney': 2,
    'ernie': 2,
    'erno': 2,
    'ernsberger': 3,
    'ernst': 1,
    'ernster': 2,
    'erny': 2,
    'ero': 2,
    'erode': 2,
    'eroded': 3,
    'erodes': 2,
    'erodible': 4,
    'eroding': 3,
    'eroh': 2,
    'eroica': 3,
    'erol': 2,
    'eron': 2,
    'eros': 2,
    'erosion': 3,
    'erosional': 4,
    'erosive': 3,
    'erotic': 3,
    'erotica': 4,
    'eroticism': 5,
    'erpelding': 3,
    'erps': 1,
    'err': 1,
    'erra': 2,
    'errand': 2,
    'errands': 2,
    'errant': 2,
    'erratic': 3,
    'erratically': 4,
    'erred': 1,
    'errett': 2,
    'erria': 3,
    'errickson': 3,
    'errico': 3,
    'erring': 2,
    'errington': 3,
    'errol': 2,
    'erroneous': 4,
    'erroneously': 5,
    'error': 2,
    'errorful': 3,
    'errors': 2,
    'errs': 1,
    'ers': 1,
    'ersatz': 2,
    'ershad': 2,
    'erskin': 2,
    'erskine': 2,
    'erstwhile': 2,
    'erte': 1,
    'ertel': 2,
    'ertha': 2,
    'ertl': 2,
    'ertle': 2,
    'ertman': 2,
    'ertz': 1,
    'erudite': 3,
    'erudition': 4,
    'erupt': 2,
    'erupted': 3,
    'erupting': 3,
    'eruption': 3,
    'eruptions': 3,
    'eruptive': 3,
    'erupts': 2,
    'erven': 2,
    'ervin': 2,
    'erving': 2,
    'erway': 2,
    'erwin': 2,
    'erwina': 3,
    'erxleben': 3,
    'erykah': 3,
    'erythropoietin': 5,
    'erzurum': 3,
    'es': 1,
    'esarey': 3,
    'esau': 2,
    'esbenshade': 3,
    'esber': 2,
    'escada': 3,
    'escalante': 4,
    'escalate': 3,
    'escalated': 4,
    'escalates': 3,
    'escalating': 4,
    'escalation': 4,
    'escalator': 4,
    'escalators': 4,
    'escalera': 4,
    'escalona': 4,
    'escambia': 4,
    'escamez': 3,
    'escamilla': 4,
    'escanaba': 4,
    'escandon': 3,
    'escapade': 3,
    'escapades': 3,
    'escape': 2,
    'escaped': 2,
    'escapee': 3,
    'escapees': 3,
    'escapement': 3,
    'escapes': 2,
    'escaping': 3,
    'escapism': 4,
    'escarcega': 4,
    'escareno': 4,
    'escarpment': 3,
    'escarpments': 3,
    'esch': 1,
    'eschatological': 6,
    'eschbach': 2,
    'esche': 1,
    'eschen': 2,
    'eschenbach': 3,
    'eschenburg': 3,
    'escher': 2,
    'eschete': 2,
    'eschew': 2,
    'eschewed': 2,
    'eschewing': 3,
    'eschews': 2,
    'eschmann': 2,
    'esco': 2,
    'escobar': 3,
    'escobedo': 4,
    'escoe': 2,
    'escondido': 4,
    'escora': 3,
    'escort': 2,
    'escorted': 3,
    'escorting': 3,
    'escorts': 2,
    'escoto': 3,
    'escott': 2,
    'escrow': 2,
    'escrowed': 2,
    'escuchando': 4,
    'escudero': 4,
    'escudo': 3,
    'escudos': 3,
    'escue': 2,
    'esculent': 3,
    'esh': 1,
    'esham': 2,
    'eshbach': 2,
    'eshbaugh': 2,
    'eshelman': 3,
    'eshleman': 3,
    'eskandarian': 5,
    'eskelson': 3,
    'eskenazi': 4,
    'esker': 2,
    'eskew': 2,
    'eskey': 2,
    'eskimo': 3,
    'eskimos': 3,
    'eskin': 2,
    'eskridge': 2,
    'esler': 3,
    'eslick': 2,
    'eslinger': 4,
    'esmark': 2,
    'esme': 1,
    'esmerelda': 4,
    'esmine': 2,
    'esmond': 2,
    'esophagus': 4,
    'esoteric': 4,
    'espada': 3,
    'espalier': 3,
    'espana': 3,
    'espanol': 3,
    'espanola': 4,
    'esparza': 3,
    'espe': 1,
    'especial': 3,
    'especially': 3,
    'espectador': 4,
    'espejo': 3,
    'espeland': 3,
    'espenschied': 3,
    'espenshade': 3,
    'esper': 2,
    'esperanto': 4,
    'espeseth': 3,
    'espey': 2,
    'espinal': 3,
    'espino': 3,
    'espinola': 4,
    'espinosa': 4,
    'espinoza': 4,
    'espionage': 4,
    'espirito': 4,
    'espiritu': 4,
    'espitia': 3,
    'esplanade': 3,
    'esplin': 2,
    'esposito': 4,
    'espouse': 2,
    'espoused': 2,
    'espouses': 3,
    'espousing': 3,
    'espresso': 3,
    'esprit': 2,
    'espy': 2,
    'esque': 1,
    'esqueda': 3,
    'esquer': 2,
    'esquibel': 3,
    'esquire': 2,
    'esquivel': 3,
    'esrey': 2,
    'ess': 1,
    'essa': 2,
    'essary': 3,
    'essay': 2,
    'essayist': 3,
    'essays': 2,
    'esse': 1,
    'esselman': 3,
    'esselte': 3,
    'essen': 2,
    'essenburg': 3,
    'essence': 2,
    'essential': 3,
    'essentially': 4,
    'essentials': 3,
    'esser': 2,
    'esses': 2,
    'essex': 2,
    'essick': 2,
    'essie': 2,
    'essig': 2,
    'esslinger': 4,
    'essman': 2,
    'essner': 2,
    'esso': 2,
    'esson': 2,
    'est': 2,
    'esta': 2,
    'establish': 3,
    'established': 3,
    'establishes': 4,
    'establishing': 4,
    'establishment': 4,
    'establishments': 4,
    'estabrook': 3,
    'estabrooks': 3,
    'estai': 2,
    'estan': 2,
    'estate': 2,
    'estatehood': 3,
    'estates': 2,
    'este': 1,
    'esteban': 3,
    'estedat': 3,
    'estee': 2,
    'esteem': 2,
    'esteemed': 2,
    'estefan': 3,
    'estel': 2,
    'estell': 2,
    'estella': 3,
    'estelle': 2,
    'estenson': 3,
    'estep': 2,
    'estepp': 2,
    'ester': 2,
    'esterline': 3,
    'esterly': 3,
    'esters': 2,
    'estes': 2,
    'esteve': 2,
    'esteves': 3,
    'estevez': 3,
    'estey': 2,
    'esther': 2,
    'esthetic': 3,
    'esthetically': 4,
    'esthetics': 3,
    'estill': 2,
    'estimable': 4,
    'estimate': 3,
    'estimated': 4,
    'estimates': 3,
    'estimating': 4,
    'estimation': 4,
    'estimations': 4,
    'estimator': 4,
    'estimators': 4,
    'estis': 2,
    'estle': 2,
    'estock': 2,
    'estok': 2,
    'estonia': 4,
    'estonian': 4,
    'estonians': 4,
    'estrada': 3,
    'estrange': 2,
    'estranged': 2,
    'estrangement': 3,
    'estrella': 3,
    'estrich': 2,
    'estridge': 2,
    'estrin': 2,
    'estrogen': 3,
    'estrogens': 3,
    'estrous': 2,
    'estrus': 2,
    'estruth': 2,
    'estuaries': 4,
    'estuary': 4,
    'esty': 2,
    'esworthy': 3,
    'eszterhas': 3,
    'et': 1,
    'etablissements': 5,
    'etc': 4,
    'etcetera': 4,
    'etch': 1,
    'etched': 1,
    'etches': 2,
    'etcheverry': 4,
    'etching': 2,
    'etchings': 2,
    'etchison': 3,
    'eternal': 3,
    'eternally': 4,
    'eternit': 3,
    'eternity': 4,
    'etess': 2,
    'ethan': 2,
    'ethane': 2,
    'ethanol': 3,
    'ethel': 2,
    'ethelbert': 3,
    'ethelda': 3,
    'ethelinda': 4,
    'etheline': 3,
    'etheljean': 3,
    'ethelson': 3,
    'ethelyn': 3,
    'ethene': 2,
    'ether': 2,
    'ethereal': 4,
    'etheredge': 3,
    'etheridge': 3,
    'etherington': 4,
    'ethernet': 3,
    'ethers': 2,
    'etherton': 3,
    'ethic': 2,
    'ethical': 3,
    'ethically': 4,
    'ethicist': 3,
    'ethicists': 3,
    'ethicon': 3,
    'ethics': 2,
    'ethier': 3,
    'ethington': 3,
    'ethiopia': 5,
    'ethiopian': 5,
    'ethiopians': 5,
    'ethnic': 2,
    'ethnical': 3,
    'ethnically': 3,
    'ethnicity': 4,
    'ethnics': 2,
    'ethnocentric': 4,
    'ethnocentrism': 5,
    'ethnographic': 4,
    'ethnology': 4,
    'ethnomusicologist': 7,
    'ethnos': 2,
    'ethology': 4,
    'ethos': 2,
    'ethridge': 2,
    'ethyl': 2,
    'ethylene': 3,
    'ethyol': 3,
    'etienne': 3,
    'etiologies': 5,
    'etiology': 5,
    'etiquette': 3,
    'etling': 2,
    'etna': 2,
    'eton': 2,
    'etops': 2,
    'etruscan': 3,
    'etruscans': 3,
    'etsty': 2,
    'etsu': 2,
    'etta': 2,
    'etter': 2,
    'etters': 2,
    'ettie': 2,
    'ettinger': 3,
    'ettlinger': 3,
    'etty': 2,
    'etudes': 2,
    'etymological': 6,
    'etymology': 5,
    'etzel': 2,
    'etzioni': 4,
    'etzkorn': 2,
    'etzler': 2,
    'euan': 2,
    'eubank': 2,
    'eubanks': 2,
    'eucalypti': 4,
    'eucalyptus': 4,
    'eucharist': 3,
    'euchre': 2,
    'euchred': 2,
    'euclea': 3,
    'euclid': 2,
    'eudocia': 3,
    'eudora': 3,
    'eudosia': 4,
    'eudoxia': 4,
    'eudy': 2,
    'eugene': 2,
    'eugenia': 4,
    'eugenic': 3,
    'eugenics': 3,
    'eugenie': 3,
    'eugenio': 4,
    'eula': 2,
    'euler': 2,
    'eulich': 2,
    'eulogies': 3,
    'eulogize': 3,
    'eulogized': 3,
    'eulogy': 3,
    'eunice': 2,
    'eunuch': 2,
    'euphemia': 4,
    'euphemism': 4,
    'euphemisms': 4,
    'euphemistic': 4,
    'euphemistically': 5,
    'euphony': 3,
    'euphoria': 4,
    'euphoric': 3,
    'euphory': 3,
    'euphrates': 3,
    'eurasia': 3,
    'eurasian': 3,
    'eure': 1,
    'eureca': 3,
    'eureka': 3,
    'eurest': 2,
    'eurich': 2,
    'euro': 2,
    'eurobond': 3,
    'eurobonds': 3,
    'eurocheck': 3,
    'eurochecks': 3,
    'eurocom': 3,
    'eurocommercial': 5,
    'eurocopter': 4,
    'eurocopters': 4,
    'eurocrat': 3,
    'eurocrats': 3,
    'eurodebenture': 5,
    'eurodebentures': 5,
    'eurodebt': 3,
    'eurodeposit': 5,
    'eurodeposits': 5,
    'eurodisney': 4,
    'eurodollar': 4,
    'eurodollars': 4,
    'eurofighter': 4,
    'eurofighters': 4,
    'eurofima': 4,
    'euromark': 3,
    'euromarket': 4,
    'euromarkets': 4,
    'euromissile': 4,
    'euromissiles': 4,
    'euromobiliare': 5,
    'euromoney': 4,
    'euronote': 3,
    'euronotes': 3,
    'europa': 3,
    'europe': 2,
    'european': 4,
    'europeans': 4,
    'europeenne': 3,
    'europewide': 3,
    'europhile': 3,
    'europhiles': 3,
    'europium': 4,
    'eurosid': 3,
    'eurostar': 3,
    'eurostat': 3,
    'eurosterling': 4,
    'eurotunnel': 4,
    'euroyen': 3,
    'eury': 2,
    'eusebio': 4,
    'eustace': 2,
    'eustachian': 4,
    'eustacia': 3,
    'eustice': 2,
    'eustis': 2,
    'euthanasia': 4,
    'euthanize': 3,
    'eutsey': 2,
    'eutsler': 2,
    'ev': 1,
    'eva': 2,
    'evacuate': 4,
    'evacuated': 5,
    'evacuating': 5,
    'evacuation': 5,
    'evacuations': 5,
    'evacuee': 4,
    'evacuees': 4,
    'evade': 2,
    'evaded': 3,
    'evader': 3,
    'evaders': 3,
    'evades': 2,
    'evading': 3,
    'evadne': 3,
    'evald': 2,
    'evaleen': 3,
    'evaluate': 4,
    'evaluated': 5,
    'evaluates': 4,
    'evaluating': 5,
    'evaluation': 5,
    'evaluations': 5,
    'evan': 2,
    'evancho': 3,
    'evander': 3,
    'evandro': 3,
    'evanescence': 4,
    'evanescent': 4,
    'evangelical': 5,
    'evangelicals': 5,
    'evangeline': 4,
    'evangelism': 5,
    'evangelist': 4,
    'evangelista': 5,
    'evangelistic': 5,
    'evangelists': 4,
    'evangelize': 4,
    'evangi': 3,
    'evanko': 3,
    'evanoff': 3,
    'evans': 2,
    'evanson': 3,
    'evanston': 3,
    'evansville': 3,
    'evaporate': 4,
    'evaporated': 5,
    'evaporates': 4,
    'evaporating': 5,
    'evaporation': 5,
    'evaporator': 5,
    'evarts': 2,
    'evasion': 3,
    'evasions': 3,
    'evasive': 3,
    'evatt': 2,
    'eve': 1,
    'evel': 2,
    'eveland': 2,
    'eveleth': 3,
    'evelina': 4,
    'eveline': 3,
    'evelyn': 3,
    'even': 2,
    'evendale': 3,
    'evened': 2,
    'evenhanded': 4,
    'evenhandedly': 5,
    'evening': 2,
    'evenings': 2,
    'evenly': 3,
    'evenness': 3,
    'evens': 2,
    'evensen': 3,
    'evensky': 3,
    'evenson': 3,
    'event': 2,
    'eventful': 3,
    'events': 2,
    'eventual': 4,
    'eventuality': 6,
    'eventually': 5,
    'ever': 2,
    'everage': 3,
    'everard': 3,
    'everbach': 3,
    'everding': 3,
    'eveready': 4,
    'evered': 2,
    'everest': 3,
    'everett': 3,
    'everette': 3,
    'everetts': 3,
    'everex': 3,
    'everglades': 3,
    'evergood': 3,
    'evergreen': 3,
    'evergreens': 3,
    'everhardt': 3,
    'everhart': 3,
    'everidge': 3,
    'everingham': 4,
    'everington': 4,
    'everitt': 3,
    'everlasting': 4,
    'everlastings': 4,
    'everley': 3,
    'everling': 3,
    'everly': 3,
    'everman': 3,
    'evermore': 3,
    'evers': 2,
    'eversman': 3,
    'eversole': 3,
    'everson': 3,
    'evert': 2,
    'everton': 3,
    'everts': 2,
    'every': 3,
    'everybody': 4,
    'everyday': 3,
    'everyman': 3,
    'everyone': 3,
    'everyplace': 3,
    'everything': 3,
    'everythings': 3,
    'everytime': 3,
    'everywhere': 3,
    'eves': 1,
    'evett': 2,
    'evetts': 2,
    'evian': 3,
    'evict': 2,
    'evicted': 3,
    'evicting': 3,
    'eviction': 3,
    'evictions': 3,
    'evidence': 3,
    'evidenced': 3,
    'evidences': 4,
    'evident': 3,
    'evidentiary': 6,
    'evidently': 4,
    'evil': 2,
    'evildoer': 4,
    'evildoers': 4,
    'evils': 2,
    'evilsizer': 4,
    'evilsizor': 4,
    'evin': 2,
    'evince': 2,
    'evinced': 2,
    'evinger': 3,
    'evins': 2,
    'eviscerate': 4,
    'eviscerated': 5,
    'evita': 3,
    'evitt': 2,
    'evitts': 2,
    'evjen': 2,
    'evlyn': 2,
    'evocation': 4,
    'evocative': 4,
    'evoke': 2,
    'evoked': 2,
    'evokes': 2,
    'evoking': 3,
    'evola': 3,
    'evolution': 4,
    'evolutionary': 6,
    'evolve': 2,
    'evolved': 2,
    'evolves': 2,
    'evolving': 3,
    'evon': 2,
    'evonne': 2,
    'evrard': 2,
    'evren': 2,
    'ewald': 2,
    'ewalt': 2,
    'ewan': 2,
    'ewart': 2,
    'ewbal': 2,
    'ewbank': 2,
    'ewe': 1,
    'ewell': 2,
    'ewen': 2,
    'ewer': 2,
    'ewers': 2,
    'ewert': 2,
    'ewes': 1,
    'ewig': 2,
    'ewing': 2,
    'ewings': 2,
    'ewoldt': 2,
    'ewong': 2,
    'eworld': 2,
    'ewton': 2,
    'ewy': 2,
    'ex': 1,
    'exabyte': 3,
    'exacerbate': 4,
    'exacerbated': 5,
    'exacerbates': 4,
    'exacerbating': 5,
    'exacerbation': 5,
    'exacerbations': 5,
    'exact': 2,
    'exacted': 3,
    'exacting': 3,
    'exaction': 3,
    'exactions': 3,
    'exactitude': 4,
    'exactly': 3,
    'exacts': 2,
    'exaggerate': 4,
    'exaggerated': 5,
    'exaggerates': 4,
    'exaggerating': 5,
    'exaggeration': 5,
    'exaggerations': 5,
    'exalt': 2,
    'exalted': 3,
    'exalting': 3,
    'exalts': 2,
    'exam': 2,
    'examination': 5,
    'examinations': 5,
    'examine': 3,
    'examined': 3,
    'examiner': 4,
    'examiners': 4,
    'examines': 3,
    'examining': 4,
    'example': 3,
    'examples': 3,
    'exams': 2,
    'exar': 2,
    'exasperate': 4,
    'exasperated': 5,
    'exasperating': 5,
    'exasperation': 5,
    'exboyfriend': 3,
    'exbroker': 3,
    'exbrokers': 3,
    'excalibur': 4,
    'excavate': 3,
    'excavated': 4,
    'excavating': 4,
    'excavation': 4,
    'excavations': 4,
    'excavator': 4,
    'excavators': 4,
    'excedrin': 3,
    'exceed': 2,
    'exceeded': 3,
    'exceeding': 3,
    'exceedingly': 4,
    'exceeds': 2,
    'excel': 2,
    'excelan': 3,
    'excelled': 2,
    'excellence': 3,
    'excellencies': 3,
    'excellency': 3,
    'excellent': 3,
    'excellently': 4,
    'excelling': 3,
    'excels': 2,
    'excelsior': 4,
    'except': 2,
    'excepted': 3,
    'excepting': 3,
    'exception': 3,
    'exceptional': 4,
    'exceptionally': 5,
    'exceptions': 3,
    'excerpt': 2,
    'excerpted': 3,
    'excerpts': 2,
    'excess': 2,
    'excesses': 3,
    'excessive': 3,
    'excessively': 4,
    'exchange': 2,
    'exchangeable': 4,
    'exchanged': 2,
    'exchanger': 3,
    'exchangers': 3,
    'exchanges': 3,
    'exchanging': 3,
    'exchequer': 3,
    'excimer': 3,
    'excise': 2,
    'excised': 2,
    'excises': 3,
    'excision': 3,
    'excitable': 4,
    'excitation': 4,
    'excite': 2,
    'excited': 3,
    'excitedly': 4,
    'excitement': 3,
    'excites': 2,
    'exciting': 3,
    'exclaim': 2,
    'exclaimed': 2,
    'exclaiming': 3,
    'exclaims': 2,
    'exclamation': 4,
    'exclamations': 4,
    'excludable': 4,
    'exclude': 2,
    'excluded': 3,
    'excludes': 2,
    'excluding': 3,
    'exclusion': 3,
    'exclusionary': 5,
    'exclusions': 3,
    'exclusive': 3,
    'exclusively': 4,
    'exclusives': 3,
    'exclusivity': 5,
    'exco': 2,
    'excoa': 3,
    'excommunicate': 5,
    'excommunicated': 6,
    'excommunication': 6,
    'excoriate': 4,
    'excoriated': 5,
    'excoriating': 5,
    'excoriation': 5,
    'excrement': 3,
    'excrete': 2,
    'excretion': 3,
    'excretory': 4,
    'excruciating': 5,
    'excruciatingly': 6,
    'exculpate': 3,
    'exculpatory': 5,
    'excursion': 3,
    'excursions': 3,
    'excusable': 4,
    'excuse': 2,
    'excused': 2,
    'excuses': 3,
    'excusing': 3,
    'exec': 2,
    'execrable': 4,
    'execs': 2,
    'execute': 3,
    'executed': 4,
    'executes': 3,
    'executing': 4,
    'execution': 4,
    'executioner': 5,
    'executioners': 5,
    'executions': 4,
    'executive': 4,
    'executives': 4,
    'executone': 4,
    'executor': 4,
    'executors': 4,
    'exel': 2,
    'exemplar': 3,
    'exemplars': 3,
    'exemplary': 4,
    'exemplified': 4,
    'exemplifies': 4,
    'exemplify': 4,
    'exemplifying': 5,
    'exemplum': 3,
    'exempt': 2,
    'exempted': 3,
    'exempting': 3,
    'exemption': 3,
    'exemptions': 3,
    'exempts': 2,
    'exercisable': 5,
    'exercise': 3,
    'exercised': 3,
    'exerciser': 4,
    'exercisers': 4,
    'exercises': 4,
    'exercising': 4,
    'exert': 2,
    'exerted': 3,
    'exerting': 3,
    'exertion': 3,
    'exertions': 3,
    'exerts': 2,
    'exes': 2,
    'exeter': 3,
    'exfoliate': 4,
    'exfoliation': 5,
    'exhalation': 4,
    'exhale': 2,
    'exhaled': 2,
    'exhaust': 2,
    'exhausted': 3,
    'exhausting': 3,
    'exhaustion': 3,
    'exhaustive': 3,
    'exhaustively': 4,
    'exhausts': 2,
    'exhibit': 3,
    'exhibited': 4,
    'exhibiting': 4,
    'exhibition': 4,
    'exhibitionist': 5,
    'exhibitionists': 5,
    'exhibitions': 4,
    'exhibitor': 4,
    'exhibitors': 4,
    'exhibits': 3,
    'exhilarate': 4,
    'exhilarated': 5,
    'exhilarating': 5,
    'exhilaration': 5,
    'exhort': 2,
    'exhortation': 4,
    'exhortations': 4,
    'exhorted': 3,
    'exhorting': 3,
    'exhorts': 2,
    'exhumation': 4,
    'exhume': 2,
    'exhumed': 2,
    'exhumes': 2,
    'exide': 2,
    'exigencies': 4,
    'exigency': 4,
    'exigent': 3,
    'exigents': 3,
    'exile': 2,
    'exiled': 2,
    'exiles': 2,
    'exim': 2,
    'exist': 2,
    'existed': 3,
    'existence': 3,
    'existent': 3,
    'existential': 4,
    'existing': 3,
    'exists': 2,
    'exit': 2,
    'exited': 3,
    'exiting': 3,
    'exits': 2,
    'exler': 2,
    'exley': 2,
    'exline': 2,
    'exner': 2,
    'exocet': 3,
    'exodus': 3,
    'exogenous': 4,
    'exon': 2,
    'exonerate': 4,
    'exonerated': 5,
    'exonerates': 4,
    'exonerating': 5,
    'exoneration': 5,
    'exorbitant': 4,
    'exorcist': 3,
    'exoskeleton': 5,
    'exothermic': 4,
    'exotic': 3,
    'exotics': 3,
    'exovir': 3,
    'expand': 2,
    'expandable': 4,
    'expanded': 3,
    'expanding': 3,
    'expands': 2,
    'expanse': 2,
    'expanses': 3,
    'expansion': 3,
    'expansionary': 5,
    'expansionism': 5,
    'expansionist': 4,
    'expansions': 3,
    'expansive': 3,
    'expatriate': 4,
    'expatriates': 4,
    'expatriation': 5,
    'expect': 2,
    'expectancies': 4,
    'expectancy': 4,
    'expectant': 3,
    'expectation': 4,
    'expectations': 4,
    'expected': 3,
    'expecting': 3,
    'expectorant': 4,
    'expects': 2,
    'expedia': 4,
    'expedience': 4,
    'expediency': 5,
    'expedient': 4,
    'expedite': 3,
    'expedited': 4,
    'expediting': 4,
    'expedition': 4,
    'expeditionary': 6,
    'expeditions': 4,
    'expeditious': 4,
    'expeditiously': 5,
    'expel': 2,
    'expelled': 2,
    'expelling': 3,
    'expend': 2,
    'expendable': 4,
    'expended': 3,
    'expending': 3,
    'expenditure': 4,
    'expenditures': 4,
    'expense': 2,
    'expenses': 3,
    'expensing': 3,
    'expensive': 3,
    'expensively': 4,
    'experience': 4,
    'experienced': 4,
    'experiences': 5,
    'experiencing': 5,
    'experiential': 5,
    'experiment': 4,
    'experimental': 5,
    'experimentalist': 6,
    'experimentally': 6,
    'experimentation': 6,
    'experimented': 5,
    'experimenter': 5,
    'experimenters': 5,
    'experimenting': 5,
    'experiments': 4,
    'expert': 2,
    'expertise': 3,
    'expertly': 3,
    'experts': 2,
    'expiate': 3,
    'expiration': 4,
    'expirations': 4,
    'expiratory': 5,
    'expire': 2,
    'expired': 2,
    'expires': 3,
    'expiring': 3,
    'expiry': 3,
    'explain': 2,
    'explainable': 4,
    'explained': 2,
    'explaining': 3,
    'explains': 2,
    'explanation': 4,
    'explanations': 4,
    'explanatory': 5,
    'expletive': 3,
    'expletives': 3,
    'explicable': 4,
    'explicate': 3,
    'explication': 4,
    'explicit': 3,
    'explicite': 3,
    'explicitly': 4,
    'explode': 2,
    'exploded': 3,
    'explodes': 2,
    'exploding': 3,
    'exploit': 2,
    'exploitation': 4,
    'exploitative': 4,
    'exploited': 3,
    'exploiting': 3,
    'exploitive': 3,
    'exploits': 2,
    'exploration': 4,
    'explorations': 4,
    'exploratory': 5,
    'exploravision': 5,
    'explore': 2,
    'explored': 2,
    'explorer': 3,
    'explorers': 3,
    'explores': 2,
    'exploring': 3,
    'explosion': 3,
    'explosions': 3,
    'explosive': 3,
    'explosively': 4,
    'explosives': 3,
    'explosivos': 4,
    'expo': 2,
    'exponent': 3,
    'exponential': 4,
    'exponentially': 5,
    'exponents': 3,
    'export': 2,
    'exportable': 4,
    'exported': 3,
    'exporter': 3,
    'exporters': 3,
    'exporting': 3,
    'exports': 2,
    'expos': 2,
    'expose': 2,
    'exposed': 2,
    'exposes': 3,
    'exposing': 3,
    'exposition': 4,
    'expositions': 4,
    'exposito': 4,
    'exposure': 3,
    'exposures': 3,
    'expound': 2,
    'expounded': 3,
    'expounding': 3,
    'expounds': 2,
    'express': 2,
    'expressed': 2,
    'expresses': 3,
    'expressing': 3,
    'expression': 3,
    'expressionism': 5,
    'expressionist': 4,
    'expressionistic': 5,
    'expressionless': 4,
    'expressions': 3,
    'expressive': 3,
    'expressivity': 5,
    'expressly': 3,
    'expresso': 3,
    'expressway': 3,
    'expropriate': 4,
    'expropriated': 5,
    'expropriation': 5,
    'expropriations': 5,
    'expulsion': 3,
    'expulsions': 3,
    'expunge': 2,
    'expunged': 2,
    'exquisite': 3,
    'exquisitely': 4,
    'extant': 2,
    'extel': 2,
    'extend': 2,
    'extendable': 4,
    'extended': 3,
    'extender': 3,
    'extenders': 3,
    'extendible': 4,
    'extending': 3,
    'extends': 2,
    'extension': 3,
    'extensions': 3,
    'extensive': 3,
    'extensively': 4,
    'extent': 2,
    'extenuate': 4,
    'extenuating': 5,
    'exterior': 4,
    'exteriors': 4,
    'exterminate': 4,
    'exterminated': 5,
    'exterminates': 4,
    'exterminating': 5,
    'extermination': 5,
    'exterminator': 5,
    'exterminators': 5,
    'external': 3,
    'externally': 4,
    'extinct': 2,
    'extinction': 3,
    'extinctions': 3,
    'extinguish': 3,
    'extinguished': 3,
    'extinguisher': 4,
    'extinguishers': 4,
    'extinguishing': 4,
    'extinguishment': 4,
    'extirpate': 3,
    'extirpated': 4,
    'extirpates': 3,
    'extirpating': 4,
    'extol': 2,
    'extolled': 2,
    'extolling': 3,
    'extols': 2,
    'exton': 2,
    'extort': 2,
    'extorted': 3,
    'extorting': 3,
    'extortion': 3,
    'extortionate': 4,
    'extortionist': 4,
    'extortionists': 4,
    'extorts': 2,
    'extra': 2,
    'extracellularly': 6,
    'extract': 2,
    'extracted': 3,
    'extracting': 3,
    'extraction': 3,
    'extractions': 3,
    'extracts': 2,
    'extracurricular': 6,
    'extradite': 3,
    'extradited': 4,
    'extradites': 3,
    'extraditing': 4,
    'extradition': 4,
    'extragalactic': 5,
    'extralegal': 4,
    'extramarital': 5,
    'extraneous': 4,
    'extraneously': 5,
    'extraordinaire': 5,
    'extraordinarily': 6,
    'extraordinary': 5,
    'extrapolate': 4,
    'extrapolated': 5,
    'extrapolates': 4,
    'extrapolating': 5,
    'extrapolation': 5,
    'extras': 2,
    'extrasensory': 5,
    'extraterrestrial': 6,
    'extraterrestrials': 6,
    'extraterritorial': 7,
    'extraterritoriality': 9,
    'extravagance': 4,
    'extravagant': 4,
    'extravagantly': 5,
    'extravaganza': 5,
    'extravaganzas': 5,
    'extreme': 2,
    'extremely': 3,
    'extremes': 2,
    'extremism': 4,
    'extremist': 3,
    'extremists': 3,
    'extremities': 4,
    'extremity': 4,
    'extricable': 4,
    'extricate': 3,
    'extricated': 4,
    'extricates': 3,
    'extricating': 4,
    'extrication': 4,
    'extrinsic': 3,
    'extrovert': 3,
    'extroverted': 4,
    'extrude': 2,
    'extruded': 3,
    'extrudes': 2,
    'extruding': 3,
    'extrusion': 3,
    'exuberance': 4,
    'exuberant': 4,
    'exude': 2,
    'exuded': 3,
    'exudes': 2,
    'exuding': 3,
    'exult': 2,
    'exultant': 3,
    'exultantly': 4,
    'exulted': 3,
    'exulting': 3,
    'exults': 2,
    'exum': 2,
    'exxon': 2,
    'eyde': 1,
    'eydie': 2,
    'eye': 1,
    'eyeball': 2,
    'eyeballs': 2,
    'eyebrow': 2,
    'eyebrows': 2,
    'eyecare': 2,
    'eyed': 1,
    'eyedrop': 2,
    'eyedropper': 3,
    'eyedrops': 2,
    'eyeglass': 2,
    'eyeglasses': 3,
    'eyeing': 2,
    'eyelab': 2,
    'eyelash': 2,
    'eyelashes': 3,
    'eyeless': 2,
    'eyelet': 2,
    'eyelets': 2,
    'eyelid': 2,
    'eyelids': 2,
    'eyelike': 2,
    'eyeliner': 3,
    'eyepiece': 2,
    'eyer': 2,
    'eyerly': 3,
    'eyerman': 3,
    'eyes': 1,
    'eyeshade': 2,
    'eyesight': 2,
    'eyesore': 2,
    'eyespot': 2,
    'eyestone': 2,
    'eyestrain': 2,
    'eyetech': 2,
    'eyewear': 2,
    'eyewitness': 3,
    'eyewitnesses': 4,
    'eyler': 2,
    'eyman': 2,
    'eynon': 2,
    'eyre': 1,
    'eyrich': 2,
    'eyrie': 2,
    'eyring': 2,
    'eyster': 2,
    'eyton': 2,
    'ezekiel': 3,
    'ezell': 2,
    'ezelle': 2,
    'ezer': 2,
    'ezoe': 2,
    'ezola': 3,
    'ezra': 2,
    'ezzell': 2,
    'ezzo': 2,
    'f': 1,
    'faal': 1,
    'faas': 1,
    'fab': 1,
    'fabbri': 2,
    'fabel': 2,
    'fabela': 3,
    'faber': 2,
    'faberge': 2,
    'faberman': 3,
    'fabia': 3,
    'fabian': 3,
    'fabiani': 4,
    'fabiano': 4,
    'fabien': 2,
    'fabio': 3,
    'fable': 2,
    'fabled': 2,
    'fables': 2,
    'fabre': 2,
    'fabri': 2,
    'fabric': 2,
    'fabricant': 3,
    'fabricate': 3,
    'fabricated': 4,
    'fabricates': 3,
    'fabricating': 4,
    'fabrication': 4,
    'fabrications': 4,
    'fabricator': 4,
    'fabricators': 4,
    'fabrics': 2,
    'fabris': 2,
    'fabrizi': 3,
    'fabrizio': 4,
    'fabrizius': 4,
    'fabron': 2,
    'fabry': 2,
    'fabulous': 3,
    'fabulously': 4,
    'fac': 1,
    'facade': 2,
    'facades': 2,
    'facchini': 3,
    'facciola': 4,
    'face': 1,
    'faced': 1,
    'facedown': 2,
    'faceless': 2,
    'facelift': 2,
    'facemire': 4,
    'facer': 2,
    'faces': 2,
    'facet': 2,
    'faceted': 3,
    'facetious': 3,
    'facetiously': 4,
    'facets': 2,
    'facey': 2,
    'fachet': 2,
    'facial': 2,
    'facials': 2,
    'faciane': 4,
    'facie': 2,
    'facile': 2,
    'facilitate': 4,
    'facilitated': 5,
    'facilitates': 4,
    'facilitating': 5,
    'facilitation': 5,
    'facilitator': 5,
    'facilitators': 5,
    'facilities': 4,
    'facility': 4,
    'facing': 2,
    'facings': 2,
    'fackler': 2,
    'fackrell': 2,
    'facsimile': 4,
    'facsimiles': 4,
    'fact': 1,
    'facteau': 2,
    'faction': 2,
    'factional': 3,
    'factionalism': 5,
    'factions': 2,
    'factitious': 3,
    'factly': 2,
    'facto': 2,
    'factor': 2,
    'factored': 2,
    'factories': 3,
    'factoring': 3,
    'factors': 2,
    'factory': 3,
    'facts': 1,
    'factual': 3,
    'factually': 4,
    'facultative': 4,
    'faculties': 3,
    'faculty': 3,
    'fad': 1,
    'fadden': 2,
    'faddis': 2,
    'faddish': 2,
    'fade': 1,
    'faded': 2,
    'fadel': 2,
    'fadeley': 3,
    'fadely': 2,
    'faden': 2,
    'fader': 2,
    'fades': 1,
    'fadiman': 3,
    'fading': 2,
    'fadlallah': 3,
    'fadness': 2,
    'fads': 1,
    'fae': 1,
    'faerber': 2,
    'faeth': 1,
    'fag': 1,
    'fagan': 2,
    'fagen': 2,
    'fager': 2,
    'fagerberg': 3,
    'fagerstrom': 3,
    'fagg': 1,
    'faggart': 2,
    'faggot': 2,
    'fagin': 2,
    'fagley': 2,
    'fagnant': 2,
    'fagoth': 2,
    'fags': 1,
    'fagundes': 2,
    'fahd': 1,
    'faherty': 3,
    'fahey': 2,
    'fahl': 1,
    'fahlman': 2,
    'fahmy': 2,
    'fahnestock': 2,
    'fahr': 1,
    'fahrenheit': 3,
    'fahrenkopf': 3,
    'fahrer': 2,
    'fahringer': 3,
    'fahrner': 2,
    'fahrney': 2,
    'fahs': 1,
    'fahy': 2,
    'faidley': 2,
    'faiella': 3,
    'fail': 1,
    'faile': 1,
    'failed': 1,
    'failing': 2,
    'failings': 2,
    'failla': 2,
    'failor': 2,
    'fails': 1,
    'failsafe': 2,
    'failure': 2,
    'failures': 2,
    'fain': 1,
    'faint': 1,
    'fainted': 2,
    'fainter': 2,
    'faintest': 2,
    'fainthearted': 3,
    'fainting': 2,
    'faintly': 2,
    'faintness': 2,
    'faiola': 3,
    'fair': 1,
    'fairall': 2,
    'fairbairn': 2,
    'fairbank': 2,
    'fairbanks': 2,
    'fairbrother': 3,
    'fairburn': 2,
    'fairchild': 2,
    'faircloth': 2,
    'fairclough': 2,
    'faire': 1,
    'fairer': 2,
    'faires': 1,
    'fairest': 2,
    'fairey': 2,
    'fairfax': 2,
    'fairfield': 2,
    'fairground': 2,
    'fairgrounds': 2,
    'fairhaven': 3,
    'fairhurst': 2,
    'fairies': 2,
    'fairlawn': 2,
    'fairleigh': 2,
    'fairless': 2,
    'fairley': 2,
    'fairlie': 2,
    'fairlow': 2,
    'fairly': 2,
    'fairman': 2,
    'fairmont': 2,
    'fairness': 2,
    'fairs': 1,
    'fairview': 2,
    'fairway': 2,
    'fairways': 2,
    'fairweather': 3,
    'fairy': 2,
    'fairyland': 3,
    'fairytale': 3,
    'fairytales': 3,
    'faisal': 2,
    'faison': 2,
    'faist': 2,
    'fait': 1,
    'faith': 1,
    'faithful': 2,
    'faithfully': 3,
    'faithfuls': 2,
    'faiths': 1,
    'faivre': 2,
    'fajardo': 3,
    'fajita': 3,
    'fajitas': 3,
    'fake': 1,
    'faked': 1,
    'faker': 2,
    'fakers': 2,
    'fakes': 1,
    'faking': 2,
    'falafel': 3,
    'falanga': 3,
    'falani': 3,
    'falardeau': 3,
    'falasco': 3,
    'falb': 1,
    'falbo': 2,
    'falcigno': 3,
    'falck': 1,
    'falco': 2,
    'falcoff': 2,
    'falcon': 2,
    'falconbridge': 3,
    'falcone': 3,
    'falconer': 3,
    'falconers': 3,
    'falconet': 3,
    'falconets': 3,
    'falconi': 3,
    'falconry': 3,
    'falcons': 2,
    'faldo': 2,
    'faler': 2,
    'fales': 1,
    'faley': 2,
    'falgoust': 2,
    'falgout': 2,
    'falik': 2,
    'falin': 2,
    'falise': 2,
    'falk': 1,
    'falke': 1,
    'falkenberg': 3,
    'falkenstein': 3,
    'falkland': 2,
    'falklands': 2,
    'falkner': 2,
    'falkowski': 3,
    'fall': 1,
    'falla': 2,
    'fallacies': 3,
    'fallacious': 3,
    'fallaciously': 4,
    'fallacy': 3,
    'fallaw': 2,
    'fallback': 2,
    'fallen': 2,
    'faller': 2,
    'fallert': 2,
    'fallibility': 5,
    'fallible': 3,
    'fallin': 2,
    'falling': 2,
    'fallis': 2,
    'fallish': 2,
    'falloff': 2,
    'fallon': 2,
    'fallopian': 4,
    'fallout': 2,
    'fallouts': 2,
    'fallow': 2,
    'fallows': 2,
    'falls': 1,
    'falmouth': 2,
    'falor': 2,
    'falotico': 4,
    'false': 1,
    'falsehood': 2,
    'falsehoods': 2,
    'falsely': 2,
    'falsetto': 3,
    'falsettos': 3,
    'falsification': 5,
    'falsified': 3,
    'falsify': 3,
    'falsifying': 4,
    'falsity': 3,
    'falsone': 2,
    'falstaff': 2,
    'falter': 2,
    'faltered': 2,
    'faltering': 3,
    'falters': 2,
    'falvey': 2,
    'falvo': 2,
    'falwell': 2,
    'falynn': 2,
    'falzarano': 4,
    'falzon': 2,
    'falzone': 3,
    'fama': 2,
    'fambro': 2,
    'fambrough': 2,
    'fame': 1,
    'famed': 1,
    'famer': 2,
    'famers': 2,
    'famiglietti': 5,
    'familial': 3,
    'familiar': 3,
    'familiarity': 5,
    'familiarize': 4,
    'familiarized': 4,
    'families': 3,
    'family': 3,
    'famine': 2,
    'famines': 2,
    'famous': 2,
    'famously': 3,
    'famularo': 4,
    'fan': 1,
    'fanara': 3,
    'fanatic': 3,
    'fanatical': 4,
    'fanatically': 5,
    'fanaticism': 5,
    'fanatics': 3,
    'fancher': 2,
    'fanchon': 2,
    'fancied': 2,
    'fancier': 3,
    'fanciers': 3,
    'fancies': 2,
    'fanciest': 3,
    'fanciful': 3,
    'fancy': 2,
    'fandango': 3,
    'fandel': 2,
    'fandrich': 2,
    'fane': 1,
    'fanech': 2,
    'fanelli': 3,
    'faneuil': 3,
    'fanfare': 2,
    'fang': 1,
    'fanger': 2,
    'fangle': 2,
    'fangled': 2,
    'fangman': 2,
    'fangs': 1,
    'fanguy': 2,
    'fankhauser': 3,
    'fanlike': 2,
    'fann': 1,
    'fanned': 1,
    'fannie': 2,
    'fannin': 2,
    'fanning': 2,
    'fannon': 2,
    'fanny': 2,
    'fans': 1,
    'fansler': 3,
    'fant': 1,
    'fanta': 2,
    'fantail': 2,
    'fantasia': 3,
    'fantasies': 3,
    'fantasize': 3,
    'fantasized': 3,
    'fantasizes': 4,
    'fantasizing': 4,
    'fantastic': 3,
    'fantastically': 4,
    'fantastik': 3,
    'fantasy': 3,
    'fantasyland': 4,
    'fantini': 3,
    'fantle': 2,
    'fanton': 2,
    'fantozzi': 3,
    'fantroy': 2,
    'fanuc': 2,
    'fanucchi': 3,
    'fanueil': 2,
    'fao': 1,
    'far': 1,
    'farabaugh': 3,
    'farabee': 3,
    'farabundo': 4,
    'farace': 3,
    'faraci': 3,
    'faraday': 3,
    'faragher': 3,
    'farago': 3,
    'farah': 2,
    'faraha': 3,
    'farahkan': 3,
    'farai': 2,
    'faranda': 3,
    'faraone': 4,
    'faraway': 3,
    'farb': 1,
    'farben': 2,
    'farber': 2,
    'farberware': 3,
    'farbman': 2,
    'farce': 1,
    'farcical': 3,
    'fare': 1,
    'fared': 1,
    'fares': 1,
    'farese': 3,
    'faretlow': 3,
    'farewell': 2,
    'farewells': 2,
    'farfen': 2,
    'farfetched': 2,
    'farge': 1,
    'fargnoli': 3,
    'fargo': 2,
    'farha': 2,
    'farhat': 2,
    'faria': 3,
    'farias': 3,
    'faribault': 3,
    'farica': 3,
    'faridoun': 3,
    'fariello': 4,
    'faries': 2,
    'farina': 3,
    'farinacci': 4,
    'farinas': 3,
    'farinella': 4,
    'faring': 2,
    'farino': 3,
    'faris': 2,
    'farish': 2,
    'fariss': 2,
    'farkas': 2,
    'farland': 2,
    'farlee': 2,
    'farler': 2,
    'farless': 2,
    'farley': 2,
    'farling': 2,
    'farlow': 2,
    'farly': 2,
    'farm': 1,
    'farman': 2,
    'farmboy': 2,
    'farmed': 1,
    'farmer': 2,
    'farmers': 2,
    'farmhand': 2,
    'farmhands': 2,
    'farmhouse': 2,
    'farmhouses': 3,
    'farming': 2,
    'farmingdale': 3,
    'farmington': 3,
    'farmland': 2,
    'farmlands': 2,
    'farms': 1,
    'farmstead': 2,
    'farmworker': 3,
    'farmworkers': 3,
    'farmyard': 2,
    'farnall': 2,
    'farnam': 2,
    'farnan': 2,
    'farnborough': 3,
    'farnell': 2,
    'farner': 2,
    'farnes': 1,
    'farney': 2,
    'farnham': 2,
    'farnley': 2,
    'farnsworth': 2,
    'farnum': 2,
    'farnworth': 2,
    'faro': 2,
    'farold': 2,
    'faron': 2,
    'farone': 2,
    'farouk': 2,
    'farquar': 2,
    'farquhar': 2,
    'farquharson': 4,
    'farr': 1,
    'farra': 2,
    'farragut': 3,
    'farrah': 2,
    'farrakhan': 3,
    'farran': 2,
    'farrand': 2,
    'farrant': 2,
    'farrar': 2,
    'farrel': 2,
    'farrell': 2,
    'farrelly': 3,
    'farren': 2,
    'farrens': 2,
    'farrer': 2,
    'farrey': 2,
    'farrier': 3,
    'farrington': 3,
    'farrior': 3,
    'farris': 2,
    'farrish': 2,
    'farro': 2,
    'farron': 2,
    'farrow': 2,
    'farrowing': 3,
    'farruggia': 3,
    'farrugia': 3,
    'farry': 2,
    'farsi': 2,
    'farsighted': 3,
    'farsightedness': 4,
    'farson': 2,
    'fart': 1,
    'farther': 2,
    'farthest': 2,
    'farthing': 2,
    'farthingale': 3,
    'farve': 1,
    'farver': 2,
    'farwell': 2,
    'farwest': 2,
    'fasano': 3,
    'fasbender': 3,
    'fascell': 2,
    'fasching': 2,
    'fasciano': 4,
    'fascinate': 3,
    'fascinated': 4,
    'fascinates': 3,
    'fascinating': 4,
    'fascination': 4,
    'fascism': 3,
    'fascist': 2,
    'fascists': 2,
    'fashion': 2,
    'fashionable': 4,
    'fashionably': 4,
    'fashioned': 2,
    'fashioning': 3,
    'fashions': 2,
    'fasick': 2,
    'fasig': 2,
    'fasnacht': 2,
    'faso': 2,
    'fason': 2,
    'fasone': 3,
    'fass': 1,
    'fassbender': 3,
    'fassett': 2,
    'fassler': 2,
    'fassnacht': 2,
    'fassulo': 3,
    'fast': 1,
    'fastball': 2,
    'fastballs': 2,
    'fasted': 2,
    'fasten': 2,
    'fastened': 2,
    'fastener': 3,
    'fasteners': 3,
    'fastening': 3,
    'fastenings': 3,
    'faster': 2,
    'fastest': 2,
    'fastfood': 2,
    'fastidious': 4,
    'fasting': 2,
    'fastness': 2,
    'fasts': 1,
    'fasulo': 3,
    'fat': 1,
    'fata': 2,
    'fatah': 2,
    'fatal': 2,
    'fatalism': 4,
    'fatalist': 3,
    'fatalistic': 4,
    'fatalists': 3,
    'fatalities': 4,
    'fatality': 4,
    'fatally': 3,
    'fate': 1,
    'fated': 2,
    'fateful': 2,
    'fateh': 2,
    'fates': 1,
    'fath': 1,
    'father': 2,
    'fathered': 2,
    'fatheree': 3,
    'fatherhood': 3,
    'fathering': 3,
    'fatherland': 3,
    'fatherless': 3,
    'fatherly': 3,
    'fathers': 2,
    'fathi': 2,
    'fathom': 2,
    'fathomable': 4,
    'fathoms': 2,
    'fatigue': 2,
    'fatigued': 2,
    'fatigues': 2,
    'fatiguing': 3,
    'fatima': 3,
    'fatimah': 3,
    'fats': 1,
    'fatten': 2,
    'fattened': 2,
    'fattening': 3,
    'fatter': 2,
    'fattest': 2,
    'fatties': 2,
    'fatty': 2,
    'fatula': 3,
    'fatuous': 3,
    'fatwa': 2,
    'fatzinger': 3,
    'faubel': 2,
    'fauber': 2,
    'faubert': 2,
    'faubion': 3,
    'fauble': 2,
    'faubus': 2,
    'faucet': 2,
    'faucets': 2,
    'faucett': 2,
    'faucette': 2,
    'faucher': 2,
    'faucheux': 2,
    'fauci': 2,
    'faughn': 1,
    'faughnan': 2,
    'faught': 1,
    'faul': 1,
    'faulcon': 2,
    'faulconer': 3,
    'faulding': 2,
    'faulds': 1,
    'faulhaber': 3,
    'faulk': 1,
    'faulkenberry': 4,
    'faulkner': 2,
    'faulks': 1,
    'faull': 1,
    'faulstich': 2,
    'fault': 1,
    'faulted': 2,
    'faulting': 2,
    'faults': 1,
    'faulty': 2,
    'fauna': 2,
    'faunal': 2,
    'faunce': 1,
    'fauntleroy': 3,
    'fauntroy': 2,
    'faupel': 2,
    'faure': 1,
    'faurot': 2,
    'fauroux': 2,
    'faus': 1,
    'fauser': 2,
    'fausett': 2,
    'fausey': 2,
    'fausnaugh': 2,
    'fauss': 1,
    'faust': 1,
    'fausta': 2,
    'faustian': 2,
    'faustina': 3,
    'faustine': 2,
    'faustino': 3,
    'fausto': 2,
    'faustus': 2,
    'fauteux': 2,
    'fauth': 1,
    'fauver': 2,
    'faux': 1,
    'fava': 2,
    'favale': 3,
    'favaloro': 4,
    'favaro': 3,
    'favata': 3,
    'favazza': 3,
    'fave': 1,
    'favela': 3,
    'faver': 2,
    'favero': 3,
    'favia': 3,
    'favinger': 3,
    'favor': 2,
    'favorability': 6,
    'favorable': 4,
    'favorably': 4,
    'favored': 2,
    'favoring': 3,
    'favorite': 3,
    'favorites': 3,
    'favoritism': 5,
    'favors': 2,
    'favre': 2,
    'favreau': 2,
    'favro': 2,
    'faw': 1,
    'fawbush': 2,
    'fawcett': 2,
    'fawkes': 1,
    'fawley': 2,
    'fawn': 1,
    'fawning': 2,
    'fawver': 2,
    'fax': 1,
    'faxed': 1,
    'faxer': 2,
    'faxes': 2,
    'faxing': 2,
    'faxon': 2,
    'fay': 1,
    'fayanjuu': 3,
    'fayanne': 2,
    'fayard': 2,
    'faye': 1,
    'fayed': 1,
    'fayette': 2,
    'fayetteville': 3,
    'fayez': 2,
    'fayme': 1,
    'faymonville': 3,
    'fayne': 1,
    'fayrouz': 2,
    'faz': 1,
    'faze': 1,
    'fazed': 1,
    'fazekas': 3,
    'fazenbaker': 4,
    'fazio': 3,
    'fazzino': 3,
    'fazzio': 3,
    'fe': 1,
    'feagan': 2,
    'feagans': 2,
    'feagin': 2,
    'feagins': 2,
    'feagle': 2,
    'fealty': 3,
    'fear': 1,
    'feared': 1,
    'fearful': 2,
    'fearing': 2,
    'fearless': 2,
    'fearn': 1,
    'fearnow': 2,
    'fearon': 2,
    'fears': 1,
    'fearsome': 2,
    'feasel': 2,
    'feasibility': 5,
    'feasible': 3,
    'feasibly': 3,
    'feast': 1,
    'feasted': 2,
    'feaster': 2,
    'feasting': 2,
    'feasts': 1,
    'feat': 1,
    'feather': 2,
    'featherbed': 3,
    'featherbedding': 4,
    'feathered': 2,
    'feathering': 3,
    'featherless': 3,
    'featherly': 3,
    'feathers': 2,
    'featherston': 3,
    'featherstone': 3,
    'featherweight': 3,
    'feathery': 3,
    'feats': 1,
    'feature': 2,
    'featured': 2,
    'featureless': 3,
    'features': 2,
    'featuring': 3,
    'feazel': 2,
    'feazell': 2,
    'feb': 4,
    'febles': 2,
    'febres': 2,
    'february': 4,
    'fecal': 2,
    'feces': 2,
    'fecher': 2,
    'fechner': 2,
    'fecht': 1,
    'fechter': 2,
    'feck': 1,
    'feckless': 2,
    'fecteau': 2,
    'fecundity': 4,
    'fed': 1,
    'fedak': 2,
    'fedder': 2,
    'fedders': 2,
    'feddersen': 3,
    'fede': 1,
    'fedeccredito': 5,
    'fedele': 2,
    'fedeli': 3,
    'feder': 2,
    'federal': 3,
    'federalism': 5,
    'federalist': 4,
    'federalists': 4,
    'federalize': 4,
    'federalized': 4,
    'federalizing': 5,
    'federally': 4,
    'federals': 3,
    'federate': 3,
    'federated': 4,
    'federation': 4,
    'federations': 4,
    'federative': 4,
    'federbush': 3,
    'federer': 3,
    'federica': 4,
    'federici': 4,
    'federico': 4,
    'federle': 3,
    'federman': 3,
    'federoff': 3,
    'federov': 3,
    'federspiel': 3,
    'fedewa': 3,
    'fedex': 2,
    'fediay': 3,
    'fedler': 2,
    'fedor': 2,
    'fedora': 3,
    'fedorchak': 3,
    'fedorko': 3,
    'fedrick': 2,
    'feds': 1,
    'fee': 1,
    'feeback': 2,
    'feebis': 2,
    'feeble': 2,
    'feebly': 2,
    'feed': 1,
    'feedback': 2,
    'feeder': 2,
    'feeders': 2,
    'feeding': 2,
    'feedings': 2,
    'feedlot': 2,
    'feedlots': 2,
    'feeds': 1,
    'feedstock': 2,
    'feedstocks': 2,
    'feehan': 2,
    'feel': 1,
    'feeler': 2,
    'feelers': 2,
    'feeley': 2,
    'feeling': 2,
    'feelings': 2,
    'feels': 1,
    'feely': 2,
    'feemster': 2,
    'feeney': 2,
    'feenstra': 2,
    'feeny': 2,
    'fees': 1,
    'feese': 1,
    'feeser': 2,
    'feet': 1,
    'feezor': 2,
    'feffer': 2,
    'fegan': 2,
    'feger': 2,
    'fegley': 2,
    'feher': 2,
    'fehl': 1,
    'fehling': 2,
    'fehlman': 2,
    'fehn': 1,
    'fehnel': 2,
    'fehr': 1,
    'fehrenbach': 3,
    'fehrenbacher': 4,
    'fehring': 2,
    'fehringer': 3,
    'fehrman': 2,
    'feick': 1,
    'feickert': 2,
    'feig': 1,
    'feige': 1,
    'feigel': 2,
    'feigen': 2,
    'feigenbaum': 3,
    'feiger': 2,
    'feighan': 2,
    'feighner': 2,
    'feight': 1,
    'feigin': 2,
    'feign': 1,
    'feigned': 1,
    'feigning': 2,
    'feil': 1,
    'feild': 1,
    'feiler': 2,
    'feimster': 2,
    'fein': 1,
    'feinauer': 3,
    'feinberg': 2,
    'feiner': 2,
    'feinerman': 3,
    'feingold': 2,
    'feinman': 2,
    'feinstein': 2,
    'feint': 1,
    'feis': 1,
    'feist': 1,
    'feister': 2,
    'feisty': 2,
    'feit': 1,
    'feith': 1,
    'feitz': 1,
    'fejes': 2,
    'fekete': 2,
    'fel': 1,
    'fela': 2,
    'felan': 2,
    'felbatol': 3,
    'felber': 2,
    'felch': 1,
    'feld': 1,
    'felda': 2,
    'feldberg': 2,
    'feldblum': 2,
    'felde': 1,
    'feldene': 2,
    'felder': 2,
    'felderman': 3,
    'feldhaus': 2,
    'feldkamp': 2,
    'feldman': 2,
    'feldmann': 2,
    'feldmeier': 3,
    'feldmuehle': 3,
    'feldner': 2,
    'feldpausch': 2,
    'feldspar': 2,
    'feldspars': 2,
    'feldstein': 2,
    'feldt': 1,
    'felgenhauer': 4,
    'felger': 2,
    'felice': 2,
    'felicetti': 4,
    'felicia': 3,
    'feliciano': 5,
    'felicitate': 4,
    'felicite': 3,
    'felicitous': 4,
    'felicity': 4,
    'felim': 2,
    'feline': 2,
    'felines': 2,
    'felipe': 2,
    'felis': 2,
    'felix': 2,
    'feliz': 2,
    'felkel': 2,
    'felker': 2,
    'felkins': 2,
    'felkner': 2,
    'fell': 1,
    'fella': 2,
    'felland': 2,
    'fellas': 2,
    'fellatio': 4,
    'felled': 1,
    'felleisen': 3,
    'fellenz': 2,
    'feller': 2,
    'fellers': 2,
    'fellheimer': 3,
    'fellin': 2,
    'felling': 2,
    'fellinger': 3,
    'fellini': 3,
    'fellman': 2,
    'fellmeth': 2,
    'fellner': 2,
    'fellow': 2,
    'fellowes': 2,
    'fellows': 2,
    'fellowship': 3,
    'fellowships': 3,
    'fells': 1,
    'fellsway': 2,
    'felman': 2,
    'felmlee': 2,
    'felon': 2,
    'felonies': 3,
    'felonious': 4,
    'felons': 2,
    'felony': 3,
    'felps': 1,
    'felrice': 2,
    'fels': 1,
    'felsenthal': 3,
    'felske': 1,
    'felt': 1,
    'felten': 2,
    'felter': 2,
    'feltes': 1,
    'feltler': 2,
    'feltman': 2,
    'feltner': 2,
    'felton': 2,
    'feltrax': 2,
    'felts': 1,
    'feltsman': 2,
    'feltus': 2,
    'felty': 2,
    'feltz': 1,
    'felucca': 3,
    'felver': 2,
    'fema': 2,
    'female': 2,
    'females': 2,
    'femia': 3,
    'femina': 3,
    'feminine': 3,
    'femininity': 5,
    'feminism': 4,
    'feminist': 3,
    'feminists': 3,
    'femme': 1,
    'femoral': 3,
    'femrite': 2,
    'femsa': 2,
    'femur': 2,
    'fen': 1,
    'fenagle': 3,
    'fence': 1,
    'fenced': 1,
    'fenceless': 2,
    'fences': 2,
    'fencing': 2,
    'fencl': 2,
    'fend': 1,
    'fended': 2,
    'fender': 2,
    'fenders': 2,
    'fenderson': 3,
    'fendi': 2,
    'fending': 2,
    'fendler': 2,
    'fendley': 2,
    'fendrick': 2,
    'fends': 1,
    'fendt': 1,
    'fenech': 2,
    'fenella': 3,
    'fenelon': 3,
    'fenfluramine': 4,
    'feng': 1,
    'fenger': 2,
    'fenian': 3,
    'fenichell': 3,
    'fenimore': 3,
    'fenjves': 3,
    'fenley': 2,
    'fenlon': 2,
    'fenn': 1,
    'fennel': 2,
    'fennell': 2,
    'fennelly': 3,
    'fennema': 3,
    'fenner': 2,
    'fennessey': 3,
    'fennessy': 3,
    'fennewald': 3,
    'fenney': 2,
    'fennimore': 3,
    'fenno': 2,
    'fenoglio': 4,
    'fenphen': 2,
    'fenske': 1,
    'fenster': 2,
    'fenstermacher': 4,
    'fenstermaker': 4,
    'fensterstock': 3,
    'fent': 1,
    'fenter': 2,
    'fenton': 2,
    'fentress': 2,
    'fenugreek': 3,
    'fenway': 2,
    'fenwick': 2,
    'fenwood': 2,
    'fenyvessy': 4,
    'fenzel': 2,
    'feodor': 3,
    'feodora': 4,
    'feodorovich': 5,
    'feola': 3,
    'fer': 1,
    'fera': 2,
    'feral': 2,
    'feraluzi': 4,
    'ferarro': 3,
    'ferber': 2,
    'ferch': 1,
    'ferd': 1,
    'ferderer': 3,
    'ferdie': 2,
    'ferdig': 2,
    'ferdinand': 3,
    'ferdinanda': 4,
    'ferdlow': 2,
    'ferdon': 2,
    'ferebee': 3,
    'ferenc': 2,
    'ference': 2,
    'ferencz': 2,
    'feretlow': 3,
    'ferg': 1,
    'fergason': 3,
    'ferger': 2,
    'fergerson': 3,
    'fergeson': 3,
    'fergie': 2,
    'fergus': 2,
    'ferguson': 3,
    'fergusson': 3,
    'feria': 3,
    'ferko': 2,
    'ferland': 2,
    'ferm': 1,
    'ferman': 2,
    'ferment': 2,
    'fermenta': 3,
    'fermentation': 4,
    'fermented': 3,
    'fermenting': 3,
    'ferments': 2,
    'fermi': 2,
    'fermilab': 3,
    'fermin': 2,
    'fermium': 3,
    'fermoyle': 2,
    'fern': 1,
    'fernald': 2,
    'fernand': 2,
    'fernanda': 3,
    'fernandes': 3,
    'fernandez': 3,
    'fernandina': 4,
    'fernando': 3,
    'fernao': 2,
    'fernau': 2,
    'fernbank': 2,
    'fernberg': 2,
    'ferndale': 2,
    'ferner': 2,
    'fernery': 3,
    'fernholz': 2,
    'fernicola': 4,
    'fernlike': 2,
    'ferns': 1,
    'fernstrom': 2,
    'fero': 2,
    'ferocious': 3,
    'ferociously': 4,
    'ferocity': 4,
    'ferol': 2,
    'ferra': 2,
    'ferraioli': 4,
    'ferrall': 2,
    'ferran': 2,
    'ferrand': 2,
    'ferrandino': 4,
    'ferrando': 3,
    'ferrant': 2,
    'ferrante': 3,
    'ferranti': 3,
    'ferrar': 2,
    'ferrara': 3,
    'ferrari': 3,
    'ferrario': 4,
    'ferraris': 3,
    'ferraro': 3,
    'ferre': 1,
    'ferrebee': 3,
    'ferree': 2,
    'ferreira': 3,
    'ferreiro': 3,
    'ferrel': 2,
    'ferrell': 2,
    'ferrelli': 3,
    'ferren': 2,
    'ferrency': 3,
    'ferrentino': 4,
    'ferrer': 2,
    'ferrera': 3,
    'ferreri': 3,
    'ferrero': 3,
    'ferret': 2,
    'ferreted': 3,
    'ferreting': 3,
    'ferrets': 2,
    'ferretti': 3,
    'ferri': 2,
    'ferrick': 2,
    'ferrie': 2,
    'ferried': 2,
    'ferrier': 3,
    'ferriera': 3,
    'ferriers': 3,
    'ferries': 2,
    'ferrigno': 3,
    'ferrill': 2,
    'ferrin': 2,
    'ferrington': 3,
    'ferrini': 3,
    'ferris': 2,
    'ferriss': 3,
    'ferrite': 2,
    'ferriter': 3,
    'ferriz': 2,
    'ferro': 2,
    'ferrochrome': 3,
    'ferrofluidic': 5,
    'ferrofluidics': 5,
    'ferromagnet': 4,
    'ferromagnetic': 5,
    'ferromagnetism': 6,
    'ferron': 2,
    'ferrone': 3,
    'ferronickel': 4,
    'ferrotype': 3,
    'ferrous': 2,
    'ferrucci': 3,
    'ferruzzi': 3,
    'ferry': 2,
    'ferryboat': 3,
    'ferryboats': 3,
    'ferrying': 3,
    'ferryman': 3,
    'fertig': 2,
    'fertile': 2,
    'fertility': 4,
    'fertilization': 5,
    'fertilize': 3,
    'fertilized': 3,
    'fertilizer': 4,
    'fertilizers': 4,
    'fertilizes': 4,
    'fertilizing': 4,
    'fertitta': 3,
    'fertlow': 2,
    'ferullo': 3,
    'fervent': 2,
    'fervently': 3,
    'fervid': 2,
    'fervor': 2,
    'fery': 2,
    'feschbach': 2,
    'fescue': 2,
    'feser': 2,
    'feshbach': 2,
    'fesler': 3,
    'fesmire': 2,
    'fesperman': 3,
    'fess': 1,
    'fessed': 1,
    'fessel': 2,
    'fessenden': 3,
    'fessler': 2,
    'fest': 1,
    'festa': 2,
    'fester': 2,
    'festered': 2,
    'festering': 3,
    'festiva': 3,
    'festival': 3,
    'festivals': 3,
    'festive': 2,
    'festivities': 4,
    'festivity': 4,
    'festoon': 2,
    'festooned': 2,
    'fests': 1,
    'festspielhaus': 3,
    'festus': 2,
    'fetal': 2,
    'fetch': 1,
    'fetched': 1,
    'fetches': 2,
    'fetching': 2,
    'fete': 1,
    'feted': 2,
    'fetherolf': 3,
    'fetherston': 3,
    'fetid': 2,
    'fetish': 2,
    'fetishism': 4,
    'fetishist': 3,
    'fetishists': 3,
    'fetner': 2,
    'fetrow': 2,
    'fetsch': 1,
    'fetsko': 2,
    'fett': 1,
    'fette': 1,
    'fetter': 2,
    'fettered': 2,
    'fetterhoff': 3,
    'fetterly': 3,
    'fetterman': 3,
    'fetterolf': 3,
    'fetters': 2,
    'fettes': 1,
    'fettig': 2,
    'fetting': 2,
    'fettuccine': 4,
    'fetty': 2,
    'fetus': 2,
    'fetuses': 3,
    'fetz': 1,
    'fetzer': 2,
    'feucht': 1,
    'feud': 1,
    'feudal': 2,
    'feudalism': 4,
    'feudalistic': 4,
    'feuded': 2,
    'feuding': 2,
    'feuds': 1,
    'feuer': 2,
    'feuerborn': 3,
    'feuerman': 3,
    'feuerstein': 3,
    'feulner': 2,
    'feur': 1,
    'feustel': 2,
    'feutz': 1,
    'fever': 2,
    'fevered': 2,
    'feverfew': 3,
    'feverfews': 3,
    'feverish': 3,
    'feverishly': 4,
    'fevers': 2,
    'few': 1,
    'fewell': 2,
    'fewer': 2,
    'fewest': 2,
    'fey': 1,
    'feyen': 1,
    'feynman': 2,
    'fez': 1,
    'fi': 1,
    'fiacco': 3,
    'fiala': 3,
    'fialkowski': 4,
    'fiance': 3,
    'fiancee': 3,
    'fianna': 3,
    'fiasco': 3,
    'fiascos': 3,
    'fiat': 2,
    'fiato': 3,
    'fiber': 2,
    'fiberboard': 3,
    'fiberglas': 3,
    'fiberglass': 3,
    'fiberoptic': 4,
    'fibers': 2,
    'fiberweb': 3,
    'fibonacci': 4,
    'fibre': 2,
    'fibreboard': 3,
    'fibrile': 2,
    'fibriles': 2,
    'fibrillation': 4,
    'fibrin': 2,
    'fibroid': 2,
    'fibroids': 2,
    'fibronic': 3,
    'fibronics': 3,
    'fibrosis': 3,
    'fibrous': 2,
    'fibula': 3,
    'fica': 2,
    'ficarra': 3,
    'ficco': 2,
    'ficek': 2,
    'fichera': 3,
    'fichtel': 2,
    'fichter': 2,
    'fichtner': 2,
    'fichus': 2,
    'ficials': 2,
    'fick': 1,
    'ficke': 1,
    'fickel': 2,
    'ficken': 2,
    'ficker': 2,
    'fickes': 1,
    'fickett': 2,
    'fickle': 2,
    'fickleness': 3,
    'ficklin': 2,
    'fickling': 2,
    'fico': 2,
    'ficom': 2,
    'ficorca': 3,
    'fiction': 2,
    'fictional': 3,
    'fictionalize': 4,
    'fictionalized': 4,
    'fictions': 2,
    'fictitious': 3,
    'fidalgo': 3,
    'fidata': 3,
    'fidder': 2,
    'fiddle': 2,
    'fiddled': 2,
    'fiddler': 3,
    'fiddlers': 3,
    'fiddles': 2,
    'fiddling': 2,
    'fide': 1,
    'fidel': 2,
    'fidelco': 3,
    'fidelcor': 3,
    'fidelia': 3,
    'fidelities': 4,
    'fidelity': 4,
    'fidenas': 3,
    'fides': 1,
    'fidget': 2,
    'fidgeting': 3,
    'fidler': 3,
    'fido': 2,
    'fidora': 3,
    'fiduciares': 4,
    'fiduciaries': 5,
    'fiduciary': 5,
    'fiebelkorn': 3,
    'fieber': 2,
    'fiebig': 2,
    'fiebiger': 3,
    'fiechter': 2,
    'fiedler': 2,
    'fief': 1,
    'fiefdom': 2,
    'fiefdoms': 2,
    'fiefs': 1,
    'fiegel': 2,
    'fieger': 2,
    'fiel': 1,
    'field': 1,
    'fieldcrest': 2,
    'fielded': 2,
    'fielden': 2,
    'fielder': 2,
    'fielders': 2,
    'fieldhouse': 2,
    'fielding': 2,
    'fields': 1,
    'fieldwork': 2,
    'fiend': 1,
    'fiendish': 2,
    'fiends': 1,
    'fiene': 1,
    'fiennes': 3,
    'fier': 1,
    'fierce': 1,
    'fiercely': 2,
    'fiercer': 2,
    'fiercest': 2,
    'fierman': 2,
    'fiero': 2,
    'fieros': 3,
    'fierro': 2,
    'fierros': 2,
    'fiers': 1,
    'fierst': 1,
    'fierstein': 2,
    'fiery': 3,
    'fieser': 2,
    'fiest': 2,
    'fiesta': 3,
    'fife': 1,
    'fifer': 2,
    'fifi': 2,
    'fifield': 2,
    'fiftee': 2,
    'fifteen': 2,
    'fifteens': 2,
    'fifteenth': 2,
    'fifth': 1,
    'fifths': 1,
    'fifties': 2,
    'fiftieth': 3,
    'fifty': 2,
    'fig': 1,
    'figallo': 3,
    'figaro': 3,
    'figaroa': 4,
    'figel': 2,
    'figg': 1,
    'figge': 1,
    'figgie': 2,
    'figgins': 2,
    'figgs': 1,
    'fight': 1,
    'fighter': 2,
    'fighters': 2,
    'fighting': 2,
    'fightings': 2,
    'fightmaster': 3,
    'fights': 1,
    'figler': 3,
    'figley': 2,
    'figment': 2,
    'figments': 2,
    'figone': 2,
    'figs': 1,
    'figueira': 3,
    'figueiredo': 4,
    'figueras': 3,
    'figueredo': 4,
    'figueroa': 4,
    'figura': 3,
    'figuration': 4,
    'figurative': 4,
    'figuratively': 5,
    'figure': 2,
    'figured': 2,
    'figurehead': 3,
    'figures': 2,
    'figurine': 3,
    'figurines': 3,
    'figuring': 3,
    'figurski': 3,
    'fiji': 2,
    'fijian': 3,
    'fijians': 3,
    'fike': 1,
    'fikes': 1,
    'fil': 1,
    'fila': 2,
    'filament': 3,
    'filaments': 3,
    'filbert': 2,
    'filberts': 2,
    'filbrick': 2,
    'filby': 2,
    'filch': 1,
    'filched': 1,
    'fildes': 1,
    'file': 1,
    'filed': 1,
    'filegate': 2,
    'filene': 2,
    'filenet': 2,
    'filer': 2,
    'filers': 2,
    'files': 1,
    'filet': 2,
    'filets': 2,
    'filial': 3,
    'filibuster': 4,
    'filibustered': 4,
    'filibustering': 5,
    'filibusters': 4,
    'filice': 2,
    'filigree': 3,
    'filigrees': 3,
    'filing': 2,
    'filings': 2,
    'filinski': 3,
    'filion': 2,
    'filip': 2,
    'filipa': 3,
    'filipek': 3,
    'filipiak': 4,
    'filipino': 4,
    'filipinos': 4,
    'filipkowski': 4,
    'filipovic': 4,
    'filipowicz': 4,
    'filippelli': 4,
    'filippello': 4,
    'filippi': 3,
    'filippini': 4,
    'filippo': 3,
    'filippone': 3,
    'filipski': 3,
    'filkins': 2,
    'fill': 1,
    'filla': 2,
    'fillauer': 3,
    'filled': 1,
    'filler': 2,
    'fillers': 2,
    'fillet': 2,
    'filleted': 3,
    'fillets': 2,
    'filley': 2,
    'fillibuster': 4,
    'fillibusters': 4,
    'fillies': 2,
    'filling': 2,
    'fillingame': 4,
    'fillinger': 3,
    'fillingham': 3,
    'fillingim': 3,
    'fillings': 2,
    'fillion': 2,
    'fillip': 2,
    'fillman': 2,
    'fillmore': 2,
    'fills': 1,
    'filly': 2,
    'film': 1,
    'filma': 2,
    'filmed': 1,
    'filmer': 2,
    'filming': 2,
    'filmless': 2,
    'filmmaker': 3,
    'filmmakers': 3,
    'filmmaking': 3,
    'filmore': 2,
    'films': 1,
    'filo': 2,
    'filofax': 3,
    'filomena': 4,
    'filosa': 3,
    'filsinger': 3,
    'filson': 2,
    'filter': 2,
    'filtered': 2,
    'filtering': 3,
    'filters': 2,
    'filtertek': 3,
    'filth': 1,
    'filthy': 2,
    'filtration': 3,
    'filtrenelli': 4,
    'filtrol': 2,
    'filyaw': 3,
    'fima': 2,
    'fimbres': 2,
    'fin': 1,
    'fina': 2,
    'finagle': 3,
    'finagler': 3,
    'final': 2,
    'finalco': 3,
    'finale': 3,
    'finalist': 3,
    'finalists': 3,
    'finality': 4,
    'finalize': 3,
    'finalized': 3,
    'finalizes': 4,
    'finalizing': 4,
    'finally': 3,
    'finals': 2,
    'finamore': 3,
    'finan': 2,
    'finance': 2,
    'financed': 2,
    'financement': 3,
    'financer': 3,
    'finances': 3,
    'financial': 3,
    'financially': 4,
    'financials': 3,
    'financier': 3,
    'financiera': 5,
    'financiere': 3,
    'financiero': 5,
    'financiers': 3,
    'financing': 3,
    'financings': 3,
    'financo': 3,
    'finanz': 2,
    'finanza': 3,
    'finanziaria': 6,
    'finanziario': 6,
    'finarte': 2,
    'finazzo': 3,
    'finbar': 2,
    'finberg': 2,
    'fincannon': 3,
    'finch': 1,
    'fincham': 2,
    'fincher': 2,
    'finches': 2,
    'finchum': 2,
    'finck': 1,
    'fincke': 1,
    'finckle': 2,
    'find': 1,
    'finder': 2,
    'finders': 2,
    'finding': 2,
    'findings': 2,
    'findlay': 2,
    'findler': 2,
    'findley': 2,
    'findling': 3,
    'finds': 1,
    'fine': 1,
    'fineberg': 2,
    'fined': 1,
    'finefrock': 2,
    'finegan': 3,
    'finegold': 2,
    'finella': 3,
    'finelli': 3,
    'finely': 2,
    'fineman': 2,
    'finer': 2,
    'fineran': 3,
    'finerty': 3,
    'finery': 3,
    'fines': 1,
    'finesse': 2,
    'finessed': 2,
    'finest': 2,
    'finestone': 2,
    'finevest': 2,
    'finex': 2,
    'finfrock': 2,
    'finger': 2,
    'fingerboard': 3,
    'fingered': 2,
    'fingerhut': 3,
    'fingering': 3,
    'fingernail': 3,
    'fingernails': 3,
    'fingerpaint': 3,
    'fingerpainted': 4,
    'fingerpointing': 4,
    'fingerprint': 3,
    'fingerprinted': 4,
    'fingerprinting': 4,
    'fingerprints': 3,
    'fingers': 2,
    'fingertip': 3,
    'fingertips': 3,
    'fini': 2,
    'finian': 3,
    'finicky': 3,
    'finigan': 3,
    'fining': 2,
    'fininvest': 3,
    'finish': 2,
    'finished': 2,
    'finisher': 3,
    'finishers': 3,
    'finishes': 3,
    'finishing': 3,
    'finite': 2,
    'fink': 1,
    'finkbeiner': 3,
    'finkbiner': 3,
    'finke': 1,
    'finkel': 2,
    'finkelman': 3,
    'finkelson': 3,
    'finkelstein': 3,
    'finken': 2,
    'finkenbinder': 4,
    'finkielstain': 3,
    'finkle': 2,
    'finklea': 3,
    'finkler': 2,
    'finkley': 2,
    'finks': 1,
    'finland': 2,
    'finlandization': 5,
    'finlay': 2,
    'finlayson': 3,
    'finlets': 2,
    'finley': 2,
    'finmeccanica': 5,
    'finn': 1,
    'finnair': 2,
    'finnan': 2,
    'finne': 1,
    'finnegan': 3,
    'finnell': 2,
    'finneran': 3,
    'finnerty': 3,
    'finney': 2,
    'finnick': 2,
    'finnicum': 3,
    'finnie': 2,
    'finnigan': 3,
    'finning': 2,
    'finnish': 2,
    'finns': 1,
    'fino': 2,
    'finocchiaro': 5,
    'finocchio': 4,
    'fins': 1,
    'finscher': 2,
    'finsider': 3,
    'finstad': 2,
    'finster': 2,
    'finstrom': 2,
    'finton': 2,
    'finucane': 3,
    'finunc': 2,
    'finzel': 2,
    'fiola': 3,
    'fiona': 3,
    'fioravanti': 5,
    'fiore': 3,
    'fiorella': 4,
    'fiorelli': 4,
    'fiorello': 4,
    'fiorentino': 5,
    'fiorenza': 4,
    'fioretti': 4,
    'fiori': 3,
    'fiorillo': 4,
    'fiorina': 4,
    'fiorini': 4,
    'fiorino': 4,
    'fiorito': 4,
    'fiorucci': 4,
    'fipple': 2,
    'fipps': 1,
    'fir': 1,
    'fire': 2,
    'firearm': 3,
    'firearms': 3,
    'fireball': 3,
    'fireballs': 3,
    'firebaugh': 2,
    'firebird': 3,
    'firebirds': 3,
    'firebomb': 3,
    'firebombed': 2,
    'firebombing': 4,
    'firebombs': 2,
    'firebrand': 3,
    'firebrands': 3,
    'firebrick': 3,
    'firebug': 3,
    'firebush': 3,
    'firecracker': 4,
    'firecrackers': 4,
    'fired': 2,
    'firedamp': 3,
    'firefight': 2,
    'firefighter': 3,
    'firefighters': 4,
    'firefighting': 3,
    'firefights': 2,
    'fireflies': 3,
    'firefly': 3,
    'firehouse': 3,
    'firehouses': 4,
    'fireman': 2,
    'firemen': 2,
    'firenza': 3,
    'firenzas': 3,
    'fireplace': 3,
    'fireplaces': 4,
    'firepower': 3,
    'fireproof': 3,
    'fireproofing': 4,
    'fires': 2,
    'fireside': 3,
    'firesign': 3,
    'firestine': 3,
    'firestone': 2,
    'firestorm': 2,
    'firewall': 2,
    'fireweed': 3,
    'firewood': 3,
    'firework': 2,
    'fireworks': 2,
    'firfer': 2,
    'firing': 2,
    'firings': 2,
    'firino': 3,
    'firkins': 2,
    'firkus': 2,
    'firm': 1,
    'firma': 2,
    'firman': 2,
    'firmans': 2,
    'firmed': 1,
    'firmer': 2,
    'firmest': 2,
    'firmin': 2,
    'firming': 2,
    'firmly': 2,
    'firmness': 2,
    'firms': 1,
    'firpo': 2,
    'firs': 1,
    'first': 1,
    'firstar': 2,
    'firstborn': 2,
    'firstfed': 2,
    'firsthand': 2,
    'firstier': 2,
    'firstly': 2,
    'firsts': 1,
    'firstsouth': 2,
    'firth': 1,
    'firzite': 2,
    'fis': 1,
    'fisc': 1,
    'fiscal': 2,
    'fiscally': 3,
    'fisch': 1,
    'fischbach': 2,
    'fischbein': 2,
    'fischel': 2,
    'fischer': 2,
    'fischetti': 3,
    'fischl': 2,
    'fischler': 3,
    'fischman': 2,
    'fiscus': 2,
    'fiser': 2,
    'fiserv': 2,
    'fisette': 2,
    'fish': 1,
    'fishbach': 2,
    'fishback': 2,
    'fishbaine': 2,
    'fishbaugh': 2,
    'fishbeck': 2,
    'fishbein': 2,
    'fishbowl': 2,
    'fishburn': 2,
    'fishburne': 2,
    'fisheater': 3,
    'fisheaters': 3,
    'fished': 1,
    'fishel': 2,
    'fishell': 2,
    'fisher': 2,
    'fisheries': 3,
    'fisherman': 3,
    'fishermen': 3,
    'fishers': 2,
    'fishery': 3,
    'fishes': 2,
    'fishing': 2,
    'fishkill': 2,
    'fishkin': 2,
    'fishlow': 2,
    'fishman': 2,
    'fishmonger': 3,
    'fishy': 2,
    'fisk': 1,
    'fiske': 1,
    'fisler': 3,
    'fisons': 2,
    'fiss': 1,
    'fissel': 2,
    'fissell': 2,
    'fissile': 2,
    'fission': 2,
    'fissionable': 4,
    'fissure': 2,
    'fissured': 2,
    'fissures': 2,
    'fist': 1,
    'fisted': 2,
    'fister': 2,
    'fistful': 2,
    'fisticuff': 3,
    'fisticuffs': 3,
    'fists': 1,
    'fit': 1,
    'fitak': 2,
    'fitch': 1,
    'fitchburg': 2,
    'fitchett': 2,
    'fite': 1,
    'fites': 1,
    'fitful': 2,
    'fitfully': 3,
    'fithe': 1,
    'fithian': 3,
    'fitness': 2,
    'fits': 1,
    'fitswater': 3,
    'fitt': 1,
    'fitted': 2,
    'fitter': 2,
    'fitterer': 3,
    'fittest': 2,
    'fitting': 2,
    'fittingly': 3,
    'fittings': 2,
    'fittipaldi': 4,
    'fitton': 2,
    'fittro': 2,
    'fitts': 1,
    'fitz': 1,
    'fitzco': 2,
    'fitzer': 2,
    'fitzgerald': 3,
    'fitzgibbon': 3,
    'fitzgibbons': 3,
    'fitzharris': 3,
    'fitzhenry': 4,
    'fitzhugh': 2,
    'fitzmaurice': 3,
    'fitzmorris': 3,
    'fitzner': 2,
    'fitzpatrick': 3,
    'fitzroy': 2,
    'fitzsimmons': 3,
    'fitzsimons': 3,
    'fitzwater': 3,
    'fitzwilliam': 3,
    'fiumara': 4,
    'five': 1,
    'fiveash': 2,
    'fivecoat': 2,
    'fivefold': 2,
    'fives': 1,
    'fix': 1,
    'fixable': 3,
    'fixate': 2,
    'fixated': 3,
    'fixates': 2,
    'fixation': 3,
    'fixative': 3,
    'fixatives': 3,
    'fixed': 1,
    'fixer': 2,
    'fixes': 2,
    'fixing': 2,
    'fixings': 2,
    'fixler': 2,
    'fixmer': 2,
    'fixture': 2,
    'fixtures': 2,
    'fizer': 2,
    'fizz': 1,
    'fizzle': 2,
    'fizzled': 2,
    'fizzles': 2,
    'fizzling': 3,
    'fjeld': 1,
    'fjelstad': 2,
    'fjord': 1,
    'fjords': 1,
    'flab': 1,
    'flabbergast': 3,
    'flabbergasted': 4,
    'flabby': 2,
    'flaccid': 2,
    'flach': 1,
    'flack': 1,
    'flad': 1,
    'flag': 1,
    'flagday': 2,
    'flagella': 3,
    'flagellate': 3,
    'flagellated': 4,
    'flagellum': 3,
    'flageolet': 3,
    'flagg': 1,
    'flagged': 1,
    'flagging': 2,
    'flagler': 2,
    'flagman': 2,
    'flagmen': 2,
    'flagofficer': 4,
    'flagofficers': 4,
    'flagpole': 2,
    'flagpoles': 2,
    'flagrant': 2,
    'flagrantly': 3,
    'flags': 1,
    'flagship': 2,
    'flagships': 2,
    'flagstaff': 2,
    'flagstaffs': 2,
    'flagstar': 2,
    'flagstone': 2,
    'flagstones': 2,
    'flaharty': 2,
    'flaherty': 2,
    'flahive': 2,
    'flaig': 1,
    'flail': 1,
    'flailing': 2,
    'flaim': 1,
    'flair': 1,
    'flak': 1,
    'flake': 1,
    'flakes': 1,
    'flaking': 2,
    'flaky': 2,
    'flam': 1,
    'flamboyance': 3,
    'flamboyant': 3,
    'flamboyantly': 4,
    'flame': 1,
    'flamed': 1,
    'flamemaster': 3,
    'flamenco': 3,
    'flamer': 2,
    'flames': 1,
    'flaming': 2,
    'flamingo': 3,
    'flamingos': 3,
    'flaminian': 4,
    'flamm': 1,
    'flammability': 5,
    'flammable': 3,
    'flammang': 2,
    'flammer': 2,
    'flammia': 3,
    'flamson': 2,
    'flan': 1,
    'flanagan': 3,
    'flanagin': 3,
    'flanary': 3,
    'flanders': 2,
    'flanery': 3,
    'flange': 1,
    'flanges': 2,
    'flanigan': 3,
    'flank': 1,
    'flanked': 1,
    'flanking': 2,
    'flanks': 1,
    'flann': 1,
    'flanna': 2,
    'flannagan': 3,
    'flannel': 2,
    'flanneled': 2,
    'flannels': 2,
    'flannery': 3,
    'flannigan': 3,
    'flansburg': 2,
    'flap': 1,
    'flapdoodle': 3,
    'flapjack': 2,
    'flapjacks': 2,
    'flaplike': 2,
    'flapped': 1,
    'flapper': 2,
    'flappers': 2,
    'flapping': 2,
    'flaps': 1,
    'flare': 1,
    'flared': 1,
    'flares': 1,
    'flaring': 2,
    'flaringly': 3,
    'flasch': 1,
    'flash': 1,
    'flashback': 2,
    'flashbacks': 2,
    'flashbulb': 2,
    'flashbulbs': 2,
    'flashcard': 2,
    'flashcards': 2,
    'flashdance': 2,
    'flashdances': 3,
    'flashed': 1,
    'flasher': 2,
    'flashers': 2,
    'flashes': 2,
    'flashgun': 2,
    'flashguns': 2,
    'flashier': 3,
    'flashiest': 3,
    'flashing': 2,
    'flashlight': 2,
    'flashlights': 2,
    'flashpoint': 2,
    'flashpoints': 2,
    'flashy': 2,
    'flask': 1,
    'flasks': 1,
    'flat': 1,
    'flatau': 2,
    'flatbed': 2,
    'flatboat': 2,
    'flatbush': 2,
    'flaten': 2,
    'flater': 2,
    'flatfish': 2,
    'flatfishes': 3,
    'flath': 1,
    'flathead': 2,
    'flatheads': 2,
    'flathers': 2,
    'flatland': 2,
    'flatlands': 2,
    'flatley': 2,
    'flatly': 2,
    'flatness': 2,
    'flato': 2,
    'flats': 1,
    'flatt': 1,
    'flatten': 2,
    'flattened': 2,
    'flattening': 3,
    'flatter': 2,
    'flattered': 2,
    'flattering': 3,
    'flattery': 3,
    'flattish': 2,
    'flatulent': 3,
    'flatware': 2,
    'flatworm': 2,
    'flaubert': 2,
    'flaugh': 1,
    'flaugher': 2,
    'flaum': 1,
    'flaunt': 1,
    'flaunted': 2,
    'flaunting': 2,
    'flaunts': 1,
    'flavell': 2,
    'flavia': 3,
    'flavier': 2,
    'flavin': 2,
    'flavio': 3,
    'flavius': 3,
    'flavor': 2,
    'flavored': 2,
    'flavorful': 3,
    'flavoring': 3,
    'flavorings': 3,
    'flavorist': 3,
    'flavorists': 3,
    'flavors': 2,
    'flaw': 1,
    'flawed': 1,
    'flawless': 2,
    'flawlessly': 3,
    'flawn': 1,
    'flaws': 1,
    'flax': 1,
    'flaxman': 2,
    'flaxseed': 2,
    'flay': 1,
    'flayed': 1,
    'flea': 1,
    'fleagle': 2,
    'fleas': 1,
    'fleck': 1,
    'fleckenstein': 3,
    'flecks': 1,
    'fled': 1,
    'fledermaus': 3,
    'fledge': 1,
    'fledged': 1,
    'fledging': 2,
    'fledgling': 2,
    'flee': 1,
    'fleece': 1,
    'fleeced': 1,
    'fleeces': 2,
    'fleecing': 2,
    'fleecy': 2,
    'fleeger': 2,
    'fleeing': 2,
    'fleek': 1,
    'fleeman': 2,
    'fleener': 2,
    'fleenor': 2,
    'fleer': 1,
    'flees': 1,
    'fleet': 1,
    'fleeting': 2,
    'fleetingly': 3,
    'fleets': 1,
    'fleetwood': 2,
    'flegal': 2,
    'flegel': 2,
    'fleharty': 3,
    'fleig': 1,
    'fleisch': 1,
    'fleischauer': 3,
    'fleischer': 2,
    'fleischhacker': 3,
    'fleischman': 2,
    'fleischmann': 2,
    'fleisher': 2,
    'fleishman': 2,
    'fleiss': 1,
    'fleissner': 2,
    'fleitas': 2,
    'fleming': 2,
    'flemings': 2,
    'flemington': 3,
    'flemish': 2,
    'flemister': 3,
    'flemmer': 2,
    'flemming': 2,
    'flemons': 2,
    'flener': 2,
    'flenner': 2,
    'flenniken': 3,
    'flerfu': 2,
    'flesch': 1,
    'flesh': 1,
    'fleshed': 1,
    'flesher': 2,
    'fleshman': 2,
    'fleshy': 2,
    'flesner': 2,
    'flessner': 2,
    'fleta': 2,
    'fletchall': 2,
    'fletcher': 2,
    'flett': 1,
    'fleur': 1,
    'fleurette': 2,
    'fleuri': 2,
    'fleury': 2,
    'flew': 1,
    'flewellen': 3,
    'flewelling': 3,
    'flex': 1,
    'flexed': 1,
    'flexer': 2,
    'flexes': 2,
    'flexi': 2,
    'flexibility': 5,
    'flexible': 3,
    'flexibly': 3,
    'flexing': 2,
    'flexion': 2,
    'flexnor': 2,
    'flextime': 2,
    'flextronic': 3,
    'flextronics': 3,
    'flicek': 2,
    'flick': 1,
    'flicker': 2,
    'flickered': 2,
    'flickering': 3,
    'flickers': 2,
    'flicking': 2,
    'flickinger': 3,
    'flicks': 1,
    'flied': 1,
    'fliegel': 2,
    'flier': 2,
    'fliers': 2,
    'flies': 1,
    'flight': 1,
    'flightier': 3,
    'flightiest': 3,
    'flightless': 2,
    'flights': 1,
    'flightsafety': 3,
    'flighty': 2,
    'flim': 1,
    'flimflam': 2,
    'flimsiest': 3,
    'flimsy': 2,
    'flinch': 1,
    'flinchbaugh': 2,
    'flinched': 1,
    'flinching': 2,
    'flinchum': 2,
    'flinders': 2,
    'fling': 1,
    'flinging': 2,
    'flings': 1,
    'flink': 1,
    'flinn': 1,
    'flinner': 2,
    'flint': 1,
    'flintlock': 2,
    'flintlocks': 2,
    'flintoff': 2,
    'flints': 1,
    'flintstone': 2,
    'flintstones': 2,
    'flinty': 2,
    'flip': 1,
    'flippant': 2,
    'flipped': 1,
    'flippen': 2,
    'flipper': 2,
    'flippers': 2,
    'flippin': 2,
    'flipping': 2,
    'flippo': 2,
    'flips': 1,
    'flirt': 1,
    'flirtation': 3,
    'flirtations': 3,
    'flirtatious': 3,
    'flirted': 2,
    'flirting': 2,
    'flirts': 1,
    'flis': 1,
    'fliss': 1,
    'flit': 1,
    'flitting': 2,
    'flo': 1,
    'float': 1,
    'floated': 2,
    'floater': 2,
    'floaters': 2,
    'floating': 2,
    'floats': 1,
    'floc': 1,
    'flock': 1,
    'flocked': 1,
    'flockhart': 2,
    'flocking': 2,
    'flocks': 1,
    'flodin': 2,
    'floe': 1,
    'floersheim': 2,
    'flog': 1,
    'flogging': 2,
    'flohr': 1,
    'floirenda': 3,
    'flom': 1,
    'flonorial': 4,
    'flood': 1,
    'flooded': 2,
    'floodgate': 2,
    'floodgates': 2,
    'flooding': 2,
    'floodlight': 2,
    'floodlights': 2,
    'floodplain': 2,
    'floods': 1,
    'floodwater': 3,
    'floodwaters': 3,
    'flook': 1,
    'floor': 1,
    'floorboard': 2,
    'floorboards': 2,
    'floored': 1,
    'flooring': 2,
    'floors': 1,
    'flop': 1,
    'flopped': 1,
    'flopping': 2,
    'floppy': 2,
    'flops': 1,
    'floptical': 3,
    'flor': 1,
    'flora': 2,
    'florafax': 3,
    'floral': 2,
    'florally': 3,
    'florals': 2,
    'florance': 2,
    'flore': 1,
    'florea': 3,
    'florek': 2,
    'floren': 2,
    'florence': 2,
    'florentina': 4,
    'florentine': 3,
    'florentino': 4,
    'florer': 2,
    'flores': 2,
    'florescue': 3,
    'floresheim': 2,
    'florey': 2,
    'florez': 2,
    'flori': 2,
    'floria': 3,
    'florian': 3,
    'florid': 2,
    'florida': 3,
    'floridabanc': 4,
    'floridian': 4,
    'floridians': 4,
    'florin': 2,
    'florinda': 3,
    'florine': 3,
    'florio': 3,
    'floris': 2,
    'florist': 2,
    'florists': 2,
    'floro': 2,
    'florrie': 2,
    'florry': 2,
    'florsheim': 2,
    'flory': 2,
    'floss': 1,
    'flossie': 2,
    'flotation': 3,
    'flotilla': 3,
    'flotow': 2,
    'flotsam': 2,
    'flott': 1,
    'flotta': 2,
    'flounce': 1,
    'flounces': 2,
    'flounder': 2,
    'floundered': 2,
    'floundering': 3,
    'flounders': 2,
    'flour': 2,
    'flourish': 2,
    'flourished': 2,
    'flourishes': 3,
    'flourishing': 3,
    'flournoy': 2,
    'flours': 2,
    'flout': 1,
    'flouted': 2,
    'flouting': 2,
    'flouts': 1,
    'flow': 1,
    'flowchart': 2,
    'flowcharts': 2,
    'flowe': 1,
    'flowed': 1,
    'flower': 2,
    'flowered': 2,
    'flowering': 3,
    'flowerpot': 3,
    'flowers': 2,
    'flowery': 3,
    'flowing': 2,
    'flown': 1,
    'flows': 1,
    'flowton': 2,
    'floy': 1,
    'floyd': 1,
    'flu': 1,
    'flubs': 1,
    'fluck': 1,
    'fluctuate': 3,
    'fluctuated': 4,
    'fluctuates': 3,
    'fluctuating': 4,
    'fluctuation': 4,
    'fluctuations': 4,
    'flud': 1,
    'fludd': 1,
    'flue': 1,
    'fluegel': 2,
    'fluegge': 1,
    'fluency': 3,
    'fluent': 2,
    'fluently': 3,
    'fluet': 1,
    'fluff': 1,
    'fluffed': 1,
    'fluffier': 3,
    'fluffiest': 3,
    'fluffs': 1,
    'fluffy': 2,
    'fluharty': 3,
    'fluhr': 1,
    'fluid': 2,
    'fluidity': 4,
    'fluids': 2,
    'fluitt': 2,
    'fluke': 1,
    'fluker': 2,
    'flukes': 1,
    'flulike': 2,
    'flume': 1,
    'flumenbaum': 3,
    'flumes': 1,
    'flummox': 2,
    'flummoxed': 2,
    'flung': 1,
    'flunk': 1,
    'flunked': 1,
    'flunking': 2,
    'flunks': 1,
    'fluor': 2,
    'fluoresce': 2,
    'fluorescent': 3,
    'fluorescently': 4,
    'fluorescents': 3,
    'fluoridation': 4,
    'fluoride': 2,
    'fluorides': 2,
    'fluorine': 2,
    'fluorite': 2,
    'fluorocarbon': 4,
    'fluorocarbons': 4,
    'fluorometer': 4,
    'fluoroscopy': 4,
    'fluorspar': 2,
    'flurried': 2,
    'flurries': 2,
    'flurry': 2,
    'flury': 2,
    'flus': 1,
    'flush': 1,
    'flushed': 1,
    'flushes': 2,
    'flushing': 2,
    'fluster': 2,
    'flustered': 2,
    'flustering': 3,
    'flusters': 2,
    'flute': 1,
    'flutes': 1,
    'flutist': 2,
    'flutter': 2,
    'fluttered': 2,
    'fluttering': 3,
    'flutters': 2,
    'fluty': 2,
    'fluvial': 3,
    'flux': 1,
    'fluxional': 3,
    'fly': 1,
    'flyby': 2,
    'flycatcher': 3,
    'flye': 1,
    'flyer': 2,
    'flyers': 2,
    'flying': 2,
    'flynn': 1,
    'flynt': 1,
    'flypaper': 3,
    'flythe': 1,
    'flytrap': 2,
    'flyway': 2,
    'flywheel': 2,
    'fm': 2,
    'foal': 1,
    'foale': 1,
    'foaling': 2,
    'foam': 1,
    'foaming': 2,
    'foams': 1,
    'foamy': 2,
    'foard': 1,
    'fob': 1,
    'fobbs': 1,
    'fobel': 2,
    'fobes': 1,
    'focaccia': 4,
    'focal': 2,
    'focht': 1,
    'fochtman': 2,
    'fockler': 2,
    'focus': 2,
    'focused': 2,
    'focuses': 3,
    'focusing': 3,
    'focussed': 2,
    'fodder': 2,
    'fodera': 3,
    'fodor': 2,
    'foe': 1,
    'foehlich': 2,
    'foell': 1,
    'foeller': 2,
    'foerster': 2,
    'foertsch': 1,
    'foes': 1,
    'fog': 1,
    'fogal': 2,
    'fogarty': 3,
    'fogel': 2,
    'fogelberg': 3,
    'fogelman': 3,
    'fogelson': 3,
    'fogerty': 3,
    'fogg': 1,
    'foggiest': 3,
    'foggy': 2,
    'fogle': 2,
    'fogleman': 3,
    'fogler': 3,
    'foglesong': 3,
    'foglia': 3,
    'foglio': 3,
    'fogs': 1,
    'fogt': 1,
    'fogy': 2,
    'fohl': 1,
    'foia': 2,
    'foible': 2,
    'foibles': 2,
    'foie': 1,
    'foil': 1,
    'foiled': 1,
    'foiles': 2,
    'foiling': 2,
    'foils': 1,
    'foist': 1,
    'foisted': 2,
    'foister': 2,
    'foisy': 2,
    'fojtik': 2,
    'fokker': 2,
    'fokkers': 2,
    'folan': 2,
    'foland': 2,
    'folck': 1,
    'fold': 1,
    'folded': 2,
    'folden': 2,
    'folder': 2,
    'folders': 2,
    'folding': 2,
    'folds': 1,
    'foley': 2,
    'folger': 2,
    'folgers': 2,
    'folha': 2,
    'foliage': 2,
    'foliate': 3,
    'foliation': 4,
    'folic': 2,
    'folino': 3,
    'folk': 1,
    'folker': 2,
    'folkers': 2,
    'folkert': 2,
    'folkerts': 2,
    'folkes': 1,
    'folkish': 2,
    'folklore': 2,
    'folkman': 2,
    'folkrock': 2,
    'folks': 1,
    'folkstone': 2,
    'folksy': 2,
    'folktale': 2,
    'folkway': 2,
    'folkways': 2,
    'folland': 2,
    'follett': 2,
    'follette': 2,
    'folley': 2,
    'folliard': 2,
    'follicle': 3,
    'follicles': 3,
    'follies': 2,
    'follin': 2,
    'follis': 2,
    'follman': 2,
    'follmer': 2,
    'follow': 2,
    'followed': 2,
    'followell': 3,
    'follower': 3,
    'followers': 3,
    'following': 3,
    'followings': 3,
    'follows': 2,
    'followup': 3,
    'folly': 2,
    'folmar': 2,
    'folmer': 2,
    'folse': 1,
    'folsom': 2,
    'folson': 2,
    'folta': 2,
    'foltene': 2,
    'folts': 1,
    'foltz': 1,
    'folwell': 2,
    'folz': 1,
    'fomby': 2,
    'foment': 2,
    'fomented': 3,
    'fomenting': 3,
    'fomento': 3,
    'fomon': 2,
    'fonar': 2,
    'foncier': 2,
    'fond': 1,
    'fonda': 2,
    'fonder': 2,
    'fondest': 2,
    'fondiaria': 5,
    'fondkommission': 4,
    'fondle': 2,
    'fondled': 2,
    'fondling': 3,
    'fondly': 2,
    'fondness': 2,
    'fondren': 3,
    'fondue': 2,
    'fondues': 2,
    'fone': 1,
    'foner': 2,
    'fones': 1,
    'fong': 1,
    'fonger': 2,
    'fonner': 2,
    'fons': 1,
    'fonseca': 3,
    'font': 1,
    'fontaine': 2,
    'fontainebleau': 3,
    'fontana': 3,
    'fontanella': 4,
    'fontanez': 3,
    'fontanilla': 4,
    'fonte': 1,
    'fontenette': 3,
    'fontenot': 3,
    'fontes': 2,
    'fonts': 1,
    'fonville': 2,
    'fonz': 1,
    'fonzie': 2,
    'foo': 1,
    'foobar': 2,
    'food': 1,
    'foodarama': 4,
    'foodie': 2,
    'foodmaker': 3,
    'foodmakers': 3,
    'foods': 1,
    'foodservice': 3,
    'foodstuff': 2,
    'foodstuffs': 2,
    'foodtown': 2,
    'foodway': 2,
    'foodways': 2,
    'fooks': 1,
    'fool': 1,
    'fooled': 1,
    'foolery': 3,
    'foolhardy': 3,
    'fooling': 2,
    'foolish': 2,
    'foolishly': 3,
    'foolishness': 3,
    'foolproof': 2,
    'fools': 1,
    'foong': 1,
    'foor': 1,
    'foos': 1,
    'foose': 1,
    'fooshee': 2,
    'foot': 1,
    'footage': 2,
    'football': 2,
    'footballs': 2,
    'foote': 1,
    'footed': 2,
    'footer': 2,
    'footfall': 2,
    'foothill': 2,
    'foothills': 2,
    'foothold': 2,
    'footholds': 2,
    'footing': 2,
    'footings': 2,
    'footlight': 2,
    'footlights': 2,
    'footloose': 2,
    'footman': 2,
    'footnote': 2,
    'footnoted': 3,
    'footnotes': 2,
    'footnoting': 3,
    'footpath': 2,
    'footprint': 2,
    'footprints': 2,
    'footrace': 2,
    'footstep': 2,
    'footsteps': 2,
    'footwall': 2,
    'footware': 2,
    'footwear': 2,
    'footwork': 2,
    'fop': 1,
    'foppiano': 4,
    'for': 1,
    'fora': 2,
    'forage': 2,
    'forages': 3,
    'foraging': 3,
    'foraker': 3,
    'foramen': 3,
    'foran': 2,
    'forand': 2,
    'foray': 2,
    'forayed': 2,
    'foraying': 3,
    'forays': 2,
    'forbad': 2,
    'forbade': 2,
    'forbearance': 3,
    'forbes': 1,
    'forbeses': 2,
    'forbess': 2,
    'forbid': 2,
    'forbidden': 3,
    'forbidding': 3,
    'forbids': 2,
    'forbis': 2,
    'forbus': 2,
    'forbush': 2,
    'force': 1,
    'forced': 1,
    'forceful': 2,
    'forcefully': 3,
    'forcefulness': 3,
    'forceps': 2,
    'forces': 2,
    'forcible': 3,
    'forcibly': 3,
    'forcier': 3,
    'forcing': 2,
    'forcum': 2,
    'ford': 1,
    'forde': 1,
    'fordham': 2,
    'fordice': 2,
    'fords': 1,
    'fordyce': 2,
    'fore': 1,
    'forearm': 2,
    'forearms': 2,
    'forebear': 2,
    'forebearance': 3,
    'forebears': 2,
    'forebode': 2,
    'foreboding': 3,
    'forebrain': 2,
    'forecast': 2,
    'forecasted': 3,
    'forecaster': 3,
    'forecasters': 3,
    'forecasting': 3,
    'forecasts': 2,
    'foreclose': 2,
    'foreclosed': 2,
    'forecloses': 3,
    'foreclosing': 3,
    'foreclosure': 3,
    'foreclosures': 3,
    'foree': 2,
    'forefather': 3,
    'forefathers': 3,
    'forefinger': 3,
    'forefingers': 3,
    'forefoot': 2,
    'forefront': 2,
    'forego': 2,
    'foregoing': 3,
    'foregone': 2,
    'foreground': 2,
    'forehand': 2,
    'forehands': 2,
    'forehead': 2,
    'foreheads': 2,
    'foreign': 2,
    'foreigner': 3,
    'foreigners': 3,
    'forelimb': 2,
    'forelimbs': 2,
    'foreman': 2,
    'foremen': 2,
    'foremost': 2,
    'forensic': 3,
    'forensically': 4,
    'forensics': 3,
    'foreperson': 3,
    'foreplay': 2,
    'forero': 2,
    'forerunner': 3,
    'forerunners': 3,
    'foresaw': 2,
    'foresee': 2,
    'foreseeable': 4,
    'foreseeing': 3,
    'foreseen': 2,
    'foresees': 2,
    'foreshadow': 3,
    'foreshadowed': 3,
    'foreshadowing': 4,
    'foreshadows': 3,
    'foresight': 2,
    'foreskin': 2,
    'foresman': 2,
    'forest': 2,
    'foresta': 2,
    'forestall': 2,
    'forestalled': 2,
    'forestalling': 3,
    'forestalls': 2,
    'forested': 3,
    'forester': 3,
    'foresters': 3,
    'forestry': 3,
    'forests': 2,
    'forestville': 3,
    'foret': 1,
    'foretaste': 2,
    'foretastes': 2,
    'foretell': 2,
    'foretelling': 3,
    'forethought': 2,
    'foretold': 2,
    'forever': 3,
    'forewarn': 2,
    'forewarned': 2,
    'forewarning': 3,
    'forewarns': 2,
    'forewing': 2,
    'forewings': 2,
    'forewoman': 3,
    'forewomen': 3,
    'foreword': 2,
    'forex': 2,
    'forfeit': 2,
    'forfeitable': 4,
    'forfeited': 3,
    'forfeiting': 3,
    'forfeits': 2,
    'forfeiture': 3,
    'forfeitures': 3,
    'forgave': 2,
    'forge': 1,
    'forged': 1,
    'forger': 2,
    'forgeries': 3,
    'forgers': 2,
    'forgery': 3,
    'forges': 2,
    'forget': 2,
    'forgetful': 3,
    'forgets': 2,
    'forgettable': 4,
    'forgette': 2,
    'forgetting': 3,
    'forgey': 2,
    'forgie': 2,
    'forging': 2,
    'forgings': 2,
    'forgione': 3,
    'forgivable': 4,
    'forgive': 2,
    'forgiven': 3,
    'forgiveness': 3,
    'forgives': 2,
    'forgiving': 3,
    'forgo': 2,
    'forgoes': 2,
    'forgoing': 3,
    'forgone': 2,
    'forgot': 2,
    'forgotten': 3,
    'forgue': 1,
    'forgy': 2,
    'forhan': 2,
    'forie': 2,
    'forin': 2,
    'forinash': 3,
    'forint': 2,
    'forints': 2,
    'forister': 3,
    'fork': 1,
    'forked': 1,
    'forker': 2,
    'forkey': 2,
    'forking': 2,
    'forklift': 2,
    'forklifts': 2,
    'forkner': 2,
    'forks': 1,
    'forlenza': 3,
    'forlorn': 2,
    'form': 1,
    'forma': 2,
    'formal': 2,
    'formaldehyde': 4,
    'formalism': 4,
    'formalist': 3,
    'formalities': 4,
    'formality': 4,
    'formalization': 5,
    'formalize': 3,
    'formalized': 3,
    'formalizes': 4,
    'formalizing': 4,
    'formally': 3,
    'forman': 2,
    'formanek': 3,
    'formant': 2,
    'formants': 2,
    'format': 2,
    'formation': 3,
    'formations': 3,
    'formative': 3,
    'formato': 3,
    'formats': 2,
    'formbey': 2,
    'formby': 2,
    'formed': 1,
    'former': 2,
    'formerly': 3,
    'formic': 2,
    'formica': 3,
    'formidable': 4,
    'formidably': 4,
    'forming': 2,
    'formosa': 3,
    'formoso': 3,
    'forms': 1,
    'formula': 3,
    'formulaic': 4,
    'formulary': 4,
    'formulas': 3,
    'formulate': 3,
    'formulated': 4,
    'formulates': 3,
    'formulating': 4,
    'formulation': 4,
    'formulations': 4,
    'formylin': 3,
    'fornal': 2,
    'forner': 2,
    'fornes': 1,
    'forness': 2,
    'forney': 2,
    'forni': 2,
    'fornoff': 2,
    'fornwalt': 2,
    'forquer': 2,
    'forr': 1,
    'forrer': 2,
    'forrest': 2,
    'forrestal': 3,
    'forrester': 3,
    'forrey': 2,
    'forry': 2,
    'fors': 1,
    'forsake': 2,
    'forsaken': 3,
    'forsaking': 3,
    'forsberg': 2,
    'forsburg': 2,
    'forschner': 2,
    'forse': 1,
    'forsee': 2,
    'forseeable': 4,
    'forsell': 2,
    'forseth': 2,
    'forsgren': 2,
    'forshan': 2,
    'forshee': 2,
    'forshey': 2,
    'forslund': 2,
    'forsman': 2,
    'forson': 2,
    'forsook': 2,
    'forst': 1,
    'forster': 2,
    'forstmann': 2,
    'forstner': 2,
    'forstrom': 2,
    'forswear': 2,
    'forsworn': 2,
    'forsyth': 2,
    'forsythe': 2,
    'forsythia': 4,
    'forsythias': 4,
    'fort': 1,
    'fortas': 2,
    'forte': 2,
    'fortenberry': 4,
    'fortes': 2,
    'forth': 1,
    'forthcoming': 3,
    'forthright': 2,
    'forthrightly': 3,
    'forthrightness': 3,
    'forthwith': 2,
    'forti': 2,
    'fortier': 3,
    'forties': 2,
    'fortieth': 3,
    'fortification': 5,
    'fortifications': 5,
    'fortified': 3,
    'fortifier': 4,
    'fortifiers': 4,
    'fortify': 3,
    'fortifying': 4,
    'fortin': 2,
    'fortini': 3,
    'fortino': 3,
    'fortis': 2,
    'fortitude': 3,
    'fortman': 2,
    'fortna': 2,
    'fortner': 2,
    'fortney': 2,
    'fortnight': 2,
    'fortnightly': 3,
    'fortnights': 2,
    'forton': 2,
    'fortress': 2,
    'fortresses': 3,
    'forts': 1,
    'fortson': 2,
    'fortuitous': 4,
    'fortuna': 3,
    'fortunate': 3,
    'fortunately': 4,
    'fortunato': 4,
    'fortune': 2,
    'fortunes': 2,
    'fortuny': 3,
    'forty': 2,
    'forum': 2,
    'forums': 2,
    'forward': 2,
    'forwarded': 3,
    'forwarder': 3,
    'forwarders': 3,
    'forwarding': 3,
    'forwards': 2,
    'forwood': 2,
    'forys': 2,
    'forza': 2,
    'fosamax': 3,
    'fosback': 2,
    'fosberg': 2,
    'fosco': 2,
    'fosdick': 2,
    'foshee': 2,
    'fosia': 2,
    'foskett': 2,
    'foskey': 2,
    'fosler': 3,
    'fosnaugh': 2,
    'foss': 1,
    'fosse': 1,
    'fossel': 2,
    'fossen': 2,
    'fossett': 2,
    'fossey': 2,
    'fossil': 2,
    'fossiliferous': 5,
    'fossilize': 3,
    'fossilized': 3,
    'fossils': 2,
    'fossum': 2,
    'foster': 2,
    'fostered': 2,
    'fostering': 3,
    'fosters': 2,
    'foth': 1,
    'fothergill': 3,
    'fotheringham': 4,
    'foti': 2,
    'fotis': 2,
    'fotopoulos': 4,
    'fouad': 2,
    'fouch': 1,
    'fouche': 1,
    'foucher': 2,
    'fougere': 2,
    'fought': 1,
    'fouhy': 2,
    'foul': 1,
    'foulds': 1,
    'fouled': 1,
    'fouling': 2,
    'foulk': 1,
    'foulke': 1,
    'foulkes': 1,
    'foulks': 1,
    'foulness': 2,
    'fouls': 1,
    'found': 1,
    'foundation': 3,
    'foundational': 4,
    'foundations': 3,
    'founded': 2,
    'founder': 2,
    'foundered': 2,
    'foundering': 3,
    'founders': 2,
    'founding': 2,
    'foundling': 2,
    'foundries': 2,
    'foundry': 2,
    'fountain': 2,
    'fountaine': 2,
    'fountains': 2,
    'four': 1,
    'fourfold': 2,
    'fourier': 3,
    'fourman': 2,
    'fournet': 2,
    'fournier': 3,
    'fours': 1,
    'foursome': 2,
    'foursquare': 2,
    'fourteen': 2,
    'fourteens': 2,
    'fourteenth': 2,
    'fourth': 1,
    'fourthly': 2,
    'fourthquarter': 3,
    'fourths': 1,
    'fourtou': 2,
    'fouse': 1,
    'fousek': 2,
    'foushee': 2,
    'foust': 1,
    'fout': 1,
    'foutch': 1,
    'fouts': 1,
    'fouty': 2,
    'foutz': 1,
    'fowble': 2,
    'fowers': 2,
    'fowkes': 1,
    'fowl': 1,
    'fowle': 2,
    'fowler': 2,
    'fowles': 2,
    'fowlkes': 1,
    'fox': 1,
    'foxboro': 3,
    'foxborough': 3,
    'foxes': 2,
    'foxfire': 2,
    'foxglove': 2,
    'foxhole': 2,
    'foxholes': 2,
    'foxhound': 2,
    'foxman': 2,
    'foxmeyer': 2,
    'foxtail': 2,
    'foxtrot': 2,
    'foxwell': 2,
    'foxwood': 2,
    'foxwoods': 2,
    'foxworth': 2,
    'foxworthy': 3,
    'foxx': 1,
    'foxy': 2,
    'foy': 1,
    'foye': 1,
    'foyer': 2,
    'foyle': 1,
    'foyt': 1,
    'fraas': 1,
    'frable': 2,
    'fracas': 2,
    'frace': 1,
    'fractal': 2,
    'fraction': 2,
    'fractional': 3,
    'fractionally': 4,
    'fractions': 2,
    'fractious': 2,
    'fractiousness': 3,
    'fracture': 2,
    'fractured': 2,
    'fractures': 2,
    'fracturing': 3,
    'fradette': 2,
    'fradkin': 2,
    'frady': 2,
    'fraga': 2,
    'fragale': 3,
    'frager': 2,
    'fragile': 2,
    'fragility': 4,
    'fragment': 2,
    'fragmentary': 4,
    'fragmentation': 4,
    'fragmented': 3,
    'fragmenting': 3,
    'fragments': 2,
    'frago': 2,
    'fragonard': 3,
    'fragoso': 3,
    'fragrance': 2,
    'fragrances': 3,
    'fragrant': 2,
    'fraher': 2,
    'frahm': 1,
    'frail': 1,
    'frailey': 2,
    'frailties': 2,
    'frailty': 2,
    'fraim': 1,
    'frain': 1,
    'fraine': 1,
    'fraioli': 3,
    'fraire': 1,
    'fraiser': 2,
    'fraizer': 2,
    'fraker': 2,
    'frakes': 1,
    'fraleigh': 2,
    'fraley': 2,
    'fralick': 2,
    'fralin': 2,
    'fralix': 2,
    'fram': 1,
    'framatome': 3,
    'frame': 1,
    'framed': 1,
    'framer': 2,
    'framers': 2,
    'frames': 1,
    'framework': 2,
    'framing': 2,
    'framingham': 3,
    'frampton': 2,
    'fran': 1,
    'frana': 2,
    'franc': 1,
    'franca': 2,
    'francais': 2,
    'francaise': 2,
    'francaises': 2,
    'francavilla': 4,
    'france': 1,
    'francek': 2,
    'frances': 2,
    'francesca': 3,
    'franceschi': 3,
    'franceschini': 4,
    'francesco': 3,
    'francesconi': 4,
    'francese': 3,
    'francesmary': 4,
    'franchi': 2,
    'franchik': 2,
    'franchini': 3,
    'franchino': 3,
    'franchise': 2,
    'franchised': 2,
    'franchisee': 3,
    'franchisees': 3,
    'franchiser': 3,
    'franchisers': 3,
    'franchises': 3,
    'franchising': 3,
    'franchot': 2,
    'francia': 2,
    'francie': 2,
    'francies': 2,
    'francine': 2,
    'francines': 2,
    'francis': 2,
    'franciscan': 3,
    'franciscans': 3,
    'francisco': 3,
    'franciso': 3,
    'francisville': 3,
    'franck': 1,
    'francke': 1,
    'francklin': 2,
    'francklyn': 2,
    'franckowiak': 4,
    'franco': 2,
    'francoeur': 2,
    'francois': 2,
    'francoise': 2,
    'francom': 2,
    'francophile': 3,
    'francophone': 3,
    'francorp': 2,
    'francs': 1,
    'francy': 2,
    'francyne': 2,
    'franczak': 2,
    'frandsen': 2,
    'frane': 1,
    'franek': 2,
    'franey': 2,
    'frangos': 2,
    'franjo': 2,
    'frank': 1,
    'franke': 1,
    'franked': 1,
    'frankel': 2,
    'franken': 2,
    'frankenberg': 3,
    'frankenberger': 4,
    'frankenberry': 4,
    'frankenfield': 3,
    'frankenheimer': 4,
    'frankenstein': 3,
    'frankfort': 2,
    'frankfurt': 2,
    'frankfurter': 3,
    'frankfurters': 3,
    'frankhouser': 3,
    'frankie': 2,
    'frankiewicz': 3,
    'frankincense': 3,
    'franking': 2,
    'frankino': 3,
    'frankish': 2,
    'frankl': 2,
    'frankland': 2,
    'franklin': 2,
    'franklinite': 3,
    'franklinville': 3,
    'frankly': 2,
    'franklyn': 2,
    'frankness': 2,
    'franko': 2,
    'frankovich': 3,
    'frankowski': 3,
    'franks': 1,
    'frankson': 2,
    'frankum': 2,
    'frannie': 2,
    'franny': 2,
    'frano': 2,
    'frans': 1,
    'fransen': 2,
    'franson': 2,
    'franssen': 2,
    'franta': 2,
    'frantic': 2,
    'frantically': 4,
    'frantom': 2,
    'frantz': 1,
    'frantzen': 2,
    'franyo': 2,
    'franz': 1,
    'franze': 1,
    'franzel': 2,
    'franzen': 2,
    'franzese': 3,
    'franzone': 3,
    'franzoni': 3,
    'frap': 1,
    'fraph': 1,
    'frappier': 3,
    'frary': 2,
    'frasca': 2,
    'frascella': 3,
    'frasch': 1,
    'frasco': 2,
    'frase': 1,
    'fraser': 2,
    'frasher': 2,
    'frashier': 2,
    'frasier': 2,
    'frasure': 2,
    'fratangelo': 4,
    'frate': 1,
    'frater': 2,
    'fraternal': 3,
    'fraternities': 4,
    'fraternity': 4,
    'fraternize': 3,
    'fraternizing': 4,
    'frates': 1,
    'fratricide': 3,
    'fratricides': 3,
    'fratus': 2,
    'fratzke': 1,
    'frau': 1,
    'fraud': 1,
    'frauds': 1,
    'fraudster': 2,
    'fraudsters': 2,
    'fraudulent': 3,
    'fraudulently': 4,
    'fraught': 1,
    'fraum': 1,
    'frausto': 2,
    'fravel': 2,
    'frawley': 2,
    'fray': 1,
    'frayed': 1,
    'frayer': 2,
    'fraying': 2,
    'frayn': 1,
    'frayne': 1,
    'fraze': 1,
    'frazee': 2,
    'frazer': 2,
    'frazier': 2,
    'frazzano': 3,
    'frazzini': 3,
    'frazzle': 2,
    'frazzled': 2,
    'fread': 1,
    'freak': 1,
    'freaked': 1,
    'freaking': 2,
    'freaks': 1,
    'freakshow': 2,
    'freaky': 2,
    'frean': 1,
    'frear': 1,
    'frears': 1,
    'freas': 1,
    'frease': 1,
    'freberg': 2,
    'frech': 1,
    'frechette': 2,
    'freck': 1,
    'freckle': 2,
    'freckled': 2,
    'freckles': 2,
    'fred': 1,
    'freda': 1,
    'freddie': 2,
    'freddy': 2,
    'freddye': 2,
    'frede': 1,
    'fredell': 2,
    'fredella': 3,
    'fredenburg': 3,
    'frederic': 2,
    'frederica': 4,
    'frederick': 2,
    'fredericka': 4,
    'fredericks': 2,
    'fredericksburg': 3,
    'fredericksen': 4,
    'frederickson': 4,
    'frederico': 4,
    'frederika': 4,
    'frederiksen': 4,
    'frederking': 3,
    'fredette': 2,
    'frediani': 4,
    'fredin': 2,
    'fredkin': 2,
    'fredlund': 2,
    'fredman': 2,
    'fredo': 2,
    'fredric': 2,
    'fredrich': 2,
    'fredrick': 2,
    'fredricks': 2,
    'fredricksen': 3,
    'fredrickson': 3,
    'fredriksen': 3,
    'fredrikson': 3,
    'free': 1,
    'freeberg': 2,
    'freebie': 2,
    'freebies': 2,
    'freeborn': 2,
    'freeburg': 2,
    'freeburn': 2,
    'freed': 1,
    'freedenberg': 3,
    'freedland': 2,
    'freedle': 2,
    'freedman': 2,
    'freedom': 2,
    'freedoms': 2,
    'freefall': 2,
    'freeform': 2,
    'freeh': 1,
    'freehand': 2,
    'freehling': 2,
    'freehoff': 2,
    'freehold': 2,
    'freeholder': 3,
    'freeholders': 3,
    'freeing': 2,
    'freel': 1,
    'freelance': 2,
    'freelancer': 3,
    'freelancers': 3,
    'freelancing': 3,
    'freeland': 2,
    'freeloader': 3,
    'freeloaders': 3,
    'freelove': 2,
    'freels': 1,
    'freely': 2,
    'freeman': 2,
    'freemans': 2,
    'freemason': 3,
    'freemasonry': 4,
    'freemen': 2,
    'freemon': 2,
    'freemont': 2,
    'freemyer': 3,
    'freen': 1,
    'freeney': 2,
    'freeny': 2,
    'freeport': 2,
    'freer': 2,
    'frees': 1,
    'freese': 1,
    'freest': 2,
    'freestanding': 3,
    'freestone': 2,
    'freestyle': 2,
    'freet': 1,
    'freethinker': 3,
    'freethinkers': 3,
    'freetown': 2,
    'freeware': 2,
    'freeway': 2,
    'freeways': 2,
    'freewheeling': 3,
    'freewing': 2,
    'freeze': 1,
    'freezer': 2,
    'freezers': 2,
    'freezes': 2,
    'freezing': 2,
    'fregeau': 2,
    'fregia': 3,
    'fregoso': 3,
    'frei': 1,
    'freia': 2,
    'freiberg': 2,
    'freiberger': 3,
    'freiburger': 3,
    'freid': 1,
    'freidan': 2,
    'freidel': 2,
    'freidman': 2,
    'freidy': 2,
    'freier': 2,
    'freiermuth': 3,
    'freight': 1,
    'freighter': 2,
    'freighters': 2,
    'freightliner': 3,
    'freightway': 2,
    'freightways': 2,
    'freiheit': 2,
    'freije': 1,
    'freilich': 2,
    'freiman': 2,
    'freimark': 2,
    'freimuth': 2,
    'frein': 1,
    'freind': 1,
    'freire': 1,
    'freis': 1,
    'freise': 1,
    'freitag': 2,
    'freitas': 2,
    'freiwald': 2,
    'frel': 1,
    'frelimo': 3,
    'frels': 1,
    'fremd': 1,
    'fremiet': 2,
    'fremin': 2,
    'fremitus': 3,
    'fremont': 2,
    'french': 1,
    'frenchman': 2,
    'frenchmen': 2,
    'frenetic': 3,
    'frenette': 2,
    'freni': 2,
    'frenkel': 2,
    'frentz': 1,
    'frenulum': 3,
    'frenz': 1,
    'frenzel': 2,
    'frenzied': 2,
    'frenzies': 2,
    'frenzy': 2,
    'freon': 2,
    'freons': 2,
    'frequencies': 3,
    'frequency': 3,
    'frequent': 2,
    'frequented': 3,
    'frequenting': 3,
    'frequently': 3,
    'frequents': 2,
    'frere': 1,
    'freres': 1,
    'frerichs': 2,
    'frericks': 2,
    'frerika': 3,
    'frerking': 2,
    'fresco': 2,
    'frescoed': 2,
    'frescoes': 2,
    'frese': 1,
    'fresenius': 4,
    'fresh': 1,
    'freshbake': 2,
    'freshen': 2,
    'freshened': 2,
    'freshener': 3,
    'fresheners': 3,
    'freshening': 3,
    'freshens': 2,
    'fresher': 2,
    'freshest': 2,
    'freshkill': 2,
    'freshkills': 2,
    'freshley': 2,
    'freshly': 2,
    'freshman': 2,
    'freshmen': 2,
    'freshness': 2,
    'freshour': 2,
    'freshwater': 3,
    'fresno': 2,
    'fresquez': 2,
    'freston': 2,
    'fret': 1,
    'fretful': 2,
    'frets': 1,
    'frett': 1,
    'fretted': 2,
    'fretter': 2,
    'fretting': 2,
    'fretwell': 2,
    'fretz': 1,
    'freud': 1,
    'freudenberg': 3,
    'freudenthal': 3,
    'freudian': 3,
    'freund': 1,
    'freundlich': 2,
    'frevert': 2,
    'frew': 1,
    'frewen': 2,
    'frewin': 2,
    'frey': 1,
    'freya': 2,
    'freyer': 2,
    'freyermuth': 3,
    'freyman': 2,
    'freymiller': 3,
    'freyne': 1,
    'freyre': 1,
    'freytag': 2,
    'frezza': 2,
    'friar': 2,
    'friars': 2,
    'friary': 3,
    'frias': 2,
    'friberg': 2,
    'fribourg': 2,
    'fricano': 3,
    'frick': 1,
    'fricke': 1,
    'fricker': 2,
    'frickey': 2,
    'fricks': 1,
    'friction': 2,
    'frictionless': 3,
    'frictions': 2,
    'frida': 2,
    'friday': 2,
    'fridays': 2,
    'friddle': 2,
    'fridge': 1,
    'fridges': 2,
    'fridley': 2,
    'fridman': 2,
    'fridolf': 2,
    'fridovich': 3,
    'fridrich': 2,
    'fridson': 2,
    'frie': 2,
    'friebel': 2,
    'fried': 1,
    'frieda': 2,
    'friedan': 2,
    'friedberg': 2,
    'friede': 1,
    'friedel': 2,
    'frieden': 2,
    'friedenberg': 3,
    'frieder': 2,
    'friederich': 3,
    'friederike': 3,
    'friedhelm': 2,
    'friedhoff': 2,
    'friedl': 2,
    'friedland': 2,
    'friedlander': 3,
    'friedley': 2,
    'friedli': 2,
    'friedline': 2,
    'friedly': 2,
    'friedman': 2,
    'friedmann': 2,
    'friedrich': 2,
    'friedrichs': 2,
    'friedrichsen': 3,
    'friedrick': 2,
    'friedsam': 2,
    'friedson': 2,
    'friedt': 1,
    'friel': 1,
    'frieling': 2,
    'frieman': 2,
    'friend': 1,
    'friendlier': 3,
    'friendliest': 3,
    'friendliness': 3,
    'friendly': 2,
    'friends': 1,
    'friendship': 2,
    'friendships': 2,
    'frier': 2,
    'frierson': 2,
    'fries': 1,
    'friese': 1,
    'friesen': 2,
    'friesenhahn': 3,
    'friesner': 2,
    'frieson': 2,
    'friess': 1,
    'friesz': 1,
    'frieze': 1,
    'friezes': 2,
    'frigate': 2,
    'frigates': 2,
    'fright': 1,
    'frighten': 2,
    'frightened': 2,
    'frightening': 3,
    'frighteningly': 4,
    'frightens': 2,
    'frightful': 2,
    'frightfully': 3,
    'frigid': 2,
    'frigo': 2,
    'frigon': 2,
    'friis': 1,
    'frikkie': 2,
    'fril': 1,
    'friley': 2,
    'frill': 1,
    'frills': 1,
    'frilly': 2,
    'fringe': 1,
    'fringer': 2,
    'fringes': 2,
    'frink': 1,
    'friona': 3,
    'frisa': 2,
    'frisbee': 2,
    'frisbie': 2,
    'frisby': 2,
    'frisch': 1,
    'friscia': 2,
    'frisco': 2,
    'frisella': 3,
    'frishman': 2,
    'frisina': 3,
    'frisinger': 3,
    'frisk': 1,
    'friske': 1,
    'frisked': 1,
    'friskin': 2,
    'frisky': 2,
    'frison': 2,
    'frist': 1,
    'fristoe': 2,
    'fritch': 1,
    'fritcher': 2,
    'fritchman': 2,
    'frith': 1,
    'frito': 2,
    'fritos': 2,
    'frits': 1,
    'fritsch': 1,
    'fritsche': 1,
    'fritter': 2,
    'frittered': 2,
    'frittering': 3,
    'fritts': 1,
    'fritz': 1,
    'fritze': 1,
    'fritzen': 2,
    'fritzi': 2,
    'fritzie': 2,
    'fritzinger': 3,
    'fritzler': 2,
    'fritzsche': 1,
    'frivolity': 4,
    'frivolous': 3,
    'frivolously': 4,
    'frix': 1,
    'frizell': 2,
    'frizzell': 2,
    'fro': 1,
    'froberg': 2,
    'frock': 1,
    'frocks': 1,
    'froder': 2,
    'froedge': 1,
    'froehlich': 2,
    'froelich': 2,
    'froemming': 2,
    'froese': 1,
    'frog': 1,
    'frogg': 1,
    'frogge': 1,
    'frogman': 2,
    'frogmen': 2,
    'frogmouth': 2,
    'frogmouths': 2,
    'frogs': 1,
    'froh': 1,
    'frohlich': 2,
    'frohman': 2,
    'frohn': 1,
    'frohnmayer': 3,
    'froio': 3,
    'frolic': 2,
    'frolicking': 3,
    'frolo': 2,
    'from': 1,
    'froman': 2,
    'frombuger': 3,
    'frome': 1,
    'fromer': 2,
    'fromm': 1,
    'fromme': 1,
    'frommelt': 2,
    'frommer': 2,
    'fromstein': 2,
    'fronczak': 2,
    'frond': 1,
    'fronde': 1,
    'fronds': 1,
    'fronek': 2,
    'froning': 2,
    'fronk': 1,
    'front': 1,
    'frontage': 2,
    'frontal': 2,
    'fronted': 2,
    'frontera': 3,
    'fronterhouse': 3,
    'frontier': 2,
    'frontiere': 2,
    'frontieres': 2,
    'frontiers': 2,
    'fronting': 2,
    'frontline': 2,
    'frontrunner': 3,
    'frontrunners': 3,
    'fronts': 1,
    'frontz': 1,
    'frosch': 1,
    'fross': 1,
    'frost': 1,
    'frostban': 2,
    'frostbite': 2,
    'frostbitten': 3,
    'frosted': 2,
    'frosting': 2,
    'frostings': 2,
    'frosts': 1,
    'frosty': 2,
    'froth': 1,
    'frothiness': 3,
    'frothingham': 3,
    'froths': 1,
    'frothy': 2,
    'frown': 1,
    'frowned': 1,
    'frownfelter': 3,
    'frowning': 2,
    'frowningly': 3,
    'frowns': 1,
    'froylan': 2,
    'froze': 1,
    'frozen': 2,
    'frucher': 2,
    'fruchey': 2,
    'fruchter': 2,
    'fruchtman': 2,
    'fructose': 2,
    'frueh': 1,
    'fruehauf': 2,
    'fruehling': 2,
    'frugal': 2,
    'frugality': 4,
    'frugally': 3,
    'fruge': 1,
    'fruin': 2,
    'fruit': 1,
    'fruitcake': 2,
    'fruitcakes': 2,
    'fruitful': 2,
    'fruiting': 2,
    'fruition': 3,
    'fruitless': 2,
    'fruitlessly': 3,
    'fruitopia': 4,
    'fruits': 1,
    'fruity': 2,
    'frum': 1,
    'frumkin': 2,
    'frump': 1,
    'frumpy': 2,
    'frunze': 1,
    'frusen': 2,
    'frush': 1,
    'frustrate': 2,
    'frustrated': 3,
    'frustrates': 2,
    'frustrating': 3,
    'frustratingly': 4,
    'frustration': 3,
    'frustrations': 3,
    'frutchey': 2,
    'fruth': 1,
    'frutiger': 3,
    'fry': 1,
    'fryar': 2,
    'fryberger': 3,
    'frye': 1,
    'fryer': 2,
    'fryers': 2,
    'frying': 2,
    'fryling': 2,
    'fryman': 2,
    'frymire': 2,
    'fryrear': 2,
    'frysinger': 3,
    'fs': 0,
    'fsi': 1,
    'fthenakis': 3,
    'fu': 1,
    'fua': 2,
    'fucci': 2,
    'fuchs': 1,
    'fuchsias': 2,
    'fuck': 1,
    'fucked': 1,
    'fucker': 2,
    'fuckers': 2,
    'fucking': 2,
    'fucks': 1,
    'fudala': 3,
    'fuddrucker': 3,
    'fuddruckers': 3,
    'fuddy': 2,
    'fudge': 1,
    'fudged': 1,
    'fudging': 2,
    'fudgy': 2,
    'fudo': 2,
    'fudosan': 3,
    'fuel': 2,
    'fueled': 2,
    'fueling': 2,
    'fuelled': 2,
    'fuelling': 2,
    'fuels': 2,
    'fuente': 2,
    'fuentes': 2,
    'fuentez': 2,
    'fuer': 1,
    'fuerst': 1,
    'fuerstenberg': 3,
    'fuerth': 1,
    'fugard': 2,
    'fugate': 2,
    'fugazy': 3,
    'fuge': 1,
    'fugere': 2,
    'fugett': 2,
    'fugitive': 3,
    'fugitives': 3,
    'fugitt': 2,
    'fugotsten': 3,
    'fugue': 1,
    'fugues': 1,
    'fuhr': 1,
    'fuhrer': 2,
    'fuhrman': 2,
    'fuhrmann': 2,
    'fuhrmans': 2,
    'fuhs': 1,
    'fuji': 2,
    'fujian': 3,
    'fujii': 3,
    'fujikawa': 4,
    'fujikura': 4,
    'fujimori': 4,
    'fujimoto': 4,
    'fujio': 3,
    'fujioka': 4,
    'fujis': 2,
    'fujisankei': 4,
    'fujisawa': 4,
    'fujita': 3,
    'fujitsu': 3,
    'fujiwara': 4,
    'fujiya': 3,
    'fujiyama': 4,
    'fuke': 1,
    'fuklah': 2,
    'fukowoka': 4,
    'fukuda': 3,
    'fukui': 3,
    'fukumoto': 4,
    'fukunaga': 4,
    'fukuoka': 4,
    'fukushima': 4,
    'fukuyama': 4,
    'fulani': 3,
    'fulbright': 2,
    'fulcher': 2,
    'fulco': 2,
    'fulcrum': 2,
    'fuld': 1,
    'fulenwider': 4,
    'fulfer': 2,
    'fulfill': 2,
    'fulfilled': 2,
    'fulfilling': 3,
    'fulfillment': 3,
    'fulfills': 2,
    'fulford': 2,
    'fulgencio': 4,
    'fulgham': 2,
    'fulghum': 2,
    'fulginiti': 4,
    'fulk': 1,
    'fulkerson': 3,
    'fulks': 1,
    'full': 1,
    'fullam': 2,
    'fullard': 2,
    'fullback': 2,
    'fullbacks': 2,
    'fullbright': 2,
    'fullen': 2,
    'fullenkamp': 3,
    'fullenwider': 4,
    'fuller': 2,
    'fullerton': 3,
    'fullest': 2,
    'fullfare': 2,
    'fullilove': 4,
    'fullington': 3,
    'fullman': 2,
    'fullmer': 2,
    'fullness': 2,
    'fulltime': 2,
    'fullwood': 2,
    'fully': 2,
    'fulmer': 2,
    'fulminate': 3,
    'fulmore': 2,
    'fulop': 2,
    'fulp': 1,
    'fulsome': 2,
    'fulton': 2,
    'fults': 1,
    'fultz': 1,
    'fulvia': 3,
    'fulwider': 3,
    'fulwiler': 3,
    'fulwood': 2,
    'fumaroles': 3,
    'fumble': 2,
    'fumbled': 2,
    'fumbles': 2,
    'fumbling': 3,
    'fume': 1,
    'fumed': 1,
    'fumes': 1,
    'fumi': 2,
    'fumidor': 3,
    'fumidors': 3,
    'fumigate': 3,
    'fumigation': 4,
    'fuming': 2,
    'fumio': 3,
    'fun': 1,
    'funari': 3,
    'funaro': 3,
    'funches': 2,
    'funchess': 2,
    'funck': 1,
    'function': 2,
    'functional': 3,
    'functionality': 5,
    'functionally': 4,
    'functionaries': 4,
    'functionary': 4,
    'functioned': 2,
    'functioning': 3,
    'functions': 2,
    'fund': 1,
    'fundacion': 3,
    'fundamental': 4,
    'fundamentalism': 6,
    'fundamentalist': 5,
    'fundamentalists': 5,
    'fundamentally': 5,
    'fundamentals': 4,
    'funded': 2,
    'funder': 2,
    'funderburg': 3,
    'funderburk': 3,
    'funderburke': 3,
    'funders': 2,
    'funding': 2,
    'fundora': 3,
    'fundraiser': 3,
    'fundraisers': 3,
    'fundraising': 3,
    'funds': 1,
    'funeral': 3,
    'funerals': 3,
    'funerary': 4,
    'funes': 1,
    'fung': 1,
    'fungal': 2,
    'fungi': 2,
    'fungible': 3,
    'fungicide': 3,
    'fungicides': 3,
    'fungus': 2,
    'funicello': 4,
    'funk': 1,
    'funke': 1,
    'funkhouser': 3,
    'funks': 1,
    'funky': 2,
    'funnel': 2,
    'funneled': 2,
    'funneling': 3,
    'funnell': 2,
    'funnels': 2,
    'funnest': 2,
    'funnier': 3,
    'funniest': 3,
    'funniness': 3,
    'funny': 2,
    'funs': 1,
    'funston': 2,
    'funtime': 2,
    'fuoco': 3,
    'fuoss': 1,
    'fuqua': 2,
    'fuquay': 2,
    'fur': 1,
    'furash': 2,
    'furbee': 2,
    'furber': 2,
    'furbish': 2,
    'furbished': 2,
    'furbishes': 3,
    'furbishing': 3,
    'furbush': 2,
    'furby': 2,
    'furches': 2,
    'furer': 2,
    'furey': 2,
    'furfaro': 3,
    'furgason': 3,
    'furgerson': 3,
    'furgeson': 3,
    'furia': 3,
    'furini': 3,
    'furino': 3,
    'furious': 3,
    'furiouser': 4,
    'furiously': 4,
    'furlan': 2,
    'furlaud': 2,
    'furlett': 2,
    'furlong': 2,
    'furlough': 2,
    'furloughed': 2,
    'furloughs': 2,
    'furlow': 2,
    'furman': 2,
    'furmark': 2,
    'furnace': 2,
    'furnaces': 3,
    'furnari': 3,
    'furnas': 2,
    'furner': 2,
    'furness': 2,
    'furney': 2,
    'furnish': 2,
    'furnished': 2,
    'furnishes': 3,
    'furnishing': 3,
    'furnishings': 3,
    'furniss': 2,
    'furniture': 3,
    'furno': 2,
    'furor': 2,
    'furr': 1,
    'furrer': 2,
    'furrh': 1,
    'furrier': 3,
    'furriers': 3,
    'furrow': 2,
    'furrowed': 2,
    'furry': 2,
    'furs': 1,
    'furse': 1,
    'furst': 1,
    'furstenberg': 3,
    'furtado': 3,
    'furtak': 2,
    'furtaw': 2,
    'furth': 1,
    'further': 2,
    'furtherance': 3,
    'furthered': 2,
    'furthering': 3,
    'furthermore': 3,
    'furthers': 2,
    'furthest': 2,
    'furtick': 2,
    'furtive': 2,
    'furtively': 3,
    'furukawa': 4,
    'furuta': 3,
    'furuya': 3,
    'fury': 2,
    'fusaro': 3,
    'fusco': 2,
    'fuse': 1,
    'fused': 1,
    'fuselage': 3,
    'fuselages': 4,
    'fuselier': 3,
    'fuses': 2,
    'fusible': 3,
    'fusillade': 3,
    'fusilli': 3,
    'fusing': 2,
    'fusion': 2,
    'fusner': 2,
    'fuson': 2,
    'fuss': 1,
    'fussed': 1,
    'fussell': 2,
    'fusselman': 3,
    'fusses': 2,
    'fussing': 2,
    'fussner': 2,
    'fussy': 2,
    'fust': 1,
    'fustat': 2,
    'fustok': 2,
    'fuston': 2,
    'futch': 1,
    'futher': 2,
    'futile': 2,
    'futility': 4,
    'futon': 2,
    'futons': 2,
    'futral': 2,
    'futrell': 2,
    'futterman': 3,
    'future': 2,
    'futures': 2,
    'futurism': 4,
    'futurist': 3,
    'futuristic': 4,
    'futurists': 3,
    'fuzes': 2,
    'fuzz': 1,
    'fuzzier': 3,
    'fuzzy': 2,
    'fye': 1,
    'fyfe': 1,
    'fyffe': 1,
    'fyffes': 1,
    'fyke': 1,
    'fyock': 1,
    'fyodor': 2,
    'fyodorov': 3,
    'g': 1,
    'ga': 1,
    'gaal': 1,
    'gaar': 1,
    'gaarder': 2,
    'gab': 1,
    'gaba': 2,
    'gabaldon': 3,
    'gabardine': 3,
    'gabardines': 3,
    'gabay': 2,
    'gabbard': 2,
    'gabbert': 2,
    'gabbing': 2,
    'gabbro': 2,
    'gabbroic': 3,
    'gabby': 2,
    'gabe': 1,
    'gabehart': 2,
    'gabeira': 3,
    'gabel': 2,
    'gabele': 3,
    'gabelli': 3,
    'gaber': 2,
    'gabert': 2,
    'gabhart': 2,
    'gabie': 2,
    'gable': 2,
    'gabled': 2,
    'gabler': 3,
    'gables': 2,
    'gabon': 2,
    'gabor': 2,
    'gaboriault': 4,
    'gaboury': 3,
    'gabrall': 2,
    'gabriel': 3,
    'gabriela': 4,
    'gabriele': 3,
    'gabriella': 4,
    'gabrielle': 3,
    'gabrielli': 4,
    'gabrielsen': 3,
    'gabrielson': 3,
    'gabrowny': 3,
    'gabrys': 3,
    'gaby': 2,
    'gaccione': 3,
    'gacek': 2,
    'gach': 1,
    'gacy': 2,
    'gad': 1,
    'gadberry': 3,
    'gadbois': 2,
    'gadd': 1,
    'gaddie': 2,
    'gadding': 2,
    'gaddis': 2,
    'gaddum': 2,
    'gaddy': 2,
    'gade': 1,
    'gadflies': 2,
    'gadfly': 2,
    'gadget': 2,
    'gadgetry': 3,
    'gadgets': 2,
    'gadhafi': 3,
    'gadomski': 3,
    'gadoury': 3,
    'gads': 1,
    'gadsby': 2,
    'gadsden': 2,
    'gadson': 2,
    'gadway': 2,
    'gadzinski': 3,
    'gae': 1,
    'gaea': 2,
    'gaebel': 2,
    'gaede': 1,
    'gael': 1,
    'gaelic': 2,
    'gaertner': 2,
    'gaeta': 2,
    'gaetano': 3,
    'gaeth': 1,
    'gaetz': 1,
    'gaf': 1,
    'gaff': 1,
    'gaffe': 1,
    'gaffes': 1,
    'gaffey': 2,
    'gaffin': 2,
    'gaffney': 2,
    'gafford': 2,
    'gag': 1,
    'gagan': 2,
    'gagarin': 3,
    'gage': 1,
    'gagel': 2,
    'gagen': 2,
    'gager': 2,
    'gagged': 1,
    'gaggle': 2,
    'gagliano': 4,
    'gagliardi': 4,
    'gagliardo': 4,
    'gaglio': 3,
    'gaglione': 4,
    'gagne': 2,
    'gagner': 2,
    'gagnier': 3,
    'gagnon': 2,
    'gags': 1,
    'gahagan': 3,
    'gahan': 2,
    'gahli': 2,
    'gahm': 1,
    'gahn': 1,
    'gahr': 1,
    'gai': 1,
    'gaia': 2,
    'gaidar': 2,
    'gaier': 2,
    'gaige': 1,
    'gail': 1,
    'gaile': 1,
    'gailey': 2,
    'gaillard': 2,
    'gaily': 2,
    'gain': 1,
    'gained': 1,
    'gainer': 2,
    'gainers': 2,
    'gaines': 1,
    'gainesville': 2,
    'gainey': 2,
    'gainful': 2,
    'gainfully': 3,
    'gaining': 2,
    'gainor': 2,
    'gainous': 2,
    'gains': 1,
    'gainsay': 2,
    'gainsboro': 3,
    'gainsco': 2,
    'gair': 1,
    'gaiser': 2,
    'gait': 1,
    'gaitan': 2,
    'gaiter': 2,
    'gaither': 2,
    'gaithersburg': 3,
    'gaitskill': 2,
    'gajda': 2,
    'gajewski': 3,
    'gal': 1,
    'gala': 2,
    'galactic': 3,
    'galactose': 3,
    'galahad': 3,
    'galambos': 3,
    'galan': 2,
    'galane': 2,
    'galang': 2,
    'galanis': 3,
    'galanos': 3,
    'galant': 2,
    'galante': 3,
    'galanter': 3,
    'galanti': 3,
    'galapagos': 4,
    'galarneau': 3,
    'galarza': 3,
    'galas': 2,
    'galasie': 3,
    'galassi': 3,
    'galasso': 3,
    'galatas': 3,
    'galatea': 4,
    'galati': 3,
    'galaviz': 3,
    'galax': 2,
    'galaxies': 3,
    'galaxy': 3,
    'galban': 2,
    'galbo': 2,
    'galbraith': 2,
    'galbreath': 2,
    'gale': 1,
    'galea': 3,
    'galeano': 3,
    'galeb': 2,
    'galebs': 2,
    'galef': 2,
    'galen': 2,
    'galena': 3,
    'galenical': 4,
    'galentine': 4,
    'galer': 2,
    'galeria': 4,
    'galerias': 4,
    'galeries': 3,
    'galernter': 3,
    'gales': 1,
    'galesburg': 2,
    'galesi': 3,
    'galey': 2,
    'galford': 2,
    'galgano': 3,
    'gali': 2,
    'galiano': 4,
    'galicia': 4,
    'galie': 2,
    'galiena': 3,
    'galik': 2,
    'galilean': 4,
    'galilee': 3,
    'galileo': 4,
    'galin': 2,
    'galina': 3,
    'galindo': 3,
    'galinski': 3,
    'galinsky': 3,
    'galioto': 4,
    'galipault': 3,
    'galipeau': 3,
    'galitsin': 3,
    'galka': 2,
    'gall': 1,
    'galla': 2,
    'gallacher': 3,
    'gallager': 3,
    'gallagher': 3,
    'gallahan': 3,
    'gallaher': 3,
    'gallamore': 3,
    'galland': 2,
    'gallant': 2,
    'gallantly': 3,
    'gallantry': 3,
    'gallard': 2,
    'gallardo': 3,
    'gallas': 2,
    'gallatin': 3,
    'gallaudet': 3,
    'gallaway': 3,
    'gallbladder': 3,
    'galle': 1,
    'gallego': 3,
    'gallegos': 3,
    'gallemore': 3,
    'gallen': 2,
    'gallentine': 4,
    'galleon': 3,
    'galleons': 3,
    'galler': 2,
    'gallerani': 4,
    'galleria': 4,
    'galleries': 3,
    'gallery': 3,
    'galles': 1,
    'gallet': 2,
    'galletti': 3,
    'galley': 2,
    'galleys': 2,
    'galli': 2,
    'gallia': 2,
    'galliano': 4,
    'gallic': 2,
    'gallick': 2,
    'gallien': 2,
    'gallier': 3,
    'galligan': 3,
    'galliher': 3,
    'gallik': 2,
    'gallimard': 3,
    'gallimore': 3,
    'gallina': 3,
    'galling': 2,
    'gallinger': 3,
    'gallinsky': 3,
    'gallion': 2,
    'gallium': 3,
    'gallivan': 3,
    'gallman': 2,
    'gallo': 2,
    'gallogly': 3,
    'gallon': 2,
    'gallons': 2,
    'galloon': 2,
    'gallop': 2,
    'galloped': 2,
    'galloping': 3,
    'gallos': 2,
    'gallow': 2,
    'galloway': 3,
    'galloways': 3,
    'gallows': 2,
    'galls': 1,
    'gallstone': 2,
    'gallstones': 2,
    'gallucci': 3,
    'galluccio': 4,
    'gallup': 2,
    'gallus': 2,
    'galluzzo': 3,
    'gallway': 2,
    'galoob': 2,
    'galore': 2,
    'galosh': 2,
    'galoshes': 3,
    'galotti': 3,
    'galpin': 2,
    'gals': 1,
    'galster': 2,
    'galsworthy': 3,
    'galt': 1,
    'galton': 2,
    'galudet': 3,
    'galus': 2,
    'galusha': 3,
    'galuska': 3,
    'galvan': 2,
    'galvanic': 3,
    'galvanize': 3,
    'galvanized': 3,
    'galvanizes': 4,
    'galvanizing': 4,
    'galven': 2,
    'galveston': 3,
    'galvez': 2,
    'galvin': 2,
    'galway': 2,
    'galyean': 3,
    'galyen': 3,
    'galyon': 3,
    'gama': 2,
    'gamache': 2,
    'gamage': 2,
    'gamal': 2,
    'gamba': 2,
    'gambale': 3,
    'gambardella': 4,
    'gamber': 2,
    'gambia': 3,
    'gambill': 2,
    'gambino': 3,
    'gambit': 2,
    'gambits': 2,
    'gamble': 2,
    'gambled': 2,
    'gambler': 2,
    'gamblers': 2,
    'gambles': 2,
    'gamblin': 2,
    'gambling': 3,
    'gamboa': 3,
    'gambone': 2,
    'gambrel': 2,
    'gambrell': 2,
    'gambrill': 2,
    'gambro': 2,
    'gamco': 2,
    'game': 1,
    'gamecock': 2,
    'gamecocks': 2,
    'gamekeeper': 3,
    'gamekeepers': 3,
    'gamel': 2,
    'gamelin': 3,
    'gamello': 3,
    'gamely': 2,
    'gamer': 2,
    'gamers': 2,
    'games': 1,
    'gameshow': 2,
    'gameshows': 2,
    'gamesman': 2,
    'gamesmanship': 3,
    'gamest': 2,
    'gamet': 2,
    'gametangia': 5,
    'gamete': 2,
    'gametophyte': 4,
    'gamez': 2,
    'gaming': 2,
    'gamino': 3,
    'gamm': 1,
    'gamma': 2,
    'gammage': 2,
    'gammal': 2,
    'gammel': 2,
    'gammell': 2,
    'gammill': 2,
    'gammon': 2,
    'gammons': 2,
    'gamons': 2,
    'gamp': 1,
    'gamper': 2,
    'gamsakhurdia': 5,
    'gamunde': 2,
    'gamut': 2,
    'gan': 1,
    'gananoque': 3,
    'ganas': 2,
    'ganatieuganauf': 5,
    'ganaway': 3,
    'gancarz': 2,
    'ganci': 2,
    'ganda': 2,
    'gandalf': 2,
    'gandara': 3,
    'gandee': 2,
    'gander': 2,
    'ganders': 2,
    'gandhi': 2,
    'gandolfi': 3,
    'gandolfo': 3,
    'gandy': 2,
    'ganem': 2,
    'ganes': 1,
    'ganey': 2,
    'gang': 1,
    'gangbanger': 3,
    'gangbangers': 3,
    'gangbuster': 3,
    'gangbusters': 3,
    'gange': 1,
    'gangemi': 3,
    'ganger': 2,
    'ganges': 2,
    'gangi': 2,
    'ganging': 2,
    'gangl': 2,
    'ganglia': 3,
    'ganglionic': 4,
    'gangloff': 2,
    'gangly': 2,
    'gangplank': 2,
    'gangrene': 2,
    'gangs': 1,
    'gangsta': 2,
    'gangster': 2,
    'gangsters': 2,
    'gangwer': 2,
    'ganic': 2,
    'ganim': 2,
    'ganis': 2,
    'ganley': 2,
    'gann': 1,
    'gannaway': 3,
    'gannett': 2,
    'gannon': 2,
    'gano': 2,
    'ganoe': 2,
    'ganong': 2,
    'gans': 1,
    'gansen': 2,
    'ganser': 2,
    'ganske': 1,
    'ganson': 2,
    'ganster': 2,
    'gant': 1,
    'gantenbein': 3,
    'ganter': 2,
    'gantlet': 2,
    'gantner': 2,
    'gantos': 2,
    'gantry': 2,
    'gantt': 1,
    'gantz': 1,
    'ganus': 2,
    'ganymede': 3,
    'ganz': 1,
    'ganzel': 2,
    'ganzer': 2,
    'gaona': 3,
    'gap': 1,
    'gape': 1,
    'gaping': 2,
    'gapinski': 3,
    'gapp': 1,
    'gappa': 2,
    'gaps': 1,
    'gar': 1,
    'gara': 2,
    'garabedian': 5,
    'garafalo': 4,
    'garafola': 4,
    'garage': 2,
    'garages': 3,
    'garagiola': 5,
    'garajda': 3,
    'garamendi': 4,
    'garand': 2,
    'garant': 2,
    'garard': 2,
    'garavaglia': 5,
    'garay': 2,
    'garb': 1,
    'garbacz': 2,
    'garbage': 2,
    'garbarini': 4,
    'garbarino': 4,
    'garbe': 1,
    'garbed': 1,
    'garber': 2,
    'garbers': 2,
    'garbett': 2,
    'garble': 2,
    'garbled': 2,
    'garbles': 2,
    'garbling': 2,
    'garbo': 2,
    'garbutt': 2,
    'garceau': 2,
    'garces': 2,
    'garcetti': 3,
    'garcia': 3,
    'garcias': 3,
    'garczynski': 3,
    'gard': 1,
    'garda': 2,
    'garde': 1,
    'gardea': 3,
    'gardella': 3,
    'garden': 2,
    'gardena': 3,
    'gardenamerica': 6,
    'gardened': 2,
    'gardener': 3,
    'gardeners': 3,
    'gardenhire': 3,
    'gardenia': 3,
    'gardenias': 3,
    'gardening': 3,
    'gardens': 2,
    'gardin': 2,
    'gardiner': 2,
    'gardini': 3,
    'gardinier': 4,
    'gardner': 2,
    'gardolin': 3,
    'garduno': 3,
    'gardyne': 2,
    'gare': 1,
    'gareau': 2,
    'gared': 2,
    'garelick': 3,
    'gareth': 2,
    'garett': 2,
    'garey': 2,
    'garfield': 2,
    'garfinkel': 3,
    'garfinkle': 3,
    'garfunkel': 3,
    'garg': 1,
    'gargan': 2,
    'gargano': 3,
    'gargantuan': 4,
    'garger': 2,
    'garges': 2,
    'gargill': 2,
    'gargis': 2,
    'gargiulo': 3,
    'gargoyle': 2,
    'gargoyles': 2,
    'garguilo': 3,
    'gargus': 2,
    'gari': 2,
    'garibaldi': 4,
    'garibay': 3,
    'gariepy': 3,
    'garin': 2,
    'garing': 2,
    'garinger': 3,
    'garis': 2,
    'garish': 2,
    'garissa': 3,
    'garity': 3,
    'garl': 1,
    'garlan': 2,
    'garland': 2,
    'garlic': 2,
    'garlick': 2,
    'garlicky': 3,
    'garling': 2,
    'garlinger': 3,
    'garlinghouse': 3,
    'garlington': 3,
    'garlitz': 2,
    'garlock': 2,
    'garlow': 2,
    'garman': 2,
    'garmany': 3,
    'garment': 2,
    'garments': 2,
    'garmon': 2,
    'garmond': 2,
    'garms': 1,
    'garmund': 2,
    'garn': 1,
    'garneau': 2,
    'garnell': 2,
    'garner': 2,
    'garnered': 2,
    'garnering': 3,
    'garners': 2,
    'garnes': 1,
    'garnet': 2,
    'garnett': 2,
    'garnette': 2,
    'garney': 2,
    'garnica': 3,
    'garnier': 3,
    'garnish': 2,
    'garnished': 2,
    'garnishes': 3,
    'garnishment': 3,
    'garno': 2,
    'garnock': 2,
    'garnsey': 2,
    'garo': 2,
    'garofalo': 4,
    'garofano': 4,
    'garofolo': 4,
    'garols': 2,
    'garon': 2,
    'garone': 2,
    'garonzik': 3,
    'garoutte': 2,
    'garp': 1,
    'garps': 1,
    'garr': 1,
    'garrabrant': 3,
    'garrahan': 3,
    'garramone': 3,
    'garrard': 2,
    'garratt': 2,
    'garraway': 3,
    'garrell': 2,
    'garrels': 2,
    'garrelts': 2,
    'garren': 2,
    'garret': 2,
    'garrets': 2,
    'garretson': 3,
    'garrett': 2,
    'garrette': 2,
    'garrey': 2,
    'garrick': 2,
    'garrido': 3,
    'garriga': 3,
    'garrigan': 3,
    'garrigues': 3,
    'garrigus': 3,
    'garringer': 3,
    'garriott': 3,
    'garris': 2,
    'garrison': 3,
    'garrisoned': 3,
    'garritano': 4,
    'garritt': 2,
    'garrity': 3,
    'garro': 2,
    'garrod': 2,
    'garrol': 2,
    'garrols': 2,
    'garron': 2,
    'garros': 2,
    'garrott': 2,
    'garrow': 2,
    'garroway': 3,
    'garroz': 2,
    'garrulous': 3,
    'garry': 2,
    'gars': 1,
    'garside': 2,
    'garske': 1,
    'garson': 2,
    'garst': 1,
    'garsten': 2,
    'garstin': 2,
    'garstka': 2,
    'garston': 2,
    'garten': 2,
    'gartenberg': 3,
    'garter': 2,
    'garters': 2,
    'garth': 1,
    'garthwaite': 2,
    'gartin': 2,
    'gartland': 2,
    'gartley': 2,
    'gartman': 2,
    'gartmore': 2,
    'gartner': 2,
    'garton': 2,
    'gartrell': 2,
    'gartside': 2,
    'gartzke': 1,
    'garuda': 3,
    'garver': 2,
    'garverick': 3,
    'garvey': 2,
    'garvie': 2,
    'garvin': 2,
    'garwin': 2,
    'garwood': 2,
    'gary': 2,
    'garza': 2,
    'garzarelli': 4,
    'garzon': 2,
    'gas': 1,
    'gasaway': 3,
    'gasbarro': 3,
    'gasca': 2,
    'gascon': 2,
    'gascons': 2,
    'gase': 1,
    'gaseous': 3,
    'gases': 2,
    'gash': 1,
    'gashed': 1,
    'gashes': 2,
    'gasich': 2,
    'gasification': 5,
    'gasify': 3,
    'gasior': 3,
    'gasiorowski': 5,
    'gaska': 2,
    'gaskamp': 2,
    'gaskell': 2,
    'gasket': 2,
    'gaskets': 2,
    'gaskey': 2,
    'gaskill': 2,
    'gaskin': 2,
    'gaskins': 2,
    'gaslight': 2,
    'gasner': 2,
    'gasohol': 3,
    'gasoline': 3,
    'gasolines': 3,
    'gasp': 1,
    'gaspar': 2,
    'gaspara': 3,
    'gaspard': 2,
    'gaspari': 3,
    'gasparini': 4,
    'gasparro': 3,
    'gaspe': 2,
    'gasped': 1,
    'gasper': 2,
    'gasperini': 4,
    'gasping': 2,
    'gasps': 1,
    'gasque': 1,
    'gass': 1,
    'gassaway': 3,
    'gassed': 1,
    'gassee': 2,
    'gassen': 2,
    'gasser': 2,
    'gassert': 2,
    'gasses': 2,
    'gassett': 2,
    'gassing': 2,
    'gassman': 2,
    'gassmann': 2,
    'gassner': 2,
    'gast': 1,
    'gastelli': 3,
    'gaster': 2,
    'gastilum': 3,
    'gastineau': 3,
    'gaston': 2,
    'gastonia': 4,
    'gastric': 2,
    'gastritis': 3,
    'gastro': 2,
    'gastrointestinal': 6,
    'gastronomic': 4,
    'gastronomy': 4,
    'gastroscope': 3,
    'gastrovascular': 5,
    'gastrulate': 3,
    'gastrulation': 4,
    'gat': 1,
    'gatch': 1,
    'gatchel': 2,
    'gatchell': 2,
    'gate': 1,
    'gated': 2,
    'gatekeeper': 3,
    'gatekeepers': 3,
    'gateley': 3,
    'gately': 2,
    'gates': 1,
    'gateway': 2,
    'gateways': 2,
    'gatewood': 2,
    'gath': 1,
    'gather': 2,
    'gathered': 2,
    'gatherer': 3,
    'gatherers': 3,
    'gathering': 3,
    'gatherings': 3,
    'gathers': 2,
    'gathings': 2,
    'gathman': 2,
    'gathright': 2,
    'gatien': 2,
    'gating': 2,
    'gatley': 2,
    'gatliff': 2,
    'gatlin': 2,
    'gatling': 2,
    'gatoil': 2,
    'gator': 2,
    'gatorade': 3,
    'gators': 2,
    'gatos': 2,
    'gatrell': 2,
    'gatsby': 2,
    'gatson': 2,
    'gatt': 1,
    'gatten': 2,
    'gatti': 2,
    'gatting': 2,
    'gattis': 2,
    'gatton': 2,
    'gattuso': 3,
    'gatty': 2,
    'gatward': 2,
    'gatwick': 2,
    'gatx': 2,
    'gatz': 1,
    'gatza': 2,
    'gatzke': 1,
    'gau': 1,
    'gaub': 1,
    'gaubatz': 2,
    'gaubert': 2,
    'gauch': 1,
    'gauche': 1,
    'gaucher': 2,
    'gaucho': 2,
    'gauchos': 2,
    'gaudet': 2,
    'gaudette': 2,
    'gaudin': 2,
    'gaudino': 3,
    'gaudio': 3,
    'gaudioso': 4,
    'gaudreau': 2,
    'gaudy': 2,
    'gauer': 2,
    'gauerke': 2,
    'gaufman': 2,
    'gauge': 1,
    'gauged': 1,
    'gauger': 2,
    'gauges': 2,
    'gaugh': 1,
    'gaughan': 2,
    'gaughman': 2,
    'gaughran': 2,
    'gauging': 2,
    'gaugler': 2,
    'gauguin': 2,
    'gaul': 1,
    'gaulan': 2,
    'gaulden': 2,
    'gaulding': 2,
    'gaulin': 2,
    'gaulke': 1,
    'gaulle': 1,
    'gaullist': 2,
    'gauls': 1,
    'gault': 1,
    'gaultier': 2,
    'gaultney': 2,
    'gaumer': 2,
    'gaumond': 2,
    'gauna': 2,
    'gaunce': 1,
    'gaunt': 1,
    'gauntlet': 2,
    'gauntness': 2,
    'gauntt': 1,
    'gaus': 1,
    'gause': 1,
    'gausman': 2,
    'gauss': 1,
    'gaussian': 3,
    'gaustad': 2,
    'gaut': 1,
    'gauthier': 3,
    'gauthreaux': 2,
    'gautier': 3,
    'gautney': 2,
    'gautreau': 2,
    'gautreaux': 2,
    'gauvain': 2,
    'gauvin': 2,
    'gauvreau': 2,
    'gauze': 1,
    'gavalda': 3,
    'gavan': 2,
    'gave': 1,
    'gavel': 2,
    'gaven': 2,
    'gaver': 2,
    'gavigan': 3,
    'gavin': 2,
    'gaviotas': 4,
    'gaviria': 4,
    'gavitt': 2,
    'gavlak': 2,
    'gavras': 2,
    'gaw': 1,
    'gawain': 2,
    'gawel': 2,
    'gawen': 2,
    'gawk': 1,
    'gawker': 2,
    'gawkers': 2,
    'gawking': 2,
    'gawky': 2,
    'gawlik': 2,
    'gawne': 1,
    'gawron': 2,
    'gawronski': 3,
    'gawthrop': 2,
    'gaxiola': 4,
    'gay': 1,
    'gayda': 2,
    'gayden': 2,
    'gaydos': 2,
    'gaydosh': 2,
    'gaye': 1,
    'gayer': 2,
    'gayhart': 2,
    'gayheart': 2,
    'gayle': 1,
    'gayler': 2,
    'gayles': 1,
    'gaylor': 2,
    'gaylord': 2,
    'gayman': 2,
    'gaymon': 2,
    'gaynatie': 3,
    'gayner': 2,
    'gayness': 2,
    'gaynor': 2,
    'gays': 1,
    'gayshill': 2,
    'gaytan': 2,
    'gayton': 2,
    'gaz': 1,
    'gaza': 2,
    'gazans': 2,
    'gazaway': 3,
    'gazda': 2,
    'gazdik': 2,
    'gaze': 1,
    'gazed': 1,
    'gazella': 3,
    'gazelle': 2,
    'gazelles': 2,
    'gazes': 2,
    'gazeta': 3,
    'gazette': 2,
    'gazing': 2,
    'gazonsky': 3,
    'gazprom': 2,
    'gazzola': 3,
    'gdansk': 2,
    'geac': 1,
    'geagea': 4,
    'gean': 1,
    'gear': 1,
    'gearan': 2,
    'gearbox': 2,
    'gearboxes': 3,
    'geared': 1,
    'gearey': 2,
    'gearhart': 2,
    'gearheart': 2,
    'gearin': 2,
    'gearing': 2,
    'gears': 1,
    'geary': 2,
    'gebauer': 3,
    'gebbia': 3,
    'gebbie': 2,
    'gebel': 2,
    'gebert': 2,
    'gebhard': 2,
    'gebhardt': 2,
    'gebhart': 2,
    'gebler': 3,
    'gebo': 2,
    'gechem': 2,
    'geck': 1,
    'gecko': 2,
    'geckos': 2,
    'ged': 1,
    'geddes': 2,
    'geddie': 2,
    'geddings': 2,
    'geddis': 2,
    'gedeon': 3,
    'gedney': 2,
    'geduld': 2,
    'gee': 1,
    'geeing': 2,
    'geek': 1,
    'geeks': 1,
    'geeky': 2,
    'geena': 2,
    'geenen': 2,
    'geer': 1,
    'geerdes': 1,
    'geers': 2,
    'geerts': 1,
    'gees': 1,
    'geesaman': 3,
    'geese': 1,
    'geesey': 2,
    'geeslin': 2,
    'geeting': 2,
    'geez': 1,
    'geeze': 1,
    'geffen': 2,
    'geffert': 2,
    'geffner': 2,
    'geffre': 2,
    'gefinor': 3,
    'gegenheimer': 4,
    'gegg': 1,
    'gehl': 1,
    'gehle': 2,
    'gehlhausen': 3,
    'gehling': 2,
    'gehm': 1,
    'gehman': 2,
    'gehr': 1,
    'gehres': 1,
    'gehret': 2,
    'gehrig': 2,
    'gehring': 2,
    'gehringer': 3,
    'gehris': 2,
    'gehrke': 1,
    'gehrman': 2,
    'gehrmann': 2,
    'gehrt': 1,
    'gehry': 2,
    'geib': 1,
    'geibel': 2,
    'geico': 2,
    'geidar': 2,
    'geidel': 2,
    'geier': 2,
    'geiger': 2,
    'geigle': 2,
    'geigy': 2,
    'geiken': 2,
    'geil': 1,
    'geiler': 2,
    'geiman': 2,
    'geimer': 2,
    'geis': 1,
    'geise': 1,
    'geisel': 2,
    'geiselman': 3,
    'geisen': 2,
    'geiser': 2,
    'geisert': 2,
    'geisha': 2,
    'geisinger': 3,
    'geisler': 2,
    'geiss': 1,
    'geissinger': 3,
    'geissler': 2,
    'geist': 1,
    'geister': 2,
    'geiszler': 2,
    'geitner': 2,
    'geitz': 1,
    'gejdenson': 3,
    'gekas': 2,
    'gekko': 2,
    'gel': 1,
    'gelardi': 3,
    'gelardin': 3,
    'gelasia': 4,
    'gelatin': 3,
    'gelatine': 3,
    'gelatinous': 4,
    'gelb': 1,
    'gelbard': 2,
    'gelbart': 2,
    'gelber': 2,
    'gelco': 2,
    'gelder': 2,
    'geldermann': 3,
    'gelernter': 3,
    'gelertner': 3,
    'gelett': 2,
    'gelette': 2,
    'gelfand': 2,
    'gelinas': 3,
    'gelineau': 3,
    'gell': 1,
    'gellatly': 3,
    'geller': 2,
    'gellerman': 3,
    'gellert': 2,
    'gelles': 1,
    'gelli': 2,
    'gellis': 2,
    'gellman': 2,
    'gellner': 2,
    'gelman': 2,
    'gelpi': 2,
    'gels': 1,
    'gelsinger': 3,
    'geltz': 1,
    'gelvin': 2,
    'gem': 1,
    'gemayel': 3,
    'gemberling': 3,
    'gemco': 2,
    'gemcraft': 2,
    'gemex': 2,
    'gemfibrozil': 4,
    'gemignani': 4,
    'gemina': 3,
    'geminate': 3,
    'gemini': 3,
    'gemma': 2,
    'gemme': 1,
    'gemmell': 2,
    'gemmer': 2,
    'gemmill': 2,
    'gems': 1,
    'gemsbok': 2,
    'gemstone': 2,
    'gemstones': 2,
    'gen': 3,
    'gena': 2,
    'genady': 3,
    'gencarelli': 4,
    'genco': 2,
    'gencor': 2,
    'gencorp': 2,
    'gendarme': 2,
    'gender': 2,
    'genders': 2,
    'gendler': 2,
    'gendreau': 2,
    'gendrisek': 3,
    'gendron': 2,
    'gene': 1,
    'genealogy': 5,
    'geneen': 2,
    'genego': 3,
    'genelab': 3,
    'genelabs': 3,
    'genemedicine': 5,
    'genencor': 3,
    'genentech': 3,
    'genera': 3,
    'general': 3,
    'generale': 3,
    'generales': 4,
    'generali': 4,
    'generalissimo': 6,
    'generalist': 4,
    'generalists': 4,
    'generalities': 5,
    'generality': 5,
    'generalization': 6,
    'generalizations': 6,
    'generalize': 4,
    'generalized': 4,
    'generalizing': 5,
    'generally': 4,
    'generals': 3,
    'generalship': 4,
    'generate': 3,
    'generated': 4,
    'generates': 3,
    'generating': 4,
    'generation': 4,
    'generational': 5,
    'generationally': 6,
    'generations': 4,
    'generative': 4,
    'generator': 4,
    'generators': 4,
    'genereux': 3,
    'generic': 3,
    'generically': 4,
    'generics': 3,
    'genero': 3,
    'generosity': 5,
    'generous': 3,
    'generously': 4,
    'genes': 1,
    'genesco': 3,
    'genesee': 3,
    'genesis': 3,
    'genet': 2,
    'genetic': 3,
    'genetically': 4,
    'geneticist': 4,
    'geneticists': 4,
    'genetics': 3,
    'genetization': 5,
    'geneva': 3,
    'geneve': 2,
    'genevieve': 3,
    'genex': 2,
    'geng': 1,
    'gengenbach': 3,
    'genger': 2,
    'genghis': 2,
    'gengler': 2,
    'genial': 2,
    'geniality': 5,
    'genicom': 3,
    'genie': 2,
    'geniere': 2,
    'genis': 2,
    'genisco': 3,
    'genital': 3,
    'genitalia': 4,
    'genitals': 3,
    'genius': 2,
    'geniuses': 3,
    'genk': 1,
    'genlyte': 2,
    'genmar': 2,
    'genna': 2,
    'gennadi': 3,
    'gennady': 3,
    'gennaro': 3,
    'gennett': 2,
    'gennifer': 3,
    'geno': 2,
    'genoa': 3,
    'genocidal': 4,
    'genocide': 3,
    'genocides': 3,
    'genoese': 2,
    'genome': 2,
    'genossenschaftsbank': 5,
    'genotype': 3,
    'genotypes': 3,
    'genova': 3,
    'genovese': 3,
    'genovesi': 4,
    'genovise': 3,
    'genpharm': 2,
    'genrad': 2,
    'genre': 2,
    'genres': 2,
    'genrich': 2,
    'gens': 1,
    'genscher': 2,
    'gensel': 2,
    'gensia': 3,
    'genske': 1,
    'gensler': 3,
    'genson': 2,
    'genstar': 2,
    'gent': 1,
    'genteel': 2,
    'genter': 2,
    'genther': 2,
    'genthner': 2,
    'gentian': 2,
    'gentile': 2,
    'gentiles': 2,
    'gentility': 4,
    'gentle': 2,
    'gentleladies': 4,
    'gentlelady': 4,
    'gentleman': 3,
    'gentlemanly': 4,
    'gentlemen': 3,
    'gentleness': 3,
    'gentler': 2,
    'gentles': 2,
    'gentlest': 3,
    'gentlewoman': 4,
    'gentlewomen': 4,
    'gently': 2,
    'gentner': 2,
    'gentrification': 5,
    'gentrified': 3,
    'gentrify': 3,
    'gentrifying': 4,
    'gentry': 2,
    'gentz': 1,
    'gentzler': 2,
    'genuine': 3,
    'genuinely': 4,
    'genuineness': 4,
    'genung': 2,
    'genus': 2,
    'genz': 1,
    'genzyme': 2,
    'geo': 2,
    'geocentric': 4,
    'geochemistry': 5,
    'geodesic': 4,
    'geodesy': 4,
    'geodyne': 3,
    'geoff': 1,
    'geoffrey': 2,
    'geoffrion': 4,
    'geoffroy': 3,
    'geoghegan': 3,
    'geographer': 4,
    'geographic': 4,
    'geographical': 5,
    'geographically': 6,
    'geography': 4,
    'geoid': 2,
    'geologic': 4,
    'geological': 5,
    'geologist': 4,
    'geologists': 4,
    'geology': 4,
    'geomagnetic': 5,
    'geometric': 4,
    'geometrical': 5,
    'geometrically': 5,
    'geometrics': 4,
    'geometries': 4,
    'geometry': 4,
    'geomorphology': 6,
    'geon': 2,
    'geophysical': 5,
    'geopolitic': 5,
    'geopolitical': 6,
    'geopolitically': 6,
    'geopolitics': 5,
    'geordie': 3,
    'georg': 2,
    'georgakis': 3,
    'georgann': 2,
    'george': 1,
    'georgene': 2,
    'georges': 2,
    'georgeson': 3,
    'georgetown': 2,
    'georgette': 2,
    'georgi': 3,
    'georgia': 2,
    'georgiades': 4,
    'georgiadis': 4,
    'georgian': 2,
    'georgiana': 4,
    'georgians': 2,
    'georgie': 2,
    'georgienne': 3,
    'georgina': 3,
    'georgine': 2,
    'georgio': 3,
    'georgiou': 2,
    'georgopoulos': 4,
    'georgy': 2,
    'geostrophic': 4,
    'geosyncline': 4,
    'geotaxis': 4,
    'geotek': 3,
    'geothermal': 4,
    'geotropic': 4,
    'geotropism': 5,
    'geoworks': 3,
    'gephardt': 2,
    'gephart': 2,
    'geppert': 2,
    'gera': 2,
    'gerace': 3,
    'geraci': 3,
    'geraghty': 3,
    'gerais': 2,
    'gerald': 2,
    'geraldi': 3,
    'geraldina': 4,
    'geraldine': 3,
    'geraldo': 3,
    'geralds': 2,
    'geran': 2,
    'geranium': 4,
    'geraniums': 4,
    'gerard': 2,
    'gerardi': 3,
    'gerardo': 3,
    'gerashchenko': 4,
    'gerasimov': 4,
    'gerbasi': 3,
    'gerber': 2,
    'gerberding': 3,
    'gerbert': 2,
    'gerbig': 2,
    'gerbino': 3,
    'gerbrandt': 2,
    'gerchas': 2,
    'gerd': 1,
    'gerda': 2,
    'gerdeman': 2,
    'gerdes': 1,
    'gerding': 2,
    'gerdts': 1,
    'gere': 1,
    'geremia': 4,
    'geren': 2,
    'gerena': 3,
    'gerety': 3,
    'gergel': 2,
    'gergely': 2,
    'gergen': 2,
    'gerger': 2,
    'gergrude': 2,
    'gerhard': 2,
    'gerhardt': 2,
    'gerhart': 2,
    'gerhold': 2,
    'geri': 2,
    'geriatric': 4,
    'geriatrician': 5,
    'geriatricians': 5,
    'geriatrics': 4,
    'gerich': 2,
    'gericke': 2,
    'gerig': 2,
    'gering': 2,
    'geringer': 3,
    'gerk': 1,
    'gerke': 1,
    'gerken': 2,
    'gerkin': 2,
    'gerlach': 2,
    'gerleman': 3,
    'gerlich': 2,
    'gerling': 2,
    'gerlock': 2,
    'gerloff': 2,
    'germ': 1,
    'germain': 2,
    'germaine': 2,
    'german': 2,
    'germane': 2,
    'germani': 3,
    'germania': 4,
    'germanic': 3,
    'germann': 2,
    'germano': 3,
    'germans': 2,
    'germantown': 3,
    'germany': 3,
    'germanys': 3,
    'germe': 1,
    'germer': 2,
    'germicide': 3,
    'germinate': 3,
    'germinated': 4,
    'germination': 4,
    'germiston': 3,
    'germond': 2,
    'germs': 1,
    'gerner': 2,
    'gernert': 2,
    'gero': 2,
    'gerold': 2,
    'gerome': 2,
    'geron': 2,
    'geronimo': 4,
    'gerontologist': 5,
    'gerontology': 5,
    'geroux': 2,
    'gerow': 2,
    'gerrald': 2,
    'gerri': 2,
    'gerrick': 2,
    'gerrie': 2,
    'gerringer': 3,
    'gerrish': 2,
    'gerrit': 2,
    'gerrits': 2,
    'gerritsen': 3,
    'gerrity': 3,
    'gerry': 2,
    'gerrymander': 4,
    'gerrymandered': 4,
    'gerrymandering': 5,
    'gerrymanders': 4,
    'gersch': 1,
    'gersh': 1,
    'gershman': 2,
    'gershon': 2,
    'gershowitz': 3,
    'gershwin': 2,
    'gerski': 2,
    'gerson': 2,
    'gerst': 1,
    'gerstein': 2,
    'gerstel': 2,
    'gersten': 2,
    'gerstenberger': 4,
    'gerstenhaber': 4,
    'gerster': 2,
    'gerstman': 2,
    'gerstner': 2,
    'gerszewski': 3,
    'gert': 1,
    'gerth': 1,
    'gertie': 2,
    'gertler': 3,
    'gertner': 2,
    'gertrud': 2,
    'gertrude': 2,
    'gertsch': 1,
    'gerty': 2,
    'gertz': 1,
    'gervais': 2,
    'gervase': 2,
    'gervasi': 3,
    'gervasio': 4,
    'gerwig': 2,
    'gerwin': 2,
    'gery': 2,
    'gesamtmetall': 4,
    'geschke': 1,
    'gesco': 2,
    'gesell': 2,
    'gesellschaft': 3,
    'geserum': 3,
    'geske': 1,
    'gesner': 2,
    'gess': 1,
    'gessel': 2,
    'gessert': 2,
    'gessler': 3,
    'gessner': 2,
    'gest': 1,
    'gestal': 2,
    'gestapo': 3,
    'gestate': 2,
    'gestation': 3,
    'gestetner': 3,
    'gesture': 2,
    'gestured': 2,
    'gestures': 2,
    'gesturing': 3,
    'gesualdi': 4,
    'gesundheit': 3,
    'get': 1,
    'getaway': 3,
    'getaways': 3,
    'getchell': 2,
    'geter': 2,
    'gethers': 2,
    'getman': 2,
    'gets': 1,
    'getsinger': 3,
    'gettel': 2,
    'getter': 2,
    'getters': 2,
    'getting': 2,
    'gettinger': 3,
    'gettings': 2,
    'gettis': 2,
    'gettle': 2,
    'gettler': 3,
    'gettman': 2,
    'getts': 1,
    'getty': 2,
    'gettys': 2,
    'gettysburg': 3,
    'getulio': 4,
    'getz': 1,
    'geurin': 2,
    'geurts': 1,
    'geva': 2,
    'gevaert': 2,
    'geving': 2,
    'gewgaw': 2,
    'gewirtz': 2,
    'geyelin': 3,
    'geyer': 2,
    'geyser': 2,
    'geysers': 2,
    'gfeller': 3,
    'ghada': 2,
    'ghadafi': 3,
    'ghadafithe': 3,
    'ghadafito': 4,
    'ghafar': 2,
    'ghali': 2,
    'ghana': 2,
    'ghanaian': 3,
    'ghanaians': 3,
    'ghandi': 2,
    'gharbonifar': 4,
    'ghastliness': 3,
    'ghastly': 2,
    'ghattas': 2,
    'ghaznavi': 3,
    'ghee': 1,
    'gheen': 1,
    'ghent': 1,
    'gherardi': 3,
    'gherig': 2,
    'gherman': 2,
    'ghetto': 2,
    'ghettoize': 3,
    'ghettos': 2,
    'ghez': 1,
    'ghezzi': 2,
    'ghidella': 3,
    'ghio': 2,
    'ghitis': 2,
    'gholamreza': 4,
    'gholson': 2,
    'gholston': 2,
    'ghorbanifar': 4,
    'ghormley': 2,
    'ghose': 1,
    'ghosh': 1,
    'ghost': 1,
    'ghostbuster': 3,
    'ghostbusters': 3,
    'ghostlike': 2,
    'ghostly': 2,
    'ghosts': 1,
    'ghoul': 1,
    'ghoulish': 2,
    'ghoulishly': 3,
    'ghouls': 1,
    'ghrist': 1,
    'ghulam': 2,
    'ghuloum': 2,
    'giacalone': 4,
    'giacco': 2,
    'giacinta': 3,
    'giacobbe': 3,
    'giacomelli': 4,
    'giacometti': 4,
    'giacomini': 4,
    'giacomo': 3,
    'giaconda': 4,
    'giacone': 4,
    'giaimo': 2,
    'gialanella': 4,
    'giamatti': 4,
    'giambalvo': 3,
    'giambra': 2,
    'giambrone': 3,
    'giammalva': 4,
    'giammarco': 3,
    'giammarino': 4,
    'giampa': 2,
    'giampaolo': 3,
    'giampapa': 3,
    'giampietro': 3,
    'gian': 2,
    'giancana': 4,
    'giancarlo': 4,
    'giancola': 3,
    'gianelli': 3,
    'gianfrancesco': 4,
    'gianfranco': 3,
    'giang': 2,
    'giangrande': 3,
    'gianini': 3,
    'gianino': 3,
    'giannattasio': 5,
    'giannelli': 3,
    'giannetti': 3,
    'giannetto': 3,
    'gianni': 2,
    'giannini': 3,
    'giannino': 4,
    'giannola': 3,
    'giannone': 3,
    'giannotti': 3,
    'gianotti': 3,
    'giant': 2,
    'giants': 2,
    'giap': 2,
    'giaquinto': 3,
    'giard': 2,
    'giardi': 3,
    'giardina': 3,
    'giardini': 3,
    'giardino': 3,
    'giarratano': 4,
    'giarrusso': 3,
    'gib': 1,
    'gibas': 2,
    'gibb': 1,
    'gibbard': 2,
    'gibbens': 2,
    'gibberish': 3,
    'gibbins': 2,
    'gibble': 2,
    'gibbon': 2,
    'gibboney': 3,
    'gibbons': 2,
    'gibbs': 1,
    'gibby': 2,
    'gibe': 1,
    'gibeau': 2,
    'gibeault': 2,
    'giberson': 3,
    'gibert': 2,
    'gibes': 1,
    'gibian': 3,
    'giblen': 2,
    'gibler': 3,
    'giblet': 2,
    'giblin': 2,
    'gibney': 2,
    'giboney': 3,
    'gibraltar': 3,
    'gibran': 2,
    'gibson': 2,
    'gibsons': 2,
    'gick': 1,
    'gidcumb': 2,
    'giddens': 2,
    'giddings': 2,
    'giddy': 2,
    'gidel': 2,
    'gideon': 3,
    'gidget': 2,
    'gidley': 2,
    'gidney': 2,
    'gidwitz': 2,
    'giebel': 2,
    'giebler': 3,
    'gieck': 1,
    'giefer': 2,
    'gieger': 2,
    'giegerich': 3,
    'giel': 1,
    'gielgud': 2,
    'gielow': 2,
    'gienger': 2,
    'gienow': 2,
    'gier': 1,
    'giere': 1,
    'gierhart': 2,
    'gierke': 1,
    'gierman': 2,
    'giersch': 1,
    'gies': 1,
    'giesbrecht': 2,
    'giese': 1,
    'giesecke': 2,
    'gieseke': 2,
    'gieseler': 3,
    'gieselman': 3,
    'giesen': 2,
    'gieser': 2,
    'giesey': 2,
    'gieske': 1,
    'giesler': 3,
    'giessen': 2,
    'gietzen': 2,
    'giff': 1,
    'giffard': 2,
    'giffen': 2,
    'gifferd': 2,
    'giffin': 2,
    'gifford': 2,
    'giffords': 2,
    'giffy': 2,
    'gift': 1,
    'gifted': 2,
    'gifting': 2,
    'giftrust': 2,
    'gifts': 1,
    'giftware': 2,
    'gig': 1,
    'gigabyte': 3,
    'gigabytes': 3,
    'gigaflop': 3,
    'gigaflops': 3,
    'gigante': 3,
    'gigantic': 3,
    'giger': 2,
    'gigging': 2,
    'giggle': 2,
    'giggled': 2,
    'giggles': 2,
    'giggling': 3,
    'giggly': 3,
    'gigi': 2,
    'giglia': 3,
    'giglio': 3,
    'gigliotti': 4,
    'gignac': 2,
    'gignoux': 2,
    'gigolo': 3,
    'gigot': 2,
    'gigs': 1,
    'giguere': 3,
    'gikas': 2,
    'gil': 1,
    'gila': 2,
    'gilardi': 3,
    'gilb': 1,
    'gilberg': 2,
    'gilbert': 2,
    'gilberta': 3,
    'gilberte': 2,
    'gilberti': 3,
    'gilbertina': 4,
    'gilbertine': 4,
    'gilberto': 3,
    'gilbertson': 3,
    'gilbey': 2,
    'gilbo': 2,
    'gilboy': 2,
    'gilbreath': 2,
    'gilbreth': 2,
    'gilbride': 2,
    'gilby': 2,
    'gilchrest': 3,
    'gilchrist': 2,
    'gilcrease': 2,
    'gilcrest': 3,
    'gild': 1,
    'gilda': 2,
    'gilday': 2,
    'gildea': 3,
    'gilded': 2,
    'gilden': 2,
    'gilder': 2,
    'gildersleeve': 3,
    'gilding': 2,
    'gildner': 2,
    'gildon': 2,
    'gile': 1,
    'gilead': 2,
    'giles': 1,
    'gilfillan': 3,
    'gilford': 2,
    'gilger': 2,
    'gilgit': 2,
    'gilgore': 2,
    'gilham': 2,
    'gilhooly': 3,
    'gilkerson': 3,
    'gilkes': 1,
    'gilkeson': 3,
    'gilkey': 2,
    'gilkison': 3,
    'gilkisons': 3,
    'gill': 1,
    'gillam': 2,
    'gillan': 2,
    'gilland': 2,
    'gillard': 2,
    'gillaspie': 3,
    'gillaspy': 3,
    'gille': 1,
    'gillean': 2,
    'gilleland': 3,
    'gillem': 2,
    'gillen': 2,
    'gillentine': 3,
    'giller': 2,
    'gilleran': 3,
    'gillers': 2,
    'gilles': 1,
    'gillespie': 3,
    'gillet': 2,
    'gillett': 2,
    'gillette': 2,
    'gilley': 2,
    'gillham': 2,
    'gilliam': 3,
    'gillian': 3,
    'gilliand': 3,
    'gilliard': 3,
    'gilliardi': 3,
    'gilliatt': 3,
    'gillick': 2,
    'gillie': 2,
    'gillies': 2,
    'gillig': 2,
    'gilligan': 3,
    'gillihan': 3,
    'gillikin': 3,
    'gillilan': 3,
    'gilliland': 3,
    'gillin': 2,
    'gillingham': 3,
    'gillings': 2,
    'gillinov': 3,
    'gillins': 2,
    'gillis': 2,
    'gillison': 3,
    'gillispie': 3,
    'gillman': 2,
    'gillmore': 2,
    'gillock': 2,
    'gillogly': 3,
    'gillon': 2,
    'gillooly': 3,
    'gillott': 2,
    'gillotti': 3,
    'gills': 1,
    'gillson': 2,
    'gillum': 2,
    'gilly': 2,
    'gilman': 2,
    'gilmartin': 3,
    'gilmer': 2,
    'gilmore': 2,
    'gilmour': 2,
    'gilpatrick': 3,
    'gilpin': 2,
    'gilreath': 2,
    'gilroy': 2,
    'gilsdorf': 2,
    'gilson': 2,
    'gilstrap': 2,
    'gilt': 1,
    'giltner': 2,
    'gilts': 1,
    'gilvin': 2,
    'gilyard': 2,
    'gimbel': 2,
    'gimenez': 3,
    'gimli': 2,
    'gimlin': 2,
    'gimme': 2,
    'gimmick': 2,
    'gimmickry': 3,
    'gimmicks': 2,
    'gimmicky': 3,
    'gimpel': 2,
    'gin': 1,
    'gina': 2,
    'ginandjar': 3,
    'ginas': 2,
    'ginder': 2,
    'gindin': 2,
    'gindlesperger': 4,
    'gines': 1,
    'ginevra': 3,
    'ging': 1,
    'gingell': 2,
    'ginger': 2,
    'gingerbread': 3,
    'gingerich': 3,
    'gingerly': 3,
    'gingery': 3,
    'gingham': 2,
    'ginghams': 2,
    'gingles': 2,
    'gingold': 2,
    'gingras': 2,
    'gingrich': 2,
    'gingriches': 3,
    'ginley': 2,
    'ginn': 1,
    'ginned': 1,
    'ginnell': 2,
    'ginnie': 2,
    'ginning': 2,
    'ginny': 2,
    'gino': 2,
    'ginocchio': 4,
    'ginsberg': 2,
    'ginsburg': 2,
    'ginseng': 2,
    'ginsu': 2,
    'gintel': 2,
    'ginter': 2,
    'ginther': 2,
    'ginting': 2,
    'ginty': 2,
    'gintz': 1,
    'ginyard': 2,
    'ginza': 2,
    'ginzberg': 2,
    'gioia': 2,
    'gionet': 3,
    'gionfriddo': 3,
    'giordani': 3,
    'giordano': 3,
    'giorgi': 2,
    'giorgia': 2,
    'giorgio': 3,
    'giovanelli': 4,
    'giovanetti': 4,
    'giovannetti': 4,
    'giovanni': 4,
    'giovanniello': 5,
    'giovannini': 4,
    'giovannoni': 4,
    'giovenco': 3,
    'giovinazzo': 4,
    'gipe': 1,
    'gipp': 1,
    'gipper': 2,
    'gipple': 2,
    'gipson': 2,
    'giraffe': 2,
    'giraffes': 2,
    'giraldo': 3,
    'girard': 2,
    'girardi': 3,
    'girardin': 3,
    'girardot': 3,
    'giraud': 2,
    'gird': 1,
    'girder': 2,
    'girders': 2,
    'girding': 2,
    'girdle': 2,
    'girdler': 3,
    'girdley': 2,
    'girdner': 2,
    'girds': 1,
    'gire': 1,
    'giren': 2,
    'girgenti': 3,
    'girgis': 2,
    'girija': 3,
    'girl': 1,
    'girlfriend': 2,
    'girlfriends': 2,
    'girlhood': 2,
    'girlie': 2,
    'girlish': 2,
    'girlishly': 3,
    'girls': 1,
    'girobank': 3,
    'girod': 2,
    'giroir': 2,
    'girolamo': 4,
    'giroldi': 3,
    'giron': 2,
    'gironde': 2,
    'girouard': 3,
    'giroux': 2,
    'girozentrale': 4,
    'girsky': 2,
    'girt': 1,
    'girten': 2,
    'girth': 1,
    'girtman': 2,
    'girton': 2,
    'girvan': 2,
    'girven': 2,
    'girvin': 2,
    'gisbert': 2,
    'giscard': 2,
    'gisclair': 2,
    'gisela': 3,
    'gisella': 3,
    'giselle': 2,
    'gisenyi': 3,
    'gish': 1,
    'gisi': 2,
    'gisler': 3,
    'gismondi': 3,
    'gissendanner': 4,
    'gissi': 2,
    'gist': 1,
    'gisu': 2,
    'gitana': 3,
    'gitano': 3,
    'gitchell': 2,
    'githa': 2,
    'githens': 2,
    'gitlin': 2,
    'gittelman': 3,
    'gittens': 2,
    'gitter': 2,
    'gittings': 2,
    'gittins': 2,
    'gittis': 2,
    'gittleman': 3,
    'gittler': 2,
    'gitto': 2,
    'giudice': 3,
    'giudici': 3,
    'giuffre': 2,
    'giuffrida': 3,
    'giuliani': 4,
    'giuliano': 4,
    'giulio': 3,
    'giunta': 2,
    'giurescu': 3,
    'giuseppe': 3,
    'giusti': 2,
    'giusto': 2,
    'givan': 2,
    'give': 1,
    'giveaway': 3,
    'giveaways': 3,
    'giveback': 2,
    'givebacks': 2,
    'given': 2,
    'givenchy': 3,
    'givenness': 3,
    'givens': 2,
    'giver': 2,
    'givers': 2,
    'gives': 1,
    'giveth': 2,
    'givhan': 2,
    'giving': 2,
    'givins': 2,
    'givler': 2,
    'giza': 2,
    'gizbert': 2,
    'gizmo': 2,
    'gizmos': 2,
    'gizzard': 2,
    'gizzi': 2,
    'gjelten': 2,
    'gjerde': 1,
    'glaab': 1,
    'glab': 1,
    'glace': 1,
    'glacial': 2,
    'glaciate': 3,
    'glaciated': 4,
    'glaciation': 4,
    'glacier': 2,
    'glaciers': 2,
    'glacis': 2,
    'glacken': 2,
    'glackin': 2,
    'glad': 1,
    'gladd': 1,
    'gladden': 2,
    'gladding': 2,
    'gladdock': 2,
    'glade': 1,
    'glades': 1,
    'gladfelter': 3,
    'gladhill': 2,
    'gladiator': 4,
    'gladiators': 4,
    'gladieux': 3,
    'gladiolus': 4,
    'gladis': 2,
    'gladish': 2,
    'gladje': 2,
    'gladly': 2,
    'gladman': 2,
    'gladney': 2,
    'gladson': 2,
    'gladstein': 2,
    'gladstone': 2,
    'gladstones': 2,
    'gladu': 2,
    'gladwell': 2,
    'gladwin': 2,
    'gladys': 2,
    'glaeser': 2,
    'glahn': 1,
    'glamor': 2,
    'glamorize': 3,
    'glamorized': 3,
    'glamorizing': 4,
    'glamorous': 3,
    'glamour': 2,
    'glance': 1,
    'glanced': 1,
    'glances': 2,
    'glancing': 2,
    'glancy': 2,
    'gland': 1,
    'glander': 2,
    'glandon': 2,
    'glands': 1,
    'glandular': 3,
    'glanton': 2,
    'glantz': 1,
    'glanville': 2,
    'glanz': 1,
    'glanzer': 2,
    'glanzman': 2,
    'glare': 1,
    'glared': 1,
    'glares': 1,
    'glaring': 2,
    'glaris': 2,
    'glas': 1,
    'glasby': 2,
    'glasco': 2,
    'glascock': 2,
    'glascoe': 2,
    'glaser': 2,
    'glasgow': 2,
    'glasheen': 2,
    'glashow': 2,
    'glasner': 2,
    'glasnost': 2,
    'glasow': 2,
    'glasper': 2,
    'glaspie': 2,
    'glaspy': 2,
    'glass': 1,
    'glassberg': 2,
    'glassblower': 3,
    'glassblowers': 3,
    'glassblowing': 3,
    'glassburn': 2,
    'glassco': 2,
    'glasscock': 2,
    'glassed': 1,
    'glasser': 2,
    'glasses': 2,
    'glassey': 2,
    'glassford': 2,
    'glasslike': 2,
    'glassmaker': 3,
    'glassmaking': 3,
    'glassman': 2,
    'glassmeyer': 3,
    'glassner': 2,
    'glasson': 2,
    'glassware': 2,
    'glassy': 2,
    'glastetter': 3,
    'glatfelter': 3,
    'glatt': 1,
    'glatz': 1,
    'glatzer': 2,
    'glaub': 1,
    'glauber': 2,
    'glaucoma': 3,
    'glaude': 1,
    'glaus': 1,
    'glauser': 2,
    'glavan': 2,
    'glavin': 2,
    'glavine': 2,
    'glawe': 1,
    'glaxo': 2,
    'glaza': 2,
    'glaze': 1,
    'glazebrook': 2,
    'glazed': 1,
    'glazener': 3,
    'glazer': 2,
    'glazes': 2,
    'glazier': 3,
    'glazing': 2,
    'glazner': 2,
    'glazunov': 3,
    'gleacher': 2,
    'gleam': 1,
    'gleamed': 1,
    'gleaming': 2,
    'gleams': 1,
    'glean': 1,
    'gleaned': 1,
    'gleason': 2,
    'gleaton': 2,
    'gleave': 1,
    'gleaves': 1,
    'gleba': 2,
    'gleckler': 2,
    'gleda': 2,
    'gledhill': 2,
    'glee': 1,
    'gleeful': 2,
    'gleefully': 3,
    'gleeson': 2,
    'gleghorn': 2,
    'gleich': 1,
    'gleichauf': 2,
    'gleim': 1,
    'gleisner': 2,
    'glemp': 1,
    'glen': 1,
    'glenayre': 2,
    'glenbrook': 2,
    'glenda': 2,
    'glendale': 2,
    'glendening': 3,
    'glendenning': 3,
    'glendinning': 3,
    'glendon': 2,
    'glenfed': 2,
    'glengarry': 3,
    'glenham': 2,
    'glenmore': 2,
    'glenn': 1,
    'glenna': 2,
    'glennie': 2,
    'glennon': 2,
    'glenny': 2,
    'glenora': 3,
    'glens': 1,
    'glenview': 2,
    'glenville': 2,
    'glenwood': 2,
    'gless': 1,
    'glessner': 2,
    'glew': 1,
    'glib': 1,
    'glibly': 2,
    'glick': 1,
    'glickenhaus': 3,
    'glickman': 2,
    'glicksman': 2,
    'glickstein': 2,
    'glidden': 2,
    'glide': 1,
    'glided': 2,
    'glider': 2,
    'gliders': 2,
    'glides': 1,
    'glidewell': 2,
    'gliding': 2,
    'glimcher': 2,
    'glimmer': 2,
    'glimmering': 3,
    'glimmers': 2,
    'glimpse': 1,
    'glimpsed': 1,
    'glimpses': 2,
    'glines': 1,
    'glinka': 2,
    'glinski': 2,
    'glint': 1,
    'glinting': 2,
    'glissandi': 3,
    'glisson': 2,
    'glisten': 2,
    'glistened': 2,
    'glistening': 3,
    'glitch': 1,
    'glitches': 2,
    'glitter': 2,
    'glittered': 2,
    'glittering': 3,
    'glitters': 2,
    'glittery': 3,
    'glitz': 1,
    'glitzy': 2,
    'glo': 1,
    'gloam': 1,
    'gloaming': 2,
    'gloat': 1,
    'gloated': 2,
    'gloating': 2,
    'gloats': 1,
    'glob': 1,
    'global': 2,
    'globalization': 5,
    'globalize': 3,
    'globalized': 3,
    'globally': 3,
    'globalstar': 3,
    'globcom': 2,
    'globe': 1,
    'globes': 1,
    'globetrotter': 3,
    'globetrotters': 3,
    'globex': 2,
    'globo': 2,
    'globs': 1,
    'globular': 3,
    'globulin': 3,
    'globulins': 3,
    'globus': 2,
    'glocester': 2,
    'glock': 1,
    'glockenspiel': 3,
    'glockner': 2,
    'glod': 1,
    'glodowski': 3,
    'gloe': 1,
    'gloeckner': 2,
    'gloede': 1,
    'glogowski': 3,
    'glomar': 2,
    'glomb': 1,
    'glomski': 2,
    'glonass': 2,
    'gloom': 1,
    'gloomier': 3,
    'gloomily': 3,
    'gloomy': 2,
    'gloor': 1,
    'glop': 1,
    'gloppy': 2,
    'glor': 1,
    'glore': 1,
    'glori': 2,
    'gloria': 3,
    'gloriana': 4,
    'gloriane': 3,
    'glories': 2,
    'glorification': 5,
    'glorified': 3,
    'glorifies': 3,
    'glorify': 3,
    'glorifying': 4,
    'glorioso': 4,
    'glorious': 3,
    'gloriously': 4,
    'glory': 2,
    'gloss': 1,
    'glossary': 3,
    'glossed': 1,
    'glosser': 2,
    'glosses': 2,
    'glossier': 3,
    'glossman': 2,
    'glosson': 2,
    'glossy': 2,
    'gloster': 2,
    'glotfelty': 3,
    'glottal': 2,
    'glottis': 2,
    'glotzbach': 2,
    'gloucester': 2,
    'glove': 1,
    'gloved': 1,
    'glover': 2,
    'gloves': 1,
    'glow': 1,
    'glowacki': 3,
    'glowed': 1,
    'glower': 2,
    'glowered': 2,
    'glowering': 3,
    'glowing': 2,
    'glowingly': 3,
    'glows': 1,
    'gloyd': 1,
    'gluck': 1,
    'gluckman': 2,
    'glucksman': 2,
    'glucosamate': 4,
    'glucosamine': 4,
    'glucose': 2,
    'glucoside': 3,
    'glucosides': 3,
    'glue': 1,
    'glueck': 1,
    'glued': 1,
    'glues': 1,
    'glum': 1,
    'glumly': 2,
    'glummer': 2,
    'glunt': 1,
    'gluntz': 1,
    'glunz': 1,
    'gluskin': 2,
    'glut': 1,
    'glutamate': 3,
    'glutamic': 3,
    'glutaric': 3,
    'gluten': 2,
    'gluth': 1,
    'gluts': 1,
    'glutted': 2,
    'gluttonous': 3,
    'gluttons': 2,
    'gluttony': 3,
    'glycel': 2,
    'glycemic': 3,
    'glycerol': 3,
    'glycine': 2,
    'glycogen': 3,
    'glycol': 2,
    'glycolic': 3,
    'glycomed': 2,
    'glycoside': 3,
    'glymph': 1,
    'glyn': 1,
    'glyndebourne': 2,
    'glynis': 2,
    'glynn': 1,
    'glynnie': 2,
    'glynnis': 2,
    'gmbh': 1,
    'gnagey': 2,
    'gnaizda': 2,
    'gnann': 1,
    'gnarl': 1,
    'gnarle': 1,
    'gnarled': 1,
    'gnarling': 2,
    'gnarls': 1,
    'gnarly': 2,
    'gnash': 1,
    'gnashing': 2,
    'gnat': 1,
    'gnatcatcher': 3,
    'gnatcatchers': 3,
    'gnats': 1,
    'gnau': 1,
    'gnaw': 1,
    'gnawed': 1,
    'gnawing': 2,
    'gnaws': 1,
    'gnc': 3,
    'gneiss': 1,
    'gnocchi': 2,
    'gnome': 1,
    'gnomes': 1,
    'gnomic': 2,
    'gnomonic': 3,
    'gnosticism': 4,
    'gnu': 1,
    'go': 1,
    'goa': 2,
    'goad': 1,
    'goaded': 2,
    'goading': 2,
    'goal': 1,
    'goalie': 2,
    'goalkeeper': 3,
    'goalpost': 2,
    'goals': 1,
    'goan': 1,
    'goans': 1,
    'goar': 1,
    'goard': 1,
    'goat': 1,
    'goatee': 2,
    'goates': 2,
    'goatley': 2,
    'goats': 1,
    'gob': 1,
    'gobain': 2,
    'gobble': 2,
    'gobbled': 2,
    'gobbledygook': 4,
    'gobbler': 3,
    'gobblers': 3,
    'gobbles': 2,
    'gobbling': 3,
    'gobel': 2,
    'goben': 2,
    'gober': 2,
    'goberstein': 3,
    'gobert': 2,
    'gobi': 2,
    'gobie': 2,
    'gobin': 2,
    'gobinot': 3,
    'gobioff': 3,
    'goble': 2,
    'goblet': 2,
    'goblin': 2,
    'goblins': 2,
    'goblirsch': 2,
    'gobs': 1,
    'gochenour': 3,
    'gochnauer': 3,
    'gochnour': 2,
    'gocke': 1,
    'gockel': 2,
    'gockley': 2,
    'god': 1,
    'goda': 2,
    'godar': 2,
    'godard': 2,
    'godart': 2,
    'godbee': 2,
    'godbey': 2,
    'godbold': 2,
    'godbolt': 2,
    'godbout': 2,
    'godby': 2,
    'goddam': 2,
    'goddamn': 2,
    'goddard': 2,
    'goddart': 2,
    'goddeau': 2,
    'godden': 2,
    'goddess': 2,
    'goddesses': 3,
    'godding': 2,
    'godek': 2,
    'godette': 2,
    'godfather': 3,
    'godfathers': 3,
    'godfrey': 2,
    'godhead': 2,
    'godin': 2,
    'godina': 3,
    'godine': 2,
    'godines': 2,
    'godinez': 3,
    'goding': 2,
    'godino': 3,
    'godiva': 3,
    'godkin': 2,
    'godleski': 3,
    'godless': 2,
    'godlewski': 3,
    'godley': 2,
    'godlove': 2,
    'godly': 2,
    'godman': 2,
    'godmother': 3,
    'godmothers': 3,
    'godot': 2,
    'godown': 2,
    'godoy': 2,
    'godrej': 2,
    'gods': 1,
    'godsell': 2,
    'godsend': 2,
    'godsends': 2,
    'godsey': 2,
    'godshalk': 2,
    'godshall': 2,
    'godspeed': 2,
    'godspell': 2,
    'godunov': 3,
    'godwin': 2,
    'godzilla': 3,
    'goe': 1,
    'goeas': 3,
    'goebbels': 2,
    'goebel': 2,
    'goecke': 1,
    'goedde': 1,
    'goede': 1,
    'goedecke': 2,
    'goeden': 2,
    'goedert': 2,
    'goedken': 2,
    'goeglein': 2,
    'goehner': 2,
    'goehring': 2,
    'goeke': 1,
    'goeken': 2,
    'goeller': 2,
    'goellner': 2,
    'goelz': 1,
    'goelzer': 2,
    'goeman': 2,
    'goemon': 3,
    'goen': 1,
    'goens': 1,
    'goepfert': 2,
    'goer': 2,
    'goergen': 2,
    'goering': 3,
    'goerke': 1,
    'goerlitz': 2,
    'goerner': 2,
    'goers': 2,
    'goertz': 1,
    'goertzen': 2,
    'goes': 1,
    'goeser': 2,
    'goetabanken': 4,
    'goethals': 2,
    'goethe': 1,
    'goetsch': 1,
    'goette': 1,
    'goettel': 2,
    'goetting': 2,
    'goettl': 2,
    'goettsch': 1,
    'goetz': 1,
    'goetze': 1,
    'goetzinger': 3,
    'goetzke': 2,
    'goetzman': 2,
    'goewey': 3,
    'goff': 1,
    'goffin': 2,
    'goffinet': 3,
    'goffman': 2,
    'goffney': 2,
    'goffredo': 3,
    'goforth': 2,
    'goga': 2,
    'gogan': 2,
    'gogel': 2,
    'goggans': 2,
    'goggin': 2,
    'goggins': 2,
    'goggle': 2,
    'goggles': 2,
    'gogh': 1,
    'goghs': 1,
    'goglia': 3,
    'gogo': 2,
    'goguen': 2,
    'goh': 1,
    'goheen': 2,
    'gohl': 1,
    'gohlke': 1,
    'gohman': 2,
    'gohn': 1,
    'gohr': 1,
    'goin': 1,
    'goines': 1,
    'going': 2,
    'goings': 2,
    'goins': 1,
    'goiter': 2,
    'goizueta': 3,
    'gokey': 2,
    'goksel': 2,
    'gola': 2,
    'golab': 2,
    'golan': 2,
    'goland': 2,
    'golas': 2,
    'golaszewski': 4,
    'golay': 2,
    'golba': 2,
    'golberg': 2,
    'golconda': 3,
    'gold': 1,
    'golda': 2,
    'goldade': 2,
    'goldammer': 3,
    'goldbach': 2,
    'goldbaum': 2,
    'goldbeck': 2,
    'goldberg': 2,
    'goldberger': 3,
    'goldblatt': 2,
    'goldblum': 2,
    'goldcor': 2,
    'goldcreek': 2,
    'goldcrest': 2,
    'golde': 1,
    'golden': 2,
    'goldenberg': 3,
    'goldeneye': 3,
    'goldenrod': 3,
    'goldenson': 3,
    'goldenstein': 3,
    'goldenthal': 3,
    'golder': 2,
    'goldest': 2,
    'goldey': 2,
    'goldfarb': 2,
    'goldfeder': 3,
    'goldfield': 2,
    'goldfields': 2,
    'goldfinch': 2,
    'goldfinches': 3,
    'goldfine': 2,
    'goldfinger': 3,
    'goldfish': 2,
    'goldfus': 2,
    'goldhammer': 3,
    'goldie': 2,
    'goldilocks': 3,
    'goldin': 2,
    'golding': 2,
    'goldinger': 3,
    'goldman': 2,
    'goldmann': 2,
    'goldmans': 2,
    'goldmark': 2,
    'goldmine': 2,
    'goldner': 2,
    'goldome': 2,
    'goldress': 2,
    'goldrick': 2,
    'goldring': 2,
    'golds': 1,
    'goldsberry': 3,
    'goldsborough': 3,
    'goldsby': 2,
    'goldschmid': 2,
    'goldschmidt': 2,
    'goldsmith': 2,
    'goldson': 2,
    'goldstar': 2,
    'goldstein': 2,
    'goldstock': 2,
    'goldston': 2,
    'goldstone': 2,
    'goldstrike': 2,
    'goldsworthy': 3,
    'goldthwaite': 2,
    'goldwasser': 3,
    'goldwater': 3,
    'goldwin': 2,
    'goldwire': 2,
    'goldwyn': 2,
    'goldy': 2,
    'golec': 2,
    'golem': 2,
    'goleman': 2,
    'golembeski': 4,
    'golembiewski': 5,
    'golen': 2,
    'goley': 2,
    'golf': 1,
    'golfarb': 2,
    'golfed': 1,
    'golfer': 2,
    'golfers': 2,
    'golfie': 2,
    'golfing': 2,
    'golfman': 2,
    'golfs': 1,
    'golgi': 2,
    'golgo': 2,
    'goliath': 3,
    'goliaths': 3,
    'golightly': 3,
    'golinski': 3,
    'golisano': 4,
    'golkar': 2,
    'goll': 1,
    'golla': 2,
    'golladay': 3,
    'golle': 1,
    'goller': 2,
    'golliday': 3,
    'golliher': 3,
    'gollnick': 2,
    'gollum': 2,
    'gollust': 2,
    'golly': 2,
    'golob': 2,
    'golomb': 2,
    'golombek': 3,
    'golonka': 3,
    'goloven': 3,
    'golphin': 2,
    'golson': 2,
    'golston': 2,
    'goltz': 1,
    'golub': 2,
    'golubski': 3,
    'golz': 1,
    'goma': 2,
    'gombar': 2,
    'gomberg': 2,
    'gombert': 2,
    'gombos': 2,
    'gomer': 2,
    'gomery': 3,
    'gomes': 2,
    'gomez': 2,
    'gomillion': 3,
    'gomoll': 2,
    'gomorrah': 3,
    'gomory': 3,
    'gonad': 2,
    'gonads': 2,
    'gonaives': 2,
    'goncalves': 3,
    'gonce': 1,
    'goncharov': 3,
    'gond': 1,
    'gonda': 2,
    'gondek': 2,
    'gonder': 2,
    'gondola': 3,
    'gondolas': 3,
    'gondolier': 3,
    'gondoliers': 3,
    'gondwana': 3,
    'gondwanaland': 4,
    'gone': 1,
    'goner': 2,
    'gonet': 2,
    'gong': 1,
    'gongaware': 3,
    'gongora': 3,
    'gongs': 1,
    'gonia': 3,
    'goniometer': 5,
    'gonium': 3,
    'gonna': 2,
    'gonnella': 3,
    'gonnerman': 3,
    'gonococcal': 4,
    'gonorrhea': 4,
    'gonsales': 2,
    'gonsalez': 3,
    'gonsalves': 3,
    'gonser': 2,
    'gonsoulin': 3,
    'gonterman': 3,
    'gonya': 2,
    'gonyea': 2,
    'gonyer': 2,
    'gonyo': 2,
    'gonzales': 3,
    'gonzalez': 3,
    'gonzalo': 3,
    'gonzo': 2,
    'goo': 1,
    'gooch': 1,
    'good': 1,
    'gooda': 2,
    'goodale': 2,
    'goodall': 2,
    'goodbar': 2,
    'goodbread': 2,
    'goodby': 2,
    'goodbye': 2,
    'goodbyes': 2,
    'goodchild': 2,
    'goode': 1,
    'goodell': 2,
    'gooden': 2,
    'goodenough': 3,
    'goodenow': 3,
    'gooder': 2,
    'gooderham': 3,
    'gooders': 2,
    'goodfella': 3,
    'goodfellas': 3,
    'goodfellow': 3,
    'goodfellows': 3,
    'goodfriend': 2,
    'goodfriends': 2,
    'goodgame': 2,
    'goodhart': 2,
    'goodheart': 2,
    'goodhue': 2,
    'goodie': 2,
    'goodies': 2,
    'goodin': 2,
    'goodine': 2,
    'gooding': 2,
    'goodison': 3,
    'goodkin': 2,
    'goodland': 2,
    'goodlet': 2,
    'goodlett': 2,
    'goodley': 2,
    'goodlin': 2,
    'goodling': 2,
    'goodloe': 2,
    'goodlow': 2,
    'goodly': 2,
    'goodman': 2,
    'goodmorning': 3,
    'goodner': 2,
    'goodness': 2,
    'goodnight': 2,
    'goodnights': 2,
    'goodnough': 2,
    'goodnow': 2,
    'goodpaster': 3,
    'goodpasture': 3,
    'goodreau': 2,
    'goodrich': 2,
    'goodrick': 2,
    'goodridge': 2,
    'goodroe': 2,
    'goodrow': 2,
    'goodrum': 2,
    'goods': 1,
    'goodsell': 2,
    'goodson': 2,
    'goodspeed': 2,
    'goodstein': 2,
    'goodtab': 2,
    'goodwill': 2,
    'goodwin': 2,
    'goodwine': 2,
    'goodwyn': 2,
    'goody': 2,
    'goodyear': 2,
    'gooey': 2,
    'goof': 1,
    'goofed': 1,
    'goofing': 2,
    'goofs': 1,
    'goofy': 2,
    'googe': 1,
    'googin': 2,
    'googins': 2,
    'gook': 1,
    'gookin': 2,
    'goold': 1,
    'gooley': 2,
    'goolsby': 2,
    'goon': 1,
    'goonan': 2,
    'goons': 1,
    'goop': 1,
    'goos': 1,
    'goosby': 2,
    'goose': 1,
    'gooseberries': 3,
    'gooseberry': 3,
    'goosefish': 2,
    'goosefoot': 2,
    'goosen': 2,
    'goosey': 2,
    'goossen': 2,
    'goossens': 2,
    'goostree': 2,
    'gootee': 2,
    'gopac': 2,
    'gopal': 2,
    'gopher': 2,
    'gophers': 2,
    'gopi': 2,
    'gopinath': 3,
    'gora': 2,
    'goracke': 2,
    'goradze': 3,
    'goral': 2,
    'goralski': 3,
    'goran': 2,
    'goranson': 3,
    'gorazde': 3,
    'gorbachev': 3,
    'gorbachevs': 3,
    'gorby': 2,
    'gorczyca': 3,
    'gorczynski': 3,
    'gorda': 2,
    'gordan': 2,
    'gordana': 3,
    'gorden': 2,
    'gorder': 2,
    'gordeyev': 3,
    'gordian': 3,
    'gordie': 2,
    'gordillo': 3,
    'gordin': 2,
    'gordinier': 4,
    'gordji': 2,
    'gordner': 2,
    'gordon': 2,
    'gordy': 2,
    'gore': 1,
    'gorecki': 3,
    'gored': 1,
    'goree': 2,
    'gorelick': 3,
    'goren': 2,
    'gorenflo': 3,
    'gores': 1,
    'gorey': 2,
    'gorgas': 2,
    'gorge': 1,
    'gorgeous': 2,
    'gorges': 2,
    'gorgets': 2,
    'gorgon': 2,
    'gorgone': 2,
    'gorgonian': 4,
    'gorgons': 2,
    'gorguze': 2,
    'gorham': 2,
    'gori': 2,
    'goria': 3,
    'gorilla': 3,
    'gorillas': 3,
    'gorin': 2,
    'goring': 2,
    'goris': 2,
    'gorka': 2,
    'gorki': 2,
    'gorky': 2,
    'gorley': 2,
    'gorman': 2,
    'gormley': 2,
    'gormly': 2,
    'gorney': 2,
    'gorniak': 3,
    'gornick': 2,
    'gornik': 2,
    'gornto': 2,
    'gorny': 2,
    'gorospe': 3,
    'gorr': 1,
    'gorrell': 2,
    'gorriak': 3,
    'gorrid': 2,
    'gorrids': 2,
    'gorski': 2,
    'gorsky': 2,
    'gorsline': 2,
    'gorsuch': 2,
    'gort': 1,
    'gortari': 3,
    'gorter': 2,
    'gortney': 2,
    'gorton': 2,
    'gorum': 2,
    'gory': 2,
    'gosa': 2,
    'gosbank': 2,
    'gosch': 1,
    'gosdin': 2,
    'gose': 1,
    'gosh': 1,
    'gosha': 2,
    'goshawk': 2,
    'goshen': 2,
    'goshorn': 2,
    'goslee': 2,
    'goslin': 2,
    'gosline': 2,
    'gosman': 2,
    'gosnell': 2,
    'gosney': 2,
    'gosornstem': 3,
    'gospel': 2,
    'gospels': 2,
    'gosplan': 2,
    'goss': 1,
    'gossage': 2,
    'gossamer': 3,
    'gossard': 2,
    'gosse': 1,
    'gosselin': 3,
    'gossen': 2,
    'gosser': 2,
    'gossett': 2,
    'gossip': 2,
    'gossiper': 3,
    'gossipers': 3,
    'gossiping': 3,
    'gossips': 2,
    'gossipy': 3,
    'gossman': 2,
    'gostev': 2,
    'gostomski': 3,
    'goswick': 2,
    'got': 1,
    'gotaas': 2,
    'gotbaum': 2,
    'gotch': 1,
    'gotcha': 2,
    'gotcher': 2,
    'goth': 1,
    'gotham': 2,
    'gothard': 2,
    'gothenburg': 3,
    'gothic': 2,
    'gotlieb': 2,
    'goto': 2,
    'gotsch': 1,
    'gotschal': 2,
    'gotschall': 2,
    'gotshal': 2,
    'gotshall': 2,
    'gott': 1,
    'gotta': 2,
    'gotten': 2,
    'gotterdammerung': 5,
    'gottesman': 2,
    'gottfried': 2,
    'gotthardt': 2,
    'gotthelf': 2,
    'gotti': 2,
    'gottis': 2,
    'gottlieb': 2,
    'gottman': 2,
    'gotts': 1,
    'gottsch': 1,
    'gottschalk': 2,
    'gottschalks': 2,
    'gottschall': 2,
    'gottshall': 2,
    'gottwald': 2,
    'gotwalt': 2,
    'gotz': 1,
    'goucher': 2,
    'goude': 1,
    'goudeau': 2,
    'goudie': 2,
    'goudreau': 2,
    'goudy': 2,
    'gouge': 1,
    'gouged': 1,
    'gougeon': 2,
    'gouger': 2,
    'gough': 1,
    'goughnour': 2,
    'gouging': 2,
    'gouin': 1,
    'gouker': 2,
    'goulart': 2,
    'goulash': 2,
    'gould': 1,
    'goulden': 2,
    'goulding': 2,
    'goulet': 2,
    'goulette': 2,
    'goulston': 2,
    'gounod': 2,
    'goupil': 2,
    'gourd': 1,
    'gourdine': 2,
    'gourds': 1,
    'gourlay': 2,
    'gourley': 2,
    'gourment': 2,
    'gourmet': 2,
    'gourmets': 2,
    'gousha': 2,
    'gout': 1,
    'goutal': 2,
    'gouty': 2,
    'gouvea': 3,
    'gouveia': 4,
    'gov': 1,
    'govan': 2,
    'gove': 1,
    'govea': 3,
    'gover': 2,
    'govern': 2,
    'governale': 3,
    'governance': 3,
    'governed': 2,
    'governess': 3,
    'governing': 3,
    'government': 3,
    'governmental': 4,
    'governmentally': 5,
    'governments': 3,
    'governor': 3,
    'governors': 3,
    'governorship': 4,
    'governorships': 4,
    'governs': 2,
    'govett': 2,
    'govier': 3,
    'govoni': 3,
    'govpx': 3,
    'govs': 1,
    'gow': 1,
    'gowan': 2,
    'gowans': 2,
    'goward': 2,
    'gowdy': 2,
    'gowell': 2,
    'gowen': 2,
    'gowens': 2,
    'gower': 2,
    'gowin': 2,
    'gowing': 2,
    'gowins': 2,
    'gown': 1,
    'gowns': 1,
    'goy': 1,
    'goya': 2,
    'goyer': 2,
    'goyette': 2,
    'goyim': 2,
    'goyko': 2,
    'goyne': 1,
    'goynes': 1,
    'goza': 2,
    'gozo': 2,
    'gozofsky': 3,
    'grab': 1,
    'grabau': 2,
    'grabbe': 1,
    'grabbed': 1,
    'grabber': 2,
    'grabbers': 2,
    'grabbing': 2,
    'grabe': 1,
    'grabel': 2,
    'graben': 2,
    'grabens': 2,
    'grabenstein': 3,
    'graber': 2,
    'grabert': 2,
    'grabill': 2,
    'grabinski': 3,
    'grable': 2,
    'grabner': 2,
    'graboski': 3,
    'grabow': 2,
    'grabowski': 3,
    'graboy': 2,
    'grabs': 1,
    'grabski': 2,
    'grace': 1,
    'graced': 1,
    'graceffa': 3,
    'graceful': 2,
    'gracefully': 3,
    'graceland': 2,
    'graceless': 2,
    'graces': 2,
    'gracey': 2,
    'grachev': 2,
    'grachov': 2,
    'graci': 2,
    'gracia': 2,
    'graciano': 4,
    'gracie': 2,
    'gracile': 2,
    'gracing': 2,
    'gracious': 2,
    'graciously': 3,
    'graciousness': 3,
    'grackle': 2,
    'grackles': 2,
    'graco': 2,
    'gracy': 2,
    'graczyk': 2,
    'grad': 1,
    'gradate': 2,
    'gradation': 3,
    'gradations': 3,
    'gradchev': 2,
    'gradco': 2,
    'graddy': 2,
    'grade': 1,
    'graded': 2,
    'gradel': 2,
    'graden': 2,
    'grader': 2,
    'graders': 2,
    'grades': 1,
    'gradient': 3,
    'gradients': 3,
    'grading': 2,
    'gradison': 3,
    'gradney': 2,
    'grado': 2,
    'gradov': 2,
    'gradovs': 2,
    'grads': 1,
    'gradstein': 2,
    'gradual': 3,
    'gradualism': 5,
    'gradualist': 4,
    'gradually': 4,
    'graduate': 3,
    'graduated': 4,
    'graduates': 3,
    'graduating': 4,
    'graduation': 4,
    'graduations': 4,
    'gradus': 2,
    'grady': 2,
    'graeber': 2,
    'graebner': 2,
    'graef': 1,
    'graefe': 1,
    'graeff': 1,
    'graeme': 1,
    'graeser': 2,
    'graessle': 2,
    'graetz': 1,
    'graf': 1,
    'grafe': 1,
    'graff': 1,
    'graffam': 2,
    'graffeo': 3,
    'graffiti': 3,
    'graffius': 3,
    'graft': 1,
    'grafted': 2,
    'grafting': 2,
    'grafton': 2,
    'grafts': 1,
    'grage': 1,
    'gragert': 2,
    'gragg': 1,
    'graham': 2,
    'grahams': 1,
    'grahek': 2,
    'grahl': 1,
    'grahn': 1,
    'graichen': 2,
    'grail': 1,
    'grain': 1,
    'grained': 1,
    'grainery': 3,
    'grainger': 3,
    'grains': 1,
    'grainy': 2,
    'grajeda': 3,
    'graley': 2,
    'grall': 1,
    'gram': 1,
    'grama': 2,
    'gramaphone': 3,
    'grambling': 2,
    'gramer': 2,
    'gramercy': 3,
    'gramersey': 3,
    'grames': 1,
    'gramley': 2,
    'gramlich': 2,
    'gramling': 2,
    'gramm': 1,
    'grammar': 2,
    'grammatical': 4,
    'grammatico': 4,
    'grammer': 2,
    'grammies': 2,
    'grammophon': 3,
    'gramms': 1,
    'grammy': 2,
    'grammys': 2,
    'grams': 1,
    'gramza': 2,
    'gran': 1,
    'grana': 2,
    'granada': 3,
    'granade': 2,
    'granado': 3,
    'granados': 3,
    'granahan': 3,
    'granat': 2,
    'granata': 3,
    'granato': 3,
    'granberg': 2,
    'granberry': 3,
    'grancare': 2,
    'grand': 1,
    'granda': 2,
    'grandad': 2,
    'grandbabies': 3,
    'grandbaby': 3,
    'grandberry': 3,
    'grandchamp': 2,
    'grandchild': 2,
    'grandchildren': 3,
    'granddaddy': 3,
    'granddaughter': 3,
    'granddaughters': 3,
    'grande': 1,
    'grander': 2,
    'granderson': 3,
    'grandest': 2,
    'grandeur': 2,
    'grandfather': 3,
    'grandfathered': 3,
    'grandfathering': 4,
    'grandfatherly': 4,
    'grandfathers': 3,
    'grandfield': 2,
    'grandi': 2,
    'grandillo': 3,
    'grandiloquent': 4,
    'grandin': 2,
    'grandinetti': 4,
    'grandiose': 3,
    'grandis': 2,
    'grandison': 3,
    'grandkid': 2,
    'grandkids': 2,
    'grandly': 2,
    'grandma': 2,
    'grandmaison': 3,
    'grandmas': 2,
    'grandmaster': 3,
    'grandmet': 2,
    'grandmother': 3,
    'grandmotherly': 4,
    'grandmothers': 3,
    'grandnephew': 3,
    'grandnephews': 3,
    'grandon': 2,
    'grandpa': 2,
    'grandparent': 3,
    'grandparents': 3,
    'grandpre': 2,
    'grands': 1,
    'grandson': 2,
    'grandsons': 2,
    'grandstaff': 2,
    'grandstand': 2,
    'grandstanding': 3,
    'grandt': 1,
    'granduncle': 3,
    'granduncles': 3,
    'grandview': 2,
    'grandy': 2,
    'graner': 2,
    'granese': 2,
    'graney': 2,
    'granfield': 2,
    'grange': 1,
    'granger': 2,
    'grangers': 2,
    'granges': 2,
    'granholm': 2,
    'granier': 3,
    'granieri': 3,
    'granillo': 3,
    'graningeverken': 5,
    'granite': 2,
    'granitic': 3,
    'granito': 3,
    'granlund': 2,
    'grannan': 2,
    'grannies': 2,
    'grannis': 2,
    'granny': 2,
    'grano': 2,
    'granoff': 2,
    'granola': 3,
    'granquist': 2,
    'granstrom': 2,
    'grant': 1,
    'granted': 2,
    'grantham': 2,
    'granting': 2,
    'grantland': 2,
    'grantor': 2,
    'grantors': 2,
    'grantree': 2,
    'grants': 1,
    'grantz': 1,
    'granular': 3,
    'granulation': 4,
    'granule': 2,
    'granules': 2,
    'granulocyte': 4,
    'granum': 2,
    'granville': 2,
    'granz': 1,
    'granzow': 2,
    'grapage': 2,
    'grape': 1,
    'grapefruit': 2,
    'grapefruits': 2,
    'graper': 2,
    'grapes': 1,
    'grapeshot': 2,
    'grapevine': 2,
    'grapevines': 2,
    'graph': 1,
    'graphic': 2,
    'graphical': 3,
    'graphically': 3,
    'graphics': 2,
    'graphite': 2,
    'graphology': 4,
    'graphs': 1,
    'grapple': 2,
    'grappled': 2,
    'grapples': 2,
    'grappling': 2,
    'gras': 1,
    'graser': 2,
    'grasmick': 2,
    'grasp': 1,
    'grasped': 1,
    'grasping': 2,
    'grasps': 1,
    'grass': 1,
    'grasse': 1,
    'grassed': 1,
    'grassel': 2,
    'grasser': 2,
    'grassers': 2,
    'grasses': 2,
    'grassfield': 2,
    'grassgreen': 2,
    'grasshopper': 3,
    'grasshoppers': 3,
    'grassi': 2,
    'grassia': 3,
    'grassings': 2,
    'grassl': 2,
    'grassland': 2,
    'grasslands': 2,
    'grassley': 2,
    'grasslike': 2,
    'grassman': 2,
    'grasso': 2,
    'grassroot': 2,
    'grassroots': 2,
    'grassy': 2,
    'grasty': 2,
    'grata': 2,
    'grate': 1,
    'grated': 2,
    'grateful': 2,
    'gratefully': 3,
    'grater': 2,
    'grates': 1,
    'grathwohl': 2,
    'gratification': 5,
    'gratified': 3,
    'gratifies': 3,
    'gratify': 3,
    'gratifying': 4,
    'grating': 2,
    'gratis': 2,
    'gratitude': 3,
    'grattan': 2,
    'gratton': 2,
    'gratuities': 4,
    'gratuitous': 4,
    'gratuitously': 5,
    'gratuity': 4,
    'gratz': 1,
    'gratzer': 2,
    'grau': 1,
    'grauberger': 3,
    'graue': 1,
    'grauel': 2,
    'grauer': 2,
    'graul': 1,
    'grauman': 2,
    'graumann': 2,
    'graunke': 1,
    'gravano': 3,
    'gravatt': 2,
    'grave': 1,
    'gravel': 2,
    'graveline': 2,
    'gravell': 2,
    'gravelle': 2,
    'gravelly': 3,
    'gravely': 2,
    'graven': 2,
    'graver': 2,
    'graves': 1,
    'graveside': 2,
    'gravesite': 2,
    'gravest': 2,
    'gravestone': 2,
    'gravestones': 2,
    'gravett': 2,
    'gravette': 2,
    'graveyard': 2,
    'graveyards': 2,
    'gravies': 2,
    'gravimeter': 4,
    'gravimetric': 4,
    'gravina': 3,
    'gravino': 3,
    'gravitas': 3,
    'gravitate': 3,
    'gravitated': 4,
    'gravitates': 3,
    'gravitating': 4,
    'gravitation': 4,
    'gravitational': 5,
    'gravitationally': 5,
    'gravitt': 2,
    'gravity': 3,
    'gravley': 2,
    'gravlin': 2,
    'gravois': 2,
    'gravy': 2,
    'graw': 1,
    'grawe': 1,
    'gray': 1,
    'graybeal': 2,
    'graybeard': 2,
    'graybeards': 2,
    'graybill': 2,
    'graydon': 2,
    'graye': 1,
    'grayer': 2,
    'grayest': 2,
    'graying': 2,
    'grayish': 2,
    'grayling': 2,
    'grays': 1,
    'grayson': 2,
    'graze': 1,
    'grazed': 1,
    'grazer': 2,
    'graziani': 4,
    'graziano': 4,
    'grazier': 3,
    'grazing': 2,
    'grbavica': 4,
    'greaney': 2,
    'grear': 1,
    'grease': 1,
    'greased': 1,
    'greaser': 2,
    'greasewood': 2,
    'greasing': 2,
    'greason': 2,
    'greasy': 2,
    'great': 1,
    'greater': 2,
    'greatest': 2,
    'greathouse': 2,
    'greatly': 2,
    'greatness': 2,
    'greats': 1,
    'greave': 1,
    'greaves': 1,
    'greb': 1,
    'grebe': 1,
    'greber': 2,
    'grebner': 2,
    'grecco': 2,
    'grech': 1,
    'grecian': 2,
    'grecko': 2,
    'greco': 2,
    'grede': 1,
    'gredel': 2,
    'greditor': 3,
    'greear': 2,
    'greece': 1,
    'greed': 1,
    'greedier': 3,
    'greediest': 3,
    'greedily': 3,
    'greedy': 2,
    'greek': 1,
    'greeks': 1,
    'greeley': 2,
    'greeleyville': 3,
    'greelieville': 3,
    'greely': 2,
    'green': 1,
    'greenan': 2,
    'greenawalt': 3,
    'greenaway': 3,
    'greenback': 2,
    'greenbacks': 2,
    'greenbaum': 2,
    'greenbelt': 2,
    'greenberg': 2,
    'greenberger': 3,
    'greenblatt': 2,
    'greenburg': 2,
    'greenbury': 3,
    'greenbush': 2,
    'greencastle': 3,
    'greene': 1,
    'greened': 1,
    'greener': 2,
    'greenery': 3,
    'greenest': 2,
    'greenfeld': 2,
    'greenfell': 2,
    'greenfield': 2,
    'greenhalgh': 2,
    'greenham': 2,
    'greenhaw': 2,
    'greenhill': 2,
    'greenhoe': 2,
    'greenhouse': 2,
    'greenhouses': 3,
    'greenhut': 2,
    'greeniaus': 3,
    'greenidge': 2,
    'greening': 2,
    'greenish': 2,
    'greenland': 2,
    'greenlaw': 2,
    'greenleaf': 2,
    'greenlee': 2,
    'greenlees': 2,
    'greenley': 2,
    'greenly': 2,
    'greenmail': 2,
    'greenmailer': 3,
    'greenman': 2,
    'greenness': 2,
    'greeno': 2,
    'greenough': 2,
    'greenpeace': 2,
    'greens': 1,
    'greensboro': 3,
    'greensburg': 2,
    'greenshield': 2,
    'greenshields': 2,
    'greenslade': 2,
    'greenslet': 2,
    'greenspan': 2,
    'greenspon': 2,
    'greenspun': 2,
    'greenstein': 2,
    'greenstone': 2,
    'greenstreet': 2,
    'greenup': 2,
    'greenvale': 2,
    'greenville': 2,
    'greenwald': 2,
    'greenwalds': 2,
    'greenwalt': 2,
    'greenway': 2,
    'greenwell': 2,
    'greenwich': 2,
    'greenwood': 2,
    'greer': 1,
    'greeson': 2,
    'greet': 1,
    'greeted': 2,
    'greetham': 2,
    'greeting': 2,
    'greetings': 2,
    'greets': 1,
    'greever': 2,
    'grefe': 1,
    'greff': 1,
    'greg': 1,
    'grega': 2,
    'gregarious': 4,
    'greger': 2,
    'gregersen': 3,
    'gregerson': 3,
    'gregg': 1,
    'greggs': 1,
    'grego': 2,
    'gregoire': 2,
    'gregor': 2,
    'gregori': 3,
    'gregoria': 4,
    'gregorian': 4,
    'gregorich': 3,
    'gregorie': 3,
    'gregorio': 4,
    'gregory': 3,
    'gregson': 2,
    'gregston': 2,
    'gregus': 2,
    'greider': 2,
    'greif': 1,
    'greiff': 1,
    'greig': 1,
    'greim': 1,
    'greiman': 2,
    'grein': 1,
    'greiner': 2,
    'greinke': 1,
    'greis': 1,
    'greitz': 1,
    'greiwe': 1,
    'grell': 1,
    'grella': 2,
    'grelle': 1,
    'gremban': 2,
    'gremillion': 3,
    'gremlin': 2,
    'gremlins': 2,
    'gren': 1,
    'grenada': 3,
    'grenade': 2,
    'grenades': 2,
    'grenda': 2,
    'grendel': 2,
    'grenell': 2,
    'grenfell': 2,
    'grenier': 3,
    'greninger': 3,
    'grennan': 2,
    'grenoble': 3,
    'grenon': 2,
    'grenouille': 3,
    'grenz': 1,
    'gresh': 1,
    'gresham': 2,
    'gresko': 2,
    'gress': 1,
    'gresser': 2,
    'gressett': 2,
    'gressley': 2,
    'gressman': 2,
    'greta': 2,
    'gretal': 2,
    'gretchen': 2,
    'grete': 1,
    'gretel': 2,
    'greth': 1,
    'grethel': 2,
    'grether': 2,
    'gretna': 2,
    'grettenberger': 4,
    'gretz': 1,
    'gretzinger': 3,
    'gretzky': 2,
    'greubel': 2,
    'greuel': 2,
    'greulich': 2,
    'greunke': 1,
    'greve': 1,
    'grever': 2,
    'grew': 1,
    'grewal': 2,
    'grewe': 1,
    'grewell': 2,
    'grey': 1,
    'greyhound': 2,
    'greying': 2,
    'greylag': 2,
    'greystone': 2,
    'gribben': 2,
    'gribbin': 2,
    'gribbins': 2,
    'gribble': 2,
    'gribbles': 2,
    'gribbon': 2,
    'grice': 1,
    'grid': 1,
    'grider': 2,
    'gridiron': 3,
    'gridley': 2,
    'gridlock': 2,
    'gridlocked': 2,
    'gridlocks': 2,
    'grids': 1,
    'grieb': 1,
    'griebel': 2,
    'grieco': 2,
    'grieder': 2,
    'grief': 1,
    'grieger': 2,
    'griego': 2,
    'grieme': 1,
    'griep': 1,
    'griepentrog': 3,
    'grier': 2,
    'grierson': 2,
    'gries': 1,
    'griesa': 3,
    'griesbach': 2,
    'griese': 1,
    'griesemer': 3,
    'grieser': 2,
    'grieshaber': 3,
    'griesinger': 3,
    'griess': 1,
    'griesser': 2,
    'griest': 2,
    'grievance': 2,
    'grievances': 3,
    'grieve': 1,
    'grieved': 1,
    'griever': 2,
    'grievers': 2,
    'grieves': 1,
    'grieveson': 3,
    'grieving': 2,
    'grievous': 2,
    'grievously': 3,
    'griff': 1,
    'griffee': 2,
    'griffen': 2,
    'griffeth': 2,
    'griffey': 2,
    'griffie': 2,
    'griffin': 2,
    'griffing': 2,
    'griffis': 2,
    'griffith': 2,
    'griffiths': 2,
    'griffitts': 2,
    'griffo': 2,
    'griffon': 2,
    'griffy': 2,
    'grigas': 2,
    'grigg': 1,
    'griggs': 1,
    'griggy': 2,
    'grignon': 2,
    'grigoli': 3,
    'grigorovich': 4,
    'grigory': 3,
    'grigoryant': 3,
    'grigoryants': 3,
    'grigsby': 2,
    'grijalva': 3,
    'grill': 1,
    'grille': 1,
    'grilled': 1,
    'grilli': 2,
    'grilling': 2,
    'grilliot': 3,
    'grillo': 2,
    'grillot': 2,
    'grills': 1,
    'grim': 1,
    'grima': 2,
    'grimace': 2,
    'grimaced': 2,
    'grimaces': 3,
    'grimacing': 3,
    'grimaldi': 3,
    'grimaldo': 3,
    'grime': 1,
    'grimes': 1,
    'grimley': 2,
    'grimly': 2,
    'grimm': 1,
    'grimme': 1,
    'grimmer': 2,
    'grimmest': 2,
    'grimmett': 2,
    'grimness': 2,
    'grimshaw': 2,
    'grimsley': 2,
    'grimstad': 2,
    'grimwood': 2,
    'grimy': 2,
    'grin': 1,
    'grinage': 2,
    'grinald': 2,
    'grinalds': 2,
    'grinberg': 2,
    'grinch': 1,
    'grind': 1,
    'grinde': 1,
    'grinder': 2,
    'grinders': 2,
    'grinding': 2,
    'grindlay': 2,
    'grindlays': 2,
    'grindle': 2,
    'grindley': 2,
    'grindrod': 2,
    'grinds': 1,
    'grindstaff': 2,
    'grindstone': 2,
    'grine': 1,
    'griner': 2,
    'gring': 1,
    'gringo': 2,
    'gringos': 2,
    'grinned': 1,
    'grinnell': 2,
    'grinning': 2,
    'grins': 1,
    'grinstead': 2,
    'grinstein': 2,
    'grip': 1,
    'gripe': 1,
    'griped': 1,
    'gripes': 1,
    'griping': 2,
    'gripp': 1,
    'gripped': 1,
    'grippi': 2,
    'gripping': 2,
    'grippo': 2,
    'grips': 1,
    'grisanti': 3,
    'grisby': 2,
    'griscom': 2,
    'grise': 1,
    'grisham': 2,
    'grishilda': 3,
    'grishman': 2,
    'grisly': 2,
    'grismer': 4,
    'grismore': 2,
    'grissett': 2,
    'grissinger': 3,
    'grisso': 2,
    'grissom': 2,
    'grisson': 2,
    'grist': 1,
    'gristle': 2,
    'griswell': 2,
    'griswold': 2,
    'grit': 1,
    'grites': 1,
    'grits': 1,
    'gritter': 2,
    'gritting': 2,
    'gritton': 2,
    'gritty': 2,
    'gritz': 1,
    'gritzmacher': 3,
    'grivas': 2,
    'griz': 1,
    'grizelda': 3,
    'grizzard': 2,
    'grizzell': 2,
    'grizzle': 2,
    'grizzled': 2,
    'grizzlies': 2,
    'grizzly': 2,
    'gro': 1,
    'groan': 1,
    'groaned': 1,
    'groaning': 2,
    'groans': 1,
    'groat': 1,
    'grob': 1,
    'grobe': 1,
    'groben': 2,
    'grober': 2,
    'grobian': 3,
    'grobler': 2,
    'groce': 1,
    'grocer': 2,
    'groceries': 3,
    'grocers': 2,
    'grocery': 3,
    'groch': 1,
    'grochowski': 3,
    'grode': 1,
    'groden': 2,
    'grodin': 2,
    'grodsky': 2,
    'groebner': 2,
    'groeger': 2,
    'groen': 1,
    'groene': 2,
    'groeneveld': 3,
    'groenewold': 3,
    'groening': 3,
    'groep': 1,
    'groer': 2,
    'groesbeck': 2,
    'groff': 1,
    'groft': 1,
    'grog': 1,
    'grogan': 2,
    'grogg': 1,
    'groggy': 2,
    'groh': 1,
    'grohman': 2,
    'grohs': 1,
    'groin': 1,
    'groined': 1,
    'groins': 1,
    'groleau': 2,
    'grolier': 2,
    'groll': 1,
    'grom': 1,
    'groma': 2,
    'groman': 2,
    'gromek': 2,
    'gromer': 2,
    'gromes': 1,
    'gromyko': 3,
    'gronau': 2,
    'gronberg': 2,
    'grondahl': 2,
    'grondin': 2,
    'grone': 1,
    'gronemeyer': 4,
    'groner': 2,
    'gronewold': 3,
    'groninger': 3,
    'gronlund': 2,
    'gronowski': 3,
    'gronseth': 2,
    'gronski': 2,
    'groom': 1,
    'groome': 1,
    'groomed': 1,
    'groomer': 2,
    'groomers': 2,
    'groomes': 1,
    'grooming': 2,
    'grooms': 1,
    'groopman': 2,
    'groos': 1,
    'groot': 1,
    'grooters': 2,
    'groove': 1,
    'groover': 2,
    'grooves': 1,
    'grooviest': 3,
    'groovy': 2,
    'grope': 1,
    'groped': 1,
    'groping': 2,
    'gropp': 1,
    'gropper': 2,
    'groppy': 2,
    'gros': 1,
    'grosbeak': 2,
    'grosbeaks': 2,
    'grosch': 1,
    'grose': 1,
    'groseclose': 3,
    'grosh': 1,
    'groshek': 2,
    'groshong': 2,
    'grosjean': 2,
    'groskopf': 2,
    'gross': 1,
    'grossbard': 2,
    'grossberg': 2,
    'grosse': 1,
    'grossed': 1,
    'grossenbacher': 4,
    'grosser': 2,
    'grosses': 2,
    'grossfeld': 2,
    'grosshans': 2,
    'grossi': 2,
    'grossing': 2,
    'grosskopf': 2,
    'grossly': 2,
    'grossman': 2,
    'grossmann': 2,
    'grossnickle': 3,
    'grosso': 2,
    'grosvenor': 2,
    'grosz': 1,
    'grote': 1,
    'grotesque': 2,
    'grotesquely': 3,
    'groth': 1,
    'grothaus': 2,
    'grothe': 1,
    'grotheer': 2,
    'groton': 2,
    'grotto': 2,
    'grotz': 1,
    'grouch': 1,
    'groucho': 2,
    'grouchy': 2,
    'groulx': 1,
    'ground': 1,
    'groundbreaking': 3,
    'grounded': 2,
    'grounder': 2,
    'groundhog': 2,
    'grounding': 2,
    'groundless': 2,
    'groundling': 2,
    'groundnut': 2,
    'groundnuts': 2,
    'grounds': 1,
    'groundskeeper': 3,
    'groundskeepers': 3,
    'groundswell': 2,
    'groundwater': 3,
    'groundwork': 2,
    'group': 1,
    'groupe': 1,
    'grouped': 1,
    'groupement': 2,
    'grouper': 2,
    'groupers': 2,
    'groupie': 2,
    'groupies': 2,
    'grouping': 2,
    'groupings': 2,
    'groups': 1,
    'groupware': 2,
    'grouse': 1,
    'groused': 1,
    'grouses': 2,
    'grousing': 2,
    'groussman': 2,
    'grout': 1,
    'grouting': 2,
    'grove': 1,
    'grovel': 2,
    'groveling': 3,
    'groveman': 2,
    'grover': 2,
    'grovers': 2,
    'groves': 1,
    'grow': 1,
    'growe': 1,
    'grower': 2,
    'growers': 2,
    'growing': 2,
    'growl': 1,
    'growled': 1,
    'growling': 2,
    'growls': 1,
    'grown': 1,
    'grownup': 2,
    'grownups': 2,
    'grows': 1,
    'growth': 1,
    'growths': 1,
    'grozny': 2,
    'grua': 2,
    'grub': 1,
    'gruba': 2,
    'grubaugh': 2,
    'grubb': 1,
    'grubbs': 1,
    'grubby': 2,
    'grube': 1,
    'gruben': 2,
    'gruber': 2,
    'gruberova': 4,
    'grubman': 2,
    'grubs': 1,
    'grubstein': 2,
    'grucci': 2,
    'grudenstein': 3,
    'grudge': 1,
    'grudges': 2,
    'grudging': 2,
    'grudgingly': 3,
    'grudzien': 2,
    'grudzinski': 3,
    'gruel': 2,
    'grueling': 3,
    'gruen': 1,
    'gruenberg': 2,
    'gruener': 2,
    'gruenewald': 3,
    'gruenhagen': 3,
    'gruenwald': 2,
    'gruesome': 2,
    'gruet': 2,
    'gruetzmacher': 3,
    'gruff': 1,
    'gruffly': 2,
    'gruhlke': 1,
    'gruhn': 1,
    'gruis': 2,
    'grulke': 1,
    'grullon': 2,
    'grum': 1,
    'grumbine': 2,
    'grumble': 2,
    'grumbled': 2,
    'grumbles': 2,
    'grumbling': 3,
    'grumblings': 3,
    'grumman': 2,
    'grummond': 2,
    'grump': 1,
    'grumpier': 3,
    'grumpy': 2,
    'grun': 1,
    'grunberg': 2,
    'grund': 1,
    'grunden': 2,
    'grunder': 2,
    'grundfest': 2,
    'grundhofer': 3,
    'grundig': 2,
    'grundman': 2,
    'grundstrom': 2,
    'grundy': 2,
    'grunebaum': 2,
    'gruneich': 2,
    'gruner': 2,
    'grunert': 2,
    'grunewald': 3,
    'grunge': 1,
    'grungy': 2,
    'grunion': 2,
    'grunow': 2,
    'grunt': 1,
    'gruntal': 2,
    'grunted': 2,
    'grunting': 2,
    'grunts': 1,
    'grunwald': 2,
    'grupe': 1,
    'grupo': 2,
    'grupp': 1,
    'gruppo': 2,
    'grush': 1,
    'grushow': 2,
    'gruss': 1,
    'grussing': 2,
    'gruszka': 2,
    'grutman': 2,
    'gruver': 2,
    'gruwell': 2,
    'gruza': 2,
    'gryder': 2,
    'grygiel': 2,
    'gryphons': 2,
    'grzelak': 3,
    'grzesiak': 4,
    'grzeskowiak': 5,
    'grzyb': 2,
    'grzybowski': 4,
    'grzywacz': 3,
    'grzywinski': 4,
    'gschwind': 2,
    'gsell': 2,
    'gtech': 2,
    'gu': 1,
    'guacamole': 4,
    'guadagno': 3,
    'guadalajara': 5,
    'guadalcanal': 4,
    'guadalupe': 3,
    'guadarrama': 5,
    'guadeloupe': 3,
    'guagliardo': 4,
    'guajardo': 3,
    'gualdoni': 3,
    'gualtieri': 3,
    'guam': 1,
    'guanaco': 3,
    'guandjo': 2,
    'guandjong': 2,
    'guandong': 2,
    'guangdong': 2,
    'guangjo': 2,
    'guangzhou': 2,
    'guanine': 2,
    'guano': 2,
    'guantanamo': 4,
    'guarani': 3,
    'guarantee': 3,
    'guaranteed': 3,
    'guaranteeing': 4,
    'guarantees': 3,
    'guaranties': 3,
    'guarantor': 3,
    'guarantors': 3,
    'guaranty': 3,
    'guard': 1,
    'guardado': 3,
    'guarded': 2,
    'guardedly': 3,
    'guardfish': 2,
    'guardia': 3,
    'guardian': 3,
    'guardians': 3,
    'guardianship': 4,
    'guardin': 2,
    'guarding': 2,
    'guardino': 3,
    'guardiola': 4,
    'guardrail': 2,
    'guardrails': 2,
    'guards': 1,
    'guardsman': 2,
    'guardsmen': 2,
    'guariglia': 4,
    'guarin': 2,
    'guarini': 3,
    'guarino': 3,
    'guarisco': 3,
    'guarnaccia': 4,
    'guarneri': 3,
    'guarnieri': 3,
    'guasch': 1,
    'guastella': 3,
    'guatemala': 4,
    'guatemalan': 4,
    'guatemalans': 4,
    'guattery': 3,
    'guava': 2,
    'guavas': 2,
    'guay': 1,
    'guayabera': 4,
    'guba': 2,
    'gubbels': 2,
    'gubbins': 2,
    'guber': 2,
    'guberman': 3,
    'gubernatorial': 6,
    'gubler': 3,
    'gubser': 2,
    'gucci': 2,
    'gucciardo': 3,
    'guccio': 3,
    'guccione': 3,
    'guck': 1,
    'guckert': 2,
    'gucwa': 2,
    'gude': 1,
    'gudelay': 3,
    'guderian': 4,
    'gudgel': 2,
    'gudgeon': 2,
    'gudger': 2,
    'gudino': 3,
    'gudmundson': 3,
    'gue': 1,
    'guedry': 2,
    'guel': 1,
    'guenette': 2,
    'guenna': 2,
    'guenter': 2,
    'guenther': 2,
    'guenthner': 2,
    'guerard': 2,
    'guercio': 3,
    'guerette': 2,
    'guerilla': 3,
    'guerillas': 3,
    'guerin': 2,
    'guerino': 3,
    'guerneville': 3,
    'guernsey': 2,
    'guernseys': 2,
    'guerra': 2,
    'guerrant': 2,
    'guerre': 1,
    'guerrera': 3,
    'guerrero': 3,
    'guerrette': 2,
    'guerrier': 3,
    'guerrieri': 3,
    'guerriero': 3,
    'guerrilla': 3,
    'guerrillas': 3,
    'guerrini': 3,
    'guerry': 2,
    'guertin': 2,
    'guess': 1,
    'guessed': 1,
    'guesser': 2,
    'guessers': 2,
    'guesses': 2,
    'guessing': 2,
    'guesstimate': 3,
    'guesstimates': 3,
    'guesswork': 2,
    'guest': 1,
    'guested': 2,
    'guesthouse': 2,
    'guesthouses': 3,
    'guests': 1,
    'guettler': 3,
    'guevara': 3,
    'guez': 1,
    'guff': 1,
    'guffaw': 2,
    'guffaws': 2,
    'guffey': 2,
    'guffin': 2,
    'guffy': 2,
    'gugel': 2,
    'guggenheim': 3,
    'guggisberg': 3,
    'gugino': 3,
    'guglielmetti': 5,
    'guglielmi': 4,
    'guglielmo': 4,
    'gugliotta': 4,
    'gugliotti': 4,
    'gugliuzza': 4,
    'guhl': 1,
    'guiana': 3,
    'guice': 1,
    'guichard': 2,
    'guida': 2,
    'guidance': 2,
    'guide': 1,
    'guidebook': 2,
    'guidebooks': 2,
    'guided': 2,
    'guideline': 2,
    'guidelines': 2,
    'guidepost': 2,
    'guideposts': 2,
    'guider': 2,
    'guidera': 3,
    'guides': 1,
    'guidi': 2,
    'guidice': 2,
    'guiding': 2,
    'guido': 2,
    'guidone': 3,
    'guidotti': 3,
    'guidroz': 2,
    'guidry': 3,
    'guier': 2,
    'guiffre': 2,
    'guilbault': 2,
    'guilbeau': 2,
    'guilbeault': 2,
    'guilbeaux': 2,
    'guilbert': 2,
    'guild': 1,
    'guilder': 2,
    'guilders': 2,
    'guildhall': 2,
    'guilds': 1,
    'guile': 1,
    'guileless': 2,
    'guiles': 1,
    'guilfoil': 2,
    'guilford': 2,
    'guilfoyle': 2,
    'guiliani': 4,
    'guiliano': 4,
    'guilin': 2,
    'guill': 1,
    'guilla': 2,
    'guillaume': 2,
    'guillemette': 3,
    'guillen': 2,
    'guillermo': 3,
    'guillet': 2,
    'guillette': 2,
    'guilliams': 3,
    'guillory': 3,
    'guillot': 2,
    'guillotine': 3,
    'guillotte': 2,
    'guilmartin': 3,
    'guilmette': 2,
    'guilt': 1,
    'guiltless': 2,
    'guilty': 2,
    'guimaraes': 3,
    'guimond': 2,
    'guimont': 2,
    'guin': 1,
    'guinan': 2,
    'guinarou': 3,
    'guindon': 2,
    'guinea': 2,
    'guineas': 2,
    'guiness': 2,
    'guiney': 2,
    'guinier': 2,
    'guinn': 1,
    'guinness': 2,
    'guinta': 2,
    'guinther': 2,
    'guinto': 2,
    'guinyard': 2,
    'guion': 2,
    'guire': 1,
    'guise': 1,
    'guises': 2,
    'guisewite': 2,
    'guisinger': 3,
    'guitar': 2,
    'guitarist': 3,
    'guitarists': 3,
    'guitarro': 3,
    'guitars': 2,
    'guiterrez': 3,
    'guitierrez': 3,
    'guizar': 2,
    'gujarat': 3,
    'gul': 1,
    'gula': 2,
    'gulag': 2,
    'gulas': 2,
    'gulbrandsen': 3,
    'gulbrandson': 3,
    'gulbransen': 3,
    'gulbranson': 3,
    'gulbuddin': 3,
    'gulch': 1,
    'guldin': 2,
    'gulf': 1,
    'gulfport': 2,
    'gulfs': 1,
    'gulfstream': 2,
    'gulick': 2,
    'gulino': 3,
    'gull': 1,
    'gulla': 2,
    'gullah': 2,
    'gullatt': 2,
    'gulledge': 2,
    'gullet': 2,
    'gullett': 2,
    'gullette': 2,
    'gulley': 2,
    'gulli': 2,
    'gullibility': 5,
    'gullible': 3,
    'gullick': 2,
    'gullickson': 3,
    'gullies': 2,
    'gulliford': 3,
    'gullikson': 3,
    'gullion': 2,
    'gulliver': 3,
    'gullo': 2,
    'gulls': 1,
    'gully': 2,
    'gulp': 1,
    'gulped': 1,
    'gulping': 2,
    'gulps': 1,
    'gulyas': 2,
    'gum': 1,
    'gumaer': 3,
    'gumbel': 2,
    'gumbert': 2,
    'gumbi': 2,
    'gumbiner': 3,
    'gumbinger': 3,
    'gumbo': 2,
    'gumbs': 1,
    'gumi': 2,
    'gumina': 3,
    'gumm': 1,
    'gummed': 1,
    'gummi': 2,
    'gummy': 2,
    'gump': 1,
    'gumpert': 2,
    'gumport': 2,
    'gumpp': 1,
    'gumption': 2,
    'gums': 1,
    'gumshoe': 2,
    'gumucio': 4,
    'gumz': 1,
    'gun': 1,
    'gunadi': 3,
    'gunatilake': 5,
    'gunboat': 2,
    'gunboats': 2,
    'gunby': 2,
    'guncotton': 3,
    'gundel': 2,
    'gunder': 2,
    'gunderman': 3,
    'gundersen': 3,
    'gunderson': 3,
    'gundlach': 2,
    'gundle': 2,
    'gundog': 2,
    'gundrum': 2,
    'gundry': 3,
    'gundy': 2,
    'gunfight': 2,
    'gunfighter': 3,
    'gunfighters': 3,
    'gunfighting': 3,
    'gunfights': 2,
    'gunfire': 3,
    'gunflint': 2,
    'gung': 1,
    'gunhilda': 3,
    'gunia': 3,
    'gunkel': 2,
    'gunlick': 2,
    'gunlicks': 2,
    'gunman': 2,
    'gunmen': 2,
    'gunn': 1,
    'gunnar': 2,
    'gunnarson': 3,
    'gunned': 1,
    'gunnell': 2,
    'gunnells': 2,
    'gunnels': 2,
    'gunner': 2,
    'gunners': 2,
    'gunnerson': 3,
    'gunnery': 3,
    'gunning': 2,
    'gunnison': 3,
    'gunnoe': 2,
    'gunny': 2,
    'gunnysack': 3,
    'gunnysacks': 3,
    'gunpoint': 2,
    'gunpowder': 3,
    'gunrunner': 3,
    'guns': 1,
    'gunsalus': 3,
    'gunship': 2,
    'gunships': 2,
    'gunshot': 2,
    'gunshots': 2,
    'gunslinger': 3,
    'gunslingers': 3,
    'gunsmoke': 2,
    'gunst': 1,
    'gunter': 2,
    'gunther': 2,
    'guntur': 2,
    'gunty': 2,
    'guppies': 2,
    'guppy': 2,
    'gupta': 2,
    'guptill': 2,
    'gupton': 2,
    'gura': 2,
    'gural': 2,
    'gurevich': 3,
    'gurganious': 4,
    'gurganus': 3,
    'gurgle': 2,
    'gurgling': 3,
    'gurian': 3,
    'gurion': 3,
    'gurit': 2,
    'gurka': 2,
    'gurkin': 2,
    'gurley': 2,
    'gurnards': 2,
    'gurnee': 2,
    'gurney': 2,
    'gurnsey': 2,
    'gurr': 1,
    'gurria': 3,
    'gurrola': 3,
    'gurry': 2,
    'gurski': 2,
    'gursky': 2,
    'gurtler': 3,
    'gurtner': 2,
    'gurtz': 1,
    'guru': 2,
    'gurule': 2,
    'gurus': 2,
    'gus': 1,
    'gusciora': 4,
    'guse': 1,
    'gusella': 3,
    'guseman': 2,
    'guses': 2,
    'gush': 1,
    'gushed': 1,
    'gusher': 2,
    'gushers': 2,
    'gushes': 2,
    'gushing': 2,
    'gusinsky': 3,
    'gusky': 2,
    'guslan': 2,
    'gusler': 3,
    'gusman': 2,
    'guss': 1,
    'gussie': 2,
    'gussied': 2,
    'gussman': 2,
    'gussy': 2,
    'gust': 1,
    'gustaf': 2,
    'gustafson': 3,
    'gustafsson': 3,
    'gustav': 2,
    'gustava': 3,
    'gustave': 3,
    'gustaveson': 4,
    'gustavo': 3,
    'gustavson': 3,
    'gustavus': 3,
    'guste': 1,
    'gusted': 2,
    'guster': 2,
    'gustin': 2,
    'gusting': 2,
    'gusto': 2,
    'guston': 2,
    'gusts': 1,
    'gustus': 2,
    'gusty': 2,
    'gut': 1,
    'gutekunst': 3,
    'gutenberg': 3,
    'guterman': 3,
    'gutermuth': 3,
    'gutfeld': 2,
    'gutfreund': 2,
    'guth': 1,
    'gutherie': 3,
    'guthery': 3,
    'guthmiller': 3,
    'guthridge': 2,
    'guthrie': 2,
    'gutierez': 3,
    'gutierres': 3,
    'gutierrez': 3,
    'gutknecht': 2,
    'gutkowski': 3,
    'gutless': 2,
    'gutman': 2,
    'gutmann': 2,
    'gutowski': 3,
    'gutridge': 2,
    'guts': 1,
    'gutshall': 2,
    'gutsy': 2,
    'gutt': 1,
    'gutted': 2,
    'guttenberg': 3,
    'gutter': 2,
    'guttered': 2,
    'guttering': 3,
    'gutterman': 3,
    'gutters': 2,
    'gutting': 2,
    'guttmacher': 3,
    'guttman': 2,
    'guttmann': 2,
    'guttural': 3,
    'gutwaks': 2,
    'gutwein': 2,
    'gutzman': 2,
    'gutzmer': 2,
    'gutzwiller': 3,
    'guy': 1,
    'guyana': 3,
    'guyer': 2,
    'guyett': 2,
    'guyette': 2,
    'guymon': 2,
    'guynes': 1,
    'guynn': 1,
    'guyon': 2,
    'guyot': 2,
    'guys': 1,
    'guyton': 2,
    'guza': 2,
    'guzek': 2,
    'guzik': 2,
    'guzman': 2,
    'guzowski': 3,
    'guzy': 2,
    'guzzardo': 3,
    'guzzetta': 3,
    'guzzetti': 3,
    'guzzi': 2,
    'guzzle': 2,
    'guzzler': 2,
    'guzzlers': 3,
    'guzzles': 2,
    'guzzling': 3,
    'guzzo': 2,
    'gvaryahu': 4,
    'gwaltney': 2,
    'gwartney': 2,
    'gway': 1,
    'gwen': 1,
    'gwenda': 2,
    'gwendolyn': 3,
    'gwennie': 2,
    'gwenore': 2,
    'gwin': 1,
    'gwinn': 1,
    'gwinner': 2,
    'gwinnett': 2,
    'gwizdala': 3,
    'gwozdz': 1,
    'gwyn': 1,
    'gwyneth': 2,
    'gwyneths': 2,
    'gwynn': 1,
    'gwynne': 1,
    'gyger': 2,
    'gyi': 1,
    'gyles': 1,
    'gyllenhammar': 4,
    'gym': 1,
    'gymboree': 3,
    'gymnasia': 4,
    'gymnasium': 4,
    'gymnasiums': 4,
    'gymnast': 2,
    'gymnastic': 3,
    'gymnastics': 3,
    'gymnasts': 2,
    'gyms': 1,
    'gyn': 1,
    'gynecologic': 5,
    'gynecological': 6,
    'gynecologist': 5,
    'gynecologists': 5,
    'gynecology': 5,
    'gynex': 2,
    'gyohten': 2,
    'gyosai': 2,
    'gyp': 1,
    'gypped': 1,
    'gypsies': 2,
    'gypsum': 2,
    'gypsy': 2,
    'gyr': 1,
    'gyrate': 2,
    'gyrated': 3,
    'gyrates': 2,
    'gyrating': 3,
    'gyration': 3,
    'gyrations': 3,
    'gyro': 2,
    'gyrocompass': 4,
    'gyrodyne': 3,
    'gyrodynes': 3,
    'gyropilot': 4,
    'gyros': 2,
    'gyroscope': 3,
    'gyroscopes': 3,
    'gyroscopic': 4,
    'gytha': 2,
    'gyuhama': 3,
    'h': 1,
    'ha': 1,
    'haab': 1,
    'haack': 1,
    'haacke': 1,
    'haaf': 1,
    'haag': 1,
    'haagen': 2,
    'haagenson': 3,
    'haak': 1,
    'haake': 1,
    'haakenson': 3,
    'haaland': 2,
    'haan': 1,
    'haapala': 3,
    'haar': 1,
    'haas': 1,
    'haase': 1,
    'haass': 1,
    'haavelmo': 3,
    'habben': 2,
    'habeas': 3,
    'habeck': 2,
    'habecker': 3,
    'habeeb': 2,
    'habegger': 3,
    'habel': 2,
    'habenicht': 3,
    'haber': 2,
    'haberdashery': 5,
    'haberer': 3,
    'haberkorn': 3,
    'haberl': 2,
    'haberland': 3,
    'haberle': 3,
    'haberman': 3,
    'habermann': 3,
    'habermehl': 3,
    'haberson': 3,
    'haberstroh': 3,
    'habib': 2,
    'habibi': 3,
    'habibie': 3,
    'habich': 2,
    'habicht': 2,
    'habig': 2,
    'habiger': 3,
    'habit': 2,
    'habitable': 4,
    'habitat': 3,
    'habitation': 4,
    'habitats': 3,
    'habits': 2,
    'habitual': 4,
    'habitually': 5,
    'habitues': 3,
    'hable': 2,
    'habs': 1,
    'habsburg': 2,
    'haby': 2,
    'habyarimana': 6,
    'hach': 1,
    'hachette': 2,
    'hachey': 2,
    'hachtel': 2,
    'hacienda': 4,
    'hack': 1,
    'hackathorn': 3,
    'hackbart': 2,
    'hackbarth': 2,
    'hackberry': 3,
    'hacke': 1,
    'hacked': 1,
    'hackel': 2,
    'hackenberg': 3,
    'hackensack': 3,
    'hacker': 2,
    'hackers': 2,
    'hackert': 2,
    'hackett': 2,
    'hackford': 2,
    'hacking': 2,
    'hackl': 2,
    'hackle': 2,
    'hackleman': 3,
    'hackler': 3,
    'hackles': 2,
    'hackley': 2,
    'hackman': 2,
    'hackmann': 2,
    'hackmatack': 3,
    'hackney': 2,
    'hackneyed': 2,
    'hacks': 1,
    'hacksaw': 2,
    'hackstaff': 2,
    'hackworth': 2,
    'had': 1,
    'hada': 2,
    'hadad': 2,
    'hadaway': 3,
    'hadd': 1,
    'haddad': 2,
    'haddan': 2,
    'haddaway': 3,
    'hadden': 2,
    'haddock': 2,
    'haddon': 2,
    'haddonfield': 3,
    'haddow': 2,
    'hade': 1,
    'haden': 2,
    'hader': 2,
    'hades': 2,
    'hadfield': 2,
    'hadid': 2,
    'hadleigh': 2,
    'hadler': 3,
    'hadley': 2,
    'hadline': 2,
    'hadlock': 2,
    'hadnot': 2,
    'hadoya': 3,
    'hadria': 3,
    'hadrian': 3,
    'hadsall': 2,
    'hadsell': 2,
    'hadson': 2,
    'hadwin': 2,
    'haeberle': 3,
    'haecker': 2,
    'haefele': 2,
    'haeffner': 2,
    'haefner': 2,
    'haegele': 2,
    'haeger': 2,
    'haen': 1,
    'haenel': 2,
    'haering': 3,
    'haertel': 2,
    'haese': 1,
    'haessly': 2,
    'haeussler': 2,
    'hafele': 2,
    'hafeman': 2,
    'hafen': 2,
    'hafer': 2,
    'hafey': 2,
    'hafez': 2,
    'haff': 1,
    'haffey': 2,
    'haffez': 2,
    'haffner': 2,
    'hafford': 2,
    'hafif': 2,
    'hafley': 2,
    'hafner': 2,
    'hafnia': 3,
    'hafnium': 3,
    'haft': 1,
    'hafta': 2,
    'hafts': 1,
    'hag': 1,
    'haga': 2,
    'hagadorn': 3,
    'hagaman': 3,
    'hagan': 2,
    'hagans': 2,
    'hagar': 2,
    'hagarty': 3,
    'hagberg': 2,
    'hage': 1,
    'hagedorn': 3,
    'hagee': 2,
    'hagel': 2,
    'hagelin': 3,
    'hageman': 2,
    'hagemann': 2,
    'hagemeier': 4,
    'hagemeister': 4,
    'hagemeyer': 4,
    'hagen': 2,
    'hagenbuch': 3,
    'hagenlocker': 4,
    'hagenow': 3,
    'hagens': 2,
    'hager': 2,
    'hagerman': 3,
    'hagerstown': 3,
    'hagerty': 3,
    'hagewood': 2,
    'hagey': 2,
    'hagfish': 2,
    'hagg': 1,
    'haggadah': 3,
    'haggan': 2,
    'haggar': 2,
    'haggard': 2,
    'haggart': 2,
    'haggarty': 3,
    'hagge': 1,
    'haggerty': 3,
    'haggett': 2,
    'haggins': 2,
    'haggle': 2,
    'haggled': 2,
    'haggling': 3,
    'hagglund': 2,
    'haggstrom': 2,
    'hagin': 2,
    'hagins': 2,
    'hagiography': 5,
    'hagiwara': 4,
    'hagle': 2,
    'hagler': 2,
    'hagley': 2,
    'haglund': 2,
    'hagman': 2,
    'hagmann': 2,
    'hagner': 2,
    'hagood': 2,
    'hagopian': 4,
    'hagstrom': 2,
    'hague': 1,
    'hagwood': 2,
    'hagy': 2,
    'hah': 1,
    'hahl': 1,
    'hahm': 1,
    'hahn': 1,
    'hahne': 1,
    'hahner': 2,
    'hahs': 1,
    'haid': 1,
    'haida': 2,
    'haidee': 2,
    'haider': 2,
    'haidet': 2,
    'haifa': 2,
    'haifong': 2,
    'haig': 1,
    'haigh': 1,
    'haight': 1,
    'haigler': 2,
    'haik': 1,
    'haiku': 2,
    'haikus': 2,
    'hail': 1,
    'hailand': 2,
    'haile': 1,
    'hailed': 1,
    'hailes': 1,
    'hailey': 2,
    'hailing': 2,
    'hails': 1,
    'hailstone': 2,
    'hailstones': 2,
    'hailstorm': 2,
    'haim': 1,
    'haimes': 1,
    'haimovitch': 3,
    'haimovitz': 3,
    'haimowitz': 3,
    'hain': 1,
    'hainan': 2,
    'hainer': 2,
    'haines': 1,
    'hainey': 2,
    'hainley': 2,
    'hainline': 2,
    'hains': 1,
    'hainsworth': 2,
    'hair': 1,
    'haircut': 2,
    'haircuts': 2,
    'hairdo': 2,
    'hairdos': 2,
    'hairdresser': 3,
    'hairdressers': 3,
    'hairdressing': 3,
    'haire': 1,
    'haired': 1,
    'hairfield': 2,
    'hairgrove': 2,
    'hairiness': 3,
    'hairless': 2,
    'hairline': 2,
    'hairr': 1,
    'hairs': 1,
    'hairspray': 2,
    'hairston': 2,
    'hairstyle': 2,
    'hairy': 2,
    'haisley': 2,
    'haislip': 2,
    'haist': 2,
    'hait': 1,
    'haith': 1,
    'haithcock': 2,
    'haiti': 2,
    'haitian': 2,
    'haitians': 2,
    'haitien': 2,
    'haitiens': 2,
    'haitis': 2,
    'haizlip': 2,
    'hajdu': 2,
    'hajduk': 2,
    'hajek': 2,
    'hajime': 3,
    'hajj': 1,
    'hajjar': 2,
    'hakala': 3,
    'hakan': 2,
    'hakanson': 3,
    'hake': 1,
    'hakeem': 2,
    'hakes': 1,
    'hakim': 2,
    'hakki': 2,
    'hakko': 2,
    'hakon': 2,
    'hakuhodo': 4,
    'hal': 1,
    'halama': 3,
    'halamandaris': 5,
    'halas': 2,
    'halasz': 2,
    'halbach': 2,
    'halberg': 2,
    'halberstadt': 3,
    'halberstam': 3,
    'halbert': 2,
    'halbig': 2,
    'halbrook': 2,
    'halbrooks': 2,
    'halbur': 2,
    'halbutogullari': 6,
    'halcion': 3,
    'halcomb': 2,
    'halcyon': 3,
    'halcyone': 3,
    'hald': 1,
    'haldan': 2,
    'haldana': 3,
    'haldeman': 2,
    'halden': 2,
    'halder': 2,
    'halderman': 3,
    'hale': 1,
    'halebopp': 2,
    'halen': 2,
    'hales': 1,
    'halevi': 3,
    'haley': 2,
    'half': 1,
    'halfacre': 3,
    'halfback': 2,
    'halfbacks': 2,
    'halfdan': 2,
    'halferty': 3,
    'halfhearted': 3,
    'halfhill': 2,
    'halfman': 2,
    'halfmann': 2,
    'halford': 2,
    'halfrida': 3,
    'halftime': 2,
    'halftimes': 2,
    'halftone': 2,
    'halftones': 2,
    'halfway': 2,
    'halfwit': 2,
    'halfwits': 2,
    'halgren': 2,
    'haliburton': 4,
    'halibut': 3,
    'halide': 2,
    'halifa': 3,
    'halifax': 3,
    'halik': 2,
    'halima': 3,
    'halimeda': 4,
    'halite': 2,
    'halko': 2,
    'hall': 1,
    'halla': 2,
    'halladay': 3,
    'hallahan': 3,
    'hallam': 2,
    'hallanan': 3,
    'hallandale': 3,
    'hallas': 2,
    'hallauer': 3,
    'hallbauer': 2,
    'hallberg': 2,
    'halle': 1,
    'halleck': 2,
    'hallelujah': 4,
    'hallen': 2,
    'hallenbeck': 3,
    'haller': 2,
    'halleran': 3,
    'hallet': 2,
    'hallett': 2,
    'halley': 2,
    'hallford': 2,
    'hallgarten': 3,
    'hallgren': 2,
    'halliburton': 4,
    'halliciforn': 4,
    'halliday': 3,
    'hallie': 2,
    'halligan': 3,
    'hallin': 2,
    'hallinan': 3,
    'halling': 2,
    'hallingby': 3,
    'hallisey': 3,
    'hallman': 2,
    'hallmark': 2,
    'hallmarks': 2,
    'hallock': 2,
    'halloran': 3,
    'hallow': 2,
    'halloway': 3,
    'hallowed': 2,
    'halloween': 3,
    'hallowell': 3,
    'hallows': 2,
    'hallquist': 2,
    'halls': 1,
    'hallstrom': 2,
    'hallucinate': 4,
    'hallucinated': 5,
    'hallucinates': 4,
    'hallucinating': 5,
    'hallucination': 5,
    'hallucinations': 5,
    'hallucinatory': 6,
    'hallucinogenic': 6,
    'hallum': 2,
    'hallums': 2,
    'hallward': 2,
    'hallway': 2,
    'hallways': 2,
    'hallwood': 2,
    'hally': 2,
    'halm': 1,
    'halmi': 2,
    'halmos': 2,
    'halmstad': 2,
    'halo': 2,
    'halogen': 3,
    'halogenate': 4,
    'halogenated': 5,
    'halon': 2,
    'halophytic': 4,
    'halos': 2,
    'halper': 2,
    'halperin': 3,
    'halpern': 2,
    'halperstant': 3,
    'halpert': 2,
    'halpin': 2,
    'halprin': 2,
    'halsell': 2,
    'halseth': 2,
    'halsey': 2,
    'halstead': 2,
    'halsted': 2,
    'halston': 2,
    'halt': 1,
    'halted': 2,
    'halteman': 2,
    'halter': 2,
    'halterman': 3,
    'halters': 2,
    'halting': 2,
    'haltingly': 3,
    'haltiwanger': 4,
    'haltom': 2,
    'halton': 2,
    'halts': 1,
    'haluska': 3,
    'halve': 1,
    'halved': 1,
    'halverson': 3,
    'halves': 1,
    'halving': 2,
    'halvorsen': 3,
    'halvorson': 3,
    'ham': 1,
    'hama': 2,
    'hamacher': 3,
    'hamad': 2,
    'hamada': 3,
    'hamadei': 3,
    'hamadi': 3,
    'hamaker': 3,
    'hamal': 2,
    'hamamoto': 4,
    'haman': 2,
    'hamanaka': 4,
    'hamann': 2,
    'hamar': 2,
    'hamas': 2,
    'hamasaki': 4,
    'hambelton': 3,
    'hamberg': 2,
    'hamberger': 3,
    'hamblen': 3,
    'hamblet': 2,
    'hambleton': 3,
    'hambley': 2,
    'hamblin': 2,
    'hambly': 2,
    'hambrecht': 2,
    'hambrick': 2,
    'hambright': 2,
    'hambro': 2,
    'hambros': 2,
    'hamburg': 2,
    'hamburger': 3,
    'hamburgers': 3,
    'hamby': 2,
    'hamdan': 2,
    'hamdoon': 2,
    'hamdun': 2,
    'hamed': 1,
    'hameister': 3,
    'hamel': 2,
    'hamelin': 3,
    'hamer': 2,
    'hames': 1,
    'hamid': 2,
    'hamiel': 2,
    'hamil': 2,
    'hamill': 2,
    'hamilton': 3,
    'hamiltons': 3,
    'hamish': 2,
    'hamiter': 3,
    'hamitic': 3,
    'hamler': 3,
    'hamlet': 2,
    'hamlets': 2,
    'hamlett': 2,
    'hamley': 2,
    'hamlin': 2,
    'hamling': 2,
    'hamlisch': 2,
    'hamm': 1,
    'hammac': 2,
    'hammacher': 3,
    'hammack': 2,
    'hammaker': 3,
    'hamman': 2,
    'hammann': 2,
    'hammar': 2,
    'hammas': 2,
    'hamme': 1,
    'hammel': 2,
    'hammell': 2,
    'hammen': 2,
    'hammer': 2,
    'hammered': 2,
    'hammering': 3,
    'hammerle': 3,
    'hammerlock': 3,
    'hammerman': 3,
    'hammermeister': 4,
    'hammermill': 3,
    'hammers': 2,
    'hammerschmidt': 3,
    'hammersley': 3,
    'hammersmith': 3,
    'hammerson': 3,
    'hammerstein': 3,
    'hammerstrom': 3,
    'hammes': 1,
    'hammett': 2,
    'hammill': 2,
    'hammitt': 2,
    'hammock': 2,
    'hammocks': 2,
    'hammon': 2,
    'hammond': 2,
    'hammonds': 2,
    'hammons': 2,
    'hammontree': 3,
    'hamner': 2,
    'hamon': 2,
    'hamor': 2,
    'hamp': 1,
    'hampe': 1,
    'hampel': 2,
    'hamper': 2,
    'hampered': 2,
    'hampering': 3,
    'hampers': 2,
    'hample': 2,
    'hampshire': 2,
    'hampshirites': 3,
    'hampson': 2,
    'hampstead': 2,
    'hampton': 2,
    'hamptons': 2,
    'hamptonshire': 3,
    'hamre': 2,
    'hamric': 2,
    'hamrick': 2,
    'hamrock': 2,
    'hams': 1,
    'hamsher': 2,
    'hamson': 2,
    'hamsphire': 2,
    'hamster': 2,
    'hamsters': 2,
    'hamstra': 2,
    'hamstring': 2,
    'hamstrings': 2,
    'hamstrung': 2,
    'hamtramck': 3,
    'han': 1,
    'hana': 2,
    'hanafin': 3,
    'hanagan': 3,
    'hanahan': 3,
    'hanak': 2,
    'hanan': 2,
    'hanas': 2,
    'hanauer': 3,
    'hanawalt': 3,
    'hanaway': 3,
    'hanback': 2,
    'hanberry': 3,
    'hanbo': 2,
    'hanbury': 3,
    'hanby': 2,
    'hance': 1,
    'hancher': 2,
    'hanchett': 2,
    'hanchey': 2,
    'hancock': 2,
    'hancox': 2,
    'hand': 1,
    'handa': 2,
    'handbag': 2,
    'handbags': 2,
    'handball': 2,
    'handballs': 2,
    'handbill': 2,
    'handbills': 2,
    'handbook': 2,
    'handbooks': 2,
    'handclasp': 2,
    'handcraft': 2,
    'handcrafted': 3,
    'handcrafts': 2,
    'handcuff': 2,
    'handcuffed': 2,
    'handcuffing': 3,
    'handcuffs': 2,
    'handed': 2,
    'handedly': 3,
    'handedness': 3,
    'handel': 2,
    'handeland': 3,
    'handelman': 3,
    'handels': 2,
    'handelsbank': 3,
    'handelsbanken': 4,
    'handelsman': 3,
    'hander': 2,
    'handers': 2,
    'handford': 2,
    'handful': 2,
    'handfuls': 2,
    'handgun': 2,
    'handguns': 2,
    'handheld': 2,
    'handhold': 2,
    'handholding': 3,
    'handicap': 3,
    'handicapped': 3,
    'handicapper': 4,
    'handicappers': 4,
    'handicapping': 4,
    'handicaps': 3,
    'handicraft': 3,
    'handicrafts': 3,
    'handier': 3,
    'handiest': 3,
    'handily': 3,
    'handing': 2,
    'handiwork': 3,
    'handke': 2,
    'handkerchief': 3,
    'handkerchiefs': 3,
    'handle': 2,
    'handlebar': 3,
    'handlebars': 3,
    'handled': 2,
    'handler': 2,
    'handlers': 2,
    'handles': 2,
    'handley': 2,
    'handlin': 2,
    'handling': 2,
    'handlon': 2,
    'handloom': 2,
    'handlooms': 2,
    'handly': 2,
    'handmade': 2,
    'handout': 2,
    'handouts': 2,
    'handover': 3,
    'handpick': 2,
    'handpicked': 2,
    'handrail': 2,
    'handrails': 2,
    'handrich': 2,
    'handros': 2,
    'hands': 1,
    'handsaw': 2,
    'handsaws': 2,
    'handset': 2,
    'handsets': 2,
    'handshake': 2,
    'handshakes': 2,
    'handshaking': 3,
    'handsome': 2,
    'handsomely': 3,
    'handstand': 2,
    'handstands': 2,
    'handwerk': 2,
    'handwerker': 3,
    'handwoven': 3,
    'handwriting': 3,
    'handwritten': 3,
    'handy': 2,
    'handyman': 3,
    'handymen': 3,
    'hane': 1,
    'haneda': 3,
    'hanel': 2,
    'haneline': 3,
    'hanemann': 2,
    'haner': 2,
    'hanes': 1,
    'haney': 2,
    'hanf': 1,
    'hanford': 2,
    'hanft': 1,
    'hang': 1,
    'hangar': 2,
    'hangars': 2,
    'hangartner': 3,
    'hanged': 1,
    'hangen': 2,
    'hanger': 2,
    'hangers': 2,
    'hanging': 2,
    'hangings': 2,
    'hangman': 2,
    'hangout': 2,
    'hangouts': 2,
    'hangover': 3,
    'hangovers': 3,
    'hangs': 1,
    'hangsang': 2,
    'hangup': 2,
    'hangups': 2,
    'hani': 2,
    'hanifen': 3,
    'hanifin': 3,
    'hanigan': 3,
    'haning': 2,
    'hanisch': 2,
    'hanisee': 3,
    'hanish': 2,
    'hanjin': 2,
    'hank': 1,
    'hanke': 1,
    'hankel': 2,
    'hanken': 2,
    'hanker': 2,
    'hankering': 3,
    'hankerson': 3,
    'hankes': 1,
    'hankey': 2,
    'hankin': 2,
    'hankins': 2,
    'hankinson': 3,
    'hankla': 2,
    'hanko': 2,
    'hanks': 1,
    'hanky': 2,
    'hanley': 2,
    'hanlin': 2,
    'hanlon': 2,
    'hanly': 2,
    'hanmer': 2,
    'hann': 1,
    'hanna': 2,
    'hannaford': 3,
    'hannagan': 3,
    'hannah': 2,
    'hannahs': 2,
    'hannam': 2,
    'hannaman': 3,
    'hannan': 2,
    'hannay': 2,
    'hanneken': 3,
    'hanneman': 2,
    'hannemann': 2,
    'hannen': 2,
    'hanner': 2,
    'hanners': 2,
    'hannes': 1,
    'hannesson': 3,
    'hanney': 2,
    'hanni': 2,
    'hannibal': 3,
    'hannie': 2,
    'hannifin': 3,
    'hannig': 2,
    'hannigan': 3,
    'hanning': 2,
    'hannis': 2,
    'hannity': 3,
    'hannoch': 2,
    'hannold': 2,
    'hannon': 2,
    'hannula': 3,
    'hannum': 2,
    'hanny': 2,
    'hano': 2,
    'hanoi': 2,
    'hanold': 2,
    'hanover': 3,
    'hanoverian': 5,
    'hanrahan': 3,
    'hanratty': 3,
    'hans': 1,
    'hansa': 2,
    'hansard': 2,
    'hansberger': 3,
    'hansberry': 3,
    'hansbrough': 2,
    'hansbury': 3,
    'hansche': 2,
    'hanscom': 2,
    'hanseatic': 4,
    'hansel': 2,
    'hansell': 2,
    'hanselman': 3,
    'hansen': 2,
    'hanser': 2,
    'hansford': 2,
    'hanshaw': 2,
    'hanshew': 2,
    'hanshin': 2,
    'hansley': 2,
    'hansma': 2,
    'hansman': 2,
    'hansmann': 2,
    'hansom': 2,
    'hanson': 2,
    'hanssen': 2,
    'hansson': 2,
    'hanta': 2,
    'hantavirus': 4,
    'hanten': 2,
    'hanthorn': 2,
    'hantman': 2,
    'hantz': 1,
    'hanukkah': 3,
    'hanukkahs': 3,
    'hanus': 2,
    'hanvey': 2,
    'hanwa': 2,
    'hanway': 2,
    'hany': 2,
    'hanzel': 2,
    'hanzlik': 2,
    'hao': 1,
    'hap': 1,
    'hapag': 2,
    'hapeman': 2,
    'hapgood': 2,
    'haphazard': 3,
    'haphazardly': 4,
    'hapke': 2,
    'hapless': 2,
    'haploid': 2,
    'hapner': 2,
    'hapoalim': 3,
    'happ': 1,
    'happe': 1,
    'happel': 2,
    'happen': 2,
    'happened': 2,
    'happening': 3,
    'happenings': 3,
    'happens': 2,
    'happenstance': 3,
    'happier': 3,
    'happiest': 3,
    'happily': 3,
    'happiness': 3,
    'happy': 2,
    'hapsburg': 2,
    'haq': 1,
    'haque': 1,
    'har': 1,
    'hara': 2,
    'harada': 3,
    'haradim': 3,
    'harahan': 3,
    'harald': 2,
    'haralda': 3,
    'haralson': 3,
    'haran': 2,
    'harangue': 2,
    'harangued': 2,
    'harangues': 2,
    'haranguing': 3,
    'harare': 3,
    'harass': 2,
    'harassed': 2,
    'harasser': 3,
    'harassers': 3,
    'harasses': 3,
    'harassing': 3,
    'harassment': 3,
    'haraszti': 3,
    'haraway': 3,
    'harb': 1,
    'harbach': 2,
    'harbaugh': 2,
    'harbeck': 2,
    'harber': 2,
    'harbert': 2,
    'harberts': 2,
    'harbeson': 3,
    'harbin': 2,
    'harbinger': 3,
    'harbingers': 3,
    'harbinson': 3,
    'harbison': 3,
    'harbold': 2,
    'harbor': 2,
    'harbored': 2,
    'harboring': 3,
    'harbors': 2,
    'harborside': 3,
    'harbory': 3,
    'harbour': 2,
    'harbuck': 2,
    'harbury': 3,
    'harc': 1,
    'harclerode': 4,
    'harcourt': 2,
    'harcrow': 2,
    'harcum': 2,
    'hard': 1,
    'hardacre': 3,
    'hardage': 2,
    'hardart': 2,
    'hardaway': 3,
    'hardback': 2,
    'hardball': 2,
    'hardboard': 2,
    'hardcastle': 3,
    'hardcore': 2,
    'hardcover': 3,
    'hardcovers': 3,
    'hardebeck': 2,
    'hardee': 2,
    'hardegree': 3,
    'hardeman': 2,
    'harden': 2,
    'hardenbrook': 3,
    'hardened': 2,
    'hardener': 3,
    'hardeners': 3,
    'hardening': 3,
    'hardens': 2,
    'harder': 2,
    'harders': 2,
    'hardest': 2,
    'hardesty': 3,
    'hardey': 2,
    'hardgood': 2,
    'hardgoods': 2,
    'hardgrave': 2,
    'hardgrove': 2,
    'hardhead': 2,
    'hardheaded': 3,
    'hardick': 2,
    'hardie': 2,
    'hardier': 3,
    'hardiest': 3,
    'hardigree': 3,
    'hardiman': 3,
    'hardimon': 3,
    'hardin': 2,
    'harding': 2,
    'hardinger': 3,
    'hardison': 3,
    'hardister': 3,
    'hardisty': 3,
    'hardline': 2,
    'hardliner': 3,
    'hardliners': 3,
    'hardly': 2,
    'hardman': 2,
    'hardness': 2,
    'hardnett': 2,
    'hardpressed': 2,
    'hardrick': 2,
    'hards': 1,
    'hardscrabble': 3,
    'hardship': 2,
    'hardships': 2,
    'hardt': 1,
    'hardtke': 2,
    'hardware': 2,
    'hardway': 2,
    'hardwick': 2,
    'hardwicke': 2,
    'hardwin': 2,
    'hardwood': 2,
    'hardwoods': 2,
    'hardwork': 2,
    'hardworking': 3,
    'hardy': 2,
    'hardymon': 3,
    'hare': 1,
    'harebrained': 2,
    'harelson': 3,
    'harem': 2,
    'haren': 2,
    'harer': 2,
    'hares': 1,
    'harewood': 2,
    'harff': 1,
    'harford': 2,
    'hargadon': 3,
    'hargan': 2,
    'hargarten': 3,
    'hargens': 2,
    'harger': 2,
    'hargett': 2,
    'hargis': 2,
    'hargrave': 2,
    'hargraves': 2,
    'hargreaves': 2,
    'hargrove': 2,
    'hargus': 2,
    'hari': 2,
    'harig': 2,
    'harima': 3,
    'haring': 2,
    'hariri': 3,
    'haris': 2,
    'haritos': 3,
    'hariz': 2,
    'harjo': 2,
    'harju': 2,
    'hark': 1,
    'harkavy': 3,
    'harke': 1,
    'harken': 2,
    'harker': 2,
    'harkey': 2,
    'harkin': 2,
    'harking': 2,
    'harkins': 2,
    'harkleroad': 3,
    'harkless': 2,
    'harkness': 2,
    'harkrader': 3,
    'harkrider': 3,
    'harks': 1,
    'harl': 1,
    'harlacher': 3,
    'harlan': 2,
    'harland': 2,
    'harle': 2,
    'harlem': 2,
    'harleman': 3,
    'harlequin': 3,
    'harless': 2,
    'harley': 2,
    'harleysville': 3,
    'harlin': 2,
    'harling': 2,
    'harlingen': 3,
    'harlison': 3,
    'harloff': 2,
    'harlot': 2,
    'harlow': 2,
    'harm': 1,
    'harman': 2,
    'harmata': 3,
    'harmattan': 3,
    'harmed': 1,
    'harmel': 2,
    'harmen': 2,
    'harmening': 3,
    'harmer': 2,
    'harmes': 1,
    'harmetz': 2,
    'harmeyer': 3,
    'harmful': 2,
    'harmfully': 3,
    'harmfulness': 3,
    'harming': 2,
    'harmison': 3,
    'harmless': 2,
    'harmlessly': 3,
    'harmon': 2,
    'harmonia': 4,
    'harmonic': 3,
    'harmonica': 4,
    'harmonics': 3,
    'harmonie': 3,
    'harmonies': 3,
    'harmonious': 4,
    'harmoniously': 5,
    'harmonium': 4,
    'harmonization': 5,
    'harmonize': 3,
    'harmonized': 3,
    'harmonizes': 4,
    'harmonizing': 4,
    'harmony': 3,
    'harms': 1,
    'harmsen': 2,
    'harn': 1,
    'harnack': 2,
    'harnage': 2,
    'harnden': 2,
    'harne': 1,
    'harned': 1,
    'harner': 2,
    'harness': 2,
    'harnessed': 2,
    'harnesses': 3,
    'harnessing': 3,
    'harnett': 2,
    'harney': 2,
    'harnisch': 2,
    'harnischfeger': 4,
    'harnish': 2,
    'harnois': 2,
    'haro': 2,
    'harold': 2,
    'haroldson': 3,
    'harp': 1,
    'harpe': 1,
    'harped': 1,
    'harpel': 2,
    'harpenau': 3,
    'harper': 2,
    'harpercollins': 4,
    'harpers': 2,
    'harpham': 2,
    'harpin': 2,
    'harping': 2,
    'harpist': 2,
    'harpists': 2,
    'harpley': 2,
    'harpo': 2,
    'harpold': 2,
    'harpole': 2,
    'harpoon': 2,
    'harpoons': 2,
    'harpootlian': 4,
    'harps': 1,
    'harpsichord': 3,
    'harpst': 1,
    'harpster': 2,
    'harquebus': 3,
    'harr': 1,
    'harra': 2,
    'harrah': 2,
    'harral': 2,
    'harralson': 3,
    'harre': 1,
    'harrel': 2,
    'harreld': 2,
    'harrell': 2,
    'harrelson': 3,
    'harren': 2,
    'harrer': 2,
    'harried': 2,
    'harrier': 3,
    'harries': 2,
    'harriet': 3,
    'harriette': 3,
    'harrigan': 3,
    'harriger': 3,
    'harrill': 2,
    'harriman': 3,
    'harring': 2,
    'harrington': 3,
    'harriott': 3,
    'harris': 2,
    'harrisburg': 3,
    'harrisburgh': 3,
    'harrises': 3,
    'harrison': 3,
    'harriss': 2,
    'harrity': 3,
    'harrod': 2,
    'harrods': 2,
    'harrold': 2,
    'harron': 2,
    'harrop': 2,
    'harroun': 2,
    'harrow': 2,
    'harrower': 3,
    'harrowing': 3,
    'harry': 2,
    'harryman': 3,
    'harsch': 1,
    'harsco': 2,
    'harsh': 1,
    'harsha': 2,
    'harsham': 2,
    'harshaw': 2,
    'harshbarger': 3,
    'harshberger': 3,
    'harsher': 2,
    'harshest': 2,
    'harshfield': 2,
    'harshly': 2,
    'harshman': 2,
    'harshness': 2,
    'harstad': 2,
    'harston': 2,
    'hart': 1,
    'harte': 1,
    'hartel': 2,
    'hartell': 2,
    'harten': 2,
    'hartenstein': 3,
    'harter': 2,
    'hartfiel': 2,
    'hartfield': 2,
    'hartford': 2,
    'hartgraves': 2,
    'hartgrove': 2,
    'harth': 1,
    'hartig': 2,
    'hartigan': 3,
    'hartin': 2,
    'harting': 2,
    'hartinger': 3,
    'hartis': 2,
    'hartje': 2,
    'hartke': 2,
    'hartkopf': 2,
    'hartl': 2,
    'hartlage': 2,
    'hartland': 2,
    'hartlaub': 2,
    'hartle': 2,
    'hartless': 2,
    'hartley': 2,
    'hartlieb': 2,
    'hartline': 2,
    'hartling': 2,
    'hartman': 2,
    'hartmann': 2,
    'hartmarx': 2,
    'hartnell': 2,
    'hartner': 2,
    'hartness': 2,
    'hartnett': 2,
    'hartney': 2,
    'hartog': 2,
    'harton': 2,
    'hartong': 2,
    'hartranft': 2,
    'harts': 1,
    'hartsell': 2,
    'hartsfield': 2,
    'hartshorn': 2,
    'hartshorne': 2,
    'hartsock': 2,
    'hartsoe': 2,
    'hartson': 2,
    'hartsook': 2,
    'hartsough': 2,
    'hartstein': 2,
    'hartsville': 2,
    'hartt': 1,
    'hartter': 2,
    'hartung': 2,
    'hartwell': 2,
    'hartwick': 2,
    'hartwig': 2,
    'hartwood': 2,
    'harty': 2,
    'hartz': 1,
    'hartzel': 2,
    'hartzell': 2,
    'hartzler': 2,
    'hartzog': 2,
    'haruo': 3,
    'harv': 1,
    'harvard': 2,
    'harvath': 2,
    'harve': 1,
    'harvel': 2,
    'harvell': 2,
    'harvest': 2,
    'harvestable': 4,
    'harvested': 3,
    'harvester': 3,
    'harvesters': 3,
    'harvesting': 3,
    'harvests': 2,
    'harvey': 2,
    'harvie': 2,
    'harvill': 2,
    'harville': 2,
    'harvin': 2,
    'harvison': 3,
    'harward': 2,
    'harwell': 2,
    'harwick': 2,
    'harwood': 2,
    'haryana': 3,
    'has': 1,
    'hasan': 2,
    'hasbro': 2,
    'hasbrook': 2,
    'hasbrouck': 2,
    'hascall': 2,
    'hasch': 1,
    'haschke': 1,
    'hase': 1,
    'hasegawa': 4,
    'hasek': 2,
    'haselden': 3,
    'haseley': 3,
    'haselhorst': 3,
    'haseltine': 3,
    'haselton': 3,
    'haseman': 2,
    'hasenauer': 4,
    'hasenfus': 3,
    'haser': 2,
    'hash': 1,
    'hashagen': 3,
    'hashed': 1,
    'hashem': 2,
    'hashemi': 3,
    'hashemite': 3,
    'hasher': 2,
    'hashers': 2,
    'hashes': 2,
    'hashi': 2,
    'hashim': 2,
    'hashimoto': 4,
    'hashing': 2,
    'hashish': 2,
    'hashman': 2,
    'hashmark': 2,
    'hashmi': 2,
    'hasidic': 3,
    'hasidim': 3,
    'haske': 1,
    'haskell': 2,
    'haskett': 2,
    'haskew': 2,
    'haskin': 2,
    'haskins': 2,
    'haslam': 2,
    'hasler': 3,
    'haslett': 2,
    'hasley': 2,
    'hasman': 2,
    'haso': 2,
    'haspel': 2,
    'hass': 1,
    'hassan': 2,
    'hasse': 1,
    'hassebrock': 3,
    'hassel': 2,
    'hasselbach': 3,
    'hasselbring': 3,
    'hassell': 2,
    'hasselman': 3,
    'hassen': 2,
    'hassenberg': 3,
    'hassenfeld': 3,
    'hassett': 2,
    'hassey': 2,
    'hassig': 2,
    'hassing': 2,
    'hassinger': 3,
    'hassle': 2,
    'hassled': 2,
    'hassler': 2,
    'hassles': 2,
    'hassling': 3,
    'hassman': 2,
    'hasson': 2,
    'hast': 1,
    'hasta': 2,
    'haste': 1,
    'hasten': 2,
    'hastened': 2,
    'hastening': 3,
    'hastens': 2,
    'hastert': 2,
    'hastey': 2,
    'hastie': 2,
    'hastily': 3,
    'hasting': 2,
    'hastings': 2,
    'haston': 2,
    'hasty': 2,
    'haswell': 2,
    'hasz': 1,
    'hat': 1,
    'hata': 2,
    'hatala': 3,
    'hataway': 3,
    'hatch': 1,
    'hatchback': 2,
    'hatched': 1,
    'hatchel': 2,
    'hatchell': 2,
    'hatcher': 2,
    'hatcheries': 3,
    'hatchery': 3,
    'hatches': 2,
    'hatchet': 2,
    'hatchets': 2,
    'hatchett': 2,
    'hatching': 2,
    'hate': 1,
    'hated': 2,
    'hateful': 2,
    'hatem': 2,
    'hater': 2,
    'haters': 2,
    'hates': 1,
    'hatfield': 2,
    'hath': 1,
    'hathaway': 3,
    'hathcoat': 2,
    'hathcock': 2,
    'hatheway': 2,
    'hathorn': 2,
    'hathorne': 2,
    'hating': 2,
    'hatler': 3,
    'hatlestad': 3,
    'hatley': 2,
    'hatmaker': 3,
    'haton': 2,
    'hatred': 2,
    'hatreds': 2,
    'hatrick': 2,
    'hats': 1,
    'hatt': 1,
    'hattabaugh': 3,
    'hattan': 2,
    'hattaway': 3,
    'hatten': 2,
    'hattendorf': 3,
    'hatter': 2,
    'hatteras': 3,
    'hattersley': 3,
    'hattery': 3,
    'hattie': 2,
    'hatton': 2,
    'hattori': 3,
    'hattusas': 3,
    'hatty': 2,
    'hatz': 1,
    'hau': 1,
    'haub': 1,
    'hauber': 2,
    'haubert': 2,
    'haubner': 2,
    'haubrich': 2,
    'hauch': 1,
    'hauck': 1,
    'hauenstein': 3,
    'hauer': 2,
    'haueter': 2,
    'hauf': 1,
    'haufer': 2,
    'hauff': 1,
    'haug': 1,
    'haugan': 2,
    'hauge': 1,
    'haugen': 2,
    'hauger': 2,
    'haugh': 1,
    'haughey': 2,
    'haughn': 1,
    'haught': 1,
    'haughtily': 3,
    'haughton': 2,
    'haughty': 2,
    'haugland': 2,
    'hauk': 1,
    'hauke': 1,
    'haul': 1,
    'hauled': 1,
    'hauler': 2,
    'haulers': 2,
    'hauling': 2,
    'hauls': 1,
    'haulsey': 2,
    'haun': 1,
    'haunt': 1,
    'haunted': 2,
    'haunting': 2,
    'hauntingly': 3,
    'haunts': 1,
    'haupert': 2,
    'hauppauge': 2,
    'haupt': 1,
    'hauptfuhrer': 3,
    'hauptman': 2,
    'hauptmann': 2,
    'haury': 2,
    'haus': 1,
    'hausa': 2,
    'hausauer': 3,
    'hausch': 1,
    'hauschild': 2,
    'hauschildt': 2,
    'hause': 1,
    'hausen': 2,
    'hauser': 2,
    'hauserman': 3,
    'hausfeld': 2,
    'hausky': 2,
    'hausler': 3,
    'hausman': 2,
    'hausmann': 2,
    'hausner': 2,
    'hauss': 1,
    'hausser': 2,
    'haussler': 3,
    'haussmann': 2,
    'hausummi': 3,
    'hauswirth': 2,
    'haut': 1,
    'hautala': 3,
    'haute': 1,
    'hauter': 2,
    'hauth': 1,
    'hauver': 2,
    'havana': 3,
    'havard': 2,
    'havas': 2,
    'have': 1,
    'havel': 2,
    'havelka': 3,
    'havelock': 2,
    'haveman': 2,
    'havemann': 3,
    'haven': 2,
    'havener': 3,
    'havens': 2,
    'haver': 2,
    'haverfield': 3,
    'haverford': 3,
    'haverkamp': 3,
    'haverland': 3,
    'haverly': 3,
    'haverstick': 3,
    'haverstock': 3,
    'haverty': 3,
    'haves': 1,
    'havey': 2,
    'havier': 2,
    'haviland': 3,
    'havill': 2,
    'havilland': 3,
    'having': 2,
    'havington': 3,
    'havins': 2,
    'havis': 2,
    'havlicek': 3,
    'havlik': 2,
    'havlin': 2,
    'havner': 2,
    'havoc': 2,
    'havran': 2,
    'havranek': 3,
    'havrilla': 3,
    'havron': 2,
    'haw': 1,
    'hawaii': 3,
    'hawaiian': 3,
    'hawaiians': 3,
    'hawass': 2,
    'hawbaker': 3,
    'hawe': 1,
    'hawes': 1,
    'hawing': 2,
    'hawk': 1,
    'hawkbill': 2,
    'hawke': 1,
    'hawked': 1,
    'hawken': 2,
    'hawker': 2,
    'hawkers': 2,
    'hawkes': 1,
    'hawkey': 2,
    'hawkeye': 2,
    'hawkiness': 2,
    'hawking': 2,
    'hawkins': 2,
    'hawkinson': 3,
    'hawkish': 2,
    'hawks': 1,
    'hawksley': 2,
    'hawley': 2,
    'hawn': 1,
    'haworth': 2,
    'haws': 1,
    'hawthorn': 2,
    'hawthorne': 2,
    'hawthorns': 2,
    'hawver': 2,
    'haxton': 2,
    'hay': 1,
    'hayashi': 3,
    'hayashida': 4,
    'haycock': 2,
    'haycraft': 2,
    'haydel': 2,
    'hayden': 2,
    'haydn': 2,
    'haydock': 2,
    'haydon': 2,
    'haydu': 2,
    'hayduk': 2,
    'haye': 1,
    'hayek': 2,
    'hayen': 2,
    'hayenga': 3,
    'hayer': 2,
    'hayes': 1,
    'hayfield': 2,
    'hayfields': 2,
    'hayford': 2,
    'haygood': 2,
    'hayhurst': 2,
    'haying': 2,
    'hayles': 1,
    'hayley': 2,
    'hayloft': 2,
    'haylofts': 2,
    'haymaker': 3,
    'haymakers': 3,
    'hayman': 2,
    'haymarket': 3,
    'haymarkets': 3,
    'haymes': 1,
    'haymon': 2,
    'haymond': 2,
    'haymore': 2,
    'hayn': 1,
    'hayne': 1,
    'hayner': 2,
    'haynes': 1,
    'haynesworth': 2,
    'haynie': 2,
    'haynsworth': 2,
    'hays': 1,
    'hayse': 1,
    'hayslett': 2,
    'hayslip': 2,
    'haystack': 2,
    'haystacks': 2,
    'hayter': 2,
    'hayton': 2,
    'hayward': 2,
    'haywire': 2,
    'haywood': 2,
    'hayworth': 2,
    'hazan': 2,
    'hazard': 2,
    'hazardous': 3,
    'hazards': 2,
    'haze': 1,
    'hazel': 2,
    'hazelbaker': 4,
    'hazelett': 3,
    'hazelip': 3,
    'hazell': 2,
    'hazelnut': 3,
    'hazelnuts': 3,
    'hazelrigg': 3,
    'hazeltine': 3,
    'hazelton': 3,
    'hazelwood': 3,
    'hazen': 2,
    'hazier': 3,
    'hazing': 2,
    'hazle': 2,
    'hazleton': 3,
    'hazlett': 2,
    'hazlewood': 3,
    'hazy': 2,
    'hazzard': 2,
    'hbox': 2,
    'hces': 4,
    'he': 1,
    'heaberlin': 3,
    'heacock': 2,
    'heacox': 2,
    'head': 1,
    'headache': 2,
    'headaches': 2,
    'headband': 2,
    'headbands': 2,
    'headboard': 2,
    'headboards': 2,
    'headcheese': 2,
    'headcount': 2,
    'headcounts': 2,
    'headdress': 2,
    'headdresses': 3,
    'headed': 2,
    'headen': 2,
    'header': 2,
    'headers': 2,
    'headfirst': 2,
    'headgear': 2,
    'headhunter': 3,
    'headhunters': 3,
    'heading': 2,
    'headings': 2,
    'headington': 3,
    'headlee': 2,
    'headless': 2,
    'headley': 2,
    'headlight': 2,
    'headlights': 2,
    'headline': 2,
    'headlined': 2,
    'headliner': 3,
    'headliners': 3,
    'headlines': 2,
    'headlining': 3,
    'headlong': 2,
    'headlund': 2,
    'headman': 2,
    'headmaster': 3,
    'headmasters': 3,
    'headmistress': 3,
    'headmistresses': 4,
    'headphone': 2,
    'headphones': 2,
    'headquarter': 3,
    'headquartered': 3,
    'headquarters': 3,
    'headrest': 2,
    'headrests': 2,
    'headrick': 2,
    'headroom': 2,
    'heads': 1,
    'headset': 2,
    'headsets': 2,
    'headship': 2,
    'headsman': 2,
    'headstart': 2,
    'headstarts': 2,
    'headstone': 2,
    'headstones': 2,
    'headstrong': 2,
    'headwater': 3,
    'headwaters': 3,
    'headway': 2,
    'headwind': 2,
    'headwinds': 2,
    'heady': 2,
    'heafner': 2,
    'heagle': 2,
    'heagney': 2,
    'heagy': 2,
    'heal': 1,
    'heald': 1,
    'healed': 1,
    'healer': 2,
    'healers': 2,
    'healey': 2,
    'healing': 2,
    'heals': 1,
    'health': 1,
    'healthamerica': 5,
    'healthcare': 2,
    'healthcorp': 2,
    'healthdyne': 2,
    'healthful': 2,
    'healthier': 3,
    'healthiest': 3,
    'healthiness': 3,
    'healthsource': 2,
    'healthsouth': 2,
    'healthtrust': 2,
    'healthvest': 2,
    'healthweek': 2,
    'healthwork': 2,
    'healthworks': 2,
    'healthy': 2,
    'healy': 2,
    'heaney': 2,
    'heap': 1,
    'heape': 1,
    'heaped': 1,
    'heaphy': 2,
    'heaping': 2,
    'heaps': 1,
    'hear': 1,
    'heard': 1,
    'hearer': 2,
    'hearers': 2,
    'hearin': 2,
    'hearing': 2,
    'hearings': 2,
    'hearkens': 2,
    'hearl': 1,
    'hearn': 1,
    'hearne': 1,
    'hearns': 1,
    'hearon': 2,
    'hearron': 2,
    'hears': 1,
    'hearsay': 2,
    'hearse': 1,
    'hearst': 1,
    'heart': 1,
    'heartache': 2,
    'heartbeat': 2,
    'heartbeats': 2,
    'heartbreak': 2,
    'heartbreaking': 3,
    'heartbreaks': 2,
    'heartbroken': 3,
    'heartburn': 2,
    'hearted': 2,
    'heartedly': 3,
    'hearten': 2,
    'heartened': 2,
    'heartening': 3,
    'heartfelt': 2,
    'hearth': 1,
    'hearths': 1,
    'heartier': 3,
    'heartiest': 3,
    'heartily': 3,
    'heartland': 2,
    'heartless': 2,
    'hearts': 1,
    'heartscan': 2,
    'heartthrob': 2,
    'heartthrobs': 2,
    'heartwarming': 3,
    'heartwise': 2,
    'heartwood': 2,
    'hearty': 2,
    'heasley': 2,
    'heaslip': 2,
    'heaston': 2,
    'heat': 1,
    'heated': 2,
    'heatedly': 3,
    'heater': 2,
    'heaters': 2,
    'heath': 1,
    'heathcliff': 2,
    'heathcock': 2,
    'heathcote': 2,
    'heathen': 2,
    'heather': 2,
    'heatherington': 4,
    'heatherly': 3,
    'heathers': 2,
    'heathman': 2,
    'heathrow': 2,
    'heathwood': 2,
    'heating': 2,
    'heatley': 2,
    'heaton': 2,
    'heats': 1,
    'heatwole': 2,
    'heave': 1,
    'heaved': 1,
    'heaven': 2,
    'heavener': 3,
    'heavenly': 3,
    'heavens': 2,
    'heaves': 1,
    'heavey': 2,
    'heavier': 3,
    'heavies': 2,
    'heaviest': 3,
    'heavily': 3,
    'heavin': 2,
    'heaving': 2,
    'heavner': 2,
    'heavrin': 2,
    'heavy': 2,
    'heavyhanded': 4,
    'heavyset': 3,
    'heavyweight': 3,
    'heavyweights': 3,
    'hebard': 2,
    'hebb': 1,
    'hebda': 2,
    'hebding': 2,
    'hebe': 1,
    'hebei': 2,
    'hebel': 2,
    'hebeler': 3,
    'hebenstreit': 3,
    'heber': 2,
    'heberer': 3,
    'heberle': 3,
    'heberlein': 3,
    'heberling': 3,
    'hebert': 2,
    'hebner': 2,
    'hebrew': 2,
    'hebrides': 3,
    'hebron': 2,
    'hecco': 2,
    'hechinger': 3,
    'hechler': 2,
    'hecht': 1,
    'hechtman': 2,
    'heck': 1,
    'heckaman': 3,
    'heckard': 2,
    'heckart': 2,
    'heckathorn': 3,
    'heckbert': 2,
    'heckel': 2,
    'heckendorn': 3,
    'hecker': 2,
    'heckert': 2,
    'heckle': 2,
    'heckled': 2,
    'heckler': 2,
    'hecklers': 2,
    'heckling': 2,
    'heckman': 2,
    'heckmann': 2,
    'heckuva': 3,
    'hecla': 2,
    'hecox': 2,
    'hectare': 2,
    'hectares': 2,
    'hectic': 2,
    'hectograph': 3,
    'hector': 2,
    'hectoring': 3,
    'hecuba': 3,
    'heda': 2,
    'hedberg': 2,
    'hedda': 2,
    'hedden': 2,
    'hedding': 2,
    'heddy': 2,
    'hedeen': 2,
    'hedge': 1,
    'hedgecock': 2,
    'hedged': 1,
    'hedgehog': 2,
    'hedgehogs': 2,
    'hedgepath': 2,
    'hedgepeth': 3,
    'hedger': 2,
    'hedgers': 2,
    'hedges': 2,
    'hedging': 2,
    'hedglin': 2,
    'hedgpeth': 2,
    'hedi': 2,
    'hediger': 3,
    'hedin': 2,
    'hedinger': 3,
    'hedley': 2,
    'hedlund': 2,
    'hedman': 2,
    'hedonic': 3,
    'hedonism': 4,
    'hedonistic': 4,
    'hedquist': 2,
    'hedrich': 2,
    'hedrick': 2,
    'hedstrom': 2,
    'hedtke': 2,
    'hedwig': 2,
    'hedwiga': 3,
    'hedy': 2,
    'hee': 1,
    'heeb': 1,
    'heebner': 2,
    'heed': 1,
    'heeded': 2,
    'heeding': 2,
    'heeds': 1,
    'heefner': 2,
    'heeg': 1,
    'heeke': 1,
    'heekin': 2,
    'heel': 1,
    'heelan': 2,
    'heeled': 1,
    'heeling': 2,
    'heels': 1,
    'heemstra': 2,
    'heenan': 2,
    'heeney': 2,
    'heer': 2,
    'heeren': 2,
    'heermann': 2,
    'hees': 1,
    'heesch': 1,
    'heese': 1,
    'heeter': 2,
    'hefei': 2,
    'heffel': 2,
    'heffelfinger': 4,
    'hefferan': 3,
    'hefferman': 3,
    'heffern': 2,
    'heffernan': 3,
    'hefferon': 3,
    'heffington': 3,
    'heffler': 2,
    'heffley': 2,
    'heffner': 2,
    'heffron': 2,
    'hefler': 2,
    'hefley': 2,
    'heflin': 2,
    'hefner': 2,
    'heft': 1,
    'hefter': 2,
    'hefti': 2,
    'heftier': 3,
    'heftiest': 3,
    'hefty': 2,
    'hegadorn': 3,
    'hegarty': 3,
    'hege': 1,
    'hegedus': 3,
    'hegel': 2,
    'hegelian': 4,
    'hegeman': 2,
    'hegemonic': 4,
    'hegemony': 4,
    'hegenna': 3,
    'heger': 2,
    'hegg': 1,
    'hegge': 1,
    'heggen': 2,
    'heggie': 2,
    'hegland': 2,
    'hegler': 2,
    'heglund': 2,
    'hegna': 2,
    'hegner': 2,
    'hegstrom': 2,
    'hegwood': 2,
    'hegyi': 2,
    'heh': 1,
    'hehir': 2,
    'hehl': 1,
    'hehman': 2,
    'hehmeyer': 2,
    'hehn': 1,
    'hehr': 1,
    'heibel': 2,
    'heiberg': 2,
    'heiberger': 3,
    'heichel': 2,
    'heichelbech': 3,
    'heick': 1,
    'heico': 2,
    'heid': 1,
    'heidbreder': 3,
    'heidbrink': 2,
    'heide': 1,
    'heidecker': 3,
    'heidel': 2,
    'heidelberg': 3,
    'heidelberger': 4,
    'heideman': 2,
    'heidemann': 2,
    'heiden': 2,
    'heidenreich': 3,
    'heider': 2,
    'heidi': 2,
    'heidinger': 3,
    'heidiwear': 3,
    'heidler': 3,
    'heidorn': 2,
    'heidrich': 2,
    'heidrick': 2,
    'heidstra': 2,
    'heidt': 1,
    'heier': 2,
    'heifer': 2,
    'heifers': 2,
    'heifetz': 2,
    'heifner': 2,
    'heiges': 2,
    'height': 1,
    'heighten': 2,
    'heightened': 2,
    'heightening': 3,
    'heightens': 2,
    'heighth': 1,
    'heights': 1,
    'heigl': 2,
    'heiken': 2,
    'heikes': 2,
    'heikkila': 3,
    'heikkinen': 3,
    'heiko': 2,
    'heil': 1,
    'heiland': 2,
    'heilbrun': 2,
    'heileman': 2,
    'heiler': 2,
    'heilig': 2,
    'heilman': 2,
    'heilmann': 2,
    'heim': 1,
    'heiman': 2,
    'heimann': 2,
    'heimbach': 2,
    'heimberger': 3,
    'heimbigner': 3,
    'heimbuch': 2,
    'heimburger': 3,
    'heimer': 2,
    'heimerl': 2,
    'heimlich': 2,
    'heims': 1,
    'heimsoth': 2,
    'hein': 1,
    'heinbach': 2,
    'heinbaugh': 2,
    'heinburger': 3,
    'heindel': 2,
    'heindl': 2,
    'heine': 1,
    'heinecke': 2,
    'heineken': 3,
    'heineman': 2,
    'heinemann': 2,
    'heinen': 2,
    'heiner': 2,
    'heines': 1,
    'heiney': 2,
    'heini': 2,
    'heinicke': 2,
    'heinig': 2,
    'heininger': 3,
    'heinisch': 2,
    'heinke': 1,
    'heinkel': 2,
    'heinl': 2,
    'heinle': 2,
    'heinlein': 2,
    'heinlen': 3,
    'heinly': 2,
    'heino': 2,
    'heinold': 2,
    'heinonen': 3,
    'heinous': 2,
    'heinrich': 2,
    'heinrichs': 2,
    'heins': 1,
    'heinsohn': 2,
    'heintz': 1,
    'heintze': 1,
    'heintzelman': 3,
    'heintzman': 2,
    'heiny': 2,
    'heinz': 1,
    'heinze': 1,
    'heinzel': 2,
    'heinzelman': 3,
    'heinzen': 2,
    'heinzman': 2,
    'heinzmann': 2,
    'heiple': 2,
    'heir': 1,
    'heiress': 2,
    'heiresses': 3,
    'heirloom': 2,
    'heirlooms': 2,
    'heironimus': 4,
    'heirs': 1,
    'heisbourg': 2,
    'heise': 1,
    'heisel': 2,
    'heiser': 2,
    'heiserman': 3,
    'heisey': 2,
    'heishman': 3,
    'heisinger': 3,
    'heiskell': 2,
    'heisler': 3,
    'heisman': 2,
    'heisner': 2,
    'heiss': 1,
    'heist': 1,
    'heistand': 2,
    'heister': 2,
    'heit': 1,
    'heitkamp': 2,
    'heitman': 2,
    'heitmann': 2,
    'heitmeyer': 3,
    'heitner': 2,
    'heitz': 1,
    'heitzenrater': 4,
    'heitzman': 2,
    'heiwa': 2,
    'heizer': 2,
    'hejl': 1,
    'hejna': 2,
    'hekker': 2,
    'hekmatyar': 3,
    'helaba': 3,
    'helander': 3,
    'helane': 2,
    'helber': 2,
    'helberg': 2,
    'helbert': 2,
    'helbig': 2,
    'helbing': 2,
    'helbling': 3,
    'held': 1,
    'heldenbrand': 3,
    'heldentenor': 4,
    'helder': 2,
    'helderman': 3,
    'heldman': 2,
    'heldor': 2,
    'heldreth': 2,
    'heldring': 2,
    'helds': 1,
    'heldt': 1,
    'helen': 2,
    'helena': 3,
    'helene': 2,
    'helens': 2,
    'helf': 1,
    'helfand': 2,
    'helfer': 2,
    'helfgott': 2,
    'helfman': 2,
    'helfrich': 2,
    'helga': 2,
    'helgerson': 3,
    'helgesen': 3,
    'helgeson': 3,
    'helget': 2,
    'helgren': 2,
    'helical': 3,
    'helice': 2,
    'helicon': 3,
    'helicons': 3,
    'helicopter': 4,
    'helicopters': 4,
    'helie': 2,
    'helin': 2,
    'heling': 2,
    'helinski': 3,
    'helionetic': 5,
    'helionetics': 5,
    'heliopolis': 5,
    'helios': 3,
    'heliotrope': 4,
    'helium': 3,
    'helix': 2,
    'helke': 1,
    'hell': 1,
    'hellacious': 3,
    'hellaciously': 4,
    'hellaciousness': 4,
    'hellams': 2,
    'helland': 2,
    'hellard': 2,
    'hellberg': 2,
    'hellbusch': 2,
    'helle': 1,
    'hellen': 2,
    'hellenbrand': 3,
    'hellenic': 3,
    'hellenism': 4,
    'hellenistic': 4,
    'hellenize': 3,
    'hellenized': 3,
    'hellenizes': 4,
    'hellenizing': 4,
    'heller': 2,
    'hellerman': 3,
    'hellfire': 2,
    'hellickson': 3,
    'hellier': 2,
    'helling': 2,
    'hellinger': 3,
    'hellish': 2,
    'hellman': 2,
    'hellmann': 2,
    'hellmer': 2,
    'hellmuth': 2,
    'hellner': 2,
    'hello': 2,
    'hellraiser': 3,
    'hellstrom': 2,
    'hellums': 2,
    'helluva': 3,
    'hellwig': 2,
    'hellyer': 3,
    'helm': 1,
    'helma': 2,
    'helman': 2,
    'helmbrecht': 2,
    'helme': 1,
    'helmer': 2,
    'helmerich': 3,
    'helmers': 2,
    'helmes': 1,
    'helmet': 2,
    'helmeted': 3,
    'helmets': 2,
    'helmich': 2,
    'helmick': 2,
    'helmig': 2,
    'helming': 2,
    'helminiak': 4,
    'helminski': 3,
    'helminth': 2,
    'helmkamp': 2,
    'helmke': 1,
    'helmont': 2,
    'helms': 1,
    'helmsburton': 3,
    'helmsley': 2,
    'helmsleys': 2,
    'helmsman': 2,
    'helmstetter': 3,
    'helmut': 2,
    'helmuth': 2,
    'helot': 2,
    'helotism': 4,
    'helotry': 3,
    'helots': 2,
    'help': 1,
    'helped': 1,
    'helper': 2,
    'helpers': 2,
    'helpful': 2,
    'helpfully': 3,
    'helping': 2,
    'helpings': 2,
    'helpless': 2,
    'helplessly': 3,
    'helplessness': 3,
    'helprin': 2,
    'helps': 1,
    'helsel': 2,
    'helser': 2,
    'helseth': 2,
    'helsinki': 3,
    'helsley': 2,
    'helstrom': 2,
    'helt': 1,
    'helter': 2,
    'helton': 2,
    'heltsley': 2,
    'heltzel': 2,
    'helvey': 2,
    'helvie': 2,
    'helwig': 2,
    'helzer': 2,
    'hem': 1,
    'heman': 2,
    'hemani': 3,
    'hemann': 2,
    'hemant': 2,
    'hematite': 3,
    'hematology': 5,
    'hemberger': 3,
    'hembree': 2,
    'hembrick': 2,
    'hemby': 2,
    'hemdale': 2,
    'hemel': 2,
    'hemenway': 3,
    'hemimorphite': 4,
    'heming': 2,
    'heminger': 3,
    'hemingway': 3,
    'hemiplegia': 5,
    'hemisphere': 3,
    'hemispheric': 4,
    'hemker': 2,
    'hemler': 2,
    'hemline': 2,
    'hemlines': 2,
    'hemlo': 2,
    'hemlock': 2,
    'hemm': 1,
    'hemme': 1,
    'hemmed': 1,
    'hemmelgarn': 3,
    'hemmen': 2,
    'hemmer': 2,
    'hemmerich': 3,
    'hemmerle': 3,
    'hemmerling': 3,
    'hemmert': 2,
    'hemmeter': 3,
    'hemming': 2,
    'hemminger': 3,
    'hemminghaus': 3,
    'hemmings': 2,
    'hemmingsen': 3,
    'hemmingson': 3,
    'hemo': 2,
    'hemocyanin': 5,
    'hemodynamic': 5,
    'hemodynamics': 5,
    'hemoglobin': 4,
    'hemolytic': 4,
    'hemond': 2,
    'hemophilia': 5,
    'hemophiliac': 5,
    'hemophiliacs': 5,
    'hemorrhage': 3,
    'hemorrhaged': 3,
    'hemorrhagic': 4,
    'hemorrhaging': 4,
    'hemorrhoid': 3,
    'hemorrhoids': 3,
    'hemotec': 3,
    'hemp': 1,
    'hempel': 2,
    'hempen': 2,
    'hempfling': 3,
    'hemphill': 2,
    'hempstead': 2,
    'hemric': 2,
    'hemrick': 2,
    'hemry': 2,
    'hems': 1,
    'hemsley': 2,
    'hemstreet': 2,
    'hemy': 2,
    'hen': 1,
    'henao': 2,
    'henard': 2,
    'henault': 2,
    'henbane': 2,
    'hence': 1,
    'henceforth': 2,
    'hench': 1,
    'henchman': 2,
    'henchmen': 2,
    'henckel': 2,
    'hendee': 2,
    'hendel': 2,
    'hendershot': 3,
    'hendershott': 3,
    'henderson': 3,
    'hendler': 2,
    'hendley': 2,
    'hendon': 2,
    'hendra': 2,
    'hendren': 3,
    'hendrich': 2,
    'hendrick': 2,
    'hendricks': 2,
    'hendricksen': 3,
    'hendrickson': 3,
    'hendrie': 3,
    'hendrik': 2,
    'hendriks': 2,
    'hendriksen': 3,
    'hendrix': 2,
    'hendrixson': 3,
    'hendron': 2,
    'hendry': 2,
    'hendryx': 2,
    'hendy': 2,
    'henegar': 3,
    'heneghan': 3,
    'henehan': 3,
    'henery': 3,
    'henes': 1,
    'heney': 2,
    'heng': 1,
    'hengel': 2,
    'hengst': 1,
    'henhouse': 2,
    'henie': 2,
    'henigan': 3,
    'henin': 2,
    'heninger': 3,
    'henion': 2,
    'henk': 1,
    'henke': 1,
    'henkel': 2,
    'henkelman': 3,
    'henkels': 2,
    'henken': 2,
    'henkes': 1,
    'henkin': 2,
    'henkle': 2,
    'henle': 2,
    'henley': 2,
    'henleys': 2,
    'henline': 2,
    'henly': 2,
    'henman': 2,
    'henn': 1,
    'henna': 2,
    'hennan': 2,
    'henne': 1,
    'henneberger': 3,
    'henneberry': 3,
    'henneke': 2,
    'hennelly': 3,
    'henneman': 2,
    'hennen': 2,
    'hennepin': 3,
    'henner': 2,
    'hennes': 1,
    'henness': 2,
    'hennessee': 3,
    'hennessey': 3,
    'hennessy': 3,
    'henney': 2,
    'hennick': 2,
    'hennig': 2,
    'hennigan': 3,
    'hennigar': 3,
    'henning': 2,
    'henninger': 3,
    'hennings': 2,
    'henningsen': 3,
    'hennington': 3,
    'hennis': 2,
    'hennon': 2,
    'henpeck': 2,
    'henpecked': 2,
    'henri': 2,
    'henrich': 2,
    'henrichs': 2,
    'henrichsen': 3,
    'henrick': 2,
    'henricks': 2,
    'henricksen': 3,
    'henrickson': 3,
    'henrie': 3,
    'henrietta': 4,
    'henriette': 3,
    'henrik': 2,
    'henrika': 3,
    'henriksen': 3,
    'henrikson': 3,
    'henrique': 2,
    'henriques': 3,
    'henriquez': 3,
    'henry': 2,
    'hens': 1,
    'hensarling': 3,
    'hensch': 1,
    'henschel': 2,
    'henschen': 2,
    'hense': 1,
    'hensel': 2,
    'hensen': 2,
    'henshaw': 2,
    'hensil': 2,
    'henske': 2,
    'henslee': 2,
    'hensler': 3,
    'hensley': 2,
    'henson': 2,
    'hentges': 2,
    'henthorn': 2,
    'henthorne': 2,
    'hentic': 2,
    'hentoff': 2,
    'henton': 2,
    'hentschel': 2,
    'hentz': 1,
    'henwood': 2,
    'henze': 1,
    'henzel': 2,
    'henzler': 2,
    'heon': 2,
    'heoroico': 5,
    'hep': 1,
    'heparin': 3,
    'hepatic': 3,
    'hepatitis': 4,
    'hepburn': 2,
    'hepfer': 2,
    'hepker': 2,
    'hepler': 2,
    'hepner': 2,
    'hepp': 1,
    'heppe': 1,
    'hepper': 2,
    'heppler': 2,
    'heppner': 2,
    'heptathlon': 3,
    'hepworth': 2,
    'her': 1,
    'hera': 2,
    'heradia': 4,
    'herald': 2,
    'heralded': 3,
    'heraldic': 3,
    'heralding': 3,
    'heraldry': 3,
    'heralds': 2,
    'herb': 1,
    'herbaceous': 3,
    'herbal': 2,
    'herbalife': 3,
    'herbalist': 3,
    'herbalists': 3,
    'herbarium': 4,
    'herbariums': 4,
    'herbeck': 2,
    'herbel': 2,
    'herber': 2,
    'herberg': 2,
    'herberger': 3,
    'herbers': 2,
    'herbert': 2,
    'herbicide': 3,
    'herbicides': 3,
    'herbie': 2,
    'herbig': 2,
    'herbin': 2,
    'herbison': 3,
    'herbivore': 3,
    'herbivorous': 4,
    'herbold': 2,
    'herbs': 1,
    'herbst': 1,
    'herbster': 2,
    'herceg': 2,
    'hercegovina': 5,
    'herculean': 4,
    'hercules': 3,
    'herczeg': 2,
    'herd': 1,
    'herda': 2,
    'herdal': 2,
    'herded': 2,
    'herder': 2,
    'herders': 2,
    'herding': 2,
    'herdman': 2,
    'herds': 1,
    'herdsmen': 2,
    'herdt': 1,
    'here': 1,
    'hereabout': 3,
    'hereabouts': 3,
    'hereafter': 3,
    'hereby': 2,
    'heredia': 4,
    'hereditary': 5,
    'heredity': 4,
    'hereford': 3,
    'herein': 2,
    'herendeen': 2,
    'heresy': 3,
    'heretic': 3,
    'heretical': 4,
    'heretofore': 3,
    'herewith': 2,
    'herford': 2,
    'herfurth': 2,
    'hergert': 2,
    'herget': 2,
    'hergott': 2,
    'herin': 2,
    'hering': 2,
    'heringer': 3,
    'herington': 3,
    'heritable': 4,
    'heritage': 3,
    'heritages': 4,
    'herk': 1,
    'herkert': 2,
    'herl': 1,
    'herlihy': 3,
    'herling': 2,
    'herlong': 2,
    'herm': 1,
    'herman': 2,
    'hermance': 2,
    'hermann': 2,
    'hermanns': 2,
    'hermans': 2,
    'hermansen': 3,
    'hermanson': 3,
    'hermaphrodite': 4,
    'hermaphroditic': 5,
    'hermenio': 4,
    'hermes': 2,
    'hermetically': 5,
    'hermia': 3,
    'hermias': 3,
    'hermida': 3,
    'hermie': 2,
    'hermina': 3,
    'hermine': 2,
    'herminia': 4,
    'herminie': 3,
    'hermit': 2,
    'hermitage': 3,
    'hermits': 2,
    'hermon': 2,
    'hermosa': 3,
    'hermosillo': 4,
    'herms': 1,
    'hermsen': 2,
    'hern': 1,
    'hernan': 2,
    'hernandes': 2,
    'hernandez': 3,
    'hernando': 3,
    'herndon': 2,
    'herne': 1,
    'herner': 2,
    'hernia': 3,
    'herniate': 3,
    'herniates': 3,
    'hernon': 2,
    'hero': 2,
    'herod': 2,
    'heroes': 2,
    'heroic': 3,
    'heroically': 4,
    'heroics': 3,
    'heroin': 3,
    'heroine': 3,
    'heroines': 3,
    'heroism': 4,
    'heroize': 3,
    'heroized': 3,
    'herold': 2,
    'heron': 2,
    'herons': 2,
    'heros': 2,
    'heroux': 2,
    'herpes': 2,
    'herr': 1,
    'herre': 1,
    'herreid': 2,
    'herrell': 2,
    'herren': 2,
    'herrera': 3,
    'herrero': 3,
    'herrhausen': 3,
    'herriage': 3,
    'herrick': 2,
    'herridge': 2,
    'herrig': 2,
    'herriman': 3,
    'herrin': 2,
    'herring': 2,
    'herrings': 2,
    'herringshaw': 3,
    'herrington': 3,
    'herriott': 3,
    'herrle': 2,
    'herrlinger': 3,
    'herrman': 2,
    'herrmann': 2,
    'herro': 2,
    'herrod': 2,
    'herrold': 2,
    'herron': 2,
    'herronimo': 4,
    'hers': 1,
    'hersant': 2,
    'hersch': 1,
    'herschel': 2,
    'herschell': 2,
    'herschensohn': 3,
    'herscu': 2,
    'herself': 2,
    'hersey': 2,
    'hersh': 1,
    'hershberger': 3,
    'hershey': 2,
    'hershiser': 3,
    'hershkowitz': 3,
    'hershman': 2,
    'hershner': 2,
    'herskovitz': 3,
    'herskowitz': 3,
    'hersman': 2,
    'hersom': 2,
    'herson': 2,
    'herst': 1,
    'hert': 1,
    'herta': 2,
    'hertel': 2,
    'hertenstein': 3,
    'herter': 2,
    'hertha': 2,
    'hertig': 2,
    'herting': 2,
    'hertlein': 2,
    'hertog': 2,
    'hertz': 1,
    'hertzberg': 2,
    'hertzenleben': 4,
    'hertzler': 3,
    'hertzog': 2,
    'herve': 1,
    'hervey': 2,
    'herwick': 2,
    'herwig': 2,
    'herwitz': 2,
    'heryana': 3,
    'herz': 1,
    'herzberg': 2,
    'herzberger': 3,
    'herzegovina': 5,
    'herzer': 2,
    'herzfeld': 2,
    'herzig': 2,
    'herzing': 2,
    'herzlinger': 3,
    'herzog': 2,
    'hesch': 1,
    'heseltine': 3,
    'heselton': 3,
    'hesheng': 2,
    'hesik': 2,
    'hesiod': 3,
    'hesitancy': 4,
    'hesitant': 3,
    'hesitantly': 4,
    'hesitate': 3,
    'hesitated': 4,
    'hesitates': 3,
    'hesitating': 4,
    'hesitation': 4,
    'hesitations': 4,
    'hesketh': 2,
    'heskett': 2,
    'heslep': 2,
    'hesler': 3,
    'heslin': 2,
    'heslop': 2,
    'hesper': 2,
    'hespera': 3,
    'hess': 1,
    'hesse': 1,
    'hessel': 2,
    'hesseltine': 3,
    'hesser': 2,
    'hessian': 2,
    'hessing': 2,
    'hessinger': 3,
    'hession': 2,
    'hessite': 2,
    'hessler': 2,
    'hessling': 3,
    'hesson': 2,
    'hesston': 2,
    'hestand': 2,
    'hester': 2,
    'hesther': 2,
    'hestia': 3,
    'heston': 2,
    'heterocercal': 5,
    'heterodox': 4,
    'heterodoxy': 5,
    'heterodyne': 4,
    'heterogeneity': 7,
    'heterogeneous': 5,
    'heterosexual': 6,
    'heterosexuality': 8,
    'heterosexuals': 6,
    'heterosis': 4,
    'heterosporous': 5,
    'heterotrophic': 5,
    'heterozygous': 5,
    'heth': 1,
    'hetherington': 4,
    'hetland': 2,
    'hetman': 2,
    'hetrick': 2,
    'hett': 1,
    'hettel': 2,
    'hetti': 2,
    'hettick': 2,
    'hettie': 2,
    'hettler': 2,
    'hettrick': 2,
    'hetty': 2,
    'hetu': 2,
    'hetz': 1,
    'hetzel': 2,
    'hetzer': 2,
    'hetzler': 2,
    'heuberger': 3,
    'heublein': 2,
    'heuer': 2,
    'heuerman': 3,
    'heuermann': 3,
    'heuman': 2,
    'heumann': 2,
    'heun': 1,
    'heupel': 2,
    'heuring': 2,
    'heuristic': 3,
    'heuristics': 3,
    'heusen': 2,
    'heuser': 2,
    'heusser': 2,
    'hevener': 3,
    'heverly': 3,
    'hevey': 2,
    'hevia': 3,
    'hevner': 2,
    'hew': 1,
    'heward': 2,
    'hewe': 1,
    'hewell': 2,
    'hewer': 2,
    'hewes': 1,
    'hewett': 2,
    'hewey': 2,
    'hewing': 2,
    'hewins': 2,
    'hewitt': 2,
    'hewler': 2,
    'hewlett': 2,
    'hewn': 1,
    'hews': 1,
    'hewson': 2,
    'hex': 1,
    'hexachlorophene': 5,
    'hexagon': 3,
    'hexagonal': 4,
    'hexane': 2,
    'hexcel': 2,
    'hext': 1,
    'hey': 1,
    'heyboer': 3,
    'heyd': 1,
    'heyday': 2,
    'heyde': 1,
    'heyden': 2,
    'heydon': 2,
    'heydt': 1,
    'heye': 1,
    'heyen': 1,
    'heyer': 2,
    'heying': 2,
    'heyl': 1,
    'heyman': 2,
    'heymann': 2,
    'heyn': 1,
    'heyne': 1,
    'heys': 1,
    'heyse': 1,
    'heyser': 2,
    'heyward': 2,
    'heywood': 2,
    'hezbollah': 3,
    'hezbullah': 3,
    'hfdf': 4,
    'hgh': 3,
    'hi': 1,
    'hiaa': 4,
    'hiaasen': 3,
    'hialeah': 4,
    'hiam': 2,
    'hiatt': 2,
    'hiatus': 3,
    'hiawatha': 4,
    'hibaaq': 2,
    'hibachi': 3,
    'hibbard': 2,
    'hibben': 2,
    'hibberd': 2,
    'hibbert': 2,
    'hibbett': 2,
    'hibbing': 2,
    'hibbitts': 2,
    'hibbler': 2,
    'hibbs': 1,
    'hibdon': 2,
    'hibernate': 3,
    'hibernation': 4,
    'hibernia': 4,
    'hibler': 2,
    'hibma': 2,
    'hibner': 2,
    'hibor': 2,
    'hibshman': 2,
    'hiccough': 2,
    'hiccoughs': 2,
    'hiccup': 2,
    'hiccups': 2,
    'hice': 1,
    'hicfa': 2,
    'hichens': 2,
    'hick': 1,
    'hickam': 2,
    'hickcox': 2,
    'hickel': 2,
    'hicken': 2,
    'hickerson': 3,
    'hickey': 2,
    'hickle': 2,
    'hicklin': 2,
    'hickling': 2,
    'hickman': 2,
    'hickmon': 2,
    'hickok': 2,
    'hickories': 3,
    'hickory': 3,
    'hickox': 2,
    'hicks': 1,
    'hickson': 2,
    'hicksville': 2,
    'hid': 1,
    'hidalgo': 3,
    'hidden': 2,
    'hide': 1,
    'hideaki': 4,
    'hideaway': 3,
    'hidebound': 2,
    'hideo': 3,
    'hideous': 3,
    'hideously': 4,
    'hideout': 2,
    'hideouts': 2,
    'hider': 2,
    'hides': 1,
    'hiding': 2,
    'hidy': 2,
    'hieb': 1,
    'hieber': 2,
    'hiebert': 2,
    'hiegel': 2,
    'hiems': 1,
    'hiemstra': 2,
    'hier': 2,
    'hierarchical': 4,
    'hierarchies': 3,
    'hierarchy': 4,
    'hierholzer': 3,
    'hieroglyph': 3,
    'hieroglyphic': 4,
    'hieroglyphics': 4,
    'hieroglyphs': 3,
    'hiers': 1,
    'hiestand': 2,
    'hiester': 3,
    'hietala': 3,
    'hietpas': 2,
    'hiett': 1,
    'higa': 2,
    'higashi': 3,
    'higbee': 2,
    'higbie': 2,
    'higby': 2,
    'higdon': 2,
    'higgason': 3,
    'higgenbotham': 4,
    'higgens': 2,
    'higgerson': 3,
    'higginbotham': 4,
    'higginbottom': 4,
    'higgins': 2,
    'higginson': 3,
    'higgs': 1,
    'high': 1,
    'higham': 2,
    'highberger': 3,
    'highboy': 2,
    'highbrow': 2,
    'highbrows': 2,
    'higher': 2,
    'highest': 2,
    'highfalutin': 4,
    'highfield': 2,
    'highfill': 2,
    'highflier': 3,
    'highfliers': 3,
    'highflying': 3,
    'highland': 2,
    'highlander': 3,
    'highlanders': 3,
    'highlands': 2,
    'highley': 2,
    'highlight': 2,
    'highlighted': 3,
    'highlighting': 3,
    'highlights': 2,
    'highly': 2,
    'highman': 2,
    'highness': 2,
    'highosin': 3,
    'highrise': 2,
    'highrises': 3,
    'highs': 1,
    'highschool': 2,
    'highschools': 2,
    'highsmith': 2,
    'highspeed': 2,
    'hight': 1,
    'hightech': 2,
    'hightower': 3,
    'highway': 2,
    'highways': 2,
    'highyield': 2,
    'higinbotham': 4,
    'higley': 2,
    'higman': 2,
    'hignight': 2,
    'hignite': 2,
    'higuchi': 3,
    'higuera': 3,
    'hijack': 2,
    'hijacked': 2,
    'hijacker': 3,
    'hijackers': 3,
    'hijacking': 3,
    'hijackings': 3,
    'hijinks': 2,
    'hike': 1,
    'hiked': 1,
    'hiker': 2,
    'hikers': 2,
    'hikes': 1,
    'hiking': 2,
    'hiland': 2,
    'hilaria': 4,
    'hilario': 4,
    'hilarious': 4,
    'hilariously': 5,
    'hilarity': 4,
    'hilary': 3,
    'hilb': 1,
    'hilberg': 2,
    'hilbert': 2,
    'hilborn': 2,
    'hilbun': 2,
    'hilburn': 2,
    'hild': 1,
    'hilda': 2,
    'hilde': 1,
    'hildebran': 3,
    'hildebrand': 3,
    'hildebrandt': 3,
    'hildebrant': 3,
    'hildegard': 3,
    'hildegarde': 3,
    'hildegardes': 3,
    'hildemar': 3,
    'hilden': 2,
    'hildenbrand': 3,
    'hilder': 2,
    'hilderbrand': 3,
    'hilderbrandt': 3,
    'hildie': 2,
    'hilditch': 2,
    'hildreth': 2,
    'hildum': 2,
    'hildy': 2,
    'hile': 1,
    'hileman': 2,
    'hilemon': 2,
    'hiler': 2,
    'hiles': 1,
    'hiley': 2,
    'hilfiger': 3,
    'hilfiker': 3,
    'hilgart': 2,
    'hilgeman': 2,
    'hilgenberg': 3,
    'hilgendorf': 3,
    'hilger': 2,
    'hilgers': 2,
    'hilgert': 2,
    'hilinski': 3,
    'hilke': 2,
    'hilker': 2,
    'hill': 1,
    'hilla': 2,
    'hillard': 2,
    'hillary': 3,
    'hillas': 2,
    'hillbillies': 3,
    'hillbilly': 3,
    'hillcrest': 2,
    'hille': 1,
    'hilleary': 3,
    'hillebrand': 3,
    'hillegas': 3,
    'hillegass': 3,
    'hillel': 2,
    'hillen': 2,
    'hillenbrand': 3,
    'hillenburg': 3,
    'hiller': 2,
    'hillerman': 3,
    'hillery': 3,
    'hillesheim': 3,
    'hillestad': 3,
    'hilley': 2,
    'hillhaven': 3,
    'hillhouse': 2,
    'hillian': 2,
    'hillians': 2,
    'hilliard': 2,
    'hillier': 3,
    'hilligoss': 3,
    'hilliker': 3,
    'hillin': 2,
    'hilling': 2,
    'hillis': 2,
    'hillman': 2,
    'hillmann': 2,
    'hillmer': 2,
    'hillock': 2,
    'hills': 1,
    'hillsboro': 3,
    'hillsborough': 3,
    'hillsdale': 2,
    'hillsdown': 2,
    'hillside': 2,
    'hillsides': 2,
    'hillsman': 2,
    'hillson': 2,
    'hillstrom': 2,
    'hilltop': 2,
    'hilltops': 2,
    'hilly': 2,
    'hillyard': 2,
    'hillyer': 3,
    'hilma': 2,
    'hilmer': 2,
    'hilmes': 1,
    'hilo': 2,
    'hilpert': 2,
    'hilsabeck': 3,
    'hilscher': 2,
    'hilsinger': 3,
    'hilsman': 2,
    'hilson': 2,
    'hilt': 1,
    'hiltner': 2,
    'hilton': 2,
    'hiltons': 2,
    'hilts': 1,
    'hiltunen': 3,
    'hilty': 2,
    'hiltz': 1,
    'hilyard': 2,
    'hilyer': 3,
    'him': 1,
    'himalaya': 4,
    'himalayan': 4,
    'himalayas': 4,
    'hime': 1,
    'himebaugh': 3,
    'himel': 2,
    'himes': 1,
    'himmel': 2,
    'himmelberger': 4,
    'himmelfarb': 3,
    'himmelsbach': 3,
    'himmelstein': 3,
    'himmler': 2,
    'himont': 2,
    'himself': 2,
    'hinch': 1,
    'hinchcliff': 2,
    'hinchcliffe': 2,
    'hinchey': 2,
    'hinchliffe': 2,
    'hinchman': 2,
    'hinck': 1,
    'hinckley': 2,
    'hind': 1,
    'hinde': 1,
    'hindelong': 3,
    'hinder': 2,
    'hindered': 2,
    'hinderer': 3,
    'hindering': 3,
    'hinderliter': 4,
    'hinderman': 3,
    'hinders': 2,
    'hindes': 1,
    'hindi': 2,
    'hindle': 2,
    'hindley': 2,
    'hindman': 2,
    'hindquarter': 3,
    'hindquarters': 3,
    'hindrance': 2,
    'hindrances': 3,
    'hinds': 1,
    'hindsight': 2,
    'hindu': 2,
    'hinduism': 4,
    'hindus': 2,
    'hindustan': 3,
    'hine': 1,
    'hinebaugh': 3,
    'hineline': 3,
    'hinely': 2,
    'hineman': 2,
    'hiner': 2,
    'hinerman': 3,
    'hines': 1,
    'hinesley': 3,
    'hiney': 2,
    'hing': 1,
    'hinge': 1,
    'hinged': 1,
    'hinger': 2,
    'hinges': 2,
    'hingham': 2,
    'hingis': 2,
    'hingle': 2,
    'hingst': 1,
    'hingston': 2,
    'hink': 1,
    'hinkel': 2,
    'hinkelman': 3,
    'hinkle': 2,
    'hinkley': 2,
    'hinkson': 2,
    'hinman': 2,
    'hinmen': 2,
    'hinn': 1,
    'hinnant': 2,
    'hinnenkamp': 3,
    'hinners': 2,
    'hinny': 2,
    'hino': 2,
    'hinojos': 3,
    'hinojosa': 4,
    'hinote': 2,
    'hinrichs': 2,
    'hinrichsen': 3,
    'hinsch': 1,
    'hinsdale': 2,
    'hinshaw': 2,
    'hinsley': 2,
    'hinson': 2,
    'hint': 1,
    'hinted': 2,
    'hinterland': 3,
    'hinterlands': 3,
    'hinting': 2,
    'hinton': 2,
    'hints': 1,
    'hintz': 1,
    'hintze': 1,
    'hinz': 1,
    'hinzack': 2,
    'hinze': 1,
    'hinzman': 2,
    'hiott': 2,
    'hip': 1,
    'hipbone': 2,
    'hipbones': 2,
    'hipkins': 2,
    'hipolito': 4,
    'hipp': 1,
    'hippe': 1,
    'hippen': 2,
    'hippensteel': 3,
    'hipper': 2,
    'hippert': 2,
    'hippest': 2,
    'hippie': 2,
    'hippies': 2,
    'hipple': 2,
    'hippler': 2,
    'hippo': 2,
    'hippocampus': 4,
    'hippocrates': 3,
    'hippocratic': 4,
    'hippodrome': 3,
    'hippolytus': 4,
    'hippopotamus': 5,
    'hippopotamuses': 6,
    'hippos': 2,
    'hipps': 1,
    'hips': 1,
    'hipsher': 2,
    'hipskind': 2,
    'hipwell': 2,
    'hirabayashi': 5,
    'hiradin': 3,
    'hirai': 3,
    'hiram': 2,
    'hirano': 3,
    'hirata': 3,
    'hirayama': 4,
    'hird': 1,
    'hire': 2,
    'hired': 2,
    'hires': 2,
    'hiriart': 3,
    'hiring': 2,
    'hirings': 2,
    'hirn': 1,
    'hiro': 2,
    'hiroaki': 4,
    'hirohito': 4,
    'hiromasa': 4,
    'hirons': 2,
    'hirosakamoki': 6,
    'hirosakima': 5,
    'hirose': 3,
    'hiroshi': 3,
    'hiroshima': 4,
    'hirota': 3,
    'hiroyuki': 4,
    'hirsch': 1,
    'hirschberg': 2,
    'hirschfeld': 2,
    'hirschfield': 2,
    'hirschhorn': 2,
    'hirschi': 2,
    'hirschman': 2,
    'hirschmann': 2,
    'hirschy': 2,
    'hirsh': 1,
    'hirshberg': 2,
    'hirshfield': 2,
    'hirshhorn': 2,
    'hirshman': 2,
    'hirst': 1,
    'hirsute': 2,
    'hirt': 1,
    'hirth': 1,
    'hirtle': 2,
    'hirtz': 1,
    'hirulog': 3,
    'hirzel': 2,
    'his': 1,
    'hisada': 3,
    'hisao': 3,
    'hisaw': 2,
    'hiscock': 2,
    'hiscox': 2,
    'hise': 1,
    'hisel': 2,
    'hiser': 2,
    'hisey': 2,
    'hisham': 2,
    'hisle': 2,
    'hislop': 2,
    'hispanic': 3,
    'hispanics': 3,
    'hispano': 3,
    'hispanoil': 3,
    'hiss': 1,
    'hissed': 1,
    'hisself': 2,
    'hisses': 2,
    'hissing': 2,
    'hissong': 2,
    'histadrut': 3,
    'histamine': 3,
    'histidine': 3,
    'histogram': 3,
    'histograms': 3,
    'histology': 4,
    'historian': 4,
    'historians': 4,
    'historic': 3,
    'historical': 4,
    'historically': 5,
    'histories': 3,
    'historiography': 6,
    'history': 3,
    'histrionic': 4,
    'histrionics': 4,
    'hit': 1,
    'hitachi': 3,
    'hitch': 1,
    'hitchcock': 2,
    'hitched': 1,
    'hitchens': 2,
    'hitches': 2,
    'hitchhike': 2,
    'hitchhiked': 2,
    'hitchhiker': 3,
    'hitchhikers': 3,
    'hitchhikes': 2,
    'hitchhiking': 3,
    'hitching': 2,
    'hitchings': 2,
    'hitchins': 2,
    'hitchman': 2,
    'hitchner': 2,
    'hite': 1,
    'hites': 1,
    'hither': 2,
    'hitherto': 3,
    'hitler': 2,
    'hitman': 2,
    'hitoshi': 3,
    'hits': 1,
    'hitschler': 2,
    'hitsman': 2,
    'hitson': 2,
    'hitt': 1,
    'hitter': 2,
    'hitters': 2,
    'hitting': 2,
    'hittite': 2,
    'hittle': 2,
    'hittner': 2,
    'hitty': 2,
    'hitz': 1,
    'hitzeman': 2,
    'hive': 1,
    'hively': 2,
    'hives': 1,
    'hiway': 2,
    'hix': 1,
    'hixenbaugh': 3,
    'hixon': 2,
    'hixson': 2,
    'hizbollah': 3,
    'hizer': 2,
    'hjelm': 2,
    'hjerpe': 2,
    'hjort': 2,
    'hlad': 1,
    'hladik': 2,
    'hladky': 2,
    'hlavac': 2,
    'hlavacek': 3,
    'hlavaty': 3,
    'hmmm': 1,
    'hmong': 1,
    'hnat': 1,
    'ho': 1,
    'hoadley': 2,
    'hoag': 1,
    'hoagie': 2,
    'hoagland': 2,
    'hoaglin': 2,
    'hoaglund': 2,
    'hoagy': 2,
    'hoak': 1,
    'hoang': 1,
    'hoar': 1,
    'hoard': 1,
    'hoarded': 2,
    'hoarding': 2,
    'hoards': 1,
    'hoare': 1,
    'hoarse': 1,
    'hoarseness': 2,
    'hoary': 2,
    'hoax': 1,
    'hoaxes': 2,
    'hob': 1,
    'hoback': 2,
    'hoban': 2,
    'hobart': 2,
    'hobaugh': 2,
    'hobbes': 2,
    'hobbie': 2,
    'hobbies': 2,
    'hobbing': 2,
    'hobbins': 2,
    'hobble': 2,
    'hobbled': 2,
    'hobbles': 2,
    'hobbling': 3,
    'hobbs': 1,
    'hobby': 2,
    'hobbyist': 3,
    'hobbyists': 3,
    'hobday': 2,
    'hobdy': 2,
    'hoben': 2,
    'hoberg': 2,
    'hoberman': 3,
    'hobert': 2,
    'hobgood': 2,
    'hobin': 2,
    'hoblit': 2,
    'hobnail': 2,
    'hobnob': 2,
    'hobnobbing': 3,
    'hobo': 2,
    'hoboes': 2,
    'hoboken': 3,
    'hobs': 1,
    'hobsbawm': 2,
    'hobson': 2,
    'hoc': 1,
    'hocevar': 3,
    'hoch': 1,
    'hochberg': 2,
    'hochbrueckner': 3,
    'hochenheim': 3,
    'hochhalter': 3,
    'hochhauser': 3,
    'hochman': 2,
    'hochmuth': 2,
    'hochstatter': 3,
    'hochstedler': 4,
    'hochstein': 2,
    'hochstetler': 4,
    'hochtief': 2,
    'hock': 1,
    'hockaday': 3,
    'hockberg': 2,
    'hockenberry': 4,
    'hockenbury': 4,
    'hockensmith': 3,
    'hocker': 2,
    'hockersmith': 3,
    'hockett': 2,
    'hockey': 2,
    'hockin': 2,
    'hocking': 2,
    'hockley': 2,
    'hockman': 2,
    'hockney': 2,
    'hoctor': 2,
    'hocus': 2,
    'hocutt': 2,
    'hodak': 2,
    'hodapp': 2,
    'hodde': 1,
    'hodder': 2,
    'hodding': 2,
    'hoddur': 2,
    'hodel': 2,
    'hodes': 1,
    'hodgdon': 2,
    'hodge': 1,
    'hodgen': 2,
    'hodgens': 2,
    'hodgepodge': 2,
    'hodges': 2,
    'hodgin': 2,
    'hodgins': 2,
    'hodgkin': 2,
    'hodgkins': 2,
    'hodgkinson': 3,
    'hodgkiss': 2,
    'hodgman': 2,
    'hodgson': 2,
    'hodkinson': 3,
    'hodnett': 2,
    'hodo': 2,
    'hodsdon': 2,
    'hodson': 2,
    'hoe': 1,
    'hoechst': 1,
    'hoeck': 1,
    'hoecker': 2,
    'hoedown': 2,
    'hoefer': 2,
    'hoeffner': 2,
    'hoefle': 2,
    'hoefler': 3,
    'hoeflich': 2,
    'hoefling': 3,
    'hoefs': 1,
    'hoeft': 1,
    'hoeg': 1,
    'hoeger': 2,
    'hoehn': 1,
    'hoehne': 1,
    'hoeing': 2,
    'hoek': 1,
    'hoeksema': 3,
    'hoekstra': 2,
    'hoel': 1,
    'hoell': 1,
    'hoelle': 1,
    'hoelscher': 2,
    'hoelterhoff': 3,
    'hoelting': 2,
    'hoelzel': 2,
    'hoelzer': 2,
    'hoen': 1,
    'hoene': 2,
    'hoener': 2,
    'hoenig': 2,
    'hoeper': 2,
    'hoepfner': 2,
    'hoepner': 2,
    'hoeppner': 2,
    'hoerig': 2,
    'hoerner': 2,
    'hoerr': 1,
    'hoes': 1,
    'hoesch': 1,
    'hoeschen': 2,
    'hoesly': 2,
    'hoeveler': 2,
    'hoevet': 2,
    'hoey': 2,
    'hof': 1,
    'hofacker': 3,
    'hofbauer': 3,
    'hofer': 2,
    'hoff': 1,
    'hoffa': 2,
    'hoffacker': 3,
    'hoffart': 2,
    'hoffarth': 2,
    'hoffecker': 3,
    'hoffenberg': 3,
    'hoffer': 2,
    'hofferber': 3,
    'hoffert': 2,
    'hoffitz': 2,
    'hoffler': 3,
    'hoffman': 2,
    'hoffmann': 2,
    'hoffmaster': 3,
    'hoffmeier': 3,
    'hoffmeister': 3,
    'hoffmeyer': 3,
    'hoffner': 2,
    'hoffpauir': 3,
    'hofi': 2,
    'hofland': 2,
    'hofler': 2,
    'hofman': 2,
    'hofmann': 2,
    'hofmeister': 3,
    'hofrichter': 3,
    'hofstad': 2,
    'hofstetter': 3,
    'hofstra': 2,
    'hog': 1,
    'hogan': 2,
    'hogans': 2,
    'hoganson': 3,
    'hogarth': 2,
    'hogarty': 3,
    'hogberg': 2,
    'hoge': 1,
    'hogeland': 3,
    'hogen': 2,
    'hogenson': 3,
    'hoger': 2,
    'hogg': 1,
    'hoggan': 2,
    'hoggard': 2,
    'hoggart': 2,
    'hoggatt': 2,
    'hogge': 1,
    'hogging': 2,
    'hogland': 2,
    'hogle': 2,
    'hoglund': 2,
    'hoglunds': 2,
    'hognose': 2,
    'hogrefe': 2,
    'hogs': 1,
    'hogsed': 1,
    'hogsett': 2,
    'hogston': 2,
    'hogue': 1,
    'hogwash': 2,
    'hogwood': 2,
    'hoh': 1,
    'hoheisel': 3,
    'hohenberger': 4,
    'hohensee': 3,
    'hohenstein': 3,
    'hohl': 1,
    'hohler': 2,
    'hohlt': 1,
    'hohman': 2,
    'hohmann': 2,
    'hohn': 1,
    'hohner': 2,
    'hohnstein': 2,
    'hoho': 2,
    'hohorst': 2,
    'hohos': 2,
    'hohum': 2,
    'hoi': 1,
    'hoiland': 2,
    'hoilman': 2,
    'hoisington': 3,
    'hoist': 1,
    'hoisted': 2,
    'hoisting': 2,
    'hoists': 1,
    'hoit': 1,
    'hoity': 2,
    'hoium': 3,
    'hojnacki': 3,
    'hok': 1,
    'hokanson': 3,
    'hoke': 1,
    'hokenson': 3,
    'hokey': 2,
    'hokkaido': 3,
    'hokum': 2,
    'hokuriku': 4,
    'hola': 2,
    'holaday': 3,
    'holahan': 3,
    'holan': 2,
    'holaway': 3,
    'holbein': 2,
    'holben': 2,
    'holberg': 2,
    'holbert': 2,
    'holbrook': 2,
    'holbrooke': 2,
    'holbrooks': 2,
    'holck': 1,
    'holcomb': 2,
    'holcombe': 2,
    'hold': 1,
    'holda': 2,
    'holdaway': 3,
    'holde': 1,
    'holdeman': 2,
    'holden': 2,
    'holder': 2,
    'holderbank': 3,
    'holderbaum': 3,
    'holderby': 3,
    'holderfield': 3,
    'holderman': 3,
    'holderness': 3,
    'holders': 2,
    'holdfast': 2,
    'holding': 2,
    'holdings': 2,
    'holdman': 2,
    'holdorf': 2,
    'holdout': 2,
    'holdouts': 2,
    'holdover': 3,
    'holdovers': 3,
    'holdren': 3,
    'holdridge': 2,
    'holds': 1,
    'holdsworth': 2,
    'holdup': 2,
    'holdups': 2,
    'hole': 1,
    'holecek': 3,
    'holed': 1,
    'holeman': 2,
    'holen': 2,
    'holes': 1,
    'holewinski': 4,
    'holford': 2,
    'holgate': 2,
    'holgerson': 3,
    'holguin': 2,
    'holian': 3,
    'holick': 2,
    'holiday': 3,
    'holidays': 3,
    'holidaysburg': 4,
    'holien': 3,
    'holier': 3,
    'holiest': 3,
    'holifield': 3,
    'holihan': 3,
    'holik': 2,
    'holiman': 3,
    'holiness': 3,
    'holing': 2,
    'holistic': 3,
    'holl': 1,
    'hollabaugh': 3,
    'holladay': 3,
    'hollan': 2,
    'holland': 2,
    'hollander': 3,
    'hollanders': 3,
    'hollands': 2,
    'hollandsworth': 3,
    'hollar': 2,
    'hollars': 2,
    'hollatz': 2,
    'hollaway': 3,
    'holle': 1,
    'holleman': 2,
    'hollen': 2,
    'hollenbach': 3,
    'hollenback': 3,
    'hollenbaugh': 3,
    'hollenbeck': 3,
    'hollenberg': 3,
    'hollender': 3,
    'hollenkamp': 3,
    'holler': 2,
    'holleran': 3,
    'hollerbach': 3,
    'hollered': 2,
    'hollering': 3,
    'hollern': 2,
    'hollers': 2,
    'hollett': 2,
    'holley': 2,
    'hollibaugh': 3,
    'hollick': 2,
    'holliday': 3,
    'hollie': 2,
    'hollier': 3,
    'hollies': 2,
    'hollifield': 3,
    'holliman': 3,
    'hollimon': 3,
    'hollin': 2,
    'holling': 2,
    'hollinger': 3,
    'hollings': 2,
    'hollingshead': 3,
    'hollingsworth': 3,
    'hollingworth': 3,
    'hollins': 2,
    'hollinshead': 3,
    'hollinsworth': 3,
    'hollis': 2,
    'hollister': 3,
    'holliston': 3,
    'hollman': 2,
    'hollmann': 2,
    'hollo': 2,
    'hollobaugh': 3,
    'hollom': 2,
    'holloman': 3,
    'hollomon': 3,
    'hollon': 2,
    'hollopeter': 4,
    'holloran': 3,
    'hollow': 2,
    'holloway': 3,
    'hollowed': 2,
    'hollowell': 3,
    'hollowing': 3,
    'hollows': 2,
    'hollstein': 2,
    'holly': 2,
    'hollyfield': 3,
    'hollyhead': 3,
    'hollyhock': 3,
    'hollyhocks': 3,
    'hollywood': 3,
    'holm': 1,
    'holman': 2,
    'holmberg': 2,
    'holmdale': 2,
    'holme': 1,
    'holmen': 2,
    'holmer': 2,
    'holmes': 1,
    'holmgren': 2,
    'holmium': 3,
    'holmlund': 2,
    'holmquest': 2,
    'holmquist': 2,
    'holmstrom': 2,
    'holness': 2,
    'holocaust': 3,
    'hologram': 3,
    'holograms': 3,
    'holographic': 4,
    'holohan': 3,
    'holquin': 2,
    'holroyd': 2,
    'holsapple': 3,
    'holscher': 2,
    'holsclaw': 2,
    'holsey': 2,
    'holshouser': 3,
    'holsinger': 3,
    'holsomback': 3,
    'holsonback': 3,
    'holsopple': 3,
    'holst': 1,
    'holstad': 2,
    'holste': 1,
    'holstead': 2,
    'holstein': 2,
    'holsten': 2,
    'holster': 2,
    'holstine': 2,
    'holston': 2,
    'holstrom': 2,
    'holsworth': 2,
    'holt': 1,
    'holtan': 2,
    'holte': 1,
    'holten': 2,
    'holter': 2,
    'holterman': 3,
    'holthaus': 2,
    'holthus': 2,
    'holtkamp': 2,
    'holtman': 2,
    'holtmann': 2,
    'holton': 2,
    'holtorf': 2,
    'holtrop': 2,
    'holtry': 2,
    'holts': 1,
    'holtsclaw': 2,
    'holtz': 1,
    'holtzapple': 3,
    'holtzclaw': 2,
    'holtzer': 2,
    'holtzinger': 3,
    'holtzman': 2,
    'holub': 2,
    'holum': 2,
    'holven': 2,
    'holverson': 3,
    'holvis': 2,
    'holway': 2,
    'holweger': 3,
    'holwerda': 3,
    'holy': 2,
    'holycross': 3,
    'holyfield': 3,
    'holyoak': 3,
    'holyoke': 3,
    'holz': 1,
    'holzapfel': 3,
    'holzer': 2,
    'holzhauer': 3,
    'holzhauser': 3,
    'holzheimer': 3,
    'holzinger': 3,
    'holzman': 2,
    'holzmann': 2,
    'holzschuh': 2,
    'holzstoff': 2,
    'holzwarth': 2,
    'holzworth': 2,
    'hom': 1,
    'homa': 2,
    'homac': 2,
    'homage': 2,
    'homages': 3,
    'homan': 2,
    'homann': 2,
    'homans': 2,
    'homart': 2,
    'hombre': 2,
    'homburg': 2,
    'home': 1,
    'homebound': 2,
    'homeboys': 2,
    'homebuilder': 3,
    'homebuilders': 3,
    'homebuilding': 3,
    'homebuyer': 3,
    'homebuyers': 3,
    'homecare': 2,
    'homeclub': 2,
    'homecoming': 3,
    'homed': 1,
    'homedco': 3,
    'homefed': 2,
    'homefront': 2,
    'homegrown': 2,
    'homeland': 2,
    'homelands': 2,
    'homeless': 2,
    'homelessness': 3,
    'homelike': 2,
    'homely': 2,
    'homemade': 2,
    'homemaker': 3,
    'homemakers': 3,
    'homemaking': 3,
    'homen': 2,
    'homeopathic': 5,
    'homeopathy': 5,
    'homeostasis': 5,
    'homeostatic': 5,
    'homeowner': 3,
    'homeowners': 3,
    'homeownership': 4,
    'homeporting': 3,
    'homer': 2,
    'homeric': 3,
    'homers': 2,
    'homerun': 2,
    'homeruns': 2,
    'homes': 1,
    'homesick': 2,
    'homesickness': 3,
    'homesley': 2,
    'homespun': 2,
    'homestake': 2,
    'homestate': 2,
    'homestead': 2,
    'homesteaded': 3,
    'homesteader': 3,
    'homesteaders': 3,
    'homesteads': 2,
    'homestretch': 2,
    'hometown': 2,
    'hometowns': 2,
    'homeward': 2,
    'homewood': 2,
    'homework': 2,
    'homeworker': 3,
    'homeworkers': 3,
    'homey': 2,
    'homeyer': 3,
    'homicidal': 4,
    'homicide': 3,
    'homicides': 3,
    'homiletic': 4,
    'homilies': 3,
    'homily': 3,
    'hominem': 3,
    'homing': 2,
    'hominid': 3,
    'hominy': 3,
    'homme': 1,
    'hommel': 2,
    'hommes': 1,
    'homo': 2,
    'homogeneity': 6,
    'homogeneous': 5,
    'homogenization': 6,
    'homogenize': 4,
    'homogenized': 4,
    'homogenous': 4,
    'homogeny': 4,
    'homola': 3,
    'homolka': 3,
    'homologous': 4,
    'homonym': 3,
    'homonyms': 3,
    'homophobia': 5,
    'homophobic': 4,
    'homophone': 3,
    'homophones': 3,
    'homosapien': 5,
    'homosapiens': 5,
    'homosexual': 5,
    'homosexuality': 7,
    'homosexuals': 5,
    'homosporous': 4,
    'homozygote': 4,
    'homozygous': 4,
    'homrich': 2,
    'homs': 1,
    'homsey': 2,
    'homuth': 2,
    'hon': 1,
    'honaker': 3,
    'honan': 2,
    'honasan': 3,
    'honcho': 2,
    'honda': 2,
    'hondas': 2,
    'honderich': 3,
    'hondo': 2,
    'honduran': 3,
    'hondurans': 3,
    'honduras': 3,
    'hone': 1,
    'honea': 3,
    'honeck': 2,
    'honecker': 3,
    'honed': 1,
    'honer': 2,
    'hones': 1,
    'honest': 2,
    'honestly': 3,
    'honestok': 3,
    'honesty': 3,
    'honey': 2,
    'honeybee': 3,
    'honeybees': 3,
    'honeycomb': 3,
    'honeycutt': 3,
    'honeydew': 3,
    'honeyman': 3,
    'honeymoon': 3,
    'honeymooned': 3,
    'honeymooner': 4,
    'honeymooners': 4,
    'honeymoons': 3,
    'honeys': 2,
    'honeysuckle': 4,
    'honeysuckles': 4,
    'honeywell': 3,
    'hong': 1,
    'hongkong': 2,
    'hongwei': 2,
    'honig': 2,
    'honing': 2,
    'honk': 1,
    'honking': 2,
    'honky': 2,
    'honn': 1,
    'honnold': 2,
    'honold': 2,
    'honolulu': 4,
    'honor': 2,
    'honora': 3,
    'honorable': 4,
    'honorably': 4,
    'honoraria': 5,
    'honorarium': 5,
    'honorariums': 5,
    'honorary': 4,
    'honore': 2,
    'honored': 2,
    'honoree': 3,
    'honorees': 3,
    'honoria': 4,
    'honorific': 4,
    'honoring': 3,
    'honors': 2,
    'hons': 1,
    'honsberger': 3,
    'honse': 1,
    'honshu': 2,
    'honsinger': 3,
    'hontz': 1,
    'hoo': 1,
    'hoobler': 2,
    'hooch': 1,
    'hoochie': 2,
    'hoock': 1,
    'hood': 1,
    'hooded': 2,
    'hoodlum': 2,
    'hoodlums': 2,
    'hoods': 1,
    'hoodwink': 2,
    'hoodwinked': 2,
    'hooey': 2,
    'hoof': 1,
    'hoofed': 1,
    'hoofs': 1,
    'hoog': 1,
    'hooge': 1,
    'hoogland': 2,
    'hoogovens': 3,
    'hook': 1,
    'hooke': 1,
    'hooked': 1,
    'hooker': 2,
    'hookers': 2,
    'hooking': 2,
    'hooks': 1,
    'hookup': 2,
    'hookups': 2,
    'hookworm': 2,
    'hookworms': 2,
    'hooky': 2,
    'hoole': 1,
    'hooley': 2,
    'hooligan': 3,
    'hooliganism': 5,
    'hooligans': 3,
    'hoolihan': 3,
    'hoon': 1,
    'hoonah': 2,
    'hoop': 1,
    'hooper': 2,
    'hooperman': 3,
    'hoopes': 1,
    'hoopingarner': 4,
    'hoopla': 2,
    'hoopoe': 2,
    'hoops': 1,
    'hooray': 2,
    'hoos': 1,
    'hoose': 1,
    'hooser': 2,
    'hoosier': 2,
    'hoosiers': 2,
    'hoot': 1,
    'hooted': 2,
    'hooten': 2,
    'hooter': 2,
    'hooters': 2,
    'hootie': 2,
    'hootman': 2,
    'hooton': 2,
    'hoots': 1,
    'hooven': 2,
    'hoover': 2,
    'hooves': 1,
    'hoovler': 2,
    'hop': 1,
    'hopalong': 3,
    'hopburg': 2,
    'hope': 1,
    'hoped': 1,
    'hopeful': 2,
    'hopefully': 3,
    'hopefulness': 3,
    'hopefuls': 2,
    'hopeless': 2,
    'hopelessly': 3,
    'hopelessness': 3,
    'hopes': 1,
    'hopewell': 2,
    'hopf': 1,
    'hopfensperger': 4,
    'hopfer': 2,
    'hopfinger': 3,
    'hopgood': 2,
    'hopi': 2,
    'hoping': 2,
    'hopke': 1,
    'hopkin': 2,
    'hopkins': 2,
    'hopkinson': 3,
    'hopkinton': 3,
    'hopkyns': 2,
    'hopman': 2,
    'hopp': 1,
    'hoppa': 2,
    'hoppe': 1,
    'hopped': 1,
    'hoppel': 2,
    'hopper': 2,
    'hoppers': 2,
    'hoppert': 2,
    'hoppes': 1,
    'hopping': 2,
    'hopple': 2,
    'hopps': 1,
    'hoppy': 2,
    'hops': 1,
    'hopscotch': 2,
    'hopscotched': 2,
    'hopson': 2,
    'hopton': 2,
    'hopwood': 2,
    'hora': 2,
    'horace': 2,
    'horacek': 3,
    'horacia': 4,
    'horacio': 4,
    'horak': 2,
    'horan': 2,
    'horatia': 3,
    'horatian': 3,
    'horatio': 3,
    'horatius': 3,
    'horch': 1,
    'horchow': 2,
    'hord': 1,
    'horde': 1,
    'hordes': 1,
    'horehound': 2,
    'horejsi': 3,
    'horelick': 3,
    'horen': 2,
    'horenstein': 3,
    'horgan': 2,
    'horgavitt': 3,
    'horger': 2,
    'hori': 2,
    'horiguchi': 4,
    'horine': 2,
    'horiuchi': 4,
    'horizon': 3,
    'horizons': 3,
    'horizontal': 4,
    'horizontally': 5,
    'horkey': 2,
    'horky': 2,
    'horlacher': 3,
    'horlick': 2,
    'horman': 2,
    'hormann': 2,
    'hormats': 2,
    'hormel': 2,
    'hormonal': 3,
    'hormone': 2,
    'hormones': 2,
    'hormuz': 2,
    'horn': 1,
    'hornack': 2,
    'hornaday': 3,
    'hornak': 2,
    'hornback': 2,
    'hornbaker': 3,
    'hornbeak': 2,
    'hornbeck': 2,
    'hornberger': 3,
    'hornbill': 2,
    'hornbills': 2,
    'hornblende': 2,
    'hornblower': 3,
    'hornbook': 2,
    'hornbostel': 3,
    'hornbrook': 2,
    'hornbuckle': 3,
    'hornburg': 2,
    'hornby': 2,
    'horne': 1,
    'horned': 1,
    'hornell': 2,
    'horner': 2,
    'hornere': 2,
    'hornet': 2,
    'hornets': 2,
    'horney': 2,
    'hornick': 2,
    'hornig': 2,
    'hornik': 2,
    'horning': 2,
    'hornless': 2,
    'hornlike': 2,
    'hornor': 2,
    'hornpipe': 2,
    'horns': 1,
    'hornsby': 2,
    'hornstein': 2,
    'hornung': 2,
    'horny': 2,
    'hornyak': 2,
    'horoscope': 3,
    'horoscopes': 3,
    'horovitz': 3,
    'horowitz': 3,
    'horr': 1,
    'horrell': 2,
    'horrendous': 3,
    'horrendously': 4,
    'horrible': 3,
    'horribly': 3,
    'horrid': 2,
    'horrific': 3,
    'horrified': 3,
    'horrify': 3,
    'horrifying': 4,
    'horrigan': 3,
    'horrocks': 2,
    'horror': 2,
    'horrors': 2,
    'hors': 1,
    'horsch': 1,
    'horse': 1,
    'horseback': 2,
    'horsed': 1,
    'horseflesh': 2,
    'horsehead': 2,
    'horsely': 2,
    'horseman': 2,
    'horsemanship': 3,
    'horsemen': 2,
    'horseplay': 2,
    'horsepower': 3,
    'horseradish': 3,
    'horses': 2,
    'horseshoe': 2,
    'horseshoes': 2,
    'horsetail': 2,
    'horsetails': 2,
    'horsey': 2,
    'horsfall': 2,
    'horsham': 2,
    'horsing': 2,
    'horsley': 2,
    'horsman': 2,
    'horst': 1,
    'horstman': 2,
    'horstmann': 2,
    'horta': 2,
    'horten': 2,
    'horter': 2,
    'horticultural': 5,
    'horticulture': 4,
    'horticulturist': 5,
    'hortman': 2,
    'horton': 2,
    'horus': 2,
    'horvac': 2,
    'horvat': 2,
    'horvath': 2,
    'horvitz': 2,
    'horwath': 2,
    'horwich': 2,
    'horwitz': 2,
    'hosack': 2,
    'hosakawa': 4,
    'hosch': 1,
    'hose': 1,
    'hosea': 3,
    'hosed': 1,
    'hosek': 2,
    'hoselton': 3,
    'hoses': 2,
    'hosey': 2,
    'hosford': 2,
    'hoshaw': 2,
    'hoshimoto': 4,
    'hosick': 2,
    'hosie': 2,
    'hosiery': 3,
    'hosing': 2,
    'hoskin': 2,
    'hosking': 2,
    'hoskins': 2,
    'hoskinson': 3,
    'hoskyns': 2,
    'hosler': 3,
    'hosley': 2,
    'hosman': 2,
    'hosner': 2,
    'hosni': 2,
    'hosoka': 3,
    'hosokawa': 4,
    'hospice': 2,
    'hospices': 3,
    'hospitable': 4,
    'hospital': 3,
    'hospitality': 5,
    'hospitalization': 6,
    'hospitalizations': 6,
    'hospitalize': 4,
    'hospitalized': 4,
    'hospitals': 3,
    'hoss': 1,
    'hossack': 2,
    'hossain': 2,
    'hossein': 2,
    'hosseini': 4,
    'hossler': 3,
    'host': 1,
    'hostage': 2,
    'hostages': 3,
    'hosted': 2,
    'hostel': 2,
    'hostels': 2,
    'hostench': 2,
    'hoster': 2,
    'hosterman': 3,
    'hostess': 2,
    'hostesses': 3,
    'hostetler': 4,
    'hostetter': 3,
    'hostettler': 4,
    'hostile': 2,
    'hostilities': 4,
    'hostility': 4,
    'hosting': 2,
    'hostler': 2,
    'hosts': 1,
    'hostutler': 4,
    'hot': 1,
    'hotaling': 3,
    'hotard': 2,
    'hotbed': 2,
    'hotbeds': 2,
    'hotcake': 2,
    'hotcakes': 2,
    'hotchkin': 2,
    'hotchkiss': 2,
    'hotdog': 2,
    'hotdogs': 2,
    'hote': 1,
    'hotel': 2,
    'hotelier': 3,
    'hoteliers': 3,
    'hotels': 2,
    'hoten': 2,
    'hoth': 1,
    'hothouse': 2,
    'hothouses': 3,
    'hotlanta': 3,
    'hotline': 2,
    'hotlines': 2,
    'hotly': 2,
    'hots': 1,
    'hotshot': 2,
    'hotshots': 2,
    'hott': 1,
    'hottel': 2,
    'hottelet': 2,
    'hottenstein': 3,
    'hotter': 2,
    'hottest': 2,
    'hottinger': 3,
    'hottle': 2,
    'hottman': 2,
    'hotwire': 3,
    'hotwired': 3,
    'hotz': 1,
    'hotze': 1,
    'hou': 1,
    'houchen': 2,
    'houchens': 2,
    'houchin': 2,
    'houchins': 2,
    'houck': 1,
    'houdaille': 2,
    'houde': 1,
    'houdek': 2,
    'houdeshell': 3,
    'houdini': 3,
    'houff': 1,
    'houg': 1,
    'houge': 1,
    'hougen': 2,
    'hough': 1,
    'hougham': 2,
    'houghland': 2,
    'houghs': 1,
    'houghtaling': 3,
    'houghton': 2,
    'hougland': 2,
    'houk': 1,
    'houle': 1,
    'houlihan': 3,
    'houlton': 2,
    'hound': 1,
    'hounded': 2,
    'hounding': 2,
    'hounds': 1,
    'hounshell': 2,
    'houp': 1,
    'houpt': 1,
    'hour': 2,
    'hourglass': 3,
    'hourglasses': 4,
    'hourigan': 3,
    'hourihan': 3,
    'hourlong': 2,
    'hourly': 2,
    'hours': 2,
    'housand': 2,
    'housden': 2,
    'house': 1,
    'houseal': 2,
    'houseboat': 2,
    'houseboats': 2,
    'housebroken': 3,
    'housecleaning': 3,
    'housed': 1,
    'houseful': 2,
    'houseguest': 2,
    'houseguests': 2,
    'household': 2,
    'householder': 3,
    'householders': 3,
    'households': 2,
    'housekeeper': 3,
    'housekeepers': 3,
    'housekeeping': 3,
    'houseknecht': 2,
    'housel': 2,
    'houseman': 2,
    'housen': 2,
    'houser': 2,
    'houseraising': 3,
    'houses': 2,
    'houseware': 2,
    'housewares': 2,
    'housewarming': 3,
    'housewife': 2,
    'housewives': 2,
    'housework': 2,
    'houseworth': 2,
    'housewright': 2,
    'housh': 1,
    'housholder': 3,
    'housing': 2,
    'housings': 2,
    'houska': 2,
    'housley': 2,
    'housman': 2,
    'houston': 2,
    'houstonian': 4,
    'hout': 1,
    'houtchens': 2,
    'houten': 2,
    'houtman': 2,
    'houts': 1,
    'houtz': 1,
    'houx': 1,
    'houy': 2,
    'houze': 1,
    'hovan': 2,
    'hovanec': 3,
    'hovater': 3,
    'hovatter': 3,
    'hovda': 2,
    'hovde': 1,
    'hovden': 2,
    'hove': 1,
    'hovel': 2,
    'hovels': 2,
    'hoven': 2,
    'hover': 2,
    'hovercraft': 3,
    'hovered': 2,
    'hoverflies': 3,
    'hoverfly': 3,
    'hovering': 3,
    'hovermale': 3,
    'hovers': 2,
    'hoverson': 3,
    'hovey': 2,
    'hovhaness': 3,
    'hoving': 2,
    'hovious': 3,
    'hovis': 2,
    'hovland': 2,
    'hovnanian': 4,
    'hovorka': 3,
    'hovsepian': 4,
    'hovy': 2,
    'how': 1,
    'howald': 2,
    'howard': 2,
    'howarth': 2,
    'howat': 2,
    'howatt': 2,
    'howcroft': 2,
    'howden': 2,
    'howdeshell': 3,
    'howdy': 2,
    'howdydoody': 4,
    'howdyshell': 3,
    'howe': 1,
    'howell': 2,
    'howells': 2,
    'howenstine': 3,
    'hower': 2,
    'howerter': 3,
    'howerton': 3,
    'howery': 3,
    'howes': 1,
    'howeth': 2,
    'however': 3,
    'howey': 2,
    'howie': 2,
    'howington': 3,
    'howison': 3,
    'howitt': 2,
    'howitzer': 3,
    'howitzers': 3,
    'howk': 1,
    'howl': 1,
    'howland': 2,
    'howle': 2,
    'howled': 1,
    'howler': 2,
    'howlett': 2,
    'howley': 2,
    'howling': 2,
    'howls': 1,
    'howmet': 2,
    'howorth': 2,
    'howry': 2,
    'howsare': 2,
    'howse': 1,
    'howsell': 2,
    'howser': 2,
    'howson': 2,
    'howtek': 2,
    'howton': 2,
    'howze': 1,
    'hoxie': 2,
    'hoxsey': 2,
    'hoxsie': 2,
    'hoxworth': 2,
    'hoy': 1,
    'hoye': 1,
    'hoyer': 2,
    'hoying': 2,
    'hoylake': 2,
    'hoyland': 2,
    'hoyle': 1,
    'hoyne': 1,
    'hoyos': 2,
    'hoyt': 1,
    'hoyvald': 2,
    'hrabak': 2,
    'hrawi': 3,
    'hrdlicka': 3,
    'hreha': 2,
    'hren': 1,
    'hribar': 2,
    'hritz': 1,
    'hrivnak': 2,
    'hrncir': 2,
    'hron': 1,
    'hronek': 2,
    'hrovat': 2,
    'hrubik': 3,
    'hruby': 2,
    'hruska': 2,
    'hsia': 1,
    'hsiao': 1,
    'hsieh': 2,
    'hsiung': 2,
    'hsu': 1,
    'hu': 1,
    'hua': 2,
    'huachuca': 3,
    'huadong': 2,
    'huairou': 2,
    'huallaga': 3,
    'huan': 1,
    'huaneng': 2,
    'huang': 1,
    'huard': 1,
    'hub': 1,
    'hubbard': 2,
    'hubbart': 2,
    'hubbell': 2,
    'hubbert': 2,
    'hubble': 2,
    'hubbs': 1,
    'hubbub': 2,
    'hubby': 2,
    'hubcap': 2,
    'hubcaps': 2,
    'hubco': 2,
    'hube': 1,
    'huber': 2,
    'huberman': 3,
    'hubers': 2,
    'hubert': 2,
    'huberta': 3,
    'huberto': 3,
    'huberty': 3,
    'hubka': 2,
    'hubler': 3,
    'hubley': 2,
    'hubner': 2,
    'hubor': 2,
    'hubris': 2,
    'hubs': 1,
    'huch': 1,
    'huchison': 3,
    'huck': 1,
    'huckaba': 3,
    'huckabay': 3,
    'huckabee': 3,
    'huckaby': 3,
    'hucke': 1,
    'huckeba': 3,
    'huckelby': 3,
    'hucker': 2,
    'huckins': 2,
    'huckle': 2,
    'huckleberry': 4,
    'hucks': 1,
    'huckstep': 2,
    'huckster': 2,
    'hucksters': 2,
    'hud': 1,
    'huda': 2,
    'hudak': 2,
    'huddie': 2,
    'huddle': 2,
    'huddled': 2,
    'huddles': 2,
    'huddleson': 3,
    'huddleston': 3,
    'huddling': 3,
    'huddy': 2,
    'hudec': 2,
    'hudecek': 3,
    'hudek': 2,
    'hudelson': 3,
    'hudgens': 2,
    'hudgins': 2,
    'hudkins': 2,
    'hudler': 3,
    'hudlow': 2,
    'hudman': 2,
    'hudnall': 2,
    'hudnell': 2,
    'hudnut': 2,
    'hudock': 2,
    'hudon': 2,
    'hudson': 2,
    'hudspeth': 2,
    'hudsucker': 3,
    'hudwon': 2,
    'hudy': 2,
    'hudzik': 2,
    'hue': 1,
    'huebel': 2,
    'hueber': 2,
    'huebert': 2,
    'huebner': 2,
    'huebsch': 1,
    'hued': 1,
    'huegel': 2,
    'hueglin': 2,
    'huelskamp': 2,
    'huelsman': 2,
    'huelsmann': 2,
    'huenink': 2,
    'huerst': 1,
    'huerta': 2,
    'huerter': 2,
    'hues': 1,
    'huesman': 2,
    'huestis': 2,
    'hueston': 2,
    'huether': 2,
    'huett': 1,
    'huetta': 3,
    'huette': 1,
    'huettl': 2,
    'huettner': 2,
    'huey': 2,
    'hufbauer': 3,
    'huff': 1,
    'huffaker': 3,
    'huffed': 1,
    'huffer': 2,
    'huffine': 2,
    'huffines': 2,
    'huffing': 2,
    'huffington': 3,
    'huffman': 2,
    'huffmaster': 3,
    'hufford': 2,
    'huffs': 1,
    'huffstetler': 4,
    'huffstutler': 4,
    'huffy': 2,
    'hufnagel': 3,
    'hufnagle': 3,
    'hufstedler': 4,
    'hufstetler': 4,
    'hug': 1,
    'huge': 1,
    'hugel': 2,
    'hugely': 2,
    'hugest': 2,
    'hugette': 2,
    'hugg': 1,
    'huggard': 2,
    'hugged': 1,
    'hugger': 2,
    'huggett': 2,
    'huggies': 2,
    'hugging': 2,
    'huggins': 2,
    'huggler': 2,
    'huggy': 2,
    'hugh': 1,
    'hughart': 2,
    'hughbanks': 2,
    'hughen': 2,
    'hughes': 1,
    'hughett': 2,
    'hughette': 2,
    'hughey': 2,
    'hughie': 2,
    'hughlett': 2,
    'hughley': 2,
    'hughs': 1,
    'hughson': 2,
    'hughston': 2,
    'hughy': 2,
    'hugill': 2,
    'hugley': 2,
    'hugo': 2,
    'hugoton': 3,
    'hugs': 1,
    'huguenin': 3,
    'huguenot': 3,
    'huguenots': 3,
    'huguet': 2,
    'huguley': 3,
    'hugus': 2,
    'huh': 1,
    'huhn': 1,
    'huhta': 2,
    'hui': 2,
    'huibregtse': 2,
    'huie': 2,
    'huish': 2,
    'huishman': 2,
    'huitt': 1,
    'huizar': 2,
    'huizenga': 3,
    'huizinga': 3,
    'hukill': 2,
    'hukstra': 2,
    'hula': 2,
    'hulbard': 2,
    'hulbert': 2,
    'hulburd': 2,
    'hulburt': 2,
    'hulce': 1,
    'huldie': 2,
    'huldy': 2,
    'hulen': 2,
    'hulet': 2,
    'hulett': 2,
    'hulette': 2,
    'hulgan': 2,
    'hulick': 2,
    'hulin': 2,
    'huling': 2,
    'hulings': 2,
    'hulk': 1,
    'hulking': 2,
    'hulks': 1,
    'hull': 1,
    'hullabaloo': 4,
    'hulled': 1,
    'hullender': 3,
    'hullett': 2,
    'hulliber': 3,
    'hullinger': 3,
    'hulls': 1,
    'hullum': 2,
    'hulme': 1,
    'hulon': 2,
    'hulond': 2,
    'huls': 1,
    'hulse': 1,
    'hulsebus': 3,
    'hulsey': 2,
    'hulsizer': 3,
    'hulslander': 3,
    'hulsman': 2,
    'hulst': 1,
    'hult': 1,
    'hultberg': 2,
    'hultgren': 2,
    'hultman': 2,
    'hulton': 2,
    'hultquist': 2,
    'hults': 1,
    'hultz': 1,
    'hulvey': 2,
    'hum': 1,
    'human': 2,
    'humana': 3,
    'humane': 2,
    'humanely': 3,
    'humanism': 4,
    'humanist': 3,
    'humanistic': 4,
    'humanists': 3,
    'humanitarian': 6,
    'humanitarians': 6,
    'humanities': 4,
    'humanity': 4,
    'humanize': 3,
    'humanized': 3,
    'humanizes': 4,
    'humanizing': 4,
    'humankind': 3,
    'humanly': 3,
    'humann': 2,
    'humanness': 3,
    'humans': 2,
    'humbarger': 3,
    'humber': 2,
    'humbert': 2,
    'humberto': 3,
    'humble': 2,
    'humbled': 2,
    'humbler': 3,
    'humbles': 2,
    'humblest': 3,
    'humbling': 3,
    'humbly': 2,
    'humboldt': 2,
    'humbug': 2,
    'humburg': 2,
    'humdinger': 3,
    'humdrum': 2,
    'hume': 1,
    'humenik': 3,
    'humerous': 3,
    'humerus': 3,
    'humes': 1,
    'humfrey': 2,
    'humfry': 3,
    'humi': 2,
    'humid': 2,
    'humidifier': 5,
    'humidifiers': 5,
    'humidity': 4,
    'humidor': 3,
    'humiliate': 4,
    'humiliated': 5,
    'humiliates': 4,
    'humiliating': 5,
    'humiliation': 5,
    'humiliations': 5,
    'humility': 4,
    'humiston': 3,
    'humke': 2,
    'huml': 2,
    'humm': 1,
    'hummel': 2,
    'hummell': 2,
    'hummer': 2,
    'humming': 2,
    'hummingbird': 3,
    'hummingbirds': 3,
    'humongous': 3,
    'humor': 2,
    'humoral': 3,
    'humored': 2,
    'humorist': 3,
    'humorists': 3,
    'humorless': 3,
    'humorous': 3,
    'humorously': 4,
    'hump': 1,
    'humpal': 2,
    'humpback': 2,
    'humped': 1,
    'humpert': 2,
    'humph': 1,
    'humphery': 3,
    'humpherys': 3,
    'humphrey': 2,
    'humphreys': 2,
    'humphries': 3,
    'humphry': 2,
    'humpty': 2,
    'hums': 1,
    'humulin': 3,
    'humus': 2,
    'humvee': 2,
    'humvees': 2,
    'hun': 1,
    'hunan': 2,
    'hunch': 1,
    'hunchback': 2,
    'hunched': 1,
    'hunches': 2,
    'hunchine': 2,
    'hund': 1,
    'hundai': 2,
    'hundertmark': 3,
    'hundley': 2,
    'hundred': 2,
    'hundreds': 2,
    'hundredth': 2,
    'hundredths': 2,
    'hundredweight': 3,
    'hundt': 1,
    'huneke': 2,
    'huneycutt': 3,
    'hung': 1,
    'hungarian': 4,
    'hungarians': 4,
    'hungary': 3,
    'hungate': 2,
    'hunger': 2,
    'hungerford': 3,
    'hungerfords': 3,
    'hungering': 3,
    'hungrier': 3,
    'hungrily': 3,
    'hungry': 2,
    'hunk': 1,
    'hunke': 1,
    'hunkele': 2,
    'hunker': 2,
    'hunkered': 2,
    'hunkering': 3,
    'hunkins': 2,
    'hunks': 1,
    'hunky': 2,
    'hunley': 2,
    'hunn': 1,
    'hunnell': 2,
    'hunnewell': 3,
    'hunnicutt': 3,
    'hunsaker': 3,
    'hunsberger': 3,
    'hunsicker': 3,
    'hunsinger': 3,
    'hunsley': 2,
    'hunsucker': 3,
    'hunt': 1,
    'huntco': 2,
    'hunte': 1,
    'hunted': 2,
    'hunter': 2,
    'hunters': 2,
    'hunting': 2,
    'huntingdon': 3,
    'huntington': 3,
    'huntley': 2,
    'huntly': 2,
    'hunton': 2,
    'huntoon': 2,
    'huntress': 2,
    'hunts': 1,
    'huntsinger': 3,
    'huntsman': 2,
    'huntsville': 2,
    'huntway': 2,
    'huntzinger': 3,
    'hunza': 2,
    'hunzeker': 3,
    'hunziker': 3,
    'huot': 2,
    'hupe': 1,
    'hupfer': 2,
    'hupp': 1,
    'huppert': 2,
    'hur': 1,
    'hurco': 2,
    'hurd': 1,
    'hurdle': 2,
    'hurdler': 3,
    'hurdles': 2,
    'hurdling': 3,
    'hurdman': 2,
    'hurford': 2,
    'hurl': 1,
    'hurlbert': 2,
    'hurlburt': 2,
    'hurlbut': 2,
    'hurlbutt': 2,
    'hurled': 1,
    'hurless': 2,
    'hurley': 2,
    'hurling': 2,
    'hurlock': 2,
    'hurly': 2,
    'hurm': 1,
    'hurn': 1,
    'hurney': 2,
    'huron': 2,
    'hurrah': 2,
    'hurray': 2,
    'hurrell': 2,
    'hurri': 2,
    'hurricane': 3,
    'hurricanes': 3,
    'hurried': 2,
    'hurriedly': 3,
    'hurries': 2,
    'hurry': 2,
    'hurrying': 3,
    'hursey': 2,
    'hursh': 1,
    'hurst': 1,
    'hurston': 2,
    'hurt': 1,
    'hurta': 2,
    'hurtado': 3,
    'hurteau': 2,
    'hurtful': 2,
    'hurtig': 2,
    'hurting': 2,
    'hurtle': 2,
    'hurtling': 2,
    'hurts': 1,
    'hurtt': 1,
    'hurtubise': 3,
    'hurvey': 2,
    'hurvitz': 2,
    'hurwitz': 2,
    'husain': 2,
    'husak': 2,
    'husar': 2,
    'husband': 2,
    'husbandry': 3,
    'husbands': 2,
    'husby': 2,
    'huschka': 2,
    'huse': 1,
    'huseby': 3,
    'huseman': 2,
    'husen': 2,
    'huser': 2,
    'hush': 1,
    'hushed': 1,
    'husic': 2,
    'husk': 1,
    'huska': 2,
    'huskey': 2,
    'huskins': 2,
    'husks': 1,
    'husky': 2,
    'husman': 2,
    'husmann': 2,
    'huson': 2,
    'huss': 1,
    'hussain': 2,
    'hussar': 2,
    'hussein': 2,
    'husseini': 3,
    'husser': 2,
    'hussey': 2,
    'hussite': 2,
    'hussman': 2,
    'husson': 2,
    'hussong': 2,
    'hussy': 2,
    'hust': 1,
    'hustad': 2,
    'hustead': 2,
    'husted': 2,
    'huster': 2,
    'hustings': 2,
    'hustle': 2,
    'hustled': 2,
    'hustler': 3,
    'hustlers': 3,
    'hustles': 2,
    'hustling': 3,
    'huston': 2,
    'hut': 1,
    'huta': 2,
    'hutch': 1,
    'hutchcraft': 2,
    'hutchens': 2,
    'hutcheon': 3,
    'hutcherson': 3,
    'hutcheson': 3,
    'hutchings': 2,
    'hutchins': 2,
    'hutchinson': 3,
    'hutchison': 3,
    'huth': 1,
    'hutmacher': 3,
    'hutnick': 2,
    'huto': 2,
    'huts': 1,
    'hutsell': 2,
    'hutson': 2,
    'hutt': 1,
    'hutter': 2,
    'huttner': 2,
    'hutto': 2,
    'hutton': 2,
    'hutu': 2,
    'hutus': 2,
    'hutzel': 2,
    'hutzell': 2,
    'hutzelman': 3,
    'hutzler': 2,
    'huval': 2,
    'huwe': 1,
    'hux': 1,
    'huxford': 2,
    'huxley': 2,
    'huxtable': 3,
    'huxtables': 3,
    'huyck': 1,
    'huyett': 2,
    'huyler': 2,
    'huynh': 1,
    'huyser': 2,
    'hwa': 1,
    'hwan': 1,
    'hwang': 1,
    'hwe': 1,
    'hy': 1,
    'hyacinth': 3,
    'hyacintha': 4,
    'hyacinthe': 3,
    'hyacinthia': 5,
    'hyacinthie': 4,
    'hyacinths': 3,
    'hyades': 3,
    'hyakutake': 5,
    'hyaluronic': 5,
    'hyams': 2,
    'hyannis': 3,
    'hyannisport': 4,
    'hyatt': 2,
    'hybl': 2,
    'hybrid': 2,
    'hybridization': 5,
    'hybridize': 3,
    'hybrids': 2,
    'hybrienko': 4,
    'hybritech': 3,
    'hyche': 1,
    'hycor': 2,
    'hycroft': 2,
    'hyde': 1,
    'hydea': 3,
    'hydeia': 3,
    'hyden': 2,
    'hyder': 2,
    'hyderabad': 4,
    'hydock': 2,
    'hydra': 2,
    'hydrant': 2,
    'hydrants': 2,
    'hydras': 2,
    'hydrate': 2,
    'hydrated': 3,
    'hydrates': 2,
    'hydration': 3,
    'hydraulic': 3,
    'hydraulics': 3,
    'hydrazine': 3,
    'hydrick': 2,
    'hydride': 2,
    'hydro': 2,
    'hydrocarbon': 4,
    'hydrocarbons': 4,
    'hydroelectric': 5,
    'hydroencephalus': 6,
    'hydrofoil': 3,
    'hydrogen': 3,
    'hydrogenate': 4,
    'hydrogenated': 5,
    'hydrogenates': 4,
    'hydrogenating': 5,
    'hydrogenation': 5,
    'hydrogens': 3,
    'hydrographic': 4,
    'hydrolysis': 4,
    'hydrolyze': 3,
    'hydrolyzed': 3,
    'hydrolyzing': 4,
    'hydrometer': 4,
    'hydron': 2,
    'hydrophilic': 4,
    'hydroponic': 4,
    'hydropower': 3,
    'hydrosol': 3,
    'hydrosulfide': 4,
    'hydrotherapy': 5,
    'hydrothermal': 4,
    'hydrous': 2,
    'hydrox': 2,
    'hydroxide': 3,
    'hydroxides': 3,
    'hydroxy': 3,
    'hydstra': 2,
    'hye': 1,
    'hyena': 3,
    'hyenas': 3,
    'hyer': 2,
    'hyers': 2,
    'hygeia': 3,
    'hygiene': 2,
    'hygienist': 3,
    'hygienists': 3,
    'hygrometer': 4,
    'hykes': 1,
    'hyland': 2,
    'hyle': 1,
    'hyler': 2,
    'hylsa': 2,
    'hylton': 2,
    'hyman': 2,
    'hymans': 2,
    'hymas': 2,
    'hymel': 2,
    'hymen': 2,
    'hymer': 2,
    'hymes': 1,
    'hymie': 2,
    'hymies': 2,
    'hymn': 1,
    'hymnal': 2,
    'hymnals': 2,
    'hymnology': 4,
    'hymns': 1,
    'hymowitz': 3,
    'hynd': 1,
    'hyndman': 2,
    'hynds': 1,
    'hynek': 2,
    'hynes': 1,
    'hynson': 2,
    'hyogo': 2,
    'hyotan': 2,
    'hype': 1,
    'hyped': 1,
    'hyper': 2,
    'hyperactive': 4,
    'hyperactivity': 6,
    'hyperbaric': 4,
    'hyperbola': 4,
    'hyperbole': 4,
    'hyperbolic': 4,
    'hyperborean': 5,
    'hypercard': 3,
    'hypercritical': 5,
    'hyperinflation': 5,
    'hyperion': 4,
    'hyperkinetic': 5,
    'hyperlink': 3,
    'hyperlinks': 3,
    'hypermarket': 4,
    'hypermarkets': 4,
    'hyperopia': 5,
    'hyperplasia': 4,
    'hypersensitive': 5,
    'hypersensitivity': 7,
    'hypersonic': 4,
    'hypertension': 4,
    'hypertensive': 4,
    'hypertext': 3,
    'hypertonic': 4,
    'hypertrophic': 4,
    'hypertrophy': 4,
    'hypes': 1,
    'hyphae': 2,
    'hyphen': 2,
    'hyphenate': 3,
    'hyphenated': 4,
    'hyphenates': 3,
    'hyphenating': 4,
    'hyphenation': 4,
    'hyping': 2,
    'hypnosis': 3,
    'hypnotic': 3,
    'hypnotics': 3,
    'hypnotism': 4,
    'hypnotist': 3,
    'hypnotists': 3,
    'hypnotize': 3,
    'hypnotized': 3,
    'hypnotizes': 4,
    'hypo': 2,
    'hypochondria': 5,
    'hypochondriac': 5,
    'hypocrisy': 4,
    'hypocrite': 3,
    'hypocrites': 3,
    'hypocritical': 5,
    'hypodermic': 4,
    'hypoglycemia': 6,
    'hypoglycemic': 5,
    'hypolite': 3,
    'hyponex': 3,
    'hypotension': 4,
    'hypothalamic': 5,
    'hypotheken': 4,
    'hypothermia': 5,
    'hypotheses': 4,
    'hypothesis': 4,
    'hypothesize': 4,
    'hypothesized': 4,
    'hypothetical': 5,
    'hypothetically': 5,
    'hypotheticals': 5,
    'hypoxia': 4,
    'hyraxes': 3,
    'hyre': 1,
    'hysell': 2,
    'hyser': 2,
    'hyslop': 2,
    'hyson': 2,
    'hysong': 2,
    'hyssop': 2,
    'hyster': 2,
    'hysterectomies': 5,
    'hysterectomy': 5,
    'hysteria': 4,
    'hysteric': 3,
    'hysterical': 4,
    'hysterically': 4,
    'hysterics': 3,
    'hyun': 2,
    'hyundai': 2,
    'hyundais': 2,
    'hywell': 2,
    'i': 1,
    'ia': 2,
    'iacobelli': 5,
    'iacobellis': 5,
    'iacobucci': 5,
    'iacocca': 4,
    'iacona': 4,
    'iacono': 4,
    'iacovelli': 5,
    'iafrate': 4,
    'iago': 3,
    'iain': 2,
    'iakovos': 4,
    'ialla': 3,
    'iams': 2,
    'ian': 2,
    'iannaccone': 5,
    'iannacone': 5,
    'iannamico': 5,
    'iannelli': 4,
    'iannello': 4,
    'ianni': 3,
    'ianniello': 5,
    'iannone': 4,
    'iannotti': 4,
    'iannucci': 4,
    'iannuzzi': 4,
    'ianovski': 3,
    'iantha': 3,
    'ianthe': 3,
    'ianthina': 4,
    'iason': 3,
    'iavarone': 5,
    'ib': 1,
    'iba': 2,
    'ibach': 2,
    'ibanez': 3,
    'ibaraki': 4,
    'ibarra': 3,
    'ibbotson': 3,
    'iberia': 4,
    'iberian': 4,
    'ibero': 3,
    'ibex': 2,
    'ibis': 2,
    'ibmer': 2,
    'ibmers': 2,
    'ibn': 2,
    'ibogaine': 3,
    'ibrahim': 3,
    'ibsen': 2,
    'ibuprofen': 4,
    'icahn': 2,
    'icard': 2,
    'icarus': 3,
    'ice': 1,
    'iceberg': 2,
    'icebergs': 2,
    'icebox': 2,
    'icebreaker': 3,
    'icebreakers': 3,
    'iced': 1,
    'icefish': 2,
    'iceland': 2,
    'icelandair': 3,
    'icelandic': 3,
    'iceman': 2,
    'icenhour': 3,
    'icenhower': 4,
    'icenogle': 4,
    'ices': 2,
    'iceskate': 2,
    'iceskating': 3,
    'ich': 1,
    'ichi': 2,
    'ichikawa': 4,
    'ichiro': 3,
    'icicle': 3,
    'icicles': 3,
    'icily': 3,
    'icing': 2,
    'ickes': 2,
    'icon': 2,
    'iconoclasm': 5,
    'iconoclast': 4,
    'iconoclastic': 5,
    'iconography': 5,
    'icons': 2,
    'icy': 2,
    'id': 1,
    'ida': 2,
    'idaho': 3,
    'idalia': 3,
    'idalina': 4,
    'idaline': 3,
    'iddings': 2,
    'ide': 1,
    'idea': 3,
    'ideal': 2,
    'idealism': 4,
    'idealist': 3,
    'idealistic': 5,
    'idealists': 3,
    'idealize': 3,
    'idealized': 4,
    'idealizes': 4,
    'idealizing': 4,
    'ideally': 3,
    'ideals': 2,
    'ideas': 3,
    'idec': 2,
    'iden': 2,
    'ident': 2,
    'identa': 3,
    'identical': 4,
    'identics': 3,
    'identifiable': 6,
    'identification': 6,
    'identifications': 6,
    'identified': 4,
    'identifier': 5,
    'identifies': 4,
    'identify': 4,
    'identifying': 5,
    'identikit': 4,
    'identities': 4,
    'identity': 4,
    'ideological': 6,
    'ideologically': 6,
    'ideologies': 5,
    'ideologist': 5,
    'ideologists': 5,
    'ideologue': 4,
    'ideologues': 4,
    'ideology': 5,
    'ideonomy': 5,
    'idette': 2,
    'ididerod': 4,
    'idiocy': 4,
    'idiom': 3,
    'idiomatic': 5,
    'idioms': 3,
    'idiopath': 4,
    'idiopathic': 5,
    'idiosyncrasies': 6,
    'idiosyncrasy': 6,
    'idiosyncratic': 6,
    'idiot': 3,
    'idiotic': 4,
    'idiotically': 5,
    'idiots': 3,
    'iditarod': 4,
    'idle': 2,
    'idled': 2,
    'idleman': 3,
    'idleness': 3,
    'idler': 3,
    'idles': 2,
    'idlewild': 3,
    'idling': 3,
    'idly': 2,
    'ido': 2,
    'idol': 2,
    'idola': 3,
    'idolatrous': 4,
    'idolatry': 4,
    'idolize': 3,
    'idolized': 3,
    'idolizes': 4,
    'idolizing': 4,
    'idols': 2,
    'idona': 3,
    'idoney': 3,
    'idonia': 4,
    'idrocarburi': 5,
    'iduna': 3,
    'idyll': 2,
    'idyllic': 3,
    'ierardi': 4,
    'ierne': 1,
    'iezzi': 3,
    'if': 1,
    'ifas': 2,
    'iffy': 2,
    'ifil': 2,
    'ifill': 2,
    'ifint': 2,
    'ifor': 2,
    'ifs': 1,
    'igarashi': 4,
    'ige': 1,
    'igel': 2,
    'igene': 2,
    'iger': 2,
    'iglehart': 3,
    'iglesia': 4,
    'iglesias': 4,
    'igloo': 2,
    'igman': 2,
    'ignace': 2,
    'ignacio': 4,
    'ignasiak': 4,
    'ignatia': 3,
    'ignatius': 3,
    'ignatowski': 4,
    'ignatz': 2,
    'igneous': 3,
    'ignite': 2,
    'ignited': 3,
    'ignites': 2,
    'igniting': 3,
    'ignition': 3,
    'ignoble': 3,
    'ignominious': 5,
    'ignominy': 4,
    'ignoramus': 4,
    'ignorance': 3,
    'ignorant': 3,
    'ignore': 2,
    'ignored': 2,
    'ignores': 2,
    'ignoring': 3,
    'igo': 2,
    'igoe': 2,
    'igon': 2,
    'igor': 2,
    'igou': 2,
    'iguana': 3,
    'iguanas': 3,
    'igushi': 3,
    'ihasz': 2,
    'ihde': 1,
    'ihle': 2,
    'ihlenfeldt': 3,
    'ihnen': 2,
    'ihrig': 2,
    'ihrke': 1,
    'iiams': 2,
    'iida': 2,
    'iie': 2,
    'ijames': 3,
    'ijaz': 2,
    'ikaes': 2,
    'ikard': 2,
    'ike': 1,
    'ikea': 3,
    'ikeda': 3,
    'ikenberry': 4,
    'ikerd': 2,
    'ikeuchi': 3,
    'ikey': 2,
    'ikie': 2,
    'ikle': 2,
    'ikner': 2,
    'ikon': 2,
    'il': 1,
    'ila': 2,
    'ilalis': 3,
    'ilan': 2,
    'ilana': 3,
    'ilardi': 3,
    'ilbo': 2,
    'ileana': 4,
    'ilene': 2,
    'iler': 2,
    'iles': 1,
    'ileto': 3,
    'ilg': 1,
    'ilgenfritz': 3,
    'ilhae': 2,
    'iliad': 3,
    'iliano': 4,
    'ilidza': 3,
    'iliescu': 4,
    'iliff': 2,
    'iljin': 2,
    'ilk': 1,
    'ilka': 2,
    'ill': 1,
    'illegal': 3,
    'illegalities': 5,
    'illegality': 5,
    'illegally': 4,
    'illegals': 3,
    'illegible': 4,
    'illegitimacy': 6,
    'illegitimate': 5,
    'illes': 1,
    'illg': 1,
    'illicit': 3,
    'illicitly': 4,
    'illig': 2,
    'illina': 3,
    'illingworth': 3,
    'illinois': 3,
    'illiquid': 3,
    'illiquidity': 5,
    'illiteracy': 5,
    'illiterate': 4,
    'illiterates': 4,
    'illness': 2,
    'illnesses': 3,
    'illogic': 3,
    'illogical': 4,
    'ills': 1,
    'illuminate': 4,
    'illuminated': 5,
    'illuminates': 4,
    'illuminating': 5,
    'illumination': 5,
    'illuminator': 5,
    'illumined': 3,
    'illusion': 3,
    'illusionary': 5,
    'illusionism': 5,
    'illusionist': 4,
    'illusionists': 4,
    'illusions': 3,
    'illusive': 3,
    'illusory': 4,
    'illustrate': 3,
    'illustrated': 4,
    'illustrates': 3,
    'illustrating': 4,
    'illustration': 4,
    'illustrations': 4,
    'illustrative': 4,
    'illustrator': 4,
    'illustrators': 4,
    'illustrious': 4,
    'illyrian': 4,
    'ilo': 2,
    'ilona': 3,
    'ilopango': 4,
    'ilsa': 2,
    'ilse': 1,
    'ilsley': 2,
    'ilva': 2,
    'ilya': 2,
    'ilyaronoff': 4,
    'im': 1,
    'image': 2,
    'imageries': 3,
    'imagery': 3,
    'images': 3,
    'imaginable': 5,
    'imaginary': 5,
    'imagination': 5,
    'imaginations': 5,
    'imaginative': 5,
    'imaginatively': 6,
    'imagine': 3,
    'imagined': 3,
    'imagineer': 4,
    'imagineering': 5,
    'imagines': 3,
    'imaging': 3,
    'imagining': 4,
    'imai': 2,
    'imam': 2,
    'imamura': 4,
    'iman': 2,
    'imari': 3,
    'imasco': 3,
    'imax': 2,
    'imbalance': 3,
    'imbalances': 4,
    'imbecile': 3,
    'imbeciles': 3,
    'imbed': 2,
    'imbedded': 3,
    'imbeds': 2,
    'imber': 2,
    'imbera': 3,
    'imbibe': 2,
    'imbibed': 2,
    'imboden': 3,
    'imbroglio': 3,
    'imbrogno': 3,
    'imbue': 2,
    'imbued': 2,
    'imburgia': 4,
    'imcera': 3,
    'imel': 2,
    'imelda': 3,
    'imes': 1,
    'imhof': 2,
    'imhoff': 2,
    'imig': 2,
    'imitable': 4,
    'imitate': 3,
    'imitated': 4,
    'imitates': 3,
    'imitating': 4,
    'imitation': 4,
    'imitations': 4,
    'imitative': 4,
    'imitator': 4,
    'imitators': 4,
    'imlay': 2,
    'imler': 2,
    'imm': 3,
    'immaculate': 4,
    'immaculately': 5,
    'immanence': 3,
    'immanent': 3,
    'immanuel': 3,
    'immaterial': 5,
    'immature': 3,
    'immaturity': 5,
    'immeasurable': 5,
    'immeasurably': 5,
    'immediacy': 5,
    'immediate': 4,
    'immediately': 5,
    'immel': 2,
    'immemorial': 5,
    'immense': 2,
    'immensely': 3,
    'immerman': 3,
    'immerse': 2,
    'immersed': 2,
    'immersion': 3,
    'immigrant': 3,
    'immigrants': 3,
    'immigrate': 3,
    'immigrated': 4,
    'immigration': 4,
    'imminence': 3,
    'imminent': 3,
    'imminently': 4,
    'immiscible': 4,
    'immobile': 3,
    'immobility': 5,
    'immobilize': 4,
    'immobilized': 4,
    'immobilizing': 5,
    'immolate': 3,
    'immolated': 4,
    'immolates': 3,
    'immolating': 4,
    'immolation': 4,
    'immoral': 3,
    'immorality': 5,
    'immortal': 3,
    'immortality': 5,
    'immortalize': 4,
    'immortalized': 4,
    'immortalizes': 5,
    'immortalizing': 5,
    'immortals': 3,
    'immotile': 3,
    'immovable': 4,
    'immu': 2,
    'immulogic': 4,
    'immune': 2,
    'immunetech': 3,
    'immunex': 3,
    'immunities': 4,
    'immunity': 4,
    'immunization': 5,
    'immunizations': 5,
    'immunize': 3,
    'immunized': 3,
    'immunizes': 4,
    'immunizing': 4,
    'immuno': 3,
    'immunodeficiency': 7,
    'immunological': 6,
    'immunologist': 5,
    'immunology': 5,
    'immunomedic': 5,
    'immunomedics': 5,
    'immunotherapy': 6,
    'immutable': 4,
    'imnet': 2,
    'imo': 2,
    'imogen': 3,
    'imogene': 3,
    'imondi': 3,
    'imp': 1,
    'impact': 2,
    'impacted': 3,
    'impacting': 3,
    'impacts': 2,
    'impair': 2,
    'impaired': 2,
    'impairing': 3,
    'impairment': 3,
    'impairments': 3,
    'impairs': 2,
    'impala': 3,
    'impale': 2,
    'impaled': 2,
    'impales': 2,
    'impaling': 3,
    'impanel': 3,
    'impaneled': 3,
    'impart': 2,
    'imparted': 3,
    'impartial': 3,
    'impartiality': 6,
    'impartially': 4,
    'imparting': 3,
    'imparts': 2,
    'impassable': 4,
    'impasse': 2,
    'impassion': 3,
    'impassioned': 3,
    'impassive': 3,
    'impassively': 4,
    'impastato': 4,
    'impatience': 3,
    'impatiens': 3,
    'impatient': 3,
    'impatiently': 4,
    'impeach': 2,
    'impeachable': 4,
    'impeached': 2,
    'impeaches': 3,
    'impeaching': 3,
    'impeachment': 3,
    'impeachments': 3,
    'impeccable': 4,
    'impeccably': 4,
    'impedance': 3,
    'impede': 2,
    'impeded': 3,
    'impedes': 2,
    'impediment': 4,
    'impediments': 4,
    'impeding': 3,
    'impel': 2,
    'impelled': 2,
    'impend': 2,
    'impending': 3,
    'impenetrable': 5,
    'imperative': 4,
    'imperatives': 4,
    'imperato': 4,
    'imperceptible': 5,
    'imperceptibly': 5,
    'imperfect': 3,
    'imperfection': 4,
    'imperfections': 4,
    'imperfectly': 4,
    'imperia': 4,
    'imperial': 4,
    'imperiale': 5,
    'imperialism': 6,
    'imperialist': 5,
    'imperialistic': 6,
    'imperialists': 5,
    'imperil': 3,
    'imperiled': 3,
    'imperiling': 4,
    'imperils': 3,
    'imperious': 4,
    'impermissible': 5,
    'impersonal': 4,
    'impersonality': 6,
    'impersonate': 4,
    'impersonated': 5,
    'impersonates': 4,
    'impersonating': 5,
    'impersonation': 5,
    'impersonator': 5,
    'impersonators': 5,
    'impertinent': 4,
    'impervious': 4,
    'impetuous': 3,
    'impetus': 3,
    'impinge': 2,
    'impinged': 2,
    'impinges': 3,
    'impinging': 3,
    'impious': 3,
    'impish': 2,
    'implacable': 4,
    'implant': 2,
    'implantable': 4,
    'implantation': 4,
    'implanted': 3,
    'implanting': 3,
    'implants': 2,
    'implausible': 4,
    'implausibly': 4,
    'implement': 3,
    'implementation': 5,
    'implemented': 4,
    'implementing': 4,
    'implements': 3,
    'implicate': 3,
    'implicated': 4,
    'implicates': 3,
    'implicating': 4,
    'implication': 4,
    'implications': 4,
    'implicit': 3,
    'implicitly': 4,
    'implied': 2,
    'implies': 2,
    'implode': 2,
    'imploded': 3,
    'implodes': 2,
    'imploding': 3,
    'implore': 2,
    'implored': 2,
    'implores': 2,
    'imploring': 3,
    'implosion': 3,
    'imply': 2,
    'implying': 3,
    'impolite': 3,
    'impolitic': 4,
    'imponderable': 5,
    'imponderables': 5,
    'import': 2,
    'importance': 3,
    'important': 3,
    'importantly': 4,
    'importation': 4,
    'imported': 3,
    'importer': 3,
    'importers': 3,
    'importing': 3,
    'imports': 2,
    'impose': 2,
    'imposed': 2,
    'imposes': 3,
    'imposing': 3,
    'imposition': 4,
    'impossibility': 6,
    'impossible': 4,
    'impossibly': 4,
    'impostor': 3,
    'impostors': 3,
    'impotence': 3,
    'impotent': 3,
    'impound': 2,
    'impounded': 3,
    'impounding': 3,
    'impoundment': 3,
    'impoundments': 3,
    'impounds': 2,
    'impoverish': 3,
    'impoverished': 3,
    'impoverishes': 4,
    'impoverishing': 4,
    'impoverishment': 4,
    'impracticable': 5,
    'impractical': 4,
    'imprecise': 3,
    'impregnable': 4,
    'impregnate': 3,
    'impregnated': 4,
    'impregnates': 3,
    'impregnating': 4,
    'impregnation': 4,
    'impresario': 5,
    'impress': 2,
    'impressed': 2,
    'impresses': 3,
    'impressing': 3,
    'impression': 3,
    'impressionable': 5,
    'impressionism': 5,
    'impressionist': 4,
    'impressionistic': 5,
    'impressionists': 4,
    'impressions': 3,
    'impressive': 3,
    'impressively': 4,
    'impressment': 3,
    'imprimatur': 4,
    'imprimis': 3,
    'imprint': 2,
    'imprinted': 3,
    'imprinting': 3,
    'imprints': 2,
    'imprison': 3,
    'imprisoned': 3,
    'imprisoning': 4,
    'imprisonment': 4,
    'imprisons': 3,
    'improbable': 4,
    'improbably': 4,
    'impromptu': 3,
    'improper': 3,
    'improperly': 4,
    'improprieties': 5,
    'impropriety': 5,
    'improv': 2,
    'improve': 2,
    'improved': 2,
    'improvement': 3,
    'improvements': 3,
    'improves': 2,
    'improving': 3,
    'improvisation': 5,
    'improvisational': 6,
    'improvisations': 5,
    'improvise': 3,
    'improvised': 3,
    'improvises': 4,
    'improvising': 4,
    'imprudence': 3,
    'imprudent': 3,
    'imprudently': 4,
    'impson': 2,
    'impugn': 2,
    'impugned': 2,
    'impugning': 3,
    'impugns': 2,
    'impulse': 2,
    'impulses': 3,
    'impulsive': 3,
    'impulsively': 4,
    'impunity': 4,
    'impure': 2,
    'impurities': 4,
    'impurity': 4,
    'imputation': 4,
    'impute': 2,
    'imputed': 3,
    'imre': 2,
    'imreg': 2,
    'imrie': 3,
    'imus': 2,
    'in': 1,
    'ina': 2,
    'inability': 5,
    'inabinet': 4,
    'inaccessibility': 7,
    'inaccessible': 5,
    'inaccuracies': 5,
    'inaccuracy': 5,
    'inaccurate': 4,
    'inaccurately': 5,
    'inacom': 3,
    'inacomp': 3,
    'inaction': 3,
    'inactivate': 4,
    'inactivated': 5,
    'inactivates': 4,
    'inactivation': 5,
    'inactive': 3,
    'inactivity': 5,
    'inadequacies': 5,
    'inadequacy': 5,
    'inadequate': 4,
    'inadequately': 5,
    'inadmissible': 5,
    'inadmissibly': 5,
    'inadvertence': 4,
    'inadvertent': 4,
    'inadvertently': 5,
    'inadvisable': 5,
    'inaez': 2,
    'inaki': 3,
    'inalienable': 5,
    'inamura': 4,
    'inane': 2,
    'inanimate': 4,
    'inapplicable': 5,
    'inappropriate': 5,
    'inappropriately': 6,
    'inarticulate': 5,
    'inasmuch': 3,
    'inattention': 4,
    'inattentive': 4,
    'inaudible': 4,
    'inaudibly': 4,
    'inaugural': 4,
    'inaugurate': 4,
    'inaugurated': 5,
    'inaugurates': 4,
    'inaugurating': 5,
    'inauguration': 5,
    'inaugurations': 5,
    'inauspicious': 4,
    'inbio': 3,
    'inboard': 2,
    'inboden': 3,
    'inbody': 3,
    'inborn': 2,
    'inbound': 2,
    'inbred': 2,
    'inbreed': 2,
    'inbreeding': 3,
    'inc': 1,
    'inca': 2,
    'incalculable': 5,
    'incandescent': 4,
    'incant': 2,
    'incantation': 4,
    'incantatory': 5,
    'incapable': 4,
    'incapacitate': 5,
    'incapacitated': 6,
    'incapacitating': 6,
    'incapacitation': 6,
    'incapacity': 5,
    'incarcerate': 4,
    'incarcerated': 5,
    'incarcerates': 4,
    'incarcerating': 5,
    'incarceration': 5,
    'incarnate': 3,
    'incarnation': 4,
    'incarnations': 4,
    'incas': 2,
    'incase': 2,
    'incata': 3,
    'ince': 1,
    'incendiary': 5,
    'incense': 2,
    'incensed': 2,
    'incentive': 3,
    'incentives': 3,
    'inception': 3,
    'incessant': 3,
    'incessantly': 4,
    'incest': 2,
    'incestuous': 3,
    'inch': 1,
    'inchcape': 2,
    'inched': 1,
    'inches': 2,
    'inching': 2,
    'inchon': 2,
    'incidence': 3,
    'incidences': 4,
    'incident': 3,
    'incidental': 4,
    'incidentally': 5,
    'incidentals': 4,
    'incidents': 3,
    'incinerate': 4,
    'incinerated': 5,
    'incinerating': 5,
    'incineration': 5,
    'incinerator': 5,
    'incinerators': 5,
    'incipient': 4,
    'incirlik': 3,
    'incise': 2,
    'incised': 2,
    'incision': 3,
    'incisions': 3,
    'incisive': 3,
    'incisor': 3,
    'incisors': 3,
    'incite': 2,
    'incited': 3,
    'incitement': 3,
    'incitements': 3,
    'incites': 2,
    'inciting': 3,
    'incivility': 5,
    'inclement': 3,
    'inclination': 4,
    'inclinations': 4,
    'incline': 2,
    'inclined': 2,
    'inclines': 2,
    'inclosure': 3,
    'include': 2,
    'included': 3,
    'includes': 2,
    'including': 3,
    'inclusion': 3,
    'inclusions': 3,
    'inclusive': 3,
    'inclusiveness': 4,
    'inco': 2,
    'incognito': 4,
    'incoherence': 4,
    'incoherent': 4,
    'incoherently': 5,
    'incom': 2,
    'income': 2,
    'incomes': 2,
    'incoming': 3,
    'incommunicado': 6,
    'incomparable': 5,
    'incomparably': 5,
    'incompatibility': 7,
    'incompatible': 5,
    'incompetence': 4,
    'incompetency': 5,
    'incompetent': 4,
    'incompetently': 5,
    'incompetents': 4,
    'incomplete': 3,
    'incomprehensible': 6,
    'incomprehensibly': 6,
    'incompressible': 5,
    'inconceivable': 5,
    'inconclusive': 4,
    'inconclusively': 5,
    'incongruity': 5,
    'incongruous': 4,
    'incongruously': 5,
    'inconsequential': 5,
    'inconsistencies': 5,
    'inconsistency': 5,
    'inconsistent': 4,
    'inconspicuous': 4,
    'inconstancy': 4,
    'incontinence': 4,
    'incontinent': 4,
    'incontrovertible': 6,
    'inconvenience': 4,
    'inconvenienced': 4,
    'inconveniences': 5,
    'inconvenient': 4,
    'incoordination': 6,
    'incorporate': 4,
    'incorporated': 5,
    'incorporates': 4,
    'incorporating': 5,
    'incorporation': 5,
    'incorporations': 5,
    'incorrect': 3,
    'incorrectly': 4,
    'incorrigible': 5,
    'incorvaia': 4,
    'increase': 2,
    'increased': 2,
    'increases': 3,
    'increasing': 3,
    'increasingly': 4,
    'incredible': 4,
    'incredibly': 4,
    'incredulity': 5,
    'incredulous': 4,
    'increment': 3,
    'incremental': 4,
    'incrementalism': 5,
    'incrementally': 5,
    'increments': 3,
    'incriminate': 4,
    'incriminating': 5,
    'incrimination': 5,
    'incrust': 2,
    'incrustation': 4,
    'incstar': 2,
    'incubate': 3,
    'incubating': 4,
    'incubation': 4,
    'incubator': 4,
    'incubators': 4,
    'inculcate': 3,
    'inculcated': 4,
    'inculcates': 3,
    'incumbency': 4,
    'incumbent': 3,
    'incumbents': 3,
    'incur': 2,
    'incurable': 4,
    'incurred': 2,
    'incurring': 3,
    'incurs': 2,
    'incursion': 3,
    'incursions': 3,
    'inda': 2,
    'indaba': 3,
    'indal': 2,
    'indata': 3,
    'indebted': 3,
    'indebtedness': 4,
    'indecency': 4,
    'indecent': 3,
    'indecision': 4,
    'indecisive': 4,
    'indecisiveness': 5,
    'indeed': 2,
    'indefatigable': 6,
    'indefensible': 5,
    'indefinable': 5,
    'indefinite': 4,
    'indefinitely': 5,
    'indelible': 4,
    'indelibly': 4,
    'indelicate': 4,
    'indelicato': 5,
    'indemnification': 6,
    'indemnified': 4,
    'indemnify': 4,
    'indemnifying': 5,
    'indemnities': 4,
    'indemnity': 4,
    'indent': 2,
    'indentation': 4,
    'indenture': 3,
    'indentured': 3,
    'indentures': 3,
    'independence': 4,
    'independent': 4,
    'independently': 5,
    'independents': 4,
    'inderal': 3,
    'indescribable': 5,
    'indestructibility': 7,
    'indestructible': 5,
    'indeterminate': 5,
    'index': 2,
    'indexation': 4,
    'indexed': 2,
    'indexer': 3,
    'indexers': 3,
    'indexes': 3,
    'indexing': 3,
    'india': 3,
    'indiaia': 4,
    'indian': 3,
    'indiana': 4,
    'indianapolis': 6,
    'indianian': 5,
    'indianians': 5,
    'indianola': 5,
    'indians': 3,
    'indic': 2,
    'indica': 3,
    'indicate': 3,
    'indicated': 4,
    'indicates': 3,
    'indicating': 4,
    'indication': 4,
    'indications': 4,
    'indicative': 4,
    'indicator': 4,
    'indicators': 4,
    'indices': 3,
    'indicia': 3,
    'indict': 2,
    'indicted': 3,
    'indicting': 3,
    'indictment': 3,
    'indictments': 3,
    'indie': 2,
    'indies': 2,
    'indifference': 4,
    'indifferent': 3,
    'indigenous': 4,
    'indigenously': 5,
    'indigent': 3,
    'indigents': 3,
    'indigest': 3,
    'indigestion': 4,
    'indignant': 3,
    'indignantly': 4,
    'indignation': 4,
    'indignities': 4,
    'indignity': 4,
    'indigo': 3,
    'indira': 3,
    'indirect': 3,
    'indirectly': 4,
    'indiscreet': 3,
    'indiscretion': 4,
    'indiscretions': 4,
    'indiscriminate': 5,
    'indiscriminately': 6,
    'indispensable': 5,
    'indispensible': 5,
    'indisputable': 5,
    'indisputably': 5,
    'indistinct': 3,
    'indistinguishable': 6,
    'indite': 2,
    'indited': 3,
    'indium': 3,
    'individual': 5,
    'individualism': 7,
    'individualist': 6,
    'individualistic': 7,
    'individualists': 6,
    'individuality': 7,
    'individualize': 6,
    'individualized': 6,
    'individually': 6,
    'individuals': 5,
    'indivisible': 5,
    'indo': 2,
    'indochina': 4,
    'indochinese': 4,
    'indoctrinated': 5,
    'indoctrination': 5,
    'indolent': 3,
    'indomitable': 5,
    'indonesia': 4,
    'indonesian': 4,
    'indonesians': 5,
    'indoor': 2,
    'indoors': 2,
    'indosuez': 4,
    'indovina': 4,
    'indri': 2,
    'indubious': 4,
    'indubiously': 5,
    'induce': 2,
    'induced': 2,
    'inducement': 3,
    'inducements': 3,
    'induces': 3,
    'inducing': 3,
    'induct': 2,
    'inductance': 3,
    'inducted': 3,
    'inductee': 3,
    'inductees': 3,
    'induction': 3,
    'inductor': 3,
    'indulge': 2,
    'indulged': 2,
    'indulgence': 3,
    'indulgences': 4,
    'indulgent': 3,
    'indulges': 3,
    'indulging': 3,
    'indus': 2,
    'industri': 3,
    'industria': 4,
    'industrial': 4,
    'industriale': 5,
    'industriali': 5,
    'industrialist': 5,
    'industrialists': 5,
    'industrialization': 7,
    'industrialize': 5,
    'industrialized': 5,
    'industrializing': 6,
    'industrially': 5,
    'industrials': 4,
    'industrie': 3,
    'industrielle': 4,
    'industrier': 4,
    'industries': 3,
    'industrious': 4,
    'industrivaerden': 5,
    'industry': 3,
    'industrywide': 4,
    'indy': 2,
    'indyk': 2,
    'inebriate': 4,
    'inebriated': 5,
    'inebriates': 4,
    'inebriating': 5,
    'inedible': 4,
    'ineffable': 4,
    'ineffective': 4,
    'ineffectiveness': 5,
    'ineffectual': 5,
    'inefficiencies': 5,
    'inefficiency': 5,
    'inefficient': 4,
    'inefficiently': 5,
    'inelastic': 4,
    'ineligible': 5,
    'ineluctible': 5,
    'inept': 2,
    'ineptitude': 4,
    'ineptly': 3,
    'ineptness': 3,
    'inequalities': 5,
    'inequality': 5,
    'inequitable': 5,
    'inequities': 4,
    'inequity': 4,
    'ineradicable': 6,
    'inerco': 3,
    'inert': 2,
    'inertia': 3,
    'inertial': 3,
    'inertness': 3,
    'ines': 2,
    'inescapable': 5,
    'inescapably': 5,
    'inevitability': 7,
    'inevitable': 5,
    'inevitably': 5,
    'inexact': 3,
    'inexcusable': 5,
    'inexcusably': 5,
    'inexhaustible': 5,
    'inexorable': 5,
    'inexorably': 5,
    'inexpensive': 4,
    'inexpensively': 5,
    'inexperience': 5,
    'inexperienced': 5,
    'inexplicable': 5,
    'inexplicably': 5,
    'inextricable': 5,
    'inextricably': 5,
    'inez': 2,
    'infallibility': 6,
    'infallible': 4,
    'infamous': 3,
    'infamy': 3,
    'infancy': 3,
    'infant': 2,
    'infante': 3,
    'infanticide': 4,
    'infanticides': 4,
    'infantile': 3,
    'infantino': 4,
    'infantry': 3,
    'infantryman': 4,
    'infantrymen': 4,
    'infants': 2,
    'infarction': 3,
    'infatuate': 4,
    'infatuated': 5,
    'infatuates': 4,
    'infatuating': 5,
    'infatuation': 5,
    'infeasible': 4,
    'infect': 2,
    'infected': 3,
    'infecting': 3,
    'infection': 3,
    'infections': 3,
    'infectious': 3,
    'infective': 3,
    'infects': 2,
    'infer': 2,
    'inference': 3,
    'inferences': 4,
    'inferential': 4,
    'inferior': 4,
    'inferiority': 6,
    'infernal': 3,
    'inferno': 3,
    'inferred': 2,
    'inferring': 3,
    'infers': 2,
    'infertile': 3,
    'infertility': 5,
    'infest': 2,
    'infestation': 4,
    'infestations': 4,
    'infested': 3,
    'infests': 2,
    'infidel': 3,
    'infidelities': 5,
    'infidelity': 5,
    'infidels': 3,
    'infield': 2,
    'infielder': 3,
    'infielders': 3,
    'infighting': 3,
    'infiltrate': 3,
    'infiltrated': 4,
    'infiltrating': 4,
    'infiltration': 4,
    'infiltrator': 4,
    'infiltrators': 4,
    'infinger': 3,
    'infinite': 3,
    'infinitely': 4,
    'infinitesimal': 6,
    'infiniti': 4,
    'infinitive': 4,
    'infinitum': 4,
    'infinity': 4,
    'infirm': 2,
    'infirmary': 4,
    'infirmed': 2,
    'infirmities': 4,
    'infirmity': 4,
    'inflame': 2,
    'inflamed': 2,
    'inflames': 2,
    'inflaming': 3,
    'inflammable': 4,
    'inflammation': 4,
    'inflammatory': 5,
    'inflatable': 4,
    'inflate': 2,
    'inflated': 3,
    'inflates': 2,
    'inflating': 3,
    'inflation': 3,
    'inflationary': 5,
    'inflator': 3,
    'inflected': 3,
    'inflection': 3,
    'inflections': 3,
    'inflexibility': 6,
    'inflexible': 4,
    'inflexibly': 4,
    'inflict': 2,
    'inflicted': 3,
    'inflicting': 3,
    'infliction': 3,
    'inflicts': 2,
    'inflight': 2,
    'inflow': 2,
    'inflows': 2,
    'influence': 3,
    'influenced': 3,
    'influences': 4,
    'influencing': 4,
    'influential': 4,
    'influenza': 4,
    'influx': 2,
    'info': 2,
    'infocorp': 3,
    'infomercial': 4,
    'infomercials': 4,
    'inform': 2,
    'informal': 3,
    'informality': 5,
    'informally': 4,
    'informals': 3,
    'informant': 3,
    'informants': 3,
    'informatic': 4,
    'informatics': 4,
    'information': 4,
    'informational': 5,
    'informations': 4,
    'informative': 4,
    'informed': 2,
    'informer': 3,
    'informers': 3,
    'informing': 3,
    'informix': 3,
    'informs': 2,
    'infoscan': 3,
    'infotainment': 4,
    'infotech': 3,
    'infotechnology': 6,
    'infotron': 3,
    'infoworld': 3,
    'infra': 2,
    'infraction': 3,
    'infractions': 3,
    'infrared': 3,
    'infrastructural': 5,
    'infrastructure': 4,
    'infrastructures': 4,
    'infrequency': 4,
    'infrequent': 3,
    'infrequently': 4,
    'infringe': 2,
    'infringed': 2,
    'infringement': 3,
    'infringements': 3,
    'infringes': 3,
    'infringing': 3,
    'infuriate': 4,
    'infuriated': 5,
    'infuriates': 4,
    'infuriating': 5,
    'infuse': 2,
    'infused': 2,
    'infuses': 3,
    'infusing': 3,
    'infusion': 3,
    'infusions': 3,
    'ing': 1,
    'inga': 2,
    'ingalls': 2,
    'ingalsbe': 3,
    'ingar': 2,
    'ingber': 2,
    'inge': 1,
    'ingelheim': 3,
    'ingels': 2,
    'ingemar': 3,
    'ingenious': 3,
    'ingeniously': 4,
    'ingenito': 4,
    'ingenue': 3,
    'ingenuity': 5,
    'ingenuous': 4,
    'inger': 2,
    'ingersoll': 3,
    'ingerson': 3,
    'ingest': 2,
    'ingested': 3,
    'ingesting': 3,
    'ingestion': 3,
    'ingham': 2,
    'inghram': 2,
    'ingimarson': 4,
    'ingle': 2,
    'inglebert': 3,
    'inglenook': 3,
    'ingles': 2,
    'inglese': 2,
    'inglett': 2,
    'inglewood': 3,
    'inglis': 2,
    'inglish': 3,
    'inglorious': 4,
    'ingman': 2,
    'ingmar': 2,
    'ingmire': 2,
    'ingo': 2,
    'ingoglia': 4,
    'ingold': 2,
    'ingot': 2,
    'ingots': 2,
    'ingraham': 3,
    'ingrained': 2,
    'ingram': 2,
    'ingrao': 2,
    'ingrassia': 3,
    'ingratiate': 4,
    'ingratiating': 5,
    'ingredient': 4,
    'ingredients': 4,
    'ingria': 3,
    'ingrid': 2,
    'ingrum': 2,
    'ingvar': 2,
    'ingwersen': 3,
    'inhabit': 3,
    'inhabitant': 4,
    'inhabitants': 4,
    'inhabitation': 5,
    'inhabited': 4,
    'inhabiting': 4,
    'inhabits': 3,
    'inhalable': 4,
    'inhalant': 3,
    'inhalants': 3,
    'inhalation': 4,
    'inhale': 2,
    'inhaled': 2,
    'inhaler': 3,
    'inhalers': 3,
    'inhaling': 3,
    'inherent': 3,
    'inherently': 4,
    'inherit': 3,
    'inheritable': 5,
    'inheritance': 4,
    'inherited': 4,
    'inheriting': 4,
    'inheritor': 4,
    'inherits': 3,
    'inhibit': 3,
    'inhibited': 4,
    'inhibiting': 4,
    'inhibition': 4,
    'inhibitions': 4,
    'inhibitor': 4,
    'inhibitors': 4,
    'inhibitory': 5,
    'inhibits': 3,
    'inhofe': 2,
    'inhospitable': 5,
    'inhouse': 2,
    'inhuman': 3,
    'inhumane': 3,
    'inhumanity': 5,
    'iniga': 3,
    'iniguez': 3,
    'iniki': 3,
    'inikpratt': 3,
    'inimical': 4,
    'inimitable': 5,
    'iniquity': 4,
    'iniss': 2,
    'initable': 4,
    'initial': 3,
    'initialed': 3,
    'initialing': 4,
    'initialize': 4,
    'initialized': 4,
    'initialling': 4,
    'initially': 4,
    'initials': 3,
    'initiate': 4,
    'initiated': 5,
    'initiates': 4,
    'initiating': 5,
    'initiation': 5,
    'initiative': 4,
    'initiatives': 4,
    'initiator': 5,
    'initiators': 5,
    'initio': 4,
    'iniziativa': 6,
    'inject': 2,
    'injectable': 4,
    'injected': 3,
    'injecting': 3,
    'injection': 3,
    'injections': 3,
    'injector': 3,
    'injectors': 3,
    'injects': 2,
    'injudicious': 4,
    'injunction': 3,
    'injunctions': 3,
    'injunctive': 3,
    'injure': 2,
    'injured': 2,
    'injures': 2,
    'injuries': 3,
    'injuring': 3,
    'injurious': 4,
    'injury': 3,
    'injustice': 3,
    'injustices': 4,
    'ink': 1,
    'inkatha': 3,
    'inkblot': 2,
    'inkjet': 2,
    'inkling': 2,
    'inks': 1,
    'inky': 2,
    'inlaid': 2,
    'inland': 2,
    'inlaw': 2,
    'inlaws': 2,
    'inlay': 2,
    'inlet': 2,
    'inlets': 2,
    'inlex': 2,
    'inlow': 2,
    'inmac': 2,
    'inman': 2,
    'inmarsat': 3,
    'inmate': 2,
    'inmates': 2,
    'inmex': 2,
    'inmobiliaria': 7,
    'inmon': 2,
    'inmont': 2,
    'inn': 1,
    'innard': 2,
    'innards': 2,
    'innate': 2,
    'innately': 3,
    'inner': 2,
    'innermost': 3,
    'innerspace': 3,
    'innes': 2,
    'inness': 2,
    'inning': 2,
    'innings': 2,
    'innis': 2,
    'inniss': 2,
    'innkeeper': 3,
    'innkeepers': 3,
    'inno': 2,
    'innocence': 3,
    'innocent': 3,
    'innocenti': 4,
    'innocently': 4,
    'innocents': 3,
    'innoculate': 4,
    'innoculated': 5,
    'innoculates': 4,
    'innoculating': 5,
    'innoculation': 5,
    'innocuous': 4,
    'innominate': 4,
    'innopac': 3,
    'innovate': 3,
    'innovated': 4,
    'innovates': 3,
    'innovating': 4,
    'innovation': 4,
    'innovations': 4,
    'innovative': 4,
    'innovator': 4,
    'innovators': 4,
    'inns': 1,
    'innuendo': 4,
    'innuendoes': 4,
    'innuendos': 4,
    'innumerable': 5,
    'inocencio': 5,
    'inoculate': 4,
    'inoculated': 5,
    'inoculates': 4,
    'inoculation': 5,
    'inoculations': 5,
    'inoffensive': 4,
    'inoperable': 5,
    'inoperative': 5,
    'inopportune': 4,
    'inordinate': 4,
    'inordinately': 5,
    'inorganic': 4,
    'inoue': 3,
    'inouye': 3,
    'inpatient': 3,
    'inpatients': 3,
    'input': 2,
    'inputs': 2,
    'inquest': 2,
    'inquire': 2,
    'inquired': 3,
    'inquirer': 3,
    'inquires': 3,
    'inquiries': 4,
    'inquiring': 4,
    'inquiry': 3,
    'inquisition': 4,
    'inquisitive': 4,
    'inquisitor': 4,
    'inquisitors': 4,
    'inroad': 2,
    'inroads': 2,
    'ins': 1,
    'insalaco': 4,
    'insane': 2,
    'insanity': 4,
    'insatiable': 4,
    'inscho': 2,
    'insco': 2,
    'inscoe': 2,
    'inscore': 3,
    'inscribe': 2,
    'inscribed': 2,
    'inscribes': 2,
    'inscribing': 3,
    'inscription': 3,
    'inscriptions': 3,
    'inscrutable': 4,
    'insect': 2,
    'insecticide': 4,
    'insecticides': 4,
    'insectivorous': 5,
    'insects': 2,
    'insecure': 3,
    'insecurities': 5,
    'insecurity': 5,
    'insel': 2,
    'inseminate': 4,
    'insemination': 5,
    'insensible': 4,
    'insensitive': 4,
    'insensitivity': 6,
    'inseparable': 5,
    'inseparably': 5,
    'inserra': 3,
    'insert': 2,
    'inserted': 3,
    'inserting': 3,
    'insertion': 3,
    'inserts': 2,
    'inset': 2,
    'inshore': 2,
    'inside': 2,
    'insider': 3,
    'insiders': 3,
    'insides': 2,
    'insidious': 4,
    'insight': 2,
    'insightful': 3,
    'insights': 2,
    'insignia': 4,
    'insignificance': 5,
    'insignificant': 5,
    'insilco': 3,
    'insincere': 3,
    'insinuate': 4,
    'insinuated': 5,
    'insinuates': 4,
    'insinuating': 5,
    'insinuation': 5,
    'insinuations': 5,
    'insist': 2,
    'insisted': 3,
    'insistence': 3,
    'insistent': 3,
    'insistently': 4,
    'insisting': 3,
    'insists': 2,
    'insituform': 4,
    'inskeep': 2,
    'inskip': 2,
    'insko': 2,
    'inslaw': 2,
    'insley': 2,
    'insofar': 3,
    'insolence': 3,
    'insolent': 3,
    'insoluble': 4,
    'insolvencies': 4,
    'insolvency': 4,
    'insolvent': 3,
    'insomnia': 4,
    'insomniac': 4,
    'insouciance': 4,
    'insouciant': 3,
    'inspect': 2,
    'inspected': 3,
    'inspecting': 3,
    'inspection': 3,
    'inspections': 3,
    'inspector': 3,
    'inspectorate': 4,
    'inspectors': 3,
    'inspects': 2,
    'inspeech': 2,
    'inspiration': 4,
    'inspirational': 5,
    'inspirations': 4,
    'inspire': 2,
    'inspired': 3,
    'inspires': 2,
    'inspiring': 3,
    'instabilities': 5,
    'instability': 5,
    'install': 2,
    'installation': 4,
    'installations': 4,
    'installed': 2,
    'installer': 3,
    'installers': 3,
    'installing': 3,
    'installment': 3,
    'installments': 3,
    'installs': 2,
    'instance': 2,
    'instances': 3,
    'instant': 2,
    'instantaneous': 5,
    'instantaneously': 6,
    'instantiate': 4,
    'instantiated': 5,
    'instantiates': 4,
    'instantiating': 5,
    'instantiation': 5,
    'instantly': 3,
    'instead': 2,
    'instigate': 3,
    'instigated': 4,
    'instigates': 3,
    'instigating': 4,
    'instigation': 4,
    'instigator': 4,
    'instigators': 4,
    'instill': 2,
    'instilled': 2,
    'instilling': 3,
    'instills': 2,
    'instinct': 2,
    'instinctive': 3,
    'instinctively': 4,
    'instincts': 2,
    'instinet': 3,
    'institucional': 6,
    'institut': 3,
    'institute': 3,
    'instituted': 4,
    'institutes': 3,
    'instituting': 4,
    'institution': 4,
    'institutional': 5,
    'institutionalist': 6,
    'institutionalists': 6,
    'institutionalization': 8,
    'institutionalize': 6,
    'institutionalized': 6,
    'institutionalizes': 7,
    'institutionalizing': 7,
    'institutionally': 6,
    'institutions': 4,
    'instituto': 4,
    'instone': 2,
    'instruct': 2,
    'instructed': 3,
    'instructing': 3,
    'instruction': 3,
    'instructional': 4,
    'instructions': 3,
    'instructive': 3,
    'instructor': 3,
    'instructors': 3,
    'instructs': 2,
    'instrument': 3,
    'instrumental': 4,
    'instrumentalist': 5,
    'instrumentalists': 5,
    'instrumentality': 6,
    'instrumentals': 4,
    'instrumentation': 5,
    'instruments': 3,
    'insubordinate': 5,
    'insubordination': 6,
    'insubstantial': 4,
    'insubstantiate': 5,
    'insubstantiated': 6,
    'insufferable': 5,
    'insufficient': 4,
    'insufficiently': 5,
    'insular': 3,
    'insularity': 5,
    'insulate': 3,
    'insulated': 4,
    'insulating': 4,
    'insulation': 4,
    'insulator': 4,
    'insulators': 4,
    'insulin': 3,
    'insult': 2,
    'insulted': 3,
    'insulting': 3,
    'insults': 2,
    'insupportable': 5,
    'insurance': 3,
    'insurances': 4,
    'insure': 2,
    'insured': 2,
    'insurer': 3,
    'insurers': 3,
    'insures': 2,
    'insurgencies': 4,
    'insurgency': 4,
    'insurgent': 3,
    'insurgents': 3,
    'insuring': 3,
    'insurmountable': 5,
    'insurrection': 4,
    'intact': 2,
    'intaglio': 3,
    'intake': 2,
    'intan': 2,
    'intangible': 4,
    'intangibles': 4,
    'intangibly': 4,
    'intefadeh': 4,
    'integer': 3,
    'integers': 3,
    'integon': 3,
    'integra': 3,
    'integral': 3,
    'integrate': 3,
    'integrated': 4,
    'integrates': 3,
    'integrating': 4,
    'integration': 4,
    'integrations': 4,
    'integrator': 4,
    'integrators': 4,
    'integrelin': 4,
    'integrity': 4,
    'integument': 4,
    'intel': 2,
    'intelco': 3,
    'intelcom': 3,
    'intellect': 3,
    'intellects': 3,
    'intellectual': 5,
    'intellectualism': 7,
    'intellectually': 6,
    'intellectuals': 5,
    'intellicall': 4,
    'intellicorp': 4,
    'intelligence': 4,
    'intelligent': 4,
    'intelligently': 5,
    'intelligentsia': 6,
    'intelligible': 5,
    'intelligibly': 5,
    'intelogic': 4,
    'intelsat': 3,
    'intemperate': 4,
    'intend': 2,
    'intended': 3,
    'intending': 3,
    'intends': 2,
    'intense': 2,
    'intensely': 3,
    'intensification': 6,
    'intensified': 4,
    'intensifies': 4,
    'intensify': 4,
    'intensifying': 5,
    'intensity': 4,
    'intensive': 3,
    'intensively': 4,
    'intent': 2,
    'intention': 3,
    'intentional': 4,
    'intentionally': 5,
    'intentioned': 3,
    'intentions': 3,
    'intently': 3,
    'intents': 2,
    'inter': 2,
    'interacciones': 5,
    'interact': 3,
    'interacted': 4,
    'interacting': 4,
    'interaction': 4,
    'interactions': 4,
    'interactive': 4,
    'interactivity': 6,
    'interacts': 3,
    'interagency': 5,
    'interamerican': 6,
    'interand': 3,
    'interbank': 3,
    'interbred': 3,
    'interbrew': 3,
    'intercable': 4,
    'intercapital': 5,
    'intercare': 3,
    'intercede': 3,
    'interceded': 4,
    'interceding': 4,
    'intercellular': 5,
    'intercept': 3,
    'intercepted': 4,
    'intercepting': 4,
    'interception': 4,
    'interceptions': 4,
    'interceptor': 4,
    'interceptors': 4,
    'intercepts': 3,
    'intercession': 4,
    'interchange': 3,
    'interchangeable': 5,
    'interchangeably': 5,
    'interchanges': 4,
    'intercity': 4,
    'interco': 3,
    'intercollegiate': 5,
    'intercom': 3,
    'intercompany': 5,
    'interconnect': 4,
    'interconnected': 5,
    'interconnection': 5,
    'interconnections': 5,
    'intercontinental': 6,
    'intercontinentale': 6,
    'intercorp': 3,
    'intercorporation': 6,
    'intercourse': 3,
    'intercultural': 5,
    'intercurrent': 4,
    'interdealer': 4,
    'interdependence': 5,
    'interdependent': 5,
    'interdict': 3,
    'interdicted': 4,
    'interdictes': 3,
    'interdicting': 4,
    'interdiction': 4,
    'interdigital': 5,
    'interdisciplinary': 7,
    'interdyne': 3,
    'interest': 2,
    'interested': 3,
    'interesting': 3,
    'interestingly': 5,
    'interestrate': 4,
    'interests': 2,
    'interface': 3,
    'interfaces': 4,
    'interfaith': 3,
    'interfax': 3,
    'interfere': 3,
    'interfered': 3,
    'interference': 4,
    'interferes': 3,
    'interfering': 4,
    'interferometer': 6,
    'interferon': 4,
    'interferons': 4,
    'interfirst': 3,
    'interflug': 3,
    'interfunding': 4,
    'intergenerational': 7,
    'intergovernmental': 6,
    'intergraph': 3,
    'intergroup': 3,
    'interim': 3,
    'interior': 4,
    'interiors': 4,
    'interject': 3,
    'interjected': 4,
    'interjection': 4,
    'interjections': 4,
    'interjects': 3,
    'interlace': 3,
    'interlaced': 3,
    'interlake': 3,
    'interleaf': 3,
    'interleave': 3,
    'interleaved': 3,
    'interleaves': 3,
    'interleaving': 4,
    'interleukin': 4,
    'interlink': 3,
    'interlinked': 3,
    'interlock': 3,
    'interlocked': 3,
    'interlocking': 4,
    'interlocutor': 5,
    'interlocutors': 5,
    'interloper': 4,
    'interlopers': 4,
    'interlude': 3,
    'interludes': 3,
    'intermagnetic': 5,
    'intermagnetics': 5,
    'intermark': 3,
    'intermarket': 4,
    'intermarriage': 4,
    'intermarried': 4,
    'intermarry': 4,
    'intermec': 3,
    'intermedia': 5,
    'intermediaries': 6,
    'intermediary': 6,
    'intermediate': 5,
    'intermediates': 5,
    'intermedic': 4,
    'intermedics': 4,
    'intermet': 3,
    'interminable': 5,
    'interminably': 5,
    'intermingle': 4,
    'intermingled': 4,
    'intermingling': 5,
    'intermission': 4,
    'intermissions': 4,
    'intermittent': 4,
    'intermittently': 5,
    'intermix': 3,
    'intermixed': 3,
    'intermixing': 4,
    'intermodal': 4,
    'intermolecular': 6,
    'intermountain': 4,
    'intern': 2,
    'internacional': 5,
    'internal': 3,
    'internalize': 4,
    'internalized': 4,
    'internally': 4,
    'international': 5,
    'internationale': 6,
    'internationalism': 7,
    'internationalist': 6,
    'internationalists': 6,
    'internationalization': 8,
    'internationalize': 6,
    'internationalized': 6,
    'internationally': 6,
    'internationals': 5,
    'internatonal': 5,
    'internecine': 4,
    'interned': 2,
    'internee': 3,
    'internees': 3,
    'internet': 3,
    'internist': 3,
    'internists': 3,
    'internment': 3,
    'internorth': 3,
    'interns': 2,
    'internship': 3,
    'internships': 3,
    'interoffice': 4,
    'interoperate': 5,
    'interoperates': 5,
    'interpart': 3,
    'interpersonal': 5,
    'interplanetary': 6,
    'interplay': 3,
    'interpol': 3,
    'interpolate': 4,
    'interpolated': 5,
    'interpose': 3,
    'interpret': 3,
    'interpretation': 5,
    'interpretations': 5,
    'interpreted': 4,
    'interpreter': 4,
    'interpreters': 4,
    'interpreting': 4,
    'interpretive': 4,
    'interprets': 3,
    'interprovincial': 5,
    'interpublic': 4,
    'interracial': 4,
    'interrante': 4,
    'interred': 2,
    'interregnum': 4,
    'interrelate': 4,
    'interrelated': 5,
    'interrelationship': 6,
    'interrent': 3,
    'interrogate': 4,
    'interrogated': 5,
    'interrogating': 5,
    'interrogation': 5,
    'interrogations': 5,
    'interrogator': 5,
    'interrogatories': 6,
    'interrogators': 5,
    'interrogatory': 6,
    'interrupt': 3,
    'interrupted': 4,
    'interruptible': 5,
    'interrupting': 4,
    'interruption': 4,
    'interruptions': 4,
    'interrupts': 3,
    'interscholastic': 5,
    'interscope': 3,
    'intersec': 3,
    'intersect': 3,
    'intersected': 4,
    'intersecting': 4,
    'intersection': 4,
    'intersections': 4,
    'intersects': 3,
    'intersegment': 4,
    'interspec': 3,
    'intersperse': 3,
    'interspersed': 3,
    'intersperses': 4,
    'interstate': 3,
    'interstates': 3,
    'interstellar': 4,
    'intertan': 3,
    'intertech': 3,
    'intertechnology': 6,
    'intertect': 3,
    'intertidal': 4,
    'intertrans': 3,
    'intertribal': 4,
    'intertwine': 3,
    'intertwined': 3,
    'intertwining': 4,
    'interurban': 4,
    'interval': 3,
    'intervals': 3,
    'intervene': 3,
    'intervened': 3,
    'intervenes': 3,
    'intervening': 4,
    'intervenor': 4,
    'intervenors': 4,
    'intervention': 4,
    'interventionism': 6,
    'interventionist': 5,
    'interventionists': 5,
    'interventions': 4,
    'interview': 3,
    'interviewed': 3,
    'interviewee': 4,
    'interviewees': 4,
    'interviewer': 4,
    'interviewers': 4,
    'interviewing': 4,
    'interviews': 3,
    'intervoice': 3,
    'interweave': 3,
    'interweaving': 4,
    'interwoven': 4,
    'intestinal': 4,
    'intestine': 3,
    'intestines': 3,
    'intex': 2,
    'inti': 2,
    'intifada': 4,
    'intimacy': 4,
    'intimate': 3,
    'intimated': 4,
    'intimately': 4,
    'intimates': 3,
    'intimation': 4,
    'intimations': 4,
    'intimidate': 4,
    'intimidated': 5,
    'intimidates': 4,
    'intimidating': 5,
    'intimidation': 5,
    'intis': 2,
    'into': 2,
    'intolerable': 5,
    'intolerably': 5,
    'intolerance': 4,
    'intolerant': 4,
    'intonation': 4,
    'intonations': 4,
    'intone': 2,
    'intoned': 2,
    'intones': 2,
    'intoning': 3,
    'intourist': 3,
    'intoxicate': 4,
    'intoxicated': 5,
    'intoxicates': 4,
    'intoxicating': 5,
    'intoxication': 5,
    'intra': 2,
    'intracompany': 5,
    'intracranial': 5,
    'intractable': 4,
    'intraday': 3,
    'intramarginal': 5,
    'intramural': 4,
    'intransigence': 4,
    'intransigent': 4,
    'intraocular': 5,
    'intraparty': 4,
    'intraspecific': 5,
    'intrastate': 3,
    'intrauterine': 5,
    'intravenous': 4,
    'intravenously': 5,
    'intrawest': 3,
    'intrepid': 3,
    'intrepidly': 4,
    'intrex': 2,
    'intricacies': 4,
    'intricacy': 4,
    'intricate': 3,
    'intricately': 4,
    'intrie': 2,
    'intrieri': 3,
    'intrigue': 2,
    'intrigued': 2,
    'intrigues': 2,
    'intriguing': 3,
    'intriguingly': 4,
    'intrinsic': 3,
    'intrinsically': 5,
    'intro': 2,
    'introduce': 3,
    'introduced': 3,
    'introduces': 4,
    'introducing': 4,
    'introduction': 4,
    'introductions': 4,
    'introductory': 5,
    'intron': 2,
    'intros': 2,
    'introspect': 3,
    'introspection': 4,
    'introspective': 4,
    'introvert': 3,
    'introverted': 4,
    'intrude': 2,
    'intruded': 3,
    'intruder': 3,
    'intruders': 3,
    'intrudes': 2,
    'intruding': 3,
    'intrusion': 3,
    'intrusions': 3,
    'intrusive': 3,
    'intrusiveness': 4,
    'intuit': 3,
    'intuition': 4,
    'intuitive': 4,
    'intuitively': 5,
    'inuit': 2,
    'inundate': 3,
    'inundated': 4,
    'inundating': 4,
    'inundation': 4,
    'inundations': 4,
    'inure': 2,
    'inured': 2,
    'invacare': 3,
    'invade': 2,
    'invaded': 3,
    'invader': 3,
    'invaders': 3,
    'invades': 2,
    'invading': 3,
    'invalid': 3,
    'invalidate': 4,
    'invalidated': 5,
    'invalidating': 5,
    'invalidation': 5,
    'invalids': 3,
    'invaluable': 4,
    'invariable': 5,
    'invariably': 5,
    'invariance': 4,
    'invariant': 4,
    'invasion': 3,
    'invasions': 3,
    'invasive': 3,
    'invective': 3,
    'invent': 2,
    'invented': 3,
    'inventing': 3,
    'invention': 3,
    'inventions': 3,
    'inventive': 3,
    'inventiveness': 4,
    'inventor': 3,
    'inventoried': 4,
    'inventories': 4,
    'inventors': 3,
    'inventory': 4,
    'invents': 2,
    'inverlat': 3,
    'inverness': 3,
    'inverse': 2,
    'inversely': 3,
    'inversion': 3,
    'invert': 2,
    'invertebrate': 4,
    'invertebrates': 4,
    'inverted': 3,
    'invesco': 3,
    'invest': 2,
    'investable': 4,
    'investcorp': 3,
    'invested': 3,
    'investigate': 4,
    'investigated': 5,
    'investigates': 4,
    'investigating': 5,
    'investigation': 5,
    'investigational': 6,
    'investigations': 5,
    'investigative': 5,
    'investigator': 5,
    'investigators': 5,
    'investigatory': 6,
    'investimento': 5,
    'investing': 3,
    'investissements': 5,
    'investiture': 4,
    'investment': 3,
    'investments': 3,
    'investnet': 3,
    'investor': 3,
    'investors': 3,
    'invests': 2,
    'inveterate': 4,
    'invidious': 4,
    'invigorate': 4,
    'invigorated': 5,
    'invigorating': 5,
    'invincibility': 6,
    'invincible': 4,
    'inviolable': 5,
    'inviolate': 4,
    'invirase': 3,
    'invisibility': 6,
    'invisible': 4,
    'invisibles': 4,
    'invitation': 4,
    'invitational': 5,
    'invitations': 4,
    'invite': 2,
    'invited': 3,
    'invitee': 3,
    'invitees': 3,
    'invites': 2,
    'inviting': 3,
    'invitron': 3,
    'invocation': 4,
    'invoice': 2,
    'invoices': 3,
    'invoicing': 3,
    'invoke': 2,
    'invoked': 2,
    'invokes': 2,
    'invoking': 3,
    'involuntarily': 6,
    'involuntary': 5,
    'involve': 2,
    'involved': 2,
    'involvement': 3,
    'involvements': 3,
    'involves': 2,
    'involving': 3,
    'invulnerability': 7,
    'invulnerable': 5,
    'inward': 2,
    'inwardly': 3,
    'inwood': 2,
    'iny': 2,
    'inyokern': 3,
    'inzer': 2,
    'io': 2,
    'iodice': 3,
    'iodide': 3,
    'iodides': 3,
    'iodine': 3,
    'iola': 3,
    'iolande': 4,
    'iolanthe': 4,
    'iole': 3,
    'iomega': 4,
    'ion': 2,
    'iona': 3,
    'ionarde': 4,
    'ione': 3,
    'ionic': 3,
    'ionics': 3,
    'ionization': 5,
    'ionize': 3,
    'ionizer': 4,
    'ionizers': 4,
    'ionizing': 4,
    'ionosphere': 4,
    'ionospheric': 5,
    'ions': 2,
    'iorio': 4,
    'ios': 2,
    'iosif': 3,
    'iosue': 3,
    'iota': 3,
    'iott': 2,
    'iou': 3,
    'iovine': 4,
    'iovino': 4,
    'iowa': 3,
    'iowan': 3,
    'iowans': 3,
    'ip': 1,
    'ipalco': 3,
    'ipco': 2,
    'iphegenia': 5,
    'ipock': 2,
    'ippolito': 4,
    'ipsco': 2,
    'ipsen': 2,
    'iptay': 2,
    'iqbal': 2,
    'ira': 2,
    'iran': 2,
    'iranamok': 4,
    'irangate': 3,
    'irani': 3,
    'iranian': 4,
    'iranians': 4,
    'iranscam': 3,
    'iraq': 2,
    'iraqgate': 3,
    'iraqi': 3,
    'iraqis': 3,
    'iras': 2,
    'irascible': 4,
    'irascibly': 4,
    'irate': 2,
    'irbinna': 3,
    'irby': 2,
    'ire': 1,
    'ireene': 2,
    'irelan': 3,
    'ireland': 3,
    'irelands': 2,
    'irell': 2,
    'irena': 3,
    'irene': 2,
    'ireson': 3,
    'ireta': 3,
    'ireton': 3,
    'iretta': 3,
    'irette': 2,
    'irey': 2,
    'irian': 3,
    'irianese': 4,
    'irick': 2,
    'iridescent': 4,
    'iridium': 4,
    'irimajiri': 5,
    'irina': 3,
    'iris': 2,
    'irises': 3,
    'irish': 2,
    'irishman': 3,
    'irishness': 3,
    'irit': 2,
    'irizarry': 4,
    'irk': 1,
    'irked': 1,
    'irks': 1,
    'irksome': 2,
    'irkutsk': 2,
    'irlbeck': 2,
    'irma': 2,
    'iron': 2,
    'ironclad': 3,
    'ironed': 2,
    'ironic': 3,
    'ironical': 4,
    'ironically': 4,
    'ironies': 3,
    'ironing': 3,
    'ironize': 3,
    'ironized': 3,
    'ironizer': 4,
    'ironizes': 4,
    'ironizing': 4,
    'irons': 2,
    'ironside': 3,
    'ironsides': 3,
    'ironton': 2,
    'ironwood': 3,
    'irony': 3,
    'iroquois': 3,
    'irradiate': 4,
    'irradiated': 5,
    'irradiation': 5,
    'irrational': 4,
    'irrationality': 6,
    'irrationally': 5,
    'irrawaddy': 4,
    'irreconcilable': 6,
    'irredentism': 5,
    'irrefutable': 5,
    'irregardless': 4,
    'irregular': 4,
    'irregularities': 6,
    'irregularity': 6,
    'irregularly': 5,
    'irregulars': 4,
    'irrelevance': 4,
    'irrelevancy': 5,
    'irrelevant': 4,
    'irremediable': 6,
    'irreparable': 5,
    'irreparably': 5,
    'irreplaceable': 5,
    'irrepressible': 5,
    'irresistible': 5,
    'irresistibly': 5,
    'irrespective': 4,
    'irresponsibility': 7,
    'irresponsible': 5,
    'irresponsibly': 5,
    'irretrievably': 5,
    'irreverence': 4,
    'irreverent': 4,
    'irreversible': 5,
    'irreversibly': 5,
    'irrevocable': 5,
    'irrevocably': 5,
    'irrigate': 3,
    'irrigated': 4,
    'irrigation': 4,
    'irrigator': 4,
    'irrigators': 4,
    'irritability': 6,
    'irritable': 4,
    'irritant': 3,
    'irritants': 3,
    'irritate': 3,
    'irritated': 4,
    'irritates': 3,
    'irritating': 4,
    'irritation': 4,
    'irritations': 4,
    'irv': 1,
    'irve': 1,
    'irvette': 2,
    'irvin': 2,
    'irvine': 2,
    'irving': 2,
    'irwin': 2,
    'irwindale': 3,
    'is': 1,
    'isa': 2,
    'isaac': 2,
    'isaacks': 2,
    'isaacs': 2,
    'isaacson': 3,
    'isaak': 2,
    'isabel': 3,
    'isabell': 3,
    'isabella': 4,
    'isabelle': 3,
    'isackson': 3,
    'isadore': 4,
    'isaiah': 3,
    'isaksen': 3,
    'isakson': 3,
    'isaly': 2,
    'isautier': 3,
    'isay': 2,
    'isbell': 2,
    'isbill': 2,
    'iscariot': 4,
    'isch': 1,
    'ischemia': 4,
    'ischo': 2,
    'ise': 1,
    'iselin': 3,
    'iseman': 2,
    'iseminger': 4,
    'isenberg': 3,
    'isenhart': 3,
    'isenhour': 3,
    'isenhower': 4,
    'isensee': 3,
    'isentrope': 3,
    'isentropic': 4,
    'iser': 2,
    'isetan': 3,
    'isgrigg': 2,
    'isgro': 2,
    'isgur': 2,
    'ish': 1,
    'isham': 2,
    'ishaq': 2,
    'ishee': 2,
    'isherwood': 3,
    'ishi': 2,
    'ishibashi': 4,
    'ishida': 3,
    'ishihara': 4,
    'ishii': 3,
    'ishikawa': 4,
    'ishikawajima': 6,
    'ishikura': 4,
    'ishimura': 4,
    'ishler': 2,
    'ishmael': 2,
    'ishmail': 2,
    'ishman': 2,
    'ishtar': 2,
    'isidore': 3,
    'isikoff': 3,
    'isis': 2,
    'iskra': 2,
    'isla': 2,
    'islam': 2,
    'islamabad': 4,
    'islami': 3,
    'islamic': 3,
    'islamically': 4,
    'islamist': 3,
    'islamists': 3,
    'islamiya': 4,
    'islamization': 5,
    'island': 2,
    'islander': 3,
    'islanders': 3,
    'islandia': 4,
    'islands': 2,
    'islas': 2,
    'isle': 1,
    'isleen': 2,
    'isler': 2,
    'isles': 1,
    'islet': 2,
    'islets': 2,
    'isley': 2,
    'islip': 2,
    'ism': 2,
    'ismael': 2,
    'ismail': 2,
    'isms': 2,
    'isner': 2,
    'isocyanate': 5,
    'isoda': 3,
    'isoelectronic': 6,
    'isoetec': 3,
    'isogenic': 4,
    'isola': 3,
    'isolate': 3,
    'isolated': 4,
    'isolates': 3,
    'isolating': 4,
    'isolation': 4,
    'isolationism': 6,
    'isolationist': 5,
    'isolationists': 5,
    'isolde': 3,
    'isom': 2,
    'isomedix': 4,
    'isomer': 3,
    'isomers': 3,
    'isomorphism': 5,
    'ison': 2,
    'isoprinosine': 5,
    'isosceles': 4,
    'isothermal': 4,
    'isotoner': 4,
    'isotonic': 4,
    'isotope': 3,
    'isotopes': 3,
    'isotopic': 4,
    'isoxicam': 4,
    'ispra': 2,
    'israel': 3,
    'israeli': 3,
    'israelis': 3,
    'israelite': 3,
    'israelites': 3,
    'israeloff': 3,
    'israelson': 3,
    'israhi': 3,
    'isringhouser': 4,
    'issa': 2,
    'issac': 2,
    'issah': 2,
    'issam': 2,
    'issar': 2,
    'issie': 2,
    'issing': 2,
    'issuable': 4,
    'issuance': 3,
    'issuances': 4,
    'issue': 2,
    'issued': 2,
    'issueless': 3,
    'issuer': 3,
    'issuers': 3,
    'issues': 2,
    'issuing': 3,
    'issy': 2,
    'istanbul': 3,
    'istat': 2,
    'istel': 2,
    'isthmus': 2,
    'istituto': 4,
    'istook': 2,
    'istre': 2,
    'isty': 2,
    'isu': 2,
    'isuzu': 3,
    'it': 1,
    'ita': 2,
    'italia': 4,
    'italian': 3,
    'italiana': 5,
    'italianate': 4,
    'italiano': 5,
    'italians': 3,
    'italic': 3,
    'italics': 3,
    'italish': 3,
    'italtel': 3,
    'italy': 3,
    'itamar': 3,
    'itami': 3,
    'itar': 2,
    'itasca': 3,
    'itch': 1,
    'itches': 2,
    'itching': 2,
    'itchy': 2,
    'itek': 2,
    'itel': 2,
    'item': 2,
    'itemize': 3,
    'itemized': 3,
    'itemizer': 4,
    'itemizers': 4,
    'itemizes': 4,
    'itemizing': 4,
    'items': 2,
    'iten': 2,
    'iterative': 4,
    'ithaca': 3,
    'itinerant': 4,
    'itineraries': 5,
    'itinerary': 5,
    'itkin': 2,
    'ito': 2,
    'itochu': 3,
    'itoh': 2,
    'its': 1,
    'itself': 2,
    'itstee': 2,
    'itsy': 2,
    'ittner': 2,
    'itty': 2,
    'itzhak': 2,
    'itzkowitz': 3,
    'iuliano': 5,
    'iva': 2,
    'ivaco': 3,
    'ivan': 2,
    'ivana': 3,
    'ivancic': 3,
    'ivane': 2,
    'ivanhoe': 3,
    'ivanko': 3,
    'ivanna': 3,
    'ivanoff': 3,
    'ivanov': 3,
    'ivanyenko': 4,
    'ivar': 2,
    'ivatans': 3,
    'ivax': 2,
    'iven': 2,
    'ivens': 2,
    'iver': 2,
    'ivern': 2,
    'ivers': 2,
    'iversen': 3,
    'iverson': 3,
    'ivery': 3,
    'ives': 1,
    'ivester': 3,
    'ivey': 2,
    'ivie': 2,
    'ivins': 2,
    'ivo': 3,
    'ivor': 2,
    'ivorian': 4,
    'ivorians': 4,
    'ivory': 3,
    'ivy': 2,
    'iwai': 2,
    'iwaki': 3,
    'iwamoto': 4,
    'iwan': 2,
    'iwanicki': 4,
    'iwanski': 3,
    'iwasaki': 4,
    'iwata': 3,
    'iwate': 2,
    'iwen': 2,
    'iwerks': 2,
    'iwinski': 3,
    'iwo': 2,
    'iwosaki': 4,
    'ixion': 3,
    'iyer': 2,
    'izaguirre': 4,
    'izansky': 3,
    'izard': 2,
    'izatt': 2,
    'izetbegovic': 5,
    'izod': 2,
    'izquierdo': 3,
    'izumi': 3,
    'izvestia': 4,
    'izzard': 2,
    'izzi': 2,
    'izzo': 2,
    'izzy': 2,
    'j': 1,
    'ja': 1,
    'jaan': 1,
    'jab': 1,
    'jabah': 2,
    'jabaliya': 4,
    'jabar': 2,
    'jabbar': 2,
    'jabbed': 1,
    'jabbing': 2,
    'jabbour': 2,
    'jaber': 2,
    'jablon': 2,
    'jablonowski': 4,
    'jablonski': 3,
    'jablonsky': 3,
    'jabril': 2,
    'jabs': 1,
    'jac': 1,
    'jacek': 2,
    'jachim': 2,
    'jacinda': 3,
    'jacinta': 3,
    'jacinth': 2,
    'jacintha': 3,
    'jacinthe': 2,
    'jacinto': 3,
    'jack': 1,
    'jacka': 2,
    'jackal': 2,
    'jackass': 2,
    'jacked': 1,
    'jackel': 2,
    'jackelyn': 3,
    'jacket': 2,
    'jacketed': 3,
    'jackets': 2,
    'jackett': 2,
    'jackhammer': 3,
    'jackhammers': 3,
    'jacki': 2,
    'jackie': 2,
    'jacking': 2,
    'jackley': 2,
    'jacklin': 2,
    'jackman': 2,
    'jacko': 2,
    'jackoby': 3,
    'jackovich': 3,
    'jackowski': 3,
    'jackpot': 2,
    'jackpots': 2,
    'jackrabbit': 3,
    'jacks': 1,
    'jacksdeit': 3,
    'jackson': 2,
    'jacksonian': 4,
    'jacksonians': 4,
    'jacksons': 2,
    'jacksonville': 3,
    'jacky': 2,
    'jaclyn': 2,
    'jaco': 2,
    'jacob': 2,
    'jacoba': 3,
    'jacobe': 2,
    'jacobean': 4,
    'jacober': 3,
    'jacobi': 3,
    'jacobin': 3,
    'jacobina': 4,
    'jacobine': 3,
    'jacobite': 3,
    'jacobites': 3,
    'jacobo': 3,
    'jacoboski': 4,
    'jacobowitz': 4,
    'jacobs': 2,
    'jacobsen': 3,
    'jacobson': 3,
    'jacobus': 3,
    'jacoby': 3,
    'jacome': 2,
    'jacor': 2,
    'jacot': 2,
    'jacox': 2,
    'jacque': 1,
    'jacqueline': 3,
    'jacquelyn': 3,
    'jacques': 1,
    'jacquet': 2,
    'jacquetta': 3,
    'jacquez': 2,
    'jacquie': 2,
    'jacquin': 2,
    'jacquot': 2,
    'jacuzzi': 3,
    'jade': 1,
    'jaded': 2,
    'jadeite': 2,
    'jades': 1,
    'jadwin': 2,
    'jae': 1,
    'jaeckel': 2,
    'jaegar': 2,
    'jaeger': 2,
    'jaegers': 2,
    'jaekel': 2,
    'jaenicke': 2,
    'jafco': 2,
    'jaffa': 2,
    'jaffar': 2,
    'jaffe': 2,
    'jaffee': 2,
    'jaffey': 2,
    'jaffna': 2,
    'jaffray': 2,
    'jaffrey': 2,
    'jagdish': 2,
    'jager': 2,
    'jagged': 1,
    'jagger': 2,
    'jaggers': 2,
    'jagiello': 3,
    'jagielski': 3,
    'jaglom': 2,
    'jago': 2,
    'jagoda': 3,
    'jagodzinski': 4,
    'jagow': 2,
    'jags': 1,
    'jagt': 1,
    'jaguar': 2,
    'jaguars': 2,
    'jahn': 1,
    'jahner': 2,
    'jahnke': 1,
    'jahns': 1,
    'jahr': 1,
    'jai': 1,
    'jail': 1,
    'jailed': 1,
    'jailer': 2,
    'jailers': 2,
    'jailhouse': 2,
    'jailing': 2,
    'jails': 1,
    'jaime': 2,
    'jaimes': 2,
    'jaimie': 2,
    'jain': 1,
    'jainism': 3,
    'jaisigher': 3,
    'jakab': 2,
    'jakarta': 3,
    'jake': 1,
    'jakel': 2,
    'jakes': 1,
    'jakeway': 2,
    'jakie': 2,
    'jakob': 2,
    'jakobsen': 3,
    'jakubek': 3,
    'jakubiak': 4,
    'jakubik': 3,
    'jakubowicz': 4,
    'jakubowski': 4,
    'jalalabad': 4,
    'jalapeno': 4,
    'jalbert': 2,
    'jalopy': 3,
    'jam': 1,
    'jama': 2,
    'jamaica': 3,
    'jamaican': 3,
    'jamaicans': 3,
    'jamail': 2,
    'jamal': 2,
    'jamar': 2,
    'jamb': 1,
    'jambor': 2,
    'jamerson': 3,
    'james': 1,
    'jameson': 2,
    'jamestown': 2,
    'jamesway': 2,
    'jami': 2,
    'jamie': 2,
    'jamieson': 3,
    'jamila': 3,
    'jamison': 3,
    'jammed': 1,
    'jammer': 2,
    'jammers': 2,
    'jamming': 2,
    'jammu': 2,
    'jamrog': 2,
    'jamroz': 2,
    'jams': 1,
    'jamshedpur': 3,
    'jamu': 2,
    'jan': 1,
    'jana': 2,
    'janacek': 3,
    'janachowski': 4,
    'janak': 2,
    'janardhan': 3,
    'janas': 2,
    'janata': 3,
    'janca': 2,
    'janco': 2,
    'janczak': 2,
    'janda': 2,
    'jandreau': 2,
    'jandt': 1,
    'jane': 1,
    'janeane': 2,
    'janecek': 3,
    'janeczko': 3,
    'janeiro': 3,
    'janek': 2,
    'janel': 2,
    'janella': 3,
    'janelle': 2,
    'janes': 1,
    'janesville': 2,
    'janet': 2,
    'janette': 2,
    'janeway': 2,
    'janey': 2,
    'jang': 1,
    'jangle': 2,
    'jangled': 2,
    'jani': 2,
    'janiak': 3,
    'janice': 2,
    'janicek': 3,
    'janick': 2,
    'janicke': 2,
    'janicki': 3,
    'janie': 2,
    'janiero': 3,
    'janiga': 3,
    'janii': 2,
    'janik': 2,
    'janikowski': 4,
    'janina': 3,
    'janine': 2,
    'janis': 2,
    'janisch': 2,
    'janish': 2,
    'janiszewski': 4,
    'janitor': 3,
    'janitorial': 5,
    'janitors': 3,
    'jank': 1,
    'janka': 2,
    'janke': 1,
    'jankiewicz': 3,
    'janklow': 2,
    'janko': 2,
    'jankovic': 3,
    'jankovich': 3,
    'jankowiak': 4,
    'jankowski': 3,
    'jann': 1,
    'janna': 2,
    'janney': 2,
    'janning': 2,
    'jannock': 2,
    'jannotta': 3,
    'janoff': 2,
    'janofsky': 3,
    'janos': 2,
    'janosik': 3,
    'janoski': 3,
    'janosko': 3,
    'janosky': 3,
    'janota': 3,
    'janousek': 3,
    'janovsky': 3,
    'janow': 2,
    'janowiak': 4,
    'janowicz': 3,
    'janowitz': 3,
    'janowski': 3,
    'jans': 1,
    'jansa': 2,
    'jansen': 2,
    'jansenism': 4,
    'jansenist': 3,
    'jansenists': 3,
    'jansky': 2,
    'jansma': 2,
    'janson': 2,
    'janssen': 2,
    'janssens': 2,
    'jansson': 2,
    'janthina': 3,
    'jantz': 1,
    'jantzen': 2,
    'january': 4,
    'janus': 2,
    'janusz': 2,
    'januszewski': 4,
    'janvier': 3,
    'janvrin': 2,
    'janz': 1,
    'janzen': 2,
    'jap': 1,
    'japan': 2,
    'japanese': 3,
    'japans': 2,
    'japonica': 4,
    'japs': 1,
    'jaqua': 2,
    'jaquay': 2,
    'jaques': 1,
    'jaquess': 2,
    'jaquez': 2,
    'jaquish': 2,
    'jaquith': 2,
    'jar': 1,
    'jara': 2,
    'jaramillo': 4,
    'jarara': 3,
    'jarash': 2,
    'jarboe': 2,
    'jarchow': 2,
    'jardin': 2,
    'jardine': 2,
    'jarecki': 3,
    'jared': 2,
    'jarema': 3,
    'jares': 1,
    'jargon': 2,
    'jarislowsky': 4,
    'jarman': 2,
    'jarmin': 2,
    'jarmon': 2,
    'jarnagin': 3,
    'jarnigan': 3,
    'jaromir': 3,
    'jaros': 2,
    'jarosz': 2,
    'jaroszewski': 4,
    'jaroszynski': 4,
    'jarrard': 2,
    'jarratt': 2,
    'jarreau': 2,
    'jarred': 1,
    'jarrell': 2,
    'jarrells': 2,
    'jarrett': 2,
    'jarriel': 2,
    'jarring': 2,
    'jarrod': 2,
    'jarryd': 2,
    'jars': 1,
    'jaruzelski': 4,
    'jarvi': 2,
    'jarvia': 3,
    'jarvie': 2,
    'jarvik': 2,
    'jarvinen': 3,
    'jarvis': 2,
    'jaryl': 2,
    'jarzombek': 3,
    'jas': 1,
    'jasek': 2,
    'jasinowski': 4,
    'jasinski': 3,
    'jaskiewicz': 3,
    'jasko': 2,
    'jaskolski': 3,
    'jaskot': 2,
    'jaskowiak': 4,
    'jaskulski': 3,
    'jasmer': 3,
    'jasmin': 2,
    'jasmina': 3,
    'jasmine': 2,
    'jaso': 2,
    'jason': 2,
    'jaspal': 2,
    'jasper': 2,
    'jaspers': 2,
    'jasperson': 3,
    'jass': 1,
    'jasso': 2,
    'jaster': 2,
    'jauch': 1,
    'jaudon': 2,
    'jaundice': 2,
    'jaundiced': 2,
    'jaunt': 1,
    'jauntily': 3,
    'jaunts': 1,
    'jaunty': 2,
    'jauregui': 3,
    'jaurequi': 3,
    'java': 2,
    'javan': 2,
    'javanese': 3,
    'javascript': 3,
    'javelin': 3,
    'javett': 2,
    'javier': 3,
    'javits': 2,
    'javorsky': 3,
    'jaw': 1,
    'jawad': 2,
    'jawbone': 2,
    'jawboning': 3,
    'jawed': 1,
    'jaworowski': 4,
    'jaworski': 3,
    'jaws': 1,
    'jay': 1,
    'jaya': 2,
    'jayachandra': 4,
    'jayashankar': 4,
    'jaycee': 2,
    'jaycees': 2,
    'jaycox': 2,
    'jaye': 1,
    'jayewardene': 3,
    'jayhawker': 3,
    'jayme': 2,
    'jaymes': 2,
    'jayne': 1,
    'jaynes': 1,
    'jayroe': 2,
    'jays': 1,
    'jayson': 2,
    'jaywalk': 2,
    'jaywalking': 3,
    'jazmin': 2,
    'jazz': 1,
    'jazzed': 1,
    'jazzman': 2,
    'jazzmen': 2,
    'jazzy': 2,
    'je': 1,
    'jealous': 2,
    'jealousies': 3,
    'jealously': 3,
    'jealousness': 3,
    'jealousy': 3,
    'jean': 1,
    'jeana': 2,
    'jeanbaptiste': 3,
    'jeanbertrand': 3,
    'jeancourt': 2,
    'jeane': 1,
    'jeaner': 2,
    'jeanerette': 2,
    'jeanes': 1,
    'jeanette': 2,
    'jeaney': 2,
    'jeanie': 2,
    'jeanine': 2,
    'jeanlouis': 3,
    'jeanmichele': 3,
    'jeanne': 1,
    'jeannette': 2,
    'jeannie': 2,
    'jeanniot': 3,
    'jeanpierre': 3,
    'jeans': 1,
    'jeansonne': 2,
    'jeb': 1,
    'jech': 1,
    'jed': 1,
    'jedlicka': 3,
    'jee': 1,
    'jeep': 1,
    'jeepney': 2,
    'jeeps': 1,
    'jeer': 1,
    'jeered': 1,
    'jeering': 2,
    'jeers': 1,
    'jees': 1,
    'jeeter': 2,
    'jeez': 1,
    'jeeze': 1,
    'jefcoat': 2,
    'jeff': 1,
    'jeffcoat': 2,
    'jeffe': 1,
    'jeffer': 2,
    'jefferey': 3,
    'jefferies': 2,
    'jefferis': 3,
    'jeffers': 2,
    'jefferson': 3,
    'jeffersonian': 5,
    'jeffersonians': 5,
    'jeffery': 3,
    'jefferys': 3,
    'jeffey': 2,
    'jeffords': 2,
    'jeffress': 2,
    'jeffrey': 2,
    'jeffreys': 2,
    'jeffries': 2,
    'jeffry': 2,
    'jeffs': 1,
    'jeffus': 2,
    'jeffy': 2,
    'jehle': 2,
    'jehovah': 3,
    'jekel': 2,
    'jekyll': 2,
    'jelen': 2,
    'jelinek': 3,
    'jelinski': 3,
    'jelisic': 3,
    'jelks': 1,
    'jell': 1,
    'jelled': 1,
    'jelley': 2,
    'jellied': 2,
    'jellies': 2,
    'jellison': 3,
    'jello': 2,
    'jelly': 2,
    'jellyfish': 3,
    'jelton': 2,
    'jem': 1,
    'jemie': 2,
    'jemima': 3,
    'jemison': 3,
    'jemmie': 2,
    'jemmott': 2,
    'jen': 1,
    'jena': 2,
    'jencks': 1,
    'jendro': 2,
    'jenin': 2,
    'jenison': 3,
    'jenkens': 2,
    'jenkin': 2,
    'jenkins': 2,
    'jenkinson': 3,
    'jenks': 1,
    'jenn': 1,
    'jenna': 2,
    'jenne': 1,
    'jenner': 2,
    'jenness': 2,
    'jennett': 2,
    'jennette': 2,
    'jennewein': 3,
    'jenney': 2,
    'jenni': 2,
    'jennie': 2,
    'jennifer': 3,
    'jenning': 2,
    'jenninger': 3,
    'jennings': 2,
    'jennison': 3,
    'jenny': 2,
    'jenrette': 2,
    'jens': 1,
    'jensen': 2,
    'jenson': 2,
    'jenssen': 2,
    'jent': 1,
    'jentsch': 1,
    'jentz': 1,
    'jeong': 1,
    'jeopardize': 3,
    'jeopardized': 3,
    'jeopardizes': 4,
    'jeopardizing': 4,
    'jeopardy': 3,
    'jeppesen': 3,
    'jeppson': 2,
    'jepsen': 2,
    'jepson': 2,
    'jerabek': 3,
    'jerald': 2,
    'jerboas': 3,
    'jerde': 1,
    'jerden': 2,
    'jere': 2,
    'jereb': 2,
    'jereissati': 4,
    'jerel': 2,
    'jereld': 2,
    'jeremia': 4,
    'jeremiad': 4,
    'jeremiah': 4,
    'jeremy': 3,
    'jerez': 2,
    'jergens': 2,
    'jergenson': 3,
    'jerger': 2,
    'jeri': 2,
    'jericho': 3,
    'jerk': 1,
    'jerked': 1,
    'jerking': 2,
    'jerkins': 2,
    'jerks': 1,
    'jerky': 2,
    'jermaine': 2,
    'jerman': 2,
    'jermyn': 2,
    'jernberg': 2,
    'jernigan': 3,
    'jeroboam': 4,
    'jerod': 2,
    'jerold': 2,
    'jerome': 2,
    'jerr': 1,
    'jerrell': 2,
    'jerri': 2,
    'jerrico': 3,
    'jerrie': 2,
    'jerris': 2,
    'jerritts': 2,
    'jerrold': 2,
    'jerrome': 3,
    'jerry': 2,
    'jersey': 2,
    'jerseys': 2,
    'jersian': 3,
    'jersians': 3,
    'jerusalem': 4,
    'jerusha': 3,
    'jervis': 2,
    'jerzak': 2,
    'jerzy': 2,
    'jeschke': 1,
    'jeske': 1,
    'jesmer': 3,
    'jesper': 2,
    'jespersen': 3,
    'jesperson': 3,
    'jess': 1,
    'jessalynn': 3,
    'jessamine': 3,
    'jessamyn': 3,
    'jesse': 2,
    'jessee': 2,
    'jesselson': 3,
    'jessen': 2,
    'jesser': 2,
    'jessica': 3,
    'jessie': 2,
    'jesson': 2,
    'jessop': 2,
    'jessup': 2,
    'jessy': 2,
    'jessye': 2,
    'jest': 1,
    'jester': 2,
    'jesters': 2,
    'jests': 1,
    'jesudason': 4,
    'jesuit': 3,
    'jesuits': 3,
    'jesup': 2,
    'jesus': 2,
    'jet': 1,
    'jetborne': 2,
    'jeter': 2,
    'jethro': 2,
    'jetliner': 3,
    'jetliners': 3,
    'jetro': 2,
    'jets': 1,
    'jetson': 2,
    'jetsons': 2,
    'jetstream': 2,
    'jetstreams': 2,
    'jett': 1,
    'jetta': 2,
    'jette': 1,
    'jetted': 2,
    'jetter': 2,
    'jetting': 2,
    'jettison': 3,
    'jettisoned': 3,
    'jettisoning': 4,
    'jetton': 2,
    'jetty': 2,
    'jeumont': 2,
    'jew': 1,
    'jewel': 2,
    'jewelcor': 3,
    'jeweler': 3,
    'jewelers': 3,
    'jewell': 2,
    'jewellers': 3,
    'jewelmaster': 4,
    'jewelmasters': 4,
    'jewelry': 3,
    'jewels': 2,
    'jewelsburg': 3,
    'jewess': 2,
    'jewett': 2,
    'jewfish': 2,
    'jewfishes': 3,
    'jewish': 2,
    'jewishness': 3,
    'jewitt': 2,
    'jewkes': 1,
    'jewry': 2,
    'jews': 1,
    'jex': 1,
    'jeyaretnam': 4,
    'jezek': 2,
    'jezewski': 3,
    'jezierski': 3,
    'jeziorski': 4,
    'jfet': 1,
    'jha': 1,
    'jheel': 1,
    'jheryl': 2,
    'jhirmack': 2,
    'ji': 1,
    'jia': 2,
    'jian': 2,
    'jiang': 1,
    'jiangsu': 2,
    'jiar': 2,
    'jiawen': 2,
    'jib': 1,
    'jibe': 1,
    'jibes': 1,
    'jicha': 2,
    'jie': 1,
    'jif': 1,
    'jifeng': 2,
    'jiffy': 2,
    'jig': 1,
    'jiggetts': 2,
    'jiggle': 2,
    'jiggling': 3,
    'jigs': 1,
    'jigsaw': 2,
    'jihad': 2,
    'jil': 1,
    'jilek': 2,
    'jiles': 1,
    'jill': 1,
    'jillian': 3,
    'jillion': 2,
    'jillions': 2,
    'jillson': 2,
    'jilt': 1,
    'jilted': 2,
    'jim': 1,
    'jima': 2,
    'jimena': 3,
    'jimenez': 3,
    'jimerson': 3,
    'jimi': 2,
    'jiminez': 3,
    'jimison': 3,
    'jimmerson': 3,
    'jimmie': 2,
    'jimmied': 2,
    'jimmy': 2,
    'jin': 1,
    'jindo': 2,
    'jindra': 2,
    'jines': 1,
    'jing': 1,
    'jingle': 2,
    'jingles': 2,
    'jingoism': 4,
    'jingoistic': 4,
    'jingsheng': 2,
    'jinkins': 2,
    'jinks': 1,
    'jinright': 2,
    'jinx': 1,
    'jipson': 2,
    'jirak': 2,
    'jirga': 2,
    'jiri': 2,
    'jirik': 2,
    'jiron': 2,
    'jirsa': 2,
    'jitney': 2,
    'jitterbug': 3,
    'jitterbugging': 4,
    'jitters': 2,
    'jittery': 3,
    'jive': 1,
    'jividen': 3,
    'jiving': 2,
    'jna': 3,
    'jo': 1,
    'joachim': 3,
    'joachims': 3,
    'joakima': 3,
    'joan': 1,
    'joann': 2,
    'joanna': 3,
    'joanne': 2,
    'joanou': 2,
    'joao': 2,
    'joaquim': 2,
    'joaquin': 2,
    'job': 1,
    'jobber': 2,
    'jobbers': 2,
    'jobe': 1,
    'jobes': 1,
    'jobete': 2,
    'jobin': 2,
    'jobina': 3,
    'jobless': 2,
    'joblessness': 3,
    'jobs': 1,
    'jobson': 2,
    'jobst': 1,
    'jobyna': 3,
    'jocelin': 2,
    'joceline': 2,
    'jocelyn': 2,
    'jocelyne': 2,
    'jochem': 2,
    'jochen': 2,
    'jochim': 2,
    'jochum': 2,
    'jock': 1,
    'jockey': 2,
    'jockeying': 3,
    'jockeys': 2,
    'jocks': 1,
    'jocosa': 3,
    'jocular': 3,
    'jocylan': 2,
    'jodi': 2,
    'jodie': 2,
    'jodohur': 2,
    'jodoin': 2,
    'jody': 2,
    'joe': 1,
    'joedicke': 2,
    'joel': 2,
    'joelle': 2,
    'joelson': 2,
    'joens': 1,
    'joerg': 1,
    'joerger': 2,
    'joers': 2,
    'joes': 1,
    'joette': 2,
    'joey': 2,
    'joffe': 1,
    'joffrey': 2,
    'joffrion': 3,
    'jog': 1,
    'jogged': 1,
    'jogger': 2,
    'joggers': 2,
    'jogging': 2,
    'jogs': 1,
    'joh': 1,
    'johan': 2,
    'johann': 2,
    'johanna': 3,
    'johannes': 3,
    'johannesburg': 4,
    'johannesen': 4,
    'johannessen': 4,
    'johanning': 3,
    'johanns': 2,
    'johannsen': 3,
    'johansen': 3,
    'johanson': 3,
    'johansson': 3,
    'john': 1,
    'johnathan': 3,
    'johnathon': 3,
    'johndrow': 2,
    'johnie': 2,
    'johnnie': 2,
    'johnny': 2,
    'johns': 1,
    'johnsen': 2,
    'johnsey': 2,
    'johnson': 2,
    'johnsons': 2,
    'johnsrud': 2,
    'johnston': 2,
    'johnstone': 2,
    'johnstown': 2,
    'johnta': 2,
    'johny': 2,
    'johson': 2,
    'joice': 1,
    'joie': 1,
    'join': 1,
    'joined': 1,
    'joiner': 2,
    'joinery': 3,
    'joines': 1,
    'joining': 2,
    'joins': 1,
    'joint': 1,
    'jointed': 2,
    'jointly': 2,
    'jointness': 2,
    'joints': 1,
    'joist': 1,
    'joists': 1,
    'jojoba': 3,
    'jojola': 3,
    'joke': 1,
    'jokebook': 2,
    'jokebooks': 2,
    'joked': 1,
    'jokela': 3,
    'joker': 2,
    'jokers': 2,
    'jokerst': 2,
    'jokes': 1,
    'jokester': 2,
    'jokhang': 2,
    'joki': 2,
    'jokinen': 3,
    'joking': 2,
    'jokingly': 3,
    'jolene': 2,
    'joles': 1,
    'joletta': 3,
    'jolette': 2,
    'jolicoeur': 3,
    'joliet': 3,
    'jolin': 2,
    'jolivette': 3,
    'jolla': 2,
    'jolley': 2,
    'jollie': 2,
    'jollier': 3,
    'jollies': 2,
    'jolliest': 3,
    'jolliff': 2,
    'jolliffe': 2,
    'jolly': 2,
    'jollying': 3,
    'jolson': 2,
    'jolt': 1,
    'jolted': 2,
    'jolting': 2,
    'jolts': 1,
    'joly': 2,
    'jon': 1,
    'jonah': 2,
    'jonas': 2,
    'jonassaint': 3,
    'jonassen': 3,
    'jonathan': 3,
    'jonathon': 3,
    'jonbenet': 3,
    'joncas': 2,
    'jone': 1,
    'jones': 1,
    'jonesboro': 3,
    'joneses': 2,
    'jonestown': 2,
    'jong': 1,
    'joni': 2,
    'jonker': 2,
    'jonn': 1,
    'jonothan': 3,
    'jonsman': 2,
    'jonson': 2,
    'jonsson': 2,
    'joo': 1,
    'joon': 1,
    'joong': 1,
    'joos': 1,
    'joost': 1,
    'joosten': 2,
    'joplin': 2,
    'jopling': 3,
    'jopp': 1,
    'jordache': 2,
    'jordahl': 2,
    'jordan': 2,
    'jordana': 3,
    'jordanian': 4,
    'jordanians': 4,
    'jordans': 2,
    'jorde': 1,
    'jorden': 2,
    'jordi': 2,
    'jordon': 2,
    'jordy': 2,
    'jorge': 2,
    'jorgensen': 3,
    'jorgenson': 3,
    'jorio': 3,
    'jornal': 2,
    'jorstad': 2,
    'jory': 2,
    'jos': 1,
    'josceline': 3,
    'joscelyn': 3,
    'joscelyne': 3,
    'jose': 2,
    'josef': 2,
    'josefina': 4,
    'joseph': 2,
    'josepha': 3,
    'josephina': 4,
    'josephine': 3,
    'josephs': 2,
    'josephson': 3,
    'josephthal': 3,
    'josephus': 3,
    'joser': 2,
    'josette': 2,
    'josey': 2,
    'josh': 1,
    'joshi': 2,
    'joshua': 3,
    'josiah': 3,
    'josie': 2,
    'josleyn': 2,
    'joslin': 2,
    'joslyn': 2,
    'jospin': 2,
    'joss': 1,
    'josselyn': 3,
    'jost': 1,
    'josten': 2,
    'jostens': 2,
    'jostes': 1,
    'jostle': 2,
    'jostled': 2,
    'jostling': 3,
    'joswick': 2,
    'jot': 1,
    'jotted': 2,
    'joubert': 2,
    'jouett': 2,
    'joule': 1,
    'joules': 1,
    'joulwan': 2,
    'jour': 1,
    'jourdan': 2,
    'jouret': 2,
    'journal': 2,
    'journalese': 3,
    'journalism': 4,
    'journalist': 3,
    'journalistic': 4,
    'journalistically': 6,
    'journalists': 3,
    'journals': 2,
    'journey': 2,
    'journeyed': 2,
    'journeying': 3,
    'journeyman': 3,
    'journeys': 2,
    'joust': 1,
    'jousting': 2,
    'jovan': 2,
    'jovanovic': 4,
    'jovanovich': 4,
    'jovi': 2,
    'jovial': 3,
    'jovian': 3,
    'jovita': 3,
    'jovito': 3,
    'jowers': 2,
    'jowett': 2,
    'jowl': 1,
    'jowley': 2,
    'jowls': 1,
    'jowly': 2,
    'joy': 1,
    'joyal': 2,
    'joyce': 1,
    'joycelyn': 2,
    'joye': 1,
    'joyful': 2,
    'joyfully': 3,
    'joying': 2,
    'joyner': 2,
    'joynes': 1,
    'joynt': 1,
    'joyoni': 3,
    'joyous': 2,
    'joyride': 2,
    'joyriding': 3,
    'joys': 1,
    'joystick': 2,
    'jozef': 2,
    'jozwiak': 3,
    'jr': 2,
    'ju': 1,
    'jua': 2,
    'juan': 1,
    'juana': 2,
    'juang': 1,
    'juanita': 3,
    'juarez': 2,
    'juba': 2,
    'jubb': 1,
    'jubilant': 3,
    'jubilation': 4,
    'jubilee': 3,
    'juckett': 2,
    'judah': 2,
    'judaism': 4,
    'judas': 2,
    'juday': 2,
    'judd': 1,
    'judds': 1,
    'jude': 1,
    'judea': 3,
    'judeo': 3,
    'judge': 1,
    'judged': 1,
    'judgement': 2,
    'judgemental': 3,
    'judgements': 2,
    'judges': 2,
    'judgeship': 2,
    'judgeships': 2,
    'judging': 2,
    'judgment': 2,
    'judgmental': 3,
    'judgments': 2,
    'judi': 2,
    'judicate': 3,
    'judice': 2,
    'judicial': 3,
    'judicially': 4,
    'judiciary': 5,
    'judicious': 3,
    'judiciously': 4,
    'judie': 2,
    'judishe': 2,
    'judith': 2,
    'juditha': 3,
    'judkins': 2,
    'judo': 2,
    'judsen': 2,
    'judson': 2,
    'judy': 2,
    'jue': 1,
    'juedes': 2,
    'juel': 1,
    'juenemann': 2,
    'juenger': 2,
    'juergen': 2,
    'juergens': 2,
    'jueteng': 2,
    'juett': 1,
    'jug': 1,
    'juge': 1,
    'juggernaut': 3,
    'juggle': 2,
    'juggled': 2,
    'juggler': 3,
    'jugglers': 3,
    'juggles': 2,
    'juggling': 3,
    'jugs': 1,
    'jugular': 3,
    'juhas': 2,
    'juhasz': 2,
    'juhl': 1,
    'juhlin': 2,
    'juhnke': 1,
    'juice': 1,
    'juices': 2,
    'juicier': 3,
    'juiciest': 3,
    'juicy': 2,
    'juilliard': 3,
    'jujitsu': 3,
    'jujo': 2,
    'juju': 2,
    'juke': 1,
    'jukebox': 2,
    'jukeboxes': 3,
    'jukes': 1,
    'juki': 2,
    'jule': 1,
    'julep': 2,
    'juleps': 2,
    'jules': 1,
    'juli': 2,
    'julia': 2,
    'julian': 3,
    'juliana': 4,
    'juliann': 3,
    'julianne': 3,
    'juliano': 4,
    'julie': 2,
    'julien': 3,
    'juliet': 3,
    'julieta': 3,
    'julietta': 4,
    'juliette': 3,
    'julin': 2,
    'julina': 3,
    'juline': 2,
    'julio': 3,
    'julius': 2,
    'juliusz': 3,
    'julliard': 3,
    'julson': 2,
    'july': 2,
    'jumanji': 3,
    'jumble': 2,
    'jumbled': 2,
    'jumbo': 2,
    'jumbos': 2,
    'jumbotron': 3,
    'jumbotrons': 3,
    'jumonville': 3,
    'jump': 1,
    'jumped': 1,
    'jumper': 2,
    'jumpers': 2,
    'jumping': 2,
    'jumps': 1,
    'jumpstart': 2,
    'jumpstarts': 2,
    'jumpsuit': 2,
    'jumpsuits': 2,
    'jumpy': 2,
    'jun': 1,
    'junco': 2,
    'junction': 2,
    'juncture': 2,
    'junctures': 2,
    'junda': 2,
    'jundt': 1,
    'june': 1,
    'juneau': 2,
    'junejo': 3,
    'junek': 2,
    'junella': 3,
    'junes': 1,
    'junette': 2,
    'jung': 1,
    'jungbluth': 2,
    'junge': 1,
    'jungels': 2,
    'junger': 2,
    'jungers': 2,
    'junghans': 2,
    'jungin': 2,
    'jungle': 2,
    'jungles': 2,
    'jungman': 2,
    'jungwirth': 2,
    'junia': 3,
    'juniata': 4,
    'junine': 2,
    'junior': 2,
    'juniors': 2,
    'juniper': 3,
    'junipers': 3,
    'junius': 3,
    'junji': 2,
    'junk': 1,
    'junkbond': 2,
    'junked': 1,
    'junker': 2,
    'junket': 2,
    'junkets': 2,
    'junkholder': 3,
    'junkholders': 3,
    'junkie': 2,
    'junkier': 3,
    'junkies': 2,
    'junkiest': 3,
    'junkin': 2,
    'junking': 2,
    'junkins': 2,
    'junky': 2,
    'junkyard': 2,
    'junkyards': 2,
    'juno': 2,
    'junod': 2,
    'junot': 2,
    'junta': 2,
    'juntunen': 3,
    'junwuxiyan': 4,
    'jupin': 2,
    'jupiter': 3,
    'juppe': 2,
    'jura': 2,
    'jurado': 3,
    'juran': 2,
    'juranek': 3,
    'juras': 2,
    'jurassic': 3,
    'jurczak': 2,
    'jurczyk': 2,
    'jure': 1,
    'jurek': 2,
    'jurewicz': 3,
    'jurgen': 2,
    'jurgens': 2,
    'jurgensen': 3,
    'jurgensmeyer': 3,
    'jurgenson': 3,
    'jurica': 3,
    'jurich': 2,
    'juries': 2,
    'juris': 2,
    'jurisdiction': 4,
    'jurisdictional': 5,
    'jurisdictions': 4,
    'jurisprudence': 4,
    'jurisprudential': 5,
    'jurist': 2,
    'jurists': 2,
    'jurkiewicz': 3,
    'jurkovich': 3,
    'jurkowski': 3,
    'jurney': 2,
    'juror': 2,
    'jurors': 2,
    'jurs': 1,
    'jury': 2,
    'jus': 1,
    'jusco': 2,
    'jusino': 3,
    'just': 1,
    'justa': 2,
    'justen': 2,
    'juster': 2,
    'justesen': 3,
    'justice': 2,
    'justices': 3,
    'justifiable': 5,
    'justifiably': 5,
    'justification': 5,
    'justifications': 5,
    'justified': 3,
    'justifies': 3,
    'justify': 3,
    'justifying': 4,
    'justin': 2,
    'justina': 3,
    'justine': 2,
    'justiniano': 5,
    'justino': 3,
    'justis': 2,
    'justiss': 2,
    'justly': 2,
    'justman': 2,
    'justo': 2,
    'justus': 2,
    'justy': 2,
    'jut': 1,
    'jute': 1,
    'jutland': 2,
    'jutras': 2,
    'juts': 1,
    'jutting': 2,
    'juul': 2,
    'juve': 1,
    'juvenile': 3,
    'juveniles': 3,
    'juwan': 2,
    'juxtapose': 3,
    'juxtaposed': 3,
    'juxtaposes': 4,
    'juxtaposing': 4,
    'juxtaposition': 5,
    'juxtapositions': 5,
    'jwan': 2,
    'jyishane': 2,
    'jynx': 1,
    'k': 1,
    'ka': 1,
    'kaas': 1,
    'kaatz': 1,
    'kabart': 2,
    'kabat': 2,
    'kabbah': 2,
    'kabbalah': 3,
    'kabbani': 3,
    'kabel': 2,
    'kabi': 2,
    'kabila': 3,
    'kabivitrum': 4,
    'kable': 2,
    'kabler': 3,
    'kaboom': 2,
    'kabral': 2,
    'kabuki': 3,
    'kabul': 2,
    'kacer': 2,
    'kach': 1,
    'kachel': 2,
    'kachigian': 4,
    'kachmar': 2,
    'kachuck': 2,
    'kachur': 2,
    'kackley': 2,
    'kaczmarczyk': 3,
    'kaczmarek': 3,
    'kaczmarski': 3,
    'kaczor': 2,
    'kaczorowski': 4,
    'kaczynski': 3,
    'kadar': 2,
    'kade': 1,
    'kadel': 2,
    'kaden': 2,
    'kader': 2,
    'kading': 2,
    'kadish': 2,
    'kadlec': 2,
    'kadow': 2,
    'kadrescu': 3,
    'kadrmas': 3,
    'kadumi': 3,
    'kady': 2,
    'kaeding': 2,
    'kaehler': 2,
    'kael': 1,
    'kaelin': 2,
    'kaercher': 2,
    'kaeser': 2,
    'kaestner': 2,
    'kaetzel': 2,
    'kafelnikov': 4,
    'kafer': 2,
    'kaffenberger': 4,
    'kafka': 2,
    'kafkaesque': 3,
    'kagami': 3,
    'kagan': 2,
    'kagarlitsky': 4,
    'kagawa': 3,
    'kage': 1,
    'kagel': 2,
    'kagey': 2,
    'kageyama': 4,
    'kagin': 2,
    'kagins': 2,
    'kagler': 2,
    'kagy': 2,
    'kah': 1,
    'kahalani': 4,
    'kahan': 2,
    'kahane': 3,
    'kahl': 1,
    'kahle': 1,
    'kahler': 2,
    'kahley': 2,
    'kahn': 1,
    'kahng': 1,
    'kahr': 1,
    'kahre': 1,
    'kahrs': 1,
    'kahului': 3,
    'kahuna': 3,
    'kai': 1,
    'kaifu': 2,
    'kaigler': 2,
    'kail': 1,
    'kailash': 2,
    'kailua': 3,
    'kaim': 1,
    'kain': 1,
    'kaine': 1,
    'kainer': 2,
    'kainz': 1,
    'kairamo': 3,
    'kairey': 2,
    'kaiser': 2,
    'kaiseraugst': 3,
    'kaisertech': 3,
    'kaisha': 2,
    'kaji': 2,
    'kajima': 3,
    'kajuahar': 4,
    'kakadu': 3,
    'kakimoto': 4,
    'kakos': 2,
    'kaktovik': 3,
    'kakuei': 3,
    'kakumaru': 4,
    'kal': 1,
    'kalafut': 3,
    'kalal': 2,
    'kalamazoo': 4,
    'kalan': 2,
    'kalas': 2,
    'kalashnikov': 4,
    'kalata': 3,
    'kalb': 1,
    'kalbach': 2,
    'kalberer': 3,
    'kalbfleisch': 2,
    'kale': 1,
    'kaleel': 2,
    'kaleida': 3,
    'kaleidoscope': 4,
    'kalen': 2,
    'kaler': 2,
    'kaleta': 3,
    'kaley': 2,
    'kalgoorlie': 3,
    'kalikow': 3,
    'kalil': 2,
    'kalin': 2,
    'kalina': 3,
    'kaliningrad': 4,
    'kalinoski': 4,
    'kalinowski': 4,
    'kalinske': 3,
    'kalinski': 3,
    'kalis': 2,
    'kalish': 2,
    'kalispell': 3,
    'kalisz': 2,
    'kaliszewski': 4,
    'kalivoda': 4,
    'kalk': 1,
    'kalka': 2,
    'kalkaska': 3,
    'kalkbrenner': 3,
    'kall': 1,
    'kalla': 2,
    'kallal': 2,
    'kallam': 2,
    'kallas': 2,
    'kallay': 2,
    'kallen': 2,
    'kallenbach': 3,
    'kallenberger': 4,
    'kallhoff': 2,
    'kalliel': 3,
    'kallins': 2,
    'kallio': 3,
    'kallis': 2,
    'kallman': 2,
    'kallmeyer': 3,
    'kallstrom': 2,
    'kallus': 2,
    'kalman': 2,
    'kalmanovitz': 4,
    'kalmar': 2,
    'kalmbach': 2,
    'kalmus': 2,
    'kalnins': 2,
    'kalo': 2,
    'kalok': 2,
    'kalous': 2,
    'kalp': 1,
    'kalt': 1,
    'kaltenbach': 3,
    'kaltenbacher': 4,
    'kalter': 2,
    'kalthoff': 1,
    'kalugin': 3,
    'kaluza': 3,
    'kaluzny': 3,
    'kalvar': 2,
    'kam': 1,
    'kama': 2,
    'kamakau': 4,
    'kamal': 2,
    'kamali': 3,
    'kaman': 2,
    'kamber': 2,
    'kamchatka': 3,
    'kamehameha': 5,
    'kamei': 2,
    'kamel': 2,
    'kamen': 2,
    'kamenar': 3,
    'kamens': 2,
    'kamensky': 3,
    'kamentsev': 3,
    'kamer': 2,
    'kamerer': 3,
    'kamikaze': 4,
    'kamin': 2,
    'kaminer': 3,
    'kamins': 2,
    'kaminski': 3,
    'kaminsky': 3,
    'kamir': 2,
    'kamke': 2,
    'kamler': 3,
    'kamm': 1,
    'kamman': 2,
    'kammer': 2,
    'kammerer': 3,
    'kammerzell': 3,
    'kammeyer': 3,
    'kamneva': 3,
    'kamp': 1,
    'kampa': 2,
    'kampala': 3,
    'kampe': 1,
    'kampelman': 3,
    'kampen': 2,
    'kamper': 2,
    'kampf': 1,
    'kampfer': 2,
    'kamphaus': 2,
    'kampman': 2,
    'kampmann': 2,
    'kamps': 1,
    'kampschulte': 3,
    'kamra': 2,
    'kamradt': 2,
    'kamran': 2,
    'kamrath': 2,
    'kamstra': 2,
    'kan': 1,
    'kana': 2,
    'kanade': 3,
    'kanady': 3,
    'kanagy': 3,
    'kanai': 2,
    'kanak': 2,
    'kanan': 2,
    'kanarek': 3,
    'kanawa': 3,
    'kanawha': 3,
    'kanazawa': 4,
    'kanda': 2,
    'kandahar': 3,
    'kandel': 2,
    'kandler': 2,
    'kandt': 1,
    'kane': 1,
    'kaneb': 2,
    'kanegsberg': 3,
    'kaneko': 3,
    'kanell': 2,
    'kanemaru': 4,
    'kaner': 2,
    'kaneshiro': 4,
    'kaney': 2,
    'kang': 1,
    'kangaroo': 3,
    'kangaroos': 3,
    'kangas': 2,
    'kanghua': 3,
    'kangyo': 2,
    'kania': 3,
    'kaniewski': 4,
    'kanipe': 2,
    'kanis': 2,
    'kanitz': 2,
    'kanji': 2,
    'kanjorski': 3,
    'kanka': 2,
    'kankakee': 3,
    'kankaku': 3,
    'kann': 1,
    'kannan': 2,
    'kanne': 1,
    'kannenberg': 3,
    'kanner': 2,
    'kanno': 2,
    'kano': 2,
    'kanode': 2,
    'kanon': 2,
    'kanouse': 4,
    'kansai': 2,
    'kansallis': 3,
    'kansan': 2,
    'kansans': 2,
    'kansas': 2,
    'kansian': 3,
    'kant': 1,
    'kanter': 2,
    'kantian': 3,
    'kantle': 2,
    'kantner': 2,
    'kantola': 3,
    'kantor': 2,
    'kantrowitz': 3,
    'kantz': 1,
    'kanz': 1,
    'kanzi': 2,
    'kanzler': 2,
    'kao': 1,
    'kaohsiung': 4,
    'kaolin': 2,
    'kaori': 2,
    'kapaun': 2,
    'kapfer': 2,
    'kapinos': 3,
    'kapitan': 3,
    'kaplain': 2,
    'kaplan': 2,
    'kapler': 3,
    'kaplin': 2,
    'kaplow': 2,
    'kaplowitz': 3,
    'kapner': 2,
    'kapnick': 2,
    'kapoor': 2,
    'kapor': 2,
    'kaposi': 3,
    'kapp': 1,
    'kappa': 2,
    'kappel': 2,
    'kappeler': 3,
    'kappelman': 3,
    'kapper': 2,
    'kappes': 1,
    'kappler': 2,
    'kappus': 2,
    'kapral': 2,
    'kaprayoon': 3,
    'kaps': 1,
    'kapton': 2,
    'kaptur': 2,
    'kapur': 2,
    'kapuscinski': 4,
    'kapusta': 3,
    'kara': 2,
    'karabakh': 3,
    'karabin': 3,
    'karach': 2,
    'karachi': 3,
    'karadzic': 3,
    'karaffa': 3,
    'karajan': 3,
    'karakos': 3,
    'karam': 2,
    'karami': 3,
    'karan': 2,
    'karanicki': 4,
    'karanitski': 4,
    'karaoke': 4,
    'karas': 2,
    'karasawa': 4,
    'karasek': 3,
    'karasik': 3,
    'karat': 2,
    'karate': 3,
    'karatirm': 3,
    'karatsu': 3,
    'karatz': 2,
    'karban': 2,
    'karbassioun': 4,
    'karber': 2,
    'karbowski': 3,
    'karch': 1,
    'karcher': 2,
    'karcz': 1,
    'karczewski': 3,
    'kardashian': 3,
    'kardell': 2,
    'kardos': 2,
    'kareem': 2,
    'karel': 2,
    'karelian': 4,
    'karels': 2,
    'karema': 3,
    'karen': 2,
    'karenina': 4,
    'karet': 2,
    'karg': 1,
    'karger': 2,
    'karges': 2,
    'kargonaov': 3,
    'kari': 2,
    'karibu': 3,
    'karim': 2,
    'karimi': 3,
    'karin': 2,
    'karino': 3,
    'kariotis': 4,
    'karis': 2,
    'karl': 1,
    'karla': 2,
    'karle': 2,
    'karlen': 2,
    'karlheinz': 2,
    'karlik': 2,
    'karlin': 2,
    'karline': 2,
    'karlow': 2,
    'karls': 1,
    'karlsen': 2,
    'karlson': 2,
    'karlsruhe': 2,
    'karlsson': 2,
    'karma': 2,
    'karman': 2,
    'karn': 1,
    'karnal': 2,
    'karner': 2,
    'karnes': 1,
    'karney': 2,
    'karnicki': 3,
    'karnow': 2,
    'karns': 1,
    'karnsund': 2,
    'karol': 2,
    'karolina': 4,
    'karoline': 3,
    'karolinska': 4,
    'karoly': 3,
    'karolyi': 3,
    'karolyn': 3,
    'karon': 2,
    'karos': 2,
    'karoun': 2,
    'karow': 2,
    'karp': 1,
    'karpatkin': 3,
    'karpel': 2,
    'karpen': 2,
    'karpf': 1,
    'karpinski': 3,
    'karpov': 2,
    'karpowicz': 3,
    'karr': 1,
    'karraker': 3,
    'karras': 2,
    'karren': 2,
    'karrer': 2,
    'karrick': 2,
    'karriker': 3,
    'karry': 2,
    'karsh': 1,
    'karshner': 2,
    'karson': 2,
    'karst': 1,
    'karstadt': 2,
    'karsten': 2,
    'karstens': 2,
    'karstetter': 3,
    'kartasasmita': 5,
    'kartchner': 2,
    'karter': 2,
    'kartes': 1,
    'karun': 2,
    'karvonen': 3,
    'karwoski': 3,
    'karwowski': 3,
    'kary': 2,
    'karydakis': 4,
    'karyn': 2,
    'kasabian': 4,
    'kasagic': 3,
    'kasal': 2,
    'kasbrol': 2,
    'kasbrols': 2,
    'kasch': 1,
    'kaschak': 2,
    'kasdorf': 2,
    'kase': 1,
    'kasel': 2,
    'kasell': 2,
    'kaseman': 2,
    'kaser': 2,
    'kasese': 3,
    'kasey': 2,
    'kash': 1,
    'kasha': 2,
    'kashiwagi': 4,
    'kashiwahara': 5,
    'kashiyama': 4,
    'kashmir': 2,
    'kashmiri': 3,
    'kashner': 2,
    'kashoggi': 3,
    'kashuba': 3,
    'kasi': 2,
    'kasich': 2,
    'kasick': 2,
    'kasik': 2,
    'kasinga': 3,
    'kasinger': 3,
    'kaska': 2,
    'kaske': 2,
    'kaskel': 2,
    'kasler': 3,
    'kasmer': 3,
    'kasmira': 3,
    'kasner': 2,
    'kaspar': 2,
    'kasparek': 3,
    'kasparian': 4,
    'kasparov': 3,
    'kasper': 2,
    'kasperek': 3,
    'kasperski': 3,
    'kasprowicz': 3,
    'kasprzak': 3,
    'kasprzyk': 3,
    'kasputys': 3,
    'kasriel': 3,
    'kass': 1,
    'kassa': 2,
    'kassab': 2,
    'kassan': 2,
    'kassar': 2,
    'kassebaum': 3,
    'kassel': 2,
    'kassem': 2,
    'kassen': 2,
    'kassin': 2,
    'kassing': 2,
    'kassis': 2,
    'kassler': 2,
    'kassner': 2,
    'kasson': 2,
    'kast': 1,
    'kastel': 2,
    'kastelic': 3,
    'kasten': 2,
    'kastenmeier': 3,
    'kastens': 2,
    'kaster': 2,
    'kastl': 2,
    'kastle': 2,
    'kastler': 2,
    'kastner': 2,
    'kasun': 2,
    'kasza': 2,
    'kaszuba': 3,
    'kat': 1,
    'kataoka': 4,
    'katarina': 4,
    'katashiba': 4,
    'katayama': 4,
    'katayan': 3,
    'katcher': 2,
    'kate': 1,
    'katen': 2,
    'kater': 2,
    'kates': 1,
    'katey': 2,
    'kath': 1,
    'katha': 2,
    'kathalene': 3,
    'kathan': 2,
    'katharina': 4,
    'katharine': 2,
    'katherine': 3,
    'kathi': 2,
    'kathie': 2,
    'kathleen': 2,
    'kathlene': 2,
    'kathline': 2,
    'kathman': 2,
    'kathryn': 2,
    'kathy': 2,
    'kati': 2,
    'katia': 3,
    'katie': 2,
    'katja': 2,
    'katmandu': 3,
    'kato': 2,
    'katona': 3,
    'katragadda': 4,
    'katrina': 3,
    'kats': 1,
    'katsanos': 3,
    'katsaros': 3,
    'katsuhiko': 4,
    'katsumi': 3,
    'katsushi': 3,
    'katt': 1,
    'kattner': 2,
    'katuni': 3,
    'katy': 2,
    'katya': 2,
    'katydid': 3,
    'katyn': 2,
    'katyusha': 3,
    'katyushas': 3,
    'katz': 1,
    'katzen': 2,
    'katzenbach': 3,
    'katzenberg': 3,
    'katzenberger': 4,
    'katzenstein': 3,
    'katzer': 2,
    'katzin': 2,
    'katzman': 2,
    'kau': 1,
    'kauai': 2,
    'kauble': 2,
    'kaucher': 2,
    'kauer': 2,
    'kaufer': 2,
    'kauffman': 2,
    'kauffmann': 2,
    'kaufhof': 2,
    'kaufhold': 2,
    'kaufman': 2,
    'kaufmann': 2,
    'kauk': 1,
    'kaul': 1,
    'kaumography': 4,
    'kaunakakai': 4,
    'kaunda': 2,
    'kaup': 1,
    'kaupp': 1,
    'kauppi': 2,
    'kaus': 1,
    'kausch': 1,
    'kauth': 1,
    'kautz': 1,
    'kautzman': 2,
    'kauzlarich': 3,
    'kavan': 2,
    'kavanagh': 3,
    'kavanaugh': 3,
    'kaveney': 3,
    'kavner': 2,
    'kawa': 2,
    'kawaguchi': 4,
    'kawahara': 4,
    'kawai': 3,
    'kawakami': 4,
    'kawamoto': 4,
    'kawamura': 4,
    'kawano': 3,
    'kawasaki': 4,
    'kawashima': 4,
    'kawasmi': 3,
    'kawate': 3,
    'kawecki': 3,
    'kaweske': 3,
    'kay': 1,
    'kaya': 2,
    'kayak': 2,
    'kayaker': 3,
    'kayakers': 3,
    'kayaking': 3,
    'kayaks': 2,
    'kayapo': 3,
    'kaydon': 2,
    'kaye': 1,
    'kayes': 1,
    'kayla': 2,
    'kaylie': 2,
    'kaylor': 2,
    'kaylynn': 2,
    'kayne': 1,
    'kayo': 2,
    'kaypro': 2,
    'kays': 1,
    'kayser': 2,
    'kaysersberg': 3,
    'kayvon': 2,
    'kazakh': 2,
    'kazakhs': 2,
    'kazakhstan': 3,
    'kazakhstana': 4,
    'kazan': 2,
    'kazanjian': 4,
    'kazarian': 4,
    'kazda': 2,
    'kazee': 2,
    'kazempour': 3,
    'kazen': 2,
    'kazikaev': 3,
    'kazin': 2,
    'kazis': 2,
    'kazlauskas': 3,
    'kazmer': 2,
    'kazmierczak': 3,
    'kazmierski': 3,
    'kazoo': 2,
    'kazuhiko': 4,
    'kazunori': 4,
    'kazuo': 3,
    'kcal': 2,
    'kcop': 2,
    'kea': 1,
    'keach': 1,
    'keadle': 2,
    'keady': 2,
    'keagan': 2,
    'keagle': 2,
    'keagy': 2,
    'keahey': 2,
    'keal': 1,
    'kealey': 2,
    'kealy': 2,
    'kean': 1,
    'keane': 1,
    'keaney': 2,
    'keanu': 3,
    'kear': 1,
    'kearby': 2,
    'kearfott': 2,
    'kearley': 2,
    'kearn': 1,
    'kearney': 2,
    'kearns': 1,
    'kearny': 2,
    'kearsarge': 2,
    'kearse': 1,
    'keary': 2,
    'keas': 1,
    'keasler': 2,
    'keasling': 2,
    'keast': 1,
    'keath': 1,
    'keathley': 2,
    'keating': 2,
    'keatley': 2,
    'keaton': 2,
    'keator': 2,
    'keats': 1,
    'keatts': 1,
    'keaveney': 3,
    'keaveny': 3,
    'keay': 2,
    'keck': 1,
    'keckler': 2,
    'kedar': 2,
    'keddy': 2,
    'kedo': 2,
    'kedrowski': 3,
    'keds': 1,
    'kedzierski': 3,
    'kee': 1,
    'keeble': 2,
    'keebler': 2,
    'keech': 1,
    'keedy': 2,
    'keef': 1,
    'keefe': 1,
    'keefer': 2,
    'keeffe': 1,
    'keegali': 3,
    'keegan': 2,
    'keehan': 2,
    'keehn': 1,
    'keehner': 2,
    'keel': 1,
    'keelan': 2,
    'keele': 1,
    'keeler': 2,
    'keeley': 2,
    'keelin': 2,
    'keeling': 2,
    'keels': 1,
    'keelson': 2,
    'keely': 2,
    'keen': 1,
    'keena': 2,
    'keenan': 2,
    'keene': 1,
    'keeneland': 3,
    'keener': 2,
    'keenest': 2,
    'keeney': 2,
    'keenly': 2,
    'keenum': 2,
    'keeny': 2,
    'keep': 1,
    'keeper': 2,
    'keepers': 2,
    'keeping': 2,
    'keeps': 1,
    'keepsake': 2,
    'keepsakes': 2,
    'keeran': 2,
    'kees': 1,
    'keese': 1,
    'keesee': 2,
    'keesey': 2,
    'keesler': 2,
    'keesling': 2,
    'keetch': 1,
    'keeter': 2,
    'keeth': 1,
    'keeton': 2,
    'keever': 2,
    'keevil': 2,
    'keezer': 2,
    'kefauver': 3,
    'keffer': 2,
    'keg': 1,
    'kegel': 2,
    'kegg': 1,
    'kegler': 2,
    'kegley': 2,
    'kegs': 1,
    'kehl': 1,
    'kehler': 2,
    'kehm': 1,
    'kehn': 1,
    'kehne': 1,
    'kehoe': 2,
    'kehr': 1,
    'kehrer': 2,
    'kehres': 1,
    'keicher': 2,
    'keidanren': 3,
    'keidel': 2,
    'keifer': 2,
    'keiffer': 2,
    'keigley': 2,
    'keiichi': 3,
    'keiji': 2,
    'keiko': 2,
    'keil': 1,
    'keillor': 2,
    'keilman': 2,
    'keim': 1,
    'keimig': 2,
    'keinath': 2,
    'keiner': 2,
    'keio': 2,
    'keiper': 2,
    'keir': 1,
    'keiretsu': 3,
    'keirn': 1,
    'keirns': 1,
    'keirsey': 2,
    'keisei': 2,
    'keiser': 2,
    'keisler': 3,
    'keisling': 3,
    'keister': 3,
    'keisuke': 3,
    'keitel': 2,
    'keiter': 2,
    'keith': 1,
    'keithley': 2,
    'keithly': 2,
    'keitt': 1,
    'keitz': 1,
    'keizai': 2,
    'keizer': 2,
    'kekst': 1,
    'kelbaugh': 2,
    'kelber': 2,
    'kelberg': 2,
    'kelby': 2,
    'kelcey': 2,
    'kelch': 1,
    'kelchner': 2,
    'kelda': 2,
    'kelder': 2,
    'keleher': 3,
    'keleman': 2,
    'kelemen': 2,
    'kelker': 2,
    'kell': 1,
    'kellam': 2,
    'kellams': 2,
    'kellan': 2,
    'kellar': 2,
    'kelleher': 3,
    'kellems': 2,
    'kellen': 2,
    'kellenberger': 4,
    'kellenyi': 3,
    'keller': 2,
    'kellerman': 3,
    'kellermann': 3,
    'kellett': 2,
    'kelley': 2,
    'kelli': 2,
    'kellie': 2,
    'kelliher': 3,
    'kelling': 2,
    'kellis': 2,
    'kellison': 3,
    'kellman': 2,
    'kellner': 2,
    'kellogg': 2,
    'kelloggs': 2,
    'kellough': 2,
    'kellow': 2,
    'kells': 1,
    'kellum': 2,
    'kellwood': 2,
    'kelly': 2,
    'kellyanne': 3,
    'kellys': 2,
    'kelm': 1,
    'kelman': 2,
    'kelner': 2,
    'kelnhofer': 3,
    'kelp': 1,
    'kelps': 1,
    'kelsall': 2,
    'kelsay': 2,
    'kelsch': 1,
    'kelsey': 2,
    'kelso': 2,
    'kelsoe': 2,
    'kelson': 2,
    'kelter': 2,
    'kelting': 2,
    'keltner': 2,
    'kelton': 2,
    'kelty': 2,
    'keltz': 1,
    'kelvan': 2,
    'kelven': 2,
    'kelvin': 2,
    'kem': 1,
    'kemal': 2,
    'kembel': 2,
    'kemble': 2,
    'kemeny': 3,
    'kemerer': 3,
    'kemery': 3,
    'kemler': 2,
    'kemmer': 2,
    'kemmerer': 3,
    'kemmerling': 3,
    'kemmons': 2,
    'kemner': 2,
    'kemnitz': 2,
    'kemp': 1,
    'kempa': 2,
    'kempe': 1,
    'kempel': 2,
    'kempen': 2,
    'kemper': 2,
    'kempf': 1,
    'kempfer': 2,
    'kempke': 1,
    'kempker': 2,
    'kemple': 2,
    'kempler': 2,
    'kemplin': 2,
    'kempner': 2,
    'kemppainen': 3,
    'kempski': 2,
    'kempson': 2,
    'kempster': 2,
    'kempthorne': 2,
    'kempton': 2,
    'kemron': 2,
    'ken': 1,
    'kenaf': 2,
    'kenagy': 3,
    'kenai': 2,
    'kenan': 2,
    'kendal': 2,
    'kendall': 2,
    'kendavis': 3,
    'kendell': 2,
    'kender': 2,
    'kendig': 2,
    'kendle': 2,
    'kendra': 2,
    'kendrick': 2,
    'kendricks': 2,
    'kendzierski': 3,
    'kendzior': 3,
    'kenealy': 4,
    'keneer': 2,
    'kenefick': 3,
    'kenerson': 3,
    'kenesset': 3,
    'kenetech': 3,
    'kenfield': 2,
    'kenichi': 3,
    'kenilworth': 3,
    'kenison': 3,
    'keniston': 3,
    'kenji': 2,
    'kenkel': 2,
    'kenley': 2,
    'kenmare': 2,
    'kenmore': 2,
    'kenn': 1,
    'kenna': 2,
    'kennamer': 3,
    'kennametal': 4,
    'kennan': 2,
    'kennard': 2,
    'kenneally': 3,
    'kennebeck': 2,
    'kennebrew': 3,
    'kennebunkport': 4,
    'kennecott': 3,
    'kennedy': 3,
    'kennedys': 3,
    'kennel': 2,
    'kennell': 2,
    'kennelly': 3,
    'kennels': 2,
    'kennemer': 3,
    'kennemore': 2,
    'kenner': 2,
    'kennerly': 3,
    'kennerson': 3,
    'kennesaw': 3,
    'kenneth': 2,
    'kennett': 2,
    'kenney': 2,
    'kenning': 2,
    'kennington': 3,
    'kennison': 3,
    'kennon': 2,
    'kenny': 2,
    'keno': 2,
    'kenosha': 3,
    'kenoyer': 3,
    'kenrick': 2,
    'kensinger': 3,
    'kensington': 3,
    'kensler': 3,
    'kent': 1,
    'kente': 2,
    'kentner': 2,
    'kenton': 2,
    'kentuckian': 4,
    'kentuckians': 4,
    'kentucky': 3,
    'kenward': 2,
    'kenway': 2,
    'kenwood': 2,
    'kenworth': 2,
    'kenworthy': 3,
    'kenya': 2,
    'kenyan': 2,
    'kenyans': 2,
    'kenyen': 2,
    'kenyon': 2,
    'kenzie': 2,
    'kenzo': 2,
    'keo': 2,
    'keogh': 2,
    'keohane': 3,
    'keokuk': 3,
    'keough': 2,
    'keown': 2,
    'kepco': 2,
    'kephart': 2,
    'kepler': 2,
    'kepley': 2,
    'keplinger': 4,
    'kepner': 2,
    'kepp': 1,
    'keppel': 2,
    'kepple': 2,
    'keppler': 2,
    'kept': 1,
    'ker': 1,
    'kera': 2,
    'kerala': 3,
    'keranen': 3,
    'keratin': 3,
    'keratoconus': 5,
    'keratotomy': 5,
    'kerb': 1,
    'kerbel': 2,
    'kerber': 2,
    'kerbow': 2,
    'kerbs': 1,
    'kerby': 2,
    'kerce': 1,
    'kercher': 2,
    'kercheval': 3,
    'kerchief': 2,
    'kerchiefs': 2,
    'kerchner': 2,
    'kerekes': 2,
    'kerestes': 2,
    'keresztes': 3,
    'kerfoot': 2,
    'kerfuffle': 3,
    'kerien': 3,
    'kerin': 2,
    'kerins': 2,
    'kerker': 2,
    'kerkhoff': 2,
    'kerkman': 2,
    'kerkorian': 4,
    'kerl': 1,
    'kerley': 2,
    'kerlin': 2,
    'kerman': 2,
    'kermit': 2,
    'kern': 1,
    'kernaghan': 3,
    'kernan': 2,
    'kernel': 2,
    'kernels': 2,
    'kernen': 2,
    'kerner': 2,
    'kernes': 1,
    'kerney': 2,
    'kernigan': 3,
    'kernite': 2,
    'kernodle': 3,
    'kerns': 1,
    'kerosene': 3,
    'kerouac': 3,
    'kerpedjiev': 4,
    'kerper': 2,
    'kerr': 1,
    'kerrey': 2,
    'kerri': 2,
    'kerrick': 2,
    'kerridge': 2,
    'kerrigan': 3,
    'kerrville': 2,
    'kerry': 2,
    'kersch': 1,
    'kerscher': 2,
    'kerschner': 2,
    'kersee': 2,
    'kersey': 2,
    'kersh': 1,
    'kershaw': 2,
    'kershner': 2,
    'kerst': 1,
    'kerstein': 2,
    'kersten': 2,
    'kerstetter': 3,
    'kersting': 2,
    'kertesz': 2,
    'kerth': 1,
    'kertz': 1,
    'kervin': 2,
    'kervorkian': 4,
    'kerwen': 2,
    'kerwin': 2,
    'kerwood': 2,
    'kerzner': 2,
    'kesel': 2,
    'keshishian': 4,
    'kesinger': 3,
    'keske': 1,
    'keslar': 2,
    'kesler': 3,
    'kesling': 3,
    'kesner': 2,
    'kess': 1,
    'kessel': 2,
    'kessell': 2,
    'kesselman': 3,
    'kesselring': 3,
    'kessen': 2,
    'kessenich': 3,
    'kessinger': 3,
    'kessle': 2,
    'kessler': 2,
    'kessner': 2,
    'kestel': 2,
    'kesten': 2,
    'kestenbaum': 3,
    'kester': 2,
    'kesterson': 3,
    'kesting': 2,
    'kestler': 2,
    'kestner': 2,
    'kestrels': 2,
    'keswick': 2,
    'keta': 2,
    'ketch': 1,
    'ketcham': 2,
    'ketchem': 2,
    'ketchen': 2,
    'ketcher': 2,
    'ketcherside': 3,
    'ketchie': 2,
    'ketchikan': 3,
    'ketchledge': 2,
    'ketchum': 2,
    'ketchup': 2,
    'ketelhut': 3,
    'ketelsen': 3,
    'ketema': 3,
    'keteyian': 3,
    'ketler': 2,
    'ketner': 2,
    'ketney': 2,
    'keto': 2,
    'ketola': 3,
    'ketone': 2,
    'ketou': 2,
    'ketron': 2,
    'kett': 1,
    'kettell': 2,
    'ketter': 2,
    'ketterer': 3,
    'kettering': 3,
    'ketterling': 3,
    'ketterman': 3,
    'ketti': 2,
    'kettle': 2,
    'kettler': 3,
    'kettles': 2,
    'kettlewell': 3,
    'kettner': 2,
    'ketura': 3,
    'ketz': 1,
    'ketzel': 2,
    'keune': 1,
    'kevan': 2,
    'keven': 2,
    'kever': 2,
    'kevex': 2,
    'keville': 2,
    'kevin': 2,
    'kevins': 2,
    'kevlar': 2,
    'kevlin': 2,
    'kevorkian': 4,
    'kew': 1,
    'kewaunee': 3,
    'kewley': 2,
    'key': 1,
    'keyboard': 2,
    'keyboards': 2,
    'keycorp': 2,
    'keye': 1,
    'keyed': 1,
    'keyes': 1,
    'keyhole': 2,
    'keyholes': 2,
    'keying': 2,
    'keyless': 2,
    'keylon': 2,
    'keynes': 1,
    'keynesian': 3,
    'keynesians': 3,
    'keynote': 2,
    'keynotes': 2,
    'keypad': 2,
    'keypads': 2,
    'keys': 1,
    'keyser': 2,
    'keysor': 2,
    'keystone': 2,
    'keystones': 2,
    'keystroke': 2,
    'keystrokes': 2,
    'keyton': 2,
    'keyword': 2,
    'keywords': 2,
    'keyworth': 2,
    'keziah': 3,
    'kganakga': 4,
    'kgori': 3,
    'khabarovsk': 3,
    'khad': 1,
    'khadafi': 3,
    'khaki': 2,
    'khakis': 2,
    'khalaf': 2,
    'khaled': 2,
    'khalid': 2,
    'khalifa': 3,
    'khalil': 2,
    'khalsa': 2,
    'khamenei': 3,
    'khan': 1,
    'khanate': 2,
    'khanna': 2,
    'kharg': 1,
    'khartoum': 2,
    'khasbulatov': 4,
    'khashoggi': 3,
    'khat': 1,
    'khatami': 3,
    'khazakhstan': 3,
    'khe': 1,
    'kheel': 1,
    'khem': 1,
    'khlebnikov': 3,
    'khmer': 1,
    'khomeini': 3,
    'khoo': 1,
    'khosla': 2,
    'khost': 1,
    'khouri': 2,
    'khoury': 2,
    'khrushchev': 2,
    'khufu': 2,
    'khumalo': 3,
    'khuu': 1,
    'ki': 1,
    'kia': 2,
    'kiam': 2,
    'kiawah': 3,
    'kibbe': 1,
    'kibbee': 2,
    'kibbel': 2,
    'kibbey': 2,
    'kibble': 2,
    'kibbutz': 2,
    'kibbutzim': 3,
    'kibbutzniks': 3,
    'kibby': 2,
    'kibeho': 3,
    'kibell': 2,
    'kibler': 3,
    'kibodeaux': 3,
    'kibumba': 3,
    'kichline': 2,
    'kick': 1,
    'kickback': 2,
    'kickbacks': 2,
    'kicked': 1,
    'kicker': 2,
    'kickers': 2,
    'kicking': 2,
    'kicklighter': 3,
    'kickoff': 2,
    'kicks': 1,
    'kid': 1,
    'kida': 2,
    'kidd': 1,
    'kidde': 1,
    'kidded': 2,
    'kidder': 2,
    'kiddie': 2,
    'kiddies': 2,
    'kidding': 2,
    'kiddingly': 3,
    'kiddle': 2,
    'kiddy': 2,
    'kidman': 2,
    'kidnap': 2,
    'kidnaped': 2,
    'kidnaping': 3,
    'kidnapped': 2,
    'kidnapper': 3,
    'kidnappers': 3,
    'kidnapping': 3,
    'kidnappings': 3,
    'kidnaps': 2,
    'kidney': 2,
    'kidneys': 2,
    'kido': 2,
    'kids': 1,
    'kidstock': 2,
    'kidwa': 2,
    'kidwell': 2,
    'kiechl': 2,
    'kiecker': 2,
    'kiedrowski': 3,
    'kief': 1,
    'kiefer': 2,
    'kieffer': 2,
    'kieft': 1,
    'kiehl': 1,
    'kiehn': 1,
    'kiehne': 1,
    'kiel': 1,
    'kielar': 2,
    'kielb': 1,
    'kielbasa': 3,
    'kieler': 2,
    'kielley': 2,
    'kielman': 2,
    'kielty': 2,
    'kiely': 2,
    'kienan': 2,
    'kienast': 2,
    'kiene': 1,
    'kiener': 2,
    'kieninger': 3,
    'kienitz': 2,
    'kienle': 2,
    'kientz': 1,
    'kienzle': 2,
    'kieper': 2,
    'kier': 1,
    'kieran': 2,
    'kiernan': 2,
    'kierscht': 1,
    'kierstead': 2,
    'kierulff': 2,
    'kies': 1,
    'kieschnick': 2,
    'kiesel': 2,
    'kieselmann': 3,
    'kieser': 2,
    'kiesewetter': 4,
    'kiesler': 2,
    'kiesling': 2,
    'kiesow': 2,
    'kiess': 1,
    'kiessling': 2,
    'kiester': 3,
    'kietzman': 2,
    'kiev': 2,
    'kievan': 2,
    'kievit': 2,
    'kiewit': 2,
    'kifer': 2,
    'kiff': 1,
    'kigale': 3,
    'kigali': 3,
    'kiger': 2,
    'kiggins': 2,
    'kight': 1,
    'kightlinger': 4,
    'kihn': 1,
    'kiichi': 3,
    'kijowski': 3,
    'kika': 2,
    'kikatte': 2,
    'kiker': 2,
    'kiki': 2,
    'kikkoman': 3,
    'kikta': 2,
    'kikuchi': 3,
    'kikumura': 4,
    'kikwit': 2,
    'kila': 2,
    'kilbane': 2,
    'kilborn': 2,
    'kilborne': 2,
    'kilbourn': 2,
    'kilbourne': 2,
    'kilbride': 2,
    'kilburg': 2,
    'kilburn': 2,
    'kilbury': 3,
    'kilby': 2,
    'kilcoin': 2,
    'kilcoyne': 2,
    'kilcrease': 2,
    'kilcullen': 3,
    'kilday': 2,
    'kildee': 2,
    'kildow': 2,
    'kilduff': 2,
    'kile': 1,
    'kilen': 2,
    'kiley': 2,
    'kilgo': 2,
    'kilgore': 2,
    'kilgour': 2,
    'kilgus': 2,
    'kilian': 3,
    'kilimanjaro': 5,
    'kilker': 2,
    'kill': 1,
    'killam': 2,
    'kille': 1,
    'killeagh': 2,
    'killebrew': 3,
    'killed': 1,
    'killeen': 2,
    'killen': 2,
    'killer': 2,
    'killers': 2,
    'killey': 2,
    'killgore': 2,
    'killian': 2,
    'killifish': 3,
    'killilea': 4,
    'killin': 2,
    'killing': 2,
    'killingbeck': 3,
    'killinger': 3,
    'killings': 2,
    'killingsworth': 3,
    'killington': 3,
    'killion': 2,
    'killjoy': 2,
    'killman': 2,
    'killmer': 2,
    'killmon': 2,
    'killoran': 3,
    'killory': 3,
    'killough': 2,
    'killpack': 2,
    'kills': 1,
    'kilman': 2,
    'kilmarnock': 3,
    'kilmartin': 3,
    'kilmer': 2,
    'kiln': 1,
    'kilns': 1,
    'kilo': 2,
    'kilobit': 3,
    'kilobyte': 3,
    'kilobytes': 3,
    'kilogram': 3,
    'kilograms': 3,
    'kilometer': 4,
    'kilometers': 4,
    'kilos': 2,
    'kilowatt': 3,
    'kilowatts': 3,
    'kilpatrick': 3,
    'kilroy': 2,
    'kilt': 1,
    'kilter': 2,
    'kilton': 2,
    'kilts': 1,
    'kilty': 2,
    'kilzer': 2,
    'kim': 1,
    'kimba': 2,
    'kimball': 2,
    'kimbel': 2,
    'kimbell': 2,
    'kimber': 2,
    'kimberley': 3,
    'kimberlin': 3,
    'kimberling': 3,
    'kimberly': 3,
    'kimble': 2,
    'kimbler': 2,
    'kimbley': 2,
    'kimbrel': 2,
    'kimbrell': 2,
    'kimbriel': 3,
    'kimbro': 2,
    'kimbrough': 2,
    'kimche': 2,
    'kimchee': 2,
    'kimchi': 2,
    'kimco': 2,
    'kime': 1,
    'kimel': 2,
    'kimery': 3,
    'kimes': 1,
    'kimler': 3,
    'kimm': 1,
    'kimmel': 2,
    'kimmell': 2,
    'kimmelman': 3,
    'kimmer': 2,
    'kimmerle': 3,
    'kimmet': 2,
    'kimmey': 2,
    'kimmich': 2,
    'kimmins': 2,
    'kimmitt': 2,
    'kimmons': 2,
    'kimono': 3,
    'kimonos': 3,
    'kimoto': 3,
    'kimpel': 2,
    'kimple': 2,
    'kimpo': 2,
    'kimpton': 2,
    'kimrey': 2,
    'kims': 1,
    'kimsey': 2,
    'kimura': 3,
    'kimwipe': 2,
    'kimwipes': 2,
    'kimzey': 2,
    'kin': 1,
    'kinard': 2,
    'kinark': 2,
    'kinburn': 2,
    'kincade': 2,
    'kincaid': 2,
    'kincannon': 3,
    'kincer': 2,
    'kinch': 1,
    'kincheloe': 3,
    'kinchen': 2,
    'kincy': 2,
    'kind': 1,
    'kinda': 2,
    'kindall': 2,
    'kindel': 2,
    'kindell': 2,
    'kinder': 2,
    'kindergarten': 4,
    'kindergartens': 4,
    'kindergartner': 4,
    'kindergartners': 4,
    'kinderman': 3,
    'kindest': 2,
    'kindig': 2,
    'kindle': 2,
    'kindleberger': 4,
    'kindled': 2,
    'kindler': 3,
    'kindley': 2,
    'kindling': 2,
    'kindly': 2,
    'kindness': 2,
    'kindran': 2,
    'kindred': 2,
    'kindrick': 2,
    'kinds': 1,
    'kindt': 1,
    'kindy': 2,
    'kinepolis': 4,
    'kiner': 2,
    'kines': 1,
    'kinesiology': 6,
    'kinesthetic': 4,
    'kineta': 3,
    'kinetic': 3,
    'kinetics': 3,
    'king': 1,
    'kingbird': 2,
    'kingbirds': 2,
    'kingdom': 2,
    'kingdoms': 2,
    'kingdon': 2,
    'kingen': 2,
    'kingery': 3,
    'kingfisher': 3,
    'kingfishers': 3,
    'kingham': 2,
    'kinghorn': 2,
    'kingly': 2,
    'kingma': 2,
    'kingmaker': 3,
    'kingmakers': 3,
    'kingman': 2,
    'kingon': 2,
    'kingpin': 2,
    'kingpins': 2,
    'kingrey': 2,
    'kingry': 3,
    'kings': 1,
    'kingsborough': 3,
    'kingsbridge': 2,
    'kingsbury': 3,
    'kingsey': 2,
    'kingsford': 2,
    'kingship': 2,
    'kingsland': 2,
    'kingsley': 2,
    'kingsolver': 3,
    'kingsport': 2,
    'kingston': 2,
    'kingsun': 2,
    'kingsville': 2,
    'kingsway': 2,
    'kingswell': 2,
    'kington': 2,
    'kinion': 2,
    'kiniry': 3,
    'kinkade': 2,
    'kinkead': 2,
    'kinkel': 2,
    'kinker': 2,
    'kinkle': 2,
    'kinko': 2,
    'kinks': 1,
    'kinky': 2,
    'kinlaw': 2,
    'kinley': 2,
    'kinloch': 2,
    'kinman': 2,
    'kinmen': 2,
    'kinn': 1,
    'kinnaird': 2,
    'kinnaman': 3,
    'kinnamon': 3,
    'kinnan': 2,
    'kinnard': 2,
    'kinne': 1,
    'kinnear': 2,
    'kinnebrew': 3,
    'kinnell': 2,
    'kinner': 2,
    'kinnett': 2,
    'kinney': 2,
    'kinnick': 2,
    'kinnie': 2,
    'kinnison': 3,
    'kinnock': 2,
    'kinnunen': 3,
    'kinny': 2,
    'kinoshita': 4,
    'kinsel': 2,
    'kinsell': 2,
    'kinsella': 3,
    'kinser': 2,
    'kinsey': 2,
    'kinshasa': 3,
    'kinship': 2,
    'kinsinger': 3,
    'kinsler': 3,
    'kinsley': 2,
    'kinslow': 2,
    'kinsman': 2,
    'kinst': 1,
    'kinstler': 2,
    'kinston': 2,
    'kint': 1,
    'kinter': 2,
    'kintigh': 2,
    'kintner': 2,
    'kinton': 2,
    'kintz': 1,
    'kintzel': 2,
    'kinyon': 2,
    'kinzel': 2,
    'kinzer': 2,
    'kinzey': 2,
    'kinzie': 2,
    'kinzler': 2,
    'kinzlmaier': 4,
    'kiosk': 2,
    'kiosks': 2,
    'kious': 2,
    'kip': 1,
    'kiper': 2,
    'kipfer': 2,
    'kipling': 2,
    'kiplinger': 4,
    'kipnis': 2,
    'kipp': 1,
    'kipper': 2,
    'kipperman': 3,
    'kippers': 2,
    'kippes': 1,
    'kippur': 2,
    'kir': 1,
    'kiracofe': 3,
    'kiraly': 3,
    'kirby': 2,
    'kirch': 1,
    'kirchberg': 2,
    'kirchberger': 3,
    'kirchen': 2,
    'kircher': 2,
    'kirchgessner': 3,
    'kirchhofer': 3,
    'kirchhoff': 2,
    'kirchman': 2,
    'kirchner': 2,
    'kirchners': 2,
    'kirchoff': 2,
    'kirgan': 2,
    'kirgiz': 2,
    'kiri': 2,
    'kiribati': 4,
    'kirin': 2,
    'kirk': 1,
    'kirkbride': 2,
    'kirkby': 2,
    'kirkeby': 3,
    'kirkendall': 3,
    'kirkendoll': 3,
    'kirker': 2,
    'kirkey': 2,
    'kirkham': 2,
    'kirkhart': 2,
    'kirkland': 2,
    'kirkley': 2,
    'kirklin': 2,
    'kirkman': 2,
    'kirkner': 2,
    'kirkpatrick': 3,
    'kirks': 1,
    'kirksey': 2,
    'kirkuk': 2,
    'kirkum': 2,
    'kirkwood': 2,
    'kirley': 2,
    'kirlin': 2,
    'kirmse': 2,
    'kirn': 1,
    'kirnan': 2,
    'kirner': 2,
    'kirouac': 3,
    'kirov': 2,
    'kirsch': 1,
    'kirschbaum': 2,
    'kirschenbaum': 3,
    'kirschenmann': 3,
    'kirschman': 2,
    'kirschner': 2,
    'kirsh': 1,
    'kirshbaum': 2,
    'kirshenbaum': 3,
    'kirshfink': 2,
    'kirshner': 2,
    'kirst': 1,
    'kirstein': 2,
    'kirsten': 2,
    'kirstie': 2,
    'kirstin': 2,
    'kirt': 1,
    'kirtland': 2,
    'kirtley': 2,
    'kirton': 2,
    'kirts': 1,
    'kirven': 2,
    'kirwan': 2,
    'kirwin': 2,
    'kiryas': 2,
    'kiryat': 2,
    'kis': 1,
    'kisamore': 3,
    'kisan': 2,
    'kisch': 1,
    'kischell': 2,
    'kisco': 2,
    'kise': 1,
    'kiser': 2,
    'kish': 1,
    'kisha': 2,
    'kishbaugh': 2,
    'kishi': 2,
    'kishore': 2,
    'kisiel': 2,
    'kiska': 2,
    'kisler': 3,
    'kisling': 3,
    'kismayu': 3,
    'kismet': 2,
    'kismets': 2,
    'kisner': 2,
    'kisor': 2,
    'kiss': 1,
    'kissack': 2,
    'kissam': 2,
    'kissane': 2,
    'kissed': 1,
    'kissee': 2,
    'kissel': 2,
    'kissell': 2,
    'kisser': 2,
    'kisses': 2,
    'kissick': 2,
    'kissimmee': 3,
    'kissing': 2,
    'kissinger': 3,
    'kissler': 2,
    'kissling': 2,
    'kissner': 2,
    'kist': 1,
    'kister': 2,
    'kistler': 2,
    'kistner': 2,
    'kiszczak': 2,
    'kit': 1,
    'kita': 2,
    'kitagawa': 4,
    'kitajima': 4,
    'kitamura': 4,
    'kitcat': 2,
    'kitch': 1,
    'kitchel': 2,
    'kitchell': 2,
    'kitchen': 2,
    'kitchenaid': 3,
    'kitchener': 3,
    'kitchenette': 3,
    'kitchens': 2,
    'kitchenware': 3,
    'kitchin': 2,
    'kitching': 2,
    'kitchings': 2,
    'kite': 1,
    'kites': 1,
    'kithcart': 2,
    'kitimat': 3,
    'kiting': 2,
    'kitner': 2,
    'kitowski': 3,
    'kits': 1,
    'kitsch': 1,
    'kitson': 2,
    'kitt': 1,
    'kittaneh': 3,
    'kittel': 2,
    'kittell': 2,
    'kittelson': 3,
    'kitten': 2,
    'kittens': 2,
    'kitterman': 3,
    'kittinger': 3,
    'kittiwake': 3,
    'kittle': 2,
    'kittler': 3,
    'kittles': 2,
    'kittleson': 3,
    'kittman': 2,
    'kittner': 2,
    'kittredge': 2,
    'kittrell': 2,
    'kitts': 1,
    'kitty': 2,
    'kitz': 1,
    'kitzhaber': 3,
    'kitzman': 2,
    'kitzmiller': 3,
    'kivela': 3,
    'kivett': 2,
    'kivi': 2,
    'kiwani': 3,
    'kiwanis': 3,
    'kiwi': 2,
    'kiwis': 2,
    'kiyohida': 4,
    'kiyoshi': 3,
    'kiyotaka': 4,
    'kizer': 2,
    'kizziah': 3,
    'kjar': 2,
    'kjell': 1,
    'kjellberg': 2,
    'kjenstad': 2,
    'kjos': 2,
    'kkk': 3,
    'klaas': 1,
    'klaassen': 2,
    'klabunde': 2,
    'kladstrup': 2,
    'klages': 2,
    'klahn': 1,
    'klahr': 1,
    'klaiber': 2,
    'klamer': 2,
    'klamm': 1,
    'klammer': 2,
    'klamon': 2,
    'klan': 1,
    'klang': 1,
    'klann': 1,
    'klans': 1,
    'klansmen': 2,
    'klapp': 1,
    'klapper': 2,
    'klapperich': 3,
    'klar': 1,
    'klare': 1,
    'klarich': 2,
    'klarman': 2,
    'klas': 1,
    'klase': 1,
    'klasen': 2,
    'klass': 1,
    'klassen': 2,
    'klatsky': 2,
    'klatt': 1,
    'klauber': 2,
    'klauer': 2,
    'klaus': 1,
    'klauser': 2,
    'klausing': 2,
    'klausner': 2,
    'klaver': 2,
    'klawitter': 3,
    'klay': 1,
    'klayman': 2,
    'kleban': 2,
    'klebba': 2,
    'klebe': 1,
    'kleber': 2,
    'kleck': 1,
    'klecka': 2,
    'klecker': 2,
    'kleckner': 2,
    'kleczka': 2,
    'klee': 1,
    'kleeman': 2,
    'kleen': 1,
    'kleenex': 2,
    'kleer': 1,
    'klees': 1,
    'kleffner': 2,
    'kleiber': 2,
    'kleier': 2,
    'kleiman': 2,
    'klein': 1,
    'kleinberg': 2,
    'kleindienst': 2,
    'kleine': 1,
    'kleiner': 2,
    'kleinert': 2,
    'kleinfeld': 2,
    'kleinfelter': 3,
    'kleinhans': 2,
    'kleinhenz': 2,
    'kleinknecht': 2,
    'kleinman': 2,
    'kleinpaste': 2,
    'kleinpeter': 3,
    'kleinsasser': 3,
    'kleinschmidt': 2,
    'kleinsmith': 2,
    'kleinwort': 2,
    'kleis': 1,
    'kleiss': 1,
    'kleist': 1,
    'klejna': 2,
    'klem': 1,
    'klema': 2,
    'kleman': 2,
    'klemann': 2,
    'klemens': 2,
    'klement': 2,
    'klemm': 1,
    'klemme': 1,
    'klemmer': 2,
    'klemp': 1,
    'klempner': 2,
    'klemz': 1,
    'klenge': 1,
    'klenk': 1,
    'klenke': 1,
    'klensch': 1,
    'klepac': 2,
    'klepfer': 2,
    'kleppe': 1,
    'klepper': 2,
    'kleppinger': 3,
    'kleptocrat': 3,
    'kleptomaniac': 5,
    'klerk': 1,
    'klesch': 1,
    'klesken': 2,
    'kless': 1,
    'klett': 1,
    'kleubeck': 2,
    'kleve': 1,
    'kleven': 2,
    'klever': 2,
    'kley': 1,
    'klezmatic': 3,
    'klezmatics': 3,
    'klezmer': 2,
    'klich': 1,
    'klick': 1,
    'kliebert': 2,
    'klieg': 1,
    'klieman': 2,
    'klier': 2,
    'kliethermes': 2,
    'kliewer': 2,
    'kligman': 2,
    'klim': 1,
    'klima': 2,
    'klimas': 2,
    'klimaszewski': 4,
    'klimczak': 2,
    'klimek': 2,
    'kliment': 2,
    'klimer': 2,
    'klimowicz': 3,
    'klinck': 1,
    'klindt': 1,
    'klindworth': 2,
    'kline': 1,
    'klinedinst': 3,
    'klinefelter': 4,
    'kling': 1,
    'klingaman': 3,
    'klingbeil': 2,
    'klingberg': 2,
    'klinge': 1,
    'klingel': 2,
    'klingenberg': 3,
    'klingensmith': 3,
    'klinger': 2,
    'klingerman': 3,
    'klinghoffer': 3,
    'klingler': 3,
    'klingman': 2,
    'klingon': 2,
    'klink': 1,
    'klinke': 1,
    'klinker': 2,
    'klinkhammer': 3,
    'klinkner': 2,
    'klint': 1,
    'klintworth': 2,
    'klinzing': 2,
    'klipfel': 2,
    'klipp': 1,
    'klippel': 2,
    'klish': 1,
    'klitz': 1,
    'klitzke': 2,
    'klixshavich': 3,
    'kloberdanz': 3,
    'kloc': 1,
    'klocek': 2,
    'klock': 1,
    'klocke': 1,
    'kloeckner': 2,
    'kloehn': 1,
    'kloepfer': 2,
    'kloeppel': 2,
    'kloepper': 2,
    'kloiber': 2,
    'klom': 1,
    'klomp': 1,
    'klondike': 2,
    'klonowski': 3,
    'klontz': 1,
    'kloos': 1,
    'klooster': 2,
    'kloosterman': 3,
    'klopf': 1,
    'klopfenstein': 3,
    'klopfer': 2,
    'klopp': 1,
    'kloppenburg': 3,
    'klos': 1,
    'klose': 1,
    'klosinski': 3,
    'klosowski': 3,
    'kloss': 1,
    'klossner': 2,
    'kloster': 2,
    'klosterman': 3,
    'klostermann': 3,
    'kloth': 1,
    'klotz': 1,
    'klotzbach': 2,
    'klucevsek': 3,
    'kluck': 1,
    'kludt': 1,
    'kluender': 2,
    'kluesner': 2,
    'kluever': 2,
    'klug': 1,
    'kluge': 1,
    'kluger': 2,
    'klugh': 1,
    'klugman': 2,
    'klugt': 1,
    'klukas': 2,
    'klumb': 1,
    'klump': 1,
    'klumpp': 1,
    'klunder': 2,
    'klundt': 1,
    'klunk': 1,
    'klus': 1,
    'klusman': 2,
    'klute': 1,
    'kluth': 1,
    'klutts': 1,
    'kluttz': 1,
    'klutz': 1,
    'klutznick': 2,
    'kluver': 2,
    'kluwer': 2,
    'klux': 1,
    'klym': 1,
    'klynveld': 2,
    'kmart': 2,
    'kmetz': 2,
    'kmiec': 2,
    'kmiecik': 3,
    'knaack': 1,
    'knaak': 1,
    'knab': 1,
    'knabb': 1,
    'knabe': 1,
    'knable': 2,
    'knack': 1,
    'knacks': 1,
    'knackstedt': 2,
    'knaggs': 1,
    'knape': 1,
    'knapik': 2,
    'knapke': 1,
    'knapp': 1,
    'knappenberger': 4,
    'knapper': 2,
    'knapsack': 2,
    'knapton': 2,
    'knarr': 1,
    'knaub': 1,
    'knauer': 2,
    'knauf': 1,
    'knauff': 1,
    'knaup': 1,
    'knaus': 1,
    'knauss': 1,
    'knave': 1,
    'knaves': 1,
    'knbc': 4,
    'knead': 1,
    'kneading': 2,
    'kneafsey': 2,
    'kneale': 1,
    'knebel': 2,
    'knecht': 1,
    'knechtel': 2,
    'knee': 1,
    'kneebone': 2,
    'kneecap': 2,
    'kneecaps': 2,
    'kneece': 1,
    'kneed': 1,
    'kneeing': 2,
    'kneel': 1,
    'kneeland': 2,
    'kneeling': 2,
    'kneer': 1,
    'knees': 1,
    'kneip': 1,
    'kneisel': 2,
    'kneisley': 2,
    'knell': 1,
    'kneller': 2,
    'knelt': 1,
    'knepp': 1,
    'knepper': 2,
    'knerr': 1,
    'kness': 1,
    'knesset': 2,
    'knew': 1,
    'knezevich': 3,
    'knicely': 2,
    'knick': 1,
    'knicker': 2,
    'knickerbocker': 4,
    'knickerbockered': 4,
    'knickerbockers': 4,
    'knickers': 2,
    'knickknack': 2,
    'knickknacks': 2,
    'knicks': 1,
    'knief': 1,
    'kniep': 1,
    'knieriem': 2,
    'knierim': 2,
    'knies': 1,
    'kniess': 1,
    'knievel': 3,
    'knife': 1,
    'knifed': 1,
    'knifelike': 2,
    'knifepoint': 2,
    'kniffen': 2,
    'kniffin': 2,
    'knifing': 2,
    'knifings': 2,
    'knigge': 1,
    'knight': 1,
    'knighted': 2,
    'knighten': 2,
    'knighthood': 2,
    'knightly': 2,
    'knighton': 2,
    'knights': 1,
    'knill': 1,
    'knin': 4,
    'knipe': 1,
    'knipfer': 2,
    'knipl': 2,
    'knipp': 1,
    'knippa': 2,
    'knippel': 2,
    'knippenberg': 3,
    'knipper': 2,
    'knipple': 2,
    'knisely': 2,
    'kniskern': 2,
    'knisley': 2,
    'knispel': 2,
    'kniss': 1,
    'knit': 1,
    'knits': 1,
    'knitted': 2,
    'knittel': 2,
    'knitter': 2,
    'knitting': 2,
    'knittle': 2,
    'knitwear': 2,
    'knives': 1,
    'kniveton': 2,
    'knob': 1,
    'knobbe': 1,
    'knobby': 2,
    'knobel': 2,
    'knoblauch': 2,
    'knoble': 2,
    'knobloch': 2,
    'knoblock': 2,
    'knobs': 1,
    'knoch': 1,
    'knoche': 1,
    'knochel': 2,
    'knock': 1,
    'knockdown': 2,
    'knocked': 1,
    'knocking': 2,
    'knockoff': 2,
    'knockoffs': 2,
    'knockout': 2,
    'knockouts': 2,
    'knocks': 1,
    'knode': 1,
    'knodel': 2,
    'knodle': 2,
    'knoebel': 2,
    'knoedler': 3,
    'knoell': 1,
    'knoff': 1,
    'knogo': 2,
    'knoke': 1,
    'knoles': 1,
    'knoll': 1,
    'knoop': 1,
    'knop': 1,
    'knope': 1,
    'knopf': 1,
    'knopp': 1,
    'knorr': 1,
    'knost': 1,
    'knot': 1,
    'knotek': 2,
    'knoth': 1,
    'knots': 1,
    'knott': 1,
    'knotted': 2,
    'knotts': 1,
    'knotty': 2,
    'knouff': 1,
    'knous': 1,
    'knouse': 1,
    'know': 1,
    'knowed': 1,
    'knower': 2,
    'knowing': 2,
    'knowingly': 3,
    'knowledge': 2,
    'knowledgeable': 4,
    'knowledgeably': 4,
    'knowledgeware': 3,
    'knowles': 1,
    'knowlton': 2,
    'known': 1,
    'knowns': 1,
    'knows': 1,
    'knox': 1,
    'knoxville': 2,
    'knoy': 1,
    'knuckle': 2,
    'knuckled': 2,
    'knuckles': 2,
    'knudsen': 2,
    'knudson': 2,
    'knudtson': 2,
    'knueppel': 2,
    'knupp': 1,
    'knust': 1,
    'knut': 1,
    'knute': 1,
    'knuth': 1,
    'knuts': 1,
    'knutsen': 2,
    'knutson': 2,
    'knutzen': 2,
    'ko': 1,
    'koala': 3,
    'koalas': 3,
    'koba': 2,
    'kobacker': 3,
    'kobak': 2,
    'kobayashi': 4,
    'kobe': 2,
    'kobel': 2,
    'kober': 2,
    'koberstein': 3,
    'kobes': 1,
    'koble': 2,
    'kobler': 3,
    'kobren': 2,
    'kobrin': 2,
    'kobs': 1,
    'kobus': 2,
    'koby': 2,
    'kobylarz': 3,
    'kobylinski': 4,
    'kobza': 2,
    'kocak': 2,
    'koch': 1,
    'kochan': 2,
    'kochanek': 3,
    'kochanowski': 4,
    'kochanski': 3,
    'kochel': 2,
    'kochendorfer': 4,
    'kocher': 2,
    'kochevar': 3,
    'kochis': 2,
    'kochman': 2,
    'koci': 2,
    'kocian': 2,
    'kociemba': 4,
    'kocinski': 3,
    'kociolek': 4,
    'kocis': 2,
    'kock': 1,
    'kocourek': 3,
    'kocsis': 2,
    'kocur': 2,
    'kocurek': 3,
    'kodacolor': 4,
    'kodak': 2,
    'kodama': 3,
    'kodiak': 3,
    'kodo': 2,
    'koebel': 2,
    'koedinger': 3,
    'koegel': 2,
    'koegler': 3,
    'koehl': 1,
    'koehler': 2,
    'koehn': 1,
    'koehne': 1,
    'koehnen': 2,
    'koeki': 2,
    'koelle': 1,
    'koeller': 2,
    'koelling': 2,
    'koelsch': 1,
    'koelzer': 2,
    'koen': 1,
    'koeneman': 3,
    'koenen': 2,
    'koenig': 2,
    'koenigs': 2,
    'koenigsberg': 3,
    'koenigsfeld': 3,
    'koep': 1,
    'koepke': 1,
    'koepp': 1,
    'koeppe': 1,
    'koeppel': 2,
    'koeppen': 2,
    'koepsel': 2,
    'koepsell': 2,
    'koerber': 2,
    'koerner': 2,
    'koernke': 3,
    'koester': 2,
    'koesters': 2,
    'koestler': 3,
    'koestner': 2,
    'koether': 2,
    'koetje': 1,
    'koetter': 2,
    'koetting': 2,
    'koff': 1,
    'koffler': 3,
    'koffman': 2,
    'kofi': 2,
    'kofler': 3,
    'kofoed': 2,
    'kofron': 2,
    'kofu': 2,
    'koga': 2,
    'kogan': 2,
    'kogel': 2,
    'koger': 2,
    'kogi': 2,
    'kogler': 3,
    'koglin': 2,
    'kogut': 2,
    'kogyo': 2,
    'koh': 1,
    'kohan': 2,
    'kohen': 2,
    'kohl': 1,
    'kohlbeck': 2,
    'kohlberg': 2,
    'kohlenberg': 3,
    'kohler': 2,
    'kohles': 2,
    'kohlhepp': 2,
    'kohlhoff': 2,
    'kohli': 2,
    'kohlman': 2,
    'kohlmann': 2,
    'kohlmeier': 3,
    'kohlmeyer': 3,
    'kohlrabi': 3,
    'kohls': 1,
    'kohn': 1,
    'kohne': 1,
    'kohnen': 2,
    'kohner': 2,
    'kohnke': 1,
    'kohout': 2,
    'kohr': 1,
    'kohring': 2,
    'kohrs': 1,
    'kohtaro': 3,
    'kohtoro': 3,
    'kohut': 2,
    'koichi': 3,
    'koido': 2,
    'koike': 1,
    'koito': 2,
    'koivisto': 3,
    'kojak': 2,
    'koji': 2,
    'kojima': 3,
    'kok': 1,
    'kokan': 2,
    'kokate': 3,
    'koke': 1,
    'koken': 2,
    'koker': 2,
    'kokes': 1,
    'kokesh': 2,
    'koki': 2,
    'kokinda': 3,
    'koko': 2,
    'kokomo': 3,
    'kokoschka': 3,
    'kokoszka': 3,
    'kokusai': 3,
    'kol': 1,
    'kolakowski': 4,
    'kolander': 3,
    'kolar': 2,
    'kolarik': 3,
    'kolasa': 3,
    'kolasinski': 4,
    'kolb': 1,
    'kolbe': 1,
    'kolbeck': 2,
    'kolber': 2,
    'kolberg': 2,
    'kolbert': 2,
    'kolbo': 2,
    'kolden': 2,
    'kole': 1,
    'kolek': 2,
    'kolenda': 3,
    'kolesar': 3,
    'kolich': 2,
    'kolin': 2,
    'kolinski': 3,
    'kolk': 1,
    'kolka': 2,
    'kolker': 2,
    'koll': 1,
    'kollar': 2,
    'kollasch': 2,
    'kollath': 2,
    'kolle': 1,
    'kollek': 2,
    'koller': 2,
    'kolli': 2,
    'kolling': 2,
    'kollman': 2,
    'kollmann': 2,
    'kollmeyer': 3,
    'kollmorgen': 3,
    'kolm': 1,
    'kolman': 2,
    'kolodny': 3,
    'kolodziej': 3,
    'kolodziejski': 5,
    'kolojejchick': 4,
    'kolokoff': 3,
    'koloski': 3,
    'kolowich': 3,
    'kolp': 1,
    'kolski': 2,
    'kolsky': 2,
    'kolstad': 2,
    'kolter': 2,
    'kolterman': 3,
    'kolton': 2,
    'kolts': 1,
    'koltz': 1,
    'komaba': 3,
    'komag': 2,
    'koman': 2,
    'komansky': 3,
    'komar': 2,
    'komara': 3,
    'komarek': 3,
    'komarik': 3,
    'komatani': 4,
    'komatsu': 3,
    'kombat': 2,
    'komer': 2,
    'komercni': 3,
    'komi': 2,
    'komine': 2,
    'komineft': 3,
    'kominek': 3,
    'komisar': 3,
    'kommer': 2,
    'komodo': 3,
    'komori': 3,
    'komorny': 3,
    'komorowski': 4,
    'komoto': 3,
    'komp': 1,
    'kompanek': 3,
    'komsomol': 3,
    'komura': 3,
    'kon': 1,
    'kona': 2,
    'konaga': 3,
    'konarski': 3,
    'konczal': 2,
    'kondas': 2,
    'kondo': 2,
    'kondracki': 3,
    'kondrat': 2,
    'konecny': 3,
    'konen': 2,
    'kong': 1,
    'konger': 2,
    'kongers': 2,
    'kongsberg': 2,
    'konica': 3,
    'konicek': 3,
    'konicki': 3,
    'konieczka': 3,
    'konieczny': 3,
    'konig': 2,
    'koning': 2,
    'koninklijke': 4,
    'konishi': 3,
    'konishiroku': 5,
    'konitzer': 3,
    'konkel': 2,
    'konkle': 2,
    'konkol': 2,
    'konner': 2,
    'kono': 2,
    'konold': 2,
    'konop': 2,
    'konopka': 3,
    'konrad': 2,
    'konrath': 2,
    'konstantin': 3,
    'konsultat': 3,
    'kontos': 2,
    'kontra': 2,
    'kontras': 2,
    'kontrollbank': 3,
    'konwinski': 3,
    'konz': 1,
    'konzen': 2,
    'konzi': 2,
    'koo': 1,
    'koob': 1,
    'koogler': 2,
    'kooi': 2,
    'kooiker': 3,
    'kooiman': 3,
    'kooistra': 3,
    'kook': 1,
    'kooken': 2,
    'kooker': 2,
    'kooks': 1,
    'kooky': 2,
    'kool': 1,
    'koolhaas': 2,
    'koon': 1,
    'koonce': 1,
    'koone': 1,
    'koonig': 2,
    'kooning': 2,
    'koons': 1,
    'koonts': 1,
    'koontz': 1,
    'koop': 1,
    'koopman': 2,
    'koopmann': 2,
    'koops': 1,
    'koor': 1,
    'koors': 1,
    'koos': 1,
    'kooser': 2,
    'kootenay': 3,
    'kooy': 2,
    'kooyman': 3,
    'kopacz': 2,
    'kopas': 2,
    'kopchev': 2,
    'kopczynski': 3,
    'kope': 1,
    'kopec': 2,
    'kopecky': 3,
    'kopek': 2,
    'kopeks': 2,
    'kopel': 2,
    'kopelman': 3,
    'koper': 2,
    'kopera': 3,
    'koperski': 3,
    'kopetski': 3,
    'kopf': 1,
    'kopinski': 3,
    'kopischke': 3,
    'kopit': 2,
    'kopka': 2,
    'kopke': 1,
    'kopko': 2,
    'koplan': 2,
    'koplin': 2,
    'koplovitz': 3,
    'kopp': 1,
    'koppe': 1,
    'koppel': 2,
    'koppell': 2,
    'koppelman': 3,
    'koppen': 2,
    'koppenhaver': 4,
    'kopper': 2,
    'koppers': 2,
    'koppes': 1,
    'kopple': 2,
    'kopplin': 2,
    'kopriva': 3,
    'koprowski': 3,
    'kops': 1,
    'kopusovic': 4,
    'kopy': 2,
    'kora': 2,
    'koral': 2,
    'koran': 2,
    'koranda': 3,
    'koranic': 3,
    'korb': 1,
    'korba': 2,
    'korbel': 2,
    'korber': 2,
    'korbren': 2,
    'korby': 2,
    'korczak': 2,
    'korda': 2,
    'kordell': 2,
    'korea': 3,
    'koreagate': 4,
    'korean': 3,
    'koreans': 3,
    'koreas': 3,
    'koreatown': 4,
    'korell': 2,
    'koren': 2,
    'korenek': 3,
    'koresh': 2,
    'koret': 2,
    'korey': 2,
    'korf': 1,
    'korff': 1,
    'korfhage': 2,
    'korhonen': 3,
    'korinek': 3,
    'korman': 2,
    'kormos': 2,
    'korn': 1,
    'kornacki': 3,
    'kornberg': 2,
    'kornblum': 2,
    'kornbluth': 2,
    'kornegay': 3,
    'korner': 2,
    'kornfeld': 2,
    'kornhauser': 3,
    'kornreich': 2,
    'korns': 1,
    'korol': 2,
    'korologos': 4,
    'korona': 3,
    'korotich': 3,
    'korpela': 3,
    'korpi': 2,
    'korry': 2,
    'korson': 2,
    'kort': 1,
    'korte': 1,
    'korten': 2,
    'korth': 1,
    'korthals': 2,
    'kortman': 2,
    'kortum': 2,
    'kortz': 1,
    'koruna': 3,
    'korver': 2,
    'kory': 2,
    'koryagin': 3,
    'korzeniewski': 5,
    'kos': 1,
    'kosa': 2,
    'kosak': 2,
    'kosakowski': 4,
    'kosan': 2,
    'kosanke': 2,
    'kosanovich': 4,
    'kosar': 2,
    'kosberg': 2,
    'kosbie': 2,
    'kosch': 1,
    'koscheck': 2,
    'koscielniak': 4,
    'koscinski': 3,
    'kosco': 2,
    'kosecoff': 3,
    'kosek': 2,
    'kosel': 2,
    'koser': 2,
    'kosh': 1,
    'kosher': 2,
    'koshered': 2,
    'koshering': 3,
    'koshers': 2,
    'kosiba': 3,
    'kosier': 3,
    'kosik': 2,
    'kosin': 2,
    'kosinski': 3,
    'koska': 2,
    'koskela': 3,
    'koskey': 2,
    'koski': 2,
    'koskinen': 3,
    'kosko': 2,
    'koskotas': 3,
    'kosky': 2,
    'kosloski': 3,
    'koslosky': 3,
    'koslow': 2,
    'koslowski': 3,
    'kosman': 2,
    'kosmatka': 3,
    'kosmetsky': 3,
    'kosmicki': 3,
    'kosnovsky': 3,
    'kosovo': 3,
    'kosowski': 3,
    'kosowsky': 3,
    'koss': 1,
    'kossman': 2,
    'kossow': 2,
    'kossuth': 2,
    'kost': 1,
    'kosta': 2,
    'kostal': 2,
    'kostas': 2,
    'kostecki': 3,
    'kostek': 2,
    'kostelecky': 4,
    'kostelnik': 3,
    'koster': 2,
    'kostic': 2,
    'kostick': 2,
    'kostka': 2,
    'kostmayer': 3,
    'kostoff': 2,
    'kostrzewa': 4,
    'kostrzewski': 4,
    'kosub': 2,
    'kosy': 2,
    'kosyakov': 3,
    'kosygin': 3,
    'kot': 1,
    'kotara': 3,
    'kotarski': 3,
    'kotas': 2,
    'kotch': 1,
    'kote': 1,
    'kotecki': 3,
    'kotek': 2,
    'koteles': 3,
    'koth': 1,
    'kothari': 3,
    'kothe': 1,
    'kotila': 3,
    'kotlarz': 2,
    'kotler': 3,
    'kotlowitz': 3,
    'koto': 2,
    'kotowski': 3,
    'kotsonis': 3,
    'kott': 1,
    'kotter': 2,
    'kottke': 2,
    'kottler': 2,
    'kottwitz': 2,
    'kotula': 3,
    'kotz': 1,
    'kotzebue': 3,
    'kouba': 2,
    'koudelka': 3,
    'kough': 1,
    'kouns': 1,
    'kountz': 1,
    'kouri': 3,
    'kouril': 2,
    'kourou': 2,
    'koury': 2,
    'kouyate': 3,
    'kovac': 2,
    'kovacevic': 4,
    'kovacevich': 4,
    'kovach': 2,
    'kovacic': 3,
    'kovacich': 3,
    'kovacik': 3,
    'kovack': 2,
    'kovacs': 2,
    'kovaks': 2,
    'koval': 2,
    'kovalchik': 3,
    'kovalcik': 3,
    'kovaleski': 4,
    'kovalik': 3,
    'kovalsky': 3,
    'kovalyov': 3,
    'kovar': 2,
    'kovarik': 3,
    'kovatch': 2,
    'kovats': 2,
    'koven': 2,
    'kover': 2,
    'koversada': 4,
    'kovich': 2,
    'kowabunga': 4,
    'kowal': 2,
    'kowalchuk': 3,
    'kowalczyk': 3,
    'kowaleski': 4,
    'kowalewski': 4,
    'kowalik': 3,
    'kowalke': 2,
    'kowalkowski': 4,
    'kowall': 2,
    'kowalski': 3,
    'kowalsky': 3,
    'kowitz': 2,
    'kownacki': 3,
    'kowtow': 2,
    'koyama': 3,
    'koyo': 2,
    'koza': 2,
    'kozak': 2,
    'kozakiewicz': 4,
    'kozar': 2,
    'kozberg': 2,
    'kozel': 2,
    'kozemchak': 3,
    'kozicki': 3,
    'koziel': 2,
    'kozik': 2,
    'kozikowski': 4,
    'kozinski': 3,
    'koziol': 3,
    'kozlik': 2,
    'kozloff': 2,
    'kozloski': 3,
    'kozlow': 2,
    'kozlowski': 3,
    'kozma': 2,
    'kozminski': 3,
    'koznovsky': 3,
    'kozo': 2,
    'kozol': 2,
    'kozub': 2,
    'kozuch': 2,
    'kozyrev': 3,
    'kraai': 2,
    'kraatz': 1,
    'krabbe': 1,
    'krabbenhoft': 3,
    'krabill': 2,
    'krach': 1,
    'kracht': 1,
    'krack': 1,
    'kracke': 1,
    'kraeger': 2,
    'kraemer': 2,
    'kraeutler': 2,
    'krafft': 1,
    'kraft': 1,
    'kraftwerk': 2,
    'krage': 1,
    'krager': 2,
    'kragh': 1,
    'kragt': 1,
    'krah': 1,
    'krahenbuhl': 3,
    'krahl': 1,
    'krahn': 1,
    'krain': 1,
    'krajewski': 3,
    'krajicek': 3,
    'krajina': 2,
    'kraker': 2,
    'krakow': 2,
    'krakower': 3,
    'krakowski': 3,
    'kral': 1,
    'kralicek': 3,
    'kralik': 2,
    'krall': 1,
    'kram': 1,
    'kramar': 2,
    'kramer': 2,
    'kramlich': 2,
    'kramm': 1,
    'kramme': 1,
    'krammer': 2,
    'krammes': 1,
    'kramp': 1,
    'krampe': 1,
    'krandall': 2,
    'krane': 1,
    'kraner': 2,
    'kranich': 2,
    'krans': 1,
    'krantz': 1,
    'kranz': 1,
    'kranzler': 2,
    'krapels': 2,
    'krapf': 1,
    'krapp': 1,
    'kraprayoon': 3,
    'kras': 1,
    'krasinski': 3,
    'kraska': 2,
    'krasner': 2,
    'krasnoff': 2,
    'krasnow': 2,
    'krasnoyarsk': 3,
    'krasny': 2,
    'krasowski': 3,
    'krass': 1,
    'kraszewski': 3,
    'krat': 1,
    'kratky': 2,
    'kratochvil': 3,
    'kratt': 1,
    'kratz': 1,
    'kratzer': 2,
    'kratzke': 2,
    'krauer': 1,
    'kraus': 1,
    'krause': 1,
    'krauser': 2,
    'kraushaar': 2,
    'krauskopf': 2,
    'krauss': 1,
    'krausse': 1,
    'krausz': 1,
    'kraut': 1,
    'krauter': 2,
    'krauth': 1,
    'krauthammer': 3,
    'krauze': 1,
    'kravchuk': 2,
    'kravetz': 2,
    'kravis': 2,
    'kravitz': 2,
    'krawccykiewi': 4,
    'krawchuk': 2,
    'krawczak': 2,
    'krawczyk': 2,
    'krawiec': 2,
    'krawitz': 2,
    'kray': 1,
    'kraynak': 2,
    'kreager': 2,
    'kreamer': 2,
    'krebbs': 1,
    'krebs': 1,
    'krebsbach': 2,
    'krech': 1,
    'krecko': 2,
    'kredietbank': 3,
    'kredit': 2,
    'kreditanstalt': 4,
    'kreeger': 2,
    'kreft': 1,
    'kreg': 1,
    'kregel': 2,
    'kreger': 2,
    'kreh': 1,
    'krehbiel': 2,
    'kreher': 1,
    'kreicher': 2,
    'kreider': 2,
    'kreidler': 3,
    'kreifels': 2,
    'kreig': 1,
    'kreiger': 2,
    'kreiling': 2,
    'kreimer': 2,
    'krein': 1,
    'kreiner': 2,
    'kreis': 1,
    'kreisberg': 2,
    'kreischer': 2,
    'kreisel': 2,
    'kreiser': 2,
    'kreisher': 3,
    'kreisler': 2,
    'kreisman': 2,
    'kreiss': 1,
    'kreiter': 2,
    'kreitman': 2,
    'kreitner': 2,
    'kreitz': 1,
    'kreitzberg': 2,
    'kreitzer': 2,
    'krejci': 2,
    'krell': 1,
    'kremer': 2,
    'kremers': 2,
    'kremlin': 2,
    'kremlinologist': 5,
    'kremlinologists': 5,
    'krempa': 2,
    'krempasky': 3,
    'krempler': 2,
    'kremplers': 2,
    'kren': 1,
    'krenek': 2,
    'krengel': 2,
    'krenik': 2,
    'krenke': 1,
    'krenn': 1,
    'krentz': 1,
    'krenwinkle': 3,
    'krenz': 1,
    'krenzer': 2,
    'krepps': 1,
    'kreps': 1,
    'kresa': 2,
    'kresge': 2,
    'kreslovsky': 3,
    'kress': 1,
    'kresse': 1,
    'kresser': 2,
    'kressin': 2,
    'kressler': 2,
    'kretchman': 2,
    'kretchmer': 2,
    'kretsch': 1,
    'kretschmar': 2,
    'kretschmer': 2,
    'kretz': 1,
    'kretzer': 2,
    'kretzschmar': 2,
    'kreuger': 2,
    'kreul': 1,
    'kreuser': 2,
    'kreuter': 2,
    'kreutz': 1,
    'kreuzer': 2,
    'krewson': 2,
    'krey': 1,
    'kribs': 1,
    'krichbaum': 2,
    'krick': 1,
    'krider': 2,
    'kridler': 2,
    'kriebel': 2,
    'krieg': 1,
    'kriegel': 2,
    'krieger': 2,
    'krienke': 1,
    'krier': 2,
    'kriese': 1,
    'kriesel': 2,
    'kriete': 1,
    'krigbaum': 2,
    'kriger': 2,
    'krigsten': 2,
    'krikalev': 3,
    'krikorian': 4,
    'krill': 1,
    'krim': 1,
    'kriminyok': 3,
    'krimmel': 2,
    'kriner': 2,
    'kring': 1,
    'kringen': 2,
    'kringley': 2,
    'krings': 1,
    'krinke': 1,
    'krinsky': 2,
    'kris': 1,
    'krisch': 1,
    'krischer': 2,
    'krise': 1,
    'krisher': 2,
    'krishna': 2,
    'krishnan': 2,
    'krisko': 2,
    'krispies': 2,
    'kriss': 1,
    'krist': 1,
    'krista': 2,
    'kristall': 2,
    'kristallnacht': 3,
    'kristen': 2,
    'kristensen': 3,
    'kristi': 2,
    'kristiansen': 3,
    'kristie': 2,
    'kristin': 2,
    'kristina': 3,
    'kristine': 2,
    'kristof': 2,
    'kristoff': 2,
    'kristoffe': 2,
    'kristofferson': 4,
    'kristol': 2,
    'kristopher': 3,
    'kristy': 2,
    'kritz': 1,
    'kritzer': 2,
    'krivanek': 3,
    'kriz': 1,
    'krizan': 2,
    'krizek': 2,
    'krob': 1,
    'kroboth': 2,
    'kroc': 1,
    'krock': 1,
    'kroeber': 2,
    'kroeger': 2,
    'kroegers': 2,
    'kroeker': 2,
    'kroener': 2,
    'kroening': 3,
    'kroenke': 1,
    'kroes': 1,
    'kroese': 1,
    'kroeze': 1,
    'kroft': 1,
    'krog': 1,
    'kroger': 2,
    'krogh': 1,
    'krogman': 2,
    'krogstad': 2,
    'kroh': 1,
    'krohn': 1,
    'krok': 1,
    'krol': 1,
    'krolak': 2,
    'krolczyk': 2,
    'krolick': 2,
    'krolikowski': 4,
    'kroll': 1,
    'krom': 1,
    'krome': 1,
    'kromer': 2,
    'kromm': 1,
    'kron': 1,
    'krona': 2,
    'kronberg': 2,
    'krone': 2,
    'kronen': 2,
    'kronenberg': 3,
    'kronenberger': 4,
    'kroner': 2,
    'kronick': 2,
    'kronish': 2,
    'kronk': 1,
    'kronor': 2,
    'kronos': 2,
    'krontz': 1,
    'kroon': 1,
    'kropf': 1,
    'kropp': 1,
    'kross': 1,
    'krossel': 2,
    'krotkov': 2,
    'krotz': 1,
    'krotzer': 2,
    'krouner': 2,
    'kroupa': 2,
    'krouse': 1,
    'krout': 1,
    'krowe': 1,
    'krowitz': 2,
    'kroy': 1,
    'kruchten': 2,
    'kruck': 1,
    'kruckeberg': 2,
    'kruckenberg': 3,
    'kruczek': 2,
    'krudman': 2,
    'krueger': 2,
    'kruer': 2,
    'krug': 1,
    'kruger': 2,
    'krugerrand': 3,
    'krugh': 1,
    'krugman': 2,
    'kruk': 1,
    'krukowski': 3,
    'krul': 1,
    'krull': 1,
    'krulwich': 2,
    'krum': 1,
    'krumholz': 2,
    'krumm': 1,
    'krumme': 1,
    'krummel': 2,
    'krumrey': 2,
    'krumwiede': 2,
    'krupa': 2,
    'krupicka': 3,
    'krupinski': 3,
    'krupka': 2,
    'krupman': 2,
    'krupnick': 2,
    'krupp': 1,
    'kruppa': 2,
    'krupski': 2,
    'krus': 1,
    'kruschev': 2,
    'kruschke': 1,
    'kruse': 1,
    'krusemark': 3,
    'krusinski': 3,
    'kruszewski': 3,
    'kruszka': 2,
    'kruszynski': 3,
    'krutick': 2,
    'krutsinger': 3,
    'kruttschnitt': 2,
    'krutz': 1,
    'kruzel': 2,
    'krych': 1,
    'kryder': 2,
    'kryger': 2,
    'krygier': 3,
    'krypton': 2,
    'kryptos': 2,
    'krysiak': 3,
    'krystal': 2,
    'krzeminski': 3,
    'krzysztof': 2,
    'krzywicki': 3,
    'krzyzanowski': 4,
    'ksiazek': 4,
    'ku': 1,
    'kuala': 2,
    'kuan': 1,
    'kuba': 2,
    'kubacki': 3,
    'kubala': 3,
    'kuban': 2,
    'kubas': 2,
    'kubat': 2,
    'kube': 1,
    'kubena': 3,
    'kuberski': 3,
    'kubes': 1,
    'kubiak': 3,
    'kubic': 2,
    'kubica': 3,
    'kubicek': 3,
    'kubick': 2,
    'kubicki': 3,
    'kubik': 2,
    'kubin': 2,
    'kubinski': 3,
    'kubis': 2,
    'kubisiak': 4,
    'kubitz': 2,
    'kubler': 3,
    'kubly': 2,
    'kubo': 2,
    'kubota': 3,
    'kubrick': 2,
    'kuby': 2,
    'kuc': 1,
    'kucan': 2,
    'kucera': 3,
    'kucewicz': 3,
    'kuch': 1,
    'kuchar': 2,
    'kucharski': 3,
    'kuchenbecker': 4,
    'kucher': 2,
    'kuchera': 3,
    'kuchinski': 3,
    'kuchinsky': 3,
    'kuchler': 3,
    'kuchma': 2,
    'kuchta': 2,
    'kucinski': 3,
    'kuck': 1,
    'kuczek': 2,
    'kuczynski': 3,
    'kuder': 2,
    'kudla': 2,
    'kudlow': 2,
    'kudner': 2,
    'kudos': 2,
    'kudrna': 3,
    'kudzu': 2,
    'kuebler': 3,
    'kuechler': 3,
    'kueck': 1,
    'kuecker': 2,
    'kuehl': 1,
    'kuehler': 2,
    'kuehn': 1,
    'kuehne': 1,
    'kuehnel': 2,
    'kuehner': 2,
    'kuehnle': 2,
    'kueker': 2,
    'kuenheim': 3,
    'kuennen': 2,
    'kuenstler': 3,
    'kuenzel': 2,
    'kuenzi': 3,
    'kuenzli': 2,
    'kuester': 2,
    'kuether': 2,
    'kufahl': 2,
    'kuffel': 2,
    'kuffner': 2,
    'kugel': 2,
    'kugelman': 3,
    'kugler': 3,
    'kuhar': 2,
    'kuhl': 1,
    'kuhle': 2,
    'kuhlenschmidt': 3,
    'kuhlman': 2,
    'kuhlmann': 2,
    'kuhn': 1,
    'kuhne': 1,
    'kuhner': 2,
    'kuhnert': 2,
    'kuhnke': 1,
    'kuhnle': 2,
    'kuhns': 1,
    'kuhr': 1,
    'kuhrt': 1,
    'kuiken': 2,
    'kuiper': 2,
    'kuipers': 2,
    'kujala': 3,
    'kujawa': 3,
    'kujawski': 3,
    'kuk': 1,
    'kuker': 2,
    'kukje': 2,
    'kukla': 2,
    'kuklinski': 3,
    'kukowski': 3,
    'kukuk': 2,
    'kula': 2,
    'kulaga': 3,
    'kulakowski': 4,
    'kulas': 2,
    'kulesa': 3,
    'kulesza': 3,
    'kulhanek': 3,
    'kulich': 2,
    'kulick': 2,
    'kulig': 2,
    'kuligowski': 4,
    'kulik': 2,
    'kulikowski': 4,
    'kulinski': 3,
    'kulis': 2,
    'kulish': 2,
    'kulka': 2,
    'kulkarni': 3,
    'kull': 1,
    'kulla': 2,
    'kullberg': 2,
    'kullman': 2,
    'kully': 2,
    'kulon': 2,
    'kulow': 2,
    'kulp': 1,
    'kulpa': 2,
    'kulwicki': 3,
    'kulzer': 2,
    'kumagai': 4,
    'kumar': 2,
    'kumaratunga': 5,
    'kumarisami': 5,
    'kumbaya': 3,
    'kumble': 2,
    'kume': 1,
    'kumho': 2,
    'kumler': 2,
    'kumm': 1,
    'kummer': 2,
    'kumouri': 3,
    'kump': 1,
    'kumpf': 1,
    'kumquat': 2,
    'kun': 1,
    'kuna': 2,
    'kunaev': 2,
    'kunath': 2,
    'kunayev': 3,
    'kunce': 1,
    'kunda': 2,
    'kunde': 1,
    'kundera': 3,
    'kundert': 2,
    'kundinger': 3,
    'kundrat': 2,
    'kundun': 2,
    'kunert': 2,
    'kunes': 1,
    'kunesh': 2,
    'kuney': 2,
    'kung': 1,
    'kunin': 2,
    'kunio': 3,
    'kunka': 2,
    'kunkel': 2,
    'kunkle': 2,
    'kunkler': 2,
    'kuns': 1,
    'kunselman': 3,
    'kunsman': 2,
    'kunst': 1,
    'kunstler': 2,
    'kuntsler': 2,
    'kuntz': 1,
    'kuntze': 1,
    'kuntzman': 2,
    'kunz': 1,
    'kunze': 1,
    'kunzelman': 3,
    'kunzler': 2,
    'kunzman': 2,
    'kuo': 2,
    'kuomintang': 3,
    'kuow': 2,
    'kupek': 2,
    'kuper': 2,
    'kuperman': 3,
    'kupfer': 2,
    'kupferman': 3,
    'kupiec': 2,
    'kupka': 2,
    'kupor': 2,
    'kupper': 2,
    'kupres': 2,
    'kuralt': 2,
    'kuramoto': 4,
    'kuranari': 4,
    'kuras': 2,
    'kurd': 1,
    'kurdish': 2,
    'kurdistan': 3,
    'kurds': 1,
    'kurdziel': 2,
    'kurek': 2,
    'kurian': 3,
    'kuriansky': 4,
    'kurihara': 4,
    'kuril': 2,
    'kurilla': 3,
    'kurinsky': 3,
    'kuriyama': 4,
    'kurk': 1,
    'kurka': 2,
    'kurkjian': 3,
    'kurkowski': 3,
    'kurlak': 2,
    'kurland': 2,
    'kurlander': 3,
    'kurman': 2,
    'kurmel': 2,
    'kurnit': 2,
    'kuroda': 3,
    'kurokawa': 4,
    'kurosawa': 4,
    'kurowski': 3,
    'kurshikov': 3,
    'kurt': 1,
    'kurtenbach': 3,
    'kurth': 1,
    'kurtis': 2,
    'kurtz': 1,
    'kurtzman': 2,
    'kuruman': 3,
    'kurumizowa': 5,
    'kury': 2,
    'kurylo': 3,
    'kurz': 1,
    'kurzawa': 3,
    'kurzban': 2,
    'kurzweil': 2,
    'kus': 1,
    'kusa': 2,
    'kusch': 1,
    'kuschel': 2,
    'kuse': 1,
    'kusek': 2,
    'kusel': 2,
    'kush': 1,
    'kushner': 2,
    'kushnir': 2,
    'kusiak': 3,
    'kuske': 1,
    'kusler': 3,
    'kuss': 1,
    'kusserow': 3,
    'kussman': 2,
    'kuster': 2,
    'kustra': 2,
    'kut': 1,
    'kutch': 1,
    'kutcher': 2,
    'kutchna': 2,
    'kuter': 2,
    'kutner': 2,
    'kutsch': 1,
    'kutscher': 2,
    'kuttab': 2,
    'kutter': 2,
    'kuttner': 2,
    'kutuzovsky': 4,
    'kutz': 1,
    'kutzer': 2,
    'kuvin': 2,
    'kuwahara': 4,
    'kuwait': 2,
    'kuwaiti': 3,
    'kuwaitis': 3,
    'kuwam': 2,
    'kuykendall': 3,
    'kuyper': 2,
    'kuzara': 3,
    'kuze': 1,
    'kuzel': 2,
    'kuzma': 2,
    'kuzminski': 3,
    'kuznets': 2,
    'kuznia': 3,
    'kuzniar': 2,
    'kuznicki': 3,
    'kvale': 2,
    'kvam': 2,
    'kvamme': 2,
    'kvant': 2,
    'kvasnicka': 4,
    'kvetch': 2,
    'kveton': 3,
    'kvistad': 3,
    'kvitsinsky': 4,
    'kwai': 1,
    'kwak': 1,
    'kwan': 1,
    'kwang': 1,
    'kwangju': 2,
    'kwanza': 2,
    'kwanzaa': 2,
    'kwasniewski': 3,
    'kwasnik': 2,
    'kwasny': 2,
    'kwazulu': 3,
    'kweisi': 2,
    'kwh': 5,
    'kwiatek': 3,
    'kwiatkowski': 4,
    'kwiecien': 2,
    'kwiecinski': 3,
    'kwik': 1,
    'kwitny': 2,
    'kwok': 1,
    'kwolek': 2,
    'kwon': 1,
    'kwong': 1,
    'kyd': 1,
    'kyer': 2,
    'kyes': 1,
    'kyger': 2,
    'kyi': 1,
    'kyker': 2,
    'kyl': 1,
    'kyle': 1,
    'kyler': 2,
    'kyles': 1,
    'kyllo': 2,
    'kyllonen': 3,
    'kym': 1,
    'kyna': 2,
    'kynard': 2,
    'kyne': 1,
    'kynikos': 3,
    'kyo': 1,
    'kyocera': 3,
    'kyodo': 2,
    'kyoko': 3,
    'kyokuto': 3,
    'kyong': 1,
    'kyongsang': 2,
    'kyoshi': 3,
    'kyoto': 2,
    'kyoung': 1,
    'kyowa': 2,
    'kyra': 2,
    'kyrgyzstan': 3,
    'kyron': 2,
    'kysar': 2,
    'kyser': 2,
    'kysor': 2,
    'kyte': 1,
    'kytril': 2,
    'kyu': 1,
    'kyung': 1,
    'kyushu': 2,
    'kyzar': 2,
    'kyzer': 2,
    'l': 1,
    'la': 1,
    'laabs': 1,
    'laack': 1,
    'laaco': 2,
    'laake': 1,
    'laakso': 2,
    'laas': 1,
    'laatsch': 1,
    'lab': 1,
    'laba': 2,
    'labadie': 3,
    'laban': 2,
    'laband': 2,
    'labant': 2,
    'labar': 2,
    'labarbera': 4,
    'labarge': 2,
    'labarr': 2,
    'labarre': 3,
    'labat': 2,
    'labate': 2,
    'labaton': 3,
    'labatt': 2,
    'labauve': 2,
    'labay': 2,
    'labbe': 1,
    'labe': 1,
    'labeau': 2,
    'label': 2,
    'labeled': 2,
    'labeling': 3,
    'labell': 2,
    'labella': 3,
    'labelle': 2,
    'labelled': 2,
    'labels': 2,
    'labenski': 3,
    'laber': 2,
    'laberge': 2,
    'labianca': 4,
    'labine': 3,
    'lablanc': 2,
    'labo': 2,
    'laboda': 3,
    'labombard': 3,
    'labonte': 2,
    'labor': 2,
    'laboratoires': 4,
    'laboratories': 4,
    'laboratory': 4,
    'laborde': 2,
    'labore': 2,
    'labored': 2,
    'laborer': 3,
    'laborers': 3,
    'laboring': 3,
    'laborious': 4,
    'laboriously': 5,
    'labors': 2,
    'labossiere': 4,
    'labouisse': 2,
    'labounty': 3,
    'labour': 2,
    'labov': 2,
    'labove': 2,
    'labow': 2,
    'laboy': 2,
    'labrador': 3,
    'labrake': 2,
    'labranche': 3,
    'labreck': 2,
    'labrecque': 2,
    'labree': 2,
    'labrie': 3,
    'labrioche': 3,
    'labriola': 4,
    'labrosse': 3,
    'labs': 1,
    'labuda': 3,
    'labus': 2,
    'labyrinth': 3,
    'labyrinthine': 4,
    'lac': 1,
    'lacana': 3,
    'lacasse': 3,
    'lacava': 3,
    'lacayo': 3,
    'lacaze': 3,
    'laccoliths': 3,
    'lace': 1,
    'laced': 1,
    'lacefield': 2,
    'lacer': 2,
    'lacerate': 3,
    'laceration': 4,
    'lacerations': 4,
    'lacerte': 3,
    'laces': 2,
    'lacewell': 2,
    'lacey': 2,
    'lach': 1,
    'lachance': 2,
    'lachapelle': 3,
    'lacharite': 3,
    'lache': 1,
    'lachenbruch': 3,
    'lacher': 2,
    'lachica': 3,
    'lachlan': 2,
    'lachman': 2,
    'lachmar': 2,
    'lachney': 2,
    'lachowicz': 3,
    'lacina': 3,
    'lacivita': 4,
    'lack': 1,
    'lackadaisical': 5,
    'lackawanna': 4,
    'lacked': 1,
    'lackey': 2,
    'lackeys': 2,
    'lackie': 2,
    'lacking': 2,
    'lackluster': 3,
    'lackman': 2,
    'lackner': 2,
    'lacko': 2,
    'lackovic': 3,
    'lacks': 1,
    'lacksadaiscious': 5,
    'laclair': 2,
    'laclede': 2,
    'lacock': 2,
    'lacomb': 2,
    'lacombe': 3,
    'laconic': 3,
    'laconte': 3,
    'lacorte': 3,
    'lacoss': 2,
    'lacosse': 3,
    'lacoste': 2,
    'lacount': 2,
    'lacour': 2,
    'lacourse': 2,
    'lacoursiere': 4,
    'lacouture': 3,
    'lacovara': 4,
    'lacquer': 2,
    'lacquered': 2,
    'lacroix': 2,
    'lacross': 2,
    'lacrosse': 2,
    'lactase': 2,
    'lactate': 2,
    'lactating': 3,
    'lactation': 3,
    'lacteals': 2,
    'lacter': 2,
    'lactic': 2,
    'lactobacillus': 5,
    'lactone': 2,
    'lactose': 2,
    'lacy': 2,
    'lad': 1,
    'lada': 2,
    'ladakh': 2,
    'ladas': 2,
    'ladbroke': 2,
    'ladd': 1,
    'ladder': 2,
    'ladders': 2,
    'lade': 1,
    'ladehoff': 3,
    'laden': 2,
    'ladenburg': 3,
    'lader': 2,
    'ladewig': 3,
    'ladies': 2,
    'lading': 2,
    'ladino': 3,
    'ladish': 2,
    'ladle': 2,
    'ladled': 2,
    'ladles': 2,
    'ladley': 2,
    'ladling': 2,
    'ladner': 2,
    'ladnier': 3,
    'ladouceur': 3,
    'ladow': 2,
    'lads': 1,
    'ladson': 2,
    'laduca': 3,
    'ladue': 2,
    'laduke': 3,
    'ladwig': 2,
    'lady': 2,
    'ladybird': 3,
    'ladybug': 3,
    'ladybugs': 3,
    'ladylike': 3,
    'laenderbank': 3,
    'laevo': 2,
    'lafalce': 3,
    'lafarge': 2,
    'lafata': 3,
    'lafauci': 3,
    'lafave': 2,
    'lafavor': 3,
    'lafavre': 3,
    'lafayette': 3,
    'lafer': 2,
    'laferriere': 4,
    'lafever': 3,
    'lafevers': 3,
    'lafeyette': 3,
    'laff': 1,
    'laffer': 2,
    'lafferty': 3,
    'laffey': 2,
    'laffin': 2,
    'laffitte': 2,
    'laffoon': 2,
    'lafite': 2,
    'lafitte': 2,
    'laflam': 2,
    'laflamme': 2,
    'lafleche': 2,
    'lafler': 3,
    'lafleur': 2,
    'laflin': 2,
    'lafoe': 2,
    'lafollette': 3,
    'lafon': 2,
    'lafond': 2,
    'lafont': 2,
    'lafontaine': 3,
    'lafontant': 3,
    'laforce': 2,
    'laforest': 3,
    'laforge': 2,
    'laforte': 2,
    'lafortune': 3,
    'lafosse': 2,
    'lafountain': 3,
    'lafountaine': 3,
    'lafoy': 2,
    'laframboise': 3,
    'lafrance': 2,
    'lafreniere': 4,
    'lafrenz': 2,
    'lafuente': 3,
    'lag': 1,
    'lagace': 3,
    'lagan': 2,
    'lagana': 3,
    'lagarde': 3,
    'lagardere': 3,
    'lagasse': 3,
    'lagattuta': 4,
    'lage': 1,
    'lager': 2,
    'lagerfeld': 3,
    'lagergren': 3,
    'lagerquist': 3,
    'lagerstrom': 3,
    'lagesse': 2,
    'laggard': 2,
    'laggards': 2,
    'lagged': 1,
    'lagging': 2,
    'lagle': 2,
    'lagnado': 3,
    'lago': 2,
    'lagomarsino': 5,
    'lagoon': 2,
    'lagoons': 2,
    'lagos': 2,
    'lagow': 2,
    'lagrange': 2,
    'lagreca': 3,
    'lagroceria': 5,
    'lagrone': 3,
    'lagrow': 2,
    'lags': 1,
    'laguardia': 4,
    'lague': 1,
    'laguna': 3,
    'lagunas': 3,
    'lahaie': 2,
    'laham': 2,
    'lahaye': 2,
    'lahey': 2,
    'lahiff': 2,
    'lahm': 1,
    'lahman': 2,
    'lahmann': 2,
    'lahn': 1,
    'lahood': 2,
    'lahore': 2,
    'lahr': 1,
    'lahti': 2,
    'lahue': 2,
    'lai': 1,
    'laib': 1,
    'laible': 2,
    'laibowitz': 3,
    'laibrook': 2,
    'laiche': 1,
    'laid': 1,
    'laidig': 2,
    'laidlaw': 2,
    'laidler': 2,
    'laidley': 2,
    'lail': 1,
    'laimbeer': 2,
    'lain': 1,
    'laine': 1,
    'laing': 2,
    'lainhart': 2,
    'laino': 2,
    'lair': 1,
    'laird': 1,
    'lairmore': 2,
    'lairson': 2,
    'lais': 1,
    'laissez': 2,
    'lait': 1,
    'laitinen': 3,
    'laity': 3,
    'lajeune': 2,
    'lajeunesse': 3,
    'lajoie': 2,
    'lajous': 2,
    'lak': 1,
    'lakatos': 3,
    'lake': 1,
    'lakeberg': 2,
    'lakefield': 2,
    'lakefront': 2,
    'lakeland': 2,
    'lakeman': 2,
    'laker': 2,
    'lakers': 2,
    'lakes': 1,
    'lakeshore': 2,
    'lakeside': 2,
    'lakeview': 2,
    'lakewood': 2,
    'lakey': 2,
    'lakin': 2,
    'lakins': 2,
    'lakota': 3,
    'lakotas': 3,
    'lakshamanan': 4,
    'lal': 1,
    'lala': 2,
    'lalande': 3,
    'lalas': 2,
    'laliberte': 4,
    'lalit': 2,
    'lalita': 3,
    'lalitesh': 3,
    'lall': 1,
    'lalla': 2,
    'lallera': 3,
    'lalley': 2,
    'lalli': 2,
    'lallie': 2,
    'lallier': 3,
    'lallo': 2,
    'lally': 2,
    'lalonde': 3,
    'lalone': 3,
    'lalor': 2,
    'lam': 1,
    'lama': 2,
    'lamacchia': 4,
    'lamagna': 3,
    'lamaist': 3,
    'lamamra': 3,
    'laman': 2,
    'lamanna': 3,
    'lamantia': 3,
    'lamar': 2,
    'lamarca': 3,
    'lamarche': 3,
    'lamarr': 2,
    'lamarre': 3,
    'lamartina': 4,
    'lamas': 2,
    'lamaster': 3,
    'lamastus': 3,
    'lamattina': 4,
    'lamaur': 2,
    'lamay': 2,
    'lamb': 1,
    'lambaste': 2,
    'lambasted': 3,
    'lambastes': 2,
    'lambasting': 3,
    'lambda': 2,
    'lambdin': 2,
    'lambe': 1,
    'lambeau': 2,
    'lamberg': 2,
    'lamberson': 3,
    'lambert': 2,
    'lamberth': 2,
    'lamberti': 3,
    'lamberto': 3,
    'lamberton': 3,
    'lambertson': 3,
    'lamberty': 3,
    'lambeth': 2,
    'lambiase': 4,
    'lambie': 2,
    'lambing': 2,
    'lamborghini': 4,
    'lamborghinis': 4,
    'lamborn': 2,
    'lamboy': 2,
    'lambreau': 2,
    'lambrecht': 2,
    'lambright': 2,
    'lambro': 2,
    'lambros': 2,
    'lambs': 1,
    'lambsdorff': 2,
    'lambson': 2,
    'lamby': 2,
    'lame': 1,
    'lamellar': 3,
    'lamely': 2,
    'lamendola': 4,
    'lament': 2,
    'lamentable': 4,
    'lamented': 3,
    'lamenting': 3,
    'laments': 2,
    'lamer': 2,
    'lamere': 3,
    'lamers': 2,
    'lameta': 3,
    'lamey': 2,
    'lamia': 3,
    'lamica': 3,
    'lamie': 2,
    'lamina': 3,
    'laminack': 3,
    'laminar': 3,
    'laminate': 3,
    'laminated': 4,
    'laminates': 3,
    'laminating': 4,
    'lamine': 2,
    'laminer': 3,
    'laminne': 2,
    'lamirande': 4,
    'lamison': 3,
    'lamke': 2,
    'lamkin': 2,
    'lamle': 2,
    'lamm': 1,
    'lamme': 1,
    'lammers': 2,
    'lammert': 2,
    'lammey': 2,
    'lammi': 2,
    'lammon': 2,
    'lammond': 2,
    'lammons': 2,
    'lamon': 2,
    'lamond': 2,
    'lamonica': 4,
    'lamons': 2,
    'lamont': 2,
    'lamontagne': 3,
    'lamonte': 2,
    'lamora': 3,
    'lamore': 2,
    'lamoreaux': 3,
    'lamoreux': 3,
    'lamorte': 3,
    'lamos': 2,
    'lamothe': 2,
    'lamott': 2,
    'lamotta': 3,
    'lamotte': 2,
    'lamountain': 3,
    'lamoureaux': 3,
    'lamoureux': 3,
    'lamp': 1,
    'lampe': 1,
    'lampert': 2,
    'lampf': 1,
    'lamphear': 2,
    'lamphere': 2,
    'lamphier': 3,
    'lampi': 2,
    'lamping': 2,
    'lampkin': 2,
    'lampkins': 2,
    'lampl': 2,
    'lampley': 2,
    'lamplighter': 3,
    'lamplighters': 3,
    'lampman': 2,
    'lampo': 2,
    'lampoon': 2,
    'lampooned': 2,
    'lamport': 2,
    'lampp': 1,
    'lamppost': 2,
    'lampposts': 2,
    'lamprey': 2,
    'lampreys': 2,
    'lampron': 2,
    'lampros': 2,
    'lamps': 1,
    'lampson': 2,
    'lampton': 2,
    'lamson': 2,
    'lamston': 2,
    'lamy': 2,
    'lan': 1,
    'lana': 2,
    'lanagan': 3,
    'lanahan': 3,
    'lanai': 2,
    'lanasa': 3,
    'lancashire': 3,
    'lancaster': 3,
    'lancastrian': 4,
    'lance': 1,
    'lancelet': 2,
    'lancelot': 3,
    'lanceolate': 4,
    'lancer': 2,
    'lancers': 2,
    'lancet': 2,
    'lancets': 2,
    'lancia': 2,
    'lancing': 2,
    'lancit': 2,
    'lanclos': 2,
    'lancome': 2,
    'lancon': 2,
    'lancour': 2,
    'lanctot': 2,
    'lancz': 1,
    'land': 1,
    'landa': 2,
    'landau': 2,
    'landauer': 3,
    'landay': 2,
    'landbank': 2,
    'landberg': 2,
    'lande': 1,
    'landeck': 2,
    'landed': 2,
    'landefeld': 3,
    'landell': 2,
    'landen': 2,
    'landenberger': 4,
    'lander': 2,
    'landeros': 3,
    'landers': 2,
    'landes': 1,
    'landesbank': 3,
    'landesman': 3,
    'landess': 2,
    'landfair': 2,
    'landfall': 2,
    'landfill': 2,
    'landfilling': 3,
    'landfills': 2,
    'landform': 2,
    'landforms': 2,
    'landfried': 3,
    'landgraf': 2,
    'landgrave': 2,
    'landgrebe': 2,
    'landgren': 2,
    'landholder': 3,
    'landholders': 3,
    'landholding': 3,
    'landholdings': 3,
    'landi': 2,
    'landin': 2,
    'landing': 2,
    'landingham': 3,
    'landings': 2,
    'landini': 3,
    'landino': 3,
    'landis': 2,
    'landlady': 3,
    'landler': 2,
    'landless': 2,
    'landlock': 2,
    'landlocked': 2,
    'landlord': 2,
    'landlords': 2,
    'landman': 2,
    'landmark': 2,
    'landmarks': 2,
    'landmass': 2,
    'landmasses': 3,
    'landmesser': 3,
    'landmine': 2,
    'landmines': 2,
    'lando': 2,
    'landolfi': 3,
    'landolt': 2,
    'landon': 2,
    'landor': 2,
    'landover': 3,
    'landow': 2,
    'landowner': 3,
    'landowners': 3,
    'landowski': 3,
    'landreneau': 3,
    'landress': 2,
    'landreth': 2,
    'landrieu': 2,
    'landrigan': 3,
    'landrith': 2,
    'landrover': 3,
    'landrum': 2,
    'landrus': 2,
    'landry': 2,
    'lands': 1,
    'landsat': 2,
    'landsberg': 2,
    'landsberger': 3,
    'landsbergis': 3,
    'landscape': 2,
    'landscaped': 2,
    'landscaper': 3,
    'landscapers': 3,
    'landscapes': 2,
    'landscaping': 3,
    'landsend': 2,
    'landslide': 2,
    'landslides': 2,
    'landsman': 2,
    'landsner': 2,
    'landston': 2,
    'landstrom': 2,
    'landt': 1,
    'landview': 2,
    'landviewite': 3,
    'landviewites': 3,
    'landward': 2,
    'landwehr': 2,
    'landy': 2,
    'lane': 1,
    'lanehart': 2,
    'lanes': 1,
    'lanese': 3,
    'lanete': 2,
    'laneve': 2,
    'laney': 2,
    'lanfear': 2,
    'lanford': 2,
    'lang': 1,
    'langan': 2,
    'langbehn': 2,
    'langbein': 2,
    'langbo': 2,
    'langdale': 2,
    'langdon': 2,
    'lange': 1,
    'langel': 2,
    'langeland': 3,
    'langelier': 3,
    'langella': 3,
    'langen': 2,
    'langenbach': 3,
    'langenberg': 3,
    'langenderfer': 4,
    'langendorf': 3,
    'langenfeld': 3,
    'langer': 2,
    'langerman': 3,
    'langers': 2,
    'langevin': 3,
    'langfeldt': 2,
    'langfitt': 2,
    'langford': 2,
    'langguth': 2,
    'langham': 2,
    'langhans': 2,
    'langhoff': 2,
    'langhorn': 2,
    'langhorne': 2,
    'langhorst': 2,
    'langill': 2,
    'langille': 3,
    'langlais': 2,
    'langland': 2,
    'langley': 2,
    'langlinais': 3,
    'langlois': 2,
    'langman': 2,
    'langner': 2,
    'lango': 2,
    'langone': 3,
    'langowski': 3,
    'langrehr': 2,
    'langridge': 2,
    'langsam': 2,
    'langsdorf': 2,
    'langseth': 2,
    'langsford': 2,
    'langstaff': 2,
    'langston': 2,
    'langton': 2,
    'language': 2,
    'languages': 3,
    'languid': 2,
    'languish': 2,
    'languished': 2,
    'languishes': 3,
    'languishing': 3,
    'langwell': 2,
    'langworth': 2,
    'langworthy': 3,
    'lanham': 2,
    'lani': 2,
    'lanier': 2,
    'lanigan': 3,
    'laning': 2,
    'laningham': 3,
    'lanius': 3,
    'lank': 1,
    'lanka': 2,
    'lankan': 2,
    'lankans': 2,
    'lankford': 2,
    'lanktree': 2,
    'lanky': 2,
    'lanman': 2,
    'lann': 1,
    'lanna': 2,
    'lannan': 2,
    'lannen': 2,
    'lanners': 2,
    'lanni': 2,
    'lanning': 2,
    'lanno': 2,
    'lannom': 2,
    'lannon': 2,
    'lanny': 2,
    'lano': 2,
    'lanolin': 3,
    'lanoue': 3,
    'lanouette': 3,
    'lanphear': 2,
    'lanpher': 2,
    'lanphere': 2,
    'lanphier': 2,
    'lansberry': 3,
    'lansbury': 3,
    'lansdale': 2,
    'lansdell': 2,
    'lansdown': 2,
    'lansdowne': 2,
    'lanser': 2,
    'lansford': 2,
    'lansing': 2,
    'lansky': 2,
    'lanston': 2,
    'lant': 1,
    'lantana': 3,
    'lanter': 2,
    'lanterman': 3,
    'lantern': 2,
    'lanterns': 2,
    'lanthanum': 3,
    'lanthier': 3,
    'lanting': 2,
    'lantis': 2,
    'lantos': 2,
    'lantrip': 2,
    'lantry': 2,
    'lantz': 1,
    'lantzy': 2,
    'lanum': 2,
    'lanvin': 2,
    'lanyard': 2,
    'lanyi': 2,
    'lanyon': 2,
    'lanz': 1,
    'lanza': 2,
    'lanzelotti': 4,
    'lanzer': 2,
    'lanzet': 2,
    'lanzi': 2,
    'lanzillo': 3,
    'lanzo': 2,
    'lao': 1,
    'laos': 2,
    'laotian': 3,
    'lap': 1,
    'lapage': 2,
    'lapaglia': 4,
    'lapalme': 3,
    'lapan': 2,
    'laparoscopic': 5,
    'laparoscopy': 5,
    'lapd': 4,
    'lapdog': 2,
    'lape': 1,
    'lapel': 2,
    'lapels': 2,
    'lapenna': 3,
    'lapenta': 3,
    'laperle': 3,
    'lapeyrouse': 3,
    'lapham': 2,
    'laphroaig': 3,
    'lapiana': 4,
    'lapidary': 4,
    'lapides': 2,
    'lapidus': 3,
    'lapier': 3,
    'lapierre': 3,
    'lapin': 2,
    'lapine': 3,
    'lapinski': 3,
    'lapinsky': 3,
    'lapis': 2,
    'lapka': 2,
    'laplaca': 3,
    'lapland': 2,
    'laplant': 2,
    'laplante': 3,
    'laplume': 2,
    'lapoint': 2,
    'lapointe': 2,
    'lapolla': 3,
    'laporta': 3,
    'laporte': 2,
    'lapp': 1,
    'lappe': 1,
    'lapped': 1,
    'lappen': 2,
    'lappin': 2,
    'lapping': 2,
    'lapps': 1,
    'laprade': 3,
    'laprairie': 3,
    'lapre': 2,
    'laprise': 2,
    'laps': 1,
    'lapse': 1,
    'lapsed': 1,
    'lapses': 2,
    'lapsing': 2,
    'lapsley': 2,
    'laptop': 2,
    'laptops': 2,
    'lar': 1,
    'lara': 2,
    'larabee': 3,
    'laraia': 3,
    'laraine': 2,
    'laramee': 3,
    'laramie': 3,
    'laramore': 3,
    'laraway': 3,
    'larcenous': 3,
    'larceny': 3,
    'larch': 1,
    'larche': 2,
    'larchmont': 2,
    'larco': 2,
    'larcom': 2,
    'lard': 1,
    'larded': 2,
    'larder': 2,
    'lardner': 2,
    'lare': 1,
    'lareau': 2,
    'laredo': 3,
    'lareina': 4,
    'larena': 3,
    'larew': 2,
    'large': 1,
    'largely': 2,
    'largen': 2,
    'largeness': 2,
    'largent': 2,
    'larger': 2,
    'largess': 2,
    'largesse': 2,
    'largest': 2,
    'largo': 2,
    'lariat': 3,
    'lariccia': 3,
    'larijani': 4,
    'larimer': 3,
    'larimore': 3,
    'larina': 3,
    'larine': 3,
    'larios': 3,
    'lariscy': 3,
    'larison': 3,
    'larissa': 3,
    'larivee': 3,
    'lariviere': 4,
    'larizza': 3,
    'lark': 1,
    'larke': 1,
    'larkey': 2,
    'larkin': 2,
    'larkins': 2,
    'larks': 1,
    'larkspur': 2,
    'larmer': 2,
    'larmon': 2,
    'larmore': 2,
    'larned': 1,
    'larner': 2,
    'larocca': 3,
    'larocco': 3,
    'laroche': 2,
    'larochelle': 3,
    'larock': 2,
    'larocque': 2,
    'laroe': 2,
    'laroque': 2,
    'larosa': 3,
    'larose': 3,
    'larosiere': 3,
    'larouche': 2,
    'laroux': 2,
    'larowe': 2,
    'larrabee': 3,
    'larrance': 2,
    'larrea': 3,
    'larrick': 2,
    'larrimore': 3,
    'larrison': 3,
    'larrivee': 3,
    'larroquette': 3,
    'larrow': 2,
    'larry': 2,
    'lars': 1,
    'larsen': 2,
    'larsh': 1,
    'larson': 2,
    'larsson': 2,
    'larue': 2,
    'laruso': 3,
    'larussa': 3,
    'larusso': 3,
    'larva': 2,
    'larvae': 2,
    'larval': 2,
    'lary': 2,
    'laryngeal': 4,
    'laryngitis': 4,
    'larynx': 2,
    'larzelere': 3,
    'las': 1,
    'lasagna': 3,
    'lasala': 3,
    'lasalle': 2,
    'lasater': 3,
    'lasca': 2,
    'lascala': 3,
    'lascaux': 2,
    'lasch': 1,
    'lascivious': 4,
    'lasco': 2,
    'lascola': 3,
    'lase': 1,
    'lasecki': 3,
    'lasek': 2,
    'laser': 2,
    'lasers': 2,
    'lasersonic': 4,
    'lasersonics': 4,
    'laseter': 3,
    'lash': 1,
    'lashbrook': 2,
    'lashed': 1,
    'lasher': 2,
    'lashes': 2,
    'lashing': 2,
    'lashings': 2,
    'lashlee': 2,
    'lashley': 2,
    'lashomb': 2,
    'lashua': 3,
    'lashway': 2,
    'lasiter': 3,
    'lask': 1,
    'laska': 2,
    'laske': 1,
    'lasker': 2,
    'laskey': 2,
    'laski': 2,
    'laskin': 2,
    'lasko': 2,
    'laskowski': 3,
    'lasky': 2,
    'lasley': 2,
    'laslo': 2,
    'lasmo': 2,
    'lasorda': 3,
    'lasorsa': 3,
    'lasota': 3,
    'laspina': 3,
    'lass': 1,
    'lassa': 2,
    'lasseigne': 2,
    'lassen': 2,
    'lasser': 2,
    'lasseter': 3,
    'lassetter': 3,
    'lassie': 2,
    'lassila': 3,
    'lassiter': 3,
    'lassman': 2,
    'lassner': 2,
    'lasso': 2,
    'lasswell': 2,
    'last': 1,
    'lasted': 2,
    'laster': 2,
    'lastest': 2,
    'lasting': 2,
    'lastinger': 3,
    'lastingly': 3,
    'lastly': 2,
    'lastra': 2,
    'lastrapes': 3,
    'lasts': 1,
    'lasumma': 3,
    'lasure': 2,
    'laswell': 2,
    'laszlo': 2,
    'lat': 1,
    'lata': 2,
    'lataif': 2,
    'latanze': 3,
    'latasha': 3,
    'latch': 1,
    'latchaw': 2,
    'latched': 1,
    'latches': 2,
    'latchford': 2,
    'latching': 2,
    'latchkey': 2,
    'late': 1,
    'latecomer': 3,
    'latecomers': 3,
    'latelies': 2,
    'latella': 3,
    'lately': 2,
    'latenan': 3,
    'latency': 3,
    'latendresse': 3,
    'lateness': 2,
    'latent': 2,
    'later': 2,
    'lateral': 3,
    'laterites': 3,
    'laterriere': 3,
    'laterza': 3,
    'latest': 2,
    'latex': 2,
    'latexes': 3,
    'lath': 1,
    'latham': 2,
    'lathan': 2,
    'lathe': 1,
    'lathem': 2,
    'lather': 2,
    'lathers': 2,
    'lathes': 1,
    'lathon': 2,
    'lathouris': 3,
    'lathrop': 2,
    'latigo': 3,
    'latimer': 3,
    'latimore': 3,
    'latin': 2,
    'latina': 3,
    'latinate': 3,
    'latini': 3,
    'latino': 3,
    'latinos': 3,
    'latins': 2,
    'latiolais': 4,
    'latisha': 3,
    'latitude': 3,
    'latitudes': 3,
    'latitudinal': 5,
    'latka': 2,
    'latkas': 2,
    'latner': 2,
    'lato': 2,
    'laton': 2,
    'latona': 3,
    'latonia': 4,
    'latorre': 3,
    'latour': 2,
    'latourette': 3,
    'latoya': 3,
    'latrine': 2,
    'latrines': 2,
    'latrobe': 2,
    'latrone': 2,
    'lats': 1,
    'latsch': 1,
    'latshaw': 2,
    'latson': 2,
    'latta': 2,
    'lattanzi': 3,
    'lattanzio': 4,
    'latte': 2,
    'latter': 2,
    'lattice': 2,
    'latticework': 3,
    'lattimer': 3,
    'lattimore': 3,
    'lattin': 2,
    'lattner': 2,
    'latty': 2,
    'latulippe': 4,
    'latvala': 3,
    'latvia': 3,
    'latvian': 3,
    'latvians': 3,
    'latz': 1,
    'latzoff': 2,
    'lau': 1,
    'laub': 1,
    'laubach': 2,
    'laubacher': 3,
    'laube': 1,
    'laubenstein': 3,
    'laubenthal': 3,
    'lauber': 2,
    'laubhan': 2,
    'laubscher': 2,
    'lauch': 1,
    'lauck': 1,
    'laud': 1,
    'laudable': 3,
    'laudanum': 3,
    'laudatory': 4,
    'laude': 1,
    'lauded': 2,
    'laudenslager': 4,
    'lauder': 2,
    'lauderback': 3,
    'lauderbaugh': 3,
    'lauderdale': 3,
    'laudicina': 4,
    'lauding': 2,
    'laudise': 2,
    'laudner': 2,
    'laudon': 2,
    'lauds': 1,
    'laue': 2,
    'lauer': 2,
    'lauerman': 3,
    'lauf': 1,
    'laufenberg': 3,
    'laufer': 2,
    'lauffer': 2,
    'laugh': 1,
    'laughable': 3,
    'laughed': 1,
    'laugher': 2,
    'laughery': 3,
    'laughing': 2,
    'laughinghouse': 3,
    'laughingly': 3,
    'laughingstock': 3,
    'laughler': 2,
    'laughlin': 2,
    'laughman': 2,
    'laughner': 2,
    'laughrey': 2,
    'laughridge': 2,
    'laughs': 1,
    'laughter': 2,
    'laughton': 2,
    'lauinger': 3,
    'lauman': 2,
    'laumann': 2,
    'laun': 1,
    'launcelot': 3,
    'launch': 1,
    'launched': 1,
    'launcher': 2,
    'launchers': 2,
    'launches': 2,
    'launching': 2,
    'launchings': 2,
    'launder': 2,
    'laundered': 2,
    'launderer': 3,
    'launderers': 3,
    'laundering': 3,
    'launders': 2,
    'laundries': 2,
    'laundromat': 3,
    'laundromats': 3,
    'laundry': 2,
    'launer': 2,
    'launius': 3,
    'lauper': 2,
    'laur': 1,
    'laura': 2,
    'laurance': 2,
    'laurdan': 2,
    'laure': 1,
    'laureano': 3,
    'laureate': 3,
    'laureates': 3,
    'laureen': 2,
    'laurel': 2,
    'laurels': 2,
    'lauren': 2,
    'laurena': 3,
    'laurence': 2,
    'laurene': 2,
    'laurens': 2,
    'laurent': 2,
    'laurentian': 3,
    'laurentiis': 3,
    'laurenz': 2,
    'lauretarian': 5,
    'lauretta': 3,
    'laurette': 2,
    'laurey': 2,
    'lauri': 2,
    'lauria': 3,
    'lauriats': 3,
    'laurice': 2,
    'lauricella': 4,
    'laurich': 2,
    'lauridsen': 3,
    'laurie': 2,
    'laurin': 2,
    'laurino': 3,
    'lauritsen': 3,
    'lauritzen': 3,
    'lauro': 2,
    'laursen': 2,
    'laury': 2,
    'lausanne': 2,
    'lausch': 1,
    'laut': 1,
    'lauten': 2,
    'lautenbach': 3,
    'lautenberg': 3,
    'lautenschlager': 4,
    'lauter': 2,
    'lauterbach': 3,
    'lauth': 1,
    'lautner': 2,
    'lautzenheiser': 4,
    'lauver': 2,
    'laux': 1,
    'lauzon': 2,
    'lava': 2,
    'laval': 2,
    'lavalle': 3,
    'lavallee': 3,
    'lavalley': 3,
    'lavan': 2,
    'lavarnway': 3,
    'lavatories': 4,
    'lavatory': 4,
    'lave': 1,
    'laveau': 2,
    'lavecchia': 4,
    'laveda': 3,
    'lavell': 2,
    'lavelle': 2,
    'lavely': 2,
    'laven': 2,
    'lavender': 3,
    'laventhol': 3,
    'laventure': 3,
    'laver': 2,
    'laverdiere': 4,
    'laverdure': 4,
    'lavere': 3,
    'lavergne': 2,
    'laverne': 2,
    'lavers': 2,
    'laverty': 3,
    'lavery': 3,
    'lavetta': 3,
    'lavette': 2,
    'lavey': 2,
    'lavi': 2,
    'lavie': 2,
    'lavigne': 2,
    'lavin': 2,
    'lavina': 3,
    'lavinder': 3,
    'lavine': 3,
    'lavinia': 3,
    'laviolette': 4,
    'lavis': 2,
    'lavish': 2,
    'lavished': 2,
    'lavishes': 3,
    'lavishing': 3,
    'lavishly': 3,
    'lavoie': 2,
    'lavorgna': 3,
    'lavoro': 3,
    'lavoy': 2,
    'lavy': 2,
    'law': 1,
    'lawall': 2,
    'laware': 2,
    'lawbreaker': 3,
    'lawbreakers': 3,
    'lawbreaking': 3,
    'lawes': 1,
    'lawford': 2,
    'lawful': 2,
    'lawfully': 3,
    'lawhead': 2,
    'lawhon': 2,
    'lawhorn': 2,
    'lawhorne': 2,
    'lawing': 2,
    'lawler': 2,
    'lawless': 2,
    'lawlessness': 3,
    'lawley': 2,
    'lawlis': 2,
    'lawlor': 2,
    'lawmaker': 3,
    'lawmakers': 3,
    'lawmaking': 3,
    'lawman': 2,
    'lawmen': 2,
    'lawn': 1,
    'lawner': 2,
    'lawniczak': 3,
    'lawnmower': 3,
    'lawnmowers': 3,
    'lawns': 1,
    'lawnsdale': 2,
    'lawplan': 2,
    'lawrance': 2,
    'lawrence': 2,
    'lawrenceburg': 3,
    'lawrenceville': 3,
    'lawrencium': 4,
    'lawrenz': 2,
    'lawrie': 2,
    'lawry': 2,
    'laws': 1,
    'lawshe': 1,
    'lawson': 2,
    'lawsuit': 2,
    'lawsuits': 2,
    'lawter': 2,
    'lawther': 2,
    'lawton': 2,
    'lawver': 2,
    'lawyer': 2,
    'lawyered': 2,
    'lawyering': 3,
    'lawyerly': 3,
    'lawyers': 2,
    'lax': 1,
    'laxalt': 2,
    'laxative': 3,
    'laxatives': 3,
    'laxey': 2,
    'laxity': 3,
    'laxson': 2,
    'laxton': 2,
    'lay': 1,
    'layah': 2,
    'layaway': 3,
    'layborne': 2,
    'laycock': 2,
    'layden': 2,
    'laye': 1,
    'layer': 2,
    'layered': 2,
    'layering': 3,
    'layers': 2,
    'layfield': 2,
    'layhee': 2,
    'laying': 2,
    'layland': 2,
    'layman': 2,
    'laymen': 2,
    'laymon': 2,
    'layne': 1,
    'layoff': 2,
    'layoffs': 2,
    'layout': 2,
    'layouts': 2,
    'layover': 3,
    'layovers': 3,
    'laypeople': 3,
    'layperson': 3,
    'lays': 1,
    'layson': 2,
    'laythan': 2,
    'layton': 2,
    'lazar': 2,
    'lazard': 2,
    'lazare': 3,
    'lazaro': 3,
    'lazaroff': 3,
    'lazarski': 3,
    'lazarus': 3,
    'lazarz': 2,
    'lazcano': 3,
    'lazear': 2,
    'lazenby': 3,
    'lazer': 2,
    'lazere': 2,
    'lazier': 3,
    'laziest': 3,
    'lazily': 3,
    'laziness': 3,
    'lazo': 2,
    'lazor': 2,
    'lazuli': 3,
    'lazulite': 3,
    'lazur': 2,
    'lazy': 2,
    'lazzara': 3,
    'lazzari': 3,
    'lazzarini': 4,
    'lazzaro': 3,
    'lazzell': 2,
    'lb': 1,
    'lbs': 1,
    'lcs': 3,
    'le': 1,
    'lea': 1,
    'leabo': 2,
    'leach': 1,
    'leached': 1,
    'leaches': 2,
    'leaching': 2,
    'leachman': 2,
    'leacock': 2,
    'lead': 1,
    'leadbelly': 3,
    'leadbetter': 3,
    'leaded': 2,
    'leaden': 2,
    'leader': 2,
    'leaderless': 3,
    'leaders': 2,
    'leadership': 3,
    'leaderships': 3,
    'leading': 2,
    'leadingham': 3,
    'leadoff': 2,
    'leads': 1,
    'leadville': 2,
    'leadwork': 2,
    'leaf': 1,
    'leafed': 1,
    'leafhopper': 3,
    'leafhoppers': 3,
    'leafing': 2,
    'leafless': 2,
    'leaflet': 2,
    'leaflets': 2,
    'leaflike': 2,
    'leafs': 1,
    'leafy': 2,
    'league': 1,
    'leaguer': 2,
    'leaguers': 2,
    'leagues': 1,
    'leah': 2,
    'leahey': 2,
    'leahy': 2,
    'leak': 1,
    'leakage': 2,
    'leake': 1,
    'leaked': 1,
    'leaker': 2,
    'leakers': 2,
    'leakey': 2,
    'leaking': 2,
    'leaks': 1,
    'leaky': 2,
    'leal': 1,
    'leala': 2,
    'lealan': 2,
    'lealia': 3,
    'lealie': 2,
    'leaman': 2,
    'leamer': 2,
    'leaming': 2,
    'leamon': 2,
    'leamy': 2,
    'lean': 1,
    'leana': 2,
    'leander': 3,
    'leandro': 3,
    'leaned': 1,
    'leaner': 2,
    'leaners': 2,
    'leanest': 2,
    'leaning': 2,
    'leanings': 2,
    'leann': 2,
    'leans': 1,
    'leant': 2,
    'leanza': 3,
    'leap': 1,
    'leaped': 1,
    'leapfrog': 2,
    'leapfrogged': 2,
    'leapfrogging': 3,
    'leaphart': 2,
    'leaping': 2,
    'leapley': 2,
    'leaps': 1,
    'leapt': 1,
    'lear': 1,
    'leard': 1,
    'learjet': 2,
    'learn': 1,
    'learned': 1,
    'learner': 2,
    'learners': 2,
    'learning': 2,
    'learns': 1,
    'learnt': 1,
    'learonal': 3,
    'leary': 2,
    'leas': 1,
    'leasable': 3,
    'leasco': 2,
    'lease': 1,
    'leaseback': 2,
    'leasebacks': 2,
    'leased': 1,
    'leasehold': 2,
    'leaser': 2,
    'leases': 2,
    'leaseway': 2,
    'leash': 1,
    'leashed': 1,
    'leashes': 2,
    'leasing': 2,
    'leask': 1,
    'leason': 2,
    'least': 1,
    'leasure': 2,
    'leath': 1,
    'leatham': 2,
    'leathem': 2,
    'leather': 2,
    'leatherback': 3,
    'leatherbacks': 3,
    'leatherberry': 4,
    'leatherbury': 4,
    'leatherman': 3,
    'leathers': 2,
    'leatherwood': 3,
    'leathery': 3,
    'leaton': 2,
    'leatrice': 2,
    'leave': 1,
    'leavell': 2,
    'leavelle': 2,
    'leaven': 2,
    'leavened': 2,
    'leavening': 3,
    'leavens': 2,
    'leavenworth': 3,
    'leaver': 2,
    'leaverton': 3,
    'leaves': 1,
    'leavey': 2,
    'leaving': 2,
    'leavings': 2,
    'leavins': 2,
    'leavitt': 2,
    'leavy': 2,
    'leazer': 2,
    'lebanese': 3,
    'lebanon': 3,
    'lebar': 2,
    'lebaron': 3,
    'lebarron': 3,
    'lebeau': 2,
    'lebeck': 2,
    'lebed': 2,
    'lebeda': 3,
    'lebegue': 2,
    'lebel': 2,
    'leben': 2,
    'lebenthal': 3,
    'leber': 2,
    'lebert': 2,
    'leblanc': 2,
    'leble': 2,
    'lebleu': 2,
    'leblond': 2,
    'lebo': 2,
    'leboeuf': 2,
    'lebold': 2,
    'lebon': 2,
    'lebouef': 2,
    'lebovitz': 3,
    'lebow': 2,
    'lebowe': 2,
    'lebowitz': 3,
    'lebrecht': 2,
    'lebreton': 3,
    'lebron': 2,
    'lebrun': 2,
    'lebsack': 2,
    'lebudde': 2,
    'lecates': 2,
    'leccese': 3,
    'lech': 1,
    'lecher': 2,
    'lecherous': 3,
    'lechler': 2,
    'lechman': 2,
    'lechmere': 2,
    'lechner': 2,
    'lechtenberg': 3,
    'lechters': 2,
    'lechuga': 3,
    'lecithin': 3,
    'leck': 1,
    'leckey': 2,
    'leckie': 2,
    'leckrone': 2,
    'leclair': 2,
    'leclaire': 2,
    'leclerc': 2,
    'leclercq': 2,
    'leclere': 2,
    'lecli': 2,
    'lecocq': 2,
    'lecomba': 3,
    'lecompte': 2,
    'lecomte': 2,
    'leconte': 3,
    'lecount': 2,
    'lecrone': 3,
    'lecroy': 2,
    'lectec': 2,
    'lecter': 2,
    'lectern': 2,
    'lector': 2,
    'lecture': 2,
    'lectured': 2,
    'lecturer': 3,
    'lecturers': 3,
    'lectures': 2,
    'lecturing': 3,
    'lecuyer': 3,
    'lecy': 2,
    'led': 1,
    'leda': 2,
    'leday': 2,
    'ledbetter': 3,
    'ledden': 2,
    'leddy': 2,
    'ledeen': 2,
    'leder': 2,
    'lederer': 3,
    'lederle': 3,
    'lederman': 3,
    'ledesma': 3,
    'ledet': 2,
    'ledezma': 3,
    'ledford': 2,
    'ledge': 1,
    'ledger': 2,
    'ledgers': 2,
    'ledgerwood': 3,
    'ledges': 2,
    'ledin': 2,
    'ledlow': 2,
    'ledo': 2,
    'ledonne': 2,
    'ledoux': 2,
    'ledvina': 3,
    'ledwell': 2,
    'ledwith': 2,
    'ledyard': 2,
    'lee': 1,
    'leeb': 1,
    'leece': 1,
    'leech': 1,
    'leeches': 2,
    'leeco': 2,
    'leed': 1,
    'leeder': 2,
    'leedham': 2,
    'leedom': 2,
    'leeds': 1,
    'leedy': 2,
    'leek': 1,
    'leeks': 1,
    'leeman': 2,
    'leeming': 2,
    'leemon': 2,
    'leen': 1,
    'leena': 2,
    'leep': 1,
    'leeper': 2,
    'leery': 2,
    'lees': 1,
    'leesburg': 2,
    'leese': 1,
    'leeser': 2,
    'leeson': 2,
    'leet': 1,
    'leetch': 1,
    'leete': 1,
    'leeth': 1,
    'leeuwen': 2,
    'leever': 2,
    'leeward': 2,
    'leeway': 2,
    'lefauve': 2,
    'lefave': 2,
    'lefcourt': 2,
    'lefeber': 3,
    'lefebre': 3,
    'lefebure': 3,
    'lefebvre': 3,
    'lefever': 3,
    'lefevers': 3,
    'lefevre': 3,
    'leff': 1,
    'leffel': 2,
    'leffert': 2,
    'lefferts': 2,
    'leffew': 2,
    'leffingwell': 3,
    'leffler': 2,
    'lefkowitz': 3,
    'lefler': 2,
    'lefleur': 2,
    'leflore': 2,
    'lefort': 2,
    'lefrak': 2,
    'lefrancois': 3,
    'lefrere': 2,
    'left': 1,
    'lefthand': 2,
    'lefthanded': 3,
    'lefties': 2,
    'leftism': 3,
    'leftist': 2,
    'leftists': 2,
    'lefton': 2,
    'leftover': 3,
    'leftovers': 3,
    'leftridge': 2,
    'leftward': 2,
    'leftwich': 2,
    'leftwing': 2,
    'leftwinger': 3,
    'leftwingers': 3,
    'lefty': 2,
    'leg': 1,
    'legacies': 3,
    'legacy': 3,
    'legal': 2,
    'legalese': 3,
    'legalism': 4,
    'legalistic': 4,
    'legalities': 4,
    'legality': 4,
    'legalization': 5,
    'legalize': 3,
    'legalized': 3,
    'legalizing': 4,
    'legally': 3,
    'legals': 2,
    'legan': 2,
    'legare': 3,
    'legaspi': 3,
    'legate': 2,
    'legates': 2,
    'legato': 3,
    'legault': 2,
    'lege': 1,
    'legend': 2,
    'legendary': 4,
    'legendre': 3,
    'legends': 2,
    'legent': 2,
    'leger': 2,
    'legerdemain': 4,
    'legere': 2,
    'leget': 2,
    'legette': 2,
    'legg': 1,
    'legge': 1,
    'legged': 2,
    'leggett': 2,
    'leggette': 2,
    'legging': 2,
    'leggings': 2,
    'leggio': 3,
    'leggitt': 2,
    'leggy': 2,
    'leghorn': 2,
    'leghorns': 2,
    'legibility': 5,
    'legible': 3,
    'legion': 2,
    'legionaries': 4,
    'legionnaire': 3,
    'legionnaires': 3,
    'legions': 2,
    'legislate': 3,
    'legislated': 4,
    'legislates': 3,
    'legislating': 4,
    'legislation': 4,
    'legislative': 4,
    'legislatively': 5,
    'legislator': 4,
    'legislators': 4,
    'legislature': 4,
    'legislatures': 4,
    'legit': 2,
    'legitimacy': 5,
    'legitimate': 4,
    'legitimately': 5,
    'legitimize': 4,
    'legitimized': 4,
    'legitimizes': 5,
    'legitimizing': 5,
    'legler': 2,
    'legless': 2,
    'legner': 2,
    'lego': 2,
    'legore': 2,
    'legrand': 2,
    'legrande': 2,
    'legree': 2,
    'legroom': 2,
    'legros': 2,
    'legs': 1,
    'legume': 2,
    'legumes': 2,
    'legwork': 2,
    'leh': 1,
    'lehan': 2,
    'lehane': 2,
    'lehder': 2,
    'lehenbauer': 4,
    'lehew': 2,
    'lehigh': 2,
    'lehl': 1,
    'lehman': 2,
    'lehmann': 2,
    'lehmer': 2,
    'lehmkuhl': 2,
    'lehn': 1,
    'lehne': 1,
    'lehnen': 2,
    'lehner': 2,
    'lehnert': 2,
    'lehnhoff': 2,
    'lehr': 1,
    'lehrer': 2,
    'lehrke': 1,
    'lehrman': 2,
    'lehrmann': 2,
    'lehtinen': 3,
    'lehtonen': 3,
    'lei': 1,
    'leib': 1,
    'leibel': 2,
    'leibensperger': 4,
    'leiber': 2,
    'leibert': 2,
    'leibfried': 3,
    'leibler': 2,
    'leibman': 2,
    'leibniz': 2,
    'leibold': 2,
    'leibovit': 3,
    'leibovitz': 3,
    'leibowitz': 3,
    'leibrand': 2,
    'leibrock': 2,
    'leiby': 2,
    'leicester': 2,
    'leichliter': 3,
    'leichner': 2,
    'leicht': 1,
    'leichter': 2,
    'leichtman': 2,
    'leichty': 2,
    'leick': 1,
    'leider': 2,
    'leiderman': 3,
    'leidig': 2,
    'leidner': 2,
    'leidy': 2,
    'leier': 2,
    'leif': 1,
    'leifer': 2,
    'leifeste': 2,
    'leifheit': 2,
    'leigh': 1,
    'leight': 1,
    'leighton': 2,
    'leighty': 2,
    'leija': 2,
    'leikam': 2,
    'leiken': 2,
    'leiker': 2,
    'leila': 2,
    'leilani': 3,
    'leilia': 3,
    'leiman': 2,
    'leimbach': 2,
    'leimer': 2,
    'leimert': 2,
    'lein': 1,
    'leinart': 2,
    'leinbach': 2,
    'leinberger': 3,
    'leindecker': 3,
    'leinen': 2,
    'leiner': 2,
    'leingang': 2,
    'leininger': 3,
    'leino': 3,
    'leinonen': 3,
    'leins': 1,
    'leinsdorf': 2,
    'leinweber': 3,
    'leiper': 2,
    'leiphart': 2,
    'leipold': 2,
    'leipzig': 2,
    'leis': 1,
    'leischner': 2,
    'leise': 1,
    'leisenring': 3,
    'leiser': 2,
    'leisey': 2,
    'leishman': 2,
    'leising': 2,
    'leisinger': 3,
    'leisner': 2,
    'leiss': 1,
    'leist': 2,
    'leister': 2,
    'leistikow': 3,
    'leistner': 2,
    'leisure': 2,
    'leisurely': 3,
    'leisy': 2,
    'leitch': 1,
    'leite': 1,
    'leiter': 2,
    'leiterman': 3,
    'leith': 1,
    'leitha': 2,
    'leithia': 3,
    'leitman': 2,
    'leitmotif': 3,
    'leitner': 2,
    'leitz': 1,
    'leitzel': 2,
    'leitzke': 2,
    'leiva': 2,
    'leja': 2,
    'lejeune': 2,
    'lek': 1,
    'lekachman': 3,
    'lekas': 2,
    'lekberg': 2,
    'lela': 2,
    'lelah': 2,
    'leland': 2,
    'leleux': 2,
    'lelia': 2,
    'lellouche': 2,
    'lem': 1,
    'lema': 2,
    'lemaire': 2,
    'lemaitre': 3,
    'leman': 2,
    'lemans': 2,
    'lemanski': 3,
    'lemar': 2,
    'lemarr': 2,
    'lemaster': 3,
    'lemasters': 3,
    'lemay': 2,
    'lembcke': 2,
    'lemberg': 2,
    'lemberger': 3,
    'lembke': 1,
    'lembo': 2,
    'lemburg': 2,
    'lemcke': 2,
    'lemelin': 3,
    'lemelle': 2,
    'lemen': 2,
    'lemercier': 4,
    'lemere': 2,
    'lemery': 3,
    'lemieux': 2,
    'leming': 2,
    'lemire': 3,
    'lemke': 2,
    'lemler': 2,
    'lemley': 2,
    'lemm': 1,
    'lemma': 2,
    'lemme': 2,
    'lemmen': 2,
    'lemmer': 2,
    'lemmerman': 3,
    'lemmie': 2,
    'lemming': 2,
    'lemmings': 2,
    'lemmo': 2,
    'lemmon': 2,
    'lemmond': 2,
    'lemmons': 2,
    'lemoine': 2,
    'lemon': 2,
    'lemonade': 3,
    'lemond': 2,
    'lemonds': 2,
    'lemons': 2,
    'lemont': 2,
    'lemos': 2,
    'lemp': 1,
    'lempert': 2,
    'lempke': 1,
    'lemuela': 3,
    'lemur': 2,
    'lemurs': 2,
    'lemus': 2,
    'lemzo': 2,
    'len': 1,
    'lena': 2,
    'lenahan': 3,
    'lenard': 2,
    'lenart': 2,
    'lenarz': 2,
    'lencioni': 3,
    'lend': 1,
    'lender': 2,
    'lenderman': 3,
    'lenders': 2,
    'lending': 2,
    'lendings': 2,
    'lendl': 2,
    'lends': 1,
    'lene': 1,
    'lenehan': 3,
    'leneta': 3,
    'lenexa': 3,
    'leng': 1,
    'lengacher': 3,
    'lengel': 2,
    'lenger': 2,
    'lengle': 2,
    'length': 1,
    'lengthen': 2,
    'lengthened': 2,
    'lengthening': 3,
    'lengthens': 2,
    'lengths': 1,
    'lengthways': 2,
    'lengthwise': 2,
    'lengthy': 2,
    'lengyel': 2,
    'lenhard': 2,
    'lenhardt': 2,
    'lenhart': 2,
    'lenhoff': 2,
    'leniency': 3,
    'lenient': 3,
    'leniently': 3,
    'lenig': 2,
    'lenihan': 3,
    'lenin': 2,
    'leningrad': 3,
    'lenington': 3,
    'leninism': 4,
    'leninist': 3,
    'lenis': 2,
    'lenita': 3,
    'lenius': 3,
    'lenk': 1,
    'lenke': 1,
    'lenker': 2,
    'lennane': 2,
    'lennar': 2,
    'lennard': 2,
    'lennartz': 2,
    'lennie': 2,
    'lennig': 2,
    'lenning': 2,
    'lennon': 2,
    'lennox': 2,
    'lenny': 2,
    'leno': 2,
    'lenon': 2,
    'lenora': 3,
    'lenore': 2,
    'lenos': 2,
    'lenox': 2,
    'lens': 1,
    'lenscrafter': 3,
    'lenscrafters': 3,
    'lenses': 2,
    'lensing': 2,
    'lent': 1,
    'lentil': 2,
    'lentils': 2,
    'lentinan': 3,
    'lentine': 3,
    'lentini': 3,
    'lentner': 2,
    'lento': 2,
    'lenton': 2,
    'lents': 1,
    'lentsch': 1,
    'lentz': 1,
    'lentzsch': 1,
    'leny': 2,
    'lenz': 1,
    'lenzen': 2,
    'lenzi': 2,
    'lenzini': 3,
    'lenzo': 2,
    'leo': 2,
    'leoda': 2,
    'leoine': 2,
    'leola': 2,
    'leoma': 3,
    'leominster': 4,
    'leon': 2,
    'leona': 3,
    'leonara': 3,
    'leonard': 2,
    'leonarda': 3,
    'leonardi': 3,
    'leonardis': 3,
    'leonardo': 4,
    'leone': 2,
    'leonel': 3,
    'leonelle': 2,
    'leonelli': 3,
    'leonerd': 3,
    'leones': 2,
    'leonetti': 3,
    'leong': 1,
    'leonhard': 3,
    'leonhardt': 2,
    'leonhart': 2,
    'leoni': 3,
    'leonid': 3,
    'leonie': 2,
    'leonora': 4,
    'leonore': 3,
    'leontine': 3,
    'leontyne': 3,
    'leopard': 2,
    'leopards': 2,
    'leopold': 3,
    'leopoldina': 4,
    'leopoldine': 4,
    'leopoldo': 4,
    'leora': 2,
    'leos': 2,
    'leota': 2,
    'leotard': 3,
    'lep': 1,
    'lepage': 2,
    'lepak': 2,
    'lepard': 2,
    'lepe': 1,
    'leper': 2,
    'lepera': 3,
    'lepere': 3,
    'lepers': 2,
    'lepine': 3,
    'lepisto': 3,
    'lepkowski': 3,
    'lepley': 2,
    'lepore': 3,
    'lepp': 1,
    'leppanen': 3,
    'leppard': 2,
    'leppek': 2,
    'lepper': 2,
    'leppert': 2,
    'leppla': 2,
    'leppo': 2,
    'lepre': 2,
    'leprosy': 3,
    'leptin': 2,
    'leptonic': 3,
    'lequire': 3,
    'lerach': 2,
    'lerch': 1,
    'lerche': 1,
    'lereah': 3,
    'lerette': 2,
    'lerew': 2,
    'lerhman': 2,
    'lerma': 2,
    'lerman': 2,
    'lerner': 2,
    'leroux': 2,
    'leroy': 2,
    'lerro': 2,
    'lersch': 1,
    'lerum': 2,
    'les': 1,
    'lesa': 2,
    'lesabre': 3,
    'lesabres': 3,
    'lesage': 2,
    'lesane': 3,
    'lesbian': 3,
    'lesbianism': 4,
    'lesbians': 3,
    'lescaze': 2,
    'lesch': 1,
    'leschly': 2,
    'leser': 2,
    'lesesne': 2,
    'lesh': 1,
    'lesharo': 3,
    'lesher': 2,
    'lesi': 2,
    'lesiak': 3,
    'lesieur': 3,
    'lesinski': 3,
    'lesion': 2,
    'lesions': 2,
    'leske': 1,
    'lesko': 2,
    'lesley': 2,
    'leslie': 2,
    'lesmeister': 3,
    'lesner': 2,
    'lesniak': 3,
    'lesnick': 2,
    'lesniewski': 3,
    'lesotho': 3,
    'lesperance': 3,
    'lespinasse': 3,
    'less': 1,
    'lessard': 2,
    'lessee': 2,
    'lessees': 2,
    'lessen': 2,
    'lessened': 2,
    'lessening': 3,
    'lessens': 2,
    'lesser': 2,
    'lessig': 2,
    'lessin': 2,
    'lessing': 2,
    'lessley': 2,
    'lessman': 2,
    'lessnau': 2,
    'lessner': 2,
    'lessness': 2,
    'lesson': 2,
    'lessons': 2,
    'lessor': 2,
    'lessors': 2,
    'lest': 1,
    'lestat': 2,
    'lester': 2,
    'lestrange': 2,
    'lesuer': 3,
    'lesueur': 3,
    'lesure': 2,
    'leszczynski': 3,
    'leszek': 2,
    'let': 1,
    'leta': 2,
    'letarte': 2,
    'letcher': 2,
    'letchworth': 2,
    'letdown': 2,
    'letellier': 4,
    'letendre': 3,
    'leth': 1,
    'letha': 2,
    'lethal': 2,
    'lethality': 4,
    'lethargic': 3,
    'lethargy': 3,
    'lethco': 2,
    'lethia': 3,
    'leticia': 3,
    'letitia': 4,
    'letizia': 4,
    'letner': 2,
    'leto': 2,
    'letourneau': 3,
    'lets': 1,
    'letsch': 1,
    'letsinger': 3,
    'letson': 2,
    'lett': 1,
    'lettau': 2,
    'letter': 2,
    'lettered': 2,
    'letterhead': 3,
    'lettering': 3,
    'letterman': 3,
    'letterpress': 3,
    'letters': 2,
    'lettice': 2,
    'lettie': 2,
    'lettiere': 3,
    'lettieri': 3,
    'letting': 2,
    'lettish': 2,
    'letts': 1,
    'lettuce': 2,
    'letty': 2,
    'letup': 2,
    'letz': 1,
    'leu': 1,
    'leubert': 2,
    'leucadia': 4,
    'leuck': 1,
    'leuenberger': 4,
    'leuffer': 2,
    'leugers': 2,
    'leukemia': 4,
    'leukocyte': 3,
    'leumi': 2,
    'leung': 1,
    'leupold': 2,
    'leuschner': 2,
    'leuthold': 2,
    'leutwiler': 3,
    'leuzzi': 2,
    'lev': 1,
    'leva': 2,
    'leval': 2,
    'levalley': 3,
    'levamisole': 4,
    'levan': 2,
    'levana': 3,
    'levander': 3,
    'levandoski': 4,
    'levandowski': 4,
    'levangie': 3,
    'levant': 2,
    'levario': 4,
    'levasseur': 3,
    'levay': 2,
    'levchenko': 3,
    'leve': 1,
    'leveck': 2,
    'levee': 2,
    'levees': 2,
    'leveille': 2,
    'level': 2,
    'leveled': 2,
    'levelers': 3,
    'leveling': 3,
    'levell': 2,
    'levelled': 2,
    'levels': 2,
    'leven': 2,
    'levenberg': 3,
    'levendusky': 4,
    'levene': 2,
    'levengood': 3,
    'levenhagen': 4,
    'levens': 2,
    'levenson': 3,
    'levenstein': 3,
    'leventhal': 3,
    'leveque': 2,
    'lever': 2,
    'leverage': 3,
    'leveraged': 3,
    'leveraging': 3,
    'leverenz': 3,
    'leverett': 3,
    'leverette': 3,
    'leverich': 3,
    'levering': 3,
    'leverone': 4,
    'levers': 2,
    'leverson': 3,
    'levert': 2,
    'leverton': 3,
    'levesque': 2,
    'levett': 2,
    'levey': 2,
    'levi': 2,
    'leviathan': 4,
    'levick': 2,
    'levie': 2,
    'levied': 2,
    'levien': 3,
    'levies': 2,
    'levin': 2,
    'levina': 3,
    'levine': 2,
    'leviner': 3,
    'levings': 2,
    'levingston': 3,
    'levins': 2,
    'levinsky': 3,
    'levinson': 3,
    'levinstone': 3,
    'levis': 2,
    'levison': 3,
    'levit': 2,
    'levitan': 3,
    'levitate': 3,
    'levitated': 4,
    'levitating': 4,
    'levitation': 4,
    'levitch': 2,
    'levitican': 4,
    'leviticus': 4,
    'leviton': 3,
    'levitsky': 3,
    'levitt': 2,
    'levitte': 2,
    'levittown': 3,
    'levity': 3,
    'levitz': 2,
    'levy': 2,
    'levying': 3,
    'lew': 1,
    'lewallen': 3,
    'lewan': 2,
    'lewanda': 3,
    'lewandoski': 4,
    'lewandowski': 4,
    'lewanna': 3,
    'lewd': 1,
    'lewellen': 3,
    'lewelling': 3,
    'lewellyn': 3,
    'lewensky': 3,
    'lewers': 2,
    'lewey': 2,
    'lewi': 2,
    'lewicki': 3,
    'lewin': 2,
    'lewing': 2,
    'lewins': 2,
    'lewinski': 3,
    'lewinsky': 3,
    'lewinsohn': 3,
    'lewinton': 3,
    'lewis': 2,
    'lewisburg': 3,
    'lewiston': 3,
    'lewke': 2,
    'lewkowicz': 3,
    'lewman': 2,
    'lewter': 2,
    'lewton': 2,
    'lewy': 2,
    'lex': 1,
    'lexical': 3,
    'lexicographer': 5,
    'lexicographers': 5,
    'lexicon': 3,
    'lexicons': 3,
    'lexie': 2,
    'lexine': 2,
    'lexington': 3,
    'lexiphonic': 4,
    'lexis': 2,
    'lexmark': 2,
    'lexus': 2,
    'ley': 1,
    'leya': 2,
    'leyba': 2,
    'leyda': 2,
    'leyden': 2,
    'leyendecker': 3,
    'leyh': 1,
    'leyland': 2,
    'leyrer': 2,
    'leys': 1,
    'leysen': 2,
    'leyva': 2,
    'lezotte': 2,
    'lhasa': 2,
    'lheureux': 2,
    'lhommedieu': 3,
    'li': 1,
    'lia': 2,
    'liabilities': 5,
    'liability': 5,
    'liable': 3,
    'liacos': 3,
    'liaison': 3,
    'liaisons': 3,
    'liam': 2,
    'lian': 1,
    'liana': 3,
    'liane': 2,
    'liang': 1,
    'lianna': 3,
    'lianne': 2,
    'liao': 2,
    'liar': 2,
    'liars': 2,
    'liason': 3,
    'liasson': 3,
    'liau': 2,
    'lib': 1,
    'libbed': 1,
    'libbey': 2,
    'libbing': 2,
    'libby': 2,
    'libel': 2,
    'libeled': 2,
    'libelous': 3,
    'liberace': 4,
    'liberal': 3,
    'liberalism': 5,
    'liberality': 5,
    'liberalization': 6,
    'liberalizations': 6,
    'liberalize': 4,
    'liberalized': 4,
    'liberalizes': 5,
    'liberalizing': 5,
    'liberally': 4,
    'liberals': 3,
    'liberate': 3,
    'liberated': 4,
    'liberati': 4,
    'liberating': 4,
    'liberation': 4,
    'liberato': 4,
    'liberator': 4,
    'liberatore': 5,
    'liberators': 4,
    'liberia': 4,
    'liberian': 4,
    'liberians': 4,
    'liberman': 3,
    'libert': 2,
    'libertarian': 5,
    'libertarians': 5,
    'liberte': 3,
    'liberti': 3,
    'liberties': 3,
    'libertine': 3,
    'libertines': 3,
    'liberto': 3,
    'liberty': 3,
    'libido': 3,
    'libman': 2,
    'libor': 2,
    'libra': 2,
    'librarian': 4,
    'librarians': 4,
    'libraries': 3,
    'library': 3,
    'libration': 3,
    'librettist': 3,
    'libretto': 3,
    'librizzi': 3,
    'liby': 2,
    'libya': 3,
    'libyan': 3,
    'libyans': 3,
    'licari': 3,
    'licata': 3,
    'licausi': 3,
    'licavoli': 4,
    'licciardi': 3,
    'lice': 1,
    'licea': 3,
    'licence': 2,
    'licences': 3,
    'license': 2,
    'licensed': 2,
    'licensee': 3,
    'licensees': 3,
    'licenser': 3,
    'licenses': 3,
    'licensing': 3,
    'licensor': 3,
    'licensure': 3,
    'licentious': 3,
    'lich': 1,
    'lichen': 2,
    'lichens': 2,
    'lichlyter': 3,
    'licht': 1,
    'lichtblau': 2,
    'lichte': 1,
    'lichten': 2,
    'lichtenberg': 3,
    'lichtenberger': 4,
    'lichtenfels': 3,
    'lichtenstein': 3,
    'lichtenwalner': 4,
    'lichtenwalter': 4,
    'lichter': 2,
    'lichterman': 3,
    'lichtman': 2,
    'lichty': 2,
    'licio': 3,
    'licitra': 3,
    'lick': 1,
    'licked': 1,
    'licker': 2,
    'lickety': 3,
    'licking': 2,
    'licklider': 3,
    'licks': 1,
    'lickteig': 2,
    'lico': 2,
    'licon': 2,
    'licorice': 3,
    'lid': 1,
    'lida': 2,
    'liddell': 2,
    'liddick': 2,
    'liddicoat': 3,
    'liddle': 2,
    'liddy': 2,
    'lide': 1,
    'liden': 2,
    'lidgerwood': 3,
    'lido': 2,
    'lids': 1,
    'lie': 1,
    'lieb': 1,
    'liebe': 1,
    'liebel': 2,
    'liebeler': 3,
    'liebelt': 2,
    'liebenow': 3,
    'lieber': 2,
    'lieberman': 3,
    'liebermann': 3,
    'liebert': 2,
    'lieberthal': 3,
    'liebig': 2,
    'liebl': 2,
    'liebler': 2,
    'liebling': 3,
    'liebman': 2,
    'liebmann': 2,
    'liebowitz': 3,
    'liechtenstein': 3,
    'liechty': 2,
    'lied': 1,
    'liedel': 2,
    'lieder': 2,
    'liederman': 3,
    'liedtke': 2,
    'lief': 1,
    'liefer': 2,
    'liege': 1,
    'liem': 1,
    'liemandt': 2,
    'lien': 1,
    'lienau': 2,
    'lienemann': 3,
    'liener': 2,
    'lienhard': 2,
    'lienhart': 2,
    'liens': 1,
    'lierman': 2,
    'liermann': 2,
    'lies': 1,
    'liesch': 1,
    'liese': 1,
    'lieser': 2,
    'liesin': 2,
    'lieske': 2,
    'lieson': 2,
    'lietz': 1,
    'lietzke': 2,
    'lieu': 1,
    'lieurance': 2,
    'lieutenant': 3,
    'lieutenants': 3,
    'life': 1,
    'lifeblood': 2,
    'lifeboat': 2,
    'lifeboats': 2,
    'lifeco': 2,
    'lifeguard': 2,
    'lifeguards': 2,
    'lifeless': 2,
    'lifelike': 2,
    'lifeline': 2,
    'lifelines': 2,
    'lifelong': 2,
    'lifer': 2,
    'lifers': 2,
    'lifes': 1,
    'lifesaver': 3,
    'lifesavers': 3,
    'lifesaving': 3,
    'lifespan': 2,
    'lifespans': 2,
    'lifestyle': 2,
    'lifestyles': 2,
    'lifetime': 2,
    'lifetimes': 2,
    'liff': 1,
    'liffe': 1,
    'lifland': 2,
    'lifo': 2,
    'liford': 2,
    'lifsey': 2,
    'lifshitz': 2,
    'lifson': 2,
    'lift': 1,
    'lifted': 2,
    'lifter': 2,
    'lifters': 2,
    'liftin': 2,
    'lifting': 2,
    'liftoff': 2,
    'lifton': 2,
    'lifts': 1,
    'ligachev': 3,
    'ligament': 3,
    'ligaments': 3,
    'ligand': 2,
    'ligands': 2,
    'ligas': 2,
    'ligation': 3,
    'ligget': 2,
    'liggett': 2,
    'liggins': 2,
    'liggio': 3,
    'light': 1,
    'lightbulb': 2,
    'lightbulbs': 2,
    'lightcap': 2,
    'lighted': 2,
    'lighten': 2,
    'lightened': 2,
    'lightening': 3,
    'lightens': 2,
    'lighter': 2,
    'lighters': 2,
    'lightest': 2,
    'lightfast': 2,
    'lightfastness': 3,
    'lightfoot': 2,
    'lighthall': 2,
    'lightheaded': 3,
    'lightheadedness': 4,
    'lighthearted': 3,
    'lighthizer': 3,
    'lighthouse': 2,
    'lighthouses': 3,
    'lighting': 2,
    'lightle': 2,
    'lightly': 2,
    'lightner': 2,
    'lightness': 2,
    'lightning': 2,
    'lightnings': 2,
    'lights': 1,
    'lightsey': 2,
    'lightship': 2,
    'lightships': 2,
    'lightstone': 2,
    'lightweight': 2,
    'lighty': 2,
    'ligman': 2,
    'lignin': 2,
    'lignite': 2,
    'ligon': 2,
    'liguori': 3,
    'lihue': 2,
    'lijewski': 3,
    'likable': 3,
    'like': 1,
    'likeable': 3,
    'liked': 1,
    'likelier': 3,
    'likeliest': 3,
    'likelihood': 3,
    'likely': 2,
    'liken': 2,
    'likened': 2,
    'likeness': 2,
    'likenesses': 3,
    'likening': 3,
    'likens': 2,
    'likes': 1,
    'likewise': 2,
    'likhachov': 3,
    'likhyani': 3,
    'liking': 2,
    'likins': 2,
    'likud': 2,
    'lil': 1,
    'lila': 2,
    'lilac': 2,
    'lilacs': 2,
    'lilah': 2,
    'lilco': 2,
    'lile': 1,
    'liles': 1,
    'liley': 2,
    'lili': 2,
    'lilia': 3,
    'lilian': 3,
    'liliana': 4,
    'liliane': 3,
    'lilias': 3,
    'lilien': 3,
    'lilienthal': 3,
    'lilies': 2,
    'lilith': 2,
    'lilja': 2,
    'liljedahl': 3,
    'liljegren': 3,
    'lill': 1,
    'lilla': 2,
    'lillard': 2,
    'lille': 1,
    'lillehamer': 4,
    'lillehammer': 4,
    'liller': 2,
    'lilley': 2,
    'lilli': 2,
    'lillian': 3,
    'lilliana': 4,
    'lillibridge': 3,
    'lillich': 2,
    'lillicrop': 3,
    'lillie': 2,
    'lilliputian': 4,
    'lillis': 2,
    'lillo': 2,
    'lilly': 2,
    'lilt': 1,
    'lily': 2,
    'lilyan': 3,
    'lilybell': 3,
    'lim': 1,
    'lima': 2,
    'liman': 2,
    'limas': 2,
    'limb': 1,
    'limbach': 2,
    'limbaugh': 2,
    'limbed': 1,
    'limber': 2,
    'limberg': 2,
    'limbers': 2,
    'limbert': 2,
    'limbless': 2,
    'limbo': 2,
    'limbrick': 2,
    'limbs': 1,
    'limburg': 2,
    'lime': 1,
    'limehouse': 2,
    'limelight': 2,
    'limerick': 3,
    'limericks': 3,
    'limes': 1,
    'limestone': 2,
    'limestones': 2,
    'limine': 2,
    'liming': 2,
    'limit': 2,
    'limitation': 4,
    'limitations': 4,
    'limited': 3,
    'limiting': 3,
    'limitless': 3,
    'limits': 2,
    'limmer': 2,
    'limnology': 4,
    'limo': 2,
    'limoges': 3,
    'limon': 2,
    'limonite': 3,
    'limos': 2,
    'limousine': 3,
    'limousines': 3,
    'limp': 1,
    'limped': 1,
    'limpert': 2,
    'limpets': 2,
    'limping': 2,
    'limps': 1,
    'lin': 1,
    'lina': 2,
    'linafelter': 4,
    'linage': 2,
    'linam': 2,
    'linares': 2,
    'linc': 1,
    'lincare': 2,
    'lince': 1,
    'lincecum': 3,
    'linch': 1,
    'linchpin': 2,
    'lincicome': 3,
    'linck': 1,
    'lincks': 1,
    'lincoln': 2,
    'lincolns': 2,
    'lincolnshire': 3,
    'lind': 1,
    'linda': 2,
    'lindahl': 2,
    'lindaman': 3,
    'lindamood': 3,
    'lindane': 2,
    'lindau': 2,
    'lindauer': 3,
    'lindbeck': 2,
    'lindberg': 2,
    'lindbergh': 2,
    'lindblad': 2,
    'lindblom': 2,
    'lindbloom': 2,
    'lindburg': 2,
    'linde': 1,
    'lindeen': 2,
    'lindell': 2,
    'lindeman': 2,
    'lindemann': 3,
    'lindemuth': 3,
    'linden': 2,
    'lindenbaum': 3,
    'lindenberg': 3,
    'lindenberger': 4,
    'lindenmuth': 3,
    'linder': 2,
    'linderman': 3,
    'lindfors': 2,
    'lindgren': 2,
    'lindh': 1,
    'lindholm': 2,
    'lindhorst': 2,
    'lindig': 2,
    'lindler': 2,
    'lindley': 2,
    'lindman': 2,
    'lindmark': 2,
    'lindner': 2,
    'lindo': 2,
    'lindon': 2,
    'lindow': 2,
    'lindquist': 2,
    'lindroth': 2,
    'lindsay': 2,
    'lindseth': 2,
    'lindsey': 2,
    'lindskog': 2,
    'lindsley': 2,
    'lindstedt': 2,
    'lindstrand': 2,
    'lindstrom': 2,
    'lindvall': 2,
    'lindy': 2,
    'line': 1,
    'lineage': 3,
    'lineages': 4,
    'lineal': 3,
    'linear': 3,
    'linearly': 4,
    'lineback': 2,
    'linebacker': 3,
    'linebackers': 3,
    'linebarger': 4,
    'linebaugh': 3,
    'lineberger': 3,
    'lineberry': 3,
    'lined': 1,
    'linehan': 3,
    'lineitem': 3,
    'lineman': 2,
    'linemen': 2,
    'linen': 2,
    'linenberger': 4,
    'linenger': 3,
    'linens': 2,
    'liner': 2,
    'linerboard': 3,
    'liners': 2,
    'lines': 1,
    'linette': 2,
    'lineup': 2,
    'lineups': 2,
    'lineweaver': 3,
    'linford': 2,
    'ling': 1,
    'lingafelter': 4,
    'lingard': 2,
    'lingelbach': 3,
    'lingenfelter': 4,
    'linger': 2,
    'lingered': 2,
    'lingerfelt': 3,
    'lingerie': 3,
    'lingering': 3,
    'lingers': 2,
    'lingg': 1,
    'lingle': 2,
    'lingner': 2,
    'lingo': 2,
    'lings': 1,
    'lingua': 2,
    'linguine': 3,
    'linguist': 2,
    'linguistic': 3,
    'linguistically': 4,
    'linguistics': 3,
    'linguists': 2,
    'lingus': 2,
    'linh': 1,
    'linhardt': 2,
    'linhares': 2,
    'linhart': 2,
    'lini': 2,
    'lining': 2,
    'lininger': 3,
    'linings': 2,
    'link': 1,
    'linkage': 2,
    'linkages': 3,
    'linke': 1,
    'linked': 1,
    'linkenhoker': 4,
    'linker': 2,
    'linking': 2,
    'linkletter': 3,
    'linkous': 2,
    'links': 1,
    'linkup': 2,
    'linkups': 2,
    'linley': 2,
    'linn': 1,
    'linnane': 2,
    'linnas': 2,
    'linne': 1,
    'linnea': 3,
    'linnehan': 3,
    'linnell': 2,
    'linneman': 2,
    'linnemann': 2,
    'linnet': 2,
    'linney': 2,
    'linnik': 2,
    'lino': 2,
    'linoleum': 4,
    'linotype': 3,
    'linowes': 2,
    'linquist': 2,
    'lins': 1,
    'linsay': 2,
    'linscomb': 2,
    'linscott': 2,
    'linse': 1,
    'linseed': 2,
    'linsey': 2,
    'linskey': 2,
    'linsky': 2,
    'linsley': 2,
    'linson': 2,
    'linstrom': 2,
    'lint': 1,
    'lintas': 2,
    'lintel': 2,
    'linters': 2,
    'linthicum': 3,
    'lintner': 2,
    'linton': 2,
    'lintz': 1,
    'linus': 2,
    'linux': 2,
    'linville': 2,
    'linwick': 2,
    'linwood': 2,
    'linz': 1,
    'linzer': 2,
    'linzey': 2,
    'linzy': 2,
    'liomine': 3,
    'lion': 2,
    'lionberger': 4,
    'lionel': 3,
    'lionetti': 4,
    'lionheart': 3,
    'lionize': 3,
    'lionized': 3,
    'lions': 2,
    'liotier': 4,
    'liotta': 3,
    'liou': 2,
    'lip': 1,
    'lipa': 2,
    'lipari': 3,
    'lipe': 1,
    'lipford': 2,
    'lipham': 2,
    'lipid': 2,
    'lipide': 2,
    'lipinski': 3,
    'lipka': 2,
    'lipke': 1,
    'lipkin': 2,
    'lipkind': 2,
    'lipman': 2,
    'lipnick': 2,
    'lipoprotein': 4,
    'lipoproteins': 4,
    'liposome': 3,
    'liposomes': 3,
    'liposuction': 4,
    'lipovsky': 3,
    'lipp': 1,
    'lippa': 2,
    'lippard': 2,
    'lippe': 1,
    'lipped': 1,
    'lippens': 2,
    'lipper': 2,
    'lippert': 2,
    'lippi': 2,
    'lippincott': 3,
    'lippitt': 2,
    'lippman': 2,
    'lippmann': 2,
    'lippo': 2,
    'lippold': 2,
    'lipps': 1,
    'lippy': 2,
    'lips': 1,
    'lipschitz': 2,
    'lipschultz': 2,
    'lipschutz': 2,
    'lipscomb': 2,
    'lipsett': 2,
    'lipsey': 2,
    'lipshie': 2,
    'lipshutz': 2,
    'lipsig': 2,
    'lipsitz': 2,
    'lipski': 2,
    'lipsky': 2,
    'lipson': 2,
    'lipstein': 2,
    'lipstick': 2,
    'lipsticks': 2,
    'liptak': 2,
    'lipton': 2,
    'lipuma': 3,
    'liquefaction': 4,
    'liquefied': 3,
    'liquefy': 3,
    'liqueur': 2,
    'liqueurs': 2,
    'liquid': 2,
    'liquidate': 3,
    'liquidated': 4,
    'liquidates': 3,
    'liquidating': 4,
    'liquidation': 4,
    'liquidations': 4,
    'liquidator': 4,
    'liquidators': 4,
    'liquide': 2,
    'liquidities': 4,
    'liquidity': 4,
    'liquids': 2,
    'liqun': 2,
    'liquor': 2,
    'liquori': 3,
    'liquors': 2,
    'lira': 2,
    'lire': 2,
    'lirette': 2,
    'liro': 2,
    'liroff': 2,
    'lis': 1,
    'lisa': 2,
    'lisabet': 3,
    'lisabeth': 3,
    'lisak': 2,
    'lisanti': 3,
    'lisbeth': 2,
    'lisbon': 2,
    'lisby': 2,
    'lisco': 2,
    'liscom': 2,
    'lise': 1,
    'lisec': 2,
    'lisenbee': 3,
    'lisenby': 3,
    'lisette': 2,
    'lish': 1,
    'lishman': 2,
    'lisi': 2,
    'lisiecki': 3,
    'lisk': 1,
    'liska': 2,
    'liske': 1,
    'liskey': 2,
    'lisko': 2,
    'lisle': 2,
    'lisman': 2,
    'lisowski': 3,
    'lisp': 1,
    'liss': 1,
    'lissa': 2,
    'lissack': 2,
    'lissie': 2,
    'lissner': 2,
    'lissouba': 3,
    'lissy': 2,
    'list': 1,
    'listed': 2,
    'listen': 2,
    'listened': 2,
    'listener': 3,
    'listeners': 3,
    'listening': 3,
    'listens': 2,
    'lister': 2,
    'listeria': 4,
    'listerine': 3,
    'listerines': 3,
    'listing': 2,
    'listings': 2,
    'listless': 2,
    'listlessly': 3,
    'liston': 2,
    'lists': 1,
    'liszewski': 3,
    'liszka': 2,
    'liszt': 1,
    'lit': 1,
    'lita': 2,
    'litaker': 3,
    'litalien': 3,
    'litan': 2,
    'litanies': 3,
    'litany': 3,
    'litaro': 3,
    'litchfield': 2,
    'litchford': 2,
    'litco': 2,
    'lite': 1,
    'litem': 2,
    'liter': 2,
    'literacy': 4,
    'literal': 3,
    'literally': 4,
    'literary': 4,
    'literate': 3,
    'literati': 4,
    'literature': 4,
    'literaturnaya': 6,
    'liters': 2,
    'lites': 1,
    'lithe': 1,
    'litherland': 3,
    'lithgow': 2,
    'lithic': 2,
    'lithium': 3,
    'lithograph': 3,
    'lithographic': 4,
    'lithographs': 3,
    'lithography': 4,
    'lithotripter': 4,
    'lithuania': 5,
    'lithuanian': 5,
    'lithuanians': 5,
    'litigant': 3,
    'litigants': 3,
    'litigate': 3,
    'litigated': 4,
    'litigates': 3,
    'litigating': 4,
    'litigation': 4,
    'litigations': 4,
    'litigator': 4,
    'litigators': 4,
    'litigious': 3,
    'litke': 2,
    'litle': 2,
    'litman': 2,
    'litmus': 2,
    'lits': 1,
    'litsey': 2,
    'litt': 1,
    'litteken': 3,
    'littell': 2,
    'litten': 2,
    'litter': 2,
    'litteral': 3,
    'littered': 2,
    'littering': 3,
    'litters': 2,
    'littig': 2,
    'little': 2,
    'littleboy': 3,
    'littlechild': 3,
    'littlefield': 3,
    'littleford': 3,
    'littlejohn': 3,
    'littlepage': 3,
    'littler': 3,
    'littles': 2,
    'littlest': 3,
    'littleton': 3,
    'littlewood': 3,
    'littman': 2,
    'littmann': 2,
    'litton': 2,
    'littoral': 3,
    'littrell': 2,
    'litts': 1,
    'litty': 2,
    'liturgical': 4,
    'liturgy': 3,
    'litvack': 2,
    'litvak': 2,
    'litvin': 2,
    'litwack': 2,
    'litwak': 2,
    'litwiller': 3,
    'litwin': 2,
    'litz': 1,
    'litzenberg': 3,
    'litzenberger': 4,
    'litzinger': 3,
    'liu': 1,
    'liuzza': 3,
    'liuzzi': 3,
    'liv': 1,
    'livable': 3,
    'livan': 2,
    'live': 1,
    'lived': 1,
    'livelier': 3,
    'liveliest': 3,
    'livelihood': 3,
    'livelihoods': 3,
    'liveliness': 3,
    'lively': 2,
    'liven': 2,
    'livened': 2,
    'livengood': 3,
    'liver': 2,
    'livergood': 3,
    'liveried': 2,
    'liverman': 3,
    'livermore': 3,
    'livernois': 3,
    'liverpool': 3,
    'livers': 2,
    'liverwort': 3,
    'liverworts': 3,
    'livery': 3,
    'lives': 1,
    'livesay': 3,
    'livesey': 3,
    'livestock': 2,
    'livezey': 3,
    'livia': 3,
    'livid': 2,
    'lividity': 4,
    'living': 2,
    'livingood': 3,
    'livingroom': 3,
    'livingrooms': 3,
    'livings': 2,
    'livingston': 3,
    'livingstone': 3,
    'livingwell': 3,
    'livolsi': 3,
    'livonia': 3,
    'livor': 2,
    'livoti': 3,
    'livsey': 2,
    'livvie': 2,
    'liz': 1,
    'liza': 2,
    'lizabeth': 3,
    'lizak': 2,
    'lizard': 2,
    'lizards': 2,
    'lizarraga': 4,
    'lizhi': 2,
    'lizotte': 2,
    'lizzie': 2,
    'lizzy': 2,
    'ljubljana': 3,
    'ljubomir': 3,
    'llama': 2,
    'llamas': 2,
    'llana': 2,
    'llanas': 2,
    'llanes': 1,
    'llano': 2,
    'llanos': 2,
    'llerena': 3,
    'llewellyn': 3,
    'llewelyn': 3,
    'llorens': 3,
    'llorente': 3,
    'llosa': 2,
    'llovio': 3,
    'lloyd': 1,
    'lloyds': 1,
    'ln': 1,
    'lo': 1,
    'load': 1,
    'loaded': 2,
    'loader': 2,
    'loaders': 2,
    'loadholt': 2,
    'loading': 2,
    'loadings': 2,
    'loadman': 2,
    'loads': 1,
    'loaf': 1,
    'loafer': 2,
    'loafers': 2,
    'loafs': 1,
    'loaiza': 4,
    'loam': 1,
    'loamy': 2,
    'loan': 1,
    'loaned': 1,
    'loaning': 2,
    'loanloss': 2,
    'loans': 1,
    'loar': 1,
    'loath': 1,
    'loathe': 1,
    'loathed': 1,
    'loathing': 2,
    'loathsome': 2,
    'loaves': 1,
    'lob': 1,
    'lobato': 3,
    'lobaugh': 2,
    'lobb': 1,
    'lobban': 2,
    'lobbed': 1,
    'lobbia': 3,
    'lobbied': 2,
    'lobbies': 2,
    'lobbing': 2,
    'lobby': 2,
    'lobbying': 3,
    'lobbyist': 3,
    'lobbyists': 3,
    'lobdell': 2,
    'lobe': 1,
    'lobed': 1,
    'lobel': 2,
    'lobell': 2,
    'lobello': 3,
    'lober': 2,
    'loberg': 2,
    'lobes': 1,
    'lobianco': 4,
    'loblaw': 2,
    'loblolly': 3,
    'lobo': 2,
    'lobos': 2,
    'lobosco': 3,
    'lobotomy': 4,
    'lobs': 1,
    'lobster': 2,
    'lobsterman': 3,
    'lobstermen': 3,
    'lobsters': 2,
    'lobue': 2,
    'lobules': 2,
    'loca': 2,
    'locadia': 4,
    'local': 2,
    'locale': 2,
    'locales': 2,
    'localities': 4,
    'locality': 4,
    'localization': 5,
    'localize': 3,
    'localized': 3,
    'locally': 3,
    'locals': 2,
    'locascio': 4,
    'locastro': 3,
    'locate': 2,
    'located': 3,
    'locatelli': 4,
    'locates': 2,
    'locating': 3,
    'location': 3,
    'locations': 3,
    'locator': 3,
    'locators': 3,
    'locey': 2,
    'loch': 1,
    'locher': 2,
    'lochhead': 2,
    'lochner': 2,
    'lochridge': 2,
    'loci': 2,
    'locicero': 4,
    'lock': 1,
    'locka': 2,
    'lockaby': 3,
    'lockamy': 3,
    'lockard': 2,
    'lockart': 2,
    'lockdown': 2,
    'locke': 1,
    'locked': 1,
    'locken': 2,
    'locker': 2,
    'lockerbie': 3,
    'lockerby': 3,
    'lockerman': 3,
    'lockers': 2,
    'lockert': 2,
    'locket': 2,
    'lockets': 2,
    'lockett': 2,
    'lockey': 2,
    'lockhart': 2,
    'lockheed': 2,
    'lockie': 2,
    'locking': 2,
    'locklair': 2,
    'locklar': 2,
    'locklear': 2,
    'lockley': 2,
    'locklin': 2,
    'lockman': 2,
    'lockmiller': 3,
    'lockner': 2,
    'lockney': 2,
    'lockneys': 2,
    'lockout': 2,
    'lockouts': 2,
    'lockport': 2,
    'lockrem': 2,
    'lockridge': 2,
    'locks': 1,
    'locksmith': 2,
    'lockstep': 2,
    'lockup': 2,
    'lockwood': 2,
    'lockyer': 2,
    'loco': 2,
    'lococo': 3,
    'locomotion': 4,
    'locomotive': 4,
    'locomotives': 4,
    'locomotor': 4,
    'loconte': 3,
    'locsin': 2,
    'loctite': 2,
    'locurto': 3,
    'locus': 2,
    'locust': 2,
    'locusts': 2,
    'locy': 2,
    'lodato': 3,
    'lode': 1,
    'lodema': 3,
    'loden': 2,
    'loder': 2,
    'lodes': 1,
    'lodestar': 2,
    'lodestone': 2,
    'lodge': 1,
    'lodged': 1,
    'lodgepole': 2,
    'lodges': 2,
    'lodging': 2,
    'lodgings': 2,
    'lodi': 2,
    'lodico': 3,
    'lodley': 2,
    'lodwick': 2,
    'lodwrick': 2,
    'loe': 1,
    'loeb': 1,
    'loeber': 2,
    'loeffel': 2,
    'loeffelholz': 3,
    'loeffler': 2,
    'loehmann': 2,
    'loehr': 1,
    'loella': 3,
    'loeper': 2,
    'loepfe': 2,
    'loera': 2,
    'loesch': 1,
    'loesche': 1,
    'loescher': 2,
    'loeser': 2,
    'loess': 2,
    'loesser': 2,
    'loew': 2,
    'loewe': 2,
    'loewen': 3,
    'loewenson': 3,
    'loewenstein': 3,
    'loewenthal': 3,
    'loewi': 2,
    'loews': 1,
    'loewy': 2,
    'lofaro': 3,
    'lofaso': 3,
    'loffredo': 3,
    'lofgren': 2,
    'lofland': 2,
    'loflin': 2,
    'lofquist': 2,
    'lofstrom': 2,
    'loft': 1,
    'lofthouse': 2,
    'lofthus': 2,
    'loftier': 3,
    'loftin': 2,
    'loftis': 2,
    'lofton': 2,
    'lofts': 1,
    'loftus': 2,
    'lofty': 2,
    'log': 1,
    'logan': 2,
    'logar': 2,
    'logarithm': 4,
    'logarithmic': 4,
    'logarithms': 4,
    'logbook': 2,
    'loge': 1,
    'loges': 2,
    'logged': 1,
    'logger': 2,
    'loggerhead': 3,
    'loggerheads': 3,
    'loggers': 2,
    'loggia': 3,
    'logging': 2,
    'loggins': 2,
    'loghry': 2,
    'logic': 2,
    'logica': 3,
    'logical': 3,
    'logically': 3,
    'logician': 3,
    'logicon': 3,
    'logie': 2,
    'logistic': 3,
    'logistical': 4,
    'logistically': 4,
    'logistics': 3,
    'logiudice': 3,
    'logjam': 2,
    'logjams': 2,
    'logo': 2,
    'logogram': 3,
    'logograms': 3,
    'logographic': 4,
    'logos': 2,
    'logothetis': 4,
    'lograsso': 3,
    'logs': 1,
    'logsdon': 2,
    'logston': 2,
    'logue': 1,
    'loguidice': 3,
    'logwood': 2,
    'loh': 1,
    'lohengrin': 3,
    'lohman': 2,
    'lohmann': 2,
    'lohmeier': 3,
    'lohmeyer': 3,
    'lohn': 1,
    'lohner': 2,
    'lohnes': 1,
    'lohr': 1,
    'lohrenz': 2,
    'lohrey': 2,
    'lohrman': 2,
    'lohrmann': 2,
    'lohse': 1,
    'loiacano': 4,
    'loiacono': 4,
    'loibl': 2,
    'loin': 1,
    'loincloth': 2,
    'loire': 1,
    'lois': 2,
    'loise': 2,
    'loiseau': 3,
    'loisel': 2,
    'loiselle': 2,
    'loiter': 2,
    'loitering': 3,
    'lok': 1,
    'loken': 2,
    'loker': 2,
    'lokey': 2,
    'lokken': 2,
    'lola': 2,
    'loleta': 3,
    'lolita': 3,
    'loll': 1,
    'lollapalooza': 5,
    'lollapaloozas': 5,
    'lollar': 2,
    'lollard': 2,
    'lolley': 2,
    'lolli': 2,
    'lolling': 2,
    'lollipop': 3,
    'lollipops': 3,
    'lollis': 2,
    'lolly': 2,
    'loma': 2,
    'lomak': 2,
    'loman': 2,
    'lomas': 2,
    'lomasney': 3,
    'lomax': 2,
    'lomb': 1,
    'lomba': 2,
    'lombard': 2,
    'lombardi': 3,
    'lombardo': 3,
    'lombards': 2,
    'lomeli': 3,
    'lommen': 2,
    'lomonaco': 4,
    'lompoc': 2,
    'lon': 1,
    'lona': 2,
    'lonardo': 3,
    'lonas': 2,
    'loncar': 2,
    'londo': 2,
    'london': 2,
    'londonderry': 4,
    'londoner': 3,
    'londoners': 3,
    'londono': 3,
    'lone': 1,
    'loneliness': 3,
    'lonely': 2,
    'loner': 2,
    'lonergan': 3,
    'lonero': 3,
    'loners': 2,
    'lones': 1,
    'lonesome': 2,
    'lonetree': 2,
    'loney': 2,
    'long': 1,
    'longacre': 3,
    'longaker': 3,
    'longan': 2,
    'longanecker': 4,
    'longbow': 2,
    'longbrake': 2,
    'longcor': 2,
    'longden': 2,
    'longe': 1,
    'longed': 1,
    'longenecker': 3,
    'longer': 2,
    'longerbeam': 3,
    'longest': 2,
    'longevity': 4,
    'longfellow': 3,
    'longfield': 2,
    'longhair': 2,
    'longhand': 2,
    'longhi': 2,
    'longhorn': 2,
    'longhorns': 2,
    'longhouse': 2,
    'longhurst': 2,
    'longing': 2,
    'longingly': 3,
    'longings': 2,
    'longino': 3,
    'longish': 2,
    'longitude': 3,
    'longitudes': 3,
    'longitudinal': 5,
    'longleaf': 2,
    'longleg': 2,
    'longlegs': 2,
    'longley': 2,
    'longman': 2,
    'longmeyer': 3,
    'longmire': 2,
    'longmont': 2,
    'longmore': 2,
    'longnecker': 3,
    'longo': 2,
    'longobardi': 4,
    'longoria': 4,
    'longpre': 2,
    'longrange': 2,
    'longs': 1,
    'longshore': 2,
    'longshoremen': 3,
    'longshot': 2,
    'longspur': 2,
    'longspurs': 2,
    'longstaff': 2,
    'longstanding': 3,
    'longstreet': 2,
    'longstreth': 2,
    'longsworth': 2,
    'longterm': 2,
    'longtime': 2,
    'longtin': 2,
    'longton': 2,
    'longuet': 2,
    'longview': 2,
    'longwell': 2,
    'longwood': 2,
    'longworth': 2,
    'loni': 2,
    'lonigro': 3,
    'lonnie': 2,
    'lonny': 2,
    'lonon': 2,
    'lonrho': 2,
    'lons': 1,
    'lonsdale': 2,
    'lonski': 2,
    'lonsky': 2,
    'lonsway': 2,
    'loo': 1,
    'looart': 2,
    'looby': 2,
    'look': 1,
    'lookabaugh': 3,
    'lookabill': 3,
    'lookalike': 3,
    'looked': 1,
    'looker': 2,
    'lookers': 2,
    'looking': 2,
    'lookingbill': 3,
    'lookit': 2,
    'lookout': 2,
    'looks': 1,
    'lookup': 2,
    'lookups': 2,
    'loom': 1,
    'looman': 2,
    'loomed': 1,
    'loomer': 2,
    'looming': 2,
    'loomis': 2,
    'looms': 1,
    'loon': 1,
    'looney': 2,
    'loong': 1,
    'loons': 1,
    'loony': 2,
    'loop': 1,
    'looped': 1,
    'looper': 2,
    'loopers': 2,
    'loophole': 2,
    'loopholes': 2,
    'looping': 2,
    'loops': 1,
    'loopy': 2,
    'loos': 1,
    'loose': 1,
    'loosed': 1,
    'loosely': 2,
    'loosen': 2,
    'loosened': 2,
    'loosening': 3,
    'loosens': 2,
    'looser': 2,
    'looses': 2,
    'loosestrife': 2,
    'loosestrifes': 2,
    'loosing': 2,
    'loot': 1,
    'looted': 2,
    'lootens': 2,
    'looter': 2,
    'looters': 2,
    'looting': 2,
    'lop': 1,
    'lopardo': 3,
    'lopata': 3,
    'lopatin': 3,
    'lope': 1,
    'lopeman': 2,
    'loper': 2,
    'lopes': 1,
    'lopez': 2,
    'lopiccolo': 4,
    'lopid': 2,
    'lopinto': 3,
    'lopp': 1,
    'lopped': 1,
    'loppers': 2,
    'lopping': 2,
    'loppnow': 2,
    'lopresti': 3,
    'lopresto': 3,
    'loprete': 2,
    'lopsided': 3,
    'loquacious': 3,
    'loquat': 2,
    'lor': 1,
    'lora': 2,
    'lorah': 2,
    'lorain': 2,
    'loraine': 2,
    'loral': 2,
    'loran': 2,
    'lorance': 2,
    'lorang': 2,
    'loranger': 3,
    'lorber': 2,
    'lorch': 1,
    'lord': 1,
    'lorden': 2,
    'lordi': 2,
    'lords': 1,
    'lordstown': 2,
    'lordy': 2,
    'lore': 1,
    'lorean': 3,
    'loredo': 3,
    'loree': 2,
    'lorei': 2,
    'lorek': 2,
    'lorelei': 3,
    'lorelli': 3,
    'lorello': 3,
    'loren': 2,
    'lorena': 3,
    'lorenc': 2,
    'lorence': 2,
    'lorene': 2,
    'lorensen': 3,
    'lorenson': 3,
    'lorentz': 2,
    'lorentzen': 3,
    'lorenz': 2,
    'lorenza': 3,
    'lorenzana': 4,
    'lorenzen': 3,
    'lorenzetti': 4,
    'lorenzi': 3,
    'lorenzini': 4,
    'lorenzo': 3,
    'loreto': 3,
    'loretta': 3,
    'lorette': 2,
    'loretto': 3,
    'lorey': 2,
    'lorge': 1,
    'lorgnette': 2,
    'lorgnettes': 2,
    'lori': 2,
    'loria': 3,
    'lorick': 2,
    'lorie': 2,
    'lorillard': 3,
    'lorimar': 3,
    'lorimer': 3,
    'lorimor': 3,
    'lorin': 2,
    'lorincz': 2,
    'loring': 2,
    'lorino': 3,
    'lorio': 3,
    'loris': 2,
    'lorita': 3,
    'lorman': 2,
    'lormand': 2,
    'lorna': 2,
    'lorne': 1,
    'loro': 2,
    'lorraine': 2,
    'lorrie': 2,
    'lorries': 2,
    'lorry': 2,
    'lorson': 2,
    'lortel': 2,
    'lortie': 2,
    'lorton': 2,
    'lortz': 1,
    'lorusso': 3,
    'lory': 2,
    'los': 1,
    'losada': 3,
    'losasso': 3,
    'loscalzo': 3,
    'losch': 1,
    'loschiavo': 4,
    'lose': 1,
    'losec': 2,
    'losee': 2,
    'loseke': 2,
    'loser': 2,
    'losers': 2,
    'loses': 2,
    'losey': 2,
    'losh': 1,
    'losing': 2,
    'losinski': 3,
    'losito': 3,
    'loso': 2,
    'losoya': 3,
    'loss': 1,
    'losses': 2,
    'lossing': 2,
    'lost': 1,
    'losurdo': 3,
    'losure': 2,
    'lot': 1,
    'loth': 1,
    'lothian': 3,
    'lothrop': 2,
    'lothson': 2,
    'lotion': 2,
    'lotions': 2,
    'lotito': 3,
    'loton': 2,
    'lots': 1,
    'lotspeich': 2,
    'lott': 1,
    'lotta': 2,
    'lotte': 1,
    'lotter': 2,
    'lotteries': 3,
    'lottery': 3,
    'lottes': 1,
    'lottie': 2,
    'lottman': 2,
    'lotto': 2,
    'lotts': 1,
    'lotty': 2,
    'lotus': 2,
    'lotz': 1,
    'lotze': 1,
    'lou': 1,
    'louanna': 3,
    'louanne': 2,
    'louart': 2,
    'louck': 1,
    'loucks': 1,
    'loud': 1,
    'louden': 2,
    'loudenslager': 4,
    'louder': 2,
    'louderback': 3,
    'loudermilk': 3,
    'loudest': 2,
    'loudin': 2,
    'loudly': 2,
    'loudon': 2,
    'loudspeaker': 3,
    'loudspeakers': 3,
    'louella': 3,
    'louganis': 3,
    'louganos': 3,
    'lougee': 2,
    'lough': 1,
    'lougheed': 2,
    'loughery': 3,
    'loughlin': 2,
    'loughman': 2,
    'loughmiller': 3,
    'loughner': 2,
    'loughney': 2,
    'loughran': 2,
    'loughrey': 2,
    'loughridge': 2,
    'loughry': 2,
    'louie': 2,
    'louima': 3,
    'louis': 2,
    'louisa': 3,
    'louisan': 3,
    'louise': 2,
    'louisette': 2,
    'louisiana': 5,
    'louisianian': 6,
    'louisianians': 6,
    'louisville': 3,
    'louk': 1,
    'louks': 1,
    'lounge': 1,
    'lounges': 2,
    'lounging': 2,
    'lounsberry': 3,
    'loup': 1,
    'loupe': 1,
    'louque': 1,
    'lour': 1,
    'lourdes': 1,
    'loureiro': 3,
    'lourenco': 3,
    'lourie': 2,
    'loury': 2,
    'louse': 1,
    'lousiness': 3,
    'lousy': 2,
    'lout': 1,
    'louth': 1,
    'louthan': 2,
    'louts': 1,
    'loutzenhiser': 4,
    'louvier': 3,
    'louviere': 3,
    'louvre': 2,
    'louw': 1,
    'loux': 1,
    'lovaas': 2,
    'lovable': 3,
    'lovage': 2,
    'lovallo': 3,
    'lovan': 2,
    'lovas': 2,
    'lovastatin': 4,
    'lovato': 3,
    'love': 1,
    'loveall': 2,
    'lovecchio': 4,
    'loved': 1,
    'loveday': 2,
    'lovegrove': 2,
    'lovejoy': 2,
    'lovelace': 2,
    'lovelan': 2,
    'loveland': 2,
    'loveless': 2,
    'lovell': 2,
    'lovely': 2,
    'lovemaking': 3,
    'loven': 2,
    'lover': 2,
    'loverde': 3,
    'loveridge': 2,
    'lovering': 3,
    'lovern': 2,
    'lovers': 2,
    'loves': 1,
    'lovett': 2,
    'lovette': 2,
    'lovewell': 2,
    'lovgren': 2,
    'lovick': 2,
    'lovie': 2,
    'loville': 2,
    'lovin': 2,
    'loving': 2,
    'lovinger': 3,
    'lovingly': 3,
    'lovingood': 3,
    'lovings': 2,
    'lovins': 2,
    'lovitt': 2,
    'lovitz': 2,
    'lovler': 2,
    'lovvorn': 2,
    'low': 1,
    'lowborn': 2,
    'lowden': 2,
    'lowder': 2,
    'lowdermilk': 3,
    'lowdown': 2,
    'lowe': 1,
    'lowekamp': 2,
    'lowell': 2,
    'lowen': 2,
    'lowenberg': 3,
    'lowensky': 3,
    'lowenstein': 3,
    'lowenthal': 3,
    'lower': 2,
    'lowered': 2,
    'lowering': 3,
    'lowers': 2,
    'lowery': 3,
    'lowes': 1,
    'lowest': 2,
    'lowey': 2,
    'lowing': 2,
    'lowinsky': 3,
    'lowland': 2,
    'lowlands': 2,
    'lowly': 2,
    'lowman': 2,
    'lown': 1,
    'lowndes': 2,
    'lowney': 2,
    'lowrance': 2,
    'lowrey': 2,
    'lowrie': 2,
    'lowrimore': 3,
    'lowry': 2,
    'lows': 1,
    'lowther': 2,
    'lowy': 2,
    'lox': 1,
    'loy': 1,
    'loya': 2,
    'loyal': 2,
    'loyalist': 3,
    'loyalists': 3,
    'loyall': 2,
    'loyally': 3,
    'loyalties': 3,
    'loyalton': 3,
    'loyalty': 3,
    'loyce': 1,
    'loyd': 1,
    'loye': 1,
    'loyer': 2,
    'loynd': 1,
    'loyola': 3,
    'loza': 2,
    'lozad': 2,
    'lozada': 3,
    'lozano': 3,
    'lozeau': 2,
    'lozenge': 2,
    'lozenges': 3,
    'lozier': 3,
    'lozinski': 3,
    'lozito': 3,
    'lozon': 2,
    'lozoya': 3,
    'lp': 2,
    'lpn': 3,
    'ls': 2,
    'lsd': 3,
    'ltd': 3,
    'lu': 1,
    'lua': 2,
    'luaka': 3,
    'luallen': 3,
    'luana': 3,
    'luanda': 3,
    'luane': 2,
    'luau': 2,
    'luba': 2,
    'lubar': 2,
    'lubarsky': 3,
    'lubas': 2,
    'lubavitch': 3,
    'lubavitcher': 4,
    'lubavitchers': 4,
    'lubben': 2,
    'lubber': 2,
    'lubbers': 2,
    'lubbock': 2,
    'lube': 1,
    'lubeck': 2,
    'lubell': 2,
    'lubensky': 3,
    'luber': 2,
    'lubicons': 3,
    'lubin': 2,
    'lubinov': 3,
    'lubinski': 3,
    'lubinsky': 3,
    'lubitz': 2,
    'lubke': 1,
    'lublin': 2,
    'lubow': 2,
    'lubowski': 3,
    'lubrano': 3,
    'lubricant': 3,
    'lubricants': 3,
    'lubricate': 3,
    'lubricated': 4,
    'lubricating': 4,
    'lubrication': 4,
    'lubrizol': 3,
    'luby': 2,
    'luc': 1,
    'luca': 2,
    'lucado': 3,
    'lucarelli': 4,
    'lucas': 2,
    'lucasfilm': 3,
    'lucasville': 3,
    'lucca': 2,
    'lucchese': 3,
    'lucchesi': 3,
    'lucchetti': 3,
    'lucci': 2,
    'lucco': 2,
    'luce': 1,
    'lucent': 2,
    'lucente': 3,
    'lucerne': 2,
    'lucero': 3,
    'lucette': 2,
    'lucey': 2,
    'luchini': 3,
    'luchsinger': 3,
    'lucht': 1,
    'lucia': 2,
    'lucian': 2,
    'luciani': 3,
    'lucianna': 3,
    'lucianne': 3,
    'luciano': 4,
    'lucich': 2,
    'lucid': 2,
    'lucida': 3,
    'lucidity': 4,
    'lucido': 3,
    'lucie': 2,
    'lucien': 3,
    'lucienne': 3,
    'lucier': 3,
    'lucifer': 3,
    'lucile': 2,
    'lucille': 2,
    'lucinda': 3,
    'lucio': 3,
    'lucite': 2,
    'lucius': 2,
    'luciw': 2,
    'luck': 1,
    'lucke': 1,
    'lucked': 1,
    'lucken': 2,
    'luckenbach': 3,
    'luckenbaugh': 3,
    'luckenbill': 3,
    'lucker': 2,
    'luckett': 2,
    'luckey': 2,
    'luckhardt': 2,
    'luckie': 2,
    'luckier': 3,
    'luckiest': 3,
    'luckily': 3,
    'lucking': 2,
    'luckless': 2,
    'luckman': 2,
    'luckow': 2,
    'lucks': 1,
    'lucky': 2,
    'luckyn': 2,
    'lucodendrum': 4,
    'lucrative': 3,
    'lucrecia': 3,
    'lucretia': 3,
    'lucus': 2,
    'lucy': 2,
    'luczak': 2,
    'ludcke': 2,
    'ludden': 2,
    'luddite': 2,
    'luddites': 2,
    'luddy': 2,
    'ludeke': 2,
    'ludella': 3,
    'ludeman': 2,
    'ludemann': 2,
    'ludewig': 3,
    'ludicrous': 3,
    'ludicrously': 4,
    'ludington': 3,
    'ludite': 2,
    'ludites': 2,
    'ludke': 2,
    'ludlam': 2,
    'ludlow': 2,
    'ludlum': 2,
    'ludmer': 2,
    'ludmila': 3,
    'ludmilla': 3,
    'ludolph': 2,
    'ludtke': 2,
    'ludvigsen': 3,
    'ludvigson': 3,
    'ludvik': 2,
    'ludwick': 2,
    'ludwig': 2,
    'ludwiga': 3,
    'ludwigshafen': 4,
    'ludwin': 2,
    'ludy': 2,
    'lue': 1,
    'luebbe': 1,
    'luebbers': 2,
    'luebbert': 2,
    'luebke': 1,
    'lueck': 2,
    'luecke': 1,
    'luedecke': 2,
    'lueder': 2,
    'lueders': 2,
    'luedke': 2,
    'luedtke': 2,
    'lueh': 1,
    'luehring': 2,
    'luehrs': 1,
    'lueken': 2,
    'luella': 3,
    'luelle': 1,
    'luellen': 2,
    'luepke': 2,
    'luera': 3,
    'lueras': 2,
    'luers': 2,
    'luerssen': 2,
    'lueth': 1,
    'luetkemeyer': 4,
    'luevano': 4,
    'luff': 1,
    'luffed': 1,
    'luffman': 2,
    'lufkin': 2,
    'luft': 1,
    'lufthansa': 3,
    'luftig': 2,
    'luftkin': 2,
    'lufttransport': 3,
    'lug': 1,
    'lugano': 3,
    'lugar': 2,
    'lugarda': 3,
    'luge': 1,
    'luger': 2,
    'lugers': 2,
    'luggage': 2,
    'lugging': 2,
    'lugi': 2,
    'luginbill': 3,
    'luginbuhl': 3,
    'lugkov': 2,
    'lugo': 2,
    'lugosi': 3,
    'lugs': 1,
    'luguarda': 3,
    'lugubrious': 4,
    'lugworm': 2,
    'lugworms': 2,
    'luhman': 2,
    'luhmann': 2,
    'luhn': 1,
    'luhr': 1,
    'luhring': 2,
    'luhrs': 1,
    'lui': 2,
    'luick': 1,
    'luigi': 3,
    'luikart': 2,
    'luis': 2,
    'luisa': 3,
    'luisi': 2,
    'luiz': 1,
    'lujan': 2,
    'luk': 1,
    'luka': 2,
    'lukach': 2,
    'lukacs': 2,
    'lukas': 2,
    'lukash': 2,
    'lukasiewicz': 4,
    'lukasik': 3,
    'lukaszewski': 4,
    'lukavica': 4,
    'lukavizta': 4,
    'luke': 1,
    'lukehart': 2,
    'luken': 2,
    'lukenbill': 3,
    'lukens': 2,
    'luker': 2,
    'lukes': 1,
    'lukewarm': 2,
    'lukin': 2,
    'lukins': 2,
    'lukman': 2,
    'lukoil': 2,
    'lukowski': 3,
    'luks': 1,
    'lukyanov': 3,
    'lula': 2,
    'lulie': 2,
    'lull': 1,
    'lullabies': 3,
    'lullaby': 3,
    'lulled': 1,
    'lulling': 2,
    'lulls': 1,
    'lulu': 2,
    'lulue': 2,
    'lum': 1,
    'luma': 2,
    'luman': 2,
    'lumb': 1,
    'lumbago': 3,
    'lumbar': 2,
    'lumbard': 2,
    'lumber': 2,
    'lumbering': 3,
    'lumberjack': 3,
    'lumberman': 3,
    'lumbert': 2,
    'lumberton': 3,
    'lumberyard': 3,
    'lumberyards': 3,
    'lumbra': 2,
    'lumen': 2,
    'lumet': 2,
    'lumex': 2,
    'lumia': 3,
    'lumina': 3,
    'luminal': 3,
    'luminance': 3,
    'luminaries': 4,
    'luminary': 4,
    'luminescence': 4,
    'luminescent': 4,
    'luminol': 3,
    'luminoso': 4,
    'luminous': 3,
    'lumley': 2,
    'lumm': 1,
    'lummus': 2,
    'lumonics': 3,
    'lump': 1,
    'lumpectomies': 4,
    'lumpectomy': 4,
    'lumped': 1,
    'lumper': 2,
    'lumping': 2,
    'lumpkin': 2,
    'lumpkins': 2,
    'lumpp': 1,
    'lumps': 1,
    'lumpur': 2,
    'lumpy': 2,
    'lumsden': 2,
    'lun': 1,
    'luna': 2,
    'lunacy': 3,
    'lunar': 2,
    'lunati': 3,
    'lunatic': 3,
    'lunatics': 3,
    'lunberg': 2,
    'lunceford': 3,
    'lunch': 1,
    'lunched': 1,
    'luncheon': 2,
    'luncheonette': 3,
    'luncheonettes': 3,
    'luncheons': 2,
    'lunches': 2,
    'lunching': 2,
    'lunchroom': 2,
    'lunchrooms': 2,
    'lunchtime': 2,
    'lund': 1,
    'lundahl': 2,
    'lunday': 2,
    'lundberg': 2,
    'lundblad': 2,
    'lundborg': 2,
    'lunde': 1,
    'lundeen': 2,
    'lundell': 2,
    'lunden': 2,
    'lunder': 2,
    'lundgren': 2,
    'lundholm': 2,
    'lundin': 2,
    'lundmark': 2,
    'lundquist': 2,
    'lundstedt': 2,
    'lundsten': 2,
    'lundstrom': 2,
    'lundt': 1,
    'lundy': 2,
    'lunenberg': 3,
    'lunetta': 3,
    'lung': 1,
    'lunge': 1,
    'lunged': 1,
    'lunger': 2,
    'lunges': 2,
    'lungfish': 2,
    'lunging': 2,
    'lungren': 2,
    'lungs': 1,
    'lunn': 1,
    'lunney': 2,
    'lunny': 2,
    'lunsford': 2,
    'lunt': 1,
    'luntz': 1,
    'lunz': 1,
    'luo': 1,
    'luoma': 3,
    'luong': 2,
    'luongo': 3,
    'lupa': 2,
    'lupatkin': 3,
    'lupe': 1,
    'luper': 2,
    'lupfer': 2,
    'lupi': 2,
    'lupica': 3,
    'lupien': 2,
    'lupin': 2,
    'lupinacci': 4,
    'lupine': 2,
    'lupinski': 3,
    'lupita': 3,
    'luplow': 2,
    'lupo': 2,
    'lupone': 2,
    'luppino': 3,
    'luptak': 2,
    'lupton': 2,
    'lupus': 2,
    'luque': 1,
    'luquette': 2,
    'lura': 2,
    'lurch': 1,
    'lurched': 1,
    'lurches': 2,
    'lurching': 2,
    'lure': 1,
    'lured': 1,
    'lures': 1,
    'lurette': 2,
    'lurgi': 2,
    'luria': 3,
    'lurid': 2,
    'lurie': 2,
    'luring': 2,
    'lurk': 1,
    'lurked': 1,
    'lurking': 2,
    'lurks': 1,
    'lurleen': 2,
    'lurlene': 2,
    'lurline': 2,
    'lurvey': 2,
    'lurz': 1,
    'lusaka': 3,
    'lusardi': 3,
    'lusby': 2,
    'lusch': 1,
    'luscher': 2,
    'luscious': 2,
    'luscombe': 3,
    'luse': 1,
    'lush': 1,
    'lusher': 2,
    'lushlife': 2,
    'lushly': 2,
    'lusignan': 3,
    'lusinchi': 3,
    'lusitania': 5,
    'lusitanias': 5,
    'lusk': 1,
    'luskin': 2,
    'lusky': 2,
    'lusser': 2,
    'lussier': 3,
    'lust': 1,
    'lusted': 2,
    'luster': 2,
    'lustful': 2,
    'lustgarten': 3,
    'lustig': 2,
    'lusting': 2,
    'lustre': 2,
    'lustrous': 2,
    'lusty': 2,
    'lute': 1,
    'lutece': 2,
    'lutecia': 3,
    'luten': 2,
    'luter': 2,
    'lutes': 1,
    'lutey': 2,
    'lutfalla': 3,
    'lutgen': 2,
    'luth': 1,
    'luther': 2,
    'lutheran': 3,
    'lutherans': 3,
    'luthi': 2,
    'luthy': 2,
    'lutman': 2,
    'luton': 2,
    'lutsky': 2,
    'lutter': 2,
    'lutterman': 3,
    'luttman': 2,
    'lutton': 2,
    'luttrell': 2,
    'luttrull': 2,
    'luttwak': 2,
    'lutweiler': 3,
    'luty': 2,
    'lutz': 1,
    'lutze': 1,
    'lutzke': 2,
    'luu': 2,
    'luvena': 3,
    'luvs': 1,
    'luwana': 3,
    'lux': 1,
    'luxembourg': 3,
    'luxor': 2,
    'luxottica': 4,
    'luxton': 2,
    'luxuriant': 4,
    'luxuries': 3,
    'luxurious': 4,
    'luxury': 3,
    'luyster': 2,
    'luz': 1,
    'luzader': 3,
    'luzar': 2,
    'luzhkov': 2,
    'luzier': 3,
    'luzon': 2,
    'luzzi': 2,
    'lwin': 1,
    'lxi': 1,
    'ly': 1,
    'lyall': 2,
    'lyanne': 2,
    'lybarger': 3,
    'lybeck': 2,
    'lybrand': 2,
    'lycan': 2,
    'lycoming': 3,
    'lycos': 2,
    'lycra': 2,
    'lycurgus': 3,
    'lyda': 2,
    'lydall': 2,
    'lyday': 2,
    'lyde': 1,
    'lyden': 2,
    'lydia': 3,
    'lydic': 2,
    'lydick': 2,
    'lydie': 2,
    'lydon': 2,
    'lye': 1,
    'lyell': 2,
    'lyerla': 3,
    'lyerly': 3,
    'lyford': 2,
    'lying': 2,
    'lyke': 1,
    'lykens': 2,
    'lykes': 1,
    'lykin': 2,
    'lykins': 2,
    'lyle': 1,
    'lyles': 1,
    'lyman': 2,
    'lyme': 1,
    'lymph': 1,
    'lymphadenopathy': 6,
    'lymphatic': 3,
    'lymphoblast': 3,
    'lymphoblastic': 4,
    'lymphocyte': 3,
    'lymphocytes': 3,
    'lymphoma': 3,
    'lymphomas': 3,
    'lyn': 1,
    'lynam': 2,
    'lynch': 1,
    'lynchburg': 2,
    'lynched': 1,
    'lynching': 2,
    'lynchings': 2,
    'lynd': 1,
    'lynda': 2,
    'lynde': 1,
    'lynden': 2,
    'lyndhurst': 2,
    'lyndon': 2,
    'lynds': 1,
    'lyne': 1,
    'lynes': 1,
    'lyness': 2,
    'lynette': 2,
    'lynford': 2,
    'lyng': 1,
    'lynk': 1,
    'lynn': 1,
    'lynna': 2,
    'lynne': 1,
    'lynott': 2,
    'lynskey': 2,
    'lynton': 2,
    'lynx': 1,
    'lynxes': 2,
    'lyon': 2,
    'lyondell': 3,
    'lyonnais': 3,
    'lyonnaise': 3,
    'lyons': 2,
    'lyphomed': 3,
    'lyra': 2,
    'lyre': 1,
    'lyric': 2,
    'lyrical': 3,
    'lyrically': 3,
    'lyricism': 4,
    'lyricist': 3,
    'lyricists': 3,
    'lyrics': 2,
    'lyris': 2,
    'lyrist': 2,
    'lysaght': 2,
    'lysander': 3,
    'lysandra': 3,
    'lysine': 2,
    'lysis': 2,
    'lysistrata': 4,
    'lyski': 2,
    'lysne': 1,
    'lysol': 2,
    'lyssy': 2,
    'lyster': 2,
    'lyter': 2,
    'lythgoe': 2,
    'lytle': 2,
    'lyttle': 2,
    'lytton': 2,
    'lyubimov': 3,
    'lyvers': 2,
    'm': 1,
    'ma': 1,
    'maack': 1,
    'maaco': 2,
    'maag': 1,
    'maahs': 1,
    'maalox': 2,
    'maam': 1,
    'maas': 1,
    'maass': 1,
    'maassen': 2,
    'maastricht': 2,
    'mab': 1,
    'mabe': 1,
    'mabee': 2,
    'mabel': 2,
    'mabelle': 2,
    'maben': 2,
    'maberry': 3,
    'mabery': 3,
    'mabey': 2,
    'mabie': 2,
    'mabile': 2,
    'mabin': 2,
    'mabis': 2,
    'mable': 2,
    'mabley': 2,
    'mabon': 2,
    'mabrey': 2,
    'mabry': 3,
    'mabus': 2,
    'mac': 1,
    'macabre': 3,
    'macadam': 3,
    'macadamia': 5,
    'macallister': 4,
    'macalpine': 3,
    'macaluso': 4,
    'macandrews': 3,
    'macao': 2,
    'macaques': 2,
    'macarena': 4,
    'macari': 3,
    'macaroni': 4,
    'macarthur': 3,
    'macartney': 3,
    'macau': 2,
    'macaulay': 3,
    'macauley': 3,
    'macaw': 2,
    'macaws': 2,
    'macbeth': 2,
    'macbride': 2,
    'maccabean': 4,
    'maccabee': 3,
    'maccabees': 3,
    'maccallum': 3,
    'maccaquano': 4,
    'maccarone': 3,
    'maccarthy': 3,
    'macchi': 2,
    'macchia': 3,
    'macchio': 3,
    'macconnell': 3,
    'maccormack': 3,
    'macdermott': 3,
    'macdiarmid': 3,
    'macdill': 2,
    'macdonald': 3,
    'macdonell': 3,
    'macdonnell': 3,
    'macdonough': 3,
    'macdougal': 3,
    'macdougall': 3,
    'macdowell': 3,
    'macduff': 2,
    'mace': 1,
    'maceachern': 3,
    'maceda': 3,
    'macedo': 3,
    'macedonia': 5,
    'macedonian': 4,
    'macek': 2,
    'macera': 3,
    'macewan': 3,
    'macewen': 3,
    'macey': 2,
    'macfadden': 3,
    'macfadyen': 4,
    'macfarland': 3,
    'macfarlane': 3,
    'macgowan': 3,
    'macgraw': 2,
    'macgregor': 3,
    'macgyver': 3,
    'mach': 1,
    'macha': 2,
    'machacek': 3,
    'machado': 3,
    'machain': 2,
    'machala': 3,
    'machamer': 3,
    'mache': 1,
    'machel': 2,
    'machen': 2,
    'macher': 2,
    'macheski': 3,
    'machete': 3,
    'machetes': 3,
    'machi': 2,
    'machiavelli': 5,
    'machiavellian': 6,
    'machida': 3,
    'machin': 2,
    'machination': 4,
    'machinations': 4,
    'machine': 2,
    'machinea': 4,
    'machined': 2,
    'machinegun': 3,
    'machineguns': 3,
    'machineries': 4,
    'machinery': 4,
    'machines': 2,
    'machining': 3,
    'machinist': 3,
    'machinists': 3,
    'machismo': 3,
    'machivelli': 4,
    'machivellian': 5,
    'machnik': 2,
    'macho': 2,
    'machold': 2,
    'machowski': 3,
    'macht': 1,
    'machtley': 2,
    'machuca': 3,
    'machuga': 3,
    'maciag': 2,
    'macias': 3,
    'maciejewski': 3,
    'maciel': 2,
    'macik': 2,
    'macinnes': 3,
    'macinnis': 3,
    'macintax': 3,
    'macintosh': 3,
    'macintoshes': 4,
    'macintyre': 3,
    'macioce': 3,
    'maciolek': 4,
    'macisaac': 3,
    'maciver': 3,
    'mack': 1,
    'mackall': 2,
    'mackay': 2,
    'macke': 1,
    'mackel': 2,
    'mackellar': 3,
    'macken': 2,
    'mackenzie': 3,
    'mackerel': 3,
    'mackert': 2,
    'mackey': 2,
    'mackie': 2,
    'mackiewicz': 4,
    'mackin': 2,
    'mackinaw': 3,
    'mackinley': 3,
    'mackinney': 3,
    'mackinnon': 3,
    'mackintosh': 3,
    'macklem': 2,
    'mackler': 2,
    'mackley': 2,
    'macklin': 2,
    'macknay': 2,
    'macknight': 2,
    'macko': 2,
    'mackowiak': 4,
    'mackowski': 3,
    'macks': 1,
    'macktal': 2,
    'maclachlan': 3,
    'maclaine': 2,
    'maclaren': 3,
    'maclaughlin': 3,
    'maclay': 2,
    'maclean': 2,
    'macleish': 2,
    'maclellan': 3,
    'maclennan': 3,
    'macleod': 2,
    'maclin': 2,
    'macmahon': 2,
    'macmaster': 3,
    'macmillan': 3,
    'macmullen': 3,
    'macmurray': 3,
    'macnab': 2,
    'macnair': 2,
    'macnamara': 4,
    'macnaughton': 3,
    'macneal': 2,
    'macneil': 2,
    'macneill': 2,
    'macomb': 2,
    'macomber': 3,
    'macon': 2,
    'macoute': 2,
    'macoutes': 2,
    'macphail': 2,
    'macphee': 2,
    'macpherson': 3,
    'macquarrie': 3,
    'macqueen': 2,
    'macrae': 2,
    'macrame': 3,
    'macri': 2,
    'macro': 2,
    'macrodantin': 4,
    'macroeconomic': 6,
    'macroeconomics': 6,
    'macromedia': 5,
    'macrophage': 3,
    'macrophages': 4,
    'macrovision': 4,
    'macs': 1,
    'macsharry': 3,
    'mactaggart': 3,
    'mactan': 2,
    'mactavish': 3,
    'macumber': 3,
    'macumolo': 4,
    'macvicar': 3,
    'macvie': 2,
    'macwilliams': 3,
    'macworld': 2,
    'macy': 2,
    'macys': 2,
    'maczko': 2,
    'mad': 1,
    'mada': 2,
    'madagascar': 4,
    'madalena': 4,
    'madam': 2,
    'madame': 2,
    'madan': 2,
    'madar': 2,
    'madara': 3,
    'madaras': 3,
    'madaris': 3,
    'maday': 2,
    'madcap': 2,
    'madd': 1,
    'maddalena': 4,
    'maddamma': 3,
    'madden': 2,
    'maddening': 3,
    'maddeningly': 4,
    'madder': 2,
    'maddie': 2,
    'madding': 2,
    'maddison': 3,
    'maddock': 2,
    'maddocks': 2,
    'maddox': 2,
    'maddux': 2,
    'maddy': 2,
    'made': 1,
    'madeira': 3,
    'madej': 2,
    'madel': 2,
    'madelaine': 3,
    'madeleine': 3,
    'madelena': 4,
    'madelene': 3,
    'madelin': 3,
    'madeline': 3,
    'madella': 3,
    'madelle': 2,
    'madelon': 3,
    'madelyn': 3,
    'mademoiselle': 4,
    'maden': 2,
    'mader': 2,
    'madera': 3,
    'madere': 2,
    'madero': 3,
    'madewell': 3,
    'madey': 2,
    'madge': 1,
    'madhouse': 2,
    'madhusudan': 4,
    'madia': 3,
    'madigan': 3,
    'madill': 2,
    'madis': 2,
    'madison': 3,
    'madkins': 2,
    'madl': 2,
    'madlen': 3,
    'madlin': 2,
    'madlock': 2,
    'madly': 2,
    'madman': 2,
    'madmen': 2,
    'madness': 2,
    'madoc': 2,
    'madock': 2,
    'madoff': 2,
    'madog': 2,
    'madole': 2,
    'madonia': 4,
    'madonna': 3,
    'madora': 3,
    'madore': 3,
    'madra': 2,
    'madras': 2,
    'madre': 2,
    'madres': 2,
    'madrid': 2,
    'madrids': 2,
    'madrigal': 3,
    'madrigals': 3,
    'madril': 2,
    'madron': 2,
    'madruga': 3,
    'madry': 2,
    'madsen': 2,
    'madson': 2,
    'madstones': 2,
    'mady': 2,
    'madyun': 3,
    'mae': 1,
    'maeda': 3,
    'maeder': 2,
    'maekawa': 4,
    'maelstrom': 2,
    'maenza': 3,
    'maersk': 1,
    'maertens': 2,
    'maertz': 1,
    'maerz': 1,
    'maes': 1,
    'maese': 1,
    'maestas': 2,
    'maestra': 2,
    'maestri': 3,
    'maestro': 2,
    'maeve': 2,
    'maez': 2,
    'maffei': 2,
    'maffeo': 3,
    'maffett': 2,
    'maffia': 3,
    'maffucci': 3,
    'mafia': 3,
    'mafias': 3,
    'mafikeng': 3,
    'mag': 1,
    'magadan': 3,
    'magaji': 3,
    'magallanes': 3,
    'magallon': 3,
    'magan': 2,
    'magana': 3,
    'magar': 2,
    'magarian': 4,
    'magaw': 2,
    'magazine': 3,
    'magaziner': 4,
    'magazines': 3,
    'magby': 2,
    'magda': 2,
    'magdala': 3,
    'magdalen': 3,
    'magdalena': 4,
    'magdalene': 3,
    'magdaleno': 4,
    'magee': 2,
    'magel': 2,
    'magellan': 3,
    'magellanic': 4,
    'magenta': 3,
    'mager': 2,
    'magerman': 3,
    'magers': 2,
    'mages': 2,
    'maggard': 2,
    'maggart': 2,
    'maggi': 2,
    'maggie': 2,
    'maggio': 3,
    'maggot': 2,
    'maggots': 2,
    'maggs': 1,
    'magi': 2,
    'magic': 2,
    'magical': 3,
    'magically': 4,
    'magician': 3,
    'magicians': 3,
    'magid': 2,
    'magie': 2,
    'magiera': 3,
    'magill': 2,
    'magin': 2,
    'maginn': 2,
    'maginnis': 3,
    'maginot': 3,
    'magistad': 3,
    'magisterial': 5,
    'magistrate': 3,
    'magistrates': 3,
    'magistro': 3,
    'maglaj': 2,
    'maglev': 2,
    'magley': 2,
    'magli': 2,
    'magliano': 4,
    'maglica': 3,
    'maglio': 3,
    'magliocco': 4,
    'magliolo': 4,
    'maglione': 4,
    'maglis': 2,
    'magma': 2,
    'magna': 2,
    'magnan': 2,
    'magnani': 3,
    'magnanimous': 4,
    'magnano': 3,
    'magnant': 2,
    'magnate': 2,
    'magnates': 2,
    'magnavox': 3,
    'magner': 2,
    'magnesia': 3,
    'magnesite': 3,
    'magnesium': 4,
    'magness': 2,
    'magnet': 2,
    'magnetek': 3,
    'magneti': 3,
    'magnetic': 3,
    'magnetically': 5,
    'magnetics': 3,
    'magnetism': 4,
    'magnetite': 3,
    'magnetization': 5,
    'magnetized': 3,
    'magnetometer': 5,
    'magnetometers': 5,
    'magnetron': 3,
    'magnets': 2,
    'magnification': 5,
    'magnifications': 5,
    'magnificent': 4,
    'magnificently': 5,
    'magnified': 3,
    'magnifier': 4,
    'magnifiers': 4,
    'magnifies': 3,
    'magnify': 3,
    'magnifying': 4,
    'magnin': 2,
    'magnitogorsk': 4,
    'magnitude': 3,
    'magnitudes': 3,
    'magno': 2,
    'magnolia': 3,
    'magnolias': 3,
    'magnone': 3,
    'magnum': 2,
    'magnus': 2,
    'magnuson': 3,
    'magnussen': 3,
    'magnusson': 3,
    'magoon': 2,
    'magouirk': 2,
    'magowan': 3,
    'magpie': 2,
    'magpies': 2,
    'magrane': 2,
    'magrath': 2,
    'magri': 2,
    'magrini': 3,
    'magro': 2,
    'magruder': 3,
    'mags': 1,
    'magten': 2,
    'maguire': 2,
    'magwood': 2,
    'magyar': 2,
    'magyars': 2,
    'mah': 1,
    'mahabharata': 5,
    'mahady': 3,
    'mahaffey': 3,
    'mahaffy': 3,
    'mahal': 2,
    'mahala': 3,
    'mahalah': 3,
    'mahalia': 4,
    'mahamud': 3,
    'mahan': 2,
    'mahaney': 3,
    'mahannah': 3,
    'mahany': 3,
    'mahar': 2,
    'maharaja': 4,
    'maharajahs': 4,
    'maharashtra': 4,
    'mahariv': 3,
    'maharive': 3,
    'mahathir': 3,
    'mahatma': 3,
    'mahayana': 4,
    'mahdi': 2,
    'mahe': 2,
    'mahen': 2,
    'maher': 1,
    'mahesh': 2,
    'maheu': 2,
    'mahfouz': 2,
    'mahila': 3,
    'mahin': 2,
    'mahindra': 3,
    'mahl': 1,
    'mahle': 2,
    'mahler': 2,
    'mahlman': 2,
    'mahlmann': 2,
    'mahlon': 2,
    'mahlstedt': 2,
    'mahlum': 2,
    'mahmood': 2,
    'mahmoud': 2,
    'mahmud': 2,
    'mahn': 1,
    'mahnke': 1,
    'mahnken': 2,
    'mahogany': 4,
    'mahon': 2,
    'mahone': 2,
    'mahoney': 3,
    'mahony': 3,
    'mahood': 2,
    'mahowald': 3,
    'mahr': 1,
    'mahrt': 1,
    'mahtan': 2,
    'mahtar': 2,
    'mahurin': 3,
    'mahvi': 2,
    'mahwah': 2,
    'mai': 1,
    'maia': 2,
    'maid': 1,
    'maida': 2,
    'maidel': 2,
    'maiden': 2,
    'maidenform': 3,
    'maidens': 2,
    'maidie': 2,
    'maids': 1,
    'maiello': 3,
    'maier': 2,
    'maiers': 2,
    'maietta': 3,
    'mail': 1,
    'mailbag': 2,
    'mailbags': 2,
    'mailbox': 2,
    'mailboxes': 3,
    'maile': 1,
    'mailed': 1,
    'mailer': 2,
    'mailers': 2,
    'mailey': 2,
    'mailgram': 2,
    'mailgrams': 2,
    'mailhot': 2,
    'mailing': 2,
    'mailings': 2,
    'maillard': 2,
    'maille': 1,
    'maillet': 2,
    'mailloux': 2,
    'mailman': 2,
    'mailmen': 2,
    'mailroom': 2,
    'mailrooms': 2,
    'mails': 1,
    'mailson': 2,
    'maim': 1,
    'maimed': 1,
    'maiming': 2,
    'maimone': 2,
    'maims': 1,
    'main': 1,
    'maina': 2,
    'mainard': 2,
    'maine': 1,
    'mainer': 2,
    'mainers': 2,
    'maines': 1,
    'mainframe': 2,
    'mainframes': 2,
    'mainichi': 4,
    'mainland': 2,
    'mainlander': 3,
    'mainlanders': 3,
    'mainline': 2,
    'mainly': 2,
    'maino': 2,
    'mainor': 2,
    'mainord': 2,
    'mains': 1,
    'mainstay': 2,
    'mainstays': 2,
    'mainstream': 2,
    'mainstreaming': 3,
    'mainstreet': 2,
    'maintain': 2,
    'maintained': 2,
    'maintaining': 3,
    'maintains': 2,
    'maintenance': 3,
    'mainville': 2,
    'mainwaring': 3,
    'mainz': 1,
    'maio': 2,
    'maiolo': 3,
    'maione': 3,
    'maiorana': 4,
    'maiorano': 4,
    'maiorino': 4,
    'mair': 1,
    'maire': 1,
    'mairead': 2,
    'mairs': 1,
    'mais': 1,
    'maisano': 3,
    'maisch': 1,
    'maisel': 2,
    'maish': 2,
    'maisie': 2,
    'maison': 2,
    'maisonet': 3,
    'maiti': 2,
    'maitland': 2,
    'maitre': 2,
    'maize': 1,
    'maiziere': 2,
    'maj': 1,
    'majcher': 2,
    'majchrzak': 2,
    'majer': 2,
    'majerus': 3,
    'majeske': 3,
    'majeski': 3,
    'majesta': 3,
    'majestic': 3,
    'majestically': 5,
    'majesty': 3,
    'majette': 2,
    'majeure': 2,
    'majewski': 3,
    'majid': 2,
    'majik': 2,
    'majka': 2,
    'majkowski': 3,
    'majnoon': 2,
    'majolica': 4,
    'major': 2,
    'majorca': 3,
    'majordomo': 4,
    'majored': 2,
    'majoring': 3,
    'majoritarian': 6,
    'majorities': 4,
    'majority': 4,
    'majoros': 3,
    'majors': 2,
    'majrooh': 2,
    'majure': 3,
    'mak': 1,
    'makar': 2,
    'makara': 3,
    'makarewicz': 4,
    'make': 1,
    'makefield': 2,
    'makegood': 2,
    'makegoods': 2,
    'makela': 3,
    'makeover': 3,
    'makeovers': 3,
    'makepeace': 2,
    'maker': 2,
    'makers': 2,
    'makes': 1,
    'makeshift': 2,
    'makeup': 2,
    'makhoul': 2,
    'maki': 2,
    'makin': 2,
    'makinen': 3,
    'making': 2,
    'makings': 2,
    'makinson': 3,
    'makita': 3,
    'mako': 2,
    'makos': 2,
    'makoto': 3,
    'makowski': 3,
    'makowsky': 3,
    'makris': 2,
    'makro': 2,
    'maksoud': 2,
    'maksymuk': 3,
    'maktoum': 2,
    'maktoums': 2,
    'makuch': 2,
    'mal': 1,
    'mala': 2,
    'malabre': 3,
    'malacanang': 4,
    'malacca': 3,
    'malachowski': 4,
    'maladich': 3,
    'maladies': 3,
    'maladjusted': 4,
    'maladroit': 3,
    'malady': 3,
    'malaga': 3,
    'malagasy': 4,
    'malahide': 3,
    'malaise': 2,
    'malak': 2,
    'malamud': 3,
    'malamugi': 4,
    'malamute': 3,
    'malan': 2,
    'maland': 2,
    'malaney': 3,
    'malanga': 3,
    'malanowski': 4,
    'malaprop': 3,
    'malara': 3,
    'malaria': 4,
    'malarial': 4,
    'malarkey': 3,
    'malary': 3,
    'malaspina': 4,
    'malatesta': 4,
    'malathion': 4,
    'malave': 3,
    'malawi': 3,
    'malay': 2,
    'malayan': 3,
    'malays': 2,
    'malaysia': 3,
    'malaysian': 3,
    'malaysians': 3,
    'malbon': 2,
    'malbrough': 2,
    'malburg': 2,
    'malchow': 2,
    'malcolm': 2,
    'malcolmson': 3,
    'malcom': 2,
    'malcomb': 2,
    'malcomson': 3,
    'malcontent': 3,
    'malcontents': 3,
    'malczewski': 3,
    'malden': 2,
    'maldives': 2,
    'maldonado': 4,
    'maldutis': 3,
    'male': 1,
    'malec': 2,
    'malecha': 3,
    'malecki': 3,
    'malefactor': 4,
    'malefactors': 4,
    'malek': 2,
    'malena': 3,
    'malenfant': 3,
    'maler': 2,
    'malerba': 3,
    'males': 1,
    'maleski': 3,
    'malesky': 3,
    'malevolent': 4,
    'maley': 2,
    'malfeasance': 3,
    'malfitano': 4,
    'malformation': 4,
    'malformations': 4,
    'malformed': 2,
    'malfunction': 3,
    'malfunctioned': 3,
    'malfunctioning': 4,
    'malfunctions': 3,
    'malhotra': 3,
    'mali': 2,
    'malia': 3,
    'malibu': 3,
    'malice': 2,
    'malicious': 3,
    'maliciously': 4,
    'malick': 2,
    'malicki': 3,
    'malicoat': 3,
    'malign': 2,
    'malignancies': 4,
    'malignancy': 4,
    'malignant': 3,
    'maligned': 2,
    'malik': 2,
    'malin': 2,
    'malina': 3,
    'malinak': 3,
    'malinda': 3,
    'malinde': 2,
    'maline': 3,
    'malino': 3,
    'malinoski': 4,
    'malinowski': 4,
    'malinski': 3,
    'malis': 2,
    'maliszewski': 4,
    'malizia': 4,
    'malkiel': 3,
    'malkiewicz': 3,
    'malkin': 2,
    'malkovich': 3,
    'malkowski': 3,
    'mall': 1,
    'mallard': 2,
    'mallards': 2,
    'malle': 1,
    'malleability': 6,
    'malleable': 4,
    'mallek': 2,
    'mallen': 2,
    'maller': 2,
    'mallery': 3,
    'mallet': 2,
    'malleton': 3,
    'mallett': 2,
    'mallette': 2,
    'malley': 2,
    'mallia': 3,
    'mallick': 2,
    'mallicoat': 3,
    'mallie': 2,
    'mallightco': 3,
    'mallin': 2,
    'mallinckrodt': 3,
    'mallinger': 3,
    'mallinson': 3,
    'mallis': 2,
    'mallison': 3,
    'mallo': 2,
    'malloch': 2,
    'mallon': 2,
    'mallonee': 3,
    'mallory': 3,
    'malloseismic': 4,
    'mallow': 2,
    'mallows': 2,
    'malloy': 2,
    'mallozzi': 3,
    'mallrat': 2,
    'mallrats': 2,
    'malls': 1,
    'mally': 2,
    'malm': 1,
    'malmberg': 2,
    'malmgren': 2,
    'malmquist': 2,
    'malmstrom': 2,
    'malnar': 2,
    'malnourish': 3,
    'malnourished': 3,
    'malnutrition': 4,
    'malo': 2,
    'malon': 2,
    'malone': 2,
    'maloney': 3,
    'maloof': 2,
    'malosovich': 4,
    'malott': 2,
    'malouf': 2,
    'maloy': 2,
    'malpass': 2,
    'malphrus': 2,
    'malpractice': 3,
    'malrite': 2,
    'malsom': 2,
    'malson': 2,
    'malstrom': 2,
    'malt': 1,
    'malta': 2,
    'maltais': 2,
    'maltase': 2,
    'maltbie': 2,
    'maltby': 2,
    'malted': 2,
    'malter': 2,
    'maltese': 2,
    'malting': 2,
    'maltose': 2,
    'maltreated': 3,
    'maltreatment': 3,
    'malts': 1,
    'maltz': 1,
    'malueg': 2,
    'maluken': 3,
    'maluso': 3,
    'malva': 2,
    'malval': 2,
    'malveaux': 2,
    'malvern': 2,
    'malvie': 2,
    'malvin': 2,
    'malvina': 3,
    'malvinas': 3,
    'maly': 2,
    'malzahn': 2,
    'mam': 1,
    'mama': 2,
    'mamaroneck': 4,
    'mamas': 2,
    'mamba': 2,
    'mambas': 2,
    'mambo': 2,
    'mame': 1,
    'mameluke': 3,
    'mamet': 2,
    'mamie': 2,
    'mamis': 2,
    'mamma': 2,
    'mammal': 2,
    'mammalian': 4,
    'mammallike': 3,
    'mammals': 2,
    'mammary': 3,
    'mammen': 2,
    'mammogram': 3,
    'mammograms': 3,
    'mammography': 4,
    'mammoth': 2,
    'mammoths': 2,
    'mamone': 2,
    'mamsted': 2,
    'mamula': 3,
    'man': 1,
    'mana': 2,
    'manac': 2,
    'manafort': 3,
    'manage': 2,
    'manageable': 4,
    'managed': 2,
    'management': 3,
    'managements': 3,
    'manager': 3,
    'managerial': 5,
    'managers': 3,
    'manages': 3,
    'managing': 3,
    'managua': 3,
    'manahan': 3,
    'manak': 2,
    'manalo': 3,
    'manama': 3,
    'manard': 2,
    'manas': 2,
    'manasco': 3,
    'manasion': 3,
    'manassas': 3,
    'manatee': 3,
    'manatees': 3,
    'manatt': 2,
    'manbeck': 2,
    'mancala': 3,
    'mance': 1,
    'mancebo': 3,
    'mancera': 3,
    'mancha': 2,
    'manchester': 3,
    'manchu': 2,
    'manchuria': 4,
    'mancil': 2,
    'mancilla': 3,
    'mancillas': 3,
    'mancinelli': 4,
    'mancini': 3,
    'mancino': 3,
    'manco': 2,
    'mancusi': 3,
    'mancuso': 3,
    'manda': 2,
    'mandala': 3,
    'mandalay': 3,
    'mandalit': 3,
    'mandalite': 3,
    'mandamus': 3,
    'mandarin': 3,
    'mandarine': 3,
    'mandarino': 4,
    'mandarins': 3,
    'mandate': 2,
    'mandated': 3,
    'mandates': 2,
    'mandating': 3,
    'mandato': 3,
    'mandatory': 4,
    'mandel': 2,
    'mandela': 3,
    'mandelbaum': 3,
    'mandell': 2,
    'mandella': 3,
    'mander': 2,
    'manders': 2,
    'manderscheid': 3,
    'manderson': 3,
    'mandeville': 3,
    'mandi': 2,
    'mandible': 3,
    'mandich': 2,
    'mandie': 2,
    'mandigo': 3,
    'mandl': 2,
    'mandle': 2,
    'mandler': 2,
    'mandolin': 3,
    'mandracchia': 4,
    'mandrake': 2,
    'mandrell': 2,
    'mandresh': 2,
    'mandrill': 2,
    'mandt': 1,
    'mandujano': 4,
    'mandy': 2,
    'mane': 1,
    'maned': 1,
    'manella': 3,
    'maner': 2,
    'maners': 2,
    'manes': 1,
    'maness': 2,
    'manet': 2,
    'manette': 2,
    'maneuver': 3,
    'maneuverability': 7,
    'maneuverable': 5,
    'maneuvered': 3,
    'maneuvering': 4,
    'maneuverings': 4,
    'maneuvers': 3,
    'maneval': 3,
    'maney': 2,
    'manford': 2,
    'manfra': 2,
    'manfre': 2,
    'manfred': 2,
    'manfredi': 3,
    'manfredo': 3,
    'manfully': 3,
    'mang': 1,
    'manga': 2,
    'mangan': 2,
    'manganaro': 4,
    'manganello': 4,
    'manganese': 3,
    'manganiello': 5,
    'mangano': 3,
    'mangas': 2,
    'mange': 1,
    'mangel': 2,
    'mangels': 2,
    'mangement': 2,
    'mangen': 2,
    'manger': 2,
    'manges': 2,
    'mangham': 2,
    'mangiapane': 4,
    'mangiaracina': 5,
    'mangieri': 3,
    'mangin': 2,
    'mangine': 3,
    'mangini': 3,
    'mangino': 3,
    'mangione': 3,
    'manglapus': 3,
    'mangle': 2,
    'mangled': 2,
    'mangles': 2,
    'mangling': 3,
    'mango': 2,
    'mangoes': 2,
    'mangold': 2,
    'mangone': 3,
    'mangope': 3,
    'mangosteen': 3,
    'mangosteens': 3,
    'mangosuthu': 4,
    'mangrove': 2,
    'mangrum': 2,
    'mangual': 2,
    'mangueira': 3,
    'mangum': 2,
    'mangus': 2,
    'mangy': 2,
    'manhandle': 3,
    'manhandled': 3,
    'manhart': 2,
    'manhasset': 3,
    'manhattan': 3,
    'manheim': 2,
    'manhole': 2,
    'manhood': 2,
    'manhunt': 2,
    'mani': 2,
    'mania': 3,
    'maniac': 3,
    'maniacal': 4,
    'maniaci': 4,
    'maniacs': 3,
    'maniatis': 4,
    'manic': 2,
    'manicure': 3,
    'manicured': 3,
    'manicurist': 4,
    'manier': 3,
    'manifest': 3,
    'manifestation': 5,
    'manifestations': 5,
    'manifested': 4,
    'manifesting': 4,
    'manifestly': 4,
    'manifesto': 4,
    'manifests': 3,
    'manifold': 3,
    'manigat': 3,
    'manigault': 3,
    'manigo': 3,
    'manik': 2,
    'manila': 3,
    'manilla': 3,
    'manilow': 3,
    'manioc': 3,
    'manion': 2,
    'maniples': 3,
    'manipulate': 4,
    'manipulated': 5,
    'manipulates': 4,
    'manipulating': 5,
    'manipulation': 5,
    'manipulations': 5,
    'manipulative': 5,
    'manipulator': 5,
    'manipulators': 5,
    'manis': 2,
    'maniscalco': 4,
    'manischewitz': 4,
    'manish': 2,
    'manitoba': 4,
    'manitowoc': 4,
    'manjaca': 3,
    'manjarrez': 3,
    'mank': 1,
    'manka': 2,
    'mankato': 3,
    'manke': 1,
    'manker': 2,
    'mankey': 2,
    'mankiewicz': 3,
    'mankiller': 3,
    'mankin': 2,
    'mankind': 2,
    'mankinds': 2,
    'mankins': 2,
    'manko': 2,
    'mankowski': 3,
    'manley': 2,
    'manlove': 2,
    'manly': 2,
    'manmade': 2,
    'mann': 1,
    'manna': 2,
    'mannarino': 4,
    'manne': 1,
    'manned': 1,
    'mannella': 3,
    'mannen': 2,
    'mannequin': 3,
    'mannequins': 3,
    'manner': 2,
    'mannered': 2,
    'mannering': 3,
    'mannerism': 4,
    'mannerisms': 4,
    'mannerist': 3,
    'manners': 2,
    'mannes': 1,
    'mannesmann': 3,
    'manney': 2,
    'mannheim': 2,
    'manni': 2,
    'mannie': 2,
    'mannina': 3,
    'manninen': 3,
    'manning': 2,
    'mannino': 3,
    'mannion': 2,
    'mannis': 2,
    'mannix': 2,
    'manno': 2,
    'mannon': 2,
    'manns': 1,
    'manny': 2,
    'mano': 2,
    'manocchio': 4,
    'manoff': 2,
    'manolis': 3,
    'manon': 2,
    'manoogian': 4,
    'manor': 2,
    'manors': 2,
    'manos': 2,
    'manpower': 3,
    'manring': 2,
    'manrique': 2,
    'manriquez': 3,
    'manry': 2,
    'mans': 1,
    'manseau': 2,
    'mansel': 2,
    'mansell': 2,
    'manser': 2,
    'mansfield': 2,
    'manship': 2,
    'mansion': 2,
    'mansions': 2,
    'manske': 1,
    'mansker': 2,
    'manslaughter': 3,
    'manso': 2,
    'manson': 2,
    'mansour': 2,
    'mansueto': 3,
    'mansur': 2,
    'manta': 2,
    'manteca': 3,
    'manteer': 2,
    'mantei': 2,
    'mantel': 2,
    'mantell': 2,
    'manter': 2,
    'manternach': 3,
    'manteufel': 3,
    'mantey': 2,
    'manthe': 1,
    'manthei': 2,
    'manthey': 2,
    'mantia': 2,
    'mantilla': 3,
    'mantione': 4,
    'mantis': 2,
    'mantle': 2,
    'mantles': 2,
    'manton': 2,
    'mantooth': 2,
    'mantra': 2,
    'mantua': 3,
    'mantz': 1,
    'manu': 2,
    'manual': 3,
    'manually': 4,
    'manuals': 3,
    'manucher': 3,
    'manuel': 2,
    'manuela': 3,
    'manuele': 2,
    'manufacture': 4,
    'manufactured': 4,
    'manufacturer': 5,
    'manufacturers': 5,
    'manufactures': 4,
    'manufacturing': 5,
    'manure': 2,
    'manus': 2,
    'manuscript': 3,
    'manuscripts': 3,
    'manvel': 2,
    'manvil': 2,
    'manville': 2,
    'manwaring': 3,
    'manwarren': 3,
    'manweb': 2,
    'manwell': 2,
    'manwiller': 3,
    'manx': 1,
    'many': 2,
    'manz': 1,
    'manza': 2,
    'manzanares': 4,
    'manzanilla': 4,
    'manzano': 3,
    'manzella': 3,
    'manzer': 2,
    'manzi': 2,
    'manzione': 4,
    'manzo': 2,
    'mao': 1,
    'maoist': 2,
    'maoists': 2,
    'maori': 2,
    'maoris': 3,
    'map': 1,
    'mapco': 2,
    'mapel': 2,
    'mapes': 1,
    'mapfumo': 3,
    'mapi': 2,
    'maple': 2,
    'maples': 2,
    'maplewood': 3,
    'mapp': 1,
    'mapped': 1,
    'mapping': 2,
    'mapplethorpe': 3,
    'maps': 1,
    'maputo': 3,
    'maquila': 3,
    'maquiladora': 5,
    'maquiladoras': 5,
    'maquilas': 3,
    'mar': 1,
    'mara': 2,
    'marabella': 4,
    'marable': 3,
    'marabou': 3,
    'maracle': 3,
    'maradona': 4,
    'maradonna': 4,
    'marafat': 3,
    'marais': 2,
    'marak': 2,
    'maralina': 4,
    'maraline': 4,
    'maran': 2,
    'maranda': 3,
    'marando': 3,
    'maraniss': 3,
    'marano': 3,
    'maranon': 3,
    'marantette': 3,
    'maranto': 3,
    'marantz': 2,
    'maranville': 3,
    'maras': 2,
    'maraschino': 4,
    'marasco': 3,
    'marasek': 3,
    'marash': 2,
    'marathi': 3,
    'marathon': 3,
    'marathons': 3,
    'maraud': 2,
    'marauder': 3,
    'marauders': 3,
    'marauding': 3,
    'maravilla': 4,
    'marbach': 2,
    'marbella': 3,
    'marberry': 3,
    'marbil': 2,
    'marble': 2,
    'marbled': 2,
    'marblehead': 3,
    'marbles': 2,
    'marbod': 2,
    'marbry': 2,
    'marburger': 3,
    'marbury': 3,
    'marbut': 2,
    'marc': 1,
    'marcade': 2,
    'marcano': 3,
    'marcantel': 3,
    'marcantonio': 5,
    'marceau': 2,
    'marceaux': 2,
    'marceca': 3,
    'marcel': 2,
    'marcela': 3,
    'marcelia': 4,
    'marcelino': 4,
    'marcell': 2,
    'marcella': 3,
    'marcelle': 2,
    'marcelli': 3,
    'marcellina': 4,
    'marcelline': 4,
    'marcellino': 4,
    'marcello': 3,
    'marcellus': 3,
    'marcelo': 3,
    'marcessa': 3,
    'march': 1,
    'marchak': 2,
    'marchal': 2,
    'marchand': 2,
    'marchant': 2,
    'marchbank': 2,
    'marchbanks': 2,
    'marche': 1,
    'marched': 1,
    'marchenko': 3,
    'marcher': 2,
    'marchers': 2,
    'marches': 2,
    'marchesani': 4,
    'marchesano': 4,
    'marcheschi': 3,
    'marchese': 3,
    'marchesi': 3,
    'marchessault': 3,
    'marchetta': 3,
    'marchetti': 3,
    'marchewka': 3,
    'marchi': 2,
    'marching': 2,
    'marchini': 3,
    'marchinko': 3,
    'marchio': 3,
    'marchione': 4,
    'marchioness': 3,
    'marchita': 3,
    'marchitto': 3,
    'marchman': 2,
    'marci': 2,
    'marcia': 2,
    'marcial': 3,
    'marciano': 4,
    'marciante': 3,
    'marcie': 2,
    'marcil': 2,
    'marcile': 2,
    'marcilio': 4,
    'marcille': 2,
    'marcin': 2,
    'marcinek': 3,
    'marciniak': 4,
    'marcinko': 3,
    'marcinkowski': 4,
    'marcinkus': 3,
    'marcissa': 3,
    'marckesano': 4,
    'marcks': 1,
    'marcmann': 2,
    'marco': 2,
    'marcoe': 2,
    'marcom': 2,
    'marcon': 2,
    'marcone': 3,
    'marconi': 3,
    'marcor': 2,
    'marcos': 2,
    'marcoses': 3,
    'marcott': 2,
    'marcotte': 2,
    'marcou': 2,
    'marcoux': 2,
    'marcrum': 2,
    'marcucci': 3,
    'marcum': 2,
    'marcus': 2,
    'marcussen': 3,
    'marcy': 2,
    'marczak': 2,
    'marden': 2,
    'marder': 2,
    'mardi': 2,
    'mardis': 2,
    'mardon': 2,
    'mare': 1,
    'maready': 3,
    'marean': 2,
    'marek': 2,
    'marelda': 3,
    'marella': 3,
    'marelli': 3,
    'mareno': 3,
    'mares': 1,
    'maresca': 3,
    'maresco': 3,
    'maresh': 2,
    'maret': 2,
    'marett': 2,
    'maretta': 3,
    'marette': 2,
    'marez': 2,
    'marfa': 2,
    'marga': 2,
    'margalo': 3,
    'margalov': 3,
    'margaret': 3,
    'margareta': 4,
    'margarete': 3,
    'margarethe': 4,
    'margaretten': 4,
    'margarine': 3,
    'margarita': 4,
    'margaritas': 4,
    'margaux': 2,
    'marge': 1,
    'margeotes': 4,
    'margerum': 3,
    'margery': 3,
    'margeson': 3,
    'marget': 2,
    'margette': 2,
    'margie': 2,
    'margin': 2,
    'marginal': 3,
    'marginalization': 6,
    'marginalize': 4,
    'marginalized': 4,
    'marginalizes': 5,
    'marginalizing': 5,
    'marginally': 4,
    'margined': 2,
    'margining': 3,
    'margins': 2,
    'margiotta': 3,
    'margittai': 3,
    'margo': 2,
    'margolick': 3,
    'margolies': 3,
    'margolin': 3,
    'margolis': 3,
    'margory': 3,
    'margoshes': 3,
    'margot': 2,
    'margraf': 2,
    'margrave': 2,
    'margret': 2,
    'marguerite': 3,
    'margulies': 3,
    'margulis': 3,
    'margus': 2,
    'margy': 2,
    'marhefka': 3,
    'mari': 2,
    'maria': 3,
    'mariachi': 4,
    'mariah': 3,
    'mariam': 3,
    'marian': 3,
    'mariana': 4,
    'marianas': 4,
    'mariani': 4,
    'marianna': 4,
    'marianne': 3,
    'mariano': 4,
    'maribel': 3,
    'marice': 2,
    'marich': 2,
    'maricle': 3,
    'maricopa': 4,
    'marie': 2,
    'mariel': 3,
    'marielito': 5,
    'marielitos': 5,
    'marien': 2,
    'marienbonn': 3,
    'marier': 3,
    'marietta': 4,
    'mariette': 3,
    'marife': 3,
    'marigold': 3,
    'marigolds': 3,
    'marijuana': 4,
    'marik': 2,
    'marilda': 3,
    'marilin': 3,
    'marilla': 3,
    'marilu': 3,
    'marilyn': 3,
    'marilynn': 3,
    'marimba': 3,
    'marimbalax': 4,
    'marin': 2,
    'marina': 3,
    'marinaccio': 5,
    'marinade': 3,
    'marinades': 3,
    'marinaro': 4,
    'marinas': 3,
    'marinate': 3,
    'marinated': 4,
    'marinates': 3,
    'marinating': 4,
    'marine': 2,
    'marineau': 3,
    'marineland': 3,
    'marinelli': 4,
    'marinello': 4,
    'mariner': 3,
    'mariners': 3,
    'marines': 2,
    'marinez': 3,
    'maring': 2,
    'marini': 3,
    'marinkovich': 4,
    'marino': 3,
    'marinoff': 3,
    'marinos': 3,
    'marinucci': 4,
    'marinvest': 3,
    'mario': 3,
    'marion': 3,
    'mariotta': 4,
    'mariotti': 4,
    'maris': 2,
    'marisa': 3,
    'mariscal': 3,
    'marish': 2,
    'marissa': 3,
    'marital': 3,
    'maritime': 3,
    'maritimes': 3,
    'maritz': 2,
    'mariucci': 4,
    'marius': 3,
    'mariusa': 4,
    'marjai': 2,
    'marje': 1,
    'marjie': 2,
    'marjoram': 3,
    'marjorie': 3,
    'marjory': 3,
    'marjy': 2,
    'mark': 1,
    'markair': 2,
    'markan': 2,
    'markarian': 4,
    'markdown': 2,
    'markdowns': 2,
    'marke': 1,
    'marked': 1,
    'markedly': 3,
    'markee': 2,
    'markel': 2,
    'markell': 2,
    'marken': 2,
    'marker': 2,
    'markers': 2,
    'markert': 2,
    'markese': 2,
    'market': 2,
    'marketability': 6,
    'marketable': 4,
    'marketamerica': 6,
    'marketed': 3,
    'marketeer': 3,
    'marketeers': 3,
    'marketer': 3,
    'marketers': 3,
    'marketi': 3,
    'marketing': 3,
    'marketings': 3,
    'marketmaker': 4,
    'marketmakers': 4,
    'marketplace': 3,
    'marketplaces': 4,
    'markets': 2,
    'marketscope': 3,
    'marketwide': 3,
    'markey': 2,
    'markgraf': 2,
    'markham': 2,
    'markie': 2,
    'markiewicz': 3,
    'markin': 2,
    'marking': 2,
    'markings': 2,
    'markka': 2,
    'markkaa': 2,
    'markkas': 2,
    'markland': 2,
    'markle': 2,
    'markley': 2,
    'markman': 2,
    'marko': 2,
    'markoff': 2,
    'markos': 2,
    'markovic': 3,
    'markovich': 3,
    'markovitz': 3,
    'markow': 2,
    'markowicz': 3,
    'markowitz': 3,
    'markowski': 3,
    'marks': 1,
    'marksberry': 3,
    'marksman': 2,
    'marksmanship': 3,
    'marksmen': 2,
    'markson': 2,
    'markum': 2,
    'markunas': 3,
    'markup': 2,
    'markups': 2,
    'markus': 2,
    'markuson': 3,
    'markwardt': 2,
    'markwell': 2,
    'markwood': 2,
    'marky': 2,
    'marla': 2,
    'marland': 2,
    'marlar': 2,
    'marlatt': 2,
    'marlboro': 3,
    'marlboros': 3,
    'marlborough': 3,
    'marleen': 2,
    'marleene': 2,
    'marlen': 2,
    'marlena': 3,
    'marlene': 2,
    'marlens': 2,
    'marler': 2,
    'marlett': 2,
    'marlette': 2,
    'marley': 2,
    'marliave': 3,
    'marlin': 2,
    'marling': 2,
    'marlins': 2,
    'marlo': 2,
    'marlon': 2,
    'marlow': 2,
    'marlowe': 2,
    'marlton': 2,
    'marlys': 2,
    'marmaduke': 3,
    'marmalade': 3,
    'marmalades': 3,
    'marmara': 3,
    'marmer': 2,
    'marmion': 3,
    'marmo': 2,
    'marmolejo': 4,
    'marmon': 2,
    'marmor': 2,
    'marmoset': 3,
    'marnell': 2,
    'marner': 2,
    'marney': 2,
    'maro': 2,
    'maroc': 2,
    'marogen': 3,
    'marohl': 2,
    'marohn': 2,
    'marois': 2,
    'marold': 2,
    'marolf': 2,
    'marolt': 2,
    'maron': 2,
    'marone': 2,
    'maroney': 3,
    'maroni': 3,
    'maronite': 3,
    'maroon': 2,
    'marooned': 2,
    'marooney': 3,
    'marose': 2,
    'marotta': 3,
    'marotte': 2,
    'marotto': 3,
    'marotz': 2,
    'marous': 2,
    'marovich': 3,
    'marplan': 2,
    'marple': 2,
    'marquai': 2,
    'marquand': 2,
    'marquard': 2,
    'marquardt': 2,
    'marquart': 2,
    'marque': 1,
    'marquee': 2,
    'marquerite': 3,
    'marques': 1,
    'marquess': 2,
    'marquest': 2,
    'marquette': 2,
    'marquez': 2,
    'marquis': 2,
    'marquiss': 2,
    'marquita': 3,
    'marr': 1,
    'marra': 2,
    'marrakech': 3,
    'marrazzo': 3,
    'marred': 1,
    'marren': 2,
    'marrero': 3,
    'marrett': 2,
    'marriage': 2,
    'marriages': 3,
    'married': 2,
    'marries': 2,
    'marrin': 2,
    'marriner': 3,
    'marring': 2,
    'marriott': 3,
    'marris': 2,
    'marro': 2,
    'marrocco': 3,
    'marron': 2,
    'marrone': 3,
    'marroquin': 3,
    'marrou': 2,
    'marrow': 2,
    'marrs': 1,
    'marrufo': 3,
    'marrujo': 3,
    'marry': 2,
    'marrying': 3,
    'mars': 1,
    'marsal': 2,
    'marsala': 3,
    'marsalis': 3,
    'marsam': 2,
    'marsch': 1,
    'marschalk': 2,
    'marschall': 2,
    'marschke': 1,
    'marschner': 2,
    'marsden': 2,
    'marsdon': 2,
    'marse': 1,
    'marseca': 3,
    'marsee': 2,
    'marseille': 2,
    'marseilles': 3,
    'marseka': 3,
    'marsella': 3,
    'marsh': 1,
    'marsha': 2,
    'marshak': 2,
    'marshal': 2,
    'marshaled': 2,
    'marshaling': 3,
    'marshall': 2,
    'marshalled': 2,
    'marshalls': 2,
    'marshalltown': 3,
    'marshals': 2,
    'marshburn': 2,
    'marshes': 2,
    'marshfield': 2,
    'marshland': 2,
    'marshlands': 2,
    'marshmallow': 3,
    'marshmallows': 3,
    'marshman': 2,
    'marshy': 2,
    'marsicano': 4,
    'marsico': 3,
    'marsiglia': 4,
    'marsili': 3,
    'marsisa': 3,
    'marsland': 2,
    'marso': 2,
    'marsolek': 3,
    'marson': 2,
    'marsteller': 3,
    'marsters': 2,
    'marston': 2,
    'marsupial': 4,
    'marsupials': 4,
    'marszalek': 3,
    'mart': 1,
    'marta': 2,
    'marte': 1,
    'martech': 2,
    'martek': 2,
    'martel': 2,
    'martell': 2,
    'martella': 3,
    'martelle': 2,
    'martelli': 3,
    'martellini': 4,
    'martello': 3,
    'marten': 2,
    'marteney': 3,
    'martens': 2,
    'martensen': 3,
    'martenson': 3,
    'marter': 2,
    'marth': 1,
    'martha': 2,
    'marthaler': 3,
    'marthe': 1,
    'marthena': 3,
    'marti': 2,
    'martial': 2,
    'martialed': 2,
    'martials': 2,
    'martian': 2,
    'martians': 2,
    'martie': 2,
    'martin': 2,
    'martina': 3,
    'martindale': 3,
    'martine': 2,
    'martineau': 3,
    'martinec': 3,
    'martinek': 3,
    'martines': 2,
    'martinez': 3,
    'marting': 2,
    'martingale': 3,
    'martini': 3,
    'martinique': 3,
    'martinis': 3,
    'martinka': 3,
    'martinko': 3,
    'martino': 3,
    'martinovich': 4,
    'martins': 2,
    'martinsen': 3,
    'martinson': 3,
    'martinsville': 3,
    'martiny': 3,
    'martion': 2,
    'martire': 3,
    'martirosov': 4,
    'martis': 2,
    'martita': 3,
    'martocci': 3,
    'marton': 2,
    'martone': 3,
    'martorana': 4,
    'martorano': 4,
    'martorelli': 4,
    'marts': 1,
    'martucci': 3,
    'marturano': 4,
    'martus': 2,
    'marty': 2,
    'martyn': 2,
    'martyr': 2,
    'martyrdom': 3,
    'martyre': 2,
    'martyred': 2,
    'martyrs': 2,
    'martz': 1,
    'marubeni': 4,
    'maruca': 3,
    'marucci': 3,
    'marui': 3,
    'marukin': 3,
    'marulanda': 4,
    'marullo': 3,
    'maruska': 3,
    'maruti': 3,
    'maruyama': 4,
    'maruyu': 3,
    'marv': 1,
    'marva': 2,
    'marvel': 2,
    'marvela': 3,
    'marveled': 2,
    'marveling': 3,
    'marvell': 2,
    'marvella': 3,
    'marvelle': 2,
    'marvelous': 3,
    'marvelously': 4,
    'marvels': 2,
    'marver': 2,
    'marvin': 2,
    'marwick': 2,
    'marwin': 2,
    'marwood': 2,
    'marx': 1,
    'marxen': 2,
    'marxism': 3,
    'marxist': 2,
    'marxists': 2,
    'mary': 2,
    'marya': 2,
    'maryalice': 4,
    'maryann': 3,
    'maryanne': 3,
    'marybeth': 3,
    'maryland': 3,
    'marylebone': 3,
    'marylin': 3,
    'marylou': 3,
    'marymount': 3,
    'maryruth': 3,
    'maryse': 2,
    'marysville': 3,
    'maryville': 3,
    'marz': 1,
    'marzan': 2,
    'marzano': 3,
    'marzec': 2,
    'marzette': 2,
    'marzilli': 3,
    'marzo': 2,
    'marzolf': 2,
    'marzotto': 3,
    'marzullo': 3,
    'mas': 1,
    'masa': 2,
    'masaaki': 4,
    'masahiko': 4,
    'masahiro': 4,
    'masai': 3,
    'masakazu': 4,
    'masaki': 3,
    'masako': 3,
    'masami': 3,
    'masamilla': 4,
    'masao': 3,
    'masaru': 3,
    'masaya': 3,
    'masayoshi': 4,
    'masayuki': 4,
    'mascara': 3,
    'mascarena': 4,
    'mascarenas': 4,
    'mascari': 3,
    'mascaro': 3,
    'masch': 1,
    'maschino': 3,
    'maschke': 1,
    'masci': 2,
    'mascia': 2,
    'masciarelli': 4,
    'mascio': 3,
    'mascioli': 3,
    'masco': 2,
    'mascola': 3,
    'mascolo': 3,
    'mascorro': 3,
    'mascot': 2,
    'mascots': 2,
    'masculine': 3,
    'masculinity': 5,
    'masden': 2,
    'mase': 1,
    'masefield': 2,
    'masek': 2,
    'masella': 3,
    'maselli': 3,
    'maseng': 2,
    'maser': 2,
    'maserati': 4,
    'maseri': 3,
    'mash': 1,
    'mashalu': 3,
    'mashantucket': 4,
    'mashaw': 2,
    'mashburn': 2,
    'mashed': 1,
    'mashek': 2,
    'masi': 2,
    'masiello': 4,
    'masin': 2,
    'masini': 3,
    'masino': 3,
    'masius': 3,
    'mask': 1,
    'maske': 1,
    'masked': 1,
    'maskell': 2,
    'masker': 2,
    'masking': 2,
    'masks': 1,
    'maslanka': 3,
    'maslen': 3,
    'masley': 2,
    'maslin': 2,
    'maslow': 2,
    'maslowski': 3,
    'maslyukov': 3,
    'maso': 2,
    'masochism': 4,
    'masochist': 3,
    'masochistic': 4,
    'mason': 2,
    'masone': 2,
    'masoner': 3,
    'masonic': 3,
    'masonite': 3,
    'masonry': 3,
    'masons': 2,
    'masood': 2,
    'masoud': 2,
    'masquerade': 3,
    'masquerading': 4,
    'masri': 2,
    'mass': 1,
    'massa': 2,
    'massachusetts': 4,
    'massachussetts': 4,
    'massacre': 3,
    'massacred': 3,
    'massacres': 3,
    'massacring': 4,
    'massad': 2,
    'massage': 2,
    'massaged': 2,
    'massager': 3,
    'massagers': 3,
    'massages': 3,
    'massaging': 3,
    'massar': 2,
    'massari': 3,
    'massaro': 3,
    'massat': 2,
    'massbauch': 2,
    'masse': 1,
    'massed': 1,
    'massenburg': 3,
    'massenet': 3,
    'massengale': 3,
    'massengill': 3,
    'masser': 2,
    'massery': 3,
    'masses': 2,
    'massett': 2,
    'massey': 2,
    'massi': 2,
    'massicotte': 3,
    'massie': 2,
    'massieu': 3,
    'massif': 2,
    'massimino': 4,
    'massimo': 3,
    'massing': 2,
    'massingale': 4,
    'massingill': 3,
    'massive': 2,
    'massively': 3,
    'massman': 2,
    'massmann': 2,
    'massmutual': 4,
    'masso': 2,
    'masson': 2,
    'massoni': 3,
    'massoth': 2,
    'massport': 2,
    'massucci': 3,
    'mast': 1,
    'mastandrea': 4,
    'mastectomies': 4,
    'mastectomy': 4,
    'masted': 2,
    'mastel': 2,
    'masteller': 3,
    'masten': 2,
    'master': 2,
    'masterbate': 3,
    'masterbated': 4,
    'masterbates': 3,
    'masterbating': 4,
    'masterbation': 4,
    'mastercard': 3,
    'mastercards': 3,
    'mastered': 2,
    'masterful': 3,
    'masterfully': 4,
    'mastergate': 3,
    'mastering': 3,
    'masterly': 3,
    'masterman': 3,
    'mastermind': 3,
    'masterminded': 4,
    'masterminding': 4,
    'masterminds': 3,
    'masterpiece': 3,
    'masterpieces': 4,
    'masters': 2,
    'masterson': 3,
    'masterstroke': 3,
    'masterwork': 3,
    'masterworks': 3,
    'mastery': 3,
    'masthead': 2,
    'mastif': 2,
    'mastiff': 2,
    'mastifs': 2,
    'mastin': 2,
    'mastodon': 3,
    'mastoiditis': 4,
    'maston': 2,
    'mastrangelo': 4,
    'mastrianni': 4,
    'mastriano': 4,
    'mastrich': 2,
    'mastro': 2,
    'mastrocola': 4,
    'mastrogiovanni': 5,
    'mastroianni': 4,
    'mastronardi': 4,
    'mastropietro': 4,
    'masts': 1,
    'masturbate': 3,
    'masturbated': 4,
    'masturbates': 3,
    'masturbating': 4,
    'masturbation': 4,
    'masucci': 3,
    'masuda': 3,
    'masullo': 3,
    'masur': 2,
    'mat': 1,
    'mata': 2,
    'matabane': 3,
    'matador': 3,
    'matagorda': 4,
    'mataksas': 3,
    'matala': 3,
    'matalin': 3,
    'matalon': 3,
    'matamoros': 4,
    'matarazzo': 4,
    'matarese': 4,
    'matas': 2,
    'matassa': 3,
    'mataya': 3,
    'match': 1,
    'matchbook': 2,
    'matchbooks': 2,
    'matchbox': 2,
    'matchboxes': 3,
    'matched': 1,
    'matches': 2,
    'matchett': 2,
    'matching': 2,
    'matchlock': 2,
    'matchlocks': 2,
    'matchmaker': 3,
    'matchmakers': 3,
    'matchmaking': 3,
    'matchup': 2,
    'matchups': 2,
    'mate': 1,
    'matecki': 3,
    'mated': 2,
    'mateer': 2,
    'mateja': 3,
    'matejka': 3,
    'matell': 2,
    'matelski': 3,
    'mateo': 3,
    'mater': 2,
    'matera': 3,
    'material': 4,
    'materialism': 6,
    'materialist': 5,
    'materialistic': 6,
    'materiality': 6,
    'materialize': 5,
    'materialized': 5,
    'materializes': 6,
    'materializing': 6,
    'materially': 5,
    'materials': 4,
    'materiel': 4,
    'materiels': 4,
    'matern': 2,
    'materna': 3,
    'maternal': 3,
    'maternity': 4,
    'mates': 1,
    'matewan': 3,
    'matey': 2,
    'math': 1,
    'mathai': 3,
    'mathan': 2,
    'mathe': 1,
    'mathea': 3,
    'matheis': 2,
    'mathematic': 4,
    'mathematical': 5,
    'mathematically': 6,
    'mathematician': 5,
    'mathematicians': 5,
    'mathematics': 4,
    'mathena': 3,
    'matheney': 3,
    'matheny': 3,
    'mather': 2,
    'matherly': 3,
    'mathern': 2,
    'matherne': 2,
    'mathers': 2,
    'matherson': 3,
    'mathes': 2,
    'matheson': 3,
    'matheus': 2,
    'mathew': 2,
    'mathews': 2,
    'mathewson': 3,
    'mathey': 2,
    'mathia': 3,
    'mathias': 3,
    'mathiasen': 4,
    'mathiason': 4,
    'mathie': 2,
    'mathies': 2,
    'mathiesen': 3,
    'mathieson': 3,
    'mathieu': 2,
    'mathilda': 3,
    'mathilde': 2,
    'mathis': 2,
    'mathisen': 3,
    'mathison': 3,
    'mathur': 2,
    'mathwig': 2,
    'mathy': 2,
    'mathys': 2,
    'matias': 2,
    'matic': 2,
    'matif': 2,
    'matilda': 3,
    'matinee': 3,
    'mating': 2,
    'mation': 2,
    'matis': 2,
    'matisse': 2,
    'matkin': 2,
    'matkins': 2,
    'matkovich': 3,
    'matlack': 2,
    'matlick': 2,
    'matlin': 2,
    'matlock': 2,
    'matney': 2,
    'mato': 2,
    'matoco': 3,
    'matola': 3,
    'matos': 2,
    'matott': 2,
    'matousek': 3,
    'matra': 2,
    'matranga': 3,
    'matriarch': 3,
    'matriarchal': 4,
    'matriarchy': 4,
    'matrice': 2,
    'matrices': 3,
    'matriculate': 4,
    'matriculated': 5,
    'matriculates': 4,
    'matriculating': 5,
    'matrilineal': 5,
    'matrimonial': 5,
    'matrimony': 4,
    'matrix': 2,
    'matrixes': 3,
    'matron': 2,
    'matronly': 3,
    'matrons': 2,
    'mats': 1,
    'matsapa': 3,
    'matsch': 1,
    'matscher': 2,
    'matsen': 2,
    'matsko': 2,
    'matson': 2,
    'matsubara': 4,
    'matsuda': 3,
    'matsui': 3,
    'matsukawa': 4,
    'matsumoto': 4,
    'matsumura': 4,
    'matsunaga': 4,
    'matsuo': 3,
    'matsuoka': 4,
    'matsushita': 4,
    'matsuura': 4,
    'matt': 1,
    'matta': 2,
    'matte': 1,
    'mattea': 2,
    'mattei': 2,
    'matteis': 2,
    'mattel': 2,
    'matteo': 3,
    'matter': 2,
    'mattera': 3,
    'matterease': 3,
    'mattered': 2,
    'mattern': 2,
    'matters': 2,
    'mattes': 1,
    'matteson': 3,
    'matteucci': 3,
    'matthau': 2,
    'matthausen': 3,
    'matthea': 3,
    'mattheis': 2,
    'matthes': 2,
    'matthew': 2,
    'matthews': 2,
    'matthewson': 3,
    'matthey': 2,
    'matthia': 3,
    'matthias': 3,
    'matthies': 2,
    'matthiesen': 3,
    'matthis': 2,
    'matthys': 2,
    'mattias': 2,
    'mattice': 2,
    'mattick': 2,
    'mattie': 2,
    'mattikalli': 4,
    'mattila': 3,
    'mattina': 3,
    'matting': 2,
    'mattingley': 3,
    'mattingly': 3,
    'mattioli': 4,
    'mattis': 2,
    'mattison': 3,
    'mattke': 2,
    'mattocks': 2,
    'matton': 2,
    'mattoon': 2,
    'mattos': 2,
    'mattox': 2,
    'mattress': 2,
    'mattresses': 3,
    'matts': 1,
    'mattson': 2,
    'mattucci': 3,
    'matty': 2,
    'matula': 3,
    'matulich': 3,
    'matulis': 3,
    'maturation': 4,
    'mature': 2,
    'matured': 2,
    'matures': 2,
    'maturing': 3,
    'maturities': 4,
    'maturity': 4,
    'maturo': 3,
    'matus': 2,
    'matuschka': 3,
    'matusek': 3,
    'matusi': 3,
    'matusiak': 4,
    'matusik': 3,
    'matuska': 3,
    'matussi': 3,
    'matuszak': 3,
    'matuszewski': 4,
    'matyas': 2,
    'matz': 1,
    'matza': 2,
    'matzek': 2,
    'matzen': 2,
    'matzinger': 3,
    'matzke': 2,
    'matzner': 2,
    'matzu': 2,
    'mau': 1,
    'mauceri': 3,
    'mauch': 1,
    'maucher': 2,
    'mauck': 1,
    'maud': 1,
    'maude': 1,
    'maudlin': 2,
    'maue': 2,
    'mauer': 2,
    'mauger': 2,
    'maugeri': 3,
    'maughan': 2,
    'maui': 2,
    'mauk': 1,
    'maul': 1,
    'maulden': 2,
    'mauldin': 2,
    'maulding': 2,
    'maule': 1,
    'mauled': 1,
    'mauler': 2,
    'maull': 1,
    'maulsby': 2,
    'mault': 1,
    'maultasch': 2,
    'maultsby': 2,
    'maumee': 2,
    'mauna': 2,
    'maund': 1,
    'maundering': 3,
    'maunderings': 3,
    'maune': 1,
    'mauney': 2,
    'maung': 1,
    'maupin': 2,
    'maura': 2,
    'maureen': 2,
    'maurer': 2,
    'mauri': 2,
    'maurice': 2,
    'mauricio': 4,
    'maurie': 2,
    'mauriello': 4,
    'maurin': 2,
    'maurine': 3,
    'maurita': 3,
    'mauritania': 5,
    'mauritius': 4,
    'mauritz': 2,
    'maurizio': 4,
    'mauro': 2,
    'maurus': 2,
    'maury': 2,
    'maus': 1,
    'mauser': 2,
    'mausoleum': 4,
    'mausolf': 2,
    'mauss': 1,
    'mausser': 2,
    'maust': 1,
    'maute': 1,
    'mauthe': 1,
    'mautner': 2,
    'mautz': 1,
    'mauve': 1,
    'mauzey': 2,
    'mauzy': 2,
    'mav': 1,
    'mavarach': 3,
    'mave': 1,
    'maven': 2,
    'mavens': 2,
    'maverick': 3,
    'mavericks': 3,
    'maverix': 3,
    'maves': 1,
    'mavis': 2,
    'mavrodi': 3,
    'mavroules': 2,
    'mavs': 1,
    'maw': 1,
    'mawhinney': 3,
    'mawkish': 2,
    'mawr': 2,
    'mawson': 2,
    'mawyer': 3,
    'max': 1,
    'maxa': 2,
    'maxam': 2,
    'maxcy': 2,
    'maxed': 1,
    'maxell': 2,
    'maxey': 2,
    'maxfield': 2,
    'maxham': 2,
    'maxi': 2,
    'maxicare': 3,
    'maxie': 2,
    'maxilla': 3,
    'maxim': 2,
    'maxima': 3,
    'maximal': 3,
    'maxime': 2,
    'maximilian': 5,
    'maximization': 5,
    'maximize': 3,
    'maximized': 3,
    'maximizes': 4,
    'maximizing': 4,
    'maxims': 2,
    'maximum': 3,
    'maxine': 2,
    'maxion': 3,
    'maxis': 2,
    'maxon': 2,
    'maxpharma': 3,
    'maxsaver': 3,
    'maxsavers': 3,
    'maxson': 2,
    'maxton': 2,
    'maxtor': 2,
    'maxus': 2,
    'maxwell': 2,
    'maxx': 1,
    'maxxam': 2,
    'maxxim': 2,
    'maxy': 2,
    'maxzide': 3,
    'may': 1,
    'maya': 2,
    'mayaguez': 3,
    'mayall': 2,
    'mayan': 2,
    'mayans': 2,
    'maybe': 2,
    'maybee': 2,
    'maybelle': 2,
    'maybelline': 3,
    'mayben': 2,
    'mayberry': 3,
    'maybin': 2,
    'maybury': 3,
    'maycock': 2,
    'mayda': 2,
    'mayday': 2,
    'mayde': 1,
    'mayden': 2,
    'maydena': 3,
    'maye': 1,
    'mayeaux': 2,
    'mayeda': 3,
    'mayer': 2,
    'mayernik': 3,
    'mayers': 2,
    'mayerson': 3,
    'mayes': 1,
    'mayeux': 2,
    'mayfair': 2,
    'mayfield': 2,
    'mayflower': 3,
    'mayhall': 2,
    'mayhan': 2,
    'mayhem': 2,
    'mayher': 2,
    'mayhew': 2,
    'mayhue': 2,
    'mayhugh': 2,
    'mayland': 2,
    'mayle': 1,
    'mayme': 1,
    'maynard': 2,
    'mayne': 1,
    'mayner': 2,
    'maynes': 1,
    'maynor': 2,
    'mayo': 2,
    'mayon': 2,
    'mayonnaise': 3,
    'mayor': 2,
    'mayoral': 3,
    'mayoralty': 4,
    'mayorga': 3,
    'mayors': 2,
    'mayotte': 2,
    'mayr': 1,
    'mays': 1,
    'mayse': 1,
    'mayson': 2,
    'maytag': 2,
    'mayton': 2,
    'mayville': 2,
    'mayweather': 3,
    'maz': 1,
    'maza': 2,
    'mazaitis': 3,
    'mazanec': 3,
    'mazankowski': 4,
    'mazda': 2,
    'maze': 1,
    'mazeika': 3,
    'mazel': 2,
    'mazeltov': 3,
    'mazer': 2,
    'mazes': 2,
    'maziarz': 3,
    'mazie': 2,
    'mazo': 2,
    'mazola': 3,
    'mazon': 2,
    'mazone': 2,
    'mazor': 2,
    'mazowiecki': 5,
    'mazur': 2,
    'mazurek': 3,
    'mazurkas': 3,
    'mazurkiewicz': 4,
    'mazurowski': 4,
    'mazyck': 2,
    'mazza': 2,
    'mazzaferro': 4,
    'mazzanti': 3,
    'mazzara': 3,
    'mazzarella': 4,
    'mazzei': 3,
    'mazzella': 3,
    'mazzeo': 3,
    'mazzetti': 3,
    'mazzie': 2,
    'mazziotti': 4,
    'mazzo': 2,
    'mazzocchi': 3,
    'mazzocco': 3,
    'mazzola': 3,
    'mazzoli': 3,
    'mazzolla': 3,
    'mazzone': 3,
    'mazzoni': 3,
    'mazzorana': 4,
    'mazzotta': 3,
    'mazzuca': 3,
    'mazzucco': 3,
    'mbank': 2,
    'mbeki': 2,
    'mbira': 3,
    'mc': 1,
    'mcabee': 3,
    'mcadam': 3,
    'mcadams': 3,
    'mcadoo': 3,
    'mcadory': 4,
    'mcadow': 3,
    'mcafee': 3,
    'mcaffee': 3,
    'mcafferty': 4,
    'mcaleer': 3,
    'mcaleese': 3,
    'mcalexander': 5,
    'mcalinden': 3,
    'mcalister': 4,
    'mcallen': 3,
    'mcallister': 4,
    'mcaloon': 3,
    'mcalpin': 3,
    'mcalpine': 3,
    'mcamis': 3,
    'mcan': 2,
    'mcanally': 4,
    'mcanany': 4,
    'mcandrew': 3,
    'mcandrews': 3,
    'mcanelly': 4,
    'mcaninch': 3,
    'mcannally': 4,
    'mcanulty': 4,
    'mcardle': 3,
    'mcarthur': 3,
    'mcartor': 3,
    'mcatee': 3,
    'mcateer': 3,
    'mcaulay': 3,
    'mcauley': 3,
    'mcauliff': 3,
    'mcauliffe': 3,
    'mcavity': 4,
    'mcavoy': 3,
    'mcbain': 2,
    'mcbane': 2,
    'mcbay': 2,
    'mcbean': 2,
    'mcbeath': 2,
    'mcbee': 2,
    'mcbeth': 2,
    'mcbirney': 3,
    'mcbrayer': 3,
    'mcbrearty': 3,
    'mcbreen': 2,
    'mcbride': 2,
    'mcbridge': 2,
    'mcbrien': 3,
    'mcbroom': 2,
    'mcbryar': 3,
    'mcbryde': 2,
    'mcburnett': 3,
    'mcburney': 3,
    'mccaa': 2,
    'mccabe': 2,
    'mccadden': 3,
    'mccade': 2,
    'mccafferty': 4,
    'mccaffery': 3,
    'mccaffrey': 3,
    'mccaghren': 3,
    'mccague': 2,
    'mccahan': 2,
    'mccahill': 3,
    'mccaig': 2,
    'mccain': 2,
    'mccaleb': 3,
    'mccalip': 3,
    'mccalister': 4,
    'mccall': 2,
    'mccalla': 3,
    'mccallen': 3,
    'mccalley': 3,
    'mccallie': 3,
    'mccallion': 3,
    'mccallister': 4,
    'mccallon': 3,
    'mccallum': 3,
    'mccalmont': 3,
    'mccamant': 3,
    'mccambridge': 3,
    'mccamey': 3,
    'mccamish': 3,
    'mccammon': 3,
    'mccampbell': 3,
    'mccamy': 3,
    'mccan': 2,
    'mccance': 2,
    'mccandless': 3,
    'mccandlish': 3,
    'mccane': 2,
    'mccanless': 3,
    'mccann': 2,
    'mccanna': 3,
    'mccannon': 3,
    'mccants': 2,
    'mccard': 2,
    'mccardell': 3,
    'mccardle': 3,
    'mccarey': 3,
    'mccargar': 3,
    'mccargo': 3,
    'mccarl': 2,
    'mccarley': 3,
    'mccarn': 2,
    'mccarney': 3,
    'mccarran': 3,
    'mccarrell': 3,
    'mccarren': 3,
    'mccarrick': 3,
    'mccarroll': 3,
    'mccarron': 3,
    'mccarry': 3,
    'mccarson': 3,
    'mccart': 2,
    'mccartan': 3,
    'mccarten': 3,
    'mccarter': 3,
    'mccartha': 3,
    'mccarthy': 3,
    'mccarthyism': 5,
    'mccarthyite': 4,
    'mccartin': 3,
    'mccartney': 3,
    'mccartt': 2,
    'mccarty': 3,
    'mccarver': 3,
    'mccarville': 3,
    'mccary': 3,
    'mccaskey': 3,
    'mccaskill': 3,
    'mccasland': 3,
    'mccaslin': 3,
    'mccaughan': 2,
    'mccaughey': 3,
    'mccaul': 2,
    'mccauley': 3,
    'mccaulley': 3,
    'mccausland': 3,
    'mccauslin': 3,
    'mccauthy': 3,
    'mccaw': 2,
    'mccawley': 3,
    'mccay': 2,
    'mcchesney': 3,
    'mcchristian': 3,
    'mcclafferty': 4,
    'mcclaflin': 3,
    'mcclain': 2,
    'mcclaine': 2,
    'mcclam': 2,
    'mcclanahan': 4,
    'mcclane': 2,
    'mcclaran': 3,
    'mcclard': 2,
    'mcclaren': 3,
    'mcclarnon': 3,
    'mcclarty': 3,
    'mcclary': 3,
    'mcclaskey': 3,
    'mcclatchey': 3,
    'mcclatchy': 3,
    'mcclaugherty': 4,
    'mcclave': 2,
    'mcclay': 2,
    'mccleaf': 2,
    'mcclean': 2,
    'mccleary': 3,
    'mccleave': 2,
    'mccleery': 3,
    'mcclees': 2,
    'mccleese': 2,
    'mcclellan': 3,
    'mcclelland': 3,
    'mcclellen': 3,
    'mcclements': 3,
    'mcclenaghan': 4,
    'mcclenahan': 4,
    'mcclenathan': 4,
    'mcclendon': 3,
    'mcclenny': 3,
    'mccleskey': 3,
    'mcclimans': 3,
    'mcclintic': 3,
    'mcclintick': 3,
    'mcclintock': 3,
    'mcclinton': 3,
    'mcclish': 2,
    'mcclory': 3,
    'mccloskey': 3,
    'mcclosky': 3,
    'mccloud': 2,
    'mccloy': 2,
    'mccluer': 2,
    'mcclune': 2,
    'mccluney': 3,
    'mcclung': 2,
    'mcclure': 2,
    'mcclurg': 2,
    'mcclurkin': 3,
    'mccluskey': 3,
    'mccoig': 2,
    'mccoin': 2,
    'mccole': 2,
    'mccolgan': 3,
    'mccoll': 2,
    'mccollam': 3,
    'mccolley': 3,
    'mccollister': 4,
    'mccolloch': 3,
    'mccollom': 3,
    'mccollough': 3,
    'mccollum': 3,
    'mccolm': 2,
    'mccomas': 3,
    'mccomb': 2,
    'mccomber': 3,
    'mccombie': 3,
    'mccombs': 2,
    'mccommon': 3,
    'mccommons': 3,
    'mccomsey': 3,
    'mcconaghy': 4,
    'mcconaha': 4,
    'mcconahay': 4,
    'mcconahy': 4,
    'mcconathy': 4,
    'mcconaughey': 4,
    'mcconaughy': 4,
    'mccone': 2,
    'mcconico': 4,
    'mcconkey': 3,
    'mcconn': 2,
    'mcconnaughey': 4,
    'mcconnel': 3,
    'mcconnell': 3,
    'mcconnon': 3,
    'mcconville': 3,
    'mccooey': 3,
    'mccook': 2,
    'mccool': 2,
    'mccord': 2,
    'mccorkel': 3,
    'mccorkell': 3,
    'mccorkindale': 4,
    'mccorkle': 3,
    'mccormac': 3,
    'mccormack': 3,
    'mccormick': 3,
    'mccorquodale': 4,
    'mccorry': 3,
    'mccort': 2,
    'mccorvey': 3,
    'mccosh': 2,
    'mccoskey': 3,
    'mccotter': 3,
    'mccoun': 2,
    'mccourt': 2,
    'mccowan': 3,
    'mccowen': 3,
    'mccowin': 3,
    'mccown': 2,
    'mccoy': 2,
    'mccoys': 2,
    'mccracken': 3,
    'mccrackin': 3,
    'mccrady': 3,
    'mccrae': 2,
    'mccraney': 3,
    'mccranie': 3,
    'mccrary': 3,
    'mccravy': 3,
    'mccraw': 2,
    'mccray': 2,
    'mccrea': 2,
    'mccreadie': 3,
    'mccready': 3,
    'mccreary': 3,
    'mccredie': 3,
    'mccree': 2,
    'mccreedy': 3,
    'mccreery': 3,
    'mccreight': 2,
    'mccreless': 3,
    'mccrickard': 3,
    'mccright': 2,
    'mccrillis': 3,
    'mccrimmon': 3,
    'mccrocklin': 3,
    'mccrone': 2,
    'mccrorey': 3,
    'mccrory': 3,
    'mccroskey': 3,
    'mccrossen': 3,
    'mccrudden': 3,
    'mccrum': 2,
    'mccrumb': 2,
    'mccrystal': 3,
    'mccuan': 3,
    'mccubbin': 3,
    'mccubbins': 3,
    'mccue': 2,
    'mccuen': 2,
    'mccuin': 3,
    'mccuistion': 3,
    'mccuiston': 3,
    'mcculla': 3,
    'mccullagh': 3,
    'mccullah': 3,
    'mccullar': 3,
    'mccullars': 3,
    'mccullen': 3,
    'mcculler': 3,
    'mccullers': 3,
    'mcculley': 3,
    'mcculloch': 3,
    'mcculloh': 3,
    'mccullough': 3,
    'mccullum': 3,
    'mccully': 3,
    'mccumber': 3,
    'mccune': 2,
    'mccur': 2,
    'mccurdy': 3,
    'mccurley': 3,
    'mccurry': 3,
    'mccusker': 3,
    'mccutchan': 3,
    'mccutchen': 3,
    'mccutcheon': 3,
    'mccuvey': 3,
    'mcdade': 2,
    'mcdaid': 2,
    'mcdanel': 3,
    'mcdaniel': 3,
    'mcdaniels': 3,
    'mcdannel': 3,
    'mcdaris': 3,
    'mcdavid': 3,
    'mcdavitt': 3,
    'mcdeal': 2,
    'mcdearmon': 3,
    'mcdermid': 3,
    'mcdermitt': 3,
    'mcdermot': 3,
    'mcdermott': 3,
    'mcdevitt': 3,
    'mcdiarmid': 3,
    'mcdill': 2,
    'mcdivett': 3,
    'mcdivitt': 3,
    'mcdole': 2,
    'mcdonagh': 3,
    'mcdonald': 3,
    'mcdonalds': 3,
    'mcdonell': 3,
    'mcdonnel': 3,
    'mcdonnell': 3,
    'mcdonough': 3,
    'mcdorman': 3,
    'mcdougal': 3,
    'mcdougald': 3,
    'mcdougall': 3,
    'mcdougals': 3,
    'mcdougle': 3,
    'mcdow': 2,
    'mcdowall': 3,
    'mcdowell': 3,
    'mcduff': 2,
    'mcduffee': 3,
    'mcduffie': 3,
    'mcduffy': 3,
    'mcdugal': 3,
    'mcdurman': 3,
    'mcdyess': 3,
    'mceachern': 3,
    'mceachin': 3,
    'mcelderry': 4,
    'mceldowney': 4,
    'mcelfresh': 3,
    'mcelhaney': 4,
    'mcelhannon': 4,
    'mcelhany': 4,
    'mcelheney': 4,
    'mcelheny': 4,
    'mcelhiney': 4,
    'mcelhinney': 4,
    'mcelhinny': 4,
    'mcelhone': 3,
    'mcelligott': 4,
    'mcelmurray': 4,
    'mcelmurry': 4,
    'mcelrath': 3,
    'mcelravy': 4,
    'mcelreath': 3,
    'mcelroy': 3,
    'mcelvain': 3,
    'mcelvaine': 3,
    'mcelveen': 3,
    'mcelwain': 3,
    'mcelwaine': 3,
    'mcelwee': 3,
    'mcelyea': 3,
    'mcenaney': 4,
    'mcenany': 4,
    'mcendree': 3,
    'mcenerney': 4,
    'mcenery': 4,
    'mcenroe': 3,
    'mcentee': 3,
    'mcentire': 3,
    'mcentyre': 3,
    'mcerlean': 3,
    'mceuen': 3,
    'mcever': 3,
    'mcevers': 3,
    'mcevilly': 4,
    'mcevoy': 3,
    'mcewan': 3,
    'mcewen': 3,
    'mcfadden': 3,
    'mcfaddin': 3,
    'mcfadin': 3,
    'mcfadyen': 4,
    'mcfall': 2,
    'mcfalland': 3,
    'mcfalls': 2,
    'mcfann': 2,
    'mcfarlan': 3,
    'mcfarland': 3,
    'mcfarlane': 3,
    'mcfarlin': 3,
    'mcfarling': 3,
    'mcfarren': 3,
    'mcfate': 2,
    'mcfatridge': 3,
    'mcfatter': 3,
    'mcfaul': 2,
    'mcfayden': 3,
    'mcfee': 2,
    'mcfeely': 3,
    'mcfeeters': 3,
    'mcferran': 3,
    'mcferren': 3,
    'mcferrin': 3,
    'mcferron': 3,
    'mcfetridge': 3,
    'mcfly': 2,
    'mcfun': 2,
    'mcgaffey': 3,
    'mcgagh': 2,
    'mcgaha': 3,
    'mcgahan': 3,
    'mcgahee': 3,
    'mcgahey': 3,
    'mcgalley': 3,
    'mcgalliard': 4,
    'mcgann': 2,
    'mcgannon': 3,
    'mcgarity': 4,
    'mcgarr': 2,
    'mcgarrah': 3,
    'mcgarrigle': 4,
    'mcgarrity': 4,
    'mcgarry': 3,
    'mcgarvey': 3,
    'mcgary': 3,
    'mcgath': 2,
    'mcgaugh': 2,
    'mcgaughey': 3,
    'mcgaughy': 3,
    'mcgauley': 3,
    'mcgavin': 3,
    'mcgavock': 3,
    'mcgaw': 2,
    'mcgeachy': 3,
    'mcgeary': 3,
    'mcgee': 2,
    'mcgeean': 3,
    'mcgeehan': 3,
    'mcgeever': 3,
    'mcgegan': 3,
    'mcgehee': 3,
    'mcgeorge': 2,
    'mcgeough': 2,
    'mcgettigan': 4,
    'mcghee': 2,
    'mcghie': 2,
    'mcgibbon': 3,
    'mcgill': 2,
    'mcgillen': 3,
    'mcgillicuddy': 5,
    'mcgillis': 3,
    'mcgillivray': 4,
    'mcgilton': 3,
    'mcgilvery': 4,
    'mcgilvray': 3,
    'mcginess': 3,
    'mcginley': 3,
    'mcginn': 2,
    'mcginnes': 2,
    'mcginness': 3,
    'mcginnis': 3,
    'mcginniss': 3,
    'mcginnity': 4,
    'mcginty': 3,
    'mcgirr': 2,
    'mcgirt': 2,
    'mcgivern': 3,
    'mcgivney': 3,
    'mcglade': 2,
    'mcglamery': 4,
    'mcglashan': 3,
    'mcglasson': 3,
    'mcglaughlin': 3,
    'mcglaun': 2,
    'mcglinchey': 3,
    'mcglinn': 2,
    'mcglocklin': 3,
    'mcgloin': 2,
    'mcglone': 2,
    'mcglory': 3,
    'mcglothen': 3,
    'mcglothin': 3,
    'mcglothlin': 3,
    'mcglynn': 2,
    'mcgoey': 3,
    'mcgoff': 2,
    'mcgoldrick': 3,
    'mcgols': 2,
    'mcgonagle': 4,
    'mcgonigal': 4,
    'mcgonigle': 4,
    'mcgough': 2,
    'mcgourty': 3,
    'mcgovern': 3,
    'mcgowan': 3,
    'mcgowen': 3,
    'mcgowin': 3,
    'mcgown': 2,
    'mcgrady': 3,
    'mcgrail': 2,
    'mcgrain': 2,
    'mcgranahan': 4,
    'mcgrane': 2,
    'mcgrath': 2,
    'mcgraw': 2,
    'mcgray': 2,
    'mcgreal': 2,
    'mcgreevey': 3,
    'mcgreevy': 3,
    'mcgregor': 3,
    'mcgregory': 4,
    'mcgrevin': 3,
    'mcgrew': 2,
    'mcgriff': 2,
    'mcgroarty': 3,
    'mcgrogan': 3,
    'mcgrory': 3,
    'mcgruder': 3,
    'mcguane': 2,
    'mcguckin': 3,
    'mcgue': 2,
    'mcguffee': 3,
    'mcguffey': 3,
    'mcguffie': 3,
    'mcguffin': 3,
    'mcguigan': 3,
    'mcguiness': 3,
    'mcguinn': 2,
    'mcguinness': 3,
    'mcguire': 2,
    'mcguirk': 2,
    'mcguirt': 2,
    'mcgurk': 2,
    'mcgurn': 2,
    'mcguyer': 3,
    'mcgwire': 2,
    'mchaffie': 3,
    'mchale': 2,
    'mcham': 2,
    'mchan': 2,
    'mchaney': 3,
    'mchargue': 2,
    'mchatton': 3,
    'mchenry': 3,
    'mchone': 2,
    'mchugh': 2,
    'mcilhenny': 4,
    'mcilrath': 3,
    'mcilroy': 3,
    'mcilvain': 3,
    'mcilvaine': 3,
    'mcilveen': 3,
    'mcilwain': 3,
    'mcinerney': 4,
    'mcinerny': 4,
    'mcingvale': 3,
    'mcinnes': 3,
    'mcinnis': 3,
    'mcinroy': 3,
    'mcintee': 3,
    'mcintire': 3,
    'mcintosh': 3,
    'mcinturf': 3,
    'mcinturff': 3,
    'mcintyre': 3,
    'mcinvale': 3,
    'mcisaac': 3,
    'mciver': 3,
    'mcivor': 3,
    'mcjunkin': 3,
    'mcjunkins': 3,
    'mckaig': 2,
    'mckain': 2,
    'mckamey': 3,
    'mckane': 2,
    'mckanie': 3,
    'mckanna': 3,
    'mckarrick': 3,
    'mckay': 2,
    'mckeag': 2,
    'mckeague': 2,
    'mckean': 2,
    'mckeand': 2,
    'mckechnie': 3,
    'mckee': 2,
    'mckeegan': 3,
    'mckeehan': 3,
    'mckeel': 2,
    'mckeeman': 3,
    'mckeen': 2,
    'mckeesport': 3,
    'mckeever': 3,
    'mckeithan': 3,
    'mckeithen': 3,
    'mckell': 2,
    'mckellan': 3,
    'mckellar': 3,
    'mckeller': 3,
    'mckellips': 3,
    'mckelvey': 3,
    'mckelvie': 3,
    'mckelvy': 3,
    'mckemie': 3,
    'mckendree': 3,
    'mckendrick': 3,
    'mckendry': 3,
    'mckenna': 3,
    'mckenney': 3,
    'mckennon': 3,
    'mckenny': 3,
    'mckenrick': 3,
    'mckenzie': 3,
    'mckeon': 3,
    'mckeone': 3,
    'mckeough': 3,
    'mckeown': 3,
    'mckercher': 3,
    'mckern': 2,
    'mckernan': 3,
    'mckesson': 3,
    'mckethan': 3,
    'mckevitt': 3,
    'mckey': 2,
    'mckibben': 3,
    'mckibbin': 3,
    'mckibbon': 3,
    'mckids': 2,
    'mckie': 2,
    'mckiernan': 3,
    'mckillip': 3,
    'mckillop': 3,
    'mckim': 2,
    'mckimmey': 3,
    'mckimmy': 3,
    'mckiness': 3,
    'mckinlay': 3,
    'mckinley': 3,
    'mckinney': 3,
    'mckinnie': 3,
    'mckinnis': 3,
    'mckinnon': 3,
    'mckinny': 3,
    'mckinsey': 3,
    'mckinstry': 3,
    'mckinzie': 3,
    'mckissack': 3,
    'mckissic': 3,
    'mckissick': 3,
    'mckitrick': 3,
    'mckittrick': 3,
    'mcklatchy': 3,
    'mckneely': 3,
    'mcknew': 2,
    'mcknight': 2,
    'mckone': 2,
    'mckowen': 3,
    'mckown': 2,
    'mckoy': 2,
    'mckree': 2,
    'mckrinkowski': 4,
    'mckune': 2,
    'mclachlan': 3,
    'mclafferty': 4,
    'mclain': 2,
    'mclamb': 2,
    'mclanahan': 4,
    'mclane': 2,
    'mclaren': 3,
    'mclarney': 3,
    'mclarty': 3,
    'mclauchlin': 3,
    'mclaughlin': 3,
    'mclaurin': 3,
    'mclaury': 3,
    'mclawhorn': 3,
    'mclay': 2,
    'mclean': 2,
    'mclear': 2,
    'mcleary': 3,
    'mclees': 2,
    'mcleish': 2,
    'mcleland': 3,
    'mclellan': 3,
    'mclelland': 3,
    'mclemore': 3,
    'mclendon': 3,
    'mclennan': 3,
    'mcleod': 2,
    'mcleroy': 3,
    'mclerran': 3,
    'mclester': 3,
    'mclin': 2,
    'mclinden': 3,
    'mclinn': 2,
    'mclish': 2,
    'mcloud': 2,
    'mclouth': 2,
    'mclucas': 3,
    'mcluckie': 3,
    'mcluhan': 3,
    'mclure': 2,
    'mcmackin': 3,
    'mcmahan': 3,
    'mcmahen': 3,
    'mcmahill': 3,
    'mcmahon': 3,
    'mcmains': 2,
    'mcmaken': 3,
    'mcmakin': 3,
    'mcmanama': 4,
    'mcmanaman': 4,
    'mcmanamon': 4,
    'mcmanaway': 4,
    'mcmanigal': 4,
    'mcmanis': 3,
    'mcmann': 2,
    'mcmannis': 3,
    'mcmanus': 3,
    'mcmartin': 3,
    'mcmaster': 3,
    'mcmasters': 3,
    'mcmath': 2,
    'mcmeans': 2,
    'mcmeekin': 3,
    'mcmeen': 2,
    'mcmenamin': 4,
    'mcmenamy': 4,
    'mcmenemy': 4,
    'mcmennamin': 4,
    'mcmichael': 3,
    'mcmichen': 3,
    'mcmickle': 3,
    'mcmil': 2,
    'mcmillan': 3,
    'mcmillen': 3,
    'mcmiller': 3,
    'mcmillian': 3,
    'mcmillin': 3,
    'mcmillion': 3,
    'mcmillon': 3,
    'mcminn': 2,
    'mcmonagle': 4,
    'mcmonigle': 4,
    'mcmoran': 3,
    'mcmorran': 3,
    'mcmorris': 3,
    'mcmorrow': 3,
    'mcmuffin': 3,
    'mcmullan': 3,
    'mcmullen': 3,
    'mcmullin': 3,
    'mcmunn': 2,
    'mcmurdo': 3,
    'mcmurphy': 3,
    'mcmurray': 3,
    'mcmurrey': 3,
    'mcmurry': 3,
    'mcmurtrey': 3,
    'mcmurtrie': 4,
    'mcmurtry': 3,
    'mcnab': 2,
    'mcnabb': 2,
    'mcnair': 2,
    'mcnairy': 3,
    'mcnall': 2,
    'mcnalley': 3,
    'mcnally': 3,
    'mcnamara': 4,
    'mcnamee': 3,
    'mcnamer': 3,
    'mcnaney': 3,
    'mcnary': 3,
    'mcnatt': 2,
    'mcnaught': 2,
    'mcnaughton': 3,
    'mcnay': 2,
    'mcneal': 2,
    'mcneally': 3,
    'mcnealy': 3,
    'mcnear': 2,
    'mcneary': 3,
    'mcnease': 2,
    'mcnee': 2,
    'mcneece': 2,
    'mcneel': 2,
    'mcneeley': 3,
    'mcneely': 3,
    'mcneer': 2,
    'mcnees': 2,
    'mcneese': 2,
    'mcneff': 2,
    'mcneice': 2,
    'mcneil': 2,
    'mcneill': 2,
    'mcneilly': 3,
    'mcneish': 2,
    'mcnelis': 3,
    'mcnellis': 3,
    'mcnelly': 3,
    'mcnemar': 3,
    'mcnerney': 3,
    'mcnett': 2,
    'mcnevin': 3,
    'mcnew': 2,
    'mcnichol': 3,
    'mcnicholas': 4,
    'mcnichols': 3,
    'mcnickle': 3,
    'mcnicol': 3,
    'mcniel': 2,
    'mcniff': 2,
    'mcninch': 2,
    'mcnish': 2,
    'mcnitt': 2,
    'mcnorton': 3,
    'mcnuggets': 3,
    'mcnulty': 3,
    'mcnutt': 2,
    'mcomber': 3,
    'mcorp': 2,
    'mcpaper': 3,
    'mcparland': 3,
    'mcpartland': 3,
    'mcpartlin': 3,
    'mcpeak': 2,
    'mcpeake': 2,
    'mcpeck': 2,
    'mcpeek': 2,
    'mcpeters': 3,
    'mcphail': 2,
    'mcphatter': 3,
    'mcphaul': 2,
    'mcphearson': 3,
    'mcphee': 2,
    'mcpheeters': 3,
    'mcpheron': 3,
    'mcpherson': 3,
    'mcphie': 2,
    'mcphillips': 3,
    'mcpike': 2,
    'mcquade': 2,
    'mcquaid': 2,
    'mcquaide': 2,
    'mcquaig': 2,
    'mcquain': 2,
    'mcquarrie': 3,
    'mcquary': 3,
    'mcquay': 2,
    'mcqueary': 3,
    'mcqueen': 2,
    'mcqueeney': 3,
    'mcquerry': 3,
    'mcquethy': 3,
    'mcquigg': 2,
    'mcquilkin': 3,
    'mcquillan': 3,
    'mcquillen': 3,
    'mcquillin': 3,
    'mcquinn': 2,
    'mcquire': 2,
    'mcquiston': 3,
    'mcquitty': 3,
    'mcquown': 2,
    'mcrae': 2,
    'mcrainey': 3,
    'mcraney': 3,
    'mcray': 2,
    'mcree': 2,
    'mcreynolds': 3,
    'mcright': 2,
    'mcroberts': 3,
    'mcrorie': 3,
    'mcroy': 2,
    'mcshan': 2,
    'mcshane': 2,
    'mcshea': 2,
    'mcsherry': 3,
    'mcsleep': 2,
    'mcsorley': 3,
    'mcspadden': 3,
    'mcstay': 2,
    'mcswain': 2,
    'mcsween': 2,
    'mcsweeney': 3,
    'mctaggart': 3,
    'mctague': 2,
    'mctavish': 3,
    'mcteer': 2,
    'mcternan': 3,
    'mctier': 3,
    'mctiernan': 3,
    'mctighe': 2,
    'mctigue': 2,
    'mcvay': 2,
    'mcvea': 2,
    'mcveigh': 2,
    'mcvey': 2,
    'mcvicar': 3,
    'mcvicker': 3,
    'mcvoy': 2,
    'mcwain': 2,
    'mcwaters': 3,
    'mcwatters': 3,
    'mcweeney': 3,
    'mcwethy': 3,
    'mcwherter': 3,
    'mcwhinney': 3,
    'mcwhirt': 2,
    'mcwhirter': 3,
    'mcwhite': 2,
    'mcwhorter': 3,
    'mcwilliam': 3,
    'mcwilliams': 3,
    'mcwright': 2,
    'mczeal': 2,
    'me': 1,
    'mea': 1,
    'meacham': 2,
    'meachum': 2,
    'mead': 1,
    'meade': 1,
    'meader': 2,
    'meaders': 2,
    'meador': 2,
    'meadors': 2,
    'meadow': 2,
    'meadowland': 3,
    'meadowlands': 3,
    'meadowlark': 3,
    'meadows': 2,
    'meads': 1,
    'meager': 2,
    'meagher': 1,
    'meaker': 2,
    'meakin': 2,
    'meal': 1,
    'mealer': 2,
    'mealey': 2,
    'mealing': 2,
    'mealo': 3,
    'mealor': 2,
    'meals': 1,
    'mealtime': 2,
    'mealy': 2,
    'mealynose': 3,
    'mealynosed': 3,
    'mean': 1,
    'meander': 3,
    'meandered': 3,
    'meandering': 4,
    'meanders': 3,
    'meaner': 2,
    'meanest': 2,
    'meaney': 2,
    'meaning': 2,
    'meaningful': 3,
    'meaningfully': 4,
    'meaningless': 3,
    'meanings': 2,
    'meanness': 2,
    'meanor': 2,
    'means': 1,
    'meant': 1,
    'meantime': 2,
    'meanwhile': 2,
    'meany': 2,
    'mear': 1,
    'meara': 2,
    'meares': 1,
    'mearns': 1,
    'mears': 1,
    'mease': 1,
    'measel': 2,
    'measles': 2,
    'measly': 2,
    'measurable': 4,
    'measurably': 4,
    'measure': 2,
    'measured': 2,
    'measurement': 3,
    'measurements': 3,
    'measures': 2,
    'measurex': 3,
    'measuring': 3,
    'meat': 1,
    'meatball': 2,
    'meatballs': 2,
    'meath': 1,
    'meatier': 3,
    'meatless': 2,
    'meatloaf': 2,
    'meatpacker': 3,
    'meatpackers': 3,
    'meatpacking': 3,
    'meats': 1,
    'meaty': 2,
    'meaux': 1,
    'meave': 1,
    'mebane': 2,
    'meca': 2,
    'mecca': 2,
    'mech': 1,
    'mecham': 2,
    'mechanic': 3,
    'mechanical': 4,
    'mechanically': 4,
    'mechanics': 3,
    'mechanicsburg': 4,
    'mechanism': 4,
    'mechanisms': 4,
    'mechanistic': 4,
    'mechanization': 5,
    'mechanize': 3,
    'mechanized': 3,
    'meche': 1,
    'mechem': 2,
    'mechler': 2,
    'mechling': 2,
    'meciar': 3,
    'meck': 1,
    'meckel': 2,
    'meckes': 1,
    'mecklenburg': 3,
    'meckler': 2,
    'meckley': 2,
    'meckstroth': 2,
    'mecum': 2,
    'med': 1,
    'meda': 2,
    'medaglia': 4,
    'medal': 2,
    'medalist': 3,
    'medalists': 3,
    'medallion': 3,
    'medallions': 3,
    'medals': 2,
    'medaphis': 3,
    'medar': 2,
    'medarex': 3,
    'medaris': 3,
    'medcalf': 2,
    'medchem': 2,
    'medco': 2,
    'meddaugh': 2,
    'medders': 2,
    'meddle': 2,
    'meddlesome': 3,
    'meddling': 3,
    'medea': 3,
    'medearis': 3,
    'medecin': 3,
    'medecins': 3,
    'medeiros': 3,
    'medel': 2,
    'medellin': 3,
    'medema': 3,
    'medendorp': 3,
    'meder': 2,
    'mederos': 3,
    'medes': 1,
    'medeva': 3,
    'medex': 2,
    'medfact': 2,
    'medfacts': 2,
    'medfirst': 2,
    'medfly': 2,
    'medford': 2,
    'medgar': 2,
    'medi': 2,
    'media': 3,
    'medial': 3,
    'mediamark': 4,
    'median': 3,
    'medianews': 4,
    'mediaset': 4,
    'mediate': 3,
    'mediated': 4,
    'mediating': 4,
    'mediation': 4,
    'mediator': 4,
    'mediators': 4,
    'medic': 2,
    'medicaid': 3,
    'medical': 3,
    'medically': 3,
    'medicare': 3,
    'medicate': 3,
    'medicated': 4,
    'medicates': 3,
    'medicating': 4,
    'medication': 4,
    'medications': 4,
    'medici': 3,
    'medicinal': 4,
    'medicinally': 5,
    'medicine': 3,
    'medicines': 3,
    'medico': 3,
    'medics': 2,
    'medicus': 3,
    'medieval': 3,
    'medigap': 3,
    'medil': 2,
    'medimmune': 3,
    'medin': 2,
    'medina': 3,
    'medinger': 3,
    'medio': 3,
    'mediobanca': 5,
    'mediocre': 4,
    'mediocrity': 5,
    'mediplex': 3,
    'mediq': 2,
    'mediscare': 3,
    'medisgroup': 3,
    'medisgroups': 3,
    'meditate': 3,
    'meditating': 4,
    'meditation': 4,
    'meditations': 4,
    'meditative': 4,
    'mediterranean': 6,
    'meditrust': 3,
    'meditz': 2,
    'medium': 3,
    'mediums': 3,
    'medivac': 3,
    'medivaced': 3,
    'medland': 2,
    'medlar': 2,
    'medlen': 3,
    'medler': 2,
    'medley': 2,
    'medlin': 2,
    'medlock': 2,
    'mednick': 2,
    'medoff': 2,
    'medora': 3,
    'medrano': 3,
    'medserv': 2,
    'medsker': 2,
    'medstone': 2,
    'medtronic': 3,
    'medulla': 3,
    'medusa': 3,
    'medusas': 3,
    'medved': 2,
    'medvedev': 3,
    'medwin': 2,
    'mee': 1,
    'meece': 1,
    'meech': 1,
    'meecham': 2,
    'meeder': 2,
    'meegan': 2,
    'meehan': 2,
    'meehans': 2,
    'meehl': 1,
    'meek': 1,
    'meeker': 2,
    'meekins': 2,
    'meekly': 2,
    'meeks': 1,
    'meeler': 2,
    'meenaghan': 3,
    'meenan': 2,
    'meents': 1,
    'meer': 2,
    'meers': 2,
    'meert': 1,
    'mees': 1,
    'meese': 1,
    'meester': 2,
    'meet': 1,
    'meeting': 2,
    'meetings': 2,
    'meets': 1,
    'meetze': 1,
    'meeuwsen': 3,
    'mefferd': 2,
    'meffert': 2,
    'mefford': 2,
    'meg': 1,
    'mega': 2,
    'megabit': 3,
    'megabuck': 3,
    'megabucks': 3,
    'megabyte': 3,
    'megabytes': 3,
    'megacarrier': 4,
    'megacarriers': 4,
    'megace': 2,
    'megadeal': 3,
    'megadeals': 3,
    'megadeath': 3,
    'megafood': 3,
    'megafoods': 3,
    'megahertz': 3,
    'megahouse': 3,
    'megahouses': 4,
    'megalomania': 6,
    'megalomaniac': 6,
    'megalopolis': 5,
    'megamerger': 4,
    'megamergers': 4,
    'megan': 2,
    'megaphone': 3,
    'megaphones': 3,
    'megaplex': 3,
    'megaquest': 3,
    'megarry': 3,
    'megastore': 3,
    'megastores': 3,
    'megatons': 3,
    'megawatt': 3,
    'megawatts': 3,
    'megee': 2,
    'meger': 2,
    'megginson': 3,
    'meggison': 3,
    'meggs': 1,
    'meghan': 2,
    'meghdar': 2,
    'megill': 2,
    'megna': 2,
    'megner': 2,
    'mehaffey': 3,
    'mehalkoff': 3,
    'mehall': 2,
    'mehan': 2,
    'mehanovitch': 4,
    'meharg': 2,
    'meharry': 3,
    'mehdi': 2,
    'mehetabel': 4,
    'mehitabel': 4,
    'mehitabelle': 4,
    'mehl': 1,
    'mehlberg': 2,
    'mehle': 2,
    'mehlenbacher': 4,
    'mehler': 2,
    'mehlhaff': 2,
    'mehlhoff': 2,
    'mehlhorn': 2,
    'mehling': 2,
    'mehlman': 2,
    'mehmet': 2,
    'mehner': 2,
    'mehnert': 2,
    'mehr': 1,
    'mehrabian': 4,
    'mehran': 2,
    'mehrens': 2,
    'mehrer': 2,
    'mehring': 2,
    'mehringer': 3,
    'mehrtens': 2,
    'mehta': 2,
    'mehul': 2,
    'mei': 1,
    'meidinger': 3,
    'meidl': 2,
    'meier': 2,
    'meierfeld': 2,
    'meighan': 2,
    'meigher': 2,
    'meiji': 2,
    'meikle': 2,
    'mein': 1,
    'meincke': 2,
    'meinders': 2,
    'meindl': 2,
    'meine': 1,
    'meinecke': 3,
    'meineke': 3,
    'meinen': 2,
    'meiner': 2,
    'meiners': 2,
    'meinert': 2,
    'meinertzhagen': 4,
    'meinhardt': 2,
    'meinhart': 2,
    'meinhold': 2,
    'meininger': 3,
    'meinke': 1,
    'meints': 1,
    'meinzer': 2,
    'meiosis': 3,
    'meir': 1,
    'meiring': 2,
    'meis': 1,
    'meisch': 1,
    'meise': 1,
    'meisel': 2,
    'meisels': 2,
    'meisenheimer': 4,
    'meiser': 2,
    'meishan': 2,
    'meisinger': 3,
    'meisler': 3,
    'meisner': 2,
    'meiss': 1,
    'meissner': 2,
    'meister': 2,
    'meisters': 2,
    'meitz': 1,
    'meitzler': 2,
    'meixner': 2,
    'meiyuh': 2,
    'mejia': 3,
    'mejias': 3,
    'mekeel': 2,
    'mekong': 2,
    'mel': 1,
    'melador': 3,
    'melamed': 3,
    'melamine': 3,
    'melanby': 3,
    'melancholic': 4,
    'melancholy': 4,
    'melancon': 3,
    'meland': 2,
    'melander': 3,
    'melanesian': 4,
    'melanesians': 4,
    'melange': 2,
    'melanie': 3,
    'melanin': 3,
    'melanoma': 4,
    'melanson': 3,
    'melantha': 3,
    'melany': 3,
    'melaragno': 4,
    'melatonin': 4,
    'melatonins': 4,
    'melba': 2,
    'melberg': 2,
    'melbourne': 2,
    'melburn': 2,
    'melby': 2,
    'melcher': 2,
    'melchert': 2,
    'melching': 2,
    'melchior': 3,
    'melchiorre': 4,
    'melchor': 2,
    'meld': 1,
    'melded': 2,
    'melder': 2,
    'melding': 2,
    'meldon': 2,
    'meldons': 2,
    'meldrum': 2,
    'mele': 1,
    'melear': 2,
    'melee': 2,
    'meleis': 3,
    'melendez': 3,
    'melendrez': 3,
    'melendy': 3,
    'melero': 3,
    'meleski': 3,
    'melessa': 3,
    'melfi': 2,
    'melgaard': 2,
    'melgar': 2,
    'melgoza': 3,
    'melhem': 2,
    'melhorn': 2,
    'meli': 2,
    'melia': 3,
    'melican': 3,
    'melicent': 3,
    'melichar': 3,
    'melick': 2,
    'melikian': 4,
    'melillo': 3,
    'melin': 2,
    'melina': 3,
    'melinda': 3,
    'meline': 2,
    'meling': 2,
    'melisent': 3,
    'melissa': 3,
    'melisse': 2,
    'melita': 3,
    'melito': 3,
    'melitta': 3,
    'melius': 3,
    'melka': 2,
    'melkar': 2,
    'melkonian': 4,
    'mell': 1,
    'mella': 2,
    'melland': 2,
    'mellaril': 3,
    'melle': 1,
    'mellem': 2,
    'mellema': 3,
    'mellen': 2,
    'meller': 2,
    'mellett': 2,
    'melley': 2,
    'mellgren': 2,
    'melli': 2,
    'mellicent': 3,
    'mellick': 2,
    'mellie': 2,
    'mellin': 2,
    'melling': 2,
    'mellinger': 3,
    'mellis': 2,
    'mellish': 2,
    'mellman': 2,
    'mello': 2,
    'melloan': 2,
    'mellon': 2,
    'mellonby': 3,
    'mellons': 2,
    'mellor': 2,
    'mellott': 2,
    'mellow': 2,
    'mellowed': 2,
    'mellowing': 3,
    'melly': 2,
    'melman': 2,
    'melnick': 2,
    'melnik': 2,
    'melnor': 2,
    'melnyk': 2,
    'melo': 2,
    'meloche': 3,
    'melodic': 3,
    'melodies': 3,
    'melodious': 4,
    'melodrama': 4,
    'melodramas': 4,
    'melodramatic': 5,
    'melody': 3,
    'melon': 2,
    'melone': 3,
    'meloni': 3,
    'melons': 2,
    'melor': 2,
    'meloy': 2,
    'melquist': 2,
    'melridge': 2,
    'melrod': 2,
    'melrose': 2,
    'melroy': 2,
    'melson': 2,
    'melt': 1,
    'meltdown': 2,
    'melted': 2,
    'melting': 2,
    'melton': 2,
    'melts': 1,
    'meltwater': 3,
    'meltz': 1,
    'meltzer': 2,
    'melucci': 3,
    'melugin': 3,
    'melva': 2,
    'melvie': 2,
    'melville': 2,
    'melvin': 2,
    'melvina': 3,
    'melvine': 2,
    'melvyn': 2,
    'melwood': 2,
    'melzer': 2,
    'member': 2,
    'membered': 2,
    'members': 2,
    'membership': 3,
    'memberships': 3,
    'membrane': 2,
    'membranes': 2,
    'membranous': 3,
    'memel': 2,
    'memento': 3,
    'mementos': 3,
    'memmer': 2,
    'memmott': 2,
    'memnon': 2,
    'memo': 2,
    'memoir': 2,
    'memoirs': 2,
    'memoli': 3,
    'memorabilia': 5,
    'memorable': 4,
    'memorably': 4,
    'memoranda': 4,
    'memorandum': 4,
    'memorandums': 4,
    'memorex': 3,
    'memorial': 4,
    'memorialize': 5,
    'memorialized': 5,
    'memorials': 4,
    'memories': 3,
    'memorize': 3,
    'memorized': 3,
    'memorizes': 4,
    'memorizing': 4,
    'memory': 3,
    'memos': 2,
    'memotec': 3,
    'memphis': 2,
    'memtec': 2,
    'men': 1,
    'mena': 2,
    'menace': 2,
    'menaced': 2,
    'menaces': 3,
    'menachem': 3,
    'menacing': 3,
    'menacingly': 4,
    'menagerie': 4,
    'menahem': 3,
    'menaker': 3,
    'menapace': 4,
    'menard': 2,
    'menasco': 3,
    'menasion': 4,
    'menatep': 3,
    'mencer': 2,
    'mench': 1,
    'menchaca': 3,
    'mencher': 2,
    'mencken': 2,
    'menconi': 3,
    'mend': 1,
    'mendacious': 3,
    'mendacity': 4,
    'mendan': 2,
    'mende': 1,
    'mended': 2,
    'mendel': 2,
    'mendell': 2,
    'mendelsohn': 3,
    'mendelson': 3,
    'mendelssohn': 3,
    'mendenhall': 3,
    'mendes': 2,
    'mendez': 2,
    'mendicino': 4,
    'mendieta': 3,
    'mendillo': 3,
    'mending': 2,
    'mendiola': 4,
    'mendivil': 3,
    'mendlowitz': 3,
    'mendocino': 4,
    'mendola': 3,
    'mendolia': 4,
    'mendonca': 3,
    'mendonsa': 3,
    'mendosa': 3,
    'mendota': 3,
    'mendoza': 3,
    'mendyk': 2,
    'menear': 2,
    'meneely': 3,
    'menees': 2,
    'menefee': 3,
    'menem': 2,
    'menendez': 3,
    'meneses': 3,
    'menezes': 3,
    'meng': 1,
    'menge': 1,
    'mengel': 2,
    'mengele': 3,
    'menger': 2,
    'mengers': 2,
    'menges': 2,
    'menghini': 3,
    'mengistu': 3,
    'menhaden': 3,
    'menia': 2,
    'menial': 3,
    'menifee': 3,
    'menil': 2,
    'meningitis': 4,
    'menino': 3,
    'menjivar': 3,
    'menk': 1,
    'menka': 2,
    'menke': 1,
    'menken': 2,
    'menkes': 1,
    'menlo': 2,
    'menn': 1,
    'menna': 2,
    'mennan': 2,
    'menne': 1,
    'mennella': 3,
    'mennen': 2,
    'mennenga': 3,
    'menning': 2,
    'menninger': 3,
    'mennini': 3,
    'mennonite': 3,
    'mennonites': 3,
    'meno': 2,
    'menon': 2,
    'menopausal': 4,
    'menopause': 3,
    'menor': 2,
    'menorah': 3,
    'menorahs': 3,
    'menotomy': 4,
    'menoyo': 3,
    'mens': 1,
    'mensah': 2,
    'mensch': 1,
    'menschville': 2,
    'menser': 2,
    'mensik': 2,
    'mensing': 2,
    'mensinger': 3,
    'menstrual': 3,
    'menstruation': 4,
    'menswear': 2,
    'mental': 2,
    'mentality': 4,
    'mentally': 3,
    'mente': 1,
    'mentel': 2,
    'menter': 2,
    'menthol': 2,
    'mentholatum': 4,
    'mentink': 2,
    'mention': 2,
    'mentioned': 2,
    'mentioning': 3,
    'mentions': 2,
    'mento': 2,
    'menton': 2,
    'mentor': 2,
    'mentored': 2,
    'mentoring': 3,
    'mentors': 2,
    'mentz': 1,
    'mentzel': 2,
    'mentzer': 2,
    'menu': 2,
    'menudo': 3,
    'menuhin': 3,
    'menus': 2,
    'menz': 1,
    'menze': 1,
    'menzel': 2,
    'menzer': 2,
    'menzie': 2,
    'menzies': 2,
    'menzione': 4,
    'meo': 2,
    'meola': 3,
    'meow': 2,
    'mequon': 2,
    'mer': 1,
    'merabank': 3,
    'meranda': 3,
    'meraz': 2,
    'merc': 1,
    'mercadante': 4,
    'mercado': 3,
    'mercantil': 3,
    'mercantile': 3,
    'mercantilism': 5,
    'mercantilist': 4,
    'mercator': 3,
    'merce': 1,
    'merced': 1,
    'mercedes': 3,
    'mercedeses': 4,
    'mercenaries': 4,
    'mercenary': 4,
    'mercer': 2,
    'merchandise': 3,
    'merchandiser': 4,
    'merchandisers': 4,
    'merchandising': 4,
    'merchant': 2,
    'merchantmen': 3,
    'merchants': 2,
    'merchantsbank': 3,
    'mercier': 3,
    'mercies': 2,
    'merciful': 3,
    'mercifully': 4,
    'merciless': 3,
    'mercilessly': 4,
    'merck': 1,
    'mercland': 2,
    'mercosur': 3,
    'mercure': 3,
    'mercuri': 3,
    'mercurial': 4,
    'mercuric': 3,
    'mercurio': 4,
    'mercury': 3,
    'mercy': 2,
    'merdyce': 2,
    'mere': 1,
    'meredith': 3,
    'merely': 2,
    'merenda': 3,
    'merendino': 4,
    'merengue': 2,
    'merest': 2,
    'meretz': 2,
    'merfeld': 2,
    'merge': 1,
    'merged': 1,
    'mergen': 2,
    'merger': 2,
    'mergers': 2,
    'merges': 2,
    'merging': 2,
    'merhige': 2,
    'meriam': 3,
    'merica': 3,
    'mericantante': 5,
    'mericle': 3,
    'mericopa': 4,
    'merida': 3,
    'meriden': 3,
    'merideth': 3,
    'meridian': 4,
    'meridien': 4,
    'meridionale': 6,
    'meridith': 3,
    'meridor': 3,
    'meriel': 2,
    'merieux': 3,
    'merillat': 3,
    'merino': 3,
    'merion': 3,
    'meris': 2,
    'merisel': 3,
    'merit': 2,
    'merited': 3,
    'merithew': 3,
    'meriting': 3,
    'meritless': 3,
    'meritocracy': 5,
    'meritor': 3,
    'meritorious': 5,
    'merits': 2,
    'meritt': 2,
    'meriweather': 4,
    'meriwether': 4,
    'merk': 1,
    'merkel': 2,
    'merker': 2,
    'merkey': 2,
    'merkich': 2,
    'merkin': 2,
    'merklan': 2,
    'merkle': 2,
    'merkley': 2,
    'merklin': 2,
    'merksamer': 3,
    'merkt': 1,
    'merkur': 2,
    'merl': 1,
    'merle': 1,
    'merletti': 3,
    'merlin': 2,
    'merlina': 3,
    'merline': 2,
    'merlino': 3,
    'merlins': 2,
    'merlis': 2,
    'merlo': 2,
    'merlot': 2,
    'merlots': 2,
    'mermaid': 2,
    'mermaids': 2,
    'merman': 2,
    'mermelstein': 3,
    'merna': 2,
    'merner': 2,
    'mernick': 2,
    'mero': 2,
    'merola': 3,
    'merolla': 3,
    'meroney': 3,
    'merow': 2,
    'merrell': 2,
    'merrett': 2,
    'merriam': 3,
    'merrick': 2,
    'merricks': 2,
    'merrie': 2,
    'merrier': 3,
    'merrifield': 3,
    'merrigan': 3,
    'merrihew': 3,
    'merrill': 2,
    'merrily': 3,
    'merrimac': 3,
    'merrimack': 3,
    'merriman': 3,
    'merrin': 2,
    'merriott': 3,
    'merrit': 2,
    'merritt': 2,
    'merritts': 2,
    'merriweather': 4,
    'merriwether': 4,
    'merrow': 2,
    'merry': 2,
    'merryfield': 3,
    'merryman': 3,
    'mers': 1,
    'mersch': 1,
    'mersereau': 3,
    'mershon': 2,
    'mersman': 2,
    'merson': 2,
    'merta': 2,
    'merten': 2,
    'mertens': 2,
    'mertes': 2,
    'mertice': 2,
    'mertins': 2,
    'mertle': 2,
    'merton': 2,
    'mertz': 1,
    'merv': 1,
    'mervin': 2,
    'mervine': 2,
    'mervis': 2,
    'mervyn': 2,
    'merwe': 2,
    'merwin': 2,
    'merwyn': 2,
    'meryl': 2,
    'meryll': 2,
    'merz': 1,
    'mesa': 2,
    'mesaba': 3,
    'mesaros': 3,
    'mesbic': 2,
    'mesbics': 2,
    'mescalero': 4,
    'mescaline': 3,
    'mesch': 1,
    'mesched': 2,
    'mescher': 2,
    'meschke': 1,
    'mesdames': 2,
    'meselsohn': 3,
    'meselson': 3,
    'mesenbrink': 3,
    'mesenteric': 4,
    'meserole': 4,
    'meserve': 2,
    'meservey': 3,
    'mesh': 1,
    'meshed': 1,
    'meshell': 2,
    'meshes': 2,
    'meshing': 2,
    'meshulam': 3,
    'mesick': 2,
    'mesirow': 3,
    'meske': 1,
    'mesker': 2,
    'meskill': 2,
    'meskimen': 3,
    'mesko': 2,
    'mesler': 3,
    'mesmer': 2,
    'mesmerism': 4,
    'mesmerize': 3,
    'mesmerized': 3,
    'mesmerizing': 4,
    'mesner': 2,
    'mesodermal': 4,
    'mesolithic': 4,
    'meson': 2,
    'mesons': 2,
    'mesopotamia': 6,
    'mesopotamian': 6,
    'mesopotamians': 6,
    'mesothelioma': 6,
    'mesothorax': 4,
    'mesozoic': 4,
    'mesquite': 2,
    'mess': 1,
    'messa': 2,
    'message': 2,
    'messagepad': 3,
    'messages': 3,
    'messaging': 3,
    'messamore': 3,
    'messana': 3,
    'messed': 1,
    'messeghero': 4,
    'messel': 2,
    'messenger': 3,
    'messengers': 3,
    'messer': 2,
    'messerli': 3,
    'messerly': 3,
    'messerschmidt': 3,
    'messerschmitt': 3,
    'messersmith': 3,
    'messes': 2,
    'messiaen': 3,
    'messiah': 3,
    'messiahs': 3,
    'messianic': 4,
    'messick': 2,
    'messier': 3,
    'messieurs': 2,
    'messimer': 3,
    'messina': 3,
    'messineo': 4,
    'messing': 2,
    'messinger': 3,
    'messler': 2,
    'messman': 2,
    'messmer': 2,
    'messmore': 2,
    'messner': 2,
    'messrs': 2,
    'messy': 2,
    'mest': 1,
    'mesta': 2,
    'mestas': 2,
    'mestek': 2,
    'mester': 2,
    'mestizo': 3,
    'meston': 2,
    'mestrallet': 3,
    'mestre': 2,
    'mestrovic': 3,
    'meszaros': 3,
    'met': 1,
    'meta': 2,
    'metabolic': 4,
    'metabolism': 5,
    'metabolisms': 5,
    'metabolize': 4,
    'metacarpal': 4,
    'metacarpals': 4,
    'metagogue': 3,
    'metagogued': 3,
    'metairie': 3,
    'metal': 2,
    'metall': 2,
    'metallatz': 3,
    'metallgesellschaft': 5,
    'metallic': 3,
    'metallica': 4,
    'metallo': 3,
    'metallurgical': 5,
    'metallurgy': 4,
    'metals': 2,
    'metalwork': 3,
    'metalworker': 4,
    'metalworkers': 4,
    'metalworking': 4,
    'metamorphasis': 5,
    'metamorphic': 4,
    'metamorphose': 4,
    'metamorphosis': 5,
    'metamucil': 4,
    'metaphor': 3,
    'metaphorical': 5,
    'metaphorically': 5,
    'metaphors': 3,
    'metaphysical': 5,
    'metaphysics': 4,
    'metastasis': 4,
    'metastasize': 4,
    'metastasized': 4,
    'metathorax': 4,
    'metavsky': 3,
    'metaxas': 3,
    'metcalf': 2,
    'metcalfe': 2,
    'metcom': 2,
    'mete': 1,
    'meted': 2,
    'meteor': 3,
    'meteoric': 4,
    'meteorite': 4,
    'meteorological': 7,
    'meteorologist': 6,
    'meteorologists': 6,
    'meteorology': 6,
    'meteors': 3,
    'meter': 2,
    'metered': 2,
    'metering': 3,
    'meters': 2,
    'metex': 2,
    'meth': 1,
    'methadone': 3,
    'methamphetamine': 5,
    'methane': 2,
    'methanex': 3,
    'methanol': 3,
    'methazine': 3,
    'metheney': 3,
    'metheny': 3,
    'methner': 2,
    'method': 2,
    'methode': 2,
    'methodical': 4,
    'methodically': 5,
    'methodism': 4,
    'methodisms': 4,
    'methodist': 3,
    'methodological': 6,
    'methodologies': 5,
    'methodology': 5,
    'methods': 2,
    'methot': 2,
    'methotrexate': 4,
    'methuselah': 4,
    'methvin': 2,
    'methyl': 2,
    'methylene': 3,
    'metics': 2,
    'meticulous': 4,
    'meticulously': 5,
    'metier': 2,
    'metis': 2,
    'metivier': 4,
    'metlakatla': 4,
    'metlife': 2,
    'metolachlor': 4,
    'metonomy': 4,
    'metoyer': 3,
    'metpath': 2,
    'metra': 2,
    'metrahealth': 3,
    'metric': 2,
    'metrical': 3,
    'metrically': 4,
    'metrick': 2,
    'metrics': 2,
    'metro': 2,
    'metrobanc': 3,
    'metrobank': 3,
    'metrocall': 3,
    'metrocolor': 4,
    'metrocorp': 3,
    'metrodade': 3,
    'metrodome': 3,
    'metromail': 3,
    'metromedia': 5,
    'metroplex': 3,
    'metroplexes': 4,
    'metropol': 3,
    'metropoli': 4,
    'metropolis': 4,
    'metropolitain': 5,
    'metropolitan': 5,
    'metropolitans': 5,
    'mets': 1,
    'metsker': 2,
    'mette': 1,
    'metter': 2,
    'metternich': 3,
    'mettle': 2,
    'mettlen': 2,
    'mettler': 2,
    'metts': 1,
    'mettur': 2,
    'metz': 1,
    'metze': 1,
    'metzenbaum': 3,
    'metzer': 2,
    'metzgar': 2,
    'metzger': 2,
    'metzinger': 3,
    'metzker': 2,
    'metzler': 2,
    'metzner': 2,
    'meunier': 3,
    'meurer': 2,
    'meuse': 1,
    'meuser': 2,
    'meuth': 1,
    'mevacor': 3,
    'mevarach': 3,
    'mevarachs': 4,
    'mew': 1,
    'mewas': 2,
    'mewborn': 2,
    'mewes': 1,
    'mex': 1,
    'mexicali': 4,
    'mexican': 3,
    'mexicana': 4,
    'mexicano': 4,
    'mexicanos': 4,
    'mexicans': 3,
    'mexico': 3,
    'mey': 1,
    'meyer': 2,
    'meyerbeer': 3,
    'meyerhoff': 3,
    'meyering': 3,
    'meyerman': 3,
    'meyerowitz': 4,
    'meyers': 2,
    'meyerson': 3,
    'meyn': 1,
    'meyo': 2,
    'meyohas': 3,
    'mez': 1,
    'meza': 2,
    'mezera': 3,
    'mezey': 2,
    'mezger': 2,
    'mezhdumarodnom': 5,
    'mezo': 2,
    'mezro': 2,
    'mezuzah': 3,
    'mezvinsky': 3,
    'mezzaluna': 4,
    'mezzanine': 3,
    'mezzo': 2,
    'mfume': 3,
    'mgm': 3,
    'mh': 2,
    'mhm': 2,
    'mhoon': 1,
    'mi': 1,
    'mia': 2,
    'miami': 3,
    'miano': 3,
    'miara': 3,
    'miasma': 3,
    'miata': 3,
    'miazga': 3,
    'mib': 1,
    'mic': 1,
    'mica': 2,
    'micaela': 3,
    'micah': 2,
    'micale': 3,
    'micallef': 3,
    'micanopy': 4,
    'micatin': 3,
    'micciche': 3,
    'miccio': 3,
    'mice': 1,
    'micek': 2,
    'miceli': 3,
    'mich': 1,
    'michael': 2,
    'michaela': 3,
    'michaelina': 4,
    'michaeline': 3,
    'michaelis': 3,
    'michaella': 3,
    'michaels': 2,
    'michaelsen': 3,
    'michaelson': 3,
    'michal': 2,
    'michalak': 3,
    'michalec': 3,
    'michalek': 3,
    'michalik': 3,
    'michalowski': 4,
    'michals': 2,
    'michalski': 3,
    'michalsky': 3,
    'michaud': 2,
    'michaux': 2,
    'michcon': 2,
    'micheal': 2,
    'micheaux': 2,
    'michel': 2,
    'michela': 3,
    'michelangelo': 5,
    'michelangelos': 5,
    'michele': 2,
    'micheletti': 4,
    'micheli': 3,
    'michelin': 3,
    'michelina': 4,
    'micheline': 3,
    'michelini': 4,
    'michell': 2,
    'michelle': 2,
    'michelli': 3,
    'michelman': 3,
    'michelob': 3,
    'michelotti': 4,
    'michels': 2,
    'michelsen': 3,
    'michelson': 3,
    'michener': 2,
    'michie': 2,
    'michiels': 2,
    'michigan': 3,
    'michihiro': 4,
    'michio': 3,
    'michl': 2,
    'michler': 2,
    'michna': 2,
    'michoacan': 3,
    'michon': 2,
    'mick': 1,
    'micka': 2,
    'micke': 1,
    'mickel': 2,
    'mickelberry': 4,
    'mickels': 2,
    'mickelsen': 3,
    'mickelson': 3,
    'mickens': 2,
    'mickey': 2,
    'micki': 2,
    'mickie': 2,
    'mickiewicz': 3,
    'mickish': 2,
    'mickle': 2,
    'mickler': 2,
    'mickles': 2,
    'mickley': 2,
    'mickus': 2,
    'micky': 2,
    'micoin': 2,
    'micom': 2,
    'micosukee': 4,
    'micro': 2,
    'microage': 3,
    'microaire': 3,
    'microamerica': 6,
    'microbe': 2,
    'microbes': 2,
    'microbial': 4,
    'microbilt': 3,
    'microbiologist': 6,
    'microbiologists': 6,
    'microbiology': 6,
    'microbreweries': 5,
    'microbrewery': 5,
    'microcentrifuge': 5,
    'microchip': 3,
    'microchips': 3,
    'microcircuit': 4,
    'microcircuits': 4,
    'microclimate': 4,
    'microclimates': 4,
    'microcode': 3,
    'microcodes': 3,
    'microcom': 3,
    'microcomputer': 5,
    'microcomputers': 5,
    'microcosm': 4,
    'microdyne': 3,
    'microeconomic': 6,
    'microeconomics': 6,
    'microelectronic': 6,
    'microelectronics': 6,
    'microelettronica': 7,
    'microfiche': 3,
    'microfilm': 3,
    'microfossil': 4,
    'microfossils': 4,
    'microgenesys': 5,
    'micrografx': 4,
    'microgram': 3,
    'micrograms': 3,
    'micrographic': 4,
    'micrographics': 4,
    'microliter': 4,
    'microliters': 4,
    'micromanage': 4,
    'micromanaged': 4,
    'micromanagement': 5,
    'micromanages': 5,
    'micromanaging': 5,
    'micrometer': 4,
    'micrometers': 4,
    'micron': 2,
    'micronesia': 4,
    'micronic': 3,
    'micronics': 3,
    'microns': 2,
    'microorganism': 6,
    'microorganisms': 6,
    'micropaleontology': 8,
    'microphone': 3,
    'microphones': 3,
    'micropolis': 4,
    'micropro': 3,
    'microprocessor': 5,
    'microprocessors': 5,
    'microprose': 3,
    'micros': 2,
    'microscope': 3,
    'microscopes': 3,
    'microscopic': 4,
    'microscopically': 5,
    'microscopy': 4,
    'microsemi': 4,
    'microsoft': 3,
    'microsystems': 4,
    'microtel': 3,
    'microtubule': 4,
    'microtubules': 4,
    'microvax': 3,
    'microware': 3,
    'microwavable': 5,
    'microwave': 3,
    'microwaved': 3,
    'microwaves': 3,
    'microwaving': 4,
    'microx': 2,
    'micucci': 3,
    'mid': 1,
    'midafternoon': 4,
    'midair': 2,
    'midamerica': 5,
    'midas': 2,
    'midcap': 2,
    'midcentury': 4,
    'midcon': 2,
    'midcontinent': 4,
    'midcourse': 2,
    'middaugh': 2,
    'midday': 2,
    'middendorf': 3,
    'middenstandsbank': 4,
    'middle': 2,
    'middlebrook': 3,
    'middlebrooks': 3,
    'middleburg': 3,
    'middlebury': 4,
    'middlekauff': 3,
    'middleman': 3,
    'middlemen': 3,
    'middlemiss': 3,
    'middlesex': 3,
    'middlesworth': 3,
    'middleton': 3,
    'middletown': 3,
    'middleweight': 3,
    'middling': 3,
    'mideast': 2,
    'mideastern': 3,
    'midflight': 2,
    'midge': 1,
    'midges': 2,
    'midget': 2,
    'midgetman': 3,
    'midgets': 2,
    'midgett': 2,
    'midgette': 2,
    'midgley': 2,
    'midi': 2,
    'midkiff': 2,
    'midland': 2,
    'midlands': 2,
    'midlantic': 3,
    'midler': 2,
    'midlevel': 3,
    'midlife': 2,
    'midline': 2,
    'midmorning': 3,
    'midnight': 2,
    'midpac': 2,
    'midpoint': 2,
    'midprice': 2,
    'midpriced': 2,
    'midrange': 2,
    'midrib': 2,
    'midseason': 3,
    'midsection': 3,
    'midsession': 3,
    'midshipman': 3,
    'midshipmen': 3,
    'midsize': 2,
    'midsized': 2,
    'midst': 1,
    'midstream': 2,
    'midsummer': 3,
    'midterm': 2,
    'midterms': 2,
    'midthun': 2,
    'midtown': 2,
    'midura': 3,
    'midway': 2,
    'midweek': 2,
    'midwest': 2,
    'midwestern': 3,
    'midwesterner': 4,
    'midwesterners': 4,
    'midwife': 2,
    'midwifery': 4,
    'midwinter': 3,
    'midwives': 2,
    'midyear': 2,
    'midyett': 2,
    'midyette': 2,
    'miears': 2,
    'mieczkowski': 3,
    'mieczyslaw': 3,
    'miedema': 3,
    'mielcarek': 3,
    'miele': 1,
    'mielke': 2,
    'mien': 1,
    'mieno': 2,
    'mier': 2,
    'miera': 2,
    'mieras': 2,
    'miers': 2,
    'miert': 2,
    'mierzejewski': 4,
    'mierzwa': 2,
    'mies': 1,
    'mieske': 2,
    'miesner': 2,
    'miesse': 1,
    'miffed': 1,
    'mifflin': 2,
    'mifsud': 2,
    'mig': 1,
    'migdal': 2,
    'migent': 2,
    'miggins': 2,
    'might': 1,
    'mightier': 3,
    'mightiest': 3,
    'mightily': 3,
    'mighty': 2,
    'migliaccio': 5,
    'miglin': 2,
    'migliore': 4,
    'mignanelli': 4,
    'mignano': 3,
    'migneault': 2,
    'mignogna': 3,
    'mignon': 2,
    'mignone': 3,
    'mignons': 2,
    'migra': 2,
    'migraine': 2,
    'migraines': 2,
    'migrant': 2,
    'migrants': 2,
    'migrate': 2,
    'migrated': 3,
    'migrates': 2,
    'migrating': 3,
    'migration': 3,
    'migrations': 3,
    'migratory': 4,
    'migs': 1,
    'miguel': 2,
    'migues': 2,
    'miguez': 2,
    'mihai': 2,
    'mihal': 2,
    'mihalek': 3,
    'mihalic': 3,
    'mihalik': 3,
    'mihalko': 3,
    'mihaly': 3,
    'mihelich': 3,
    'mihm': 1,
    'mihn': 1,
    'mihok': 2,
    'mijares': 3,
    'mika': 2,
    'mikado': 3,
    'mikael': 2,
    'mikaela': 3,
    'mikako': 3,
    'mikal': 2,
    'mike': 1,
    'mikel': 2,
    'mikell': 2,
    'mikels': 2,
    'mikelson': 3,
    'mikes': 1,
    'mikesell': 3,
    'mikesh': 2,
    'mikeska': 3,
    'mikey': 2,
    'mikhail': 2,
    'mikidu': 3,
    'mikita': 3,
    'mikkelsen': 3,
    'mikkelson': 3,
    'mikkola': 3,
    'miklas': 2,
    'mikles': 2,
    'miklos': 2,
    'miko': 2,
    'mikol': 2,
    'mikolajczak': 4,
    'mikolajczyk': 4,
    'mikos': 2,
    'mikrut': 2,
    'miksch': 1,
    'mikula': 3,
    'mikulak': 3,
    'mikulec': 3,
    'mikulic': 3,
    'mikulich': 3,
    'mikulski': 3,
    'mikus': 2,
    'mikva': 2,
    'mil': 1,
    'milacron': 3,
    'miladic': 3,
    'milagro': 3,
    'milam': 2,
    'milan': 2,
    'milani': 3,
    'milano': 3,
    'milanowski': 4,
    'milardo': 3,
    'milas': 2,
    'milazzo': 3,
    'milbank': 2,
    'milbauer': 3,
    'milberg': 2,
    'milberger': 3,
    'milbourn': 2,
    'milbourne': 2,
    'milbrandt': 2,
    'milbrath': 2,
    'milburn': 2,
    'milby': 2,
    'milch': 1,
    'milco': 2,
    'mild': 1,
    'mildenberger': 4,
    'milder': 2,
    'mildest': 2,
    'mildew': 2,
    'mildews': 2,
    'mildly': 2,
    'mildred': 2,
    'mildrid': 3,
    'mile': 1,
    'mileage': 2,
    'mileham': 3,
    'milem': 2,
    'miler': 2,
    'miles': 1,
    'mileski': 3,
    'milestone': 2,
    'milestones': 2,
    'miletich': 3,
    'milewski': 3,
    'miley': 2,
    'milford': 2,
    'milgram': 2,
    'milham': 2,
    'milhoan': 2,
    'milholland': 3,
    'milhollin': 3,
    'milhorn': 2,
    'milhous': 2,
    'milhouse': 2,
    'milian': 3,
    'milicent': 3,
    'milich': 2,
    'milici': 3,
    'milieu': 2,
    'mililiter': 4,
    'milissent': 3,
    'militancy': 4,
    'militant': 3,
    'militantly': 4,
    'militants': 3,
    'militaries': 4,
    'militarily': 5,
    'militarism': 5,
    'militaristic': 5,
    'militarists': 4,
    'militarize': 4,
    'militarized': 4,
    'military': 4,
    'militate': 3,
    'militello': 4,
    'militia': 3,
    'militiamen': 4,
    'militias': 3,
    'milito': 3,
    'milius': 3,
    'milk': 1,
    'milke': 1,
    'milked': 1,
    'milken': 2,
    'milkens': 2,
    'milking': 2,
    'milkman': 2,
    'milko': 2,
    'milkovich': 3,
    'milkowski': 3,
    'milks': 1,
    'milkshake': 2,
    'milkweed': 2,
    'milky': 2,
    'mill': 1,
    'millage': 2,
    'millan': 2,
    'millar': 2,
    'millard': 2,
    'millau': 2,
    'millay': 2,
    'millbank': 2,
    'millburn': 2,
    'mille': 1,
    'milled': 1,
    'milledge': 2,
    'millen': 2,
    'millender': 3,
    'millennia': 4,
    'millennial': 4,
    'millennium': 4,
    'millenniums': 4,
    'miller': 2,
    'millerbrook': 3,
    'millerick': 3,
    'millers': 2,
    'milleson': 3,
    'millet': 2,
    'millets': 2,
    'millett': 2,
    'millette': 2,
    'milley': 2,
    'millhouse': 2,
    'milli': 2,
    'millibar': 3,
    'millican': 3,
    'millicent': 3,
    'millicom': 3,
    'millie': 2,
    'milliet': 3,
    'milligal': 3,
    'milligan': 3,
    'milligauss': 3,
    'milligram': 3,
    'milligrams': 3,
    'millikan': 3,
    'milliken': 3,
    'millikin': 3,
    'milliliter': 4,
    'milliliters': 4,
    'milliman': 3,
    'millimeter': 4,
    'millimeters': 4,
    'millin': 2,
    'milliner': 3,
    'milling': 2,
    'millington': 3,
    'million': 2,
    'millionaire': 3,
    'millionaires': 3,
    'millions': 2,
    'millionth': 2,
    'millionths': 2,
    'millipore': 3,
    'milliron': 3,
    'millirons': 3,
    'millis': 2,
    'millisecond': 4,
    'milliseconds': 4,
    'millisent': 3,
    'millman': 2,
    'milln': 1,
    'millner': 2,
    'millon': 2,
    'milloy': 2,
    'mills': 1,
    'millsap': 2,
    'millsaps': 2,
    'millspaugh': 2,
    'millstein': 2,
    'millstone': 2,
    'millward': 2,
    'millwood': 2,
    'millworker': 3,
    'millworkers': 3,
    'millworking': 3,
    'milly': 2,
    'milman': 2,
    'milne': 1,
    'milner': 2,
    'milnes': 1,
    'milo': 2,
    'milodic': 3,
    'milonas': 3,
    'milone': 2,
    'milos': 2,
    'milosevic': 4,
    'milosevich': 4,
    'milosh': 2,
    'milot': 2,
    'milpitas': 3,
    'milroy': 2,
    'mils': 1,
    'milsap': 2,
    'milson': 2,
    'milstead': 2,
    'milstein': 2,
    'milt': 1,
    'miltenberger': 4,
    'miltie': 2,
    'miltner': 2,
    'milton': 2,
    'miltonic': 3,
    'milum': 2,
    'milunovich': 4,
    'milward': 2,
    'milwaukee': 3,
    'milz': 1,
    'mim': 1,
    'mimbs': 1,
    'mime': 1,
    'mimeograph': 4,
    'mimi': 2,
    'mimic': 2,
    'mimicked': 2,
    'mimicking': 3,
    'mimicry': 3,
    'mimics': 2,
    'mimis': 2,
    'mimms': 1,
    'mimnaugh': 2,
    'mimosa': 3,
    'mims': 1,
    'min': 1,
    'mina': 2,
    'minahan': 3,
    'minami': 3,
    'minamide': 4,
    'minar': 2,
    'minard': 2,
    'minardos': 3,
    'minaret': 3,
    'minarets': 3,
    'minarik': 3,
    'minas': 2,
    'minasian': 3,
    'minassian': 3,
    'minatome': 3,
    'minc': 1,
    'mince': 1,
    'minced': 1,
    'mincemeat': 2,
    'mincer': 2,
    'mincers': 2,
    'minces': 2,
    'mincey': 2,
    'minch': 1,
    'mincher': 2,
    'minchew': 2,
    'minchey': 2,
    'minchin': 2,
    'mincing': 2,
    'minckler': 2,
    'mincks': 1,
    'mincy': 2,
    'mind': 1,
    'minda': 2,
    'mindanao': 3,
    'mindboggling': 3,
    'minded': 2,
    'mindedly': 3,
    'mindedness': 3,
    'mindel': 2,
    'mindell': 2,
    'minden': 2,
    'minder': 2,
    'mindful': 2,
    'mindfully': 3,
    'minding': 2,
    'mindless': 2,
    'mindlessly': 3,
    'mindlin': 2,
    'minds': 1,
    'mindset': 2,
    'mindsets': 2,
    'mindy': 2,
    'mine': 1,
    'minea': 3,
    'minear': 2,
    'mineau': 2,
    'minebea': 4,
    'mined': 1,
    'mineer': 2,
    'minefield': 2,
    'minefields': 2,
    'minehan': 3,
    'minehart': 2,
    'minella': 3,
    'minelli': 3,
    'mineo': 3,
    'mineola': 4,
    'mineowner': 3,
    'mineowners': 3,
    'miner': 2,
    'minera': 3,
    'mineral': 3,
    'mineralization': 6,
    'mineralize': 4,
    'mineralogically': 7,
    'mineralogist': 5,
    'mineralogy': 5,
    'minerals': 3,
    'minerd': 2,
    'minero': 3,
    'miners': 2,
    'minerva': 3,
    'minervini': 4,
    'mines': 1,
    'minesweeper': 3,
    'minesweepers': 3,
    'minet': 2,
    'mineta': 3,
    'minette': 2,
    'mineworker': 3,
    'mineworkers': 3,
    'ming': 1,
    'minge': 1,
    'minger': 2,
    'minges': 2,
    'mingle': 2,
    'mingled': 2,
    'mingles': 2,
    'mingling': 3,
    'mingo': 2,
    'mings': 1,
    'mingun': 2,
    'mingus': 2,
    'minh': 1,
    'mini': 2,
    'miniard': 2,
    'miniature': 4,
    'miniatures': 4,
    'miniaturization': 7,
    'miniaturize': 4,
    'miniaturized': 4,
    'minibus': 3,
    'minibuses': 4,
    'minicar': 3,
    'minicars': 3,
    'minich': 2,
    'minichiello': 5,
    'minick': 2,
    'minicomputer': 5,
    'minicomputers': 5,
    'minicucci': 4,
    'minidisc': 3,
    'minier': 3,
    'minihan': 3,
    'minilab': 3,
    'minilabs': 3,
    'minimal': 3,
    'minimalism': 5,
    'minimalist': 4,
    'minimally': 4,
    'minimill': 3,
    'minimills': 3,
    'minimize': 3,
    'minimized': 3,
    'minimizes': 4,
    'minimizing': 4,
    'minimum': 3,
    'minimums': 3,
    'mining': 2,
    'mininger': 3,
    'minion': 2,
    'minions': 2,
    'minis': 2,
    'miniscribe': 3,
    'miniscule': 3,
    'miniseries': 4,
    'minish': 2,
    'miniskirt': 3,
    'miniskirts': 3,
    'minister': 3,
    'ministerial': 5,
    'ministering': 3,
    'ministers': 3,
    'ministership': 4,
    'ministral': 3,
    'ministration': 4,
    'ministrations': 4,
    'ministries': 3,
    'ministry': 3,
    'minisupercomputer': 7,
    'minisupercomputers': 7,
    'minit': 2,
    'minitel': 3,
    'minium': 3,
    'minivan': 3,
    'minivans': 3,
    'minix': 2,
    'minjares': 3,
    'minjarez': 3,
    'mink': 1,
    'minke': 1,
    'minkel': 2,
    'minkin': 2,
    'minkler': 2,
    'minkoff': 2,
    'minkow': 2,
    'minks': 1,
    'minna': 2,
    'minnaar': 2,
    'minneapolis': 5,
    'minnelli': 3,
    'minner': 2,
    'minnesota': 4,
    'minnesotan': 4,
    'minnesotans': 4,
    'minnetonka': 4,
    'minney': 2,
    'minnich': 2,
    'minnick': 2,
    'minnie': 2,
    'minniear': 3,
    'minnifield': 3,
    'minnig': 2,
    'minnis': 2,
    'minniti': 3,
    'minnix': 2,
    'minnow': 2,
    'minnows': 2,
    'minns': 1,
    'minntech': 2,
    'minny': 2,
    'mino': 2,
    'minoan': 3,
    'minogue': 2,
    'minoli': 3,
    'minolta': 3,
    'minor': 2,
    'minorca': 3,
    'minorco': 3,
    'minored': 2,
    'minoring': 3,
    'minorite': 3,
    'minorities': 4,
    'minority': 4,
    'minors': 2,
    'minoru': 3,
    'minoso': 3,
    'minosos': 3,
    'minot': 2,
    'minott': 2,
    'minotti': 3,
    'minow': 2,
    'minoxidil': 4,
    'minpeco': 3,
    'minshall': 2,
    'minshew': 2,
    'minsk': 1,
    'minskoff': 2,
    'minsky': 2,
    'minson': 2,
    'minstar': 2,
    'minster': 2,
    'minstrel': 2,
    'minstrels': 2,
    'mint': 1,
    'minta': 2,
    'mintage': 2,
    'minted': 2,
    'minteer': 2,
    'minter': 2,
    'mintha': 2,
    'mintier': 3,
    'minting': 2,
    'minto': 2,
    'minton': 2,
    'mints': 1,
    'minturn': 2,
    'mintz': 1,
    'mintzer': 2,
    'minuet': 3,
    'minus': 2,
    'minuscule': 3,
    'minuses': 3,
    'minute': 2,
    'minutely': 3,
    'minuteman': 3,
    'minutemen': 3,
    'minutes': 2,
    'minutia': 4,
    'minutiae': 4,
    'minyard': 2,
    'miocene': 3,
    'mion': 2,
    'mione': 2,
    'miot': 2,
    'miotke': 3,
    'mips': 1,
    'mir': 1,
    'mira': 2,
    'mirabal': 3,
    'mirabel': 3,
    'mirabella': 4,
    'mirabelle': 3,
    'mirabelli': 4,
    'mirabile': 3,
    'mirabito': 4,
    'miracid': 3,
    'miracle': 3,
    'miracles': 3,
    'miraculous': 4,
    'miraculously': 5,
    'mirad': 2,
    'mirada': 3,
    'mirage': 2,
    'mirages': 3,
    'miraglia': 4,
    'mirak': 2,
    'miramar': 3,
    'miramax': 3,
    'miramontes': 4,
    'miranda': 3,
    'mirando': 3,
    'miratec': 3,
    'mire': 1,
    'mired': 1,
    'mireles': 3,
    'mirelez': 3,
    'mirell': 2,
    'mirella': 3,
    'mirelle': 2,
    'mirenda': 3,
    'mires': 1,
    'miriam': 3,
    'mirick': 2,
    'mirilla': 3,
    'mirkin': 2,
    'mirna': 2,
    'miro': 2,
    'miron': 2,
    'mironenko': 4,
    'miroslav': 3,
    'mirra': 2,
    'mirren': 2,
    'mirror': 2,
    'mirrored': 2,
    'mirroring': 3,
    'mirrors': 2,
    'mirsad': 2,
    'mirsky': 2,
    'mirth': 1,
    'mirti': 2,
    'mirtle': 2,
    'mirto': 2,
    'mirtz': 1,
    'mirv': 1,
    'mirza': 2,
    'mis': 1,
    'misa': 2,
    'misadventure': 4,
    'misadventures': 4,
    'misallocate': 4,
    'misallocated': 5,
    'misallocates': 4,
    'misallocating': 5,
    'misallocation': 5,
    'misanthrope': 3,
    'misapplication': 5,
    'misapplied': 3,
    'misapplies': 3,
    'misapply': 3,
    'misapplying': 4,
    'misapprehension': 5,
    'misappropriate': 5,
    'misappropriated': 6,
    'misappropriates': 5,
    'misappropriating': 6,
    'misappropriation': 6,
    'misater': 3,
    'misawa': 3,
    'misbegotten': 4,
    'misbehave': 3,
    'misbehaved': 3,
    'misbehaves': 3,
    'misbehaving': 4,
    'misbehavior': 4,
    'misbrener': 3,
    'miscalculate': 4,
    'miscalculated': 5,
    'miscalculates': 4,
    'miscalculation': 5,
    'miscalculations': 5,
    'miscarriage': 3,
    'miscarriages': 4,
    'miscast': 2,
    'miscavige': 3,
    'miscayuna': 4,
    'miscellaneous': 5,
    'miscellany': 4,
    'misch': 1,
    'mischa': 2,
    'mischaracterization': 7,
    'mischaracterizations': 7,
    'mischaracterize': 5,
    'mischaracterized': 5,
    'mischaracterizes': 6,
    'mischaracterizing': 6,
    'mischarge': 2,
    'mischarged': 2,
    'mischarges': 3,
    'mischarging': 3,
    'mischel': 2,
    'mischer': 2,
    'mischief': 2,
    'mischievous': 3,
    'mischke': 1,
    'mischler': 3,
    'miscibility': 5,
    'miscible': 3,
    'miscommunicate': 5,
    'miscommunication': 6,
    'misconceive': 3,
    'misconceived': 3,
    'misconceives': 3,
    'misconceiving': 4,
    'misconception': 4,
    'misconceptions': 4,
    'misconduct': 3,
    'misconstrue': 3,
    'misconstrued': 3,
    'misconstrues': 3,
    'misconstruing': 4,
    'miscount': 2,
    'miscreant': 3,
    'miscreants': 3,
    'miscreation': 4,
    'miscreations': 4,
    'miscue': 2,
    'miscues': 2,
    'misdeed': 2,
    'misdeeds': 2,
    'misdemeanor': 4,
    'misdemeanors': 4,
    'misdiagnose': 4,
    'misdiagnosed': 4,
    'misdiagnoses': 5,
    'misdiagnosis': 5,
    'misdirect': 3,
    'misdirected': 4,
    'misek': 2,
    'misener': 3,
    'misenheimer': 4,
    'miser': 2,
    'miserable': 4,
    'miserables': 4,
    'miserably': 4,
    'miseration': 4,
    'miseries': 3,
    'miserly': 3,
    'misers': 2,
    'misery': 3,
    'mises': 2,
    'misfeldt': 2,
    'misfire': 3,
    'misfit': 2,
    'misfits': 2,
    'misfortune': 3,
    'misfortunes': 3,
    'misgive': 2,
    'misgiving': 3,
    'misgivings': 3,
    'misgovernment': 4,
    'misguide': 2,
    'misguided': 3,
    'misguides': 2,
    'mish': 1,
    'misha': 2,
    'mishandle': 3,
    'mishandled': 3,
    'mishandles': 3,
    'mishandling': 3,
    'mishap': 2,
    'mishaps': 2,
    'mishawaka': 4,
    'mishawaum': 3,
    'mishawum': 3,
    'mishear': 2,
    'misheard': 2,
    'mishkin': 2,
    'mishler': 2,
    'mishmash': 2,
    'mishoe': 2,
    'mishra': 2,
    'misiak': 3,
    'misiaszek': 4,
    'misidentification': 7,
    'misidentified': 5,
    'misidentifies': 5,
    'misidentify': 5,
    'misidentifying': 6,
    'misiewicz': 3,
    'misimpression': 4,
    'misinform': 3,
    'misinformation': 5,
    'misinformed': 3,
    'misinforming': 4,
    'misinforms': 3,
    'misinterpret': 4,
    'misinterpretation': 6,
    'misinterpreted': 5,
    'misinterpreting': 5,
    'misinterprets': 4,
    'misjudge': 2,
    'misjudged': 2,
    'misjudges': 3,
    'misjudging': 3,
    'misjudgment': 3,
    'misjudgments': 3,
    'miska': 2,
    'miske': 1,
    'miskell': 2,
    'miskito': 3,
    'miskitos': 3,
    'misko': 2,
    'mislabel': 3,
    'mislabeled': 3,
    'mislabeling': 4,
    'mislabels': 3,
    'mislaid': 2,
    'mislead': 2,
    'misleading': 3,
    'misleadingly': 4,
    'misleads': 2,
    'misled': 2,
    'mismanage': 3,
    'mismanaged': 3,
    'mismanagement': 4,
    'mismanages': 4,
    'mismanaging': 4,
    'mismatch': 2,
    'mismatched': 2,
    'mismatches': 3,
    'misner': 2,
    'misnomer': 3,
    'misogyne': 3,
    'misogynist': 4,
    'misogynists': 4,
    'misogyny': 4,
    'misperceive': 3,
    'misperceived': 3,
    'misperceives': 3,
    'misperception': 4,
    'misperceptions': 4,
    'misplace': 2,
    'misplaced': 2,
    'misplaces': 3,
    'misplacing': 3,
    'misprice': 2,
    'mispriced': 2,
    'misprices': 3,
    'mispricing': 3,
    'misprint': 2,
    'misprinted': 3,
    'misprints': 2,
    'misprision': 3,
    'mispronounce': 3,
    'mispronounced': 3,
    'mispronounces': 4,
    'mispronouncing': 4,
    'misquote': 2,
    'misquoted': 3,
    'misquotes': 2,
    'misquoting': 3,
    'misread': 2,
    'misreading': 3,
    'misrecognition': 5,
    'misrecognize': 4,
    'misrecognized': 4,
    'misrecognizes': 5,
    'misrecognizing': 5,
    'misremember': 4,
    'misremembered': 4,
    'misreport': 3,
    'misreported': 4,
    'misrepresent': 4,
    'misrepresentation': 6,
    'misrepresentations': 6,
    'misrepresented': 5,
    'misrepresenting': 5,
    'misrepresents': 4,
    'misrule': 2,
    'miss': 1,
    'missal': 2,
    'missed': 1,
    'missel': 2,
    'misses': 2,
    'missett': 2,
    'missey': 2,
    'misshapen': 3,
    'missie': 2,
    'missildine': 4,
    'missile': 2,
    'missiles': 2,
    'missimer': 3,
    'missing': 2,
    'mission': 2,
    'missionaries': 4,
    'missionary': 4,
    'missions': 2,
    'mississauga': 4,
    'mississippi': 4,
    'mississippian': 5,
    'mississippians': 5,
    'mississippis': 4,
    'missive': 2,
    'missler': 2,
    'missoula': 3,
    'missouri': 3,
    'missourian': 4,
    'missourians': 4,
    'misspeak': 2,
    'misspell': 2,
    'misspelled': 2,
    'misspelling': 3,
    'misspells': 2,
    'misspend': 2,
    'misspending': 3,
    'misspent': 2,
    'misspoke': 2,
    'misspoken': 3,
    'misstate': 2,
    'misstated': 3,
    'misstatement': 3,
    'misstatements': 3,
    'misstates': 2,
    'misstating': 3,
    'misstep': 2,
    'missteps': 2,
    'missus': 2,
    'missy': 2,
    'mist': 1,
    'mistake': 2,
    'mistaken': 3,
    'mistakenly': 4,
    'mistakes': 2,
    'mistaking': 3,
    'mister': 2,
    'mistero': 3,
    'misters': 2,
    'mistic': 2,
    'mistler': 2,
    'mistletoe': 3,
    'mistook': 2,
    'mistral': 2,
    'mistreat': 2,
    'mistreated': 3,
    'mistreating': 3,
    'mistreatment': 3,
    'mistreats': 2,
    'mistress': 2,
    'mistresses': 3,
    'mistretta': 3,
    'mistrial': 3,
    'mistrials': 3,
    'mistrust': 2,
    'mistrusted': 3,
    'mistrustful': 3,
    'mistrusting': 3,
    'mistrusts': 2,
    'mistry': 2,
    'mists': 1,
    'misty': 2,
    'misunder': 3,
    'misunderstand': 4,
    'misunderstanding': 5,
    'misunderstandings': 5,
    'misunderstands': 4,
    'misunderstood': 4,
    'misuraca': 4,
    'misuse': 2,
    'misused': 2,
    'misuses': 3,
    'misusing': 3,
    'mit': 3,
    'mita': 2,
    'mitamura': 4,
    'mitch': 1,
    'mitcham': 2,
    'mitchel': 2,
    'mitchell': 2,
    'mitchelson': 3,
    'mitcheltree': 3,
    'mitchem': 2,
    'mitchen': 2,
    'mitchener': 3,
    'mitchner': 2,
    'mitchum': 2,
    'mite': 1,
    'mitek': 2,
    'mitel': 2,
    'miter': 2,
    'mitering': 3,
    'mites': 1,
    'mithcell': 2,
    'mithridate': 3,
    'mithridates': 3,
    'mithun': 2,
    'miti': 2,
    'mitigate': 3,
    'mitigated': 4,
    'mitigates': 3,
    'mitigating': 4,
    'mitigation': 4,
    'mitman': 2,
    'mitnick': 2,
    'mitochondria': 5,
    'mitochondrion': 5,
    'mitofsky': 3,
    'mitra': 2,
    'mitral': 2,
    'mitrano': 3,
    'mitre': 2,
    'mitro': 2,
    'mitrovich': 3,
    'mitsch': 1,
    'mitschke': 1,
    'mitsotakis': 4,
    'mitsuba': 3,
    'mitsubishi': 4,
    'mitsui': 3,
    'mitsukoshi': 4,
    'mitsuru': 3,
    'mitt': 1,
    'mittag': 2,
    'mittal': 2,
    'mittan': 2,
    'mitteleuropa': 5,
    'mittelman': 3,
    'mittelstadt': 3,
    'mittelstaedt': 3,
    'mittelsteadt': 3,
    'mitten': 2,
    'mittendorf': 3,
    'mittens': 2,
    'mitterand': 3,
    'mitterrand': 3,
    'mittleman': 3,
    'mittler': 2,
    'mittman': 2,
    'mitton': 2,
    'mitts': 1,
    'mityunov': 3,
    'mitzel': 2,
    'mitzi': 2,
    'mitzner': 2,
    'mitzvah': 2,
    'miura': 3,
    'mix': 1,
    'mixed': 1,
    'mixer': 2,
    'mixers': 2,
    'mixes': 2,
    'mixing': 2,
    'mixner': 2,
    'mixon': 2,
    'mixson': 2,
    'mixte': 1,
    'mixter': 2,
    'mixture': 2,
    'mixtures': 2,
    'mixup': 2,
    'mixups': 2,
    'miyagawa': 4,
    'miyahara': 4,
    'miyake': 3,
    'miyako': 3,
    'miyamori': 4,
    'miyamoto': 4,
    'miyasaki': 4,
    'miyasato': 4,
    'miyashiro': 4,
    'miyazaki': 4,
    'miyazawa': 4,
    'mizar': 2,
    'mize': 1,
    'mizel': 2,
    'mizell': 2,
    'mizelle': 2,
    'mizen': 2,
    'mizer': 2,
    'mizner': 2,
    'mizrahi': 3,
    'mizuno': 3,
    'mizzell': 2,
    'mkhatshwa': 3,
    'mladenic': 3,
    'mladic': 2,
    'mlecko': 2,
    'mlotok': 2,
    'mmabatho': 3,
    'mme': 2,
    'mmmm': 0,
    'mnemonic': 3,
    'mnemonics': 3,
    'mnookin': 2,
    'mo': 1,
    'moab': 2,
    'moacq': 1,
    'moad': 1,
    'moak': 1,
    'moakley': 2,
    'moami': 2,
    'moammar': 3,
    'moan': 1,
    'moaned': 1,
    'moaning': 2,
    'moans': 1,
    'moat': 1,
    'moates': 2,
    'moats': 1,
    'moawiya': 4,
    'mob': 1,
    'mobay': 2,
    'mobbed': 1,
    'mobbs': 1,
    'mobe': 1,
    'moberg': 2,
    'moberly': 3,
    'mobil': 2,
    'mobile': 2,
    'mobiler': 3,
    'mobiles': 2,
    'mobilia': 4,
    'mobiliare': 3,
    'mobility': 4,
    'mobilization': 5,
    'mobilizations': 5,
    'mobilize': 3,
    'mobilized': 3,
    'mobilizer': 4,
    'mobilizers': 4,
    'mobilizing': 4,
    'mobius': 3,
    'mobley': 2,
    'mobs': 1,
    'mobster': 2,
    'mobsters': 2,
    'mobutu': 3,
    'moby': 2,
    'moca': 2,
    'mocarski': 3,
    'mocatta': 3,
    'moccasin': 3,
    'moccasins': 3,
    'moccia': 2,
    'moccio': 3,
    'moceri': 3,
    'moch': 1,
    'mocha': 2,
    'mochel': 2,
    'mochida': 3,
    'mochizuki': 4,
    'mock': 1,
    'mockby': 2,
    'mocked': 1,
    'mockery': 3,
    'mocking': 2,
    'mockingbird': 3,
    'mockler': 2,
    'mocks': 1,
    'moczygemba': 4,
    'mod': 1,
    'modal': 2,
    'modalism': 4,
    'modalities': 4,
    'modality': 4,
    'modarressi': 4,
    'mode': 1,
    'moded': 2,
    'model': 2,
    'modeled': 2,
    'modeling': 3,
    'modell': 2,
    'modelo': 3,
    'models': 2,
    'modem': 2,
    'modems': 2,
    'moden': 2,
    'modena': 3,
    'moder': 2,
    'moderate': 3,
    'moderated': 4,
    'moderately': 4,
    'moderates': 3,
    'moderating': 4,
    'moderation': 4,
    'moderator': 4,
    'moderators': 4,
    'modern': 2,
    'moderne': 2,
    'modernism': 4,
    'modernist': 3,
    'modernists': 3,
    'modernity': 4,
    'modernization': 5,
    'modernize': 3,
    'modernized': 3,
    'modernizes': 4,
    'modernizing': 4,
    'moderns': 2,
    'modes': 1,
    'modesitt': 3,
    'modest': 2,
    'modesta': 3,
    'modestia': 3,
    'modestine': 4,
    'modestly': 3,
    'modesto': 3,
    'modesty': 3,
    'modglin': 2,
    'modi': 2,
    'modic': 2,
    'modica': 3,
    'modicum': 3,
    'modification': 5,
    'modifications': 5,
    'modified': 3,
    'modifies': 3,
    'modify': 3,
    'modifying': 4,
    'modigliani': 5,
    'modiliani': 5,
    'modine': 2,
    'modisette': 3,
    'modjeski': 3,
    'modlin': 2,
    'modrak': 2,
    'modrow': 2,
    'modugno': 3,
    'modulaire': 3,
    'modular': 3,
    'modulate': 3,
    'modulated': 4,
    'modulation': 4,
    'modulator': 4,
    'module': 2,
    'modules': 2,
    'modus': 2,
    'mody': 2,
    'modzelewski': 4,
    'moe': 1,
    'moebius': 3,
    'moeckel': 2,
    'moede': 1,
    'moehle': 2,
    'moehlman': 2,
    'moehring': 2,
    'moeller': 2,
    'moellering': 3,
    'moen': 1,
    'moench': 1,
    'moening': 3,
    'moerbe': 1,
    'moerke': 1,
    'moerman': 3,
    'moers': 2,
    'moertel': 2,
    'moes': 1,
    'moesch': 1,
    'moeser': 2,
    'moessner': 2,
    'moet': 2,
    'mof': 1,
    'moffa': 2,
    'moffat': 2,
    'moffatt': 2,
    'moffet': 2,
    'moffett': 2,
    'moffit': 2,
    'moffitt': 2,
    'mofford': 2,
    'mofield': 2,
    'moga': 2,
    'mogadishu': 4,
    'mogan': 2,
    'mogavaro': 4,
    'mogavero': 4,
    'mogayon': 3,
    'mogel': 2,
    'mogensen': 3,
    'moger': 2,
    'mogg': 1,
    'mogle': 2,
    'moglia': 3,
    'mogopa': 3,
    'mogul': 2,
    'moguls': 2,
    'mohair': 2,
    'mohamad': 3,
    'mohamadi': 4,
    'mohamed': 3,
    'mohammad': 3,
    'mohammed': 3,
    'mohan': 2,
    'mohar': 2,
    'mohasco': 3,
    'mohawk': 2,
    'mohawks': 2,
    'mohel': 2,
    'moher': 2,
    'mohican': 3,
    'mohicans': 3,
    'mohit': 2,
    'mohl': 1,
    'mohler': 2,
    'mohlman': 2,
    'mohn': 1,
    'mohney': 2,
    'mohnke': 1,
    'mohnkern': 2,
    'mohon': 2,
    'mohr': 1,
    'mohring': 2,
    'mohrman': 2,
    'mohrmann': 2,
    'mohs': 1,
    'mohtashemi': 4,
    'moi': 1,
    'moilanen': 3,
    'moina': 2,
    'moines': 1,
    'moir': 1,
    'moira': 2,
    'moisan': 2,
    'moise': 1,
    'moises': 2,
    'moishe': 2,
    'moisi': 2,
    'moist': 1,
    'moisten': 2,
    'moistened': 2,
    'moisture': 2,
    'moisturizer': 4,
    'mojave': 3,
    'mojica': 3,
    'mojo': 2,
    'mok': 1,
    'moka': 2,
    'moke': 1,
    'mokes': 1,
    'mokhiber': 3,
    'mokoena': 3,
    'mokry': 2,
    'mol': 1,
    'mola': 2,
    'molaison': 3,
    'moland': 2,
    'molander': 3,
    'molano': 3,
    'molasses': 3,
    'molchan': 2,
    'mold': 1,
    'moldable': 3,
    'moldavia': 4,
    'moldavian': 4,
    'molded': 2,
    'molden': 2,
    'moldenhauer': 4,
    'molder': 2,
    'molders': 2,
    'molding': 2,
    'moldings': 2,
    'moldova': 3,
    'moldovan': 3,
    'molds': 1,
    'moldy': 2,
    'mole': 1,
    'molecular': 4,
    'molecule': 3,
    'molecules': 3,
    'moleculon': 4,
    'molehill': 2,
    'molelike': 2,
    'molen': 2,
    'molenaar': 3,
    'molenda': 3,
    'moler': 2,
    'moles': 1,
    'moleski': 3,
    'molesky': 3,
    'molest': 2,
    'molestation': 4,
    'molested': 3,
    'molester': 3,
    'molesters': 3,
    'molesting': 3,
    'molests': 2,
    'molesworth': 2,
    'molex': 2,
    'moliere': 2,
    'molin': 2,
    'molina': 3,
    'molinar': 3,
    'molinari': 4,
    'molinaro': 4,
    'moline': 2,
    'molineaux': 3,
    'molinelli': 4,
    'molino': 3,
    'molitor': 3,
    'moll': 1,
    'molle': 1,
    'mollen': 2,
    'mollenhauer': 4,
    'mollenkopf': 3,
    'moller': 2,
    'mollering': 3,
    'mollet': 2,
    'mollett': 2,
    'molley': 2,
    'mollica': 3,
    'mollie': 2,
    'mollified': 3,
    'mollify': 3,
    'mollison': 3,
    'mollner': 2,
    'mollo': 2,
    'mollohan': 3,
    'molloy': 2,
    'mollusk': 2,
    'mollusks': 2,
    'molly': 2,
    'mollycoddle': 4,
    'molnar': 2,
    'molner': 2,
    'molock': 2,
    'molokai': 3,
    'moloney': 3,
    'molonicks': 3,
    'molony': 3,
    'molotov': 3,
    'molpus': 2,
    'molson': 2,
    'molstad': 2,
    'molt': 1,
    'molten': 2,
    'molter': 2,
    'molting': 2,
    'molton': 2,
    'moltz': 1,
    'molucca': 3,
    'moly': 2,
    'molybdenum': 4,
    'molyneaux': 3,
    'molzahn': 2,
    'mom': 1,
    'moma': 2,
    'moman': 2,
    'momayez': 3,
    'mombasa': 3,
    'moment': 2,
    'momentarily': 5,
    'momentary': 4,
    'momentous': 3,
    'moments': 2,
    'momentum': 3,
    'momigliano': 5,
    'momma': 2,
    'mommens': 2,
    'mommies': 2,
    'mommy': 2,
    'momokawa': 4,
    'moms': 1,
    'mon': 1,
    'mona': 2,
    'monacelli': 4,
    'monachino': 4,
    'monaco': 3,
    'monad': 2,
    'monadnock': 3,
    'monagene': 3,
    'monaghan': 3,
    'monahan': 3,
    'monarch': 2,
    'monarchies': 3,
    'monarchist': 3,
    'monarchists': 3,
    'monarchs': 2,
    'monarchy': 3,
    'monarrez': 3,
    'monash': 2,
    'monasteries': 4,
    'monastery': 4,
    'monastic': 3,
    'monasticism': 5,
    'monatomic': 4,
    'monca': 2,
    'moncada': 3,
    'moncayo': 3,
    'monceaux': 2,
    'moncrief': 2,
    'moncur': 2,
    'moncure': 3,
    'moncus': 2,
    'monda': 2,
    'mondadori': 4,
    'mondale': 2,
    'mondallo': 3,
    'mondavi': 3,
    'monday': 2,
    'mondays': 2,
    'monde': 1,
    'mondelli': 3,
    'mondello': 3,
    'mondeo': 3,
    'mondex': 2,
    'mondo': 2,
    'mondor': 2,
    'mondragon': 3,
    'mondrian': 3,
    'mondry': 2,
    'monds': 1,
    'mondschein': 2,
    'mondy': 2,
    'mone': 1,
    'monell': 2,
    'mones': 1,
    'monessen': 3,
    'monet': 2,
    'monetarily': 5,
    'monetarism': 5,
    'monetarist': 4,
    'monetarists': 4,
    'monetary': 4,
    'monett': 2,
    'monette': 2,
    'money': 2,
    'moneycard': 3,
    'moneycenter': 4,
    'moneyed': 2,
    'moneyless': 3,
    'moneyline': 3,
    'moneymaker': 4,
    'moneymakers': 4,
    'moneymaking': 4,
    'moneypenny': 4,
    'moneys': 2,
    'moneywatch': 3,
    'moneyweek': 3,
    'monfils': 2,
    'monfort': 2,
    'monforte': 3,
    'mong': 1,
    'mongan': 2,
    'monge': 1,
    'mongeau': 2,
    'mongelli': 3,
    'mongeon': 2,
    'monger': 2,
    'mongering': 3,
    'mongers': 2,
    'mongiello': 3,
    'mongillo': 3,
    'mongol': 2,
    'mongold': 2,
    'mongolia': 4,
    'mongolian': 4,
    'mongoloid': 3,
    'mongols': 2,
    'mongoose': 2,
    'mongooses': 3,
    'mongosutu': 4,
    'mongstad': 2,
    'monhollen': 3,
    'monica': 3,
    'monical': 3,
    'monico': 3,
    'monied': 2,
    'monier': 3,
    'monies': 2,
    'monieson': 3,
    'monigold': 3,
    'moniker': 3,
    'monikers': 3,
    'moninger': 3,
    'monique': 2,
    'monism': 3,
    'monisms': 3,
    'monit': 2,
    'monitor': 3,
    'monitored': 3,
    'monitoring': 4,
    'monitors': 3,
    'moniz': 2,
    'monje': 1,
    'monk': 1,
    'monkee': 2,
    'monkees': 2,
    'monkey': 2,
    'monkeying': 3,
    'monkeylike': 3,
    'monkeys': 2,
    'monks': 1,
    'monmouth': 2,
    'monn': 1,
    'monnett': 2,
    'monnier': 3,
    'monnig': 2,
    'monnin': 2,
    'mono': 2,
    'monocarpic': 4,
    'monochromatic': 5,
    'monochrome': 3,
    'monoclate': 3,
    'monocle': 3,
    'monoclinic': 4,
    'monoclonal': 4,
    'monogamous': 4,
    'monogamy': 4,
    'monogram': 3,
    'monogrammed': 3,
    'monograph': 3,
    'monohull': 3,
    'monolingual': 4,
    'monolith': 3,
    'monolithic': 4,
    'monoliths': 3,
    'monologue': 3,
    'monologues': 3,
    'monomer': 3,
    'monomers': 3,
    'monongahela': 5,
    'mononokai': 4,
    'mononuclear': 5,
    'monophonic': 4,
    'monoplane': 3,
    'monoplanes': 3,
    'monopole': 3,
    'monopoles': 3,
    'monopolies': 4,
    'monopolist': 4,
    'monopolistic': 5,
    'monopolization': 6,
    'monopolize': 4,
    'monopolized': 4,
    'monopolizes': 5,
    'monopolizing': 5,
    'monopoly': 4,
    'monorail': 3,
    'monorails': 3,
    'monosaccharide': 5,
    'monoszon': 3,
    'monotheism': 5,
    'monotone': 3,
    'monotonous': 4,
    'monotony': 4,
    'monotype': 3,
    'monovalent': 4,
    'monoxide': 3,
    'monreal': 2,
    'monro': 2,
    'monroe': 2,
    'monroeville': 3,
    'monrovia': 4,
    'monroy': 2,
    'monsanto': 3,
    'monsees': 2,
    'monsen': 2,
    'monsey': 2,
    'monsieur': 2,
    'monsieurs': 2,
    'monsignor': 3,
    'monsignors': 3,
    'monsky': 2,
    'monsod': 2,
    'monson': 2,
    'monsoon': 2,
    'monsoonal': 3,
    'monsoons': 2,
    'monsour': 2,
    'monster': 2,
    'monsters': 2,
    'monstrosity': 4,
    'monstrous': 2,
    'mont': 1,
    'montag': 2,
    'montage': 2,
    'montagna': 3,
    'montagne': 2,
    'montagnier': 3,
    'montagnino': 4,
    'montagu': 3,
    'montague': 3,
    'montalban': 3,
    'montalbano': 4,
    'montalbo': 3,
    'montalto': 3,
    'montalvo': 3,
    'montana': 3,
    'montanan': 3,
    'montanans': 3,
    'montanari': 4,
    'montanaro': 4,
    'montand': 2,
    'montanez': 3,
    'montano': 3,
    'montante': 3,
    'montanye': 3,
    'montavon': 3,
    'montazeri': 4,
    'montblanc': 2,
    'montclair': 2,
    'monte': 2,
    'monteagudo': 4,
    'montebello': 4,
    'montecalvo': 4,
    'montedison': 4,
    'montee': 2,
    'montefiore': 5,
    'monteforte': 4,
    'montefusco': 4,
    'montego': 3,
    'monteil': 2,
    'monteiro': 3,
    'montejano': 4,
    'montel': 2,
    'monteleone': 5,
    'montella': 3,
    'montello': 3,
    'montelongo': 4,
    'montemarano': 5,
    'montemayor': 4,
    'montemurro': 4,
    'montenay': 3,
    'montenegran': 4,
    'montenegro': 4,
    'monter': 2,
    'monterey': 3,
    'montero': 3,
    'monterosso': 4,
    'monterrey': 3,
    'montes': 2,
    'montesano': 4,
    'montesdeoca': 5,
    'montesi': 3,
    'montesino': 4,
    'montessori': 4,
    'monteverde': 4,
    'montevideo': 5,
    'montez': 2,
    'montezuma': 4,
    'montford': 2,
    'montfort': 2,
    'montgomery': 3,
    'montgoris': 3,
    'month': 1,
    'monthlong': 2,
    'monthly': 2,
    'months': 1,
    'monti': 2,
    'monticciolo': 5,
    'monticello': 4,
    'montie': 2,
    'montiel': 3,
    'montien': 3,
    'montier': 2,
    'montieth': 3,
    'montijo': 3,
    'montini': 3,
    'montjoy': 2,
    'montminy': 3,
    'montone': 3,
    'montooth': 2,
    'montoya': 3,
    'montpelier': 4,
    'montreal': 3,
    'montrealer': 4,
    'montrealers': 4,
    'montrone': 2,
    'montrose': 2,
    'montross': 2,
    'montrouis': 3,
    'montroy': 2,
    'monts': 1,
    'montserrat': 3,
    'montuori': 3,
    'montvale': 2,
    'montville': 2,
    'monty': 2,
    'montz': 1,
    'monument': 3,
    'monumental': 4,
    'monumentally': 5,
    'monuments': 3,
    'monus': 2,
    'mony': 2,
    'monzert': 2,
    'monzingo': 3,
    'monzo': 2,
    'monzon': 2,
    'monzonite': 3,
    'moo': 1,
    'mooberry': 3,
    'mood': 1,
    'moodie': 2,
    'moods': 1,
    'moody': 2,
    'mooers': 2,
    'moog': 1,
    'mook': 1,
    'moomaw': 2,
    'moomey': 2,
    'moon': 1,
    'moonbeam': 2,
    'moonbeams': 2,
    'moondreamer': 3,
    'moondreamers': 3,
    'moone': 1,
    'mooney': 2,
    'mooneyham': 3,
    'mooneyhan': 3,
    'moonie': 2,
    'moonies': 2,
    'moonlight': 2,
    'moonlighting': 3,
    'moonlike': 2,
    'moonlit': 2,
    'moons': 1,
    'moonshine': 2,
    'moonstone': 2,
    'moonstones': 2,
    'moonstruck': 2,
    'moonves': 2,
    'moor': 1,
    'mooradian': 4,
    'moorco': 2,
    'moore': 1,
    'moored': 1,
    'moorefield': 3,
    'moorehead': 2,
    'moorehouse': 2,
    'moorer': 2,
    'moores': 1,
    'moorestown': 2,
    'moorhead': 2,
    'moorhouse': 2,
    'mooring': 2,
    'moorings': 2,
    'moorish': 2,
    'moorland': 2,
    'moorlands': 2,
    'moorman': 2,
    'moormann': 2,
    'moors': 1,
    'moos': 1,
    'moosa': 2,
    'moose': 1,
    'moosehead': 2,
    'moosman': 2,
    'moot': 1,
    'moothart': 2,
    'moots': 1,
    'mooty': 2,
    'mootz': 1,
    'mop': 1,
    'mopboard': 2,
    'mope': 1,
    'moped': 1,
    'mopeds': 2,
    'moping': 2,
    'mopped': 1,
    'moppes': 1,
    'mopping': 2,
    'mops': 1,
    'mopus': 2,
    'moquin': 2,
    'mor': 1,
    'mora': 2,
    'morabito': 4,
    'morace': 3,
    'morachov': 3,
    'morad': 2,
    'morado': 3,
    'moraga': 3,
    'moragne': 2,
    'morahan': 3,
    'morain': 2,
    'morainal': 3,
    'moraine': 2,
    'morais': 2,
    'moraitis': 3,
    'moral': 2,
    'morale': 2,
    'morales': 2,
    'moralez': 3,
    'moralism': 4,
    'moralist': 3,
    'moralistic': 4,
    'moralists': 3,
    'morality': 4,
    'moralize': 3,
    'moralizing': 4,
    'morally': 3,
    'morals': 2,
    'moran': 2,
    'morand': 2,
    'morandan': 3,
    'morandi': 3,
    'morando': 3,
    'morang': 2,
    'morano': 3,
    'morant': 2,
    'morante': 3,
    'morash': 2,
    'moraski': 3,
    'morass': 2,
    'morasses': 3,
    'morath': 2,
    'moratoria': 5,
    'moratorium': 5,
    'moratoriums': 5,
    'moravec': 3,
    'moravek': 3,
    'moravian': 4,
    'morawski': 3,
    'moray': 2,
    'morays': 2,
    'morbid': 2,
    'morbidity': 4,
    'morbidly': 3,
    'morby': 2,
    'morcom': 2,
    'mord': 1,
    'mordant': 2,
    'mordecai': 3,
    'mordechai': 3,
    'morden': 2,
    'more': 1,
    'morea': 3,
    'moreau': 2,
    'moredock': 3,
    'moree': 2,
    'moreen': 2,
    'morefield': 3,
    'morehart': 3,
    'morehead': 2,
    'morehouse': 2,
    'moreira': 3,
    'morejon': 3,
    'morel': 2,
    'moreland': 2,
    'morell': 2,
    'morella': 3,
    'morelli': 3,
    'morello': 3,
    'morelock': 3,
    'moreman': 2,
    'moren': 2,
    'morena': 3,
    'morency': 3,
    'moreno': 3,
    'moreover': 3,
    'morera': 3,
    'mores': 2,
    'moresco': 3,
    'moreso': 3,
    'moret': 2,
    'moreton': 3,
    'moretti': 3,
    'moretto': 3,
    'moretz': 2,
    'morever': 3,
    'morey': 2,
    'morfin': 2,
    'morford': 2,
    'morga': 2,
    'morgado': 3,
    'morgan': 2,
    'morgana': 3,
    'morgans': 2,
    'morganstern': 3,
    'morgante': 3,
    'morganti': 3,
    'morgantown': 3,
    'morgart': 2,
    'morgen': 2,
    'morgenroth': 3,
    'morgenstern': 3,
    'morgenthaler': 4,
    'morgenthau': 3,
    'morgue': 1,
    'morgues': 1,
    'morgun': 2,
    'mori': 2,
    'moria': 3,
    'morial': 3,
    'moriarity': 5,
    'moriarty': 4,
    'moribund': 3,
    'moric': 2,
    'morice': 2,
    'morici': 3,
    'morihiro': 4,
    'morikawa': 4,
    'morillo': 3,
    'morillom': 3,
    'morillono': 4,
    'morimoto': 4,
    'morin': 2,
    'morine': 3,
    'moring': 2,
    'morini': 3,
    'morino': 3,
    'morioka': 4,
    'moris': 2,
    'morisette': 3,
    'morishita': 4,
    'morison': 3,
    'morissette': 3,
    'morita': 3,
    'moritz': 2,
    'moriya': 3,
    'morjera': 3,
    'mork': 1,
    'morken': 2,
    'morlan': 2,
    'morland': 2,
    'morley': 2,
    'morlock': 2,
    'morman': 2,
    'mormile': 2,
    'mormino': 3,
    'mormon': 2,
    'mormonism': 3,
    'mormons': 2,
    'morn': 1,
    'morna': 2,
    'morneau': 2,
    'morneault': 2,
    'morning': 2,
    'mornings': 2,
    'morningstar': 3,
    'moro': 2,
    'moroccan': 3,
    'moroccans': 3,
    'morocco': 3,
    'moron': 2,
    'morones': 3,
    'moroney': 3,
    'moroni': 3,
    'moros': 2,
    'morose': 2,
    'morosky': 3,
    'morovcic': 3,
    'morowick': 3,
    'moroz': 2,
    'morph': 1,
    'morphed': 1,
    'morphew': 2,
    'morphin': 2,
    'morphine': 2,
    'morphing': 2,
    'morphis': 2,
    'morphogenesis': 5,
    'morphological': 5,
    'morphology': 4,
    'morphonios': 4,
    'morphs': 1,
    'morr': 1,
    'morra': 2,
    'morrall': 2,
    'morreale': 3,
    'morrell': 2,
    'morren': 2,
    'morrical': 3,
    'morricone': 3,
    'morrie': 2,
    'morrill': 2,
    'morrin': 2,
    'morris': 2,
    'morrisett': 3,
    'morrisette': 3,
    'morrisey': 3,
    'morrish': 2,
    'morrison': 3,
    'morriss': 3,
    'morrissette': 3,
    'morrissey': 3,
    'morristown': 3,
    'morrisville': 3,
    'morro': 2,
    'morrone': 3,
    'morrow': 2,
    'morry': 2,
    'morsch': 1,
    'morse': 1,
    'morsel': 2,
    'morsels': 2,
    'morson': 2,
    'morss': 1,
    'mort': 1,
    'mortal': 2,
    'mortality': 4,
    'mortally': 3,
    'mortals': 2,
    'mortar': 2,
    'mortara': 3,
    'mortars': 2,
    'morteki': 3,
    'mortell': 2,
    'mortellaro': 4,
    'mortem': 2,
    'mortems': 2,
    'morten': 2,
    'mortensen': 3,
    'mortenson': 3,
    'morter': 2,
    'mortgage': 2,
    'mortgaged': 2,
    'mortgagepower': 3,
    'mortgages': 3,
    'mortgaging': 3,
    'mortician': 3,
    'mortie': 2,
    'mortier': 3,
    'mortified': 3,
    'mortify': 3,
    'mortimer': 3,
    'mortimore': 3,
    'mortis': 2,
    'mortland': 2,
    'mortner': 2,
    'morton': 2,
    'mortuary': 4,
    'morty': 2,
    'moruhiro': 4,
    'morvant': 2,
    'morven': 2,
    'morvillo': 3,
    'mory': 2,
    'morze': 1,
    'mos': 1,
    'mosaic': 3,
    'mosaical': 4,
    'mosaicked': 3,
    'mosaics': 3,
    'mosbacher': 3,
    'mosbrucker': 3,
    'mosby': 2,
    'mosca': 2,
    'moscato': 3,
    'moschella': 3,
    'moschetti': 3,
    'mosco': 2,
    'moscom': 2,
    'moscoso': 3,
    'moscow': 2,
    'moscowane': 3,
    'moscowitz': 3,
    'mose': 1,
    'mosel': 2,
    'moseley': 2,
    'moselle': 2,
    'mosely': 2,
    'moseman': 2,
    'moser': 2,
    'moses': 2,
    'mosey': 2,
    'mosh': 1,
    'moshe': 2,
    'mosher': 2,
    'moshier': 3,
    'moshood': 2,
    'moshus': 2,
    'mosie': 2,
    'mosier': 2,
    'mosiman': 3,
    'mosinee': 3,
    'mosk': 1,
    'moskal': 2,
    'mosko': 2,
    'moskolenko': 4,
    'moskovitz': 3,
    'moskow': 2,
    'moskowitz': 3,
    'moskwa': 2,
    'mosle': 2,
    'moslem': 2,
    'moslems': 2,
    'mosler': 3,
    'mosley': 2,
    'mosman': 2,
    'mosque': 1,
    'mosqueda': 3,
    'mosquera': 3,
    'mosques': 1,
    'mosquito': 3,
    'mosquitoes': 3,
    'mosquitos': 3,
    'moss': 1,
    'mossad': 2,
    'mossbacher': 3,
    'mossbarger': 3,
    'mossberg': 2,
    'mossburg': 2,
    'mosser': 2,
    'mosses': 2,
    'mossey': 2,
    'mossholder': 3,
    'mosslike': 2,
    'mossman': 2,
    'mosso': 2,
    'most': 1,
    'mostaert': 2,
    'mostar': 2,
    'mostek': 2,
    'mosteller': 3,
    'moster': 2,
    'mostly': 2,
    'mostoller': 3,
    'mostow': 2,
    'mostrom': 2,
    'mostyn': 2,
    'mosul': 2,
    'mosur': 2,
    'moszkowski': 3,
    'mota': 2,
    'mote': 1,
    'motel': 2,
    'motels': 2,
    'moten': 2,
    'motes': 1,
    'moteurs': 2,
    'moth': 1,
    'mothball': 2,
    'mothballed': 2,
    'mothballing': 3,
    'mothballs': 2,
    'mother': 2,
    'mothercare': 3,
    'motherfucker': 4,
    'motherfuckers': 4,
    'motherhood': 3,
    'mothering': 3,
    'motherland': 3,
    'motherless': 3,
    'motherly': 3,
    'mothers': 2,
    'mothershead': 3,
    'mothershed': 3,
    'mothon': 2,
    'moths': 1,
    'moti': 2,
    'motif': 2,
    'motifs': 2,
    'motile': 2,
    'motility': 4,
    'motion': 2,
    'motioned': 2,
    'motioning': 3,
    'motionless': 3,
    'motions': 2,
    'motivate': 3,
    'motivated': 4,
    'motivates': 3,
    'motivating': 4,
    'motivation': 4,
    'motivational': 5,
    'motivations': 4,
    'motivator': 4,
    'motive': 2,
    'motives': 2,
    'motl': 2,
    'motley': 2,
    'moto': 2,
    'motohashi': 4,
    'motola': 3,
    'moton': 2,
    'motonori': 4,
    'motor': 2,
    'motorbike': 3,
    'motorbikes': 3,
    'motorboat': 3,
    'motorcade': 3,
    'motorcades': 3,
    'motorcar': 3,
    'motorcars': 3,
    'motorcycle': 4,
    'motorcycles': 4,
    'motorcyclist': 5,
    'motorcyclists': 5,
    'motoren': 3,
    'motoring': 3,
    'motorist': 3,
    'motorists': 3,
    'motorize': 3,
    'motorized': 3,
    'motorola': 4,
    'motors': 2,
    'motown': 2,
    'motrin': 2,
    'mots': 1,
    'motsinger': 3,
    'motsoaledi': 4,
    'mott': 1,
    'motte': 1,
    'motter': 2,
    'mottern': 2,
    'mottl': 2,
    'mottled': 2,
    'mottley': 2,
    'mottling': 3,
    'motto': 2,
    'mottola': 3,
    'mottos': 2,
    'mottram': 2,
    'motts': 1,
    'mottus': 2,
    'motyka': 3,
    'motyl': 2,
    'motz': 1,
    'motzer': 2,
    'motzko': 2,
    'moua': 2,
    'moudry': 2,
    'moudy': 2,
    'moueix': 2,
    'moul': 1,
    'mould': 1,
    'moulden': 2,
    'moulder': 2,
    'moulding': 2,
    'mouldings': 2,
    'moulds': 1,
    'mouldy': 2,
    'moulin': 2,
    'moulinex': 3,
    'moulthrop': 2,
    'moulton': 2,
    'moultrie': 2,
    'mounce': 1,
    'mound': 1,
    'mounds': 1,
    'mounger': 2,
    'mounsey': 2,
    'mount': 1,
    'mountain': 2,
    'mountaineer': 3,
    'mountaineering': 4,
    'mountaineers': 3,
    'mountainous': 3,
    'mountains': 2,
    'mountainside': 3,
    'mountainsides': 3,
    'mountaintop': 3,
    'mountaintops': 3,
    'mountcastle': 3,
    'mounted': 2,
    'mountford': 2,
    'mounties': 2,
    'mounting': 2,
    'mountjoy': 2,
    'mountleigh': 2,
    'mounts': 1,
    'mountz': 1,
    'moura': 2,
    'mourad': 2,
    'mouradian': 4,
    'mourer': 2,
    'mourn': 1,
    'mourned': 1,
    'mourner': 2,
    'mourners': 2,
    'mournful': 2,
    'mourning': 2,
    'mourns': 1,
    'mouse': 1,
    'mousehole': 2,
    'mousel': 2,
    'mousepad': 2,
    'mouser': 2,
    'mousers': 2,
    'mousetrap': 2,
    'mousley': 2,
    'moussa': 2,
    'mousse': 1,
    'mousseau': 2,
    'moussorgsky': 3,
    'moustache': 2,
    'mousy': 2,
    'mouth': 1,
    'mouthed': 1,
    'mouthful': 2,
    'mouthing': 2,
    'mouthpart': 2,
    'mouthparts': 2,
    'mouthpiece': 2,
    'mouthpieces': 3,
    'mouths': 1,
    'mouthwash': 2,
    'mouthy': 2,
    'mouton': 2,
    'moutray': 2,
    'moutse': 2,
    'mouw': 1,
    'mouzon': 2,
    'movable': 3,
    'move': 1,
    'moved': 1,
    'movement': 2,
    'movements': 2,
    'mover': 2,
    'movers': 2,
    'moves': 1,
    'movida': 3,
    'movie': 2,
    'moviegoer': 4,
    'moviegoers': 4,
    'moviegoing': 4,
    'movielab': 3,
    'movieline': 3,
    'moviemaker': 4,
    'moviemakers': 4,
    'moviemaking': 4,
    'movies': 2,
    'movietime': 3,
    'moving': 2,
    'movingly': 3,
    'mow': 1,
    'mowat': 2,
    'mowatt': 2,
    'mowbray': 2,
    'mowdy': 2,
    'mowed': 1,
    'mowell': 2,
    'mowen': 2,
    'mower': 2,
    'mowers': 2,
    'mowery': 3,
    'mowing': 2,
    'mowrer': 2,
    'mowrey': 2,
    'mowry': 2,
    'mows': 1,
    'moxie': 2,
    'moxley': 2,
    'moxon': 2,
    'moy': 1,
    'moya': 2,
    'moye': 1,
    'moyer': 2,
    'moyers': 2,
    'moyes': 1,
    'moyl': 1,
    'moylan': 2,
    'moyle': 1,
    'moyna': 2,
    'moynahan': 3,
    'moynihan': 3,
    'moyse': 1,
    'mozambican': 4,
    'mozambicans': 4,
    'mozambique': 3,
    'mozart': 2,
    'mozartean': 4,
    'mozee': 2,
    'mozelle': 2,
    'mozena': 3,
    'mozer': 2,
    'mozingo': 3,
    'mozley': 2,
    'mpeg': 2,
    'mpg': 3,
    'mph': 3,
    'mr': 2,
    'mraz': 1,
    'mrazek': 2,
    'mrazik': 2,
    'mroczek': 2,
    'mroczka': 2,
    'mroczkowski': 3,
    'mrotek': 2,
    'mroz': 1,
    'mrozek': 2,
    'mrozinski': 3,
    'mrs': 2,
    'mruk': 1,
    'ms': 1,
    'msgr': 3,
    'mssrs': 2,
    'mt': 1,
    'mtel': 2,
    'mu': 1,
    'muammar': 3,
    'muavenet': 4,
    'mubarak': 3,
    'mucci': 2,
    'muccio': 3,
    'much': 1,
    'mucha': 2,
    'muchmore': 2,
    'muchnick': 2,
    'mucho': 2,
    'muchow': 2,
    'muck': 1,
    'muckenfuss': 3,
    'muckey': 2,
    'mucking': 2,
    'muckle': 2,
    'muckleroy': 3,
    'muckrake': 2,
    'muckraker': 3,
    'muckraking': 3,
    'mucky': 2,
    'mucosa': 3,
    'mucosal': 3,
    'mucous': 2,
    'mucus': 2,
    'mud': 1,
    'mudd': 1,
    'muddied': 2,
    'muddier': 3,
    'muddle': 2,
    'muddled': 2,
    'muddleheaded': 4,
    'muddles': 2,
    'muddling': 3,
    'muddy': 2,
    'muddying': 3,
    'mudge': 1,
    'mudgett': 2,
    'mudra': 2,
    'mudrick': 2,
    'mudry': 2,
    'muds': 1,
    'mudslide': 2,
    'mudslides': 2,
    'mudslinging': 3,
    'mudwagon': 3,
    'muecke': 1,
    'muegge': 1,
    'muehl': 1,
    'muehlbauer': 3,
    'muehlebach': 2,
    'muehler': 2,
    'mueller': 2,
    'muellner': 2,
    'muench': 1,
    'muenchen': 2,
    'muenchow': 2,
    'muenster': 2,
    'muetzel': 2,
    'muff': 1,
    'muffin': 2,
    'muffins': 2,
    'muffle': 2,
    'muffled': 2,
    'muffler': 2,
    'mufflers': 2,
    'muffles': 2,
    'muffley': 2,
    'muffling': 2,
    'muffoletto': 4,
    'muffs': 1,
    'muffy': 2,
    'mug': 1,
    'mugabe': 3,
    'mugar': 2,
    'mugavero': 4,
    'mugford': 2,
    'mugged': 1,
    'mugger': 2,
    'muggeridge': 3,
    'muggers': 2,
    'mugging': 2,
    'muggings': 2,
    'muggsy': 2,
    'muggy': 2,
    'mughniyeh': 3,
    'mugniyah': 3,
    'mugs': 1,
    'mugu': 2,
    'muha': 2,
    'muhabara': 4,
    'muhamed': 3,
    'muhammad': 3,
    'muhammed': 3,
    'muharram': 3,
    'muhl': 1,
    'muhlbauer': 3,
    'muhlenkamp': 3,
    'muhr': 1,
    'muhs': 1,
    'mui': 2,
    'muilenburg': 3,
    'muir': 1,
    'muirfield': 2,
    'muirhead': 2,
    'muise': 1,
    'mujahadeen': 4,
    'mujahedeen': 4,
    'mujahideen': 4,
    'mujica': 3,
    'mujzel': 2,
    'mukai': 3,
    'mukasey': 3,
    'mukherjee': 3,
    'mukhopadhyay': 4,
    'mukluk': 2,
    'mula': 2,
    'mulanaphy': 4,
    'mulanax': 3,
    'mulatto': 3,
    'mulberry': 3,
    'mulcahey': 3,
    'mulcahy': 3,
    'mulcare': 2,
    'mulch': 1,
    'mulched': 1,
    'mulches': 2,
    'mulching': 2,
    'mulct': 1,
    'mulder': 2,
    'muldoon': 2,
    'muldorfer': 3,
    'muldowney': 3,
    'muldrew': 2,
    'muldrow': 2,
    'mule': 1,
    'mules': 1,
    'mulford': 2,
    'mulgrew': 2,
    'mulhall': 2,
    'mulhearn': 2,
    'mulheren': 3,
    'mulherin': 3,
    'mulhern': 2,
    'mulholland': 3,
    'mulhouse': 2,
    'mulkern': 2,
    'mulkey': 2,
    'mulkins': 2,
    'mull': 1,
    'mullady': 3,
    'mullah': 2,
    'mullahs': 2,
    'mullally': 3,
    'mullaly': 3,
    'mullan': 2,
    'mullane': 2,
    'mullaney': 3,
    'mullany': 3,
    'mullarkey': 3,
    'mulldorfer': 3,
    'mulled': 1,
    'mullen': 2,
    'mullenax': 3,
    'mullendore': 3,
    'mullenix': 3,
    'mullens': 2,
    'muller': 2,
    'mullet': 2,
    'mullett': 2,
    'mullican': 3,
    'mulligan': 3,
    'mulligans': 3,
    'mulliken': 3,
    'mullikin': 3,
    'mullin': 2,
    'mullinax': 3,
    'mullineaux': 3,
    'mulling': 2,
    'mullings': 2,
    'mullinix': 3,
    'mullins': 2,
    'mullis': 2,
    'mulloy': 2,
    'mulls': 1,
    'mulnix': 2,
    'mulqueen': 2,
    'mulroney': 3,
    'mulrooney': 3,
    'mulroy': 2,
    'mulry': 2,
    'multer': 2,
    'multi': 2,
    'multibank': 3,
    'multibillion': 4,
    'multicandidate': 5,
    'multicenter': 4,
    'multicolor': 4,
    'multicolored': 4,
    'multicultural': 5,
    'multiculturalism': 7,
    'multidex': 3,
    'multiemployer': 5,
    'multiethnic': 4,
    'multifacet': 4,
    'multifaceted': 5,
    'multifamily': 5,
    'multifoods': 3,
    'multilateral': 5,
    'multilateralism': 7,
    'multilaterally': 6,
    'multilayer': 4,
    'multilayered': 4,
    'multilevel': 4,
    'multiline': 3,
    'multilingual': 4,
    'multimarket': 4,
    'multimate': 3,
    'multimedia': 5,
    'multimillion': 4,
    'multimillionaire': 5,
    'multimillionaires': 5,
    'multinational': 5,
    'multinationals': 5,
    'multipart': 3,
    'multiparty': 4,
    'multiplayer': 4,
    'multiple': 3,
    'multiples': 3,
    'multiplex': 3,
    'multiplexer': 4,
    'multiplexers': 4,
    'multiplication': 5,
    'multiplicity': 5,
    'multiplied': 3,
    'multiplier': 4,
    'multiplies': 3,
    'multiply': 3,
    'multiplying': 4,
    'multiprocessor': 5,
    'multipurpose': 4,
    'multiracial': 4,
    'multistate': 3,
    'multistory': 4,
    'multitask': 3,
    'multitasking': 4,
    'multitude': 3,
    'multitudes': 3,
    'multiuser': 4,
    'multivalve': 3,
    'multivision': 4,
    'multiyear': 3,
    'multnomah': 3,
    'mulvaney': 3,
    'mulvany': 3,
    'mulvehill': 2,
    'mulvey': 2,
    'mulvihill': 3,
    'mum': 1,
    'muma': 2,
    'mumaw': 2,
    'mumble': 2,
    'mumbled': 2,
    'mumbles': 2,
    'mumbling': 3,
    'mumbo': 2,
    'mumby': 2,
    'mumford': 2,
    'mumia': 3,
    'mumm': 1,
    'mumma': 2,
    'mumme': 1,
    'mummert': 2,
    'mummey': 2,
    'mummies': 2,
    'mummification': 5,
    'mummified': 3,
    'mummify': 3,
    'mummifying': 4,
    'mummy': 2,
    'mumper': 2,
    'mumphrey': 2,
    'mumpower': 3,
    'mumps': 1,
    'mums': 1,
    'mun': 1,
    'munafo': 3,
    'munce': 1,
    'muncee': 2,
    'muncey': 2,
    'munch': 1,
    'munchausen': 3,
    'munched': 1,
    'munchies': 2,
    'munching': 2,
    'muncie': 2,
    'muncy': 2,
    'mund': 1,
    'mundane': 2,
    'munday': 2,
    'mundell': 2,
    'munden': 2,
    'mundi': 2,
    'mundie': 2,
    'mundinger': 3,
    'mundis': 2,
    'mundlow': 2,
    'mundo': 2,
    'mundorf': 2,
    'mundorff': 2,
    'mundt': 1,
    'mundy': 2,
    'munford': 2,
    'munger': 2,
    'mungia': 2,
    'mungin': 2,
    'mungle': 2,
    'mungo': 2,
    'munguia': 3,
    'muni': 2,
    'munich': 2,
    'municipal': 4,
    'municipalities': 6,
    'municipality': 6,
    'municipally': 5,
    'municipals': 4,
    'munier': 3,
    'munis': 2,
    'munition': 3,
    'munitions': 3,
    'muniz': 2,
    'munk': 1,
    'munkres': 2,
    'munley': 2,
    'munn': 1,
    'munnell': 2,
    'munnerlyn': 3,
    'munni': 2,
    'munns': 1,
    'munos': 2,
    'munoz': 2,
    'munro': 2,
    'munroe': 2,
    'muns': 1,
    'munsch': 1,
    'munsell': 2,
    'munsey': 2,
    'munsingwear': 3,
    'munson': 2,
    'munster': 2,
    'munsterman': 3,
    'munt': 1,
    'muntean': 2,
    'munter': 2,
    'muntz': 1,
    'munyan': 2,
    'munyon': 2,
    'munz': 1,
    'munzer': 2,
    'muoio': 3,
    'muolo': 2,
    'muppet': 2,
    'muppets': 2,
    'mura': 2,
    'murad': 2,
    'murai': 2,
    'murakami': 4,
    'mural': 2,
    'murali': 3,
    'murals': 2,
    'muramatsu': 4,
    'muramoto': 4,
    'muranaga': 4,
    'murano': 3,
    'muraoka': 4,
    'murase': 2,
    'muraski': 3,
    'murasky': 3,
    'murata': 3,
    'muratore': 4,
    'muravich': 3,
    'murawski': 3,
    'murayama': 4,
    'murch': 1,
    'murchie': 2,
    'murchinson': 3,
    'murchison': 3,
    'murdaugh': 2,
    'murden': 2,
    'murder': 2,
    'murdered': 2,
    'murderer': 3,
    'murderers': 3,
    'murdering': 3,
    'murderous': 3,
    'murders': 2,
    'murdick': 2,
    'murdoch': 2,
    'murdock': 2,
    'murdocks': 2,
    'murdy': 2,
    'murff': 1,
    'murfin': 2,
    'murguia': 3,
    'muri': 2,
    'murial': 3,
    'murias': 3,
    'muriel': 3,
    'murihuro': 4,
    'murillo': 3,
    'murin': 2,
    'murine': 2,
    'murjani': 3,
    'murk': 1,
    'murkier': 3,
    'murkowski': 3,
    'murky': 2,
    'murley': 2,
    'murmansk': 2,
    'murmur': 2,
    'murmured': 2,
    'murmuring': 3,
    'murmurs': 2,
    'murnan': 2,
    'murnane': 2,
    'murnian': 3,
    'muro': 2,
    'murph': 1,
    'murphey': 2,
    'murphree': 2,
    'murphrey': 2,
    'murphy': 2,
    'murphys': 2,
    'murr': 1,
    'murrah': 2,
    'murray': 2,
    'murree': 2,
    'murrelet': 2,
    'murrell': 2,
    'murren': 2,
    'murrey': 2,
    'murrie': 2,
    'murrieta': 3,
    'murrill': 2,
    'murrin': 2,
    'murrow': 2,
    'murry': 2,
    'murtagh': 2,
    'murtaugh': 2,
    'murtha': 2,
    'murthy': 2,
    'murto': 2,
    'murton': 2,
    'murty': 2,
    'murveit': 2,
    'murzyn': 2,
    'musa': 2,
    'musacchio': 4,
    'musalo': 3,
    'musante': 3,
    'musarra': 3,
    'musavi': 3,
    'musburger': 3,
    'muscarella': 4,
    'muscarello': 4,
    'muscat': 2,
    'muscatine': 3,
    'muscato': 3,
    'musch': 1,
    'muscle': 2,
    'muscled': 2,
    'muscles': 2,
    'muscling': 3,
    'musco': 2,
    'muscocho': 3,
    'muscovite': 3,
    'muscovites': 3,
    'muscular': 3,
    'musculature': 4,
    'muse': 1,
    'mused': 1,
    'musee': 2,
    'muses': 2,
    'museum': 3,
    'museums': 3,
    'musgrave': 2,
    'musgrove': 2,
    'mush': 1,
    'musha': 2,
    'musher': 2,
    'mushers': 2,
    'mushroom': 2,
    'mushroomed': 2,
    'mushrooming': 3,
    'mushrooms': 2,
    'mushrush': 2,
    'mushtaq': 2,
    'mushy': 2,
    'musial': 3,
    'music': 2,
    'musica': 3,
    'musical': 3,
    'musicality': 5,
    'musically': 3,
    'musicals': 3,
    'musich': 2,
    'musician': 3,
    'musicians': 3,
    'musicianship': 4,
    'musick': 2,
    'musicland': 3,
    'musicologist': 5,
    'musics': 2,
    'musidora': 4,
    'musil': 2,
    'musing': 2,
    'musings': 2,
    'musk': 1,
    'muska': 2,
    'muske': 1,
    'muskegon': 3,
    'musket': 2,
    'musketeer': 3,
    'musketeers': 3,
    'muskie': 2,
    'muskmelon': 3,
    'muskogee': 3,
    'muskopf': 2,
    'muskrat': 2,
    'muskrats': 2,
    'musky': 2,
    'muslim': 2,
    'muslims': 2,
    'muslin': 2,
    'musman': 2,
    'musolf': 2,
    'musolino': 4,
    'musschenbroek': 3,
    'mussel': 2,
    'mussell': 2,
    'musselman': 3,
    'mussels': 2,
    'musselwhite': 3,
    'musser': 2,
    'mussman': 2,
    'musso': 2,
    'mussolini': 4,
    'musson': 2,
    'must': 1,
    'mustache': 2,
    'mustached': 2,
    'mustaches': 3,
    'mustachio': 4,
    'mustachioed': 4,
    'mustafa': 3,
    'mustain': 2,
    'mustang': 2,
    'mustangs': 2,
    'mustard': 2,
    'mustards': 2,
    'muster': 2,
    'mustered': 2,
    'mustering': 3,
    'musters': 2,
    'mustin': 2,
    'musto': 2,
    'mustoe': 2,
    'muston': 2,
    'musty': 2,
    'musumeci': 4,
    'muszynski': 3,
    'mutable': 3,
    'mutagen': 3,
    'mutagens': 3,
    'mutant': 2,
    'mutants': 2,
    'mutate': 2,
    'mutated': 3,
    'mutating': 3,
    'mutation': 3,
    'mutations': 3,
    'mutch': 1,
    'mutchler': 2,
    'mute': 1,
    'muted': 2,
    'mutert': 2,
    'mutes': 1,
    'muth': 1,
    'muti': 2,
    'mutilate': 3,
    'mutilated': 4,
    'mutilating': 4,
    'mutilation': 4,
    'mutilations': 4,
    'mutineer': 3,
    'mutineers': 3,
    'muting': 2,
    'mutinied': 3,
    'mutinies': 3,
    'mutinous': 3,
    'mutiny': 3,
    'mutlangen': 3,
    'mutsch': 1,
    'mutschler': 2,
    'mutt': 1,
    'mutter': 2,
    'muttered': 2,
    'muttering': 3,
    'mutterings': 3,
    'mutters': 2,
    'mutton': 2,
    'mutts': 1,
    'mutual': 3,
    'mutualism': 5,
    'mutuality': 5,
    'mutually': 4,
    'mutz': 1,
    'mutzich': 2,
    'mutzman': 2,
    'muzak': 2,
    'muzik': 2,
    'muzio': 3,
    'muzyka': 3,
    'muzzey': 2,
    'muzzle': 2,
    'muzzled': 2,
    'muzzles': 2,
    'muzzy': 2,
    'mwanza': 2,
    'my': 1,
    'myanmar': 2,
    'myatt': 2,
    'mycenaean': 4,
    'mycogen': 3,
    'mycology': 4,
    'myelin': 3,
    'myer': 2,
    'myers': 2,
    'myerson': 2,
    'myette': 2,
    'myhand': 2,
    'myhre': 1,
    'myhren': 2,
    'myklebust': 3,
    'myla': 2,
    'mylan': 2,
    'mylar': 2,
    'myler': 2,
    'myles': 1,
    'mylod': 2,
    'myna': 2,
    'mynatt': 2,
    'mynhier': 3,
    'myocardial': 5,
    'myocardium': 5,
    'myojin': 2,
    'myopia': 4,
    'myopic': 3,
    'myoscint': 2,
    'myosin': 3,
    'myotrophin': 4,
    'myra': 2,
    'myre': 1,
    'myren': 2,
    'myreon': 3,
    'myres': 1,
    'myriad': 3,
    'myriam': 3,
    'myrick': 2,
    'myrilla': 3,
    'myrlene': 2,
    'myrlie': 2,
    'myrmidon': 3,
    'myrmidons': 3,
    'myrna': 2,
    'myron': 2,
    'myrrh': 1,
    'myrta': 2,
    'myrtia': 2,
    'myrtice': 2,
    'myrtis': 2,
    'myrtle': 2,
    'myrtles': 2,
    'myself': 2,
    'myslinski': 3,
    'mysliwiec': 3,
    'mystere': 2,
    'mysteries': 3,
    'mysterious': 4,
    'mysteriously': 5,
    'mysterium': 4,
    'mystery': 3,
    'mystic': 2,
    'mystical': 3,
    'mysticism': 4,
    'mystics': 2,
    'mystified': 3,
    'mystifies': 3,
    'mystify': 3,
    'mystifying': 4,
    'mystique': 2,
    'myszka': 2,
    'myth': 1,
    'mythic': 2,
    'mythical': 3,
    'mythological': 5,
    'mythology': 4,
    'myths': 1,
    'myung': 1,
    'n': 1,
    'na': 1,
    'naab': 1,
    'naas': 1,
    'nab': 1,
    'nabb': 1,
    'nabbed': 1,
    'nabbing': 2,
    'naber': 2,
    'nabers': 2,
    'nabi': 2,
    'nabil': 2,
    'nabisco': 3,
    'nablus': 2,
    'nabob': 2,
    'nabobs': 2,
    'nabokov': 3,
    'nabors': 2,
    'nabozny': 3,
    'nabretta': 3,
    'naccarato': 4,
    'nacchio': 3,
    'nacco': 2,
    'nace': 1,
    'nachbar': 2,
    'nachman': 2,
    'nachmany': 3,
    'nacho': 2,
    'nachos': 2,
    'nachreiner': 3,
    'nachtigal': 3,
    'nacional': 4,
    'nack': 1,
    'nacke': 1,
    'naclerio': 4,
    'nacobre': 3,
    'nacogdoches': 4,
    'nad': 1,
    'nada': 2,
    'nadal': 3,
    'nadeau': 2,
    'nadein': 2,
    'nadel': 2,
    'nadell': 2,
    'nader': 2,
    'naderite': 3,
    'naderites': 3,
    'nadgwick': 2,
    'nadia': 2,
    'nadine': 2,
    'nading': 2,
    'nadir': 2,
    'nadja': 2,
    'nadjen': 2,
    'nadler': 3,
    'nadolny': 3,
    'nadolski': 3,
    'naef': 1,
    'naegele': 2,
    'naegeli': 3,
    'naeger': 2,
    'naeve': 1,
    'nafco': 2,
    'naff': 1,
    'naffziger': 3,
    'nafta': 2,
    'naftalis': 3,
    'nafziger': 3,
    'nag': 1,
    'nagai': 3,
    'nagano': 3,
    'nagao': 3,
    'nagasaki': 4,
    'nagata': 3,
    'nagel': 2,
    'nagele': 2,
    'nagengast': 3,
    'nager': 2,
    'nagged': 1,
    'nagging': 2,
    'nagi': 2,
    'nagin': 2,
    'nagle': 2,
    'nagler': 2,
    'nagorno': 3,
    'nagorski': 3,
    'nagoya': 3,
    'nags': 1,
    'nagy': 2,
    'nagymaros': 4,
    'nah': 1,
    'nahas': 2,
    'nahm': 1,
    'nahmias': 3,
    'nahum': 2,
    'naidoo': 2,
    'naify': 2,
    'naik': 1,
    'nail': 1,
    'nailed': 1,
    'nailing': 2,
    'naill': 1,
    'nailor': 2,
    'nails': 1,
    'naim': 2,
    'naiman': 2,
    'nair': 1,
    'naira': 2,
    'nairn': 1,
    'nairne': 1,
    'nairobi': 3,
    'naisbitt': 2,
    'naish': 2,
    'naismith': 2,
    'naito': 2,
    'naive': 2,
    'naively': 3,
    'naivete': 4,
    'najar': 2,
    'najarian': 4,
    'najera': 3,
    'najib': 2,
    'najibullah': 4,
    'najjar': 2,
    'nakagawa': 4,
    'nakahara': 4,
    'nakai': 3,
    'nakajima': 4,
    'nakama': 3,
    'nakamoto': 4,
    'nakamura': 4,
    'nakanishi': 4,
    'nakano': 3,
    'nakao': 3,
    'nakashima': 4,
    'nakasone': 4,
    'nakata': 3,
    'nakatani': 4,
    'nakayama': 4,
    'naked': 2,
    'nakhamkin': 3,
    'nalbandian': 4,
    'nalbone': 2,
    'nalcap': 2,
    'nalco': 2,
    'naldo': 2,
    'nale': 1,
    'nalepa': 3,
    'nall': 1,
    'nalley': 2,
    'nalls': 1,
    'nally': 2,
    'nam': 1,
    'namara': 3,
    'nambi': 2,
    'nambla': 2,
    'name': 1,
    'named': 1,
    'nameless': 2,
    'namely': 2,
    'nameplate': 2,
    'nameplates': 2,
    'namer': 2,
    'names': 1,
    'namesake': 2,
    'namesakes': 2,
    'namibia': 4,
    'namibian': 4,
    'naming': 2,
    'namis': 2,
    'namphy': 2,
    'nan': 1,
    'nana': 2,
    'nancarrow': 3,
    'nance': 1,
    'nanchang': 2,
    'nancy': 2,
    'nandaime': 2,
    'nandi': 2,
    'nandita': 3,
    'nando': 2,
    'nandonet': 3,
    'nanette': 2,
    'nanez': 2,
    'nang': 1,
    'nangaku': 3,
    'nangle': 2,
    'nania': 3,
    'nanjing': 2,
    'nankai': 2,
    'nankervis': 3,
    'nanking': 2,
    'nanna': 2,
    'nanney': 2,
    'nanni': 2,
    'nannies': 2,
    'nanninga': 3,
    'nannini': 3,
    'nanny': 2,
    'nano': 2,
    'nanobaby': 4,
    'nanogram': 3,
    'nanograms': 3,
    'nanos': 2,
    'nanosecond': 4,
    'nanoseconds': 4,
    'nantes': 1,
    'nantucket': 3,
    'nantz': 1,
    'naoki': 3,
    'naoma': 3,
    'naomi': 3,
    'nap': 1,
    'napa': 2,
    'napalm': 2,
    'napco': 2,
    'napea': 3,
    'naperville': 3,
    'naphtha': 2,
    'naphthalene': 3,
    'napier': 3,
    'napierala': 4,
    'napkin': 2,
    'napkins': 2,
    'naples': 2,
    'napoleon': 4,
    'napoleonic': 5,
    'napoles': 2,
    'napoletano': 5,
    'napoli': 3,
    'napolitan': 4,
    'napolitano': 5,
    'napora': 3,
    'napp': 1,
    'nappa': 2,
    'napped': 1,
    'napper': 2,
    'nappi': 2,
    'nappier': 3,
    'napping': 2,
    'nappo': 2,
    'naprosyn': 3,
    'naps': 1,
    'naquin': 2,
    'naqvi': 2,
    'nara': 2,
    'naral': 2,
    'naramore': 3,
    'naranja': 3,
    'naranjo': 3,
    'narasimha': 4,
    'narasimhan': 4,
    'narayan': 3,
    'narayanan': 4,
    'narazaki': 4,
    'narc': 1,
    'narciso': 3,
    'narcisse': 2,
    'narcissism': 4,
    'narcissist': 3,
    'narcissistic': 4,
    'narcissus': 3,
    'narco': 2,
    'narcolepsy': 4,
    'narcos': 2,
    'narcotic': 3,
    'narcotics': 3,
    'nard': 1,
    'narda': 2,
    'nardella': 3,
    'nardelli': 3,
    'nardi': 2,
    'nardiello': 4,
    'nardini': 3,
    'nardo': 2,
    'nardone': 3,
    'nardozzi': 3,
    'narducci': 3,
    'nares': 1,
    'narita': 3,
    'narjes': 2,
    'narlikar': 3,
    'narlock': 2,
    'narly': 2,
    'naro': 2,
    'narodny': 3,
    'naron': 2,
    'narragansett': 4,
    'narramore': 3,
    'narrate': 2,
    'narrated': 3,
    'narrates': 2,
    'narration': 3,
    'narrative': 3,
    'narratives': 3,
    'narrator': 3,
    'narrators': 3,
    'narron': 2,
    'narrow': 2,
    'narrowed': 2,
    'narrower': 3,
    'narrowest': 3,
    'narrowing': 3,
    'narrowly': 3,
    'narrowness': 3,
    'narrows': 2,
    'nars': 1,
    'naruhito': 4,
    'narum': 2,
    'narvaez': 3,
    'narveson': 3,
    'nary': 2,
    'nasa': 2,
    'nasal': 2,
    'nasally': 3,
    'nasby': 2,
    'nasca': 2,
    'nascar': 2,
    'nascent': 2,
    'nascimento': 4,
    'nasco': 2,
    'nasdaq': 2,
    'nase': 1,
    'naser': 2,
    'nash': 1,
    'nashashibi': 4,
    'nashburg': 2,
    'nashnamie': 3,
    'nashua': 3,
    'nashville': 2,
    'nasional': 4,
    'naslund': 2,
    'naso': 2,
    'nason': 2,
    'nasons': 2,
    'nasopharynx': 4,
    'nasr': 2,
    'nasrullah': 3,
    'nass': 1,
    'nassar': 2,
    'nassau': 2,
    'nasser': 2,
    'nassif': 2,
    'nast': 1,
    'nasta': 2,
    'nastasi': 3,
    'nastier': 3,
    'nastiest': 3,
    'nastiness': 3,
    'nasty': 2,
    'nasworthy': 3,
    'nat': 1,
    'nata': 2,
    'natal': 2,
    'natala': 3,
    'natale': 3,
    'natali': 3,
    'natalia': 3,
    'natalie': 3,
    'natalle': 3,
    'natan': 2,
    'natasha': 3,
    'natcher': 2,
    'natchez': 2,
    'natchitoches': 3,
    'nate': 1,
    'nath': 1,
    'nathalia': 4,
    'nathalie': 3,
    'nathan': 2,
    'nathanael': 3,
    'nathania': 4,
    'nathaniel': 3,
    'nathanson': 3,
    'nathe': 1,
    'nathene': 2,
    'natick': 2,
    'nation': 2,
    'nationair': 3,
    'national': 3,
    'nationale': 4,
    'nationales': 4,
    'nationalism': 5,
    'nationalist': 4,
    'nationalistic': 5,
    'nationalists': 4,
    'nationalities': 5,
    'nationality': 5,
    'nationalization': 6,
    'nationalizations': 6,
    'nationalize': 4,
    'nationalized': 4,
    'nationalizes': 5,
    'nationalizing': 5,
    'nationally': 4,
    'nationals': 3,
    'nationhood': 3,
    'nations': 2,
    'nationsbanc': 3,
    'nationsbank': 3,
    'nationwide': 3,
    'native': 2,
    'natives': 2,
    'natividad': 4,
    'nativism': 4,
    'nativist': 3,
    'nativity': 4,
    'nato': 2,
    'natoli': 3,
    'natomas': 3,
    'nats': 1,
    'natsios': 3,
    'natter': 2,
    'nattering': 3,
    'nattie': 2,
    'nattily': 3,
    'natty': 2,
    'natuna': 3,
    'natural': 3,
    'naturalism': 5,
    'naturalist': 4,
    'naturalistic': 5,
    'naturalists': 4,
    'naturalization': 6,
    'naturalize': 4,
    'naturalized': 4,
    'naturalizes': 5,
    'naturalizing': 5,
    'naturally': 4,
    'nature': 2,
    'natured': 2,
    'naturedly': 3,
    'natures': 2,
    'natwest': 2,
    'natzke': 2,
    'nau': 1,
    'nauer': 2,
    'nauert': 2,
    'naugatuck': 3,
    'naught': 1,
    'naughton': 2,
    'naughty': 2,
    'naugle': 2,
    'naugles': 2,
    'nault': 1,
    'nauman': 2,
    'naumann': 2,
    'naus': 1,
    'nausea': 3,
    'nauseam': 2,
    'nauseate': 3,
    'nauseated': 4,
    'nauseating': 4,
    'nauseous': 2,
    'nauss': 1,
    'nauta': 3,
    'nautica': 3,
    'nautical': 3,
    'nautilus': 3,
    'nav': 1,
    'nava': 2,
    'navajo': 3,
    'navajos': 3,
    'naval': 2,
    'navar': 2,
    'navarette': 3,
    'navarra': 3,
    'navarre': 2,
    'navarrete': 3,
    'navarrette': 3,
    'navarro': 3,
    'navas': 2,
    'navcom': 2,
    'nave': 1,
    'navel': 2,
    'navellier': 3,
    'navickas': 3,
    'navies': 2,
    'navigable': 4,
    'navigate': 3,
    'navigated': 4,
    'navigates': 3,
    'navigating': 4,
    'navigation': 4,
    'navigational': 5,
    'navigator': 4,
    'navigators': 4,
    'navin': 2,
    'navis': 2,
    'navistar': 3,
    'navlab': 2,
    'navratilova': 5,
    'navstar': 2,
    'navy': 2,
    'naw': 1,
    'nawrocki': 3,
    'nawrot': 2,
    'nay': 1,
    'nayden': 2,
    'naylor': 2,
    'nays': 1,
    'naysayer': 3,
    'naysayers': 3,
    'nayyar': 2,
    'nazar': 2,
    'nazarbayev': 4,
    'nazareth': 3,
    'nazarian': 4,
    'nazario': 4,
    'nazer': 2,
    'nazi': 2,
    'nazionale': 5,
    'nazis': 2,
    'nazism': 3,
    'nazzaro': 3,
    'ndau': 2,
    'ne': 1,
    'neace': 1,
    'nead': 1,
    'neagle': 2,
    'neal': 1,
    'neala': 2,
    'neale': 1,
    'nealey': 2,
    'nealis': 2,
    'neall': 1,
    'nealon': 2,
    'nealson': 2,
    'nealy': 2,
    'neanderthal': 4,
    'neanderthals': 4,
    'neapolitan': 5,
    'near': 1,
    'nearby': 2,
    'neared': 1,
    'nearer': 2,
    'nearest': 2,
    'nearhood': 2,
    'nearing': 2,
    'nearly': 2,
    'nears': 1,
    'nearsighted': 3,
    'nearsightedness': 4,
    'neary': 2,
    'neas': 1,
    'nease': 1,
    'neat': 1,
    'neater': 2,
    'neatest': 2,
    'neathery': 3,
    'neatly': 2,
    'neatness': 2,
    'neault': 1,
    'neave': 1,
    'neaves': 1,
    'nebeker': 3,
    'nebel': 2,
    'nebergall': 3,
    'neblett': 2,
    'nebraska': 3,
    'nebraskan': 3,
    'nebraskans': 3,
    'nebula': 3,
    'nebulizer': 4,
    'nebulous': 3,
    'nec': 1,
    'necaise': 2,
    'necci': 2,
    'necessarily': 5,
    'necessary': 4,
    'necessitate': 4,
    'necessitated': 5,
    'necessitates': 4,
    'necessitating': 5,
    'necessities': 4,
    'necessity': 4,
    'nechayev': 3,
    'nechyba': 3,
    'neck': 1,
    'necked': 1,
    'necker': 2,
    'necklace': 2,
    'necklaces': 3,
    'necklacing': 3,
    'necks': 1,
    'necktie': 2,
    'neckties': 2,
    'neckwear': 2,
    'neco': 2,
    'necrologist': 4,
    'necrologists': 4,
    'necrology': 4,
    'necromancer': 4,
    'necromancy': 4,
    'necropolis': 4,
    'necrosis': 3,
    'nectar': 2,
    'necula': 3,
    'ned': 1,
    'neda': 2,
    'nedd': 1,
    'nedda': 2,
    'neddick': 2,
    'neddo': 2,
    'nedeau': 2,
    'nedel': 2,
    'nederland': 3,
    'nederlanden': 4,
    'nederlander': 4,
    'nederlandsche': 4,
    'nederlandse': 4,
    'nedlloyd': 3,
    'nedrow': 2,
    'nedved': 2,
    'nee': 1,
    'neeb': 1,
    'neece': 1,
    'neeco': 2,
    'need': 1,
    'needed': 2,
    'needelman': 3,
    'needful': 2,
    'needham': 2,
    'needier': 3,
    'neediest': 3,
    'needing': 2,
    'needle': 2,
    'needled': 2,
    'needlefish': 3,
    'needlelike': 3,
    'needleman': 3,
    'needlepoint': 3,
    'needler': 3,
    'needles': 2,
    'needless': 2,
    'needlessly': 3,
    'needlework': 3,
    'needling': 2,
    'needs': 1,
    'needy': 2,
    'neef': 1,
    'neel': 1,
    'neeld': 1,
    'neeley': 2,
    'neels': 1,
    'neely': 2,
    'neeman': 2,
    'neenan': 2,
    'neeper': 2,
    'neer': 1,
    'nees': 1,
    'neese': 1,
    'neeson': 2,
    'neet': 1,
    'nefarious': 4,
    'neff': 1,
    'neft': 1,
    'neftegorsk': 3,
    'neg': 1,
    'negara': 3,
    'negate': 2,
    'negated': 3,
    'negates': 2,
    'negating': 3,
    'negation': 3,
    'negative': 3,
    'negatively': 4,
    'negatives': 3,
    'negativism': 5,
    'negativity': 5,
    'negatron': 3,
    'negatrons': 3,
    'negent': 2,
    'negev': 2,
    'neglect': 2,
    'neglected': 3,
    'neglectful': 3,
    'neglecting': 3,
    'neglects': 2,
    'negley': 2,
    'neglia': 3,
    'negligence': 3,
    'negligent': 3,
    'negligently': 4,
    'negligible': 4,
    'negotiable': 4,
    'negotiate': 4,
    'negotiated': 5,
    'negotiates': 4,
    'negotiating': 5,
    'negotiation': 5,
    'negotiations': 5,
    'negotiator': 5,
    'negotiators': 5,
    'negrete': 2,
    'negri': 2,
    'negrin': 2,
    'negro': 2,
    'negroes': 2,
    'negroid': 2,
    'negron': 2,
    'negroni': 3,
    'negropalpus': 4,
    'negros': 2,
    'negs': 1,
    'negu': 2,
    'negus': 2,
    'nehemiah': 4,
    'neher': 2,
    'nehls': 1,
    'nehring': 2,
    'nehru': 2,
    'neibauer': 3,
    'neiberg': 2,
    'neibert': 2,
    'neice': 1,
    'neider': 2,
    'neidhardt': 2,
    'neidhart': 2,
    'neidigh': 2,
    'neidl': 2,
    'neidlinger': 4,
    'neier': 2,
    'neifert': 2,
    'neiger': 2,
    'neighbor': 2,
    'neighborhood': 3,
    'neighborhoods': 3,
    'neighboring': 3,
    'neighborly': 3,
    'neighbors': 2,
    'neighmond': 2,
    'neikirk': 2,
    'neil': 1,
    'neilan': 2,
    'neild': 1,
    'neile': 1,
    'neill': 1,
    'neils': 1,
    'neilsen': 2,
    'neilson': 2,
    'neiman': 2,
    'neimeyer': 3,
    'nein': 1,
    'neira': 2,
    'neis': 1,
    'neisen': 2,
    'neises': 2,
    'neisler': 3,
    'neiss': 1,
    'neiswender': 3,
    'neither': 2,
    'neitz': 1,
    'neitzel': 2,
    'neitzke': 2,
    'nej': 1,
    'nejamatin': 4,
    'nekia': 3,
    'nekoosa': 3,
    'nekton': 2,
    'nel': 1,
    'nela': 2,
    'nelda': 2,
    'nelia': 3,
    'nelina': 3,
    'nelita': 3,
    'nell': 1,
    'nella': 2,
    'nellcor': 2,
    'nelle': 1,
    'neller': 2,
    'nelles': 1,
    'nellette': 2,
    'nelli': 2,
    'nelliana': 4,
    'nellie': 2,
    'nelligan': 3,
    'nellis': 2,
    'nellwyn': 2,
    'nelly': 2,
    'nelms': 1,
    'neloms': 2,
    'nelon': 2,
    'nels': 1,
    'nelsen': 2,
    'nelson': 2,
    'nematode': 3,
    'nematodes': 3,
    'nemean': 3,
    'nemec': 2,
    'nemecek': 3,
    'nemer': 2,
    'nemeroff': 3,
    'nemes': 1,
    'nemesis': 3,
    'nemeth': 2,
    'nemetz': 2,
    'nemir': 2,
    'nemitz': 2,
    'nemmers': 2,
    'nemo': 2,
    'nemos': 2,
    'nemours': 2,
    'nendick': 2,
    'nene': 1,
    'nenet': 2,
    'neni': 2,
    'nenninger': 3,
    'neo': 2,
    'neoax': 3,
    'neoclassic': 4,
    'neoclassical': 5,
    'neoconservative': 6,
    'neoconservatives': 6,
    'neola': 3,
    'neoliberal': 5,
    'neoliberals': 5,
    'neoma': 3,
    'neon': 2,
    'neonatal': 4,
    'neonazi': 4,
    'neonazis': 4,
    'neons': 2,
    'neophyte': 3,
    'neophytes': 3,
    'neoplasm': 4,
    'neoplatonic': 5,
    'neoplatonist': 5,
    'neopolis': 4,
    'neopositivism': 7,
    'neopositivist': 6,
    'neoprene': 3,
    'neoprobe': 3,
    'neorx': 3,
    'neotenic': 4,
    'neoteny': 4,
    'nepa': 2,
    'nepal': 2,
    'nepalese': 3,
    'nepali': 3,
    'nephew': 2,
    'nephews': 2,
    'nephridium': 4,
    'nephrite': 2,
    'nephron': 2,
    'nephrosis': 3,
    'nepl': 2,
    'neponset': 3,
    'nepool': 2,
    'nepotism': 4,
    'neptune': 2,
    'neptunium': 4,
    'nerco': 2,
    'nerd': 1,
    'nerds': 1,
    'nerdy': 2,
    'nerenberg': 3,
    'neri': 2,
    'nerice': 2,
    'nerine': 2,
    'nerio': 3,
    'neritic': 3,
    'nerl': 1,
    'nerlich': 2,
    'nerney': 2,
    'nero': 2,
    'nerone': 2,
    'nerren': 2,
    'neruda': 3,
    'nervana': 3,
    'nerve': 1,
    'nerves': 1,
    'nervion': 3,
    'nervosa': 3,
    'nervous': 2,
    'nervously': 3,
    'nervousness': 3,
    'nervy': 2,
    'nes': 1,
    'nesbit': 2,
    'nesbitt': 2,
    'nesby': 2,
    'nesci': 2,
    'nesheim': 2,
    'nesi': 2,
    'nesler': 3,
    'nesmith': 2,
    'ness': 1,
    'nessa': 2,
    'nessel': 2,
    'nessen': 2,
    'nesser': 2,
    'nesseth': 2,
    'nessi': 2,
    'nessie': 2,
    'nessler': 2,
    'nest': 1,
    'nesta': 2,
    'neste': 1,
    'nestea': 3,
    'nested': 2,
    'nester': 2,
    'nesters': 2,
    'nesting': 2,
    'nestle': 2,
    'nestled': 2,
    'nestler': 3,
    'nestles': 2,
    'nestling': 2,
    'nestlings': 2,
    'nestor': 2,
    'nestorian': 4,
    'nestorianism': 6,
    'nests': 1,
    'net': 1,
    'netan': 2,
    'netanyahu': 4,
    'netback': 2,
    'netcom': 2,
    'neth': 1,
    'nether': 2,
    'nethercutt': 3,
    'netherland': 3,
    'netherlandic': 4,
    'netherlands': 3,
    'nethers': 2,
    'netherton': 3,
    'netherworld': 3,
    'nethery': 3,
    'nethuva': 3,
    'netlike': 2,
    'neto': 2,
    'nets': 1,
    'netscape': 2,
    'nett': 1,
    'netta': 2,
    'netted': 2,
    'netter': 2,
    'netterville': 3,
    'nettesheim': 3,
    'nettie': 2,
    'netting': 2,
    'nettle': 2,
    'nettled': 2,
    'nettles': 2,
    'nettlesome': 3,
    'nettleton': 3,
    'netto': 2,
    'netty': 2,
    'netview': 2,
    'netware': 2,
    'network': 2,
    'networked': 2,
    'networking': 3,
    'networks': 2,
    'networth': 2,
    'netz': 1,
    'netzel': 2,
    'netzer': 2,
    'netzley': 2,
    'neu': 1,
    'neubauer': 3,
    'neubecker': 3,
    'neuber': 2,
    'neuberger': 3,
    'neubert': 2,
    'neuburg': 2,
    'neuburger': 3,
    'neudecker': 3,
    'neue': 2,
    'neuendorf': 3,
    'neuendorffer': 3,
    'neuenfeldt': 3,
    'neuenschwander': 4,
    'neuer': 2,
    'neufeld': 2,
    'neugebauer': 4,
    'neugent': 2,
    'neuhart': 2,
    'neuharth': 2,
    'neuhaus': 2,
    'neuhauser': 3,
    'neuhoff': 2,
    'neukam': 2,
    'neukirchen': 3,
    'neukom': 2,
    'neumaier': 3,
    'neuman': 2,
    'neumann': 2,
    'neumayer': 3,
    'neumeier': 3,
    'neumeister': 3,
    'neumeyer': 3,
    'neumiller': 3,
    'neuner': 2,
    'neupert': 2,
    'neupogen': 3,
    'neural': 2,
    'neurasthenia': 5,
    'neuro': 2,
    'neurofibromatosis': 7,
    'neurohr': 2,
    'neurologic': 4,
    'neurological': 5,
    'neurologist': 4,
    'neurologists': 4,
    'neurology': 4,
    'neurons': 2,
    'neuropathy': 4,
    'neuroscience': 4,
    'neuroscientist': 5,
    'neuroses': 3,
    'neurosis': 3,
    'neurosurgeon': 4,
    'neurosurgeons': 4,
    'neurosurgery': 5,
    'neuroth': 2,
    'neurotic': 3,
    'neuser': 2,
    'neustadt': 2,
    'neustine': 2,
    'neuter': 2,
    'neutered': 2,
    'neutering': 3,
    'neutral': 2,
    'neutralism': 4,
    'neutralist': 3,
    'neutrality': 4,
    'neutralization': 5,
    'neutralize': 3,
    'neutralized': 3,
    'neutralizes': 4,
    'neutralizing': 4,
    'neutrally': 3,
    'neutrals': 2,
    'neutrino': 3,
    'neutrinos': 3,
    'neutrogena': 4,
    'neutron': 2,
    'neutrons': 2,
    'neuville': 2,
    'neuwirth': 2,
    'neuzil': 2,
    'neva': 2,
    'nevada': 3,
    'nevadan': 3,
    'nevadans': 3,
    'nevala': 3,
    'nevarach': 3,
    'nevarez': 3,
    'neve': 1,
    'neveau': 2,
    'nevel': 2,
    'nevels': 2,
    'never': 2,
    'neverland': 3,
    'nevermind': 3,
    'nevermore': 3,
    'nevers': 2,
    'nevertheless': 4,
    'neves': 1,
    'neveu': 2,
    'nevil': 2,
    'nevile': 2,
    'nevill': 2,
    'neville': 2,
    'nevills': 2,
    'nevils': 2,
    'nevin': 2,
    'nevins': 2,
    'nevis': 2,
    'nevitt': 2,
    'nevius': 3,
    'new': 1,
    'newall': 2,
    'newark': 2,
    'newbauer': 3,
    'newberg': 2,
    'newberger': 3,
    'newbern': 2,
    'newberry': 3,
    'newbery': 3,
    'newbill': 2,
    'newbold': 2,
    'newborn': 2,
    'newborns': 2,
    'newbraudwick': 3,
    'newbridge': 2,
    'newbrough': 2,
    'newburg': 2,
    'newburger': 3,
    'newburgh': 2,
    'newburn': 2,
    'newbury': 3,
    'newby': 2,
    'newcastle': 3,
    'newcom': 2,
    'newcomb': 2,
    'newcombe': 2,
    'newcome': 2,
    'newcomer': 3,
    'newcomers': 3,
    'newcor': 2,
    'newedge': 2,
    'newell': 2,
    'newer': 2,
    'newest': 2,
    'newey': 2,
    'newfangle': 3,
    'newfangled': 3,
    'newfield': 2,
    'newfound': 2,
    'newfoundland': 3,
    'newgard': 2,
    'newgate': 2,
    'newgateway': 3,
    'newhall': 2,
    'newhard': 2,
    'newhart': 2,
    'newhouse': 2,
    'newill': 2,
    'newingham': 3,
    'newish': 2,
    'newkirk': 2,
    'newlan': 2,
    'newland': 2,
    'newley': 2,
    'newlin': 2,
    'newlon': 2,
    'newlun': 2,
    'newly': 2,
    'newlyn': 2,
    'newlywed': 3,
    'newlyweds': 3,
    'newman': 2,
    'newmann': 2,
    'newmark': 2,
    'newmarket': 3,
    'newmeyer': 3,
    'newmont': 2,
    'newmyer': 3,
    'newnam': 2,
    'newness': 2,
    'neworld': 2,
    'newport': 2,
    'newquist': 2,
    'news': 1,
    'newscast': 2,
    'newscaster': 3,
    'newscasters': 3,
    'newscasts': 2,
    'newscorp': 2,
    'newsday': 2,
    'newsflash': 2,
    'newsflashes': 3,
    'newsgroup': 2,
    'newsgroups': 2,
    'newsham': 2,
    'newshour': 2,
    'newsies': 2,
    'newsletter': 3,
    'newsletters': 3,
    'newslink': 2,
    'newsmagazine': 4,
    'newsmagazines': 4,
    'newsmaker': 3,
    'newsmakers': 3,
    'newsman': 2,
    'newsmen': 2,
    'newsnight': 2,
    'newsom': 2,
    'newsome': 2,
    'newson': 2,
    'newspaper': 3,
    'newspaperman': 4,
    'newspapermen': 4,
    'newspapers': 3,
    'newspeak': 2,
    'newspeople': 3,
    'newsperson': 3,
    'newspersons': 3,
    'newsprint': 2,
    'newsreel': 2,
    'newsreels': 2,
    'newsroom': 2,
    'newsrooms': 2,
    'newsstand': 2,
    'newsstands': 2,
    'newstrom': 2,
    'newsum': 2,
    'newswanger': 3,
    'newsweek': 2,
    'newsweekly': 3,
    'newswire': 2,
    'newswires': 2,
    'newswoman': 3,
    'newswomen': 3,
    'newsworthy': 3,
    'newsy': 2,
    'newt': 1,
    'newton': 2,
    'newtonchik': 3,
    'newtonian': 4,
    'newtown': 2,
    'newtowne': 2,
    'newts': 1,
    'newvector': 3,
    'newville': 2,
    'newwave': 2,
    'nex': 1,
    'nexgen': 2,
    'nexis': 2,
    'nexrad': 2,
    'next': 1,
    'nextel': 2,
    'nextstep': 2,
    'nexus': 2,
    'ney': 1,
    'neyens': 1,
    'neyer': 2,
    'neyhart': 2,
    'neyland': 2,
    'neylon': 2,
    'neyman': 2,
    'neysa': 2,
    'nez': 1,
    'ng': 1,
    'ngai': 1,
    'ngema': 3,
    'nghi': 1,
    'ngo': 1,
    'ngor': 2,
    'ngos': 1,
    'ngueppe': 2,
    'nguyen': 2,
    'ni': 1,
    'niacin': 3,
    'niad': 2,
    'niagara': 3,
    'niaid': 2,
    'nial': 2,
    'niall': 1,
    'nib': 1,
    'nibbe': 1,
    'nibble': 2,
    'nibbled': 2,
    'nibbles': 2,
    'nibbling': 3,
    'nibert': 2,
    'niblack': 2,
    'niblett': 2,
    'niblock': 2,
    'nibs': 1,
    'nic': 3,
    'nicandros': 3,
    'nicaragua': 4,
    'nicaraguan': 4,
    'nicaraguans': 4,
    'nicastro': 3,
    'niccoli': 3,
    'niccolite': 3,
    'niccum': 2,
    'nice': 1,
    'nicely': 2,
    'nicen': 2,
    'niceness': 2,
    'nicer': 2,
    'nicest': 2,
    'niceties': 3,
    'nicety': 3,
    'niche': 1,
    'nichelson': 3,
    'niches': 2,
    'nichol': 2,
    'nichola': 3,
    'nicholas': 3,
    'nichole': 2,
    'nicholes': 2,
    'nicholi': 3,
    'nicholl': 2,
    'nicholls': 2,
    'nichols': 2,
    'nicholson': 3,
    'nichter': 2,
    'nick': 1,
    'nicked': 1,
    'nickel': 2,
    'nickell': 2,
    'nickelodeon': 5,
    'nickels': 2,
    'nickelson': 3,
    'nickens': 2,
    'nickerson': 3,
    'nickeson': 3,
    'nickey': 2,
    'nicki': 2,
    'nicklas': 2,
    'nicklaus': 2,
    'nickle': 2,
    'nickleby': 3,
    'nickles': 2,
    'nickless': 2,
    'nicklin': 2,
    'nicklow': 2,
    'nickname': 2,
    'nicknamed': 2,
    'nicknames': 2,
    'nickol': 2,
    'nickolas': 3,
    'nickoloff': 3,
    'nickols': 2,
    'nickolson': 3,
    'nicks': 1,
    'nickson': 2,
    'nickum': 2,
    'nicky': 2,
    'niclaneshia': 4,
    'nicley': 2,
    'nico': 2,
    'nicodemo': 4,
    'nicoderm': 3,
    'nicol': 2,
    'nicola': 3,
    'nicolae': 3,
    'nicolai': 4,
    'nicolaides': 3,
    'nicolaisen': 4,
    'nicolas': 3,
    'nicolau': 3,
    'nicolaus': 3,
    'nicolay': 3,
    'nicole': 2,
    'nicolet': 3,
    'nicoletta': 4,
    'nicolette': 3,
    'nicoletti': 4,
    'nicoli': 3,
    'nicolin': 3,
    'nicolina': 4,
    'nicoline': 4,
    'nicolini': 4,
    'nicoll': 2,
    'nicolle': 2,
    'nicollier': 3,
    'nicolls': 2,
    'nicolo': 3,
    'nicoloff': 3,
    'nicolosi': 4,
    'nicols': 2,
    'nicolson': 3,
    'nicor': 2,
    'nicorette': 3,
    'nicosia': 4,
    'nicoski': 3,
    'nicoson': 3,
    'nicotera': 4,
    'nicotine': 3,
    'nicotra': 3,
    'nida': 2,
    'nidal': 2,
    'niday': 2,
    'nidiffer': 3,
    'nie': 1,
    'niebauer': 3,
    'niebling': 3,
    'niebuhr': 2,
    'niebur': 2,
    'niece': 1,
    'nieces': 2,
    'nied': 1,
    'niedbala': 3,
    'niedbalski': 3,
    'nieder': 2,
    'niederer': 3,
    'niederhauser': 4,
    'niederman': 3,
    'niedermeier': 4,
    'niedermeyer': 4,
    'niedzielski': 3,
    'niedzwiecki': 3,
    'niehaus': 2,
    'niehoff': 2,
    'niekamp': 2,
    'niel': 1,
    'nieland': 2,
    'nield': 1,
    'nields': 1,
    'niels': 1,
    'nielsen': 2,
    'nielson': 2,
    'nieman': 2,
    'niemann': 2,
    'niemczyk': 2,
    'niemeier': 3,
    'niemela': 3,
    'niemeyer': 3,
    'niemi': 2,
    'niemiec': 2,
    'niemoeller': 3,
    'niemuth': 2,
    'nienaber': 3,
    'nienhaus': 2,
    'nienhuis': 3,
    'nienow': 2,
    'nier': 2,
    'nierenberg': 3,
    'nierman': 2,
    'nies': 1,
    'niese': 1,
    'niesen': 2,
    'niess': 1,
    'nieto': 2,
    'nietzsche': 2,
    'nieves': 3,
    'niezgoda': 3,
    'nifedipine': 4,
    'nifong': 2,
    'nifty': 2,
    'nigel': 2,
    'niger': 2,
    'nigeria': 4,
    'nigerian': 4,
    'nigerians': 4,
    'nigg': 1,
    'niggard': 2,
    'niggardliness': 4,
    'niggardly': 3,
    'nigger': 2,
    'niggers': 2,
    'nigh': 1,
    'nighswonger': 3,
    'night': 1,
    'nightclub': 2,
    'nightclubs': 2,
    'nightengale': 3,
    'nightengales': 3,
    'nighter': 2,
    'nighters': 2,
    'nightfall': 2,
    'nighthawk': 2,
    'nighthawks': 2,
    'nighthorse': 2,
    'nightingale': 3,
    'nightlife': 2,
    'nightline': 2,
    'nightlines': 2,
    'nightly': 2,
    'nightmare': 2,
    'nightmares': 2,
    'nightmarish': 3,
    'nights': 1,
    'nightshade': 2,
    'nightshirt': 2,
    'nightshirts': 2,
    'nightstage': 2,
    'nightstand': 2,
    'nightstands': 2,
    'nightstick': 2,
    'nightsticks': 2,
    'nighttime': 2,
    'nighttimes': 2,
    'nigrelli': 3,
    'nigris': 2,
    'nigro': 2,
    'niguel': 2,
    'nihart': 2,
    'nihay': 2,
    'nihei': 2,
    'nihilism': 4,
    'nihilists': 3,
    'nihiser': 3,
    'nihon': 2,
    'niihau': 2,
    'nik': 1,
    'nike': 2,
    'nikes': 1,
    'niki': 2,
    'nikita': 3,
    'nikkei': 2,
    'nikkel': 2,
    'nikkhah': 2,
    'nikki': 2,
    'nikko': 2,
    'niko': 2,
    'nikola': 3,
    'nikolai': 3,
    'nikolaivich': 4,
    'nikolaus': 3,
    'nikolic': 3,
    'nikolich': 3,
    'nikon': 2,
    'nikou': 2,
    'nikovski': 3,
    'nil': 1,
    'nila': 2,
    'nilan': 2,
    'niland': 2,
    'nile': 1,
    'niles': 1,
    'nilges': 2,
    'nill': 1,
    'nilles': 1,
    'nilly': 2,
    'nils': 1,
    'nilsen': 2,
    'nilson': 2,
    'nilsson': 2,
    'nimble': 2,
    'nimbly': 2,
    'nimbus': 2,
    'nimby': 2,
    'nimitz': 2,
    'nimmer': 2,
    'nimmo': 2,
    'nimmons': 2,
    'nimoy': 2,
    'nimrod': 2,
    'nimrodi': 3,
    'nims': 1,
    'nimtz': 1,
    'nina': 2,
    'ninad': 2,
    'ninagawa': 4,
    'nincompoop': 3,
    'nincompoops': 3,
    'nine': 1,
    'ninefold': 2,
    'niner': 2,
    'niners': 2,
    'nines': 1,
    'nineteen': 2,
    'nineteenth': 2,
    'nineties': 2,
    'ninetieth': 3,
    'ninette': 2,
    'ninety': 2,
    'nineveh': 3,
    'ning': 1,
    'ninja': 2,
    'ninjas': 2,
    'ninneman': 2,
    'ninny': 2,
    'nino': 2,
    'ninon': 2,
    'ninos': 2,
    'ninsu': 2,
    'nintendo': 3,
    'ninth': 1,
    'ninths': 1,
    'niobite': 3,
    'niobium': 4,
    'nip': 1,
    'nipon': 2,
    'nipp': 1,
    'nipped': 1,
    'nipper': 2,
    'nippert': 2,
    'nipping': 2,
    'nipple': 2,
    'nipples': 2,
    'nippon': 2,
    'nippondenso': 4,
    'nippy': 2,
    'nips': 1,
    'nipsco': 2,
    'niquette': 2,
    'nir': 1,
    'nirenberg': 3,
    'niriko': 3,
    'niro': 2,
    'nirvana': 3,
    'nirvanas': 3,
    'nisbet': 2,
    'nisbett': 2,
    'nisei': 2,
    'nishi': 2,
    'nishida': 3,
    'nishikawa': 4,
    'nishimo': 3,
    'nishimoto': 4,
    'nishimura': 4,
    'nishioka': 4,
    'nishiyama': 4,
    'nishizawa': 4,
    'niskanen': 3,
    'nisley': 2,
    'nissa': 2,
    'nissan': 2,
    'nissans': 2,
    'nissei': 2,
    'nissen': 2,
    'nissenbaum': 3,
    'nisshin': 2,
    'nissho': 2,
    'nissim': 2,
    'nissin': 2,
    'nissley': 2,
    'nist': 1,
    'nistler': 2,
    'niswander': 3,
    'niswonger': 3,
    'nit': 1,
    'nita': 2,
    'nite': 1,
    'nithuekan': 3,
    'nitka': 2,
    'nito': 2,
    'nitpick': 2,
    'nitpicking': 3,
    'nitrate': 2,
    'nitrates': 2,
    'nitrating': 3,
    'nitration': 3,
    'nitric': 2,
    'nitride': 2,
    'nitriles': 2,
    'nitro': 2,
    'nitrocellulose': 5,
    'nitrogen': 3,
    'nitrogenous': 4,
    'nitroglycerin': 5,
    'nitroglycerine': 5,
    'nitrosamines': 4,
    'nitrosomine': 4,
    'nitrosomines': 4,
    'nitrous': 2,
    'nitsa': 2,
    'nitsch': 1,
    'nitsche': 1,
    'nitschke': 1,
    'nitta': 2,
    'nitto': 2,
    'nitty': 2,
    'nitz': 1,
    'nitza': 2,
    'nitzberg': 2,
    'nitze': 2,
    'nitzel': 2,
    'nitzsche': 1,
    'niven': 2,
    'nivens': 2,
    'niver': 2,
    'nivison': 3,
    'nix': 1,
    'nixdorf': 2,
    'nixed': 1,
    'nixes': 2,
    'nixie': 2,
    'nixon': 2,
    'nixons': 2,
    'nizar': 2,
    'nizhny': 2,
    'niziolek': 4,
    'niznik': 2,
    'njt': 3,
    'nkohse': 3,
    'no': 1,
    'noa': 2,
    'noaa': 2,
    'noack': 1,
    'noah': 2,
    'noakes': 1,
    'noam': 1,
    'noami': 2,
    'nobatoshi': 4,
    'nobbe': 1,
    'nobec': 2,
    'nobel': 2,
    'nobelium': 4,
    'nobile': 2,
    'nobility': 4,
    'nobis': 2,
    'noble': 2,
    'nobleman': 3,
    'nobler': 2,
    'nobles': 2,
    'noblesse': 2,
    'noblest': 2,
    'noblet': 2,
    'noblett': 2,
    'noblewoman': 4,
    'noblin': 2,
    'noblitt': 2,
    'nobly': 2,
    'nobodies': 3,
    'nobody': 3,
    'noboru': 3,
    'nobrega': 3,
    'nobriga': 3,
    'nobuaki': 4,
    'nobuo': 3,
    'nobuto': 3,
    'nobuyuki': 4,
    'noce': 1,
    'nocella': 3,
    'nocera': 3,
    'nock': 1,
    'nockard': 2,
    'nocturnal': 3,
    'nod': 1,
    'noda': 2,
    'nodded': 2,
    'nodding': 2,
    'node': 1,
    'nodes': 1,
    'nodine': 3,
    'nods': 1,
    'nodular': 3,
    'nodule': 2,
    'nodules': 2,
    'noe': 1,
    'noecker': 2,
    'noel': 2,
    'noell': 1,
    'noelle': 2,
    'noes': 1,
    'noeth': 1,
    'noffke': 1,
    'noffsinger': 3,
    'nofsinger': 3,
    'noftsker': 2,
    'nofziger': 3,
    'noga': 2,
    'nogales': 3,
    'nogawa': 3,
    'noggle': 2,
    'nogle': 2,
    'noguchi': 3,
    'nogueira': 3,
    'noguera': 3,
    'noh': 1,
    'nohl': 1,
    'nohr': 1,
    'noiman': 2,
    'noir': 1,
    'noirs': 1,
    'noise': 1,
    'noises': 2,
    'noisier': 3,
    'noisiest': 3,
    'noisily': 3,
    'noisy': 2,
    'nokes': 1,
    'nokia': 3,
    'nokyo': 2,
    'nola': 2,
    'nolan': 2,
    'nolana': 3,
    'noland': 2,
    'nolanda': 3,
    'nolasco': 3,
    'nold': 1,
    'nolde': 1,
    'nolden': 2,
    'nolder': 2,
    'nole': 1,
    'nolen': 2,
    'noles': 1,
    'nolet': 2,
    'noleta': 3,
    'nolette': 2,
    'nolf': 1,
    'nolie': 2,
    'nolin': 2,
    'nolita': 3,
    'noll': 1,
    'nolla': 2,
    'nollan': 2,
    'noller': 2,
    'nolley': 2,
    'nollie': 2,
    'nolls': 1,
    'nolo': 2,
    'nolt': 1,
    'nolte': 1,
    'nolting': 2,
    'nom': 1,
    'nomad': 2,
    'nomadic': 3,
    'nomads': 2,
    'nome': 1,
    'nomenclatorial': 6,
    'nomenclatural': 5,
    'nomenclature': 4,
    'nomenklatura': 5,
    'nomi': 2,
    'nominal': 3,
    'nominally': 4,
    'nominate': 3,
    'nominated': 4,
    'nominates': 3,
    'nominating': 4,
    'nomination': 4,
    'nominations': 4,
    'nominee': 3,
    'nominees': 3,
    'nomo': 2,
    'nomura': 3,
    'non': 1,
    'nona': 2,
    'nonacademic': 5,
    'nonaccrual': 4,
    'nonaccruing': 4,
    'nonaggression': 4,
    'nonagricultural': 6,
    'nonalcoholic': 5,
    'nonalign': 3,
    'nonaligned': 3,
    'nonautomotive': 5,
    'nonbank': 2,
    'nonbanking': 3,
    'nonbeliever': 4,
    'nonbelievers': 4,
    'nonbinding': 3,
    'nonbiting': 3,
    'nonbuilding': 3,
    'nonbusiness': 3,
    'noncallable': 4,
    'noncash': 2,
    'nonce': 1,
    'nonchalance': 3,
    'nonchalant': 3,
    'nonchalantly': 4,
    'noncolor': 3,
    'noncombatant': 4,
    'noncombatants': 4,
    'noncommercial': 4,
    'noncommittal': 4,
    'noncommunist': 4,
    'noncommunists': 4,
    'noncompete': 3,
    'noncompetitive': 5,
    'noncompliance': 4,
    'nonconformist': 4,
    'nonconformists': 4,
    'nonconformity': 5,
    'noncontract': 3,
    'noncontroversial': 5,
    'nonconvertible': 5,
    'noncore': 2,
    'noncorporate': 3,
    'noncriminal': 4,
    'noncumulative': 5,
    'nondeductible': 5,
    'nondefense': 3,
    'nondescript': 3,
    'nondirect': 3,
    'nondiscriminate': 5,
    'nondiscrimination': 6,
    'nondiscriminatory': 7,
    'nondollar': 3,
    'nondurable': 4,
    'nondurables': 4,
    'none': 1,
    'noneconomic': 5,
    'nonelectrical': 5,
    'nonemergency': 5,
    'nonentity': 4,
    'nonessential': 4,
    'nonesuch': 2,
    'nonetheless': 3,
    'nonevent': 3,
    'nonexclusive': 4,
    'nonexecutive': 5,
    'nonexistent': 4,
    'nonfarm': 2,
    'nonfat': 2,
    'nonfatal': 3,
    'nonferrous': 3,
    'nonfiction': 3,
    'nonfinancial': 4,
    'nonfood': 2,
    'nongovernment': 4,
    'nongovernmental': 5,
    'nongreek': 2,
    'nonhuman': 3,
    'nonie': 2,
    'noninflationary': 6,
    'noninterest': 3,
    'noninterference': 5,
    'nonintervention': 5,
    'nonjet': 2,
    'nonjets': 2,
    'nonlethal': 3,
    'nonlinear': 4,
    'nonliturgical': 5,
    'nonmanagement': 4,
    'nonmanufacturing': 6,
    'nonmember': 3,
    'nonmembers': 3,
    'nonmilitary': 5,
    'nonnative': 3,
    'nonnatives': 3,
    'nonnemacher': 4,
    'nonnuclear': 4,
    'nonoperating': 5,
    'nonpacifist': 4,
    'nonpacifists': 4,
    'nonpartisan': 4,
    'nonpaying': 3,
    'nonpayment': 3,
    'nonperforming': 4,
    'nonplanar': 3,
    'nonpluss': 2,
    'nonplussed': 2,
    'nonpoisonous': 4,
    'nonpolitical': 5,
    'nonprescription': 4,
    'nonproductive': 4,
    'nonprofessional': 5,
    'nonprofessionals': 5,
    'nonprofit': 3,
    'nonprofits': 3,
    'nonproliferation': 6,
    'nonpublic': 3,
    'nonqualified': 4,
    'nonracial': 3,
    'nonrecurring': 4,
    'nonrefundable': 5,
    'nonregulated': 5,
    'nonreligious': 4,
    'nonrenewable': 5,
    'nonresident': 4,
    'nonresidential': 5,
    'nonresidents': 4,
    'nonresponsive': 4,
    'nonrestricted': 4,
    'nonroman': 3,
    'nonruling': 3,
    'nonsectarian': 5,
    'nonsense': 2,
    'nonsensical': 4,
    'nonsmoker': 3,
    'nonsmokers': 3,
    'nonsmoking': 3,
    'nonspecific': 4,
    'nonsporting': 3,
    'nonstandard': 3,
    'nonstarter': 3,
    'nonstarters': 3,
    'nonstick': 2,
    'nonstop': 2,
    'nonstops': 2,
    'nonstrategic': 4,
    'nonsurgical': 4,
    'nontaxable': 4,
    'nontechnical': 4,
    'nontoxic': 3,
    'nontraditional': 5,
    'nontransferable': 5,
    'nonturbo': 3,
    'nonturbos': 3,
    'nonunion': 3,
    'nonunionized': 4,
    'nonutility': 5,
    'nonverbal': 3,
    'nonviolence': 4,
    'nonviolent': 4,
    'nonvolatile': 4,
    'nonvoting': 3,
    'nonwhite': 2,
    'nonwhites': 2,
    'nonwoven': 3,
    'nonwovens': 3,
    'noodle': 2,
    'noodles': 2,
    'nook': 1,
    'nooks': 1,
    'noon': 1,
    'noonan': 2,
    'noone': 1,
    'nooney': 2,
    'noons': 1,
    'noontime': 2,
    'noorda': 2,
    'noose': 1,
    'nope': 1,
    'nor': 1,
    'nora': 2,
    'norah': 2,
    'noramco': 3,
    'noranda': 3,
    'norba': 2,
    'norbeck': 2,
    'norberg': 2,
    'norbert': 2,
    'norberta': 3,
    'norberto': 3,
    'norbury': 3,
    'norby': 2,
    'norcen': 2,
    'norcia': 2,
    'norco': 2,
    'norcross': 2,
    'nord': 1,
    'nordahl': 2,
    'nordan': 2,
    'nordbanken': 3,
    'nordberg': 2,
    'nordby': 2,
    'nordeen': 2,
    'nordell': 2,
    'norden': 2,
    'norder': 2,
    'nordgren': 2,
    'nordic': 2,
    'nordica': 3,
    'nordictrack': 3,
    'nordin': 2,
    'nordine': 3,
    'nordio': 3,
    'nordisk': 2,
    'nordling': 3,
    'nordlund': 2,
    'nordman': 2,
    'nordmann': 2,
    'nordmeyer': 3,
    'nordquist': 2,
    'nordson': 2,
    'nordstrom': 2,
    'nordstroms': 2,
    'nordyke': 2,
    'noreco': 3,
    'nored': 1,
    'noreen': 2,
    'noreiga': 3,
    'norelco': 3,
    'norell': 2,
    'norem': 2,
    'noren': 2,
    'norenberg': 3,
    'norenco': 3,
    'norenko': 3,
    'norex': 2,
    'norfleet': 2,
    'norfolk': 2,
    'norgaard': 2,
    'norgard': 2,
    'norge': 1,
    'norgren': 2,
    'norick': 2,
    'noriega': 4,
    'noriegas': 4,
    'norilsk': 2,
    'norimatsu': 4,
    'norinchukin': 4,
    'norinko': 3,
    'norita': 3,
    'nork': 1,
    'norkus': 2,
    'norland': 2,
    'norlander': 3,
    'norlin': 2,
    'norling': 2,
    'norm': 1,
    'norma': 2,
    'normal': 2,
    'normalcy': 3,
    'normality': 4,
    'normalization': 5,
    'normalize': 3,
    'normalized': 3,
    'normalizes': 4,
    'normalizing': 4,
    'normally': 3,
    'norman': 2,
    'normand': 2,
    'normandie': 3,
    'normandin': 3,
    'normandy': 3,
    'normative': 3,
    'norment': 2,
    'normick': 2,
    'normie': 2,
    'normile': 2,
    'norming': 2,
    'normington': 3,
    'normoyle': 2,
    'norms': 1,
    'normy': 2,
    'norna': 2,
    'norodom': 3,
    'norplant': 2,
    'norquist': 2,
    'norred': 1,
    'norrell': 2,
    'norrie': 2,
    'norrington': 3,
    'norris': 2,
    'norrod': 2,
    'norry': 2,
    'norse': 1,
    'norsemen': 2,
    'norsk': 1,
    'norske': 2,
    'norstan': 2,
    'norstar': 2,
    'norstrom': 2,
    'norsworthy': 3,
    'norte': 1,
    'nortek': 2,
    'nortel': 2,
    'north': 1,
    'northam': 2,
    'northampton': 3,
    'northbound': 2,
    'northbrook': 2,
    'northcliff': 2,
    'northcott': 2,
    'northcraft': 2,
    'northcutt': 2,
    'northeast': 2,
    'northeasterly': 4,
    'northeastern': 3,
    'northeasterner': 4,
    'northeasterners': 4,
    'northeastward': 3,
    'norther': 2,
    'northerly': 3,
    'northern': 2,
    'northerner': 3,
    'northerners': 3,
    'northernmost': 3,
    'northey': 2,
    'northfield': 2,
    'northgate': 2,
    'northington': 3,
    'northland': 2,
    'northolt': 2,
    'northridge': 2,
    'northrop': 2,
    'northrup': 2,
    'northstar': 2,
    'northup': 2,
    'northvale': 2,
    'northview': 2,
    'northward': 2,
    'northwardly': 3,
    'northway': 2,
    'northwest': 2,
    'northwesterly': 4,
    'northwestern': 3,
    'northwood': 2,
    'norton': 2,
    'norvel': 2,
    'norvell': 2,
    'norvil': 2,
    'norville': 2,
    'norvin': 2,
    'norwalk': 2,
    'norward': 2,
    'norway': 2,
    'norweb': 2,
    'norwegian': 3,
    'norwegians': 3,
    'norwell': 2,
    'norwest': 2,
    'norwich': 2,
    'norwin': 2,
    'norwitz': 2,
    'norwood': 2,
    'norwyn': 2,
    'nosair': 2,
    'nosal': 2,
    'nose': 1,
    'nosebleed': 2,
    'nosed': 1,
    'nosedive': 2,
    'nosedived': 2,
    'nosek': 2,
    'noses': 2,
    'noseworthy': 3,
    'nosiness': 3,
    'nosing': 2,
    'noska': 2,
    'noss': 1,
    'nostalgia': 3,
    'nostalgic': 3,
    'nostra': 2,
    'nostradamus': 4,
    'nostrand': 2,
    'nostril': 2,
    'nostrils': 2,
    'nostrums': 2,
    'nosy': 2,
    'not': 1,
    'notable': 3,
    'notables': 3,
    'notably': 3,
    'notarianni': 5,
    'notaro': 3,
    'notary': 3,
    'notation': 3,
    'notations': 3,
    'notch': 1,
    'notched': 1,
    'notches': 2,
    'note': 1,
    'notebaert': 3,
    'notebook': 2,
    'notebooks': 2,
    'noteboom': 3,
    'noted': 2,
    'noteholder': 3,
    'noteholders': 3,
    'notes': 1,
    'notestine': 4,
    'notetaker': 3,
    'notetakers': 3,
    'noteware': 2,
    'noteworthy': 3,
    'noth': 1,
    'nothdurft': 2,
    'nother': 2,
    'nothin': 2,
    'nothing': 2,
    'nothingness': 3,
    'nothings': 2,
    'nothnagel': 3,
    'nothstein': 2,
    'notice': 2,
    'noticeable': 4,
    'noticeably': 4,
    'noticed': 2,
    'notices': 3,
    'noticing': 3,
    'notification': 5,
    'notifications': 5,
    'notified': 3,
    'notifies': 3,
    'notify': 3,
    'notifying': 4,
    'noting': 2,
    'notion': 2,
    'notions': 2,
    'notis': 2,
    'noto': 2,
    'notochord': 3,
    'notoriety': 5,
    'notorious': 4,
    'notoriously': 5,
    'notre': 2,
    'nots': 1,
    'nott': 1,
    'nottage': 2,
    'notte': 1,
    'notter': 2,
    'nottingham': 3,
    'notwithstanding': 4,
    'notz': 1,
    'noun': 1,
    'nouns': 1,
    'nourish': 2,
    'nourished': 2,
    'nourishes': 3,
    'nourishing': 3,
    'nourishment': 3,
    'nourse': 1,
    'nous': 1,
    'nouveau': 2,
    'nouvel': 2,
    'nouvelle': 2,
    'nov': 3,
    'nova': 2,
    'novacare': 3,
    'novacek': 3,
    'novack': 2,
    'novacor': 3,
    'novak': 2,
    'novakovich': 4,
    'novakowski': 4,
    'novametrix': 4,
    'novas': 2,
    'novato': 3,
    'novel': 2,
    'novelist': 3,
    'novelistic': 4,
    'novelists': 3,
    'novell': 2,
    'novella': 3,
    'novelli': 3,
    'novello': 3,
    'novellus': 3,
    'novelly': 3,
    'novels': 2,
    'novelties': 3,
    'novelty': 3,
    'november': 3,
    'noverco': 3,
    'novey': 2,
    'novgorod': 3,
    'novi': 2,
    'novia': 3,
    'novice': 2,
    'novices': 3,
    'novick': 2,
    'novicki': 3,
    'novinger': 3,
    'novitski': 3,
    'novitsky': 3,
    'novo': 2,
    'novoa': 3,
    'novogrod': 3,
    'novopharm': 3,
    'novosad': 3,
    'novosel': 3,
    'novosibirsk': 4,
    'novosti': 3,
    'novotel': 3,
    'novotels': 3,
    'novotney': 3,
    'novotny': 3,
    'novum': 2,
    'novy': 2,
    'now': 1,
    'nowa': 2,
    'nowack': 2,
    'nowacki': 3,
    'nowaczyk': 3,
    'nowaday': 3,
    'nowadays': 3,
    'nowak': 2,
    'nowakowski': 4,
    'nowell': 2,
    'nowels': 2,
    'nowhere': 2,
    'nowicki': 3,
    'nowinski': 3,
    'nowlan': 2,
    'nowland': 2,
    'nowlin': 2,
    'nowling': 2,
    'nowotny': 3,
    'noxell': 2,
    'noxema': 3,
    'noxious': 2,
    'noxon': 2,
    'noxso': 2,
    'noyce': 1,
    'noyd': 1,
    'noyes': 1,
    'noyola': 3,
    'nozzle': 2,
    'nozzles': 2,
    'npr': 3,
    'nth': 1,
    'nu': 1,
    'nuala': 3,
    'nuance': 2,
    'nuanced': 2,
    'nuances': 3,
    'nub': 1,
    'nuber': 2,
    'nubian': 3,
    'nucci': 2,
    'nuccio': 3,
    'nuckles': 2,
    'nuckolls': 2,
    'nuckols': 2,
    'nuclear': 3,
    'nucleation': 4,
    'nuclei': 3,
    'nucleic': 3,
    'nucleonic': 4,
    'nucleonics': 4,
    'nucleotide': 4,
    'nucleus': 3,
    'nucor': 2,
    'nucorp': 2,
    'nudd': 1,
    'nude': 1,
    'nudelman': 3,
    'nudes': 1,
    'nudey': 2,
    'nudge': 1,
    'nudged': 1,
    'nudges': 2,
    'nudging': 2,
    'nudist': 2,
    'nudity': 3,
    'nudo': 2,
    'nuevo': 2,
    'nuexco': 2,
    'nueyung': 2,
    'nufer': 2,
    'nuffer': 2,
    'nugan': 2,
    'nugent': 2,
    'nugett': 2,
    'nugget': 2,
    'nuggets': 2,
    'nuhfer': 2,
    'nuhn': 1,
    'nuisance': 2,
    'nuisances': 3,
    'nujoma': 3,
    'nuke': 1,
    'nukem': 2,
    'nukes': 1,
    'nuland': 2,
    'null': 1,
    'nullification': 5,
    'nullified': 3,
    'nullifies': 3,
    'nullify': 3,
    'nullifying': 4,
    'nulph': 1,
    'nulton': 2,
    'nulty': 2,
    'numac': 2,
    'numb': 1,
    'numbed': 1,
    'number': 2,
    'numbered': 2,
    'numbering': 3,
    'numbers': 2,
    'numbing': 2,
    'numbingly': 3,
    'numbness': 2,
    'numed': 2,
    'numeiri': 3,
    'numeral': 3,
    'numerals': 3,
    'numeric': 3,
    'numerica': 4,
    'numerical': 4,
    'numerically': 4,
    'numerology': 5,
    'numerous': 3,
    'numia': 2,
    'numidia': 4,
    'numismatic': 4,
    'numismatics': 4,
    'numismatist': 4,
    'numismatists': 4,
    'nummi': 2,
    'nun': 1,
    'nunamaker': 4,
    'nunan': 2,
    'nuncio': 3,
    'nunemaker': 3,
    'nunes': 1,
    'nunez': 2,
    'nungesser': 3,
    'nunley': 2,
    'nunn': 1,
    'nunnally': 3,
    'nunnelley': 3,
    'nunnelly': 3,
    'nunnery': 3,
    'nuno': 2,
    'nuns': 1,
    'nunsense': 2,
    'nunziata': 4,
    'nunziato': 4,
    'nunzio': 3,
    'nuova': 2,
    'nuovo': 2,
    'nupeng': 2,
    'nuptial': 2,
    'nur': 1,
    'nuremberg': 3,
    'nureyev': 3,
    'nurmi': 2,
    'nurnberger': 3,
    'nurock': 2,
    'nurr': 1,
    'nurre': 1,
    'nurse': 1,
    'nursed': 1,
    'nurseries': 3,
    'nursery': 3,
    'nurseryman': 4,
    'nurserymen': 4,
    'nurses': 2,
    'nursing': 2,
    'nurture': 2,
    'nurtured': 2,
    'nurturer': 3,
    'nurturers': 3,
    'nurtures': 2,
    'nurturing': 3,
    'nusbaum': 2,
    'nusen': 2,
    'nuss': 1,
    'nussbaum': 2,
    'nussbaumer': 3,
    'nusser': 2,
    'nussle': 2,
    'nusz': 1,
    'nut': 1,
    'nutcracker': 3,
    'nutcrackers': 3,
    'nute': 1,
    'nutlets': 2,
    'nutley': 2,
    'nutmeg': 2,
    'nutone': 2,
    'nutrasweet': 3,
    'nutri': 2,
    'nutria': 3,
    'nutriclean': 3,
    'nutrient': 3,
    'nutrients': 3,
    'nutrition': 3,
    'nutritional': 4,
    'nutritionally': 5,
    'nutritionist': 4,
    'nutritionists': 4,
    'nutritious': 3,
    'nutritive': 3,
    'nuts': 1,
    'nutshell': 2,
    'nutshells': 2,
    'nutt': 1,
    'nuttall': 2,
    'nutter': 2,
    'nutting': 2,
    'nuttle': 2,
    'nutty': 2,
    'nuveen': 2,
    'nuxoll': 2,
    'nuys': 1,
    'nuzum': 2,
    'nuzzi': 2,
    'nuzzle': 2,
    'nuzzo': 2,
    'nvhome': 3,
    'nvhomes': 3,
    'nvryan': 3,
    'nyack': 2,
    'nyberg': 2,
    'nyborg': 2,
    'nyce': 1,
    'nycor': 2,
    'nycum': 2,
    'nycz': 1,
    'nydam': 2,
    'nydegger': 3,
    'nydia': 3,
    'nye': 1,
    'nyenhuis': 3,
    'nyerere': 3,
    'nyeri': 3,
    'nyers': 1,
    'nyet': 1,
    'nygaard': 2,
    'nygard': 2,
    'nygren': 2,
    'nyhan': 2,
    'nyholm': 2,
    'nyhus': 2,
    'nyina': 3,
    'nyland': 2,
    'nylander': 3,
    'nylen': 2,
    'nylex': 2,
    'nylon': 2,
    'nylons': 2,
    'nylund': 2,
    'nyman': 2,
    'nymex': 2,
    'nymph': 1,
    'nymphomaniac': 5,
    'nymphomaniacs': 5,
    'nymphs': 1,
    'nynex': 2,
    'nyquist': 2,
    'nyssa': 2,
    'nystrom': 2,
    'nyx': 1,
    'o': 1,
    'oad': 3,
    'oahu': 3,
    'oak': 1,
    'oakar': 2,
    'oakbrook': 2,
    'oakdale': 2,
    'oakes': 1,
    'oakey': 2,
    'oakite': 2,
    'oakland': 2,
    'oakleaf': 2,
    'oakley': 2,
    'oakman': 2,
    'oakmark': 2,
    'oakmont': 2,
    'oakridge': 2,
    'oaks': 1,
    'oakville': 2,
    'oakwood': 2,
    'oaky': 2,
    'oana': 2,
    'oanh': 1,
    'oar': 1,
    'oared': 1,
    'oarlock': 2,
    'oars': 1,
    'oarsman': 2,
    'oas': 3,
    'oases': 3,
    'oasis': 3,
    'oat': 1,
    'oates': 1,
    'oath': 1,
    'oathout': 2,
    'oaths': 1,
    'oatis': 2,
    'oatley': 2,
    'oatman': 2,
    'oatmeal': 2,
    'oats': 1,
    'oaxaca': 3,
    'oba': 2,
    'obadiah': 4,
    'obama': 3,
    'obando': 3,
    'obanion': 3,
    'obannon': 3,
    'obar': 2,
    'obara': 3,
    'obarr': 2,
    'obdurate': 3,
    'obedience': 4,
    'obedient': 4,
    'obediently': 5,
    'obeid': 2,
    'obeirne': 2,
    'obelia': 4,
    'obenchain': 3,
    'obenshain': 3,
    'ober': 2,
    'oberbeck': 3,
    'oberdorf': 3,
    'oberg': 2,
    'oberhaus': 3,
    'oberhausen': 4,
    'oberhelman': 4,
    'oberholtzer': 4,
    'oberlander': 4,
    'oberle': 3,
    'oberlin': 3,
    'oberly': 3,
    'obermaier': 3,
    'oberman': 3,
    'obermeier': 4,
    'obermeyer': 4,
    'obermiller': 4,
    'oberon': 3,
    'oberry': 3,
    'oberst': 2,
    'oberstar': 3,
    'obert': 2,
    'oberweis': 3,
    'obese': 2,
    'obesity': 4,
    'obey': 2,
    'obeyed': 2,
    'obeying': 3,
    'obeys': 2,
    'obfuscate': 3,
    'obfuscation': 4,
    'obie': 2,
    'obispo': 3,
    'obit': 2,
    'obits': 2,
    'obituaries': 5,
    'obituary': 5,
    'object': 2,
    'objected': 3,
    'objecting': 3,
    'objection': 3,
    'objectionable': 5,
    'objections': 3,
    'objective': 3,
    'objectively': 4,
    'objectives': 3,
    'objectivity': 5,
    'objector': 3,
    'objectors': 3,
    'objects': 2,
    'oblak': 2,
    'oblander': 3,
    'oblast': 2,
    'oblate': 2,
    'obligate': 3,
    'obligated': 4,
    'obligates': 3,
    'obligating': 4,
    'obligation': 4,
    'obligations': 4,
    'obligatory': 5,
    'oblige': 2,
    'obliged': 2,
    'obliges': 3,
    'obliging': 3,
    'obligingly': 4,
    'oblinger': 4,
    'oblique': 2,
    'obliquely': 3,
    'obliterate': 4,
    'obliterated': 5,
    'obliterates': 4,
    'obliterating': 5,
    'obliteration': 5,
    'oblivion': 4,
    'oblivious': 4,
    'oblong': 2,
    'obnoxious': 3,
    'oboe': 2,
    'oboist': 3,
    'obon': 2,
    'oboyle': 2,
    'obradovich': 4,
    'obrecht': 2,
    'obregon': 3,
    'obremski': 3,
    'obremsky': 3,
    'obrenovich': 4,
    'obrian': 3,
    'obriant': 3,
    'obrien': 3,
    'obringer': 3,
    'obrinsky': 3,
    'obryan': 3,
    'obryant': 3,
    'obscene': 2,
    'obscenely': 4,
    'obscenities': 4,
    'obscenity': 4,
    'obscure': 2,
    'obscured': 2,
    'obscures': 2,
    'obscuring': 3,
    'obscurity': 4,
    'obsequious': 4,
    'observable': 4,
    'observables': 4,
    'observance': 3,
    'observances': 4,
    'observant': 3,
    'observateur': 4,
    'observation': 4,
    'observations': 4,
    'observatories': 5,
    'observatory': 5,
    'observe': 2,
    'observed': 2,
    'observer': 3,
    'observers': 3,
    'observes': 2,
    'observing': 3,
    'obsess': 2,
    'obsessed': 2,
    'obsesses': 3,
    'obsessing': 3,
    'obsession': 3,
    'obsessional': 4,
    'obsessions': 3,
    'obsessive': 3,
    'obsessively': 4,
    'obshchestvennom': 4,
    'obsidian': 4,
    'obsolescence': 4,
    'obsolescent': 4,
    'obsolete': 3,
    'obsoletes': 3,
    'obst': 1,
    'obstacle': 3,
    'obstacles': 3,
    'obstetric': 3,
    'obstetrical': 4,
    'obstetrician': 4,
    'obstetricians': 4,
    'obstetrics': 3,
    'obstfeld': 2,
    'obstinacy': 4,
    'obstinate': 3,
    'obstreperous': 4,
    'obstruct': 2,
    'obstructed': 3,
    'obstructing': 3,
    'obstruction': 3,
    'obstructionism': 5,
    'obstructionist': 4,
    'obstructionists': 4,
    'obstructions': 3,
    'obstructive': 3,
    'obstructs': 2,
    'obtain': 2,
    'obtainable': 4,
    'obtained': 2,
    'obtaining': 3,
    'obtains': 2,
    'obtrude': 2,
    'obtrudes': 2,
    'obtrusive': 3,
    'obtuse': 2,
    'obuchowski': 4,
    'obverse': 2,
    'obviate': 3,
    'obviated': 4,
    'obviates': 3,
    'obviating': 4,
    'obvious': 3,
    'obviously': 4,
    'obyrne': 2,
    'ocain': 3,
    'ocala': 3,
    'ocallaghan': 4,
    'ocallahan': 4,
    'ocampo': 3,
    'ocana': 3,
    'ocanas': 3,
    'ocarroll': 3,
    'ocasio': 4,
    'ocaw': 2,
    'occasion': 3,
    'occasional': 4,
    'occasionally': 5,
    'occasioned': 3,
    'occasions': 3,
    'occhino': 3,
    'occhipinti': 4,
    'occhoa': 3,
    'occident': 3,
    'occidental': 4,
    'occidentale': 4,
    'occipital': 4,
    'occlusion': 3,
    'occult': 2,
    'occupancy': 4,
    'occupant': 3,
    'occupants': 3,
    'occupation': 4,
    'occupational': 5,
    'occupations': 4,
    'occupied': 3,
    'occupier': 4,
    'occupiers': 4,
    'occupies': 3,
    'occupy': 3,
    'occupying': 4,
    'occur': 2,
    'occuring': 3,
    'occurred': 2,
    'occurrence': 3,
    'occurrences': 4,
    'occurring': 3,
    'occurs': 2,
    'ocean': 2,
    'oceana': 4,
    'oceaneering': 4,
    'oceanfront': 3,
    'oceangoing': 4,
    'oceania': 4,
    'oceanic': 4,
    'oceanographer': 5,
    'oceanographic': 5,
    'oceanography': 5,
    'oceans': 2,
    'oceanside': 3,
    'oceanview': 3,
    'ocelot': 3,
    'och': 1,
    'ocheltree': 3,
    'ochoa': 3,
    'ochra': 2,
    'ochre': 2,
    'ochs': 1,
    'ochsenschlager': 4,
    'ochsner': 2,
    'ocilla': 3,
    'ocker': 2,
    'ockerbloom': 3,
    'ockerman': 3,
    'oclair': 2,
    'ocon': 2,
    'oconnell': 3,
    'oconner': 3,
    'oconnor': 3,
    'oct': 3,
    'octagon': 3,
    'octagonal': 4,
    'octahedral': 4,
    'octahedron': 4,
    'octane': 2,
    'octave': 2,
    'octaves': 2,
    'octavia': 4,
    'octavio': 4,
    'octavius': 4,
    'octavus': 3,
    'octel': 2,
    'octet': 2,
    'octial': 2,
    'octillion': 3,
    'october': 3,
    'octogenarian': 6,
    'octopi': 3,
    'octopus': 3,
    'octuplet': 3,
    'octuplets': 3,
    'oda': 2,
    'odaiko': 3,
    'odaniel': 3,
    'oday': 2,
    'odd': 1,
    'oddball': 2,
    'oddballs': 2,
    'odden': 2,
    'odder': 2,
    'oddest': 2,
    'oddi': 2,
    'oddities': 3,
    'oddity': 3,
    'oddler': 2,
    'oddly': 2,
    'odds': 1,
    'oddsmaker': 3,
    'oddsmakers': 3,
    'oddy': 2,
    'ode': 1,
    'odea': 3,
    'oded': 2,
    'odegaard': 3,
    'odegard': 3,
    'odekirk': 3,
    'odele': 3,
    'odelet': 3,
    'odelette': 3,
    'odelia': 4,
    'odelinda': 4,
    'odell': 2,
    'odella': 3,
    'odem': 2,
    'oden': 2,
    'odenthal': 3,
    'odeon': 3,
    'oder': 2,
    'odes': 1,
    'odessa': 3,
    'odetics': 3,
    'odette': 2,
    'odgers': 2,
    'odiaum': 3,
    'odiaun': 3,
    'odier': 3,
    'odilia': 4,
    'odin': 2,
    'odiorne': 4,
    'odious': 3,
    'odland': 2,
    'odle': 2,
    'odneal': 2,
    'odoherty': 4,
    'odolf': 2,
    'odom': 2,
    'odometer': 4,
    'odometers': 4,
    'odoms': 2,
    'odonald': 3,
    'odonnel': 3,
    'odonnell': 3,
    'odonoghue': 4,
    'odonohue': 4,
    'odonovan': 4,
    'odor': 2,
    'odorant': 3,
    'odorless': 3,
    'odorous': 3,
    'odors': 2,
    'odowd': 2,
    'odp': 3,
    'odriscoll': 3,
    'odum': 2,
    'odwalla': 3,
    'odwyer': 3,
    'odysseus': 4,
    'odyssey': 3,
    'oedipal': 3,
    'oedipus': 3,
    'oehler': 2,
    'oehlert': 2,
    'oehlke': 1,
    'oehme': 1,
    'oehmen': 2,
    'oehmens': 2,
    'oehrlein': 2,
    'oeien': 3,
    'oelke': 1,
    'oelkers': 2,
    'oelman': 2,
    'oelrich': 2,
    'oelschlager': 3,
    'oenophile': 3,
    'oerlikon': 3,
    'oertel': 2,
    'oesch': 1,
    'oeser': 2,
    'oest': 2,
    'oesterle': 3,
    'oesterling': 3,
    'oesterreich': 3,
    'oesterreichische': 5,
    'oestreich': 2,
    'oestreicher': 3,
    'oetken': 2,
    'oetting': 2,
    'oettinger': 3,
    'oeuvre': 2,
    'of': 1,
    'ofallon': 3,
    'ofarrell': 3,
    'ofc': 3,
    'ofelia': 4,
    'ofer': 2,
    'off': 1,
    'offbeat': 2,
    'offen': 2,
    'offend': 2,
    'offended': 3,
    'offender': 3,
    'offenders': 3,
    'offending': 3,
    'offends': 2,
    'offense': 2,
    'offenses': 3,
    'offensive': 3,
    'offensively': 4,
    'offensives': 3,
    'offer': 2,
    'offerdahl': 3,
    'offered': 2,
    'offerer': 3,
    'offerers': 3,
    'offering': 3,
    'offerings': 3,
    'offerman': 3,
    'offermann': 3,
    'offers': 2,
    'offhand': 2,
    'office': 2,
    'officeholder': 4,
    'officeholders': 4,
    'officemax': 3,
    'officer': 3,
    'officers': 3,
    'offices': 3,
    'official': 3,
    'officialdom': 4,
    'officially': 4,
    'officials': 3,
    'officiate': 4,
    'officiated': 5,
    'officiates': 4,
    'officiating': 5,
    'officio': 4,
    'offield': 2,
    'offill': 2,
    'offing': 2,
    'offner': 2,
    'offord': 2,
    'offs': 1,
    'offset': 2,
    'offsets': 2,
    'offsetting': 3,
    'offshoot': 2,
    'offshoots': 2,
    'offshore': 2,
    'offspring': 2,
    'offstage': 2,
    'offutt': 2,
    'ofilia': 4,
    'oflaherty': 4,
    'oflynn': 2,
    'oft': 1,
    'oftedahl': 3,
    'oftel': 2,
    'often': 2,
    'oftener': 3,
    'oftentimes': 3,
    'og': 1,
    'ogan': 2,
    'ogara': 3,
    'ogata': 3,
    'ogawa': 3,
    'ogborn': 2,
    'ogburn': 2,
    'ogden': 2,
    'ogg': 1,
    'ogier': 3,
    'ogilvie': 3,
    'ogilvy': 3,
    'oglala': 3,
    'ogle': 2,
    'oglebay': 3,
    'ogled': 2,
    'ogles': 2,
    'oglesbee': 3,
    'oglesby': 3,
    'oglethorpe': 3,
    'ogletree': 3,
    'ognibene': 4,
    'ogoni': 3,
    'ogonyok': 3,
    'ogorman': 3,
    'ogrady': 3,
    'ogre': 2,
    'ogren': 2,
    'ogress': 2,
    'oguin': 3,
    'oguinn': 2,
    'oh': 1,
    'ohagan': 3,
    'ohair': 2,
    'ohalloran': 4,
    'ohanesian': 4,
    'ohanian': 4,
    'ohanlon': 3,
    'ohara': 3,
    'ohare': 2,
    'oharra': 3,
    'ohashi': 3,
    'ohaver': 3,
    'ohba': 2,
    'ohbayashi': 4,
    'ohearn': 2,
    'ohern': 2,
    'oherron': 3,
    'ohio': 3,
    'ohioan': 4,
    'ohioans': 4,
    'ohkawara': 4,
    'ohl': 1,
    'ohland': 2,
    'ohlendorf': 3,
    'ohler': 2,
    'ohlin': 2,
    'ohlinger': 3,
    'ohlman': 2,
    'ohlmann': 2,
    'ohlmeyer': 2,
    'ohlrich': 2,
    'ohlsen': 2,
    'ohlson': 2,
    'ohlsson': 2,
    'ohm': 1,
    'ohmae': 2,
    'ohman': 2,
    'ohmann': 2,
    'ohmer': 2,
    'ohms': 1,
    'ohmura': 3,
    'ohnemus': 3,
    'ohnstad': 2,
    'ohr': 1,
    'ohrt': 1,
    'ohs': 1,
    'oi': 1,
    'oie': 1,
    'oien': 2,
    'oil': 1,
    'oiled': 1,
    'oiler': 2,
    'oilers': 2,
    'oilfield': 2,
    'oilfields': 2,
    'oiling': 2,
    'oilman': 2,
    'oilmen': 2,
    'oils': 1,
    'oilseed': 2,
    'oilseeds': 2,
    'oilwell': 2,
    'oily': 2,
    'ointment': 2,
    'oishi': 3,
    'oj': 2,
    'oja': 2,
    'ojala': 3,
    'ojeda': 3,
    'oji': 2,
    'ojibwa': 3,
    'ok': 2,
    'oka': 2,
    'okabe': 3,
    'okada': 3,
    'okaloosa': 4,
    'okamoto': 4,
    'okamura': 4,
    'okane': 2,
    'okano': 3,
    'okasaki': 4,
    'okasan': 3,
    'okay': 2,
    'okayed': 2,
    'okays': 2,
    'okaz': 2,
    'okazaki': 4,
    'okdo': 2,
    'okeechobee': 4,
    'okeefe': 2,
    'okeeffe': 2,
    'okelley': 3,
    'okelly': 3,
    'oken': 3,
    'okerlund': 3,
    'okeson': 3,
    'okey': 2,
    'oki': 2,
    'okie': 2,
    'okimoto': 4,
    'okin': 2,
    'okinawa': 4,
    'okinawans': 4,
    'oklahoma': 4,
    'oklahoman': 4,
    'oklahomans': 4,
    'okon': 2,
    'okoniewski': 5,
    'okonski': 3,
    'okpo': 2,
    'okra': 2,
    'okray': 2,
    'oksana': 3,
    'oksenberg': 3,
    'okubo': 3,
    'okuda': 3,
    'okuma': 3,
    'okumura': 4,
    'okun': 2,
    'okuno': 3,
    'okura': 3,
    'okurowski': 4,
    'okwu': 2,
    'ola': 2,
    'olack': 2,
    'olaf': 2,
    'olafson': 3,
    'olague': 2,
    'olah': 2,
    'olajuwon': 4,
    'oland': 2,
    'olander': 3,
    'olano': 3,
    'olasky': 3,
    'olathe': 3,
    'olaughlin': 3,
    'olay': 2,
    'olayan': 3,
    'olberding': 3,
    'olbrich': 2,
    'olcott': 2,
    'olczak': 2,
    'old': 1,
    'oldaker': 3,
    'oldani': 3,
    'olde': 1,
    'olden': 2,
    'oldenburg': 3,
    'oldenkamp': 3,
    'older': 2,
    'oldest': 2,
    'oldfashioned': 3,
    'oldfather': 3,
    'oldfield': 2,
    'oldham': 2,
    'oldie': 2,
    'oldies': 2,
    'oldman': 2,
    'oldroyd': 2,
    'olds': 1,
    'oldsmar': 2,
    'oldsmobile': 3,
    'oldsmobiles': 3,
    'oldster': 2,
    'oldsters': 2,
    'oldt': 1,
    'ole': 1,
    'olea': 3,
    'oleaginous': 5,
    'olean': 2,
    'oleander': 4,
    'oleandrin': 4,
    'olear': 2,
    'oleary': 3,
    'oleaster': 4,
    'olefin': 3,
    'olefins': 3,
    'oleg': 2,
    'olejniczak': 4,
    'olejnik': 3,
    'oleksiak': 4,
    'oleksy': 3,
    'olen': 2,
    'olender': 3,
    'olenick': 3,
    'olenik': 3,
    'oleo': 3,
    'oleoyl': 3,
    'oleoyls': 3,
    'oler': 2,
    'oles': 1,
    'olesen': 3,
    'oleske': 3,
    'olesky': 3,
    'oleson': 3,
    'olestra': 3,
    'olexa': 3,
    'olexson': 3,
    'oley': 2,
    'olfactory': 4,
    'olga': 2,
    'olguin': 2,
    'olick': 2,
    'oliff': 2,
    'oligarch': 3,
    'oligarchs': 3,
    'oligarchy': 4,
    'oliger': 3,
    'oligocene': 4,
    'oligopolistic': 6,
    'oligopoly': 5,
    'olin': 2,
    'olinda': 3,
    'olinde': 2,
    'olinger': 3,
    'oliphant': 3,
    'oliva': 3,
    'olivares': 4,
    'olivarez': 4,
    'olivas': 3,
    'olive': 2,
    'oliveira': 4,
    'oliver': 3,
    'olivera': 4,
    'oliveras': 4,
    'oliveri': 4,
    'oliveria': 5,
    'oliverio': 5,
    'olivero': 4,
    'oliveros': 4,
    'olives': 2,
    'oliveto': 4,
    'olivette': 3,
    'olivetti': 4,
    'olivia': 4,
    'olivier': 4,
    'olivieri': 4,
    'olivine': 3,
    'olivo': 3,
    'oliwa': 3,
    'olk': 1,
    'olkowski': 3,
    'ollar': 2,
    'oller': 2,
    'olley': 2,
    'ollie': 2,
    'olliff': 2,
    'ollila': 3,
    'ollinger': 3,
    'ollis': 2,
    'ollison': 3,
    'olly': 2,
    'olmeda': 3,
    'olmedo': 3,
    'olmert': 2,
    'olmo': 2,
    'olmos': 2,
    'olmstead': 2,
    'olney': 2,
    'olof': 2,
    'olofson': 3,
    'olokuei': 4,
    'oloughlin': 3,
    'olovo': 3,
    'olsdat': 2,
    'olsen': 2,
    'olshan': 2,
    'olshansky': 3,
    'olshefski': 3,
    'olsher': 2,
    'olson': 2,
    'olsson': 2,
    'olstad': 2,
    'olsten': 2,
    'olszewski': 3,
    'olt': 1,
    'olthoff': 2,
    'oltman': 2,
    'oltmann': 2,
    'oltmanns': 2,
    'olund': 2,
    'olva': 2,
    'olver': 2,
    'olvera': 3,
    'olvey': 2,
    'olympia': 4,
    'olympiad': 4,
    'olympian': 4,
    'olympians': 4,
    'olympias': 4,
    'olympic': 3,
    'olympics': 3,
    'olympus': 3,
    'oma': 2,
    'omaar': 2,
    'omaha': 3,
    'omahoney': 4,
    'omahony': 4,
    'omalley': 3,
    'oman': 2,
    'omani': 3,
    'omans': 2,
    'omar': 2,
    'omara': 3,
    'omarska': 3,
    'omary': 3,
    'ombudsman': 3,
    'omdahl': 2,
    'omead': 2,
    'omeara': 3,
    'omega': 3,
    'omelet': 2,
    'omelets': 2,
    'omelia': 4,
    'omen': 2,
    'omens': 2,
    'omer': 2,
    'ominous': 3,
    'ominously': 4,
    'omission': 3,
    'omissions': 3,
    'omit': 2,
    'omits': 2,
    'omitted': 3,
    'omitting': 3,
    'omlor': 2,
    'ommen': 2,
    'omni': 2,
    'omnibank': 3,
    'omnibook': 3,
    'omnibus': 3,
    'omnibuses': 4,
    'omnicare': 3,
    'omnicom': 3,
    'omnipotence': 4,
    'omnipotent': 4,
    'omnipresence': 4,
    'omnipresent': 4,
    'omniscient': 3,
    'omnivorous': 4,
    'omohundro': 4,
    'omonia': 4,
    'omori': 3,
    'omron': 2,
    'on': 1,
    'ona': 2,
    'onagers': 3,
    'onan': 2,
    'onassis': 3,
    'onate': 2,
    'onawa': 3,
    'onboard': 2,
    'oncale': 3,
    'once': 1,
    'oncogen': 3,
    'oncogene': 3,
    'oncogenes': 4,
    'oncologist': 4,
    'oncologists': 4,
    'oncology': 4,
    'oncoming': 3,
    'oncor': 2,
    'ondaatje': 3,
    'onder': 2,
    'onderdonk': 3,
    'ondo': 2,
    'ondracek': 3,
    'one': 1,
    'oneal': 2,
    'oneall': 2,
    'onecomm': 2,
    'oneida': 3,
    'oneil': 2,
    'oneill': 2,
    'oneness': 2,
    'oneok': 3,
    'onerous': 3,
    'ones': 1,
    'oneself': 2,
    'onetime': 2,
    'onex': 2,
    'oney': 2,
    'oneyear': 2,
    'ong': 1,
    'ongoing': 3,
    'ongpin': 2,
    'onion': 2,
    'onions': 2,
    'onishi': 3,
    'onken': 2,
    'onley': 2,
    'online': 2,
    'onlooker': 3,
    'onlookers': 3,
    'only': 2,
    'onnen': 2,
    'onni': 2,
    'onno': 2,
    'ono': 2,
    'onoda': 3,
    'onofre': 3,
    'onofrio': 4,
    'onomastic': 4,
    'onomastics': 4,
    'onomatopeia': 6,
    'onondaga': 4,
    'onorato': 4,
    'onrushing': 3,
    'ons': 1,
    'onscreen': 2,
    'onset': 2,
    'onshore': 2,
    'onsite': 2,
    'onslaught': 2,
    'onslow': 2,
    'onstad': 2,
    'onstage': 2,
    'onstott': 2,
    'ontario': 4,
    'ontiveros': 4,
    'ontko': 2,
    'onto': 2,
    'ontogeny': 4,
    'ontological': 5,
    'ontology': 4,
    'onus': 2,
    'onward': 2,
    'onwards': 2,
    'onyx': 2,
    'oodle': 2,
    'oodles': 2,
    'ooh': 1,
    'oohs': 1,
    'ooley': 2,
    'oolong': 2,
    'oomph': 1,
    'oona': 2,
    'oooh': 1,
    'ooohs': 1,
    'oop': 1,
    'ooph': 1,
    'oops': 1,
    'ooten': 2,
    'ooze': 1,
    'oozed': 1,
    'oozes': 2,
    'oozing': 2,
    'op': 1,
    'opacity': 4,
    'opal': 2,
    'opalina': 4,
    'opaline': 3,
    'opalines': 3,
    'opalka': 3,
    'opaque': 2,
    'opax': 2,
    'opcom': 2,
    'opdahl': 2,
    'opdyke': 2,
    'opec': 2,
    'opel': 2,
    'open': 2,
    'opened': 2,
    'opener': 3,
    'openers': 3,
    'opening': 3,
    'openings': 3,
    'openly': 3,
    'openness': 3,
    'opens': 2,
    'openshaw': 3,
    'openwork': 3,
    'opera': 2,
    'operable': 4,
    'operan': 3,
    'operandi': 4,
    'operant': 3,
    'operas': 2,
    'operate': 3,
    'operated': 4,
    'operates': 3,
    'operatic': 4,
    'operating': 4,
    'operation': 4,
    'operational': 5,
    'operationally': 6,
    'operations': 4,
    'operative': 4,
    'operatives': 4,
    'operator': 4,
    'operators': 4,
    'opere': 2,
    'operetta': 4,
    'operettas': 4,
    'opfer': 2,
    'opheim': 2,
    'ophelia': 3,
    'ophthalmic': 3,
    'ophthalmologist': 5,
    'ophthalmologists': 5,
    'ophthalmology': 5,
    'ophthalmoscope': 4,
    'ophuls': 2,
    'opiate': 3,
    'opiates': 3,
    'opic': 2,
    'opie': 2,
    'opiela': 3,
    'opine': 2,
    'opined': 2,
    'opines': 2,
    'opining': 3,
    'opinion': 3,
    'opinionate': 4,
    'opinionated': 5,
    'opinions': 3,
    'opitz': 2,
    'opium': 3,
    'opland': 2,
    'ople': 2,
    'oplinger': 4,
    'opossum': 3,
    'opp': 1,
    'oppedisano': 5,
    'oppel': 2,
    'oppelt': 2,
    'oppenheim': 3,
    'oppenheimer': 4,
    'oppenheimers': 4,
    'oppens': 2,
    'opper': 2,
    'opperman': 3,
    'oppermann': 3,
    'oppler': 2,
    'oppliger': 3,
    'opponent': 3,
    'opponents': 3,
    'opportune': 3,
    'opportunism': 5,
    'opportunist': 4,
    'opportunistic': 5,
    'opportunists': 4,
    'opportunities': 5,
    'opportunity': 5,
    'oppose': 2,
    'opposed': 2,
    'opposes': 3,
    'opposing': 3,
    'opposite': 3,
    'opposites': 3,
    'opposition': 4,
    'oppositions': 4,
    'oppress': 2,
    'oppressed': 2,
    'oppresses': 3,
    'oppressing': 3,
    'oppression': 3,
    'oppressions': 3,
    'oppressive': 3,
    'oppressor': 3,
    'oppressors': 3,
    'opprobrium': 4,
    'oprah': 2,
    'opry': 2,
    'opryland': 3,
    'ops': 1,
    'opsahl': 2,
    'opsal': 2,
    'opt': 1,
    'optation': 3,
    'opted': 2,
    'optek': 2,
    'opthalmologist': 5,
    'opthalmology': 5,
    'optic': 2,
    'optica': 3,
    'optical': 3,
    'optically': 3,
    'optician': 3,
    'opticians': 3,
    'optics': 2,
    'optima': 3,
    'optimal': 3,
    'optimism': 4,
    'optimist': 3,
    'optimistic': 4,
    'optimistically': 6,
    'optimists': 3,
    'optimization': 5,
    'optimize': 3,
    'optimum': 3,
    'opting': 2,
    'option': 2,
    'optional': 3,
    'optioned': 2,
    'optioning': 3,
    'options': 2,
    'opto': 2,
    'optometric': 4,
    'optometrist': 4,
    'optometrists': 4,
    'optometry': 4,
    'opts': 1,
    'optus': 2,
    'opulence': 3,
    'opulent': 3,
    'opus': 2,
    'oquendo': 3,
    'oquin': 2,
    'oquinn': 2,
    'or': 1,
    'ora': 2,
    'orabel': 3,
    'orabelle': 3,
    'oracle': 3,
    'oracles': 3,
    'oradour': 3,
    'oraflex': 3,
    'orahood': 3,
    'oral': 2,
    'oralia': 4,
    'oralie': 3,
    'orally': 3,
    'oram': 2,
    'oran': 2,
    'orand': 2,
    'orange': 2,
    'orangeburg': 3,
    'oranges': 3,
    'orangina': 4,
    'orangutan': 4,
    'orangutans': 4,
    'orasure': 3,
    'oration': 3,
    'orations': 3,
    'orator': 3,
    'oratorical': 5,
    'oratorio': 5,
    'orators': 3,
    'oratory': 4,
    'oravec': 3,
    'oravetz': 3,
    'orb': 1,
    'orbach': 2,
    'orban': 2,
    'orbanco': 3,
    'orben': 2,
    'orbin': 2,
    'orbis': 2,
    'orbit': 2,
    'orbital': 3,
    'orbited': 3,
    'orbiter': 3,
    'orbiters': 3,
    'orbiting': 3,
    'orbits': 2,
    'orca': 2,
    'orcas': 2,
    'orchard': 2,
    'orchards': 2,
    'orchestra': 3,
    'orchestral': 3,
    'orchestrally': 4,
    'orchestras': 3,
    'orchestrate': 3,
    'orchestrated': 4,
    'orchestrates': 3,
    'orchestrating': 4,
    'orchestration': 4,
    'orchestrations': 4,
    'orchid': 2,
    'orchids': 2,
    'orct': 1,
    'orcutt': 2,
    'ord': 1,
    'ordain': 2,
    'ordained': 2,
    'ordaining': 3,
    'ordaz': 2,
    'ordeal': 2,
    'ordeals': 2,
    'ordella': 3,
    'order': 2,
    'ordered': 2,
    'ordering': 3,
    'orderliness': 4,
    'orderly': 3,
    'orders': 2,
    'ordinance': 3,
    'ordinances': 4,
    'ordinaries': 4,
    'ordinarily': 5,
    'ordinary': 4,
    'ordination': 4,
    'ordiway': 3,
    'ordnance': 2,
    'ordonez': 3,
    'ordoyne': 2,
    'ordway': 2,
    'ore': 1,
    'orea': 3,
    'orear': 2,
    'orebaugh': 3,
    'oreffice': 3,
    'orefice': 3,
    'oregan': 3,
    'oregano': 4,
    'oregon': 3,
    'oregonian': 5,
    'oregonians': 5,
    'oreilly': 3,
    'orel': 2,
    'orela': 3,
    'orelia': 4,
    'orellana': 4,
    'orelon': 3,
    'orem': 2,
    'oren': 2,
    'orenda': 3,
    'orendorff': 3,
    'orens': 2,
    'orenstein': 3,
    'orenthal': 3,
    'oreo': 3,
    'ores': 1,
    'oreskovich': 4,
    'orestes': 3,
    'orf': 1,
    'orfield': 2,
    'orford': 2,
    'org': 1,
    'organ': 2,
    'organelles': 3,
    'organic': 3,
    'organically': 4,
    'organics': 3,
    'organisation': 5,
    'organisations': 5,
    'organism': 4,
    'organismic': 4,
    'organisms': 4,
    'organist': 3,
    'organization': 5,
    'organizational': 6,
    'organizations': 5,
    'organize': 3,
    'organized': 3,
    'organizer': 4,
    'organizers': 4,
    'organizes': 4,
    'organizing': 4,
    'organogenesis': 6,
    'organs': 2,
    'organum': 3,
    'orgasm': 3,
    'orgasms': 3,
    'orgel': 2,
    'orgeron': 3,
    'orgies': 2,
    'orgill': 2,
    'orgotein': 3,
    'orgy': 2,
    'ori': 2,
    'oriana': 4,
    'orick': 2,
    'orielda': 3,
    'orielle': 3,
    'orient': 3,
    'oriental': 4,
    'orientals': 4,
    'orientated': 5,
    'orientation': 5,
    'orientations': 5,
    'oriented': 4,
    'orifice': 3,
    'orifices': 4,
    'origin': 3,
    'original': 4,
    'originality': 6,
    'originally': 5,
    'originals': 4,
    'originate': 4,
    'originated': 5,
    'originates': 4,
    'originating': 5,
    'origination': 5,
    'originations': 5,
    'originator': 5,
    'originators': 5,
    'origins': 3,
    'oriley': 3,
    'orilla': 3,
    'orin': 2,
    'orinda': 3,
    'orinoco': 4,
    'oriola': 4,
    'oriole': 3,
    'orioles': 3,
    'orion': 3,
    'oriordan': 3,
    'oritz': 2,
    'orix': 2,
    'orkem': 2,
    'orkin': 2,
    'orkney': 2,
    'orks': 1,
    'orky': 2,
    'orla': 2,
    'orlan': 2,
    'orland': 2,
    'orlanda': 3,
    'orlandi': 3,
    'orlando': 3,
    'orlean': 3,
    'orleanian': 5,
    'orleanians': 5,
    'orleans': 3,
    'orlena': 3,
    'orlene': 2,
    'orlich': 2,
    'orlick': 2,
    'orloff': 2,
    'orloski': 3,
    'orlosky': 3,
    'orlowski': 3,
    'orly': 2,
    'orman': 2,
    'ormand': 2,
    'orme': 1,
    'ormerod': 3,
    'ormes': 1,
    'ormiston': 3,
    'ormond': 2,
    'ormonde': 2,
    'ormsbee': 2,
    'ormsby': 2,
    'orn': 1,
    'orna': 2,
    'ornament': 3,
    'ornamental': 4,
    'ornamentally': 5,
    'ornamentation': 5,
    'ornamented': 4,
    'ornaments': 3,
    'ornate': 2,
    'ornately': 3,
    'ornda': 2,
    'orndoff': 2,
    'orndorff': 2,
    'orne': 1,
    'ornelas': 3,
    'ornella': 3,
    'ornellas': 3,
    'orner': 2,
    'ornery': 3,
    'ornette': 2,
    'ornish': 2,
    'ornithological': 6,
    'ornithology': 5,
    'ornoff': 2,
    'ornstein': 2,
    'oro': 2,
    'oroark': 2,
    'orogenic': 4,
    'orogil': 3,
    'orographic': 4,
    'orona': 3,
    'oropeza': 4,
    'oros': 2,
    'orosco': 3,
    'orosz': 2,
    'orourke': 2,
    'orozco': 3,
    'orpah': 2,
    'orphan': 2,
    'orphanage': 3,
    'orphanages': 4,
    'orphaned': 2,
    'orphaning': 3,
    'orphans': 2,
    'orphee': 2,
    'orpheum': 3,
    'orpheus': 3,
    'orphic': 2,
    'orpiment': 3,
    'orr': 1,
    'orran': 2,
    'orrell': 2,
    'orren': 2,
    'orrery': 3,
    'orrick': 2,
    'orrico': 3,
    'orrin': 2,
    'orris': 2,
    'orrison': 3,
    'orsa': 2,
    'orsak': 2,
    'orsay': 2,
    'orsborn': 2,
    'orsburn': 2,
    'orser': 2,
    'orsi': 2,
    'orsini': 3,
    'orsino': 3,
    'orso': 2,
    'orsola': 3,
    'orson': 2,
    'ort': 1,
    'orta': 2,
    'ortega': 3,
    'ortegas': 3,
    'ortego': 3,
    'ortegon': 3,
    'orten': 2,
    'ortenzio': 4,
    'ortez': 2,
    'orth': 1,
    'ortho': 2,
    'orthoclase': 3,
    'orthodontia': 5,
    'orthodontic': 4,
    'orthodontics': 4,
    'orthodontist': 4,
    'orthodontists': 4,
    'orthodox': 3,
    'orthodoxy': 4,
    'orthogonal': 4,
    'orthography': 4,
    'orthopedic': 4,
    'orthopedist': 4,
    'orthopedists': 4,
    'ortis': 2,
    'ortiz': 2,
    'ortlieb': 2,
    'ortloff': 2,
    'ortman': 2,
    'ortmann': 2,
    'ortner': 2,
    'ortolano': 4,
    'ortomisio': 5,
    'orton': 2,
    'ortwein': 2,
    'ortwin': 2,
    'orum': 2,
    'orva': 2,
    'orval': 2,
    'orville': 2,
    'orvin': 2,
    'orvis': 2,
    'orwell': 2,
    'orwellian': 4,
    'orwick': 2,
    'orwig': 2,
    'ory': 2,
    'oryx': 2,
    'orzech': 2,
    'orzechowski': 4,
    'orzel': 2,
    'os': 1,
    'osage': 2,
    'osages': 3,
    'osaka': 3,
    'osake': 3,
    'osaki': 3,
    'osakowa': 4,
    'osama': 3,
    'osamu': 3,
    'osberg': 2,
    'osbert': 2,
    'osbon': 2,
    'osborn': 2,
    'osborne': 2,
    'osbourn': 2,
    'osbourne': 2,
    'osbun': 2,
    'osburn': 2,
    'osby': 2,
    'oscalus': 3,
    'oscar': 2,
    'oscars': 2,
    'oscarson': 3,
    'osceola': 4,
    'oscillate': 3,
    'oscillated': 4,
    'oscillates': 3,
    'oscillating': 4,
    'oscillation': 4,
    'oscillations': 4,
    'oscillator': 4,
    'oscillators': 4,
    'oscillatory': 5,
    'oscilloscope': 4,
    'oscilloscopes': 4,
    'osco': 2,
    'ose': 1,
    'oseguera': 4,
    'oser': 2,
    'osgood': 2,
    'osh': 1,
    'osha': 2,
    'oshaf': 2,
    'oshatz': 2,
    'oshaughnessy': 4,
    'oshawa': 3,
    'oshea': 2,
    'oshel': 2,
    'oshell': 2,
    'osher': 2,
    'oshields': 2,
    'oshima': 3,
    'oshiro': 3,
    'oshita': 3,
    'oshkosh': 2,
    'oshman': 2,
    'osiecki': 3,
    'osika': 3,
    'osinski': 3,
    'oskar': 2,
    'osland': 2,
    'osler': 2,
    'osley': 2,
    'oslin': 2,
    'oslo': 2,
    'oslund': 2,
    'osman': 2,
    'osmar': 2,
    'osmena': 3,
    'osment': 2,
    'osmer': 3,
    'osmium': 3,
    'osmon': 2,
    'osmond': 2,
    'osmonds': 2,
    'osmosis': 3,
    'osmotic': 3,
    'osmun': 2,
    'osmund': 2,
    'osmundson': 3,
    'osorio': 4,
    'osowski': 3,
    'ospina': 3,
    'osprey': 2,
    'osred': 2,
    'osric': 2,
    'oss': 1,
    'ossa': 2,
    'ossetia': 3,
    'ossetian': 3,
    'ossetians': 3,
    'ossicles': 3,
    'ossification': 5,
    'ossified': 3,
    'ossify': 3,
    'ossman': 2,
    'osso': 2,
    'osswald': 2,
    'ost': 1,
    'ostankino': 4,
    'ostberg': 2,
    'ostby': 2,
    'osteen': 2,
    'osten': 2,
    'ostendorf': 3,
    'ostensible': 4,
    'ostensibly': 4,
    'ostenson': 3,
    'ostentation': 4,
    'ostentatious': 4,
    'ostentatiously': 5,
    'osteoarthritis': 6,
    'osteopathic': 5,
    'osteoporosis': 6,
    'oster': 2,
    'osterberg': 3,
    'ostergaard': 3,
    'ostergard': 3,
    'ostergren': 3,
    'osterhaus': 3,
    'osterhoff': 3,
    'osterholt': 3,
    'osterhoudt': 3,
    'osterhout': 3,
    'osterkamp': 3,
    'osterling': 3,
    'osterloh': 3,
    'osterlund': 3,
    'osterman': 3,
    'ostermann': 3,
    'ostermeier': 4,
    'ostermeyer': 4,
    'ostermiller': 4,
    'osterreichische': 5,
    'ostertag': 3,
    'osthoff': 2,
    'ostia': 3,
    'ostin': 2,
    'ostinato': 4,
    'osting': 2,
    'ostling': 3,
    'ostlund': 2,
    'ostman': 2,
    'ostpolitik': 4,
    'ostracism': 4,
    'ostracize': 3,
    'ostracized': 3,
    'ostrand': 2,
    'ostrander': 3,
    'ostrem': 2,
    'ostrich': 2,
    'ostriches': 3,
    'ostroff': 2,
    'ostrogoth': 3,
    'ostrogoths': 3,
    'ostrom': 2,
    'ostroski': 3,
    'ostrosky': 3,
    'ostrow': 2,
    'ostrowski': 3,
    'ostrowsky': 3,
    'ostrum': 2,
    'ostwald': 2,
    'osuch': 2,
    'osullivan': 4,
    'osuna': 3,
    'oswald': 2,
    'oswalt': 2,
    'oswego': 3,
    'oswell': 2,
    'ot': 1,
    'ota': 2,
    'otaiba': 3,
    'otani': 3,
    'otello': 3,
    'oteri': 3,
    'otero': 3,
    'otey': 2,
    'otha': 2,
    'othello': 3,
    'other': 2,
    'otherness': 3,
    'others': 2,
    'otherwise': 3,
    'otherworldly': 4,
    'othilia': 4,
    'othman': 2,
    'otis': 2,
    'otmar': 2,
    'otologies': 4,
    'otology': 4,
    'otomobil': 4,
    'otoole': 2,
    'otremba': 3,
    'otsego': 3,
    'otsuka': 3,
    'ott': 1,
    'ottaviani': 5,
    'ottaviano': 5,
    'ottawa': 3,
    'ottaway': 3,
    'otte': 1,
    'otten': 2,
    'ottens': 2,
    'otter': 2,
    'otterbein': 3,
    'otterloo': 3,
    'otters': 2,
    'otterson': 3,
    'ottesen': 3,
    'otteson': 3,
    'ottilie': 3,
    'otting': 2,
    'ottley': 2,
    'ottman': 2,
    'otto': 2,
    'ottoman': 3,
    'ottosen': 3,
    'ottoson': 3,
    'otts': 1,
    'ottum': 2,
    'otulsky': 3,
    'otwell': 2,
    'ou': 1,
    'ouaga': 2,
    'ouagadougou': 4,
    'oubre': 2,
    'ouch': 1,
    'ouderkirk': 3,
    'ouelette': 3,
    'ouellet': 3,
    'ouellette': 3,
    'ought': 1,
    'oughta': 2,
    'oui': 1,
    'ouimet': 2,
    'ouimette': 2,
    'ounce': 1,
    'ounces': 2,
    'ounsted': 2,
    'our': 2,
    'ourada': 4,
    'ours': 2,
    'ourself': 3,
    'ourselves': 3,
    'ourso': 2,
    'ouseba': 3,
    'ousley': 2,
    'oust': 1,
    'ousted': 2,
    'ouster': 2,
    'ousting': 2,
    'out': 1,
    'outage': 2,
    'outages': 3,
    'outback': 2,
    'outbid': 2,
    'outbidded': 3,
    'outbidding': 3,
    'outbids': 2,
    'outboard': 2,
    'outboards': 2,
    'outbound': 2,
    'outbreak': 2,
    'outbreaks': 2,
    'outburst': 2,
    'outbursts': 2,
    'outcalt': 2,
    'outcast': 2,
    'outcasts': 2,
    'outclass': 2,
    'outclassed': 2,
    'outclasses': 3,
    'outclassing': 3,
    'outcome': 2,
    'outcomes': 2,
    'outcries': 2,
    'outcry': 2,
    'outdate': 2,
    'outdated': 3,
    'outdid': 2,
    'outdistance': 3,
    'outdistanced': 3,
    'outdistances': 4,
    'outdistancing': 4,
    'outdo': 2,
    'outdoes': 2,
    'outdoing': 3,
    'outdone': 2,
    'outdoor': 2,
    'outdoors': 2,
    'outdoorsman': 3,
    'outed': 2,
    'outen': 2,
    'outer': 2,
    'outermost': 3,
    'outerwear': 3,
    'outfield': 2,
    'outfielder': 3,
    'outfielders': 3,
    'outfields': 2,
    'outfit': 2,
    'outfits': 2,
    'outfitted': 3,
    'outfitter': 3,
    'outfitters': 3,
    'outfitting': 3,
    'outflank': 2,
    'outflanked': 2,
    'outflanking': 3,
    'outflanks': 2,
    'outflow': 2,
    'outflows': 2,
    'outfox': 2,
    'outfoxed': 2,
    'outfoxes': 3,
    'outfoxing': 3,
    'outgain': 2,
    'outgained': 2,
    'outgaining': 3,
    'outgains': 2,
    'outgo': 2,
    'outgoing': 3,
    'outgrew': 2,
    'outgrow': 2,
    'outgrowing': 3,
    'outgrown': 2,
    'outgrows': 2,
    'outgrowth': 2,
    'outguess': 2,
    'outguessed': 2,
    'outguesses': 3,
    'outguessing': 3,
    'outgun': 2,
    'outgunned': 2,
    'outgunning': 3,
    'outguns': 2,
    'outhouse': 2,
    'outhouses': 3,
    'outing': 2,
    'outings': 2,
    'outland': 2,
    'outlandish': 3,
    'outlast': 2,
    'outlasted': 3,
    'outlasting': 3,
    'outlasts': 2,
    'outlaw': 2,
    'outlawed': 2,
    'outlawing': 3,
    'outlawry': 3,
    'outlaws': 2,
    'outlay': 2,
    'outlays': 2,
    'outler': 3,
    'outlet': 2,
    'outlets': 2,
    'outley': 2,
    'outlier': 3,
    'outliers': 3,
    'outline': 2,
    'outlined': 2,
    'outlines': 2,
    'outlining': 3,
    'outlive': 2,
    'outlived': 2,
    'outlives': 2,
    'outliving': 3,
    'outlook': 2,
    'outlooks': 2,
    'outlying': 3,
    'outman': 2,
    'outmaneuver': 4,
    'outmaneuvered': 4,
    'outmaneuvering': 5,
    'outmaneuvers': 4,
    'outmanned': 2,
    'outmode': 2,
    'outmoded': 3,
    'outnumber': 3,
    'outnumbered': 3,
    'outnumbering': 4,
    'outnumbers': 3,
    'outokumpu': 4,
    'outpace': 2,
    'outpaced': 2,
    'outpaces': 3,
    'outpacing': 3,
    'outpatient': 3,
    'outpatients': 3,
    'outperform': 3,
    'outperformance': 4,
    'outperformed': 3,
    'outperformer': 4,
    'outperformers': 4,
    'outperforming': 4,
    'outperforms': 3,
    'outplacement': 3,
    'outpost': 2,
    'outposts': 2,
    'outpouch': 2,
    'outpouching': 3,
    'outpour': 2,
    'outpouring': 3,
    'outpours': 2,
    'output': 2,
    'outputs': 2,
    'outrage': 2,
    'outraged': 2,
    'outrageous': 3,
    'outrageously': 4,
    'outrageousness': 4,
    'outrages': 3,
    'outraging': 3,
    'outran': 2,
    'outreach': 2,
    'outrider': 3,
    'outriders': 3,
    'outrigger': 3,
    'outriggers': 3,
    'outright': 2,
    'outrun': 2,
    'outrunning': 3,
    'outruns': 2,
    'outs': 1,
    'outscore': 2,
    'outscored': 2,
    'outscores': 2,
    'outscoring': 3,
    'outsell': 2,
    'outselling': 3,
    'outsells': 2,
    'outset': 2,
    'outshine': 2,
    'outshines': 2,
    'outshining': 3,
    'outshone': 2,
    'outside': 2,
    'outsider': 3,
    'outsiders': 3,
    'outsides': 2,
    'outsize': 2,
    'outsized': 2,
    'outskirt': 2,
    'outskirts': 2,
    'outsmart': 2,
    'outsmarted': 3,
    'outsmarting': 3,
    'outsmarts': 2,
    'outsold': 2,
    'outsource': 2,
    'outsourced': 2,
    'outsources': 3,
    'outsourcing': 3,
    'outspend': 2,
    'outspending': 3,
    'outspends': 2,
    'outspent': 2,
    'outspoken': 3,
    'outspokenness': 4,
    'outstanding': 3,
    'outstandingly': 4,
    'outstretch': 2,
    'outstretched': 2,
    'outstrip': 2,
    'outstripped': 2,
    'outstripping': 3,
    'outstrips': 2,
    'outta': 2,
    'outtake': 2,
    'outtakes': 2,
    'outten': 2,
    'outvote': 2,
    'outvoted': 3,
    'outvotes': 2,
    'outvoting': 3,
    'outward': 2,
    'outwardly': 3,
    'outwards': 2,
    'outweigh': 2,
    'outweighed': 2,
    'outweighing': 3,
    'outweighs': 2,
    'outwit': 2,
    'outwits': 2,
    'outwitted': 3,
    'outwitting': 3,
    'ouzts': 1,
    'ova': 2,
    'oval': 2,
    'ovalle': 2,
    'ovarian': 4,
    'ovaries': 3,
    'ovary': 3,
    'ovate': 2,
    'ovation': 3,
    'ovations': 3,
    'oven': 2,
    'ovens': 2,
    'over': 2,
    'overabundance': 5,
    'overacker': 4,
    'overact': 3,
    'overacted': 4,
    'overactive': 4,
    'overacts': 3,
    'overall': 3,
    'overallotment': 5,
    'overallotments': 5,
    'overalls': 3,
    'overambitious': 5,
    'overarching': 4,
    'overate': 3,
    'overbaugh': 3,
    'overbay': 3,
    'overbearing': 4,
    'overbeck': 3,
    'overbey': 3,
    'overbill': 3,
    'overbilled': 3,
    'overbilling': 4,
    'overbills': 3,
    'overblown': 3,
    'overboard': 3,
    'overbook': 3,
    'overbooked': 3,
    'overbooking': 4,
    'overbooks': 3,
    'overbought': 3,
    'overbuilding': 4,
    'overbuilt': 3,
    'overburden': 4,
    'overburdened': 4,
    'overbuy': 3,
    'overby': 3,
    'overcame': 3,
    'overcapacity': 6,
    'overcash': 3,
    'overcast': 3,
    'overcharge': 3,
    'overcharged': 3,
    'overcharges': 4,
    'overcharging': 4,
    'overcoat': 3,
    'overcoats': 3,
    'overcome': 3,
    'overcomes': 3,
    'overcoming': 4,
    'overconfidence': 5,
    'overconfident': 5,
    'overconsumption': 5,
    'overcook': 3,
    'overcooked': 3,
    'overcooking': 4,
    'overcooks': 3,
    'overcrowd': 3,
    'overcrowded': 4,
    'overcrowding': 4,
    'overcrowds': 3,
    'overdamping': 4,
    'overdependence': 5,
    'overdependent': 5,
    'overdid': 3,
    'overdo': 3,
    'overdoes': 3,
    'overdoing': 4,
    'overdone': 3,
    'overdorf': 3,
    'overdose': 3,
    'overdosed': 3,
    'overdoses': 4,
    'overdosing': 4,
    'overdraft': 3,
    'overdrafting': 4,
    'overdrafts': 3,
    'overdraw': 3,
    'overdrawn': 3,
    'overdress': 3,
    'overdrew': 3,
    'overdrive': 3,
    'overdue': 3,
    'overeager': 4,
    'overeat': 3,
    'overeating': 4,
    'overemphasize': 5,
    'overemphasized': 5,
    'overemphasizes': 6,
    'overemphasizing': 6,
    'overestimate': 5,
    'overestimated': 6,
    'overestimates': 5,
    'overestimating': 6,
    'overexcite': 4,
    'overexcited': 5,
    'overexcites': 4,
    'overexciting': 5,
    'overexpansion': 5,
    'overexpose': 4,
    'overexposed': 4,
    'overexposes': 5,
    'overexposing': 5,
    'overexposure': 5,
    'overextend': 4,
    'overextended': 5,
    'overextending': 5,
    'overextends': 4,
    'overfed': 3,
    'overfeed': 3,
    'overfelt': 3,
    'overfield': 3,
    'overfill': 3,
    'overfishing': 4,
    'overflight': 3,
    'overflights': 3,
    'overflow': 3,
    'overflowed': 3,
    'overflowing': 4,
    'overflows': 3,
    'overfly': 3,
    'overflying': 4,
    'overfund': 3,
    'overfunded': 4,
    'overfunding': 4,
    'overgaard': 3,
    'overgenerous': 5,
    'overgrazing': 4,
    'overgrowing': 4,
    'overgrown': 3,
    'overgrowth': 3,
    'overhang': 3,
    'overhanging': 4,
    'overhangs': 3,
    'overhaul': 3,
    'overhauled': 3,
    'overhauling': 4,
    'overhauls': 3,
    'overhead': 3,
    'overheads': 3,
    'overhear': 3,
    'overheard': 3,
    'overhearing': 4,
    'overhears': 3,
    'overheat': 3,
    'overheated': 4,
    'overheating': 4,
    'overheats': 3,
    'overholser': 4,
    'overholt': 3,
    'overholtzer': 4,
    'overhung': 3,
    'overjoyed': 3,
    'overkill': 3,
    'overkilling': 4,
    'overlaid': 3,
    'overlain': 3,
    'overland': 3,
    'overlap': 3,
    'overlapped': 3,
    'overlapping': 4,
    'overlaps': 3,
    'overlay': 3,
    'overlays': 3,
    'overleverage': 4,
    'overleveraged': 4,
    'overley': 3,
    'overload': 3,
    'overloaded': 4,
    'overloading': 4,
    'overloads': 3,
    'overlock': 3,
    'overlook': 3,
    'overlooked': 3,
    'overlooking': 4,
    'overlooks': 3,
    'overlord': 3,
    'overlords': 3,
    'overly': 3,
    'overlying': 4,
    'overman': 3,
    'overmatch': 3,
    'overmatched': 3,
    'overmyer': 4,
    'overnight': 3,
    'overnighter': 4,
    'overnighters': 4,
    'overnite': 3,
    'overoptimism': 6,
    'overpaid': 3,
    'overpass': 3,
    'overpasses': 4,
    'overpay': 3,
    'overpaying': 4,
    'overpayment': 4,
    'overpayments': 4,
    'overpeck': 3,
    'overplay': 3,
    'overplayed': 3,
    'overplaying': 4,
    'overplays': 3,
    'overpopulate': 5,
    'overpopulated': 6,
    'overpopulates': 5,
    'overpopulating': 6,
    'overpopulation': 6,
    'overpower': 4,
    'overpowered': 4,
    'overpowering': 4,
    'overpowers': 4,
    'overprice': 3,
    'overpriced': 3,
    'overprices': 4,
    'overpricing': 4,
    'overproduce': 4,
    'overproduced': 4,
    'overproducer': 5,
    'overproducers': 5,
    'overproducing': 5,
    'overproduction': 5,
    'overprotect': 4,
    'overprotected': 5,
    'overprotection': 5,
    'overprotective': 5,
    'overqualified': 5,
    'overqualify': 5,
    'overran': 3,
    'overrate': 3,
    'overrated': 4,
    'overrates': 3,
    'overrating': 4,
    'overreach': 3,
    'overreached': 3,
    'overreaches': 4,
    'overreaching': 4,
    'overreact': 4,
    'overreacted': 5,
    'overreacting': 5,
    'overreaction': 5,
    'overreacts': 4,
    'overregulate': 5,
    'overregulated': 6,
    'overregulates': 5,
    'overregulation': 6,
    'overreliance': 5,
    'overrepresent': 5,
    'overrepresented': 6,
    'overridden': 4,
    'override': 3,
    'overrides': 3,
    'overriding': 4,
    'overripe': 3,
    'overrode': 3,
    'overrule': 3,
    'overruled': 3,
    'overrules': 3,
    'overruling': 4,
    'overrun': 3,
    'overrunning': 4,
    'overruns': 3,
    'overs': 2,
    'oversaw': 3,
    'oversea': 3,
    'overseas': 3,
    'oversee': 3,
    'overseeing': 4,
    'overseen': 3,
    'overseer': 4,
    'overseers': 4,
    'oversees': 3,
    'oversell': 3,
    'overselling': 4,
    'oversensitive': 5,
    'oversensitivity': 7,
    'overshadow': 4,
    'overshadowed': 4,
    'overshadowing': 5,
    'overshadows': 4,
    'overshoot': 3,
    'overshooting': 4,
    'overshot': 3,
    'oversight': 3,
    'oversimplification': 7,
    'oversimplified': 5,
    'oversimplify': 5,
    'oversimplifying': 6,
    'oversize': 3,
    'oversized': 3,
    'oversizes': 4,
    'oversizing': 4,
    'oversleep': 3,
    'overslept': 3,
    'oversold': 3,
    'overson': 3,
    'overspend': 3,
    'overspending': 4,
    'overspends': 3,
    'overspent': 3,
    'overstaff': 3,
    'overstaffed': 3,
    'overstate': 3,
    'overstated': 4,
    'overstatement': 4,
    'overstatements': 4,
    'overstates': 3,
    'overstating': 4,
    'overstay': 3,
    'overstayed': 3,
    'overstep': 3,
    'overstepped': 3,
    'overstepping': 4,
    'overstock': 3,
    'overstocked': 3,
    'overstreet': 3,
    'overstrom': 3,
    'overstuff': 3,
    'overstuffed': 3,
    'oversubscribe': 4,
    'oversubscribed': 4,
    'oversupplied': 4,
    'oversupply': 4,
    'overt': 2,
    'overtake': 3,
    'overtaken': 4,
    'overtakes': 3,
    'overtaking': 4,
    'overtax': 3,
    'overtaxed': 3,
    'overthrew': 3,
    'overthrow': 3,
    'overthrowing': 4,
    'overthrown': 3,
    'overthrows': 3,
    'overtime': 3,
    'overtly': 3,
    'overton': 3,
    'overtone': 3,
    'overtones': 3,
    'overtook': 3,
    'overtrain': 3,
    'overtraining': 4,
    'overture': 3,
    'overtures': 3,
    'overturf': 3,
    'overturn': 3,
    'overturned': 3,
    'overturning': 4,
    'overturns': 3,
    'overuse': 3,
    'overused': 3,
    'overuses': 4,
    'overusing': 4,
    'overvaluation': 6,
    'overvalue': 4,
    'overvalued': 4,
    'overview': 3,
    'overviews': 3,
    'overweight': 3,
    'overweighted': 4,
    'overwhelm': 3,
    'overwhelmability': 7,
    'overwhelmed': 3,
    'overwhelming': 4,
    'overwhelmingly': 5,
    'overwhelms': 3,
    'overwinter': 4,
    'overwork': 3,
    'overworked': 3,
    'overworking': 4,
    'overworks': 3,
    'overwrite': 3,
    'overwrites': 3,
    'overwriting': 4,
    'overwritten': 4,
    'overwrote': 3,
    'overwrought': 3,
    'overy': 3,
    'overzealous': 4,
    'overzealously': 5,
    'oveson': 3,
    'ovett': 2,
    'ovex': 2,
    'oviatt': 3,
    'oviedo': 4,
    'oviparous': 4,
    'ovitt': 2,
    'ovitz': 2,
    'ovoid': 2,
    'ovonic': 3,
    'ovoviviparous': 6,
    'ovshinsky': 3,
    'ovulation': 4,
    'ovulations': 4,
    'ovule': 2,
    'ovules': 2,
    'ovum': 2,
    'ow': 1,
    'owada': 3,
    'owades': 2,
    'owczarzak': 3,
    'owe': 1,
    'owed': 1,
    'owen': 2,
    'owenby': 3,
    'owens': 2,
    'owensboro': 4,
    'owensby': 3,
    'owes': 1,
    'owing': 2,
    'owings': 2,
    'owl': 1,
    'owls': 1,
    'own': 1,
    'ownbey': 2,
    'ownby': 2,
    'owned': 1,
    'owner': 2,
    'owners': 2,
    'ownership': 3,
    'owning': 2,
    'owns': 1,
    'owosso': 3,
    'owsley': 2,
    'owyhee': 3,
    'ox': 1,
    'oxalates': 3,
    'oxalis': 3,
    'oxbow': 2,
    'oxbridge': 2,
    'oxcart': 2,
    'oxdon': 2,
    'oxen': 2,
    'oxendine': 3,
    'oxfam': 2,
    'oxford': 2,
    'oxfords': 2,
    'oxidant': 3,
    'oxidants': 3,
    'oxidation': 4,
    'oxide': 2,
    'oxides': 2,
    'oxidize': 3,
    'oxidized': 3,
    'oxidizer': 4,
    'oxidizing': 4,
    'oxidyne': 3,
    'oxley': 2,
    'oxman': 2,
    'oxnard': 2,
    'oxner': 2,
    'oxoco': 3,
    'oxton': 2,
    'oxy': 2,
    'oxygen': 3,
    'oxygenate': 4,
    'oxygenated': 5,
    'oxymoron': 4,
    'oxytocin': 4,
    'oy': 1,
    'oyama': 3,
    'oye': 1,
    'oyen': 2,
    'oyer': 2,
    'oyler': 2,
    'oyola': 3,
    'oyster': 2,
    'oysters': 2,
    'oz': 1,
    'ozaki': 3,
    'ozal': 2,
    'ozanich': 3,
    'ozanne': 2,
    'ozark': 2,
    'ozarks': 2,
    'ozawa': 3,
    'ozbun': 2,
    'ozburn': 2,
    'ozelle': 2,
    'ozga': 2,
    'ozick': 2,
    'ozier': 3,
    'ozimek': 3,
    'ozment': 2,
    'ozmet': 2,
    'ozmun': 2,
    'ozolins': 3,
    'ozols': 2,
    'ozone': 2,
    'ozora': 3,
    'ozuna': 3,
    'ozzie': 2,
    'p': 1,
    'pa': 1,
    'paap': 1,
    'paape': 1,
    'paar': 1,
    'paasch': 1,
    'paavola': 3,
    'pablo': 2,
    'pablum': 2,
    'pabon': 2,
    'pabst': 1,
    'pac': 1,
    'paca': 2,
    'paccar': 2,
    'paccione': 3,
    'pace': 1,
    'paced': 1,
    'pacella': 3,
    'pacelli': 3,
    'pacemaker': 3,
    'pacemakers': 3,
    'pacer': 2,
    'pacers': 2,
    'paces': 2,
    'pacesetter': 3,
    'paceway': 2,
    'pacey': 2,
    'pacheco': 3,
    'pachinko': 3,
    'pacholder': 3,
    'pacholski': 3,
    'pachter': 2,
    'pachysandra': 4,
    'paci': 2,
    'pacific': 3,
    'pacifica': 4,
    'pacificare': 4,
    'pacification': 5,
    'pacifico': 4,
    'pacificorp': 4,
    'pacified': 3,
    'pacifier': 4,
    'pacifiers': 4,
    'pacifism': 4,
    'pacifist': 3,
    'pacifists': 3,
    'pacify': 3,
    'pacing': 2,
    'pacini': 3,
    'pacino': 3,
    'pack': 1,
    'package': 2,
    'packaged': 2,
    'packager': 3,
    'packagers': 3,
    'packages': 3,
    'packaging': 3,
    'packard': 2,
    'packed': 1,
    'packer': 2,
    'packers': 2,
    'packet': 2,
    'packets': 2,
    'packett': 2,
    'packham': 2,
    'packing': 2,
    'packinghouse': 3,
    'packman': 2,
    'packs': 1,
    'packwood': 2,
    'pacman': 2,
    'paco': 2,
    'pacs': 1,
    'pact': 1,
    'pactel': 2,
    'pacto': 2,
    'pacts': 1,
    'pacyna': 3,
    'paczkowski': 3,
    'pad': 1,
    'paddack': 2,
    'padded': 2,
    'padden': 2,
    'paddies': 2,
    'padding': 2,
    'paddington': 3,
    'paddle': 2,
    'paddled': 2,
    'paddles': 2,
    'paddling': 3,
    'paddock': 2,
    'paddy': 2,
    'paden': 2,
    'paderewski': 4,
    'padfield': 2,
    'padget': 2,
    'padgett': 2,
    'padgitt': 2,
    'padilla': 3,
    'padley': 2,
    'padlock': 2,
    'padlocked': 2,
    'padlocks': 2,
    'padmanabhan': 4,
    'padovano': 4,
    'padre': 2,
    'padres': 2,
    'padrick': 2,
    'padro': 2,
    'padron': 2,
    'pads': 1,
    'padua': 3,
    'paduano': 4,
    'paducah': 3,
    'padula': 3,
    'pae': 1,
    'paean': 2,
    'paeans': 2,
    'paek': 1,
    'paeth': 1,
    'paetz': 1,
    'paez': 2,
    'paff': 1,
    'pafford': 2,
    'pagan': 2,
    'paganelli': 4,
    'pagani': 3,
    'paganini': 4,
    'paganism': 4,
    'pagano': 3,
    'pagar': 2,
    'page': 1,
    'pageant': 2,
    'pageantry': 3,
    'pageants': 2,
    'paged': 1,
    'pagel': 2,
    'pagels': 2,
    'pagemaker': 3,
    'pagenkopf': 3,
    'pager': 2,
    'pagers': 2,
    'pages': 2,
    'paget': 2,
    'pagett': 2,
    'pagette': 2,
    'pagey': 2,
    'pagezy': 3,
    'pagination': 4,
    'paging': 2,
    'paglia': 3,
    'pagliaro': 4,
    'pagliarulo': 5,
    'pagliuca': 4,
    'pagni': 2,
    'pagnotta': 3,
    'pagoda': 3,
    'pagodas': 3,
    'pagurian': 4,
    'pah': 1,
    'pahl': 1,
    'pahnos': 2,
    'pai': 2,
    'paid': 1,
    'paige': 1,
    'paighton': 2,
    'paign': 1,
    'paik': 1,
    'pail': 1,
    'paille': 1,
    'pails': 1,
    'pain': 1,
    'paine': 1,
    'pained': 1,
    'painesville': 2,
    'painewebber': 3,
    'painful': 2,
    'painfully': 3,
    'painkiller': 3,
    'painkillers': 3,
    'painless': 2,
    'painlessly': 3,
    'paino': 2,
    'pains': 1,
    'painstaking': 3,
    'painstakingly': 4,
    'paint': 1,
    'paintball': 2,
    'paintbrush': 2,
    'paintbrushes': 3,
    'painted': 2,
    'painter': 2,
    'painterly': 3,
    'painters': 2,
    'painting': 2,
    'paintings': 2,
    'paints': 1,
    'pair': 1,
    'paired': 1,
    'pairing': 2,
    'pairs': 1,
    'pais': 1,
    'paisley': 2,
    'pait': 1,
    'paiute': 2,
    'paiva': 2,
    'paiz': 1,
    'pajak': 2,
    'pajama': 3,
    'pajamas': 3,
    'pajole': 2,
    'pajoli': 3,
    'pak': 1,
    'pake': 1,
    'pakeha': 3,
    'pakistan': 3,
    'pakistani': 4,
    'pakistanian': 5,
    'pakistanis': 4,
    'pakula': 3,
    'pakulski': 3,
    'pal': 1,
    'palace': 2,
    'palaces': 3,
    'palacio': 4,
    'palacios': 4,
    'paladin': 3,
    'paladino': 4,
    'palafox': 3,
    'palais': 2,
    'palance': 2,
    'palansky': 3,
    'palardy': 3,
    'palash': 2,
    'palatability': 6,
    'palatable': 4,
    'palate': 2,
    'palates': 2,
    'palatial': 3,
    'palatine': 3,
    'palau': 2,
    'palauans': 3,
    'palay': 2,
    'palazina': 4,
    'palazzi': 3,
    'palazzo': 3,
    'palazzola': 4,
    'palazzolo': 4,
    'palca': 2,
    'palco': 2,
    'paldon': 2,
    'pale': 1,
    'palecek': 3,
    'paled': 1,
    'palen': 2,
    'paleo': 3,
    'paleobotany': 6,
    'paleocene': 4,
    'paleontologist': 6,
    'paleontologists': 6,
    'paleontology': 6,
    'paleozoic': 5,
    'palermo': 3,
    'pales': 1,
    'palese': 3,
    'palest': 2,
    'palestine': 3,
    'palestinian': 5,
    'palestinians': 5,
    'palette': 2,
    'paley': 2,
    'palfrey': 2,
    'palimony': 4,
    'palin': 2,
    'palinkas': 3,
    'palisade': 3,
    'palisades': 3,
    'palka': 2,
    'palkar': 2,
    'palko': 2,
    'palkovic': 3,
    'pall': 1,
    'palla': 2,
    'palladino': 4,
    'palladium': 4,
    'pallante': 3,
    'pallas': 2,
    'paller': 2,
    'palleschi': 3,
    'pallet': 2,
    'pallets': 2,
    'pallett': 2,
    'palliative': 4,
    'palliatives': 4,
    'pallid': 2,
    'pallidotomy': 5,
    'pallo': 2,
    'pallone': 3,
    'palm': 1,
    'palma': 2,
    'palmateer': 3,
    'palmatier': 4,
    'palmdale': 2,
    'palme': 1,
    'palmer': 2,
    'palmeri': 3,
    'palmerino': 4,
    'palmero': 3,
    'palmerton': 3,
    'palmertree': 3,
    'palmetto': 3,
    'palmgren': 2,
    'palmieri': 4,
    'palminteri': 4,
    'palmira': 3,
    'palmisano': 4,
    'palmist': 2,
    'palmistry': 3,
    'palmists': 2,
    'palmiter': 3,
    'palmitic': 3,
    'palmolive': 3,
    'palmore': 2,
    'palmquist': 2,
    'palms': 1,
    'palmstierna': 4,
    'palo': 2,
    'paloma': 3,
    'palomar': 3,
    'palomares': 4,
    'palomba': 3,
    'palombi': 3,
    'palombo': 3,
    'palometa': 4,
    'palomino': 4,
    'palomita': 4,
    'palomo': 3,
    'palone': 2,
    'palonius': 4,
    'palos': 2,
    'palpable': 3,
    'palpably': 3,
    'palpitate': 3,
    'palpitated': 4,
    'palpitates': 3,
    'palpitating': 4,
    'palpitation': 4,
    'palpitations': 4,
    'pals': 1,
    'palsy': 2,
    'palton': 2,
    'paltry': 2,
    'paltz': 1,
    'paluch': 2,
    'paluck': 2,
    'palumbo': 3,
    'paluzzi': 3,
    'pam': 1,
    'pamacho': 3,
    'pamby': 2,
    'pamela': 3,
    'pamelina': 4,
    'pamella': 3,
    'pamer': 2,
    'pammy': 2,
    'pamour': 2,
    'pampas': 2,
    'pampel': 2,
    'pamper': 2,
    'pampered': 2,
    'pamperin': 3,
    'pampering': 3,
    'pampers': 2,
    'pamphlet': 2,
    'pamphleteer': 3,
    'pamphleteers': 3,
    'pamphlets': 2,
    'pamplin': 2,
    'pamplona': 3,
    'pan': 1,
    'panacea': 4,
    'panache': 2,
    'panaco': 3,
    'panagopoulos': 5,
    'panagos': 3,
    'panam': 2,
    'panama': 3,
    'panamanian': 5,
    'panamanians': 5,
    'panamsat': 3,
    'panaro': 3,
    'panas': 2,
    'panasonic': 4,
    'pancake': 2,
    'pancaked': 2,
    'pancakes': 2,
    'pancanadian': 5,
    'pancer': 2,
    'pancho': 2,
    'panciera': 3,
    'pancoast': 2,
    'pancontinental': 5,
    'pancreas': 3,
    'pancreatic': 4,
    'panda': 2,
    'pandanus': 3,
    'pandas': 2,
    'pandemic': 3,
    'pandemonium': 5,
    'pander': 2,
    'pandered': 2,
    'pandering': 3,
    'pandey': 2,
    'pandick': 2,
    'pandit': 2,
    'pando': 2,
    'pandolfi': 3,
    'pandolfo': 3,
    'pandora': 3,
    'pandy': 2,
    'pandya': 2,
    'pane': 1,
    'panebianco': 5,
    'panek': 2,
    'panel': 2,
    'paneled': 2,
    'paneling': 3,
    'panelist': 3,
    'panelists': 3,
    'panelization': 5,
    'panelize': 3,
    'panelized': 3,
    'panella': 3,
    'panels': 2,
    'panem': 2,
    'panepinto': 4,
    'panes': 1,
    'panetta': 3,
    'panfida': 3,
    'panfil': 2,
    'panfile': 2,
    'panful': 2,
    'panfuls': 2,
    'pang': 1,
    'pangallo': 3,
    'pangborn': 2,
    'pangburn': 2,
    'pangels': 2,
    'pangle': 2,
    'pangloss': 2,
    'pangs': 1,
    'panhandle': 3,
    'panhandler': 3,
    'panhandlers': 3,
    'panhandling': 3,
    'paniagua': 4,
    'panic': 2,
    'paniccia': 3,
    'panicked': 2,
    'panicking': 3,
    'panicky': 3,
    'panico': 3,
    'panics': 2,
    'panik': 2,
    'pankau': 2,
    'pankey': 2,
    'pankki': 2,
    'panko': 2,
    'pankonin': 3,
    'pankow': 2,
    'pankratz': 2,
    'panky': 2,
    'panmunjom': 3,
    'panmure': 2,
    'panned': 1,
    'pannell': 2,
    'pannier': 3,
    'pannill': 2,
    'panning': 2,
    'pannone': 3,
    'pannu': 2,
    'pannullo': 3,
    'panny': 2,
    'panoply': 3,
    'panopolys': 4,
    'panora': 3,
    'panorama': 4,
    'panoramic': 4,
    'panos': 2,
    'panoz': 2,
    'panozzo': 3,
    'panphila': 3,
    'pans': 1,
    'pansies': 2,
    'pansophic': 3,
    'pansy': 2,
    'pant': 1,
    'pantaleo': 4,
    'pantalone': 3,
    'pantaloon': 3,
    'pantaloons': 3,
    'pantano': 3,
    'panted': 2,
    'pantel': 2,
    'panter': 2,
    'pantex': 2,
    'panthea': 3,
    'pantheistic': 4,
    'pantheon': 3,
    'panther': 2,
    'panthers': 2,
    'panties': 2,
    'panting': 2,
    'pantle': 2,
    'pantoja': 3,
    'pantomime': 3,
    'panton': 2,
    'pantry': 2,
    'pants': 1,
    'pantsuit': 2,
    'pantsuits': 2,
    'pantuso': 3,
    'panty': 2,
    'pantyhose': 3,
    'panza': 2,
    'panzarella': 4,
    'panzer': 2,
    'panzhihua': 4,
    'panzica': 3,
    'pao': 1,
    'paolella': 4,
    'paoletti': 4,
    'paoli': 2,
    'paolillo': 4,
    'paolini': 4,
    'paolino': 4,
    'paolo': 2,
    'paolucci': 4,
    'paone': 2,
    'paonessa': 4,
    'pap': 1,
    'papa': 2,
    'papacy': 3,
    'papadakis': 4,
    'papadopoulos': 5,
    'papageorge': 3,
    'papago': 3,
    'papal': 2,
    'papale': 3,
    'papaleo': 4,
    'papalia': 4,
    'papandrea': 4,
    'papandreou': 4,
    'papania': 4,
    'paparazzi': 4,
    'paparazzis': 4,
    'paparella': 4,
    'papas': 2,
    'papaw': 2,
    'papay': 2,
    'papaya': 3,
    'papayas': 3,
    'papazian': 4,
    'pape': 1,
    'papenfuss': 3,
    'paper': 2,
    'paperback': 3,
    'paperbacks': 3,
    'paperboard': 3,
    'paperboy': 3,
    'paperboys': 3,
    'papered': 2,
    'papering': 3,
    'paperless': 3,
    'papermaking': 4,
    'papermate': 3,
    'papernick': 3,
    'papers': 2,
    'paperweight': 3,
    'paperwork': 3,
    'paperworker': 4,
    'paperworkers': 4,
    'papery': 3,
    'papesh': 2,
    'papier': 2,
    'papillion': 3,
    'papilloma': 4,
    'papin': 2,
    'papineau': 3,
    'papini': 3,
    'papke': 1,
    'papo': 2,
    'papon': 2,
    'papp': 1,
    'pappa': 2,
    'pappadio': 4,
    'pappalardo': 4,
    'pappas': 2,
    'pappert': 2,
    'paprika': 3,
    'paprocki': 3,
    'papson': 2,
    'papua': 3,
    'paque': 1,
    'paquet': 2,
    'paquette': 2,
    'paquin': 2,
    'par': 1,
    'para': 2,
    'parable': 3,
    'parables': 3,
    'parabola': 4,
    'parabolic': 4,
    'parachute': 3,
    'parachuted': 4,
    'parachutes': 3,
    'parachuting': 4,
    'parada': 3,
    'parade': 2,
    'paraded': 3,
    'paradee': 3,
    'parades': 2,
    'paradigm': 3,
    'paradigms': 3,
    'parading': 3,
    'paradis': 3,
    'paradise': 3,
    'paradiso': 4,
    'parador': 3,
    'paradowski': 4,
    'paradox': 3,
    'paradoxes': 4,
    'paradoxical': 5,
    'paradoxically': 5,
    'parady': 3,
    'paradyne': 3,
    'paraffin': 3,
    'parag': 2,
    'paraglide': 3,
    'paragon': 3,
    'paragraph': 3,
    'paragraphs': 3,
    'paraguay': 3,
    'paraguayan': 4,
    'parakeet': 3,
    'parakeets': 3,
    'paralegal': 4,
    'paralegals': 4,
    'parallax': 3,
    'parallel': 3,
    'paralleled': 3,
    'paralleling': 4,
    'parallelism': 5,
    'parallels': 3,
    'paralympic': 4,
    'paralysis': 4,
    'paralytic': 4,
    'paralyze': 3,
    'paralyzed': 3,
    'paralyzes': 4,
    'paralyzing': 4,
    'paramagnetic': 5,
    'paramax': 3,
    'paramecium': 5,
    'paramedic': 4,
    'paramedics': 4,
    'parameter': 4,
    'parameters': 4,
    'parametric': 4,
    'paramilitaries': 6,
    'paramilitary': 6,
    'paramo': 3,
    'paramonova': 5,
    'paramore': 3,
    'paramount': 3,
    'paramus': 3,
    'parana': 3,
    'paranoia': 4,
    'paranoiac': 4,
    'paranoid': 3,
    'paranormal': 4,
    'parapet': 3,
    'paraphernalia': 5,
    'paraphrase': 3,
    'paraphrasing': 4,
    'paraplegia': 5,
    'paraplegic': 4,
    'parasite': 3,
    'parasites': 3,
    'parasitic': 4,
    'parasympathetic': 6,
    'paratore': 4,
    'paratroop': 3,
    'paratrooper': 4,
    'paratroopers': 4,
    'paratroops': 3,
    'parazoa': 4,
    'parboil': 2,
    'parboiling': 3,
    'parc': 1,
    'parcel': 2,
    'parceled': 2,
    'parceling': 3,
    'parcell': 2,
    'parcells': 2,
    'parcels': 2,
    'parch': 1,
    'parched': 1,
    'parcher': 2,
    'parchman': 2,
    'parchment': 2,
    'parchmentlike': 3,
    'parcplace': 2,
    'parde': 1,
    'pardee': 2,
    'parden': 2,
    'pardi': 2,
    'pardini': 3,
    'pardo': 2,
    'pardoe': 2,
    'pardon': 2,
    'pardoned': 2,
    'pardoning': 3,
    'pardons': 2,
    'pardue': 2,
    'pardus': 2,
    'pardy': 2,
    'pare': 1,
    'pared': 1,
    'paredes': 3,
    'paredez': 3,
    'paredis': 3,
    'paredo': 3,
    'parekh': 2,
    'parella': 3,
    'parent': 2,
    'parentage': 3,
    'parental': 3,
    'parente': 3,
    'parenteau': 3,
    'parentheses': 4,
    'parenthesis': 4,
    'parenthetical': 5,
    'parenthetically': 6,
    'parenthood': 3,
    'parenti': 3,
    'parenting': 3,
    'parents': 2,
    'pares': 1,
    'pareti': 3,
    'paretti': 3,
    'parfitt': 2,
    'parfums': 2,
    'parga': 2,
    'pargesa': 3,
    'parham': 2,
    'pariagua': 4,
    'pariah': 3,
    'pariahs': 3,
    'paribas': 3,
    'parietal': 4,
    'parikh': 2,
    'parillo': 3,
    'parimutuel': 5,
    'paring': 2,
    'paris': 2,
    'parise': 2,
    'pariseau': 3,
    'parish': 2,
    'parishes': 3,
    'parishioner': 4,
    'parishioners': 4,
    'parisi': 3,
    'parisian': 3,
    'parisians': 4,
    'parisienne': 4,
    'parities': 3,
    'parity': 3,
    'parizeau': 3,
    'parizek': 3,
    'parizo': 3,
    'park': 1,
    'parka': 2,
    'parkas': 2,
    'parke': 1,
    'parked': 1,
    'parker': 2,
    'parkers': 2,
    'parkersburg': 3,
    'parkerson': 3,
    'parkes': 1,
    'parkey': 2,
    'parkfield': 2,
    'parkhill': 2,
    'parkhurst': 2,
    'parkin': 2,
    'parking': 2,
    'parkins': 2,
    'parkinson': 3,
    'parkinsonism': 5,
    'parkison': 3,
    'parkland': 2,
    'parkman': 2,
    'parks': 1,
    'parkside': 2,
    'parkway': 2,
    'parlance': 2,
    'parlato': 3,
    'parlay': 2,
    'parlayed': 2,
    'parlaying': 3,
    'parlays': 2,
    'parlee': 2,
    'parler': 2,
    'parlett': 2,
    'parlette': 2,
    'parley': 2,
    'parliament': 3,
    'parliamentarian': 6,
    'parliamentarianism': 8,
    'parliamentarians': 6,
    'parliamentary': 5,
    'parliaments': 3,
    'parlier': 3,
    'parlin': 2,
    'parlodel': 3,
    'parlor': 2,
    'parlors': 2,
    'parlour': 2,
    'parlow': 2,
    'parma': 2,
    'parmalat': 3,
    'parman': 2,
    'parmar': 2,
    'parmele': 3,
    'parmelee': 3,
    'parmenter': 3,
    'parmentier': 4,
    'parmer': 2,
    'parmesan': 3,
    'parmeter': 3,
    'parmley': 2,
    'parnassus': 3,
    'parnell': 2,
    'parnella': 3,
    'parnes': 1,
    'parness': 2,
    'paro': 2,
    'parochial': 4,
    'parochialism': 6,
    'parodi': 3,
    'parodied': 3,
    'parodies': 3,
    'parodist': 3,
    'parody': 3,
    'parole': 2,
    'paroled': 2,
    'parolee': 3,
    'parolees': 3,
    'paroles': 2,
    'paroling': 3,
    'parpart': 2,
    'parquet': 2,
    'parr': 1,
    'parra': 2,
    'parrack': 2,
    'parramore': 3,
    'parras': 2,
    'parrella': 3,
    'parrent': 2,
    'parrett': 2,
    'parretti': 3,
    'parried': 2,
    'parrill': 2,
    'parrilla': 3,
    'parrillo': 3,
    'parrinello': 4,
    'parrino': 3,
    'parriott': 3,
    'parris': 2,
    'parrish': 2,
    'parrishes': 3,
    'parrot': 2,
    'parrots': 2,
    'parrott': 2,
    'parrotta': 3,
    'parrow': 2,
    'parry': 2,
    'pars': 1,
    'parse': 1,
    'parsec': 2,
    'parsed': 1,
    'parsefal': 3,
    'parsell': 2,
    'parser': 2,
    'parsers': 2,
    'parshall': 2,
    'parshley': 2,
    'parsi': 2,
    'parsifal': 3,
    'parsimonious': 5,
    'parsimony': 4,
    'parsing': 2,
    'parsippany': 4,
    'parsky': 2,
    'parsley': 2,
    'parslow': 2,
    'parson': 2,
    'parsonage': 3,
    'parsonages': 4,
    'parsons': 2,
    'parsow': 2,
    'part': 1,
    'partain': 2,
    'partake': 2,
    'partch': 1,
    'parte': 1,
    'partecipazioni': 7,
    'parted': 2,
    'partee': 2,
    'parten': 2,
    'partenope': 3,
    'parthenia': 4,
    'parthenogenetic': 6,
    'parthenon': 3,
    'parthian': 3,
    'parti': 2,
    'partial': 2,
    'partiality': 5,
    'partially': 3,
    'participant': 4,
    'participants': 4,
    'participate': 4,
    'participated': 5,
    'participates': 4,
    'participating': 5,
    'participation': 5,
    'participations': 5,
    'participative': 5,
    'participatory': 6,
    'participle': 4,
    'participles': 4,
    'particle': 3,
    'particles': 3,
    'particular': 4,
    'particularity': 6,
    'particularly': 5,
    'particulars': 4,
    'particulate': 4,
    'particulates': 4,
    'partida': 3,
    'partido': 3,
    'partied': 2,
    'parties': 2,
    'partin': 2,
    'parting': 2,
    'partington': 3,
    'partisan': 3,
    'partisaned': 3,
    'partisans': 3,
    'partisanship': 4,
    'partition': 3,
    'partitioned': 3,
    'partitioning': 4,
    'partitions': 3,
    'partlow': 2,
    'partly': 2,
    'partner': 2,
    'partnering': 3,
    'partners': 2,
    'partnership': 3,
    'partnerships': 3,
    'partney': 2,
    'parton': 2,
    'partridge': 2,
    'partridges': 3,
    'parts': 1,
    'parttime': 2,
    'partum': 2,
    'party': 2,
    'partying': 3,
    'partyka': 3,
    'parvin': 2,
    'pary': 2,
    'parziale': 4,
    'parzych': 2,
    'pas': 1,
    'pasadena': 4,
    'pascagoula': 4,
    'pascal': 2,
    'pascale': 2,
    'pascarella': 4,
    'pascarelli': 4,
    'pasch': 1,
    'paschal': 2,
    'paschall': 2,
    'paschen': 2,
    'paschke': 1,
    'pasco': 2,
    'pascoe': 2,
    'pascua': 3,
    'pascual': 3,
    'pascucci': 3,
    'pascutto': 3,
    'pascuzzi': 3,
    'pasek': 2,
    'paseo': 3,
    'pash': 1,
    'pasha': 2,
    'pashley': 2,
    'pasillas': 3,
    'pasing': 2,
    'pasion': 2,
    'pask': 1,
    'paske': 1,
    'paskey': 2,
    'pasko': 2,
    'paslay': 2,
    'pasley': 2,
    'pasman': 2,
    'paso': 2,
    'pasok': 2,
    'pasqua': 2,
    'pasquale': 3,
    'pasquarella': 4,
    'pasquarelli': 4,
    'pasquarello': 4,
    'pasquariello': 5,
    'pasque': 1,
    'pasquinelli': 4,
    'pasquini': 3,
    'pass': 1,
    'passable': 3,
    'passably': 3,
    'passafiume': 4,
    'passage': 2,
    'passages': 3,
    'passageway': 3,
    'passaic': 3,
    'passalacqua': 4,
    'passanisi': 4,
    'passante': 3,
    'passantino': 4,
    'passarella': 4,
    'passarelli': 4,
    'passaretti': 4,
    'passaro': 3,
    'passat': 2,
    'passbook': 2,
    'passbooks': 2,
    'passe': 2,
    'passed': 1,
    'passel': 2,
    'passenger': 3,
    'passengers': 3,
    'passer': 2,
    'passerby': 3,
    'passero': 3,
    'passers': 2,
    'passersby': 3,
    'passes': 2,
    'passey': 2,
    'passim': 2,
    'passing': 2,
    'passino': 3,
    'passion': 2,
    'passionate': 3,
    'passionately': 4,
    'passions': 2,
    'passive': 2,
    'passively': 3,
    'passivity': 4,
    'passman': 2,
    'passmore': 2,
    'passon': 2,
    'passover': 3,
    'passow': 2,
    'passport': 2,
    'passports': 2,
    'password': 2,
    'passwords': 2,
    'past': 1,
    'pasta': 2,
    'pastas': 2,
    'paste': 1,
    'pasted': 2,
    'pastel': 2,
    'pastels': 2,
    'paster': 2,
    'pasternack': 3,
    'pasternak': 3,
    'pastes': 1,
    'pasteur': 2,
    'pasteurization': 5,
    'pasteurize': 3,
    'pasteurized': 3,
    'pasteurizes': 4,
    'pasteurizing': 4,
    'pastiche': 2,
    'pastiches': 3,
    'pastime': 2,
    'pastimes': 2,
    'pasting': 2,
    'pastor': 2,
    'pastora': 3,
    'pastoral': 3,
    'pastoralism': 5,
    'pastoralize': 4,
    'pastore': 2,
    'pastorino': 4,
    'pastorius': 4,
    'pastors': 2,
    'pastrami': 3,
    'pastrana': 3,
    'pastries': 2,
    'pastry': 2,
    'pasts': 1,
    'pastula': 3,
    'pasture': 2,
    'pastures': 2,
    'paszek': 2,
    'paszkiewicz': 3,
    'pasztor': 2,
    'pat': 1,
    'patagonia': 5,
    'patagonian': 5,
    'patak': 2,
    'pataki': 3,
    'pataky': 3,
    'patalano': 4,
    'patane': 2,
    'patch': 1,
    'patched': 1,
    'patchell': 2,
    'patchen': 2,
    'patches': 2,
    'patchett': 2,
    'patchin': 2,
    'patching': 2,
    'patchwork': 2,
    'patchworks': 2,
    'patchy': 2,
    'patco': 2,
    'pate': 1,
    'patek': 2,
    'patel': 2,
    'patella': 3,
    'patenaude': 3,
    'patent': 2,
    'patentable': 4,
    'patented': 3,
    'patenting': 3,
    'patently': 3,
    'patents': 2,
    'pater': 2,
    'patera': 3,
    'paternal': 3,
    'paternalism': 5,
    'paternalistic': 5,
    'paternity': 4,
    'paterno': 3,
    'paternostro': 4,
    'paterson': 3,
    'pates': 1,
    'patese': 3,
    'patey': 2,
    'path': 1,
    'pathak': 2,
    'pathan': 2,
    'pathans': 2,
    'pathe': 1,
    'pathetic': 3,
    'pathetically': 5,
    'pathfinder': 3,
    'pathmark': 2,
    'pathogen': 3,
    'pathogenic': 4,
    'pathogens': 3,
    'pathological': 5,
    'pathologically': 5,
    'pathologies': 4,
    'pathologist': 4,
    'pathologists': 4,
    'pathology': 4,
    'pathos': 2,
    'paths': 1,
    'pathway': 2,
    'pathways': 2,
    'patience': 2,
    'patient': 2,
    'patiently': 3,
    'patients': 2,
    'patillo': 3,
    'patin': 2,
    'patina': 3,
    'patinkin': 3,
    'patino': 3,
    'patio': 3,
    'patios': 3,
    'patlan': 2,
    'patlex': 2,
    'patman': 2,
    'patmore': 2,
    'patnaude': 3,
    'patnode': 2,
    'paton': 2,
    'patons': 2,
    'patriarca': 4,
    'patriarch': 3,
    'patriarchal': 4,
    'patriarchate': 4,
    'patriarchs': 3,
    'patriarchy': 4,
    'patrice': 2,
    'patricelli': 4,
    'patricia': 3,
    'patrician': 3,
    'patricians': 3,
    'patricio': 4,
    'patrick': 2,
    'patricks': 2,
    'patricof': 3,
    'patridge': 2,
    'patrie': 3,
    'patrilineal': 5,
    'patrimonial': 4,
    'patrimony': 4,
    'patriot': 3,
    'patriotic': 4,
    'patriotism': 5,
    'patriots': 3,
    'patristic': 3,
    'patrizio': 4,
    'patrol': 2,
    'patrolled': 2,
    'patrolling': 3,
    'patrolman': 3,
    'patrolmen': 3,
    'patrols': 2,
    'patron': 2,
    'patronage': 3,
    'patrone': 3,
    'patroness': 3,
    'patronize': 3,
    'patronized': 3,
    'patronizes': 4,
    'patronizing': 4,
    'patrons': 2,
    'patronym': 3,
    'patronymic': 4,
    'patry': 2,
    'pats': 1,
    'patsies': 2,
    'patsy': 2,
    'patt': 1,
    'patted': 2,
    'pattee': 2,
    'patten': 2,
    'patter': 2,
    'pattered': 2,
    'pattern': 2,
    'patterned': 2,
    'patterns': 2,
    'patterson': 3,
    'patteson': 3,
    'patti': 2,
    'pattie': 2,
    'patties': 2,
    'pattillo': 3,
    'pattin': 2,
    'patting': 2,
    'pattinson': 3,
    'pattis': 2,
    'pattison': 3,
    'pattiz': 2,
    'patton': 2,
    'patty': 2,
    'patuxent': 3,
    'patuxet': 3,
    'patuxets': 3,
    'paty': 2,
    'patz': 1,
    'patzer': 2,
    'patzke': 2,
    'paucity': 3,
    'paugh': 1,
    'paul': 1,
    'paula': 2,
    'paulding': 2,
    'paule': 1,
    'paules': 1,
    'pauletta': 3,
    'paulette': 2,
    'pauley': 2,
    'paulhamus': 3,
    'paulhus': 2,
    'pauli': 2,
    'paulick': 2,
    'paulik': 2,
    'paulin': 2,
    'paulina': 3,
    'pauline': 2,
    'pauling': 2,
    'paulino': 3,
    'paulita': 3,
    'paulk': 1,
    'paull': 1,
    'paullin': 2,
    'paulo': 2,
    'paulos': 2,
    'pauls': 1,
    'paulsen': 2,
    'paulson': 2,
    'paulus': 2,
    'pauly': 2,
    'paunchy': 2,
    'paup': 1,
    'pauper': 2,
    'paupers': 2,
    'pause': 1,
    'paused': 1,
    'pauses': 2,
    'pausing': 2,
    'paustian': 2,
    'pautler': 3,
    'pautsch': 1,
    'pautz': 1,
    'pauwels': 2,
    'pavao': 2,
    'pavarotti': 4,
    'pave': 1,
    'paved': 1,
    'pavek': 2,
    'pavel': 2,
    'pavelic': 3,
    'pavelka': 3,
    'pavelko': 3,
    'pavement': 2,
    'pavements': 2,
    'paver': 2,
    'paves': 1,
    'pavese': 3,
    'pavey': 2,
    'pavia': 3,
    'pavich': 2,
    'pavilion': 3,
    'pavilions': 3,
    'pavillion': 3,
    'paving': 2,
    'pavlak': 2,
    'pavlic': 2,
    'pavlica': 3,
    'pavlicek': 3,
    'pavlich': 2,
    'pavlick': 2,
    'pavlik': 2,
    'pavlis': 2,
    'pavlock': 2,
    'pavlov': 2,
    'pavlovian': 4,
    'pavlovic': 3,
    'pavlovich': 3,
    'pavo': 2,
    'pavon': 2,
    'pavone': 2,
    'paw': 1,
    'pawed': 1,
    'pawelek': 3,
    'pawelski': 3,
    'pawelsky': 3,
    'pawing': 2,
    'pawlak': 2,
    'pawley': 2,
    'pawlicki': 3,
    'pawlik': 2,
    'pawlikowski': 4,
    'pawling': 2,
    'pawloski': 3,
    'pawlowicz': 3,
    'pawlowski': 3,
    'pawluk': 2,
    'pawn': 1,
    'pawned': 1,
    'pawnee': 2,
    'pawnees': 2,
    'pawns': 1,
    'pawnshop': 2,
    'pawnshops': 2,
    'paws': 1,
    'pawson': 2,
    'pawtucket': 3,
    'pawtuxet': 3,
    'pax': 1,
    'paxar': 2,
    'paxman': 2,
    'paxon': 2,
    'paxson': 2,
    'paxton': 2,
    'pay': 1,
    'payable': 3,
    'payables': 3,
    'payan': 2,
    'payback': 2,
    'paybacks': 2,
    'paycheck': 2,
    'paychecks': 2,
    'paychex': 2,
    'payco': 2,
    'payday': 2,
    'paye': 1,
    'payer': 2,
    'payers': 2,
    'payette': 2,
    'payeur': 2,
    'paying': 2,
    'payless': 2,
    'payload': 2,
    'payloads': 2,
    'paylor': 2,
    'payment': 2,
    'payments': 2,
    'paymer': 2,
    'payna': 2,
    'payne': 1,
    'paynter': 2,
    'payoff': 2,
    'payoffs': 2,
    'payola': 3,
    'payout': 2,
    'payouts': 2,
    'payroll': 2,
    'payrolls': 2,
    'pays': 1,
    'paysinger': 3,
    'payson': 2,
    'paysop': 2,
    'paysops': 2,
    'payton': 2,
    'paz': 1,
    'pazner': 2,
    'pazos': 2,
    'pazuto': 3,
    'pc': 2,
    'pcs': 2,
    'pea': 1,
    'peabody': 3,
    'peace': 1,
    'peaceable': 3,
    'peaceably': 3,
    'peaceful': 2,
    'peacefully': 3,
    'peacefulness': 3,
    'peacekeeper': 3,
    'peacekeepers': 3,
    'peacekeeping': 3,
    'peacemaker': 3,
    'peacemakers': 3,
    'peacemaking': 3,
    'peacetime': 2,
    'peach': 1,
    'peacher': 2,
    'peaches': 2,
    'peachey': 2,
    'peachtree': 2,
    'peachy': 2,
    'peacock': 2,
    'peacocks': 2,
    'peaden': 2,
    'peafowl': 2,
    'peagler': 2,
    'peak': 1,
    'peake': 1,
    'peaked': 1,
    'peakes': 1,
    'peaking': 2,
    'peaks': 1,
    'peal': 1,
    'peale': 1,
    'pealer': 2,
    'peanut': 2,
    'peanuts': 2,
    'peapack': 2,
    'peapod': 2,
    'pear': 1,
    'pearce': 1,
    'pearcy': 2,
    'pearl': 1,
    'pearle': 1,
    'pearline': 2,
    'pearling': 2,
    'pearlman': 2,
    'pearls': 1,
    'pearlstein': 2,
    'pearlstine': 2,
    'pearly': 2,
    'pearman': 2,
    'pears': 1,
    'pearsall': 2,
    'pearse': 1,
    'pearson': 2,
    'peart': 1,
    'peary': 2,
    'peas': 1,
    'peasant': 2,
    'peasantry': 3,
    'peasants': 2,
    'pease': 1,
    'peasey': 2,
    'peaslee': 2,
    'peasley': 2,
    'peat': 1,
    'peatross': 2,
    'peaudouce': 2,
    'peavey': 2,
    'peavler': 2,
    'peavy': 2,
    'peay': 1,
    'pebble': 2,
    'pebbles': 2,
    'pebereau': 3,
    'pebley': 2,
    'pecan': 2,
    'pecanins': 3,
    'pecans': 2,
    'pecci': 2,
    'pech': 1,
    'pecha': 2,
    'pechacek': 3,
    'pechin': 2,
    'pechiney': 3,
    'pechman': 2,
    'pechora': 3,
    'pecht': 1,
    'pecina': 3,
    'peck': 1,
    'pecka': 2,
    'peckenpaugh': 3,
    'peckham': 2,
    'pecking': 2,
    'peckinpaugh': 3,
    'peckman': 2,
    'peco': 2,
    'pecor': 2,
    'pecora': 3,
    'pecoraro': 4,
    'pecore': 3,
    'pecot': 2,
    'pectic': 2,
    'pectin': 2,
    'pectoral': 3,
    'pectoris': 3,
    'peculiar': 3,
    'peculiarities': 6,
    'peculiarity': 6,
    'peculiarly': 4,
    'pecuniary': 5,
    'pedagogical': 5,
    'pedagogy': 4,
    'pedal': 2,
    'pedaled': 2,
    'pedaling': 3,
    'pedalled': 2,
    'pedals': 2,
    'pedantic': 3,
    'pedantry': 3,
    'peddicord': 3,
    'peddie': 2,
    'peddle': 2,
    'peddled': 2,
    'peddler': 2,
    'peddlers': 2,
    'peddles': 2,
    'peddling': 3,
    'peddy': 2,
    'peden': 2,
    'pedersen': 3,
    'pederson': 3,
    'pedestal': 3,
    'pedestals': 3,
    'pedestrian': 4,
    'pedestrians': 4,
    'pediatric': 4,
    'pediatrician': 5,
    'pediatricians': 5,
    'pediatrics': 4,
    'pedicure': 3,
    'pedicures': 3,
    'pedigo': 3,
    'pedigree': 3,
    'pedigrees': 3,
    'pedley': 2,
    'pedone': 3,
    'pedophile': 3,
    'pedophiles': 3,
    'pedophilia': 4,
    'pedophiliac': 4,
    'pedophiliacs': 4,
    'pedophilic': 4,
    'pedowitz': 3,
    'pedraza': 3,
    'pedretti': 3,
    'pedrick': 2,
    'pedro': 2,
    'pedroli': 3,
    'pedros': 2,
    'pedrosa': 3,
    'pedroso': 3,
    'pedrotti': 3,
    'pedroza': 3,
    'pee': 1,
    'peebler': 2,
    'peebles': 2,
    'peed': 1,
    'peedin': 2,
    'peek': 1,
    'peeked': 1,
    'peeking': 2,
    'peeks': 1,
    'peel': 1,
    'peele': 1,
    'peeled': 1,
    'peeler': 2,
    'peelers': 2,
    'peeling': 2,
    'peels': 1,
    'peens': 1,
    'peep': 1,
    'peeped': 1,
    'peeping': 2,
    'peeples': 2,
    'peeps': 1,
    'peer': 1,
    'peerage': 2,
    'peered': 1,
    'peering': 2,
    'peerless': 2,
    'peers': 1,
    'peerson': 2,
    'peery': 2,
    'peet': 1,
    'peete': 1,
    'peeters': 2,
    'peets': 1,
    'peetz': 1,
    'peeve': 1,
    'peeved': 1,
    'peeves': 1,
    'peevey': 2,
    'peevish': 2,
    'peevy': 2,
    'peewee': 2,
    'peffer': 2,
    'peffley': 2,
    'peg': 1,
    'pegasus': 3,
    'pegboard': 2,
    'pegboards': 2,
    'pegg': 1,
    'pegged': 1,
    'peggie': 2,
    'pegging': 2,
    'peggs': 1,
    'peggy': 2,
    'peglow': 2,
    'pegmatite': 3,
    'pegram': 2,
    'pegs': 1,
    'peguero': 3,
    'pegues': 2,
    'pehl': 1,
    'pehrson': 2,
    'pei': 1,
    'peifer': 2,
    'peiffer': 2,
    'peil': 1,
    'peinado': 4,
    'peine': 1,
    'peipu': 2,
    'peirce': 1,
    'peirson': 2,
    'peiser': 2,
    'peitz': 1,
    'peixoto': 3,
    'pejorative': 4,
    'pekala': 3,
    'pekar': 2,
    'pekarek': 3,
    'pekin': 2,
    'peking': 2,
    'peko': 2,
    'pekrul': 2,
    'peladeau': 3,
    'pelaez': 3,
    'pelagia': 3,
    'pelagian': 4,
    'pelagians': 4,
    'pelagic': 3,
    'pelagreeny': 4,
    'pelayo': 3,
    'pelc': 1,
    'pelchat': 2,
    'pelczar': 2,
    'pele': 2,
    'pelekunu': 4,
    'peleponnesian': 5,
    'pelerin': 3,
    'pelfrey': 2,
    'pelham': 2,
    'pelican': 3,
    'pelicans': 3,
    'pelikan': 3,
    'pelino': 3,
    'pelissier': 4,
    'pelka': 2,
    'pelkey': 2,
    'pelky': 2,
    'pell': 1,
    'pella': 2,
    'pellagra': 3,
    'pelland': 2,
    'pelle': 1,
    'pellecchia': 4,
    'pellegrin': 3,
    'pellegrini': 4,
    'pellegrino': 4,
    'peller': 2,
    'pellerin': 3,
    'pellerito': 4,
    'pellet': 2,
    'pelleted': 3,
    'pelletier': 3,
    'pellets': 2,
    'pellett': 2,
    'pelleu': 2,
    'pelley': 2,
    'pellicane': 3,
    'pellicano': 4,
    'pellman': 2,
    'pellow': 2,
    'pellston': 2,
    'pellum': 2,
    'pelon': 2,
    'peloponnesian': 5,
    'peloquin': 3,
    'pelosi': 3,
    'peloso': 3,
    'pelot': 2,
    'pelphrey': 2,
    'pels': 1,
    'pelster': 2,
    'pelt': 1,
    'pelted': 2,
    'peltier': 3,
    'pelting': 2,
    'pelto': 2,
    'pelton': 2,
    'pelts': 1,
    'peltz': 1,
    'peltzer': 2,
    'peluso': 3,
    'pelvic': 2,
    'pelvis': 2,
    'pelz': 1,
    'pelzel': 2,
    'pelzer': 2,
    'pember': 2,
    'pemberton': 3,
    'pembina': 3,
    'pemble': 2,
    'pembleton': 3,
    'pembridge': 2,
    'pembroke': 2,
    'pemex': 2,
    'pemrich': 2,
    'pen': 1,
    'pena': 2,
    'penal': 2,
    'penalize': 3,
    'penalized': 3,
    'penalizes': 4,
    'penalizing': 4,
    'penaloza': 4,
    'penalties': 3,
    'penalty': 3,
    'penance': 2,
    'penang': 2,
    'penans': 2,
    'penberthy': 3,
    'pence': 1,
    'penchant': 2,
    'pencil': 2,
    'penciled': 2,
    'pencils': 2,
    'pendant': 2,
    'pendarvis': 3,
    'pendell': 2,
    'pendelton': 3,
    'pender': 2,
    'pendergast': 3,
    'pendergraft': 3,
    'pendergraph': 3,
    'pendergrass': 3,
    'pendergrast': 3,
    'penders': 2,
    'pending': 2,
    'pendleton': 3,
    'pendley': 2,
    'pendola': 3,
    'pendril': 2,
    'pendry': 2,
    'pendulous': 3,
    'pendulum': 3,
    'pendyala': 3,
    'penelas': 3,
    'penelope': 4,
    'penetrate': 3,
    'penetrated': 4,
    'penetrates': 3,
    'penetrating': 4,
    'penetration': 4,
    'penetrator': 4,
    'penetrators': 4,
    'penfield': 2,
    'penfil': 2,
    'penfold': 2,
    'peng': 1,
    'pengassan': 3,
    'pengelly': 3,
    'pengo': 2,
    'penguin': 2,
    'penguins': 2,
    'penh': 1,
    'penicillin': 4,
    'penicillins': 4,
    'penick': 2,
    'penikese': 3,
    'penile': 2,
    'peninger': 3,
    'peninsula': 4,
    'peninsular': 4,
    'penis': 2,
    'penises': 3,
    'peniston': 3,
    'penitent': 3,
    'penitentiaries': 5,
    'penitentiary': 5,
    'penix': 2,
    'penja': 2,
    'penkala': 3,
    'penkava': 3,
    'penland': 2,
    'penley': 2,
    'penman': 2,
    'penn': 1,
    'penna': 2,
    'pennacchio': 4,
    'pennant': 2,
    'pennants': 2,
    'pennbancorp': 3,
    'penncorp': 2,
    'pennebaker': 4,
    'penned': 1,
    'pennel': 2,
    'pennell': 2,
    'pennella': 3,
    'penner': 2,
    'pennex': 2,
    'penney': 2,
    'pennick': 2,
    'pennie': 2,
    'pennies': 2,
    'penniless': 3,
    'penniman': 3,
    'penning': 2,
    'penninger': 3,
    'pennings': 2,
    'pennington': 3,
    'pennino': 3,
    'pennisi': 3,
    'pennix': 2,
    'pennock': 2,
    'penns': 1,
    'pennsauken': 3,
    'pennsylvania': 4,
    'pennsylvanian': 5,
    'pennsylvanians': 5,
    'pennwalt': 2,
    'penny': 2,
    'pennypacker': 4,
    'pennywell': 3,
    'pennzoil': 2,
    'penobscot': 3,
    'penology': 4,
    'penoyer': 3,
    'penril': 2,
    'penrod': 2,
    'penrose': 2,
    'penry': 2,
    'pens': 1,
    'pensacola': 4,
    'pense': 1,
    'pensinger': 3,
    'pension': 2,
    'pensioner': 3,
    'pensioners': 3,
    'pensions': 2,
    'pensive': 2,
    'penske': 2,
    'penson': 2,
    'pensyl': 2,
    'pent': 1,
    'penta': 2,
    'pentagon': 3,
    'pentagons': 3,
    'pentair': 2,
    'pentamidine': 4,
    'pentane': 2,
    'pentathlon': 3,
    'pentax': 2,
    'pentech': 2,
    'pentecost': 3,
    'pentecostal': 4,
    'penthea': 3,
    'penthouse': 2,
    'penthouses': 3,
    'pentimento': 4,
    'pentium': 3,
    'pentiums': 3,
    'pentland': 2,
    'penton': 2,
    'pentothal': 3,
    'pentron': 2,
    'pentrust': 2,
    'pentz': 1,
    'penuel': 2,
    'penultimate': 4,
    'penumbra': 3,
    'penurious': 4,
    'penury': 3,
    'penwell': 2,
    'penwest': 2,
    'penza': 2,
    'penzer': 2,
    'peon': 2,
    'peonies': 3,
    'peons': 2,
    'peony': 3,
    'people': 2,
    'peopled': 2,
    'peoples': 2,
    'peoria': 4,
    'pep': 1,
    'pepcid': 2,
    'pepe': 2,
    'pepenadores': 5,
    'peper': 2,
    'pepi': 2,
    'pepin': 2,
    'pepita': 3,
    'pepitone': 3,
    'peplinski': 3,
    'peplow': 2,
    'peppard': 2,
    'peppel': 2,
    'pepper': 2,
    'pepperdine': 3,
    'peppered': 2,
    'pepperell': 3,
    'pepperidge': 3,
    'peppering': 3,
    'pepperman': 3,
    'peppermint': 3,
    'pepperoni': 4,
    'peppers': 2,
    'peppi': 2,
    'peppin': 2,
    'pepple': 2,
    'peppler': 2,
    'peppy': 2,
    'pepsi': 2,
    'pepsico': 3,
    'peptic': 2,
    'peptide': 2,
    'peptides': 2,
    'pequeno': 3,
    'pequignot': 3,
    'per': 1,
    'pera': 2,
    'peragine': 4,
    'peraino': 4,
    'perala': 3,
    'perales': 3,
    'peralez': 3,
    'peralta': 3,
    'peras': 2,
    'peratis': 3,
    'peraza': 3,
    'perazzo': 3,
    'perc': 1,
    'perce': 1,
    'perceive': 2,
    'perceived': 2,
    'perceives': 2,
    'perceiving': 3,
    'percell': 2,
    'percent': 2,
    'percentage': 3,
    'percentages': 4,
    'percentile': 3,
    'percentiles': 3,
    'percents': 2,
    'percept': 2,
    'perceptible': 4,
    'perception': 3,
    'perceptions': 3,
    'perceptive': 3,
    'perceptual': 4,
    'perceval': 3,
    'perch': 1,
    'perchance': 2,
    'perched': 1,
    'perches': 2,
    'perchlorate': 3,
    'perchloric': 3,
    'percifield': 3,
    'percipient': 4,
    'percival': 3,
    'percle': 2,
    'percolate': 3,
    'percolated': 4,
    'percolating': 4,
    'percolator': 4,
    'percolators': 4,
    'percs': 1,
    'percussion': 3,
    'percussionist': 4,
    'percussive': 3,
    'percutaneous': 5,
    'percy': 2,
    'perdew': 2,
    'perdita': 3,
    'perdomo': 3,
    'perdue': 2,
    'pere': 1,
    'perea': 3,
    'pereda': 3,
    'peregoy': 3,
    'peregrine': 3,
    'peregrines': 3,
    'pereira': 3,
    'perella': 3,
    'perelman': 3,
    'peremptory': 4,
    'perennial': 4,
    'perennially': 5,
    'perennials': 4,
    'perera': 3,
    'peres': 2,
    'perestroika': 4,
    'peretti': 3,
    'peretz': 2,
    'pereyra': 3,
    'perez': 2,
    'perfect': 2,
    'perfecta': 3,
    'perfected': 3,
    'perfecting': 3,
    'perfection': 3,
    'perfectionism': 5,
    'perfectionist': 4,
    'perfectionists': 4,
    'perfectly': 3,
    'perfetti': 3,
    'perfetto': 3,
    'perfidy': 3,
    'perforate': 3,
    'perforated': 4,
    'perforates': 3,
    'perforating': 4,
    'perforation': 4,
    'perforations': 4,
    'perforce': 2,
    'perform': 2,
    'performa': 3,
    'performance': 3,
    'performances': 4,
    'performative': 4,
    'performatives': 4,
    'performed': 2,
    'performer': 3,
    'performers': 3,
    'performing': 3,
    'performs': 2,
    'perfume': 2,
    'perfumed': 2,
    'perfumes': 2,
    'perfuming': 3,
    'perfunctory': 4,
    'pergamon': 3,
    'pergande': 2,
    'pergola': 3,
    'pergolas': 3,
    'perham': 2,
    'perhaps': 2,
    'peria': 3,
    'perich': 2,
    'peridot': 3,
    'perignon': 3,
    'perigo': 3,
    'peril': 2,
    'perillo': 3,
    'perilous': 3,
    'perilously': 4,
    'perils': 2,
    'periman': 3,
    'perimeter': 4,
    'perimeters': 4,
    'perin': 2,
    'perine': 3,
    'perini': 3,
    'perino': 3,
    'period': 3,
    'periodic': 4,
    'periodical': 5,
    'periodically': 6,
    'periodicals': 5,
    'periodontal': 5,
    'periods': 3,
    'peripatetic': 5,
    'peripheral': 4,
    'peripherally': 5,
    'peripherals': 4,
    'periphery': 4,
    'periscope': 3,
    'periscopes': 3,
    'perish': 2,
    'perishable': 4,
    'perishables': 4,
    'perished': 2,
    'perishing': 3,
    'peristyle': 3,
    'perito': 3,
    'perjure': 2,
    'perjured': 2,
    'perjurer': 3,
    'perjures': 2,
    'perjuring': 3,
    'perjury': 3,
    'perk': 1,
    'perked': 1,
    'perkey': 2,
    'perkin': 2,
    'perking': 2,
    'perkins': 2,
    'perkinson': 3,
    'perko': 2,
    'perkovic': 3,
    'perkovich': 3,
    'perkowski': 3,
    'perks': 1,
    'perky': 2,
    'perl': 1,
    'perla': 2,
    'perlberg': 2,
    'perle': 1,
    'perley': 2,
    'perlin': 2,
    'perlis': 2,
    'perlite': 2,
    'perlman': 2,
    'perlmutter': 3,
    'perlow': 2,
    'perls': 1,
    'perlstein': 2,
    'perma': 2,
    'perman': 2,
    'permanence': 3,
    'permanent': 3,
    'permanente': 4,
    'permanently': 4,
    'permeability': 6,
    'permeable': 4,
    'permeate': 3,
    'permeated': 4,
    'permeates': 3,
    'permeating': 4,
    'permenante': 4,
    'permenter': 3,
    'permiam': 3,
    'permian': 3,
    'permissible': 4,
    'permission': 3,
    'permissions': 3,
    'permissive': 3,
    'permissiveness': 4,
    'permit': 2,
    'permits': 2,
    'permitted': 3,
    'permitting': 3,
    'permount': 2,
    'permut': 2,
    'permutate': 3,
    'permutated': 4,
    'permutates': 3,
    'permutating': 4,
    'permutation': 4,
    'permutations': 4,
    'permute': 2,
    'perna': 2,
    'pernell': 2,
    'pernella': 3,
    'pernice': 2,
    'perniciaro': 4,
    'pernicious': 3,
    'perno': 2,
    'pernod': 2,
    'pero': 2,
    'peron': 2,
    'perona': 3,
    'perone': 2,
    'peroni': 3,
    'peronism': 4,
    'peronist': 3,
    'peronists': 3,
    'perot': 2,
    'perots': 2,
    'perotti': 3,
    'peroutka': 3,
    'perovich': 3,
    'peroxide': 3,
    'perpendicular': 5,
    'perpetrate': 3,
    'perpetrated': 4,
    'perpetrates': 3,
    'perpetrating': 4,
    'perpetrator': 4,
    'perpetrators': 4,
    'perpetua': 4,
    'perpetual': 4,
    'perpetually': 5,
    'perpetuals': 4,
    'perpetuate': 4,
    'perpetuated': 5,
    'perpetuates': 4,
    'perpetuating': 5,
    'perpetuation': 5,
    'perpetuity': 5,
    'perpich': 2,
    'perplex': 2,
    'perplexed': 2,
    'perplexes': 3,
    'perplexing': 3,
    'perplexity': 4,
    'perquisite': 3,
    'perquisites': 3,
    'perra': 2,
    'perras': 2,
    'perrault': 2,
    'perreault': 2,
    'perreira': 3,
    'perrell': 2,
    'perrella': 3,
    'perrelli': 3,
    'perren': 2,
    'perret': 2,
    'perrett': 2,
    'perretta': 3,
    'perretti': 3,
    'perri': 2,
    'perricone': 4,
    'perriello': 4,
    'perrier': 3,
    'perrigo': 3,
    'perrin': 2,
    'perrine': 3,
    'perrino': 3,
    'perrins': 2,
    'perritt': 2,
    'perro': 2,
    'perrodin': 3,
    'perron': 2,
    'perrone': 3,
    'perrot': 2,
    'perrott': 2,
    'perrotta': 3,
    'perrotti': 3,
    'perrotto': 3,
    'perrow': 2,
    'perry': 2,
    'perryman': 3,
    'perryn': 2,
    'pers': 1,
    'persall': 2,
    'persaud': 2,
    'perschbacher': 3,
    'perse': 1,
    'persecute': 3,
    'persecuted': 4,
    'persecutes': 3,
    'persecuting': 4,
    'persecution': 4,
    'persecutions': 4,
    'persecutor': 4,
    'persecutors': 4,
    'persepolis': 4,
    'perses': 2,
    'perset': 2,
    'perseus': 3,
    'perseverance': 4,
    'persevere': 3,
    'persevered': 3,
    'perseveres': 3,
    'persevering': 4,
    'pershall': 2,
    'pershare': 2,
    'pershing': 2,
    'pershings': 2,
    'persia': 2,
    'persian': 2,
    'persians': 2,
    'persico': 3,
    'persing': 2,
    'persinger': 3,
    'persis': 2,
    'persist': 2,
    'persisted': 3,
    'persistence': 3,
    'persistent': 3,
    'persistently': 4,
    'persisting': 3,
    'persists': 2,
    'persky': 2,
    'persley': 2,
    'person': 2,
    'persona': 3,
    'personable': 4,
    'personably': 4,
    'personae': 3,
    'personal': 3,
    'personalink': 4,
    'personalities': 5,
    'personality': 5,
    'personalization': 6,
    'personalize': 4,
    'personalized': 4,
    'personalizes': 5,
    'personalizing': 5,
    'personally': 4,
    'personals': 3,
    'personic': 3,
    'personics': 3,
    'personification': 6,
    'personified': 4,
    'personifies': 4,
    'personify': 4,
    'personifying': 5,
    'personnel': 3,
    'persons': 2,
    'perspective': 3,
    'perspectives': 3,
    'perspicacious': 4,
    'perspiration': 4,
    'perspire': 2,
    'perspiring': 3,
    'persson': 2,
    'persuadable': 4,
    'persuade': 2,
    'persuaded': 3,
    'persuades': 2,
    'persuading': 3,
    'persuasion': 3,
    'persuasions': 3,
    'persuasive': 3,
    'persuasively': 4,
    'persuasiveness': 4,
    'persyn': 2,
    'pert': 1,
    'pertain': 2,
    'pertained': 2,
    'pertaining': 3,
    'pertains': 2,
    'pertamina': 4,
    'perth': 1,
    'pertinent': 3,
    'pertinently': 4,
    'pertschuk': 2,
    'pertuit': 2,
    'perturb': 2,
    'perturbation': 4,
    'perturbations': 4,
    'perturbed': 2,
    'perturbing': 3,
    'perturbs': 2,
    'pertussis': 3,
    'peru': 2,
    'perugia': 4,
    'perugina': 4,
    'perugini': 4,
    'perulac': 3,
    'perusal': 3,
    'peruse': 2,
    'perused': 2,
    'peruses': 3,
    'perusing': 3,
    'perusse': 3,
    'peruvian': 4,
    'peruvians': 4,
    'peruzzi': 3,
    'pervade': 2,
    'pervaded': 3,
    'pervades': 2,
    'pervading': 3,
    'pervasive': 3,
    'pervasiveness': 4,
    'perverse': 2,
    'perversely': 3,
    'perversion': 3,
    'perversity': 4,
    'pervert': 2,
    'perverted': 3,
    'perverting': 3,
    'perverts': 2,
    'pervomaiskaya': 5,
    'perz': 1,
    'perzigian': 4,
    'pesatori': 4,
    'pesavento': 4,
    'pescatore': 4,
    'pesce': 1,
    'pesch': 1,
    'peschel': 2,
    'peschke': 1,
    'pesci': 2,
    'pesek': 2,
    'peseta': 3,
    'pesetas': 3,
    'pesh': 1,
    'peshawar': 3,
    'peshek': 2,
    'pesina': 3,
    'peskin': 2,
    'pesky': 2,
    'pesnell': 2,
    'peso': 2,
    'pesola': 3,
    'pesos': 2,
    'pessimism': 4,
    'pessimist': 3,
    'pessimistic': 4,
    'pessimists': 3,
    'pessin': 2,
    'pest': 1,
    'pesta': 2,
    'pestana': 3,
    'pester': 2,
    'pestered': 2,
    'pestering': 3,
    'pesters': 2,
    'pesticide': 3,
    'pesticides': 3,
    'pestilence': 3,
    'pestillo': 3,
    'pestka': 2,
    'pests': 1,
    'pet': 1,
    'peta': 2,
    'petain': 2,
    'petak': 2,
    'petal': 2,
    'petals': 2,
    'petaluma': 4,
    'petard': 2,
    'pete': 1,
    'peter': 2,
    'peterbilt': 3,
    'peterborough': 4,
    'petered': 2,
    'petering': 3,
    'peterka': 3,
    'peterkin': 3,
    'peterman': 3,
    'petermann': 3,
    'peterpaul': 3,
    'peters': 2,
    'petersburg': 3,
    'petersen': 3,
    'peterson': 3,
    'petery': 3,
    'petey': 2,
    'peth': 1,
    'pethel': 2,
    'pethtel': 2,
    'petie': 2,
    'petillo': 3,
    'petino': 3,
    'petipa': 3,
    'petit': 2,
    'petite': 2,
    'petites': 2,
    'petition': 3,
    'petitioned': 3,
    'petitioner': 4,
    'petitioners': 4,
    'petitioning': 4,
    'petitions': 3,
    'petitjean': 3,
    'petito': 3,
    'petitt': 2,
    'petitti': 3,
    'petitto': 3,
    'petko': 2,
    'petkovsek': 3,
    'petkus': 2,
    'peto': 2,
    'petr': 2,
    'petra': 2,
    'petracca': 3,
    'petraglia': 4,
    'petraitis': 3,
    'petrak': 2,
    'petrakis': 3,
    'petralia': 4,
    'petrarca': 3,
    'petras': 2,
    'petrasek': 3,
    'petrash': 2,
    'petrauskas': 3,
    'petre': 2,
    'petrea': 3,
    'petree': 2,
    'petrella': 3,
    'petrelli': 3,
    'petrels': 2,
    'petrey': 2,
    'petri': 2,
    'petrich': 2,
    'petricioli': 5,
    'petrick': 2,
    'petrides': 2,
    'petrie': 2,
    'petrified': 3,
    'petrify': 3,
    'petrik': 2,
    'petrilla': 3,
    'petrilli': 3,
    'petrillo': 3,
    'petrin': 2,
    'petrina': 3,
    'petrini': 3,
    'petrino': 3,
    'petrizzo': 3,
    'petro': 2,
    'petrobras': 3,
    'petrocelli': 4,
    'petrochemical': 5,
    'petrochemicals': 5,
    'petrocorp': 3,
    'petrodollar': 4,
    'petrodollars': 4,
    'petroff': 2,
    'petrofina': 4,
    'petroglyph': 3,
    'petroglyphs': 3,
    'petrol': 2,
    'petrolane': 3,
    'petrolear': 3,
    'petroleos': 4,
    'petroles': 2,
    'petroleum': 4,
    'petrolia': 4,
    'petrology': 4,
    'petromin': 3,
    'petromineral': 5,
    'petrominerals': 5,
    'petronas': 3,
    'petrone': 3,
    'petronella': 4,
    'petroni': 3,
    'petronia': 4,
    'petronilla': 4,
    'petronio': 4,
    'petropoulos': 4,
    'petros': 2,
    'petrosa': 3,
    'petrosino': 4,
    'petroski': 3,
    'petrosky': 3,
    'petrossian': 3,
    'petrosynthese': 4,
    'petrovic': 3,
    'petrovich': 3,
    'petrovietnam': 5,
    'petrow': 2,
    'petrowski': 3,
    'petrowsky': 3,
    'petru': 2,
    'petruccelli': 4,
    'petrucci': 3,
    'petrucelli': 4,
    'petrus': 2,
    'petruska': 3,
    'petruzzelli': 4,
    'petruzzi': 3,
    'petry': 2,
    'pets': 1,
    'petsch': 1,
    'petsche': 1,
    'petsmart': 2,
    'petstuff': 2,
    'pett': 1,
    'petta': 2,
    'pettaway': 3,
    'petted': 2,
    'pettee': 2,
    'pettengill': 3,
    'petter': 2,
    'pettersen': 3,
    'petterson': 3,
    'pettersson': 3,
    'pettet': 2,
    'petteway': 2,
    'pettey': 2,
    'petteys': 2,
    'petti': 2,
    'petticoat': 3,
    'petticoats': 3,
    'pettie': 2,
    'petties': 2,
    'pettigrew': 3,
    'pettijohn': 3,
    'pettinato': 4,
    'pettine': 3,
    'pettiness': 3,
    'petting': 2,
    'pettinger': 3,
    'pettingill': 3,
    'pettis': 2,
    'pettit': 2,
    'pettitt': 2,
    'pettry': 2,
    'pettus': 2,
    'pettway': 2,
    'petty': 2,
    'petulance': 3,
    'petulant': 3,
    'petunia': 4,
    'petunias': 4,
    'petway': 2,
    'petz': 1,
    'petzel': 2,
    'petzinger': 3,
    'petzold': 2,
    'petzoldt': 2,
    'peugeot': 2,
    'peugh': 1,
    'pevehouse': 2,
    'peveler': 3,
    'peverell': 3,
    'peveto': 3,
    'pevey': 2,
    'pew': 1,
    'pewabic': 3,
    'pewex': 2,
    'pewitt': 2,
    'pews': 1,
    'pewter': 2,
    'peyman': 2,
    'peyot': 2,
    'peyrelevade': 4,
    'peyser': 2,
    'peyton': 2,
    'pez': 1,
    'pezeshkan': 3,
    'pezim': 2,
    'pezza': 2,
    'pezzella': 3,
    'pezzullo': 3,
    'pfaff': 1,
    'pfahl': 1,
    'pfahler': 2,
    'pfalzgraf': 2,
    'pfannenstiel': 3,
    'pfarr': 1,
    'pfau': 1,
    'pfeffer': 2,
    'pfefferkorn': 3,
    'pfefferle': 3,
    'pfeifer': 2,
    'pfeiff': 1,
    'pfeiffer': 2,
    'pfeifle': 2,
    'pfeil': 1,
    'pfennig': 2,
    'pfennigs': 2,
    'pfenning': 2,
    'pfenninger': 3,
    'pfeuffer': 2,
    'pfiester': 2,
    'pfiesteria': 4,
    'pfiffner': 2,
    'pfingsten': 2,
    'pfister': 2,
    'pfisterer': 3,
    'pfizer': 2,
    'pflaum': 1,
    'pfleger': 2,
    'pfleiderer': 3,
    'pflieger': 2,
    'pflueger': 2,
    'pflug': 1,
    'pfluger': 2,
    'pflum': 1,
    'pfohl': 1,
    'pforzheimer': 3,
    'pfost': 1,
    'pfund': 1,
    'pfundstein': 2,
    'pgm': 3,
    'ph': 2,
    'phagan': 2,
    'phagocyte': 3,
    'phagocytes': 3,
    'phair': 1,
    'phalange': 2,
    'phalanges': 3,
    'phalanx': 2,
    'phalen': 2,
    'phallic': 2,
    'pham': 1,
    'phan': 1,
    'phaneuf': 2,
    'phantasm': 3,
    'phantasmagoria': 6,
    'phantom': 2,
    'phantoms': 2,
    'phar': 1,
    'pharaoh': 2,
    'pharaohs': 2,
    'pharaon': 2,
    'pharaonic': 4,
    'phares': 1,
    'pharis': 2,
    'pharisaism': 5,
    'phariss': 2,
    'pharma': 2,
    'pharmaceutical': 5,
    'pharmaceuticals': 5,
    'pharmacia': 4,
    'pharmacies': 3,
    'pharmacist': 3,
    'pharmacists': 3,
    'pharmacological': 6,
    'pharmacologist': 5,
    'pharmacologists': 5,
    'pharmacology': 5,
    'pharmacy': 3,
    'pharmakinetic': 5,
    'pharmakinetics': 5,
    'pharo': 2,
    'pharoah': 2,
    'pharoahs': 2,
    'pharos': 2,
    'pharr': 1,
    'pharris': 2,
    'pharynx': 2,
    'phase': 1,
    'phased': 1,
    'phaseout': 2,
    'phases': 2,
    'phasing': 2,
    'pheasant': 2,
    'pheasants': 2,
    'phebe': 2,
    'phebus': 2,
    'phedra': 2,
    'phegley': 2,
    'phelan': 2,
    'phelps': 1,
    'phen': 1,
    'phenfen': 2,
    'phenicie': 3,
    'phenix': 2,
    'phenol': 2,
    'phenolic': 3,
    'phenolphthalein': 4,
    'phenom': 2,
    'phenomena': 4,
    'phenomenal': 4,
    'phenomenally': 5,
    'phenomenon': 4,
    'phenotype': 3,
    'phenotypes': 3,
    'phenotypic': 4,
    'phenylthaline': 4,
    'pherigo': 3,
    'pheromone': 3,
    'pheromones': 3,
    'pherson': 2,
    'phetteplace': 2,
    'phew': 1,
    'phi': 1,
    'phibbs': 1,
    'phibro': 2,
    'phifer': 2,
    'phil': 1,
    'philadelphia': 5,
    'philadelphian': 5,
    'philadelphians': 5,
    'philana': 3,
    'philandering': 4,
    'philantha': 3,
    'philanthropic': 4,
    'philanthropies': 4,
    'philanthropist': 4,
    'philanthropists': 4,
    'philanthropy': 4,
    'philatelic': 4,
    'philatelist': 4,
    'philately': 4,
    'philbeck': 2,
    'philbert': 2,
    'philberta': 3,
    'philbin': 2,
    'philbrick': 2,
    'philbrook': 2,
    'philby': 2,
    'phileas': 3,
    'philemon': 3,
    'philene': 2,
    'phileo': 3,
    'philharmonia': 5,
    'philharmonic': 4,
    'philhower': 3,
    'philibert': 3,
    'philibosian': 5,
    'philida': 3,
    'philina': 3,
    'philip': 2,
    'philipp': 2,
    'philippa': 3,
    'philippe': 3,
    'philippi': 3,
    'philippic': 3,
    'philippine': 3,
    'philippines': 3,
    'philippino': 4,
    'philippoussis': 4,
    'philipps': 2,
    'philips': 2,
    'philipson': 3,
    'philistia': 4,
    'philistine': 3,
    'philistines': 3,
    'phillabaum': 3,
    'philley': 2,
    'phillie': 2,
    'phillies': 2,
    'phillip': 2,
    'phillipe': 2,
    'phillipine': 3,
    'phillipines': 3,
    'phillipp': 2,
    'phillippe': 2,
    'phillippi': 3,
    'phillipps': 2,
    'phillips': 2,
    'phillipson': 3,
    'phillis': 2,
    'philly': 2,
    'philo': 2,
    'philodendron': 4,
    'philomela': 4,
    'philomena': 4,
    'philon': 2,
    'philosopher': 4,
    'philosophers': 4,
    'philosophic': 4,
    'philosophical': 5,
    'philosophically': 6,
    'philosophies': 4,
    'philosophy': 4,
    'philp': 1,
    'philpot': 2,
    'philpott': 2,
    'philson': 2,
    'philyaw': 2,
    'phineas': 3,
    'phinney': 2,
    'phippen': 2,
    'phipps': 1,
    'phlcorp': 4,
    'phlebitis': 3,
    'phlegmatic': 3,
    'phlogopite': 3,
    'phnom': 1,
    'phobia': 3,
    'phobias': 3,
    'phobic': 2,
    'phobos': 2,
    'phoebe': 2,
    'phoebus': 2,
    'phoenicia': 3,
    'phoenician': 3,
    'phoenicians': 3,
    'phoenix': 2,
    'phone': 1,
    'phoned': 1,
    'phonemate': 2,
    'phoneme': 2,
    'phonemes': 2,
    'phonemic': 3,
    'phones': 1,
    'phonetic': 3,
    'phonetically': 4,
    'phonetics': 3,
    'phoney': 2,
    'phonic': 2,
    'phonics': 2,
    'phoning': 2,
    'phonograph': 3,
    'phonographs': 3,
    'phonological': 5,
    'phonology': 4,
    'phony': 2,
    'phooey': 2,
    'phosphate': 2,
    'phosphates': 2,
    'phosphatic': 3,
    'phosphor': 2,
    'phosphorescence': 4,
    'phosphoric': 3,
    'phosphorite': 3,
    'phosphorites': 3,
    'phosphorous': 3,
    'phosphors': 2,
    'phosphorus': 3,
    'photo': 2,
    'photocall': 3,
    'photochemical': 5,
    'photoconductive': 5,
    'photocopied': 4,
    'photocopier': 5,
    'photocopiers': 5,
    'photocopies': 4,
    'photocopy': 4,
    'photocopying': 5,
    'photodynamic': 5,
    'photoelectric': 5,
    'photofinisher': 5,
    'photofinishers': 5,
    'photofinishing': 5,
    'photogenic': 4,
    'photograph': 3,
    'photographed': 3,
    'photographer': 4,
    'photographers': 4,
    'photographic': 4,
    'photographing': 4,
    'photographs': 3,
    'photography': 4,
    'photojournalist': 5,
    'photometer': 4,
    'photon': 2,
    'photons': 2,
    'photoop': 3,
    'photophoresis': 5,
    'photorefractive': 5,
    'photos': 2,
    'photosynthesis': 5,
    'phototaxis': 4,
    'phototron': 3,
    'photovoltaic': 5,
    'photovoltaics': 5,
    'photronic': 3,
    'photronics': 3,
    'phrase': 1,
    'phrased': 1,
    'phraseology': 5,
    'phrases': 2,
    'phrasing': 2,
    'phrygian': 3,
    'phu': 1,
    'phua': 2,
    'phung': 1,
    'phuong': 2,
    'phut': 1,
    'phy': 1,
    'phyla': 2,
    'phyle': 1,
    'phylis': 2,
    'phyllis': 2,
    'phyllys': 2,
    'phylogeny': 4,
    'phylum': 2,
    'physical': 3,
    'physically': 4,
    'physicals': 3,
    'physician': 3,
    'physicians': 3,
    'physicist': 3,
    'physicists': 3,
    'physics': 2,
    'physio': 3,
    'physiological': 6,
    'physiologically': 6,
    'physiologist': 5,
    'physiology': 5,
    'physique': 2,
    'phytogeography': 6,
    'phytoplankton': 4,
    'pi': 1,
    'pia': 2,
    'piacente': 4,
    'piacentini': 5,
    'piaget': 3,
    'piana': 3,
    'pianist': 3,
    'pianists': 3,
    'pianka': 3,
    'piano': 3,
    'pianos': 3,
    'piascik': 3,
    'piasecki': 4,
    'piasio': 4,
    'piatek': 3,
    'piatkowski': 4,
    'piatt': 2,
    'piazza': 3,
    'piazzolla': 4,
    'pic': 1,
    'pica': 2,
    'picabo': 3,
    'picante': 3,
    'picard': 2,
    'picariello': 5,
    'picaro': 3,
    'picas': 2,
    'picasso': 3,
    'picassos': 3,
    'picayune': 3,
    'picazo': 3,
    'piccadilly': 4,
    'picchi': 2,
    'picchu': 2,
    'picciano': 4,
    'piccinini': 4,
    'piccione': 3,
    'piccirilli': 4,
    'piccirillo': 4,
    'picco': 2,
    'piccola': 3,
    'piccoli': 3,
    'piccolo': 3,
    'piccone': 3,
    'picha': 2,
    'pichardo': 3,
    'piche': 1,
    'picheny': 3,
    'picher': 2,
    'pichette': 2,
    'pichler': 3,
    'pichon': 2,
    'picht': 1,
    'picinich': 3,
    'pick': 1,
    'pickands': 2,
    'pickar': 2,
    'pickard': 2,
    'pickart': 2,
    'picked': 1,
    'pickel': 2,
    'pickell': 2,
    'pickelsimer': 4,
    'picken': 2,
    'pickens': 2,
    'picker': 2,
    'pickeral': 3,
    'pickerel': 3,
    'pickerell': 3,
    'pickerill': 3,
    'pickering': 3,
    'pickers': 2,
    'pickert': 2,
    'picket': 2,
    'picketed': 3,
    'picketer': 3,
    'picketers': 3,
    'picketing': 3,
    'pickets': 2,
    'pickett': 2,
    'pickford': 2,
    'pickier': 3,
    'picking': 2,
    'pickings': 2,
    'pickins': 2,
    'pickle': 2,
    'pickled': 2,
    'pickler': 3,
    'pickles': 2,
    'picklesimer': 4,
    'pickling': 2,
    'pickney': 2,
    'pickpocket': 3,
    'pickpockets': 3,
    'pickrel': 2,
    'pickrell': 2,
    'pickren': 3,
    'pickron': 2,
    'picks': 1,
    'pickup': 2,
    'pickups': 2,
    'pickus': 2,
    'pickwick': 2,
    'pickworth': 2,
    'picky': 2,
    'picnic': 2,
    'picnics': 2,
    'pico': 2,
    'picon': 2,
    'picone': 2,
    'picop': 2,
    'picotte': 2,
    'picou': 2,
    'picoult': 2,
    'picower': 2,
    'pictet': 2,
    'picton': 2,
    'pictorial': 4,
    'picts': 1,
    'picture': 2,
    'pictured': 2,
    'pictures': 2,
    'picturesque': 3,
    'picturetel': 3,
    'picturing': 3,
    'pidcock': 2,
    'piddle': 2,
    'piddles': 2,
    'piddling': 3,
    'piddock': 2,
    'pidgeon': 2,
    'pie': 1,
    'piebald': 2,
    'piece': 1,
    'pieced': 1,
    'piecemeal': 2,
    'pieces': 2,
    'piecework': 2,
    'piech': 1,
    'piechocki': 3,
    'piechota': 3,
    'piechowski': 3,
    'piecing': 2,
    'piecuch': 2,
    'pieczynski': 3,
    'pied': 1,
    'piedboeuf': 2,
    'piedmont': 2,
    'piedra': 2,
    'piehl': 1,
    'piekarski': 3,
    'piel': 1,
    'piela': 2,
    'pienta': 2,
    'pieper': 2,
    'piepgras': 2,
    'piepho': 2,
    'pier': 1,
    'pieraccini': 4,
    'pieratt': 2,
    'pierce': 1,
    'pierceall': 2,
    'pierced': 1,
    'piercey': 2,
    'piercing': 2,
    'piercy': 2,
    'pierette': 2,
    'pieri': 2,
    'pierie': 3,
    'pierini': 3,
    'pierman': 2,
    'piero': 2,
    'pieroni': 3,
    'pierotti': 3,
    'pierpoint': 2,
    'pierpont': 2,
    'pierre': 2,
    'pierrelouis': 3,
    'pierrepont': 3,
    'pierro': 2,
    'pierron': 2,
    'piers': 1,
    'piersall': 2,
    'piersol': 2,
    'pierson': 2,
    'pies': 1,
    'piet': 2,
    'pieter': 2,
    'pietermaritzburg': 5,
    'pieters': 2,
    'pieties': 3,
    'pietila': 3,
    'pietism': 3,
    'pietrangelo': 4,
    'pietras': 2,
    'pietro': 3,
    'pietrowski': 3,
    'pietruski': 4,
    'pietruszka': 3,
    'pietrzak': 3,
    'pietrzyk': 3,
    'pietsch': 1,
    'piette': 1,
    'piety': 3,
    'pietz': 1,
    'piezoelectric': 6,
    'pifer': 2,
    'pig': 1,
    'pigan': 2,
    'pigeon': 2,
    'pigeonhole': 3,
    'pigeonholed': 3,
    'pigeons': 2,
    'pigford': 2,
    'pigg': 1,
    'piggee': 2,
    'piggly': 2,
    'piggott': 2,
    'piggy': 2,
    'piggyback': 3,
    'piggybacked': 3,
    'piggybacking': 4,
    'piglet': 2,
    'piglets': 2,
    'pigman': 2,
    'pigment': 2,
    'pigmentation': 4,
    'pigments': 2,
    'pigmied': 2,
    'pigmy': 2,
    'pignataro': 4,
    'pignatelli': 4,
    'pignato': 3,
    'pignone': 3,
    'pigott': 2,
    'pigs': 1,
    'pigskin': 2,
    'pigskins': 2,
    'pigue': 1,
    'pih': 1,
    'pihl': 1,
    'pik': 1,
    'pike': 1,
    'piker': 2,
    'pikers': 2,
    'pikes': 1,
    'pikul': 2,
    'pikula': 3,
    'pikus': 2,
    'pil': 1,
    'piland': 2,
    'pilant': 2,
    'pilar': 2,
    'pilarski': 3,
    'pilaster': 3,
    'pilasters': 3,
    'pilat': 2,
    'pilato': 3,
    'pilatus': 3,
    'pilch': 1,
    'pilcher': 2,
    'pile': 1,
    'pilecki': 3,
    'piled': 1,
    'pileggi': 3,
    'piles': 1,
    'pileup': 2,
    'pilevsky': 3,
    'pilfer': 2,
    'pilferage': 3,
    'pilfered': 2,
    'pilfering': 3,
    'pilfers': 2,
    'pilger': 2,
    'pilgram': 2,
    'pilgreen': 2,
    'pilgrim': 2,
    'pilgrimage': 3,
    'pilgrimages': 4,
    'pilgrims': 2,
    'piling': 2,
    'pilings': 2,
    'pilkenton': 3,
    'pilkerton': 3,
    'pilkington': 3,
    'pilkinton': 3,
    'pill': 1,
    'pilla': 2,
    'pillage': 2,
    'pillaged': 2,
    'pillager': 3,
    'pillagers': 3,
    'pillages': 3,
    'pillaging': 3,
    'pillar': 2,
    'pillard': 2,
    'pillared': 2,
    'pillars': 2,
    'pille': 1,
    'piller': 2,
    'pilley': 2,
    'pilling': 2,
    'pillion': 2,
    'pilloried': 3,
    'pillory': 3,
    'pillow': 2,
    'pillows': 2,
    'pillowtex': 3,
    'pills': 1,
    'pillsbury': 3,
    'pilnak': 2,
    'pilon': 2,
    'pilot': 2,
    'piloted': 3,
    'piloting': 3,
    'pilotless': 3,
    'pilots': 2,
    'pilotte': 2,
    'pilsner': 2,
    'pilson': 2,
    'piltdown': 2,
    'piltz': 1,
    'pilz': 1,
    'pima': 2,
    'pimco': 2,
    'pimental': 3,
    'pimentel': 3,
    'pimm': 1,
    'pimp': 1,
    'pimping': 2,
    'pimplapure': 3,
    'pimple': 2,
    'pimples': 2,
    'pimps': 1,
    'pin': 1,
    'pina': 2,
    'pinard': 2,
    'pinatubo': 4,
    'pinault': 2,
    'pinball': 2,
    'pincavage': 3,
    'pincerlike': 3,
    'pincers': 2,
    'pinch': 1,
    'pinched': 1,
    'pincher': 2,
    'pinchers': 2,
    'pinching': 2,
    'pinckard': 2,
    'pinckney': 2,
    'pincus': 2,
    'pindaric': 3,
    'pindell': 2,
    'pinder': 2,
    'pindling': 2,
    'pine': 1,
    'pineal': 3,
    'pineapple': 3,
    'pineapples': 3,
    'pineau': 2,
    'pined': 1,
    'pineda': 3,
    'pinedo': 3,
    'pinegar': 3,
    'pineiro': 3,
    'pinellas': 3,
    'pinelli': 3,
    'pineo': 3,
    'piner': 2,
    'pinero': 3,
    'pines': 1,
    'pinetta': 3,
    'pinette': 2,
    'ping': 1,
    'pinged': 1,
    'pingel': 2,
    'pinging': 2,
    'pingitore': 4,
    'pingleton': 3,
    'pingley': 2,
    'pingpong': 2,
    'pingree': 2,
    'pings': 1,
    'pinheiro': 3,
    'pinho': 2,
    'pinhole': 2,
    'pinholes': 2,
    'pini': 2,
    'pining': 2,
    'pinion': 2,
    'pink': 1,
    'pinkard': 2,
    'pinkelman': 3,
    'pinker': 2,
    'pinkerman': 3,
    'pinkerton': 3,
    'pinkest': 2,
    'pinkett': 2,
    'pinkham': 2,
    'pinkie': 2,
    'pinkish': 2,
    'pinkley': 2,
    'pinkney': 2,
    'pinkos': 2,
    'pinkowski': 3,
    'pinks': 1,
    'pinkstaff': 2,
    'pinkston': 2,
    'pinkus': 2,
    'pinkwater': 3,
    'pinky': 2,
    'pinn': 1,
    'pinnacle': 3,
    'pinned': 1,
    'pinnell': 2,
    'pinneo': 3,
    'pinner': 2,
    'pinney': 2,
    'pinnick': 2,
    'pinning': 2,
    'pinnix': 2,
    'pinnock': 2,
    'pinnow': 2,
    'pino': 2,
    'pinocchio': 4,
    'pinochet': 3,
    'pinola': 3,
    'pinot': 2,
    'pinpoint': 2,
    'pinpointed': 3,
    'pinpointing': 3,
    'pinpoints': 2,
    'pinprick': 2,
    'pinquater': 3,
    'pins': 1,
    'pinshan': 2,
    'pinsker': 2,
    'pinsky': 2,
    'pinson': 2,
    'pinsoneault': 3,
    'pinstripe': 2,
    'pinstriped': 2,
    'pinstripes': 2,
    'pint': 1,
    'pinta': 2,
    'pintar': 2,
    'pinter': 2,
    'pinto': 2,
    'pints': 1,
    'piny': 2,
    'pinyan': 2,
    'pinzon': 2,
    'pio': 2,
    'pion': 2,
    'pioneer': 3,
    'pioneered': 3,
    'pioneering': 4,
    'pioneers': 3,
    'piontek': 3,
    'piontkowski': 4,
    'piorkowski': 4,
    'piotrowski': 4,
    'piotter': 3,
    'pious': 2,
    'piously': 3,
    'pip': 1,
    'pipe': 1,
    'piped': 1,
    'pipefish': 2,
    'pipefishes': 3,
    'pipeline': 2,
    'pipelines': 2,
    'piper': 2,
    'pipers': 2,
    'pipes': 1,
    'pipetec': 2,
    'pipette': 2,
    'pipetter': 3,
    'pipetters': 3,
    'pipettes': 2,
    'pipher': 2,
    'piping': 2,
    'pipitone': 3,
    'pipkin': 2,
    'pipkins': 2,
    'pipp': 1,
    'pippen': 2,
    'pippenger': 3,
    'pippert': 2,
    'pippin': 2,
    'pippins': 2,
    'pips': 1,
    'piquant': 2,
    'pique': 1,
    'piqued': 1,
    'piquette': 2,
    'piracy': 3,
    'piraeus': 3,
    'piraino': 3,
    'pirandello': 4,
    'piranha': 3,
    'pirate': 2,
    'pirated': 3,
    'pirates': 2,
    'pirating': 3,
    'pirelli': 3,
    'pires': 1,
    'pirestani': 4,
    'pirie': 2,
    'pirkey': 2,
    'pirkl': 2,
    'pirkle': 2,
    'pirko': 2,
    'piro': 2,
    'pirog': 2,
    'pirone': 2,
    'pirouette': 3,
    'pirouettes': 3,
    'pirozzi': 3,
    'pirrello': 3,
    'pirro': 2,
    'pirrone': 3,
    'pirtle': 2,
    'pisa': 2,
    'pisani': 3,
    'pisano': 3,
    'pisarek': 3,
    'pisarski': 3,
    'pisca': 2,
    'piscataway': 4,
    'pisces': 2,
    'pischel': 2,
    'pischke': 1,
    'pisciotta': 3,
    'piscitelli': 4,
    'piscitello': 4,
    'piscopo': 3,
    'pisello': 3,
    'pisoni': 3,
    'piss': 1,
    'pissed': 1,
    'pistachio': 4,
    'pistachios': 4,
    'pistil': 2,
    'pistilli': 3,
    'pistol': 2,
    'pistole': 2,
    'pistols': 2,
    'piston': 2,
    'pistone': 2,
    'pistons': 2,
    'pistor': 2,
    'pistorio': 4,
    'pit': 1,
    'pita': 2,
    'pitblado': 3,
    'pitch': 1,
    'pitchblende': 2,
    'pitched': 1,
    'pitcher': 2,
    'pitchers': 2,
    'pitches': 2,
    'pitchford': 2,
    'pitchfork': 2,
    'pitchforks': 2,
    'pitching': 2,
    'pitchman': 2,
    'pitchmen': 2,
    'pitcock': 2,
    'pitfall': 2,
    'pitfalls': 2,
    'pith': 1,
    'pithy': 2,
    'pitiable': 4,
    'pitied': 2,
    'pitiesalpetriere': 6,
    'pitiful': 3,
    'pitifully': 4,
    'pitiless': 3,
    'pitino': 3,
    'pitkin': 2,
    'pitman': 2,
    'pitner': 2,
    'pitney': 2,
    'pitofsky': 3,
    'pitre': 2,
    'pits': 1,
    'pitsch': 1,
    'pitsenbarger': 4,
    'pitstick': 2,
    'pitswon': 2,
    'pitt': 1,
    'pittance': 2,
    'pittard': 2,
    'pitted': 2,
    'pittencrieff': 3,
    'pittenger': 3,
    'pitting': 2,
    'pittinger': 3,
    'pittle': 2,
    'pittman': 2,
    'pittner': 2,
    'pitts': 1,
    'pittsboro': 3,
    'pittsburg': 2,
    'pittsburgh': 2,
    'pittsburgher': 3,
    'pittsburghers': 3,
    'pittsfield': 2,
    'pittsford': 2,
    'pittsley': 2,
    'pittston': 2,
    'pituitary': 5,
    'pity': 2,
    'pitying': 3,
    'pitz': 1,
    'pitzen': 2,
    'pitzer': 2,
    'pius': 2,
    'piver': 2,
    'pivonka': 3,
    'pivot': 2,
    'pivotal': 3,
    'pivoted': 3,
    'pivoting': 3,
    'pivots': 2,
    'pix': 1,
    'pixar': 2,
    'pixel': 2,
    'pixels': 2,
    'pixie': 2,
    'pixler': 2,
    'pixley': 2,
    'pizana': 3,
    'pizano': 3,
    'pizarro': 3,
    'pizatti': 3,
    'pizazz': 2,
    'pizer': 2,
    'pizza': 2,
    'pizzano': 3,
    'pizzas': 2,
    'pizzazier': 3,
    'pizzazz': 2,
    'pizzeria': 4,
    'pizzerias': 4,
    'pizzi': 2,
    'pizzimenti': 4,
    'pizzino': 3,
    'pizzitola': 4,
    'pizzo': 2,
    'pizzolato': 4,
    'pizzuti': 3,
    'pizzuto': 3,
    'pla': 1,
    'placard': 2,
    'placards': 2,
    'placate': 2,
    'placating': 3,
    'place': 1,
    'placebo': 3,
    'placebos': 3,
    'placed': 1,
    'placek': 2,
    'placemat': 2,
    'placement': 2,
    'placements': 2,
    'placencia': 3,
    'placenta': 3,
    'placentia': 3,
    'placer': 2,
    'places': 2,
    'placeta': 3,
    'placetas': 3,
    'placeway': 2,
    'placid': 2,
    'placida': 3,
    'placidly': 3,
    'placido': 3,
    'placing': 2,
    'plack': 1,
    'placke': 1,
    'placko': 2,
    'placoid': 2,
    'placzek': 2,
    'plagens': 2,
    'plagge': 1,
    'plagiarism': 4,
    'plagiarize': 3,
    'plagiarized': 3,
    'plagioclase': 4,
    'plague': 1,
    'plagued': 1,
    'plagues': 1,
    'plaguing': 2,
    'plaia': 2,
    'plaid': 1,
    'plain': 1,
    'plainclothes': 2,
    'plainer': 2,
    'plaines': 1,
    'plainfield': 2,
    'plainly': 2,
    'plaino': 2,
    'plains': 1,
    'plainsong': 2,
    'plaintiff': 2,
    'plaintiffs': 2,
    'plaintive': 2,
    'plaintively': 3,
    'plainview': 2,
    'plair': 1,
    'plaisance': 2,
    'plaisted': 3,
    'plait': 1,
    'plaited': 2,
    'plaiting': 2,
    'plaits': 1,
    'plake': 1,
    'plam': 1,
    'plamann': 2,
    'plambeck': 2,
    'plamondon': 3,
    'plan': 1,
    'planar': 2,
    'planarian': 4,
    'planas': 2,
    'planck': 1,
    'plane': 1,
    'planecon': 3,
    'planed': 1,
    'planeload': 2,
    'planeloads': 2,
    'planer': 2,
    'planers': 2,
    'planes': 1,
    'planet': 2,
    'planetarium': 5,
    'planetary': 4,
    'planets': 2,
    'planitzer': 3,
    'plank': 1,
    'planking': 2,
    'planks': 1,
    'plankton': 2,
    'planktonic': 3,
    'planned': 1,
    'planner': 2,
    'planners': 2,
    'planning': 2,
    'plano': 2,
    'plans': 1,
    'plant': 1,
    'plantain': 2,
    'plantains': 2,
    'plantation': 3,
    'plantations': 3,
    'plante': 1,
    'planted': 2,
    'planter': 2,
    'planters': 2,
    'plantiffs': 2,
    'planting': 2,
    'plantings': 2,
    'plantlike': 2,
    'plantronic': 3,
    'plantronics': 3,
    'plants': 1,
    'plantz': 1,
    'plaque': 1,
    'plaques': 1,
    'plas': 1,
    'plascencia': 3,
    'plasencia': 3,
    'plaskett': 2,
    'plasma': 2,
    'plasminogen': 4,
    'plasmodia': 4,
    'plasmodium': 4,
    'plass': 1,
    'plassard': 2,
    'plasse': 1,
    'plaster': 2,
    'plasterboard': 3,
    'plastered': 2,
    'plasterer': 3,
    'plastering': 3,
    'plasters': 2,
    'plasterwork': 3,
    'plastic': 2,
    'plasticine': 3,
    'plasticizer': 4,
    'plastics': 2,
    'plata': 2,
    'plate': 1,
    'plateau': 2,
    'plateaued': 2,
    'plateauing': 3,
    'plated': 2,
    'platek': 2,
    'platelet': 2,
    'platelets': 2,
    'platelike': 2,
    'platen': 2,
    'plater': 2,
    'plates': 1,
    'platform': 2,
    'platforms': 2,
    'plath': 1,
    'plating': 2,
    'platinum': 2,
    'platitude': 3,
    'platitudes': 3,
    'platner': 2,
    'plato': 2,
    'platona': 3,
    'platonic': 3,
    'platonist': 3,
    'platonists': 3,
    'platoon': 2,
    'platoons': 2,
    'platt': 1,
    'platte': 1,
    'platten': 2,
    'platter': 2,
    'platters': 2,
    'plattner': 2,
    'platts': 1,
    'plattsburgh': 2,
    'platy': 2,
    'platypus': 3,
    'platz': 1,
    'platzer': 2,
    'plauche': 1,
    'plaudit': 2,
    'plaudits': 2,
    'plaugher': 2,
    'plausibility': 5,
    'plausible': 3,
    'plausibly': 3,
    'plaut': 1,
    'plautz': 1,
    'plavsic': 2,
    'plax': 1,
    'play': 1,
    'playa': 2,
    'playback': 2,
    'playbook': 2,
    'playboy': 2,
    'playcount': 2,
    'played': 1,
    'player': 2,
    'players': 2,
    'playford': 2,
    'playful': 2,
    'playfully': 3,
    'playfulness': 3,
    'playground': 2,
    'playgrounds': 2,
    'playhouse': 2,
    'playhouses': 3,
    'playing': 2,
    'playmate': 2,
    'playmates': 2,
    'playoff': 2,
    'playoffs': 2,
    'playpen': 2,
    'playpens': 2,
    'playroom': 2,
    'playrooms': 2,
    'plays': 1,
    'playstation': 3,
    'playstations': 3,
    'playtex': 2,
    'plaything': 2,
    'playthings': 2,
    'playwright': 2,
    'playwrights': 2,
    'plaza': 2,
    'plazas': 2,
    'plazic': 2,
    'plea': 1,
    'plead': 1,
    'pleaded': 2,
    'pleading': 2,
    'pleadings': 2,
    'pleads': 1,
    'pleas': 1,
    'pleasant': 2,
    'pleasantly': 3,
    'pleasanton': 3,
    'pleasantries': 3,
    'pleasantry': 3,
    'pleasants': 2,
    'pleasantville': 3,
    'please': 1,
    'pleased': 1,
    'pleaser': 2,
    'pleases': 2,
    'pleasing': 2,
    'pleasingly': 3,
    'pleasurable': 4,
    'pleasurama': 4,
    'pleasure': 2,
    'pleasures': 2,
    'pleat': 1,
    'pleats': 1,
    'plebeian': 3,
    'plebeians': 3,
    'plebiscite': 3,
    'pled': 1,
    'pledge': 1,
    'pledged': 1,
    'pledger': 2,
    'pledges': 2,
    'pledging': 2,
    'pleiad': 2,
    'pleiades': 3,
    'pleiads': 2,
    'plein': 1,
    'pleistocene': 3,
    'plemmons': 2,
    'plemons': 2,
    'plenary': 3,
    'plenet': 2,
    'plenmeer': 2,
    'plentiful': 3,
    'plenty': 2,
    'plenum': 2,
    'pleonasm': 3,
    'plescia': 3,
    'plese': 1,
    'pleshar': 2,
    'pleskow': 2,
    'pless': 1,
    'plessey': 2,
    'plessinger': 3,
    'plessis': 2,
    'plessy': 2,
    'pletcher': 2,
    'plethora': 3,
    'pletscher': 2,
    'plett': 1,
    'pletz': 1,
    'pleurisy': 3,
    'pleva': 2,
    'plew': 1,
    'plewa': 2,
    'plex': 1,
    'plexiglas': 3,
    'plexiglass': 3,
    'plexus': 2,
    'pliable': 3,
    'pliant': 2,
    'plichta': 2,
    'plied': 1,
    'plier': 2,
    'pliers': 2,
    'plies': 1,
    'plight': 1,
    'pliler': 2,
    'plimpton': 2,
    'plinius': 3,
    'plink': 1,
    'plinking': 2,
    'plinth': 1,
    'pliny': 2,
    'pliocene': 3,
    'plisetskaya': 4,
    'pliska': 2,
    'plitt': 1,
    'plocar': 2,
    'plocek': 2,
    'ploch': 1,
    'plocher': 2,
    'plock': 1,
    'plod': 1,
    'plodded': 2,
    'plodding': 2,
    'ploeger': 2,
    'ploetz': 1,
    'plog': 1,
    'plohn': 1,
    'plonka': 2,
    'plonski': 2,
    'ploof': 1,
    'plop': 1,
    'plopped': 1,
    'plosive': 2,
    'ploss': 1,
    'plot': 1,
    'plotkin': 2,
    'plotner': 2,
    'plotnick': 2,
    'plots': 1,
    'plott': 1,
    'plotted': 2,
    'plotter': 2,
    'plotters': 2,
    'plotting': 2,
    'plotts': 1,
    'plotz': 1,
    'plouff': 1,
    'plouffe': 1,
    'plough': 1,
    'ploughed': 1,
    'ploughs': 1,
    'ploughshare': 2,
    'ploughshares': 2,
    'plourde': 1,
    'plover': 2,
    'plovers': 2,
    'plow': 1,
    'plowden': 2,
    'plowed': 1,
    'plowing': 2,
    'plowman': 2,
    'plowright': 2,
    'plows': 1,
    'plowshare': 2,
    'plowshares': 2,
    'ploy': 1,
    'ploys': 1,
    'plucinski': 3,
    'pluck': 1,
    'plucked': 1,
    'plucker': 2,
    'plucking': 2,
    'plucks': 1,
    'plucky': 2,
    'plude': 1,
    'plue': 1,
    'pluff': 1,
    'plug': 1,
    'plugged': 1,
    'plugging': 2,
    'plugs': 1,
    'plum': 1,
    'plumage': 2,
    'plumages': 3,
    'plumb': 1,
    'plumbed': 1,
    'plumber': 2,
    'plumbers': 2,
    'plumbing': 2,
    'plume': 1,
    'plumer': 2,
    'plumeri': 3,
    'plumes': 1,
    'plumlee': 2,
    'plumley': 2,
    'plummer': 2,
    'plummet': 2,
    'plummeted': 3,
    'plummeting': 3,
    'plummets': 2,
    'plump': 1,
    'plumped': 1,
    'plums': 1,
    'plunder': 2,
    'plundered': 2,
    'plundering': 3,
    'plunge': 1,
    'plunged': 1,
    'plunger': 2,
    'plunges': 2,
    'plunging': 2,
    'plunk': 1,
    'plunked': 1,
    'plunker': 2,
    'plunkers': 2,
    'plunkett': 2,
    'plunking': 2,
    'plunkitt': 2,
    'plural': 2,
    'pluralism': 4,
    'pluralistic': 4,
    'plurality': 4,
    'pluralize': 3,
    'pluralized': 3,
    'pluralizes': 4,
    'pluralizing': 4,
    'plurals': 2,
    'pluribus': 3,
    'plurilateral': 5,
    'plus': 1,
    'pluses': 2,
    'plush': 1,
    'pluta': 2,
    'pluth': 1,
    'pluto': 2,
    'plutocrat': 3,
    'plutocrats': 3,
    'plutonian': 4,
    'plutonic': 3,
    'plutonium': 4,
    'pluvial': 3,
    'ply': 1,
    'plybon': 2,
    'plying': 2,
    'plyler': 2,
    'plymale': 2,
    'plymouth': 2,
    'plympton': 2,
    'plywood': 2,
    'pm': 2,
    'pneumatic': 3,
    'pneumatics': 3,
    'pneumo': 2,
    'pneumocystis': 4,
    'pneumonia': 3,
    'pneumonic': 3,
    'po': 1,
    'poach': 1,
    'poached': 1,
    'poacher': 2,
    'poachers': 2,
    'poaching': 2,
    'poag': 1,
    'poage': 2,
    'poarch': 1,
    'pobanz': 2,
    'pobla': 2,
    'poblano': 3,
    'pobst': 1,
    'pocahontas': 4,
    'pocatello': 4,
    'poch': 1,
    'poche': 1,
    'pochiluk': 3,
    'pociask': 3,
    'pocius': 3,
    'pock': 1,
    'pocked': 1,
    'pocket': 2,
    'pocketbook': 3,
    'pocketbooks': 3,
    'pocketed': 3,
    'pocketful': 3,
    'pocketing': 3,
    'pockets': 2,
    'pocklington': 3,
    'pockmark': 2,
    'pockmarked': 2,
    'pocks': 1,
    'poclain': 2,
    'poco': 2,
    'pocock': 2,
    'pocono': 3,
    'poconos': 3,
    'pocus': 2,
    'pod': 1,
    'podany': 3,
    'podell': 2,
    'podesta': 3,
    'podge': 1,
    'podgorski': 3,
    'podgurski': 3,
    'podhoretz': 3,
    'podiatrist': 4,
    'podium': 3,
    'podiums': 3,
    'podleska': 3,
    'podnar': 2,
    'podolak': 3,
    'podoll': 2,
    'podolski': 3,
    'podolsky': 3,
    'podrasky': 3,
    'podraza': 3,
    'pods': 1,
    'podunk': 2,
    'poduska': 3,
    'poe': 1,
    'poehl': 1,
    'poehler': 2,
    'poehlman': 2,
    'poel': 2,
    'poelman': 2,
    'poem': 2,
    'poems': 2,
    'poer': 2,
    'poeschel': 2,
    'poeschl': 2,
    'poet': 2,
    'poetic': 3,
    'poetically': 4,
    'poetry': 3,
    'poets': 2,
    'pofahl': 2,
    'poff': 1,
    'poffenbarger': 4,
    'poffenberger': 4,
    'pog': 1,
    'poggi': 2,
    'poggioli': 3,
    'pogo': 2,
    'pogorzelski': 4,
    'pogrom': 2,
    'pogroms': 2,
    'pogs': 1,
    'pogue': 1,
    'poh': 1,
    'pohang': 2,
    'pohjola': 3,
    'pohl': 1,
    'pohlad': 2,
    'pohle': 2,
    'pohlman': 2,
    'pohlmann': 2,
    'pohnpei': 2,
    'poignancy': 3,
    'poignant': 2,
    'poignantly': 3,
    'poindexter': 3,
    'poinot': 2,
    'poinsett': 2,
    'poinsettia': 4,
    'poinsettias': 4,
    'point': 1,
    'pointe': 1,
    'pointed': 2,
    'pointedly': 3,
    'pointenoire': 3,
    'pointer': 2,
    'pointers': 2,
    'pointing': 2,
    'pointingly': 3,
    'pointless': 2,
    'points': 1,
    'pointy': 2,
    'poirier': 3,
    'poirrier': 3,
    'poise': 1,
    'poised': 1,
    'poison': 2,
    'poisoned': 2,
    'poisoning': 3,
    'poisonings': 3,
    'poisonous': 3,
    'poisons': 2,
    'poissant': 2,
    'poisson': 2,
    'poitier': 2,
    'poitra': 2,
    'poitras': 2,
    'pokazukha': 4,
    'poke': 1,
    'poked': 1,
    'poker': 2,
    'pokes': 1,
    'pokeweed': 2,
    'pokey': 2,
    'poking': 2,
    'pokorney': 3,
    'pokorny': 3,
    'pokorski': 3,
    'poky': 2,
    'pol': 1,
    'polacek': 3,
    'polachek': 3,
    'polack': 2,
    'polak': 2,
    'polakoff': 3,
    'polakowski': 4,
    'polan': 2,
    'polanco': 3,
    'poland': 2,
    'polandri': 3,
    'polanski': 3,
    'polansky': 3,
    'polar': 2,
    'polarimeter': 5,
    'polaris': 3,
    'polariscope': 4,
    'polarity': 4,
    'polarization': 5,
    'polarize': 3,
    'polarized': 3,
    'polarizer': 4,
    'polarizes': 4,
    'polarizing': 4,
    'polarography': 5,
    'polaroid': 3,
    'polaroids': 3,
    'polasek': 3,
    'polaski': 3,
    'polasky': 3,
    'polce': 1,
    'polcyn': 2,
    'polder': 2,
    'pole': 1,
    'polecat': 2,
    'polecats': 2,
    'polek': 2,
    'polemic': 3,
    'polemical': 4,
    'polemicist': 4,
    'polemics': 3,
    'polen': 2,
    'poles': 1,
    'poletti': 3,
    'polevanov': 4,
    'polevoi': 3,
    'poleward': 2,
    'poley': 2,
    'polgar': 2,
    'polhamus': 3,
    'polhemus': 3,
    'polhill': 2,
    'poli': 2,
    'policastro': 4,
    'police': 2,
    'policed': 2,
    'policeman': 3,
    'policemen': 3,
    'polices': 3,
    'policewoman': 4,
    'policewomen': 4,
    'polich': 2,
    'policies': 3,
    'policing': 3,
    'policy': 3,
    'policyholder': 5,
    'policyholders': 5,
    'policymaker': 5,
    'policymakers': 5,
    'policymaking': 5,
    'polidori': 4,
    'polidoro': 4,
    'polifroni': 4,
    'polimeni': 4,
    'polin': 2,
    'poling': 2,
    'polino': 3,
    'polinski': 3,
    'polinsky': 3,
    'polio': 3,
    'poliquin': 3,
    'polis': 2,
    'polisario': 5,
    'polish': 2,
    'polished': 2,
    'polishing': 3,
    'politano': 4,
    'politburo': 4,
    'polite': 2,
    'politely': 3,
    'politeness': 3,
    'polites': 2,
    'politi': 3,
    'politic': 3,
    'political': 4,
    'politically': 5,
    'politician': 4,
    'politicians': 4,
    'politicization': 6,
    'politicize': 4,
    'politicized': 4,
    'politicizing': 5,
    'politicking': 4,
    'politico': 4,
    'politicos': 4,
    'politics': 3,
    'politis': 3,
    'polito': 3,
    'politte': 2,
    'polity': 3,
    'politz': 2,
    'polivka': 3,
    'polizzi': 3,
    'polje': 2,
    'polk': 1,
    'polka': 2,
    'polkas': 2,
    'polkinghorn': 3,
    'poll': 1,
    'pollack': 2,
    'pollak': 2,
    'pollan': 2,
    'polland': 2,
    'pollard': 2,
    'polled': 1,
    'pollen': 2,
    'pollens': 2,
    'pollet': 2,
    'pollett': 2,
    'polley': 2,
    'pollick': 2,
    'pollina': 3,
    'pollinate': 3,
    'pollinated': 4,
    'pollinates': 3,
    'pollination': 4,
    'polling': 2,
    'pollinger': 3,
    'pollini': 3,
    'pollinia': 4,
    'pollino': 3,
    'pollio': 3,
    'pollitt': 2,
    'pollman': 2,
    'pollner': 2,
    'pollo': 2,
    'pollock': 2,
    'polloi': 2,
    'pollok': 2,
    'polls': 1,
    'pollster': 2,
    'pollsters': 2,
    'pollutant': 3,
    'pollutants': 3,
    'pollute': 2,
    'polluted': 3,
    'polluter': 3,
    'polluters': 3,
    'pollutes': 2,
    'polluting': 3,
    'pollution': 3,
    'pollux': 2,
    'polly': 2,
    'pollyanna': 4,
    'pollyannish': 4,
    'polo': 2,
    'polonaise': 3,
    'polonium': 4,
    'polonsky': 3,
    'pols': 1,
    'polsby': 2,
    'polsinelli': 4,
    'polskin': 2,
    'polsky': 2,
    'polson': 2,
    'polster': 2,
    'polston': 2,
    'poltergeist': 3,
    'poltergeists': 3,
    'poltrack': 2,
    'polus': 2,
    'poly': 2,
    'polyacetylene': 6,
    'polyak': 3,
    'polyamide': 4,
    'polyandrous': 4,
    'polyandry': 4,
    'polybutylene': 5,
    'polycarpic': 4,
    'polycast': 3,
    'polychlorinate': 5,
    'polychlorinated': 6,
    'polychrome': 3,
    'polyconomics': 5,
    'polyester': 4,
    'polyesters': 4,
    'polyethylene': 5,
    'polygamous': 4,
    'polygamy': 4,
    'polyglot': 3,
    'polygon': 3,
    'polygonal': 4,
    'polygram': 3,
    'polygraph': 3,
    'polygraphs': 3,
    'polygynous': 4,
    'polygyny': 4,
    'polyhedron': 4,
    'polyhedrons': 4,
    'polyhemoglobin': 6,
    'polymarker': 4,
    'polymarkers': 4,
    'polymer': 3,
    'polymerase': 4,
    'polymerization': 6,
    'polymerize': 4,
    'polymerized': 4,
    'polymerizes': 5,
    'polymers': 3,
    'polymorph': 3,
    'polymorphic': 4,
    'polymorphism': 5,
    'polynesia': 4,
    'polynesian': 4,
    'polynomial': 5,
    'polyolefin': 5,
    'polyp': 2,
    'polyphase': 3,
    'polyphonic': 4,
    'polyphony': 4,
    'polypropylene': 5,
    'polyps': 2,
    'polysaccharide': 5,
    'polysaccharides': 5,
    'polysar': 3,
    'polysilicon': 5,
    'polystyrene': 4,
    'polysyllabic': 5,
    'polytech': 3,
    'polytechnic': 4,
    'polytechnologies': 6,
    'polytechnology': 6,
    'polytheism': 5,
    'polytheistic': 5,
    'polytropic': 4,
    'polyurethane': 5,
    'polyvinyl': 4,
    'polzer': 2,
    'polzin': 2,
    'pom': 1,
    'poma': 2,
    'pombo': 2,
    'pomerance': 3,
    'pomerania': 5,
    'pomeranian': 5,
    'pomerantz': 3,
    'pomeranz': 3,
    'pomerleau': 3,
    'pomeroy': 3,
    'pomicino': 4,
    'pommel': 2,
    'pommer': 2,
    'pommier': 3,
    'pomodoro': 4,
    'pomography': 4,
    'pomology': 4,
    'pomona': 3,
    'pomp': 1,
    'pompa': 2,
    'pompadour': 3,
    'pompadur': 3,
    'pompano': 3,
    'pompeo': 3,
    'pomper': 2,
    'pompey': 2,
    'pompidou': 3,
    'pompilio': 4,
    'pomplun': 2,
    'pompom': 2,
    'pompoms': 2,
    'pompon': 2,
    'pomponio': 4,
    'pompons': 2,
    'pomposity': 4,
    'pompous': 2,
    'pompousness': 3,
    'pomrenze': 2,
    'pomroy': 2,
    'pon': 1,
    'ponce': 2,
    'poncet': 2,
    'ponchan': 2,
    'poncho': 2,
    'ponchos': 2,
    'pond': 1,
    'ponder': 2,
    'pondered': 2,
    'pondering': 3,
    'ponderosa': 4,
    'ponderous': 3,
    'ponders': 2,
    'pondimin': 3,
    'ponds': 1,
    'pong': 1,
    'pongratz': 2,
    'poniatowski': 5,
    'ponied': 2,
    'ponies': 2,
    'pons': 1,
    'ponsolle': 2,
    'pont': 1,
    'pontarelli': 4,
    'pontbriand': 3,
    'ponte': 1,
    'pontes': 2,
    'ponti': 2,
    'pontiac': 3,
    'pontiacs': 3,
    'pontiff': 2,
    'pontifical': 4,
    'pontificate': 4,
    'pontificated': 5,
    'pontificater': 5,
    'pontificaters': 5,
    'pontificates': 4,
    'pontificating': 5,
    'pontification': 5,
    'pontifications': 5,
    'pontikes': 3,
    'pontillo': 3,
    'pontious': 2,
    'pontius': 3,
    'ponto': 2,
    'ponton': 2,
    'pontoon': 2,
    'pontoons': 2,
    'ponts': 1,
    'pony': 2,
    'ponytail': 3,
    'ponzetti': 3,
    'ponzi': 2,
    'ponzio': 3,
    'ponzo': 2,
    'poo': 1,
    'pooch': 1,
    'pooches': 2,
    'poodle': 2,
    'poodles': 2,
    'poof': 1,
    'pooh': 1,
    'poohed': 1,
    'pool': 1,
    'poolas': 2,
    'poole': 1,
    'pooled': 1,
    'pooler': 2,
    'pooley': 2,
    'pooling': 2,
    'pools': 1,
    'poolside': 2,
    'poon': 1,
    'poop': 1,
    'pooped': 1,
    'pooper': 2,
    'poopers': 2,
    'poops': 1,
    'poor': 1,
    'poorbaugh': 2,
    'poore': 1,
    'poorer': 2,
    'poorest': 2,
    'poorhouse': 2,
    'poorly': 2,
    'poorman': 2,
    'poors': 1,
    'pooser': 2,
    'poovey': 2,
    'pop': 1,
    'popa': 2,
    'poparelli': 4,
    'popcorn': 2,
    'pope': 1,
    'popeil': 2,
    'popejoy': 2,
    'popek': 2,
    'popelka': 3,
    'popes': 1,
    'popeye': 2,
    'popeyes': 2,
    'popham': 2,
    'popick': 2,
    'popiel': 2,
    'popieluszko': 5,
    'popik': 2,
    'popken': 2,
    'popkin': 2,
    'popko': 2,
    'poplar': 2,
    'poplawski': 3,
    'poplin': 2,
    'popoff': 2,
    'popolare': 4,
    'popov': 2,
    'popovic': 3,
    'popovich': 3,
    'popowski': 3,
    'popp': 1,
    'poppa': 2,
    'poppe': 1,
    'poppea': 3,
    'popped': 1,
    'poppell': 2,
    'poppen': 2,
    'popper': 2,
    'poppers': 2,
    'poppies': 2,
    'popping': 2,
    'poppins': 2,
    'popple': 2,
    'poppleton': 3,
    'popplewell': 3,
    'poppy': 2,
    'poppycock': 3,
    'pops': 1,
    'popsicle': 3,
    'popu': 2,
    'populace': 3,
    'popular': 3,
    'popularity': 5,
    'popularization': 6,
    'popularize': 4,
    'popularized': 4,
    'popularizer': 5,
    'popularizing': 5,
    'popularly': 4,
    'populate': 3,
    'populated': 4,
    'populating': 4,
    'population': 4,
    'populations': 4,
    'populism': 4,
    'populist': 3,
    'populists': 3,
    'populous': 3,
    'popwell': 2,
    'poquette': 2,
    'por': 1,
    'porada': 3,
    'porath': 2,
    'porcaro': 3,
    'porcelain': 3,
    'porcelains': 3,
    'porcella': 3,
    'porcelli': 3,
    'porcello': 3,
    'porch': 1,
    'porcher': 2,
    'porches': 2,
    'porchia': 3,
    'porco': 2,
    'porcupine': 3,
    'porcupines': 3,
    'pordy': 2,
    'pore': 1,
    'pored': 1,
    'poremba': 3,
    'pores': 1,
    'porges': 2,
    'porgy': 2,
    'poring': 2,
    'pork': 1,
    'porker': 2,
    'porkers': 2,
    'porky': 2,
    'porn': 1,
    'porno': 2,
    'pornographer': 4,
    'pornographers': 4,
    'pornographic': 4,
    'pornography': 4,
    'porous': 2,
    'porphyritic': 4,
    'porphyry': 3,
    'porpoise': 2,
    'porpoises': 3,
    'porr': 1,
    'porras': 2,
    'porrazzo': 3,
    'porreca': 3,
    'porretta': 3,
    'porridge': 2,
    'porritt': 2,
    'porro': 2,
    'porsche': 2,
    'porsches': 2,
    'port': 1,
    'porta': 2,
    'portability': 5,
    'portable': 3,
    'portables': 3,
    'portadown': 3,
    'portage': 2,
    'portal': 2,
    'portales': 3,
    'portals': 2,
    'portanova': 4,
    'portculis': 3,
    'porte': 1,
    'portec': 2,
    'ported': 2,
    'portee': 2,
    'portela': 3,
    'portell': 2,
    'portelli': 3,
    'portend': 2,
    'portending': 3,
    'portends': 2,
    'portent': 2,
    'portentous': 3,
    'portents': 2,
    'porteous': 3,
    'porter': 2,
    'portera': 3,
    'porterages': 4,
    'porterfield': 3,
    'porters': 2,
    'portfolio': 4,
    'portfolios': 4,
    'porth': 1,
    'portia': 2,
    'portico': 3,
    'portier': 3,
    'portilla': 3,
    'portillo': 3,
    'portinadi': 4,
    'porting': 2,
    'portion': 2,
    'portions': 2,
    'portis': 2,
    'portland': 2,
    'portlock': 2,
    'portly': 2,
    'portman': 2,
    'portner': 2,
    'portney': 2,
    'portnoy': 2,
    'porto': 2,
    'portrait': 2,
    'portraits': 2,
    'portray': 2,
    'portrayal': 3,
    'portrayals': 3,
    'portrayed': 2,
    'portraying': 3,
    'portrays': 2,
    'ports': 1,
    'portsmouth': 2,
    'portugal': 3,
    'portugalia': 5,
    'portuguese': 3,
    'portwood': 2,
    'portz': 1,
    'porzio': 3,
    'pos': 1,
    'posa': 2,
    'posada': 3,
    'posavina': 4,
    'posch': 1,
    'posco': 2,
    'pose': 1,
    'posed': 1,
    'poseidon': 3,
    'posen': 2,
    'poser': 2,
    'poses': 2,
    'posey': 2,
    'posh': 1,
    'poshard': 2,
    'posing': 2,
    'posit': 2,
    'posited': 3,
    'position': 3,
    'positioned': 3,
    'positioning': 4,
    'positions': 3,
    'positive': 3,
    'positively': 4,
    'positives': 3,
    'positivism': 5,
    'positron': 3,
    'positrons': 3,
    'posits': 2,
    'posluns': 2,
    'posluszny': 3,
    'posner': 2,
    'posners': 2,
    'posnick': 2,
    'pospisil': 3,
    'poss': 1,
    'posse': 2,
    'possehl': 2,
    'posses': 2,
    'possess': 2,
    'possessed': 2,
    'possesses': 3,
    'possessing': 3,
    'possession': 3,
    'possessions': 3,
    'possessive': 3,
    'possessiveness': 4,
    'possessor': 3,
    'possibilities': 5,
    'possibility': 5,
    'possible': 3,
    'possibly': 3,
    'possum': 2,
    'possums': 2,
    'post': 1,
    'postage': 2,
    'postal': 2,
    'postcard': 2,
    'postcards': 2,
    'postcrash': 2,
    'postdate': 2,
    'postdated': 3,
    'postdates': 2,
    'postdating': 3,
    'postdoctoral': 4,
    'posted': 2,
    'postel': 2,
    'postell': 2,
    'postema': 3,
    'posten': 2,
    'poster': 2,
    'posteraro': 4,
    'posterior': 4,
    'posteriors': 4,
    'posterity': 4,
    'posters': 2,
    'postgame': 2,
    'posthole': 2,
    'postholes': 2,
    'posthumous': 3,
    'posthumously': 4,
    'posting': 2,
    'postings': 2,
    'postino': 3,
    'postipankki': 4,
    'postit': 2,
    'postle': 2,
    'postlethwait': 3,
    'postlewait': 3,
    'postlewaite': 3,
    'postma': 2,
    'postman': 2,
    'postmark': 2,
    'postmarked': 2,
    'postmaster': 3,
    'postmasters': 3,
    'postmodern': 3,
    'postmortem': 3,
    'postnatal': 3,
    'poston': 2,
    'postpone': 2,
    'postponed': 2,
    'postponement': 3,
    'postponements': 3,
    'postpones': 2,
    'postponing': 3,
    'postrel': 2,
    'postrelle': 2,
    'postretirement': 5,
    'posts': 1,
    'postscript': 2,
    'postscripts': 2,
    'posttraumatic': 4,
    'postulate': 3,
    'postulates': 3,
    'posture': 2,
    'postures': 2,
    'posturing': 3,
    'posturings': 3,
    'postwar': 2,
    'posuvalyuk': 4,
    'pot': 1,
    'potable': 3,
    'potamkin': 3,
    'potapov': 3,
    'potash': 2,
    'potassium': 4,
    'potato': 3,
    'potatoe': 3,
    'potatoes': 3,
    'potbaryoskin': 4,
    'pote': 1,
    'poteat': 2,
    'poteet': 2,
    'poteete': 2,
    'potemkin': 3,
    'potempa': 3,
    'poten': 2,
    'potency': 3,
    'potent': 2,
    'potentate': 3,
    'potentates': 3,
    'potential': 3,
    'potentially': 4,
    'potentials': 3,
    'potenza': 3,
    'poth': 1,
    'pothier': 3,
    'pothitos': 3,
    'pothole': 2,
    'potholed': 2,
    'potholes': 2,
    'potier': 3,
    'potiker': 3,
    'potion': 2,
    'potions': 2,
    'potlatch': 2,
    'potluck': 2,
    'potocki': 3,
    'potomac': 3,
    'potpie': 2,
    'potpies': 2,
    'potpourri': 3,
    'potpourris': 3,
    'potratz': 2,
    'pots': 1,
    'potsdam': 2,
    'potshot': 2,
    'potshots': 2,
    'pott': 1,
    'pottebaum': 2,
    'potted': 2,
    'potteiger': 3,
    'pottenger': 3,
    'potter': 2,
    'potters': 2,
    'pottery': 3,
    'potthast': 2,
    'potthoff': 2,
    'potthurst': 2,
    'pottinger': 3,
    'pottle': 2,
    'pottorff': 2,
    'potts': 1,
    'potty': 2,
    'potucek': 3,
    'potvin': 2,
    'pou': 1,
    'pouch': 1,
    'poucher': 2,
    'pouches': 2,
    'poudrier': 4,
    'pough': 1,
    'poughkeepsie': 3,
    'poul': 1,
    'poulenc': 2,
    'poulin': 2,
    'pouliot': 3,
    'poulos': 2,
    'poulsen': 2,
    'poulson': 2,
    'poulter': 2,
    'poultice': 2,
    'poultices': 3,
    'poulton': 2,
    'poultry': 2,
    'pounce': 1,
    'pounced': 1,
    'pouncey': 2,
    'pouncing': 2,
    'pouncy': 2,
    'pound': 1,
    'poundage': 2,
    'pounded': 2,
    'pounder': 2,
    'pounders': 2,
    'pounding': 2,
    'pounds': 1,
    'poundstone': 2,
    'poupard': 2,
    'pour': 1,
    'pourciau': 3,
    'poured': 1,
    'pouring': 2,
    'pours': 1,
    'poussaint': 2,
    'poussant': 2,
    'poussin': 2,
    'pousson': 2,
    'poust': 1,
    'pout': 1,
    'pouted': 2,
    'pouter': 2,
    'pouting': 2,
    'poutre': 2,
    'pouts': 1,
    'pouty': 2,
    'poveromo': 4,
    'poverty': 3,
    'povich': 2,
    'pow': 1,
    'powalsky': 3,
    'powder': 2,
    'powdered': 2,
    'powdering': 3,
    'powderly': 3,
    'powders': 2,
    'powdery': 3,
    'powe': 1,
    'powell': 2,
    'powelson': 3,
    'power': 2,
    'powerball': 3,
    'powerboat': 3,
    'powerbook': 3,
    'powercise': 2,
    'powered': 2,
    'powerful': 3,
    'powerfully': 3,
    'powergen': 3,
    'powerhouse': 3,
    'powerhouses': 4,
    'powering': 3,
    'powerless': 3,
    'powerlessness': 4,
    'powerpc': 4,
    'powerpcs': 4,
    'powers': 2,
    'powersoft': 3,
    'powertrain': 2,
    'powis': 2,
    'powles': 2,
    'powless': 2,
    'powley': 2,
    'pownall': 2,
    'pows': 1,
    'powter': 2,
    'powwow': 2,
    'powwows': 2,
    'pox': 1,
    'poyer': 2,
    'poyner': 2,
    'poynor': 2,
    'poynter': 2,
    'poynton': 2,
    'poyser': 2,
    'poythress': 2,
    'poznan': 2,
    'poznanski': 3,
    'pozner': 2,
    'pozniak': 3,
    'poznikov': 3,
    'pozo': 2,
    'pozos': 2,
    'pozzi': 2,
    'ppm': 3,
    'prab': 1,
    'pracht': 1,
    'practicable': 4,
    'practical': 3,
    'practicality': 5,
    'practically': 3,
    'practice': 2,
    'practiced': 2,
    'practices': 3,
    'practicing': 3,
    'practitioner': 4,
    'practitioners': 4,
    'prada': 2,
    'pradesh': 2,
    'pradetto': 3,
    'prado': 2,
    'praetor': 2,
    'praetorian': 4,
    'prager': 2,
    'pragmatic': 3,
    'pragmatically': 5,
    'pragmatism': 4,
    'pragmatist': 3,
    'pragmatists': 3,
    'prague': 1,
    'prahl': 1,
    'prairie': 2,
    'prairies': 2,
    'praise': 1,
    'praised': 1,
    'praises': 2,
    'praiseworthy': 3,
    'praising': 2,
    'prakash': 2,
    'prall': 1,
    'pralle': 1,
    'pran': 1,
    'prance': 1,
    'prancer': 2,
    'prances': 2,
    'prancing': 2,
    'prange': 1,
    'pranger': 2,
    'prank': 1,
    'pranks': 1,
    'prankster': 2,
    'pranksters': 2,
    'prapas': 2,
    'prasad': 2,
    'prasek': 2,
    'prashant': 2,
    'pratap': 2,
    'prater': 2,
    'pratfall': 2,
    'pratfalls': 2,
    'prather': 2,
    'prato': 2,
    'prats': 1,
    'pratt': 1,
    'pratte': 1,
    'prattle': 2,
    'prattled': 2,
    'prattles': 2,
    'prattling': 2,
    'prattville': 2,
    'praun': 1,
    'prause': 1,
    'pravda': 2,
    'prawn': 1,
    'prawns': 1,
    'praxair': 2,
    'praxis': 2,
    'pray': 1,
    'prayed': 1,
    'prayer': 1,
    'prayerful': 2,
    'prayers': 1,
    'praying': 2,
    'prays': 1,
    'praytor': 2,
    'prazak': 2,
    'prchal': 2,
    'pre': 1,
    'preach': 1,
    'preached': 1,
    'preacher': 2,
    'preachers': 2,
    'preaches': 2,
    'preaching': 2,
    'preachy': 2,
    'preadolescence': 5,
    'preadolescent': 5,
    'preakness': 2,
    'preamble': 3,
    'prearrange': 3,
    'prearranged': 3,
    'prearranges': 4,
    'prearranging': 4,
    'preas': 1,
    'prebble': 2,
    'prebe': 1,
    'preble': 2,
    'prebon': 2,
    'precambrian': 4,
    'precancerous': 4,
    'precarious': 4,
    'precariously': 5,
    'precast': 2,
    'precaution': 3,
    'precautionary': 5,
    'precautions': 3,
    'precede': 2,
    'preceded': 3,
    'precedence': 3,
    'precedent': 3,
    'precedents': 3,
    'precedes': 2,
    'preceding': 3,
    'preceed': 2,
    'preceeding': 3,
    'preceeds': 2,
    'precept': 2,
    'precepts': 2,
    'precession': 3,
    'precht': 1,
    'prechter': 2,
    'prechtl': 2,
    'preciado': 3,
    'precinct': 2,
    'precincts': 2,
    'precious': 2,
    'precip': 2,
    'precipice': 3,
    'precipitate': 4,
    'precipitated': 5,
    'precipitating': 5,
    'precipitation': 5,
    'precipitous': 4,
    'precipitously': 5,
    'precis': 2,
    'precise': 2,
    'precisely': 3,
    'precision': 3,
    'preclinical': 4,
    'preclude': 2,
    'precluded': 3,
    'precludes': 2,
    'precluding': 3,
    'preclusion': 3,
    'precocious': 3,
    'preconceive': 3,
    'preconceived': 3,
    'preconception': 4,
    'preconceptions': 4,
    'precondition': 4,
    'preconditions': 4,
    'precook': 2,
    'precooked': 2,
    'precourt': 2,
    'precrash': 2,
    'precursor': 3,
    'precursors': 3,
    'predaceous': 3,
    'predate': 2,
    'predated': 3,
    'predates': 2,
    'predation': 3,
    'predator': 3,
    'predators': 3,
    'predatory': 4,
    'predawn': 2,
    'preddy': 2,
    'predecessor': 4,
    'predecessors': 4,
    'predestination': 5,
    'predestine': 3,
    'predestined': 3,
    'predetermine': 4,
    'predetermined': 4,
    'predicament': 4,
    'predicaments': 4,
    'predicate': 3,
    'predicated': 4,
    'predicates': 3,
    'predicating': 4,
    'predict': 2,
    'predictability': 6,
    'predictable': 4,
    'predictably': 4,
    'predicted': 3,
    'predicting': 3,
    'prediction': 3,
    'predictions': 3,
    'predictive': 3,
    'predictor': 3,
    'predictors': 3,
    'predicts': 2,
    'predilection': 4,
    'predilections': 4,
    'predispose': 3,
    'predisposed': 3,
    'predisposing': 4,
    'predisposition': 5,
    'predispositions': 5,
    'predmore': 2,
    'predominance': 4,
    'predominant': 4,
    'predominantly': 5,
    'predominate': 4,
    'predominated': 5,
    'predominates': 4,
    'predominating': 5,
    'pree': 1,
    'preece': 1,
    'preelection': 4,
    'preeminence': 4,
    'preeminent': 4,
    'preempt': 2,
    'preempted': 3,
    'preempting': 3,
    'preemption': 3,
    'preemptive': 3,
    'preemptively': 4,
    'preempts': 2,
    'preen': 1,
    'preened': 1,
    'preening': 2,
    'preexist': 3,
    'preexisted': 4,
    'preexisting': 4,
    'preexists': 3,
    'prefab': 2,
    'prefabricate': 4,
    'prefabricated': 5,
    'prefabrication': 5,
    'prefabs': 2,
    'preface': 2,
    'prefaced': 2,
    'prefect': 2,
    'prefectural': 4,
    'prefecture': 3,
    'prefer': 2,
    'preferable': 4,
    'preferably': 4,
    'prefered': 2,
    'preference': 3,
    'preferenced': 3,
    'preferences': 4,
    'preferential': 4,
    'preferentially': 5,
    'preferred': 2,
    'preferreds': 3,
    'preferring': 3,
    'prefers': 2,
    'prefix': 2,
    'prefrontal': 3,
    'pregler': 2,
    'pregnancies': 3,
    'pregnancy': 3,
    'pregnant': 2,
    'prego': 2,
    'preheim': 2,
    'prehistoric': 4,
    'prehn': 1,
    'preholiday': 4,
    'preignition': 4,
    'preis': 1,
    'preiser': 2,
    'preisig': 2,
    'preisler': 3,
    'preiss': 1,
    'prejean': 2,
    'prejudge': 2,
    'prejudged': 2,
    'prejudgment': 3,
    'prejudice': 3,
    'prejudiced': 3,
    'prejudices': 4,
    'prejudicial': 4,
    'prejudicing': 4,
    'prekindergarten': 5,
    'prelate': 2,
    'prelates': 2,
    'prelim': 2,
    'preliminaries': 5,
    'preliminarily': 6,
    'preliminary': 5,
    'prelims': 2,
    'prell': 1,
    'prellwitz': 2,
    'prelude': 2,
    'preludes': 2,
    'prem': 1,
    'premadasa': 4,
    'premarin': 3,
    'premarital': 4,
    'premark': 2,
    'premarket': 3,
    'premature': 3,
    'prematurely': 4,
    'premeditate': 4,
    'premeditated': 5,
    'premeditation': 5,
    'premier': 2,
    'premiere': 2,
    'premiered': 2,
    'premieres': 2,
    'premiering': 3,
    'premiers': 2,
    'premiership': 3,
    'premise': 2,
    'premised': 2,
    'premises': 3,
    'premium': 3,
    'premiums': 3,
    'premo': 2,
    'premodern': 3,
    'premonition': 4,
    'premonitions': 4,
    'premonitory': 5,
    'prenatal': 3,
    'prendergast': 3,
    'prenger': 2,
    'prenn': 1,
    'prensa': 2,
    'prentice': 2,
    'prentiss': 2,
    'prentnieks': 3,
    'prenuptial': 3,
    'preoccupation': 5,
    'preoccupations': 5,
    'preoccupied': 4,
    'preoccupies': 4,
    'preoccupy': 4,
    'preordain': 3,
    'preordained': 3,
    'prep': 1,
    'prepackage': 3,
    'prepackaged': 3,
    'prepaid': 2,
    'preparation': 4,
    'preparations': 4,
    'preparatory': 5,
    'prepare': 2,
    'prepared': 2,
    'preparedness': 4,
    'preparer': 3,
    'preparers': 3,
    'prepares': 2,
    'preparing': 3,
    'prepay': 2,
    'prepaying': 3,
    'prepayment': 3,
    'prepayments': 3,
    'preplanning': 3,
    'preponderance': 3,
    'preposterous': 4,
    'prepped': 1,
    'preppie': 2,
    'prepping': 2,
    'preppy': 2,
    'prepuce': 2,
    'prequel': 2,
    'prerecord': 3,
    'prerecorded': 4,
    'prerequisite': 4,
    'prerequisites': 4,
    'prerogative': 4,
    'prerogatives': 4,
    'pres': 1,
    'presage': 2,
    'presaged': 2,
    'presages': 3,
    'presaging': 3,
    'presale': 2,
    'presario': 4,
    'presby': 2,
    'presbyterian': 5,
    'presbyterians': 5,
    'preschel': 2,
    'prescher': 2,
    'preschool': 2,
    'preschooler': 3,
    'preschoolers': 3,
    'preschools': 2,
    'prescience': 3,
    'prescient': 3,
    'prescot': 2,
    'prescott': 2,
    'prescribe': 2,
    'prescribed': 2,
    'prescriber': 3,
    'prescribers': 3,
    'prescribes': 2,
    'prescribing': 3,
    'prescription': 3,
    'prescriptions': 3,
    'presence': 2,
    'presences': 3,
    'present': 2,
    'presentable': 4,
    'presentation': 4,
    'presentations': 4,
    'presented': 3,
    'presenter': 3,
    'presenters': 3,
    'presenting': 3,
    'presently': 3,
    'presentment': 3,
    'presents': 2,
    'preservation': 4,
    'preservationist': 5,
    'preservationists': 5,
    'preservative': 4,
    'preservatives': 4,
    'preserve': 2,
    'preserved': 2,
    'preserver': 3,
    'preservers': 3,
    'preserves': 2,
    'preserving': 3,
    'preset': 2,
    'preside': 2,
    'presided': 3,
    'presidencies': 4,
    'presidency': 4,
    'president': 3,
    'presidential': 4,
    'presidentialist': 5,
    'presidentially': 5,
    'presidents': 3,
    'presides': 2,
    'presiding': 3,
    'presidio': 4,
    'presidium': 4,
    'preslar': 2,
    'presler': 3,
    'presley': 2,
    'presnall': 2,
    'presnell': 2,
    'presplit': 2,
    'press': 1,
    'pressboard': 2,
    'pressburger': 3,
    'presse': 1,
    'pressed': 1,
    'pressel': 2,
    'presser': 2,
    'presses': 2,
    'pressey': 2,
    'pressing': 2,
    'pressler': 2,
    'pressley': 2,
    'pressly': 2,
    'pressman': 2,
    'pressnell': 2,
    'presson': 2,
    'presstek': 2,
    'pressure': 2,
    'pressured': 2,
    'pressures': 2,
    'pressuring': 3,
    'pressurization': 5,
    'pressurize': 3,
    'pressurized': 3,
    'pressurizes': 4,
    'pressurizing': 4,
    'presswood': 2,
    'presswoods': 2,
    'prest': 1,
    'presta': 2,
    'prestage': 2,
    'prestech': 2,
    'presti': 2,
    'prestia': 2,
    'prestidge': 2,
    'prestidigitation': 6,
    'prestidigitator': 6,
    'prestidigitators': 6,
    'prestige': 2,
    'prestigiacomo': 5,
    'prestigious': 3,
    'presto': 2,
    'preston': 2,
    'prestowitz': 3,
    'prestridge': 2,
    'prestwich': 2,
    'prestwood': 2,
    'presumably': 4,
    'presume': 2,
    'presumed': 2,
    'presumes': 2,
    'presuming': 3,
    'presumption': 3,
    'presumptions': 3,
    'presumptive': 3,
    'presumptuous': 4,
    'presuppose': 3,
    'presupposes': 4,
    'presupposition': 5,
    'presutti': 3,
    'preszler': 2,
    'pret': 1,
    'pretax': 2,
    'prete': 1,
    'preteen': 2,
    'preteens': 2,
    'pretend': 2,
    'pretended': 3,
    'pretender': 3,
    'pretenders': 3,
    'pretending': 3,
    'pretends': 2,
    'pretense': 2,
    'pretenses': 3,
    'pretension': 3,
    'pretensions': 3,
    'pretentious': 3,
    'preterm': 2,
    'pretext': 2,
    'pretoria': 4,
    'pretrial': 3,
    'pretti': 2,
    'prettier': 3,
    'pretties': 2,
    'prettiest': 3,
    'pretty': 2,
    'prettyman': 3,
    'pretzel': 2,
    'pretzels': 2,
    'pretzer': 2,
    'preuss': 1,
    'preussag': 2,
    'preusser': 2,
    'prevail': 2,
    'prevailed': 2,
    'prevailing': 3,
    'prevails': 2,
    'preval': 2,
    'prevalence': 3,
    'prevalent': 3,
    'prevaricate': 4,
    'prevaricated': 5,
    'prevaricates': 4,
    'prevaricating': 5,
    'prevarication': 5,
    'prevatt': 2,
    'prevatte': 2,
    'prevent': 2,
    'preventable': 4,
    'preventative': 4,
    'preventatives': 4,
    'prevented': 3,
    'preventing': 3,
    'prevention': 3,
    'preventive': 3,
    'prevents': 2,
    'prevette': 2,
    'preview': 2,
    'previewed': 2,
    'previewing': 3,
    'previews': 2,
    'previn': 2,
    'previous': 3,
    'previously': 4,
    'previte': 2,
    'previti': 3,
    'prevo': 2,
    'prevost': 2,
    'prew': 1,
    'prewar': 2,
    'preway': 2,
    'prewett': 2,
    'prewitt': 2,
    'prey': 1,
    'preyed': 1,
    'preyer': 2,
    'preying': 2,
    'preyista': 3,
    'preyistas': 3,
    'preys': 1,
    'preziosi': 4,
    'prezioso': 4,
    'prezzano': 3,
    'pri': 1,
    'priam': 2,
    'pribble': 2,
    'pribula': 3,
    'pribyl': 2,
    'price': 1,
    'priced': 1,
    'priceless': 2,
    'pricer': 2,
    'prices': 2,
    'pricey': 2,
    'prichard': 2,
    'prichett': 2,
    'pricier': 3,
    'priciest': 3,
    'pricing': 2,
    'pricings': 2,
    'prick': 1,
    'pricked': 1,
    'prickett': 2,
    'prickleback': 3,
    'pricklebacks': 3,
    'prickly': 2,
    'pricks': 1,
    'pricor': 2,
    'priddy': 2,
    'pride': 1,
    'prideaux': 2,
    'prided': 2,
    'pridemore': 2,
    'prides': 1,
    'pridgen': 2,
    'pridgeon': 2,
    'pridmore': 2,
    'priebe': 1,
    'priebke': 2,
    'pried': 1,
    'priem': 1,
    'prien': 1,
    'prier': 2,
    'pries': 1,
    'priess': 1,
    'priest': 1,
    'priester': 2,
    'priestess': 2,
    'priesthood': 2,
    'priestley': 2,
    'priestly': 2,
    'priests': 1,
    'prieta': 2,
    'prieto': 2,
    'prieur': 2,
    'prig': 1,
    'prigge': 1,
    'prigmore': 2,
    'prihoda': 3,
    'prijedor': 3,
    'prill': 1,
    'prillaman': 3,
    'prim': 1,
    'prima': 2,
    'primack': 2,
    'primacy': 3,
    'primakov': 3,
    'primal': 2,
    'primaries': 3,
    'primarily': 4,
    'primark': 2,
    'primary': 3,
    'primate': 2,
    'primates': 2,
    'primavera': 4,
    'prime': 1,
    'primeau': 2,
    'primeaux': 2,
    'primebank': 2,
    'primeco': 2,
    'primed': 1,
    'primenews': 2,
    'primer': 2,
    'primerica': 4,
    'primers': 2,
    'primes': 1,
    'primestar': 2,
    'primetime': 2,
    'primeval': 3,
    'primiano': 4,
    'priming': 2,
    'primitive': 3,
    'primitivistic': 5,
    'primly': 2,
    'primm': 1,
    'primmer': 2,
    'primo': 2,
    'primordial': 4,
    'primp': 1,
    'primrose': 2,
    'primroses': 3,
    'primus': 2,
    'prince': 1,
    'princely': 2,
    'princes': 2,
    'princess': 2,
    'princesses': 3,
    'princeton': 2,
    'princeville': 2,
    'principal': 3,
    'principalities': 5,
    'principality': 5,
    'principally': 3,
    'principals': 3,
    'principato': 4,
    'principe': 3,
    'principia': 4,
    'principle': 3,
    'principled': 3,
    'principles': 3,
    'prindiville': 3,
    'prindl': 2,
    'prindle': 2,
    'prine': 1,
    'pring': 1,
    'pringle': 2,
    'prinkey': 2,
    'prinos': 2,
    'prins': 1,
    'prinsen': 2,
    'print': 1,
    'printable': 3,
    'printed': 2,
    'printemps': 2,
    'printer': 2,
    'printers': 2,
    'printing': 2,
    'printings': 2,
    'printmaker': 3,
    'printmakers': 3,
    'printmaking': 3,
    'printon': 2,
    'printout': 2,
    'printouts': 2,
    'prints': 1,
    'printup': 2,
    'printy': 2,
    'printz': 1,
    'prinz': 1,
    'priola': 3,
    'prioleau': 3,
    'priolo': 3,
    'prior': 2,
    'priore': 3,
    'priorities': 4,
    'prioritize': 4,
    'prioritized': 4,
    'prioritizes': 5,
    'prioritizing': 5,
    'priority': 4,
    'priors': 2,
    'priory': 3,
    'priroda': 3,
    'pris': 1,
    'prisburg': 2,
    'priscilla': 3,
    'prisco': 2,
    'prisk': 1,
    'prism': 2,
    'prismatic': 3,
    'prisms': 2,
    'prison': 2,
    'prisoned': 2,
    'prisoner': 3,
    'prisoners': 3,
    'prisons': 2,
    'prissie': 2,
    'prissy': 2,
    'pristine': 2,
    'pritchard': 2,
    'pritchett': 2,
    'prithvi': 2,
    'pritt': 1,
    'pritts': 1,
    'pritz': 1,
    'pritzker': 2,
    'pritzkers': 2,
    'pritzl': 2,
    'privacies': 3,
    'privacy': 3,
    'private': 2,
    'privately': 3,
    'privates': 2,
    'privation': 3,
    'privations': 3,
    'privatisation': 5,
    'privatization': 5,
    'privatizations': 5,
    'privatize': 3,
    'privatized': 3,
    'privatizing': 4,
    'privett': 2,
    'privette': 2,
    'privilege': 2,
    'privileged': 2,
    'privileges': 3,
    'privitera': 4,
    'privott': 2,
    'privy': 2,
    'prix': 1,
    'priyam': 2,
    'prizant': 2,
    'prize': 1,
    'prized': 1,
    'prizes': 2,
    'prizm': 2,
    'pro': 1,
    'proactive': 3,
    'proactively': 4,
    'prob': 1,
    'proba': 2,
    'probabilities': 5,
    'probability': 5,
    'probable': 3,
    'probably': 3,
    'probasco': 3,
    'probate': 2,
    'probation': 3,
    'probationary': 5,
    'probationer': 4,
    'probationers': 4,
    'probative': 3,
    'probe': 1,
    'probed': 1,
    'probenecid': 4,
    'probert': 2,
    'probes': 1,
    'probing': 2,
    'probity': 3,
    'problem': 2,
    'problematic': 4,
    'problematical': 5,
    'problems': 2,
    'proboscis': 3,
    'probst': 1,
    'probursa': 3,
    'probus': 2,
    'procaccini': 4,
    'procaine': 2,
    'procardia': 4,
    'procassini': 4,
    'procedural': 4,
    'procedurally': 5,
    'procedure': 3,
    'procedures': 3,
    'proceed': 2,
    'proceeded': 3,
    'proceeding': 3,
    'proceedings': 3,
    'proceeds': 2,
    'procell': 2,
    'proceso': 3,
    'process': 2,
    'processed': 2,
    'processes': 3,
    'processing': 3,
    'procession': 3,
    'processional': 4,
    'processions': 3,
    'processor': 3,
    'processors': 3,
    'proch': 1,
    'prochaska': 3,
    'prochazka': 3,
    'prochnow': 2,
    'procida': 3,
    'prock': 1,
    'prockter': 2,
    'proclaim': 2,
    'proclaimed': 2,
    'proclaiming': 3,
    'proclaims': 2,
    'proclamation': 4,
    'proclamations': 4,
    'proclivities': 4,
    'proclivity': 4,
    'proconsul': 3,
    'procopio': 4,
    'procordia': 4,
    'procrastinate': 4,
    'procrastinated': 5,
    'procrastinates': 4,
    'procrastinating': 5,
    'procrastination': 5,
    'procrastinator': 5,
    'procrastinators': 5,
    'procreate': 3,
    'procreated': 4,
    'procreates': 3,
    'procreating': 4,
    'procreation': 4,
    'procter': 2,
    'proctor': 2,
    'proctors': 2,
    'proctorsilex': 4,
    'procure': 2,
    'procured': 2,
    'procurement': 3,
    'procurements': 3,
    'procurer': 3,
    'procuring': 3,
    'procyte': 2,
    'prod': 1,
    'prodded': 2,
    'prodding': 2,
    'prodi': 2,
    'prodigal': 3,
    'prodigies': 3,
    'prodigious': 3,
    'prodigiously': 4,
    'prodigy': 3,
    'prods': 1,
    'produc': 2,
    'produce': 2,
    'produced': 2,
    'producer': 3,
    'producers': 3,
    'produces': 3,
    'producing': 3,
    'product': 2,
    'production': 3,
    'productions': 3,
    'productive': 3,
    'productively': 4,
    'productivity': 5,
    'products': 2,
    'prody': 2,
    'proehl': 1,
    'profanation': 4,
    'profane': 2,
    'profanity': 4,
    'profess': 2,
    'professed': 2,
    'professes': 3,
    'professing': 3,
    'profession': 3,
    'professional': 4,
    'professionalism': 6,
    'professionalize': 5,
    'professionalized': 5,
    'professionally': 5,
    'professionals': 4,
    'professions': 3,
    'professor': 3,
    'professorial': 5,
    'professors': 3,
    'professorship': 4,
    'professorships': 4,
    'profeta': 3,
    'proffer': 2,
    'proffered': 2,
    'proffering': 3,
    'proffit': 2,
    'proffitt': 2,
    'proficiency': 4,
    'proficient': 3,
    'profile': 2,
    'profiled': 2,
    'profiles': 2,
    'profiling': 3,
    'profit': 2,
    'profitability': 6,
    'profitable': 4,
    'profitably': 4,
    'profited': 3,
    'profiteer': 3,
    'profiteering': 4,
    'profiteers': 3,
    'profiting': 3,
    'profits': 2,
    'profitt': 2,
    'profittaking': 4,
    'profligacy': 4,
    'profligate': 3,
    'profound': 2,
    'profoundly': 3,
    'profs': 1,
    'profuse': 2,
    'profusely': 3,
    'profusion': 3,
    'progenitor': 4,
    'progeny': 3,
    'progesterone': 4,
    'progestin': 3,
    'proglacial': 3,
    'prognoses': 3,
    'prognosis': 3,
    'prognosticate': 4,
    'prognosticated': 5,
    'prognosticater': 5,
    'prognosticates': 4,
    'prognosticating': 5,
    'prognostication': 5,
    'prognostications': 5,
    'prognosticative': 5,
    'program': 2,
    'programing': 3,
    'programmable': 4,
    'programmatic': 4,
    'programme': 2,
    'programmed': 2,
    'programmer': 3,
    'programmers': 3,
    'programming': 3,
    'programs': 2,
    'progress': 2,
    'progressed': 2,
    'progresses': 3,
    'progressing': 3,
    'progression': 3,
    'progressive': 3,
    'progressively': 4,
    'progressives': 3,
    'progressivity': 5,
    'prohaska': 3,
    'prohibit': 3,
    'prohibited': 4,
    'prohibiting': 4,
    'prohibition': 4,
    'prohibitions': 4,
    'prohibitive': 4,
    'prohibitively': 5,
    'prohibitory': 5,
    'prohibits': 3,
    'proia': 2,
    'proietti': 3,
    'project': 2,
    'projected': 3,
    'projectile': 3,
    'projectiles': 3,
    'projecting': 3,
    'projection': 3,
    'projections': 3,
    'projective': 3,
    'projector': 3,
    'projectors': 3,
    'projects': 2,
    'prokofiev': 3,
    'prokop': 2,
    'prolactin': 3,
    'proler': 2,
    'proletarian': 5,
    'proletariat': 5,
    'proleukin': 3,
    'proliferate': 4,
    'proliferated': 5,
    'proliferates': 4,
    'proliferating': 5,
    'proliferation': 5,
    'prolific': 3,
    'prolifically': 4,
    'prolinea': 4,
    'prolog': 2,
    'prologue': 2,
    'prolong': 2,
    'prolonged': 2,
    'prolonging': 3,
    'prolongs': 2,
    'prom': 1,
    'promenade': 3,
    'promenades': 3,
    'promethium': 4,
    'promilacidic': 5,
    'prominence': 3,
    'prominences': 4,
    'prominent': 3,
    'prominently': 4,
    'promiscuity': 5,
    'promiscuous': 3,
    'promise': 2,
    'promised': 2,
    'promises': 3,
    'promising': 3,
    'promissory': 4,
    'promo': 2,
    'promos': 2,
    'promote': 2,
    'promoted': 3,
    'promoter': 3,
    'promoters': 3,
    'promotes': 2,
    'promoting': 3,
    'promotion': 3,
    'promotional': 4,
    'promotions': 3,
    'prompt': 1,
    'prompted': 2,
    'prompting': 2,
    'promptly': 2,
    'prompts': 1,
    'proms': 1,
    'promster': 2,
    'promsters': 2,
    'promulgate': 3,
    'promulgated': 4,
    'promulgates': 3,
    'promulgating': 4,
    'promus': 2,
    'prone': 1,
    'prong': 1,
    'pronged': 1,
    'pronger': 2,
    'pronghorn': 2,
    'prongs': 1,
    'pronoun': 2,
    'pronounce': 2,
    'pronounced': 2,
    'pronouncement': 3,
    'pronouncements': 3,
    'pronounces': 3,
    'pronouncing': 3,
    'pronouns': 2,
    'pronovost': 3,
    'pronto': 2,
    'pronunciation': 5,
    'pronunciations': 5,
    'proof': 1,
    'proofed': 1,
    'proofing': 2,
    'proofread': 2,
    'proofreading': 3,
    'proofs': 1,
    'prop': 1,
    'propaganda': 4,
    'propagandist': 4,
    'propagandistic': 5,
    'propagandists': 4,
    'propagandize': 4,
    'propagate': 3,
    'propagated': 4,
    'propagating': 4,
    'propagation': 4,
    'propane': 2,
    'propel': 2,
    'propellant': 3,
    'propellants': 3,
    'propelled': 2,
    'propeller': 3,
    'propellers': 3,
    'propelling': 3,
    'propels': 2,
    'propensities': 4,
    'propensity': 4,
    'proper': 2,
    'properly': 3,
    'properties': 3,
    'property': 3,
    'propes': 1,
    'propfan': 2,
    'prophecies': 3,
    'prophecy': 3,
    'prophesied': 3,
    'prophesies': 3,
    'prophet': 2,
    'prophetess': 3,
    'prophetic': 3,
    'prophets': 2,
    'prophylactic': 4,
    'propionic': 4,
    'propitious': 3,
    'proponent': 3,
    'proponents': 3,
    'proportion': 3,
    'proportional': 4,
    'proportionality': 6,
    'proportionally': 5,
    'proportionate': 4,
    'proportionately': 5,
    'proportioned': 3,
    'proportions': 3,
    'proposal': 3,
    'proposals': 3,
    'propose': 2,
    'proposed': 2,
    'proposes': 3,
    'proposing': 3,
    'proposition': 4,
    'propositioned': 4,
    'propositions': 4,
    'propound': 2,
    'propounded': 3,
    'propp': 1,
    'propped': 1,
    'propper': 2,
    'propping': 2,
    'propps': 1,
    'proprietaries': 5,
    'proprietary': 5,
    'proprietor': 4,
    'proprietors': 4,
    'proprietorship': 5,
    'proprietorships': 5,
    'propriety': 4,
    'props': 1,
    'propst': 1,
    'propulsion': 3,
    'propylene': 3,
    'prorate': 2,
    'prorated': 3,
    'proration': 3,
    'prorok': 2,
    'pros': 1,
    'prosaic': 3,
    'proscar': 2,
    'prosch': 1,
    'proscia': 2,
    'proscribe': 2,
    'proscribed': 2,
    'proscribes': 2,
    'proscription': 3,
    'prose': 1,
    'prosecutable': 5,
    'prosecute': 3,
    'prosecuted': 4,
    'prosecutes': 3,
    'prosecuting': 4,
    'prosecution': 4,
    'prosecutions': 4,
    'prosecutor': 4,
    'prosecutorial': 6,
    'prosecutors': 4,
    'prosek': 2,
    'prosekey': 3,
    'proselytize': 4,
    'proselytized': 4,
    'proselytizing': 5,
    'proserpina': 4,
    'proserv': 2,
    'proshare': 2,
    'prosise': 2,
    'proskauer': 2,
    'proske': 1,
    'prosody': 3,
    'prosorba': 3,
    'prospect': 2,
    'prospecting': 3,
    'prospective': 3,
    'prospectively': 4,
    'prospector': 3,
    'prospectors': 3,
    'prospects': 2,
    'prospectus': 3,
    'prospectuses': 4,
    'prospekt': 2,
    'prosper': 2,
    'prospera': 3,
    'prospered': 2,
    'prosperi': 3,
    'prospering': 3,
    'prosperity': 4,
    'prosperous': 3,
    'prospers': 2,
    'pross': 1,
    'prosser': 2,
    'prost': 1,
    'prostaglandin': 4,
    'prostaglandins': 4,
    'prostate': 2,
    'prostatectomy': 5,
    'prostatic': 3,
    'prostheses': 3,
    'prosthesis': 3,
    'prosthetic': 3,
    'prosthetics': 3,
    'prostitute': 3,
    'prostitutes': 3,
    'prostitution': 4,
    'prostrate': 2,
    'prostration': 3,
    'protagonist': 4,
    'protagonists': 4,
    'protean': 3,
    'protease': 3,
    'protect': 2,
    'protected': 3,
    'protectee': 3,
    'protectees': 3,
    'protecting': 3,
    'protection': 3,
    'protectionism': 5,
    'protectionist': 4,
    'protectionists': 4,
    'protections': 3,
    'protective': 3,
    'protectively': 4,
    'protector': 3,
    'protectorate': 4,
    'protectors': 3,
    'protects': 2,
    'protege': 3,
    'proteges': 3,
    'protein': 2,
    'proteinaceous': 4,
    'proteins': 2,
    'protest': 2,
    'protestant': 3,
    'protestantism': 5,
    'protestants': 3,
    'protestation': 4,
    'protestations': 4,
    'protested': 3,
    'protester': 3,
    'protesters': 3,
    'protesting': 3,
    'protestors': 3,
    'protests': 2,
    'prothallus': 3,
    'prothero': 3,
    'prothorax': 3,
    'prothro': 2,
    'protigal': 3,
    'protium': 3,
    'proto': 2,
    'protocol': 3,
    'protocols': 3,
    'protohistory': 5,
    'proton': 2,
    'protons': 2,
    'prototype': 3,
    'prototypes': 3,
    'prototypical': 5,
    'protozoa': 4,
    'protozoan': 4,
    'protozoans': 4,
    'protract': 2,
    'protracted': 3,
    'protropin': 3,
    'protrude': 2,
    'protruding': 3,
    'protuberance': 4,
    'protuberances': 5,
    'protz': 1,
    'protzman': 2,
    'proud': 1,
    'prouder': 2,
    'proudest': 2,
    'proudfit': 2,
    'proudfoot': 2,
    'proudly': 2,
    'prough': 1,
    'proulx': 1,
    'prouse': 1,
    'proust': 1,
    'prout': 1,
    'prouty': 2,
    'provable': 3,
    'provance': 2,
    'provant': 2,
    'prove': 1,
    'proved': 1,
    'proven': 2,
    'provenance': 3,
    'provence': 2,
    'provencher': 3,
    'provencio': 4,
    'provenience': 4,
    'proventus': 3,
    'provenza': 3,
    'provenzano': 4,
    'provera': 3,
    'proverb': 2,
    'proverbial': 4,
    'proverbs': 2,
    'proves': 1,
    'provide': 2,
    'provided': 3,
    'providence': 3,
    'provideniya': 5,
    'provident': 3,
    'providential': 4,
    'provider': 3,
    'providers': 3,
    'provides': 2,
    'providian': 4,
    'providing': 3,
    'provigo': 3,
    'province': 2,
    'provinces': 3,
    'provincetown': 3,
    'provincial': 3,
    'provincialism': 5,
    'provincially': 4,
    'provine': 3,
    'proving': 2,
    'provision': 3,
    'provisional': 4,
    'provisionally': 5,
    'provisioning': 4,
    'provisions': 3,
    'proviso': 3,
    'provisos': 3,
    'provo': 2,
    'provocation': 4,
    'provocations': 4,
    'provocative': 4,
    'provocatively': 5,
    'provoke': 2,
    'provoked': 2,
    'provokes': 2,
    'provoking': 3,
    'provolone': 3,
    'provost': 2,
    'provosts': 2,
    'provote': 2,
    'provus': 2,
    'prow': 1,
    'prowant': 2,
    'prowell': 2,
    'prowess': 2,
    'prowl': 1,
    'prowler': 2,
    'prowling': 2,
    'prows': 1,
    'prowse': 1,
    'proxies': 2,
    'proximal': 3,
    'proximate': 3,
    'proximity': 4,
    'proxmire': 2,
    'proxy': 2,
    'prozac': 2,
    'pru': 1,
    'prucapital': 4,
    'prucha': 2,
    'prude': 1,
    'pruden': 2,
    'prudence': 2,
    'prudency': 3,
    'prudent': 2,
    'prudente': 2,
    'prudential': 3,
    'prudentialbache': 5,
    'prudently': 3,
    'prudentrust': 3,
    'prudery': 3,
    'prudhoe': 2,
    'prudhomme': 2,
    'prudish': 2,
    'prudit': 2,
    'prue': 1,
    'pruer': 2,
    'pruess': 1,
    'prueter': 2,
    'pruett': 1,
    'pruette': 2,
    'prugh': 1,
    'pruiett': 2,
    'pruitt': 2,
    'prune': 1,
    'pruneau': 2,
    'pruned': 1,
    'pruneda': 3,
    'prunedale': 2,
    'prunella': 3,
    'pruner': 2,
    'prunes': 1,
    'prunier': 3,
    'pruning': 2,
    'prunskiene': 2,
    'prunty': 2,
    'prurient': 3,
    'prus': 1,
    'prusak': 2,
    'prusha': 2,
    'prusinski': 3,
    'pruski': 2,
    'pruss': 1,
    'prussak': 2,
    'prussia': 2,
    'prussian': 2,
    'prust': 1,
    'prutzman': 2,
    'pruyn': 1,
    'pry': 1,
    'pryce': 1,
    'pryde': 1,
    'pryer': 2,
    'prying': 2,
    'prynne': 1,
    'pryor': 2,
    'przybocki': 3,
    'przybyl': 3,
    'przybyla': 4,
    'przybylski': 4,
    'przybysz': 3,
    'przywara': 4,
    'psalm': 1,
    'psalms': 1,
    'psalter': 2,
    'psalters': 2,
    'psarouthakis': 4,
    'psencik': 2,
    'pseudo': 2,
    'pseudomonas': 4,
    'pseudonym': 3,
    'pseudonyms': 3,
    'pseudopodial': 5,
    'pseudoscience': 4,
    'pseudoscientific': 6,
    'pshew': 1,
    'psi': 1,
    'psilocybin': 4,
    'psoriasis': 4,
    'psych': 1,
    'psyche': 2,
    'psyched': 1,
    'psychedelic': 4,
    'psyches': 2,
    'psychiatric': 4,
    'psychiatrist': 4,
    'psychiatrists': 4,
    'psychiatry': 4,
    'psychic': 2,
    'psychical': 3,
    'psychics': 2,
    'psycho': 2,
    'psychoanalysis': 6,
    'psychoanalyst': 5,
    'psychoanalytic': 6,
    'psychobabble': 4,
    'psychodynamic': 5,
    'psychogenic': 4,
    'psychokinesis': 5,
    'psychological': 5,
    'psychologically': 5,
    'psychologist': 4,
    'psychologists': 4,
    'psychology': 4,
    'psychopath': 3,
    'psychopathic': 4,
    'psychopaths': 3,
    'psychopathy': 4,
    'psychosis': 3,
    'psychosomatic': 5,
    'psychotherapeutic': 6,
    'psychotherapist': 5,
    'psychotherapists': 5,
    'psychotherapy': 5,
    'psychotic': 3,
    'psyllium': 3,
    'ptacek': 2,
    'ptak': 1,
    'ptolemaic': 4,
    'ptolemy': 3,
    'ptomaine': 2,
    'ptomaines': 2,
    'ptovsky': 3,
    'pty': 1,
    'ptyon': 2,
    'pu': 1,
    'puat': 2,
    'pub': 1,
    'pubco': 2,
    'puberty': 3,
    'pubic': 2,
    'public': 2,
    'publically': 3,
    'publication': 4,
    'publications': 4,
    'publicis': 3,
    'publicist': 3,
    'publicists': 3,
    'publicity': 4,
    'publicize': 3,
    'publicized': 3,
    'publicizing': 4,
    'publicker': 3,
    'publicly': 3,
    'publics': 2,
    'publish': 2,
    'published': 2,
    'publisher': 3,
    'publishers': 3,
    'publishes': 3,
    'publishing': 3,
    'publishings': 3,
    'publitech': 3,
    'publix': 2,
    'pubs': 1,
    'pucci': 2,
    'pucciarelli': 4,
    'puccinelli': 4,
    'puccini': 3,
    'puccio': 3,
    'puchalski': 3,
    'puche': 1,
    'pucillo': 3,
    'puck': 1,
    'pucker': 2,
    'puckett': 2,
    'puckish': 2,
    'pudding': 2,
    'puddings': 2,
    'puddle': 2,
    'puddles': 2,
    'puder': 2,
    'pudgie': 2,
    'pudgies': 2,
    'pudgy': 2,
    'pudlo': 2,
    'pudong': 2,
    'pudwill': 2,
    'puebla': 2,
    'pueblo': 2,
    'puente': 1,
    'puentes': 2,
    'puerile': 2,
    'puerto': 2,
    'puett': 1,
    'puetz': 1,
    'pufahl': 2,
    'puff': 1,
    'puffed': 1,
    'puffenbarger': 4,
    'puffer': 2,
    'puffery': 3,
    'puffin': 2,
    'puffing': 2,
    'puffins': 2,
    'puffs': 1,
    'puffy': 2,
    'pug': 1,
    'puga': 2,
    'puget': 2,
    'pugh': 1,
    'pughs': 1,
    'pugilism': 4,
    'pugilist': 3,
    'pugilistic': 4,
    'puglia': 3,
    'pugliese': 4,
    'puglisi': 3,
    'pugmire': 2,
    'pugnacious': 3,
    'pugsley': 2,
    'pugwash': 2,
    'puhl': 1,
    'puig': 1,
    'puipoe': 3,
    'pujol': 2,
    'puke': 1,
    'pukes': 1,
    'pulaski': 3,
    'pulcini': 3,
    'puleo': 3,
    'pulford': 2,
    'pulice': 2,
    'pulido': 3,
    'pulitzer': 3,
    'pulkrabek': 3,
    'pull': 1,
    'pullam': 2,
    'pullan': 2,
    'pullano': 3,
    'pullara': 3,
    'pullback': 2,
    'pullbacks': 2,
    'pulled': 1,
    'pullen': 2,
    'puller': 2,
    'pulley': 2,
    'pulleys': 2,
    'pulliam': 3,
    'pullin': 2,
    'pulling': 2,
    'pullins': 2,
    'pullman': 2,
    'pullout': 2,
    'pullouts': 2,
    'pulls': 1,
    'pullum': 2,
    'pulmazyme': 3,
    'pulmonary': 4,
    'pulmozyme': 3,
    'pulos': 2,
    'pulp': 1,
    'pulping': 2,
    'pulpit': 2,
    'pulpits': 2,
    'pulpwood': 2,
    'puls': 1,
    'pulsar': 2,
    'pulsating': 3,
    'pulse': 1,
    'pulses': 2,
    'pulsifer': 3,
    'pulsing': 2,
    'pulsipher': 3,
    'pulte': 2,
    'pultz': 1,
    'pulver': 2,
    'pulverize': 3,
    'pulverized': 3,
    'pulvermacher': 4,
    'puma': 2,
    'pumice': 2,
    'pumilia': 4,
    'puming': 2,
    'pummel': 2,
    'pummeled': 2,
    'pummeling': 3,
    'pummill': 2,
    'pump': 1,
    'pumped': 1,
    'pumpernickel': 4,
    'pumphrey': 2,
    'pumping': 2,
    'pumpkin': 2,
    'pumpkins': 2,
    'pumps': 1,
    'pumsie': 2,
    'pun': 1,
    'punch': 1,
    'punched': 1,
    'punches': 2,
    'punching': 2,
    'punchline': 2,
    'punchy': 2,
    'punctate': 2,
    'punctilious': 4,
    'punctual': 3,
    'punctuality': 5,
    'punctuate': 3,
    'punctuated': 4,
    'punctuating': 4,
    'punctuation': 4,
    'puncture': 2,
    'punctured': 2,
    'punctures': 2,
    'puncturing': 3,
    'pundit': 2,
    'punditry': 3,
    'pundits': 2,
    'pundt': 1,
    'puneet': 2,
    'pung': 1,
    'pungent': 2,
    'pungently': 3,
    'punic': 2,
    'punish': 2,
    'punishable': 4,
    'punished': 2,
    'punisher': 3,
    'punishes': 3,
    'punishing': 3,
    'punishingly': 4,
    'punishment': 3,
    'punishments': 3,
    'punit': 2,
    'punitive': 3,
    'punjab': 2,
    'punk': 1,
    'punkin': 2,
    'punks': 1,
    'punky': 2,
    'puns': 1,
    'punsalan': 3,
    'punt': 1,
    'punta': 2,
    'punter': 2,
    'punto': 2,
    'punts': 1,
    'punxatauney': 4,
    'puny': 2,
    'puopolo': 3,
    'pup': 1,
    'pupa': 2,
    'pupil': 2,
    'pupils': 2,
    'pupo': 2,
    'puppet': 2,
    'puppeteer': 3,
    'puppeteers': 3,
    'puppetry': 3,
    'puppets': 2,
    'puppies': 2,
    'puppy': 2,
    'pups': 1,
    'pur': 1,
    'pura': 2,
    'purcell': 2,
    'purchase': 2,
    'purchased': 2,
    'purchaser': 3,
    'purchasers': 3,
    'purchases': 3,
    'purchasing': 3,
    'purdham': 2,
    'purdie': 2,
    'purdin': 2,
    'purdom': 2,
    'purdon': 2,
    'purdue': 2,
    'purdum': 2,
    'purdy': 2,
    'pure': 1,
    'purebred': 2,
    'purebreds': 2,
    'puree': 2,
    'purely': 2,
    'purer': 2,
    'purest': 2,
    'purex': 2,
    'purgason': 3,
    'purgatory': 4,
    'purge': 1,
    'purged': 1,
    'purges': 2,
    'purging': 2,
    'puri': 2,
    'puricelli': 4,
    'purification': 5,
    'purified': 3,
    'purifier': 4,
    'purifiers': 4,
    'purifoy': 3,
    'purify': 3,
    'purifying': 4,
    'purile': 2,
    'purim': 2,
    'purina': 3,
    'purington': 3,
    'purinton': 3,
    'puris': 2,
    'purist': 2,
    'puristic': 3,
    'purists': 2,
    'puritan': 3,
    'puritanical': 5,
    'puritanism': 5,
    'puritanisms': 5,
    'puritans': 3,
    'purity': 3,
    'purk': 1,
    'purkey': 2,
    'purloin': 2,
    'purloined': 2,
    'purnell': 2,
    'purolator': 4,
    'purpa': 2,
    'purple': 2,
    'purples': 2,
    'purplish': 2,
    'purport': 2,
    'purported': 3,
    'purportedly': 4,
    'purporting': 3,
    'purports': 2,
    'purpose': 2,
    'purposeful': 3,
    'purposefully': 4,
    'purposeless': 3,
    'purposely': 3,
    'purposes': 3,
    'purr': 1,
    'purring': 2,
    'purrington': 3,
    'purrs': 1,
    'purse': 1,
    'pursed': 1,
    'pursel': 2,
    'pursell': 2,
    'purser': 2,
    'purses': 2,
    'pursifull': 3,
    'pursley': 2,
    'pursuant': 3,
    'pursue': 2,
    'pursued': 2,
    'pursuer': 3,
    'pursuers': 3,
    'pursues': 2,
    'pursueth': 3,
    'pursuing': 3,
    'pursuit': 2,
    'pursuits': 2,
    'purt': 1,
    'purtee': 2,
    'purtell': 2,
    'purtle': 2,
    'purulent': 3,
    'purves': 1,
    'purvey': 2,
    'purveyed': 2,
    'purveying': 3,
    'purveyor': 3,
    'purveyors': 3,
    'purveys': 2,
    'purviance': 3,
    'purview': 2,
    'purvin': 2,
    'purvis': 2,
    'puryear': 2,
    'pus': 1,
    'pusan': 2,
    'pusant': 2,
    'pusateri': 4,
    'pusch': 1,
    'pusey': 2,
    'push': 1,
    'pushed': 1,
    'pusher': 2,
    'pushers': 2,
    'pushes': 2,
    'pushing': 2,
    'pushkin': 2,
    'pushover': 3,
    'pushup': 2,
    'pushups': 2,
    'pushy': 2,
    'pusillanimous': 5,
    'puskar': 2,
    'puskarich': 3,
    'puskas': 2,
    'puss': 1,
    'pusses': 2,
    'pussies': 2,
    'pussy': 2,
    'pussycat': 3,
    'pussycats': 3,
    'pussyfoot': 3,
    'pussyfooted': 4,
    'pussyfooting': 4,
    'pussyfoots': 3,
    'pustejovsky': 4,
    'put': 1,
    'putative': 3,
    'putco': 2,
    'putdown': 2,
    'putdowns': 2,
    'pute': 1,
    'puterbaugh': 3,
    'putes': 1,
    'puth': 1,
    'puthoff': 2,
    'putman': 2,
    'putnam': 2,
    'putney': 2,
    'putrid': 2,
    'puts': 1,
    'putsch': 1,
    'putt': 1,
    'puttable': 3,
    'putted': 2,
    'puttenbay': 3,
    'putter': 2,
    'putterer': 3,
    'puttering': 3,
    'putterman': 3,
    'putters': 2,
    'putting': 2,
    'puttnam': 2,
    'putts': 1,
    'putty': 2,
    'putz': 1,
    'putzier': 3,
    'puusepp': 2,
    'puza': 2,
    'puzio': 3,
    'puzo': 2,
    'puzzle': 2,
    'puzzled': 2,
    'puzzlemaster': 4,
    'puzzlement': 3,
    'puzzler': 2,
    'puzzles': 2,
    'puzzling': 3,
    'puzzo': 2,
    'pyatt': 2,
    'pyburn': 2,
    'pye': 1,
    'pyeatt': 2,
    'pygmalion': 3,
    'pygmies': 2,
    'pygmy': 2,
    'pyhrric': 2,
    'pyka': 2,
    'pyke': 1,
    'pyland': 2,
    'pylant': 2,
    'pyle': 1,
    'pyles': 1,
    'pylon': 2,
    'pylons': 2,
    'pylori': 3,
    'pylos': 2,
    'pymm': 1,
    'pyne': 1,
    'pynes': 1,
    'pyongyang': 2,
    'pyper': 2,
    'pyramid': 3,
    'pyramidal': 4,
    'pyramids': 3,
    'pyre': 2,
    'pyrena': 3,
    'pyrenees': 3,
    'pyres': 2,
    'pyrite': 2,
    'pyro': 2,
    'pyron': 2,
    'pyrotechnic': 4,
    'pyrotechnics': 4,
    'pyroxene': 3,
    'pyrrhic': 2,
    'pysher': 2,
    'pytel': 2,
    'pythagorean': 5,
    'pythagorus': 4,
    'pythia': 3,
    'python': 2,
    'pyxis': 2,
    'q': 1,
    'qana': 2,
    'qantas': 2,
    'qasr': 2,
    'qatar': 2,
    'qawi': 2,
    'qi': 1,
    'qian': 2,
    'qiao': 2,
    'qiaotou': 2,
    'qichen': 2,
    'qin': 1,
    'qing': 1,
    'qingdao': 2,
    'qingming': 2,
    'qintex': 2,
    'qiryat': 2,
    'qmax': 2,
    'qom': 1,
    'qu': 1,
    'qua': 1,
    'quach': 1,
    'quack': 1,
    'quackenbush': 3,
    'quackery': 3,
    'quacks': 1,
    'quad': 1,
    'quade': 1,
    'quadra': 2,
    'quadrant': 2,
    'quadrennial': 4,
    'quadrex': 2,
    'quadriceps': 3,
    'quadriplegic': 4,
    'quadruple': 3,
    'quadrupled': 3,
    'quadrupling': 4,
    'quads': 1,
    'quaglia': 3,
    'quagmire': 3,
    'quai': 1,
    'quaid': 1,
    'quail': 1,
    'quails': 1,
    'quain': 1,
    'quaint': 1,
    'quaintance': 2,
    'quaintly': 2,
    'quake': 1,
    'quakenbush': 3,
    'quaker': 2,
    'quakers': 2,
    'quakes': 1,
    'quaking': 2,
    'qual': 1,
    'qualcast': 2,
    'qualcomm': 2,
    'quale': 1,
    'qualex': 2,
    'qualey': 2,
    'qualification': 5,
    'qualifications': 5,
    'qualified': 3,
    'qualifier': 4,
    'qualifiers': 4,
    'qualifies': 3,
    'qualify': 3,
    'qualifying': 4,
    'qualitative': 4,
    'qualitatively': 5,
    'qualities': 3,
    'quality': 3,
    'qualley': 2,
    'qualls': 1,
    'qualms': 1,
    'quam': 1,
    'quamme': 1,
    'quan': 1,
    'quandaries': 3,
    'quandary': 3,
    'quandt': 1,
    'quanex': 2,
    'quang': 1,
    'quant': 1,
    'quantico': 3,
    'quantifiable': 5,
    'quantification': 5,
    'quantified': 3,
    'quantify': 3,
    'quantifying': 4,
    'quantitative': 4,
    'quantitatively': 5,
    'quantities': 3,
    'quantity': 3,
    'quantum': 2,
    'quaquil': 2,
    'quaranta': 3,
    'quarante': 3,
    'quarantine': 3,
    'quarantined': 3,
    'quarantines': 3,
    'quarantining': 4,
    'quark': 1,
    'quarks': 1,
    'quarles': 1,
    'quarnstrom': 2,
    'quarre': 1,
    'quarrel': 2,
    'quarreled': 2,
    'quarreling': 3,
    'quarrels': 2,
    'quarrelsome': 3,
    'quarries': 2,
    'quarry': 2,
    'quarrying': 3,
    'quart': 1,
    'quartararo': 4,
    'quarter': 2,
    'quarterback': 3,
    'quarterbacking': 4,
    'quarterbacks': 3,
    'quarterdeck': 3,
    'quarterly': 3,
    'quarterman': 3,
    'quartermaster': 4,
    'quarters': 2,
    'quartet': 2,
    'quartets': 2,
    'quarteurlanc': 3,
    'quartile': 2,
    'quarto': 2,
    'quarts': 1,
    'quartz': 1,
    'quasar': 2,
    'quash': 1,
    'quashed': 1,
    'quashing': 2,
    'quasi': 2,
    'quasimodo': 4,
    'quassia': 3,
    'quast': 1,
    'quaternary': 4,
    'quattlebaum': 3,
    'quattro': 2,
    'quattrocchi': 3,
    'quattrochi': 3,
    'quattrone': 3,
    'quave': 1,
    'quaver': 2,
    'quavered': 2,
    'quavering': 3,
    'quavers': 2,
    'quay': 1,
    'quaye': 1,
    'quayle': 1,
    'quayles': 1,
    'quazulu': 3,
    'qubilah': 3,
    'quddus': 2,
    'que': 1,
    'quealy': 2,
    'queasiness': 3,
    'queasy': 2,
    'queau': 1,
    'quebec': 2,
    'quebecker': 3,
    'quebeckers': 3,
    'quebecois': 3,
    'quebecoise': 3,
    'quebecor': 3,
    'quebedeaux': 3,
    'quechee': 2,
    'queen': 1,
    'queena': 2,
    'queenan': 2,
    'queener': 2,
    'queenfish': 2,
    'queenie': 2,
    'queens': 1,
    'queensberry': 3,
    'queensland': 2,
    'queensway': 2,
    'queer': 1,
    'queerer': 2,
    'queers': 1,
    'quek': 1,
    'quel': 1,
    'quell': 1,
    'quelled': 1,
    'quellette': 2,
    'quelling': 2,
    'quello': 2,
    'quemoy': 2,
    'quenby': 2,
    'quench': 1,
    'quencher': 2,
    'quenchers': 2,
    'quenches': 2,
    'quenching': 2,
    'quennel': 2,
    'quenneville': 3,
    'quentin': 2,
    'quenzer': 2,
    'querida': 3,
    'queried': 2,
    'queries': 2,
    'querns': 1,
    'querry': 2,
    'querulous': 3,
    'query': 2,
    'ques': 1,
    'quesada': 3,
    'quesenberry': 4,
    'quesinberry': 4,
    'quesnel': 2,
    'quesnell': 2,
    'quest': 1,
    'questar': 2,
    'questech': 2,
    'question': 2,
    'questionable': 4,
    'questioned': 2,
    'questioner': 3,
    'questioners': 3,
    'questioning': 3,
    'questionings': 3,
    'questionnaire': 3,
    'questionnaires': 3,
    'questions': 2,
    'questran': 2,
    'questrom': 2,
    'quests': 1,
    'queue': 1,
    'queues': 1,
    'queuing': 2,
    'quevedo': 3,
    'quezada': 3,
    'qui': 1,
    'quibble': 2,
    'quibbles': 2,
    'quibbling': 3,
    'quiche': 1,
    'quick': 1,
    'quickel': 2,
    'quicken': 2,
    'quickened': 2,
    'quickening': 3,
    'quickens': 2,
    'quicker': 2,
    'quickest': 2,
    'quickie': 2,
    'quickly': 2,
    'quickness': 2,
    'quicksand': 2,
    'quicksands': 2,
    'quicksilver': 3,
    'quicktime': 2,
    'quid': 1,
    'quiescent': 3,
    'quiet': 2,
    'quieted': 3,
    'quieter': 3,
    'quietest': 3,
    'quieting': 3,
    'quietist': 3,
    'quietly': 3,
    'quietness': 3,
    'quiets': 2,
    'quiett': 1,
    'quievoni': 3,
    'quigg': 1,
    'quiggle': 2,
    'quigley': 2,
    'quijada': 3,
    'quijano': 3,
    'quik': 1,
    'quiksilver': 3,
    'quilali': 3,
    'quiles': 2,
    'quilici': 3,
    'quill': 1,
    'quillan': 2,
    'quillen': 2,
    'quillian': 2,
    'quillin': 2,
    'quilling': 2,
    'quillman': 2,
    'quillon': 2,
    'quilmes': 1,
    'quilt': 1,
    'quilted': 2,
    'quilter': 2,
    'quilters': 2,
    'quilting': 2,
    'quilts': 1,
    'quilty': 2,
    'quimby': 2,
    'quin': 1,
    'quina': 2,
    'quinby': 2,
    'quince': 1,
    'quincey': 2,
    'quincy': 2,
    'quindlen': 2,
    'quine': 1,
    'quinine': 2,
    'quinlan': 2,
    'quinley': 2,
    'quinlin': 2,
    'quinlivan': 3,
    'quinn': 1,
    'quinnell': 2,
    'quinney': 2,
    'quinobequin': 4,
    'quinoco': 3,
    'quinon': 2,
    'quinones': 2,
    'quinonez': 3,
    'quint': 1,
    'quinta': 2,
    'quintal': 2,
    'quintana': 3,
    'quintanar': 3,
    'quintanilla': 4,
    'quintela': 3,
    'quinter': 2,
    'quintero': 3,
    'quinteros': 3,
    'quintessence': 3,
    'quintessential': 4,
    'quintessentially': 5,
    'quintet': 2,
    'quintile': 2,
    'quintin': 2,
    'quintina': 3,
    'quinto': 2,
    'quinton': 2,
    'quints': 1,
    'quintuple': 3,
    'quintupled': 3,
    'quintuplet': 3,
    'quintuplets': 3,
    'quip': 1,
    'quipp': 1,
    'quipped': 1,
    'quips': 1,
    'quiram': 2,
    'quire': 1,
    'quirin': 2,
    'quiring': 2,
    'quirion': 3,
    'quirk': 1,
    'quirke': 1,
    'quirks': 1,
    'quirky': 2,
    'quiroga': 3,
    'quiros': 2,
    'quiroz': 2,
    'quiry': 2,
    'quisenberry': 4,
    'quisling': 2,
    'quist': 1,
    'quit': 1,
    'quite': 1,
    'quito': 2,
    'quits': 1,
    'quitter': 2,
    'quitters': 2,
    'quitting': 2,
    'quiver': 2,
    'quivering': 3,
    'quixote': 3,
    'quixotic': 3,
    'quiz': 1,
    'quizard': 2,
    'quizzed': 1,
    'quizzes': 2,
    'quizzical': 3,
    'quizzing': 2,
    'quo': 1,
    'quon': 1,
    'quorum': 2,
    'quorums': 2,
    'quota': 2,
    'quotable': 3,
    'quotas': 2,
    'quotation': 3,
    'quotations': 3,
    'quote': 1,
    'quoted': 2,
    'quotes': 1,
    'quotient': 2,
    'quoting': 2,
    'quotron': 2,
    'qureshey': 3,
    'qureshi': 3,
    'r': 1,
    'ra': 1,
    'raab': 1,
    'raabe': 1,
    'raad': 1,
    'raap': 1,
    'raasch': 1,
    'raatz': 1,
    'rab': 1,
    'rabago': 3,
    'rabalais': 3,
    'rabb': 1,
    'rabbani': 3,
    'rabbi': 2,
    'rabbinical': 4,
    'rabbis': 2,
    'rabbit': 2,
    'rabbitears': 3,
    'rabbitlike': 3,
    'rabbits': 2,
    'rabbitt': 2,
    'rabble': 2,
    'rabe': 1,
    'rabel': 2,
    'raben': 2,
    'rabenold': 3,
    'raber': 2,
    'rabes': 2,
    'rabey': 2,
    'rabi': 2,
    'rabid': 2,
    'rabideau': 3,
    'rabies': 2,
    'rabin': 2,
    'rabine': 2,
    'rabiner': 3,
    'rabinovich': 4,
    'rabinovitz': 4,
    'rabinowitz': 4,
    'rabkin': 2,
    'rabobank': 3,
    'raboin': 2,
    'rabold': 2,
    'rabon': 2,
    'raborn': 2,
    'rabourn': 2,
    'raboy': 2,
    'rabuck': 2,
    'rabuka': 3,
    'rabun': 2,
    'raburn': 2,
    'raby': 2,
    'racal': 2,
    'racamier': 3,
    'racanelli': 4,
    'racca': 2,
    'raccoon': 2,
    'raccoons': 2,
    'race': 1,
    'raced': 1,
    'racehorse': 2,
    'racehorses': 3,
    'racemes': 2,
    'racer': 2,
    'racers': 2,
    'races': 2,
    'racetrack': 2,
    'racetracks': 2,
    'racette': 2,
    'raceway': 2,
    'racey': 2,
    'rach': 1,
    'rachad': 2,
    'rachael': 2,
    'rachal': 2,
    'rachel': 2,
    'rachelle': 2,
    'rachels': 2,
    'rachelvolt': 3,
    'rachford': 2,
    'rachi': 2,
    'rachlin': 2,
    'rachmaninoff': 4,
    'racial': 2,
    'racialism': 4,
    'racially': 3,
    'racicot': 3,
    'racier': 3,
    'raciest': 3,
    'racine': 2,
    'raciness': 3,
    'racing': 2,
    'racioppi': 3,
    'racism': 3,
    'racist': 2,
    'racists': 2,
    'rack': 1,
    'racked': 1,
    'racker': 2,
    'rackers': 2,
    'racket': 2,
    'racketeer': 3,
    'racketeering': 4,
    'racketeers': 3,
    'rackets': 2,
    'racking': 2,
    'rackley': 2,
    'rackliff': 2,
    'rackliffe': 2,
    'rackmil': 2,
    'rackow': 2,
    'racks': 1,
    'raconteur': 3,
    'racquet': 2,
    'racquetball': 3,
    'racquets': 2,
    'racy': 2,
    'racz': 1,
    'raczka': 2,
    'raczkowski': 3,
    'raczynski': 3,
    'rad': 1,
    'rada': 2,
    'radabaugh': 3,
    'radakovich': 4,
    'radant': 2,
    'radar': 2,
    'radars': 2,
    'radatz': 2,
    'radavan': 3,
    'radbert': 2,
    'radborne': 2,
    'radbourn': 2,
    'radbourne': 2,
    'radburn': 2,
    'radcliff': 2,
    'radcliffe': 2,
    'raddatz': 2,
    'radde': 1,
    'radder': 2,
    'radditz': 2,
    'radebaugh': 3,
    'radecki': 3,
    'radel': 2,
    'radell': 2,
    'radella': 3,
    'rademacher': 4,
    'rademaker': 3,
    'raden': 2,
    'rader': 2,
    'radermacher': 4,
    'radford': 2,
    'radha': 2,
    'radi': 2,
    'radial': 3,
    'radially': 4,
    'radials': 3,
    'radiance': 3,
    'radiant': 3,
    'radiate': 3,
    'radiated': 4,
    'radiates': 3,
    'radiating': 4,
    'radiation': 4,
    'radiator': 4,
    'radiators': 4,
    'radical': 3,
    'radicalism': 5,
    'radicalization': 6,
    'radicalize': 4,
    'radicalized': 4,
    'radically': 3,
    'radicals': 3,
    'radice': 2,
    'radich': 2,
    'radick': 2,
    'radin': 2,
    'radinka': 3,
    'radio': 3,
    'radioactive': 5,
    'radioactivity': 7,
    'radioed': 3,
    'radiography': 5,
    'radiological': 6,
    'radiologist': 5,
    'radiologists': 5,
    'radiology': 5,
    'radioman': 4,
    'radios': 3,
    'radish': 2,
    'radishes': 3,
    'radisson': 3,
    'raditz': 2,
    'radium': 3,
    'radius': 3,
    'radke': 2,
    'radko': 2,
    'radle': 2,
    'radler': 3,
    'radley': 2,
    'radliff': 2,
    'radloff': 2,
    'radman': 2,
    'radmilla': 3,
    'radmund': 2,
    'radner': 2,
    'radney': 2,
    'radnor': 2,
    'rado': 2,
    'radolf': 2,
    'radomski': 3,
    'radon': 2,
    'rados': 2,
    'radosevich': 4,
    'radosh': 2,
    'radovan': 3,
    'radowski': 3,
    'radtke': 2,
    'radu': 2,
    'radwan': 2,
    'rady': 2,
    'radziewicz': 3,
    'radzik': 2,
    'rae': 1,
    'raeburn': 2,
    'raeder': 2,
    'raedler': 2,
    'rael': 1,
    'raether': 2,
    'raetz': 1,
    'raf': 1,
    'rafael': 3,
    'rafale': 2,
    'rafalski': 3,
    'rafe': 1,
    'rafelghem': 3,
    'raff': 1,
    'raffa': 2,
    'raffaele': 4,
    'raffaelli': 4,
    'raffel': 2,
    'raffensperger': 4,
    'rafferty': 3,
    'raffetto': 3,
    'raffety': 2,
    'raffi': 2,
    'raffield': 2,
    'raffish': 2,
    'raffle': 2,
    'raffles': 2,
    'raffo': 2,
    'rafi': 2,
    'rafik': 2,
    'rafsanjani': 4,
    'raft': 1,
    'rafted': 2,
    'rafter': 2,
    'rafters': 2,
    'raftery': 3,
    'rafting': 2,
    'rafts': 1,
    'rafuse': 3,
    'rag': 1,
    'ragain': 2,
    'ragan': 2,
    'ragas': 2,
    'ragavan': 3,
    'rage': 1,
    'raged': 1,
    'ragel': 2,
    'ragen': 2,
    'rager': 2,
    'rages': 2,
    'ragged': 2,
    'raggedy': 3,
    'raggio': 3,
    'raghida': 3,
    'ragin': 2,
    'raging': 2,
    'ragland': 2,
    'ragle': 2,
    'raglin': 2,
    'ragmen': 2,
    'rago': 2,
    'ragon': 2,
    'ragona': 3,
    'ragone': 3,
    'ragonese': 4,
    'rags': 1,
    'ragsdale': 2,
    'ragtag': 2,
    'ragtime': 2,
    'ragtop': 2,
    'ragu': 2,
    'ragucci': 3,
    'rague': 1,
    'ragunathan': 4,
    'ragusa': 3,
    'ragweed': 2,
    'rah': 1,
    'rahal': 2,
    'rahe': 2,
    'raheem': 2,
    'rahill': 2,
    'rahilly': 3,
    'rahim': 2,
    'rahl': 1,
    'rahm': 1,
    'rahman': 2,
    'rahming': 2,
    'rahn': 1,
    'rahrig': 2,
    'rahul': 2,
    'rahway': 2,
    'rai': 2,
    'raia': 2,
    'raible': 2,
    'raiche': 1,
    'raid': 1,
    'raided': 2,
    'raider': 2,
    'raiders': 2,
    'raiding': 2,
    'raids': 1,
    'raiff': 1,
    'raiford': 2,
    'raikes': 1,
    'raiko': 2,
    'rail': 1,
    'railbike': 2,
    'railbiker': 3,
    'railbikers': 3,
    'railcar': 2,
    'railcars': 2,
    'raile': 1,
    'railed': 1,
    'railey': 2,
    'railing': 2,
    'railings': 2,
    'railroad': 2,
    'railroaded': 3,
    'railroading': 3,
    'railroads': 2,
    'rails': 1,
    'railsback': 2,
    'railtex': 2,
    'railway': 2,
    'railways': 2,
    'raimer': 2,
    'raimo': 2,
    'raimond': 2,
    'raimondi': 3,
    'raimondo': 3,
    'rain': 1,
    'raina': 2,
    'rainbolt': 2,
    'rainbow': 2,
    'rainbows': 2,
    'raincoat': 2,
    'raincoats': 2,
    'raindancer': 3,
    'raindrop': 2,
    'raindrops': 2,
    'raine': 1,
    'rained': 1,
    'rainer': 2,
    'raineri': 3,
    'raines': 1,
    'rainey': 2,
    'rainfall': 2,
    'rainfalls': 2,
    'rainford': 2,
    'rainforest': 3,
    'rainforests': 3,
    'rainger': 3,
    'rainie': 2,
    'rainier': 2,
    'rainiest': 3,
    'raining': 2,
    'rainley': 2,
    'rainmaker': 3,
    'rainman': 2,
    'rainone': 2,
    'rains': 1,
    'rainstorm': 2,
    'rainstorms': 2,
    'rainville': 2,
    'rainwater': 3,
    'rainy': 2,
    'rais': 1,
    'raisa': 2,
    'raisanen': 3,
    'raisbeck': 2,
    'raisch': 1,
    'raise': 1,
    'raised': 1,
    'raiser': 2,
    'raisers': 2,
    'raises': 2,
    'raisin': 2,
    'raising': 2,
    'raisins': 2,
    'raisler': 2,
    'raison': 2,
    'raisor': 2,
    'raissa': 2,
    'rait': 1,
    'raith': 1,
    'raithel': 2,
    'raitt': 1,
    'raj': 1,
    'rajala': 3,
    'rajaratnam': 4,
    'rajewski': 3,
    'rajiv': 2,
    'rajko': 2,
    'rajkumar': 3,
    'rajneesh': 2,
    'raju': 2,
    'rak': 1,
    'rake': 1,
    'raked': 1,
    'raker': 2,
    'rakers': 2,
    'rakes': 1,
    'rakestraw': 2,
    'rakich': 2,
    'raking': 2,
    'rakish': 2,
    'rakiya': 3,
    'rakocy': 3,
    'rakoczy': 3,
    'rakoff': 2,
    'rakolta': 3,
    'rakovica': 4,
    'rakow': 2,
    'rakowski': 3,
    'raleigh': 2,
    'rales': 1,
    'raleses': 3,
    'raley': 2,
    'ralf': 1,
    'rall': 1,
    'rallied': 2,
    'rallies': 2,
    'rallis': 2,
    'rallo': 2,
    'ralls': 1,
    'rally': 2,
    'rallying': 3,
    'ralph': 1,
    'ralphs': 1,
    'ralston': 2,
    'ram': 1,
    'rama': 2,
    'ramada': 3,
    'ramadan': 3,
    'ramage': 2,
    'ramaker': 3,
    'ramala': 3,
    'ramallah': 3,
    'raman': 2,
    'ramaphosa': 4,
    'ramaswami': 4,
    'ramat': 2,
    'rambeau': 2,
    'ramberg': 2,
    'rambert': 2,
    'rambin': 2,
    'ramble': 2,
    'rambled': 2,
    'rambler': 2,
    'ramblers': 2,
    'rambling': 2,
    'rambo': 2,
    'rambow': 2,
    'rambunctious': 3,
    'ramel': 2,
    'ramella': 3,
    'ramer': 2,
    'rameriz': 3,
    'rameses': 3,
    'ramesh': 2,
    'ramesses': 3,
    'ramey': 2,
    'rami': 2,
    'ramification': 5,
    'ramifications': 5,
    'ramify': 3,
    'ramires': 3,
    'ramirez': 3,
    'ramiro': 3,
    'ramlawi': 3,
    'ramler': 2,
    'ramlow': 2,
    'ramm': 1,
    'rammed': 1,
    'rammel': 2,
    'rammer': 2,
    'ramming': 2,
    'ramo': 2,
    'ramon': 2,
    'ramona': 3,
    'ramonda': 3,
    'ramone': 2,
    'ramos': 2,
    'ramp': 1,
    'rampage': 2,
    'rampaged': 2,
    'rampager': 3,
    'rampages': 3,
    'rampaging': 3,
    'rampant': 2,
    'ramparts': 2,
    'rampell': 2,
    'rampey': 2,
    'ramping': 2,
    'rampley': 2,
    'ramps': 1,
    'rampy': 2,
    'ramqvist': 2,
    'ramrod': 2,
    'rams': 1,
    'ramsay': 2,
    'ramsburg': 2,
    'ramsdell': 2,
    'ramsden': 2,
    'ramser': 2,
    'ramses': 2,
    'ramseur': 2,
    'ramsey': 2,
    'ramseyer': 3,
    'ramseys': 2,
    'ramshackle': 3,
    'ramstad': 2,
    'ramstein': 2,
    'ramtane': 2,
    'ramtek': 2,
    'ramtha': 2,
    'ramthun': 2,
    'ramu': 2,
    'ramune': 2,
    'ramus': 2,
    'ramzi': 2,
    'ran': 1,
    'rana': 2,
    'ranalli': 3,
    'ranallo': 3,
    'rance': 1,
    'ranch': 1,
    'rancher': 2,
    'ranchera': 3,
    'ranchero': 3,
    'ranchers': 2,
    'ranches': 2,
    'ranching': 2,
    'ranchland': 2,
    'rancho': 2,
    'rancid': 2,
    'rancidity': 4,
    'ranck': 1,
    'ranco': 2,
    'rancor': 2,
    'rancorous': 3,
    'rancorousness': 4,
    'rancourt': 2,
    'rand': 1,
    'randa': 2,
    'randal': 2,
    'randall': 2,
    'randazzo': 3,
    'randel': 2,
    'randell': 2,
    'rander': 2,
    'randi': 2,
    'randle': 2,
    'randleman': 3,
    'randles': 2,
    'randlett': 2,
    'rando': 2,
    'randol': 2,
    'randolf': 2,
    'randolph': 2,
    'random': 2,
    'randomize': 3,
    'randomized': 3,
    'randomly': 3,
    'randomness': 3,
    'rands': 1,
    'randy': 2,
    'ranee': 2,
    'raneri': 3,
    'ranes': 1,
    'raney': 2,
    'ranft': 1,
    'rang': 1,
    'range': 1,
    'ranged': 1,
    'rangel': 2,
    'ranger': 2,
    'rangers': 2,
    'ranges': 2,
    'ranging': 2,
    'rangoon': 2,
    'rangy': 2,
    'ranh': 1,
    'rani': 2,
    'rania': 3,
    'ranieri': 4,
    'rank': 1,
    'ranke': 1,
    'ranked': 1,
    'ranker': 2,
    'rankers': 2,
    'rankin': 2,
    'rankine': 2,
    'ranking': 2,
    'rankings': 2,
    'rankins': 2,
    'rankle': 2,
    'rankled': 2,
    'rankles': 2,
    'rankling': 2,
    'ranks': 1,
    'ranley': 2,
    'rann': 1,
    'ranney': 2,
    'rannow': 2,
    'rans': 1,
    'ransack': 2,
    'ransacked': 2,
    'ransacking': 3,
    'ransall': 2,
    'ransbottom': 3,
    'ransburg': 2,
    'ransdell': 2,
    'ransford': 2,
    'ransier': 3,
    'ransley': 2,
    'ransom': 2,
    'ransome': 2,
    'ransoms': 2,
    'ranson': 2,
    'ransone': 2,
    'rant': 1,
    'ranta': 2,
    'rantala': 3,
    'ranted': 2,
    'ranting': 2,
    'rantoul': 2,
    'rantz': 1,
    'ranum': 2,
    'ranz': 1,
    'rao': 1,
    'raoul': 2,
    'raoux': 2,
    'rap': 1,
    'rapacious': 3,
    'rapacz': 2,
    'rapanelli': 4,
    'rapaport': 3,
    'rape': 1,
    'raped': 1,
    'raper': 2,
    'rapers': 2,
    'rapes': 1,
    'rapeseed': 2,
    'raphael': 3,
    'raphaela': 4,
    'raphaelite': 3,
    'raphalian': 4,
    'raphel': 2,
    'rapid': 2,
    'rapidity': 4,
    'rapidly': 3,
    'rapids': 2,
    'rapier': 3,
    'raping': 2,
    'rapist': 2,
    'rapists': 2,
    'rapkin': 2,
    'rapley': 2,
    'rapoca': 3,
    'rapoport': 3,
    'raposa': 3,
    'raposo': 3,
    'rapoza': 3,
    'rapozo': 3,
    'rapp': 1,
    'rappa': 2,
    'rappahannock': 4,
    'rappaport': 3,
    'rappe': 1,
    'rapped': 1,
    'rappelling': 3,
    'rapper': 2,
    'rappers': 2,
    'rapping': 2,
    'rappold': 2,
    'rappoport': 3,
    'rapport': 2,
    'rapprochement': 3,
    'raps': 1,
    'rapson': 2,
    'rapt': 1,
    'raptis': 2,
    'raptly': 2,
    'raptopoulos': 4,
    'raptor': 2,
    'raptorial': 4,
    'raptors': 2,
    'rapture': 2,
    'rapturous': 3,
    'rapuano': 4,
    'raquel': 2,
    'rara': 2,
    'rardon': 2,
    'rare': 1,
    'rarefied': 3,
    'rarefy': 3,
    'rarely': 2,
    'rareness': 2,
    'rarer': 2,
    'rarest': 2,
    'rarick': 2,
    'raritan': 3,
    'rarities': 3,
    'rarity': 3,
    'ras': 1,
    'rasbury': 3,
    'rascal': 2,
    'rascals': 2,
    'rasch': 1,
    'rasche': 1,
    'raschke': 1,
    'rasco': 2,
    'rascoe': 2,
    'rascon': 2,
    'rase': 1,
    'raser': 2,
    'rasey': 2,
    'rash': 1,
    'rashad': 2,
    'rasheed': 2,
    'rashes': 2,
    'rashid': 2,
    'rasia': 3,
    'rask': 1,
    'raska': 2,
    'raske': 1,
    'raskin': 2,
    'raskyn': 2,
    'rasmin': 2,
    'rasmus': 2,
    'rasmuson': 3,
    'rasmussen': 3,
    'rasmusson': 3,
    'rasnake': 2,
    'rasner': 2,
    'rasnick': 2,
    'raso': 2,
    'rasor': 2,
    'rasorite': 3,
    'rasp': 1,
    'raspberries': 3,
    'raspberry': 3,
    'rasped': 1,
    'rasps': 1,
    'rasputin': 3,
    'raspy': 2,
    'rast': 1,
    'raster': 2,
    'rasterop': 3,
    'rasterops': 3,
    'rastetter': 3,
    'rastus': 2,
    'rat': 1,
    'rata': 2,
    'ratajczak': 3,
    'ratatisement': 4,
    'ratatisements': 4,
    'ratay': 2,
    'ratchet': 2,
    'ratcheted': 3,
    'ratcheting': 3,
    'ratchford': 2,
    'ratcliff': 2,
    'ratcliffe': 2,
    'rate': 1,
    'rated': 2,
    'rateliff': 2,
    'ratepayer': 3,
    'ratepayers': 3,
    'rater': 2,
    'raters': 2,
    'rates': 1,
    'rath': 1,
    'rathbone': 2,
    'rathbun': 2,
    'rathburn': 2,
    'rathburne': 2,
    'rathe': 1,
    'rathel': 2,
    'rather': 2,
    'rathert': 2,
    'rathgeber': 3,
    'rathje': 2,
    'rathjen': 2,
    'rathke': 2,
    'rathman': 2,
    'rathmann': 2,
    'rathskeller': 3,
    'ratican': 3,
    'ratieni': 3,
    'ratier': 2,
    'ratification': 5,
    'ratified': 3,
    'ratifies': 3,
    'ratify': 3,
    'ratifying': 4,
    'rating': 2,
    'ratings': 2,
    'ratio': 3,
    'ration': 2,
    'rational': 3,
    'rationale': 3,
    'rationales': 3,
    'rationality': 5,
    'rationalization': 6,
    'rationalizations': 6,
    'rationalize': 4,
    'rationalized': 4,
    'rationalizing': 5,
    'rationally': 4,
    'rationed': 2,
    'rationing': 3,
    'rations': 2,
    'ratios': 3,
    'ratko': 2,
    'ratkovich': 3,
    'ratkowski': 3,
    'ratledge': 2,
    'ratley': 2,
    'ratliff': 2,
    'ratliffe': 2,
    'ratner': 2,
    'ratners': 2,
    'raton': 2,
    'rats': 1,
    'rattan': 2,
    'rattatouille': 4,
    'ratte': 1,
    'ratterman': 3,
    'ratterree': 3,
    'rattigan': 3,
    'rattle': 2,
    'rattled': 2,
    'rattler': 3,
    'rattles': 2,
    'rattlesnake': 3,
    'rattlesnakes': 3,
    'rattling': 2,
    'rattner': 2,
    'rattray': 2,
    'ratts': 1,
    'ratty': 2,
    'ratu': 2,
    'ratz': 1,
    'ratzinger': 3,
    'ratzlaff': 2,
    'rau': 1,
    'raub': 1,
    'rauber': 2,
    'raucci': 2,
    'rauch': 1,
    'raucher': 2,
    'raucous': 2,
    'raudabaugh': 3,
    'raudenbush': 3,
    'rauen': 2,
    'rauer': 2,
    'rauh': 1,
    'raul': 1,
    'raulerson': 3,
    'rauls': 2,
    'raulston': 2,
    'raum': 1,
    'raun': 1,
    'raunchy': 2,
    'raup': 1,
    'raupp': 1,
    'rausch': 1,
    'rauschenberg': 3,
    'rauscher': 2,
    'rauser': 2,
    'raut': 1,
    'rautenberg': 3,
    'rauth': 1,
    'rautio': 3,
    'ravage': 2,
    'ravaged': 2,
    'ravages': 3,
    'ravaging': 3,
    'ravan': 2,
    'rave': 1,
    'raved': 1,
    'ravel': 2,
    'raveled': 2,
    'raveling': 3,
    'ravelo': 3,
    'raven': 2,
    'ravenel': 3,
    'ravenell': 3,
    'ravenna': 3,
    'ravenous': 3,
    'ravens': 2,
    'ravenscraft': 3,
    'ravenscroft': 3,
    'ravenswood': 3,
    'raver': 2,
    'raves': 1,
    'ravi': 2,
    'ravin': 2,
    'ravindran': 3,
    'ravine': 2,
    'ravines': 2,
    'raving': 2,
    'ravinous': 3,
    'ravinus': 3,
    'ravioli': 4,
    'ravishankar': 4,
    'ravishing': 3,
    'ravitch': 2,
    'ravitz': 2,
    'raw': 1,
    'rawalpindi': 4,
    'rawding': 2,
    'rawdon': 2,
    'rawe': 1,
    'rawest': 2,
    'rawhide': 2,
    'rawl': 1,
    'rawles': 1,
    'rawley': 2,
    'rawling': 2,
    'rawlings': 2,
    'rawlins': 2,
    'rawlinson': 3,
    'rawls': 1,
    'rawness': 2,
    'rawski': 2,
    'rawson': 2,
    'rax': 1,
    'raxter': 2,
    'ray': 1,
    'raya': 2,
    'raybon': 2,
    'rayborn': 2,
    'raybould': 2,
    'raybourn': 2,
    'raybuck': 2,
    'rayburn': 2,
    'raychem': 2,
    'raycon': 2,
    'raycraft': 2,
    'raye': 1,
    'rayed': 1,
    'rayfield': 2,
    'rayford': 2,
    'raygoza': 3,
    'rayl': 1,
    'rayle': 1,
    'rayman': 2,
    'raymark': 2,
    'rayment': 2,
    'raymer': 2,
    'raymo': 2,
    'raymond': 2,
    'raymonda': 3,
    'raymund': 2,
    'raymundo': 3,
    'raynard': 2,
    'rayne': 1,
    'rayner': 2,
    'raynes': 1,
    'raynet': 2,
    'rayno': 2,
    'raynor': 2,
    'rayon': 2,
    'rayonier': 3,
    'rayos': 2,
    'rayovac': 3,
    'rayrock': 2,
    'rays': 1,
    'rayson': 2,
    'raysor': 2,
    'raytech': 2,
    'raytheon': 3,
    'raz': 1,
    'raza': 2,
    'razaleigh': 3,
    'raze': 1,
    'razed': 1,
    'razing': 2,
    'razo': 2,
    'razor': 2,
    'razorback': 3,
    'razorbacks': 3,
    'razors': 2,
    'razzano': 3,
    'razzle': 2,
    'razzmatazz': 3,
    'rb': 2,
    're': 1,
    'rea': 1,
    'reabsorb': 3,
    'reabsorbed': 3,
    'reach': 1,
    'reachable': 3,
    'reached': 1,
    'reaches': 2,
    'reaching': 2,
    'reacquire': 3,
    'reacquired': 3,
    'react': 2,
    'reacted': 3,
    'reacting': 3,
    'reaction': 3,
    'reactionaries': 5,
    'reactionary': 5,
    'reactions': 3,
    'reactivate': 4,
    'reactivated': 5,
    'reactivating': 5,
    'reactive': 3,
    'reactivity': 5,
    'reactor': 3,
    'reactors': 3,
    'reacts': 2,
    'read': 1,
    'readability': 5,
    'readable': 3,
    'reade': 1,
    'reader': 2,
    'readerman': 3,
    'readers': 2,
    'readership': 3,
    'readied': 2,
    'readier': 3,
    'readies': 2,
    'readily': 3,
    'readiness': 3,
    'reading': 2,
    'readinger': 3,
    'readings': 2,
    'readjust': 3,
    'readjusted': 4,
    'readjusting': 4,
    'readjustment': 4,
    'readjustments': 4,
    'readmit': 3,
    'readmitted': 4,
    'readout': 2,
    'reads': 1,
    'readus': 2,
    'ready': 2,
    'readying': 3,
    'reaffiliation': 6,
    'reaffirm': 3,
    'reaffirmation': 5,
    'reaffirmed': 3,
    'reaffirming': 4,
    'reaffirms': 3,
    'reagan': 2,
    'reaganesque': 3,
    'reaganism': 4,
    'reaganite': 3,
    'reaganites': 3,
    'reaganomics': 4,
    'reagans': 2,
    'reagen': 2,
    'reagent': 3,
    'reagents': 3,
    'reagle': 2,
    'reagor': 2,
    'real': 1,
    'realamerica': 5,
    'realcap': 2,
    'reale': 1,
    'realestate': 3,
    'reali': 2,
    'realign': 3,
    'realigned': 3,
    'realigning': 4,
    'realignment': 4,
    'realignments': 4,
    'realism': 3,
    'realist': 2,
    'realistic': 4,
    'realistically': 5,
    'realists': 2,
    'realities': 4,
    'reality': 4,
    'realizable': 5,
    'realization': 4,
    'realizations': 4,
    'realize': 2,
    'realized': 2,
    'realizes': 4,
    'realizing': 3,
    'reallocate': 4,
    'reallocated': 5,
    'reallocating': 5,
    'reallocation': 5,
    'reallowance': 4,
    'really': 2,
    'realm': 1,
    'realms': 1,
    'realmuto': 3,
    'realpolitik': 4,
    'realtor': 3,
    'realtors': 3,
    'realty': 3,
    'ream': 1,
    'reamer': 2,
    'reamers': 2,
    'reames': 1,
    'reams': 1,
    'reamy': 2,
    'reanalyse': 4,
    'reanalysed': 4,
    'reanalyses': 5,
    'reanalysing': 5,
    'reaney': 2,
    'reap': 1,
    'reaped': 1,
    'reaper': 2,
    'reaping': 2,
    'reappear': 3,
    'reappearance': 4,
    'reappeared': 3,
    'reappears': 3,
    'reapply': 3,
    'reappoint': 3,
    'reappointed': 4,
    'reappointment': 4,
    'reapportionment': 5,
    'reappraisal': 4,
    'reappraise': 3,
    'reappraised': 3,
    'reaps': 1,
    'rear': 1,
    'rearden': 2,
    'reardon': 2,
    'reared': 1,
    'rearick': 2,
    'rearing': 2,
    'rearm': 2,
    'rearmament': 4,
    'rearming': 3,
    'rearrange': 3,
    'rearranged': 3,
    'rearrangement': 4,
    'rearranging': 4,
    'rearrest': 3,
    'rearrested': 4,
    'rears': 1,
    'rearview': 2,
    'reas': 1,
    'rease': 1,
    'reaser': 2,
    'reasner': 2,
    'reason': 2,
    'reasonable': 4,
    'reasonableness': 5,
    'reasonably': 4,
    'reasoned': 2,
    'reasoner': 3,
    'reasoning': 3,
    'reasonover': 4,
    'reasons': 2,
    'reasor': 2,
    'reassemble': 4,
    'reassembled': 4,
    'reassembly': 4,
    'reassert': 3,
    'reasserted': 4,
    'reasserting': 4,
    'reassertion': 4,
    'reasserts': 3,
    'reassess': 3,
    'reassessed': 3,
    'reassessing': 4,
    'reassessment': 4,
    'reassign': 3,
    'reassigned': 3,
    'reassigning': 4,
    'reassignment': 4,
    'reassignments': 4,
    'reassume': 3,
    'reassumed': 3,
    'reassurance': 4,
    'reassurances': 5,
    'reassure': 3,
    'reassured': 3,
    'reassures': 3,
    'reassuring': 4,
    'reassuringly': 5,
    'reatta': 3,
    'reattach': 3,
    'reattached': 3,
    'reaume': 1,
    'reauthorization': 6,
    'reauthorize': 4,
    'reauthorized': 4,
    'reauthorizing': 5,
    'reaux': 1,
    'reave': 1,
    'reaver': 2,
    'reaves': 1,
    'reavis': 2,
    'reawaken': 4,
    'reawakened': 4,
    'reawakening': 5,
    'reay': 1,
    'reba': 2,
    'reback': 2,
    'rebalancing': 4,
    'rebar': 2,
    'rebate': 2,
    'rebated': 3,
    'rebates': 2,
    'rebbe': 2,
    'rebecca': 3,
    'rebeck': 2,
    'rebeka': 3,
    'rebekka': 3,
    'rebel': 2,
    'rebelled': 2,
    'rebelling': 3,
    'rebellion': 3,
    'rebellions': 3,
    'rebellious': 3,
    'rebelliousness': 5,
    'rebello': 3,
    'rebelo': 3,
    'rebels': 2,
    'reber': 2,
    'rebert': 2,
    'rebholz': 2,
    'rebid': 2,
    'rebirth': 2,
    'rebirths': 2,
    'rebman': 2,
    'rebmann': 2,
    'rebo': 2,
    'reboard': 2,
    'rebook': 2,
    'rebooked': 2,
    'reborn': 2,
    'rebound': 2,
    'rebounded': 3,
    'rebounding': 3,
    'rebounds': 2,
    'rebroadcast': 3,
    'rebstock': 2,
    'rebuck': 2,
    'rebuff': 2,
    'rebuffed': 2,
    'rebuffing': 3,
    'rebuffs': 2,
    'rebuild': 2,
    'rebuilder': 3,
    'rebuilders': 3,
    'rebuilding': 3,
    'rebuilds': 2,
    'rebuilt': 2,
    'rebuke': 2,
    'rebuked': 2,
    'rebukes': 2,
    'rebuking': 3,
    'rebus': 2,
    'rebut': 2,
    'rebuttal': 3,
    'rebuttals': 3,
    'rebutted': 3,
    'rebutting': 3,
    'rec': 1,
    'recadi': 3,
    'recalcitrance': 4,
    'recalcitrant': 4,
    'recalculate': 4,
    'recalculated': 5,
    'recalculating': 5,
    'recalculation': 5,
    'recall': 2,
    'recalled': 2,
    'recalling': 3,
    'recalls': 2,
    'recant': 2,
    'recantation': 4,
    'recanted': 3,
    'recanting': 3,
    'recap': 2,
    'recapitalization': 7,
    'recapitalizations': 7,
    'recapitalize': 5,
    'recapitalized': 5,
    'recapitalizing': 6,
    'recapitulate': 5,
    'recapitulates': 5,
    'recapped': 2,
    'recapping': 3,
    'recaps': 2,
    'recapture': 3,
    'recaptured': 3,
    'recapturing': 4,
    'recarey': 3,
    'recast': 2,
    'recasting': 3,
    'recchia': 3,
    'recede': 2,
    'receded': 3,
    'recedes': 2,
    'receding': 3,
    'receipt': 2,
    'receipts': 2,
    'receivable': 4,
    'receivables': 4,
    'receive': 2,
    'received': 2,
    'receiver': 3,
    'receivers': 3,
    'receivership': 4,
    'receiverships': 4,
    'receives': 2,
    'receiving': 3,
    'recent': 2,
    'recently': 3,
    'receptacle': 4,
    'receptacles': 4,
    'receptech': 3,
    'reception': 3,
    'receptionist': 4,
    'receptionists': 4,
    'receptions': 3,
    'receptive': 3,
    'receptivity': 5,
    'receptor': 3,
    'receptors': 3,
    'recertification': 6,
    'recertified': 4,
    'recertify': 4,
    'recertifying': 5,
    'recess': 2,
    'recessed': 2,
    'recesses': 3,
    'recessing': 3,
    'recession': 3,
    'recessionary': 5,
    'recessions': 3,
    'recessive': 3,
    'rech': 1,
    'recharge': 2,
    'rechargeable': 4,
    'recharged': 2,
    'recharging': 3,
    'recheck': 2,
    'rechecked': 2,
    'rechristen': 3,
    'rechristened': 3,
    'recht': 1,
    'recidivism': 5,
    'recidivist': 4,
    'recidivists': 4,
    'recine': 3,
    'recio': 3,
    'recipe': 3,
    'recipes': 3,
    'recipient': 4,
    'recipients': 4,
    'reciprocal': 4,
    'reciprocant': 4,
    'reciprocants': 4,
    'reciprocate': 4,
    'reciprocated': 5,
    'reciprocates': 4,
    'reciprocating': 5,
    'reciprocity': 5,
    'recision': 3,
    'recisions': 3,
    'recission': 3,
    'recital': 3,
    'recitals': 3,
    'recitation': 4,
    'recitations': 4,
    'recitatives': 4,
    'recite': 2,
    'recited': 3,
    'recites': 2,
    'reciting': 3,
    'reck': 1,
    'reckard': 2,
    'recker': 2,
    'reckitt': 2,
    'reckless': 2,
    'recklessly': 3,
    'recklessness': 3,
    'reckner': 2,
    'reckon': 2,
    'reckoned': 2,
    'reckoning': 3,
    'reckons': 2,
    'recktenwald': 3,
    'reclaim': 2,
    'reclaimed': 2,
    'reclaimer': 3,
    'reclaiming': 3,
    'reclamation': 4,
    'reclassification': 6,
    'reclassified': 4,
    'reclassify': 4,
    'reclassifying': 5,
    'recline': 2,
    'reclined': 2,
    'recliner': 3,
    'reclines': 2,
    'reclining': 3,
    'recluse': 2,
    'reclusive': 3,
    'reco': 2,
    'recognition': 4,
    'recognizable': 5,
    'recognizably': 5,
    'recognizance': 4,
    'recognize': 3,
    'recognized': 3,
    'recognizes': 4,
    'recognizing': 4,
    'recoil': 2,
    'recoiled': 2,
    'recoils': 2,
    'recollect': 3,
    'recollected': 4,
    'recollecting': 4,
    'recollection': 4,
    'recollections': 4,
    'recollects': 3,
    'recombinant': 4,
    'recombine': 3,
    'recommend': 3,
    'recommendation': 5,
    'recommendations': 5,
    'recommended': 4,
    'recommending': 4,
    'recommends': 3,
    'recommit': 3,
    'recommited': 4,
    'recompense': 3,
    'recon': 2,
    'reconcile': 3,
    'reconciled': 3,
    'reconciles': 3,
    'reconciliation': 6,
    'reconciliations': 6,
    'reconciling': 4,
    'recondite': 3,
    'recondition': 4,
    'reconditioned': 4,
    'reconditioning': 5,
    'reconfiguration': 6,
    'reconfigure': 4,
    'reconfigured': 4,
    'reconfirm': 3,
    'reconfirmation': 5,
    'reconfirmed': 3,
    'reconnaissance': 4,
    'reconnect': 3,
    'reconnected': 4,
    'reconnecting': 4,
    'reconnects': 3,
    'reconnoiter': 4,
    'reconquer': 3,
    'reconquered': 3,
    'reconsider': 4,
    'reconsideration': 6,
    'reconsidered': 4,
    'reconsidering': 5,
    'reconstitute': 4,
    'reconstituted': 5,
    'reconstituting': 5,
    'reconstruct': 3,
    'reconstructed': 4,
    'reconstructing': 4,
    'reconstruction': 4,
    'reconstructions': 4,
    'reconstructive': 4,
    'reconvene': 3,
    'reconvened': 3,
    'reconvenes': 3,
    'record': 2,
    'recordable': 4,
    'recorded': 3,
    'recorder': 3,
    'recorders': 3,
    'recording': 3,
    'recordings': 3,
    'recordkeeping': 4,
    'records': 2,
    'recore': 3,
    'recoton': 3,
    'recount': 2,
    'recounted': 3,
    'recounting': 3,
    'recounts': 2,
    'recoup': 2,
    'recouped': 2,
    'recouping': 3,
    'recoups': 2,
    'recourse': 2,
    'recover': 3,
    'recoverable': 5,
    'recovered': 3,
    'recoveries': 4,
    'recovering': 4,
    'recovers': 3,
    'recovery': 3,
    'recreate': 3,
    'recreated': 4,
    'recreates': 3,
    'recreating': 4,
    'recreation': 4,
    'recreational': 5,
    'recriminate': 4,
    'recrimination': 5,
    'recriminations': 5,
    'recross': 2,
    'recruit': 2,
    'recruited': 3,
    'recruiter': 3,
    'recruiters': 3,
    'recruiting': 3,
    'recruitment': 3,
    'recruits': 2,
    'rectal': 2,
    'rectangle': 3,
    'rectangles': 3,
    'rectangular': 4,
    'rectenwald': 3,
    'rectification': 5,
    'rectified': 3,
    'rectifier': 4,
    'rectifiers': 4,
    'rectifies': 3,
    'rectify': 3,
    'rectifying': 4,
    'rectitude': 3,
    'rector': 2,
    'rectory': 3,
    'rectum': 2,
    'recuperate': 4,
    'recuperated': 5,
    'recuperater': 5,
    'recuperates': 4,
    'recuperating': 5,
    'recuperation': 5,
    'recuperative': 5,
    'recupero': 4,
    'recur': 2,
    'recurred': 2,
    'recurrence': 3,
    'recurrent': 3,
    'recurring': 3,
    'recusal': 3,
    'recuse': 2,
    'recused': 2,
    'recusing': 3,
    'recyclable': 4,
    'recyclables': 4,
    'recycle': 3,
    'recycled': 3,
    'recycler': 3,
    'recyclers': 3,
    'recycles': 3,
    'recycling': 4,
    'reczek': 2,
    'red': 1,
    'reda': 2,
    'redact': 2,
    'redacted': 3,
    'redactes': 2,
    'redacting': 3,
    'redbird': 2,
    'redbook': 2,
    'redbud': 2,
    'redburn': 2,
    'redcay': 2,
    'redco': 2,
    'redcoat': 2,
    'redcoats': 2,
    'redd': 1,
    'reddell': 2,
    'redden': 2,
    'redder': 2,
    'reddest': 2,
    'reddick': 2,
    'reddig': 2,
    'reddin': 2,
    'redding': 2,
    'reddinger': 3,
    'reddington': 3,
    'reddish': 2,
    'redditt': 2,
    'reddoch': 2,
    'reddy': 2,
    'redecorate': 4,
    'redecorated': 5,
    'redecorating': 5,
    'redecoration': 5,
    'rededicate': 4,
    'rededication': 5,
    'redeem': 2,
    'redeemable': 4,
    'redeemed': 2,
    'redeeming': 3,
    'redeems': 2,
    'redefine': 3,
    'redefined': 3,
    'redefines': 3,
    'redefining': 4,
    'redefinition': 5,
    'redeker': 3,
    'redel': 2,
    'redell': 2,
    'redemption': 3,
    'redemptions': 3,
    'redemptive': 3,
    'redenbaugh': 3,
    'redenius': 4,
    'redeploy': 3,
    'redeployed': 3,
    'redeploying': 4,
    'redeployment': 4,
    'redeployments': 4,
    'redeposition': 5,
    'reder': 2,
    'redesign': 3,
    'redesigned': 3,
    'redesigning': 4,
    'redevelop': 4,
    'redevelopment': 5,
    'redeye': 2,
    'redeyes': 2,
    'redfearn': 2,
    'redfern': 2,
    'redfield': 2,
    'redfish': 2,
    'redford': 2,
    'redgrave': 2,
    'redhanded': 3,
    'redhead': 2,
    'redheaded': 3,
    'redheads': 2,
    'redi': 2,
    'redial': 3,
    'redic': 2,
    'redick': 2,
    'redicker': 3,
    'redid': 2,
    'redifer': 3,
    'rediffusion': 4,
    'rediger': 3,
    'rediker': 3,
    'reding': 2,
    'redinger': 3,
    'redington': 3,
    'redirect': 3,
    'redirected': 4,
    'redirecting': 4,
    'redirection': 4,
    'rediscover': 4,
    'rediscovered': 4,
    'rediscovering': 5,
    'rediscovery': 4,
    'redish': 2,
    'redisplay': 3,
    'redistribute': 4,
    'redistributed': 5,
    'redistributing': 5,
    'redistribution': 5,
    'redistributive': 5,
    'redistricting': 4,
    'redken': 2,
    'redland': 2,
    'redler': 2,
    'redley': 2,
    'redlich': 2,
    'redlin': 2,
    'redlinger': 4,
    'redlining': 3,
    'redman': 2,
    'redmann': 2,
    'redmon': 2,
    'redmond': 2,
    'redmund': 2,
    'redneck': 2,
    'rednecks': 2,
    'redner': 2,
    'redness': 2,
    'rednour': 2,
    'redo': 2,
    'redoglia': 4,
    'redoing': 3,
    'redolent': 3,
    'redondo': 3,
    'redone': 2,
    'redouble': 3,
    'redoubled': 3,
    'redoubling': 4,
    'redoubt': 2,
    'redoubtable': 4,
    'redound': 2,
    'redpath': 2,
    'redpoll': 2,
    'redpolls': 2,
    'redraft': 2,
    'redrafted': 3,
    'redraw': 2,
    'redrawing': 3,
    'redrawn': 2,
    'redress': 2,
    'redressing': 3,
    'reds': 1,
    'redshaw': 2,
    'redskin': 2,
    'redskins': 2,
    'redstone': 2,
    'reduce': 2,
    'reduced': 2,
    'reduces': 3,
    'reducible': 4,
    'reducing': 3,
    'reduction': 3,
    'reductions': 3,
    'redundancies': 4,
    'redundancy': 4,
    'redundant': 3,
    'redus': 2,
    'redux': 2,
    'redwald': 2,
    'redwine': 2,
    'redwood': 2,
    'redwoods': 2,
    'ree': 1,
    'reeb': 1,
    'reebok': 2,
    'reeboks': 2,
    'reebs': 1,
    'reece': 1,
    'reeche': 1,
    'reeck': 1,
    'reed': 1,
    'reeder': 2,
    'reeders': 2,
    'reeds': 1,
    'reeducate': 4,
    'reeducation': 5,
    'reedy': 2,
    'reef': 1,
    'reefer': 2,
    'reefers': 2,
    'reefs': 1,
    'reeg': 1,
    'reeh': 1,
    'reek': 1,
    'reeking': 2,
    'reeks': 1,
    'reel': 1,
    'reelect': 3,
    'reelected': 4,
    'reelecting': 4,
    'reelection': 4,
    'reeled': 1,
    'reeling': 2,
    'reels': 1,
    'reemerge': 3,
    'reemerged': 3,
    'reemergence': 4,
    'reemphasize': 4,
    'reemployment': 4,
    'reen': 1,
    'reenact': 3,
    'reenacted': 4,
    'reenactment': 4,
    'reenactments': 4,
    'reenacts': 3,
    'reengineer': 4,
    'reengineering': 5,
    'reenter': 3,
    'reentered': 3,
    'reentering': 4,
    'reentry': 3,
    'reents': 1,
    'reep': 1,
    'rees': 1,
    'reese': 1,
    'reeser': 2,
    'reesjones': 2,
    'reesman': 2,
    'reesor': 2,
    'reestablish': 4,
    'reestablished': 4,
    'reestablishing': 5,
    'reetz': 1,
    'reevaluate': 5,
    'reevaluated': 6,
    'reevaluating': 6,
    'reevaluation': 6,
    'reeve': 1,
    'reever': 2,
    'reeves': 1,
    'reexamination': 6,
    'reexamine': 4,
    'reexamined': 4,
    'reexamining': 5,
    'reexport': 3,
    'reexports': 3,
    'ref': 1,
    'refaat': 2,
    'refah': 2,
    'refco': 2,
    'refcorp': 2,
    'refenes': 3,
    'refer': 2,
    'referee': 3,
    'referees': 3,
    'reference': 3,
    'referenced': 3,
    'references': 4,
    'referencing': 4,
    'referenda': 4,
    'referendum': 4,
    'referendums': 4,
    'refering': 3,
    'referral': 3,
    'referrals': 3,
    'referred': 2,
    'referring': 3,
    'refers': 2,
    'reff': 1,
    'reffett': 2,
    'reffitt': 2,
    'reffner': 2,
    'refile': 2,
    'refiled': 2,
    'refill': 2,
    'refilled': 2,
    'refills': 2,
    'refinance': 3,
    'refinanced': 3,
    'refinances': 4,
    'refinancing': 4,
    'refinancings': 4,
    'refine': 2,
    'refined': 2,
    'refinement': 3,
    'refinements': 3,
    'refiner': 3,
    'refineries': 4,
    'refiners': 3,
    'refinery': 4,
    'refines': 2,
    'refining': 3,
    'refinish': 3,
    'refinished': 3,
    'refinishing': 4,
    'refit': 2,
    'refitted': 3,
    'refitting': 3,
    'reflag': 2,
    'reflagged': 2,
    'reflagging': 3,
    'reflate': 2,
    'reflation': 3,
    'reflect': 2,
    'reflected': 3,
    'reflecting': 3,
    'reflection': 3,
    'reflections': 3,
    'reflective': 3,
    'reflectone': 3,
    'reflector': 3,
    'reflectors': 3,
    'reflects': 2,
    'reflex': 2,
    'reflexes': 3,
    'reflexive': 3,
    'reflexively': 4,
    'reflexivity': 5,
    'refocus': 3,
    'refocused': 3,
    'refocuses': 4,
    'refocusing': 4,
    'reforest': 3,
    'reforestation': 5,
    'reform': 2,
    'reforma': 3,
    'reformation': 4,
    'reformatories': 5,
    'reformatory': 5,
    'reformed': 2,
    'reformer': 3,
    'reformers': 3,
    'reforming': 3,
    'reformist': 3,
    'reformists': 3,
    'reforms': 2,
    'reformulate': 4,
    'reformulated': 5,
    'refractive': 3,
    'refractor': 3,
    'refractories': 4,
    'refractors': 3,
    'refractory': 4,
    'refrain': 2,
    'refrained': 2,
    'refraining': 3,
    'refrains': 2,
    'refresh': 2,
    'refreshed': 2,
    'refresher': 3,
    'refreshes': 3,
    'refreshing': 3,
    'refreshingly': 4,
    'refreshment': 3,
    'refreshments': 3,
    'refrigerant': 4,
    'refrigerants': 4,
    'refrigerate': 4,
    'refrigerated': 5,
    'refrigeration': 5,
    'refrigerator': 5,
    'refrigerators': 5,
    'refsnes': 2,
    'refuel': 3,
    'refueled': 3,
    'refueling': 4,
    'refuge': 2,
    'refugee': 3,
    'refugees': 3,
    'refuges': 3,
    'refugio': 4,
    'refund': 2,
    'refundable': 4,
    'refunded': 3,
    'refunding': 3,
    'refundings': 3,
    'refunds': 2,
    'refurbish': 3,
    'refurbished': 3,
    'refurbishing': 4,
    'refurbishment': 4,
    'refusal': 3,
    'refusals': 3,
    'refuse': 2,
    'refused': 2,
    'refusenik': 3,
    'refuseniks': 3,
    'refuses': 3,
    'refusing': 3,
    'refutation': 4,
    'refute': 2,
    'refuted': 3,
    'refutes': 2,
    'refuting': 3,
    'reg': 1,
    'rega': 2,
    'regain': 2,
    'regained': 2,
    'regaining': 3,
    'regains': 2,
    'regal': 2,
    'regalado': 4,
    'regaled': 2,
    'regales': 2,
    'regalia': 3,
    'regaling': 3,
    'regally': 3,
    'regan': 2,
    'regard': 2,
    'regarded': 3,
    'regarding': 3,
    'regardless': 3,
    'regards': 2,
    'regas': 2,
    'regatta': 3,
    'regattas': 3,
    'regehr': 2,
    'regel': 2,
    'regen': 2,
    'regency': 3,
    'regenerate': 4,
    'regenerated': 5,
    'regenerating': 5,
    'regeneration': 5,
    'regeneron': 4,
    'regensburg': 3,
    'regensteiner': 4,
    'regent': 2,
    'regents': 2,
    'reger': 2,
    'regester': 3,
    'reggae': 2,
    'reggie': 2,
    'reggio': 3,
    'reggy': 2,
    'regie': 2,
    'regier': 3,
    'regime': 2,
    'regimen': 3,
    'regimens': 3,
    'regiment': 3,
    'regimental': 4,
    'regimentation': 5,
    'regimented': 4,
    'regiments': 3,
    'regimes': 2,
    'regina': 3,
    'reginald': 3,
    'region': 2,
    'regional': 3,
    'regionalize': 4,
    'regionalized': 4,
    'regionally': 4,
    'regionals': 3,
    'regions': 2,
    'regis': 2,
    'register': 3,
    'registered': 3,
    'registering': 4,
    'registers': 3,
    'registrant': 3,
    'registrants': 3,
    'registrar': 3,
    'registrars': 3,
    'registration': 4,
    'registrations': 4,
    'registries': 3,
    'registry': 3,
    'regner': 2,
    'regnery': 3,
    'regnier': 3,
    'rego': 2,
    'regress': 2,
    'regression': 3,
    'regressions': 3,
    'regressive': 3,
    'regret': 2,
    'regretful': 3,
    'regretfully': 4,
    'regrets': 2,
    'regrettable': 4,
    'regrettably': 4,
    'regretted': 3,
    'regretting': 3,
    'regroup': 2,
    'regrouped': 2,
    'regrouping': 3,
    'regs': 1,
    'regula': 3,
    'regular': 3,
    'regularity': 5,
    'regularly': 4,
    'regulars': 3,
    'regulate': 3,
    'regulated': 4,
    'regulates': 3,
    'regulating': 4,
    'regulatingly': 5,
    'regulation': 4,
    'regulations': 4,
    'regulative': 4,
    'regulator': 4,
    'regulators': 4,
    'regulatory': 5,
    'regwan': 2,
    'reh': 1,
    'reha': 2,
    'rehab': 2,
    'rehabilitate': 5,
    'rehabilitated': 6,
    'rehabilitating': 6,
    'rehabilitation': 6,
    'rehabilitations': 6,
    'rehabilitative': 6,
    'rehabilitator': 6,
    'rehabilitators': 6,
    'rehability': 5,
    'rehak': 2,
    'rehash': 2,
    'rehashing': 3,
    'rehbein': 2,
    'rehberg': 2,
    'rehberger': 3,
    'rehder': 2,
    'rehear': 2,
    'rehearing': 3,
    'rehearings': 3,
    'rehearsal': 3,
    'rehearsals': 3,
    'rehearse': 2,
    'rehearsed': 2,
    'rehearses': 3,
    'rehearsing': 3,
    'reher': 1,
    'rehfeld': 2,
    'rehfeldt': 2,
    'rehg': 1,
    'rehire': 2,
    'rehired': 3,
    'rehiring': 3,
    'rehkopf': 2,
    'rehling': 2,
    'rehm': 1,
    'rehman': 2,
    'rehmann': 2,
    'rehmer': 2,
    'rehn': 1,
    'rehnquist': 2,
    'rehor': 2,
    'rehrig': 2,
    'rehydrate': 3,
    'rehydration': 4,
    'reibel': 2,
    'reiber': 2,
    'reich': 1,
    'reichard': 2,
    'reichardt': 2,
    'reichart': 3,
    'reiche': 1,
    'reichel': 2,
    'reichelderfer': 4,
    'reichelt': 2,
    'reichenbach': 3,
    'reichenberg': 3,
    'reichenberger': 4,
    'reicher': 2,
    'reichert': 2,
    'reichhold': 2,
    'reichl': 2,
    'reichle': 2,
    'reichler': 2,
    'reichley': 2,
    'reichlin': 2,
    'reichling': 3,
    'reichman': 2,
    'reichmann': 2,
    'reichmanns': 2,
    'reichmuth': 2,
    'reichow': 2,
    'reichstuhl': 2,
    'reichwein': 2,
    'reick': 1,
    'reicks': 1,
    'reid': 1,
    'reidel': 2,
    'reidenbach': 3,
    'reider': 2,
    'reiders': 2,
    'reidinger': 3,
    'reidt': 1,
    'reidy': 2,
    'reier': 2,
    'reierson': 3,
    'reif': 1,
    'reifel': 2,
    'reiff': 1,
    'reifschneider': 3,
    'reifsnyder': 3,
    'reifsteck': 2,
    'reigel': 2,
    'reiger': 2,
    'reighard': 2,
    'reigle': 2,
    'reign': 1,
    'reigned': 1,
    'reigning': 2,
    'reignite': 3,
    'reignited': 4,
    'reigniting': 4,
    'reigns': 1,
    'reiher': 2,
    'reihl': 1,
    'reiki': 2,
    'reil': 1,
    'reiland': 2,
    'reiley': 2,
    'reiling': 2,
    'reilley': 2,
    'reilly': 2,
    'reily': 2,
    'reim': 1,
    'reiman': 2,
    'reimann': 2,
    'reimburse': 3,
    'reimbursed': 3,
    'reimbursement': 4,
    'reimbursements': 4,
    'reimburses': 4,
    'reimbursing': 4,
    'reimer': 2,
    'reimers': 2,
    'reimpose': 3,
    'reimposed': 3,
    'reimposing': 4,
    'reimposition': 5,
    'reims': 1,
    'rein': 1,
    'reina': 3,
    'reinaldo': 3,
    'reinard': 2,
    'reinbold': 2,
    'reinbolt': 2,
    'reincarnate': 4,
    'reincarnated': 5,
    'reincarnation': 5,
    'reincke': 2,
    'reincorporate': 5,
    'reincorporating': 6,
    'reincorporation': 6,
    'reindeer': 2,
    'reindel': 2,
    'reinders': 2,
    'reindl': 2,
    'reindustrialize': 6,
    'reine': 1,
    'reineck': 2,
    'reinecke': 3,
    'reined': 1,
    'reineke': 2,
    'reiner': 2,
    'reiners': 2,
    'reinert': 2,
    'reinertsen': 3,
    'reinertson': 3,
    'reines': 1,
    'reiney': 2,
    'reinforce': 3,
    'reinforced': 3,
    'reinforcement': 4,
    'reinforcements': 4,
    'reinforces': 4,
    'reinforcing': 4,
    'reingold': 2,
    'reinhard': 2,
    'reinhardt': 2,
    'reinhart': 2,
    'reinheimer': 3,
    'reinhold': 2,
    'reinholz': 2,
    'reinig': 2,
    'reining': 2,
    'reininger': 3,
    'reinitz': 2,
    'reinke': 1,
    'reinking': 2,
    'reinoehl': 2,
    'reins': 1,
    'reinsch': 1,
    'reinsdorf': 2,
    'reinsel': 2,
    'reinspect': 3,
    'reinspection': 4,
    'reinspections': 4,
    'reinstall': 3,
    'reinstalled': 3,
    'reinstalls': 3,
    'reinstate': 3,
    'reinstated': 4,
    'reinstatement': 4,
    'reinstating': 4,
    'reinstein': 2,
    'reinstitute': 4,
    'reinstituted': 5,
    'reinstituting': 5,
    'reinsurance': 4,
    'reinsure': 3,
    'reinsured': 3,
    'reinsurer': 4,
    'reinsurers': 4,
    'reintegrate': 4,
    'reintegrated': 5,
    'reintegration': 5,
    'reinterpret': 4,
    'reinterpretation': 6,
    'reinterpreted': 5,
    'reinterpreting': 5,
    'reintroduce': 4,
    'reintroduced': 4,
    'reintroduces': 5,
    'reintroducing': 5,
    'reintroduction': 5,
    'reints': 1,
    'reinvent': 3,
    'reinvented': 4,
    'reinventing': 4,
    'reinvention': 4,
    'reinvest': 3,
    'reinvested': 4,
    'reinvesting': 4,
    'reinvestment': 4,
    'reinvests': 3,
    'reinvigorate': 5,
    'reinvigorated': 6,
    'reinvigorating': 6,
    'reinvigoration': 6,
    'reinvite': 3,
    'reinvited': 4,
    'reinwald': 2,
    'reis': 1,
    'reisch': 1,
    'reischauer': 2,
    'reischman': 2,
    'reisdorf': 2,
    'reise': 1,
    'reisen': 2,
    'reisenauer': 4,
    'reiser': 2,
    'reisert': 2,
    'reish': 2,
    'reisig': 2,
    'reising': 2,
    'reisinger': 3,
    'reisman': 2,
    'reisner': 2,
    'reiss': 1,
    'reissig': 2,
    'reissman': 2,
    'reissue': 3,
    'reissued': 3,
    'reissuing': 4,
    'reist': 2,
    'reister': 3,
    'reiswig': 2,
    'reisz': 1,
    'reit': 1,
    'reitan': 2,
    'reitano': 4,
    'reiten': 2,
    'reiter': 2,
    'reitera': 4,
    'reiterate': 4,
    'reiterated': 5,
    'reiterates': 4,
    'reiterating': 5,
    'reiteration': 5,
    'reith': 1,
    'reither': 2,
    'reitman': 2,
    'reitmeier': 3,
    'reitmeyer': 3,
    'reits': 1,
    'reitsma': 2,
    'reitter': 2,
    'reitz': 1,
    'reitzel': 2,
    'reitzes': 2,
    'reitzfeld': 2,
    'reject': 2,
    'rejected': 3,
    'rejecting': 3,
    'rejection': 3,
    'rejectionist': 4,
    'rejectionists': 4,
    'rejections': 3,
    'rejects': 2,
    'rejiggering': 4,
    'rejoice': 2,
    'rejoiced': 2,
    'rejoicing': 3,
    'rejoin': 2,
    'rejoinder': 3,
    'rejoined': 2,
    'rejoining': 3,
    'rejoins': 2,
    'rejuvenate': 4,
    'rejuvenated': 5,
    'rejuvenates': 4,
    'rejuvenating': 5,
    'rejuvenation': 5,
    'rekenthaler': 4,
    'reker': 2,
    'rekindle': 3,
    'rekindled': 3,
    'rekindling': 3,
    'relabel': 3,
    'relapse': 2,
    'relapsed': 2,
    'relapses': 3,
    'relapsing': 3,
    'relate': 2,
    'related': 3,
    'relates': 2,
    'relating': 3,
    'relation': 3,
    'relational': 4,
    'relations': 3,
    'relationship': 4,
    'relationships': 4,
    'relative': 3,
    'relatively': 4,
    'relatives': 3,
    'relativism': 5,
    'relativistic': 5,
    'relativity': 5,
    'relaunch': 2,
    'relaunched': 2,
    'relax': 2,
    'relaxation': 4,
    'relaxed': 2,
    'relaxes': 3,
    'relaxing': 3,
    'relay': 2,
    'relayed': 2,
    'relaying': 3,
    'relays': 2,
    'relearn': 2,
    'relearning': 3,
    'release': 2,
    'released': 2,
    'releases': 3,
    'releasing': 3,
    'releford': 3,
    'relegate': 3,
    'relegated': 4,
    'relegating': 4,
    'relend': 2,
    'relending': 3,
    'relent': 2,
    'relented': 3,
    'relenting': 3,
    'relentless': 3,
    'relentlessly': 4,
    'relevance': 3,
    'relevancy': 4,
    'relevant': 3,
    'relf': 1,
    'relford': 2,
    'reli': 2,
    'reliability': 6,
    'reliable': 4,
    'reliably': 4,
    'reliance': 3,
    'reliant': 3,
    'relic': 2,
    'relics': 2,
    'relied': 2,
    'relief': 2,
    'reliefs': 2,
    'relies': 2,
    'relieve': 2,
    'relieved': 2,
    'reliever': 3,
    'relievers': 3,
    'relieves': 2,
    'relieving': 3,
    'reliford': 3,
    'relig': 2,
    'religion': 3,
    'religione': 5,
    'religionist': 4,
    'religions': 3,
    'religiosity': 6,
    'religious': 3,
    'religiously': 4,
    'relinquish': 3,
    'relinquished': 3,
    'relinquishes': 4,
    'relinquishing': 4,
    'relish': 2,
    'relished': 2,
    'relishes': 3,
    'relishing': 3,
    'relive': 2,
    'relived': 2,
    'reliving': 3,
    'rella': 2,
    'reller': 2,
    'relman': 2,
    'reload': 2,
    'reloadable': 4,
    'reloaded': 3,
    'reloads': 2,
    'relocate': 3,
    'relocated': 4,
    'relocating': 4,
    'relocation': 4,
    'relocations': 4,
    'relondo': 3,
    'reloral': 3,
    'relph': 1,
    'reluctance': 3,
    'reluctant': 3,
    'reluctantly': 4,
    'rely': 2,
    'relyea': 2,
    'relying': 3,
    'rem': 1,
    'remade': 2,
    'remain': 2,
    'remainder': 3,
    'remained': 2,
    'remaining': 3,
    'remains': 2,
    'remake': 2,
    'remakes': 2,
    'remaking': 3,
    'remaley': 3,
    'remaly': 3,
    'remand': 2,
    'remanded': 3,
    'remanufacture': 5,
    'remanufactured': 5,
    'remark': 2,
    'remarkable': 4,
    'remarkably': 4,
    'remarked': 2,
    'remarket': 3,
    'remarketed': 4,
    'remarketing': 4,
    'remarking': 3,
    'remarks': 2,
    'remarriage': 3,
    'remarried': 3,
    'remarry': 3,
    'remarrying': 4,
    'rematch': 2,
    'rembert': 2,
    'rembold': 2,
    'rembrandt': 2,
    'rembrandts': 2,
    'remco': 2,
    'remedial': 4,
    'remediate': 4,
    'remediation': 5,
    'remedied': 3,
    'remedies': 3,
    'remedy': 3,
    'remedying': 4,
    'remember': 3,
    'remembered': 3,
    'remembering': 4,
    'remembers': 3,
    'remembrance': 3,
    'remembrances': 4,
    'remer': 2,
    'remerchandise': 4,
    'remerchandised': 4,
    'remerci': 3,
    'remi': 2,
    'remic': 2,
    'remick': 2,
    'remics': 2,
    'remigio': 4,
    'remillard': 3,
    'remind': 2,
    'reminded': 3,
    'reminder': 3,
    'reminders': 3,
    'reminding': 3,
    'reminds': 2,
    'remington': 3,
    'remingtons': 3,
    'reminisce': 3,
    'reminisced': 3,
    'reminiscence': 4,
    'reminiscences': 5,
    'reminiscent': 4,
    'reminisces': 4,
    'reminiscing': 4,
    'remis': 2,
    'remiss': 2,
    'remisses': 3,
    'remission': 3,
    'remissions': 3,
    'remit': 2,
    'remittance': 3,
    'remittances': 4,
    'remitted': 3,
    'remley': 2,
    'remlinger': 4,
    'remme': 1,
    'remmel': 2,
    'remmers': 2,
    'remmert': 2,
    'remmington': 3,
    'remmy': 2,
    'remnant': 2,
    'remnants': 2,
    'remnick': 2,
    'remo': 2,
    'remodel': 3,
    'remodeled': 3,
    'remodeling': 4,
    'remold': 2,
    'remolded': 3,
    'remorse': 2,
    'remorseful': 3,
    'remorseless': 3,
    'remote': 2,
    'remotely': 3,
    'remoteness': 3,
    'remotest': 3,
    'remoulded': 3,
    'removable': 4,
    'removal': 3,
    'removals': 3,
    'remove': 2,
    'removed': 2,
    'remover': 3,
    'removes': 2,
    'removing': 3,
    'rempac': 2,
    'rempe': 1,
    'rempel': 2,
    'rempfer': 2,
    'remsberg': 2,
    'remsburg': 2,
    'remsen': 2,
    'remson': 2,
    'remunerate': 4,
    'remuneration': 5,
    'remunerative': 5,
    'remus': 2,
    'remy': 2,
    'ren': 1,
    'rena': 2,
    'renaissance': 3,
    'renal': 2,
    'renaldo': 3,
    'rename': 2,
    'renamed': 2,
    'renaming': 3,
    'renamo': 3,
    'renard': 2,
    'renata': 3,
    'renate': 3,
    'renationalization': 7,
    'renationalize': 5,
    'renato': 3,
    'renaud': 2,
    'renault': 2,
    'renbarger': 3,
    'rencen': 2,
    'rench': 1,
    'rencher': 2,
    'renco': 2,
    'renda': 2,
    'rendall': 2,
    'rende': 1,
    'rendell': 2,
    'render': 2,
    'rendered': 2,
    'rendering': 3,
    'renderings': 3,
    'renders': 2,
    'rendezvous': 3,
    'rendina': 3,
    'rending': 2,
    'rendition': 3,
    'renditions': 3,
    'rendleman': 3,
    'rendon': 2,
    'rene': 2,
    'reneau': 2,
    'renee': 2,
    'renegade': 3,
    'renegades': 3,
    'renegar': 3,
    'renege': 2,
    'reneged': 2,
    'reneging': 3,
    'renegotiate': 5,
    'renegotiated': 6,
    'renegotiating': 6,
    'renegotiation': 6,
    'renegotiations': 6,
    'renehan': 3,
    'renew': 2,
    'renewable': 4,
    'renewal': 3,
    'renewals': 3,
    'renewed': 2,
    'renewing': 3,
    'renews': 2,
    'renfred': 2,
    'renfrew': 2,
    'renfro': 2,
    'renfroe': 2,
    'renfrow': 2,
    'rengel': 2,
    'rengo': 2,
    'renick': 2,
    'renier': 3,
    'renin': 2,
    'renison': 3,
    'renita': 3,
    'renk': 1,
    'renken': 2,
    'renko': 2,
    'renminbi': 3,
    'renn': 1,
    'renna': 2,
    'rennard': 2,
    'renne': 1,
    'rennels': 2,
    'renner': 2,
    'rennert': 2,
    'rennet': 2,
    'rennick': 2,
    'rennie': 2,
    'renninger': 3,
    'renno': 2,
    'renny': 2,
    'reno': 2,
    'renoir': 2,
    'renoirs': 2,
    'renominate': 4,
    'renominated': 5,
    'renomination': 5,
    'renouf': 2,
    'renounce': 2,
    'renounced': 2,
    'renounces': 3,
    'renouncing': 3,
    'renovatable': 5,
    'renovate': 3,
    'renovated': 4,
    'renovating': 4,
    'renovation': 4,
    'renovations': 4,
    'renovator': 4,
    'renovators': 4,
    'renown': 2,
    'renowned': 2,
    'renquist': 2,
    'rens': 1,
    'rensberger': 3,
    'rensch': 1,
    'renschler': 3,
    'renshaw': 2,
    'rensing': 2,
    'rensselaer': 3,
    'renstrom': 2,
    'rent': 1,
    'renta': 2,
    'rentable': 3,
    'rental': 2,
    'rentals': 2,
    'rentas': 2,
    'rented': 2,
    'renter': 2,
    'renteria': 4,
    'renters': 2,
    'rentfro': 2,
    'rentfrow': 2,
    'renting': 2,
    'rentmeester': 3,
    'renton': 2,
    'rents': 1,
    'rentsch': 1,
    'rentschler': 2,
    'rentz': 1,
    'renunciate': 4,
    'renunciation': 5,
    'renuzit': 3,
    'renwick': 2,
    'reny': 2,
    'renz': 1,
    'renzi': 2,
    'renzulli': 3,
    'reo': 2,
    'reoccupy': 4,
    'reoccur': 3,
    'reoffend': 3,
    'reoffended': 4,
    'reoffer': 3,
    'reoffered': 3,
    'reoffering': 4,
    'reopen': 3,
    'reopened': 3,
    'reopening': 4,
    'reopens': 3,
    'reorder': 3,
    'reordering': 4,
    'reorganization': 6,
    'reorganizations': 6,
    'reorganize': 4,
    'reorganized': 4,
    'reorganizes': 5,
    'reorganizing': 5,
    'reorient': 4,
    'reorientate': 5,
    'rep': 1,
    'repack': 2,
    'repackage': 3,
    'repackaged': 3,
    'repackages': 4,
    'repackaging': 4,
    'repacked': 2,
    'repaid': 2,
    'repaint': 2,
    'repainted': 3,
    'repainting': 3,
    'repair': 2,
    'repairable': 4,
    'repaired': 2,
    'repairing': 3,
    'repairman': 3,
    'repairmen': 3,
    'repairs': 2,
    'repap': 2,
    'reparation': 4,
    'reparations': 4,
    'repartee': 3,
    'repasky': 3,
    'repass': 2,
    'repatriate': 4,
    'repatriated': 5,
    'repatriating': 5,
    'repatriation': 5,
    'repay': 2,
    'repayable': 4,
    'repaying': 3,
    'repayment': 3,
    'repayments': 3,
    'repays': 2,
    'repeal': 2,
    'repealed': 2,
    'repealing': 3,
    'repeals': 2,
    'repeat': 2,
    'repeatable': 4,
    'repeated': 3,
    'repeatedly': 4,
    'repeater': 3,
    'repeaters': 3,
    'repeating': 3,
    'repeats': 2,
    'repel': 2,
    'repelled': 2,
    'repellent': 3,
    'repellents': 3,
    'repelling': 3,
    'repels': 2,
    'repent': 2,
    'repentance': 3,
    'repentant': 3,
    'repented': 3,
    'repenting': 3,
    'repents': 2,
    'repercussion': 4,
    'repercussions': 4,
    'reperfusion': 4,
    'repertoire': 3,
    'repertory': 4,
    'repetition': 4,
    'repetitions': 4,
    'repetitious': 4,
    'repetitive': 4,
    'repetti': 3,
    'repetto': 3,
    'reph': 1,
    'rephrase': 2,
    'repinski': 3,
    'repka': 2,
    'repko': 2,
    'replace': 2,
    'replaceable': 4,
    'replaced': 2,
    'replacement': 3,
    'replacements': 3,
    'replaces': 3,
    'replacing': 3,
    'replant': 2,
    'replanted': 3,
    'replanting': 3,
    'replay': 2,
    'replayed': 2,
    'replaying': 3,
    'replays': 2,
    'replenish': 3,
    'replenished': 3,
    'replenishing': 4,
    'replenishment': 4,
    'replete': 2,
    'replica': 3,
    'replicas': 3,
    'replicase': 3,
    'replicate': 3,
    'replicated': 4,
    'replicates': 3,
    'replicating': 4,
    'replication': 4,
    'replied': 2,
    'replies': 2,
    'repligen': 3,
    'replogle': 3,
    'reply': 2,
    'replying': 3,
    'repo': 2,
    'reponse': 2,
    'report': 2,
    'reportable': 4,
    'reportage': 3,
    'reported': 3,
    'reportedly': 4,
    'reporter': 3,
    'reporters': 3,
    'reporting': 3,
    'reportorial': 5,
    'reports': 2,
    'repos': 2,
    'reposa': 3,
    'repose': 2,
    'reposition': 4,
    'repositioned': 4,
    'repositioning': 5,
    'repositories': 5,
    'repository': 5,
    'repossess': 3,
    'repossessed': 3,
    'repossession': 4,
    'repossessions': 4,
    'repp': 1,
    'reppert': 2,
    'reppond': 2,
    'reppucci': 3,
    'repr': 2,
    'reprehensible': 5,
    'represent': 3,
    'representation': 5,
    'representational': 6,
    'representations': 5,
    'representative': 5,
    'representatives': 5,
    'represented': 4,
    'representing': 4,
    'represents': 3,
    'repress': 2,
    'repressed': 2,
    'repressing': 3,
    'repression': 3,
    'repressions': 3,
    'repressive': 3,
    'reprice': 2,
    'repriced': 2,
    'repricing': 3,
    'reprieve': 2,
    'reprimand': 3,
    'reprimanded': 4,
    'reprimands': 3,
    'reprint': 2,
    'reprinted': 3,
    'reprinting': 3,
    'reprints': 2,
    'reprisal': 3,
    'reprisals': 3,
    'reprise': 2,
    'reproach': 2,
    'reprobate': 3,
    'reprocess': 3,
    'reprocessed': 3,
    'reprocessing': 4,
    'reproduce': 3,
    'reproduced': 3,
    'reproduces': 4,
    'reproducing': 4,
    'reproduction': 4,
    'reproductions': 4,
    'reproductive': 4,
    'reprogram': 3,
    'reprogrammed': 3,
    'reprogramming': 4,
    'reprograms': 3,
    'reprograph': 3,
    'reprographic': 4,
    'reprographics': 4,
    'reps': 1,
    'repsher': 2,
    'repsol': 2,
    'reptile': 2,
    'reptiles': 2,
    'reptilian': 3,
    'reptilians': 3,
    'republic': 3,
    'republica': 4,
    'republican': 4,
    'republicanism': 6,
    'republicans': 4,
    'republicbank': 4,
    'republics': 3,
    'republika': 4,
    'repudiate': 4,
    'repudiated': 5,
    'repudiates': 4,
    'repudiating': 5,
    'repudiation': 5,
    'repugnant': 3,
    'repulse': 2,
    'repulsed': 2,
    'repulsing': 3,
    'repulsive': 3,
    'repurchase': 3,
    'repurchased': 3,
    'repurchases': 4,
    'repurchasing': 4,
    'reputable': 4,
    'reputation': 4,
    'reputations': 4,
    'repute': 2,
    'reputed': 3,
    'reputedly': 4,
    'requa': 2,
    'requalify': 4,
    'request': 2,
    'requested': 3,
    'requester': 3,
    'requesting': 3,
    'requests': 2,
    'requiem': 3,
    'require': 3,
    'required': 3,
    'requirement': 3,
    'requirements': 3,
    'requires': 3,
    'requiring': 4,
    'requisite': 3,
    'requisites': 3,
    'requisition': 4,
    'requisitioned': 4,
    'reread': 2,
    'rereading': 3,
    'reregulate': 4,
    'reregulation': 5,
    'reroute': 2,
    'rerouted': 3,
    'rerouting': 3,
    'rerun': 2,
    'rerunning': 3,
    'reruns': 2,
    'res': 1,
    'resale': 2,
    'resales': 2,
    'resch': 1,
    'reschedule': 3,
    'rescheduled': 3,
    'rescheduling': 4,
    'reschedulings': 4,
    'reschke': 1,
    'rescigno': 3,
    'rescind': 2,
    'rescinded': 3,
    'rescinding': 3,
    'rescinds': 2,
    'rescission': 3,
    'rescissions': 3,
    'rescue': 2,
    'rescued': 2,
    'rescuer': 3,
    'rescuers': 3,
    'rescues': 2,
    'rescuing': 3,
    'resdel': 2,
    'reseachers': 3,
    'reseal': 2,
    'resealable': 4,
    'resealed': 2,
    'reseals': 2,
    'research': 2,
    'researched': 2,
    'researcher': 3,
    'researchers': 3,
    'researches': 3,
    'researching': 3,
    'reseda': 3,
    'reseed': 2,
    'resell': 2,
    'reseller': 3,
    'resellers': 3,
    'reselling': 3,
    'resells': 2,
    'resemblance': 3,
    'resemblances': 4,
    'resemble': 3,
    'resembled': 3,
    'resembles': 3,
    'resembling': 4,
    'resende': 3,
    'resendes': 2,
    'resendez': 3,
    'resendiz': 3,
    'resent': 2,
    'resented': 3,
    'resentful': 3,
    'resenting': 3,
    'resentment': 3,
    'resentments': 3,
    'resents': 2,
    'reser': 2,
    'reservation': 4,
    'reservationist': 5,
    'reservationists': 5,
    'reservations': 4,
    'reserve': 2,
    'reserved': 2,
    'reserveese': 3,
    'reserves': 2,
    'reserving': 3,
    'reservist': 3,
    'reservists': 3,
    'reservoir': 3,
    'reservoirs': 3,
    'reset': 2,
    'resetar': 3,
    'reseting': 3,
    'resets': 2,
    'resettable': 4,
    'resettle': 3,
    'resettled': 3,
    'resettlement': 4,
    'resh': 1,
    'reshape': 2,
    'reshaped': 2,
    'reshaping': 3,
    'reshot': 2,
    'reshuffle': 3,
    'reshuffled': 3,
    'reshuffling': 4,
    'reside': 2,
    'resided': 3,
    'residence': 3,
    'residences': 4,
    'residencies': 4,
    'residency': 4,
    'resident': 3,
    'residential': 4,
    'residents': 3,
    'resides': 2,
    'residing': 3,
    'residual': 4,
    'residuals': 4,
    'residue': 3,
    'residues': 3,
    'resign': 2,
    'resignation': 4,
    'resignations': 4,
    'resigned': 2,
    'resignee': 3,
    'resignees': 3,
    'resigning': 3,
    'resigns': 2,
    'resilience': 4,
    'resiliency': 4,
    'resilient': 3,
    'resin': 2,
    'resins': 2,
    'resist': 2,
    'resistance': 3,
    'resistant': 3,
    'resisted': 3,
    'resistence': 3,
    'resisting': 3,
    'resistiveness': 4,
    'resistors': 3,
    'resists': 2,
    'reske': 1,
    'resler': 2,
    'resnais': 2,
    'resner': 2,
    'resnick': 2,
    'resnik': 2,
    'reso': 2,
    'resold': 2,
    'resolute': 3,
    'resolutely': 4,
    'resolution': 4,
    'resolutions': 4,
    'resolve': 2,
    'resolved': 2,
    'resolves': 2,
    'resolving': 3,
    'resonance': 3,
    'resonant': 3,
    'resonate': 3,
    'resonated': 4,
    'resonates': 3,
    'resonating': 4,
    'resort': 2,
    'resorted': 3,
    'resorting': 3,
    'resorts': 2,
    'resound': 2,
    'resounding': 3,
    'resoundingly': 4,
    'resource': 2,
    'resourceful': 3,
    'resourcefulness': 4,
    'resources': 3,
    'respeak': 2,
    'respect': 2,
    'respectability': 6,
    'respectable': 4,
    'respectably': 4,
    'respected': 3,
    'respectful': 3,
    'respectfully': 4,
    'respecting': 3,
    'respective': 3,
    'respectively': 4,
    'respects': 2,
    'respess': 2,
    'respiration': 4,
    'respirator': 4,
    'respirators': 4,
    'respiratory': 5,
    'respironic': 4,
    'respironics': 4,
    'respite': 2,
    'resplendent': 3,
    'respond': 2,
    'responded': 3,
    'respondent': 3,
    'respondents': 3,
    'responder': 3,
    'responders': 3,
    'responding': 3,
    'responds': 2,
    'response': 2,
    'responses': 3,
    'responsibilities': 6,
    'responsibility': 6,
    'responsible': 4,
    'responsibly': 4,
    'responsive': 3,
    'responsiveness': 4,
    'respress': 2,
    'ress': 1,
    'ressa': 2,
    'resseguie': 3,
    'ressel': 2,
    'ressler': 2,
    'rest': 1,
    'restage': 2,
    'restaged': 2,
    'restaino': 4,
    'restart': 2,
    'restarted': 3,
    'restarting': 3,
    'restate': 2,
    'restated': 3,
    'restatement': 3,
    'restatements': 3,
    'restates': 2,
    'restating': 3,
    'restaurant': 3,
    'restauranteur': 3,
    'restauranteurs': 3,
    'restaurants': 3,
    'restaurateur': 4,
    'restaurateurs': 4,
    'rested': 2,
    'rester': 2,
    'restful': 2,
    'resting': 2,
    'restitute': 3,
    'restitution': 4,
    'restive': 2,
    'restiveness': 3,
    'restivo': 3,
    'restless': 2,
    'restlessly': 3,
    'restlessness': 3,
    'resto': 2,
    'restock': 2,
    'restocked': 2,
    'restocking': 3,
    'reston': 2,
    'restoration': 4,
    'restorations': 4,
    'restorative': 4,
    'restore': 2,
    'restored': 2,
    'restorer': 3,
    'restores': 2,
    'restoring': 3,
    'restrain': 2,
    'restrained': 2,
    'restraining': 3,
    'restrains': 2,
    'restraint': 2,
    'restraints': 2,
    'restrepo': 3,
    'restrict': 2,
    'restricted': 3,
    'restricting': 3,
    'restriction': 3,
    'restrictions': 3,
    'restrictive': 3,
    'restrictiveness': 4,
    'restricts': 2,
    'restroom': 2,
    'restrooms': 2,
    'restructure': 3,
    'restructured': 3,
    'restructures': 3,
    'restructuring': 4,
    'restructurings': 4,
    'rests': 1,
    'restyle': 2,
    'restyled': 2,
    'resubmit': 3,
    'resubmitted': 4,
    'resubmitting': 4,
    'result': 2,
    'resultant': 3,
    'resulted': 3,
    'resulting': 3,
    'results': 2,
    'resume': 2,
    'resumed': 2,
    'resumes': 2,
    'resuming': 3,
    'resumption': 3,
    'resupply': 3,
    'resurface': 3,
    'resurfaced': 3,
    'resurfacing': 4,
    'resurgence': 3,
    'resurgency': 4,
    'resurgent': 3,
    'resurging': 3,
    'resurrect': 3,
    'resurrected': 4,
    'resurrecting': 4,
    'resurrection': 4,
    'resuscitate': 4,
    'resuscitated': 5,
    'resuscitating': 5,
    'resuscitation': 5,
    'resveratrol': 4,
    'ret': 1,
    'reta': 2,
    'retablos': 3,
    'retail': 2,
    'retailed': 2,
    'retailer': 3,
    'retailers': 3,
    'retailing': 3,
    'retails': 2,
    'retain': 2,
    'retained': 2,
    'retainer': 3,
    'retainers': 3,
    'retaining': 3,
    'retains': 2,
    'retake': 2,
    'retaken': 3,
    'retaking': 3,
    'retaliate': 4,
    'retaliated': 5,
    'retaliating': 5,
    'retaliation': 5,
    'retaliatory': 5,
    'retana': 3,
    'retard': 2,
    'retardant': 3,
    'retardation': 4,
    'retarded': 3,
    'retarding': 3,
    'retards': 2,
    'retell': 2,
    'retelling': 3,
    'retemeyer': 3,
    'retention': 3,
    'retest': 2,
    'retested': 3,
    'retesting': 3,
    'retests': 2,
    'retherford': 3,
    'rethink': 2,
    'rethinking': 3,
    'rethought': 2,
    'reti': 2,
    'reticence': 3,
    'reticent': 3,
    'retin': 2,
    'retina': 3,
    'retinal': 3,
    'retinoblastoma': 6,
    'retinoid': 3,
    'retinoids': 3,
    'retinue': 3,
    'retinyl': 3,
    'retire': 2,
    'retired': 2,
    'retiree': 3,
    'retirees': 3,
    'retirement': 4,
    'retirements': 3,
    'retires': 2,
    'retiring': 3,
    'retlin': 2,
    'reto': 2,
    'retook': 2,
    'retool': 2,
    'retooled': 2,
    'retooling': 3,
    'retort': 2,
    'retorted': 3,
    'retorts': 2,
    'retouching': 3,
    'retrace': 2,
    'retraced': 2,
    'retracement': 3,
    'retracing': 3,
    'retract': 2,
    'retractable': 4,
    'retracted': 3,
    'retracting': 3,
    'retraction': 3,
    'retracts': 2,
    'retrain': 2,
    'retrained': 2,
    'retraining': 3,
    'retransmission': 4,
    'retread': 2,
    'retreading': 3,
    'retreads': 2,
    'retreat': 2,
    'retreated': 3,
    'retreating': 3,
    'retreats': 2,
    'retrench': 2,
    'retrenched': 2,
    'retrenching': 3,
    'retrenchment': 3,
    'retrenchments': 3,
    'retrial': 3,
    'retribution': 4,
    'retried': 2,
    'retrieval': 3,
    'retrieve': 2,
    'retrieved': 2,
    'retriever': 3,
    'retrievers': 3,
    'retrieves': 2,
    'retrieving': 3,
    'retro': 2,
    'retroactive': 4,
    'retroactively': 5,
    'retroactivity': 6,
    'retrocession': 4,
    'retrocessionaries': 6,
    'retrocessionary': 6,
    'retrofit': 3,
    'retrofits': 3,
    'retrofitted': 4,
    'retrofitter': 4,
    'retrofitting': 4,
    'retrograde': 3,
    'retrospect': 3,
    'retrospective': 4,
    'retrospectively': 5,
    'retrovir': 3,
    'retrovirus': 4,
    'retroviruses': 5,
    'retry': 2,
    'retrying': 3,
    'rettberg': 2,
    'retter': 2,
    'rettew': 2,
    'rettig': 2,
    'rettinger': 3,
    'rettke': 2,
    'retton': 2,
    'return': 2,
    'returnable': 4,
    'returned': 2,
    'returnee': 3,
    'returnees': 3,
    'returning': 3,
    'returns': 2,
    'retz': 1,
    'retzer': 2,
    'retzlaff': 2,
    'retzloff': 2,
    'reuben': 2,
    'reuber': 2,
    'reul': 1,
    'reuland': 2,
    'reule': 1,
    'reum': 2,
    'reunification': 6,
    'reunified': 4,
    'reunify': 4,
    'reunion': 3,
    'reunions': 3,
    'reunite': 3,
    'reunited': 4,
    'reunites': 3,
    'reuniting': 4,
    'reusable': 4,
    'reusch': 1,
    'reuse': 2,
    'reused': 2,
    'reusing': 3,
    'reuss': 1,
    'reusser': 2,
    'reust': 1,
    'reuter': 2,
    'reuters': 2,
    'reutgers': 2,
    'reuther': 2,
    'reutter': 2,
    'reutzel': 2,
    'rev': 1,
    'reva': 2,
    'revak': 2,
    'revaluation': 5,
    'revaluations': 6,
    'revalue': 3,
    'revalued': 3,
    'revaluing': 4,
    'revamp': 2,
    'revamped': 2,
    'revamping': 3,
    'revamps': 2,
    'revard': 2,
    'revco': 2,
    'reveal': 2,
    'revealed': 2,
    'revealing': 3,
    'reveals': 2,
    'revel': 2,
    'revelation': 4,
    'revelations': 4,
    'revelatory': 5,
    'reveled': 2,
    'reveler': 3,
    'revelers': 3,
    'reveles': 3,
    'reveling': 3,
    'revell': 2,
    'revelle': 2,
    'reveller': 3,
    'revellers': 3,
    'revello': 3,
    'revelry': 3,
    'revels': 2,
    'revenge': 2,
    'revenue': 3,
    'revenuer': 4,
    'revenuers': 4,
    'revenues': 3,
    'reverb': 2,
    'reverbed': 2,
    'reverberate': 4,
    'reverberated': 5,
    'reverberates': 4,
    'reverberating': 5,
    'reverberation': 5,
    'reverberations': 5,
    'revercomb': 3,
    'revere': 2,
    'revered': 2,
    'reverence': 3,
    'reverend': 3,
    'reverent': 3,
    'reverential': 4,
    'reverently': 4,
    'reveres': 2,
    'reverie': 3,
    'reveries': 3,
    'revering': 3,
    'reveron': 3,
    'reversal': 3,
    'reversals': 3,
    'reverse': 2,
    'reversed': 2,
    'reverser': 3,
    'reversers': 3,
    'reverses': 3,
    'reversible': 4,
    'reversing': 3,
    'reversion': 3,
    'revert': 2,
    'reverted': 3,
    'reverting': 3,
    'reverts': 2,
    'reves': 1,
    'revette': 2,
    'revier': 3,
    'review': 2,
    'reviewed': 2,
    'reviewer': 3,
    'reviewers': 3,
    'reviewing': 3,
    'reviews': 2,
    'reviglio': 4,
    'revile': 2,
    'reviled': 2,
    'revilla': 3,
    'reville': 2,
    'revis': 2,
    'revise': 2,
    'revised': 2,
    'revises': 3,
    'revising': 3,
    'revision': 3,
    'revisionism': 5,
    'revisionist': 4,
    'revisionists': 4,
    'revisions': 3,
    'revisit': 3,
    'revisited': 4,
    'revisiting': 4,
    'revisits': 3,
    'revitalization': 6,
    'revitalize': 4,
    'revitalized': 4,
    'revitalizing': 5,
    'revival': 3,
    'revivalist': 4,
    'revivals': 3,
    'revive': 2,
    'revived': 2,
    'revives': 2,
    'reviving': 3,
    'revlon': 2,
    'revocable': 4,
    'revocation': 4,
    'revocations': 4,
    'revoir': 2,
    'revoke': 2,
    'revoked': 2,
    'revoking': 3,
    'revolt': 2,
    'revolted': 3,
    'revolting': 3,
    'revolts': 2,
    'revolucion': 5,
    'revolucionario': 8,
    'revolution': 4,
    'revolutionaries': 6,
    'revolutionary': 6,
    'revolutionist': 5,
    'revolutionists': 5,
    'revolutionize': 5,
    'revolutionized': 5,
    'revolutionizing': 6,
    'revolutions': 4,
    'revolve': 2,
    'revolved': 2,
    'revolver': 3,
    'revolvers': 3,
    'revolves': 2,
    'revolving': 3,
    'revord': 2,
    'revs': 1,
    'revson': 2,
    'revue': 2,
    'revuelta': 3,
    'revues': 2,
    'revulsion': 3,
    'revved': 1,
    'revving': 2,
    'revzin': 2,
    'rew': 1,
    'reward': 2,
    'rewarded': 3,
    'rewarding': 3,
    'rewards': 2,
    'rewatching': 3,
    'rewerts': 2,
    'rewey': 2,
    'rewind': 2,
    'rewire': 3,
    'rewired': 3,
    'rewiring': 4,
    'rewis': 2,
    'reword': 2,
    'rework': 2,
    'reworked': 2,
    'reworking': 3,
    'rewrite': 2,
    'rewrites': 2,
    'rewriting': 3,
    'rewritten': 3,
    'rewrote': 2,
    'rex': 1,
    'rexall': 2,
    'rexana': 3,
    'rexene': 2,
    'rexford': 2,
    'rexham': 2,
    'rexnord': 2,
    'rexon': 2,
    'rexroad': 2,
    'rexroat': 2,
    'rexrode': 2,
    'rexrodt': 2,
    'rexroth': 2,
    'rey': 1,
    'reyburn': 2,
    'reycraft': 2,
    'reyer': 2,
    'reyes': 2,
    'reykjavik': 3,
    'reyman': 2,
    'reyna': 2,
    'reynaga': 3,
    'reynaldo': 3,
    'reynard': 2,
    'reynaud': 2,
    'reynold': 2,
    'reynolds': 2,
    'reynoldson': 3,
    'reynosa': 3,
    'reynoso': 3,
    'reza': 2,
    'rezabek': 3,
    'rezac': 2,
    'rezek': 2,
    'rezendes': 3,
    'reznicek': 3,
    'reznick': 2,
    'reznik': 2,
    'rezoning': 3,
    'rha': 1,
    'rhame': 1,
    'rhames': 1,
    'rhapsodic': 3,
    'rhapsodize': 3,
    'rhapsodizes': 4,
    'rhapsody': 3,
    'rhea': 2,
    'rheault': 1,
    'rheaume': 1,
    'rhee': 1,
    'rheem': 1,
    'rhein': 1,
    'rheinberger': 3,
    'rheingold': 2,
    'rheinisch': 2,
    'rheinstein': 2,
    'rhem': 1,
    'rheology': 4,
    'rhesus': 2,
    'rheta': 2,
    'rhetoric': 3,
    'rhetorical': 4,
    'rhetorically': 4,
    'rhetorician': 4,
    'rhetoricians': 4,
    'rhett': 1,
    'rheumatic': 3,
    'rheumatism': 4,
    'rheumatoid': 3,
    'rheumatologist': 5,
    'rheumatology': 5,
    'rhew': 1,
    'rhin': 1,
    'rhine': 1,
    'rhinehardt': 2,
    'rhinehart': 2,
    'rhineland': 2,
    'rhineman': 2,
    'rhines': 1,
    'rhinesmith': 2,
    'rhinestone': 2,
    'rhinestones': 2,
    'rhino': 2,
    'rhinoceros': 4,
    'rhinos': 2,
    'rhizoid': 2,
    'rhizoidal': 3,
    'rhizome': 2,
    'rhizomes': 2,
    'rho': 1,
    'rhoad': 1,
    'rhoades': 1,
    'rhoads': 1,
    'rhoda': 2,
    'rhodanthe': 2,
    'rhode': 1,
    'rhoden': 2,
    'rhodes': 1,
    'rhodesia': 3,
    'rhodesian': 3,
    'rhodia': 3,
    'rhodies': 2,
    'rhodium': 3,
    'rhododendron': 4,
    'rhodopsin': 3,
    'rhodus': 2,
    'rhody': 2,
    'rhome': 1,
    'rhonda': 2,
    'rhone': 1,
    'rhoney': 2,
    'rhorer': 2,
    'rhoten': 2,
    'rhoto': 2,
    'rhoton': 2,
    'rhubarb': 2,
    'rhude': 1,
    'rhudy': 2,
    'rhue': 1,
    'rhule': 1,
    'rhyme': 1,
    'rhymed': 1,
    'rhymer': 2,
    'rhymes': 1,
    'rhyming': 2,
    'rhyne': 1,
    'rhyner': 2,
    'rhynes': 1,
    'rhys': 1,
    'rhythm': 2,
    'rhythmic': 2,
    'rhythmically': 3,
    'rhythms': 2,
    'ria': 2,
    'riad': 2,
    'riady': 3,
    'riadys': 3,
    'rial': 2,
    'rials': 1,
    'rib': 1,
    'riba': 2,
    'ribald': 2,
    'ribaldry': 3,
    'ribar': 2,
    'ribas': 2,
    'ribaudo': 3,
    'ribavirin': 4,
    'ribbed': 1,
    'ribbing': 2,
    'ribbit': 2,
    'ribble': 2,
    'ribbon': 2,
    'ribbons': 2,
    'ribeiro': 3,
    'ribera': 3,
    'ribi': 2,
    'ribicoff': 3,
    'ribka': 2,
    'ribonucleic': 5,
    'ribs': 1,
    'ric': 1,
    'rica': 2,
    'ricadonna': 4,
    'rican': 2,
    'ricans': 2,
    'ricard': 2,
    'ricardo': 3,
    'ricaurte': 3,
    'ricca': 2,
    'riccardi': 3,
    'riccardo': 3,
    'ricci': 2,
    'ricciardelli': 4,
    'ricciardi': 3,
    'riccio': 3,
    'riccitelli': 4,
    'ricciuti': 3,
    'ricco': 2,
    'riccobono': 4,
    'rice': 1,
    'riceville': 2,
    'rich': 1,
    'richa': 2,
    'richard': 2,
    'richards': 2,
    'richardson': 3,
    'richardsonsmith': 4,
    'richardt': 2,
    'richarson': 3,
    'richart': 2,
    'richaud': 2,
    'richbourg': 2,
    'richburg': 2,
    'richco': 2,
    'richcreek': 2,
    'riche': 1,
    'richelieu': 3,
    'richelson': 3,
    'richemont': 3,
    'richenthal': 3,
    'richer': 2,
    'richerd': 2,
    'richerson': 3,
    'richert': 2,
    'riches': 2,
    'richesin': 3,
    'richeson': 3,
    'richest': 2,
    'richey': 2,
    'richfield': 2,
    'richford': 2,
    'richie': 2,
    'richins': 2,
    'richison': 3,
    'richland': 2,
    'richly': 2,
    'richman': 2,
    'richmond': 2,
    'richner': 2,
    'richness': 2,
    'richoux': 2,
    'richstone': 2,
    'richter': 2,
    'richton': 2,
    'richts': 1,
    'richwhite': 2,
    'richwine': 2,
    'ricigliano': 5,
    'rick': 1,
    'rickabaugh': 3,
    'rickard': 2,
    'rickards': 2,
    'ricke': 1,
    'rickel': 2,
    'rickels': 2,
    'ricken': 2,
    'rickenbach': 3,
    'rickenbaker': 4,
    'ricker': 2,
    'rickers': 2,
    'rickerson': 3,
    'rickert': 2,
    'rickets': 2,
    'ricketson': 3,
    'rickett': 2,
    'ricketts': 2,
    'rickety': 3,
    'rickey': 2,
    'ricki': 2,
    'rickie': 2,
    'ricklefs': 2,
    'rickles': 2,
    'rickman': 2,
    'rickner': 2,
    'ricks': 1,
    'rickson': 2,
    'rickward': 2,
    'rickwood': 2,
    'ricky': 2,
    'rico': 2,
    'ricochet': 3,
    'ricocheted': 3,
    'ricocheting': 4,
    'ricoh': 2,
    'ricostruzione': 6,
    'rid': 1,
    'ridable': 3,
    'riddance': 2,
    'riddell': 2,
    'ridden': 2,
    'ridder': 2,
    'riddick': 2,
    'ridding': 2,
    'riddle': 2,
    'riddled': 2,
    'riddler': 2,
    'riddles': 2,
    'riddock': 2,
    'ride': 1,
    'rideau': 2,
    'rideaux': 2,
    'ridell': 2,
    'riden': 2,
    'ridener': 3,
    'ridenhour': 3,
    'ridenour': 3,
    'rideout': 2,
    'rider': 2,
    'riders': 2,
    'ridership': 3,
    'rides': 1,
    'ridge': 1,
    'ridgecrest': 2,
    'ridged': 1,
    'ridgefield': 2,
    'ridgell': 2,
    'ridgely': 2,
    'ridges': 2,
    'ridgeway': 2,
    'ridgewood': 2,
    'ridgley': 2,
    'ridgway': 2,
    'ridicule': 3,
    'ridiculed': 3,
    'ridicules': 3,
    'ridiculing': 4,
    'ridiculous': 4,
    'ridiculously': 5,
    'riding': 2,
    'ridinger': 3,
    'ridings': 2,
    'ridley': 2,
    'ridling': 2,
    'ridlon': 2,
    'ridner': 2,
    'ridolfi': 3,
    'ridout': 2,
    'ridpath': 2,
    'rids': 1,
    'riebe': 1,
    'riebel': 2,
    'rieben': 2,
    'rieber': 2,
    'riebman': 2,
    'riechers': 2,
    'rieck': 1,
    'riecke': 1,
    'ried': 1,
    'riede': 1,
    'riedel': 2,
    'rieder': 2,
    'riederer': 3,
    'rieders': 2,
    'riedesel': 3,
    'riedl': 2,
    'riedlinger': 4,
    'riedy': 2,
    'rief': 1,
    'rieg': 1,
    'riege': 1,
    'riegel': 2,
    'rieger': 2,
    'riegert': 2,
    'riegle': 2,
    'riegler': 2,
    'riehl': 1,
    'riehle': 2,
    'riehm': 1,
    'riek': 1,
    'rieke': 1,
    'rieken': 2,
    'rieker': 2,
    'riel': 1,
    'rielly': 2,
    'riely': 2,
    'rieman': 2,
    'riemann': 2,
    'riemenschneider': 4,
    'riemer': 2,
    'riemersma': 3,
    'riendeau': 2,
    'rienstra': 2,
    'riepe': 1,
    'riera': 2,
    'rierson': 2,
    'ries': 1,
    'riesberg': 2,
    'riese': 1,
    'riesen': 2,
    'riesenberg': 3,
    'rieser': 2,
    'riesh': 1,
    'riesling': 2,
    'riess': 1,
    'riester': 3,
    'riesterer': 3,
    'rieth': 2,
    'rietveld': 2,
    'rietz': 1,
    'rieves': 1,
    'rife': 1,
    'rifenburgh': 3,
    'riff': 1,
    'riffe': 1,
    'riffed': 1,
    'riffel': 2,
    'riffey': 2,
    'riffing': 2,
    'riffle': 2,
    'riffraff': 2,
    'riffs': 1,
    'rifkin': 2,
    'rifkind': 2,
    'rifle': 2,
    'rifled': 2,
    'rifleman': 3,
    'riflery': 3,
    'rifles': 2,
    'rifling': 2,
    'rift': 1,
    'rifts': 1,
    'rig': 1,
    'riga': 2,
    'rigas': 2,
    'rigatoni': 4,
    'rigby': 2,
    'rigdon': 2,
    'rigel': 2,
    'rigg': 1,
    'riggan': 2,
    'rigged': 1,
    'riggen': 2,
    'riggenbach': 3,
    'riggi': 2,
    'riggin': 2,
    'rigging': 2,
    'riggings': 2,
    'riggins': 2,
    'riggio': 3,
    'riggle': 2,
    'riggleman': 3,
    'riggs': 1,
    'riggsbee': 2,
    'righetti': 3,
    'righi': 2,
    'right': 1,
    'righted': 2,
    'righteous': 2,
    'righteously': 3,
    'righteousness': 3,
    'righter': 2,
    'rightful': 2,
    'rightfully': 3,
    'righthand': 2,
    'righthanded': 3,
    'rightish': 2,
    'rightist': 2,
    'rightists': 2,
    'rightly': 2,
    'rightmire': 2,
    'rightmyer': 3,
    'rightness': 2,
    'righto': 2,
    'rights': 1,
    'rightward': 2,
    'rightwing': 2,
    'righty': 2,
    'rigid': 2,
    'rigidities': 4,
    'rigidity': 4,
    'rigidly': 3,
    'rigler': 3,
    'rigney': 2,
    'rigo': 2,
    'rigoletto': 4,
    'rigoni': 3,
    'rigor': 2,
    'rigorous': 3,
    'rigorously': 4,
    'rigors': 2,
    'rigs': 1,
    'rigsbee': 2,
    'rigsby': 2,
    'rigueur': 2,
    'riha': 2,
    'rihn': 1,
    'rijn': 2,
    'rikard': 2,
    'rike': 1,
    'rikeld': 2,
    'riker': 2,
    'rikers': 2,
    'rikki': 2,
    'riklis': 2,
    'riksbank': 2,
    'riksdag': 2,
    'rile': 1,
    'riled': 1,
    'riles': 1,
    'riley': 2,
    'rill': 1,
    'rilla': 2,
    'rillette': 2,
    'rilling': 2,
    'rilwanu': 3,
    'rim': 1,
    'rima': 2,
    'rimbey': 2,
    'rimel': 2,
    'rimer': 2,
    'rimes': 1,
    'rimkus': 2,
    'rimmed': 1,
    'rimmer': 2,
    'rimpochet': 3,
    'rimpochets': 3,
    'rims': 1,
    'rimson': 2,
    'rina': 2,
    'rinaldi': 3,
    'rinaldo': 3,
    'rinard': 2,
    'rinas': 2,
    'rinaudo': 3,
    'rinck': 1,
    'rincon': 2,
    'rind': 1,
    'rindahl': 2,
    'rinderer': 3,
    'rinderknecht': 3,
    'rindfleisch': 2,
    'rinds': 1,
    'rindt': 1,
    'rine': 1,
    'rineer': 2,
    'rinehardt': 2,
    'rinehart': 2,
    'rinehimer': 3,
    'rinella': 3,
    'riner': 2,
    'rines': 1,
    'riney': 2,
    'ring': 1,
    'ringberg': 2,
    'ringdahl': 2,
    'ringe': 1,
    'ringed': 1,
    'ringeisen': 3,
    'ringel': 2,
    'ringen': 2,
    'ringenberg': 3,
    'ringer': 2,
    'ringers': 2,
    'ringgenberg': 3,
    'ringgit': 2,
    'ringgold': 2,
    'ringing': 2,
    'ringland': 2,
    'ringle': 2,
    'ringleader': 3,
    'ringleaders': 3,
    'ringler': 3,
    'ringley': 2,
    'ringling': 2,
    'ringmaster': 3,
    'ringneck': 2,
    'ringo': 2,
    'ringold': 2,
    'ringquist': 2,
    'rings': 1,
    'ringside': 2,
    'ringstad': 2,
    'ringuette': 2,
    'ringwald': 2,
    'ringwood': 2,
    'rini': 2,
    'rininger': 3,
    'rink': 1,
    'rinke': 1,
    'rinkenberger': 4,
    'rinker': 2,
    'rinko': 2,
    'rinks': 1,
    'rinn': 1,
    'rinne': 1,
    'rinse': 1,
    'rinsing': 2,
    'rintala': 3,
    'rintel': 2,
    'rintels': 2,
    'rio': 2,
    'riojas': 3,
    'riolo': 3,
    'rion': 2,
    'riopel': 3,
    'riopelle': 4,
    'riordan': 2,
    'rios': 2,
    'riot': 2,
    'rioted': 3,
    'rioter': 3,
    'rioters': 3,
    'rioting': 3,
    'riotous': 3,
    'riots': 2,
    'rioux': 1,
    'rip': 1,
    'ripa': 2,
    'ripe': 1,
    'ripen': 2,
    'ripened': 2,
    'ripening': 3,
    'riphagen': 3,
    'ripka': 2,
    'ripke': 1,
    'ripken': 2,
    'ripkin': 2,
    'ripley': 2,
    'ripoff': 2,
    'ripoffs': 2,
    'riposte': 2,
    'ripostes': 2,
    'ripp': 1,
    'rippe': 1,
    'ripped': 1,
    'rippee': 2,
    'rippel': 2,
    'rippeon': 3,
    'ripper': 2,
    'ripperger': 3,
    'rippetoe': 3,
    'rippey': 2,
    'ripping': 2,
    'ripple': 2,
    'rippled': 2,
    'ripples': 2,
    'rippling': 3,
    'ripplinger': 4,
    'rippon': 2,
    'rippy': 2,
    'rips': 1,
    'risa': 2,
    'risberg': 2,
    'risc': 1,
    'risch': 1,
    'risden': 2,
    'risdon': 2,
    'rise': 1,
    'risen': 2,
    'risenhoover': 4,
    'riser': 2,
    'rises': 2,
    'rish': 1,
    'rishel': 2,
    'rishell': 2,
    'risher': 2,
    'risi': 2,
    'risin': 2,
    'rising': 2,
    'risinger': 3,
    'risk': 1,
    'riske': 1,
    'risked': 1,
    'riskier': 3,
    'riskiest': 3,
    'riskin': 2,
    'riskiness': 3,
    'risking': 2,
    'riskless': 2,
    'risko': 2,
    'risks': 1,
    'risky': 2,
    'risley': 2,
    'risner': 2,
    'riso': 2,
    'rison': 2,
    'rispoli': 3,
    'risque': 2,
    'riss': 1,
    'risse': 1,
    'risser': 2,
    'rissler': 2,
    'rissman': 2,
    'rissmiller': 3,
    'risso': 2,
    'rissoli': 3,
    'rist': 1,
    'ristau': 2,
    'rister': 2,
    'riston': 2,
    'ristorante': 4,
    'ristorantes': 4,
    'ristow': 2,
    'rita': 2,
    'ritacco': 3,
    'ritalin': 3,
    'ritcey': 2,
    'ritch': 1,
    'ritchey': 2,
    'ritchie': 2,
    'rite': 1,
    'ritenour': 3,
    'riter': 2,
    'rites': 1,
    'ritesh': 2,
    'ritger': 2,
    'ritner': 2,
    'ritsema': 3,
    'ritson': 2,
    'ritt': 1,
    'rittenberg': 3,
    'rittenberry': 4,
    'rittenhouse': 3,
    'rittenour': 3,
    'ritter': 2,
    'ritterbusch': 3,
    'rittereiser': 4,
    'rittgers': 2,
    'ritthaler': 3,
    'rittle': 2,
    'rittman': 2,
    'rittner': 2,
    'ritts': 1,
    'ritual': 3,
    'ritualistic': 5,
    'ritualistically': 7,
    'rituals': 3,
    'ritz': 1,
    'ritzel': 2,
    'ritzenthaler': 4,
    'ritzer': 2,
    'ritzert': 2,
    'ritziest': 3,
    'ritzman': 2,
    'ritzy': 2,
    'riunite': 3,
    'riva': 2,
    'rival': 2,
    'rivaled': 2,
    'rivaling': 3,
    'rivalries': 3,
    'rivalry': 3,
    'rivals': 2,
    'rivard': 2,
    'rivas': 2,
    'riven': 2,
    'rivenbark': 3,
    'rivenburg': 3,
    'rivenburgh': 3,
    'river': 2,
    'rivera': 3,
    'riverbank': 3,
    'riverbed': 3,
    'riverbend': 3,
    'riverboat': 3,
    'riverboats': 3,
    'riverdale': 3,
    'riverfront': 3,
    'riverhead': 3,
    'rivero': 3,
    'rivers': 2,
    'riverside': 3,
    'riverso': 3,
    'riverway': 3,
    'riverwood': 3,
    'rives': 1,
    'rivest': 2,
    'rivet': 2,
    'riveted': 3,
    'riveting': 3,
    'rivets': 2,
    'rivett': 2,
    'rivette': 2,
    'riviello': 4,
    'riviera': 4,
    'riviere': 3,
    'rivieres': 3,
    'rivkin': 2,
    'rivkind': 2,
    'rivlin': 2,
    'rix': 1,
    'riyad': 2,
    'riyadh': 2,
    'riyals': 2,
    'riz': 1,
    'rizer': 2,
    'rizk': 1,
    'rizo': 2,
    'rizor': 2,
    'rizza': 2,
    'rizzi': 2,
    'rizzo': 2,
    'rizzoli': 3,
    'rizzolo': 3,
    'rizzuti': 3,
    'rizzuto': 3,
    'ro': 1,
    'roa': 2,
    'roach': 1,
    'roache': 1,
    'roaches': 2,
    'road': 1,
    'roadblock': 2,
    'roadblocks': 2,
    'roadcap': 2,
    'roades': 1,
    'roadhouse': 2,
    'roadie': 2,
    'roadmaster': 3,
    'roadrailer': 3,
    'roadrunner': 3,
    'roads': 1,
    'roadshow': 2,
    'roadside': 2,
    'roadsides': 2,
    'roadster': 2,
    'roadway': 2,
    'roadways': 2,
    'roadwork': 2,
    'roady': 2,
    'roald': 1,
    'roam': 1,
    'roamed': 1,
    'roaming': 2,
    'roams': 1,
    'roan': 1,
    'roane': 1,
    'roanna': 2,
    'roanoke': 3,
    'roar': 1,
    'roared': 1,
    'roaring': 2,
    'roark': 1,
    'roarke': 1,
    'roars': 1,
    'roarty': 2,
    'roast': 1,
    'roasted': 2,
    'roaster': 2,
    'roasters': 2,
    'roasting': 2,
    'roasts': 1,
    'roat': 1,
    'roath': 1,
    'rob': 1,
    'roback': 2,
    'robaina': 3,
    'robak': 2,
    'robar': 2,
    'robards': 2,
    'robare': 3,
    'robarge': 2,
    'robart': 2,
    'robarts': 2,
    'robb': 1,
    'robbe': 1,
    'robbed': 1,
    'robben': 2,
    'robber': 2,
    'robberies': 3,
    'robbers': 2,
    'robberson': 3,
    'robbery': 3,
    'robbie': 2,
    'robbin': 2,
    'robbing': 2,
    'robbins': 2,
    'robbs': 1,
    'robby': 2,
    'robe': 1,
    'robeck': 2,
    'robed': 1,
    'robel': 2,
    'robello': 3,
    'robelo': 3,
    'rober': 2,
    'roberds': 2,
    'roberg': 2,
    'roberge': 2,
    'robers': 2,
    'roberson': 3,
    'robert': 2,
    'roberta': 3,
    'roberti': 3,
    'roberto': 3,
    'roberts': 2,
    'robertshaw': 3,
    'robertson': 3,
    'robes': 1,
    'robeson': 3,
    'robey': 2,
    'robia': 3,
    'robichaud': 3,
    'robichaux': 3,
    'robicheaux': 3,
    'robideau': 3,
    'robidoux': 3,
    'robie': 2,
    'robillard': 3,
    'robin': 2,
    'robina': 3,
    'robinett': 3,
    'robinette': 3,
    'robinhood': 3,
    'robinia': 4,
    'robins': 2,
    'robinson': 3,
    'robinsons': 3,
    'robishaw': 3,
    'robison': 3,
    'robitaille': 3,
    'robitussin': 4,
    'robl': 2,
    'roble': 2,
    'robledo': 3,
    'roblee': 2,
    'robles': 2,
    'robley': 2,
    'roblin': 2,
    'robling': 3,
    'robnett': 2,
    'robo': 2,
    'robocop': 3,
    'robot': 2,
    'robotic': 3,
    'robotics': 3,
    'robots': 2,
    'robs': 1,
    'robson': 2,
    'robuck': 2,
    'robust': 2,
    'robusta': 3,
    'robustas': 3,
    'robustelli': 4,
    'robustly': 3,
    'robustness': 3,
    'roby': 2,
    'robyn': 2,
    'roc': 1,
    'roca': 2,
    'rocap': 2,
    'rocard': 2,
    'rocca': 2,
    'roccaforte': 4,
    'roccas': 2,
    'rocchi': 2,
    'rocchio': 3,
    'rocco': 2,
    'roch': 1,
    'rocha': 2,
    'rochat': 2,
    'rochberg': 2,
    'roche': 1,
    'rochefort': 3,
    'rocheleau': 3,
    'rochella': 3,
    'rochelle': 2,
    'rocher': 2,
    'rochester': 3,
    'rochette': 2,
    'rochford': 2,
    'rochlin': 2,
    'rochon': 2,
    'rock': 1,
    'rockabilly': 4,
    'rockafellow': 4,
    'rockaway': 3,
    'rocke': 1,
    'rocked': 1,
    'rockefeller': 4,
    'rockefellers': 4,
    'rockel': 2,
    'rockenbach': 3,
    'rocker': 2,
    'rockers': 2,
    'rocket': 2,
    'rocketdyne': 3,
    'rocketed': 3,
    'rocketing': 3,
    'rocketry': 3,
    'rockets': 2,
    'rockett': 2,
    'rockette': 2,
    'rockettes': 2,
    'rockey': 2,
    'rockford': 2,
    'rockhill': 2,
    'rockhold': 2,
    'rockholt': 2,
    'rockies': 2,
    'rocking': 2,
    'rockingham': 3,
    'rockland': 2,
    'rockley': 2,
    'rocklin': 2,
    'rockman': 2,
    'rockmore': 2,
    'rockne': 2,
    'rockoff': 2,
    'rockow': 2,
    'rockport': 2,
    'rockresort': 3,
    'rockresorts': 3,
    'rockrose': 2,
    'rocks': 1,
    'rockville': 2,
    'rockwell': 2,
    'rockwood': 2,
    'rocky': 2,
    'rococo': 3,
    'rocque': 1,
    'rod': 1,
    'roda': 2,
    'rodabaugh': 3,
    'rodak': 2,
    'rodale': 2,
    'rodarte': 2,
    'rodas': 2,
    'rodd': 1,
    'rodden': 2,
    'roddenberry': 4,
    'roddey': 2,
    'roddick': 2,
    'roddie': 2,
    'rodding': 2,
    'roddy': 2,
    'rode': 1,
    'rodebaugh': 3,
    'rodefer': 3,
    'rodeffer': 3,
    'rodeheaver': 4,
    'rodela': 3,
    'rodell': 2,
    'roden': 2,
    'rodenbaugh': 3,
    'rodenbeck': 3,
    'rodenberg': 3,
    'rodenberger': 4,
    'rodenburg': 3,
    'rodent': 2,
    'rodenticide': 4,
    'rodents': 2,
    'rodeo': 3,
    'rodeos': 3,
    'roder': 2,
    'roderica': 4,
    'roderick': 2,
    'rodes': 1,
    'rodewald': 3,
    'rodeway': 2,
    'rodge': 1,
    'rodger': 2,
    'rodgers': 2,
    'rodgin': 2,
    'rodham': 2,
    'rodi': 2,
    'rodick': 2,
    'rodier': 3,
    'rodime': 2,
    'rodin': 2,
    'rodine': 3,
    'rodino': 3,
    'rodkey': 2,
    'rodman': 2,
    'rodmond': 2,
    'rodmund': 2,
    'rodney': 2,
    'rodocker': 3,
    'rodolf': 2,
    'rodolfo': 3,
    'rodolph': 2,
    'rodrick': 2,
    'rodrigeuz': 3,
    'rodrigo': 3,
    'rodrigue': 2,
    'rodrigues': 3,
    'rodriguez': 3,
    'rodriques': 3,
    'rodriquez': 3,
    'rods': 1,
    'rodwell': 2,
    'rody': 2,
    'roe': 1,
    'roeber': 2,
    'roebke': 1,
    'roebling': 2,
    'roebuck': 2,
    'roecker': 2,
    'roed': 1,
    'roedel': 2,
    'roeder': 2,
    'roederer': 3,
    'roediger': 3,
    'roedl': 2,
    'roegner': 2,
    'roehi': 2,
    'roehl': 1,
    'roehling': 2,
    'roehm': 1,
    'roehr': 1,
    'roehrich': 2,
    'roehrig': 2,
    'roehrs': 1,
    'roeland': 2,
    'roelke': 1,
    'roell': 1,
    'roelle': 1,
    'roeller': 2,
    'roelofs': 2,
    'roemer': 2,
    'roemmich': 2,
    'roen': 1,
    'roeper': 2,
    'roepke': 1,
    'roes': 1,
    'roesch': 1,
    'roese': 1,
    'roesel': 2,
    'roeser': 2,
    'roeske': 1,
    'roesler': 3,
    'roesner': 2,
    'roessler': 3,
    'roessner': 2,
    'roether': 2,
    'roethler': 3,
    'roettger': 2,
    'roever': 3,
    'roff': 1,
    'roffe': 1,
    'roffman': 2,
    'rofin': 2,
    'rog': 1,
    'rogachev': 3,
    'rogacki': 3,
    'rogaine': 2,
    'rogal': 2,
    'rogala': 3,
    'rogalla': 3,
    'rogalski': 3,
    'rogan': 2,
    'rogel': 2,
    'rogelio': 4,
    'roger': 2,
    'rogernomics': 4,
    'rogers': 2,
    'rogerson': 3,
    'roget': 2,
    'rogge': 1,
    'roggenbuck': 3,
    'roggenkamp': 3,
    'roggio': 3,
    'roggow': 2,
    'rogier': 3,
    'rogin': 2,
    'roginski': 3,
    'rogness': 2,
    'rogoff': 2,
    'rogowski': 3,
    'rogozinski': 4,
    'rogstad': 2,
    'rogue': 1,
    'rogues': 1,
    'rogus': 2,
    'roh': 1,
    'rohan': 2,
    'rohana': 3,
    'rohatyn': 3,
    'rohde': 1,
    'rohe': 1,
    'roher': 2,
    'rohl': 1,
    'rohland': 2,
    'rohleder': 3,
    'rohlf': 1,
    'rohlfing': 2,
    'rohlfs': 1,
    'rohlicek': 3,
    'rohling': 2,
    'rohlman': 2,
    'rohloff': 2,
    'rohm': 1,
    'rohman': 2,
    'rohmer': 2,
    'rohn': 1,
    'rohner': 2,
    'rohr': 1,
    'rohrbach': 2,
    'rohrbacher': 3,
    'rohrback': 2,
    'rohrbaugh': 2,
    'rohrbough': 2,
    'rohrer': 2,
    'rohrich': 2,
    'rohrig': 2,
    'rohrman': 2,
    'rohrs': 1,
    'rohs': 1,
    'rohstoff': 2,
    'rohwedder': 3,
    'rohweder': 3,
    'rohwer': 2,
    'rohypnol': 3,
    'roi': 1,
    'roig': 1,
    'roil': 1,
    'roiled': 1,
    'roiling': 2,
    'roister': 2,
    'rojas': 2,
    'rojek': 2,
    'rojo': 2,
    'roka': 2,
    'rokahr': 2,
    'rokar': 2,
    'rokicki': 3,
    'rokos': 2,
    'rokosz': 2,
    'rol': 1,
    'rolan': 2,
    'roland': 2,
    'rolanda': 3,
    'rolando': 3,
    'roldan': 2,
    'role': 1,
    'rolemodel': 3,
    'rolemodels': 3,
    'rolen': 2,
    'roleplaying': 3,
    'roles': 1,
    'rolette': 2,
    'rolex': 2,
    'roley': 2,
    'rolf': 1,
    'rolfe': 1,
    'rolfes': 1,
    'rolfs': 1,
    'rolfson': 2,
    'rolin': 2,
    'roling': 2,
    'rolison': 3,
    'roll': 1,
    'rolla': 2,
    'rolland': 2,
    'rollback': 2,
    'rollbacks': 2,
    'rolle': 1,
    'rolled': 1,
    'rollei': 2,
    'rollen': 2,
    'roller': 2,
    'rollerblade': 3,
    'rollerblades': 3,
    'rollerblading': 4,
    'rollercoaster': 4,
    'rollers': 2,
    'rollet': 2,
    'rolley': 2,
    'rolli': 2,
    'rollicking': 3,
    'rollie': 2,
    'rollin': 2,
    'rolling': 2,
    'rollinger': 3,
    'rollings': 2,
    'rollins': 2,
    'rollinson': 3,
    'rollison': 3,
    'rollman': 2,
    'rollo': 2,
    'rollout': 2,
    'rollover': 3,
    'rollovers': 3,
    'rolls': 1,
    'rolltop': 2,
    'rollwagen': 3,
    'rolly': 2,
    'rollyson': 3,
    'rolm': 1,
    'rolnick': 2,
    'rolodex': 3,
    'roloff': 2,
    'rolon': 2,
    'rolph': 1,
    'rolston': 2,
    'rolt': 1,
    'rom': 1,
    'roma': 2,
    'romack': 2,
    'romagnoli': 4,
    'romagnolo': 4,
    'romain': 2,
    'romaine': 2,
    'roman': 2,
    'romance': 2,
    'romances': 3,
    'romancing': 3,
    'romanek': 3,
    'romanelli': 4,
    'romanello': 4,
    'romanesque': 3,
    'romani': 3,
    'romania': 4,
    'romanian': 4,
    'romanians': 4,
    'romaniello': 5,
    'romanik': 3,
    'romann': 2,
    'romano': 3,
    'romanoff': 3,
    'romanone': 3,
    'romanones': 3,
    'romanoski': 4,
    'romanov': 3,
    'romanow': 3,
    'romanowski': 4,
    'romans': 2,
    'romanski': 3,
    'romantic': 3,
    'romantically': 5,
    'romanticism': 5,
    'romanticize': 4,
    'romanticized': 4,
    'romanticizing': 5,
    'romantics': 3,
    'romany': 3,
    'romas': 2,
    'rombach': 2,
    'romberg': 2,
    'romberger': 3,
    'rome': 1,
    'romel': 2,
    'romella': 3,
    'romelle': 2,
    'romeo': 3,
    'romer': 2,
    'romero': 3,
    'romesburg': 2,
    'romey': 2,
    'romick': 2,
    'romig': 2,
    'romilda': 3,
    'romina': 3,
    'romine': 3,
    'romines': 2,
    'rominger': 3,
    'romito': 3,
    'romley': 2,
    'romm': 1,
    'rommel': 2,
    'romney': 2,
    'romo': 2,
    'romola': 3,
    'romp': 1,
    'romping': 2,
    'roms': 1,
    'romuald': 3,
    'romulus': 3,
    'ron': 1,
    'rona': 2,
    'ronald': 2,
    'ronalda': 3,
    'ronan': 2,
    'ronayne': 2,
    'ronca': 2,
    'ronco': 2,
    'ronda': 2,
    'rondeau': 2,
    'rondinelli': 4,
    'rondon': 2,
    'rondonia': 4,
    'rone': 1,
    'ronen': 2,
    'roney': 2,
    'rong': 1,
    'rongji': 2,
    'roni': 2,
    'ronin': 2,
    'ronk': 1,
    'ronko': 2,
    'ronna': 2,
    'ronne': 1,
    'ronnie': 2,
    'ronning': 2,
    'ronny': 2,
    'ronquillo': 3,
    'ronson': 2,
    'ronstadt': 2,
    'rood': 1,
    'roode': 1,
    'roof': 1,
    'roofed': 1,
    'roofer': 2,
    'roofers': 2,
    'roofing': 2,
    'roofs': 1,
    'rooftop': 2,
    'rooftops': 2,
    'rooftree': 2,
    'rook': 1,
    'rookard': 2,
    'rooke': 1,
    'rooker': 2,
    'rookie': 2,
    'rookies': 2,
    'rooks': 1,
    'rookstool': 2,
    'room': 1,
    'roome': 1,
    'roomful': 2,
    'roomier': 3,
    'roominess': 3,
    'rooming': 2,
    'roommate': 2,
    'roommates': 2,
    'rooms': 1,
    'roomy': 2,
    'roone': 1,
    'rooney': 2,
    'roop': 1,
    'roope': 1,
    'roorda': 2,
    'roos': 1,
    'roosa': 2,
    'roose': 1,
    'roosevelt': 3,
    'roosevelts': 3,
    'roost': 1,
    'roosted': 2,
    'rooster': 2,
    'roosters': 2,
    'root': 1,
    'rooted': 2,
    'rooter': 2,
    'rooters': 2,
    'rooting': 2,
    'rootless': 2,
    'roots': 1,
    'rootstein': 2,
    'ropak': 2,
    'rope': 1,
    'roped': 1,
    'roper': 2,
    'ropers': 2,
    'ropes': 1,
    'roping': 2,
    'ropp': 1,
    'roppolo': 3,
    'rops': 1,
    'roque': 1,
    'roquemore': 2,
    'rorabaugh': 3,
    'rorer': 2,
    'rorex': 2,
    'rorick': 2,
    'rorie': 2,
    'rork': 1,
    'rorke': 1,
    'rorrer': 2,
    'rorschach': 2,
    'rory': 2,
    'ros': 1,
    'rosa': 2,
    'rosabel': 3,
    'rosabelle': 3,
    'rosado': 3,
    'rosalee': 3,
    'rosales': 2,
    'rosalez': 3,
    'rosalia': 4,
    'rosalie': 3,
    'rosalind': 3,
    'rosalinda': 4,
    'rosalyn': 3,
    'rosalynd': 3,
    'rosalynn': 3,
    'rosamilia': 5,
    'rosamond': 3,
    'rosamund': 3,
    'rosan': 2,
    'rosander': 3,
    'rosanna': 3,
    'rosanne': 2,
    'rosano': 3,
    'rosaria': 4,
    'rosaries': 3,
    'rosario': 4,
    'rosary': 3,
    'rosas': 2,
    'rosasco': 3,
    'rosati': 3,
    'rosato': 3,
    'rosberg': 2,
    'rosborough': 3,
    'rosch': 1,
    'roscher': 2,
    'roscoe': 2,
    'rose': 1,
    'roseanne': 2,
    'roseate': 3,
    'roseberry': 3,
    'roseboom': 2,
    'roseboro': 3,
    'roseborough': 3,
    'rosebrock': 2,
    'rosebrook': 2,
    'rosebrough': 2,
    'rosebud': 2,
    'rosebush': 2,
    'rosecrans': 2,
    'rosekrans': 2,
    'rosel': 2,
    'roseland': 2,
    'roselawn': 2,
    'roseline': 2,
    'rosell': 2,
    'rosella': 3,
    'rosellen': 3,
    'roselli': 3,
    'rosello': 3,
    'roseman': 2,
    'rosemarie': 3,
    'rosemary': 3,
    'rosemead': 2,
    'rosemeyer': 3,
    'rosemond': 2,
    'rosemont': 2,
    'rosen': 2,
    'rosenau': 2,
    'rosenbach': 3,
    'rosenbalm': 3,
    'rosenbaum': 3,
    'rosenbeck': 3,
    'rosenberg': 3,
    'rosenberger': 4,
    'rosenbergs': 3,
    'rosenberry': 4,
    'rosenblatt': 3,
    'rosenblatts': 3,
    'rosenbloom': 3,
    'rosenblum': 3,
    'rosenbluth': 3,
    'rosenboom': 3,
    'rosenburg': 3,
    'rosencrans': 3,
    'rosendahl': 3,
    'rosendale': 3,
    'rosene': 2,
    'rosener': 3,
    'rosenfeld': 3,
    'rosenfield': 3,
    'rosengarten': 4,
    'rosengrant': 3,
    'rosengren': 3,
    'rosenhaus': 3,
    'rosenkrans': 3,
    'rosenkrantz': 3,
    'rosenkranz': 3,
    'rosenlund': 3,
    'rosenman': 3,
    'rosenow': 3,
    'rosenquist': 3,
    'rosenshine': 3,
    'rosensteel': 3,
    'rosenstein': 3,
    'rosenstiel': 3,
    'rosenstock': 3,
    'rosensweig': 3,
    'rosenthal': 3,
    'rosentreter': 4,
    'rosenwald': 3,
    'rosenwasser': 4,
    'rosenzweig': 3,
    'roser': 2,
    'roses': 2,
    'rosete': 2,
    'rosett': 2,
    'rosetta': 3,
    'rosette': 2,
    'rosettes': 2,
    'rosetti': 3,
    'rosevear': 2,
    'roseville': 2,
    'rosewicz': 2,
    'rosewood': 2,
    'rosey': 2,
    'rosh': 1,
    'roshier': 2,
    'rosiak': 3,
    'rosie': 2,
    'rosier': 3,
    'rosin': 2,
    'rosina': 3,
    'rosine': 3,
    'rosing': 2,
    'rosinski': 3,
    'rosinsky': 3,
    'rosita': 3,
    'roskam': 2,
    'roske': 1,
    'rosko': 2,
    'roskos': 2,
    'roslin': 2,
    'roslund': 2,
    'roslyn': 2,
    'rosman': 2,
    'rosmunda': 3,
    'rosneft': 2,
    'rosner': 2,
    'rosoff': 2,
    'rosol': 2,
    'rospatch': 2,
    'ross': 1,
    'rossa': 2,
    'rossano': 3,
    'rossbach': 2,
    'rossborough': 3,
    'rosse': 1,
    'rosselin': 3,
    'rossell': 2,
    'rosselli': 3,
    'rosselot': 3,
    'rossen': 2,
    'rosser': 2,
    'rossetti': 3,
    'rossetto': 3,
    'rossi': 2,
    'rossie': 2,
    'rossignol': 3,
    'rossin': 2,
    'rossing': 2,
    'rossini': 3,
    'rossiter': 3,
    'rossiya': 3,
    'rossler': 3,
    'rosslyn': 2,
    'rossman': 2,
    'rossmann': 2,
    'rossmiller': 3,
    'rossmore': 2,
    'rossner': 2,
    'rosso': 2,
    'rosson': 2,
    'rossow': 2,
    'rost': 1,
    'rostad': 2,
    'rosten': 2,
    'rostenkowski': 4,
    'roster': 2,
    'rosters': 2,
    'roston': 2,
    'rostov': 2,
    'rostovondon': 4,
    'rostron': 2,
    'rostropovich': 4,
    'rostrum': 2,
    'rostuca': 3,
    'rosty': 2,
    'roswald': 2,
    'roswell': 2,
    'rosy': 2,
    'roszak': 2,
    'roszell': 2,
    'roszkowski': 3,
    'rot': 1,
    'rota': 2,
    'rotan': 2,
    'rotary': 3,
    'rotate': 2,
    'rotated': 3,
    'rotates': 2,
    'rotating': 3,
    'rotation': 3,
    'rotational': 4,
    'rotationally': 5,
    'rotations': 3,
    'rotberg': 2,
    'rotblat': 2,
    'rote': 1,
    'rotea': 3,
    'rotella': 3,
    'roten': 2,
    'rotenberg': 3,
    'rotenberry': 4,
    'rotert': 2,
    'roth': 1,
    'rothacker': 3,
    'rothbard': 2,
    'rothbart': 2,
    'rothbauer': 3,
    'rothberg': 2,
    'rothchild': 2,
    'rothe': 1,
    'rothenberg': 3,
    'rothenberger': 4,
    'rother': 2,
    'rothermel': 3,
    'rothert': 2,
    'rothery': 3,
    'rothfuss': 2,
    'rothgeb': 2,
    'rothkopf': 2,
    'rothlisberger': 4,
    'rothman': 2,
    'rothmans': 2,
    'rothmeier': 2,
    'rothrock': 2,
    'roths': 1,
    'rothschild': 2,
    'rothschilds': 2,
    'rothstein': 2,
    'rothwax': 2,
    'rothweiler': 3,
    'rothwell': 2,
    'rothwells': 2,
    'roti': 2,
    'rotisserie': 4,
    'rotman': 2,
    'roto': 2,
    'rotolo': 3,
    'rotondi': 3,
    'rotondo': 3,
    'rotor': 2,
    'rotors': 2,
    'rototill': 3,
    'rototilled': 3,
    'rototiller': 4,
    'rototilles': 3,
    'rototilling': 4,
    'rotramel': 3,
    'rotruck': 2,
    'rots': 1,
    'rott': 1,
    'rotted': 2,
    'rotten': 2,
    'rottenberg': 3,
    'rotter': 2,
    'rotterdam': 3,
    'rotting': 2,
    'rottinghaus': 3,
    'rottler': 2,
    'rottman': 2,
    'rottmann': 2,
    'rotund': 2,
    'rotunda': 3,
    'rotundo': 3,
    'rotunno': 3,
    'rotz': 1,
    'roubal': 2,
    'rouble': 2,
    'rouch': 1,
    'roudabush': 3,
    'roudebush': 3,
    'rouge': 1,
    'rougeau': 2,
    'rough': 1,
    'roughed': 1,
    'rougher': 2,
    'roughest': 2,
    'roughing': 2,
    'roughly': 2,
    'roughneck': 2,
    'roughnecks': 2,
    'roughness': 2,
    'roughshod': 2,
    'rought': 1,
    'roughton': 2,
    'rouillard': 2,
    'roukema': 2,
    'roukos': 2,
    'roulac': 2,
    'rouleau': 2,
    'roulette': 2,
    'roulettes': 2,
    'roulhac': 2,
    'roulston': 2,
    'round': 1,
    'roundabout': 3,
    'rounded': 2,
    'rounder': 2,
    'roundhead': 2,
    'roundhouse': 2,
    'rounding': 2,
    'roundly': 2,
    'rounds': 1,
    'roundtable': 3,
    'roundtree': 2,
    'roundtrip': 2,
    'roundup': 2,
    'roundworm': 2,
    'roundworms': 2,
    'roundy': 2,
    'rounsaville': 3,
    'rountree': 2,
    'roupe': 1,
    'rourk': 1,
    'rourke': 1,
    'rouse': 1,
    'roused': 1,
    'rouser': 2,
    'rousey': 2,
    'roush': 1,
    'rousing': 2,
    'rousse': 1,
    'rousseau': 2,
    'roussel': 2,
    'rousselet': 3,
    'roussell': 2,
    'rousselle': 2,
    'roussin': 2,
    'roussos': 2,
    'rout': 1,
    'route': 1,
    'routed': 2,
    'router': 2,
    'routers': 2,
    'routes': 1,
    'routh': 1,
    'routhier': 3,
    'routine': 2,
    'routinely': 3,
    'routines': 2,
    'routing': 2,
    'routledge': 2,
    'routon': 2,
    'routson': 2,
    'routt': 1,
    'routzahn': 2,
    'roux': 1,
    'rouyn': 2,
    'rouzer': 2,
    'rovaniemi': 5,
    'rove': 1,
    'roven': 2,
    'rover': 2,
    'rovers': 2,
    'roving': 2,
    'rovings': 2,
    'rovira': 3,
    'rovito': 3,
    'rovner': 2,
    'row': 1,
    'rowaine': 2,
    'rowan': 2,
    'rowand': 2,
    'rowboat': 2,
    'rowbotham': 3,
    'rowden': 2,
    'rowdies': 2,
    'rowdiness': 3,
    'rowdy': 2,
    'rowe': 1,
    'rowed': 1,
    'rowell': 2,
    'rowen': 2,
    'rowena': 3,
    'rowenta': 3,
    'rower': 2,
    'rowes': 1,
    'rowin': 2,
    'rowing': 2,
    'rowinski': 3,
    'rowland': 2,
    'rowlands': 2,
    'rowles': 1,
    'rowlett': 2,
    'rowlette': 2,
    'rowley': 2,
    'rowlie': 2,
    'rowly': 2,
    'rowney': 2,
    'rowntree': 2,
    'rowny': 2,
    'rows': 1,
    'rowse': 1,
    'rowser': 2,
    'rowsey': 2,
    'rowson': 2,
    'rowton': 2,
    'rox': 1,
    'roxana': 3,
    'roxani': 3,
    'roxanna': 3,
    'roxanne': 2,
    'roxborough': 3,
    'roxbury': 3,
    'roxie': 2,
    'roxine': 2,
    'roxy': 2,
    'roy': 1,
    'royal': 2,
    'royale': 2,
    'royalist': 3,
    'royall': 2,
    'royally': 3,
    'royals': 2,
    'royalties': 3,
    'royalty': 3,
    'roybal': 2,
    'royce': 1,
    'royces': 2,
    'roycroft': 2,
    'royd': 1,
    'royden': 2,
    'roydon': 2,
    'roye': 1,
    'royer': 2,
    'royex': 2,
    'royko': 2,
    'roylance': 2,
    'royle': 1,
    'roys': 1,
    'royse': 1,
    'royster': 2,
    'royston': 2,
    'roz': 1,
    'roza': 2,
    'rozak': 2,
    'rozanski': 3,
    'rozar': 2,
    'rozas': 2,
    'rozeboom': 3,
    'rozek': 2,
    'rozell': 2,
    'rozella': 3,
    'rozelle': 2,
    'rozema': 3,
    'rozen': 2,
    'rozier': 3,
    'rozman': 2,
    'roznowski': 3,
    'rozsa': 2,
    'rozycki': 3,
    'rozzell': 2,
    'rozzi': 2,
    'rpf': 3,
    'rpm': 3,
    'rrrot': 5,
    'ru': 1,
    'rua': 2,
    'ruam': 2,
    'ruane': 2,
    'ruano': 3,
    'ruark': 2,
    'rub': 1,
    'ruback': 2,
    'rubalcaba': 4,
    'rubalcava': 4,
    'rubano': 3,
    'rubbed': 1,
    'rubber': 2,
    'rubberize': 3,
    'rubberized': 3,
    'rubbermaid': 3,
    'rubbers': 2,
    'rubbery': 3,
    'rubbia': 3,
    'rubbing': 2,
    'rubbish': 2,
    'rubble': 2,
    'rubbo': 2,
    'rube': 1,
    'rubeck': 2,
    'rubel': 2,
    'rubell': 2,
    'rubella': 3,
    'ruben': 2,
    'rubendall': 3,
    'rubenfeld': 3,
    'rubens': 2,
    'rubenstein': 3,
    'ruberg': 2,
    'rubert': 2,
    'ruberto': 3,
    'rubey': 2,
    'rubi': 2,
    'rubia': 3,
    'rubicam': 3,
    'rubicon': 3,
    'rubie': 2,
    'rubies': 2,
    'rubik': 2,
    'rubin': 2,
    'rubina': 3,
    'rubino': 3,
    'rubinson': 3,
    'rubinstein': 3,
    'rubio': 3,
    'rubios': 3,
    'rubis': 2,
    'ruble': 2,
    'rubles': 2,
    'rubley': 2,
    'rubloff': 2,
    'rubottom': 3,
    'rubout': 2,
    'rubric': 2,
    'rubright': 2,
    'rubs': 1,
    'ruby': 2,
    'rucci': 2,
    'ruch': 1,
    'ruchlamer': 3,
    'ruchti': 2,
    'rucinski': 3,
    'ruck': 1,
    'ruckel': 2,
    'ruckelshaus': 3,
    'rucker': 2,
    'ruckert': 2,
    'rucki': 2,
    'ruckle': 2,
    'ruckman': 2,
    'rucks': 1,
    'ruckus': 2,
    'rud': 1,
    'ruda': 2,
    'rudani': 3,
    'rudasill': 3,
    'rudd': 1,
    'ruddell': 2,
    'rudden': 2,
    'rudder': 2,
    'rudderless': 3,
    'ruddick': 2,
    'ruddle': 2,
    'ruddock': 2,
    'ruddy': 2,
    'rude': 1,
    'rudeen': 2,
    'rudel': 2,
    'rudell': 2,
    'rudelle': 2,
    'rudely': 2,
    'ruden': 2,
    'rudeness': 2,
    'rudenstein': 3,
    'rudenstine': 3,
    'ruder': 2,
    'ruderman': 3,
    'rudesill': 3,
    'rudest': 2,
    'rudge': 1,
    'rudi': 2,
    'rudich': 2,
    'rudicker': 3,
    'rudie': 2,
    'rudiger': 3,
    'rudiment': 3,
    'rudimentary': 5,
    'rudiments': 3,
    'rudin': 2,
    'ruding': 2,
    'rudis': 2,
    'rudisill': 3,
    'rudkin': 2,
    'rudloff': 2,
    'rudman': 2,
    'rudner': 2,
    'rudnet': 2,
    'rudnick': 2,
    'rudnicki': 3,
    'rudnicky': 3,
    'rudnik': 2,
    'rudolf': 2,
    'rudolph': 2,
    'rudow': 2,
    'rudy': 2,
    'rudyard': 2,
    'rudzinski': 3,
    'rue': 1,
    'rueb': 1,
    'ruebel': 2,
    'rueckert': 2,
    'rueda': 3,
    'ruediger': 3,
    'ruedlinger': 3,
    'ruedy': 2,
    'ruef': 1,
    'rueff': 1,
    'rueful': 2,
    'ruefully': 3,
    'rueger': 2,
    'ruegg': 1,
    'ruegsegger': 3,
    'ruehl': 1,
    'ruehle': 2,
    'ruel': 1,
    'ruelas': 2,
    'ruella': 3,
    'ruelle': 2,
    'ruesch': 1,
    'ruess': 1,
    'ruest': 1,
    'rueter': 2,
    'rueth': 1,
    'ruether': 2,
    'ruetz': 1,
    'ruf': 1,
    'rufe': 1,
    'rufenacht': 3,
    'rufener': 3,
    'rufer': 2,
    'ruff': 1,
    'ruffalo': 3,
    'ruffcorn': 2,
    'ruffer': 2,
    'ruffin': 2,
    'ruffing': 2,
    'ruffini': 3,
    'ruffino': 3,
    'ruffle': 2,
    'ruffled': 2,
    'ruffles': 2,
    'ruffling': 3,
    'ruffner': 2,
    'ruffo': 2,
    'ruffolo': 3,
    'rufford': 2,
    'rufina': 3,
    'rufo': 2,
    'ruford': 2,
    'rufow': 2,
    'rufty': 2,
    'rufus': 2,
    'rug': 1,
    'rugby': 2,
    'ruge': 1,
    'rugen': 2,
    'ruger': 2,
    'rugeroni': 4,
    'rugg': 1,
    'rugged': 2,
    'ruggedly': 3,
    'ruggeri': 3,
    'ruggerio': 4,
    'ruggieri': 3,
    'ruggiero': 3,
    'ruggirello': 4,
    'ruggles': 2,
    'rugh': 1,
    'rugs': 1,
    'ruh': 1,
    'ruhe': 1,
    'ruhl': 1,
    'ruhland': 2,
    'ruhle': 2,
    'ruhlman': 2,
    'ruhnau': 2,
    'ruhnke': 1,
    'ruhollah': 3,
    'ruhr': 1,
    'ruhrgas': 2,
    'ruhul': 2,
    'ruin': 2,
    'ruined': 2,
    'ruining': 3,
    'ruinous': 3,
    'ruins': 2,
    'ruis': 2,
    'ruiter': 2,
    'ruiz': 2,
    'rujith': 2,
    'rukavina': 4,
    'rukeyser': 3,
    'rula': 2,
    'ruland': 2,
    'rule': 1,
    'rulebook': 2,
    'ruled': 1,
    'rulemaking': 3,
    'ruler': 2,
    'rulers': 2,
    'rules': 1,
    'ruley': 2,
    'ruling': 2,
    'rulings': 2,
    'rulison': 3,
    'rulli': 2,
    'rullman': 2,
    'rullo': 2,
    'rulon': 2,
    'rum': 1,
    'rumack': 2,
    'rumage': 2,
    'ruman': 2,
    'rumania': 4,
    'rumanian': 4,
    'rumbaugh': 2,
    'rumberger': 3,
    'rumble': 2,
    'rumbled': 2,
    'rumbles': 2,
    'rumbley': 2,
    'rumbling': 3,
    'rumblings': 3,
    'rumbold': 2,
    'rumen': 2,
    'rumer': 2,
    'rumery': 3,
    'rumfelt': 2,
    'rumford': 2,
    'rumina': 3,
    'ruminant': 3,
    'ruminate': 3,
    'ruminated': 4,
    'rumination': 4,
    'ruminations': 4,
    'ruminski': 3,
    'rumler': 2,
    'rumley': 2,
    'rummage': 2,
    'rummaged': 2,
    'rummages': 3,
    'rummaging': 3,
    'rummel': 2,
    'rummell': 2,
    'rummy': 2,
    'rumney': 2,
    'rumor': 2,
    'rumore': 2,
    'rumored': 2,
    'rumors': 2,
    'rumour': 2,
    'rumours': 2,
    'rump': 1,
    'rumpel': 2,
    'rumpf': 1,
    'rumph': 1,
    'rumple': 2,
    'rumpled': 2,
    'rumrill': 2,
    'rums': 1,
    'rumsey': 2,
    'rumsfeld': 2,
    'rumson': 2,
    'run': 1,
    'runabout': 3,
    'runaround': 3,
    'runaway': 3,
    'runaways': 3,
    'runcie': 2,
    'runck': 1,
    'runckel': 2,
    'runco': 2,
    'rund': 1,
    'rundall': 2,
    'runde': 1,
    'rundell': 2,
    'rundle': 2,
    'rundown': 2,
    'rundquist': 2,
    'rune': 1,
    'runes': 1,
    'rung': 1,
    'runge': 1,
    'rungs': 1,
    'runion': 2,
    'runions': 2,
    'runk': 1,
    'runkel': 2,
    'runkle': 2,
    'runkles': 2,
    'runko': 2,
    'runnells': 2,
    'runnels': 2,
    'runner': 2,
    'runners': 2,
    'running': 2,
    'runnings': 2,
    'runnion': 2,
    'runny': 2,
    'runoff': 2,
    'runquist': 2,
    'runs': 1,
    'runte': 1,
    'runup': 2,
    'runups': 2,
    'runway': 2,
    'runways': 2,
    'runyan': 2,
    'runyon': 2,
    'runzheimer': 3,
    'ruocco': 3,
    'ruoff': 2,
    'ruotolo': 4,
    'rupard': 2,
    'rupaul': 2,
    'rupe': 1,
    'rupee': 2,
    'rupees': 2,
    'rupert': 2,
    'ruperta': 3,
    'ruphy': 2,
    'rupiah': 3,
    'ruple': 2,
    'rupley': 2,
    'rupnow': 2,
    'ruposi': 3,
    'rupp': 1,
    'ruppe': 1,
    'ruppel': 2,
    'ruppenthal': 3,
    'ruppert': 2,
    'rupprecht': 2,
    'ruprecht': 2,
    'rupture': 2,
    'ruptured': 2,
    'ruptures': 2,
    'rupturing': 3,
    'rural': 2,
    'rurik': 2,
    'ruritania': 5,
    'rus': 1,
    'rusalka': 3,
    'rusch': 1,
    'rusche': 1,
    'ruscitti': 3,
    'rusconi': 3,
    'ruse': 1,
    'rused': 1,
    'ruseler': 3,
    'ruses': 2,
    'rush': 1,
    'rushdie': 2,
    'rushed': 1,
    'rusher': 2,
    'rushes': 2,
    'rushford': 2,
    'rushin': 2,
    'rushing': 2,
    'rushlow': 2,
    'rushmore': 2,
    'rushton': 2,
    'rusin': 2,
    'rusinko': 3,
    'rusk': 1,
    'ruskin': 2,
    'ruslan': 2,
    'rusnak': 2,
    'rusnock': 2,
    'russ': 1,
    'russa': 2,
    'russak': 2,
    'russe': 1,
    'russel': 2,
    'russell': 2,
    'russellville': 3,
    'russert': 2,
    'russet': 2,
    'russets': 2,
    'russett': 2,
    'russey': 2,
    'russi': 2,
    'russia': 2,
    'russian': 2,
    'russians': 2,
    'russification': 5,
    'russified': 3,
    'russin': 2,
    'russler': 2,
    'russman': 2,
    'russo': 2,
    'russolillo': 4,
    'russom': 2,
    'russomanno': 4,
    'russow': 2,
    'russum': 2,
    'rust': 1,
    'rustad': 2,
    'rusted': 2,
    'rustic': 2,
    'rustier': 3,
    'rustiest': 3,
    'rustigan': 3,
    'rustin': 2,
    'rusting': 2,
    'rustle': 2,
    'rustler': 2,
    'rustlers': 3,
    'rustling': 2,
    'ruston': 2,
    'rustproof': 2,
    'rustproofing': 3,
    'rusts': 1,
    'rusty': 2,
    'rustys': 2,
    'ruszkowski': 3,
    'rut': 1,
    'ruta': 2,
    'rutabaga': 4,
    'rutabagas': 4,
    'rutan': 2,
    'ruter': 2,
    'rutgard': 2,
    'rutger': 2,
    'rutgers': 2,
    'ruth': 1,
    'ruthann': 2,
    'ruthenberg': 3,
    'ruther': 2,
    'rutherford': 3,
    'ruthie': 2,
    'ruthless': 2,
    'ruthlessly': 3,
    'ruthlessness': 3,
    'ruthven': 2,
    'rutigliano': 5,
    'rutile': 2,
    'rutkoski': 3,
    'rutkowski': 3,
    'rutland': 2,
    'rutledge': 2,
    'rutley': 2,
    'rutman': 2,
    'ruts': 1,
    'rutskoi': 2,
    'rutstein': 2,
    'rutt': 1,
    'ruttan': 2,
    'rutted': 2,
    'rutten': 2,
    'ruttenberg': 3,
    'rutter': 2,
    'ruttle': 2,
    'ruttles': 2,
    'rutts': 1,
    'rutz': 1,
    'rutzen': 2,
    'ruud': 1,
    'ruvalcaba': 4,
    'ruvolo': 3,
    'ruwe': 1,
    'rux': 1,
    'ruxpin': 2,
    'ruybal': 2,
    'ruyle': 1,
    'ruzek': 2,
    'ruzich': 2,
    'ruzicka': 3,
    'ruzzo': 2,
    'rwanda': 3,
    'rwandan': 3,
    'rwandans': 3,
    'rwandese': 3,
    'ryal': 2,
    'ryall': 2,
    'ryals': 2,
    'ryan': 2,
    'ryanair': 3,
    'ryanodine': 4,
    'ryans': 2,
    'ryava': 3,
    'ryave': 2,
    'ryba': 2,
    'ryback': 2,
    'rybacki': 3,
    'rybak': 2,
    'rybakov': 3,
    'rybarczyk': 3,
    'ryberg': 2,
    'rybicki': 3,
    'rybinski': 3,
    'rybka': 2,
    'rybolt': 2,
    'ryburn': 2,
    'rychlik': 2,
    'ryckman': 2,
    'rycroft': 2,
    'ryczek': 2,
    'rydberg': 2,
    'rydell': 2,
    'ryden': 2,
    'ryder': 2,
    'rydman': 2,
    'rydzewski': 3,
    'rye': 1,
    'ryen': 2,
    'ryer': 2,
    'ryerson': 2,
    'rygiel': 2,
    'ryka': 2,
    'ryker': 2,
    'rykiel': 2,
    'rykoff': 2,
    'rykowski': 3,
    'rylan': 2,
    'ryland': 2,
    'rylander': 3,
    'ryle': 1,
    'rylee': 2,
    'ryles': 1,
    'ryley': 2,
    'ryman': 2,
    'rymer': 2,
    'rynders': 2,
    'rynearson': 3,
    'ryner': 2,
    'rynes': 1,
    'ryno': 2,
    'ryobi': 3,
    'ryohei': 3,
    'ryon': 2,
    'ryrie': 2,
    'rys': 1,
    'rysavy': 3,
    'ryser': 2,
    'ryskamp': 2,
    'ryther': 2,
    'ryton': 2,
    'ryutaro': 3,
    'ryuzo': 3,
    'ryzhkov': 2,
    'rzasa': 3,
    'rzepka': 3,
    's': 1,
    'sa': 1,
    'saab': 1,
    'saad': 1,
    'saal': 1,
    'saam': 1,
    'saamstaan': 2,
    'saar': 1,
    'saari': 2,
    'saarinen': 3,
    'saatchi': 2,
    'saathoff': 2,
    'saavedra': 3,
    'saba': 2,
    'sabah': 2,
    'sabala': 3,
    'sabalan': 3,
    'saban': 2,
    'sabas': 2,
    'sabat': 2,
    'sabatine': 4,
    'sabatini': 4,
    'sabatino': 4,
    'sabatista': 4,
    'sabatistas': 4,
    'sabatka': 3,
    'sabato': 3,
    'sabaudia': 4,
    'sabb': 1,
    'sabbagh': 2,
    'sabbath': 2,
    'sabbaths': 2,
    'sabbatical': 4,
    'sabbaticals': 4,
    'sabedra': 3,
    'sabel': 2,
    'sabella': 3,
    'sabena': 3,
    'saber': 2,
    'saberbein': 3,
    'sabers': 2,
    'sabertech': 3,
    'sabey': 2,
    'sabia': 3,
    'sabin': 2,
    'sabina': 3,
    'sabine': 2,
    'sabino': 3,
    'sabins': 2,
    'sabir': 2,
    'sablan': 2,
    'sable': 2,
    'sables': 2,
    'sabo': 2,
    'sabol': 2,
    'sabortech': 3,
    'sabotage': 3,
    'sabotaged': 3,
    'sabotaging': 4,
    'saboteur': 3,
    'saboteurs': 3,
    'sabourin': 3,
    'sabra': 2,
    'sabre': 2,
    'sabres': 2,
    'sabretech': 3,
    'sabrina': 3,
    'sabry': 2,
    'sabta': 2,
    'sabunuma': 4,
    'sac': 1,
    'sacca': 2,
    'saccharin': 3,
    'saccharine': 3,
    'sacco': 2,
    'saccoccio': 4,
    'saccomanno': 4,
    'saccone': 3,
    'saccule': 2,
    'sacha': 2,
    'sachdev': 2,
    'sacher': 2,
    'sachet': 2,
    'sachi': 2,
    'sachin': 2,
    'sachs': 1,
    'sachse': 1,
    'sacilor': 3,
    'sacirbey': 3,
    'sack': 1,
    'sacked': 1,
    'sackett': 2,
    'sacking': 2,
    'sackler': 2,
    'sackman': 2,
    'sackmann': 2,
    'sackrider': 3,
    'sacks': 1,
    'sacksteder': 3,
    'sackville': 2,
    'saco': 2,
    'sacra': 2,
    'sacrament': 3,
    'sacramental': 4,
    'sacramento': 4,
    'sacraments': 3,
    'sacred': 2,
    'sacredness': 3,
    'sacrifice': 3,
    'sacrificed': 3,
    'sacrifices': 4,
    'sacrificial': 4,
    'sacrificing': 4,
    'sacrilege': 3,
    'sacrilegious': 4,
    'sacrosanct': 3,
    'sacs': 1,
    'sad': 1,
    'sada': 2,
    'sadaka': 3,
    'sadako': 3,
    'sadao': 2,
    'sadat': 2,
    'saddam': 2,
    'sadden': 2,
    'saddened': 2,
    'saddening': 3,
    'saddens': 2,
    'sadder': 2,
    'saddest': 2,
    'saddle': 2,
    'saddlebag': 3,
    'saddlebags': 3,
    'saddled': 2,
    'saddler': 3,
    'saddles': 2,
    'saddling': 3,
    'saddoris': 3,
    'sadeghi': 3,
    'sadeh': 2,
    'sadek': 2,
    'sadella': 3,
    'sader': 2,
    'sadie': 2,
    'sadira': 3,
    'sadism': 3,
    'sadist': 2,
    'sadistic': 3,
    'sadler': 2,
    'sadlier': 3,
    'sadlon': 2,
    'sadlowski': 3,
    'sadly': 2,
    'sadness': 2,
    'sado': 2,
    'sadoff': 2,
    'sadowski': 3,
    'sadowsky': 3,
    'sadye': 2,
    'saechao': 2,
    'saeed': 2,
    'saeger': 2,
    'saehan': 2,
    'saelens': 2,
    'saemaul': 2,
    'saenger': 2,
    'saenz': 1,
    'saez': 1,
    'safari': 3,
    'safarik': 3,
    'safaris': 3,
    'safdie': 2,
    'safe': 1,
    'safecard': 2,
    'safeco': 2,
    'safeguard': 2,
    'safeguarded': 3,
    'safeguarding': 3,
    'safeguards': 2,
    'safehouse': 2,
    'safekeeping': 3,
    'safely': 2,
    'safer': 2,
    'safes': 1,
    'safest': 2,
    'safeties': 2,
    'safety': 2,
    'safeway': 2,
    'saffell': 2,
    'saffer': 2,
    'safferstein': 3,
    'saffir': 2,
    'saffle': 2,
    'safflower': 3,
    'saffold': 2,
    'safford': 2,
    'saffran': 2,
    'saffron': 2,
    'safian': 3,
    'safier': 2,
    'safing': 2,
    'safir': 2,
    'safire': 2,
    'safko': 2,
    'safley': 2,
    'safra': 2,
    'safran': 2,
    'safranek': 3,
    'safrit': 2,
    'sag': 1,
    'saga': 2,
    'sagan': 2,
    'sagansky': 3,
    'sagar': 2,
    'sagas': 2,
    'sage': 1,
    'sagebrush': 2,
    'sagen': 2,
    'sager': 2,
    'sagers': 2,
    'sages': 2,
    'sagged': 1,
    'saggese': 2,
    'sagging': 2,
    'saginaw': 3,
    'sagittal': 3,
    'sago': 2,
    'sagona': 3,
    'sagraves': 3,
    'sags': 1,
    'saguaro': 3,
    'saguenay': 3,
    'saha': 2,
    'sahagian': 4,
    'sahagun': 3,
    'sahara': 3,
    'saharan': 3,
    'sahgal': 2,
    'sahl': 1,
    'sahlen': 2,
    'sahli': 2,
    'sahlin': 2,
    'sahlman': 2,
    'sahm': 1,
    'sahr': 1,
    'sai': 1,
    'saia': 2,
    'said': 1,
    'saidan': 2,
    'saidiner': 3,
    'saif': 2,
    'saigon': 2,
    'saiki': 2,
    'sail': 1,
    'sailboat': 2,
    'sailboats': 2,
    'sailed': 1,
    'sailer': 2,
    'sailfish': 2,
    'sailing': 2,
    'sailor': 2,
    'sailors': 2,
    'sails': 1,
    'sain': 1,
    'sainato': 3,
    'saindon': 2,
    'saine': 1,
    'sainsbury': 3,
    'saint': 1,
    'sainted': 2,
    'sainthood': 2,
    'saintly': 2,
    'saints': 1,
    'sainz': 1,
    'saipan': 2,
    'sais': 1,
    'saison': 2,
    'saitama': 4,
    'saito': 2,
    'saitta': 2,
    'saiz': 1,
    'sajak': 2,
    'sajdak': 2,
    'sak': 1,
    'sakaguchi': 4,
    'sakai': 3,
    'sakal': 2,
    'sakamoto': 4,
    'sakata': 3,
    'sakau': 3,
    'sake': 1,
    'saker': 2,
    'sakes': 1,
    'sakhalin': 3,
    'sakharov': 3,
    'saki': 2,
    'sakigake': 4,
    'sakina': 3,
    'sako': 2,
    'sakowitz': 3,
    'sakowski': 3,
    'saks': 1,
    'saksa': 2,
    'saksida': 3,
    'sakuma': 3,
    'sakura': 3,
    'sakurai': 4,
    'sal': 1,
    'sala': 2,
    'salaam': 2,
    'salable': 3,
    'salables': 3,
    'salacious': 3,
    'salad': 2,
    'salada': 3,
    'saladin': 3,
    'saladino': 4,
    'salads': 2,
    'salafia': 4,
    'salah': 2,
    'salak': 2,
    'salam': 2,
    'salama': 3,
    'salamanca': 4,
    'salamander': 4,
    'salamanders': 4,
    'salameh': 3,
    'salami': 3,
    'salamis': 3,
    'salamon': 3,
    'salamone': 3,
    'salant': 2,
    'salaried': 3,
    'salaries': 3,
    'salary': 3,
    'salarymen': 4,
    'salas': 2,
    'salata': 3,
    'salatino': 4,
    'salawah': 3,
    'salay': 2,
    'salaz': 2,
    'salazar': 3,
    'salberg': 2,
    'salce': 1,
    'salcedo': 3,
    'salcido': 3,
    'saldana': 3,
    'saldivar': 3,
    'saldovar': 3,
    'sale': 1,
    'saleable': 3,
    'saleeby': 3,
    'saleem': 2,
    'saleh': 2,
    'salek': 2,
    'salem': 2,
    'salema': 3,
    'salemi': 3,
    'salemme': 2,
    'salerno': 3,
    'sales': 1,
    'salesman': 2,
    'salesmanship': 3,
    'salesmen': 2,
    'salespeople': 3,
    'salesperson': 3,
    'saleswoman': 3,
    'saleswomen': 3,
    'salgado': 3,
    'salguero': 3,
    'salhany': 3,
    'saliba': 3,
    'salick': 2,
    'salient': 3,
    'salif': 2,
    'saligman': 3,
    'salim': 2,
    'salin': 2,
    'salina': 3,
    'salinas': 3,
    'saline': 2,
    'saling': 2,
    'salinger': 3,
    'salingers': 3,
    'salinity': 4,
    'salis': 2,
    'salisbury': 3,
    'saliva': 3,
    'salivate': 3,
    'salivated': 4,
    'salivating': 4,
    'salizzoni': 4,
    'salk': 1,
    'salkeld': 2,
    'salkin': 2,
    'sall': 1,
    'sallade': 2,
    'sallas': 2,
    'salle': 1,
    'sallee': 2,
    'salleh': 2,
    'saller': 2,
    'salles': 2,
    'salley': 2,
    'sallie': 2,
    'sallies': 2,
    'salling': 2,
    'sallis': 2,
    'salls': 1,
    'sally': 2,
    'salm': 1,
    'salman': 2,
    'salmans': 2,
    'salmela': 3,
    'salmen': 2,
    'salmeron': 3,
    'salmi': 2,
    'salminen': 3,
    'salmon': 2,
    'salmond': 2,
    'salmonella': 4,
    'salmons': 2,
    'salmonson': 3,
    'salo': 2,
    'salois': 2,
    'saloma': 3,
    'salome': 3,
    'salomi': 3,
    'salomon': 3,
    'salomone': 4,
    'salon': 2,
    'salone': 2,
    'salonga': 3,
    'salons': 2,
    'saloojee': 3,
    'saloon': 2,
    'saloons': 2,
    'salopek': 3,
    'salow': 2,
    'salpetriere': 4,
    'salsa': 2,
    'salsberry': 3,
    'salsbery': 3,
    'salsbury': 3,
    'salser': 2,
    'salsgiver': 3,
    'salsman': 2,
    'salt': 1,
    'saltarelli': 4,
    'saltbox': 2,
    'salted': 2,
    'salter': 2,
    'salters': 2,
    'saltier': 3,
    'salting': 2,
    'saltlike': 2,
    'saltmarsh': 2,
    'salton': 2,
    'salts': 1,
    'saltsman': 2,
    'saltwater': 3,
    'salty': 2,
    'saltz': 1,
    'saltzburg': 2,
    'saltzman': 2,
    'salus': 2,
    'salutary': 4,
    'salutatorian': 6,
    'salute': 2,
    'saluted': 3,
    'salutes': 2,
    'saluting': 3,
    'salva': 2,
    'salvador': 3,
    'salvadoran': 4,
    'salvadorans': 4,
    'salvadore': 4,
    'salvage': 2,
    'salvageable': 4,
    'salvaged': 2,
    'salvager': 3,
    'salvagers': 3,
    'salvaggio': 4,
    'salvaging': 3,
    'salvas': 2,
    'salvati': 3,
    'salvatierra': 4,
    'salvation': 3,
    'salvato': 3,
    'salvatore': 4,
    'salvatori': 4,
    'salve': 1,
    'salves': 1,
    'salvesen': 3,
    'salveson': 3,
    'salvetti': 3,
    'salvi': 2,
    'salvia': 3,
    'salvigsen': 3,
    'salvigsten': 3,
    'salvina': 3,
    'salvino': 3,
    'salvo': 2,
    'salvos': 2,
    'salvucci': 3,
    'salway': 2,
    'salwen': 2,
    'salyard': 2,
    'salyards': 2,
    'salyer': 3,
    'salyers': 3,
    'salz': 1,
    'salzano': 3,
    'salzberg': 2,
    'salzburg': 2,
    'salzer': 2,
    'salzgitter': 3,
    'salzhauer': 2,
    'salzman': 2,
    'salzmann': 2,
    'salzwedel': 3,
    'sam': 1,
    'sama': 2,
    'samaha': 3,
    'samaniego': 4,
    'samano': 3,
    'samantha': 3,
    'samar': 2,
    'samara': 3,
    'samaranch': 3,
    'samaras': 3,
    'samaria': 4,
    'samarin': 3,
    'samaritan': 4,
    'samaritans': 4,
    'samarkand': 3,
    'samatar': 3,
    'samba': 2,
    'samberg': 2,
    'sambo': 2,
    'samborski': 3,
    'sambrano': 3,
    'sambre': 2,
    'sambuca': 3,
    'samcor': 2,
    'same': 1,
    'samedan': 3,
    'sameer': 2,
    'samek': 2,
    'samella': 3,
    'samelle': 2,
    'samelson': 3,
    'sameness': 2,
    'samerol': 3,
    'sames': 1,
    'samet': 2,
    'samford': 2,
    'sami': 2,
    'samina': 3,
    'samir': 2,
    'sammarco': 3,
    'sammartino': 4,
    'sammet': 2,
    'sammie': 2,
    'sammis': 2,
    'sammon': 2,
    'sammons': 2,
    'samms': 1,
    'sammut': 2,
    'sammy': 2,
    'samoa': 3,
    'samoan': 3,
    'samojlik': 3,
    'samons': 2,
    'samora': 3,
    'samos': 2,
    'samoth': 2,
    'samp': 1,
    'sampan': 2,
    'sampans': 2,
    'samper': 2,
    'sampere': 2,
    'sampey': 2,
    'sample': 2,
    'sampled': 2,
    'sampler': 2,
    'samplers': 2,
    'samples': 2,
    'sampley': 2,
    'sampling': 2,
    'samplings': 2,
    'sampras': 2,
    'sampre': 2,
    'sampsel': 2,
    'sampsell': 2,
    'sampson': 2,
    'samra': 2,
    'sams': 1,
    'samsara': 3,
    'samsel': 2,
    'samsom': 2,
    'samson': 2,
    'samsonite': 3,
    'samsung': 2,
    'samudio': 4,
    'samuel': 2,
    'samuela': 4,
    'samuelle': 3,
    'samuels': 3,
    'samuelsen': 3,
    'samuelson': 4,
    'samurai': 3,
    'samurais': 3,
    'san': 1,
    'sana': 2,
    'sanaa': 2,
    'sanabria': 4,
    'sanada': 3,
    'sanborn': 2,
    'sanches': 2,
    'sanchez': 2,
    'sancho': 2,
    'sancia': 2,
    'sanctification': 5,
    'sanctify': 3,
    'sanctimonious': 5,
    'sanctimony': 4,
    'sanction': 2,
    'sanctioned': 2,
    'sanctioning': 3,
    'sanctions': 2,
    'sanctity': 3,
    'sanctuaries': 4,
    'sanctuary': 4,
    'sanctum': 2,
    'sand': 1,
    'sanda': 2,
    'sandage': 2,
    'sandahl': 2,
    'sandal': 2,
    'sandall': 2,
    'sandalo': 3,
    'sandals': 2,
    'sandau': 2,
    'sandbag': 2,
    'sandbagged': 2,
    'sandbagger': 3,
    'sandbaggers': 3,
    'sandbagging': 3,
    'sandbags': 2,
    'sandbar': 2,
    'sandberg': 2,
    'sandblast': 2,
    'sandblasted': 3,
    'sandbox': 2,
    'sandburg': 2,
    'sande': 1,
    'sanded': 2,
    'sandeen': 2,
    'sandefer': 3,
    'sandefur': 3,
    'sandel': 2,
    'sandell': 2,
    'sanden': 2,
    'sander': 2,
    'sanderfer': 3,
    'sanderford': 3,
    'sanderlin': 3,
    'sanders': 2,
    'sanderson': 3,
    'sandford': 2,
    'sandgren': 2,
    'sandhill': 2,
    'sandhog': 2,
    'sandhogs': 2,
    'sandhu': 2,
    'sandi': 2,
    'sandia': 3,
    'sandidge': 2,
    'sandie': 2,
    'sandifer': 3,
    'sandiford': 3,
    'sandin': 2,
    'sanding': 2,
    'sandinista': 4,
    'sandinistas': 4,
    'sandino': 3,
    'sandler': 2,
    'sandlin': 2,
    'sandling': 2,
    'sandman': 2,
    'sandmann': 2,
    'sandmeyer': 3,
    'sandner': 2,
    'sandness': 2,
    'sando': 2,
    'sandom': 2,
    'sandon': 2,
    'sandor': 2,
    'sandos': 2,
    'sandose': 2,
    'sandoval': 3,
    'sandow': 2,
    'sandoz': 2,
    'sandpaper': 3,
    'sandpoint': 2,
    'sandquist': 2,
    'sandra': 2,
    'sandridge': 2,
    'sandro': 2,
    'sandrock': 2,
    'sands': 1,
    'sandstone': 2,
    'sandstorm': 2,
    'sandstorms': 2,
    'sandstrom': 2,
    'sandt': 1,
    'sandtown': 2,
    'sandusky': 3,
    'sandvig': 2,
    'sandvik': 2,
    'sandwich': 2,
    'sandwiched': 2,
    'sandwiches': 3,
    'sandwick': 2,
    'sandy': 2,
    'sane': 1,
    'saner': 2,
    'sanfilippo': 4,
    'sanford': 2,
    'sang': 1,
    'sanger': 2,
    'sangiovese': 4,
    'sangster': 2,
    'sanguine': 2,
    'sanguinetti': 4,
    'sangyo': 2,
    'sanhedrin': 3,
    'sani': 2,
    'sanitary': 4,
    'sanitation': 4,
    'sanitize': 3,
    'sanitized': 3,
    'sanitizing': 4,
    'sanity': 3,
    'sanjay': 2,
    'sanjiv': 2,
    'sanjuan': 3,
    'sank': 1,
    'sanka': 2,
    'sankara': 3,
    'sankei': 2,
    'sanker': 2,
    'sankey': 2,
    'sanko': 2,
    'sanks': 1,
    'sankyo': 2,
    'sanmark': 2,
    'sanmartin': 3,
    'sanmiguel': 3,
    'sanna': 2,
    'sanner': 2,
    'sannes': 1,
    'sano': 2,
    'sanofi': 3,
    'sanrio': 3,
    'sans': 1,
    'sansbury': 3,
    'sanseverino': 5,
    'sansing': 2,
    'sanskrit': 2,
    'sanso': 2,
    'sansom': 2,
    'sanson': 2,
    'sansone': 2,
    'sansoucie': 3,
    'sansui': 3,
    'sant': 1,
    'santa': 2,
    'santagata': 4,
    'santala': 3,
    'santana': 3,
    'santander': 3,
    'santangelo': 4,
    'santaniello': 5,
    'santarelli': 4,
    'santarsiero': 4,
    'santas': 2,
    'santayana': 4,
    'sante': 2,
    'santella': 3,
    'santelli': 3,
    'santer': 2,
    'santeria': 4,
    'santerre': 2,
    'santi': 2,
    'santia': 2,
    'santiago': 4,
    'santidad': 3,
    'santiesteban': 4,
    'santillan': 3,
    'santillanes': 3,
    'santilli': 3,
    'santillo': 3,
    'santin': 2,
    'santini': 3,
    'santino': 3,
    'santistevan': 4,
    'santo': 2,
    'santon': 2,
    'santone': 3,
    'santoni': 3,
    'santopietro': 4,
    'santor': 2,
    'santora': 3,
    'santore': 3,
    'santorelli': 4,
    'santoro': 3,
    'santorum': 3,
    'santos': 2,
    'santosh': 2,
    'santosuosso': 4,
    'santow': 2,
    'santoyo': 3,
    'santry': 2,
    'santucci': 3,
    'santulli': 3,
    'santy': 2,
    'sanville': 2,
    'sanwa': 2,
    'sanyo': 2,
    'sanz': 1,
    'sanzo': 2,
    'sanzone': 3,
    'sao': 1,
    'saone': 2,
    'saouma': 3,
    'sap': 1,
    'sapanski': 3,
    'saperstein': 3,
    'saphira': 3,
    'sapia': 3,
    'sapien': 2,
    'sapiens': 3,
    'sapienza': 3,
    'sapiro': 3,
    'sapling': 2,
    'saplings': 2,
    'sapoa': 3,
    'sapone': 3,
    'saponification': 6,
    'saporito': 4,
    'sapp': 1,
    'sapped': 1,
    'sappenfield': 3,
    'sapper': 2,
    'sapphire': 3,
    'sapping': 2,
    'sappington': 3,
    'sapporo': 3,
    'saps': 1,
    'saputo': 3,
    'sar': 1,
    'sara': 2,
    'sarabia': 4,
    'saracco': 3,
    'saraceni': 4,
    'saraceno': 4,
    'saracens': 3,
    'saracino': 4,
    'sarafian': 4,
    'sarafin': 3,
    'sarafina': 4,
    'sarage': 2,
    'sarah': 2,
    'sarajevan': 4,
    'sarajevans': 4,
    'sarajevo': 4,
    'saran': 2,
    'sarandon': 3,
    'sarant': 2,
    'sarasin': 3,
    'sarasota': 4,
    'saratoga': 4,
    'saratov': 3,
    'saravia': 4,
    'sarawak': 3,
    'sarazen': 3,
    'sarazin': 3,
    'sarbane': 2,
    'sarbanes': 2,
    'sarber': 2,
    'sarcasm': 3,
    'sarcastic': 3,
    'sarcastically': 4,
    'sarchet': 2,
    'sarcoma': 3,
    'sarcophagus': 4,
    'sardas': 2,
    'sardella': 3,
    'sardina': 3,
    'sardinas': 3,
    'sardine': 2,
    'sardines': 2,
    'sardinha': 3,
    'sardinia': 4,
    'sardo': 2,
    'sardonic': 3,
    'sardonically': 5,
    'sare': 1,
    'sarejevo': 4,
    'sarene': 2,
    'sarette': 2,
    'sarff': 1,
    'sargasso': 3,
    'sarge': 1,
    'sargeant': 2,
    'sargen': 2,
    'sargent': 2,
    'sargis': 2,
    'sari': 2,
    'sarich': 2,
    'sarin': 2,
    'sarine': 3,
    'saris': 2,
    'sarisi': 3,
    'sark': 1,
    'sarka': 2,
    'sarkar': 2,
    'sarkis': 2,
    'sarkisian': 3,
    'sarkissian': 3,
    'sarles': 1,
    'sarli': 2,
    'sarlo': 2,
    'sarlos': 2,
    'sarmatian': 3,
    'sarmento': 3,
    'sarmiento': 3,
    'sarna': 2,
    'sarney': 2,
    'sarni': 2,
    'sarno': 2,
    'sarnoff': 2,
    'sarnowski': 3,
    'sarofim': 3,
    'sarokin': 3,
    'sarong': 2,
    'sarongs': 2,
    'saros': 2,
    'sarowiwa': 4,
    'sarra': 2,
    'sarratt': 2,
    'sarrazin': 3,
    'sarrett': 2,
    'sarris': 2,
    'sarro': 2,
    'sars': 1,
    'sarsfield': 2,
    'sartain': 2,
    'sarti': 2,
    'sartin': 2,
    'sartor': 2,
    'sartori': 3,
    'sartorial': 4,
    'sartorius': 4,
    'sartrain': 2,
    'sartre': 2,
    'sartwell': 2,
    'sarum': 2,
    'sarver': 2,
    'sarvis': 2,
    'sas': 1,
    'sasaki': 3,
    'sasayama': 4,
    'sasek': 2,
    'sash': 1,
    'sasha': 2,
    'sashes': 2,
    'saskatchewan': 4,
    'saskatoon': 3,
    'saslow': 2,
    'sasnett': 2,
    'sass': 1,
    'sassafras': 3,
    'sassaman': 3,
    'sassano': 3,
    'sasse': 1,
    'sasseen': 2,
    'sasser': 2,
    'sassi': 2,
    'sassman': 2,
    'sassnet': 2,
    'sassnets': 2,
    'sasso': 2,
    'sasson': 2,
    'sassone': 3,
    'sassoon': 2,
    'sassou': 2,
    'sassounguesso': 4,
    'sassulo': 3,
    'sassy': 2,
    'sat': 1,
    'satagaj': 3,
    'satan': 2,
    'satanic': 3,
    'satanism': 4,
    'satanist': 3,
    'satanjeev': 3,
    'satchell': 2,
    'satcher': 2,
    'sate': 1,
    'sated': 2,
    'satellite': 3,
    'satellites': 3,
    'sater': 2,
    'sates': 1,
    'sather': 2,
    'sathre': 2,
    'sathyavagiswaran': 6,
    'satin': 2,
    'satins': 2,
    'satire': 3,
    'satires': 3,
    'satiric': 3,
    'satirical': 4,
    'satirist': 3,
    'satirists': 3,
    'satirize': 3,
    'satirizes': 4,
    'satirizing': 4,
    'satisfaction': 4,
    'satisfactions': 4,
    'satisfactorily': 5,
    'satisfactory': 4,
    'satisfied': 3,
    'satisfies': 3,
    'satisfy': 3,
    'satisfying': 4,
    'satloff': 2,
    'sato': 2,
    'satoh': 2,
    'satoshi': 3,
    'satre': 2,
    'satriani': 4,
    'satter': 2,
    'satterfield': 3,
    'satterlee': 3,
    'satterly': 3,
    'satterwhite': 3,
    'sattler': 2,
    'saturate': 3,
    'saturated': 4,
    'saturating': 4,
    'saturation': 4,
    'saturday': 3,
    'saturdays': 3,
    'saturn': 2,
    'saturns': 2,
    'satya': 2,
    'satyandra': 3,
    'satz': 1,
    'sauber': 2,
    'sauce': 1,
    'sauceda': 3,
    'saucedo': 3,
    'saucepan': 2,
    'saucer': 2,
    'saucers': 2,
    'sauces': 2,
    'saucier': 3,
    'saucony': 3,
    'saucy': 2,
    'saud': 1,
    'sauder': 2,
    'saudi': 2,
    'saudia': 3,
    'saudiization': 5,
    'saudis': 2,
    'sauer': 2,
    'sauerkraut': 3,
    'sauers': 2,
    'sauerteig': 3,
    'sauerwein': 3,
    'sauey': 2,
    'saugerties': 3,
    'sauk': 1,
    'saul': 1,
    'saulnier': 3,
    'sauls': 1,
    'saulsberry': 3,
    'saulsbury': 3,
    'sault': 1,
    'saulter': 2,
    'saulters': 2,
    'saum': 1,
    'sauna': 2,
    'saunas': 2,
    'saunders': 2,
    'saunderson': 3,
    'saundra': 2,
    'saunier': 3,
    'saunter': 2,
    'saur': 1,
    'saurer': 2,
    'sauro': 2,
    'saus': 1,
    'sausage': 2,
    'sausages': 3,
    'sausalito': 4,
    'sause': 1,
    'sauseda': 3,
    'sauser': 2,
    'sausser': 2,
    'saute': 2,
    'sauteed': 2,
    'sauter': 3,
    'sauterne': 2,
    'sauternes': 2,
    'sautter': 2,
    'sauvage': 2,
    'sauvageau': 3,
    'sauve': 1,
    'sauvignon': 3,
    'sava': 2,
    'savage': 2,
    'savaged': 2,
    'savagely': 3,
    'savagery': 4,
    'savages': 3,
    'savaging': 3,
    'savaiko': 3,
    'savala': 3,
    'savalas': 3,
    'savanna': 3,
    'savannah': 3,
    'savannas': 3,
    'savant': 2,
    'savants': 2,
    'savard': 2,
    'savarese': 4,
    'savarino': 4,
    'savary': 3,
    'savas': 2,
    'savasta': 3,
    'savastano': 4,
    'save': 1,
    'saved': 1,
    'savedra': 3,
    'savel': 2,
    'savell': 2,
    'savely': 2,
    'saver': 2,
    'savers': 2,
    'savery': 3,
    'saves': 1,
    'saviano': 4,
    'savich': 2,
    'savickas': 3,
    'savidge': 2,
    'savier': 2,
    'savikas': 3,
    'savill': 2,
    'saville': 2,
    'savimbi': 3,
    'savin': 2,
    'savina': 3,
    'saving': 2,
    'savings': 2,
    'savini': 3,
    'savino': 3,
    'savio': 3,
    'savion': 3,
    'savior': 2,
    'saviors': 2,
    'savir': 2,
    'savitch': 2,
    'savitsky': 3,
    'savitt': 2,
    'savitz': 2,
    'savko': 2,
    'savo': 2,
    'savoca': 3,
    'savoia': 3,
    'savoie': 2,
    'savon': 2,
    'savona': 3,
    'savor': 2,
    'savored': 2,
    'savoring': 3,
    'savors': 2,
    'savory': 3,
    'savoy': 2,
    'savr': 2,
    'savviest': 3,
    'savvy': 2,
    'saw': 1,
    'sawa': 2,
    'sawall': 2,
    'sawasdee': 3,
    'sawatzky': 3,
    'sawaya': 3,
    'sawchuk': 2,
    'sawdey': 2,
    'sawdon': 2,
    'sawdust': 2,
    'sawdy': 2,
    'sawed': 1,
    'sawhill': 2,
    'sawicki': 3,
    'sawicz': 2,
    'sawin': 2,
    'sawing': 2,
    'sawka': 2,
    'sawmill': 2,
    'sawmills': 2,
    'saws': 1,
    'sawshank': 2,
    'sawtell': 2,
    'sawtelle': 2,
    'sawyer': 2,
    'sawyers': 2,
    'sax': 1,
    'saxby': 2,
    'saxe': 1,
    'saxena': 3,
    'saxer': 2,
    'saxman': 2,
    'saxon': 2,
    'saxona': 3,
    'saxons': 2,
    'saxony': 3,
    'saxophone': 3,
    'saxophones': 3,
    'saxophonist': 4,
    'saxton': 2,
    'say': 1,
    'sayad': 2,
    'saybrook': 2,
    'saye': 1,
    'sayed': 2,
    'sayegh': 2,
    'sayer': 2,
    'sayers': 2,
    'sayiid': 2,
    'saying': 2,
    'sayings': 2,
    'sayito': 3,
    'sayle': 1,
    'sayler': 2,
    'sayles': 1,
    'saylor': 2,
    'saylors': 2,
    'sayre': 1,
    'sayres': 1,
    'says': 1,
    'sayyid': 2,
    'sazama': 3,
    'sbarro': 2,
    'sbf': 3,
    'scab': 1,
    'scabbard': 2,
    'scabs': 1,
    'scaccia': 2,
    'scad': 1,
    'scadden': 2,
    'scads': 1,
    'scaduto': 3,
    'scaff': 1,
    'scaffidi': 3,
    'scaffold': 2,
    'scaffolding': 3,
    'scaffolds': 2,
    'scafidi': 3,
    'scaggs': 1,
    'scaglione': 4,
    'scags': 1,
    'scahill': 2,
    'scaife': 1,
    'scala': 2,
    'scalamandre': 4,
    'scalar': 2,
    'scald': 1,
    'scalded': 2,
    'scalding': 2,
    'scalds': 1,
    'scale': 1,
    'scaleatron': 4,
    'scaled': 1,
    'scalera': 3,
    'scales': 1,
    'scalese': 3,
    'scalf': 1,
    'scalfaro': 3,
    'scali': 2,
    'scalia': 3,
    'scaling': 2,
    'scalise': 2,
    'scalisi': 3,
    'scallan': 2,
    'scallion': 2,
    'scallions': 2,
    'scallon': 2,
    'scallop': 2,
    'scalloped': 2,
    'scallops': 2,
    'scally': 2,
    'scalp': 1,
    'scalped': 1,
    'scalpel': 2,
    'scalpels': 2,
    'scalper': 2,
    'scalpers': 2,
    'scalping': 2,
    'scalps': 1,
    'scalzi': 2,
    'scalzitti': 3,
    'scalzo': 2,
    'scam': 1,
    'scammed': 1,
    'scammell': 2,
    'scammer': 2,
    'scammers': 2,
    'scammon': 2,
    'scamper': 2,
    'scampered': 2,
    'scampering': 3,
    'scams': 1,
    'scan': 1,
    'scandal': 2,
    'scandalize': 3,
    'scandalized': 3,
    'scandalous': 3,
    'scandals': 2,
    'scandia': 3,
    'scandinavia': 5,
    'scandinavian': 5,
    'scandinavians': 5,
    'scania': 3,
    'scanlan': 2,
    'scanland': 2,
    'scanlin': 2,
    'scanlon': 2,
    'scanned': 1,
    'scannell': 2,
    'scanner': 2,
    'scanners': 2,
    'scanning': 2,
    'scans': 1,
    'scant': 1,
    'scantily': 3,
    'scantiness': 3,
    'scantlin': 2,
    'scanty': 2,
    'scapa': 2,
    'scape': 1,
    'scapegoat': 2,
    'scapegoated': 3,
    'scapegoating': 3,
    'scapegoats': 2,
    'scapula': 3,
    'scar': 1,
    'scaramouch': 3,
    'scarano': 3,
    'scarberry': 3,
    'scarborough': 3,
    'scarbro': 2,
    'scarbrough': 2,
    'scarce': 1,
    'scarcella': 3,
    'scarcely': 2,
    'scarcer': 2,
    'scarcity': 3,
    'scardina': 3,
    'scardino': 3,
    'scare': 1,
    'scarecrow': 2,
    'scared': 1,
    'scares': 1,
    'scarf': 1,
    'scarface': 2,
    'scarff': 1,
    'scarfo': 2,
    'scarfs': 1,
    'scargill': 2,
    'scarier': 3,
    'scariest': 3,
    'scaring': 2,
    'scarlata': 3,
    'scarlet': 2,
    'scarlett': 2,
    'scarola': 3,
    'scarp': 1,
    'scarpa': 2,
    'scarpati': 3,
    'scarpelli': 3,
    'scarpello': 3,
    'scarpinatto': 4,
    'scarpino': 3,
    'scarpone': 3,
    'scarpulla': 3,
    'scarred': 1,
    'scarring': 2,
    'scarry': 2,
    'scars': 1,
    'scarsdale': 2,
    'scarsella': 3,
    'scarves': 1,
    'scary': 2,
    'scat': 1,
    'scatena': 3,
    'scates': 1,
    'scathing': 2,
    'scatological': 5,
    'scatology': 4,
    'scatter': 2,
    'scattered': 2,
    'scattergood': 3,
    'scattergories': 4,
    'scattergory': 4,
    'scattering': 3,
    'scattershot': 3,
    'scaturro': 3,
    'scavenge': 2,
    'scavenger': 3,
    'scavengers': 3,
    'scavenging': 3,
    'scavo': 2,
    'scavone': 2,
    'scavuzzo': 3,
    'scearce': 1,
    'scenario': 4,
    'scenarios': 4,
    'scene': 1,
    'sceneries': 3,
    'scenery': 3,
    'scenes': 1,
    'scenic': 2,
    'scent': 1,
    'scented': 2,
    'scents': 1,
    'sceptre': 2,
    'scerbo': 2,
    'scerzusky': 3,
    'schaab': 1,
    'schaack': 1,
    'schaad': 1,
    'schaadt': 1,
    'schaaf': 1,
    'schaafsma': 2,
    'schaal': 1,
    'schaap': 1,
    'schaar': 1,
    'schab': 1,
    'schabacker': 3,
    'schabel': 2,
    'schaben': 2,
    'schaber': 2,
    'schaberg': 2,
    'schabes': 1,
    'schacher': 2,
    'schachner': 2,
    'schacht': 1,
    'schachter': 2,
    'schack': 1,
    'schad': 1,
    'schade': 1,
    'schadel': 2,
    'schaden': 2,
    'schader': 2,
    'schadler': 3,
    'schadt': 1,
    'schaecher': 2,
    'schaedel': 2,
    'schaedler': 3,
    'schaefer': 2,
    'schaefers': 2,
    'schaeffer': 2,
    'schaeffler': 2,
    'schaer': 2,
    'schafer': 2,
    'schaff': 1,
    'schaffer': 2,
    'schaffert': 2,
    'schaffler': 2,
    'schaffner': 2,
    'schaible': 2,
    'schairer': 2,
    'schaja': 2,
    'schake': 1,
    'schalk': 1,
    'schall': 1,
    'schaller': 2,
    'schallock': 2,
    'schalow': 2,
    'schama': 2,
    'schamber': 2,
    'schamberger': 3,
    'schamel': 2,
    'schamp': 1,
    'schanbacher': 3,
    'schanck': 1,
    'schaner': 2,
    'schank': 1,
    'schantz': 1,
    'schanz': 1,
    'schaper': 2,
    'schapiro': 3,
    'schapp': 1,
    'schappell': 2,
    'schappert': 2,
    'schar': 1,
    'schara': 2,
    'schardt': 1,
    'scharenberg': 3,
    'scharer': 2,
    'scharf': 1,
    'scharfe': 1,
    'scharfenberg': 3,
    'scharff': 1,
    'scharffenberger': 4,
    'scharlau': 2,
    'scharnhorst': 2,
    'scharp': 1,
    'scharpf': 1,
    'scharr': 1,
    'scharrer': 2,
    'schartz': 1,
    'schattner': 2,
    'schatz': 1,
    'schatzberg': 2,
    'schatzel': 2,
    'schatzman': 2,
    'schau': 1,
    'schaub': 1,
    'schauble': 2,
    'schauer': 2,
    'schauf': 1,
    'schaufler': 3,
    'schaul': 1,
    'schaum': 1,
    'schaumberg': 2,
    'schaumburg': 2,
    'schaus': 1,
    'schaut': 1,
    'schave': 1,
    'schear': 1,
    'schechter': 2,
    'schechtman': 2,
    'scheck': 1,
    'scheckel': 2,
    'schecter': 2,
    'schedler': 3,
    'schedule': 2,
    'scheduled': 2,
    'scheduler': 3,
    'schedulers': 3,
    'schedules': 2,
    'scheduling': 3,
    'scheel': 1,
    'scheele': 1,
    'scheeler': 2,
    'scheer': 1,
    'scheerer': 2,
    'scheets': 1,
    'scheetz': 1,
    'scheff': 1,
    'scheffel': 2,
    'scheffer': 2,
    'scheffler': 3,
    'schehr': 1,
    'scheib': 1,
    'scheibe': 1,
    'scheibel': 2,
    'scheiber': 2,
    'scheible': 2,
    'scheibner': 2,
    'scheid': 1,
    'scheidecker': 3,
    'scheidegger': 3,
    'scheidel': 2,
    'scheider': 2,
    'scheiderer': 3,
    'scheidler': 3,
    'scheidt': 1,
    'scheier': 2,
    'scheiman': 2,
    'schein': 1,
    'scheinberg': 2,
    'scheiner': 2,
    'scheirer': 2,
    'scheler': 2,
    'schelin': 2,
    'schell': 1,
    'schellenberg': 3,
    'schellenberger': 4,
    'schellenger': 3,
    'scheller': 2,
    'schellhammer': 3,
    'schellhase': 2,
    'schellhorn': 2,
    'schelling': 2,
    'schellinger': 3,
    'schemata': 3,
    'schematic': 3,
    'schembri': 2,
    'scheme': 1,
    'schemed': 1,
    'schemel': 2,
    'schemer': 2,
    'schemes': 1,
    'scheming': 2,
    'schemm': 1,
    'schemmel': 2,
    'schempf': 1,
    'schempp': 1,
    'schena': 2,
    'schenck': 1,
    'schendel': 2,
    'schenectady': 4,
    'schenk': 1,
    'schenkel': 2,
    'schenken': 2,
    'schenker': 2,
    'schenley': 2,
    'scheper': 2,
    'schepers': 2,
    'schepis': 2,
    'schepp': 1,
    'scher': 1,
    'scherb': 1,
    'scherbarth': 2,
    'scherer': 2,
    'scherf': 1,
    'scherff': 1,
    'scherger': 2,
    'schering': 2,
    'scherlis': 2,
    'scherman': 2,
    'schermer': 2,
    'schermerhorn': 3,
    'scherr': 1,
    'scherrer': 2,
    'schertz': 1,
    'schertzer': 2,
    'scherz': 1,
    'scherzer': 2,
    'scherzinger': 3,
    'schetter': 2,
    'schettino': 3,
    'schettler': 3,
    'scheu': 1,
    'scheuer': 2,
    'scheuerman': 3,
    'scheuermann': 3,
    'scheufler': 3,
    'scheunemann': 2,
    'scheurer': 2,
    'scheurich': 2,
    'scheuring': 2,
    'scheve': 1,
    'schewe': 1,
    'schexnayder': 3,
    'schexnider': 3,
    'schey': 1,
    'schiano': 3,
    'schiappa': 3,
    'schiavi': 3,
    'schiavo': 3,
    'schiavone': 4,
    'schiavoni': 4,
    'schick': 1,
    'schicker': 2,
    'schickler': 3,
    'schickling': 3,
    'schiebel': 2,
    'schieber': 2,
    'schiefelbein': 3,
    'schiefer': 2,
    'schieffelin': 3,
    'schieffer': 2,
    'schiel': 1,
    'schield': 1,
    'schiele': 1,
    'schielke': 1,
    'schiemann': 2,
    'schier': 2,
    'schieren': 2,
    'schierl': 1,
    'schiess': 1,
    'schiesser': 2,
    'schiewe': 2,
    'schifano': 3,
    'schiferon': 3,
    'schiff': 1,
    'schiffbauer': 3,
    'schiffer': 2,
    'schiffler': 3,
    'schiffman': 2,
    'schiffner': 2,
    'schild': 1,
    'schilder': 2,
    'schildknecht': 3,
    'schildt': 1,
    'schilke': 1,
    'schill': 1,
    'schillaci': 3,
    'schiller': 2,
    'schilling': 2,
    'schillinger': 3,
    'schillings': 2,
    'schillo': 2,
    'schilt': 1,
    'schiltknecht': 2,
    'schiltz': 1,
    'schilz': 1,
    'schimberni': 3,
    'schimek': 2,
    'schimke': 1,
    'schimmel': 2,
    'schimmelbusch': 3,
    'schimming': 2,
    'schimpf': 1,
    'schindel': 2,
    'schindler': 2,
    'schinke': 1,
    'schinkel': 2,
    'schipani': 3,
    'schiphol': 2,
    'schipke': 2,
    'schipper': 2,
    'schippers': 2,
    'schiraldi': 3,
    'schirm': 1,
    'schirmer': 2,
    'schiro': 2,
    'schirtzinger': 3,
    'schisler': 3,
    'schism': 2,
    'schisms': 2,
    'schissel': 2,
    'schissler': 3,
    'schist': 1,
    'schists': 1,
    'schizophrenia': 5,
    'schizophrenic': 4,
    'schlabach': 2,
    'schlachter': 2,
    'schlack': 1,
    'schlafer': 2,
    'schlafly': 2,
    'schlag': 1,
    'schlagel': 2,
    'schlager': 2,
    'schlageter': 3,
    'schlake': 1,
    'schlang': 1,
    'schlangen': 2,
    'schlanger': 2,
    'schlarb': 1,
    'schlater': 2,
    'schlatter': 2,
    'schlauch': 1,
    'schlecht': 1,
    'schlechter': 2,
    'schlee': 1,
    'schleeter': 2,
    'schlegel': 2,
    'schlegelmilch': 3,
    'schleich': 1,
    'schleicher': 2,
    'schleif': 1,
    'schleifer': 2,
    'schleiger': 2,
    'schleimer': 2,
    'schlein': 1,
    'schleis': 1,
    'schlemmer': 2,
    'schlender': 2,
    'schlenker': 2,
    'schlepp': 1,
    'schlereth': 2,
    'schlesinger': 3,
    'schlesser': 2,
    'schlessinger': 3,
    'schleswig': 2,
    'schley': 1,
    'schleyer': 2,
    'schlicher': 2,
    'schlicht': 1,
    'schlichter': 2,
    'schlichting': 2,
    'schlick': 1,
    'schlicker': 2,
    'schlict': 1,
    'schlie': 1,
    'schliep': 1,
    'schlieper': 2,
    'schlink': 1,
    'schlitt': 1,
    'schlitter': 2,
    'schlitz': 1,
    'schlobohm': 2,
    'schlock': 1,
    'schloemer': 2,
    'schloesser': 3,
    'schlosberg': 2,
    'schloss': 1,
    'schlossberg': 2,
    'schlosser': 2,
    'schlossman': 2,
    'schlott': 1,
    'schlotter': 2,
    'schlotterbeck': 3,
    'schlottman': 2,
    'schlotzhauer': 3,
    'schlough': 1,
    'schlueter': 2,
    'schlumberger': 3,
    'schlund': 1,
    'schlup': 1,
    'schluter': 2,
    'schmader': 2,
    'schmahl': 1,
    'schmal': 1,
    'schmale': 1,
    'schmall': 1,
    'schmaltz': 1,
    'schmalz': 1,
    'schmancy': 2,
    'schmaus': 1,
    'schmead': 1,
    'schmechel': 2,
    'schmeck': 1,
    'schmehl': 1,
    'schmeichel': 2,
    'schmeiser': 2,
    'schmeling': 2,
    'schmelter': 2,
    'schmeltz': 1,
    'schmeltzer': 2,
    'schmelz': 1,
    'schmelzer': 2,
    'schmelzle': 2,
    'schmergel': 2,
    'schmertz': 1,
    'schmetterer': 3,
    'schmick': 1,
    'schmid': 1,
    'schmiddy': 2,
    'schmidgall': 2,
    'schmidl': 2,
    'schmidlin': 2,
    'schmidt': 1,
    'schmidtke': 2,
    'schmied': 1,
    'schmieder': 2,
    'schmieg': 1,
    'schmierer': 3,
    'schmiesing': 2,
    'schmit': 1,
    'schmitt': 1,
    'schmitter': 2,
    'schmittou': 2,
    'schmitz': 1,
    'schmitzer': 2,
    'schmoke': 1,
    'schmoker': 2,
    'schmoldt': 1,
    'schmoll': 1,
    'schmooze': 1,
    'schmoozing': 2,
    'schmotzer': 2,
    'schmoyer': 2,
    'schmuck': 1,
    'schmucker': 2,
    'schmuckler': 2,
    'schmuhl': 1,
    'schmults': 1,
    'schmunk': 1,
    'schmutz': 1,
    'schmutzler': 3,
    'schnabel': 2,
    'schnack': 1,
    'schnackenberg': 3,
    'schnake': 1,
    'schnakenberg': 3,
    'schnall': 1,
    'schnapp': 1,
    'schnapps': 1,
    'schnarr': 1,
    'schnauzer': 2,
    'schneberger': 3,
    'schnebly': 2,
    'schneck': 1,
    'schneckloth': 2,
    'schnee': 1,
    'schneeberger': 3,
    'schneeman': 2,
    'schneerson': 2,
    'schneid': 1,
    'schneider': 2,
    'schneiderman': 3,
    'schneiders': 2,
    'schneidewind': 3,
    'schneier': 2,
    'schneiter': 2,
    'schnell': 1,
    'schnelle': 1,
    'schneller': 2,
    'schnepf': 1,
    'schnepp': 1,
    'schnettler': 3,
    'schnetzer': 2,
    'schnick': 1,
    'schnider': 2,
    'schnieder': 2,
    'schnieders': 2,
    'schnier': 2,
    'schnitker': 2,
    'schnittke': 2,
    'schnitz': 1,
    'schnitzer': 2,
    'schnitzler': 2,
    'schnoebelen': 3,
    'schnook': 1,
    'schnooks': 1,
    'schnoor': 1,
    'schnorr': 1,
    'schnur': 1,
    'schnurr': 1,
    'schnyder': 2,
    'schobel': 2,
    'schober': 2,
    'schobert': 2,
    'schoch': 1,
    'schock': 1,
    'schoeck': 1,
    'schoeffler': 3,
    'schoeller': 2,
    'schoellhorn': 2,
    'schoemaker': 3,
    'schoen': 1,
    'schoenbaum': 2,
    'schoenbeck': 2,
    'schoenberg': 2,
    'schoenberger': 3,
    'schoenborn': 2,
    'schoendorf': 2,
    'schoene': 2,
    'schoeneck': 2,
    'schoenecker': 3,
    'schoeneman': 3,
    'schoenemann': 3,
    'schoener': 2,
    'schoenfeld': 2,
    'schoenfelder': 3,
    'schoenfeldt': 2,
    'schoenhals': 2,
    'schoenherr': 2,
    'schoenhof': 2,
    'schoenholtz': 2,
    'schoenig': 2,
    'schoening': 3,
    'schoenrock': 2,
    'schoenthal': 2,
    'schoenwald': 2,
    'schoepe': 1,
    'schoepf': 1,
    'schoepke': 2,
    'schoepp': 1,
    'schoeppner': 2,
    'schoettle': 2,
    'schoff': 1,
    'schoffstall': 2,
    'schofield': 2,
    'scholar': 2,
    'scholarly': 3,
    'scholars': 2,
    'scholarship': 3,
    'scholarships': 3,
    'scholastic': 3,
    'scholberg': 2,
    'scholer': 2,
    'scholes': 1,
    'scholey': 2,
    'scholfield': 2,
    'scholl': 1,
    'scholle': 1,
    'scholler': 2,
    'schollmeyer': 3,
    'scholten': 2,
    'scholtes': 1,
    'scholtz': 1,
    'scholz': 1,
    'scholze': 1,
    'schomaker': 3,
    'schomberg': 2,
    'schomburg': 2,
    'schomer': 2,
    'schommer': 2,
    'schon': 1,
    'schonberg': 2,
    'schonberger': 3,
    'schone': 1,
    'schoneman': 2,
    'schonfeld': 2,
    'schons': 1,
    'schoo': 1,
    'schoof': 1,
    'schook': 1,
    'school': 1,
    'schoolbook': 2,
    'schoolbooks': 2,
    'schoolboy': 2,
    'schoolboys': 2,
    'schoolbus': 2,
    'schoolchild': 2,
    'schoolchildren': 3,
    'schoolcraft': 2,
    'schooled': 1,
    'schooler': 2,
    'schoolers': 2,
    'schooley': 2,
    'schoolfield': 2,
    'schoolhouse': 2,
    'schooling': 2,
    'schoolmaster': 3,
    'schoolmate': 2,
    'schoolmates': 2,
    'schoolroom': 2,
    'schools': 1,
    'schoolteacher': 3,
    'schoolteachers': 3,
    'schooltime': 2,
    'schoolwork': 2,
    'schoolyard': 2,
    'schoon': 1,
    'schooner': 2,
    'schooners': 2,
    'schoonmaker': 3,
    'schoonover': 3,
    'schopf': 1,
    'schopfer': 2,
    'schopp': 1,
    'schoppe': 1,
    'schor': 1,
    'schork': 1,
    'schorn': 1,
    'schorr': 1,
    'schorsch': 1,
    'schott': 1,
    'schottenstein': 3,
    'schou': 1,
    'schouten': 3,
    'schow': 1,
    'schowalter': 3,
    'schrack': 1,
    'schrade': 1,
    'schrader': 2,
    'schraeder': 2,
    'schrag': 1,
    'schrage': 1,
    'schrager': 2,
    'schram': 1,
    'schramm': 1,
    'schrandt': 1,
    'schrank': 1,
    'schrantz': 1,
    'schranz': 1,
    'schraufnagel': 3,
    'schrecengost': 3,
    'schreck': 1,
    'schreckengost': 3,
    'schrecongost': 3,
    'schreder': 2,
    'schreffler': 3,
    'schreger': 2,
    'schreiber': 2,
    'schreibman': 2,
    'schreier': 2,
    'schreifels': 2,
    'schreiner': 2,
    'schreiter': 2,
    'schremp': 1,
    'schrempf': 1,
    'schrempp': 1,
    'schrenk': 1,
    'schreur': 1,
    'schreurs': 1,
    'schreyer': 1,
    'schriber': 2,
    'schrick': 1,
    'schricker': 2,
    'schrieber': 2,
    'schriefer': 2,
    'schrier': 2,
    'schriever': 2,
    'schrimpf': 1,
    'schrimsher': 2,
    'schriner': 2,
    'schriver': 2,
    'schroader': 2,
    'schrock': 1,
    'schroder': 2,
    'schroders': 2,
    'schrodt': 1,
    'schroeck': 1,
    'schroeder': 2,
    'schroedl': 2,
    'schroepfer': 2,
    'schroer': 2,
    'schroeter': 2,
    'schroff': 1,
    'schroll': 1,
    'schrom': 1,
    'schronce': 1,
    'schropp': 1,
    'schroth': 1,
    'schroyer': 2,
    'schrum': 1,
    'schrumpf': 1,
    'schrupp': 1,
    'schryer': 2,
    'schryver': 2,
    'schtick': 1,
    'schubach': 2,
    'schubel': 2,
    'schubert': 2,
    'schubring': 3,
    'schuch': 1,
    'schuchard': 2,
    'schuchardt': 2,
    'schuchart': 2,
    'schuchert': 2,
    'schuchman': 2,
    'schuchmann': 2,
    'schuck': 1,
    'schucker': 2,
    'schuckman': 2,
    'schue': 1,
    'schuele': 1,
    'schueler': 2,
    'schuelke': 1,
    'schueller': 2,
    'schueneman': 3,
    'schuenemann': 3,
    'schuerman': 3,
    'schuermann': 3,
    'schuessler': 2,
    'schuett': 1,
    'schuette': 1,
    'schuetz': 1,
    'schuetze': 1,
    'schuff': 1,
    'schug': 1,
    'schuh': 1,
    'schuhmacher': 3,
    'schuhmann': 2,
    'schuitema': 3,
    'schuknecht': 2,
    'schul': 1,
    'schuld': 1,
    'schuldener': 3,
    'schulder': 2,
    'schuldt': 1,
    'schulenberg': 3,
    'schulenburg': 3,
    'schuler': 2,
    'schulhauf': 2,
    'schulhaufs': 2,
    'schulhof': 2,
    'schulke': 2,
    'schull': 1,
    'schuller': 2,
    'schulman': 2,
    'schulof': 2,
    'schult': 1,
    'schulte': 1,
    'schulten': 2,
    'schultes': 1,
    'schultheis': 2,
    'schultheiss': 2,
    'schultz': 1,
    'schultze': 1,
    'schulz': 1,
    'schulze': 1,
    'schum': 1,
    'schumacher': 3,
    'schumacker': 3,
    'schumaker': 3,
    'schuman': 2,
    'schumann': 2,
    'schumer': 2,
    'schumm': 1,
    'schumpert': 2,
    'schundler': 2,
    'schuneman': 2,
    'schunk': 1,
    'schupak': 2,
    'schupbach': 2,
    'schupp': 1,
    'schur': 1,
    'schuring': 2,
    'schurman': 2,
    'schurr': 1,
    'schurrenberg': 3,
    'schussler': 2,
    'schuster': 2,
    'schut': 1,
    'schuth': 1,
    'schutt': 1,
    'schutte': 1,
    'schutter': 2,
    'schuttler': 2,
    'schutz': 1,
    'schutzman': 2,
    'schuur': 1,
    'schuyler': 2,
    'schuylkill': 2,
    'schwab': 1,
    'schwabe': 1,
    'schwaderer': 3,
    'schwager': 2,
    'schwahn': 1,
    'schwaiger': 2,
    'schwake': 1,
    'schwalb': 1,
    'schwalbach': 2,
    'schwalbe': 1,
    'schwalier': 2,
    'schwall': 1,
    'schwaller': 2,
    'schwalm': 1,
    'schwam': 1,
    'schwan': 1,
    'schwandt': 1,
    'schwanke': 1,
    'schwantes': 1,
    'schwanz': 1,
    'schwark': 1,
    'schwarm': 1,
    'schwartz': 1,
    'schwartzberg': 2,
    'schwartzchild': 2,
    'schwartzkopf': 2,
    'schwartzman': 2,
    'schwarz': 1,
    'schwarze': 1,
    'schwarzenegger': 4,
    'schwarzer': 2,
    'schwarzkopf': 2,
    'schwarzman': 2,
    'schwebach': 2,
    'schwebel': 2,
    'schwebke': 1,
    'schwed': 1,
    'schweda': 2,
    'schwede': 1,
    'schweder': 2,
    'schweer': 1,
    'schweers': 1,
    'schwegel': 2,
    'schwegler': 3,
    'schwegman': 2,
    'schweich': 1,
    'schweickert': 2,
    'schweiger': 2,
    'schweigert': 2,
    'schweighardt': 2,
    'schweikert': 2,
    'schweinsberg': 2,
    'schweiss': 1,
    'schweitzer': 2,
    'schweizer': 2,
    'schweizerische': 3,
    'schwemm': 1,
    'schwemmer': 2,
    'schwendeman': 2,
    'schwenk': 1,
    'schwenke': 1,
    'schwenker': 2,
    'schwenn': 1,
    'schwent': 1,
    'schweppe': 1,
    'schweppes': 1,
    'schwer': 1,
    'schwerdloff': 2,
    'schwerdt': 1,
    'schwerdtfeger': 3,
    'schwerin': 2,
    'schwering': 2,
    'schwerner': 2,
    'schwertfeger': 3,
    'schwertner': 2,
    'schwichtenberg': 3,
    'schwieger': 2,
    'schwier': 2,
    'schwiesow': 2,
    'schwieterman': 3,
    'schwimmer': 2,
    'schwind': 1,
    'schwindt': 1,
    'schwing': 1,
    'schwinger': 2,
    'schwinn': 1,
    'schwisow': 2,
    'schwoerer': 3,
    'sci': 1,
    'sciacca': 2,
    'scialabba': 3,
    'scialdone': 3,
    'sciandra': 2,
    'scianna': 2,
    'sciara': 2,
    'sciaroni': 4,
    'sciarra': 3,
    'sciarrino': 3,
    'sciascia': 2,
    'scibelli': 3,
    'scibilia': 4,
    'scicchitano': 4,
    'sciclone': 3,
    'scicom': 2,
    'science': 2,
    'sciences': 3,
    'scientific': 4,
    'scientifically': 6,
    'scientifics': 4,
    'scientist': 3,
    'scientists': 3,
    'scientologist': 5,
    'scientologists': 5,
    'scientology': 5,
    'scifres': 2,
    'scilla': 2,
    'scillas': 2,
    'scimeca': 3,
    'scimed': 2,
    'scimone': 2,
    'scinta': 2,
    'scintilla': 3,
    'scintilore': 4,
    'scinto': 2,
    'scioli': 3,
    'scion': 2,
    'sciortino': 4,
    'scios': 2,
    'scioto': 3,
    'scipio': 3,
    'scipione': 4,
    'scire': 1,
    'scism': 2,
    'scissor': 2,
    'scissors': 2,
    'scitex': 2,
    'sciulli': 3,
    'sciullo': 3,
    'sciuto': 3,
    'sclafani': 3,
    'scleroderma': 4,
    'sclerosis': 3,
    'scobee': 2,
    'scobey': 2,
    'scobie': 2,
    'scoby': 2,
    'scocozza': 3,
    'scoff': 1,
    'scoffed': 1,
    'scoffield': 2,
    'scofflaws': 2,
    'scoffs': 1,
    'scofield': 2,
    'scoggin': 2,
    'scoggins': 2,
    'scogin': 2,
    'scohier': 2,
    'scola': 2,
    'scolari': 3,
    'scolaro': 3,
    'scold': 1,
    'scolded': 2,
    'scolding': 2,
    'scolds': 1,
    'scoles': 1,
    'scolia': 2,
    'scoma': 2,
    'sconc': 1,
    'sconce': 1,
    'sconces': 2,
    'scone': 1,
    'scones': 1,
    'sconiers': 3,
    'sconnix': 2,
    'sconyers': 3,
    'scooby': 2,
    'scoop': 1,
    'scooped': 1,
    'scooper': 2,
    'scooping': 2,
    'scoops': 1,
    'scoot': 1,
    'scooter': 2,
    'scooters': 2,
    'scoots': 1,
    'scope': 1,
    'scopes': 1,
    'scopic': 2,
    'scoping': 2,
    'scopolamine': 4,
    'scor': 1,
    'scorch': 1,
    'scorched': 1,
    'scorcher': 2,
    'scorching': 2,
    'score': 1,
    'scoreboard': 2,
    'scorecard': 2,
    'scorecards': 2,
    'scored': 1,
    'scorekeeper': 3,
    'scorekeepers': 3,
    'scorekeeping': 3,
    'scoreless': 2,
    'scorer': 2,
    'scorers': 2,
    'scores': 1,
    'scoring': 2,
    'scorn': 1,
    'scorned': 1,
    'scornful': 2,
    'scorns': 1,
    'scorpio': 3,
    'scorpion': 3,
    'scorpions': 3,
    'scorsese': 2,
    'scorsone': 2,
    'scortese': 2,
    'scorza': 2,
    'scot': 1,
    'scotch': 1,
    'scotched': 1,
    'scotches': 2,
    'scotchgard': 2,
    'scotia': 2,
    'scotland': 2,
    'scots': 1,
    'scotsman': 2,
    'scott': 1,
    'scotten': 2,
    'scottie': 2,
    'scottish': 2,
    'scotto': 2,
    'scotton': 2,
    'scotts': 1,
    'scottsbluff': 2,
    'scottsdale': 2,
    'scotty': 2,
    'scoundrel': 2,
    'scoundrels': 2,
    'scour': 2,
    'scoured': 2,
    'scourge': 1,
    'scourged': 1,
    'scourges': 2,
    'scourging': 2,
    'scouring': 3,
    'scours': 2,
    'scout': 1,
    'scouted': 2,
    'scouten': 2,
    'scouter': 2,
    'scouters': 2,
    'scouting': 2,
    'scoutmaster': 3,
    'scouts': 1,
    'scovel': 2,
    'scovell': 2,
    'scovil': 2,
    'scovill': 2,
    'scoville': 2,
    'scow': 1,
    'scowcroft': 2,
    'scowden': 2,
    'scowl': 1,
    'scowled': 1,
    'scowling': 2,
    'scozzafava': 4,
    'scrabble': 2,
    'scragg': 1,
    'scraggle': 2,
    'scraggly': 2,
    'scramble': 2,
    'scrambled': 2,
    'scrambles': 2,
    'scrambling': 3,
    'scranton': 2,
    'scrap': 1,
    'scrapbook': 2,
    'scrapbooks': 2,
    'scrape': 1,
    'scraped': 1,
    'scraper': 2,
    'scrapers': 2,
    'scrapes': 1,
    'scraping': 2,
    'scrapings': 2,
    'scrapiron': 3,
    'scrapped': 1,
    'scrapping': 2,
    'scrappy': 2,
    'scraps': 1,
    'scratch': 1,
    'scratched': 1,
    'scratches': 2,
    'scratching': 2,
    'scratchy': 2,
    'scrawl': 1,
    'scrawled': 1,
    'scrawny': 2,
    'scream': 1,
    'screamed': 1,
    'screamer': 2,
    'screamers': 2,
    'screaming': 2,
    'screamingly': 3,
    'screams': 1,
    'screech': 1,
    'screeched': 1,
    'screeches': 2,
    'screeching': 2,
    'screed': 1,
    'screen': 1,
    'screened': 1,
    'screener': 2,
    'screeners': 2,
    'screening': 2,
    'screenings': 2,
    'screenplay': 2,
    'screenplays': 2,
    'screens': 1,
    'screenwriter': 3,
    'screenwriters': 3,
    'screenwriting': 3,
    'screw': 1,
    'screwball': 2,
    'screwdriver': 3,
    'screwdrivers': 3,
    'screwed': 1,
    'screwing': 2,
    'screws': 1,
    'screwy': 2,
    'scribble': 2,
    'scribbled': 2,
    'scribbler': 2,
    'scribblers': 2,
    'scribbles': 2,
    'scribbling': 3,
    'scribe': 1,
    'scriber': 2,
    'scribes': 1,
    'scribner': 2,
    'scribners': 2,
    'scrimgeour': 2,
    'scrimmage': 2,
    'scrimp': 1,
    'scrimping': 2,
    'scrimshaw': 2,
    'scrip': 1,
    'scripp': 1,
    'scripps': 1,
    'script': 1,
    'scripted': 2,
    'scripts': 1,
    'scriptural': 3,
    'scripture': 2,
    'scriptures': 2,
    'scriptwriter': 3,
    'scriptwriters': 3,
    'scriptwriting': 3,
    'scritchfield': 2,
    'scriven': 2,
    'scrivener': 2,
    'scrivens': 2,
    'scriver': 2,
    'scrivner': 2,
    'scrod': 1,
    'scroggin': 2,
    'scroggins': 2,
    'scroggs': 1,
    'scrogham': 2,
    'scroll': 1,
    'scrolls': 1,
    'scronce': 1,
    'scrooge': 1,
    'scrotten': 2,
    'scrounge': 1,
    'scrounged': 1,
    'scrounging': 2,
    'scrub': 1,
    'scrubbed': 1,
    'scrubber': 2,
    'scrubbers': 2,
    'scrubbing': 2,
    'scrubby': 2,
    'scruffy': 2,
    'scruggs': 1,
    'scrunch': 1,
    'scrunched': 1,
    'scruple': 2,
    'scruples': 2,
    'scrupulous': 3,
    'scrupulously': 4,
    'scrutinize': 3,
    'scrutinized': 3,
    'scrutinizes': 4,
    'scrutinizing': 4,
    'scrutiny': 3,
    'scruton': 2,
    'scrutton': 2,
    'scuba': 2,
    'scud': 1,
    'scudder': 2,
    'scuderi': 3,
    'scuds': 1,
    'scuff': 1,
    'scuffed': 1,
    'scuffle': 2,
    'scuffled': 2,
    'scuffles': 2,
    'scuffling': 3,
    'scull': 1,
    'sculley': 2,
    'scullin': 2,
    'scullion': 2,
    'scully': 2,
    'sculpt': 1,
    'sculpted': 2,
    'sculpting': 2,
    'sculptor': 2,
    'sculptors': 2,
    'sculpts': 1,
    'sculptural': 3,
    'sculpture': 2,
    'sculptured': 2,
    'sculptures': 2,
    'sculpturing': 3,
    'scum': 1,
    'scupper': 2,
    'scuppernong': 3,
    'scurdall': 2,
    'scurdell': 2,
    'scurlock': 2,
    'scurried': 2,
    'scurrilous': 3,
    'scurry': 2,
    'scurrying': 3,
    'scutt': 1,
    'scuttle': 2,
    'scuttlebutt': 3,
    'scuttled': 2,
    'scuttling': 3,
    'scylla': 2,
    'scythe': 1,
    'scythian': 3,
    'sczechuan': 3,
    'sczepanski': 3,
    'sdn': 3,
    'se': 2,
    'sea': 1,
    'seabaugh': 2,
    'seabeach': 2,
    'seabed': 2,
    'seabee': 2,
    'seabees': 2,
    'seaberg': 2,
    'seaberry': 3,
    'seabert': 2,
    'seaboard': 2,
    'seabold': 2,
    'seabolt': 2,
    'seaborn': 2,
    'seaborne': 2,
    'seabright': 2,
    'seabrook': 2,
    'seabrooks': 2,
    'seaburg': 2,
    'seabury': 3,
    'seaco': 2,
    'seacoast': 2,
    'seader': 2,
    'seadrift': 2,
    'seafarer': 3,
    'seafarers': 3,
    'seafirst': 2,
    'seafood': 2,
    'seafoods': 2,
    'seaford': 2,
    'seafront': 2,
    'seaga': 2,
    'seagal': 2,
    'seagate': 2,
    'seager': 2,
    'seagle': 2,
    'seago': 2,
    'seagoing': 3,
    'seagram': 2,
    'seagrams': 2,
    'seagrave': 2,
    'seagraves': 2,
    'seagren': 2,
    'seagrove': 2,
    'seagroves': 2,
    'seagull': 2,
    'seagulls': 2,
    'seahawk': 2,
    'seahawks': 2,
    'seaholm': 2,
    'seal': 1,
    'sealand': 2,
    'sealant': 2,
    'sealants': 2,
    'seale': 1,
    'sealed': 1,
    'sealer': 2,
    'seales': 1,
    'sealey': 2,
    'sealift': 2,
    'sealing': 2,
    'sealock': 2,
    'seals': 1,
    'sealtest': 2,
    'sealy': 2,
    'seam': 1,
    'seaman': 2,
    'seamans': 2,
    'seamen': 2,
    'seamless': 2,
    'seamlessly': 3,
    'seamon': 2,
    'seamons': 2,
    'seams': 1,
    'seamster': 2,
    'seamstress': 2,
    'seamstresses': 3,
    'seamus': 2,
    'seamy': 2,
    'sean': 1,
    'seanang': 2,
    'seanangs': 2,
    'seance': 2,
    'seanor': 2,
    'seaport': 2,
    'seaports': 2,
    'seapower': 3,
    'seaq': 1,
    'seaquest': 2,
    'seaquist': 2,
    'sear': 1,
    'search': 1,
    'searched': 1,
    'searcher': 2,
    'searchers': 2,
    'searches': 2,
    'searching': 2,
    'searchlight': 2,
    'searchlights': 2,
    'searcy': 2,
    'seared': 1,
    'searfoss': 2,
    'searight': 2,
    'searing': 2,
    'searl': 1,
    'searle': 1,
    'searles': 1,
    'searls': 1,
    'sears': 1,
    'searson': 2,
    'seas': 1,
    'sease': 1,
    'seashell': 2,
    'seashells': 2,
    'seashore': 2,
    'seasick': 2,
    'seaside': 2,
    'season': 2,
    'seasonable': 4,
    'seasonal': 3,
    'seasonality': 5,
    'seasonally': 4,
    'seasoned': 2,
    'seasoning': 3,
    'seasonings': 3,
    'seasons': 2,
    'seastrand': 2,
    'seastrom': 2,
    'seat': 1,
    'seatbelt': 2,
    'seatbelts': 2,
    'seated': 2,
    'seater': 2,
    'seating': 2,
    'seatings': 2,
    'seato': 2,
    'seaton': 2,
    'seatrain': 2,
    'seats': 1,
    'seattle': 3,
    'seaver': 2,
    'seavers': 2,
    'seavey': 2,
    'seaward': 2,
    'seawater': 3,
    'seaway': 2,
    'seaweed': 2,
    'seaweeds': 2,
    'seawell': 2,
    'seawolf': 2,
    'seaworthy': 3,
    'seawright': 2,
    'seay': 1,
    'sebaceous': 3,
    'sebald': 2,
    'sebastian': 3,
    'sebastiana': 5,
    'sebastiane': 4,
    'sebastiani': 5,
    'sebastianis': 5,
    'sebby': 2,
    'sebek': 2,
    'seber': 2,
    'sebert': 2,
    'sebesta': 3,
    'sebi': 2,
    'sebo': 2,
    'sebold': 2,
    'sebree': 2,
    'sebring': 2,
    'sec': 1,
    'secada': 3,
    'secateur': 3,
    'secateurs': 3,
    'secaucus': 3,
    'secchia': 3,
    'secede': 2,
    'seceded': 3,
    'seceding': 3,
    'secession': 3,
    'secessionist': 4,
    'secessionists': 4,
    'sechler': 2,
    'sechrest': 3,
    'sechrist': 3,
    'seck': 1,
    'seckel': 2,
    'seckinger': 3,
    'seckler': 2,
    'seckman': 2,
    'seclude': 2,
    'secluded': 3,
    'seclusion': 3,
    'seco': 2,
    'secom': 2,
    'secomerica': 5,
    'second': 2,
    'secondaries': 4,
    'secondarily': 5,
    'secondary': 4,
    'seconded': 3,
    'secondhand': 3,
    'secondly': 3,
    'seconds': 2,
    'secor': 2,
    'secord': 2,
    'secoy': 2,
    'secrecy': 3,
    'secrest': 3,
    'secret': 2,
    'secretarial': 5,
    'secretariat': 5,
    'secretaries': 4,
    'secretary': 4,
    'secrete': 2,
    'secreted': 3,
    'secretion': 3,
    'secretions': 3,
    'secretive': 3,
    'secretiveness': 4,
    'secretly': 3,
    'secrets': 2,
    'secrist': 3,
    'sect': 1,
    'sectarian': 4,
    'sectarianism': 6,
    'secteur': 2,
    'section': 2,
    'sectional': 3,
    'sectioned': 2,
    'sectioning': 3,
    'sections': 2,
    'sector': 2,
    'sectoral': 3,
    'sectors': 2,
    'sects': 1,
    'secular': 3,
    'secularism': 5,
    'secularist': 4,
    'secularists': 4,
    'secularized': 4,
    'seculow': 3,
    'secunda': 3,
    'secure': 2,
    'secured': 2,
    'securely': 3,
    'secures': 2,
    'securing': 3,
    'securities': 4,
    'securitization': 6,
    'securitize': 4,
    'securitized': 4,
    'securitizing': 5,
    'security': 4,
    'seda': 2,
    'sedalia': 3,
    'sedam': 2,
    'sedan': 2,
    'sedano': 3,
    'sedans': 2,
    'sedate': 2,
    'sedated': 3,
    'sedately': 3,
    'sedates': 2,
    'sedating': 3,
    'sedation': 3,
    'sedative': 3,
    'sedberry': 3,
    'sedco': 2,
    'seddon': 2,
    'sedentary': 4,
    'seder': 2,
    'sedge': 1,
    'sedgewick': 2,
    'sedgley': 2,
    'sedgwick': 2,
    'sedillo': 3,
    'sediment': 3,
    'sedimentary': 5,
    'sedimentation': 5,
    'sediments': 3,
    'sedita': 3,
    'sedition': 3,
    'seditious': 3,
    'sedivy': 3,
    'sedlacek': 3,
    'sedlack': 2,
    'sedlak': 2,
    'sedlar': 2,
    'sedler': 2,
    'sedlock': 2,
    'sedona': 3,
    'sedor': 2,
    'sedore': 3,
    'seduce': 2,
    'seduced': 2,
    'seducer': 3,
    'seducing': 3,
    'seduction': 3,
    'seductive': 3,
    'seductively': 4,
    'sedum': 2,
    'sedums': 2,
    'sedwick': 2,
    'see': 1,
    'seebach': 2,
    'seebeck': 2,
    'seeber': 2,
    'seeberger': 3,
    'seebold': 2,
    'seeburger': 3,
    'seed': 1,
    'seedeater': 3,
    'seedeaters': 3,
    'seeded': 2,
    'seeding': 2,
    'seedling': 2,
    'seedlings': 2,
    'seedorf': 2,
    'seedpod': 2,
    'seeds': 1,
    'seedsman': 2,
    'seedy': 2,
    'seefeld': 2,
    'seefeldt': 2,
    'seegars': 2,
    'seeger': 2,
    'seegers': 2,
    'seegert': 2,
    'seegmiller': 3,
    'seehafer': 3,
    'seehusen': 3,
    'seeing': 2,
    'seek': 1,
    'seekamp': 2,
    'seeker': 2,
    'seekers': 2,
    'seeking': 2,
    'seekins': 2,
    'seekonk': 2,
    'seeks': 1,
    'seel': 1,
    'seelbach': 2,
    'seelert': 2,
    'seeley': 2,
    'seelig': 2,
    'seeling': 2,
    'seelinger': 3,
    'seelman': 2,
    'seely': 2,
    'seelye': 2,
    'seem': 1,
    'seema': 2,
    'seemala': 3,
    'seeman': 2,
    'seemann': 2,
    'seemed': 1,
    'seeming': 2,
    'seemingly': 3,
    'seems': 1,
    'seen': 1,
    'seep': 1,
    'seepage': 2,
    'seeped': 1,
    'seeping': 2,
    'seeps': 1,
    'seer': 1,
    'seers': 1,
    'seery': 2,
    'sees': 1,
    'seesaw': 2,
    'seesawed': 2,
    'seesawing': 3,
    'seese': 1,
    'seethe': 1,
    'seething': 2,
    'seetin': 2,
    'seeton': 2,
    'seever': 2,
    'seevers': 2,
    'seewald': 2,
    'sefcik': 2,
    'seff': 1,
    'sefton': 2,
    'sega': 2,
    'segal': 2,
    'segalas': 3,
    'segall': 2,
    'segar': 2,
    'segarra': 3,
    'segars': 2,
    'sege': 1,
    'segel': 2,
    'seger': 2,
    'segers': 2,
    'segerstrom': 3,
    'segler': 2,
    'segment': 2,
    'segmentation': 4,
    'segmented': 3,
    'segments': 2,
    'segner': 2,
    'sego': 2,
    'segovia': 4,
    'segraves': 3,
    'segregate': 3,
    'segregated': 4,
    'segregating': 4,
    'segregation': 4,
    'segregationist': 5,
    'segregationists': 5,
    'segrest': 3,
    'segreto': 3,
    'segue': 1,
    'seguin': 2,
    'segundo': 3,
    'segur': 2,
    'segura': 3,
    'seguros': 3,
    'seher': 2,
    'sehnert': 2,
    'sehorn': 2,
    'sehr': 1,
    'seib': 1,
    'seibel': 2,
    'seiber': 2,
    'seiberlich': 3,
    'seiberling': 3,
    'seibert': 2,
    'seibold': 2,
    'seibu': 2,
    'seid': 1,
    'seide': 1,
    'seidel': 2,
    'seidell': 2,
    'seidelman': 3,
    'seiden': 2,
    'seidenberg': 3,
    'seider': 2,
    'seiders': 2,
    'seidl': 2,
    'seidler': 2,
    'seidman': 2,
    'seidner': 2,
    'seier': 2,
    'seif': 1,
    'seife': 1,
    'seifer': 2,
    'seifert': 2,
    'seiffert': 2,
    'seifried': 3,
    'seigal': 2,
    'seige': 1,
    'seigel': 2,
    'seiger': 2,
    'seigle': 2,
    'seigler': 3,
    'seigniorage': 4,
    'seiji': 2,
    'seiki': 2,
    'seiko': 2,
    'seil': 1,
    'seiler': 2,
    'seils': 1,
    'seim': 1,
    'sein': 1,
    'seine': 2,
    'seinfeld': 2,
    'seip': 1,
    'seipel': 2,
    'seiple': 2,
    'seipp': 1,
    'seis': 1,
    'seiser': 2,
    'seismic': 2,
    'seismological': 5,
    'seismologist': 4,
    'seismologists': 4,
    'seismology': 4,
    'seita': 2,
    'seitel': 2,
    'seiter': 2,
    'seith': 1,
    'seither': 2,
    'seitman': 2,
    'seitter': 2,
    'seitz': 1,
    'seitzinger': 3,
    'seivert': 2,
    'seiwert': 2,
    'seix': 1,
    'seixas': 2,
    'seiyaku': 3,
    'seiyu': 2,
    'seize': 1,
    'seized': 1,
    'seizes': 2,
    'seizing': 2,
    'seizure': 2,
    'seizures': 2,
    'sejm': 1,
    'sekerak': 3,
    'seki': 2,
    'sekisui': 4,
    'seko': 2,
    'sekula': 3,
    'sekulow': 3,
    'sel': 1,
    'sela': 2,
    'selander': 3,
    'selas': 2,
    'selassie': 3,
    'selbe': 1,
    'selberg': 2,
    'selby': 2,
    'selchow': 2,
    'selda': 2,
    'seldane': 2,
    'selden': 2,
    'selders': 2,
    'seldin': 2,
    'seldom': 2,
    'seldon': 2,
    'select': 2,
    'selected': 3,
    'selecting': 3,
    'selection': 3,
    'selections': 3,
    'selective': 3,
    'selectively': 4,
    'selectivity': 5,
    'selectnet': 3,
    'selects': 2,
    'selena': 3,
    'selene': 2,
    'selenite': 3,
    'selenium': 4,
    'seles': 2,
    'seley': 2,
    'self': 1,
    'selfish': 2,
    'selfishness': 3,
    'selfless': 2,
    'selflessness': 3,
    'selfridge': 2,
    'selfs': 1,
    'selia': 3,
    'selie': 2,
    'selies': 2,
    'selig': 2,
    'seliga': 3,
    'seliger': 3,
    'seligman': 3,
    'seligmann': 3,
    'seligson': 3,
    'selikoff': 3,
    'selin': 2,
    'selina': 3,
    'selinas': 3,
    'selinda': 3,
    'selinger': 3,
    'seljuk': 2,
    'selk': 1,
    'selke': 1,
    'selkin': 2,
    'selkirk': 2,
    'sell': 1,
    'sella': 2,
    'selland': 2,
    'sellards': 2,
    'sellars': 2,
    'selle': 1,
    'selleck': 2,
    'sellen': 2,
    'seller': 2,
    'sellers': 2,
    'selley': 2,
    'sellick': 2,
    'sellier': 2,
    'sellin': 2,
    'selling': 2,
    'sellinger': 3,
    'sellings': 2,
    'sellman': 2,
    'sellmeyer': 3,
    'sellner': 2,
    'selloff': 2,
    'selloffs': 2,
    'sellon': 2,
    'sellout': 2,
    'sellouts': 2,
    'sells': 1,
    'selma': 2,
    'selman': 2,
    'selmer': 2,
    'selmon': 2,
    'selner': 2,
    'selover': 3,
    'selowsky': 3,
    'selph': 1,
    'selsor': 2,
    'seltz': 1,
    'seltzer': 2,
    'selva': 2,
    'selvage': 2,
    'selvaggio': 4,
    'selves': 1,
    'selvey': 2,
    'selvidge': 2,
    'selvig': 2,
    'selway': 2,
    'selwin': 2,
    'selwitz': 2,
    'selwyn': 2,
    'selz': 1,
    'selzer': 2,
    'sema': 2,
    'seman': 2,
    'semans': 2,
    'semantic': 3,
    'semantics': 3,
    'sematech': 3,
    'sember': 2,
    'semblance': 2,
    'semegran': 3,
    'semel': 2,
    'semele': 3,
    'semen': 2,
    'semenza': 3,
    'semerad': 3,
    'semester': 3,
    'semesters': 3,
    'semi': 2,
    'semiannual': 5,
    'semiannually': 6,
    'semiautomatic': 6,
    'semicircular': 5,
    'semiclad': 3,
    'semiclassical': 5,
    'semicon': 3,
    'semiconductor': 5,
    'semiconductors': 5,
    'semicylindrical': 6,
    'semidry': 3,
    'semidrying': 4,
    'semien': 2,
    'semifinal': 4,
    'semifinalist': 5,
    'semifinalists': 5,
    'semifinals': 4,
    'semifinish': 4,
    'semifinished': 4,
    'semigloss': 3,
    'semilegendary': 6,
    'seminal': 3,
    'seminar': 3,
    'seminara': 4,
    'seminarian': 5,
    'seminarians': 5,
    'seminaries': 4,
    'seminario': 5,
    'seminars': 3,
    'seminary': 4,
    'seminole': 3,
    'seminoles': 3,
    'semionenkov': 5,
    'semiotic': 4,
    'semiotics': 4,
    'semipermanent': 5,
    'semiprecious': 4,
    'semira': 3,
    'semireligious': 5,
    'semis': 2,
    'semisecrecy': 5,
    'semite': 2,
    'semitic': 3,
    'semitism': 4,
    'semitrailer': 4,
    'semitropical': 5,
    'semler': 2,
    'semmel': 2,
    'semmens': 2,
    'semmes': 1,
    'semmler': 2,
    'semolina': 4,
    'semon': 2,
    'semones': 3,
    'semple': 2,
    'semrad': 2,
    'semrau': 2,
    'semrow': 2,
    'semtex': 2,
    'sen': 1,
    'sena': 2,
    'senate': 2,
    'senator': 3,
    'senatore': 4,
    'senatorial': 5,
    'senators': 3,
    'send': 1,
    'sendak': 2,
    'sendawal': 3,
    'sendelbach': 3,
    'sender': 2,
    'sendero': 3,
    'senders': 2,
    'sending': 2,
    'sendo': 2,
    'sends': 1,
    'seneca': 3,
    'senecal': 3,
    'senechal': 3,
    'seneff': 2,
    'senegal': 3,
    'senegalese': 4,
    'senegambia': 5,
    'seneker': 3,
    'senesac': 3,
    'senescence': 3,
    'senese': 2,
    'seney': 2,
    'senf': 1,
    'senff': 1,
    'senft': 1,
    'seng': 1,
    'senger': 2,
    'sengers': 2,
    'senile': 2,
    'senility': 4,
    'senior': 2,
    'seniority': 4,
    'seniornet': 3,
    'seniors': 2,
    'senk': 1,
    'senkbeil': 2,
    'senko': 2,
    'senn': 1,
    'senna': 2,
    'senne': 1,
    'senner': 2,
    'sennet': 2,
    'sennett': 2,
    'sennott': 2,
    'senor': 2,
    'senora': 3,
    'sens': 1,
    'sensabaugh': 3,
    'sensation': 3,
    'sensational': 4,
    'sensationalism': 6,
    'sensationalist': 5,
    'sensationalistic': 6,
    'sensationalize': 5,
    'sensationalized': 5,
    'sensationalizes': 6,
    'sensationalizing': 6,
    'sensations': 3,
    'sense': 1,
    'sensed': 1,
    'senseless': 2,
    'sensenbrenner': 4,
    'senseney': 3,
    'sensenig': 3,
    'senses': 2,
    'sensibilities': 5,
    'sensibility': 5,
    'sensible': 3,
    'sensibly': 3,
    'sensing': 2,
    'sensitive': 3,
    'sensitively': 4,
    'sensitiveness': 4,
    'sensitivities': 5,
    'sensitivity': 5,
    'sensitize': 3,
    'sensitized': 3,
    'senske': 1,
    'sensor': 2,
    'sensormatic': 4,
    'sensors': 2,
    'sensory': 3,
    'sensual': 3,
    'sensuality': 5,
    'sensuous': 3,
    'sent': 1,
    'sentell': 2,
    'sentelle': 2,
    'sentence': 2,
    'sentenced': 2,
    'sentences': 3,
    'sentencing': 3,
    'senter': 2,
    'senters': 2,
    'sentiment': 3,
    'sentimental': 4,
    'sentimentality': 6,
    'sentiments': 3,
    'sentinel': 3,
    'sentinels': 3,
    'sentman': 2,
    'sentra': 2,
    'sentras': 2,
    'sentries': 2,
    'sentry': 2,
    'senty': 2,
    'sentz': 1,
    'senzaki': 3,
    'seo': 2,
    'seoul': 1,
    'seow': 2,
    'sep': 1,
    'separate': 3,
    'separated': 4,
    'separately': 4,
    'separateness': 4,
    'separates': 3,
    'separating': 4,
    'separation': 4,
    'separations': 4,
    'separatism': 5,
    'separatist': 4,
    'separatists': 4,
    'sepe': 1,
    'sepeda': 3,
    'sephardic': 3,
    'sephardim': 3,
    'sephlon': 2,
    'sepia': 3,
    'sepich': 2,
    'sepik': 2,
    'sepp': 1,
    'seppala': 3,
    'seppi': 2,
    'sepsis': 2,
    'sept': 1,
    'septa': 2,
    'september': 3,
    'septer': 2,
    'septic': 2,
    'septima': 3,
    'septimus': 3,
    'septuagenarian': 7,
    'sepulvado': 4,
    'sepulveda': 4,
    'sequa': 2,
    'sequeira': 3,
    'sequel': 2,
    'sequels': 2,
    'sequence': 2,
    'sequences': 3,
    'sequencing': 3,
    'sequent': 2,
    'sequential': 3,
    'sequentially': 4,
    'sequester': 3,
    'sequestered': 3,
    'sequestering': 4,
    'sequesters': 3,
    'sequestration': 4,
    'sequin': 2,
    'sequined': 2,
    'sequins': 2,
    'sequitur': 3,
    'sequiturs': 3,
    'sequoia': 3,
    'sequoias': 3,
    'sequoyah': 3,
    'sera': 2,
    'serafin': 3,
    'serafina': 4,
    'serafine': 4,
    'serafini': 4,
    'serafino': 4,
    'seragut': 3,
    'seraphina': 4,
    'seraphine': 4,
    'seratonin': 4,
    'seratti': 3,
    'seraw': 2,
    'serb': 1,
    'serbaine': 2,
    'serbia': 3,
    'serbian': 3,
    'serbians': 3,
    'serbias': 3,
    'serbin': 2,
    'serbs': 1,
    'serbska': 2,
    'serco': 2,
    'serda': 2,
    'sere': 1,
    'serena': 3,
    'serenade': 3,
    'serenaded': 4,
    'serenading': 4,
    'serendipitous': 5,
    'serendipity': 5,
    'serene': 2,
    'serenely': 4,
    'serengeti': 4,
    'serenity': 4,
    'sereno': 3,
    'seres': 1,
    'serex': 2,
    'serf': 1,
    'serfass': 2,
    'serfin': 2,
    'serfling': 2,
    'serfs': 1,
    'serge': 1,
    'sergeant': 2,
    'sergeants': 2,
    'sergei': 2,
    'sergent': 2,
    'sergey': 2,
    'sergi': 2,
    'sergio': 3,
    'sergius': 3,
    'seria': 3,
    'serial': 3,
    'serialize': 4,
    'serialized': 4,
    'serials': 3,
    'series': 2,
    'serilda': 3,
    'serino': 3,
    'serio': 3,
    'serious': 3,
    'seriously': 4,
    'seriousness': 4,
    'serle': 2,
    'serlin': 2,
    'serling': 2,
    'serlo': 2,
    'sermersheim': 3,
    'sermon': 2,
    'sermons': 2,
    'serna': 2,
    'seroka': 3,
    'serological': 5,
    'serologist': 4,
    'serology': 4,
    'serono': 3,
    'serota': 3,
    'serotonin': 4,
    'serowski': 3,
    'serpa': 2,
    'serpas': 2,
    'serpe': 1,
    'serpent': 2,
    'serpentine': 3,
    'serpents': 2,
    'serpico': 3,
    'serr': 1,
    'serra': 2,
    'serrano': 3,
    'serranto': 3,
    'serrao': 2,
    'serrate': 2,
    'serrated': 3,
    'serrato': 3,
    'serratore': 4,
    'serres': 2,
    'serritella': 4,
    'sertich': 2,
    'serum': 2,
    'serv': 1,
    'servaas': 2,
    'servais': 2,
    'servam': 2,
    'servan': 2,
    'servant': 2,
    'servants': 2,
    'serve': 1,
    'served': 1,
    'servello': 3,
    'server': 2,
    'servers': 2,
    'serves': 1,
    'service': 2,
    'serviceable': 4,
    'serviced': 2,
    'serviceman': 3,
    'servicemaster': 4,
    'servicemen': 3,
    'servicer': 3,
    'services': 3,
    'servicing': 3,
    'servico': 3,
    'servidio': 4,
    'servile': 2,
    'servin': 2,
    'serving': 2,
    'servings': 2,
    'serviou': 3,
    'servis': 2,
    'serviss': 2,
    'servitude': 3,
    'servo': 2,
    'sesame': 3,
    'sesdaq': 2,
    'sese': 2,
    'seshadri': 3,
    'sesit': 2,
    'seskar': 2,
    'sesler': 3,
    'sesno': 2,
    'sessa': 2,
    'session': 2,
    'sessions': 2,
    'sessler': 2,
    'sessoms': 2,
    'sessums': 2,
    'sestak': 2,
    'sestanovich': 4,
    'sestito': 3,
    'set': 1,
    'setaro': 3,
    'setback': 2,
    'setbacks': 2,
    'seth': 1,
    'sether': 2,
    'sethi': 2,
    'sethness': 2,
    'sethuraman': 4,
    'seti': 2,
    'setliff': 2,
    'seto': 2,
    'seton': 2,
    'sets': 1,
    'setser': 2,
    'settanni': 3,
    'sette': 1,
    'setter': 2,
    'setterlund': 3,
    'setters': 2,
    'setting': 2,
    'settings': 2,
    'settle': 2,
    'settled': 2,
    'settlement': 3,
    'settlements': 3,
    'settlemire': 3,
    'settlemyre': 3,
    'settler': 3,
    'settlers': 2,
    'settles': 2,
    'settling': 3,
    'settsu': 2,
    'setty': 2,
    'setup': 2,
    'setups': 2,
    'setzer': 2,
    'setzler': 2,
    'seubert': 2,
    'seufert': 2,
    'seuss': 1,
    'sevareid': 3,
    'sevcik': 2,
    'seve': 2,
    'seven': 2,
    'sevenfold': 3,
    'sevens': 2,
    'seventeen': 3,
    'seventeens': 3,
    'seventeenth': 3,
    'seventh': 2,
    'sevenths': 2,
    'seventies': 3,
    'seventieth': 4,
    'seventy': 3,
    'sever': 2,
    'severa': 3,
    'several': 2,
    'severally': 3,
    'severance': 3,
    'severe': 2,
    'severed': 2,
    'severely': 3,
    'severest': 3,
    'severeville': 3,
    'severin': 3,
    'severing': 3,
    'severini': 4,
    'severino': 4,
    'severity': 4,
    'severn': 2,
    'severns': 2,
    'severovic': 4,
    'severs': 2,
    'severson': 3,
    'severt': 2,
    'severtson': 3,
    'sevey': 2,
    'sevick': 2,
    'sevier': 2,
    'sevigny': 3,
    'sevilla': 3,
    'seville': 2,
    'sevin': 2,
    'sevruga': 3,
    'sevy': 2,
    'sew': 1,
    'sewage': 2,
    'sewald': 2,
    'sewall': 2,
    'seward': 2,
    'sewed': 1,
    'sewell': 2,
    'sewer': 2,
    'sewerage': 3,
    'sewers': 2,
    'sewing': 2,
    'sewn': 1,
    'sex': 1,
    'sexauer': 3,
    'sexed': 1,
    'sexes': 2,
    'sexier': 3,
    'sexiest': 3,
    'sexiness': 3,
    'sexism': 3,
    'sexist': 2,
    'sexless': 2,
    'sexson': 2,
    'sextet': 2,
    'sexton': 2,
    'sextuplet': 3,
    'sextuplets': 3,
    'sextus': 2,
    'sexual': 3,
    'sexuality': 5,
    'sexually': 4,
    'sexy': 2,
    'seybert': 2,
    'seybold': 2,
    'seychelles': 2,
    'seydel': 2,
    'seydoux': 2,
    'seyer': 2,
    'seyfarth': 2,
    'seyfert': 2,
    'seyfried': 3,
    'seyi': 2,
    'seyler': 2,
    'seyller': 2,
    'seymore': 2,
    'seymour': 2,
    'sfernice': 2,
    'sferra': 2,
    'sferrazza': 3,
    'sffed': 1,
    'sforza': 2,
    'sfuzzi': 2,
    'sgambati': 3,
    'sgro': 1,
    'sgroi': 1,
    'sh': 2,
    'sha': 1,
    'shaak': 1,
    'shaanxi': 2,
    'shaath': 1,
    'shabad': 2,
    'shabazz': 2,
    'shabby': 2,
    'shaber': 2,
    'shack': 1,
    'shackelford': 3,
    'shackelton': 3,
    'shackett': 2,
    'shackle': 2,
    'shackled': 2,
    'shackleford': 3,
    'shackles': 2,
    'shackleton': 3,
    'shackletons': 3,
    'shacklett': 2,
    'shacks': 1,
    'shad': 1,
    'shadbush': 2,
    'shadd': 1,
    'shadden': 2,
    'shaddix': 2,
    'shaddock': 2,
    'shadduck': 2,
    'shade': 1,
    'shaded': 2,
    'shadegg': 2,
    'shadel': 2,
    'shader': 2,
    'shades': 1,
    'shadid': 2,
    'shading': 2,
    'shadings': 2,
    'shadle': 2,
    'shadley': 2,
    'shadoan': 2,
    'shadow': 2,
    'shadowed': 2,
    'shadowens': 3,
    'shadowing': 3,
    'shadowland': 3,
    'shadowlands': 3,
    'shadows': 2,
    'shadowy': 3,
    'shadrick': 2,
    'shadur': 2,
    'shadwell': 2,
    'shadwick': 2,
    'shady': 2,
    'shaefer': 2,
    'shaeffer': 2,
    'shaer': 2,
    'shafer': 2,
    'shaff': 1,
    'shaffer': 2,
    'shaffner': 2,
    'shafran': 2,
    'shaft': 1,
    'shafted': 2,
    'shafting': 2,
    'shafts': 1,
    'shag': 1,
    'shaggy': 2,
    'shah': 1,
    'shahan': 2,
    'shahar': 2,
    'shaheed': 2,
    'shaheen': 2,
    'shahian': 3,
    'shahidi': 3,
    'shahin': 2,
    'shahinian': 4,
    'shahrokh': 2,
    'shahrzad': 2,
    'shai': 1,
    'shaik': 1,
    'shaiken': 2,
    'shaikh': 1,
    'shain': 1,
    'shaine': 1,
    'shaka': 2,
    'shake': 1,
    'shakedown': 2,
    'shakedowns': 2,
    'shaken': 2,
    'shakeout': 2,
    'shaker': 2,
    'shakers': 2,
    'shakes': 1,
    'shakespeare': 2,
    'shakespearean': 4,
    'shakeup': 2,
    'shakeups': 2,
    'shakier': 3,
    'shakiest': 3,
    'shakiness': 3,
    'shaking': 2,
    'shakir': 2,
    'shaklee': 2,
    'shakunaga': 4,
    'shakur': 2,
    'shaky': 2,
    'shalala': 3,
    'shale': 1,
    'shaler': 2,
    'shales': 1,
    'shali': 2,
    'shalikashvili': 5,
    'shall': 1,
    'shallcross': 2,
    'shallenberger': 4,
    'shallow': 2,
    'shallower': 3,
    'shallowness': 3,
    'shalnev': 2,
    'shalom': 2,
    'shalt': 1,
    'sham': 1,
    'shamalia': 3,
    'shaman': 2,
    'shamanists': 3,
    'shamans': 2,
    'shamas': 2,
    'shambaugh': 2,
    'shamberger': 3,
    'shamble': 2,
    'shambles': 2,
    'shambley': 2,
    'shamblin': 2,
    'shambo': 2,
    'shamburg': 2,
    'shamburger': 3,
    'shame': 1,
    'shamed': 1,
    'shameful': 2,
    'shamel': 2,
    'shameless': 2,
    'shamelessly': 3,
    'shamelle': 2,
    'shames': 1,
    'shaming': 2,
    'shamir': 2,
    'shammond': 2,
    'shamp': 1,
    'shampine': 2,
    'shampoo': 2,
    'shampooed': 2,
    'shampoos': 2,
    'shamrock': 2,
    'shamu': 2,
    'shamus': 2,
    'shan': 1,
    'shana': 2,
    'shanafelt': 3,
    'shanahan': 3,
    'shanansky': 3,
    'shand': 1,
    'shandling': 2,
    'shandong': 2,
    'shands': 1,
    'shandwick': 2,
    'shandy': 2,
    'shane': 1,
    'shaner': 2,
    'shaneyfelt': 3,
    'shanghai': 2,
    'shangkun': 2,
    'shangri': 2,
    'shanholtz': 2,
    'shanholtzer': 3,
    'shank': 1,
    'shankar': 2,
    'shankel': 2,
    'shanken': 2,
    'shanker': 2,
    'shankland': 2,
    'shankle': 2,
    'shankles': 2,
    'shanklin': 2,
    'shankman': 2,
    'shanks': 1,
    'shanley': 2,
    'shannahan': 3,
    'shannon': 2,
    'shansev': 2,
    'shant': 1,
    'shanti': 2,
    'shanties': 2,
    'shanty': 2,
    'shantytown': 3,
    'shantytowns': 3,
    'shantz': 1,
    'shao': 1,
    'shap': 1,
    'shape': 1,
    'shaped': 1,
    'shapeless': 2,
    'shapely': 2,
    'shapero': 3,
    'shapes': 1,
    'shaping': 2,
    'shapira': 3,
    'shapiro': 3,
    'shapiros': 3,
    'shapley': 2,
    'shapp': 1,
    'shappell': 2,
    'shaq': 1,
    'shaquille': 2,
    'shara': 2,
    'sharaa': 2,
    'sharansky': 3,
    'sharar': 2,
    'sharber': 2,
    'sharbono': 3,
    'shard': 1,
    'shards': 1,
    'share': 1,
    'sharecrop': 2,
    'sharecropper': 3,
    'sharecroppers': 3,
    'shared': 1,
    'shareholder': 3,
    'shareholders': 3,
    'shareholding': 3,
    'shareholdings': 3,
    'sharell': 2,
    'shareowner': 3,
    'shareowners': 3,
    'sharer': 2,
    'shares': 1,
    'shareware': 2,
    'sharf': 1,
    'sharfman': 2,
    'shari': 2,
    'sharia': 3,
    'sharif': 2,
    'sharing': 2,
    'shark': 1,
    'sharkey': 2,
    'sharking': 2,
    'sharklike': 2,
    'sharks': 1,
    'sharla': 2,
    'sharleen': 2,
    'sharlene': 2,
    'sharline': 2,
    'sharlow': 2,
    'sharm': 1,
    'sharma': 2,
    'sharman': 2,
    'sharon': 2,
    'sharp': 1,
    'sharpe': 1,
    'sharpen': 2,
    'sharpened': 2,
    'sharpening': 3,
    'sharpens': 2,
    'sharper': 2,
    'sharpest': 2,
    'sharpeville': 3,
    'sharpie': 2,
    'sharpies': 2,
    'sharples': 2,
    'sharpless': 2,
    'sharpley': 2,
    'sharply': 2,
    'sharpnack': 2,
    'sharpness': 2,
    'sharps': 1,
    'sharpshooter': 3,
    'sharpshooters': 3,
    'sharpton': 2,
    'sharpy': 2,
    'sharrar': 2,
    'sharrer': 2,
    'sharrett': 2,
    'sharrock': 2,
    'sharron': 2,
    'sharrow': 2,
    'sharry': 2,
    'shartzer': 2,
    'sharum': 2,
    'sharyl': 2,
    'shas': 1,
    'shashlik': 2,
    'shashoua': 3,
    'shasta': 2,
    'shasteen': 2,
    'shatkin': 2,
    'shatley': 2,
    'shatman': 2,
    'shatner': 2,
    'shatrov': 2,
    'shatt': 1,
    'shatter': 2,
    'shattered': 2,
    'shattering': 3,
    'shatterproof': 3,
    'shatters': 2,
    'shattuck': 2,
    'shatz': 1,
    'shatzer': 2,
    'shaub': 1,
    'shauger': 2,
    'shaughnessy': 3,
    'shaul': 1,
    'shaulis': 2,
    'shaull': 1,
    'shaun': 1,
    'shauna': 2,
    'shave': 1,
    'shaved': 1,
    'shavelson': 3,
    'shaven': 2,
    'shaver': 2,
    'shavers': 2,
    'shaves': 1,
    'shaving': 2,
    'shavings': 2,
    'shavord': 2,
    'shaw': 1,
    'shawcross': 2,
    'shawgo': 2,
    'shawhan': 2,
    'shawinigan': 4,
    'shawl': 1,
    'shawler': 2,
    'shawley': 2,
    'shawls': 1,
    'shawmut': 2,
    'shawn': 1,
    'shawna': 2,
    'shawnee': 2,
    'shawnut': 2,
    'shawshank': 2,
    'shawver': 2,
    'shay': 1,
    'shaykin': 2,
    'shayne': 1,
    'shays': 1,
    'shcharansky': 3,
    'shchedrin': 2,
    'shcherbitsky': 3,
    'she': 1,
    'shea': 1,
    'shead': 1,
    'sheaf': 1,
    'sheaffer': 2,
    'sheahan': 2,
    'shealey': 2,
    'shealy': 2,
    'shean': 1,
    'shear': 1,
    'sheard': 1,
    'sheared': 1,
    'shearer': 2,
    'shearin': 2,
    'shearing': 2,
    'shearman': 2,
    'shearn': 1,
    'shearon': 2,
    'shearouse': 2,
    'shears': 1,
    'shearson': 2,
    'sheath': 1,
    'sheathbill': 2,
    'sheathbills': 2,
    'sheathe': 1,
    'sheathed': 1,
    'sheathing': 2,
    'sheats': 1,
    'sheaves': 1,
    'sheba': 2,
    'shebek': 2,
    'sheboygan': 3,
    'sheck': 1,
    'sheckler': 2,
    'shed': 1,
    'shedd': 1,
    'shedden': 2,
    'shedding': 2,
    'shedlock': 2,
    'shedrick': 2,
    'sheds': 1,
    'sheeder': 2,
    'sheedy': 2,
    'sheehan': 2,
    'sheehy': 2,
    'sheek': 1,
    'sheeks': 1,
    'sheela': 2,
    'sheelah': 2,
    'sheelen': 2,
    'sheeler': 2,
    'sheeley': 2,
    'sheely': 2,
    'sheen': 1,
    'sheena': 2,
    'sheens': 1,
    'sheep': 1,
    'sheepish': 2,
    'sheepishly': 3,
    'sheeps': 1,
    'sheepskin': 2,
    'sheer': 1,
    'sheeran': 2,
    'sheerer': 2,
    'sheerin': 2,
    'sheesh': 1,
    'sheesley': 2,
    'sheet': 1,
    'sheeting': 2,
    'sheets': 1,
    'sheetz': 1,
    'sheff': 1,
    'sheffer': 2,
    'sheffey': 2,
    'sheffield': 2,
    'sheffler': 2,
    'sheftel': 2,
    'shegog': 2,
    'shehan': 2,
    'shehane': 2,
    'shehorn': 2,
    'sheik': 1,
    'sheikdom': 2,
    'sheikdoms': 2,
    'sheikh': 1,
    'sheiks': 1,
    'sheil': 1,
    'sheila': 2,
    'sheilah': 2,
    'sheilds': 1,
    'sheils': 1,
    'shein': 1,
    'sheinberg': 2,
    'shek': 1,
    'shekel': 2,
    'shekels': 2,
    'shelburne': 2,
    'shelby': 2,
    'shelbyville': 3,
    'sheldahl': 2,
    'shelden': 2,
    'sheldon': 2,
    'shelev': 2,
    'sheley': 2,
    'shelf': 1,
    'shelhah': 2,
    'shelhamer': 3,
    'shelia': 2,
    'sheline': 2,
    'shell': 1,
    'shellabarger': 4,
    'shelled': 1,
    'shellenbarger': 4,
    'shellenberger': 4,
    'sheller': 2,
    'shelley': 2,
    'shellfish': 2,
    'shellhammer': 3,
    'shellhorn': 2,
    'shelling': 2,
    'shellings': 2,
    'shellito': 3,
    'shellman': 2,
    'shells': 1,
    'shellshock': 2,
    'shellshocked': 2,
    'shelly': 2,
    'shelman': 2,
    'shelnutt': 2,
    'shelor': 2,
    'shelp': 1,
    'shelstad': 2,
    'shelter': 2,
    'sheltered': 2,
    'sheltering': 3,
    'shelters': 2,
    'shelton': 2,
    'shelve': 1,
    'shelved': 1,
    'shelves': 1,
    'shelving': 2,
    'shema': 2,
    'shemanski': 3,
    'shemona': 3,
    'shemwell': 2,
    'shen': 1,
    'shena': 2,
    'shenandoah': 4,
    'shenanigan': 4,
    'shenanigans': 4,
    'shenberger': 3,
    'shenefield': 3,
    'sheneman': 2,
    'sheng': 1,
    'shenice': 2,
    'shenk': 1,
    'shenker': 2,
    'shenkman': 2,
    'shenton': 2,
    'shenyang': 2,
    'shenzhen': 2,
    'shep': 1,
    'shepard': 2,
    'shepardson': 3,
    'sheperd': 2,
    'shephard': 2,
    'shepheard': 2,
    'shepherd': 2,
    'shepherded': 3,
    'shepherding': 3,
    'shepherds': 2,
    'shepler': 2,
    'shepley': 2,
    'shepp': 1,
    'sheppard': 2,
    'sheppards': 2,
    'shepper': 2,
    'shepperd': 2,
    'shepperson': 3,
    'sheppy': 2,
    'sher': 1,
    'sherak': 2,
    'sherard': 2,
    'sheraton': 3,
    'sherbert': 2,
    'sherbet': 2,
    'sherbondy': 3,
    'sherborne': 2,
    'sherbourn': 2,
    'sherbourne': 2,
    'sherburn': 2,
    'sherburne': 2,
    'shere': 1,
    'sheree': 2,
    'sherer': 2,
    'sherfey': 2,
    'sherfield': 2,
    'sheri': 2,
    'sherick': 2,
    'sheridan': 3,
    'sheriff': 2,
    'sheriffs': 2,
    'sherin': 2,
    'sherk': 1,
    'sherley': 2,
    'sherlin': 2,
    'sherlock': 2,
    'sherlund': 2,
    'sherman': 2,
    'shermer': 2,
    'shernoff': 2,
    'sherod': 2,
    'sherr': 1,
    'sherrard': 2,
    'sherrell': 2,
    'sherrer': 2,
    'sherrgold': 2,
    'sherri': 2,
    'sherrick': 2,
    'sherrie': 2,
    'sherriff': 2,
    'sherrill': 2,
    'sherrin': 2,
    'sherrit': 2,
    'sherritt': 2,
    'sherrod': 2,
    'sherron': 2,
    'sherrow': 2,
    'sherry': 2,
    'shertzer': 2,
    'sherva': 2,
    'sherwin': 2,
    'sherwood': 2,
    'sheryl': 2,
    'sheshunoff': 3,
    'sheth': 1,
    'shetland': 2,
    'shetler': 2,
    'shetley': 2,
    'shetter': 2,
    'shetterly': 3,
    'shevardnadze': 4,
    'shevlin': 2,
    'shevtl': 2,
    'shew': 1,
    'sheward': 2,
    'shewchuk': 2,
    'shewell': 2,
    'shewmake': 2,
    'shewmaker': 3,
    'shh': 0,
    'shi': 1,
    'shia': 2,
    'shiah': 2,
    'shias': 2,
    'shibanna': 3,
    'shibata': 3,
    'shibboleth': 3,
    'shibley': 2,
    'shick': 1,
    'shicoff': 2,
    'shideler': 3,
    'shidler': 3,
    'shied': 1,
    'shieh': 1,
    'shiel': 1,
    'shiela': 2,
    'shield': 1,
    'shielded': 2,
    'shielding': 2,
    'shields': 1,
    'shiels': 1,
    'shier': 2,
    'shies': 1,
    'shiff': 1,
    'shiffer': 2,
    'shiffler': 2,
    'shifflet': 2,
    'shifflett': 2,
    'shiffman': 2,
    'shiflet': 2,
    'shiflett': 2,
    'shifrin': 2,
    'shift': 1,
    'shifted': 2,
    'shifter': 2,
    'shifting': 2,
    'shiftless': 2,
    'shifts': 1,
    'shifty': 2,
    'shigeki': 3,
    'shigekuni': 4,
    'shigeo': 3,
    'shigeru': 3,
    'shigley': 2,
    'shih': 1,
    'shihan': 2,
    'shiina': 2,
    'shiite': 2,
    'shiites': 2,
    'shiley': 2,
    'shill': 1,
    'shiller': 2,
    'shilling': 2,
    'shillingburg': 3,
    'shillinger': 3,
    'shillinglaw': 3,
    'shillings': 2,
    'shillington': 3,
    'shiloh': 2,
    'shilts': 1,
    'shim': 1,
    'shima': 2,
    'shimabukuro': 5,
    'shimada': 3,
    'shimbun': 2,
    'shimek': 2,
    'shimel': 2,
    'shimer': 2,
    'shimizu': 3,
    'shimko': 2,
    'shimkus': 2,
    'shimmel': 2,
    'shimmer': 2,
    'shimmered': 2,
    'shimmering': 3,
    'shimmerlik': 3,
    'shimmers': 2,
    'shimmin': 2,
    'shimmy': 2,
    'shimoda': 3,
    'shimoga': 3,
    'shimokawa': 4,
    'shimon': 2,
    'shimone': 2,
    'shimp': 1,
    'shin': 1,
    'shinall': 2,
    'shinault': 2,
    'shinbein': 2,
    'shinbone': 2,
    'shindig': 2,
    'shindle': 2,
    'shindledecker': 4,
    'shindler': 2,
    'shindou': 2,
    'shine': 1,
    'shined': 1,
    'shiner': 2,
    'shines': 1,
    'shing': 1,
    'shingle': 2,
    'shingledecker': 4,
    'shingler': 2,
    'shingles': 2,
    'shingleton': 3,
    'shinichi': 3,
    'shining': 2,
    'shinkle': 2,
    'shinko': 2,
    'shinn': 1,
    'shinners': 2,
    'shinnick': 2,
    'shinri': 2,
    'shinrikyo': 3,
    'shinsei': 2,
    'shinsky': 2,
    'shintaro': 3,
    'shinto': 2,
    'shinwa': 2,
    'shinxiaku': 3,
    'shiny': 2,
    'shinyuka': 3,
    'shiong': 2,
    'shionogi': 4,
    'ship': 1,
    'shipboard': 2,
    'shipbuilder': 3,
    'shipbuilders': 3,
    'shipbuilding': 3,
    'shipbuildings': 3,
    'shipe': 1,
    'shipes': 1,
    'shipholding': 3,
    'shiplett': 2,
    'shipley': 2,
    'shipload': 2,
    'shiploads': 2,
    'shipman': 2,
    'shipmate': 2,
    'shipmates': 2,
    'shipment': 2,
    'shipments': 2,
    'shipowner': 3,
    'shipowners': 3,
    'shipp': 1,
    'shipped': 1,
    'shippee': 2,
    'shipper': 2,
    'shippers': 2,
    'shippey': 2,
    'shipping': 2,
    'shippingport': 3,
    'shipps': 1,
    'shippy': 2,
    'ships': 1,
    'shipshape': 2,
    'shipton': 2,
    'shipwash': 2,
    'shipwreck': 2,
    'shipwright': 2,
    'shipwrights': 2,
    'shipyard': 2,
    'shipyards': 2,
    'shir': 1,
    'shira': 2,
    'shirah': 2,
    'shiraishi': 4,
    'shirazi': 3,
    'shire': 1,
    'shirell': 2,
    'shirelle': 2,
    'shireman': 3,
    'shiremanstown': 3,
    'shirer': 2,
    'shires': 1,
    'shirey': 2,
    'shirin': 2,
    'shirk': 1,
    'shirked': 1,
    'shirkey': 2,
    'shirking': 2,
    'shirl': 1,
    'shirlee': 2,
    'shirleen': 2,
    'shirlene': 2,
    'shirley': 2,
    'shirlie': 2,
    'shiroma': 3,
    'shirone': 2,
    'shirr': 1,
    'shirrell': 2,
    'shirt': 1,
    'shirted': 2,
    'shirting': 2,
    'shirts': 1,
    'shirtsleeve': 2,
    'shiseido': 3,
    'shishido': 3,
    'shisler': 3,
    'shissler': 2,
    'shit': 1,
    'shithead': 2,
    'shitila': 3,
    'shitty': 2,
    'shiu': 1,
    'shiva': 2,
    'shive': 1,
    'shiveley': 3,
    'shively': 2,
    'shiver': 2,
    'shiverdecker': 4,
    'shivered': 2,
    'shivering': 3,
    'shivers': 2,
    'shives': 1,
    'shivley': 2,
    'shiyuan': 3,
    'shizuka': 3,
    'shizuoka': 3,
    'shlaes': 1,
    'shlenker': 2,
    'shlobidan': 3,
    'shoaf': 1,
    'shoaff': 1,
    'shoals': 1,
    'shobana': 3,
    'shobanna': 3,
    'shobe': 1,
    'shober': 2,
    'shoberg': 2,
    'shobert': 2,
    'shock': 1,
    'shocked': 1,
    'shocker': 2,
    'shockey': 2,
    'shocking': 2,
    'shockingly': 3,
    'shockley': 2,
    'shocks': 1,
    'shockwave': 2,
    'shockwaves': 2,
    'shod': 1,
    'shoddy': 2,
    'shoe': 1,
    'shoebox': 2,
    'shoehorn': 2,
    'shoehorned': 2,
    'shoelace': 2,
    'shoelaces': 3,
    'shoemake': 2,
    'shoemaker': 3,
    'shoemakers': 3,
    'shoemate': 2,
    'shoen': 1,
    'shoeprint': 2,
    'shoeprints': 2,
    'shoes': 1,
    'shoeshine': 2,
    'shoestring': 2,
    'shoff': 1,
    'shoffner': 2,
    'shofner': 2,
    'shogren': 2,
    'shogun': 2,
    'shohat': 2,
    'shoichi': 3,
    'shoichiro': 4,
    'shoji': 2,
    'shokhin': 2,
    'shoko': 2,
    'sholar': 2,
    'sholem': 2,
    'sholes': 1,
    'sholl': 1,
    'shollenberger': 4,
    'sholly': 2,
    'sholtis': 2,
    'sholto': 2,
    'shomaker': 3,
    'shomo': 2,
    'shon': 1,
    'shona': 2,
    'shone': 1,
    'shoney': 2,
    'shonk': 1,
    'shonka': 2,
    'shonkwiler': 3,
    'shontz': 1,
    'shoo': 1,
    'shoob': 1,
    'shooed': 1,
    'shoofly': 2,
    'shook': 1,
    'shoop': 1,
    'shoopman': 2,
    'shooshan': 2,
    'shoot': 1,
    'shootdown': 2,
    'shooter': 2,
    'shooters': 2,
    'shooting': 2,
    'shootings': 2,
    'shootout': 2,
    'shootouts': 2,
    'shoots': 1,
    'shop': 1,
    'shope': 1,
    'shopkeeper': 3,
    'shopkeepers': 3,
    'shopko': 2,
    'shopkorn': 2,
    'shoplift': 2,
    'shoplifter': 3,
    'shoplifters': 3,
    'shoplifting': 3,
    'shoppe': 1,
    'shopped': 1,
    'shopper': 2,
    'shoppers': 2,
    'shoppes': 1,
    'shopping': 2,
    'shops': 1,
    'shoptaw': 2,
    'shopwell': 2,
    'shopworn': 2,
    'shor': 2,
    'shorb': 1,
    'shore': 1,
    'shorebird': 2,
    'shored': 1,
    'shoreham': 2,
    'shoreline': 2,
    'shorenstein': 3,
    'shores': 1,
    'shoreward': 2,
    'shorey': 2,
    'shorin': 2,
    'shoring': 2,
    'shorkey': 2,
    'shorn': 1,
    'shorr': 1,
    'short': 1,
    'shortage': 2,
    'shortages': 3,
    'shortall': 2,
    'shortcake': 2,
    'shortchange': 2,
    'shortchanged': 2,
    'shortchanging': 3,
    'shortcoming': 3,
    'shortcomings': 3,
    'shortcut': 2,
    'shortcuts': 2,
    'shorted': 2,
    'shortell': 2,
    'shorten': 2,
    'shortened': 2,
    'shortening': 3,
    'shortens': 2,
    'shorter': 2,
    'shortest': 2,
    'shortfall': 2,
    'shortfalls': 2,
    'shortgrass': 2,
    'shorthair': 2,
    'shorthaired': 2,
    'shorthand': 2,
    'shorting': 2,
    'shortlived': 2,
    'shortly': 2,
    'shortness': 2,
    'shortridge': 2,
    'shorts': 1,
    'shortsighted': 3,
    'shortsightedness': 4,
    'shortstop': 2,
    'shortt': 1,
    'shortterm': 2,
    'shortwave': 2,
    'shorty': 2,
    'shortz': 1,
    'shosh': 1,
    'shoshone': 3,
    'shostak': 2,
    'shostakovich': 4,
    'shot': 1,
    'shotgun': 2,
    'shotguns': 2,
    'shots': 1,
    'shott': 1,
    'shotts': 1,
    'shotwell': 2,
    'shougang': 2,
    'shough': 1,
    'should': 1,
    'shoulder': 2,
    'shouldered': 2,
    'shouldering': 3,
    'shoulders': 2,
    'shouli': 2,
    'shoults': 1,
    'shoultz': 1,
    'shoumaker': 3,
    'shoun': 1,
    'shoup': 1,
    'shoupe': 1,
    'shouse': 1,
    'shout': 1,
    'shouted': 2,
    'shouting': 2,
    'shouts': 1,
    'shoval': 2,
    'shove': 1,
    'shoved': 1,
    'shovel': 2,
    'shoveled': 2,
    'shoveling': 2,
    'shovels': 2,
    'shover': 2,
    'shoves': 1,
    'shoving': 2,
    'shovlin': 2,
    'show': 1,
    'showa': 2,
    'showalter': 3,
    'showbiz': 2,
    'showboat': 2,
    'showbuzz': 2,
    'showcase': 2,
    'showcased': 2,
    'showcases': 3,
    'showcasing': 3,
    'showdown': 2,
    'showdowns': 2,
    'showed': 1,
    'showell': 2,
    'shower': 2,
    'showered': 2,
    'showering': 3,
    'showers': 2,
    'showgirl': 2,
    'showgirls': 2,
    'showiest': 3,
    'showing': 2,
    'showings': 2,
    'showman': 2,
    'showmanship': 3,
    'shown': 1,
    'showpiece': 2,
    'showplace': 2,
    'showroom': 2,
    'showrooms': 2,
    'shows': 1,
    'showscan': 2,
    'showtime': 2,
    'showy': 2,
    'shrader': 2,
    'shrake': 1,
    'shramek': 2,
    'shrank': 1,
    'shrapnel': 2,
    'shreck': 1,
    'shred': 1,
    'shredded': 2,
    'shredder': 2,
    'shredders': 2,
    'shredding': 2,
    'shreds': 1,
    'shreeve': 1,
    'shreffler': 2,
    'shreiber': 2,
    'shreiner': 2,
    'shreve': 1,
    'shreveport': 2,
    'shreves': 1,
    'shrewd': 1,
    'shrewdest': 2,
    'shrewdly': 2,
    'shrewdness': 2,
    'shrewsberry': 3,
    'shrewsbury': 3,
    'shri': 1,
    'shriber': 2,
    'shribman': 2,
    'shrider': 2,
    'shriek': 1,
    'shrieked': 1,
    'shrieking': 2,
    'shrieks': 1,
    'shrift': 1,
    'shrikant': 2,
    'shrikelike': 2,
    'shrill': 1,
    'shrimp': 1,
    'shrimper': 2,
    'shrimpers': 2,
    'shrine': 1,
    'shriner': 2,
    'shriners': 2,
    'shrines': 1,
    'shrink': 1,
    'shrinkage': 2,
    'shrinking': 2,
    'shrinks': 1,
    'shrivel': 2,
    'shriveled': 2,
    'shriveling': 3,
    'shriver': 2,
    'shrock': 1,
    'shrode': 1,
    'shroff': 1,
    'shrontz': 1,
    'shropshire': 2,
    'shroud': 1,
    'shrouded': 2,
    'shrouding': 2,
    'shrouds': 1,
    'shrout': 1,
    'shroyer': 2,
    'shrub': 1,
    'shrubbery': 3,
    'shrubby': 2,
    'shrublike': 2,
    'shrubs': 1,
    'shrug': 1,
    'shrugged': 1,
    'shrugging': 2,
    'shrugs': 1,
    'shrum': 1,
    'shrunk': 1,
    'shrunken': 2,
    'shryock': 2,
    'shtick': 1,
    'shtyrov': 2,
    'shu': 1,
    'shuart': 2,
    'shubert': 2,
    'shubin': 2,
    'shuchman': 2,
    'shuck': 1,
    'shucked': 1,
    'shucking': 2,
    'shucks': 1,
    'shuda': 2,
    'shudder': 2,
    'shuddered': 2,
    'shuddering': 3,
    'shudders': 2,
    'shue': 1,
    'shuey': 2,
    'shufelt': 2,
    'shuff': 1,
    'shuffield': 2,
    'shuffle': 2,
    'shuffled': 2,
    'shuffler': 3,
    'shufflers': 3,
    'shuffles': 2,
    'shuffling': 2,
    'shuford': 2,
    'shufro': 2,
    'shugars': 2,
    'shugart': 2,
    'shughart': 2,
    'shugrue': 2,
    'shui': 2,
    'shukla': 2,
    'shukri': 2,
    'shula': 2,
    'shular': 2,
    'shuler': 2,
    'shull': 1,
    'shulman': 2,
    'shultis': 2,
    'shults': 1,
    'shultz': 1,
    'shum': 1,
    'shumake': 2,
    'shumaker': 3,
    'shuman': 2,
    'shumard': 2,
    'shumate': 2,
    'shumeet': 2,
    'shumer': 2,
    'shumpert': 2,
    'shumsky': 2,
    'shumway': 2,
    'shun': 1,
    'shunk': 1,
    'shunned': 1,
    'shunning': 2,
    'shuns': 1,
    'shunt': 1,
    'shunted': 2,
    'shunting': 2,
    'shunto': 2,
    'shunts': 1,
    'shupe': 1,
    'shupert': 2,
    'shuping': 2,
    'shupp': 1,
    'shur': 1,
    'shure': 1,
    'shurgard': 2,
    'shurley': 2,
    'shurr': 1,
    'shurtleff': 2,
    'shurtliff': 2,
    'shurtz': 1,
    'shuster': 2,
    'shusterman': 3,
    'shut': 1,
    'shutdown': 2,
    'shutdowns': 2,
    'shute': 1,
    'shutes': 1,
    'shutout': 2,
    'shuts': 1,
    'shutt': 1,
    'shutter': 2,
    'shuttered': 2,
    'shuttering': 3,
    'shutters': 2,
    'shutting': 2,
    'shuttle': 2,
    'shuttled': 2,
    'shuttles': 2,
    'shuttlesworth': 3,
    'shuttleworth': 3,
    'shuttling': 3,
    'shutts': 1,
    'shuwa': 2,
    'shy': 1,
    'shying': 2,
    'shyjan': 2,
    'shylock': 2,
    'shyly': 2,
    'shymanski': 3,
    'shyne': 1,
    'shyness': 2,
    'shyster': 2,
    'shysters': 2,
    'si': 1,
    'siad': 2,
    'siam': 2,
    'siamese': 3,
    'siang': 1,
    'siano': 3,
    'sias': 1,
    'sib': 1,
    'sibbie': 2,
    'sibby': 2,
    'sibelle': 2,
    'siberia': 4,
    'siberian': 4,
    'sibert': 2,
    'sibil': 2,
    'sibilia': 4,
    'sibilla': 3,
    'sibille': 2,
    'sible': 2,
    'sibley': 2,
    'sibling': 2,
    'siblings': 2,
    'sibson': 2,
    'sibyl': 2,
    'sibyll': 2,
    'sic': 1,
    'sica': 2,
    'sicard': 2,
    'sichel': 2,
    'sichuan': 2,
    'sicilia': 3,
    'sicilian': 4,
    'siciliano': 5,
    'sicily': 3,
    'sick': 1,
    'sickel': 2,
    'sickels': 2,
    'sicken': 2,
    'sickened': 2,
    'sickening': 3,
    'sicker': 2,
    'sickest': 2,
    'sickinger': 3,
    'sickle': 2,
    'sickler': 2,
    'sickles': 2,
    'sickly': 2,
    'sickman': 2,
    'sickness': 2,
    'siconolfi': 4,
    'sicotte': 2,
    'sid': 1,
    'sidak': 2,
    'siddall': 2,
    'siddeley': 3,
    'siddell': 2,
    'sidden': 2,
    'siddens': 2,
    'siddhartha': 3,
    'siddig': 2,
    'siddiqi': 3,
    'siddiqui': 3,
    'siddle': 2,
    'siddons': 2,
    'side': 1,
    'sidearm': 2,
    'sidearms': 2,
    'sidebar': 2,
    'sidebars': 2,
    'sided': 2,
    'sidek': 2,
    'sidekick': 2,
    'sideline': 2,
    'sidelined': 2,
    'sidelines': 2,
    'sidell': 2,
    'sideman': 2,
    'sidener': 3,
    'sider': 2,
    'siderca': 3,
    'sideris': 3,
    'siderographer': 5,
    'siderographers': 5,
    'siders': 2,
    'sides': 1,
    'sideshow': 2,
    'sideshows': 2,
    'sidestep': 2,
    'sidestepped': 2,
    'sidestepping': 3,
    'sidesteps': 2,
    'sidestream': 2,
    'sidetrack': 2,
    'sidetracked': 2,
    'sidewalk': 2,
    'sidewalks': 2,
    'sidewater': 3,
    'sideways': 2,
    'sidewinder': 3,
    'sidewise': 2,
    'sidey': 2,
    'sidgraph': 2,
    'sidhu': 2,
    'sidi': 2,
    'siding': 2,
    'sidle': 2,
    'sidled': 2,
    'sidler': 3,
    'sidles': 2,
    'sidley': 2,
    'sidling': 3,
    'sidman': 2,
    'sidney': 2,
    'sidon': 2,
    'sidonia': 4,
    'sidor': 2,
    'sidoti': 3,
    'sidra': 2,
    'sids': 1,
    'sidwell': 2,
    'sie': 1,
    'siebe': 1,
    'siebel': 2,
    'siebels': 2,
    'sieben': 2,
    'siebenaler': 4,
    'sieber': 2,
    'siebers': 2,
    'siebert': 2,
    'siebold': 2,
    'siebrecht': 2,
    'sieck': 1,
    'siecle': 2,
    'siedenburg': 3,
    'siedlecki': 3,
    'siedschlag': 2,
    'siefert': 2,
    'siefken': 2,
    'siefker': 2,
    'sieg': 1,
    'siegal': 2,
    'siegan': 2,
    'siege': 1,
    'siegecraft': 2,
    'siegel': 2,
    'siegell': 2,
    'siegelman': 3,
    'siegenthaler': 4,
    'sieger': 2,
    'siegert': 2,
    'sieges': 2,
    'siegfried': 2,
    'siegle': 2,
    'siegler': 2,
    'siegman': 2,
    'siegmann': 2,
    'siegmund': 2,
    'siegrist': 2,
    'sieh': 1,
    'sieja': 2,
    'siek': 1,
    'siekierski': 3,
    'siekman': 2,
    'sielaff': 2,
    'sieler': 2,
    'sieling': 2,
    'sieloff': 2,
    'sielski': 2,
    'siem': 1,
    'siemel': 2,
    'siemens': 2,
    'siemer': 2,
    'siemers': 2,
    'sieminski': 3,
    'siemon': 2,
    'siems': 1,
    'siemsen': 2,
    'sienko': 3,
    'sienna': 3,
    'sieracki': 3,
    'sierchio': 3,
    'sierra': 3,
    'sierracin': 4,
    'sierras': 3,
    'siers': 1,
    'sies': 1,
    'siese': 2,
    'siess': 1,
    'sietsema': 3,
    'sieve': 1,
    'siever': 2,
    'sievers': 2,
    'sievert': 2,
    'sieves': 1,
    'sieving': 2,
    'siew': 1,
    'siewert': 2,
    'siewiorek': 3,
    'sifco': 2,
    'sifers': 2,
    'sifford': 2,
    'sift': 1,
    'sifted': 2,
    'sifting': 2,
    'sifts': 1,
    'sifuentes': 3,
    'sig': 1,
    'sigafoos': 3,
    'sigal': 2,
    'sigala': 3,
    'sigel': 2,
    'sigfreda': 3,
    'sigg': 1,
    'siggers': 2,
    'siggins': 2,
    'sigh': 1,
    'sighed': 1,
    'sighing': 2,
    'sighs': 1,
    'sight': 1,
    'sighted': 2,
    'sighting': 2,
    'sightings': 2,
    'sights': 1,
    'sightsee': 2,
    'sightseeing': 3,
    'sightseer': 3,
    'sightseers': 3,
    'sigl': 2,
    'sigle': 2,
    'sigler': 3,
    'sigley': 2,
    'siglin': 2,
    'sigma': 2,
    'sigmaform': 3,
    'sigman': 2,
    'sigmon': 2,
    'sigmund': 2,
    'sign': 1,
    'signa': 2,
    'signage': 2,
    'signal': 2,
    'signaled': 2,
    'signaling': 3,
    'signalled': 2,
    'signalling': 3,
    'signals': 2,
    'signatories': 4,
    'signatory': 4,
    'signature': 3,
    'signatures': 3,
    'signed': 1,
    'signer': 2,
    'signers': 2,
    'signet': 2,
    'significance': 4,
    'significant': 4,
    'significantly': 5,
    'signified': 3,
    'signifies': 3,
    'signify': 3,
    'signifying': 4,
    'signing': 2,
    'signings': 2,
    'signor': 2,
    'signore': 3,
    'signorelli': 4,
    'signori': 3,
    'signpost': 2,
    'signposts': 2,
    'signs': 1,
    'sigoloff': 3,
    'sigourney': 3,
    'sigrid': 2,
    'sigrist': 2,
    'sigur': 2,
    'sigurd': 2,
    'sigurdson': 3,
    'sigwald': 2,
    'sihanouk': 3,
    'sikes': 1,
    'sikh': 1,
    'sikhs': 1,
    'sikkema': 3,
    'siklie': 2,
    'sikora': 3,
    'sikorski': 3,
    'sikorsky': 3,
    'sil': 1,
    'silajdzic': 3,
    'silas': 2,
    'silbaugh': 2,
    'silber': 2,
    'silberberg': 3,
    'silberg': 2,
    'silbergeld': 3,
    'silberman': 3,
    'silbernagel': 4,
    'silberner': 3,
    'silberstein': 3,
    'silbert': 2,
    'silcott': 2,
    'silcox': 2,
    'silence': 2,
    'silenced': 2,
    'silences': 3,
    'silencing': 3,
    'silent': 2,
    'silently': 3,
    'sileo': 3,
    'siler': 2,
    'silesia': 3,
    'silex': 2,
    'silfies': 2,
    'silga': 2,
    'silguero': 3,
    'silhouette': 3,
    'silhouetted': 4,
    'silhouettes': 3,
    'silica': 3,
    'silicate': 3,
    'silicates': 3,
    'silicon': 3,
    'silicone': 3,
    'silicones': 3,
    'siliconix': 4,
    'silk': 1,
    'silken': 2,
    'silkey': 2,
    'silks': 1,
    'silkwood': 2,
    'silkworm': 2,
    'silkworms': 2,
    'silky': 2,
    'sill': 1,
    'siller': 2,
    'sillerman': 3,
    'sillier': 3,
    'silliest': 3,
    'silliman': 3,
    'silliness': 3,
    'sillman': 2,
    'sills': 1,
    'silly': 2,
    'silman': 2,
    'silmon': 2,
    'silo': 2,
    'silos': 2,
    'silsbee': 2,
    'silsby': 2,
    'silt': 1,
    'siltec': 2,
    'silva': 2,
    'silvadio': 4,
    'silvana': 3,
    'silvar': 2,
    'silvas': 2,
    'silveira': 3,
    'silver': 2,
    'silvera': 3,
    'silverado': 4,
    'silverberg': 3,
    'silvercrest': 3,
    'silverdome': 3,
    'silveri': 3,
    'silveria': 4,
    'silverio': 4,
    'silverlake': 3,
    'silverman': 3,
    'silvernail': 3,
    'silverplate': 3,
    'silvers': 2,
    'silvershoe': 3,
    'silverside': 3,
    'silversides': 3,
    'silverstein': 3,
    'silverstone': 3,
    'silverthorn': 3,
    'silverthorne': 3,
    'silverware': 3,
    'silvery': 3,
    'silvester': 3,
    'silvestre': 3,
    'silvestri': 3,
    'silvestro': 3,
    'silvey': 2,
    'silvi': 2,
    'silvia': 3,
    'silvie': 2,
    'silvio': 3,
    'silvis': 2,
    'silvius': 3,
    'sim': 1,
    'sima': 2,
    'simard': 2,
    'simas': 2,
    'simba': 2,
    'simcha': 2,
    'simco': 2,
    'simcoe': 2,
    'simcox': 2,
    'sime': 1,
    'simek': 2,
    'simenson': 3,
    'simental': 3,
    'simeon': 3,
    'simeone': 3,
    'simer': 2,
    'simerly': 3,
    'simerson': 3,
    'simes': 1,
    'simex': 2,
    'simi': 2,
    'simian': 3,
    'simich': 2,
    'simien': 2,
    'similack': 3,
    'similar': 3,
    'similarities': 5,
    'similarity': 5,
    'similarly': 4,
    'similiar': 3,
    'simington': 3,
    'siminski': 3,
    'simion': 3,
    'simione': 4,
    'simison': 3,
    'simkin': 2,
    'simkins': 2,
    'simko': 2,
    'simler': 3,
    'simm': 1,
    'simmer': 2,
    'simmered': 2,
    'simmering': 3,
    'simmerman': 3,
    'simmers': 2,
    'simmert': 2,
    'simmon': 2,
    'simmonds': 2,
    'simmons': 2,
    'simms': 1,
    'simo': 2,
    'simoes': 2,
    'simon': 2,
    'simona': 3,
    'simonds': 2,
    'simone': 2,
    'simoneau': 3,
    'simoneaux': 3,
    'simonelli': 4,
    'simonet': 3,
    'simonetta': 4,
    'simonette': 3,
    'simonetti': 4,
    'simoni': 3,
    'simonian': 4,
    'simonich': 3,
    'simonin': 3,
    'simonis': 3,
    'simons': 2,
    'simonsen': 3,
    'simonson': 3,
    'simonsson': 3,
    'simonton': 3,
    'simony': 3,
    'simper': 2,
    'simpkins': 2,
    'simple': 2,
    'simpler': 3,
    'simplesse': 2,
    'simplest': 2,
    'simplex': 2,
    'simplicity': 4,
    'simplification': 5,
    'simplified': 3,
    'simplifies': 3,
    'simplify': 3,
    'simplifying': 4,
    'simplistic': 3,
    'simplot': 2,
    'simply': 2,
    'simpson': 2,
    'simpsons': 2,
    'sims': 1,
    'simsbury': 3,
    'simson': 2,
    'simulac': 3,
    'simulate': 3,
    'simulated': 4,
    'simulates': 3,
    'simulating': 4,
    'simulation': 4,
    'simulations': 4,
    'simulator': 4,
    'simulators': 4,
    'simulcast': 3,
    'simultaneous': 5,
    'simultaneously': 6,
    'sin': 1,
    'sinagra': 3,
    'sinai': 2,
    'sinar': 2,
    'sinatra': 3,
    'sinay': 2,
    'sinbad': 2,
    'sincavage': 3,
    'since': 1,
    'sincere': 2,
    'sincerely': 3,
    'sincerity': 4,
    'sinclair': 2,
    'sind': 1,
    'sindelar': 3,
    'sindlinger': 3,
    'sindona': 3,
    'sindoni': 3,
    'sindt': 1,
    'sine': 1,
    'sinead': 3,
    'sineath': 2,
    'sinegal': 3,
    'siner': 2,
    'sines': 1,
    'sinewy': 3,
    'sinfonia': 4,
    'sinful': 2,
    'sing': 1,
    'singapore': 3,
    'singaporean': 5,
    'singaporeans': 5,
    'singe': 1,
    'singed': 1,
    'singel': 2,
    'singer': 2,
    'singerman': 3,
    'singers': 2,
    'singh': 1,
    'singing': 2,
    'singlaub': 2,
    'single': 2,
    'singled': 2,
    'singlehandedly': 5,
    'singler': 3,
    'singles': 2,
    'singletary': 4,
    'singleterry': 4,
    'singleton': 3,
    'singley': 2,
    'singling': 3,
    'singly': 2,
    'sings': 1,
    'singular': 3,
    'singularization': 6,
    'singularly': 4,
    'sinha': 2,
    'sinhalese': 3,
    'siniard': 3,
    'sinibaldi': 4,
    'siniscalchi': 4,
    'sinise': 2,
    'sinisi': 3,
    'sinister': 3,
    'sink': 1,
    'sinker': 2,
    'sinkfield': 2,
    'sinkhole': 2,
    'sinkhorn': 2,
    'sinking': 2,
    'sinkler': 2,
    'sinko': 2,
    'sinks': 1,
    'sinn': 1,
    'sinned': 1,
    'sinner': 2,
    'sinners': 2,
    'sinnett': 2,
    'sinning': 2,
    'sinnott': 2,
    'sino': 2,
    'sinopec': 3,
    'sinopoli': 4,
    'sinor': 2,
    'sinquefield': 3,
    'sins': 1,
    'sinsabaugh': 3,
    'sinsel': 2,
    'sinton': 2,
    'sinuous': 2,
    'sinus': 2,
    'sinuses': 3,
    'sinusoid': 3,
    'sinusoidal': 4,
    'sinusoidally': 5,
    'sinyard': 2,
    'siobhan': 2,
    'sioux': 1,
    'sip': 1,
    'sipa': 2,
    'sipe': 1,
    'sipelstein': 3,
    'sipes': 1,
    'siphon': 2,
    'siphoned': 2,
    'siphoning': 3,
    'siphons': 2,
    'siple': 2,
    'sipos': 2,
    'sipp': 1,
    'sipped': 1,
    'sippel': 2,
    'sippican': 3,
    'sipping': 2,
    'sipple': 2,
    'sips': 1,
    'sir': 1,
    'siracusa': 4,
    'siracuse': 4,
    'siragusa': 4,
    'siravo': 3,
    'sircy': 2,
    'sire': 2,
    'sirek': 2,
    'siren': 2,
    'sirena': 3,
    'sirens': 2,
    'sires': 1,
    'sirhan': 2,
    'siri': 2,
    'sirianni': 4,
    'sirignano': 4,
    'siris': 2,
    'sirk': 1,
    'sirkin': 2,
    'sirko': 2,
    'sirles': 1,
    'sirman': 2,
    'sirmans': 2,
    'sirmon': 2,
    'sirmons': 2,
    'sirna': 2,
    'sirohi': 3,
    'sirois': 2,
    'siroky': 3,
    'siron': 2,
    'sirota': 3,
    'sirowitz': 3,
    'sirri': 2,
    'sirrine': 3,
    'sis': 1,
    'sisco': 2,
    'siscoe': 2,
    'sisemore': 2,
    'sisk': 1,
    'siska': 2,
    'siskel': 2,
    'siskin': 2,
    'siskind': 2,
    'sisko': 2,
    'sisler': 3,
    'sisley': 2,
    'sisneros': 3,
    'sisney': 2,
    'sison': 2,
    'sissel': 2,
    'sissie': 2,
    'sissom': 2,
    'sisson': 2,
    'sissy': 2,
    'sistare': 3,
    'sistek': 2,
    'sister': 2,
    'sisterhood': 3,
    'sisters': 2,
    'sisti': 2,
    'sistine': 2,
    'sisto': 2,
    'sistrunk': 2,
    'sisulu': 3,
    'sit': 1,
    'sitar': 2,
    'sitars': 2,
    'sitcom': 2,
    'sitcoms': 2,
    'site': 1,
    'sited': 2,
    'sitek': 2,
    'sitenski': 3,
    'sitenskis': 3,
    'sites': 1,
    'sithe': 1,
    'siting': 2,
    'sitiveni': 4,
    'sitka': 2,
    'sitko': 2,
    'sitler': 3,
    'sitmar': 2,
    'sito': 2,
    'sitrick': 2,
    'sits': 1,
    'sitter': 2,
    'sitterly': 3,
    'sitters': 2,
    'sittig': 2,
    'sitting': 2,
    'sittler': 2,
    'sittner': 2,
    'sitton': 2,
    'sitts': 1,
    'situate': 3,
    'situated': 4,
    'situation': 4,
    'situational': 5,
    'situations': 4,
    'situs': 2,
    'sitz': 1,
    'sitze': 1,
    'sitzer': 2,
    'sitzes': 2,
    'sitzman': 2,
    'sitzmann': 2,
    'siu': 2,
    'siuda': 3,
    'sivak': 2,
    'siver': 2,
    'siverling': 3,
    'siverson': 3,
    'sivertsen': 3,
    'sivertson': 3,
    'sivils': 2,
    'sivley': 2,
    'sivy': 2,
    'siwek': 2,
    'six': 1,
    'sixed': 1,
    'sixer': 2,
    'sixers': 2,
    'sixes': 2,
    'sixfold': 2,
    'sixteen': 2,
    'sixteens': 2,
    'sixteenth': 2,
    'sixteenths': 2,
    'sixth': 1,
    'sixths': 1,
    'sixties': 2,
    'sixtieth': 3,
    'sixty': 2,
    'sizable': 3,
    'size': 1,
    'sizeable': 3,
    'sized': 1,
    'sizeler': 2,
    'sizelove': 2,
    'sizemore': 2,
    'sizer': 2,
    'sizes': 2,
    'sizing': 2,
    'sizzle': 2,
    'sizzled': 2,
    'sizzler': 2,
    'sizzlin': 2,
    'sizzling': 3,
    'sjoberg': 2,
    'sjoblom': 2,
    'sjodin': 2,
    'sjogren': 2,
    'sjolander': 3,
    'sjoquist': 2,
    'sjostrom': 2,
    'skaar': 1,
    'skadden': 2,
    'skaff': 1,
    'skaggs': 1,
    'skains': 1,
    'skala': 2,
    'skalicky': 3,
    'skalla': 2,
    'skalski': 2,
    'skalsky': 2,
    'skandia': 3,
    'skandinaviska': 5,
    'skanska': 2,
    'skarda': 2,
    'skare': 1,
    'skase': 1,
    'skate': 1,
    'skateboard': 2,
    'skateboarding': 3,
    'skated': 2,
    'skater': 2,
    'skaters': 2,
    'skates': 1,
    'skating': 2,
    'skeat': 1,
    'skeel': 1,
    'skeels': 1,
    'skeen': 1,
    'skeens': 1,
    'skees': 1,
    'skeet': 1,
    'skeete': 1,
    'skeeter': 2,
    'skeeters': 2,
    'skeets': 1,
    'skeffington': 3,
    'skehan': 2,
    'skein': 1,
    'skeins': 1,
    'skeletal': 3,
    'skeleton': 3,
    'skeletons': 3,
    'skellenger': 3,
    'skelley': 2,
    'skelly': 2,
    'skelter': 2,
    'skelton': 2,
    'skenandore': 3,
    'skender': 2,
    'skenderian': 4,
    'skene': 1,
    'skeoch': 2,
    'skeptic': 2,
    'skeptical': 3,
    'skeptically': 4,
    'skepticism': 4,
    'skeptics': 2,
    'skerdal': 2,
    'skerry': 2,
    'sketch': 1,
    'sketchbook': 2,
    'sketchbooks': 2,
    'sketched': 1,
    'sketches': 2,
    'sketching': 2,
    'sketchy': 2,
    'skew': 1,
    'skewed': 1,
    'skewer': 2,
    'skewered': 2,
    'skewers': 2,
    'skewes': 1,
    'skewing': 2,
    'skews': 1,
    'ski': 1,
    'skiba': 2,
    'skibbe': 1,
    'skibicki': 3,
    'skibinski': 3,
    'skibo': 2,
    'skid': 1,
    'skidded': 2,
    'skidding': 2,
    'skidgel': 2,
    'skidmore': 2,
    'skids': 1,
    'skied': 1,
    'skier': 1,
    'skiers': 2,
    'skies': 1,
    'skiff': 1,
    'skiffington': 3,
    'skiffs': 1,
    'skiing': 2,
    'skiles': 1,
    'skill': 1,
    'skilled': 1,
    'skillen': 2,
    'skillern': 2,
    'skillet': 2,
    'skillful': 2,
    'skillfully': 3,
    'skillin': 2,
    'skilling': 2,
    'skillings': 2,
    'skillman': 2,
    'skills': 1,
    'skilton': 2,
    'skim': 1,
    'skimmed': 1,
    'skimmer': 2,
    'skimmers': 2,
    'skimming': 2,
    'skimp': 1,
    'skimpier': 3,
    'skimpiest': 3,
    'skimping': 2,
    'skimpy': 2,
    'skin': 1,
    'skinflint': 2,
    'skinhead': 2,
    'skinheads': 2,
    'skinks': 1,
    'skinless': 2,
    'skinned': 1,
    'skinner': 2,
    'skinnier': 3,
    'skinniest': 3,
    'skinny': 2,
    'skins': 1,
    'skip': 1,
    'skipjack': 2,
    'skipjacks': 2,
    'skipp': 1,
    'skippa': 2,
    'skipped': 1,
    'skipper': 2,
    'skippers': 2,
    'skipping': 2,
    'skippy': 2,
    'skips': 1,
    'skipton': 2,
    'skipwith': 2,
    'skipworth': 2,
    'skirdall': 2,
    'skirmish': 2,
    'skirmishes': 3,
    'skirmishing': 3,
    'skirt': 1,
    'skirted': 2,
    'skirting': 2,
    'skirts': 1,
    'skirvin': 2,
    'skis': 1,
    'skit': 1,
    'skits': 1,
    'skittish': 2,
    'skittishness': 3,
    'skittle': 2,
    'skittles': 2,
    'skiver': 2,
    'sklar': 1,
    'sklenar': 2,
    'skoal': 1,
    'skoczylas': 3,
    'skoda': 2,
    'skoff': 1,
    'skog': 1,
    'skogen': 2,
    'skoglund': 2,
    'skok': 1,
    'skokie': 2,
    'skoler': 2,
    'skolnick': 2,
    'skolnik': 2,
    'skolniks': 2,
    'skonieczny': 3,
    'skoog': 1,
    'skop': 1,
    'skopje': 3,
    'skora': 2,
    'skorupa': 3,
    'skorupski': 3,
    'skousen': 2,
    'skov': 1,
    'skow': 1,
    'skowhegan': 3,
    'skowron': 2,
    'skowronek': 3,
    'skowronski': 3,
    'skramstad': 2,
    'skroch': 1,
    'skrocki': 2,
    'skrzypek': 3,
    'skudra': 2,
    'skufca': 2,
    'skulduggery': 4,
    'skulk': 1,
    'skull': 1,
    'skulls': 1,
    'skunk': 1,
    'skunks': 1,
    'skura': 2,
    'skurdal': 2,
    'skurka': 2,
    'skutt': 1,
    'sky': 1,
    'skybox': 2,
    'skyboxes': 3,
    'skycap': 2,
    'skydive': 2,
    'skydiving': 3,
    'skydome': 2,
    'skyhawk': 2,
    'skyhawks': 2,
    'skyhigh': 2,
    'skyhopping': 3,
    'skylark': 2,
    'skylarks': 2,
    'skylawn': 2,
    'skyles': 1,
    'skylight': 2,
    'skylights': 2,
    'skyline': 2,
    'skylines': 2,
    'skylite': 2,
    'skylites': 2,
    'skyrocket': 3,
    'skyrocketed': 4,
    'skyrocketing': 4,
    'skyrockets': 3,
    'skyscraper': 3,
    'skyscrapers': 3,
    'skytel': 2,
    'skyward': 2,
    'skywave': 2,
    'skyway': 2,
    'skywest': 2,
    'skywriter': 3,
    'skywriters': 3,
    'skywriting': 3,
    'slab': 1,
    'slabaugh': 2,
    'slabs': 1,
    'slaby': 2,
    'slack': 1,
    'slacked': 1,
    'slacken': 2,
    'slackened': 2,
    'slackening': 3,
    'slackens': 2,
    'slacker': 2,
    'slackers': 2,
    'slackness': 2,
    'slacks': 1,
    'slade': 1,
    'sladek': 2,
    'sladky': 2,
    'slag': 1,
    'slagel': 2,
    'slager': 2,
    'slaght': 1,
    'slagle': 2,
    'slagter': 2,
    'slain': 1,
    'slaine': 1,
    'slalom': 2,
    'slam': 1,
    'slama': 2,
    'slammed': 1,
    'slammer': 2,
    'slamming': 2,
    'slams': 1,
    'slander': 2,
    'slandered': 2,
    'slanderer': 3,
    'slandering': 3,
    'slanderous': 3,
    'slanders': 2,
    'slane': 1,
    'slaney': 2,
    'slang': 1,
    'slanina': 3,
    'slankard': 2,
    'slant': 1,
    'slanted': 2,
    'slanting': 2,
    'slap': 1,
    'slapdash': 2,
    'slape': 1,
    'slaphappy': 3,
    'slapped': 1,
    'slappey': 2,
    'slapping': 2,
    'slaps': 1,
    'slapstick': 2,
    'slash': 1,
    'slashed': 1,
    'slasher': 2,
    'slashes': 2,
    'slashing': 2,
    'slat': 1,
    'slate': 1,
    'slated': 2,
    'slaten': 2,
    'slater': 2,
    'slaters': 2,
    'slates': 1,
    'slather': 2,
    'slathered': 2,
    'slathering': 3,
    'slathers': 2,
    'slating': 2,
    'slatkin': 2,
    'slaton': 2,
    'slats': 1,
    'slatten': 2,
    'slatter': 2,
    'slattery': 3,
    'slatton': 2,
    'slaugh': 1,
    'slaughter': 2,
    'slaughtered': 2,
    'slaughterhouse': 3,
    'slaughterhouses': 4,
    'slaughtering': 3,
    'slaughterings': 3,
    'slaughters': 2,
    'slauson': 2,
    'slav': 1,
    'slava': 2,
    'slave': 1,
    'slaven': 2,
    'slavens': 2,
    'slavery': 3,
    'slaves': 1,
    'slavic': 2,
    'slavick': 2,
    'slavik': 2,
    'slavin': 2,
    'slavinski': 3,
    'slavish': 2,
    'slavishly': 3,
    'slavonia': 4,
    'slavs': 1,
    'slaw': 1,
    'slawinski': 3,
    'slawson': 2,
    'slay': 1,
    'slayback': 2,
    'slaybaugh': 2,
    'slayden': 2,
    'slaydon': 2,
    'slayer': 2,
    'slaying': 2,
    'slayings': 2,
    'slaymaker': 3,
    'slayter': 2,
    'slayton': 2,
    'sleasman': 2,
    'sleator': 2,
    'sleaze': 1,
    'sleazy': 2,
    'slechta': 2,
    'sled': 1,
    'sledd': 1,
    'sledding': 2,
    'sledge': 1,
    'sledgehammer': 3,
    'sleds': 1,
    'sledz': 1,
    'slee': 1,
    'sleek': 1,
    'sleeker': 2,
    'sleekest': 2,
    'sleekly': 2,
    'sleeman': 2,
    'sleep': 1,
    'sleeper': 2,
    'sleepers': 2,
    'sleepily': 3,
    'sleepiness': 3,
    'sleeping': 2,
    'sleepless': 2,
    'sleeps': 1,
    'sleepwalk': 2,
    'sleepwalker': 3,
    'sleepwalkers': 3,
    'sleepwalking': 3,
    'sleepwear': 2,
    'sleepy': 2,
    'sleet': 1,
    'sleeter': 2,
    'sleeth': 1,
    'sleeve': 1,
    'sleeved': 1,
    'sleeveless': 2,
    'sleeves': 1,
    'sleigh': 1,
    'sleight': 1,
    'sleipner': 2,
    'slemmer': 2,
    'slemp': 1,
    'slender': 2,
    'slentz': 1,
    'slepian': 3,
    'slept': 1,
    'slessenger': 3,
    'sletten': 2,
    'sleuth': 1,
    'sleuthing': 2,
    'sleuths': 1,
    'sleven': 2,
    'slevin': 2,
    'slew': 1,
    'slezak': 2,
    'slice': 1,
    'sliced': 1,
    'slicer': 2,
    'slices': 2,
    'slicing': 2,
    'slick': 1,
    'slicked': 1,
    'slicker': 2,
    'slickers': 2,
    'slickest': 2,
    'slickly': 2,
    'slicks': 1,
    'slid': 1,
    'slide': 1,
    'slider': 2,
    'slides': 1,
    'sliding': 2,
    'slife': 1,
    'slifer': 2,
    'slifka': 2,
    'slifko': 2,
    'sligar': 2,
    'sliger': 2,
    'sligh': 1,
    'slight': 1,
    'slighted': 2,
    'slightest': 2,
    'slighting': 2,
    'slightly': 2,
    'slights': 1,
    'sliker': 2,
    'slim': 1,
    'slimak': 2,
    'slime': 1,
    'slimmed': 1,
    'slimmer': 2,
    'slimmest': 2,
    'slimming': 2,
    'slimp': 1,
    'slims': 1,
    'slimy': 2,
    'sliney': 2,
    'sling': 1,
    'slinger': 2,
    'slingerland': 3,
    'slinging': 2,
    'slings': 1,
    'slingshot': 2,
    'slingshots': 2,
    'slinkard': 2,
    'slinker': 2,
    'slinky': 2,
    'slip': 1,
    'slippage': 2,
    'slipped': 1,
    'slipper': 2,
    'slippers': 2,
    'slippery': 3,
    'slipping': 2,
    'slips': 1,
    'slipshod': 2,
    'slit': 1,
    'sliter': 2,
    'slither': 2,
    'slithering': 3,
    'slits': 1,
    'slitting': 2,
    'sliva': 2,
    'sliver': 2,
    'slivers': 2,
    'slivinski': 3,
    'slivka': 2,
    'slivovitz': 3,
    'sliwa': 2,
    'sliwinski': 3,
    'sloan': 1,
    'sloane': 1,
    'sloat': 1,
    'sloate': 1,
    'slob': 1,
    'slobber': 2,
    'slobbering': 3,
    'sloboda': 3,
    'slobodan': 3,
    'slobogin': 3,
    'slobs': 1,
    'slocan': 2,
    'slocomb': 2,
    'slocum': 2,
    'slocumb': 2,
    'sloe': 1,
    'slog': 1,
    'slogan': 2,
    'slogans': 2,
    'slogged': 1,
    'slogging': 2,
    'slokum': 2,
    'sloma': 2,
    'sloman': 2,
    'slominski': 3,
    'slomski': 2,
    'slonaker': 3,
    'slone': 1,
    'sloniker': 3,
    'sloop': 1,
    'slop': 1,
    'slope': 1,
    'sloper': 2,
    'slopes': 1,
    'sloping': 2,
    'sloppier': 3,
    'sloppiest': 3,
    'sloppily': 3,
    'sloppiness': 3,
    'sloppy': 2,
    'slorc': 1,
    'slosh': 1,
    'sloshing': 2,
    'sloss': 1,
    'slot': 1,
    'slota': 2,
    'sloth': 1,
    'slothower': 3,
    'slotnick': 2,
    'slots': 1,
    'slott': 1,
    'slotted': 2,
    'slotting': 2,
    'slouch': 1,
    'slouches': 2,
    'slouching': 2,
    'slough': 1,
    'sloughs': 1,
    'slovacek': 3,
    'slovak': 2,
    'slovakia': 4,
    'slovaks': 2,
    'slovene': 2,
    'slovenes': 2,
    'slovenia': 4,
    'slovenian': 4,
    'slovenliness': 4,
    'slovenly': 3,
    'slover': 2,
    'sloves': 1,
    'slovo': 2,
    'slovonia': 4,
    'slow': 1,
    'slowdown': 2,
    'slowdowns': 2,
    'slowed': 1,
    'slower': 2,
    'slowest': 2,
    'slowey': 2,
    'slowik': 2,
    'slowing': 2,
    'slowinski': 3,
    'slowly': 2,
    'slowness': 2,
    'slowpoke': 2,
    'slows': 1,
    'sluder': 2,
    'sludge': 1,
    'sludgy': 2,
    'slug': 1,
    'sluga': 2,
    'slugfest': 2,
    'slugged': 1,
    'slugger': 2,
    'sluggers': 2,
    'slugging': 2,
    'sluggish': 2,
    'sluggishly': 3,
    'sluggishness': 3,
    'slugs': 1,
    'sluice': 1,
    'sluicing': 2,
    'sluiter': 2,
    'sluka': 2,
    'slum': 1,
    'slumber': 2,
    'slumbering': 3,
    'slump': 1,
    'slumped': 1,
    'slumping': 2,
    'slumps': 1,
    'slums': 1,
    'slung': 1,
    'slupic': 2,
    'slur': 1,
    'slurp': 1,
    'slurred': 1,
    'slurring': 2,
    'slurry': 2,
    'slurs': 1,
    'slush': 1,
    'slushayete': 3,
    'slusher': 2,
    'sluss': 1,
    'slusser': 2,
    'slut': 1,
    'slutsky': 2,
    'slutty': 2,
    'slutz': 1,
    'slutzky': 2,
    'sluyter': 2,
    'sly': 1,
    'slye': 1,
    'slyly': 2,
    'slyness': 2,
    'slyter': 2,
    'smaby': 2,
    'smack': 1,
    'smacked': 1,
    'smacking': 2,
    'smacks': 1,
    'smail': 1,
    'smaldone': 2,
    'smale': 1,
    'small': 1,
    'smallcap': 2,
    'smallen': 2,
    'smaller': 2,
    'smallest': 2,
    'smalley': 2,
    'smalling': 2,
    'smallish': 2,
    'smallman': 2,
    'smallness': 2,
    'smallpox': 2,
    'smallridge': 2,
    'smalls': 1,
    'smalltalk': 2,
    'smalltown': 2,
    'smallwood': 2,
    'smaltz': 1,
    'smarmy': 2,
    'smarr': 1,
    'smart': 1,
    'smartaleck': 3,
    'smartass': 2,
    'smartcard': 2,
    'smarted': 2,
    'smarten': 2,
    'smartened': 2,
    'smartening': 3,
    'smarter': 2,
    'smartest': 2,
    'smarties': 2,
    'smarting': 2,
    'smartish': 2,
    'smartly': 2,
    'smartmoney': 3,
    'smartness': 2,
    'smarts': 1,
    'smartt': 1,
    'smarty': 2,
    'smash': 1,
    'smashed': 1,
    'smasher': 2,
    'smashes': 2,
    'smashing': 2,
    'smather': 2,
    'smathers': 2,
    'smatter': 2,
    'smattering': 3,
    'smay': 1,
    'smead': 1,
    'smeal': 1,
    'smear': 1,
    'smeared': 1,
    'smearing': 2,
    'smears': 1,
    'smeby': 2,
    'smedberg': 2,
    'smedley': 2,
    'smee': 1,
    'smeeting': 2,
    'smejkal': 2,
    'smelcer': 2,
    'smell': 1,
    'smelled': 1,
    'smelley': 2,
    'smelling': 2,
    'smells': 1,
    'smelly': 2,
    'smelser': 2,
    'smelt': 1,
    'smelter': 2,
    'smelters': 2,
    'smelting': 2,
    'smeltz': 1,
    'smeltzer': 2,
    'smestad': 2,
    'smet': 1,
    'smetana': 3,
    'smethers': 2,
    'smethurst': 2,
    'smetzer': 2,
    'smick': 1,
    'smid': 1,
    'smiddy': 2,
    'smidgen': 2,
    'smidt': 1,
    'smigel': 2,
    'smigelski': 3,
    'smigiel': 2,
    'smigielski': 3,
    'smile': 1,
    'smiled': 1,
    'smiles': 1,
    'smiley': 2,
    'smilgis': 2,
    'smilie': 2,
    'smiling': 2,
    'smilingly': 3,
    'smillie': 2,
    'smilow': 2,
    'smink': 1,
    'smirk': 1,
    'smirking': 2,
    'smirks': 1,
    'smirnoff': 2,
    'smirnov': 2,
    'smirnova': 3,
    'smisek': 2,
    'smit': 1,
    'smith': 1,
    'smithburg': 2,
    'smithee': 2,
    'smither': 2,
    'smithereen': 3,
    'smithereens': 3,
    'smitherman': 3,
    'smithers': 2,
    'smithey': 2,
    'smithfield': 2,
    'smithhart': 2,
    'smithkline': 2,
    'smiths': 1,
    'smithson': 2,
    'smithsonian': 4,
    'smithtown': 2,
    'smithwick': 2,
    'smitley': 2,
    'smits': 1,
    'smitten': 2,
    'smittle': 2,
    'smitty': 2,
    'smoak': 1,
    'smock': 1,
    'smog': 1,
    'smoggiest': 3,
    'smoggy': 2,
    'smoke': 1,
    'smoked': 1,
    'smokejumper': 3,
    'smokejumpers': 3,
    'smokeless': 2,
    'smoker': 2,
    'smokers': 2,
    'smokes': 1,
    'smokescreen': 2,
    'smokestack': 2,
    'smokestacks': 2,
    'smokey': 2,
    'smoking': 2,
    'smoky': 2,
    'smola': 2,
    'smolak': 2,
    'smolder': 2,
    'smoldering': 3,
    'smolen': 2,
    'smolensk': 2,
    'smolenski': 3,
    'smoley': 2,
    'smolik': 2,
    'smolin': 2,
    'smolinski': 3,
    'smolinsky': 3,
    'smolka': 2,
    'smoot': 1,
    'smooth': 1,
    'smoothed': 1,
    'smoothen': 2,
    'smoothens': 2,
    'smoother': 2,
    'smoothest': 2,
    'smoothing': 2,
    'smoothly': 2,
    'smoothness': 2,
    'smooths': 1,
    'smoots': 1,
    'smorgasbord': 3,
    'smosh': 1,
    'smoshed': 1,
    'smoshes': 2,
    'smoshing': 2,
    'smother': 2,
    'smothered': 2,
    'smothering': 3,
    'smotherman': 3,
    'smothermon': 3,
    'smothers': 2,
    'smotrich': 2,
    'smouse': 1,
    'smoyer': 2,
    'smrekar': 2,
    'smuck': 1,
    'smucker': 2,
    'smudge': 1,
    'smudged': 1,
    'smug': 1,
    'smuggle': 2,
    'smuggled': 2,
    'smuggler': 2,
    'smugglers': 2,
    'smuggling': 2,
    'smugly': 2,
    'smugness': 2,
    'smull': 1,
    'smullen': 2,
    'smurf': 1,
    'smurfit': 2,
    'smurfs': 1,
    'smut': 1,
    'smyers': 2,
    'smylie': 2,
    'smyly': 2,
    'smyre': 1,
    'smyrna': 2,
    'smyser': 2,
    'smyth': 1,
    'smythe': 1,
    'snabel': 2,
    'snack': 1,
    'snackbar': 2,
    'snacked': 1,
    'snacking': 2,
    'snacks': 1,
    'snackwell': 2,
    'snackwells': 2,
    'snader': 2,
    'snafu': 2,
    'snafus': 2,
    'snag': 1,
    'snagged': 1,
    'snagger': 2,
    'snaggers': 2,
    'snagging': 2,
    'snags': 1,
    'snail': 1,
    'snails': 1,
    'snake': 1,
    'snakebite': 2,
    'snakebites': 2,
    'snakelike': 2,
    'snakes': 1,
    'snaking': 2,
    'snap': 1,
    'snapdragon': 3,
    'snapdragons': 3,
    'snape': 1,
    'snapp': 1,
    'snapped': 1,
    'snapper': 2,
    'snapping': 2,
    'snapple': 2,
    'snapples': 2,
    'snappy': 2,
    'snaps': 1,
    'snapshot': 2,
    'snapshots': 2,
    'snare': 1,
    'snared': 1,
    'snares': 1,
    'snaring': 2,
    'snarl': 1,
    'snarled': 2,
    'snarling': 2,
    'snarls': 1,
    'snarr': 1,
    'snarski': 2,
    'snatch': 1,
    'snatched': 1,
    'snatcher': 2,
    'snatchers': 2,
    'snatches': 2,
    'snatching': 2,
    'snavely': 2,
    'snay': 1,
    'snazzier': 3,
    'snazzy': 2,
    'snead': 1,
    'sneak': 1,
    'sneaked': 1,
    'sneaker': 2,
    'sneakers': 2,
    'sneaking': 2,
    'sneaks': 1,
    'sneaky': 2,
    'sneary': 2,
    'sneath': 1,
    'snecma': 2,
    'snedaker': 3,
    'snedden': 2,
    'sneddon': 2,
    'snedegar': 3,
    'snedeker': 3,
    'snee': 1,
    'sneed': 1,
    'sneer': 1,
    'sneered': 1,
    'sneering': 2,
    'sneeringer': 3,
    'sneers': 1,
    'sneeze': 1,
    'sneezed': 1,
    'sneezes': 2,
    'sneezing': 2,
    'sneh': 1,
    'sneider': 2,
    'snelgrove': 2,
    'snell': 1,
    'snellen': 2,
    'snellenberger': 4,
    'sneller': 2,
    'snellgrove': 2,
    'snelling': 2,
    'snellings': 2,
    'snelson': 2,
    'snethen': 2,
    'snia': 2,
    'snicker': 2,
    'snickered': 2,
    'snickering': 3,
    'snickers': 2,
    'snide': 1,
    'snider': 2,
    'snidow': 2,
    'sniegowski': 3,
    'sniff': 1,
    'sniffed': 1,
    'sniffen': 2,
    'sniffer': 2,
    'sniffier': 3,
    'sniffiest': 3,
    'sniffily': 3,
    'sniffing': 2,
    'sniffle': 2,
    'sniffler': 2,
    'sniffles': 2,
    'sniffling': 2,
    'sniffs': 1,
    'sniffy': 2,
    'snifter': 2,
    'snifters': 2,
    'snip': 1,
    'snipe': 1,
    'sniped': 1,
    'sniper': 2,
    'snipers': 2,
    'snipes': 1,
    'sniping': 2,
    'snippet': 2,
    'snippets': 2,
    'snips': 1,
    'snitch': 1,
    'snitching': 2,
    'snitker': 2,
    'snitzer': 2,
    'snively': 2,
    'snob': 1,
    'snobbery': 3,
    'snobbish': 2,
    'snobby': 2,
    'snobs': 1,
    'snodderly': 3,
    'snoddy': 2,
    'snodgrass': 2,
    'snohomish': 3,
    'snoke': 1,
    'snook': 1,
    'snooker': 2,
    'snookered': 2,
    'snooks': 1,
    'snoop': 1,
    'snooping': 2,
    'snoopy': 2,
    'snooty': 2,
    'snooze': 1,
    'snoozing': 2,
    'snore': 1,
    'snoring': 2,
    'snort': 1,
    'snorted': 2,
    'snorter': 2,
    'snorters': 2,
    'snorting': 2,
    'snorts': 1,
    'snot': 1,
    'snouffer': 2,
    'snout': 1,
    'snouts': 1,
    'snover': 2,
    'snow': 1,
    'snowball': 2,
    'snowballed': 2,
    'snowballing': 3,
    'snowballs': 2,
    'snowberger': 3,
    'snowbird': 2,
    'snowbirds': 2,
    'snowboard': 2,
    'snowboarder': 3,
    'snowboarders': 3,
    'snowboards': 2,
    'snowbound': 2,
    'snowden': 2,
    'snowdon': 2,
    'snowe': 1,
    'snowed': 1,
    'snowfall': 2,
    'snowfalls': 2,
    'snowflake': 2,
    'snowflakes': 2,
    'snowing': 2,
    'snowman': 2,
    'snowmobile': 3,
    'snowmobiles': 3,
    'snowplow': 2,
    'snowplows': 2,
    'snows': 1,
    'snowshoe': 2,
    'snowstorm': 2,
    'snowstorms': 2,
    'snowy': 2,
    'snub': 1,
    'snubbed': 1,
    'snubbing': 2,
    'snubs': 1,
    'snuck': 1,
    'snuff': 1,
    'snuffed': 1,
    'snuffer': 2,
    'snuffing': 2,
    'snuffs': 1,
    'snug': 1,
    'snugging': 2,
    'snuggle': 2,
    'snuggs': 1,
    'snugly': 2,
    'snyder': 2,
    'snydergeneral': 5,
    'so': 1,
    'soady': 2,
    'soak': 1,
    'soaked': 1,
    'soaker': 2,
    'soaking': 2,
    'soaks': 1,
    'soap': 1,
    'soapbox': 2,
    'soaps': 1,
    'soapy': 2,
    'soar': 1,
    'soard': 1,
    'soared': 1,
    'soares': 2,
    'soaring': 2,
    'soars': 1,
    'soave': 1,
    'sob': 1,
    'sobbed': 1,
    'sobbing': 2,
    'sobbingly': 3,
    'sobczak': 2,
    'sobczyk': 2,
    'sobeck': 2,
    'sobecki': 3,
    'sobek': 2,
    'sobel': 2,
    'sobelman': 3,
    'sober': 2,
    'sobered': 2,
    'sobering': 3,
    'soberly': 3,
    'sobers': 2,
    'sobey': 2,
    'sobibor': 3,
    'sobiech': 2,
    'sobieski': 4,
    'sobil': 2,
    'sobils': 2,
    'sobin': 2,
    'sobkowiak': 4,
    'soble': 2,
    'sobocinski': 4,
    'sobol': 2,
    'soboleski': 4,
    'sobolewski': 4,
    'sobolik': 3,
    'sobon': 2,
    'sobota': 3,
    'sobotka': 3,
    'sobotta': 3,
    'sobriety': 4,
    'sobriquet': 3,
    'sobs': 1,
    'socal': 2,
    'socalled': 2,
    'socanav': 3,
    'socarras': 3,
    'soccer': 2,
    'socci': 2,
    'socha': 2,
    'sochacki': 3,
    'sochet': 2,
    'sochi': 2,
    'sochor': 2,
    'socia': 2,
    'sociable': 3,
    'social': 2,
    'socialism': 4,
    'socialist': 3,
    'socialistic': 4,
    'socialists': 3,
    'socialite': 3,
    'socialites': 3,
    'socialization': 5,
    'socialize': 3,
    'socialized': 3,
    'socializing': 4,
    'socially': 3,
    'sociedad': 3,
    'societa': 4,
    'societal': 4,
    'societe': 4,
    'societies': 4,
    'society': 4,
    'socio': 3,
    'socioeconomic': 7,
    'sociological': 6,
    'sociologist': 5,
    'sociologists': 5,
    'sociology': 5,
    'sociopath': 4,
    'sociopaths': 4,
    'sock': 1,
    'socked': 1,
    'socket': 2,
    'sockets': 2,
    'socking': 2,
    'socks': 1,
    'sockwell': 2,
    'soco': 2,
    'socol': 2,
    'socrates': 3,
    'socratic': 3,
    'sod': 1,
    'soda': 2,
    'sodano': 3,
    'sodaro': 3,
    'sodas': 2,
    'sodden': 2,
    'sodders': 2,
    'sodecom': 3,
    'soden': 2,
    'soder': 2,
    'soderberg': 3,
    'soderbergh': 3,
    'soderblom': 3,
    'sodergren': 3,
    'soderholm': 3,
    'soderlund': 3,
    'soderman': 3,
    'soderquist': 3,
    'soderstrom': 3,
    'soditic': 3,
    'sodium': 3,
    'sodom': 2,
    'sodomize': 3,
    'sodomized': 3,
    'sodomizer': 4,
    'sodomizes': 4,
    'sodomizing': 4,
    'sodomy': 3,
    'sodus': 2,
    'soeder': 2,
    'soens': 1,
    'soerensen': 3,
    'sofa': 2,
    'sofaer': 2,
    'sofamor': 3,
    'sofas': 2,
    'sofer': 2,
    'soffel': 2,
    'soffer': 2,
    'sofia': 3,
    'sofian': 3,
    'sofians': 3,
    'sofie': 2,
    'sofranko': 3,
    'soft': 1,
    'softball': 2,
    'softballs': 2,
    'softbank': 2,
    'softdrink': 2,
    'softech': 2,
    'soften': 2,
    'softened': 2,
    'softener': 3,
    'softening': 3,
    'softens': 2,
    'softer': 2,
    'softest': 2,
    'softimage': 3,
    'softkey': 2,
    'softletter': 3,
    'softly': 2,
    'softness': 2,
    'softsoap': 2,
    'softspoken': 3,
    'software': 2,
    'softwood': 2,
    'sogang': 2,
    'soggy': 2,
    'sogo': 2,
    'sohio': 3,
    'sohl': 1,
    'sohm': 1,
    'sohmer': 2,
    'sohn': 1,
    'sohns': 1,
    'soho': 2,
    'soifer': 2,
    'soiffer': 2,
    'soil': 1,
    'soileau': 2,
    'soiled': 1,
    'soils': 1,
    'soir': 1,
    'soiree': 2,
    'soisson': 2,
    'soja': 2,
    'sojka': 2,
    'sojourn': 2,
    'sojourner': 3,
    'sojourners': 3,
    'sojourning': 3,
    'sok': 1,
    'sokaiya': 3,
    'sokol': 2,
    'sokolik': 3,
    'sokolin': 3,
    'sokoloff': 3,
    'sokoloski': 4,
    'sokolov': 3,
    'sokolow': 3,
    'sokolowski': 4,
    'sol': 1,
    'sola': 2,
    'solace': 2,
    'solak': 2,
    'solaman': 3,
    'solan': 2,
    'solana': 3,
    'soland': 2,
    'solangi': 3,
    'solano': 3,
    'solar': 2,
    'solares': 3,
    'solari': 3,
    'solaris': 3,
    'solarz': 2,
    'solazzo': 3,
    'solberg': 2,
    'solchaga': 3,
    'sold': 1,
    'soldan': 2,
    'soldano': 3,
    'solder': 2,
    'soldering': 3,
    'soldier': 2,
    'soldiering': 3,
    'soldiers': 2,
    'soldner': 2,
    'soldo': 2,
    'sole': 1,
    'solecki': 3,
    'soledad': 2,
    'soleil': 2,
    'solely': 2,
    'solem': 2,
    'solemin': 3,
    'solemn': 2,
    'solemnity': 4,
    'solemnly': 3,
    'soler': 2,
    'soleri': 3,
    'soles': 1,
    'solesbee': 2,
    'soley': 2,
    'solheim': 2,
    'soli': 2,
    'solicit': 3,
    'solicitation': 5,
    'solicitations': 5,
    'solicited': 4,
    'soliciting': 4,
    'solicitor': 4,
    'solicitors': 4,
    'solicitous': 4,
    'solicits': 3,
    'solicitude': 4,
    'solid': 2,
    'solidarity': 5,
    'soliday': 3,
    'soliders': 3,
    'solidified': 4,
    'solidifies': 4,
    'solidify': 4,
    'solidifying': 5,
    'solidity': 4,
    'solidly': 3,
    'solids': 2,
    'solie': 2,
    'soliloquize': 4,
    'soliloquy': 4,
    'soliman': 3,
    'solimine': 4,
    'solimon': 3,
    'solin': 2,
    'solingen': 3,
    'solinger': 3,
    'solis': 2,
    'solita': 3,
    'solitaire': 3,
    'solitariness': 5,
    'solitary': 4,
    'solitec': 3,
    'solitron': 3,
    'solitude': 3,
    'soliz': 2,
    'soll': 1,
    'sollars': 2,
    'sollenberger': 4,
    'soller': 2,
    'sollers': 2,
    'solley': 2,
    'solliday': 3,
    'sollie': 2,
    'sollinger': 3,
    'sollish': 2,
    'solloway': 3,
    'solly': 2,
    'solo': 2,
    'solodar': 3,
    'soloff': 2,
    'soloist': 3,
    'soloists': 3,
    'soloman': 3,
    'solomon': 3,
    'solomos': 3,
    'solon': 2,
    'solorio': 4,
    'solorzano': 4,
    'solos': 2,
    'soloviev': 3,
    'solow': 2,
    'soloway': 3,
    'solstice': 2,
    'solstices': 3,
    'solt': 1,
    'soltau': 2,
    'soltero': 3,
    'soltes': 1,
    'soltesz': 2,
    'solti': 2,
    'soltis': 2,
    'soltys': 2,
    'soltysiak': 4,
    'soluble': 3,
    'solum': 2,
    'solution': 3,
    'solutions': 3,
    'solvable': 3,
    'solvay': 2,
    'solve': 1,
    'solved': 1,
    'solvency': 3,
    'solvent': 2,
    'solvents': 2,
    'solver': 2,
    'solvers': 2,
    'solves': 1,
    'solvig': 2,
    'solving': 2,
    'solwin': 2,
    'solzhenitsyn': 4,
    'som': 1,
    'soma': 2,
    'somali': 3,
    'somalia': 4,
    'somalian': 3,
    'somalians': 3,
    'somalias': 4,
    'somaliland': 4,
    'somalis': 3,
    'somatogen': 4,
    'somatotropin': 5,
    'somber': 2,
    'somberly': 3,
    'sombrero': 3,
    'some': 1,
    'somebody': 3,
    'someday': 2,
    'somehow': 2,
    'someone': 2,
    'someplace': 2,
    'somer': 2,
    'somers': 2,
    'somersault': 3,
    'somersaulting': 4,
    'somersaults': 3,
    'somerset': 3,
    'somerton': 3,
    'somerville': 3,
    'somes': 1,
    'somesh': 2,
    'something': 2,
    'somethings': 2,
    'sometime': 2,
    'sometimes': 2,
    'somewhat': 2,
    'somewhere': 2,
    'somewheres': 2,
    'somma': 2,
    'sommer': 2,
    'sommerfeld': 3,
    'sommerfeldt': 3,
    'sommerfield': 3,
    'sommers': 2,
    'sommersby': 3,
    'sommerville': 3,
    'somnolence': 3,
    'somnolent': 3,
    'somogyi': 3,
    'somoza': 3,
    'son': 1,
    'sonar': 2,
    'sonat': 2,
    'sonata': 3,
    'sonatas': 3,
    'sonatrach': 3,
    'sonchez': 2,
    'sondag': 2,
    'sonderman': 3,
    'sondgeroth': 3,
    'sondheim': 2,
    'sondheimer': 3,
    'sondra': 2,
    'sones': 1,
    'sonesta': 3,
    'sonet': 2,
    'sonex': 2,
    'song': 1,
    'songbird': 2,
    'songbirds': 2,
    'songer': 2,
    'songs': 1,
    'songwriter': 3,
    'songwriters': 3,
    'songwriting': 3,
    'songy': 2,
    'soni': 2,
    'sonia': 2,
    'sonic': 2,
    'sonics': 2,
    'sonier': 3,
    'sonja': 2,
    'sonji': 2,
    'sonn': 1,
    'sonne': 1,
    'sonneborn': 3,
    'sonnen': 2,
    'sonnenberg': 3,
    'sonnenblick': 3,
    'sonnenburg': 3,
    'sonnenfeld': 3,
    'sonnenschein': 3,
    'sonner': 2,
    'sonnet': 2,
    'sonnets': 2,
    'sonnett': 2,
    'sonnier': 3,
    'sonntag': 2,
    'sonny': 2,
    'sonoco': 3,
    'sonoda': 3,
    'sonogram': 3,
    'sonograms': 3,
    'sonoma': 3,
    'sonora': 3,
    'sonorous': 3,
    'sonrise': 2,
    'sons': 1,
    'sontag': 2,
    'sonum': 2,
    'sony': 2,
    'sonya': 2,
    'soo': 1,
    'sood': 1,
    'soohoo': 2,
    'soon': 1,
    'sooner': 2,
    'sooners': 2,
    'soonest': 2,
    'soong': 1,
    'soonyi': 2,
    'soos': 1,
    'soot': 1,
    'sooter': 2,
    'soothe': 1,
    'soothed': 1,
    'soothes': 1,
    'soothing': 2,
    'soothingly': 3,
    'soothsayer': 3,
    'soothsayers': 3,
    'soots': 1,
    'sooty': 2,
    'sooy': 2,
    'sop': 1,
    'sope': 1,
    'soper': 2,
    'sopher': 2,
    'sophia': 3,
    'sophie': 2,
    'sophisticate': 4,
    'sophisticated': 5,
    'sophisticates': 4,
    'sophistication': 5,
    'sophocles': 3,
    'sophomore': 2,
    'sophomores': 2,
    'sophomoric': 4,
    'sophronia': 4,
    'sophy': 2,
    'sopko': 2,
    'sopp': 1,
    'sopping': 2,
    'soprano': 3,
    'sopranos': 3,
    'sops': 1,
    'soquip': 2,
    'sor': 1,
    'soranno': 3,
    'sorbello': 3,
    'sorber': 2,
    'sorbet': 2,
    'sorbo': 2,
    'sorbonne': 2,
    'sorbus': 2,
    'sorce': 1,
    'sorcerer': 3,
    'sorcerers': 3,
    'sorcery': 3,
    'sorcha': 2,
    'sorci': 2,
    'sordid': 2,
    'sordoni': 3,
    'sore': 1,
    'sorel': 2,
    'sorell': 2,
    'sorely': 2,
    'sorensen': 3,
    'sorenson': 3,
    'sorento': 3,
    'sores': 1,
    'sorey': 2,
    'sorg': 1,
    'sorge': 1,
    'sorgen': 2,
    'sorghum': 2,
    'soria': 3,
    'soriano': 4,
    'sorice': 2,
    'sorin': 2,
    'sorkin': 2,
    'sorkow': 2,
    'sorlie': 2,
    'soroka': 3,
    'sororities': 4,
    'sorority': 4,
    'soros': 2,
    'sorovski': 3,
    'sorpasso': 3,
    'sorrel': 2,
    'sorrell': 2,
    'sorrells': 2,
    'sorrels': 2,
    'sorrenti': 3,
    'sorrow': 2,
    'sorrowful': 3,
    'sorrows': 2,
    'sorry': 2,
    'sort': 1,
    'sorted': 2,
    'sorter': 2,
    'sorters': 2,
    'sortie': 2,
    'sorties': 2,
    'sorting': 2,
    'sortino': 3,
    'sortor': 2,
    'sorts': 1,
    'sorum': 2,
    'sorus': 2,
    'sorvino': 3,
    'sosa': 2,
    'sosebee': 3,
    'soshi': 2,
    'sosin': 2,
    'sosinski': 3,
    'soskin': 2,
    'sosna': 2,
    'sosnoff': 2,
    'sosnowski': 3,
    'soss': 1,
    'sossamon': 3,
    'sosuke': 3,
    'sot': 1,
    'sotak': 2,
    'sotello': 3,
    'sotelo': 3,
    'soter': 2,
    'sotheby': 3,
    'soto': 2,
    'sotolongo': 4,
    'sotomayor': 4,
    'sotti': 2,
    'sottile': 2,
    'souccar': 2,
    'soucek': 2,
    'soucie': 2,
    'soucy': 2,
    'souder': 2,
    'souders': 2,
    'souers': 2,
    'souffle': 2,
    'sought': 1,
    'souk': 1,
    'soukup': 2,
    'soul': 1,
    'soule': 1,
    'soules': 1,
    'soulful': 2,
    'soulier': 3,
    'souljah': 2,
    'soulless': 2,
    'soulliere': 3,
    'souls': 1,
    'sound': 1,
    'soundbite': 2,
    'soundbites': 2,
    'sounded': 2,
    'sounder': 2,
    'soundest': 2,
    'sounding': 2,
    'soundings': 2,
    'soundly': 2,
    'soundness': 2,
    'sounds': 1,
    'soundscan': 2,
    'soundtrack': 2,
    'soundtracks': 2,
    'soundview': 2,
    'soup': 1,
    'souped': 1,
    'soups': 1,
    'soupy': 2,
    'sour': 2,
    'sourby': 2,
    'source': 1,
    'sourcebook': 2,
    'sources': 2,
    'sourcing': 2,
    'soured': 2,
    'souring': 3,
    'souris': 2,
    'sourrouille': 3,
    'sours': 2,
    'sous': 1,
    'sousa': 2,
    'sousaphone': 3,
    'sousley': 2,
    'soutar': 2,
    'souter': 2,
    'south': 1,
    'southall': 2,
    'southam': 2,
    'southampton': 3,
    'southard': 2,
    'southbound': 2,
    'southdown': 2,
    'southeast': 2,
    'southeastern': 3,
    'souther': 2,
    'southerland': 3,
    'southerly': 3,
    'southern': 2,
    'southerner': 3,
    'southerners': 3,
    'southernmost': 3,
    'southernnet': 3,
    'southers': 2,
    'southfield': 2,
    'southgate': 2,
    'southin': 2,
    'southland': 2,
    'southlife': 2,
    'southmark': 2,
    'southold': 2,
    'southport': 2,
    'souths': 1,
    'southside': 2,
    'southstate': 2,
    'southwall': 2,
    'southward': 2,
    'southway': 2,
    'southwell': 2,
    'southwest': 2,
    'southwestern': 3,
    'southwick': 2,
    'southwood': 2,
    'southworth': 2,
    'souto': 2,
    'souvenir': 3,
    'souvenirs': 3,
    'souveroff': 3,
    'souza': 2,
    'souzas': 2,
    'sova': 2,
    'sovereign': 2,
    'sovereigns': 2,
    'sovereignty': 3,
    'sovetskaya': 4,
    'sovexportfilm': 4,
    'sovey': 2,
    'soviet': 3,
    'sovietologist': 6,
    'sovietologists': 6,
    'soviets': 3,
    'sovine': 2,
    'sovran': 2,
    'sovrans': 2,
    'sovyetsky': 3,
    'sow': 1,
    'sowa': 2,
    'soward': 2,
    'sowards': 2,
    'sowash': 2,
    'sowata': 3,
    'sowden': 2,
    'sowder': 2,
    'sowders': 2,
    'sowed': 1,
    'sowell': 2,
    'sower': 2,
    'sowers': 2,
    'soweto': 3,
    'sowing': 2,
    'sowinski': 3,
    'sowle': 1,
    'sowles': 1,
    'sown': 1,
    'sows': 1,
    'sox': 1,
    'soy': 1,
    'soya': 2,
    'soyars': 2,
    'soybean': 2,
    'soybeans': 2,
    'soyka': 2,
    'soysauce': 2,
    'soyuz': 2,
    'soza': 2,
    'sozio': 3,
    'spa': 1,
    'space': 1,
    'spaceball': 2,
    'spaceballs': 2,
    'spaceband': 2,
    'spacebands': 2,
    'spacecraft': 2,
    'spaced': 1,
    'spacehab': 2,
    'spacek': 2,
    'spacelink': 2,
    'spacenet': 2,
    'spaceport': 2,
    'spacer': 2,
    'spacers': 2,
    'spaces': 2,
    'spaceship': 2,
    'spaceships': 2,
    'spacesuit': 2,
    'spacesuits': 2,
    'spacewalk': 2,
    'spacewalking': 3,
    'spacewalks': 2,
    'spacey': 2,
    'spacial': 2,
    'spacing': 2,
    'spacious': 2,
    'spack': 1,
    'spackman': 2,
    'spada': 2,
    'spadaccini': 4,
    'spadafora': 4,
    'spadafore': 3,
    'spadaro': 3,
    'spade': 1,
    'spader': 2,
    'spades': 1,
    'spadework': 2,
    'spadoni': 3,
    'spady': 2,
    'spaeth': 1,
    'spafford': 2,
    'spaghetti': 3,
    'spagna': 2,
    'spagnola': 3,
    'spagnoli': 3,
    'spagnolo': 3,
    'spagnuolo': 4,
    'spago': 2,
    'spahn': 1,
    'spahr': 1,
    'spaid': 1,
    'spain': 1,
    'spainhour': 2,
    'spainhower': 3,
    'spak': 1,
    'spake': 1,
    'spalding': 2,
    'spalink': 2,
    'spall': 1,
    'spalla': 2,
    'spallone': 2,
    'spalvins': 2,
    'spam': 1,
    'spampinato': 4,
    'span': 1,
    'spanbauer': 3,
    'spandex': 2,
    'spang': 1,
    'spangenberg': 3,
    'spangle': 2,
    'spangled': 2,
    'spangler': 3,
    'spaniard': 2,
    'spaniards': 2,
    'spaniel': 2,
    'spanier': 2,
    'spaniol': 2,
    'spanish': 2,
    'spank': 1,
    'spanked': 1,
    'spanking': 2,
    'spanky': 2,
    'spann': 1,
    'spanned': 1,
    'spanner': 2,
    'spanning': 2,
    'spanninger': 3,
    'spano': 2,
    'spanos': 2,
    'spans': 1,
    'spanton': 2,
    'spar': 1,
    'sparacino': 4,
    'sparacio': 4,
    'sparaco': 3,
    'sparano': 3,
    'sparc': 1,
    'spare': 1,
    'spared': 1,
    'spares': 1,
    'sparger': 2,
    'spargo': 2,
    'spargur': 2,
    'sparing': 2,
    'sparingly': 3,
    'spark': 1,
    'sparked': 1,
    'sparkes': 1,
    'sparking': 2,
    'sparkle': 2,
    'sparkled': 2,
    'sparkles': 2,
    'sparkling': 2,
    'sparkly': 2,
    'sparkman': 2,
    'sparks': 1,
    'sparky': 2,
    'sparlin': 2,
    'sparling': 2,
    'sparr': 1,
    'sparred': 1,
    'sparring': 2,
    'sparrow': 2,
    'sparrows': 2,
    'sparse': 1,
    'sparsely': 2,
    'sparta': 2,
    'spartacus': 3,
    'spartan': 2,
    'spartanburg': 3,
    'spartech': 2,
    'spartz': 1,
    'spas': 1,
    'spasm': 2,
    'spasmodic': 3,
    'spasmodically': 4,
    'spasms': 2,
    'spasso': 2,
    'spat': 1,
    'spatafora': 4,
    'spatafore': 3,
    'spataro': 3,
    'spate': 1,
    'spates': 1,
    'spath': 1,
    'spatial': 2,
    'spatola': 3,
    'spats': 1,
    'spatter': 2,
    'spattered': 2,
    'spattering': 3,
    'spatters': 2,
    'spatula': 3,
    'spatz': 1,
    'spaugh': 1,
    'spaulding': 2,
    'spaur': 1,
    'spavo': 2,
    'spaw': 1,
    'spawn': 1,
    'spawned': 1,
    'spawning': 2,
    'spawns': 1,
    'spayd': 1,
    'spayde': 1,
    'spaziani': 4,
    'speagle': 2,
    'speak': 1,
    'speake': 1,
    'speaker': 2,
    'speakers': 2,
    'speakership': 3,
    'speakes': 1,
    'speaking': 2,
    'speakman': 2,
    'speaks': 1,
    'spear': 1,
    'speare': 1,
    'spearhead': 2,
    'spearheaded': 3,
    'spearheading': 3,
    'spearing': 2,
    'spearman': 2,
    'spears': 1,
    'speas': 1,
    'spease': 1,
    'spec': 1,
    'specht': 1,
    'special': 2,
    'speciale': 3,
    'specialist': 3,
    'specialists': 3,
    'specialities': 3,
    'speciality': 5,
    'specialization': 5,
    'specialize': 3,
    'specialized': 3,
    'specializes': 4,
    'specializing': 4,
    'specially': 3,
    'specials': 2,
    'specialties': 3,
    'specialty': 3,
    'species': 2,
    'specific': 3,
    'specifically': 4,
    'specification': 5,
    'specifications': 5,
    'specificity': 5,
    'specifics': 3,
    'specified': 3,
    'specifies': 3,
    'specify': 3,
    'specifying': 4,
    'specimen': 3,
    'specimens': 3,
    'specious': 2,
    'speck': 1,
    'specker': 2,
    'speckle': 2,
    'speckled': 2,
    'speckman': 2,
    'specks': 1,
    'specs': 1,
    'spectacle': 3,
    'spectacles': 3,
    'spectacular': 4,
    'spectacularly': 5,
    'spectator': 3,
    'spectators': 3,
    'specter': 2,
    'specthrie': 2,
    'spector': 2,
    'spectra': 2,
    'spectradyne': 3,
    'spectramed': 2,
    'spectran': 2,
    'spectravision': 4,
    'spectre': 2,
    'spectrograph': 3,
    'spectrometer': 4,
    'spectrometry': 4,
    'spectroscopy': 4,
    'spectrum': 2,
    'spectrums': 2,
    'speculate': 3,
    'speculated': 4,
    'speculates': 3,
    'speculating': 4,
    'speculation': 4,
    'speculations': 4,
    'speculative': 4,
    'speculator': 4,
    'speculators': 4,
    'sped': 1,
    'speece': 1,
    'speech': 1,
    'speeches': 2,
    'speechify': 3,
    'speechifying': 4,
    'speechless': 2,
    'speechwriter': 3,
    'speechwriters': 3,
    'speed': 1,
    'speedboat': 2,
    'speedboats': 2,
    'speeded': 2,
    'speeder': 2,
    'speeders': 2,
    'speedier': 3,
    'speedily': 3,
    'speeding': 2,
    'speedometer': 4,
    'speedring': 2,
    'speeds': 1,
    'speedskate': 2,
    'speedskating': 3,
    'speedup': 2,
    'speedway': 2,
    'speedy': 2,
    'speegle': 2,
    'speelman': 2,
    'speer': 1,
    'spees': 1,
    'spegal': 2,
    'spehar': 2,
    'speich': 1,
    'speicher': 2,
    'speidel': 2,
    'speier': 2,
    'speight': 1,
    'speights': 1,
    'speigner': 2,
    'speir': 1,
    'speirs': 1,
    'speiser': 2,
    'spektr': 2,
    'spell': 1,
    'spellacy': 3,
    'spellbinding': 3,
    'spellbound': 2,
    'spelled': 1,
    'speller': 2,
    'spellers': 2,
    'spelling': 2,
    'spellings': 2,
    'spellman': 2,
    'spellmeyer': 3,
    'spells': 1,
    'spelman': 2,
    'speltz': 1,
    'spelunk': 2,
    'spelunked': 2,
    'spelunker': 3,
    'spelunkers': 3,
    'spelunking': 3,
    'spelunks': 2,
    'spence': 1,
    'spencer': 2,
    'spencers': 2,
    'spend': 1,
    'spendable': 3,
    'spender': 2,
    'spenders': 2,
    'spending': 2,
    'spendley': 2,
    'spendlove': 2,
    'spends': 1,
    'spendthrift': 2,
    'spengler': 3,
    'spenner': 2,
    'speno': 2,
    'spens': 1,
    'spenser': 2,
    'spensers': 2,
    'spent': 1,
    'spera': 2,
    'speranza': 3,
    'sperbeck': 2,
    'sperber': 2,
    'sperduto': 3,
    'sperl': 1,
    'sperle': 2,
    'sperlich': 2,
    'sperling': 2,
    'sperm': 1,
    'sperms': 1,
    'spero': 2,
    'speros': 2,
    'sperrazza': 3,
    'sperrfrist': 2,
    'sperry': 2,
    'spessard': 2,
    'speth': 1,
    'spethmann': 2,
    'spetsnaz': 2,
    'spevak': 2,
    'spew': 1,
    'spewed': 1,
    'spewing': 2,
    'spews': 1,
    'spey': 1,
    'speyer': 2,
    'speziale': 4,
    'spezzano': 3,
    'sphar': 1,
    'sphere': 1,
    'spheres': 1,
    'spherical': 3,
    'spheroid': 2,
    'sphinx': 1,
    'spic': 1,
    'spice': 1,
    'spiced': 1,
    'spiceland': 2,
    'spicer': 2,
    'spices': 2,
    'spicey': 2,
    'spicher': 2,
    'spicing': 2,
    'spickard': 2,
    'spickler': 2,
    'spicuzza': 3,
    'spicy': 2,
    'spidel': 2,
    'spidell': 2,
    'spider': 2,
    'spiderman': 3,
    'spiders': 2,
    'spidle': 2,
    'spie': 1,
    'spied': 1,
    'spiegel': 2,
    'spiegelberg': 3,
    'spiegelman': 3,
    'spiegler': 2,
    'spieker': 2,
    'spiel': 1,
    'spielberg': 2,
    'spielberger': 3,
    'spieler': 2,
    'spielman': 2,
    'spielmann': 2,
    'spielvogel': 3,
    'spier': 2,
    'spiering': 3,
    'spiers': 2,
    'spies': 1,
    'spiess': 1,
    'spieth': 2,
    'spiewak': 2,
    'spiffing': 2,
    'spiffy': 2,
    'spight': 1,
    'spigner': 2,
    'spigot': 2,
    'spigots': 2,
    'spike': 1,
    'spiked': 1,
    'spiker': 2,
    'spikes': 1,
    'spiking': 2,
    'spiky': 2,
    'spilde': 1,
    'spilker': 2,
    'spill': 1,
    'spillage': 2,
    'spillane': 2,
    'spilled': 1,
    'spiller': 2,
    'spillers': 2,
    'spilling': 2,
    'spillman': 2,
    'spillover': 3,
    'spills': 1,
    'spillway': 2,
    'spilman': 2,
    'spilotro': 3,
    'spilt': 1,
    'spin': 1,
    'spina': 2,
    'spinach': 2,
    'spinal': 2,
    'spinale': 3,
    'spindel': 2,
    'spindle': 2,
    'spindler': 3,
    'spine': 1,
    'spineless': 2,
    'spinella': 3,
    'spinelli': 3,
    'spinello': 3,
    'spines': 1,
    'spinfizz': 2,
    'spinifex': 3,
    'spink': 1,
    'spinks': 1,
    'spinner': 2,
    'spinners': 2,
    'spinney': 2,
    'spinning': 2,
    'spino': 2,
    'spinoff': 2,
    'spinoffs': 2,
    'spinola': 3,
    'spinosa': 3,
    'spinoza': 3,
    'spins': 1,
    'spinster': 2,
    'spiny': 2,
    'spira': 2,
    'spiral': 2,
    'spiraled': 2,
    'spiraling': 3,
    'spiralling': 3,
    'spirals': 2,
    'spire': 1,
    'spires': 1,
    'spirit': 2,
    'spirited': 3,
    'spiritedness': 4,
    'spirito': 3,
    'spirits': 2,
    'spiritual': 4,
    'spiritualism': 5,
    'spiritualist': 5,
    'spiritualists': 5,
    'spirituality': 6,
    'spiritually': 5,
    'spirituals': 4,
    'spiro': 2,
    'spisak': 2,
    'spit': 1,
    'spitale': 3,
    'spitball': 2,
    'spite': 1,
    'spiteful': 2,
    'spiteri': 3,
    'spitler': 2,
    'spits': 1,
    'spitting': 2,
    'spittle': 2,
    'spittler': 2,
    'spitz': 1,
    'spitzer': 2,
    'spitzley': 2,
    'spitznagel': 3,
    'spiva': 2,
    'spivack': 2,
    'spivak': 2,
    'spivey': 2,
    'spiwak': 2,
    'spizzirri': 3,
    'splain': 1,
    'splaine': 1,
    'splash': 1,
    'splashed': 1,
    'splashes': 2,
    'splashing': 2,
    'splashy': 2,
    'splat': 1,
    'splatter': 2,
    'splattered': 2,
    'splawn': 1,
    'spleen': 1,
    'spleisson': 2,
    'splendid': 2,
    'splendidly': 3,
    'splendor': 2,
    'splenic': 2,
    'splice': 1,
    'spliced': 1,
    'splices': 2,
    'splichal': 2,
    'splicing': 2,
    'splint': 1,
    'splinter': 2,
    'splintered': 2,
    'splintering': 3,
    'splintery': 3,
    'splints': 1,
    'split': 1,
    'splits': 1,
    'splitt': 1,
    'splitting': 2,
    'splurge': 1,
    'splurged': 1,
    'splurging': 2,
    'spoarer': 2,
    'spock': 1,
    'spoden': 2,
    'spoelstra': 2,
    'spoerl': 1,
    'spofford': 2,
    'spogli': 2,
    'spohn': 1,
    'spohr': 1,
    'spoil': 1,
    'spoilage': 2,
    'spoiled': 1,
    'spoiler': 2,
    'spoilers': 2,
    'spoiling': 2,
    'spoils': 1,
    'spokane': 2,
    'spoke': 1,
    'spoken': 2,
    'spokenly': 3,
    'spokes': 1,
    'spokesman': 2,
    'spokesmen': 2,
    'spokespeople': 3,
    'spokesperson': 3,
    'spokespersons': 3,
    'spokeswoman': 3,
    'spokeswomen': 3,
    'spoleto': 3,
    'spoletto': 3,
    'spomer': 2,
    'sponaugle': 3,
    'spong': 1,
    'sponge': 1,
    'sponged': 1,
    'spongeform': 2,
    'sponges': 2,
    'spongy': 2,
    'sponsel': 2,
    'sponseller': 3,
    'sponsler': 3,
    'sponsor': 2,
    'sponsored': 2,
    'sponsoring': 3,
    'sponsors': 2,
    'sponsorship': 3,
    'sponsorships': 3,
    'spontaneity': 5,
    'spontaneous': 4,
    'spontaneously': 5,
    'spoof': 1,
    'spoofed': 1,
    'spoofing': 2,
    'spoofs': 1,
    'spook': 1,
    'spooked': 1,
    'spooks': 1,
    'spooky': 2,
    'spool': 1,
    'spoon': 1,
    'spoonemore': 2,
    'spooner': 2,
    'spoonful': 2,
    'spoonfuls': 2,
    'spoons': 1,
    'spoor': 1,
    'sporadic': 3,
    'sporadically': 4,
    'sporck': 1,
    'spore': 1,
    'sporer': 2,
    'spores': 1,
    'sporkin': 2,
    'sporleder': 3,
    'sporn': 1,
    'sporophyte': 2,
    'sporophytes': 2,
    'sporrer': 2,
    'sport': 1,
    'sported': 2,
    'sportier': 3,
    'sporting': 2,
    'sportingly': 3,
    'sports': 1,
    'sportsbar': 2,
    'sportscaster': 3,
    'sportscasters': 3,
    'sportschannel': 3,
    'sportsclub': 2,
    'sportsman': 2,
    'sportsmanship': 3,
    'sportsmen': 2,
    'sportster': 2,
    'sportswear': 2,
    'sportswriter': 3,
    'sportswriters': 3,
    'sporty': 2,
    'sposato': 3,
    'sposito': 3,
    'spot': 1,
    'spotless': 2,
    'spotlight': 2,
    'spotlighted': 3,
    'spotlighting': 3,
    'spotlights': 2,
    'spoto': 2,
    'spots': 1,
    'spotted': 2,
    'spotter': 2,
    'spotters': 2,
    'spotting': 2,
    'spotts': 1,
    'spotty': 2,
    'spousal': 2,
    'spouse': 1,
    'spouses': 2,
    'spout': 1,
    'spouted': 2,
    'spouting': 2,
    'spouts': 1,
    'spracklen': 3,
    'spradley': 2,
    'spradlin': 2,
    'spradling': 2,
    'spragg': 1,
    'spraggins': 2,
    'sprague': 1,
    'sprain': 1,
    'sprained': 1,
    'sprains': 1,
    'spraker': 2,
    'sprang': 1,
    'spranger': 2,
    'sprankle': 2,
    'spratley': 2,
    'spratlin': 2,
    'spratling': 2,
    'spratt': 1,
    'sprawl': 1,
    'sprawled': 1,
    'sprawling': 2,
    'sprawls': 1,
    'spray': 1,
    'sprayberry': 3,
    'sprayed': 1,
    'sprayer': 2,
    'sprayers': 2,
    'spraying': 2,
    'sprays': 1,
    'spread': 1,
    'spreader': 2,
    'spreading': 2,
    'spreads': 1,
    'spreadsheet': 2,
    'spreadsheets': 2,
    'sprecher': 2,
    'spreckels': 2,
    'spree': 1,
    'spreeman': 2,
    'spreen': 1,
    'sprees': 1,
    'sprehe': 1,
    'spreitzer': 2,
    'spreng': 1,
    'sprenger': 2,
    'sprenkle': 2,
    'sprick': 1,
    'sprigg': 1,
    'sprigged': 1,
    'spriggs': 1,
    'sprightly': 2,
    'spring': 1,
    'springboard': 2,
    'springborn': 2,
    'springdale': 2,
    'springer': 2,
    'springerville': 3,
    'springfield': 2,
    'springing': 2,
    'springman': 2,
    'springs': 1,
    'springstead': 2,
    'springsteen': 2,
    'springston': 2,
    'springtime': 2,
    'sprinkel': 2,
    'sprinkle': 2,
    'sprinkled': 2,
    'sprinkler': 2,
    'sprinklers': 2,
    'sprinkles': 2,
    'sprinkling': 2,
    'sprint': 1,
    'sprinted': 2,
    'sprinter': 2,
    'sprinters': 2,
    'sprinting': 2,
    'sprints': 1,
    'sprite': 1,
    'sprizzo': 2,
    'sproat': 1,
    'sprock': 1,
    'sprocket': 2,
    'sprogus': 2,
    'sproles': 1,
    'sprong': 1,
    'sprott': 1,
    'sproul': 1,
    'sproule': 1,
    'sproull': 1,
    'sprouls': 1,
    'sprouse': 1,
    'sprout': 1,
    'sproutaphile': 3,
    'sproutaphiles': 3,
    'sprouted': 2,
    'sprouting': 2,
    'sprouts': 1,
    'sprow': 1,
    'sprowl': 1,
    'sprowle': 2,
    'sprowls': 1,
    'spruce': 1,
    'spruced': 1,
    'sprucing': 2,
    'spruell': 1,
    'spruiell': 1,
    'spruill': 1,
    'sprung': 1,
    'sprunger': 2,
    'spry': 1,
    'spud': 1,
    'spuds': 1,
    'spuhler': 2,
    'spun': 1,
    'spunk': 1,
    'spunky': 2,
    'spur': 1,
    'spurdle': 2,
    'spurge': 1,
    'spurgeon': 2,
    'spurgin': 2,
    'spurious': 3,
    'spurlin': 2,
    'spurling': 2,
    'spurlock': 2,
    'spurn': 1,
    'spurned': 1,
    'spurning': 2,
    'spurns': 1,
    'spurr': 1,
    'spurred': 1,
    'spurrier': 3,
    'spurring': 2,
    'spurs': 1,
    'spurt': 1,
    'spurted': 2,
    'spurting': 2,
    'spurts': 1,
    'sputnik': 2,
    'sputniks': 2,
    'sputter': 2,
    'sputtered': 2,
    'sputtering': 3,
    'sputters': 2,
    'spy': 1,
    'spycatcher': 3,
    'spychalski': 3,
    'spyglass': 2,
    'spying': 2,
    'spyker': 2,
    'squabble': 2,
    'squabbled': 2,
    'squabbles': 2,
    'squabbling': 3,
    'squad': 1,
    'squadron': 2,
    'squadrons': 2,
    'squads': 1,
    'squalid': 2,
    'squall': 1,
    'squalls': 1,
    'squalor': 2,
    'squander': 2,
    'squandered': 2,
    'squandering': 3,
    'squanders': 2,
    'square': 1,
    'squared': 1,
    'squarely': 2,
    'squares': 1,
    'squaring': 2,
    'squash': 1,
    'squashed': 1,
    'squashing': 2,
    'squashy': 2,
    'squat': 1,
    'squats': 1,
    'squatter': 2,
    'squatters': 2,
    'squatting': 2,
    'squatty': 2,
    'squawk': 1,
    'squawking': 2,
    'squawks': 1,
    'squeak': 1,
    'squeaked': 1,
    'squeaker': 2,
    'squeakers': 2,
    'squeaking': 2,
    'squeaks': 1,
    'squeaky': 2,
    'squeal': 1,
    'squealing': 2,
    'squeals': 1,
    'squeamish': 2,
    'squeamishness': 3,
    'squeegee': 2,
    'squeeze': 1,
    'squeezed': 1,
    'squeezes': 2,
    'squeezing': 2,
    'squelch': 1,
    'squelched': 1,
    'squelching': 2,
    'squibb': 1,
    'squid': 1,
    'squier': 2,
    'squiers': 2,
    'squiggle': 2,
    'squiggles': 2,
    'squillace': 2,
    'squillacote': 3,
    'squillante': 3,
    'squint': 1,
    'squinting': 2,
    'squire': 1,
    'squires': 2,
    'squirm': 1,
    'squirming': 2,
    'squirms': 1,
    'squirrel': 2,
    'squirreled': 2,
    'squirrels': 2,
    'squirt': 1,
    'squirted': 2,
    'squirting': 2,
    'squirts': 1,
    'squishy': 2,
    'squitieri': 3,
    'squyres': 1,
    'sr': 2,
    'srader': 2,
    'sram': 1,
    'sramek': 2,
    'srdan': 2,
    'srebrenica': 4,
    'sremac': 2,
    'sri': 1,
    'srinagar': 3,
    'srinagarb': 3,
    'srinivas': 3,
    'srinivasan': 4,
    'sriram': 2,
    'srivastava': 4,
    'srock': 1,
    'sroge': 1,
    'sroka': 2,
    'sroufe': 1,
    'srpska': 2,
    'ssangyong': 2,
    'st': 1,
    'sta': 1,
    'staab': 1,
    'staack': 1,
    'staal': 1,
    'staar': 1,
    'staas': 1,
    'staat': 1,
    'staats': 1,
    'stab': 1,
    'stabbed': 1,
    'stabbing': 2,
    'stabbings': 2,
    'stabenow': 3,
    'stabile': 2,
    'stability': 4,
    'stabilization': 5,
    'stabilize': 3,
    'stabilized': 3,
    'stabilizer': 4,
    'stabilizers': 4,
    'stabilizes': 4,
    'stabilizing': 4,
    'stable': 2,
    'stabler': 3,
    'stables': 2,
    'stablest': 3,
    'stabley': 2,
    'stabs': 1,
    'stac': 1,
    'staccato': 3,
    'stacey': 2,
    'stach': 1,
    'stachnik': 2,
    'stachowiak': 4,
    'stachowicz': 3,
    'stachowski': 3,
    'stachura': 3,
    'stachurski': 3,
    'stacia': 2,
    'stacie': 2,
    'stack': 1,
    'stacked': 1,
    'stacker': 2,
    'stackhouse': 2,
    'stacking': 2,
    'stackpole': 2,
    'stacks': 1,
    'stacy': 2,
    'stade': 1,
    'stadel': 2,
    'stadelman': 3,
    'stader': 2,
    'stadia': 3,
    'stadium': 3,
    'stadiums': 3,
    'stadler': 2,
    'stadnik': 2,
    'stadt': 1,
    'stadtlander': 3,
    'stadtmiller': 3,
    'staebell': 2,
    'staebler': 2,
    'staehle': 1,
    'staff': 1,
    'staffa': 2,
    'staffed': 1,
    'staffer': 2,
    'staffers': 2,
    'staffieri': 3,
    'staffing': 2,
    'stafford': 2,
    'staffordshire': 3,
    'staffs': 1,
    'stag': 1,
    'stage': 1,
    'stagecoach': 2,
    'stagecraft': 2,
    'staged': 1,
    'stagehand': 2,
    'stagehands': 2,
    'stager': 2,
    'stages': 2,
    'stagflate': 2,
    'stagflation': 3,
    'stagg': 1,
    'stagger': 2,
    'staggered': 2,
    'staggering': 3,
    'staggers': 2,
    'staggs': 1,
    'staging': 2,
    'stagliano': 4,
    'stagnant': 2,
    'stagnaro': 3,
    'stagnate': 2,
    'stagnated': 3,
    'stagnating': 3,
    'stagnation': 3,
    'stagner': 2,
    'stahl': 1,
    'stahlberg': 2,
    'stahle': 2,
    'stahlecker': 3,
    'stahler': 2,
    'stahley': 2,
    'stahlhut': 2,
    'stahlman': 2,
    'stahly': 2,
    'stahmer': 2,
    'stahnke': 1,
    'stahr': 1,
    'stai': 2,
    'staib': 1,
    'staid': 1,
    'staiger': 2,
    'stailey': 2,
    'stain': 1,
    'stainback': 2,
    'stainbrook': 2,
    'stained': 1,
    'staines': 1,
    'staining': 2,
    'stainless': 2,
    'stainmaster': 3,
    'stains': 1,
    'stair': 1,
    'staircase': 2,
    'staircases': 3,
    'stairs': 1,
    'stairway': 2,
    'stairways': 2,
    'stairwell': 2,
    'stairwells': 2,
    'stake': 1,
    'staked': 1,
    'stakeholder': 3,
    'stakeholders': 3,
    'stakeout': 2,
    'stakeouts': 2,
    'staker': 2,
    'stakes': 1,
    'staking': 2,
    'stalcup': 2,
    'stalder': 2,
    'stale': 1,
    'stalemate': 2,
    'stalemated': 3,
    'staley': 2,
    'stalin': 2,
    'stalingrad': 3,
    'stalinism': 4,
    'stalinist': 3,
    'stalinistic': 4,
    'stalinists': 3,
    'stalinization': 5,
    'stalinize': 3,
    'stalk': 1,
    'stalked': 1,
    'stalker': 2,
    'stalkers': 2,
    'stalking': 2,
    'stalks': 1,
    'stalky': 2,
    'stall': 1,
    'stallard': 2,
    'stallcup': 2,
    'stalled': 1,
    'staller': 2,
    'stallibrass': 3,
    'stalling': 2,
    'stallings': 2,
    'stallion': 2,
    'stallions': 2,
    'stallkamp': 2,
    'stallman': 2,
    'stallone': 2,
    'stalls': 1,
    'stallsmith': 2,
    'stallworth': 2,
    'stalnaker': 3,
    'staloff': 2,
    'stalon': 2,
    'stalowa': 3,
    'stalter': 2,
    'stalvey': 2,
    'stalwart': 2,
    'stalwarts': 2,
    'stalzer': 2,
    'stam': 1,
    'stamand': 2,
    'stamant': 2,
    'stamas': 2,
    'stamaty': 3,
    'stambaugh': 2,
    'stamberg': 2,
    'stamenson': 3,
    'stamer': 2,
    'stamey': 2,
    'stamford': 2,
    'stamina': 3,
    'stamler': 2,
    'stamm': 1,
    'stammen': 2,
    'stammer': 2,
    'stamos': 2,
    'stamour': 2,
    'stamp': 1,
    'stamped': 1,
    'stampede': 2,
    'stampeded': 3,
    'stampeding': 3,
    'stamper': 2,
    'stampfli': 2,
    'stamping': 2,
    'stampings': 2,
    'stampley': 2,
    'stamps': 1,
    'stan': 1,
    'stanadyne': 3,
    'stanaland': 3,
    'stanard': 2,
    'stanaway': 3,
    'stanback': 2,
    'stanberry': 3,
    'stanbery': 3,
    'stanbic': 2,
    'stanbrough': 2,
    'stanbury': 3,
    'stance': 1,
    'stances': 2,
    'stanch': 1,
    'stanched': 1,
    'stanchfield': 2,
    'stanching': 2,
    'stancik': 2,
    'stancil': 2,
    'stancill': 2,
    'stancliff': 2,
    'stanco': 2,
    'stanczak': 2,
    'stanczyk': 2,
    'stand': 1,
    'standa': 2,
    'standard': 2,
    'standardization': 5,
    'standardize': 3,
    'standardized': 3,
    'standardizing': 4,
    'standards': 2,
    'standby': 2,
    'standbys': 2,
    'standefer': 3,
    'standen': 2,
    'stander': 2,
    'standerfer': 3,
    'standerford': 3,
    'standex': 2,
    'standfield': 2,
    'standifer': 3,
    'standiford': 3,
    'standing': 2,
    'standings': 2,
    'standish': 2,
    'standlee': 2,
    'standley': 2,
    'standoff': 2,
    'standoffs': 2,
    'standout': 2,
    'standouts': 2,
    'standpoint': 2,
    'standpoints': 2,
    'standre': 2,
    'standridge': 2,
    'stands': 1,
    'standstill': 2,
    'standup': 2,
    'stanek': 2,
    'stanfield': 2,
    'stanfill': 2,
    'stanford': 2,
    'stanforth': 2,
    'stang': 1,
    'stanga': 2,
    'stange': 1,
    'stangel': 2,
    'stangeland': 3,
    'stangelo': 3,
    'stanger': 2,
    'stangl': 2,
    'stangland': 2,
    'stangle': 2,
    'stangler': 3,
    'stango': 2,
    'stanhope': 2,
    'staniar': 3,
    'stanich': 2,
    'stanifer': 3,
    'stanis': 2,
    'stanish': 2,
    'stanislas': 3,
    'stanislav': 3,
    'stanislaw': 3,
    'stanislawski': 4,
    'staniszewski': 4,
    'stank': 1,
    'stanke': 1,
    'stankevich': 3,
    'stankey': 2,
    'stankiewicz': 3,
    'stanko': 2,
    'stankovich': 3,
    'stankowski': 3,
    'stankus': 2,
    'stanleigh': 2,
    'stanley': 2,
    'stanleytown': 3,
    'stanline': 2,
    'stanly': 2,
    'stanmore': 2,
    'stann': 1,
    'stannard': 2,
    'stannie': 2,
    'stano': 2,
    'stanphill': 2,
    'stansberry': 3,
    'stansbury': 3,
    'stansel': 2,
    'stansell': 2,
    'stansfield': 2,
    'stansky': 2,
    'stant': 1,
    'stanton': 2,
    'stanway': 2,
    'stanwick': 2,
    'stanwood': 2,
    'stanza': 2,
    'stanzas': 2,
    'stanzione': 4,
    'stapel': 2,
    'stapf': 1,
    'staph': 1,
    'staphylococcus': 5,
    'staple': 2,
    'stapled': 2,
    'stapler': 3,
    'staplers': 3,
    'staples': 2,
    'stapleton': 3,
    'stapley': 2,
    'stapling': 3,
    'stapp': 1,
    'star': 1,
    'starace': 3,
    'starbird': 2,
    'starboard': 2,
    'starboards': 2,
    'starbuck': 2,
    'starbucks': 2,
    'starcevich': 3,
    'starch': 1,
    'starched': 1,
    'starcher': 2,
    'starches': 2,
    'starchlike': 2,
    'starchy': 2,
    'starck': 1,
    'starckmann': 2,
    'starcraft': 2,
    'starcross': 2,
    'stardel': 2,
    'stardent': 2,
    'stardom': 2,
    'stardust': 2,
    'stare': 1,
    'stared': 1,
    'starer': 2,
    'stares': 1,
    'starfish': 2,
    'stargate': 2,
    'stargates': 2,
    'stargazer': 3,
    'stargazers': 3,
    'starin': 2,
    'staring': 2,
    'stark': 1,
    'starke': 1,
    'starkel': 2,
    'starker': 2,
    'starkes': 1,
    'starkey': 2,
    'starkist': 2,
    'starkly': 2,
    'starkman': 2,
    'starkness': 2,
    'starkovich': 3,
    'starks': 1,
    'starkweather': 3,
    'starlet': 2,
    'starlets': 2,
    'starlight': 2,
    'starlike': 2,
    'starlin': 2,
    'starling': 2,
    'starliper': 3,
    'starlit': 2,
    'starman': 2,
    'starmer': 2,
    'starn': 1,
    'starner': 2,
    'starnes': 1,
    'starns': 1,
    'starodubstev': 4,
    'staron': 2,
    'starplex': 2,
    'starpointe': 2,
    'starr': 1,
    'starred': 1,
    'starrett': 2,
    'starring': 2,
    'starrs': 1,
    'starry': 2,
    'stars': 1,
    'starship': 2,
    'starsight': 2,
    'starstream': 2,
    'starstruck': 2,
    'start': 1,
    'started': 2,
    'starter': 2,
    'starters': 2,
    'starting': 2,
    'startle': 2,
    'startled': 2,
    'startles': 2,
    'startling': 2,
    'startlingly': 3,
    'starts': 1,
    'startup': 2,
    'startups': 2,
    'starvation': 3,
    'starve': 1,
    'starved': 1,
    'starving': 2,
    'starwalt': 2,
    'starwave': 2,
    'stary': 2,
    'starzyk': 2,
    'stash': 1,
    'stashed': 1,
    'stashing': 2,
    'stasi': 2,
    'stasiak': 3,
    'stasik': 2,
    'stasio': 3,
    'stasko': 2,
    'stasney': 2,
    'stasny': 2,
    'stassen': 2,
    'stassi': 2,
    'stastny': 2,
    'staszak': 2,
    'staszewski': 3,
    'stat': 1,
    'state': 1,
    'statecraft': 2,
    'stated': 2,
    'statehood': 2,
    'statehouse': 2,
    'statehouses': 3,
    'stateless': 2,
    'stately': 2,
    'statement': 2,
    'statements': 2,
    'staten': 2,
    'stater': 2,
    'staters': 2,
    'states': 1,
    'statesborough': 3,
    'stateside': 2,
    'statesman': 2,
    'statesmanlike': 4,
    'statesmanship': 3,
    'statesmen': 2,
    'stateswest': 2,
    'statewide': 2,
    'statham': 2,
    'stathis': 2,
    'stathopoulos': 4,
    'static': 2,
    'statin': 2,
    'stating': 2,
    'statins': 2,
    'station': 2,
    'stationary': 4,
    'stationed': 2,
    'stationer': 3,
    'stationers': 3,
    'stationery': 4,
    'stationing': 3,
    'stations': 2,
    'statism': 3,
    'statist': 2,
    'statistic': 3,
    'statistical': 4,
    'statistically': 5,
    'statistician': 4,
    'statisticians': 4,
    'statistics': 3,
    'statists': 2,
    'statler': 2,
    'statoil': 2,
    'staton': 2,
    'stats': 1,
    'stattin': 2,
    'statton': 2,
    'statuary': 4,
    'statue': 2,
    'statues': 2,
    'statuesque': 3,
    'statuette': 3,
    'statuettes': 3,
    'statum': 2,
    'stature': 2,
    'status': 2,
    'statute': 2,
    'statutes': 2,
    'statutorily': 5,
    'statutory': 4,
    'statz': 1,
    'statzer': 2,
    'staub': 1,
    'stauber': 2,
    'staubin': 2,
    'staubs': 1,
    'stauch': 1,
    'staudacher': 3,
    'staude': 1,
    'stauder': 2,
    'staudinger': 3,
    'staudt': 1,
    'stauff': 1,
    'stauffacher': 3,
    'stauffer': 2,
    'staunch': 1,
    'staunchest': 2,
    'staunchly': 2,
    'staunton': 2,
    'staup': 1,
    'stauss': 1,
    'stauter': 2,
    'stave': 1,
    'staved': 1,
    'stavely': 2,
    'staver': 2,
    'staves': 1,
    'staving': 2,
    'stavinoha': 4,
    'stavola': 3,
    'stavropoulos': 4,
    'stavros': 2,
    'stawicki': 3,
    'stawski': 2,
    'stay': 1,
    'stayed': 1,
    'stayer': 2,
    'staying': 2,
    'stayner': 2,
    'stayover': 3,
    'stayovers': 3,
    'stays': 1,
    'stayton': 2,
    'stclair': 2,
    'stds': 3,
    'stead': 1,
    'steadfast': 2,
    'steadfastly': 3,
    'steadfastness': 3,
    'steadham': 2,
    'steadied': 2,
    'steadier': 3,
    'steadily': 3,
    'steadiness': 3,
    'steadman': 2,
    'steady': 2,
    'steagall': 2,
    'steagle': 2,
    'steak': 1,
    'steakhouse': 2,
    'steakhouses': 3,
    'steakley': 2,
    'steaks': 1,
    'steal': 1,
    'stealer': 2,
    'stealey': 2,
    'stealing': 2,
    'steals': 1,
    'stealth': 1,
    'stealthier': 3,
    'stealthies': 2,
    'stealthiest': 3,
    'stealthy': 2,
    'steam': 1,
    'steamboat': 2,
    'steamboats': 2,
    'steamed': 1,
    'steamer': 2,
    'steamers': 2,
    'steamier': 3,
    'steamiest': 3,
    'steamily': 3,
    'steaming': 2,
    'steamroller': 3,
    'steamrollered': 3,
    'steams': 1,
    'steamship': 2,
    'steamy': 2,
    'stear': 1,
    'stearic': 2,
    'stearman': 2,
    'stearn': 1,
    'stearne': 1,
    'stearns': 1,
    'stears': 1,
    'stebbins': 2,
    'steber': 2,
    'stebner': 2,
    'stec': 1,
    'stech': 1,
    'stecher': 2,
    'stechler': 2,
    'stechschulte': 2,
    'steck': 1,
    'steckel': 2,
    'steckelberg': 3,
    'stecker': 2,
    'stecklein': 2,
    'steckler': 2,
    'steckley': 2,
    'steckman': 2,
    'stedman': 2,
    'steeb': 1,
    'steeber': 2,
    'steed': 1,
    'steedley': 2,
    'steedman': 2,
    'steeds': 1,
    'steeg': 1,
    'steege': 1,
    'steego': 2,
    'steel': 1,
    'steelcase': 2,
    'steele': 1,
    'steeler': 2,
    'steelers': 2,
    'steeley': 2,
    'steelhead': 2,
    'steelmaker': 3,
    'steelmakers': 3,
    'steelmaking': 3,
    'steelman': 2,
    'steels': 1,
    'steelworker': 3,
    'steelworkers': 3,
    'steelworks': 2,
    'steely': 2,
    'steen': 1,
    'steenbergen': 3,
    'steenburgen': 3,
    'steenkamp': 2,
    'steenkiste': 2,
    'steenrod': 2,
    'steensma': 2,
    'steenson': 2,
    'steep': 1,
    'steeped': 1,
    'steepened': 2,
    'steeper': 2,
    'steepest': 2,
    'steeple': 2,
    'steeplechase': 3,
    'steeples': 2,
    'steeply': 2,
    'steepness': 2,
    'steer': 1,
    'steere': 1,
    'steered': 1,
    'steering': 2,
    'steers': 1,
    'stees': 1,
    'steeton': 2,
    'steever': 2,
    'steeves': 1,
    'stefa': 2,
    'stefan': 2,
    'stefanelli': 4,
    'stefani': 3,
    'stefaniak': 4,
    'stefanic': 3,
    'stefanich': 3,
    'stefanick': 3,
    'stefanie': 3,
    'stefanik': 3,
    'stefanko': 3,
    'stefano': 3,
    'stefanopolis': 5,
    'stefanowicz': 4,
    'stefanski': 3,
    'steffan': 2,
    'steffanci': 3,
    'steffe': 1,
    'steffek': 2,
    'steffel': 2,
    'steffen': 2,
    'steffenhagen': 4,
    'steffens': 2,
    'steffensen': 3,
    'steffensmeier': 4,
    'steffenson': 3,
    'steffes': 1,
    'steffey': 2,
    'steffi': 2,
    'steffie': 2,
    'steffler': 2,
    'steffy': 2,
    'stefko': 2,
    'stefl': 2,
    'stegall': 2,
    'stegar': 2,
    'stege': 1,
    'stegeman': 2,
    'stegemann': 2,
    'stegemeier': 2,
    'stegenga': 3,
    'steger': 2,
    'stegmaier': 3,
    'stegman': 2,
    'stegmann': 2,
    'stegner': 2,
    'stegosaurus': 4,
    'stehle': 2,
    'stehlik': 2,
    'stehlin': 2,
    'stehling': 2,
    'stehman': 2,
    'stehr': 1,
    'steib': 1,
    'steichen': 2,
    'steidel': 2,
    'steidinger': 3,
    'steidl': 2,
    'steidle': 2,
    'steidtmann': 2,
    'steier': 2,
    'steig': 1,
    'steiger': 2,
    'steigerwald': 3,
    'steigerwalt': 3,
    'steil': 1,
    'steiman': 2,
    'steimel': 2,
    'steimer': 2,
    'steimle': 2,
    'stein': 1,
    'steinacker': 3,
    'steinbach': 2,
    'steinbacher': 3,
    'steinback': 2,
    'steinbauer': 3,
    'steinbaugh': 2,
    'steinbeck': 2,
    'steinberg': 2,
    'steinbergen': 3,
    'steinberger': 3,
    'steinbock': 2,
    'steinborn': 2,
    'steinbrecher': 3,
    'steinbrenner': 3,
    'steinbrink': 2,
    'steinem': 2,
    'steiner': 2,
    'steinert': 2,
    'steines': 1,
    'steinfeld': 2,
    'steinfeldt': 2,
    'steingut': 2,
    'steinhagen': 3,
    'steinhardt': 2,
    'steinhart': 2,
    'steinhauer': 3,
    'steinhaus': 2,
    'steinhauser': 3,
    'steinhilber': 3,
    'steinhoff': 2,
    'steinhorst': 2,
    'steininger': 3,
    'steinkamp': 2,
    'steinke': 1,
    'steinkraus': 2,
    'steinkrauss': 2,
    'steinkuehler': 3,
    'steinle': 2,
    'steinman': 2,
    'steinmann': 2,
    'steinmetz': 2,
    'steinmeyer': 3,
    'steinmiller': 3,
    'steinroe': 2,
    'steinway': 2,
    'steitz': 1,
    'stejskal': 2,
    'steketee': 3,
    'stekly': 2,
    'stelco': 2,
    'stelian': 3,
    'stell': 1,
    'stella': 2,
    'stellar': 2,
    'stellarton': 3,
    'stellato': 3,
    'stelle': 1,
    'stellenbosch': 3,
    'steller': 2,
    'stellhorn': 2,
    'stelling': 2,
    'stelljes': 2,
    'stellmach': 2,
    'stellmacher': 3,
    'stellman': 2,
    'stello': 2,
    'stelly': 2,
    'stelmach': 2,
    'stelmack': 2,
    'steloff': 2,
    'stelter': 2,
    'steltz': 1,
    'stelzer': 2,
    'stelzner': 2,
    'stem': 1,
    'stemberg': 2,
    'stembridge': 2,
    'stemen': 2,
    'stemler': 2,
    'stemlike': 2,
    'stemm': 1,
    'stemmed': 1,
    'stemmer': 2,
    'stemming': 2,
    'stemmler': 2,
    'stempel': 2,
    'stemper': 2,
    'stempien': 2,
    'stemple': 2,
    'stempler': 2,
    'stemposts': 2,
    'stems': 1,
    'sten': 1,
    'stena': 2,
    'stenberg': 2,
    'stencel': 2,
    'stench': 1,
    'stencil': 2,
    'stenciled': 2,
    'stenciling': 3,
    'stendal': 2,
    'stender': 2,
    'stendig': 2,
    'stene': 1,
    'stenerson': 3,
    'stengel': 2,
    'stenger': 2,
    'stenglein': 2,
    'stenholm': 2,
    'stenhouse': 2,
    'stenner': 2,
    'stennett': 2,
    'stennis': 2,
    'steno': 2,
    'stenographer': 4,
    'stenographic': 4,
    'stenquist': 2,
    'stenseth': 2,
    'stensland': 2,
    'stenson': 2,
    'stensrud': 2,
    'stenstrom': 2,
    'stent': 1,
    'stentor': 2,
    'stentors': 2,
    'stentz': 1,
    'steny': 2,
    'stenz': 1,
    'stenzel': 2,
    'step': 1,
    'stepan': 2,
    'stepanakert': 4,
    'stepanek': 3,
    'stepanian': 4,
    'stepanski': 3,
    'stepchild': 2,
    'stepchildren': 3,
    'stepdaughter': 3,
    'stepdaughters': 3,
    'stepfather': 3,
    'steph': 1,
    'stepha': 2,
    'stephan': 2,
    'stephana': 3,
    'stephane': 2,
    'stephani': 3,
    'stephania': 4,
    'stephanie': 3,
    'stephanopoulos': 5,
    'stephans': 2,
    'stephany': 3,
    'stephen': 2,
    'stephens': 2,
    'stephenson': 3,
    'stephenville': 3,
    'stepien': 3,
    'stepka': 2,
    'stepladder': 3,
    'stepladders': 3,
    'stepmother': 3,
    'stepney': 2,
    'stepp': 1,
    'steppe': 1,
    'stepped': 1,
    'steppel': 2,
    'steppenwolf': 3,
    'stepper': 2,
    'steppers': 2,
    'steppes': 1,
    'stepping': 2,
    'steppingstone': 3,
    'steps': 1,
    'stepsister': 3,
    'stepsisters': 3,
    'stepson': 2,
    'steptoe': 2,
    'sterba': 2,
    'sterbenz': 2,
    'sterchi': 2,
    'stereo': 3,
    'stereographic': 5,
    'stereolab': 4,
    'stereomicroscope': 6,
    'stereos': 3,
    'stereotype': 4,
    'stereotyped': 4,
    'stereotypes': 4,
    'stereotypical': 6,
    'stereotyping': 5,
    'sterett': 2,
    'sterile': 2,
    'sterility': 4,
    'sterilization': 5,
    'sterilizations': 5,
    'sterilize': 3,
    'sterilized': 3,
    'sterilizer': 4,
    'sterilizers': 4,
    'sterilizes': 4,
    'sterilizing': 4,
    'sterk': 1,
    'sterkel': 2,
    'sterle': 2,
    'sterling': 2,
    'sterman': 2,
    'stermer': 2,
    'stern': 1,
    'sternbach': 2,
    'sternberg': 2,
    'sternberger': 3,
    'sterne': 1,
    'sternem': 2,
    'sterner': 2,
    'sternest': 2,
    'sternfeld': 2,
    'sternhagen': 3,
    'sternly': 2,
    'sternness': 2,
    'sternpost': 2,
    'sternposts': 2,
    'sterns': 1,
    'steroid': 2,
    'steroids': 2,
    'sterr': 1,
    'sterrett': 2,
    'sterry': 2,
    'stet': 1,
    'stethem': 2,
    'stethoscope': 3,
    'stethoscopes': 3,
    'stetler': 2,
    'stetson': 2,
    'stetter': 2,
    'stettler': 2,
    'stettner': 2,
    'stetz': 1,
    'stetzel': 2,
    'stetzer': 2,
    'steuart': 2,
    'steuben': 2,
    'steubenville': 3,
    'steuber': 2,
    'steuck': 1,
    'steuer': 2,
    'steuerwald': 3,
    'steurer': 2,
    'steuver': 2,
    'stevana': 3,
    'steve': 1,
    'stevedore': 3,
    'stevedoring': 4,
    'steven': 2,
    'stevena': 3,
    'stevens': 2,
    'stevenson': 3,
    'stever': 2,
    'steverson': 3,
    'steves': 1,
    'steveson': 3,
    'stevick': 2,
    'stevie': 2,
    'stevison': 3,
    'stew': 1,
    'steward': 2,
    'stewardess': 3,
    'stewardesses': 4,
    'stewards': 2,
    'stewardship': 3,
    'stewart': 2,
    'stewed': 1,
    'stewing': 2,
    'stews': 1,
    'steyer': 2,
    'stibel': 2,
    'stice': 1,
    'stich': 1,
    'sticha': 2,
    'stichnoth': 2,
    'sticht': 1,
    'stichter': 2,
    'stick': 1,
    'sticka': 2,
    'stickball': 2,
    'stickel': 2,
    'stickels': 2,
    'sticker': 2,
    'stickers': 2,
    'stickier': 3,
    'stickiest': 3,
    'sticking': 2,
    'stickland': 2,
    'stickle': 2,
    'stickler': 3,
    'stickles': 2,
    'stickley': 2,
    'stickney': 2,
    'sticks': 1,
    'sticktight': 2,
    'sticktights': 2,
    'sticky': 2,
    'stidd': 1,
    'stidham': 2,
    'stiebel': 2,
    'stieber': 2,
    'stief': 1,
    'stiefel': 2,
    'stiefelhagen': 4,
    'stiefvater': 3,
    'stieg': 1,
    'stiegemeier': 3,
    'stiegler': 2,
    'stieglitz': 2,
    'stiehl': 1,
    'stiens': 1,
    'stier': 2,
    'stiers': 2,
    'stierwalt': 2,
    'stieve': 1,
    'stifel': 2,
    'stiff': 1,
    'stiffed': 1,
    'stiffel': 2,
    'stiffen': 2,
    'stiffened': 2,
    'stiffener': 3,
    'stiffening': 3,
    'stiffens': 2,
    'stiffer': 2,
    'stiffest': 2,
    'stiffler': 2,
    'stiffly': 2,
    'stiffness': 2,
    'stiffs': 1,
    'stifle': 2,
    'stifled': 2,
    'stifles': 2,
    'stifling': 2,
    'stifter': 2,
    'stig': 1,
    'stigall': 2,
    'stiger': 2,
    'stigers': 2,
    'stigler': 2,
    'stiglitz': 2,
    'stigma': 2,
    'stigmata': 3,
    'stigmatism': 4,
    'stigmatize': 3,
    'stigmatized': 3,
    'stigmatizing': 4,
    'stihl': 1,
    'stika': 2,
    'stikeleather': 4,
    'stil': 1,
    'stile': 1,
    'stiles': 1,
    'stiletto': 3,
    'stilettos': 3,
    'still': 1,
    'stillborn': 2,
    'stille': 1,
    'stilled': 1,
    'stiller': 2,
    'stilley': 2,
    'stillinger': 3,
    'stillings': 2,
    'stillion': 2,
    'stillman': 2,
    'stillness': 2,
    'stills': 1,
    'stillson': 2,
    'stillwagon': 3,
    'stillwater': 3,
    'stillwell': 2,
    'stils': 1,
    'stilson': 2,
    'stilt': 1,
    'stilted': 2,
    'stiltner': 2,
    'stilts': 1,
    'stilwell': 2,
    'stimac': 2,
    'stimmel': 2,
    'stimpert': 2,
    'stimpson': 2,
    'stimpy': 2,
    'stimson': 2,
    'stimulant': 3,
    'stimulants': 3,
    'stimulate': 3,
    'stimulated': 4,
    'stimulates': 3,
    'stimulating': 4,
    'stimulation': 4,
    'stimulative': 4,
    'stimulator': 4,
    'stimulators': 4,
    'stimuli': 3,
    'stimulus': 3,
    'stinar': 2,
    'stinchcomb': 2,
    'stinchfield': 2,
    'stine': 1,
    'stinebaugh': 3,
    'stinel': 2,
    'stineman': 2,
    'stiner': 2,
    'stines': 1,
    'sting': 1,
    'stinger': 2,
    'stingers': 2,
    'stingier': 3,
    'stinginess': 3,
    'stinging': 2,
    'stingley': 2,
    'stings': 1,
    'stingy': 2,
    'stink': 1,
    'stinkbug': 2,
    'stinkbugs': 2,
    'stinkers': 2,
    'stinking': 2,
    'stinks': 1,
    'stinky': 2,
    'stinnett': 2,
    'stinnette': 2,
    'stinson': 2,
    'stint': 1,
    'stints': 1,
    'stipanovich': 4,
    'stipe': 1,
    'stipend': 2,
    'stipends': 2,
    'stipes': 1,
    'stipp': 1,
    'stipulate': 3,
    'stipulated': 4,
    'stipulates': 3,
    'stipulating': 4,
    'stipulation': 4,
    'stipulations': 4,
    'stir': 1,
    'stires': 1,
    'stirewalt': 3,
    'stirling': 2,
    'stirn': 1,
    'stirred': 1,
    'stirring': 2,
    'stirrings': 2,
    'stirrup': 2,
    'stirrups': 2,
    'stirs': 1,
    'stitch': 1,
    'stitched': 1,
    'stitches': 2,
    'stitching': 2,
    'stiteler': 2,
    'stitely': 2,
    'stites': 1,
    'stith': 1,
    'stitt': 1,
    'stitz': 1,
    'stitzel': 2,
    'stitzer': 2,
    'stiver': 2,
    'stivers': 2,
    'stiverson': 3,
    'stivoric': 3,
    'stjohn': 2,
    'stobaugh': 2,
    'stobbe': 1,
    'stober': 2,
    'stobie': 2,
    'stochastic': 3,
    'stock': 1,
    'stockard': 2,
    'stockbridge': 2,
    'stockbroker': 3,
    'stockbrokerage': 4,
    'stockbrokerages': 5,
    'stockbrokers': 3,
    'stockbroking': 3,
    'stockburger': 3,
    'stockdale': 2,
    'stocke': 1,
    'stocked': 1,
    'stockel': 2,
    'stocker': 2,
    'stockert': 2,
    'stockett': 2,
    'stockford': 2,
    'stockham': 2,
    'stockhausen': 3,
    'stockholder': 3,
    'stockholders': 3,
    'stockholding': 3,
    'stockholdings': 3,
    'stockholm': 2,
    'stocking': 2,
    'stockinger': 3,
    'stockings': 2,
    'stockley': 2,
    'stockman': 2,
    'stockmarket': 3,
    'stockpile': 2,
    'stockpiled': 2,
    'stockpiles': 2,
    'stockpiling': 3,
    'stockroom': 2,
    'stocks': 1,
    'stocksdale': 2,
    'stockslager': 3,
    'stockstill': 2,
    'stockton': 2,
    'stockwell': 2,
    'stocky': 2,
    'stockyard': 2,
    'stockyards': 2,
    'stocum': 2,
    'stoddard': 2,
    'stodghill': 2,
    'stodgy': 2,
    'stodola': 3,
    'stodolsky': 3,
    'stoeber': 2,
    'stoeckel': 2,
    'stoecker': 2,
    'stoeckle': 2,
    'stoecklein': 2,
    'stoeger': 2,
    'stoehr': 1,
    'stoelting': 2,
    'stoeltze': 1,
    'stoermer': 2,
    'stoessel': 2,
    'stoever': 3,
    'stofer': 2,
    'stoff': 1,
    'stoffel': 2,
    'stoffels': 2,
    'stoffer': 2,
    'stoffers': 2,
    'stoffregen': 3,
    'stofko': 2,
    'stoga': 2,
    'stogdill': 2,
    'stogner': 2,
    'stogsdill': 2,
    'stohl': 1,
    'stohler': 2,
    'stohr': 1,
    'stoia': 2,
    'stoiber': 2,
    'stoic': 2,
    'stoically': 3,
    'stoicism': 4,
    'stoics': 2,
    'stoke': 1,
    'stoked': 1,
    'stokely': 2,
    'stoker': 2,
    'stokes': 1,
    'stoking': 2,
    'stokke': 1,
    'stokley': 2,
    'stoklosa': 3,
    'stol': 1,
    'stolar': 2,
    'stolarski': 3,
    'stolarz': 2,
    'stolberg': 2,
    'stoldt': 1,
    'stole': 1,
    'stolen': 2,
    'stoler': 2,
    'stolfi': 2,
    'stolichnaya': 4,
    'stolid': 2,
    'stoll': 1,
    'stollar': 2,
    'stolle': 1,
    'stollen': 2,
    'stoller': 2,
    'stolley': 2,
    'stollings': 2,
    'stolp': 1,
    'stolpe': 1,
    'stolper': 2,
    'stolt': 1,
    'stolte': 1,
    'stoltenberg': 3,
    'stoltman': 2,
    'stoltz': 1,
    'stoltzfus': 2,
    'stoltzman': 2,
    'stolz': 1,
    'stolze': 1,
    'stomach': 2,
    'stomachs': 2,
    'stomata': 3,
    'stombaugh': 2,
    'stomp': 1,
    'stomped': 1,
    'stomping': 2,
    'stone': 1,
    'stoneback': 2,
    'stoneberg': 2,
    'stoneberger': 3,
    'stonebraker': 4,
    'stoneburner': 3,
    'stonecipher': 3,
    'stonecutter': 3,
    'stonecutters': 3,
    'stonecypher': 4,
    'stoned': 1,
    'stoneham': 2,
    'stonehenge': 2,
    'stonehill': 2,
    'stonehocker': 3,
    'stonehouse': 2,
    'stoneking': 2,
    'stoneman': 2,
    'stoner': 2,
    'stoneridge': 2,
    'stonerock': 2,
    'stones': 1,
    'stonesifer': 3,
    'stonestreet': 2,
    'stonewall': 2,
    'stonewalled': 2,
    'stonewalling': 3,
    'stoneware': 2,
    'stoney': 2,
    'stong': 1,
    'stonge': 1,
    'stoning': 2,
    'stony': 2,
    'stonyfield': 3,
    'stood': 1,
    'stooge': 1,
    'stooges': 2,
    'stookey': 2,
    'stooksbury': 3,
    'stool': 1,
    'stools': 1,
    'stoop': 1,
    'stooped': 1,
    'stooping': 2,
    'stoops': 1,
    'stoots': 1,
    'stop': 1,
    'stopa': 2,
    'stopgap': 2,
    'stopher': 2,
    'stopka': 2,
    'stoplight': 2,
    'stoplights': 2,
    'stopover': 3,
    'stopovers': 3,
    'stoppage': 2,
    'stoppages': 3,
    'stopped': 1,
    'stoppel': 2,
    'stopper': 2,
    'stoppers': 2,
    'stopping': 2,
    'stops': 1,
    'stopwatch': 2,
    'stopwatches': 3,
    'stora': 2,
    'storage': 2,
    'storagetek': 3,
    'storaska': 3,
    'storbeck': 2,
    'storch': 1,
    'storck': 1,
    'stordahl': 2,
    'store': 1,
    'stored': 1,
    'storefront': 2,
    'storefronts': 2,
    'storehouse': 2,
    'storehouses': 3,
    'storekeeper': 3,
    'storekeepers': 3,
    'storer': 2,
    'storeroom': 2,
    'stores': 1,
    'storey': 2,
    'storfer': 2,
    'storie': 2,
    'storied': 2,
    'stories': 2,
    'storing': 2,
    'stork': 1,
    'storlie': 2,
    'storm': 1,
    'stormed': 1,
    'storment': 2,
    'stormer': 2,
    'stormes': 1,
    'stormiest': 3,
    'storminess': 3,
    'storming': 2,
    'stormont': 2,
    'storms': 1,
    'stormy': 2,
    'storr': 1,
    'storrow': 2,
    'storrs': 1,
    'storti': 2,
    'storting': 2,
    'storts': 1,
    'stortz': 1,
    'story': 2,
    'storyboard': 3,
    'storybook': 3,
    'storybooks': 3,
    'storyline': 3,
    'storylines': 3,
    'storyteller': 4,
    'storytellers': 4,
    'storytelling': 4,
    'storz': 1,
    'stossel': 2,
    'stotler': 2,
    'stott': 1,
    'stottlemyer': 4,
    'stotts': 1,
    'stotz': 1,
    'stoudemire': 3,
    'stoudenmire': 3,
    'stouder': 2,
    'stoudt': 1,
    'stouffer': 2,
    'stouffs': 1,
    'stough': 1,
    'stoughton': 2,
    'stoup': 1,
    'stout': 1,
    'stoute': 1,
    'stoutenburg': 3,
    'stoutly': 2,
    'stoutness': 2,
    'stovall': 2,
    'stove': 1,
    'stover': 2,
    'stoves': 1,
    'stow': 1,
    'stowe': 1,
    'stowed': 1,
    'stowell': 2,
    'stower': 2,
    'stowers': 2,
    'stoy': 1,
    'stoyer': 2,
    'stpierre': 2,
    'stracener': 3,
    'strachan': 2,
    'strack': 1,
    'stracke': 1,
    'strada': 2,
    'straddle': 2,
    'straddled': 2,
    'straddles': 2,
    'straddling': 2,
    'strader': 2,
    'stradford': 2,
    'stradivarius': 5,
    'stradley': 2,
    'stradling': 2,
    'strafe': 1,
    'strafing': 2,
    'straggle': 2,
    'straggled': 2,
    'straggler': 2,
    'stragglers': 2,
    'strahan': 2,
    'strahl': 1,
    'strahle': 2,
    'strahler': 2,
    'strahm': 1,
    'straight': 1,
    'straightaway': 3,
    'straightedge': 2,
    'straighten': 2,
    'straightened': 2,
    'straightening': 3,
    'straightens': 2,
    'straighter': 2,
    'straightforward': 3,
    'straightforwardly': 4,
    'straights': 1,
    'strain': 1,
    'strained': 1,
    'straining': 2,
    'strains': 1,
    'strait': 1,
    'straitjacket': 3,
    'straits': 1,
    'straka': 2,
    'straker': 2,
    'straley': 2,
    'stram': 1,
    'stranahan': 3,
    'strand': 1,
    'strandberg': 2,
    'strande': 1,
    'stranded': 2,
    'stranding': 2,
    'strandline': 2,
    'strandlines': 2,
    'strands': 1,
    'strang': 1,
    'strange': 1,
    'strangelove': 2,
    'strangely': 2,
    'strangeness': 2,
    'stranger': 2,
    'strangers': 2,
    'strangest': 2,
    'strangis': 2,
    'strangle': 2,
    'strangled': 2,
    'stranglehold': 3,
    'strangler': 2,
    'strangling': 3,
    'strangulate': 3,
    'strangulation': 4,
    'strangulations': 4,
    'strangwayes': 2,
    'strano': 2,
    'stransky': 2,
    'strap': 1,
    'strapped': 1,
    'strapping': 2,
    'straps': 1,
    'strasberg': 2,
    'strasbourg': 2,
    'strasburg': 2,
    'strasburger': 3,
    'strassburg': 2,
    'strassburger': 3,
    'strasser': 2,
    'strassman': 2,
    'strassner': 2,
    'straszheim': 2,
    'strata': 2,
    'stratacom': 3,
    'stratagem': 3,
    'stratagems': 3,
    'stratas': 2,
    'strate': 1,
    'strategem': 3,
    'strategic': 3,
    'strategical': 4,
    'strategically': 4,
    'strategies': 3,
    'strategist': 3,
    'strategists': 3,
    'strategize': 3,
    'strategizing': 4,
    'strategy': 3,
    'strater': 2,
    'stratford': 2,
    'strathman': 2,
    'stratified': 3,
    'stratify': 3,
    'stratigraphic': 4,
    'stratman': 2,
    'stratmann': 2,
    'stratocaster': 4,
    'stratoflex': 3,
    'stratosphere': 3,
    'stratospheric': 4,
    'stratton': 2,
    'stratum': 2,
    'stratus': 2,
    'straub': 1,
    'straube': 1,
    'strauch': 1,
    'straughan': 2,
    'straughn': 1,
    'straughter': 2,
    'straum': 1,
    'straus': 1,
    'strausbaugh': 2,
    'strause': 1,
    'strauser': 2,
    'strauss': 1,
    'strausser': 2,
    'stravinsky': 3,
    'straw': 1,
    'strawberries': 3,
    'strawberry': 3,
    'strawbridge': 2,
    'strawder': 2,
    'strawderman': 3,
    'strawn': 1,
    'straws': 1,
    'strawser': 2,
    'stray': 1,
    'strayed': 1,
    'strayer': 2,
    'strayhorn': 2,
    'straying': 2,
    'strays': 1,
    'streak': 1,
    'streaked': 1,
    'streaker': 2,
    'streaking': 2,
    'streaks': 1,
    'stream': 1,
    'streamed': 1,
    'streamer': 2,
    'streamers': 2,
    'streaming': 2,
    'streamline': 2,
    'streamlined': 2,
    'streamlining': 3,
    'streams': 1,
    'streater': 2,
    'streb': 1,
    'strebe': 1,
    'strebeck': 2,
    'strebel': 2,
    'streck': 1,
    'strecker': 2,
    'streed': 1,
    'streep': 1,
    'streeper': 2,
    'street': 1,
    'streetcar': 2,
    'streeter': 2,
    'streeters': 2,
    'streetman': 2,
    'streets': 1,
    'streett': 1,
    'streetwise': 2,
    'streety': 2,
    'streff': 1,
    'strege': 1,
    'strehl': 1,
    'strehle': 1,
    'strehlow': 2,
    'streib': 1,
    'streiber': 2,
    'streich': 1,
    'streicher': 2,
    'streiff': 1,
    'streight': 1,
    'streiker': 2,
    'streisand': 2,
    'streit': 1,
    'streitmatter': 3,
    'strelow': 2,
    'streng': 1,
    'strenger': 2,
    'strength': 1,
    'strengthen': 2,
    'strengthened': 2,
    'strengthening': 3,
    'strengthens': 2,
    'strengths': 1,
    'strenio': 3,
    'strenuous': 3,
    'strenuously': 4,
    'strep': 1,
    'streps': 1,
    'streptococcus': 4,
    'streptokinase': 4,
    'stress': 1,
    'stressed': 1,
    'stresses': 2,
    'stressful': 2,
    'stressing': 2,
    'stressor': 2,
    'stressors': 2,
    'stretch': 1,
    'stretched': 1,
    'stretcher': 2,
    'stretchers': 2,
    'stretches': 2,
    'stretching': 2,
    'stretto': 2,
    'strevig': 2,
    'strew': 1,
    'strewn': 1,
    'strey': 1,
    'striar': 1,
    'stribling': 4,
    'strick': 1,
    'stricken': 2,
    'stricker': 2,
    'strickland': 2,
    'stricklen': 3,
    'strickler': 2,
    'stricklin': 2,
    'strickling': 2,
    'strict': 1,
    'stricter': 2,
    'strictest': 2,
    'strictly': 2,
    'stricture': 2,
    'strictures': 2,
    'stride': 1,
    'stridency': 3,
    'strident': 2,
    'stridently': 3,
    'strider': 2,
    'strides': 1,
    'striding': 2,
    'strieber': 2,
    'striegel': 2,
    'strieker': 2,
    'strieter': 2,
    'strife': 1,
    'strifes': 1,
    'strike': 1,
    'strikebreaker': 3,
    'strikebreakers': 3,
    'strikeout': 2,
    'strikeouts': 2,
    'striker': 2,
    'strikers': 2,
    'strikes': 1,
    'striking': 2,
    'strikingly': 3,
    'strimple': 2,
    'strinden': 2,
    'strine': 1,
    'string': 1,
    'stringed': 1,
    'stringency': 3,
    'stringent': 2,
    'stringently': 3,
    'stringer': 2,
    'stringers': 2,
    'stringfellow': 3,
    'stringfield': 2,
    'stringham': 2,
    'stringing': 2,
    'strings': 1,
    'stringy': 2,
    'strip': 1,
    'stripe': 1,
    'striped': 1,
    'striper': 2,
    'stripers': 2,
    'stripes': 1,
    'striplin': 2,
    'stripling': 2,
    'stripped': 1,
    'stripper': 2,
    'strippers': 2,
    'stripping': 2,
    'strips': 1,
    'striptease': 2,
    'strite': 1,
    'strittmatter': 3,
    'strive': 1,
    'striven': 2,
    'strives': 1,
    'striving': 2,
    'strivings': 2,
    'strnad': 2,
    'strobe': 1,
    'strobel': 2,
    'strober': 2,
    'strobl': 2,
    'stroble': 2,
    'strobridge': 2,
    'strock': 1,
    'strode': 1,
    'stroder': 2,
    'stroebel': 2,
    'stroessner': 2,
    'stroganoff': 3,
    'stroh': 1,
    'strohecker': 3,
    'strohl': 1,
    'strohm': 1,
    'strohmaier': 3,
    'strohman': 2,
    'strohmeier': 3,
    'strohmeyer': 3,
    'stroik': 1,
    'strojny': 2,
    'stroke': 1,
    'stroked': 1,
    'strokes': 1,
    'stroking': 2,
    'strole': 1,
    'stroll': 1,
    'strolled': 1,
    'stroller': 2,
    'strollers': 2,
    'strolling': 2,
    'strollo': 2,
    'strolls': 1,
    'strom': 1,
    'stroma': 2,
    'stromain': 2,
    'stroman': 2,
    'strombeck': 2,
    'stromberg': 2,
    'stromboli': 3,
    'strome': 1,
    'stromer': 2,
    'stromgren': 2,
    'stromme': 1,
    'strommen': 2,
    'stromquist': 2,
    'stronach': 2,
    'strong': 1,
    'stronger': 2,
    'strongest': 2,
    'stronghold': 2,
    'strongholds': 2,
    'strongly': 2,
    'strongman': 2,
    'strontium': 3,
    'stroock': 1,
    'stroop': 1,
    'stroope': 1,
    'stroot': 1,
    'strope': 1,
    'stroschein': 2,
    'strosnider': 3,
    'strossen': 2,
    'strother': 2,
    'strothers': 2,
    'strothman': 2,
    'stroud': 1,
    'strough': 1,
    'stroup': 1,
    'stroupe': 1,
    'strouse': 1,
    'strout': 1,
    'strouth': 1,
    'strove': 1,
    'strow': 1,
    'strozier': 3,
    'strub': 1,
    'strubbe': 1,
    'strube': 1,
    'strubel': 2,
    'struble': 2,
    'struchen': 2,
    'struck': 1,
    'struckman': 2,
    'structural': 3,
    'structurally': 4,
    'structure': 2,
    'structured': 2,
    'structures': 2,
    'structuring': 3,
    'struebing': 2,
    'struggle': 2,
    'struggled': 2,
    'struggles': 2,
    'struggling': 3,
    'strum': 1,
    'strumming': 2,
    'strums': 1,
    'strung': 1,
    'strunk': 1,
    'strupp': 1,
    'struss': 1,
    'strut': 1,
    'struthers': 2,
    'struts': 1,
    'strutting': 2,
    'strutton': 2,
    'strutz': 1,
    'struve': 1,
    'strycharz': 2,
    'strychnine': 2,
    'stryker': 2,
    'strzelecki': 4,
    'stu': 1,
    'stuard': 2,
    'stuart': 2,
    'stuarts': 2,
    'stub': 1,
    'stubbe': 1,
    'stubbed': 1,
    'stubbins': 2,
    'stubble': 2,
    'stubblefield': 3,
    'stubborn': 2,
    'stubbornly': 3,
    'stubbornness': 3,
    'stubbs': 1,
    'stubby': 2,
    'stuber': 2,
    'stubs': 1,
    'stucco': 2,
    'stuccoed': 2,
    'stuchell': 2,
    'stuck': 1,
    'stucke': 1,
    'stucker': 2,
    'stuckert': 2,
    'stuckey': 2,
    'stucki': 2,
    'stuckman': 2,
    'stucky': 2,
    'stud': 1,
    'studdard': 2,
    'studded': 2,
    'studds': 1,
    'stude': 1,
    'studebaker': 4,
    'studeman': 2,
    'student': 2,
    'students': 2,
    'studer': 2,
    'studied': 2,
    'studies': 2,
    'studio': 3,
    'studios': 3,
    'studious': 3,
    'studiously': 4,
    'studley': 2,
    'studnicka': 3,
    'studs': 1,
    'studstill': 2,
    'studt': 1,
    'studtgard': 2,
    'study': 2,
    'studying': 3,
    'studzinski': 3,
    'stuebe': 1,
    'stueber': 2,
    'stueck': 1,
    'stueve': 1,
    'stuewe': 1,
    'stuff': 1,
    'stuffed': 1,
    'stuffer': 2,
    'stuffing': 2,
    'stufflebeam': 3,
    'stufflebean': 3,
    'stuffs': 1,
    'stuffy': 2,
    'stuhler': 2,
    'stuhr': 1,
    'stuka': 2,
    'stukel': 2,
    'stukes': 1,
    'stukey': 2,
    'stull': 1,
    'stuller': 2,
    'stultify': 3,
    'stultifying': 4,
    'stults': 1,
    'stultz': 1,
    'stum': 1,
    'stumbaugh': 2,
    'stumble': 2,
    'stumbled': 2,
    'stumbles': 2,
    'stumbling': 3,
    'stumbo': 2,
    'stumm': 1,
    'stump': 1,
    'stumpage': 2,
    'stumpe': 1,
    'stumped': 1,
    'stumper': 2,
    'stumpf': 1,
    'stumpff': 1,
    'stumph': 1,
    'stumping': 2,
    'stumpo': 2,
    'stumpp': 1,
    'stumps': 1,
    'stun': 1,
    'stung': 1,
    'stunk': 1,
    'stunned': 1,
    'stunner': 2,
    'stunning': 2,
    'stunningly': 3,
    'stuns': 1,
    'stunt': 1,
    'stunted': 2,
    'stunts': 1,
    'stuntz': 1,
    'stupa': 2,
    'stupak': 2,
    'stupar': 2,
    'stupay': 2,
    'stupendous': 3,
    'stupid': 2,
    'stupidest': 3,
    'stupidity': 4,
    'stupidly': 3,
    'stupka': 2,
    'stupor': 2,
    'stupp': 1,
    'stupski': 2,
    'sturbridge': 2,
    'sturc': 1,
    'sturdevant': 3,
    'sturdhal': 2,
    'sturdier': 3,
    'sturdiness': 3,
    'sturdivant': 3,
    'sturdy': 2,
    'sturgell': 2,
    'sturgeon': 2,
    'sturgeons': 2,
    'sturges': 2,
    'sturgess': 2,
    'sturgill': 2,
    'sturgis': 2,
    'sturkie': 2,
    'sturm': 1,
    'sturman': 2,
    'sturmans': 2,
    'sturmer': 2,
    'sturn': 1,
    'sturrock': 2,
    'sturtevant': 3,
    'sturtz': 1,
    'sturza': 2,
    'stutes': 1,
    'stutesman': 2,
    'stuteville': 2,
    'stutheit': 2,
    'stutler': 2,
    'stutner': 2,
    'stutsman': 2,
    'stutter': 2,
    'stuttering': 3,
    'stutters': 2,
    'stuttgart': 2,
    'stutts': 1,
    'stutz': 1,
    'stutzman': 2,
    'stuve': 1,
    'stuver': 2,
    'stuyvesant': 3,
    'sty': 1,
    'styer': 2,
    'styers': 2,
    'stygian': 3,
    'style': 1,
    'styled': 1,
    'styles': 1,
    'styling': 2,
    'stylish': 2,
    'stylishly': 3,
    'stylist': 2,
    'stylistic': 3,
    'stylistically': 4,
    'stylists': 2,
    'stylites': 2,
    'stylize': 2,
    'stylized': 2,
    'stylus': 2,
    'stymie': 2,
    'stymied': 2,
    'stymies': 2,
    'styne': 1,
    'styrene': 2,
    'styrofoam': 3,
    'styron': 2,
    'stys': 1,
    'styx': 1,
    'su': 1,
    'suard': 2,
    'suarez': 2,
    'suasion': 2,
    'suata': 3,
    'suave': 1,
    'suazo': 2,
    'sub': 1,
    'suba': 2,
    'subacute': 3,
    'subandrio': 4,
    'subaru': 3,
    'subassemblies': 4,
    'subassembly': 4,
    'subatomic': 4,
    'subbed': 1,
    'subbing': 2,
    'subchapter': 3,
    'subcommander': 4,
    'subcommanders': 4,
    'subcommittee': 4,
    'subcommittees': 4,
    'subcompact': 3,
    'subcompacts': 3,
    'subconscious': 3,
    'subconsciously': 4,
    'subcontinent': 4,
    'subcontinents': 4,
    'subcontract': 3,
    'subcontracted': 4,
    'subcontracting': 4,
    'subcontractor': 4,
    'subcontractors': 4,
    'subcontracts': 3,
    'subculture': 3,
    'subcultures': 3,
    'subdivide': 3,
    'subdivided': 4,
    'subdivision': 4,
    'subdivisions': 4,
    'subdue': 2,
    'subdued': 2,
    'subduing': 3,
    'suber': 2,
    'subfamilies': 4,
    'subfamily': 4,
    'subgroup': 2,
    'subgroups': 2,
    'subhlok': 2,
    'subhuman': 3,
    'subia': 3,
    'subic': 2,
    'subject': 2,
    'subjected': 3,
    'subjecting': 3,
    'subjective': 3,
    'subjectivity': 5,
    'subjects': 2,
    'subjugate': 3,
    'subjugated': 4,
    'subkingdom': 3,
    'sublease': 2,
    'subleasing': 3,
    'sublet': 2,
    'sublett': 2,
    'sublime': 2,
    'subliminal': 4,
    'subliminally': 5,
    'subluxation': 4,
    'subluxations': 4,
    'submachine': 3,
    'submarine': 3,
    'submarines': 3,
    'submerge': 2,
    'submerged': 2,
    'submergence': 3,
    'submerse': 2,
    'submersed': 2,
    'submersible': 4,
    'submersion': 3,
    'subminimum': 4,
    'submission': 3,
    'submissions': 3,
    'submissive': 3,
    'submit': 2,
    'submits': 2,
    'submitted': 3,
    'submitting': 3,
    'subnotebook': 3,
    'subordinate': 4,
    'subordinated': 5,
    'subordinates': 4,
    'subordinating': 5,
    'subordination': 5,
    'subotnick': 3,
    'subpar': 2,
    'subplot': 2,
    'subplots': 2,
    'subpoena': 3,
    'subpoenaed': 3,
    'subpoenaing': 4,
    'subpoenas': 3,
    'subprincipal': 4,
    'subprincipals': 4,
    'subramanian': 5,
    'subroto': 3,
    'subs': 1,
    'subsaharan': 4,
    'subscribe': 2,
    'subscribed': 2,
    'subscriber': 3,
    'subscribers': 3,
    'subscribes': 2,
    'subscribing': 3,
    'subscription': 3,
    'subscriptions': 3,
    'subsection': 3,
    'subsequent': 3,
    'subsequently': 4,
    'subservience': 4,
    'subservient': 4,
    'subset': 2,
    'subside': 2,
    'subsided': 3,
    'subsidence': 3,
    'subsides': 2,
    'subsidiaries': 5,
    'subsidiary': 5,
    'subsidies': 3,
    'subsiding': 3,
    'subsidization': 5,
    'subsidize': 3,
    'subsidized': 3,
    'subsidizes': 4,
    'subsidizing': 4,
    'subsidy': 3,
    'subsist': 2,
    'subsistence': 3,
    'subsoil': 2,
    'substance': 2,
    'substances': 3,
    'substandard': 3,
    'substantial': 3,
    'substantially': 4,
    'substantiate': 4,
    'substantiated': 5,
    'substantiates': 4,
    'substantiation': 5,
    'substantive': 3,
    'substantively': 4,
    'substation': 3,
    'substitute': 3,
    'substituted': 4,
    'substitutes': 3,
    'substituting': 4,
    'substitution': 4,
    'substitutions': 4,
    'substrate': 2,
    'substrates': 2,
    'subsurface': 3,
    'subsystem': 3,
    'subsystems': 3,
    'subterfuge': 3,
    'subterranean': 5,
    'subtext': 2,
    'subtitle': 3,
    'subtitled': 3,
    'subtitles': 3,
    'subtle': 2,
    'subtler': 3,
    'subtleties': 3,
    'subtlety': 3,
    'subtly': 3,
    'subtract': 2,
    'subtracted': 3,
    'subtracting': 3,
    'subtraction': 3,
    'subtype': 2,
    'subtyping': 3,
    'suburb': 2,
    'suburban': 3,
    'suburbanite': 4,
    'suburbanites': 4,
    'suburbanization': 6,
    'suburbanize': 4,
    'suburbans': 3,
    'suburbia': 4,
    'suburbs': 2,
    'subversion': 3,
    'subversive': 3,
    'subversives': 3,
    'subvert': 2,
    'subverted': 3,
    'subverting': 3,
    'subverts': 2,
    'subvolcanic': 4,
    'subway': 2,
    'subways': 2,
    'subzero': 3,
    'suc': 1,
    'succeed': 2,
    'succeeded': 3,
    'succeeding': 3,
    'succeeds': 2,
    'success': 2,
    'successes': 3,
    'successful': 3,
    'successfully': 4,
    'succession': 3,
    'successive': 3,
    'successively': 4,
    'successor': 3,
    'successors': 3,
    'succinct': 2,
    'succinctly': 3,
    'succor': 2,
    'succulent': 3,
    'succulents': 3,
    'succumb': 2,
    'succumbed': 2,
    'succumbing': 3,
    'succumbs': 2,
    'such': 1,
    'suchan': 2,
    'suchanek': 3,
    'suchard': 2,
    'sucharski': 3,
    'suchecki': 3,
    'sucher': 2,
    'suchinda': 3,
    'suchocki': 3,
    'suchomel': 3,
    'suchy': 2,
    'suck': 1,
    'sucked': 1,
    'sucker': 2,
    'suckered': 2,
    'suckers': 2,
    'sucking': 2,
    'suckle': 2,
    'suckling': 2,
    'suckow': 2,
    'suckrow': 2,
    'sucks': 1,
    'sucralose': 3,
    'sucre': 2,
    'sucrose': 2,
    'suction': 2,
    'suda': 2,
    'sudafed': 3,
    'sudan': 2,
    'sudanese': 3,
    'sudano': 3,
    'sudbeck': 2,
    'sudberry': 3,
    'sudbury': 3,
    'sudd': 1,
    'suddam': 2,
    'suddarth': 2,
    'suddath': 2,
    'sudden': 2,
    'suddenly': 3,
    'suddenness': 3,
    'sudderth': 2,
    'suddeth': 2,
    'suddreth': 2,
    'sudduth': 2,
    'suder': 2,
    'suderman': 3,
    'sudler': 3,
    'sudol': 2,
    'suds': 1,
    'sue': 1,
    'sued': 1,
    'suede': 1,
    'suedes': 1,
    'sueker': 2,
    'suen': 1,
    'sues': 1,
    'suess': 1,
    'suey': 2,
    'suez': 2,
    'suffer': 2,
    'suffered': 2,
    'sufferer': 3,
    'sufferers': 3,
    'suffering': 3,
    'sufferings': 3,
    'suffern': 2,
    'suffers': 2,
    'suffice': 2,
    'sufficed': 2,
    'suffices': 3,
    'sufficiency': 4,
    'sufficient': 3,
    'sufficiently': 4,
    'suffield': 2,
    'suffix': 2,
    'suffocate': 3,
    'suffocated': 4,
    'suffocating': 4,
    'suffocation': 4,
    'suffolk': 2,
    'suffrage': 2,
    'suffragette': 3,
    'suffragettes': 3,
    'suffragist': 3,
    'suffragists': 3,
    'suffuse': 2,
    'suffused': 2,
    'suffuses': 3,
    'suffusing': 3,
    'sugar': 2,
    'sugarcane': 3,
    'sugared': 2,
    'sugarman': 3,
    'sugars': 2,
    'sugary': 3,
    'sugden': 2,
    'sugerman': 3,
    'sugg': 1,
    'suggest': 2,
    'suggested': 3,
    'suggestible': 4,
    'suggesting': 3,
    'suggestion': 3,
    'suggestions': 3,
    'suggestive': 3,
    'suggestiveness': 4,
    'suggests': 2,
    'suggs': 1,
    'sugihara': 4,
    'sugimoto': 4,
    'sugiyama': 4,
    'sugrue': 2,
    'suh': 1,
    'suharto': 3,
    'suhler': 2,
    'suhm': 1,
    'suhr': 1,
    'suhre': 1,
    'suhua': 2,
    'suhud': 2,
    'sui': 2,
    'suicidal': 4,
    'suicide': 3,
    'suicides': 3,
    'suing': 2,
    'suire': 1,
    'suisse': 1,
    'suit': 1,
    'suitability': 5,
    'suitable': 3,
    'suitably': 3,
    'suitcase': 2,
    'suitcases': 3,
    'suite': 1,
    'suited': 2,
    'suiter': 2,
    'suites': 1,
    'suiting': 2,
    'suitor': 2,
    'suitors': 2,
    'suits': 1,
    'suitt': 1,
    'suk': 1,
    'sukarno': 3,
    'sukey': 2,
    'sukharev': 3,
    'sukhumi': 3,
    'suki': 2,
    'sukiyaki': 4,
    'sukru': 2,
    'sukthankar': 3,
    'sukup': 2,
    'sul': 1,
    'sulak': 2,
    'sulawesi': 4,
    'sulcer': 2,
    'sulek': 2,
    'suleski': 3,
    'sulewski': 3,
    'suleyman': 3,
    'sulfa': 2,
    'sulfate': 2,
    'sulfide': 2,
    'sulfite': 2,
    'sulfites': 2,
    'sulfur': 2,
    'sulfuric': 3,
    'sulfurophane': 4,
    'sulgrave': 2,
    'sulik': 2,
    'sulk': 1,
    'sulked': 1,
    'sulking': 2,
    'sulkowski': 3,
    'sullen': 2,
    'sullenberger': 4,
    'sullenger': 3,
    'sullens': 2,
    'sullie': 2,
    'sullied': 2,
    'sullinger': 3,
    'sullins': 2,
    'sullivan': 3,
    'sullivans': 3,
    'sullivant': 3,
    'sullo': 2,
    'sully': 2,
    'sulpetro': 3,
    'sulphate': 2,
    'sulphates': 2,
    'sulphur': 2,
    'sulser': 2,
    'sult': 1,
    'sultan': 2,
    'sultanate': 3,
    'sultans': 2,
    'sulton': 2,
    'sultry': 2,
    'sulya': 2,
    'sulzbach': 2,
    'sulzberger': 3,
    'sulzer': 2,
    'sum': 1,
    'suma': 2,
    'sumac': 2,
    'suman': 2,
    'sumarlin': 3,
    'sumatoma': 4,
    'sumatomo': 4,
    'sumatra': 3,
    'sumatran': 3,
    'sumeria': 4,
    'sumerlin': 3,
    'sumgait': 2,
    'sumida': 3,
    'suminski': 3,
    'sumita': 3,
    'sumitomo': 4,
    'sumler': 2,
    'sumlin': 2,
    'summa': 2,
    'summagraphic': 4,
    'summagraphics': 4,
    'summar': 2,
    'summaries': 3,
    'summarily': 4,
    'summarize': 3,
    'summarized': 3,
    'summarizes': 4,
    'summarizing': 4,
    'summary': 3,
    'summation': 3,
    'summations': 3,
    'summcorp': 2,
    'summed': 1,
    'summer': 2,
    'summerall': 3,
    'summerfield': 3,
    'summerford': 3,
    'summerhill': 3,
    'summerlin': 3,
    'summerour': 3,
    'summers': 2,
    'summerson': 3,
    'summertime': 3,
    'summerville': 3,
    'summey': 2,
    'summing': 2,
    'summit': 2,
    'summiteer': 3,
    'summiteers': 3,
    'summitry': 3,
    'summits': 2,
    'summitt': 2,
    'summitville': 3,
    'summon': 2,
    'summoned': 2,
    'summoning': 3,
    'summons': 2,
    'summonsed': 2,
    'summonses': 3,
    'summum': 2,
    'summy': 2,
    'sumner': 2,
    'sumners': 2,
    'sumo': 2,
    'sump': 1,
    'sumpter': 2,
    'sumptuous': 2,
    'sumrall': 2,
    'sumrell': 2,
    'sumrow': 2,
    'sums': 1,
    'sumter': 2,
    'sun': 1,
    'sunamerica': 5,
    'sunau': 2,
    'sunbath': 2,
    'sunbathe': 2,
    'sunbathing': 3,
    'sunbeam': 2,
    'sunbelt': 2,
    'sunbird': 2,
    'sunbirds': 2,
    'sunburn': 2,
    'sunburned': 2,
    'suncoast': 2,
    'suncook': 2,
    'suncor': 2,
    'sund': 1,
    'sundae': 2,
    'sundahl': 2,
    'sundai': 2,
    'sundance': 2,
    'sundar': 2,
    'sundararajan': 5,
    'sunday': 2,
    'sundays': 2,
    'sundberg': 2,
    'sundby': 2,
    'sunde': 1,
    'sundeen': 2,
    'sundell': 2,
    'sunder': 2,
    'sunderland': 3,
    'sunderlin': 3,
    'sunderman': 3,
    'sundermeyer': 4,
    'sundews': 2,
    'sundheim': 2,
    'sundial': 2,
    'sundin': 2,
    'sundlun': 2,
    'sundown': 2,
    'sundquist': 2,
    'sundry': 2,
    'sundstrand': 2,
    'sundstrom': 2,
    'sundt': 1,
    'sundy': 2,
    'sunfire': 2,
    'sunfish': 2,
    'sunflower': 3,
    'sunflowers': 3,
    'sung': 1,
    'sungard': 2,
    'sunglass': 2,
    'sunglasses': 3,
    'sungroup': 2,
    'suni': 2,
    'sunia': 3,
    'suniga': 3,
    'sunil': 2,
    'sunk': 1,
    'sunken': 2,
    'sunkist': 2,
    'sunland': 2,
    'sunlight': 2,
    'sunlit': 2,
    'sunlite': 2,
    'sunni': 2,
    'sunning': 2,
    'sunnis': 2,
    'sunny': 2,
    'sunnyside': 3,
    'sunnyvale': 3,
    'sunobe': 3,
    'sunoco': 3,
    'sunpoint': 2,
    'sunrise': 2,
    'sunroof': 2,
    'sunroom': 2,
    'suns': 1,
    'sunscreen': 2,
    'sunscreens': 2,
    'sunseri': 3,
    'sunset': 2,
    'sunsets': 2,
    'sunshine': 2,
    'sunshiny': 3,
    'sunspot': 2,
    'sunspots': 2,
    'sunstar': 2,
    'sunstate': 2,
    'sunstates': 2,
    'sunsweet': 2,
    'suntan': 2,
    'sunter': 2,
    'suntory': 3,
    'suntrust': 2,
    'sununu': 3,
    'sunup': 2,
    'sunward': 2,
    'sunworld': 2,
    'suny': 2,
    'suon': 2,
    'sup': 1,
    'supak': 2,
    'supan': 2,
    'super': 2,
    'superabrasive': 5,
    'superabrasives': 5,
    'superamerica': 6,
    'superb': 2,
    'superbar': 3,
    'superbly': 3,
    'superbowl': 3,
    'superbowls': 3,
    'supercalifragilistic': 8,
    'supercalifragilisticexpealidoshus': 14,
    'supercenter': 4,
    'supercenters': 4,
    'supercharge': 3,
    'supercharged': 3,
    'supercilious': 5,
    'supercollider': 5,
    'supercomputer': 5,
    'supercomputers': 5,
    'supercomputing': 5,
    'superconducting': 5,
    'superconductive': 5,
    'superconductivity': 7,
    'superconductor': 5,
    'superconductors': 5,
    'supercool': 3,
    'supercooled': 3,
    'supercut': 3,
    'supercuts': 3,
    'superdelegate': 5,
    'superdelegates': 5,
    'superdome': 3,
    'superdot': 3,
    'superdrug': 3,
    'superfamily': 4,
    'superfan': 3,
    'superfast': 3,
    'superficial': 4,
    'superficially': 5,
    'superfluidity': 6,
    'superfluous': 3,
    'superfon': 3,
    'superfreighter': 4,
    'superfreighters': 4,
    'superfund': 3,
    'supergiant': 4,
    'supergiants': 4,
    'superheated': 4,
    'superhero': 4,
    'superheroes': 4,
    'superheterodyne': 6,
    'superhighway': 4,
    'superhighways': 4,
    'superhuman': 4,
    'superimpose': 4,
    'superimposed': 4,
    'superintendant': 5,
    'superintendent': 5,
    'superintendents': 5,
    'superior': 4,
    'superiority': 6,
    'superiors': 4,
    'superlative': 4,
    'superlatives': 4,
    'supermac': 3,
    'supermajority': 6,
    'superman': 3,
    'supermarket': 4,
    'supermarkets': 4,
    'superminicomputer': 7,
    'superminicomputers': 7,
    'supermodel': 4,
    'supermodels': 4,
    'supernatural': 5,
    'supernaturalism': 7,
    'supernova': 4,
    'superoxide': 4,
    'superpower': 4,
    'superpowers': 4,
    'superpremium': 5,
    'superregional': 5,
    'superregionals': 5,
    'supers': 2,
    'supersaver': 4,
    'supersecret': 4,
    'supersede': 3,
    'superseded': 4,
    'supersedes': 3,
    'superseding': 4,
    'supersonic': 4,
    'superstar': 3,
    'superstars': 3,
    'superstation': 4,
    'superstition': 4,
    'superstitions': 4,
    'superstitious': 4,
    'superstore': 3,
    'superstores': 3,
    'superstructure': 4,
    'superstructures': 4,
    'supertanker': 4,
    'supervalu': 4,
    'supervise': 3,
    'supervised': 3,
    'supervises': 4,
    'supervising': 4,
    'supervision': 4,
    'supervisor': 4,
    'supervisors': 4,
    'supervisory': 5,
    'supervoting': 4,
    'superwoman': 4,
    'superwomen': 4,
    'superx': 3,
    'supine': 2,
    'supinski': 3,
    'suppa': 2,
    'supper': 2,
    'suppers': 2,
    'suppes': 1,
    'supplant': 2,
    'supplanted': 3,
    'supplanting': 3,
    'supplants': 2,
    'supple': 2,
    'supplee': 2,
    'supplement': 3,
    'supplemental': 4,
    'supplementary': 5,
    'supplemented': 4,
    'supplementing': 4,
    'supplements': 3,
    'supplicant': 3,
    'supplied': 2,
    'supplier': 3,
    'suppliers': 3,
    'supplies': 2,
    'supply': 2,
    'supplying': 3,
    'support': 2,
    'supportable': 4,
    'supported': 3,
    'supporter': 3,
    'supporters': 3,
    'supporting': 3,
    'supportive': 3,
    'supports': 2,
    'suppose': 2,
    'supposed': 2,
    'supposedly': 4,
    'supposes': 3,
    'supposing': 3,
    'supposition': 4,
    'suppositions': 4,
    'suppress': 2,
    'suppressant': 3,
    'suppressants': 3,
    'suppressed': 2,
    'suppresses': 3,
    'suppressing': 3,
    'suppression': 3,
    'suppressor': 3,
    'supra': 2,
    'supranational': 5,
    'supremacist': 4,
    'supremacists': 4,
    'supremacy': 4,
    'supreme': 2,
    'supremely': 4,
    'supremes': 2,
    'suprenant': 3,
    'suprisingly': 4,
    'sur': 1,
    'sura': 2,
    'surace': 3,
    'surat': 2,
    'surbaugh': 2,
    'surber': 2,
    'surcharge': 2,
    'surcharges': 3,
    'sure': 1,
    'surely': 2,
    'suren': 2,
    'surer': 2,
    'sures': 1,
    'suresh': 2,
    'surest': 2,
    'surette': 2,
    'surety': 3,
    'surf': 1,
    'surface': 2,
    'surfaced': 2,
    'surfaceness': 3,
    'surfaces': 3,
    'surfacing': 3,
    'surfactant': 3,
    'surfboard': 2,
    'surfboards': 2,
    'surfed': 1,
    'surfeit': 2,
    'surfer': 2,
    'surfers': 2,
    'surfing': 2,
    'surge': 1,
    'surged': 1,
    'surgeon': 2,
    'surgeons': 2,
    'surgeries': 3,
    'surgery': 3,
    'surges': 2,
    'surgical': 3,
    'surgically': 4,
    'surging': 2,
    'suriano': 4,
    'surinam': 3,
    'suriname': 3,
    'surles': 2,
    'surly': 2,
    'surma': 2,
    'surman': 2,
    'surmise': 2,
    'surmised': 2,
    'surmises': 3,
    'surmount': 2,
    'surmounted': 3,
    'surmounting': 3,
    'surname': 2,
    'surnames': 2,
    'surowiec': 3,
    'surpass': 2,
    'surpassed': 2,
    'surpasses': 3,
    'surpassing': 3,
    'surplus': 2,
    'surpluses': 3,
    'surprenant': 3,
    'surprise': 2,
    'surprised': 2,
    'surprises': 3,
    'surprising': 3,
    'surprisingly': 4,
    'surratt': 2,
    'surreal': 2,
    'surrealism': 4,
    'surrealisms': 4,
    'surrealistic': 4,
    'surrebuttal': 4,
    'surrell': 2,
    'surrency': 3,
    'surrender': 3,
    'surrendered': 3,
    'surrendering': 4,
    'surrenders': 3,
    'surreptitious': 4,
    'surreptitiously': 5,
    'surrett': 2,
    'surrette': 2,
    'surrey': 2,
    'surrogacy': 4,
    'surrogate': 3,
    'surrogates': 3,
    'surrogation': 4,
    'surround': 2,
    'surrounded': 3,
    'surrounding': 3,
    'surroundings': 3,
    'surrounds': 2,
    'surry': 2,
    'surtax': 2,
    'surtaxes': 3,
    'surveil': 2,
    'surveillance': 3,
    'surveilling': 3,
    'survey': 2,
    'surveyed': 2,
    'surveying': 3,
    'surveyor': 3,
    'surveyors': 3,
    'surveys': 2,
    'survivability': 6,
    'survivable': 4,
    'survival': 3,
    'survivalist': 4,
    'survivalists': 4,
    'survive': 2,
    'survived': 2,
    'survives': 2,
    'surviving': 3,
    'survivor': 3,
    'survivors': 3,
    'sus': 1,
    'susa': 2,
    'susan': 2,
    'susana': 3,
    'susann': 2,
    'susanna': 3,
    'susannah': 3,
    'susanne': 2,
    'susceptibility': 6,
    'susceptible': 4,
    'susette': 2,
    'sushi': 2,
    'susi': 2,
    'susie': 2,
    'suski': 2,
    'suskind': 2,
    'susko': 2,
    'susman': 2,
    'susong': 2,
    'suspect': 2,
    'suspected': 3,
    'suspecting': 3,
    'suspects': 2,
    'suspend': 2,
    'suspended': 3,
    'suspender': 3,
    'suspenders': 3,
    'suspending': 3,
    'suspends': 2,
    'suspense': 2,
    'suspenseful': 3,
    'suspension': 3,
    'suspensions': 3,
    'suspicion': 3,
    'suspicions': 3,
    'suspicious': 3,
    'suspiciously': 4,
    'susquehanna': 4,
    'suss': 1,
    'susser': 2,
    'sussex': 2,
    'susskind': 2,
    'sussman': 2,
    'susswein': 2,
    'sustain': 2,
    'sustainability': 6,
    'sustainable': 4,
    'sustained': 2,
    'sustaining': 3,
    'sustains': 2,
    'sustaita': 3,
    'sustenance': 3,
    'susteren': 3,
    'susumu': 3,
    'susy': 2,
    'sutch': 1,
    'sutcliff': 2,
    'sutcliffe': 2,
    'suter': 2,
    'sutera': 3,
    'sutfin': 2,
    'suthard': 2,
    'suther': 2,
    'sutherland': 3,
    'sutherlin': 3,
    'suthers': 2,
    'sutley': 2,
    'sutliff': 2,
    'sutner': 2,
    'sutnick': 2,
    'suto': 2,
    'sutor': 2,
    'sutphen': 2,
    'sutphin': 2,
    'sutro': 2,
    'sutter': 2,
    'suttle': 2,
    'suttles': 2,
    'suttmeier': 2,
    'sutton': 2,
    'suture': 2,
    'sutured': 2,
    'sutures': 2,
    'suturing': 3,
    'suu': 3,
    'suva': 2,
    'suydam': 2,
    'suzanna': 3,
    'suzanne': 2,
    'suzette': 2,
    'suzhou': 2,
    'suzie': 2,
    'suzman': 2,
    'suzuana': 4,
    'suzuki': 3,
    'suzy': 2,
    'svec': 1,
    'svehla': 2,
    'svelte': 1,
    'sven': 1,
    'svendsen': 2,
    'svensk': 1,
    'svenska': 2,
    'svenson': 2,
    'svensson': 2,
    'sverdlovsk': 2,
    'sverige': 2,
    'svetlana': 3,
    'svetlik': 2,
    'svitak': 2,
    'svizzera': 3,
    'svoboda': 3,
    'svoray': 2,
    'swab': 1,
    'swabs': 1,
    'swaby': 2,
    'swackhamer': 3,
    'swader': 2,
    'swadley': 2,
    'swafford': 2,
    'swager': 2,
    'swagerty': 3,
    'swaggart': 2,
    'swagger': 2,
    'swaggering': 3,
    'swaggerty': 3,
    'swahili': 3,
    'swailes': 1,
    'swails': 1,
    'swaim': 1,
    'swain': 1,
    'swaine': 1,
    'swainston': 2,
    'swales': 1,
    'swalley': 2,
    'swallow': 2,
    'swallowed': 2,
    'swallowing': 3,
    'swallows': 2,
    'swam': 1,
    'swami': 2,
    'swaminathan': 4,
    'swamp': 1,
    'swampbuster': 3,
    'swamped': 1,
    'swamper': 2,
    'swampers': 2,
    'swamping': 2,
    'swamps': 1,
    'swampy': 2,
    'swan': 1,
    'swanberg': 2,
    'swanda': 2,
    'swander': 2,
    'swanee': 2,
    'swaner': 2,
    'swaney': 2,
    'swanger': 2,
    'swango': 2,
    'swanigan': 3,
    'swank': 1,
    'swanke': 1,
    'swanky': 2,
    'swann': 1,
    'swanner': 2,
    'swans': 1,
    'swanson': 2,
    'swanstrom': 2,
    'swantek': 2,
    'swanton': 2,
    'swanzy': 2,
    'swap': 1,
    'swape': 1,
    'swapes': 1,
    'swapo': 2,
    'swapp': 1,
    'swapped': 1,
    'swapping': 2,
    'swaps': 1,
    'sward': 1,
    'swaringen': 3,
    'swarm': 1,
    'swarmed': 1,
    'swarming': 2,
    'swarms': 1,
    'swarner': 2,
    'swarovski': 3,
    'swart': 1,
    'swarthmore': 2,
    'swarthout': 2,
    'swarthy': 2,
    'swartley': 2,
    'swartout': 2,
    'swarts': 1,
    'swartwood': 2,
    'swartwout': 2,
    'swartz': 1,
    'swartzbaugh': 2,
    'swartzendruber': 4,
    'swartzentruber': 4,
    'swartzlander': 3,
    'swartzwelder': 3,
    'swasey': 2,
    'swashbuckling': 3,
    'swastika': 3,
    'swastikas': 3,
    'swat': 1,
    'swatch': 1,
    'swatched': 1,
    'swatches': 2,
    'swatching': 2,
    'swatek': 2,
    'swath': 1,
    'swathe': 1,
    'swathed': 1,
    'swatow': 2,
    'swatted': 2,
    'swatzell': 2,
    'swauger': 2,
    'swavely': 2,
    'sway': 1,
    'swayed': 1,
    'swaying': 2,
    'swayne': 1,
    'sways': 1,
    'swayze': 1,
    'swazi': 2,
    'swaziland': 3,
    'sweaney': 2,
    'sweany': 2,
    'swear': 1,
    'swearengen': 3,
    'swearengin': 3,
    'swearing': 2,
    'swearingen': 3,
    'swearingin': 3,
    'swears': 1,
    'sweat': 1,
    'sweated': 2,
    'sweater': 2,
    'sweaters': 2,
    'sweating': 2,
    'sweatman': 2,
    'sweatpants': 2,
    'sweats': 1,
    'sweatshirt': 2,
    'sweatshirts': 2,
    'sweatshop': 2,
    'sweatshops': 2,
    'sweatsuit': 2,
    'sweatt': 1,
    'sweaty': 2,
    'sweazy': 2,
    'swecker': 2,
    'swed': 1,
    'sweda': 2,
    'swedberg': 2,
    'swede': 1,
    'sweden': 2,
    'swedes': 1,
    'swedish': 2,
    'swedlund': 2,
    'sweeden': 2,
    'sween': 1,
    'sweeney': 2,
    'sweeny': 2,
    'sweep': 1,
    'sweeper': 2,
    'sweepers': 2,
    'sweeping': 2,
    'sweeps': 1,
    'sweepstake': 2,
    'sweepstakes': 2,
    'sweers': 1,
    'sweet': 1,
    'sweeten': 2,
    'sweetened': 2,
    'sweetener': 3,
    'sweeteners': 3,
    'sweetening': 3,
    'sweetens': 2,
    'sweeter': 2,
    'sweetest': 2,
    'sweetheart': 2,
    'sweethearts': 2,
    'sweetie': 2,
    'sweeting': 2,
    'sweetland': 2,
    'sweetly': 2,
    'sweetman': 2,
    'sweetness': 2,
    'sweeton': 2,
    'sweets': 1,
    'sweetser': 2,
    'sweetwater': 3,
    'sweezey': 2,
    'sweezy': 2,
    'sweger': 2,
    'swehla': 2,
    'sweig': 1,
    'sweigard': 2,
    'sweigart': 2,
    'sweigert': 2,
    'sweitzer': 2,
    'swell': 1,
    'swelled': 1,
    'swelling': 2,
    'swells': 1,
    'swelter': 2,
    'sweltered': 2,
    'sweltering': 3,
    'swelters': 2,
    'sweltry': 2,
    'swem': 1,
    'swendsen': 2,
    'swenet': 2,
    'sweney': 2,
    'swensen': 2,
    'swenson': 2,
    'swept': 1,
    'swerdlow': 2,
    'swergold': 2,
    'swerling': 2,
    'swerve': 1,
    'swerved': 1,
    'swete': 1,
    'swetland': 2,
    'swetnam': 2,
    'swett': 1,
    'swetz': 1,
    'swezey': 2,
    'swiatek': 3,
    'swiatkowski': 4,
    'swicegood': 3,
    'swick': 1,
    'swickard': 2,
    'swider': 2,
    'swiderski': 3,
    'swiech': 1,
    'swier': 2,
    'swift': 1,
    'swifter': 2,
    'swiftest': 2,
    'swiftly': 2,
    'swiftness': 2,
    'swifts': 1,
    'swig': 1,
    'swigart': 2,
    'swiger': 2,
    'swigert': 2,
    'swiggum': 2,
    'swihart': 2,
    'swill': 1,
    'swilley': 2,
    'swilling': 2,
    'swillinger': 3,
    'swim': 1,
    'swimmer': 2,
    'swimmers': 2,
    'swimming': 2,
    'swims': 1,
    'swimsuit': 2,
    'swimsuits': 2,
    'swimwear': 2,
    'swinburnes': 2,
    'swindall': 2,
    'swindell': 2,
    'swindle': 2,
    'swindled': 2,
    'swindlehurst': 3,
    'swindler': 3,
    'swindlers': 2,
    'swindles': 2,
    'swindling': 2,
    'swine': 1,
    'swineford': 3,
    'swinehart': 2,
    'swiney': 2,
    'swinford': 2,
    'swing': 1,
    'swinger': 2,
    'swingers': 2,
    'swinging': 2,
    'swingle': 2,
    'swingler': 3,
    'swingley': 2,
    'swings': 1,
    'swink': 1,
    'swinney': 2,
    'swinson': 2,
    'swint': 1,
    'swinton': 2,
    'swipe': 1,
    'swiped': 1,
    'swipes': 1,
    'swiping': 2,
    'swire': 1,
    'swirl': 1,
    'swirled': 1,
    'swirling': 2,
    'swirls': 1,
    'swirsky': 2,
    'swish': 1,
    'swished': 1,
    'swisher': 2,
    'swishes': 2,
    'swiss': 1,
    'swissair': 2,
    'swisshelm': 2,
    'swistak': 2,
    'switaj': 2,
    'switala': 3,
    'switalski': 3,
    'switch': 1,
    'switchblade': 2,
    'switchblades': 2,
    'switchboard': 2,
    'switchboards': 2,
    'switched': 1,
    'switcher': 2,
    'switchers': 2,
    'switches': 2,
    'switching': 2,
    'switzer': 2,
    'switzerland': 3,
    'swivel': 2,
    'swiveled': 2,
    'swiveling': 3,
    'swivels': 2,
    'swoboda': 3,
    'swofford': 2,
    'swogger': 2,
    'swollen': 2,
    'swonger': 2,
    'swoon': 1,
    'swooned': 1,
    'swooning': 2,
    'swoop': 1,
    'swoope': 1,
    'swooped': 1,
    'swooping': 2,
    'swoops': 1,
    'swoosh': 1,
    'swooshed': 1,
    'swope': 1,
    'swopes': 1,
    'swor': 1,
    'sword': 1,
    'swordfish': 2,
    'swordlike': 2,
    'swordplay': 2,
    'swordplays': 2,
    'swords': 1,
    'swore': 1,
    'sworn': 1,
    'swoveland': 2,
    'swoyer': 2,
    'swum': 1,
    'swung': 1,
    'swyers': 2,
    'swygert': 2,
    'swyto': 2,
    'sy': 1,
    'sybase': 2,
    'sybert': 2,
    'sybil': 2,
    'sybilla': 3,
    'sybille': 2,
    'sybron': 2,
    'sycamore': 3,
    'sycara': 3,
    'sycophantic': 4,
    'syd': 1,
    'sydell': 2,
    'syders': 2,
    'sydney': 2,
    'sydnor': 2,
    'sydow': 2,
    'syed': 1,
    'syers': 2,
    'syfert': 2,
    'sygma': 2,
    'sykes': 1,
    'sykora': 3,
    'syler': 2,
    'sylla': 2,
    'syllabic': 3,
    'syllable': 3,
    'syllables': 3,
    'syllabus': 3,
    'sylmar': 2,
    'sylph': 1,
    'sylphide': 2,
    'sylva': 2,
    'sylvain': 2,
    'sylvan': 2,
    'sylvana': 3,
    'sylvania': 4,
    'sylvanus': 3,
    'sylvest': 2,
    'sylvester': 3,
    'sylvestre': 3,
    'sylvia': 3,
    'sylvie': 2,
    'sylvio': 3,
    'sylvite': 2,
    'sym': 1,
    'symanski': 3,
    'symantec': 3,
    'symbion': 3,
    'symbiosis': 4,
    'symbiotic': 4,
    'symbol': 2,
    'symbolic': 3,
    'symbolically': 5,
    'symbolics': 3,
    'symbolism': 4,
    'symbolists': 3,
    'symbolize': 3,
    'symbolized': 3,
    'symbolizes': 4,
    'symbolizing': 4,
    'symbols': 2,
    'syme': 1,
    'symes': 1,
    'symington': 3,
    'symmes': 1,
    'symmetrical': 4,
    'symmetrically': 4,
    'symmetry': 3,
    'symmonds': 2,
    'symms': 1,
    'symon': 2,
    'symonds': 2,
    'symons': 2,
    'sympathetic': 4,
    'sympathetically': 6,
    'sympathies': 3,
    'sympathize': 3,
    'sympathized': 3,
    'sympathizer': 4,
    'sympathizers': 4,
    'sympathizes': 4,
    'sympathizing': 4,
    'sympathy': 3,
    'symphonic': 3,
    'symphonies': 3,
    'symphony': 3,
    'symposium': 4,
    'symposiums': 4,
    'sympson': 2,
    'symptom': 2,
    'symptomatic': 4,
    'symptoms': 2,
    'syms': 1,
    'symtron': 2,
    'syn': 1,
    'syna': 2,
    'synagogue': 3,
    'synagogues': 3,
    'synalloy': 3,
    'synan': 2,
    'synapse': 2,
    'synapses': 3,
    'synar': 2,
    'synbiotics': 4,
    'sync': 1,
    'synch': 1,
    'synchro': 2,
    'synchronic': 3,
    'synchronicity': 5,
    'synchronization': 5,
    'synchronize': 3,
    'synchronized': 3,
    'synchronizes': 4,
    'synchronizing': 4,
    'syncom': 2,
    'syncopate': 3,
    'syncopated': 4,
    'syncopation': 4,
    'syncor': 2,
    'synder': 2,
    'syndicate': 3,
    'syndicated': 4,
    'syndicates': 3,
    'syndicating': 4,
    'syndication': 4,
    'syndications': 4,
    'syndicator': 4,
    'syndicators': 4,
    'syndicats': 3,
    'syndrich': 2,
    'syndrome': 2,
    'syndromes': 2,
    'synergen': 3,
    'synergetic': 4,
    'synergies': 3,
    'synergism': 4,
    'synergistic': 4,
    'synergy': 3,
    'syngman': 2,
    'synhorst': 2,
    'synnott': 2,
    'synod': 2,
    'synonym': 3,
    'synonymous': 4,
    'synonymously': 5,
    'synopsis': 3,
    'synoptic': 3,
    'synoptics': 3,
    'synovus': 3,
    'syntax': 2,
    'syntech': 2,
    'syntex': 2,
    'synthesis': 3,
    'synthesize': 3,
    'synthesized': 3,
    'synthesizer': 4,
    'synthesizers': 4,
    'synthesizing': 4,
    'synthetic': 3,
    'synthetically': 4,
    'synthetics': 3,
    'syntrex': 2,
    'syp': 1,
    'sypher': 2,
    'syphers': 2,
    'syphilis': 3,
    'syphon': 2,
    'sypniewski': 4,
    'sypolt': 2,
    'syracuse': 3,
    'syrek': 2,
    'syria': 3,
    'syrian': 3,
    'syrians': 3,
    'syring': 2,
    'syringe': 2,
    'syringes': 3,
    'syron': 2,
    'syrup': 2,
    'syrups': 2,
    'sysco': 2,
    'syscon': 2,
    'system': 2,
    'systemat': 3,
    'systematic': 4,
    'systematically': 5,
    'systematics': 4,
    'systemhouse': 3,
    'systemic': 3,
    'systemically': 4,
    'systemix': 3,
    'systemone': 3,
    'systems': 2,
    'systemwide': 3,
    'systran': 2,
    'sytsma': 2,
    'syverson': 3,
    'syvertsen': 3,
    'szabo': 2,
    'szafran': 2,
    'szafranski': 3,
    'szalay': 2,
    'szalkowski': 3,
    'szanto': 2,
    'szarek': 2,
    'szatkowski': 3,
    'szczech': 1,
    'szczepaniak': 4,
    'szczepanik': 3,
    'szczepanski': 3,
    'szczerba': 2,
    'szczesniak': 3,
    'szczesny': 2,
    'szczygiel': 2,
    'sze': 1,
    'szekely': 2,
    'szeliga': 3,
    'szeto': 2,
    'szewczyk': 2,
    'szilagyi': 3,
    'szilard': 2,
    'szoke': 1,
    'szostak': 2,
    'szostek': 2,
    'szot': 1,
    'szott': 1,
    'szuba': 2,
    'szuch': 1,
    'szucs': 1,
    'szumski': 2,
    'szwed': 1,
    'szydlowski': 3,
    'szymanowski': 4,
    'szymanski': 3,
    'szymborski': 3,
    'szymczak': 2,
    't': 1,
    'ta': 1,
    'taaffe': 1,
    'tab': 1,
    'tabacalera': 5,
    'tabachneck': 3,
    'tabak': 2,
    'tabaka': 3,
    'tabar': 2,
    'tabares': 3,
    'tabasco': 3,
    'tabb': 1,
    'tabbert': 2,
    'tabbing': 2,
    'tabby': 2,
    'taber': 2,
    'tabernacle': 4,
    'tabitha': 3,
    'tablature': 3,
    'table': 2,
    'tableau': 2,
    'tableaux': 2,
    'tablecloth': 3,
    'tablecloths': 3,
    'tabled': 2,
    'tabler': 3,
    'tables': 2,
    'tablespoon': 3,
    'tablespoons': 3,
    'tablet': 2,
    'tabletop': 3,
    'tablets': 2,
    'tableware': 3,
    'tabling': 3,
    'tabloid': 2,
    'tabloidization': 5,
    'tabloids': 2,
    'tabone': 2,
    'taboo': 2,
    'taboos': 2,
    'tabor': 2,
    'taborn': 2,
    'tabron': 2,
    'tabs': 1,
    'tabuchi': 3,
    'tabulate': 3,
    'tabulated': 4,
    'tabulating': 4,
    'tabulation': 4,
    'tabulations': 4,
    'tabulature': 4,
    'tac': 1,
    'tacey': 2,
    'tacit': 2,
    'tacita': 3,
    'tacitly': 3,
    'taciturn': 3,
    'tack': 1,
    'tacke': 1,
    'tacked': 1,
    'tacker': 2,
    'tackett': 2,
    'tacking': 2,
    'tackitt': 2,
    'tackle': 2,
    'tackled': 2,
    'tackles': 2,
    'tackling': 2,
    'tacks': 1,
    'tacky': 2,
    'taco': 2,
    'tacoma': 3,
    'tacos': 2,
    'tact': 1,
    'tactful': 2,
    'tactfully': 3,
    'tactic': 2,
    'tactical': 3,
    'tactically': 4,
    'tactician': 3,
    'tacticians': 3,
    'tactics': 2,
    'tactile': 2,
    'tactless': 2,
    'tacy': 2,
    'tad': 1,
    'tada': 2,
    'tadashi': 3,
    'tadd': 1,
    'taddei': 2,
    'taddeo': 3,
    'taddy': 2,
    'tadeusz': 3,
    'tadic': 2,
    'tadlock': 2,
    'tadpole': 2,
    'tadpoles': 2,
    'tadros': 2,
    'tadych': 2,
    'tae': 1,
    'taegu': 2,
    'taekwondo': 3,
    'taewoo': 2,
    'tafaro': 3,
    'tafel': 2,
    'taff': 1,
    'taffe': 1,
    'taffel': 2,
    'taffeta': 3,
    'taffy': 2,
    'taflinger': 4,
    'tafolla': 3,
    'tafoya': 3,
    'taft': 1,
    'tag': 1,
    'tagalog': 3,
    'tagamet': 3,
    'taganka': 3,
    'tager': 2,
    'tagg': 1,
    'taggart': 2,
    'tagge': 1,
    'tagged': 1,
    'taggert': 2,
    'tagging': 2,
    'tagle': 2,
    'tagliaferri': 4,
    'taglieri': 3,
    'tagline': 2,
    'tagout': 2,
    'tags': 1,
    'tague': 1,
    'taher': 2,
    'tahiti': 3,
    'tahmassebi': 4,
    'tahoe': 2,
    'tai': 1,
    'taibi': 2,
    'taiko': 2,
    'tail': 1,
    'tailed': 1,
    'tailgate': 2,
    'tailhook': 2,
    'tailing': 2,
    'tailings': 2,
    'taillon': 2,
    'tailor': 2,
    'tailored': 2,
    'tailoring': 3,
    'tailors': 2,
    'tailpipe': 2,
    'tails': 1,
    'tailspin': 2,
    'taima': 2,
    'tainer': 2,
    'taing': 2,
    'taint': 1,
    'tainted': 2,
    'tainter': 2,
    'tainting': 2,
    'taints': 1,
    'taipei': 2,
    'taira': 3,
    'taisei': 2,
    'taisho': 2,
    'tait': 1,
    'taite': 1,
    'taitt': 1,
    'taiwan': 2,
    'taiwanese': 3,
    'taiyo': 2,
    'taj': 1,
    'tajik': 2,
    'tajikistan': 4,
    'tajima': 3,
    'tak': 1,
    'takach': 2,
    'takacs': 2,
    'takagi': 3,
    'takahashi': 4,
    'takaki': 3,
    'takako': 3,
    'takanashi': 4,
    'takao': 3,
    'takara': 3,
    'takasago': 4,
    'takashi': 3,
    'takashima': 4,
    'takashimaya': 5,
    'takata': 3,
    'takayama': 4,
    'take': 1,
    'takecare': 2,
    'takeda': 3,
    'takei': 2,
    'takemoto': 4,
    'takemura': 4,
    'taken': 2,
    'takeo': 3,
    'takeoff': 2,
    'takeoffs': 2,
    'takeout': 2,
    'takeover': 3,
    'takeovers': 3,
    'taker': 2,
    'takers': 2,
    'takes': 1,
    'takeshi': 3,
    'takeshima': 4,
    'takeshita': 4,
    'taketa': 3,
    'taketh': 2,
    'taketomi': 4,
    'takeuchi': 4,
    'taki': 2,
    'takihyo': 3,
    'taking': 2,
    'takings': 2,
    'taku': 2,
    'takushoku': 4,
    'tal': 1,
    'talaga': 3,
    'talal': 2,
    'talamantes': 4,
    'talamantez': 4,
    'talamo': 3,
    'talarico': 4,
    'talavera': 4,
    'talbert': 2,
    'talbot': 2,
    'talbots': 2,
    'talbott': 2,
    'talc': 1,
    'talcott': 2,
    'talcs': 1,
    'talcum': 2,
    'tale': 1,
    'talent': 2,
    'talented': 3,
    'talents': 2,
    'talerico': 4,
    'tales': 1,
    'talese': 2,
    'talford': 2,
    'taliban': 3,
    'talibans': 3,
    'taligent': 3,
    'talisman': 3,
    'talitha': 3,
    'talk': 1,
    'talkable': 3,
    'talkative': 3,
    'talkback': 2,
    'talked': 1,
    'talker': 2,
    'talkers': 2,
    'talkie': 2,
    'talkies': 2,
    'talkin': 2,
    'talking': 2,
    'talkington': 3,
    'talks': 1,
    'talky': 2,
    'tall': 1,
    'talladega': 4,
    'tallahassean': 5,
    'tallahasseans': 5,
    'tallahassee': 4,
    'tallant': 2,
    'tallarico': 4,
    'tallent': 2,
    'taller': 2,
    'tallerico': 4,
    'tallest': 2,
    'talley': 2,
    'tallgrass': 2,
    'tallie': 2,
    'tallied': 2,
    'tallies': 2,
    'tallil': 2,
    'tallmadge': 2,
    'tallman': 2,
    'tallo': 2,
    'tallon': 2,
    'tallow': 2,
    'tallula': 3,
    'tallulah': 3,
    'tally': 2,
    'tallyho': 3,
    'tallying': 3,
    'talmadge': 2,
    'talmage': 2,
    'talman': 2,
    'talmor': 2,
    'talmud': 2,
    'talon': 2,
    'talons': 2,
    'talsma': 2,
    'talton': 2,
    'taltos': 2,
    'talty': 2,
    'tam': 1,
    'tama': 2,
    'tamales': 3,
    'tamanaha': 4,
    'tamara': 3,
    'tamarac': 3,
    'tamargo': 3,
    'tamarin': 3,
    'tamarins': 3,
    'tamarisk': 3,
    'tamarisks': 3,
    'tamas': 2,
    'tamashiro': 4,
    'tamayo': 3,
    'tamblyn': 2,
    'tambo': 2,
    'tambocor': 3,
    'tambourine': 3,
    'tambrands': 2,
    'tambs': 1,
    'tamburello': 4,
    'tamburo': 3,
    'tamburri': 3,
    'tamburrino': 4,
    'tamburro': 3,
    'tame': 1,
    'tamed': 1,
    'tamer': 2,
    'tames': 1,
    'tamez': 2,
    'tami': 2,
    'tamiami': 4,
    'tamil': 2,
    'tamils': 2,
    'taming': 2,
    'tamke': 2,
    'tamm': 1,
    'tammany': 3,
    'tammaro': 3,
    'tammen': 2,
    'tammie': 2,
    'tamminga': 3,
    'tammo': 2,
    'tammy': 2,
    'tamoxifen': 4,
    'tamp': 1,
    'tampa': 2,
    'tampax': 2,
    'tamper': 2,
    'tampered': 2,
    'tampering': 3,
    'tamping': 2,
    'tamplin': 2,
    'tampon': 2,
    'tampons': 2,
    'tampopo': 3,
    'tamposi': 3,
    'tamps': 1,
    'tamraz': 2,
    'tamres': 2,
    'tamura': 3,
    'tan': 1,
    'tanabe': 3,
    'tanaka': 3,
    'tanartkit': 3,
    'tancredi': 3,
    'tancredo': 3,
    'tandem': 2,
    'tandon': 2,
    'tandy': 2,
    'tandycraft': 3,
    'tandycrafts': 3,
    'taneja': 3,
    'tanen': 2,
    'tanenbaum': 3,
    'taney': 2,
    'tang': 1,
    'tange': 1,
    'tangeman': 2,
    'tangen': 2,
    'tangent': 2,
    'tangential': 3,
    'tangentially': 4,
    'tangents': 2,
    'tanger': 2,
    'tangerine': 3,
    'tangerines': 3,
    'tangible': 3,
    'tangibles': 3,
    'tangibly': 3,
    'tangiers': 2,
    'tangle': 2,
    'tangled': 2,
    'tangles': 2,
    'tanglewood': 3,
    'tangling': 2,
    'tangney': 2,
    'tango': 2,
    'tangos': 2,
    'tangqui': 2,
    'tangredi': 3,
    'tangs': 1,
    'tanguay': 2,
    'tanguma': 3,
    'tani': 2,
    'tania': 2,
    'taniguchi': 4,
    'tanimoto': 4,
    'tanimura': 4,
    'tanis': 2,
    'tanjug': 2,
    'tank': 1,
    'tankan': 2,
    'tankard': 2,
    'tanke': 1,
    'tanked': 1,
    'tanker': 2,
    'tankers': 2,
    'tankersley': 3,
    'tanking': 2,
    'tanks': 1,
    'tanksley': 2,
    'tann': 1,
    'tannahill': 3,
    'tanned': 1,
    'tannehill': 3,
    'tannen': 2,
    'tannenbaum': 3,
    'tanner': 2,
    'tannest': 2,
    'tanney': 2,
    'tannhauser': 3,
    'tanning': 2,
    'tanqueray': 3,
    'tans': 1,
    'tansey': 2,
    'tanski': 2,
    'tansy': 2,
    'tant': 1,
    'tantalize': 3,
    'tantalized': 3,
    'tantalizes': 4,
    'tantalizing': 4,
    'tantalizingly': 5,
    'tantalum': 3,
    'tantalus': 3,
    'tantamount': 3,
    'tantillo': 3,
    'tanton': 2,
    'tantric': 2,
    'tantrum': 2,
    'tantrums': 2,
    'tanu': 2,
    'tanya': 2,
    'tanzania': 4,
    'tanzanian': 4,
    'tanzer': 2,
    'tanzi': 2,
    'tao': 1,
    'taoism': 3,
    'taoist': 2,
    'taoists': 2,
    'taormina': 4,
    'taos': 1,
    'tap': 1,
    'tapas': 2,
    'tape': 1,
    'taped': 1,
    'tapeie': 3,
    'taper': 2,
    'tapered': 2,
    'tapering': 3,
    'tapers': 2,
    'tapes': 1,
    'tapestries': 3,
    'tapestry': 3,
    'tapia': 3,
    'tapias': 3,
    'tapie': 2,
    'taping': 2,
    'tapings': 2,
    'tapley': 2,
    'taplin': 2,
    'tapp': 1,
    'tappan': 2,
    'tappe': 1,
    'tapped': 1,
    'tappen': 2,
    'tapper': 2,
    'tappet': 2,
    'tappin': 2,
    'tapping': 2,
    'taps': 1,
    'tapscott': 2,
    'tar': 1,
    'tara': 2,
    'tarahumara': 5,
    'tarallo': 3,
    'tarango': 3,
    'tarantino': 4,
    'taranto': 3,
    'tarantula': 4,
    'tarantulas': 4,
    'taras': 2,
    'tarasoff': 3,
    'taravella': 4,
    'tarbell': 2,
    'tarbert': 2,
    'tarbet': 2,
    'tarbox': 2,
    'tarbutton': 3,
    'tardif': 2,
    'tardiff': 2,
    'tardiness': 3,
    'tarditi': 3,
    'tardy': 2,
    'target': 2,
    'targeted': 3,
    'targeting': 3,
    'targets': 2,
    'tarheel': 2,
    'tarheels': 2,
    'tariff': 2,
    'tariffs': 2,
    'tarin': 2,
    'tariq': 2,
    'tarkington': 3,
    'tarkowski': 3,
    'tarleton': 2,
    'tarlow': 2,
    'tarlton': 2,
    'tarmac': 2,
    'tarmacs': 2,
    'tarman': 2,
    'tarnish': 2,
    'tarnished': 2,
    'tarnishing': 3,
    'tarnoff': 2,
    'tarnow': 2,
    'tarnower': 3,
    'tarnowski': 3,
    'taro': 2,
    'tarom': 2,
    'tarot': 2,
    'tarots': 2,
    'tarp': 1,
    'tarpey': 2,
    'tarpley': 2,
    'tarpon': 2,
    'tarps': 1,
    'tarpy': 2,
    'tarquinio': 4,
    'tarr': 1,
    'tarrance': 2,
    'tarrant': 2,
    'tarred': 1,
    'tarring': 2,
    'tarry': 2,
    'tarrytown': 3,
    'tarses': 2,
    'tarsus': 2,
    'tart': 1,
    'tartaglia': 4,
    'tartaglione': 5,
    'tartan': 2,
    'tartar': 2,
    'tartars': 2,
    'tarte': 1,
    'tarter': 2,
    'tartikoff': 3,
    'tartikoffs': 3,
    'tartly': 2,
    'tartness': 2,
    'tarts': 1,
    'tartt': 1,
    'tarver': 2,
    'tarvin': 2,
    'tarzan': 2,
    'tasaday': 3,
    'tasca': 2,
    'tasch': 1,
    'taschner': 2,
    'taser': 2,
    'tash': 1,
    'tasha': 2,
    'tashin': 2,
    'tashiro': 3,
    'tashjian': 3,
    'tashkent': 2,
    'task': 1,
    'tasked': 1,
    'tasker': 2,
    'taskforce': 2,
    'tasking': 2,
    'taskmaster': 3,
    'tasks': 1,
    'tasm': 2,
    'tasman': 2,
    'tasmania': 4,
    'tasmanian': 4,
    'tass': 1,
    'tassel': 2,
    'tasseled': 2,
    'tassi': 2,
    'tassin': 2,
    'tassinari': 4,
    'tasso': 2,
    'tassone': 3,
    'taste': 1,
    'tasted': 2,
    'tasteful': 2,
    'tastefully': 3,
    'tasteless': 2,
    'taster': 2,
    'tasters': 2,
    'tastes': 1,
    'tastier': 3,
    'tastiness': 3,
    'tasting': 2,
    'tastings': 2,
    'tasty': 2,
    'tat': 1,
    'tata': 2,
    'tatar': 2,
    'tatars': 2,
    'tatarstan': 3,
    'tate': 1,
    'tateho': 3,
    'tatem': 2,
    'tater': 2,
    'tatge': 1,
    'tatham': 2,
    'tatiana': 4,
    'tatlock': 2,
    'tatman': 2,
    'tatom': 2,
    'tatra': 2,
    'tatro': 2,
    'tatsch': 1,
    'tatsukichi': 4,
    'tatsuno': 3,
    'tatter': 2,
    'tattered': 2,
    'tatters': 2,
    'tattersall': 3,
    'tattle': 2,
    'tattled': 2,
    'tattler': 2,
    'tattoo': 2,
    'tattooed': 2,
    'tattooing': 3,
    'tattoos': 2,
    'tatty': 2,
    'tatu': 2,
    'tatum': 2,
    'tatyana': 3,
    'taub': 1,
    'taube': 1,
    'tauber': 2,
    'taubert': 2,
    'taubes': 1,
    'taubman': 2,
    'taucher': 2,
    'tauer': 2,
    'taught': 1,
    'tauke': 1,
    'taul': 1,
    'taulbee': 2,
    'taunt': 1,
    'taunted': 2,
    'taunting': 2,
    'taunton': 2,
    'taunts': 1,
    'taurus': 2,
    'tauruses': 3,
    'tausch': 1,
    'tauscher': 2,
    'taussig': 2,
    'taut': 1,
    'tauzin': 2,
    'tavano': 3,
    'tavares': 3,
    'tavarez': 3,
    'tavel': 2,
    'tavenner': 3,
    'tavera': 3,
    'taveras': 3,
    'tavern': 2,
    'taverna': 3,
    'tavernas': 3,
    'tavernier': 4,
    'taverns': 2,
    'taves': 1,
    'tavie': 2,
    'tavis': 2,
    'tavish': 2,
    'tavist': 2,
    'tavlin': 2,
    'tavoulareas': 5,
    'tavy': 2,
    'tawana': 3,
    'tawdry': 2,
    'tawes': 1,
    'tawfiq': 2,
    'tawil': 2,
    'tawney': 2,
    'tawny': 2,
    'tax': 1,
    'taxability': 5,
    'taxable': 3,
    'taxables': 3,
    'taxation': 3,
    'taxcut': 2,
    'taxed': 1,
    'taxer': 2,
    'taxers': 2,
    'taxes': 2,
    'taxi': 2,
    'taxicab': 3,
    'taxicabs': 3,
    'taxidermist': 4,
    'taxidermists': 4,
    'taxidermy': 4,
    'taxied': 2,
    'taxiing': 3,
    'taxing': 2,
    'taxis': 2,
    'taxiway': 3,
    'taxol': 2,
    'taxpayer': 3,
    'taxpayers': 3,
    'taxpaying': 3,
    'tay': 1,
    'tayler': 2,
    'tayloe': 2,
    'taylor': 2,
    'taylors': 2,
    'tayman': 2,
    'tays': 1,
    'tb': 2,
    'tbilisi': 4,
    'tcas': 4,
    'tchaikovsky': 3,
    'tchuruk': 2,
    'te': 1,
    'tea': 1,
    'teac': 1,
    'teach': 1,
    'teachable': 3,
    'teacher': 2,
    'teachers': 2,
    'teaches': 2,
    'teachey': 2,
    'teaching': 2,
    'teachings': 2,
    'teachout': 2,
    'teacup': 2,
    'teaford': 2,
    'teagarden': 3,
    'teagle': 2,
    'teague': 1,
    'teahan': 2,
    'teak': 1,
    'teakamp': 2,
    'teakwood': 2,
    'teal': 1,
    'teale': 1,
    'teall': 1,
    'team': 1,
    'teamed': 1,
    'teamer': 2,
    'teaming': 2,
    'teammate': 2,
    'teammates': 2,
    'teams': 1,
    'teamster': 2,
    'teamsters': 2,
    'teamwork': 2,
    'teaneck': 2,
    'teaney': 2,
    'teapot': 2,
    'tear': 1,
    'teare': 1,
    'tearful': 2,
    'tearfully': 3,
    'tearing': 2,
    'tearle': 2,
    'tears': 1,
    'teary': 2,
    'teas': 1,
    'teasdale': 2,
    'tease': 1,
    'teased': 1,
    'teaser': 2,
    'teases': 2,
    'teasing': 2,
    'teasley': 2,
    'teason': 2,
    'teaspoon': 2,
    'teaspoonful': 3,
    'teaspoons': 2,
    'teast': 1,
    'teaster': 2,
    'teat': 1,
    'teate': 2,
    'teater': 2,
    'teatro': 2,
    'teats': 1,
    'tebbe': 1,
    'tebben': 2,
    'tebbetts': 2,
    'tebbit': 2,
    'tebbs': 1,
    'tebeau': 2,
    'tebelskis': 3,
    'tebo': 2,
    'tebuthiuron': 4,
    'tec': 1,
    'tech': 1,
    'techie': 2,
    'techies': 2,
    'techint': 2,
    'technic': 2,
    'technical': 3,
    'technicalities': 5,
    'technicality': 5,
    'technically': 4,
    'technicals': 3,
    'technician': 3,
    'technicians': 3,
    'technicolor': 4,
    'technicon': 3,
    'technics': 2,
    'technique': 2,
    'techniques': 2,
    'technitrol': 3,
    'techno': 2,
    'technocrat': 3,
    'technocratic': 4,
    'technocrats': 3,
    'technodyne': 3,
    'technologic': 4,
    'technological': 5,
    'technologically': 6,
    'technologies': 4,
    'technologist': 4,
    'technologists': 4,
    'technology': 4,
    'technomic': 3,
    'technophobe': 3,
    'technophobes': 3,
    'techs': 1,
    'techsystem': 3,
    'techsystems': 3,
    'techy': 2,
    'teck': 1,
    'tecla': 2,
    'teco': 2,
    'tecogen': 3,
    'tecos': 2,
    'tectonic': 3,
    'tectonics': 3,
    'tecumseh': 3,
    'ted': 1,
    'tedder': 2,
    'teddie': 2,
    'teddy': 2,
    'tedeschi': 3,
    'tedesco': 3,
    'tedford': 2,
    'tedious': 3,
    'tediously': 4,
    'tedium': 3,
    'tedman': 2,
    'tedmond': 2,
    'tedmund': 2,
    'tedrick': 2,
    'tedrow': 2,
    'tee': 1,
    'teed': 1,
    'teegarden': 3,
    'teegardin': 3,
    'teehan': 2,
    'teeing': 2,
    'teel': 1,
    'teele': 1,
    'teeley': 2,
    'teeling': 2,
    'teem': 1,
    'teeming': 2,
    'teems': 1,
    'teen': 1,
    'teena': 2,
    'teenage': 2,
    'teenaged': 2,
    'teenager': 3,
    'teenagers': 3,
    'teenie': 2,
    'teens': 1,
    'teensy': 2,
    'teeny': 2,
    'teeple': 2,
    'teeples': 2,
    'teer': 1,
    'tees': 1,
    'teet': 1,
    'teeter': 2,
    'teetered': 2,
    'teetering': 3,
    'teeters': 2,
    'teeth': 1,
    'teethe': 1,
    'teething': 2,
    'teets': 1,
    'teffeteller': 4,
    'tefft': 1,
    'teflon': 2,
    'tefra': 2,
    'tegeler': 3,
    'tegethoff': 3,
    'tegge': 1,
    'tegtmeier': 3,
    'tegtmeyer': 3,
    'tegucigalpa': 5,
    'tehan': 2,
    'teheran': 3,
    'teherani': 4,
    'teheranis': 4,
    'teherans': 3,
    'tehran': 2,
    'tehrani': 3,
    'tehranis': 3,
    'tehrans': 2,
    'teich': 1,
    'teicher': 2,
    'teichert': 2,
    'teichman': 2,
    'teichmann': 2,
    'teicholz': 2,
    'teig': 1,
    'teige': 1,
    'teigen': 2,
    'teikoku': 3,
    'teitel': 2,
    'teitelbaum': 3,
    'teitell': 2,
    'teixeira': 3,
    'tejada': 3,
    'tejan': 2,
    'tejano': 3,
    'tejas': 2,
    'tejeda': 3,
    'tejera': 3,
    'tejon': 2,
    'tek': 1,
    'teknowledge': 3,
    'tektronix': 3,
    'tel': 1,
    'telaction': 3,
    'telamon': 3,
    'telander': 3,
    'telco': 2,
    'tele': 2,
    'telecable': 4,
    'telecard': 3,
    'telecast': 3,
    'telecasts': 3,
    'telecharge': 3,
    'telecheck': 3,
    'telecom': 3,
    'telecommunication': 7,
    'telecommunications': 7,
    'telecommute': 4,
    'telecommuter': 5,
    'telecommuters': 5,
    'telecommuting': 5,
    'telecoms': 3,
    'teleconference': 4,
    'teleconferencing': 5,
    'teleconnect': 4,
    'telecrafter': 4,
    'telecredit': 4,
    'telectron': 3,
    'telectronic': 4,
    'telectronics': 4,
    'teledesic': 4,
    'teledyne': 3,
    'teleflex': 3,
    'telefon': 3,
    'telefonica': 5,
    'telefonos': 4,
    'telefunken': 4,
    'telegenic': 4,
    'teleglobe': 3,
    'telegram': 3,
    'telegrams': 3,
    'telegraph': 3,
    'telegraphed': 3,
    'telekom': 3,
    'telemacho': 4,
    'telemanagement': 5,
    'telemarket': 4,
    'telemarketer': 5,
    'telemarketers': 5,
    'telemarketing': 5,
    'telematic': 4,
    'telematics': 4,
    'telemecanique': 5,
    'telemedia': 5,
    'telemedicine': 5,
    'telemetry': 4,
    'telemundo': 4,
    'telenet': 3,
    'teleological': 6,
    'telepath': 3,
    'telepathic': 4,
    'telepathy': 4,
    'telephone': 3,
    'telephoned': 3,
    'telephones': 3,
    'telephonic': 4,
    'telephonics': 4,
    'telephoning': 4,
    'telephoniques': 4,
    'telephony': 4,
    'telephoto': 4,
    'telepicture': 4,
    'telepictures': 4,
    'teleport': 3,
    'teleprobe': 3,
    'teleprompter': 4,
    'telequest': 3,
    'telerate': 3,
    'telesat': 3,
    'telescience': 4,
    'telesciences': 5,
    'telesco': 3,
    'telescope': 3,
    'telescopes': 3,
    'telescopic': 4,
    'telescript': 3,
    'telesis': 3,
    'telesphere': 3,
    'telestrator': 4,
    'telesystem': 4,
    'teletext': 3,
    'telethon': 3,
    'teletron': 3,
    'telettra': 3,
    'teletype': 3,
    'teletypes': 3,
    'televangelist': 5,
    'televangelists': 5,
    'televideo': 5,
    'televisa': 4,
    'televise': 3,
    'televised': 3,
    'televising': 4,
    'television': 4,
    'televisions': 4,
    'televison': 4,
    'telewest': 3,
    'telex': 2,
    'telexed': 2,
    'telexes': 3,
    'telfair': 2,
    'telfer': 2,
    'telfor': 2,
    'telford': 2,
    'telfour': 2,
    'telit': 2,
    'tell': 1,
    'tellabs': 2,
    'tellefsen': 3,
    'tellep': 2,
    'teller': 2,
    'tellers': 2,
    'telles': 1,
    'tellez': 2,
    'tellier': 3,
    'telling': 2,
    'tellingly': 3,
    'tellis': 2,
    'tello': 2,
    'tells': 1,
    'telltale': 2,
    'telltales': 2,
    'telluride': 3,
    'tellurides': 3,
    'tellurium': 4,
    'telly': 2,
    'telmex': 2,
    'telomerase': 4,
    'telomere': 3,
    'telomeres': 3,
    'telos': 2,
    'telsource': 2,
    'telstar': 2,
    'telstra': 2,
    'teltschik': 2,
    'telugu': 3,
    'telustrator': 4,
    'telxon': 2,
    'telzrow': 2,
    'temblor': 2,
    'temblors': 2,
    'temco': 2,
    'temecula': 4,
    'temerity': 4,
    'temerlin': 3,
    'temerous': 3,
    'temkin': 2,
    'temme': 1,
    'temp': 1,
    'tempe': 2,
    'tempel': 2,
    'tempelsman': 3,
    'temper': 2,
    'tempera': 3,
    'temperament': 3,
    'temperamental': 4,
    'temperamentally': 5,
    'temperaments': 3,
    'temperance': 3,
    'temperate': 2,
    'temperature': 3,
    'temperatures': 3,
    'tempered': 2,
    'tempering': 3,
    'tempers': 2,
    'tempest': 2,
    'tempesta': 3,
    'tempestuous': 4,
    'templar': 2,
    'templars': 2,
    'template': 2,
    'temple': 2,
    'templeman': 3,
    'templer': 3,
    'templers': 3,
    'temples': 2,
    'templet': 2,
    'templeton': 3,
    'templin': 2,
    'tempo': 2,
    'temporal': 3,
    'temporally': 4,
    'temporaries': 4,
    'temporarily': 5,
    'temporary': 4,
    'tempore': 2,
    'temporize': 3,
    'temporizing': 4,
    'tempos': 2,
    'temps': 1,
    'tempt': 1,
    'temptation': 3,
    'temptations': 3,
    'tempted': 2,
    'tempting': 2,
    'temptress': 2,
    'tempts': 1,
    'ten': 1,
    'tena': 2,
    'tenable': 3,
    'tenacious': 3,
    'tenaciously': 4,
    'tenacity': 4,
    'tenaglia': 4,
    'tenancy': 3,
    'tenant': 2,
    'tenants': 2,
    'tenbrink': 2,
    'tench': 1,
    'tencza': 2,
    'tend': 1,
    'tended': 2,
    'tendencies': 3,
    'tendency': 3,
    'tendentious': 3,
    'tender': 2,
    'tendered': 2,
    'tendering': 3,
    'tenderloin': 3,
    'tenderly': 3,
    'tenderness': 3,
    'tenders': 2,
    'tending': 2,
    'tendler': 2,
    'tendon': 2,
    'tendons': 2,
    'tends': 1,
    'tenebrous': 3,
    'tenement': 3,
    'tenements': 3,
    'tenenbaum': 3,
    'tener': 2,
    'tenera': 3,
    'tenet': 2,
    'tenets': 2,
    'teneyck': 2,
    'tenfold': 2,
    'teng': 1,
    'tengelmann': 3,
    'tengiz': 2,
    'tenglemann': 3,
    'tenn': 3,
    'tennant': 2,
    'tenncare': 2,
    'tenneco': 3,
    'tennell': 2,
    'tennenbaum': 3,
    'tennent': 2,
    'tenner': 2,
    'tennessean': 4,
    'tennesseans': 4,
    'tennessee': 3,
    'tenney': 2,
    'tennis': 2,
    'tennison': 3,
    'tenny': 2,
    'tennyson': 3,
    'tenor': 2,
    'tenore': 2,
    'tenorio': 4,
    'tenors': 2,
    'tenpas': 2,
    'tenpenny': 3,
    'tenrecs': 2,
    'tens': 1,
    'tense': 1,
    'tensely': 2,
    'tensile': 2,
    'tensiometer': 5,
    'tension': 2,
    'tensions': 2,
    'tent': 1,
    'tentacle': 3,
    'tentacles': 3,
    'tentative': 3,
    'tentatively': 4,
    'tenth': 1,
    'tenths': 1,
    'tenting': 2,
    'tents': 1,
    'tenuous': 3,
    'tenure': 2,
    'tenured': 2,
    'tenures': 2,
    'tenuta': 3,
    'tenzer': 2,
    'teo': 2,
    'teodoro': 4,
    'tepe': 1,
    'teper': 2,
    'tepid': 2,
    'teply': 2,
    'tepoztlan': 3,
    'tepper': 2,
    'tepperman': 3,
    'tequila': 3,
    'tequiliu': 3,
    'ter': 1,
    'terada': 3,
    'teradata': 4,
    'teradyne': 3,
    'teran': 2,
    'terasawa': 4,
    'terbush': 2,
    'tercel': 2,
    'tercero': 3,
    'terence': 2,
    'terentia': 3,
    'teresa': 3,
    'terese': 2,
    'teresi': 3,
    'teresita': 4,
    'teressa': 3,
    'terex': 2,
    'tereza': 3,
    'terhaar': 2,
    'terhorst': 2,
    'terhune': 2,
    'teri': 2,
    'teriyaki': 4,
    'terkel': 2,
    'terkhorn': 2,
    'terlecki': 3,
    'terlizzi': 3,
    'term': 1,
    'terman': 2,
    'termed': 1,
    'termeer': 2,
    'termer': 2,
    'termers': 2,
    'terminable': 4,
    'terminal': 3,
    'terminally': 4,
    'terminals': 3,
    'terminate': 3,
    'terminated': 4,
    'terminates': 3,
    'terminating': 4,
    'termination': 4,
    'terminations': 4,
    'terminator': 4,
    'termine': 2,
    'terming': 2,
    'termini': 3,
    'terminology': 5,
    'termite': 2,
    'termites': 2,
    'terms': 1,
    'ternes': 1,
    'terns': 1,
    'terpening': 3,
    'terpsichorde': 3,
    'terpstra': 2,
    'terra': 2,
    'terracciano': 5,
    'terrace': 2,
    'terraced': 2,
    'terraces': 3,
    'terrain': 2,
    'terrains': 2,
    'terral': 2,
    'terrana': 3,
    'terrance': 2,
    'terrano': 3,
    'terranova': 4,
    'terrasi': 3,
    'terrazas': 3,
    'terre': 2,
    'terrebonne': 4,
    'terrel': 2,
    'terrell': 2,
    'terrence': 2,
    'terrestrial': 4,
    'terri': 2,
    'terrible': 3,
    'terribly': 3,
    'terrie': 2,
    'terrien': 3,
    'terrier': 3,
    'terriers': 3,
    'terrific': 3,
    'terrifically': 4,
    'terrified': 3,
    'terrifies': 3,
    'terrify': 3,
    'terrifying': 4,
    'terrile': 2,
    'terrill': 2,
    'terrington': 3,
    'terrio': 3,
    'terris': 2,
    'territo': 3,
    'territorial': 5,
    'territorialism': 7,
    'territorially': 6,
    'territories': 4,
    'territory': 4,
    'terrizzi': 3,
    'terrones': 2,
    'terror': 2,
    'terrorism': 4,
    'terrorist': 3,
    'terroristic': 4,
    'terrorists': 3,
    'terrorize': 3,
    'terrorized': 3,
    'terrorizes': 4,
    'terrorizing': 4,
    'terrors': 2,
    'terry': 2,
    'terse': 1,
    'tersely': 2,
    'terseness': 2,
    'tertia': 2,
    'tertiary': 3,
    'tertius': 3,
    'teruko': 3,
    'teruya': 3,
    'tervo': 2,
    'terwilliger': 4,
    'terzian': 3,
    'tesar': 2,
    'tesch': 1,
    'teschner': 2,
    'tesco': 2,
    'tese': 1,
    'tesh': 1,
    'teska': 2,
    'teske': 1,
    'tesla': 2,
    'tesler': 3,
    'teslik': 2,
    'tesmer': 3,
    'tesobono': 4,
    'tesobonos': 4,
    'tesol': 2,
    'tesoriero': 4,
    'tesoro': 3,
    'tess': 1,
    'tessa': 2,
    'tesselate': 3,
    'tesselated': 4,
    'tessick': 2,
    'tessie': 2,
    'tessier': 3,
    'tessitore': 4,
    'tessler': 2,
    'tessman': 2,
    'tessmer': 2,
    'tessy': 2,
    'test': 1,
    'testa': 2,
    'testament': 3,
    'testamentary': 5,
    'testaments': 3,
    'tested': 2,
    'tester': 2,
    'testerman': 3,
    'testers': 2,
    'testes': 2,
    'testicle': 3,
    'testicles': 3,
    'testicular': 4,
    'testified': 3,
    'testifies': 3,
    'testify': 3,
    'testifying': 4,
    'testily': 3,
    'testimonial': 5,
    'testimonials': 5,
    'testimonies': 4,
    'testimony': 4,
    'testing': 2,
    'teston': 2,
    'testor': 2,
    'testosterone': 4,
    'testrake': 2,
    'tests': 1,
    'testuro': 3,
    'testy': 2,
    'tet': 1,
    'teta': 2,
    'tetanus': 3,
    'tete': 1,
    'teter': 2,
    'teters': 2,
    'tether': 2,
    'tethered': 2,
    'tethers': 2,
    'teti': 2,
    'tetley': 2,
    'tetlow': 2,
    'teton': 2,
    'tetons': 2,
    'tetra': 2,
    'tetrads': 2,
    'tetrahedral': 4,
    'tetrahedron': 4,
    'tetramerous': 4,
    'tetrault': 2,
    'tetravalent': 4,
    'tetreault': 2,
    'tetrick': 2,
    'tetro': 2,
    'tetsuji': 3,
    'tetsuo': 3,
    'tettamanti': 4,
    'tetterton': 3,
    'tetzlaff': 2,
    'tetzloff': 2,
    'teuber': 2,
    'teubner': 2,
    'teufel': 2,
    'teuscher': 2,
    'teutonic': 3,
    'teutsch': 1,
    'teva': 2,
    'tevatron': 3,
    'teves': 1,
    'tevis': 2,
    'tevlin': 2,
    'tevye': 2,
    'tew': 1,
    'tewell': 2,
    'tewes': 1,
    'tewksbury': 3,
    'tews': 1,
    'tex': 1,
    'texaco': 3,
    'texan': 2,
    'texans': 2,
    'texarkana': 4,
    'texas': 2,
    'texasgulf': 3,
    'texeira': 3,
    'texfi': 2,
    'texpool': 2,
    'texscan': 2,
    'texstar': 2,
    'texstyrene': 3,
    'text': 1,
    'textbook': 2,
    'textbooks': 2,
    'texter': 2,
    'textile': 2,
    'textiles': 2,
    'textor': 2,
    'textron': 2,
    'texts': 1,
    'textual': 3,
    'texture': 2,
    'textured': 2,
    'textures': 2,
    'tezak': 2,
    'tezeno': 3,
    'th': 2,
    'thabo': 2,
    'thach': 1,
    'thacher': 2,
    'thacker': 2,
    'thackeray': 3,
    'thackston': 2,
    'thad': 1,
    'thada': 2,
    'thadda': 2,
    'thaddea': 3,
    'thaddeus': 3,
    'thaden': 2,
    'thagard': 2,
    'thaggard': 2,
    'thai': 1,
    'thailand': 2,
    'thain': 1,
    'thaine': 1,
    'thais': 1,
    'thakkar': 2,
    'thal': 1,
    'thalacker': 3,
    'thalamus': 3,
    'thalassa': 3,
    'thaler': 2,
    'thalheimer': 3,
    'thalia': 2,
    'thalidomide': 4,
    'thall': 1,
    'thallium': 3,
    'thalman': 2,
    'thalmann': 2,
    'tham': 1,
    'thames': 1,
    'than': 1,
    'thanatology': 5,
    'thane': 1,
    'thang': 1,
    'thanh': 1,
    'thank': 1,
    'thanked': 1,
    'thankful': 2,
    'thankfully': 3,
    'thanking': 2,
    'thankless': 2,
    'thanks': 1,
    'thanksgiving': 3,
    'thankyou': 2,
    'thano': 2,
    'thanos': 2,
    'thao': 1,
    'thar': 1,
    'tharp': 1,
    'tharpe': 1,
    'tharrington': 3,
    'thaser': 2,
    'thassos': 2,
    'that': 1,
    'thatch': 1,
    'thatched': 1,
    'thatcher': 2,
    'thatcherism': 4,
    'thatcherite': 3,
    'thatchers': 2,
    'thatches': 2,
    'thau': 1,
    'thaw': 1,
    'thawed': 1,
    'thawing': 2,
    'thaws': 1,
    'thaxter': 2,
    'thaxton': 2,
    'thayer': 2,
    'thayers': 2,
    'thayne': 1,
    'the': 1,
    'thea': 2,
    'theall': 1,
    'theano': 2,
    'theater': 3,
    'theatergoer': 4,
    'theatergoers': 4,
    'theaters': 3,
    'theatre': 3,
    'theatres': 3,
    'theatric': 2,
    'theatrical': 4,
    'theatricality': 6,
    'theatrically': 5,
    'theatrics': 2,
    'thebeau': 2,
    'theberge': 2,
    'thebes': 1,
    'thecla': 2,
    'theda': 2,
    'thede': 1,
    'thedford': 2,
    'thee': 1,
    'theel': 1,
    'theft': 1,
    'thefts': 1,
    'theil': 1,
    'theile': 1,
    'theilen': 2,
    'theiler': 2,
    'thein': 1,
    'their': 1,
    'theirs': 1,
    'theirself': 2,
    'theirselves': 2,
    'theis': 1,
    'theisen': 2,
    'theism': 3,
    'theiss': 1,
    'theissen': 2,
    'thekla': 2,
    'thelander': 3,
    'thelen': 2,
    'thelin': 2,
    'thelma': 2,
    'thelonius': 4,
    'them': 1,
    'thematic': 3,
    'thematically': 4,
    'theme': 1,
    'themed': 1,
    'themes': 1,
    'theming': 2,
    'themself': 2,
    'themselves': 2,
    'then': 1,
    'thence': 1,
    'thenceforth': 2,
    'theo': 2,
    'theobald': 3,
    'theocracy': 4,
    'theocratic': 4,
    'theodor': 3,
    'theodora': 4,
    'theodore': 3,
    'theodorou': 4,
    'theodric': 3,
    'theola': 3,
    'theologian': 5,
    'theologians': 4,
    'theological': 5,
    'theologically': 5,
    'theology': 4,
    'theon': 2,
    'theone': 2,
    'theophania': 5,
    'theophila': 4,
    'theora': 3,
    'theorem': 2,
    'theoretical': 5,
    'theoretically': 6,
    'theoretician': 5,
    'theoreticians': 5,
    'theories': 2,
    'theorist': 3,
    'theorists': 3,
    'theorize': 3,
    'theorized': 3,
    'theorizes': 4,
    'theorizing': 4,
    'theory': 2,
    'thera': 2,
    'therafectin': 4,
    'therani': 3,
    'therapeutic': 4,
    'therapeutically': 6,
    'therapeutics': 4,
    'therapies': 3,
    'therapist': 3,
    'therapists': 3,
    'therapy': 3,
    'there': 1,
    'thereabouts': 3,
    'thereafter': 3,
    'thereby': 2,
    'therefore': 2,
    'therein': 2,
    'thereof': 2,
    'thereon': 2,
    'theresa': 3,
    'therese': 2,
    'thereupon': 3,
    'theriault': 3,
    'therien': 2,
    'theriot': 3,
    'therm': 1,
    'thermae': 2,
    'thermal': 2,
    'thermco': 2,
    'thermedics': 3,
    'thermits': 2,
    'thermo': 2,
    'thermometer': 4,
    'thermometers': 4,
    'thermonuclear': 5,
    'thermopatch': 3,
    'thermoplastic': 4,
    'thermoplastics': 4,
    'thermos': 2,
    'thermosetting': 4,
    'thermostat': 3,
    'thermostats': 3,
    'theron': 2,
    'theroux': 2,
    'therrell': 2,
    'therriault': 3,
    'therrien': 2,
    'thesaurus': 3,
    'these': 1,
    'theses': 2,
    'thesing': 2,
    'thesis': 2,
    'thespian': 3,
    'theta': 2,
    'thetford': 2,
    'thetis': 2,
    'theurer': 2,
    'theus': 1,
    'thew': 1,
    'thewlis': 2,
    'they': 1,
    'thi': 1,
    'thiamin': 3,
    'thibadeau': 3,
    'thibault': 2,
    'thibeau': 2,
    'thibeault': 2,
    'thibeaux': 2,
    'thibedeau': 3,
    'thibert': 2,
    'thibodeau': 3,
    'thibodeaux': 3,
    'thick': 1,
    'thicken': 2,
    'thickened': 2,
    'thickener': 3,
    'thickening': 3,
    'thickens': 2,
    'thicker': 2,
    'thickest': 2,
    'thicket': 2,
    'thickets': 2,
    'thickhead': 2,
    'thickly': 2,
    'thickness': 2,
    'thiebaud': 2,
    'thiede': 1,
    'thief': 1,
    'thiel': 1,
    'thiele': 1,
    'thielemann': 2,
    'thielen': 2,
    'thielke': 1,
    'thielman': 2,
    'thielsch': 1,
    'thiem': 1,
    'thieman': 2,
    'thiemann': 2,
    'thieme': 1,
    'thien': 1,
    'thier': 2,
    'thierry': 2,
    'thiery': 2,
    'thies': 1,
    'thiesen': 2,
    'thiessen': 2,
    'thievery': 3,
    'thieves': 1,
    'thieving': 2,
    'thigh': 1,
    'thighs': 1,
    'thigpen': 2,
    'thill': 1,
    'thima': 2,
    'thimble': 2,
    'thin': 1,
    'thine': 1,
    'thing': 1,
    'thingamabob': 4,
    'things': 1,
    'thingy': 2,
    'think': 1,
    'thinker': 2,
    'thinkers': 2,
    'thinkin': 2,
    'thinking': 2,
    'thinkpad': 2,
    'thinks': 1,
    'thinly': 2,
    'thinned': 1,
    'thinner': 2,
    'thinnes': 1,
    'thinness': 2,
    'thinnest': 2,
    'thinning': 2,
    'thins': 1,
    'thiodiglycol': 5,
    'thiokol': 3,
    'third': 1,
    'thirdly': 2,
    'thirdquarter': 3,
    'thirds': 1,
    'thirst': 1,
    'thirsty': 2,
    'thirteen': 2,
    'thirteenth': 2,
    'thirteenths': 2,
    'thirties': 2,
    'thirtieth': 3,
    'thirty': 2,
    'thirtysomething': 4,
    'thiry': 2,
    'thirza': 2,
    'this': 1,
    'thissen': 2,
    'thistle': 2,
    'thistles': 2,
    'thivierge': 2,
    'tho': 1,
    'thobe': 1,
    'thode': 1,
    'thoele': 1,
    'thoen': 1,
    'thoene': 2,
    'thoennes': 1,
    'thole': 1,
    'tholen': 2,
    'tholl': 1,
    'thom': 1,
    'thoma': 2,
    'thoman': 2,
    'thomann': 2,
    'thomas': 2,
    'thomasas': 3,
    'thomases': 3,
    'thomasina': 4,
    'thomasine': 4,
    'thomason': 3,
    'thomassen': 3,
    'thomasson': 3,
    'thomasville': 3,
    'thome': 1,
    'thomes': 1,
    'thometz': 2,
    'thomison': 3,
    'thomley': 2,
    'thommen': 2,
    'thomp': 1,
    'thompkins': 2,
    'thompsen': 2,
    'thompson': 2,
    'thompsons': 2,
    'thoms': 1,
    'thomsen': 2,
    'thomson': 2,
    'thomspon': 2,
    'thomure': 2,
    'thon': 1,
    'thone': 1,
    'thong': 1,
    'thor': 1,
    'thora': 2,
    'thoracic': 3,
    'thorald': 2,
    'thorax': 2,
    'thorbert': 2,
    'thorberta': 3,
    'thorburn': 2,
    'thordia': 3,
    'thordis': 2,
    'thoreau': 2,
    'thorell': 2,
    'thoren': 2,
    'thoresen': 3,
    'thoreson': 3,
    'thorington': 3,
    'thorium': 3,
    'thorley': 2,
    'thorman': 2,
    'thormond': 2,
    'thormund': 2,
    'thorn': 1,
    'thornberg': 2,
    'thornberry': 3,
    'thornberrys': 3,
    'thornburg': 2,
    'thornburgh': 2,
    'thornburghs': 2,
    'thornburgs': 2,
    'thornbury': 3,
    'thornburys': 3,
    'thorndike': 2,
    'thorndikes': 2,
    'thorndyke': 2,
    'thorne': 1,
    'thornell': 2,
    'thorner': 2,
    'thornhill': 2,
    'thorniest': 3,
    'thornley': 2,
    'thornock': 2,
    'thorns': 1,
    'thornsberry': 3,
    'thornsbury': 3,
    'thornton': 2,
    'thorny': 2,
    'thoro': 2,
    'thorough': 2,
    'thoroughbred': 3,
    'thoroughbreds': 3,
    'thoroughfare': 3,
    'thoroughfares': 3,
    'thoroughly': 3,
    'thoroughness': 3,
    'thorp': 1,
    'thorpe': 1,
    'thorsell': 2,
    'thorsen': 2,
    'thorson': 2,
    'thorstad': 2,
    'thorsten': 2,
    'thorstenson': 3,
    'thortec': 2,
    'thorton': 2,
    'thorup': 2,
    'thorvald': 2,
    'those': 1,
    'thou': 1,
    'though': 1,
    'thought': 1,
    'thoughtful': 2,
    'thoughtfully': 3,
    'thoughtfulness': 3,
    'thoughtless': 2,
    'thoughts': 1,
    'thous': 1,
    'thousand': 2,
    'thousands': 2,
    'thousandth': 2,
    'thousandths': 2,
    'thrailkill': 2,
    'thrall': 1,
    'thrash': 1,
    'thrashed': 1,
    'thrasher': 2,
    'thrashes': 2,
    'thrashing': 2,
    'thread': 1,
    'threadbare': 2,
    'threaded': 2,
    'threadfin': 2,
    'threadgill': 2,
    'threading': 2,
    'threads': 1,
    'threat': 1,
    'threaten': 2,
    'threatened': 2,
    'threatening': 3,
    'threateningly': 4,
    'threatens': 2,
    'threats': 1,
    'threatt': 1,
    'three': 1,
    'threefold': 2,
    'threemonth': 2,
    'threes': 1,
    'threesome': 2,
    'threet': 1,
    'threlkeld': 2,
    'thresh': 1,
    'thresher': 2,
    'threshold': 2,
    'thresholds': 2,
    'threw': 1,
    'thrice': 1,
    'thrift': 1,
    'thriftier': 3,
    'thrifts': 1,
    'thrifty': 2,
    'thrill': 1,
    'thrilled': 1,
    'thriller': 2,
    'thrillers': 2,
    'thrilling': 2,
    'thrills': 1,
    'thrips': 1,
    'thristing': 2,
    'thrive': 1,
    'thrived': 1,
    'thrives': 1,
    'thriving': 2,
    'throat': 1,
    'throated': 2,
    'throats': 1,
    'throaty': 2,
    'throb': 1,
    'throbbing': 2,
    'throes': 1,
    'throgmorton': 3,
    'throm': 1,
    'thrombolysis': 4,
    'thrombolytic': 4,
    'thrombosis': 3,
    'throne': 1,
    'throneberry': 3,
    'throng': 1,
    'thronged': 1,
    'throngs': 1,
    'thronson': 2,
    'throop': 1,
    'throttle': 2,
    'throttled': 2,
    'throttles': 2,
    'throttling': 3,
    'through': 1,
    'throughout': 2,
    'throughput': 2,
    'throughs': 1,
    'throughway': 2,
    'throve': 1,
    'throw': 1,
    'throwaway': 3,
    'throwback': 2,
    'thrower': 2,
    'throwers': 2,
    'throwing': 2,
    'thrown': 1,
    'throws': 1,
    'thru': 1,
    'thrun': 1,
    'thrush': 1,
    'thrushes': 2,
    'thrust': 1,
    'thruster': 2,
    'thrusters': 2,
    'thrusting': 2,
    'thrusts': 1,
    'thruway': 2,
    'ths': 0,
    'thud': 1,
    'thug': 1,
    'thuggery': 3,
    'thugs': 1,
    'thul': 1,
    'thulin': 2,
    'thull': 1,
    'thum': 1,
    'thuma': 2,
    'thuman': 2,
    'thumann': 2,
    'thumb': 1,
    'thumbed': 1,
    'thumbing': 2,
    'thumbnail': 2,
    'thumbs': 1,
    'thumm': 1,
    'thumma': 2,
    'thump': 1,
    'thumped': 1,
    'thumper': 2,
    'thumping': 2,
    'thumps': 1,
    'thun': 1,
    'thunberg': 2,
    'thunder': 2,
    'thunderbird': 3,
    'thunderbirds': 3,
    'thunderbolt': 3,
    'thundercat': 3,
    'thundercats': 3,
    'thundered': 2,
    'thundering': 3,
    'thunderous': 3,
    'thunders': 2,
    'thundershower': 4,
    'thundershowers': 4,
    'thunderstorm': 3,
    'thunderstorms': 3,
    'thunderstruck': 3,
    'thune': 1,
    'thunk': 1,
    'thuot': 1,
    'thur': 1,
    'thurber': 2,
    'thurgood': 2,
    'thurlow': 2,
    'thurm': 1,
    'thurman': 2,
    'thurmon': 2,
    'thurmond': 2,
    'thurn': 1,
    'thurnau': 2,
    'thurner': 2,
    'thurnher': 2,
    'thurow': 2,
    'thursby': 2,
    'thursday': 2,
    'thursdays': 2,
    'thurstan': 2,
    'thurston': 2,
    'thus': 1,
    'thusfar': 2,
    'thusly': 2,
    'thuy': 1,
    'thwart': 1,
    'thwarted': 2,
    'thwarting': 2,
    'thwarts': 1,
    'thweatt': 1,
    'thwing': 1,
    'thy': 1,
    'thygerson': 3,
    'thyme': 1,
    'thymidine': 3,
    'thyra': 2,
    'thyroid': 2,
    'thyssen': 2,
    'ti': 1,
    'tia': 2,
    'tiaacref': 3,
    'tian': 1,
    'tiananmen': 4,
    'tianjin': 3,
    'tiano': 3,
    'tiara': 3,
    'tiaras': 3,
    'tibbals': 2,
    'tibbets': 2,
    'tibbett': 2,
    'tibbetts': 2,
    'tibbie': 2,
    'tibbits': 2,
    'tibbitts': 2,
    'tibbs': 1,
    'tibby': 2,
    'tiber': 2,
    'tiberi': 3,
    'tiberia': 4,
    'tiberio': 4,
    'tiberon': 3,
    'tibet': 2,
    'tibetan': 3,
    'tibetans': 3,
    'tibi': 2,
    'tibia': 3,
    'tibidow': 3,
    'tic': 1,
    'tice': 1,
    'ticer': 2,
    'tichenor': 3,
    'tichy': 2,
    'ticinus': 3,
    'tick': 1,
    'ticked': 1,
    'ticker': 2,
    'ticket': 2,
    'ticketed': 3,
    'ticketing': 3,
    'ticketless': 3,
    'ticketmaster': 4,
    'ticketron': 3,
    'tickets': 2,
    'ticking': 2,
    'tickle': 2,
    'tickled': 2,
    'tickles': 2,
    'ticklish': 3,
    'tickner': 2,
    'ticknor': 2,
    'ticks': 1,
    'ticonderoga': 5,
    'ticor': 2,
    'tics': 1,
    'tidal': 2,
    'tidball': 2,
    'tidbit': 2,
    'tidbits': 2,
    'tidd': 1,
    'tiddly': 2,
    'tiddlywinks': 3,
    'tide': 1,
    'tides': 1,
    'tidewater': 3,
    'tiding': 2,
    'tidings': 2,
    'tidmore': 2,
    'tidrick': 2,
    'tidwell': 2,
    'tidy': 2,
    'tie': 1,
    'tied': 1,
    'tiede': 1,
    'tiedeman': 2,
    'tiedemann': 2,
    'tiedt': 1,
    'tiegs': 1,
    'tiein': 2,
    'tieing': 2,
    'tieken': 2,
    'tieless': 2,
    'tieman': 2,
    'tiemann': 2,
    'tiemeyer': 3,
    'tien': 1,
    'tienanmen': 3,
    'tier': 1,
    'tierce': 1,
    'tierco': 2,
    'tiered': 1,
    'tiernan': 2,
    'tierney': 2,
    'tierno': 2,
    'tiers': 1,
    'ties': 1,
    'tieszen': 2,
    'tietje': 1,
    'tietjen': 2,
    'tietmeyer': 2,
    'tietz': 1,
    'tietze': 1,
    'tieu': 2,
    'tiff': 1,
    'tiffany': 3,
    'tiffin': 2,
    'tifft': 1,
    'tift': 1,
    'tifton': 2,
    'tig': 1,
    'tigar': 2,
    'tiger': 2,
    'tigera': 3,
    'tigers': 2,
    'tigert': 2,
    'tigges': 1,
    'tiggs': 1,
    'tighe': 1,
    'tight': 1,
    'tighten': 2,
    'tightened': 2,
    'tightening': 3,
    'tightenings': 3,
    'tightens': 2,
    'tighter': 2,
    'tightest': 2,
    'tightfisted': 3,
    'tightly': 2,
    'tightness': 2,
    'tightrope': 2,
    'tights': 1,
    'tightwad': 2,
    'tigner': 2,
    'tignor': 2,
    'tigon': 2,
    'tigrean': 2,
    'tigris': 2,
    'tigue': 1,
    'tijerina': 4,
    'tijuana': 3,
    'tike': 1,
    'tikes': 1,
    'tiki': 2,
    'til': 1,
    'tilbury': 3,
    'tilda': 2,
    'tilden': 2,
    'tile': 1,
    'tiled': 1,
    'tiles': 1,
    'tiley': 2,
    'tilford': 2,
    'tilghman': 2,
    'till': 1,
    'tiller': 2,
    'tillery': 3,
    'tilles': 1,
    'tillett': 2,
    'tilley': 2,
    'tillie': 2,
    'tilling': 2,
    'tillinghast': 3,
    'tillis': 2,
    'tillison': 3,
    'tillman': 2,
    'tillmon': 2,
    'tillotson': 3,
    'tillson': 2,
    'tilly': 2,
    'tilman': 2,
    'tilney': 2,
    'tilson': 2,
    'tilt': 1,
    'tilted': 2,
    'tilth': 1,
    'tilting': 2,
    'tilton': 2,
    'tilts': 1,
    'tim': 1,
    'timan': 2,
    'timber': 2,
    'timberlake': 3,
    'timberland': 3,
    'timberlands': 3,
    'timberline': 3,
    'timberman': 3,
    'timbers': 2,
    'timblin': 2,
    'timbre': 2,
    'timbrook': 2,
    'timbs': 1,
    'timbuktu': 3,
    'time': 1,
    'timed': 1,
    'timeframe': 2,
    'timeless': 2,
    'timeline': 2,
    'timelines': 2,
    'timeliness': 3,
    'timely': 2,
    'timeout': 2,
    'timepiece': 2,
    'timeplex': 2,
    'timer': 2,
    'timers': 2,
    'times': 1,
    'timeshare': 2,
    'timetable': 3,
    'timetables': 3,
    'timewise': 2,
    'timex': 2,
    'timid': 2,
    'timidity': 4,
    'timidly': 3,
    'timing': 2,
    'timisoara': 5,
    'timken': 2,
    'timko': 2,
    'timlen': 2,
    'timlin': 2,
    'timm': 1,
    'timme': 1,
    'timmeny': 3,
    'timmer': 2,
    'timmerman': 3,
    'timmermann': 3,
    'timmers': 2,
    'timmie': 2,
    'timmins': 2,
    'timmons': 2,
    'timms': 1,
    'timmy': 2,
    'timon': 2,
    'timoney': 3,
    'timor': 2,
    'timorese': 3,
    'timorous': 3,
    'timothea': 4,
    'timothy': 3,
    'timpani': 3,
    'timpe': 1,
    'timpone': 3,
    'timpson': 2,
    'tims': 1,
    'timson': 2,
    'timur': 2,
    'timurian': 4,
    'tin': 1,
    'tina': 2,
    'tinajero': 4,
    'tinch': 1,
    'tincher': 2,
    'tincture': 2,
    'tinctures': 2,
    'tindal': 2,
    'tindall': 2,
    'tindel': 2,
    'tindell': 2,
    'tinder': 2,
    'tinderbox': 3,
    'tindle': 2,
    'tindol': 2,
    'tine': 1,
    'tiner': 2,
    'tines': 1,
    'ting': 1,
    'tinge': 1,
    'tinged': 1,
    'tingen': 2,
    'tingey': 2,
    'tingle': 2,
    'tingler': 3,
    'tingley': 2,
    'tingling': 3,
    'tinier': 3,
    'tiniest': 3,
    'tinker': 2,
    'tinkered': 2,
    'tinkerer': 3,
    'tinkerers': 3,
    'tinkering': 3,
    'tinkey': 2,
    'tinkham': 2,
    'tinkle': 2,
    'tinkled': 2,
    'tinkler': 3,
    'tinkling': 3,
    'tinley': 2,
    'tinman': 2,
    'tinnell': 2,
    'tinney': 2,
    'tinnin': 2,
    'tinnon': 2,
    'tinny': 2,
    'tino': 2,
    'tinoco': 3,
    'tins': 1,
    'tinsel': 2,
    'tinseltown': 3,
    'tinsley': 2,
    'tinsman': 2,
    'tint': 1,
    'tinted': 2,
    'tinto': 2,
    'tinton': 2,
    'tints': 1,
    'tintype': 2,
    'tiny': 2,
    'tip': 1,
    'tipa': 2,
    'tiphook': 2,
    'tipler': 3,
    'tipo': 2,
    'tipoff': 2,
    'tipp': 1,
    'tipped': 1,
    'tippen': 2,
    'tippens': 2,
    'tipper': 2,
    'tipperary': 4,
    'tippers': 2,
    'tippet': 2,
    'tippets': 2,
    'tippett': 2,
    'tippetts': 2,
    'tippie': 2,
    'tippin': 2,
    'tipping': 2,
    'tippins': 2,
    'tippit': 2,
    'tippitt': 2,
    'tipple': 2,
    'tipps': 1,
    'tippy': 2,
    'tips': 1,
    'tipster': 2,
    'tipsword': 2,
    'tipsy': 2,
    'tiptoe': 2,
    'tiptoed': 2,
    'tiptoeing': 3,
    'tipton': 2,
    'tirade': 2,
    'tirades': 2,
    'tirado': 3,
    'tiramisu': 4,
    'tirana': 3,
    'tirane': 2,
    'tire': 2,
    'tired': 2,
    'tiredness': 3,
    'tireless': 3,
    'tirelessly': 3,
    'tirello': 3,
    'tiremaker': 3,
    'tires': 2,
    'tiresome': 3,
    'tirey': 2,
    'tiring': 2,
    'tirone': 2,
    'tiros': 2,
    'tirpak': 2,
    'tirrell': 2,
    'tis': 1,
    'tisch': 1,
    'tischer': 2,
    'tischler': 3,
    'tisdale': 2,
    'tisdel': 2,
    'tisdell': 2,
    'tish': 1,
    'tisher': 2,
    'tishler': 2,
    'tishman': 2,
    'tison': 2,
    'tissue': 2,
    'tissues': 2,
    'tit': 1,
    'tita': 2,
    'titan': 2,
    'titanate': 3,
    'titania': 3,
    'titanic': 3,
    'titanium': 4,
    'titans': 2,
    'titcomb': 2,
    'tithe': 1,
    'tithing': 2,
    'titian': 2,
    'titillate': 3,
    'titillated': 4,
    'titillating': 4,
    'titillation': 4,
    'titinus': 3,
    'title': 2,
    'titled': 2,
    'titleholder': 4,
    'titles': 2,
    'titlow': 2,
    'titman': 2,
    'tito': 2,
    'titone': 2,
    'titsworth': 2,
    'tittel': 2,
    'titter': 2,
    'titterington': 4,
    'tittle': 2,
    'tittsworth': 2,
    'titular': 3,
    'titus': 2,
    'titusville': 3,
    'titzer': 2,
    'tivoli': 3,
    'tizzy': 2,
    'tjaden': 2,
    'tjarks': 1,
    'tkach': 1,
    'tkacz': 1,
    'tlateloco': 4,
    'tlingit': 2,
    'to': 1,
    'toa': 2,
    'toad': 1,
    'toads': 1,
    'toal': 1,
    'toalson': 2,
    'toalster': 2,
    'toast': 1,
    'toasted': 2,
    'toaster': 2,
    'toasters': 2,
    'toasting': 2,
    'toastmaster': 3,
    'toasts': 1,
    'tobacco': 3,
    'tobaccos': 3,
    'toback': 2,
    'tobago': 3,
    'tobar': 2,
    'tobe': 1,
    'toben': 2,
    'tober': 2,
    'tobey': 2,
    'tobia': 3,
    'tobias': 3,
    'tobiason': 4,
    'tobie': 2,
    'tobin': 2,
    'tobler': 3,
    'toboggan': 3,
    'toboggans': 3,
    'tobolski': 3,
    'tobruk': 2,
    'tobu': 2,
    'toby': 2,
    'tocci': 2,
    'tocco': 2,
    'tock': 1,
    'tocqueville': 2,
    'toczek': 2,
    'tod': 1,
    'toda': 2,
    'todaro': 3,
    'today': 2,
    'todays': 2,
    'todd': 1,
    'toddle': 2,
    'toddler': 2,
    'toddlers': 2,
    'toddling': 2,
    'todhunter': 3,
    'todisco': 3,
    'todman': 2,
    'todoroff': 3,
    'todt': 1,
    'toe': 1,
    'toed': 1,
    'toehold': 2,
    'toeing': 2,
    'toelle': 1,
    'toenail': 2,
    'toenails': 2,
    'toenjes': 2,
    'toensing': 2,
    'toepfer': 2,
    'toes': 1,
    'toews': 2,
    'toffee': 2,
    'toffler': 2,
    'toft': 1,
    'tofte': 1,
    'tofu': 2,
    'toga': 2,
    'together': 3,
    'togetherness': 4,
    'togethers': 3,
    'toggle': 2,
    'toggled': 2,
    'toggling': 2,
    'tognino': 3,
    'togo': 2,
    'togs': 1,
    'toibin': 2,
    'toil': 1,
    'toiled': 1,
    'toilet': 2,
    'toiletries': 3,
    'toiletry': 3,
    'toilets': 2,
    'toiling': 2,
    'toils': 1,
    'toity': 2,
    'toivonen': 3,
    'tokai': 2,
    'tokar': 2,
    'tokarczyk': 3,
    'tokars': 2,
    'tokarski': 3,
    'tokarz': 2,
    'token': 2,
    'tokenism': 4,
    'tokens': 2,
    'tokio': 3,
    'tokkin': 2,
    'tokos': 2,
    'tokunaga': 4,
    'tokuo': 3,
    'tokuyama': 4,
    'tokyo': 3,
    'tokyu': 2,
    'tol': 1,
    'tolan': 2,
    'toland': 2,
    'tolanthe': 3,
    'tolar': 2,
    'tolbert': 2,
    'told': 1,
    'toldrian': 3,
    'tole': 1,
    'toledo': 3,
    'tolen': 2,
    'tolentino': 4,
    'toler': 2,
    'tolerable': 4,
    'tolerance': 3,
    'tolerances': 4,
    'tolerant': 3,
    'tolerants': 3,
    'tolerate': 3,
    'tolerated': 4,
    'tolerates': 3,
    'tolerating': 4,
    'toleration': 4,
    'toles': 1,
    'tolhurst': 2,
    'tolin': 2,
    'toliver': 3,
    'toll': 1,
    'tolland': 2,
    'tollbooth': 2,
    'tolle': 1,
    'tolled': 1,
    'tollefsen': 3,
    'tollefson': 3,
    'toller': 2,
    'tolles': 1,
    'tolleson': 3,
    'tollett': 2,
    'tolley': 2,
    'tolling': 2,
    'tollison': 3,
    'tolliver': 3,
    'tolls': 1,
    'tolly': 2,
    'tolman': 2,
    'tolsma': 2,
    'tolson': 2,
    'tolstoy': 2,
    'tom': 1,
    'toma': 2,
    'tomahawk': 3,
    'tomahawks': 3,
    'tomaino': 4,
    'tomako': 3,
    'toman': 2,
    'tomanek': 3,
    'tomaro': 3,
    'tomas': 2,
    'tomasek': 3,
    'tomaselli': 4,
    'tomasello': 4,
    'tomasetti': 4,
    'tomasi': 3,
    'tomasic': 3,
    'tomasik': 3,
    'tomasina': 4,
    'tomasine': 4,
    'tomasini': 4,
    'tomasino': 4,
    'tomasko': 3,
    'tomaso': 3,
    'tomassetti': 4,
    'tomassi': 3,
    'tomasso': 3,
    'tomasulo': 4,
    'tomaszewski': 4,
    'tomatino': 4,
    'tomato': 3,
    'tomatoe': 3,
    'tomatoes': 3,
    'tomatos': 3,
    'tomayko': 3,
    'tomb': 1,
    'tomberlin': 3,
    'tomblike': 2,
    'tomblin': 2,
    'tomboy': 2,
    'tombs': 1,
    'tombstone': 2,
    'tombstones': 2,
    'tomcat': 2,
    'tomczak': 2,
    'tomczyk': 2,
    'tome': 1,
    'tomei': 2,
    'tomek': 2,
    'tomeo': 3,
    'tomer': 2,
    'tomerlin': 3,
    'tomes': 1,
    'tomey': 2,
    'tomich': 2,
    'tomiichi': 4,
    'tomilson': 3,
    'tomita': 3,
    'tomkiewicz': 3,
    'tomkin': 2,
    'tomkins': 2,
    'tomkinson': 3,
    'tomko': 2,
    'tomlin': 2,
    'tomlinson': 3,
    'tommie': 2,
    'tommy': 2,
    'tomographic': 4,
    'tomorrow': 3,
    'tomorrows': 3,
    'tompane': 2,
    'tompkins': 2,
    'tompkinses': 3,
    'tompson': 2,
    'toms': 1,
    'tomshich': 2,
    'tomsic': 2,
    'tomson': 2,
    'ton': 1,
    'tonal': 2,
    'tonalities': 4,
    'tonality': 4,
    'tonawanda': 4,
    'tonda': 2,
    'tondreau': 2,
    'tone': 1,
    'toned': 1,
    'tonegawa': 4,
    'tonelli': 3,
    'toner': 2,
    'tones': 1,
    'toney': 2,
    'tong': 1,
    'tonga': 2,
    'tonge': 1,
    'tongs': 1,
    'tongue': 1,
    'tongued': 1,
    'tongues': 1,
    'toni': 2,
    'tonia': 3,
    'tonic': 2,
    'tonics': 2,
    'tonie': 2,
    'toniest': 2,
    'tonight': 2,
    'toning': 2,
    'tonini': 3,
    'tonite': 2,
    'tonjes': 2,
    'tonk': 1,
    'tonka': 2,
    'tonkin': 2,
    'tonkovich': 3,
    'tonks': 1,
    'tonn': 1,
    'tonnage': 2,
    'tonnages': 3,
    'tonne': 1,
    'tonner': 2,
    'tonnes': 1,
    'tonnesen': 2,
    'tons': 1,
    'tonsil': 2,
    'tonsillectomies': 5,
    'tonsillectomy': 5,
    'tonsils': 2,
    'tonti': 2,
    'tonton': 2,
    'tontons': 2,
    'tony': 2,
    'tonya': 2,
    'tonyes': 2,
    'tonys': 2,
    'tonysk': 2,
    'too': 1,
    'toobin': 2,
    'toogood': 2,
    'toohey': 2,
    'took': 1,
    'tooke': 1,
    'tooker': 2,
    'tookes': 1,
    'tool': 1,
    'toolan': 2,
    'toolbox': 2,
    'toole': 1,
    'tooled': 1,
    'tooley': 2,
    'tooling': 2,
    'toolmaker': 3,
    'toolmakers': 3,
    'toolroom': 2,
    'tools': 1,
    'toolworks': 2,
    'tooman': 2,
    'toombs': 1,
    'toomer': 2,
    'toomey': 2,
    'toon': 1,
    'toone': 1,
    'toops': 1,
    'toot': 1,
    'tootal': 2,
    'toote': 1,
    'tooth': 1,
    'toothaker': 3,
    'toothbrush': 2,
    'toothbrushes': 3,
    'toothed': 1,
    'toothless': 2,
    'toothlike': 2,
    'toothman': 2,
    'toothpaste': 2,
    'toothpastes': 2,
    'toothpick': 2,
    'toothpicks': 2,
    'toothy': 2,
    'tootle': 2,
    'toots': 1,
    'tootsie': 2,
    'top': 1,
    'topalian': 4,
    'topanga': 3,
    'topaz': 2,
    'tope': 1,
    'topeka': 3,
    'topekan': 3,
    'topekans': 3,
    'topel': 2,
    'topete': 2,
    'topham': 2,
    'topiary': 4,
    'topic': 2,
    'topical': 3,
    'topics': 2,
    'topix': 2,
    'topkapi': 3,
    'topknot': 2,
    'topless': 2,
    'topliff': 2,
    'topographic': 4,
    'topography': 4,
    'topol': 2,
    'topological': 5,
    'topology': 4,
    'topolski': 3,
    'topor': 2,
    'topp': 1,
    'topped': 1,
    'topper': 2,
    'toppers': 2,
    'toppin': 2,
    'topping': 2,
    'toppings': 2,
    'toppins': 2,
    'topple': 2,
    'toppled': 2,
    'topples': 2,
    'toppling': 3,
    'topps': 1,
    'tops': 1,
    'topsoil': 2,
    'topsy': 2,
    'toquepala': 4,
    'tor': 1,
    'torah': 2,
    'torain': 2,
    'torald': 2,
    'toran': 2,
    'toray': 2,
    'torbeck': 2,
    'torbert': 2,
    'torbett': 2,
    'torch': 1,
    'torched': 1,
    'torches': 2,
    'torchia': 3,
    'torching': 2,
    'torchmark': 2,
    'tordella': 3,
    'tore': 1,
    'torell': 2,
    'torelli': 3,
    'torello': 3,
    'toren': 2,
    'torey': 2,
    'torgersen': 3,
    'torgerson': 3,
    'torgeson': 3,
    'tori': 2,
    'torian': 3,
    'toribio': 4,
    'torie': 2,
    'toriente': 4,
    'tories': 2,
    'torino': 3,
    'torkelson': 3,
    'torley': 2,
    'torma': 2,
    'torme': 1,
    'torment': 2,
    'tormenta': 3,
    'tormented': 3,
    'tormenting': 3,
    'tormentor': 3,
    'tormentors': 3,
    'torments': 2,
    'tormey': 2,
    'torn': 1,
    'tornabene': 4,
    'tornadic': 3,
    'tornado': 3,
    'tornadoes': 3,
    'tornados': 3,
    'tornatore': 4,
    'torney': 2,
    'torno': 2,
    'tornow': 2,
    'tornquist': 2,
    'toro': 2,
    'torok': 2,
    'toronado': 4,
    'toronto': 3,
    'torosian': 3,
    'torp': 1,
    'torpedo': 3,
    'torpedoed': 3,
    'torpedoes': 3,
    'torpedoing': 4,
    'torpedos': 3,
    'torpey': 2,
    'torpid': 2,
    'torpor': 2,
    'torque': 1,
    'torr': 1,
    'torrance': 2,
    'torras': 2,
    'torray': 2,
    'torre': 1,
    'torregrossa': 4,
    'torrejon': 3,
    'torrence': 2,
    'torrens': 2,
    'torrent': 2,
    'torrential': 3,
    'torrents': 2,
    'torrenzano': 4,
    'torreon': 3,
    'torres': 2,
    'torrey': 2,
    'torrez': 2,
    'torricelli': 4,
    'torrid': 2,
    'torrijos': 3,
    'torrington': 3,
    'torrisi': 3,
    'torry': 2,
    'torsiello': 4,
    'torsion': 2,
    'torso': 2,
    'torsos': 2,
    'torstar': 2,
    'torsten': 2,
    'tort': 1,
    'torte': 1,
    'torti': 2,
    'tortilla': 3,
    'tortillas': 3,
    'tortoise': 2,
    'tortoises': 3,
    'tortora': 3,
    'tortorella': 4,
    'tortorelli': 4,
    'tortorello': 4,
    'tortorice': 3,
    'tortorici': 4,
    'tortoriello': 5,
    'torts': 1,
    'tortuous': 3,
    'torture': 2,
    'tortured': 2,
    'torturer': 3,
    'torturers': 3,
    'tortures': 2,
    'torturing': 3,
    'torturous': 3,
    'toru': 2,
    'torumi': 3,
    'tory': 2,
    'tosca': 2,
    'toscanini': 4,
    'toscano': 3,
    'tosch': 1,
    'tosco': 2,
    'tosh': 1,
    'toshiba': 3,
    'toshiharu': 4,
    'toshihiko': 4,
    'toshiki': 3,
    'toshimitsu': 4,
    'toshio': 3,
    'toshiyuki': 4,
    'tosi': 2,
    'toso': 2,
    'toss': 1,
    'tossed': 1,
    'tosses': 2,
    'tossing': 2,
    'tostado': 3,
    'toste': 1,
    'tostenson': 3,
    'tosti': 2,
    'tosto': 2,
    'tot': 1,
    'tota': 2,
    'total': 2,
    'totaled': 2,
    'totaling': 3,
    'totalitarian': 6,
    'totalitarianism': 8,
    'totalitarians': 6,
    'totality': 4,
    'totalled': 2,
    'totalling': 3,
    'totally': 3,
    'totals': 2,
    'totaro': 3,
    'tote': 1,
    'toted': 2,
    'totem': 2,
    'totems': 2,
    'totenberg': 3,
    'totes': 1,
    'toth': 1,
    'totherow': 3,
    'toti': 2,
    'toting': 2,
    'totman': 2,
    'toto': 2,
    'tots': 1,
    'totten': 2,
    'tottenberg': 3,
    'tottenham': 3,
    'totter': 2,
    'tottering': 3,
    'totton': 2,
    'totty': 2,
    'totzke': 2,
    'touareg': 3,
    'touch': 1,
    'touchable': 3,
    'touchdown': 2,
    'touchdowns': 2,
    'touche': 1,
    'touched': 1,
    'touches': 2,
    'touchet': 2,
    'touchette': 2,
    'touching': 2,
    'touchstone': 2,
    'touchton': 2,
    'touchy': 2,
    'toufexis': 3,
    'tougaloo': 3,
    'tougas': 2,
    'tough': 1,
    'toughed': 1,
    'toughen': 2,
    'toughened': 2,
    'toughening': 3,
    'toughens': 2,
    'tougher': 2,
    'toughest': 2,
    'toughie': 2,
    'toughness': 2,
    'toughs': 1,
    'touhey': 2,
    'toulouse': 2,
    'toupee': 2,
    'toupin': 2,
    'toups': 1,
    'tour': 1,
    'tourangeau': 3,
    'toured': 1,
    'tourette': 2,
    'touretzky': 3,
    'tourigny': 3,
    'touring': 2,
    'tourism': 3,
    'tourist': 2,
    'tourists': 2,
    'tourmaline': 3,
    'tournament': 3,
    'tournaments': 3,
    'tournedos': 3,
    'tourney': 2,
    'tourneys': 2,
    'tourniquet': 3,
    'tournquist': 2,
    'touro': 2,
    'tours': 1,
    'tourtelot': 3,
    'tourville': 2,
    'tousey': 2,
    'tousignant': 3,
    'tousley': 2,
    'toussaint': 2,
    'toussaud': 2,
    'toussie': 2,
    'tout': 1,
    'toutant': 2,
    'touted': 2,
    'touting': 2,
    'touts': 1,
    'touvier': 3,
    'tov': 1,
    'tovar': 2,
    'tovaz': 2,
    'tovey': 2,
    'tovia': 3,
    'tow': 1,
    'toward': 2,
    'towards': 2,
    'towbin': 2,
    'towboat': 2,
    'towe': 1,
    'towed': 1,
    'towel': 2,
    'toweling': 3,
    'towell': 2,
    'towels': 2,
    'tower': 2,
    'towered': 2,
    'towering': 3,
    'towers': 2,
    'towery': 3,
    'towey': 2,
    'towing': 2,
    'towle': 1,
    'towler': 2,
    'towles': 2,
    'town': 1,
    'towne': 1,
    'towner': 2,
    'towners': 2,
    'townes': 1,
    'townhouse': 2,
    'townhouses': 3,
    'townie': 2,
    'townley': 2,
    'towns': 1,
    'townsel': 2,
    'townsell': 2,
    'townsend': 2,
    'townsfolk': 2,
    'townshend': 2,
    'township': 2,
    'townships': 2,
    'townsley': 2,
    'townsman': 2,
    'townson': 2,
    'townspeople': 3,
    'towry': 2,
    'tows': 1,
    'towsley': 2,
    'towson': 2,
    'toxic': 2,
    'toxicity': 4,
    'toxicological': 6,
    'toxicologist': 5,
    'toxicologists': 5,
    'toxicology': 5,
    'toxics': 2,
    'toxin': 2,
    'toxins': 2,
    'toy': 1,
    'toya': 2,
    'toyama': 3,
    'toye': 1,
    'toyed': 1,
    'toying': 2,
    'toyko': 2,
    'toymaker': 3,
    'toymakers': 3,
    'toyo': 2,
    'toyobo': 3,
    'toyoda': 3,
    'toyoo': 2,
    'toyota': 3,
    'toyotas': 3,
    'toys': 1,
    'toystore': 2,
    'toystores': 2,
    'tozer': 2,
    'tozier': 3,
    'tozzi': 2,
    'traber': 2,
    'trabert': 2,
    'trabucco': 3,
    'trabue': 2,
    'trac': 1,
    'trace': 1,
    'traceable': 3,
    'traced': 1,
    'tracer': 2,
    'tracers': 2,
    'traces': 2,
    'tracey': 2,
    'trachea': 3,
    'tracheal': 3,
    'tracheophyte': 4,
    'tracheophytes': 4,
    'trachsel': 2,
    'tracht': 1,
    'trachtenberg': 3,
    'traci': 2,
    'tracie': 2,
    'tracinda': 3,
    'tracing': 2,
    'track': 1,
    'trackage': 2,
    'trackball': 2,
    'trackballs': 2,
    'tracked': 1,
    'tracker': 2,
    'trackers': 2,
    'tracking': 2,
    'tracks': 1,
    'tracor': 2,
    'tract': 1,
    'tractable': 3,
    'tractebel': 3,
    'traction': 2,
    'tractor': 2,
    'tractors': 2,
    'tracts': 1,
    'tracy': 2,
    'tracz': 1,
    'traczyk': 2,
    'tradable': 3,
    'trade': 1,
    'tradeable': 3,
    'traded': 2,
    'trademark': 2,
    'trademarked': 2,
    'trademarks': 2,
    'tradeoff': 2,
    'tradeoffs': 2,
    'trader': 2,
    'traders': 2,
    'trades': 1,
    'tradesmen': 2,
    'trading': 2,
    'tradings': 2,
    'tradition': 3,
    'traditional': 4,
    'traditionalist': 5,
    'traditionalists': 4,
    'traditionally': 5,
    'traditions': 3,
    'traeger': 2,
    'traer': 1,
    'trafalgar': 3,
    'traffic': 2,
    'trafficker': 3,
    'traffickers': 3,
    'trafficking': 3,
    'trafford': 2,
    'traficant': 3,
    'traficante': 4,
    'traficants': 3,
    'trafillio': 4,
    'trafton': 2,
    'tragedies': 3,
    'tragedy': 3,
    'trager': 2,
    'trageser': 3,
    'tragic': 2,
    'tragically': 3,
    'tragicomic': 4,
    'tragos': 2,
    'trahan': 2,
    'trahern': 2,
    'trail': 1,
    'trailblazer': 3,
    'trailblazers': 3,
    'trailed': 1,
    'trailer': 2,
    'trailers': 2,
    'trailhead': 2,
    'trailing': 2,
    'trails': 1,
    'trailways': 2,
    'train': 1,
    'traina': 2,
    'trainable': 3,
    'trained': 1,
    'trainee': 2,
    'trainees': 2,
    'trainer': 2,
    'trainers': 2,
    'training': 2,
    'trainmen': 2,
    'trainor': 2,
    'trains': 1,
    'traipse': 1,
    'traipsing': 2,
    'traister': 2,
    'trait': 1,
    'traitor': 2,
    'traitorous': 3,
    'traitors': 2,
    'traits': 1,
    'trajan': 2,
    'trajectory': 4,
    'trak': 1,
    'trakas': 2,
    'tram': 1,
    'tramble': 2,
    'tramco': 2,
    'tramel': 2,
    'tramell': 2,
    'tramiel': 3,
    'trammel': 2,
    'trammell': 2,
    'tramontana': 4,
    'tramontano': 4,
    'tramonte': 3,
    'tramontin': 3,
    'tramp': 1,
    'trampe': 1,
    'tramped': 1,
    'tramping': 2,
    'trample': 2,
    'trampled': 2,
    'tramples': 2,
    'trampling': 2,
    'trampoline': 3,
    'tramps': 1,
    'trams': 1,
    'tran': 1,
    'tranberg': 2,
    'trance': 1,
    'tranche': 1,
    'tranches': 2,
    'tranchina': 3,
    'trane': 1,
    'trang': 1,
    'trani': 2,
    'tranquil': 2,
    'tranquility': 4,
    'tranquilize': 3,
    'tranquilizer': 4,
    'tranquilizers': 4,
    'tranquilizing': 4,
    'trans': 1,
    'transact': 2,
    'transacted': 3,
    'transaction': 3,
    'transactions': 3,
    'transafrica': 4,
    'transalaska': 4,
    'transalta': 3,
    'transamerica': 5,
    'transamerican': 5,
    'transatlantic': 4,
    'transcanada': 4,
    'transcapital': 4,
    'transcend': 2,
    'transcended': 3,
    'transcendence': 3,
    'transcendent': 3,
    'transcendental': 4,
    'transcending': 3,
    'transcends': 2,
    'transchannel': 3,
    'transcisco': 3,
    'transco': 2,
    'transcon': 2,
    'transcontinental': 5,
    'transcribe': 2,
    'transcribed': 2,
    'transcriber': 3,
    'transcribers': 3,
    'transcribes': 2,
    'transcribing': 3,
    'transcript': 2,
    'transcription': 3,
    'transcriptions': 3,
    'transcripts': 2,
    'transducer': 3,
    'transducers': 3,
    'transect': 2,
    'transected': 3,
    'transection': 3,
    'transfer': 2,
    'transferability': 6,
    'transferable': 4,
    'transfered': 2,
    'transference': 3,
    'transfering': 3,
    'transferrable': 4,
    'transferred': 2,
    'transferring': 3,
    'transfers': 2,
    'transfix': 2,
    'transfixed': 2,
    'transform': 2,
    'transformation': 4,
    'transformational': 5,
    'transformations': 4,
    'transformed': 2,
    'transformer': 3,
    'transformers': 3,
    'transforming': 3,
    'transforms': 2,
    'transfuse': 2,
    'transfused': 2,
    'transfusion': 3,
    'transfusions': 3,
    'transgender': 3,
    'transgenic': 3,
    'transgress': 2,
    'transgressed': 2,
    'transgresses': 3,
    'transgressing': 3,
    'transgression': 3,
    'transgressions': 3,
    'transgressor': 3,
    'transience': 3,
    'transient': 2,
    'transients': 3,
    'transillumination': 6,
    'transimage': 3,
    'transistor': 3,
    'transistors': 3,
    'transit': 2,
    'transited': 3,
    'transiting': 3,
    'transition': 3,
    'transitional': 4,
    'transitioning': 4,
    'transitions': 3,
    'transitory': 4,
    'transits': 2,
    'transkei': 2,
    'translate': 2,
    'translated': 3,
    'translates': 2,
    'translating': 3,
    'translation': 3,
    'translations': 3,
    'translator': 3,
    'translators': 3,
    'translogic': 3,
    'translucent': 3,
    'transmark': 2,
    'transmedia': 4,
    'transmission': 3,
    'transmissions': 3,
    'transmit': 2,
    'transmits': 2,
    'transmittable': 4,
    'transmittal': 3,
    'transmitted': 3,
    'transmitter': 3,
    'transmitters': 3,
    'transmitting': 3,
    'transnational': 4,
    'transoceanic': 5,
    'transohio': 4,
    'transom': 2,
    'transoms': 2,
    'transou': 2,
    'transpac': 2,
    'transpacific': 4,
    'transparencies': 4,
    'transparency': 4,
    'transparent': 3,
    'transparently': 4,
    'transpark': 2,
    'transpire': 3,
    'transpired': 3,
    'transpires': 3,
    'transpiring': 4,
    'transplant': 2,
    'transplantation': 4,
    'transplanted': 3,
    'transplanting': 3,
    'transplants': 2,
    'transponder': 3,
    'transponders': 3,
    'transport': 2,
    'transportable': 4,
    'transportation': 4,
    'transported': 3,
    'transporter': 3,
    'transporters': 3,
    'transporting': 3,
    'transports': 2,
    'transpose': 2,
    'transposed': 2,
    'transracial': 3,
    'transrapid': 3,
    'transsexual': 4,
    'transsexuals': 4,
    'transtar': 2,
    'transtechnology': 5,
    'transtector': 3,
    'transue': 2,
    'transvaal': 2,
    'transverse': 2,
    'transvestite': 3,
    'transvestites': 3,
    'transway': 2,
    'transwestern': 3,
    'transworld': 2,
    'transylvania': 5,
    'trant': 1,
    'tranter': 2,
    'trantham': 2,
    'tranum': 2,
    'tranzonic': 3,
    'trap': 1,
    'trapani': 3,
    'trapasso': 3,
    'trapelo': 3,
    'trapeze': 2,
    'traphagen': 3,
    'trapnell': 2,
    'trapp': 1,
    'trappe': 1,
    'trapped': 1,
    'trapper': 2,
    'trappers': 2,
    'trapping': 2,
    'trappings': 2,
    'trappist': 2,
    'traps': 1,
    'trash': 1,
    'trashed': 1,
    'trashes': 2,
    'trashing': 2,
    'trashy': 2,
    'trask': 1,
    'traub': 1,
    'traudt': 1,
    'trauger': 2,
    'traugh': 1,
    'traughber': 2,
    'traugott': 2,
    'traum': 1,
    'trauma': 2,
    'traumas': 2,
    'traumatic': 3,
    'traumatize': 3,
    'traumatized': 3,
    'trausch': 1,
    'traut': 1,
    'trauth': 1,
    'trautman': 2,
    'trautmann': 2,
    'trautner': 2,
    'trautwein': 2,
    'travaglini': 4,
    'travail': 2,
    'travails': 2,
    'travel': 2,
    'travelday': 3,
    'traveldays': 3,
    'traveled': 2,
    'traveler': 3,
    'travelers': 3,
    'travelgate': 3,
    'traveling': 3,
    'travelled': 2,
    'traveller': 3,
    'travellers': 3,
    'travelling': 3,
    'travelodge': 3,
    'travelogue': 3,
    'travels': 2,
    'travelstead': 3,
    'travenol': 3,
    'traver': 2,
    'travers': 2,
    'traverse': 2,
    'traversed': 2,
    'traversing': 3,
    'traverso': 3,
    'travesties': 3,
    'travesty': 3,
    'traviata': 4,
    'travieso': 3,
    'travis': 2,
    'travisano': 4,
    'travnik': 2,
    'travolta': 3,
    'travoltas': 3,
    'traweek': 2,
    'trawick': 2,
    'trawler': 2,
    'trawlers': 2,
    'trax': 1,
    'traxler': 2,
    'tray': 1,
    'trayer': 2,
    'traylor': 2,
    'traynham': 2,
    'traynor': 2,
    'trays': 1,
    'traywick': 2,
    'trbovich': 3,
    'tre': 1,
    'treacherous': 3,
    'treachery': 3,
    'treacy': 2,
    'tread': 1,
    'treadaway': 3,
    'treading': 2,
    'treadmill': 2,
    'treadmills': 2,
    'treads': 1,
    'treadway': 2,
    'treadwell': 2,
    'treadwheel': 2,
    'treanor': 2,
    'trease': 1,
    'treason': 2,
    'treaster': 2,
    'treasure': 2,
    'treasured': 2,
    'treasurer': 3,
    'treasurers': 3,
    'treasures': 2,
    'treasuries': 3,
    'treasury': 3,
    'treasurys': 3,
    'treat': 1,
    'treatable': 3,
    'treated': 2,
    'treater': 2,
    'treaters': 2,
    'treaties': 2,
    'treating': 2,
    'treatise': 2,
    'treatises': 3,
    'treatment': 2,
    'treatments': 2,
    'treats': 1,
    'treaty': 2,
    'trebilcock': 3,
    'treble': 2,
    'trebled': 2,
    'treblinka': 3,
    'trecker': 2,
    'treder': 2,
    'tredway': 2,
    'tree': 1,
    'treece': 1,
    'treeless': 2,
    'treen': 1,
    'trees': 1,
    'treese': 1,
    'treesh': 1,
    'treesweet': 2,
    'treetop': 2,
    'treetops': 2,
    'trefethen': 3,
    'trefgarne': 2,
    'trefry': 2,
    'trefz': 1,
    'treglia': 3,
    'trego': 2,
    'tregoning': 3,
    'tregre': 2,
    'tregurtha': 3,
    'treharne': 2,
    'treiber': 2,
    'treichel': 2,
    'treichler': 3,
    'treinen': 2,
    'trejo': 2,
    'trek': 1,
    'trekked': 1,
    'trekking': 2,
    'treks': 1,
    'trela': 2,
    'trella': 2,
    'trelleborg': 3,
    'trellis': 2,
    'treloar': 2,
    'tremain': 2,
    'tremaine': 2,
    'tremayne': 2,
    'trembath': 2,
    'tremblay': 2,
    'tremble': 2,
    'trembled': 2,
    'trembley': 2,
    'trembling': 3,
    'trembly': 2,
    'tremel': 2,
    'tremendous': 3,
    'tremendously': 4,
    'tremens': 2,
    'treml': 2,
    'tremmel': 2,
    'tremont': 2,
    'tremor': 2,
    'tremors': 2,
    'tremper': 2,
    'tremulous': 3,
    'tremulously': 4,
    'trenary': 3,
    'trench': 1,
    'trenchant': 2,
    'trenchard': 2,
    'trencher': 2,
    'trenches': 2,
    'trend': 1,
    'trended': 2,
    'trendier': 2,
    'trendiest': 2,
    'trending': 2,
    'trendless': 2,
    'trendline': 2,
    'trends': 1,
    'trendsetter': 3,
    'trendy': 2,
    'trenholm': 2,
    'trenkamp': 2,
    'trenkle': 2,
    'trent': 1,
    'trentham': 2,
    'trentman': 2,
    'trenton': 2,
    'treon': 2,
    'trepagnier': 4,
    'trepanier': 4,
    'trepidation': 4,
    'treppel': 2,
    'treptow': 2,
    'tresch': 1,
    'trescott': 2,
    'trespass': 2,
    'trespassing': 3,
    'tress': 1,
    'tressel': 2,
    'tresses': 2,
    'tressler': 2,
    'trest': 1,
    'trester': 2,
    'trestle': 2,
    'trethewey': 3,
    'tretinoin': 3,
    'trettel': 2,
    'tretter': 2,
    'trettin': 2,
    'treu': 1,
    'treuhand': 2,
    'treuhandanstalt': 4,
    'treurnicht': 2,
    'trevathan': 3,
    'trevelyan': 3,
    'trevino': 3,
    'treviso': 3,
    'trevizo': 3,
    'trevor': 2,
    'trew': 1,
    'trexler': 2,
    'trey': 1,
    'treybig': 2,
    'trezise': 2,
    'trezza': 2,
    'tri': 1,
    'triad': 2,
    'triads': 2,
    'triage': 2,
    'trial': 2,
    'trials': 2,
    'triana': 3,
    'triangle': 3,
    'triangles': 3,
    'triangular': 4,
    'triangulation': 5,
    'triano': 3,
    'triarc': 2,
    'triassic': 3,
    'triathlon': 3,
    'trib': 1,
    'tribal': 2,
    'tribalism': 3,
    'tribasa': 3,
    'tribbett': 2,
    'tribbey': 2,
    'tribble': 2,
    'tribbles': 2,
    'tribby': 2,
    'tribe': 1,
    'tribeca': 3,
    'tribes': 1,
    'tribesman': 2,
    'tribesmen': 2,
    'trible': 2,
    'triborough': 3,
    'tribulation': 4,
    'tribulations': 4,
    'tribull': 2,
    'tribunal': 3,
    'tribunals': 3,
    'tribune': 2,
    'tributaries': 4,
    'tributary': 4,
    'tribute': 2,
    'tributes': 2,
    'tricarico': 4,
    'trice': 1,
    'tricentrol': 3,
    'triceps': 2,
    'triceratops': 4,
    'triche': 1,
    'trichet': 2,
    'tricia': 2,
    'tricil': 2,
    'trick': 1,
    'tricked': 1,
    'trickel': 2,
    'trickery': 3,
    'trickett': 2,
    'trickey': 2,
    'trickier': 3,
    'trickiest': 3,
    'tricking': 2,
    'trickle': 2,
    'trickled': 2,
    'trickles': 2,
    'trickling': 2,
    'tricks': 1,
    'trickster': 2,
    'tricksters': 2,
    'tricky': 2,
    'trico': 2,
    'tricuspid': 3,
    'tricycle': 3,
    'trident': 2,
    'tridex': 2,
    'trie': 1,
    'tried': 1,
    'triennial': 4,
    'trier': 2,
    'trierweiler': 3,
    'tries': 1,
    'trieste': 1,
    'trieu': 1,
    'trifari': 3,
    'trifecta': 3,
    'trifle': 2,
    'trifles': 2,
    'trifling': 2,
    'trig': 1,
    'trigg': 1,
    'trigger': 2,
    'triggered': 2,
    'triggering': 3,
    'triggers': 2,
    'triggs': 1,
    'triglyceride': 4,
    'triglycerides': 4,
    'trigo': 2,
    'trilateral': 4,
    'trilby': 2,
    'trill': 1,
    'trillin': 2,
    'trilling': 2,
    'trillion': 2,
    'trillions': 2,
    'trillo': 2,
    'trilogy': 3,
    'trilon': 2,
    'trim': 1,
    'trimac': 2,
    'trimarchi': 3,
    'trimarco': 3,
    'trimas': 2,
    'trimble': 2,
    'trimboli': 3,
    'trimedyne': 2,
    'trimester': 3,
    'trimetrexate': 4,
    'trimm': 1,
    'trimmed': 1,
    'trimmer': 2,
    'trimmers': 2,
    'trimming': 2,
    'trimmings': 2,
    'trimpe': 1,
    'trims': 1,
    'trincomalee': 4,
    'trine': 1,
    'tringali': 3,
    'trinh': 1,
    'trinidad': 3,
    'trinity': 3,
    'trinka': 2,
    'trinket': 2,
    'trinkets': 2,
    'trinkle': 2,
    'trinova': 3,
    'trintex': 2,
    'trio': 2,
    'triola': 3,
    'triolo': 3,
    'triomphe': 2,
    'trip': 1,
    'tripartite': 3,
    'tripe': 1,
    'tripi': 2,
    'triple': 2,
    'triplecast': 3,
    'tripled': 2,
    'triples': 2,
    'triplet': 2,
    'triplets': 2,
    'triplett': 2,
    'triplex': 2,
    'triplicate': 3,
    'tripling': 3,
    'tripod': 2,
    'tripodi': 3,
    'tripods': 2,
    'tripoli': 3,
    'tripp': 1,
    'trippe': 1,
    'tripped': 1,
    'trippel': 2,
    'tripper': 2,
    'trippers': 2,
    'trippett': 2,
    'trippie': 2,
    'tripping': 2,
    'tripple': 2,
    'trips': 1,
    'triptych': 2,
    'triremes': 2,
    'trish': 1,
    'trisha': 2,
    'triska': 2,
    'trisler': 3,
    'trism': 2,
    'trista': 2,
    'tristan': 2,
    'tristar': 2,
    'tristate': 2,
    'tristram': 2,
    'tritch': 1,
    'trite': 1,
    'trites': 1,
    'tritium': 3,
    'triton': 2,
    'tritsch': 1,
    'tritschler': 2,
    'tritt': 1,
    'tritz': 1,
    'triumph': 2,
    'triumphal': 3,
    'triumphant': 3,
    'triumphantly': 4,
    'triumphed': 2,
    'triumphs': 2,
    'triumvirate': 4,
    'trivedi': 3,
    'trivest': 2,
    'trivett': 2,
    'trivette': 2,
    'trivia': 3,
    'trivial': 3,
    'triviality': 5,
    'trivialize': 4,
    'trivialized': 4,
    'trivializes': 5,
    'trivializing': 5,
    'trixie': 2,
    'trixy': 2,
    'trizec': 2,
    'trnka': 2,
    'trnopolje': 4,
    'trobaugh': 2,
    'troche': 1,
    'trochmann': 2,
    'trocki': 2,
    'trod': 1,
    'trodden': 2,
    'troeger': 2,
    'troendle': 2,
    'troester': 2,
    'trogdon': 2,
    'troha': 2,
    'troia': 2,
    'troiani': 3,
    'troiano': 4,
    'troika': 2,
    'trois': 1,
    'troise': 1,
    'troisi': 2,
    'trojan': 2,
    'trojanowski': 4,
    'trojans': 2,
    'trokel': 2,
    'trolinger': 3,
    'troll': 1,
    'trolley': 2,
    'trolleys': 2,
    'trolling': 2,
    'trollinger': 3,
    'troma': 2,
    'trombino': 3,
    'tromble': 2,
    'trombley': 2,
    'trombly': 2,
    'trombone': 2,
    'trombones': 2,
    'trombonist': 3,
    'tromp': 1,
    'trompeter': 3,
    'tron': 1,
    'troncoso': 3,
    'trone': 1,
    'tronic': 2,
    'troon': 1,
    'troop': 1,
    'trooped': 1,
    'trooper': 2,
    'troopers': 2,
    'trooping': 2,
    'troops': 1,
    'troost': 1,
    'tropea': 3,
    'tropeano': 3,
    'trophies': 2,
    'trophy': 2,
    'tropic': 2,
    'tropical': 3,
    'tropicana': 4,
    'tropics': 2,
    'tropopause': 3,
    'tropp': 1,
    'tropworld': 2,
    'trosch': 1,
    'trosclair': 2,
    'trosper': 2,
    'trost': 1,
    'trostel': 2,
    'trostle': 2,
    'trot': 1,
    'troth': 1,
    'trotman': 2,
    'trots': 1,
    'trotsky': 2,
    'trott': 1,
    'trotted': 2,
    'trotten': 2,
    'trotter': 2,
    'trottier': 3,
    'trotting': 2,
    'troubadour': 3,
    'troubh': 1,
    'trouble': 2,
    'troubled': 2,
    'troublefield': 3,
    'troublemaker': 4,
    'troublemakers': 4,
    'troubles': 2,
    'troubleshooter': 4,
    'troublesome': 3,
    'troubling': 3,
    'troudt': 1,
    'trough': 1,
    'troughs': 1,
    'trounce': 1,
    'trounced': 1,
    'trouncing': 2,
    'troung': 1,
    'troup': 1,
    'troupe': 1,
    'troupes': 1,
    'trousdale': 3,
    'trouser': 2,
    'trousers': 2,
    'trout': 1,
    'troutman': 2,
    'troutner': 2,
    'troutt': 1,
    'troutwine': 2,
    'trovato': 3,
    'trovatore': 3,
    'trove': 1,
    'trow': 1,
    'trowbridge': 2,
    'trowel': 2,
    'trowell': 2,
    'trower': 2,
    'troxel': 2,
    'troxell': 2,
    'troxler': 2,
    'troy': 1,
    'troyan': 2,
    'troyanos': 3,
    'troyat': 2,
    'troyer': 2,
    'troyka': 2,
    'troyu': 2,
    'truancy': 3,
    'truant': 2,
    'truax': 2,
    'trubey': 2,
    'truby': 2,
    'trucco': 2,
    'truce': 1,
    'truchan': 2,
    'truck': 1,
    'trucked': 1,
    'truckee': 2,
    'trucker': 2,
    'truckers': 2,
    'trucking': 2,
    'truckload': 2,
    'truckloads': 2,
    'trucks': 1,
    'truculent': 3,
    'truda': 2,
    'trude': 1,
    'trudeau': 2,
    'trudel': 2,
    'trudell': 2,
    'trudge': 1,
    'trudged': 1,
    'trudgen': 2,
    'trudges': 2,
    'trudging': 2,
    'trudia': 3,
    'trudie': 2,
    'trudo': 2,
    'trudy': 2,
    'true': 1,
    'trueblood': 2,
    'truell': 2,
    'truelove': 2,
    'trueman': 2,
    'truer': 2,
    'truesdale': 2,
    'truesdell': 2,
    'truest': 2,
    'truett': 1,
    'truex': 2,
    'truffaut': 2,
    'truffle': 2,
    'truffles': 2,
    'truglio': 3,
    'truicko': 2,
    'truism': 3,
    'truitt': 1,
    'trujillo': 3,
    'trull': 1,
    'trullinger': 3,
    'trulock': 2,
    'trulove': 3,
    'trulson': 2,
    'truluck': 2,
    'truly': 2,
    'truman': 2,
    'trumble': 2,
    'trumbo': 2,
    'trumbull': 2,
    'trumka': 2,
    'trump': 1,
    'trumped': 1,
    'trumpet': 2,
    'trumpeted': 3,
    'trumpeter': 3,
    'trumpeting': 3,
    'trumpets': 2,
    'trumpower': 3,
    'trumps': 1,
    'truncate': 2,
    'truncated': 3,
    'truncates': 2,
    'truncating': 3,
    'truncation': 3,
    'truncheon': 2,
    'truncheons': 2,
    'trundle': 2,
    'trundled': 2,
    'trunk': 1,
    'trunkline': 2,
    'trunks': 1,
    'trunnell': 2,
    'trunzo': 2,
    'truong': 1,
    'trupiano': 4,
    'trupin': 2,
    'trupp': 1,
    'truro': 2,
    'trus': 1,
    'truscott': 2,
    'truskowski': 3,
    'trusler': 3,
    'truslow': 2,
    'truss': 1,
    'trussell': 2,
    'trusses': 2,
    'trust': 1,
    'trustco': 2,
    'trustcorp': 2,
    'trusted': 2,
    'trustee': 2,
    'trustees': 2,
    'trusteeship': 3,
    'trusthouse': 2,
    'trusting': 2,
    'trusts': 1,
    'trustworthiness': 4,
    'trustworthy': 3,
    'trusty': 2,
    'truszkowski': 3,
    'truth': 1,
    'truthful': 2,
    'truthfully': 3,
    'truthfulness': 3,
    'truths': 1,
    'truxal': 2,
    'truxillo': 3,
    'try': 1,
    'tryart': 2,
    'tryba': 2,
    'trybus': 2,
    'trygg': 1,
    'trygstad': 2,
    'trygve': 2,
    'trying': 2,
    'tryon': 2,
    'tryout': 2,
    'tryouts': 2,
    'tryphena': 3,
    'tryst': 1,
    'trzaska': 3,
    'trzcinski': 3,
    'trzeciak': 4,
    'ts': 2,
    'tsai': 1,
    'tsakos': 2,
    'tsang': 1,
    'tsao': 1,
    'tsar': 1,
    'tschantz': 1,
    'tschetter': 2,
    'tschida': 2,
    'tschirhart': 2,
    'tschopp': 1,
    'tschudy': 2,
    'tschumi': 2,
    'tse': 1,
    'tsemel': 2,
    'tseng': 1,
    'tsetse': 2,
    'tshisekedi': 4,
    'tsiang': 1,
    'tsingtao': 2,
    'tso': 1,
    'tsongas': 2,
    'tsu': 1,
    'tsuda': 2,
    'tsui': 2,
    'tsuji': 2,
    'tsukamoto': 4,
    'tsukuba': 3,
    'tsunami': 3,
    'tsuneo': 3,
    'tsurumi': 3,
    'tsutomu': 3,
    'tu': 1,
    'tub': 1,
    'tuba': 2,
    'tubal': 2,
    'tubb': 1,
    'tubbs': 1,
    'tubby': 2,
    'tube': 1,
    'tuberculosis': 5,
    'tuberville': 3,
    'tubes': 1,
    'tubing': 2,
    'tubman': 2,
    'tubs': 1,
    'tubular': 3,
    'tubule': 2,
    'tucci': 2,
    'tucciarone': 4,
    'tuccillo': 3,
    'tucek': 2,
    'tuch': 1,
    'tuchman': 2,
    'tucholski': 3,
    'tuck': 1,
    'tucked': 1,
    'tucker': 2,
    'tuckerman': 3,
    'tuckett': 2,
    'tuckey': 2,
    'tucking': 2,
    'tuckman': 2,
    'tucks': 1,
    'tucson': 2,
    'tucuman': 3,
    'tude': 1,
    'tudisco': 3,
    'tudjman': 2,
    'tudor': 2,
    'tue': 1,
    'tuel': 1,
    'tuell': 1,
    'tuesday': 2,
    'tuesdays': 2,
    'tufa': 2,
    'tufano': 3,
    'tuff': 1,
    'tufnel': 2,
    'tufo': 2,
    'tuft': 1,
    'tufte': 1,
    'tufted': 2,
    'tuftlike': 2,
    'tufts': 1,
    'tug': 1,
    'tugboat': 2,
    'tugboats': 2,
    'tugged': 1,
    'tugging': 2,
    'tuggle': 2,
    'tugman': 2,
    'tugs': 1,
    'tugwell': 2,
    'tuhan': 2,
    'tuinstra': 3,
    'tuite': 1,
    'tuition': 3,
    'tuitions': 3,
    'tuk': 1,
    'tuks': 1,
    'tula': 2,
    'tulane': 2,
    'tuley': 2,
    'tulip': 2,
    'tulips': 2,
    'tull': 1,
    'tullar': 2,
    'tuller': 2,
    'tulley': 2,
    'tullia': 3,
    'tullier': 3,
    'tullio': 3,
    'tullis': 2,
    'tullius': 3,
    'tullo': 2,
    'tulloch': 2,
    'tullock': 2,
    'tullos': 2,
    'tully': 2,
    'tulsa': 2,
    'tuma': 2,
    'tumacoceri': 5,
    'tuman': 2,
    'tumazos': 3,
    'tumble': 2,
    'tumbled': 2,
    'tumbler': 2,
    'tumblers': 2,
    'tumbles': 2,
    'tumbleson': 3,
    'tumblin': 2,
    'tumbling': 3,
    'tumeric': 3,
    'tumescent': 3,
    'tumey': 2,
    'tuminello': 4,
    'tumlin': 2,
    'tumlinson': 3,
    'tummies': 2,
    'tumminello': 4,
    'tummond': 2,
    'tummy': 2,
    'tumolo': 3,
    'tumor': 2,
    'tumors': 2,
    'tums': 1,
    'tumult': 2,
    'tumultuous': 4,
    'tumulty': 3,
    'tuna': 2,
    'tunable': 3,
    'tunafish': 3,
    'tunas': 2,
    'tunde': 1,
    'tundra': 2,
    'tune': 1,
    'tuned': 1,
    'tuneful': 2,
    'tuner': 2,
    'tunes': 1,
    'tuneup': 2,
    'tung': 1,
    'tungate': 2,
    'tungsram': 2,
    'tungsrom': 2,
    'tungsten': 2,
    'tunic': 2,
    'tunica': 3,
    'tunicate': 3,
    'tunick': 2,
    'tuning': 2,
    'tunis': 2,
    'tunisia': 3,
    'tunisian': 3,
    'tunison': 3,
    'tunkelang': 2,
    'tunks': 1,
    'tunku': 2,
    'tunnel': 2,
    'tunneling': 3,
    'tunnell': 2,
    'tunnels': 2,
    'tunney': 2,
    'tunny': 2,
    'tunstall': 2,
    'tuohey': 2,
    'tuohy': 2,
    'tuolumne': 2,
    'tuomi': 2,
    'tupa': 2,
    'tupac': 2,
    'tupelo': 3,
    'tupelov': 3,
    'tupper': 2,
    'tupperware': 3,
    'tupy': 2,
    'turandot': 3,
    'turano': 3,
    'turban': 2,
    'turbans': 2,
    'turbas': 2,
    'turben': 2,
    'turberville': 3,
    'turbett': 2,
    'turbeville': 2,
    'turbidity': 4,
    'turbin': 2,
    'turbine': 2,
    'turbinen': 3,
    'turbines': 2,
    'turbo': 2,
    'turbocharge': 3,
    'turbocharged': 3,
    'turbocharges': 4,
    'turbocharging': 4,
    'turbofan': 3,
    'turbojet': 3,
    'turboprop': 3,
    'turboprops': 3,
    'turbos': 2,
    'turbot': 2,
    'turbotax': 3,
    'turbulence': 3,
    'turbulent': 3,
    'turbyfill': 3,
    'turchi': 2,
    'turchin': 2,
    'turchyn': 2,
    'turck': 1,
    'turco': 2,
    'turcott': 2,
    'turcotte': 2,
    'ture': 1,
    'turek': 2,
    'turenne': 2,
    'turf': 1,
    'turgeon': 2,
    'turgid': 2,
    'turgut': 2,
    'turi': 2,
    'turin': 2,
    'turing': 2,
    'turismo': 3,
    'turk': 1,
    'turkel': 2,
    'turkey': 2,
    'turkeys': 2,
    'turkic': 2,
    'turkington': 3,
    'turkish': 2,
    'turkmen': 2,
    'turkmenistan': 4,
    'turko': 2,
    'turkovich': 3,
    'turks': 1,
    'turley': 2,
    'turlington': 3,
    'turlough': 2,
    'turman': 2,
    'turmel': 2,
    'turmoil': 2,
    'turn': 1,
    'turnabout': 3,
    'turnage': 2,
    'turnaround': 3,
    'turnarounds': 3,
    'turnbaugh': 2,
    'turnbo': 2,
    'turnbough': 2,
    'turnbow': 2,
    'turnbridge': 2,
    'turnbull': 2,
    'turncoat': 2,
    'turndown': 2,
    'turned': 1,
    'turner': 2,
    'turney': 2,
    'turnham': 2,
    'turning': 2,
    'turnip': 2,
    'turnips': 2,
    'turnipseed': 3,
    'turnkey': 2,
    'turnley': 2,
    'turnmire': 2,
    'turnoff': 2,
    'turnout': 2,
    'turnouts': 2,
    'turnover': 3,
    'turnovers': 3,
    'turnpike': 2,
    'turnpikes': 2,
    'turnquest': 2,
    'turnquist': 2,
    'turns': 1,
    'turnstile': 2,
    'turnstiles': 2,
    'turntable': 3,
    'turntables': 3,
    'turo': 2,
    'turow': 2,
    'turowski': 3,
    'turpen': 2,
    'turpentine': 3,
    'turpin': 2,
    'turpitude': 3,
    'turquoise': 2,
    'turrell': 2,
    'turrentine': 4,
    'turret': 2,
    'turrets': 2,
    'turri': 2,
    'turrill': 2,
    'tursi': 2,
    'turski': 2,
    'turtle': 2,
    'turtleneck': 3,
    'turtlenecks': 3,
    'turtles': 2,
    'turton': 2,
    'turvey': 2,
    'turville': 2,
    'turvy': 2,
    'tusa': 2,
    'tuscaloosa': 4,
    'tuscan': 2,
    'tuscany': 3,
    'tush': 1,
    'tushes': 2,
    'tusing': 2,
    'tusk': 1,
    'tuskegee': 3,
    'tusks': 1,
    'tusla': 2,
    'tussey': 2,
    'tussing': 2,
    'tussle': 2,
    'tussled': 2,
    'tussles': 2,
    'tustin': 2,
    'tut': 1,
    'tutelage': 3,
    'tuten': 2,
    'tuthill': 2,
    'tutino': 3,
    'tutko': 2,
    'tutor': 2,
    'tutored': 2,
    'tutorial': 4,
    'tutorials': 4,
    'tutoring': 3,
    'tutors': 2,
    'tutsi': 2,
    'tutsis': 2,
    'tutt': 1,
    'tutterow': 3,
    'tutti': 2,
    'tuttle': 2,
    'tutton': 2,
    'tutu': 2,
    'tutwiler': 3,
    'tux': 1,
    'tuxedo': 3,
    'tuxedos': 3,
    'tuxford': 2,
    'tuxhorn': 2,
    'tuyle': 1,
    'tuzla': 2,
    'tuzzolino': 4,
    'tv': 2,
    'tvedt': 1,
    'tveit': 1,
    'tvsat': 2,
    'twaddell': 2,
    'twaddle': 2,
    'twain': 1,
    'twang': 1,
    'twangy': 2,
    'twardowski': 3,
    'twardy': 2,
    'twarog': 2,
    'twas': 1,
    'tweak': 1,
    'tweaked': 1,
    'tweaking': 2,
    'tweaks': 1,
    'twedt': 1,
    'tweed': 1,
    'tweedie': 2,
    'tweedle': 2,
    'tweedy': 2,
    'tweet': 1,
    'tweezer': 2,
    'tweezerman': 3,
    'tweezers': 2,
    'twelfth': 1,
    'twelve': 1,
    'twelvth': 1,
    'twenties': 2,
    'twentieth': 3,
    'twenty': 2,
    'twentysomething': 4,
    'twentysomethings': 4,
    'twersky': 2,
    'tweten': 2,
    'twice': 1,
    'twichell': 2,
    'twiddle': 2,
    'twiddling': 2,
    'twiddy': 2,
    'twiford': 2,
    'twig': 1,
    'twigg': 1,
    'twigged': 1,
    'twiggs': 1,
    'twiggy': 2,
    'twigs': 1,
    'twilight': 2,
    'twill': 1,
    'twilley': 2,
    'twin': 1,
    'twine': 1,
    'twined': 1,
    'twinge': 1,
    'twining': 2,
    'twinjet': 2,
    'twinjets': 2,
    'twinkie': 2,
    'twinkies': 2,
    'twinkle': 2,
    'twinkled': 2,
    'twinkles': 2,
    'twinkling': 3,
    'twins': 1,
    'twinsburg': 2,
    'twirl': 1,
    'twirled': 1,
    'twirler': 2,
    'twirling': 2,
    'twirls': 1,
    'twiss': 1,
    'twist': 1,
    'twisted': 2,
    'twister': 2,
    'twisters': 2,
    'twisting': 2,
    'twists': 1,
    'twisty': 2,
    'twitch': 1,
    'twitched': 1,
    'twitchell': 2,
    'twitches': 2,
    'twitching': 2,
    'twite': 1,
    'twitty': 2,
    'two': 1,
    'twofold': 2,
    'twohig': 2,
    'twombly': 2,
    'twomey': 2,
    'twonshein': 2,
    'tworek': 2,
    'twos': 1,
    'twosome': 2,
    'twothirds': 2,
    'twyford': 2,
    'twyla': 2,
    'twyman': 2,
    'ty': 1,
    'tybalt': 2,
    'tyburski': 3,
    'tyce': 1,
    'tyco': 2,
    'tycoon': 2,
    'tycoons': 2,
    'tydings': 2,
    'tye': 1,
    'tyer': 2,
    'tygar': 2,
    'tygart': 2,
    'tyger': 2,
    'tying': 2,
    'tyke': 1,
    'tylan': 2,
    'tylee': 2,
    'tylenol': 3,
    'tyler': 2,
    'tylka': 2,
    'tyminski': 3,
    'tymnet': 2,
    'tympanic': 3,
    'tympanum': 3,
    'tynan': 2,
    'tyndall': 2,
    'tyne': 1,
    'tyner': 2,
    'tynes': 1,
    'tyo': 2,
    'type': 1,
    'typeable': 3,
    'typecast': 2,
    'typecasting': 3,
    'typed': 1,
    'typeface': 2,
    'typefaces': 3,
    'types': 1,
    'typeset': 2,
    'typesetting': 3,
    'typewriter': 3,
    'typewriters': 3,
    'typewriting': 3,
    'typewritten': 3,
    'typhoid': 2,
    'typhoon': 2,
    'typhoons': 2,
    'typhus': 2,
    'typical': 3,
    'typically': 3,
    'typified': 3,
    'typifies': 3,
    'typify': 3,
    'typing': 2,
    'typings': 2,
    'typist': 2,
    'typists': 2,
    'typo': 2,
    'typographical': 5,
    'typography': 4,
    'tyra': 2,
    'tyrannical': 4,
    'tyrannies': 3,
    'tyrannosaurus': 5,
    'tyranny': 3,
    'tyrant': 2,
    'tyrants': 2,
    'tyre': 1,
    'tyree': 2,
    'tyrell': 2,
    'tyrone': 2,
    'tyrrell': 2,
    'tyrrhenian': 4,
    'tysinger': 3,
    'tyson': 2,
    'tysons': 2,
    'tyus': 1,
    'u': 1,
    'uarco': 3,
    'uart': 2,
    'ubben': 2,
    'uber': 2,
    'ubiquitous': 4,
    'ubiquity': 4,
    'uccel': 2,
    'ucci': 2,
    'uchida': 3,
    'uclaf': 2,
    'udagawa': 4,
    'udale': 2,
    'udall': 2,
    'uday': 2,
    'uddin': 2,
    'ude': 1,
    'udell': 2,
    'udelle': 2,
    'uden': 2,
    'udo': 2,
    'udolf': 2,
    'udvar': 2,
    'udverhye': 3,
    'udy': 2,
    'uebel': 2,
    'ueberroth': 3,
    'uecker': 2,
    'ueckert': 2,
    'ueda': 2,
    'uehara': 4,
    'uehling': 2,
    'ueki': 3,
    'ueland': 2,
    'uelman': 2,
    'uelmen': 2,
    'uemura': 4,
    'ueno': 2,
    'uenohara': 4,
    'uffelman': 3,
    'ugalde': 3,
    'uganda': 3,
    'ugandan': 3,
    'ugarte': 3,
    'ugh': 1,
    'ugity': 3,
    'uglier': 3,
    'ugliest': 3,
    'ugliness': 3,
    'ugly': 2,
    'ugolin': 3,
    'ugolini': 4,
    'ugric': 2,
    'uh': 1,
    'uhde': 1,
    'uher': 2,
    'uhh': 1,
    'uhl': 1,
    'uhland': 2,
    'uhle': 2,
    'uhler': 2,
    'uhlich': 2,
    'uhlig': 2,
    'uhlir': 2,
    'uhlman': 2,
    'uhlmann': 2,
    'uhls': 1,
    'uhr': 1,
    'uhrich': 2,
    'uhrig': 2,
    'uhrin': 2,
    'uinta': 3,
    'ukase': 2,
    'ukman': 2,
    'ukraine': 2,
    'ukrainian': 4,
    'ukrainians': 4,
    'ukulele': 4,
    'ul': 1,
    'ula': 2,
    'ulam': 2,
    'uland': 2,
    'ulatowski': 4,
    'ulbrich': 2,
    'ulbricht': 2,
    'ulcer': 2,
    'ulceration': 4,
    'ulcerations': 4,
    'ulcers': 2,
    'ulch': 1,
    'ulee': 2,
    'ulery': 3,
    'ulf': 1,
    'ulfred': 2,
    'ulger': 2,
    'uli': 2,
    'uliano': 4,
    'uliaski': 3,
    'uliassi': 3,
    'ulibarri': 4,
    'ulicny': 3,
    'ulima': 3,
    'ulin': 2,
    'ulitsa': 3,
    'ulland': 2,
    'ullenberg': 3,
    'ullery': 3,
    'ullman': 2,
    'ullmann': 2,
    'ullmer': 2,
    'ulloa': 3,
    'ullock': 2,
    'ullom': 2,
    'ullrich': 2,
    'ullyses': 3,
    'ulm': 1,
    'ulman': 2,
    'ulmar': 2,
    'ulmer': 2,
    'ulmus': 2,
    'ulrey': 2,
    'ulric': 2,
    'ulrica': 3,
    'ulrich': 2,
    'ulrick': 2,
    'ulsan': 2,
    'ulsh': 1,
    'ulshafer': 3,
    'ulster': 2,
    'ulterior': 4,
    'ultima': 3,
    'ultimate': 3,
    'ultimately': 4,
    'ultimatum': 4,
    'ultimatums': 4,
    'ultra': 2,
    'ultraconservative': 6,
    'ultrair': 2,
    'ultramar': 3,
    'ultramodern': 4,
    'ultranationalist': 6,
    'ultranationalists': 6,
    'ultrasonic': 4,
    'ultrasound': 3,
    'ultrasystem': 4,
    'ultrasystems': 4,
    'ultraviolet': 5,
    'ulundi': 3,
    'ulva': 2,
    'ulvert': 2,
    'ulysses': 3,
    'um': 1,
    'uma': 2,
    'umana': 3,
    'umansky': 3,
    'umass': 2,
    'umbach': 2,
    'umbarger': 3,
    'umbaugh': 2,
    'umbel': 2,
    'umberger': 3,
    'umberto': 3,
    'umbilical': 4,
    'umble': 2,
    'umbra': 2,
    'umbrage': 2,
    'umbrella': 3,
    'umbrellas': 3,
    'umbria': 3,
    'umetsu': 3,
    'umfleet': 2,
    'umholtz': 2,
    'umland': 2,
    'umlasi': 3,
    'umlauf': 2,
    'umm': 1,
    'ummel': 2,
    'umphlett': 2,
    'umphress': 2,
    'umphrey': 2,
    'umpire': 3,
    'umpires': 3,
    'umpteen': 2,
    'umpteenth': 2,
    'umscheid': 2,
    'umstead': 2,
    'un': 1,
    'una': 2,
    'unabashed': 3,
    'unabashedly': 5,
    'unabated': 4,
    'unable': 3,
    'unabom': 3,
    'unabomb': 3,
    'unabomber': 4,
    'unabombers': 4,
    'unabombing': 4,
    'unabombings': 4,
    'unabridged': 3,
    'unacceptable': 5,
    'unacceptably': 5,
    'unaccompanied': 5,
    'unaccountable': 5,
    'unaccountably': 5,
    'unaccounted': 4,
    'unaccredited': 5,
    'unaccustomed': 4,
    'unaddressed': 3,
    'unadjust': 3,
    'unadjustable': 5,
    'unadjusted': 4,
    'unadorn': 3,
    'unadorned': 3,
    'unadulterated': 6,
    'unaffected': 4,
    'unaffiliate': 5,
    'unaffiliated': 6,
    'unaffordable': 5,
    'unafraid': 3,
    'unaided': 3,
    'unalienable': 6,
    'unallocate': 4,
    'unallocated': 5,
    'unallowable': 5,
    'unalloyed': 3,
    'unalterable': 5,
    'unalterably': 5,
    'unaltered': 3,
    'unambiguous': 5,
    'unambiguously': 6,
    'unamended': 4,
    'unamerican': 5,
    'unamortized': 4,
    'unangst': 2,
    'unanimity': 5,
    'unanimous': 4,
    'unanimously': 5,
    'unannounced': 3,
    'unanswerable': 5,
    'unanswered': 3,
    'unanticipated': 6,
    'unapologetic': 6,
    'unappealing': 4,
    'unappetizing': 5,
    'unappreciated': 6,
    'unapproved': 3,
    'unarm': 2,
    'unarmed': 2,
    'unashamed': 3,
    'unashamedly': 5,
    'unassailable': 5,
    'unassisted': 4,
    'unassuming': 4,
    'unattach': 3,
    'unattached': 3,
    'unattainable': 5,
    'unattended': 4,
    'unattractive': 4,
    'unaudited': 4,
    'unauthentic': 4,
    'unauthorized': 4,
    'unavailability': 7,
    'unavailable': 5,
    'unavailing': 4,
    'unavoidable': 5,
    'unavoidably': 5,
    'unaware': 3,
    'unawares': 3,
    'unbalance': 3,
    'unbalanced': 3,
    'unbearable': 4,
    'unbearably': 4,
    'unbeatable': 4,
    'unbeaten': 3,
    'unbecoming': 4,
    'unbeknown': 3,
    'unbeknownst': 3,
    'unbelievable': 5,
    'unbelievably': 5,
    'unbelieving': 4,
    'unbend': 2,
    'unbending': 3,
    'unbiased': 3,
    'unblemished': 3,
    'unbolt': 2,
    'unbolted': 3,
    'unbook': 2,
    'unborn': 2,
    'unbound': 2,
    'unbounded': 3,
    'unbowed': 2,
    'unbranded': 3,
    'unbreakability': 6,
    'unbreakable': 4,
    'unbridled': 3,
    'unbroken': 3,
    'unbuildable': 4,
    'unbuilt': 2,
    'unbundle': 3,
    'unbundled': 3,
    'unbundling': 3,
    'unburden': 3,
    'unburdened': 3,
    'unburned': 2,
    'unbutton': 3,
    'unbuttoned': 3,
    'uncalled': 2,
    'uncannily': 4,
    'uncanny': 3,
    'uncapher': 3,
    'uncapitalized': 5,
    'uncaring': 3,
    'unceasing': 3,
    'uncensored': 3,
    'unceremonious': 6,
    'unceremoniously': 7,
    'uncertain': 3,
    'uncertainly': 4,
    'uncertainties': 4,
    'uncertainty': 4,
    'unchallenged': 3,
    'unchanged': 2,
    'unchanging': 3,
    'uncharacteristic': 6,
    'uncharacteristically': 7,
    'uncharted': 3,
    'unchartered': 3,
    'unchecked': 2,
    'uncivil': 3,
    'uncivilized': 4,
    'unclaimed': 2,
    'unclamp': 2,
    'unclamps': 2,
    'unclassified': 4,
    'unclassify': 4,
    'uncle': 2,
    'unclean': 2,
    'unclear': 2,
    'uncles': 2,
    'uncluttered': 3,
    'uncoat': 2,
    'uncoated': 3,
    'uncoil': 2,
    'uncoiled': 2,
    'uncollectable': 5,
    'uncollected': 4,
    'uncollectible': 5,
    'uncomfortable': 5,
    'uncomfortably': 4,
    'uncommitted': 4,
    'uncommon': 3,
    'uncommonly': 4,
    'uncompensate': 4,
    'uncompensated': 5,
    'uncompetitive': 5,
    'uncomplete': 3,
    'uncompleted': 4,
    'uncomplicate': 4,
    'uncomplicated': 5,
    'uncompromising': 5,
    'unconcealed': 3,
    'unconcern': 3,
    'unconcerned': 3,
    'unconditional': 5,
    'unconditionally': 6,
    'unconfined': 3,
    'unconfirmed': 3,
    'unconnected': 4,
    'unconscionable': 5,
    'unconscious': 3,
    'unconsciously': 4,
    'unconsciousness': 4,
    'unconsolidated': 6,
    'unconstitutional': 6,
    'unconstitutionally': 7,
    'unconstrained': 3,
    'uncontaminated': 6,
    'uncontested': 4,
    'uncontradicted': 5,
    'uncontrollable': 5,
    'uncontrollably': 5,
    'uncontrolled': 3,
    'uncontroversial': 5,
    'unconventional': 5,
    'unconverted': 4,
    'unconvinced': 3,
    'unconvincing': 4,
    'uncool': 2,
    'uncooperative': 6,
    'uncoordinated': 6,
    'uncork': 2,
    'uncorked': 2,
    'uncorks': 2,
    'uncorrected': 4,
    'uncorroborated': 6,
    'uncounted': 3,
    'uncouple': 3,
    'uncouth': 2,
    'uncover': 3,
    'uncovered': 3,
    'uncovering': 4,
    'uncovers': 3,
    'uncritical': 4,
    'uncritically': 5,
    'unctad': 2,
    'unctuous': 2,
    'uncured': 2,
    'uncut': 2,
    'und': 1,
    'undamaged': 3,
    'undated': 3,
    'undaunted': 3,
    'undecided': 4,
    'undecideds': 4,
    'undeclared': 3,
    'undefeated': 4,
    'undefined': 3,
    'undelivered': 4,
    'undemocratic': 5,
    'undeniable': 5,
    'undeniably': 5,
    'under': 2,
    'underachiever': 5,
    'underachievers': 5,
    'underage': 3,
    'underarm': 3,
    'underbelly': 4,
    'underberg': 3,
    'underbid': 3,
    'underbrush': 3,
    'undercapitalize': 6,
    'undercapitalized': 6,
    'undercarriage': 4,
    'underclass': 3,
    'underclassmen': 4,
    'undercoat': 3,
    'undercook': 3,
    'undercooked': 3,
    'undercount': 3,
    'undercounted': 4,
    'undercover': 4,
    'undercurrent': 4,
    'undercurrents': 4,
    'undercut': 3,
    'undercuts': 3,
    'undercutting': 4,
    'underdahl': 3,
    'underdevelop': 5,
    'underdeveloped': 5,
    'underdevelopment': 6,
    'underdog': 3,
    'underdogs': 3,
    'underdown': 3,
    'underemploy': 4,
    'underemployed': 4,
    'underemployment': 5,
    'underestimate': 5,
    'underestimated': 6,
    'underestimates': 5,
    'underestimating': 6,
    'underfinance': 4,
    'underfinanced': 4,
    'underfoot': 3,
    'underfund': 3,
    'underfunded': 4,
    'underfunding': 4,
    'undergarment': 4,
    'undergarments': 4,
    'undergo': 3,
    'undergoes': 3,
    'undergoing': 4,
    'undergone': 3,
    'undergrad': 3,
    'undergraduate': 5,
    'undergraduates': 5,
    'underground': 3,
    'undergrowth': 3,
    'underhanded': 4,
    'underhill': 3,
    'underinsure': 4,
    'underinsured': 4,
    'underkoffler': 5,
    'underlie': 3,
    'underlies': 3,
    'underline': 3,
    'underlined': 3,
    'underlines': 3,
    'underling': 3,
    'underlings': 3,
    'underlining': 4,
    'underly': 3,
    'underlying': 4,
    'underman': 3,
    'undermanned': 3,
    'undermine': 3,
    'undermined': 3,
    'undermines': 3,
    'undermining': 4,
    'underneath': 3,
    'undernourish': 4,
    'undernourished': 4,
    'underpaid': 3,
    'underpants': 3,
    'underpass': 3,
    'underpay': 3,
    'underpaying': 4,
    'underpayment': 4,
    'underpayments': 4,
    'underperform': 4,
    'underperformance': 5,
    'underperformed': 4,
    'underperformer': 5,
    'underperforming': 5,
    'underpin': 3,
    'underpinned': 3,
    'underpinning': 4,
    'underpinnings': 4,
    'underplay': 3,
    'underplayed': 3,
    'underpower': 4,
    'underpowered': 4,
    'underprice': 3,
    'underpriced': 3,
    'underpricing': 4,
    'underpriviledged': 4,
    'underrate': 3,
    'underrated': 4,
    'underreport': 4,
    'underreported': 5,
    'underreporting': 5,
    'underrepresent': 5,
    'underrepresented': 6,
    'underscore': 3,
    'underscored': 3,
    'underscores': 3,
    'underscoring': 4,
    'undersea': 3,
    'underseas': 3,
    'undersecretary': 6,
    'undersell': 3,
    'underselling': 4,
    'underserve': 3,
    'underserved': 3,
    'undershirt': 3,
    'undershirts': 3,
    'underside': 3,
    'undersized': 3,
    'undersold': 3,
    'underspin': 3,
    'understaff': 3,
    'understaffed': 3,
    'understand': 3,
    'understandable': 5,
    'understandably': 5,
    'understanding': 4,
    'understandings': 4,
    'understands': 3,
    'understate': 3,
    'understated': 4,
    'understatement': 4,
    'understates': 3,
    'understating': 4,
    'understood': 3,
    'understory': 4,
    'understudy': 4,
    'undersubscribed': 4,
    'undertake': 3,
    'undertaken': 4,
    'undertaker': 4,
    'undertakes': 3,
    'undertaking': 4,
    'undertakings': 4,
    'undertone': 3,
    'undertones': 3,
    'undertook': 3,
    'undertow': 3,
    'undertows': 3,
    'underuse': 3,
    'underused': 3,
    'underutilize': 5,
    'underutilized': 5,
    'undervaluation': 6,
    'undervalue': 4,
    'undervalued': 4,
    'undervalues': 4,
    'undervaluing': 5,
    'underwater': 4,
    'underway': 3,
    'underwear': 3,
    'underweight': 3,
    'underweighted': 4,
    'underwent': 3,
    'underwhelm': 3,
    'underwhelmed': 3,
    'underwithheld': 4,
    'underwithhold': 4,
    'underwithholding': 5,
    'underwood': 3,
    'underwoods': 3,
    'underworld': 3,
    'underwrite': 3,
    'underwriter': 4,
    'underwriters': 4,
    'underwrites': 3,
    'underwriting': 4,
    'underwritings': 4,
    'underwritten': 4,
    'underwrote': 3,
    'undeserved': 3,
    'undeserving': 4,
    'undesirable': 5,
    'undetectable': 5,
    'undetected': 4,
    'undetermined': 4,
    'undetermining': 5,
    'undeterred': 3,
    'undeveloped': 4,
    'undiagnosed': 4,
    'undid': 2,
    'undifferentiated': 7,
    'undignified': 4,
    'undilute': 3,
    'undiluted': 4,
    'undiminished': 4,
    'undine': 2,
    'undiplomatic': 5,
    'undiplomatically': 6,
    'undisciplined': 4,
    'undisclosed': 3,
    'undiscovered': 4,
    'undisguised': 3,
    'undisputed': 4,
    'undistinguished': 4,
    'undistributed': 5,
    'undisturbed': 3,
    'undiversified': 5,
    'undivided': 4,
    'undo': 2,
    'undock': 2,
    'undocking': 3,
    'undocumented': 5,
    'undoing': 3,
    'undone': 2,
    'undoubted': 3,
    'undoubtedly': 4,
    'undreamed': 2,
    'undress': 2,
    'undressed': 2,
    'undue': 2,
    'undulate': 3,
    'undulated': 4,
    'undulates': 3,
    'undulating': 4,
    'unduly': 3,
    'undying': 3,
    'unearned': 2,
    'unearth': 2,
    'unearthed': 2,
    'unearthing': 3,
    'unearthly': 3,
    'unease': 2,
    'uneasily': 4,
    'uneasiness': 4,
    'uneasy': 3,
    'uneconomic': 5,
    'uneconomical': 6,
    'unedited': 4,
    'uneducated': 5,
    'unelectable': 5,
    'unelected': 4,
    'unemotional': 5,
    'unemploy': 3,
    'unemployable': 5,
    'unemployed': 3,
    'unemployment': 4,
    'unencumber': 4,
    'unencumbered': 4,
    'unending': 3,
    'unenforceable': 5,
    'unenforced': 3,
    'unenlightened': 4,
    'unenthusiastic': 6,
    'unenumerated': 6,
    'unenviable': 5,
    'uneo': 3,
    'unequal': 3,
    'unequaled': 3,
    'unequivocably': 6,
    'unequivocal': 5,
    'unequivocally': 6,
    'unesco': 3,
    'unethical': 4,
    'unethically': 4,
    'uneven': 3,
    'unevenly': 4,
    'uneventful': 4,
    'unexciting': 4,
    'unexercised': 4,
    'unexpected': 4,
    'unexpectedly': 5,
    'unexpired': 4,
    'unexplainable': 5,
    'unexplained': 3,
    'unexploded': 4,
    'unexploited': 4,
    'unexplored': 3,
    'unfailing': 3,
    'unfailingly': 4,
    'unfair': 2,
    'unfairly': 3,
    'unfairness': 3,
    'unfaithful': 3,
    'unfamiliar': 4,
    'unfamiliarities': 6,
    'unfamiliarity': 6,
    'unfashionable': 5,
    'unfathomable': 5,
    'unfav': 2,
    'unfavorable': 5,
    'unfavorably': 5,
    'unfazed': 2,
    'unfeasible': 4,
    'unfeeling': 3,
    'unfenced': 2,
    'unfettered': 3,
    'unfilled': 2,
    'unfiltered': 3,
    'unfinished': 3,
    'unfit': 2,
    'unflagging': 3,
    'unflappable': 4,
    'unflattering': 4,
    'unflinching': 3,
    'unfocused': 3,
    'unfold': 2,
    'unfolded': 3,
    'unfolding': 3,
    'unfolds': 2,
    'unforeseeable': 5,
    'unforeseen': 3,
    'unforgettable': 5,
    'unforgivable': 5,
    'unforgiven': 4,
    'unforgiving': 4,
    'unformed': 2,
    'unfortunate': 4,
    'unfortunately': 5,
    'unfortunates': 4,
    'unfounded': 3,
    'unfriendly': 3,
    'unfrozen': 3,
    'unfulfilled': 3,
    'unfunded': 3,
    'unfunnily': 4,
    'unfurl': 2,
    'unfurled': 2,
    'unfurling': 3,
    'ung': 1,
    'ungainly': 3,
    'ungar': 2,
    'ungaro': 3,
    'unger': 2,
    'ungerer': 3,
    'ungermann': 3,
    'unglamorous': 4,
    'unglue': 2,
    'unglued': 2,
    'ungo': 2,
    'ungodly': 3,
    'ungovernable': 5,
    'ungrateful': 3,
    'unguarded': 3,
    'unhampered': 3,
    'unhappily': 4,
    'unhappiness': 4,
    'unhappy': 3,
    'unharmed': 2,
    'unhealthful': 3,
    'unhealthy': 3,
    'unheard': 2,
    'unhedged': 2,
    'unheeded': 3,
    'unhelpful': 3,
    'unheralded': 4,
    'unhesitatingly': 6,
    'unhindered': 3,
    'unhinge': 2,
    'unhinged': 2,
    'unhitch': 2,
    'unholy': 3,
    'unhook': 2,
    'unhorsed': 2,
    'unhurried': 3,
    'unhurt': 2,
    'uni': 2,
    'unibancorp': 4,
    'unicef': 3,
    'unicellular': 5,
    'unicenter': 4,
    'unicom': 3,
    'unicorn': 3,
    'unicorp': 3,
    'unicycle': 4,
    'unicycles': 4,
    'uniden': 3,
    'unidentified': 5,
    'unification': 5,
    'unified': 3,
    'unifil': 3,
    'uniforce': 3,
    'uniform': 3,
    'uniformed': 3,
    'uniformity': 5,
    'uniformly': 4,
    'uniforms': 3,
    'unify': 3,
    'unifying': 4,
    'unigesco': 4,
    'unikom': 3,
    'unilab': 3,
    'unilateral': 5,
    'unilateralism': 7,
    'unilaterally': 6,
    'unilever': 4,
    'unimaginable': 6,
    'unimaginative': 6,
    'unimagined': 4,
    'unimate': 3,
    'unimation': 4,
    'unimedia': 5,
    'unimpaired': 3,
    'unimpeachable': 5,
    'unimpeded': 4,
    'unimportant': 4,
    'unimpressed': 3,
    'unimpressive': 4,
    'unimproved': 3,
    'unincorporated': 6,
    'unindicted': 4,
    'uninfected': 4,
    'uninformative': 5,
    'uninformed': 3,
    'uninhabitable': 6,
    'uninhabited': 5,
    'uninhibited': 5,
    'uninitiated': 6,
    'uninjured': 3,
    'uninspired': 4,
    'uninspiring': 4,
    'uninsurable': 5,
    'uninsured': 3,
    'unintelligible': 6,
    'unintended': 4,
    'unintentional': 5,
    'unintentionally': 6,
    'uninterested': 4,
    'uninteresting': 4,
    'uninterrupted': 5,
    'uninterruptible': 6,
    'uninvited': 4,
    'uninviting': 4,
    'uninvolved': 3,
    'union': 2,
    'uniondale': 3,
    'unionfed': 3,
    'unionism': 4,
    'unionist': 3,
    'unionists': 3,
    'unionization': 5,
    'unionize': 3,
    'unionized': 3,
    'unionizing': 4,
    'unions': 2,
    'unique': 2,
    'uniquely': 3,
    'uniqueness': 3,
    'uniroyal': 4,
    'unisex': 3,
    'unisom': 3,
    'unison': 3,
    'unisons': 3,
    'unisource': 3,
    'unissued': 3,
    'unisys': 3,
    'unit': 2,
    'unita': 3,
    'unitaf': 3,
    'unitarian': 5,
    'unitary': 4,
    'unitas': 3,
    'unite': 2,
    'united': 3,
    'unitedbank': 4,
    'unitek': 3,
    'unitel': 3,
    'unites': 2,
    'unitholder': 4,
    'unitholders': 4,
    'uniting': 3,
    'unitrin': 3,
    'unitrode': 3,
    'units': 2,
    'unity': 3,
    'univa': 3,
    'univar': 3,
    'univation': 4,
    'universal': 4,
    'universality': 6,
    'universally': 5,
    'universe': 3,
    'universes': 4,
    'universities': 5,
    'university': 5,
    'univisa': 4,
    'univision': 4,
    'unix': 2,
    'unjust': 2,
    'unjustifiable': 6,
    'unjustifiably': 6,
    'unjustified': 4,
    'unjustly': 3,
    'unkefer': 3,
    'unkempt': 2,
    'unkind': 2,
    'unkindest': 3,
    'unknowable': 4,
    'unknowing': 3,
    'unknowingly': 4,
    'unknown': 2,
    'unknowns': 2,
    'unlabeled': 3,
    'unland': 2,
    'unlawful': 3,
    'unlawfully': 4,
    'unleaded': 3,
    'unleash': 2,
    'unleashed': 2,
    'unleashes': 3,
    'unleashing': 3,
    'unless': 2,
    'unleveraged': 3,
    'unlicensed': 3,
    'unlike': 2,
    'unlikely': 3,
    'unlimited': 4,
    'unlined': 2,
    'unlisted': 3,
    'unlivable': 4,
    'unload': 2,
    'unloaded': 3,
    'unloading': 3,
    'unloads': 2,
    'unlock': 2,
    'unlocked': 2,
    'unlocking': 3,
    'unloved': 2,
    'unlucky': 3,
    'unmade': 2,
    'unmanage': 3,
    'unmanageable': 5,
    'unmanaged': 3,
    'unmanned': 2,
    'unmarked': 2,
    'unmarried': 3,
    'unmask': 2,
    'unmasked': 2,
    'unmatched': 2,
    'unmentionable': 5,
    'unmentioned': 3,
    'unmet': 2,
    'unmistakable': 5,
    'unmistakably': 5,
    'unmitigated': 5,
    'unmixed': 2,
    'unmolested': 4,
    'unmoved': 2,
    'unnamed': 2,
    'unnatural': 4,
    'unnaturally': 5,
    'unnecessarily': 6,
    'unnecessary': 5,
    'unneeded': 3,
    'unnerve': 2,
    'unnerved': 2,
    'unnerving': 3,
    'unnoticed': 3,
    'uno': 2,
    'unobstructed': 4,
    'unobtainable': 5,
    'unobtrusive': 4,
    'unocal': 3,
    'unoccupied': 4,
    'unofficial': 4,
    'unofficially': 5,
    'unopened': 3,
    'unopposed': 3,
    'unorganized': 4,
    'unorthodox': 4,
    'unos': 2,
    'unosom': 3,
    'unpack': 2,
    'unpacked': 2,
    'unpacking': 3,
    'unpaid': 2,
    'unpainted': 3,
    'unpalatable': 5,
    'unparalleled': 4,
    'unpatriotic': 5,
    'unpayable': 4,
    'unperturbed': 3,
    'unplaced': 2,
    'unplanned': 2,
    'unpleasant': 3,
    'unpleasantly': 4,
    'unpleasantness': 4,
    'unplug': 2,
    'unplugged': 2,
    'unpopular': 4,
    'unpopularity': 6,
    'unprecedented': 5,
    'unprecedentedly': 6,
    'unpredictability': 7,
    'unpredictable': 5,
    'unpredictably': 5,
    'unprepared': 3,
    'unpreserved': 3,
    'unpressurized': 4,
    'unpretentious': 4,
    'unprincipled': 4,
    'unprintable': 4,
    'unprocessed': 3,
    'unproductive': 4,
    'unprofessional': 5,
    'unprofitability': 7,
    'unprofitable': 5,
    'unprofor': 3,
    'unpromising': 4,
    'unprotected': 4,
    'unproved': 2,
    'unproven': 3,
    'unprovoked': 3,
    'unpublicized': 4,
    'unpublished': 3,
    'unpunished': 3,
    'unqualified': 4,
    'unquestionable': 5,
    'unquestionably': 5,
    'unquestioned': 3,
    'unquestioning': 4,
    'unquote': 2,
    'unrated': 3,
    'unrath': 2,
    'unratified': 4,
    'unravel': 3,
    'unraveled': 3,
    'unraveling': 4,
    'unravels': 3,
    'unreachable': 4,
    'unread': 2,
    'unreadable': 4,
    'unreal': 2,
    'unrealistic': 4,
    'unrealistically': 6,
    'unreality': 5,
    'unrealized': 4,
    'unreasonable': 4,
    'unreasonably': 5,
    'unreasoning': 3,
    'unreceptive': 4,
    'unrecognizable': 6,
    'unrecognized': 4,
    'unreconciled': 4,
    'unreconstructed': 5,
    'unrecorded': 4,
    'unredeemed': 3,
    'unrefined': 3,
    'unrefuted': 4,
    'unregistered': 4,
    'unregulated': 5,
    'unrehearsed': 3,
    'unreimbursed': 4,
    'unrein': 2,
    'unrelated': 4,
    'unreleased': 3,
    'unrelenting': 4,
    'unreliability': 7,
    'unreliable': 5,
    'unrelieved': 3,
    'unremarkable': 5,
    'unremarked': 3,
    'unremitted': 4,
    'unremitting': 4,
    'unrepentant': 4,
    'unreported': 4,
    'unrepresentative': 6,
    'unrepresented': 5,
    'unrequited': 4,
    'unresolved': 3,
    'unresponsive': 4,
    'unrest': 2,
    'unrestrained': 3,
    'unrestricted': 4,
    'unrevised': 3,
    'unring': 2,
    'unrivaled': 3,
    'unrue': 2,
    'unruffled': 3,
    'unruh': 2,
    'unruly': 3,
    'unsafe': 2,
    'unsaid': 2,
    'unsalable': 4,
    'unsaleable': 4,
    'unsanctioned': 3,
    'unsanitary': 5,
    'unsatisfactory': 6,
    'unsatisfied': 4,
    'unsatisfying': 5,
    'unsaturated': 5,
    'unsavory': 4,
    'unscathed': 2,
    'unscheduled': 3,
    'unscientific': 5,
    'unscom': 2,
    'unscramble': 3,
    'unscripted': 3,
    'unscrupulous': 4,
    'unseal': 2,
    'unsealed': 2,
    'unseasonably': 5,
    'unseat': 2,
    'unseated': 3,
    'unseating': 3,
    'unsecured': 3,
    'unseemly': 3,
    'unseen': 2,
    'unsell': 2,
    'unsentimental': 5,
    'unser': 2,
    'unserviceable': 5,
    'unset': 2,
    'unsettle': 3,
    'unsettled': 3,
    'unsettling': 4,
    'unshackle': 3,
    'unshackled': 3,
    'unshakable': 4,
    'unshakeable': 4,
    'unshaken': 3,
    'unsharpened': 3,
    'unshaven': 3,
    'unsightly': 3,
    'unsigned': 2,
    'unsinkable': 4,
    'unskilled': 2,
    'unsmiling': 3,
    'unsold': 2,
    'unsolicited': 5,
    'unsolvable': 4,
    'unsolved': 2,
    'unsophisticated': 6,
    'unsound': 2,
    'unsparing': 3,
    'unspeakable': 4,
    'unspecified': 4,
    'unspectacular': 5,
    'unspent': 2,
    'unspoiled': 2,
    'unspoken': 3,
    'unsportsmanlike': 4,
    'unstable': 3,
    'unstained': 2,
    'unstated': 3,
    'unsteady': 3,
    'unstinting': 3,
    'unstoppable': 4,
    'unstructured': 3,
    'unstuck': 2,
    'unsubscribe': 3,
    'unsubscribed': 3,
    'unsubsidized': 4,
    'unsubstantiated': 6,
    'unsubtle': 3,
    'unsuccessful': 4,
    'unsuccessfully': 5,
    'unsuitable': 4,
    'unsuited': 3,
    'unsullied': 3,
    'unsung': 2,
    'unsupervised': 4,
    'unsupportable': 5,
    'unsupported': 4,
    'unsure': 2,
    'unsurpassed': 3,
    'unsurprising': 4,
    'unsurprisingly': 5,
    'unsuspected': 4,
    'unsuspecting': 4,
    'unsustainable': 5,
    'unsustainably': 5,
    'unswayed': 2,
    'unswerving': 3,
    'unsworth': 2,
    'unsympathetic': 5,
    'untainted': 3,
    'untangle': 3,
    'untangling': 3,
    'untapped': 2,
    'untaxed': 2,
    'unted': 2,
    'untenable': 4,
    'untendered': 3,
    'unterberg': 3,
    'unterman': 3,
    'untermeyer': 3,
    'unterreiner': 4,
    'untested': 3,
    'unthinkable': 4,
    'unthinking': 3,
    'unthinkingly': 4,
    'untidy': 3,
    'untie': 2,
    'untied': 2,
    'untiedt': 2,
    'until': 2,
    'untimely': 3,
    'unto': 2,
    'untold': 2,
    'untouchable': 4,
    'untouchables': 4,
    'untouched': 2,
    'untoward': 3,
    'untraceable': 4,
    'untraditional': 5,
    'untrained': 2,
    'untrammeled': 3,
    'untreatable': 4,
    'untreated': 3,
    'untried': 2,
    'untroubled': 3,
    'untrue': 2,
    'untrustworthy': 4,
    'untruth': 2,
    'untruthful': 3,
    'untruths': 2,
    'unturned': 2,
    'untypical': 4,
    'unum': 2,
    'unusable': 4,
    'unused': 2,
    'unusual': 4,
    'unusually': 5,
    'unvarnished': 3,
    'unveil': 2,
    'unveiled': 2,
    'unveiling': 3,
    'unveils': 2,
    'unverifiable': 6,
    'unverified': 4,
    'unverzagt': 3,
    'unwanted': 3,
    'unwarranted': 4,
    'unwary': 3,
    'unwashed': 2,
    'unwavering': 4,
    'unwed': 2,
    'unwelcome': 3,
    'unwelcomed': 3,
    'unwieldiness': 4,
    'unwielding': 3,
    'unwieldy': 3,
    'unwilling': 3,
    'unwillingly': 4,
    'unwillingness': 4,
    'unwin': 2,
    'unwind': 2,
    'unwinding': 3,
    'unwinnable': 4,
    'unwise': 2,
    'unwisely': 3,
    'unwitting': 3,
    'unwittingly': 4,
    'unworkable': 4,
    'unworried': 3,
    'unworthy': 3,
    'unwound': 2,
    'unwrap': 2,
    'unwrapped': 2,
    'unwrapping': 3,
    'unwritten': 3,
    'unyielding': 3,
    'unz': 1,
    'unzicker': 3,
    'unzip': 2,
    'unzipped': 2,
    'up': 1,
    'upbeat': 2,
    'upbraid': 2,
    'upbraided': 3,
    'upbringing': 3,
    'upchurch': 2,
    'upcoming': 3,
    'update': 2,
    'updated': 3,
    'updates': 2,
    'updating': 3,
    'updegraff': 3,
    'updegrove': 4,
    'updike': 2,
    'updraft': 2,
    'updrafts': 2,
    'updyke': 2,
    'upfront': 2,
    'upgrade': 2,
    'upgraded': 3,
    'upgrader': 3,
    'upgrades': 2,
    'upgrading': 3,
    'upham': 2,
    'upheaval': 3,
    'upheavals': 3,
    'upheld': 2,
    'uphill': 2,
    'uphoff': 2,
    'uphold': 2,
    'upholding': 3,
    'upholds': 2,
    'upholster': 3,
    'upholstered': 3,
    'upholstery': 4,
    'upjohn': 2,
    'upkeep': 2,
    'upland': 2,
    'uplands': 2,
    'uplift': 2,
    'uplifted': 3,
    'uplifting': 3,
    'uplinger': 4,
    'upmanship': 3,
    'upmarket': 3,
    'upon': 2,
    'upp': 1,
    'upped': 1,
    'upper': 2,
    'upperclass': 3,
    'upperman': 3,
    'uppermost': 3,
    'uppers': 2,
    'upping': 2,
    'uppity': 3,
    'upright': 2,
    'uprights': 2,
    'uprise': 2,
    'uprising': 3,
    'uprisings': 3,
    'upriver': 3,
    'uproar': 2,
    'uproot': 2,
    'uprooted': 3,
    'uprooting': 3,
    'ups': 1,
    'upscale': 2,
    'upset': 2,
    'upsets': 2,
    'upsetting': 3,
    'upshaw': 2,
    'upshot': 2,
    'upshur': 2,
    'upside': 2,
    'upsize': 2,
    'upsizing': 3,
    'upson': 2,
    'upstage': 2,
    'upstaged': 2,
    'upstairs': 2,
    'upstanding': 3,
    'upstart': 2,
    'upstarts': 2,
    'upstate': 2,
    'upstream': 2,
    'upsurge': 2,
    'upswing': 2,
    'upswings': 2,
    'uptain': 2,
    'uptake': 2,
    'uptegrove': 3,
    'uptick': 2,
    'upticks': 2,
    'uptight': 2,
    'upton': 2,
    'uptown': 2,
    'uptrend': 2,
    'upturn': 2,
    'upturns': 2,
    'upward': 2,
    'upwardly': 3,
    'upwards': 2,
    'upwood': 2,
    'ur': 1,
    'ural': 2,
    'urals': 2,
    'uram': 2,
    'uranga': 3,
    'uranium': 4,
    'uranus': 3,
    'urbach': 2,
    'urbain': 2,
    'urban': 2,
    'urbana': 3,
    'urbanchuk': 3,
    'urbanczyk': 3,
    'urbane': 2,
    'urbanek': 3,
    'urbani': 3,
    'urbaniak': 4,
    'urbanik': 3,
    'urbanism': 3,
    'urbanite': 3,
    'urbanites': 3,
    'urbanization': 5,
    'urbanize': 3,
    'urbanized': 3,
    'urbano': 3,
    'urbanowicz': 4,
    'urbanski': 3,
    'urbas': 2,
    'urbaszewski': 4,
    'urbina': 3,
    'urchin': 2,
    'urchins': 2,
    'urda': 2,
    'urdangarin': 4,
    'urdiales': 3,
    'urdu': 2,
    'ure': 1,
    'urea': 3,
    'uremia': 4,
    'uren': 2,
    'urena': 3,
    'uresti': 3,
    'urethane': 3,
    'urethra': 3,
    'urey': 2,
    'urfer': 2,
    'urge': 1,
    'urged': 1,
    'urgen': 2,
    'urgency': 3,
    'urgent': 2,
    'urgently': 3,
    'urges': 2,
    'urging': 2,
    'urgings': 2,
    'urgo': 2,
    'uri': 2,
    'urian': 3,
    'uriarte': 4,
    'urias': 3,
    'uribe': 3,
    'uric': 2,
    'urich': 2,
    'urick': 2,
    'urie': 2,
    'uriegas': 3,
    'urinalysis': 5,
    'urinary': 4,
    'urinate': 3,
    'urinating': 4,
    'urine': 2,
    'urioste': 4,
    'urkel': 2,
    'urman': 2,
    'urn': 1,
    'urness': 2,
    'urokinase': 4,
    'urologist': 4,
    'urologists': 4,
    'urology': 4,
    'urquhart': 2,
    'urquidez': 3,
    'urquidi': 3,
    'urquiza': 3,
    'urrea': 3,
    'urrutia': 3,
    'ursa': 2,
    'urschel': 2,
    'ursery': 3,
    'ursie': 2,
    'ursin': 2,
    'ursini': 3,
    'urso': 2,
    'ursola': 3,
    'urson': 2,
    'urstadt': 2,
    'ursula': 3,
    'ursus': 2,
    'ursy': 2,
    'urton': 2,
    'uruguay': 3,
    'uruguayan': 4,
    'urwin': 2,
    'ury': 2,
    'us': 1,
    'usa': 3,
    'usable': 3,
    'usafe': 2,
    'usage': 2,
    'usages': 3,
    'usaid': 3,
    'usair': 3,
    'usairways': 4,
    'usameribancs': 6,
    'usbancorp': 4,
    'use': 1,
    'usec': 2,
    'used': 1,
    'useful': 2,
    'usefully': 3,
    'usefulness': 3,
    'useless': 2,
    'uselman': 3,
    'uselton': 3,
    'usenet': 2,
    'user': 2,
    'users': 2,
    'usery': 3,
    'uses': 2,
    'usher': 2,
    'ushered': 2,
    'ushering': 3,
    'ushers': 2,
    'usines': 2,
    'using': 2,
    'usinor': 3,
    'usoniam': 4,
    'usrey': 2,
    'usry': 2,
    'uss': 3,
    'ussery': 3,
    'ustasha': 3,
    'ustinov': 3,
    'ustrust': 3,
    'usual': 3,
    'usually': 4,
    'usurp': 2,
    'usurpation': 4,
    'usurped': 2,
    'usurping': 3,
    'usurps': 2,
    'usury': 3,
    'utah': 2,
    'utamaro': 4,
    'utech': 2,
    'utecht': 2,
    'utensils': 3,
    'uterine': 3,
    'utero': 3,
    'uterus': 3,
    'utgoff': 2,
    'uthe': 1,
    'uther': 2,
    'utica': 3,
    'utilicorp': 4,
    'utilitarian': 6,
    'utilities': 4,
    'utility': 4,
    'utilization': 5,
    'utilize': 3,
    'utilized': 3,
    'utilizes': 4,
    'utilizing': 4,
    'utke': 2,
    'utley': 2,
    'utmost': 2,
    'utopia': 4,
    'utopian': 4,
    'utopians': 4,
    'utopias': 4,
    'utsey': 2,
    'utsumi': 3,
    'utt': 1,
    'uttech': 2,
    'utter': 2,
    'utterance': 3,
    'utterances': 4,
    'uttered': 2,
    'uttering': 3,
    'utterly': 3,
    'utters': 2,
    'utz': 1,
    'utzinger': 3,
    'uva': 2,
    'uwe': 1,
    'uy': 2,
    'uyeda': 3,
    'uyehara': 4,
    'uyeno': 3,
    'uys': 2,
    'uzbek': 2,
    'uzbekistan': 4,
    'uzbeks': 2,
    'uzelac': 3,
    'uzi': 2,
    'uzis': 2,
    'uzmack': 2,
    'uzzell': 2,
    'uzzle': 2,
    'v': 1,
    'vaal': 1,
    'vaapenfabrikk': 4,
    'vaca': 2,
    'vacancies': 3,
    'vacancy': 3,
    'vacant': 2,
    'vacanti': 3,
    'vacate': 2,
    'vacated': 3,
    'vacating': 3,
    'vacation': 3,
    'vacationed': 3,
    'vacationer': 4,
    'vacationers': 4,
    'vacationing': 4,
    'vacations': 3,
    'vacaville': 3,
    'vacca': 2,
    'vaccarella': 4,
    'vaccaro': 3,
    'vaccinate': 3,
    'vaccinated': 4,
    'vaccination': 4,
    'vaccinations': 4,
    'vaccine': 2,
    'vaccines': 2,
    'vaccinia': 4,
    'vacco': 2,
    'vacek': 2,
    'vacha': 2,
    'vachon': 2,
    'vacillate': 3,
    'vacillated': 4,
    'vacillates': 3,
    'vacillating': 4,
    'vacillation': 4,
    'vaclav': 2,
    'vaclavik': 3,
    'vacuous': 3,
    'vacutainer': 4,
    'vacuum': 2,
    'vacuuming': 3,
    'vacuums': 2,
    'vadala': 3,
    'vadas': 2,
    'vadehra': 3,
    'vaden': 2,
    'vader': 2,
    'vadim': 2,
    'vadnais': 2,
    'vadney': 2,
    'vaeth': 1,
    'vagabond': 3,
    'vagabonds': 3,
    'vagaries': 3,
    'vagary': 3,
    'vagelos': 3,
    'vagina': 3,
    'vaginal': 3,
    'vagrancy': 3,
    'vagrant': 2,
    'vagrants': 2,
    'vague': 1,
    'vaguely': 2,
    'vagueness': 2,
    'vaguer': 2,
    'vaguest': 2,
    'vaguine': 2,
    'vahey': 2,
    'vahid': 2,
    'vahl': 1,
    'vahle': 2,
    'vail': 1,
    'vailab': 2,
    'vaile': 1,
    'vaillancourt': 3,
    'vain': 1,
    'vainly': 2,
    'vajda': 2,
    'vajna': 2,
    'vakuf': 2,
    'val': 1,
    'vala': 2,
    'valable': 3,
    'valade': 3,
    'valadez': 3,
    'valasek': 3,
    'valborga': 3,
    'valcourt': 2,
    'valda': 2,
    'valdemar': 3,
    'valderrama': 4,
    'valdes': 2,
    'valdeshari': 4,
    'valdez': 2,
    'valdis': 2,
    'valdivia': 4,
    'valdosta': 3,
    'valdovinos': 4,
    'vale': 1,
    'valea': 3,
    'valeda': 3,
    'valedictorian': 6,
    'valedictory': 5,
    'valek': 2,
    'valence': 2,
    'valencia': 4,
    'valencienne': 4,
    'valens': 2,
    'valensuela': 4,
    'valent': 3,
    'valenta': 3,
    'valente': 3,
    'valenti': 3,
    'valentia': 3,
    'valentin': 3,
    'valentina': 4,
    'valentine': 3,
    'valentines': 3,
    'valentini': 4,
    'valentino': 4,
    'valenza': 3,
    'valenzano': 4,
    'valenzuela': 4,
    'valeo': 3,
    'valera': 3,
    'valeri': 3,
    'valeria': 4,
    'valerian': 4,
    'valeriano': 5,
    'valerie': 3,
    'valerio': 4,
    'valero': 3,
    'valery': 3,
    'vales': 1,
    'valeska': 3,
    'valet': 2,
    'valhalla': 3,
    'valhi': 2,
    'valia': 2,
    'valiant': 2,
    'valiantly': 3,
    'valid': 2,
    'valida': 3,
    'validate': 3,
    'validated': 4,
    'validates': 3,
    'validating': 4,
    'validation': 4,
    'valided': 3,
    'validity': 4,
    'validly': 3,
    'valiente': 3,
    'valin': 2,
    'valiquette': 3,
    'valis': 2,
    'valium': 3,
    'valk': 1,
    'valko': 2,
    'valla': 2,
    'valladares': 4,
    'vallance': 2,
    'vallandingham': 4,
    'vallario': 4,
    'vallarta': 3,
    'vallas': 2,
    'valle': 1,
    'valleau': 2,
    'vallee': 2,
    'vallegrande': 4,
    'vallejo': 3,
    'vallejos': 3,
    'vallely': 2,
    'vallery': 3,
    'valles': 1,
    'vallette': 2,
    'valley': 2,
    'valleys': 2,
    'vallez': 2,
    'valli': 2,
    'valliant': 3,
    'vallie': 2,
    'vallier': 3,
    'valliere': 3,
    'vallis': 2,
    'vallo': 2,
    'vallone': 3,
    'vallot': 2,
    'valls': 1,
    'valmeyer': 3,
    'valmont': 2,
    'valois': 2,
    'valone': 2,
    'valonia': 3,
    'valor': 2,
    'valoree': 3,
    'valores': 3,
    'valparaiso': 4,
    'valsella': 3,
    'valspar': 2,
    'valtek': 2,
    'valtierra': 3,
    'valu': 2,
    'valuable': 3,
    'valuables': 3,
    'valuation': 4,
    'valuations': 4,
    'value': 2,
    'valued': 2,
    'valueless': 3,
    'valuepoint': 3,
    'values': 2,
    'valuevision': 4,
    'valuing': 3,
    'valujet': 3,
    'valukas': 3,
    'valvano': 3,
    'valve': 1,
    'valverde': 3,
    'valves': 1,
    'valvo': 2,
    'valvoline': 3,
    'vamos': 2,
    'vamp': 1,
    'vampire': 2,
    'vampires': 2,
    'vampiric': 3,
    'van': 1,
    'vana': 2,
    'vanacker': 3,
    'vanacore': 4,
    'vanadium': 4,
    'vanaken': 3,
    'vanallen': 3,
    'vanalstine': 3,
    'vanalstyne': 3,
    'vanaman': 3,
    'vanamburg': 3,
    'vanamburgh': 3,
    'vanantwerp': 3,
    'vanarsdale': 3,
    'vanarsdall': 3,
    'vanasse': 2,
    'vanatta': 3,
    'vanatter': 3,
    'vanauken': 3,
    'vanausdall': 3,
    'vanbebber': 3,
    'vanbeek': 2,
    'vanbenschoten': 4,
    'vanbergen': 3,
    'vanbibber': 3,
    'vanblarcom': 3,
    'vanblaricom': 4,
    'vanbrocklin': 3,
    'vanbrunt': 2,
    'vanburen': 3,
    'vanbuskirk': 3,
    'vancamp': 2,
    'vancampen': 3,
    'vance': 1,
    'vancil': 2,
    'vancise': 2,
    'vancleave': 2,
    'vancleef': 2,
    'vancleve': 2,
    'vancomycin': 4,
    'vancott': 2,
    'vancourt': 2,
    'vancouver': 3,
    'vancura': 3,
    'vancuren': 3,
    'vandaele': 2,
    'vandagriff': 3,
    'vandal': 2,
    'vandalen': 3,
    'vandalism': 4,
    'vandalize': 3,
    'vandalized': 3,
    'vandalizing': 4,
    'vandall': 2,
    'vandals': 2,
    'vandam': 2,
    'vandamme': 2,
    'vande': 1,
    'vandeberg': 3,
    'vandecar': 3,
    'vandegrift': 3,
    'vandehei': 3,
    'vandehey': 3,
    'vandella': 3,
    'vandellas': 3,
    'vandeman': 3,
    'vandemark': 3,
    'vanden': 2,
    'vandenberg': 3,
    'vandenbergh': 3,
    'vandenberghe': 3,
    'vandenboom': 3,
    'vandenbos': 3,
    'vandenbosch': 3,
    'vandenbrink': 3,
    'vandenburg': 3,
    'vandenburgh': 3,
    'vandenheuvel': 4,
    'vander': 2,
    'vanderbeck': 3,
    'vanderbeek': 3,
    'vanderberg': 3,
    'vanderbilt': 3,
    'vanderburg': 3,
    'vanderbush': 3,
    'vandercook': 3,
    'vanderford': 3,
    'vandergriff': 3,
    'vandergrift': 3,
    'vanderheide': 3,
    'vanderheiden': 4,
    'vanderheyden': 4,
    'vanderhoef': 3,
    'vanderhoff': 3,
    'vanderhoof': 3,
    'vanderhorst': 3,
    'vanderkolk': 3,
    'vanderkooi': 4,
    'vanderlaan': 3,
    'vanderleest': 4,
    'vanderlinde': 3,
    'vanderlinden': 4,
    'vanderlip': 3,
    'vandermark': 3,
    'vandermeer': 3,
    'vandermeulen': 4,
    'vandermolen': 4,
    'vanderploeg': 3,
    'vanderpoel': 3,
    'vanderpol': 3,
    'vanderpool': 3,
    'vanderschaaf': 3,
    'vanderslice': 3,
    'vandersluis': 4,
    'vanderveen': 3,
    'vanderveer': 3,
    'vandervelde': 3,
    'vandervelden': 4,
    'vandervliet': 3,
    'vandervoort': 3,
    'vandervort': 3,
    'vanderwal': 3,
    'vanderwall': 3,
    'vanderweele': 3,
    'vanderweide': 3,
    'vanderwerf': 3,
    'vanderwerff': 3,
    'vanderwilt': 3,
    'vanderwoude': 3,
    'vanderzanden': 4,
    'vanderzee': 3,
    'vandeusen': 3,
    'vandevander': 4,
    'vandeveer': 3,
    'vandevelde': 3,
    'vandeven': 3,
    'vandevender': 4,
    'vandeventer': 4,
    'vandever': 3,
    'vandevoorde': 3,
    'vandevoort': 3,
    'vandewalker': 4,
    'vandewalle': 3,
    'vandewater': 4,
    'vandezande': 3,
    'vandine': 2,
    'vandiver': 3,
    'vandivier': 4,
    'vandoren': 3,
    'vandorn': 2,
    'vandriel': 2,
    'vandross': 2,
    'vandunk': 2,
    'vandusen': 3,
    'vanduyn': 2,
    'vanduyne': 2,
    'vanduzer': 3,
    'vandyck': 2,
    'vandyk': 2,
    'vandyke': 2,
    'vandyken': 3,
    'vandyne': 2,
    'vane': 1,
    'vaneaton': 3,
    'vanecek': 3,
    'vaneck': 2,
    'vanegas': 3,
    'vanek': 2,
    'vanella': 3,
    'vanengen': 3,
    'vanepps': 2,
    'vanes': 1,
    'vaness': 2,
    'vanessa': 3,
    'vanessen': 3,
    'vanetten': 3,
    'vanevery': 3,
    'vanfleet': 2,
    'vanfossan': 3,
    'vanfossen': 3,
    'vang': 1,
    'vangel': 2,
    'vangelder': 3,
    'vangie': 2,
    'vangieson': 3,
    'vangilder': 3,
    'vangorden': 3,
    'vangorder': 3,
    'vangorp': 2,
    'vanguard': 2,
    'vanguilder': 3,
    'vangundy': 3,
    'vangy': 2,
    'vanhall': 2,
    'vanhandel': 3,
    'vanhecke': 2,
    'vanheel': 2,
    'vanhise': 2,
    'vanhoesen': 3,
    'vanhook': 2,
    'vanhoose': 2,
    'vanhooser': 3,
    'vanhoozer': 3,
    'vanhorn': 2,
    'vanhorne': 2,
    'vanhousen': 3,
    'vanhouten': 4,
    'vanhove': 2,
    'vanhoy': 2,
    'vanhuss': 2,
    'vanhyning': 3,
    'vania': 3,
    'vanier': 3,
    'vanik': 2,
    'vanilla': 3,
    'vanilli': 3,
    'vanillin': 3,
    'vanish': 2,
    'vanished': 2,
    'vanishes': 3,
    'vanishing': 3,
    'vanities': 3,
    'vanity': 3,
    'vankampen': 3,
    'vankeuren': 3,
    'vankirk': 2,
    'vankleeck': 2,
    'vankuren': 3,
    'vanlandingham': 4,
    'vanlanen': 3,
    'vanlaningham': 4,
    'vanleer': 2,
    'vanleeuwen': 3,
    'vanleuven': 3,
    'vanliere': 2,
    'vanliew': 3,
    'vanloan': 2,
    'vanloo': 2,
    'vanlue': 2,
    'vanluven': 3,
    'vanmaanen': 3,
    'vanmarter': 3,
    'vanmatre': 3,
    'vanmeter': 3,
    'vanmetre': 3,
    'vann': 1,
    'vanna': 2,
    'vanname': 2,
    'vannater': 3,
    'vannatta': 3,
    'vannatter': 3,
    'vannelli': 3,
    'vanness': 2,
    'vannest': 2,
    'vannguyen': 3,
    'vanni': 2,
    'vannice': 2,
    'vannie': 2,
    'vannorman': 3,
    'vannortwick': 3,
    'vannostrand': 3,
    'vannote': 2,
    'vannoy': 2,
    'vannucci': 3,
    'vanny': 2,
    'vano': 2,
    'vanora': 3,
    'vanorden': 3,
    'vanorder': 3,
    'vanorman': 3,
    'vanornum': 3,
    'vanosdol': 3,
    'vanoss': 2,
    'vanostrand': 3,
    'vanous': 2,
    'vanover': 3,
    'vanpatten': 3,
    'vanpelt': 2,
    'vanputten': 3,
    'vanquish': 2,
    'vanquished': 2,
    'vanriper': 3,
    'vanroekel': 3,
    'vanrossum': 3,
    'vanryn': 2,
    'vans': 1,
    'vansandt': 2,
    'vansant': 2,
    'vanschaick': 2,
    'vanschoick': 2,
    'vansciver': 3,
    'vanscoy': 2,
    'vanscoyoc': 3,
    'vanscyoc': 3,
    'vanselow': 3,
    'vansickel': 3,
    'vansickle': 3,
    'vanskike': 2,
    'vanskiver': 3,
    'vanslooten': 3,
    'vanslyke': 2,
    'vanstone': 2,
    'vanstory': 3,
    'vanstraten': 3,
    'vansyckle': 3,
    'vantage': 2,
    'vantages': 3,
    'vantassel': 3,
    'vantassell': 3,
    'vantil': 2,
    'vantilburg': 3,
    'vantine': 3,
    'vantol': 2,
    'vantran': 2,
    'vantrease': 2,
    'vantreese': 2,
    'vantuyl': 2,
    'vanuaaku': 4,
    'vanuatu': 4,
    'vanunu': 3,
    'vanvalkenburg': 4,
    'vanvalkenburgh': 4,
    'vanveen': 2,
    'vanvlack': 2,
    'vanvleck': 2,
    'vanvleet': 2,
    'vanvliet': 2,
    'vanvooren': 3,
    'vanvoorhis': 3,
    'vanvoorst': 2,
    'vanvorst': 2,
    'vanvranken': 3,
    'vanwagenen': 4,
    'vanwagner': 3,
    'vanwagoner': 4,
    'vanwart': 2,
    'vanwert': 2,
    'vanwey': 2,
    'vanwhy': 2,
    'vanwie': 2,
    'vanwieren': 3,
    'vanwinkle': 3,
    'vanwormer': 3,
    'vanwyck': 2,
    'vanwyhe': 2,
    'vanwyk': 2,
    'vanya': 2,
    'vanyo': 2,
    'vanzandt': 2,
    'vanzant': 2,
    'vanzanten': 3,
    'vanzee': 2,
    'vanzile': 2,
    'vapid': 2,
    'vapor': 2,
    'vaporization': 5,
    'vaporize': 3,
    'vaporized': 3,
    'vapors': 2,
    'vaporware': 3,
    'vaquera': 3,
    'vara': 2,
    'varady': 3,
    'varani': 3,
    'varano': 3,
    'varble': 2,
    'varco': 2,
    'vardeman': 2,
    'varden': 2,
    'vardon': 2,
    'varela': 3,
    'vares': 2,
    'varga': 2,
    'vargas': 2,
    'vargason': 3,
    'varghese': 2,
    'vargo': 2,
    'vari': 2,
    'variability': 6,
    'variable': 4,
    'variables': 4,
    'variably': 4,
    'varian': 3,
    'variance': 3,
    'variances': 4,
    'variant': 3,
    'variants': 3,
    'variation': 4,
    'variations': 4,
    'varick': 2,
    'varied': 2,
    'variegate': 3,
    'variegated': 4,
    'varies': 2,
    'varietal': 3,
    'varietals': 4,
    'varieties': 4,
    'variety': 4,
    'varig': 2,
    'varin': 2,
    'varina': 3,
    'various': 3,
    'variously': 4,
    'varisco': 3,
    'varitronic': 4,
    'varity': 3,
    'varityper': 4,
    'varlam': 2,
    'varlen': 2,
    'varley': 2,
    'varma': 2,
    'varmint': 2,
    'varmus': 2,
    'varn': 1,
    'varnado': 3,
    'varnadoe': 3,
    'varnadore': 4,
    'varnell': 2,
    'varner': 2,
    'varnes': 1,
    'varney': 2,
    'varni': 2,
    'varnish': 2,
    'varnished': 2,
    'varnishes': 3,
    'varnon': 2,
    'varnum': 2,
    'varo': 2,
    'varon': 2,
    'varona': 3,
    'varone': 2,
    'varricchio': 4,
    'varrone': 3,
    'vars': 1,
    'varsity': 3,
    'vartanian': 4,
    'varvaro': 3,
    'varvel': 2,
    'varves': 1,
    'vary': 2,
    'varying': 3,
    'varzi': 2,
    'vasbinder': 3,
    'vasco': 2,
    'vasconcellos': 4,
    'vasconcelos': 4,
    'vascular': 3,
    'vase': 1,
    'vasectomies': 4,
    'vasectomy': 4,
    'vasek': 2,
    'vaseline': 3,
    'vases': 2,
    'vasey': 2,
    'vashinsky': 3,
    'vashti': 2,
    'vasicek': 3,
    'vasil': 2,
    'vasile': 2,
    'vasily': 3,
    'vaske': 1,
    'vaskevitch': 3,
    'vasko': 2,
    'vaslev': 2,
    'vaslov': 2,
    'vasotec': 3,
    'vasques': 2,
    'vasquez': 2,
    'vass': 1,
    'vassal': 2,
    'vassallo': 3,
    'vassals': 2,
    'vassar': 2,
    'vassel': 2,
    'vasser': 2,
    'vasseur': 2,
    'vassey': 2,
    'vassilios': 3,
    'vassiliou': 4,
    'vassily': 3,
    'vast': 1,
    'vasta': 2,
    'vastine': 3,
    'vastly': 2,
    'vastness': 2,
    'vastola': 3,
    'vat': 1,
    'vater': 2,
    'vath': 1,
    'vatican': 3,
    'vats': 1,
    'vatted': 2,
    'vatter': 2,
    'vaudeville': 2,
    'vaudevillian': 3,
    'vaugh': 1,
    'vaughan': 1,
    'vaughn': 1,
    'vaughns': 1,
    'vaught': 1,
    'vault': 1,
    'vaulted': 2,
    'vaulting': 2,
    'vaults': 1,
    'vaunted': 2,
    'vaupel': 2,
    'vause': 1,
    'vaux': 1,
    'vauxhall': 2,
    'vavra': 2,
    'vavrek': 2,
    'vawter': 2,
    'vax': 1,
    'vaxes': 2,
    'vaxstation': 3,
    'vayda': 2,
    'vaz': 1,
    'vazquez': 2,
    've': 1,
    'veach': 1,
    'veal': 1,
    'veale': 1,
    'veals': 1,
    'veasey': 2,
    'veasley': 2,
    'veatch': 1,
    'veazey': 2,
    'veazie': 2,
    'veba': 2,
    'vecchiarelli': 5,
    'vecchio': 3,
    'vecchione': 4,
    'vecci': 2,
    'vecellio': 4,
    'vector': 2,
    'vectors': 2,
    'vectra': 2,
    'veda': 2,
    'vedder': 2,
    'vedette': 2,
    'vedis': 2,
    'vedula': 3,
    'vee': 1,
    'veech': 1,
    'veeco': 2,
    'veeder': 2,
    'veegenan': 3,
    'veen': 1,
    'veenstra': 2,
    'veep': 1,
    'veer': 1,
    'veered': 1,
    'veering': 2,
    'veers': 1,
    'vees': 1,
    'vega': 2,
    'vegan': 2,
    'vegans': 2,
    'vegas': 2,
    'vegemite': 3,
    'vegesna': 3,
    'vegetable': 3,
    'vegetables': 3,
    'vegetal': 3,
    'vegetarian': 5,
    'vegetarianism': 7,
    'vegetarians': 5,
    'vegetate': 3,
    'vegetation': 4,
    'vegetative': 4,
    'veggie': 2,
    'veggies': 2,
    'vegh': 1,
    'vegisnax': 3,
    'vehemence': 3,
    'vehement': 3,
    'vehemently': 4,
    'vehicle': 3,
    'vehicles': 3,
    'vehicular': 4,
    'veiga': 2,
    'veigel': 2,
    'veil': 1,
    'veiled': 1,
    'veiling': 2,
    'veillette': 2,
    'veilleux': 2,
    'veillon': 2,
    'veils': 1,
    'vein': 1,
    'veins': 1,
    'veira': 2,
    'veit': 1,
    'veitch': 1,
    'veith': 1,
    'vela': 2,
    'velagrande': 4,
    'velarde': 3,
    'velardi': 3,
    'velardo': 3,
    'velasco': 3,
    'velasquez': 3,
    'velayati': 4,
    'velazco': 3,
    'velazquez': 3,
    'velcro': 2,
    'velda': 2,
    'veldhuizen': 3,
    'veldman': 2,
    'veley': 2,
    'velez': 2,
    'velie': 2,
    'velika': 3,
    'veliotis': 4,
    'veliz': 2,
    'vella': 2,
    'vellucci': 3,
    'velma': 2,
    'velo': 2,
    'velobind': 3,
    'velociraptor': 5,
    'velocities': 4,
    'velocity': 4,
    'veloso': 3,
    'velotta': 3,
    'veloz': 2,
    'velsicol': 3,
    'velte': 1,
    'velten': 2,
    'veltman': 2,
    'veltre': 2,
    'veltri': 2,
    'velveeta': 3,
    'velvet': 2,
    'velvety': 3,
    'vemich': 2,
    'vempala': 3,
    'vena': 2,
    'venable': 3,
    'venables': 3,
    'venacuro': 4,
    'venal': 2,
    'venality': 4,
    'venalum': 3,
    'venango': 3,
    'venard': 2,
    'vencill': 2,
    'vencor': 2,
    'vendee': 2,
    'vendela': 3,
    'vendetta': 3,
    'vendettas': 3,
    'vendetti': 3,
    'vending': 2,
    'venditti': 3,
    'vendo': 2,
    'vendome': 2,
    'vendor': 2,
    'vendors': 2,
    'veneer': 2,
    'veneers': 2,
    'venegas': 3,
    'venema': 3,
    'venerable': 4,
    'venerate': 3,
    'venerated': 4,
    'venerates': 3,
    'venerating': 4,
    'veneration': 4,
    'venereal': 4,
    'venetian': 3,
    'veney': 2,
    'venezia': 4,
    'veneziano': 5,
    'venezuela': 4,
    'venezuelan': 4,
    'venezuelans': 4,
    'vengeance': 2,
    'vengeful': 2,
    'venice': 2,
    'venier': 3,
    'venison': 3,
    'venita': 3,
    'venkatesh': 3,
    'venn': 1,
    'vennard': 2,
    'venne': 1,
    'venneman': 2,
    'venner': 2,
    'vennick': 2,
    'venning': 2,
    'veno': 2,
    'venom': 2,
    'venomous': 3,
    'venous': 2,
    'vensel': 2,
    'venson': 2,
    'vent': 1,
    'ventech': 2,
    'vented': 2,
    'venter': 2,
    'venters': 2,
    'venti': 2,
    'ventilate': 3,
    'ventilated': 4,
    'ventilating': 4,
    'ventilation': 4,
    'ventilator': 4,
    'ventimiglia': 5,
    'venting': 2,
    'ventner': 2,
    'vento': 2,
    'ventola': 3,
    'ventral': 2,
    'ventre': 2,
    'ventrella': 3,
    'ventres': 2,
    'ventresca': 3,
    'ventress': 2,
    'ventricular': 4,
    'ventritex': 3,
    'vents': 1,
    'ventura': 3,
    'venture': 2,
    'ventured': 2,
    'venturella': 4,
    'ventures': 2,
    'venturesome': 3,
    'venturi': 3,
    'venturian': 4,
    'venturing': 3,
    'venturini': 4,
    'venturino': 4,
    'venue': 2,
    'venues': 2,
    'venus': 2,
    'venuti': 3,
    'venuto': 3,
    'venzke': 1,
    'ver': 1,
    'vera': 2,
    'veracity': 4,
    'veranda': 3,
    'verandas': 3,
    'veras': 2,
    'verb': 1,
    'verba': 2,
    'verbal': 2,
    'verbalize': 3,
    'verbalizing': 4,
    'verbally': 3,
    'verbatim': 3,
    'verbeck': 2,
    'verbeek': 2,
    'verbeke': 2,
    'verbena': 3,
    'verbiage': 3,
    'verble': 2,
    'verboon': 2,
    'verboons': 2,
    'verboten': 3,
    'verbrugge': 2,
    'verbs': 1,
    'verburg': 2,
    'vercammen': 3,
    'vercher': 2,
    'verda': 2,
    'verdant': 2,
    'verde': 2,
    'verderame': 3,
    'verderber': 3,
    'verderosa': 4,
    'verdes': 2,
    'verdi': 2,
    'verdict': 2,
    'verdicts': 2,
    'verdier': 3,
    'verdin': 2,
    'verdinsgang': 3,
    'verdon': 2,
    'verdone': 3,
    'verdugo': 3,
    'verduin': 3,
    'verdun': 2,
    'verduzco': 3,
    'vere': 1,
    'vereb': 2,
    'vereen': 2,
    'vereinsbank': 3,
    'verena': 3,
    'verene': 2,
    'veres': 1,
    'verex': 2,
    'verga': 2,
    'vergara': 3,
    'verge': 1,
    'verges': 2,
    'vergesh': 2,
    'vergil': 2,
    'verging': 2,
    'vergresh': 2,
    'verhage': 2,
    'verhagen': 3,
    'verhey': 2,
    'verhoef': 2,
    'verhoeven': 3,
    'verhoff': 2,
    'verhofstadt': 3,
    'verhulst': 2,
    'veribanc': 3,
    'verifiable': 5,
    'verification': 5,
    'verified': 3,
    'verifies': 3,
    'verify': 3,
    'verifying': 4,
    'verina': 3,
    'verine': 3,
    'verisimilitude': 6,
    'verit': 2,
    'veritable': 4,
    'veritably': 4,
    'verities': 3,
    'verity': 3,
    'verkuilen': 3,
    'verla': 2,
    'verlag': 2,
    'verleger': 3,
    'verley': 2,
    'verma': 2,
    'vermeer': 2,
    'vermette': 2,
    'vermeulen': 3,
    'vermilion': 3,
    'vermillion': 3,
    'vermilya': 3,
    'vermilyea': 3,
    'vermin': 2,
    'vermont': 2,
    'vermonter': 3,
    'vermonters': 3,
    'vermouth': 2,
    'vern': 1,
    'verna': 2,
    'vernacular': 4,
    'vernal': 2,
    'verne': 1,
    'verner': 2,
    'vernes': 1,
    'verneta': 3,
    'verney': 2,
    'vernick': 2,
    'vernier': 3,
    'vernis': 2,
    'vernita': 3,
    'vernitron': 3,
    'verno': 2,
    'vernon': 2,
    'vernonia': 4,
    'vernor': 2,
    'vero': 2,
    'veroa': 3,
    'veron': 2,
    'verona': 3,
    'veronda': 3,
    'veronica': 4,
    'veronique': 3,
    'veronis': 3,
    'verrall': 2,
    'verrastro': 3,
    'verrell': 2,
    'verret': 2,
    'verrett': 2,
    'verrette': 2,
    'verri': 2,
    'verrier': 3,
    'verrill': 2,
    'verrilli': 3,
    'verry': 2,
    'vers': 1,
    'versa': 2,
    'versace': 3,
    'versailles': 2,
    'versatile': 3,
    'versatility': 5,
    'verse': 1,
    'versed': 1,
    'verser': 2,
    'verses': 2,
    'versicherung': 4,
    'versicherungs': 4,
    'version': 2,
    'versions': 2,
    'versluis': 3,
    'versteeg': 2,
    'verstraete': 2,
    'versus': 2,
    'vert': 1,
    'vertebra': 3,
    'vertebrae': 3,
    'vertebral': 3,
    'vertebrate': 3,
    'vertebrates': 3,
    'vertel': 2,
    'vertex': 2,
    'vertibrak': 3,
    'vertical': 3,
    'vertically': 3,
    'verticom': 3,
    'vertigo': 3,
    'vertol': 2,
    'vertrees': 2,
    'vertucci': 3,
    'vertz': 1,
    'verve': 1,
    'verville': 2,
    'verwey': 2,
    'verwoerd': 2,
    'very': 2,
    'vescinoid': 3,
    'vescio': 3,
    'vesco': 2,
    'vesel': 2,
    'veselka': 3,
    'vesely': 2,
    'vesey': 2,
    'vesicle': 3,
    'vesicles': 3,
    'vesmen': 2,
    'vesna': 2,
    'vespa': 2,
    'vesper': 2,
    'vespera': 3,
    'vess': 1,
    'vessel': 2,
    'vessell': 2,
    'vessels': 2,
    'vesser': 2,
    'vessey': 2,
    'vest': 1,
    'vesta': 2,
    'vestal': 2,
    'vestar': 2,
    'vested': 2,
    'vester': 2,
    'vestibule': 3,
    'vestige': 2,
    'vestiges': 3,
    'vestigial': 4,
    'vesting': 2,
    'vestron': 2,
    'vests': 1,
    'vesuvius': 4,
    'vet': 1,
    'veta': 2,
    'vetco': 2,
    'veteran': 3,
    'veterans': 3,
    'vetere': 2,
    'veterinarian': 5,
    'veterinarians': 5,
    'veterinary': 4,
    'veteto': 3,
    'veto': 2,
    'vetoed': 2,
    'vetoes': 2,
    'vetoing': 3,
    'vetos': 2,
    'vetrano': 3,
    'vets': 1,
    'vetsch': 1,
    'vette': 1,
    'vetted': 2,
    'vetter': 2,
    'vetting': 2,
    'veuve': 1,
    'vevay': 2,
    'veverka': 3,
    'vevey': 2,
    'vevila': 3,
    'vex': 1,
    'vexatious': 3,
    'vexed': 1,
    'vexing': 2,
    'vey': 1,
    'veyne': 1,
    'vezina': 3,
    'vi': 1,
    'via': 2,
    'viability': 5,
    'viable': 3,
    'viacom': 3,
    'viaduct': 3,
    'viaducts': 3,
    'viag': 2,
    'viagene': 3,
    'viagra': 3,
    'vial': 2,
    'viall': 2,
    'vialpando': 4,
    'vials': 2,
    'vian': 2,
    'viana': 3,
    'viani': 3,
    'viano': 3,
    'viansa': 3,
    'viar': 2,
    'viars': 2,
    'viatical': 4,
    'viator': 3,
    'viau': 2,
    'vibbert': 2,
    'vibe': 1,
    'vibes': 1,
    'vibhu': 2,
    'vibrancy': 3,
    'vibrant': 2,
    'vibrate': 2,
    'vibrates': 2,
    'vibrating': 3,
    'vibration': 3,
    'vibrations': 3,
    'vibrato': 3,
    'vibrator': 3,
    'vic': 1,
    'vicar': 2,
    'vicari': 3,
    'vicario': 4,
    'vicarious': 4,
    'vicariously': 5,
    'vicars': 2,
    'vicary': 3,
    'vice': 1,
    'vicencio': 4,
    'vicente': 3,
    'vicenzi': 3,
    'viceroy': 2,
    'vices': 2,
    'vichy': 2,
    'vicinity': 4,
    'vicino': 3,
    'vicious': 2,
    'viciously': 3,
    'viciousness': 3,
    'vicissitude': 4,
    'vicissitudes': 4,
    'vick': 1,
    'vicker': 2,
    'vickerman': 3,
    'vickers': 2,
    'vickery': 3,
    'vickey': 2,
    'vicki': 2,
    'vickie': 2,
    'vickland': 2,
    'vicknair': 2,
    'vickrey': 2,
    'vickroy': 2,
    'vicks': 1,
    'vicksburg': 2,
    'vicky': 2,
    'vicomte': 3,
    'vicon': 2,
    'vicorp': 2,
    'victim': 2,
    'victimhood': 3,
    'victimization': 5,
    'victimize': 3,
    'victimized': 3,
    'victimizing': 4,
    'victimless': 3,
    'victims': 2,
    'victoire': 2,
    'victor': 2,
    'victoria': 4,
    'victorian': 4,
    'victorians': 4,
    'victorias': 4,
    'victories': 3,
    'victorine': 4,
    'victorino': 4,
    'victorious': 4,
    'victors': 2,
    'victory': 3,
    'vicuna': 3,
    'vida': 2,
    'vidal': 2,
    'vidales': 3,
    'vidalia': 3,
    'vidartes': 3,
    'vidaurri': 3,
    'vide': 1,
    'video': 3,
    'videocassette': 5,
    'videocassettes': 5,
    'videoconference': 5,
    'videoconferencing': 6,
    'videodisk': 4,
    'videodisks': 4,
    'videogame': 4,
    'videographer': 5,
    'videophone': 4,
    'videos': 3,
    'videotape': 4,
    'videotaped': 4,
    'videotapes': 4,
    'videotaping': 5,
    'videotex': 4,
    'videotext': 4,
    'videotron': 4,
    'vidigo': 3,
    'vidmar': 2,
    'vidonia': 4,
    'vidor': 2,
    'vidrine': 2,
    'vie': 1,
    'vieau': 2,
    'viebrock': 2,
    'vied': 1,
    'viedma': 2,
    'viegas': 2,
    'vieira': 3,
    'viejo': 3,
    'viel': 1,
    'viele': 1,
    'vielma': 2,
    'vien': 1,
    'vienna': 3,
    'vienneau': 2,
    'viennese': 3,
    'vienot': 2,
    'viens': 1,
    'viera': 2,
    'viereck': 2,
    'vierling': 2,
    'vierra': 2,
    'viers': 1,
    'viertel': 2,
    'viertels': 2,
    'vies': 1,
    'viet': 2,
    'vietcong': 3,
    'vieth': 2,
    'vietnam': 3,
    'vietnamese': 4,
    'vietor': 2,
    'viets': 1,
    'vietti': 3,
    'view': 1,
    'viewed': 1,
    'viewer': 2,
    'viewers': 2,
    'viewership': 3,
    'viewfinder': 3,
    'viewing': 2,
    'viewpoint': 2,
    'viewpoints': 2,
    'views': 1,
    'vieyra': 3,
    'vig': 1,
    'vigdor': 2,
    'vigeant': 2,
    'vigeland': 2,
    'vigen': 2,
    'viger': 2,
    'viggiano': 4,
    'vigil': 2,
    'vigilance': 3,
    'vigilant': 3,
    'vigilante': 4,
    'vigilantes': 4,
    'vigilantism': 5,
    'vigilia': 4,
    'vigils': 2,
    'viglione': 4,
    'vigliotti': 4,
    'viglund': 2,
    'vigna': 2,
    'vigneau': 2,
    'vigneault': 2,
    'vignette': 2,
    'vignettes': 2,
    'vignola': 3,
    'vigo': 2,
    'vigor': 2,
    'vigorito': 4,
    'vigorous': 3,
    'vigorously': 4,
    'vigue': 1,
    'viguerie': 3,
    'vigus': 2,
    'vik': 1,
    'vike': 1,
    'vikes': 1,
    'viking': 2,
    'vikings': 2,
    'viktor': 2,
    'vila': 2,
    'vilardo': 3,
    'vilas': 2,
    'vile': 1,
    'viles': 1,
    'vilhauer': 3,
    'vilification': 5,
    'vilified': 3,
    'vilify': 3,
    'villa': 2,
    'villafana': 4,
    'villafane': 4,
    'villafranca': 4,
    'villafuerte': 4,
    'village': 2,
    'villager': 3,
    'villagers': 3,
    'villages': 3,
    'villagomez': 4,
    'villagran': 3,
    'villagrana': 4,
    'villain': 2,
    'villainous': 3,
    'villains': 2,
    'villalba': 3,
    'villalobos': 4,
    'villalon': 3,
    'villalovos': 4,
    'villalpando': 4,
    'villalva': 3,
    'villamil': 3,
    'villani': 3,
    'villano': 3,
    'villanova': 4,
    'villanovan': 4,
    'villanueva': 4,
    'villar': 2,
    'villard': 2,
    'villareal': 4,
    'villari': 3,
    'villarreal': 3,
    'villars': 2,
    'villas': 2,
    'villasana': 4,
    'villasenor': 5,
    'villaverde': 4,
    'villavicencio': 6,
    'ville': 1,
    'villegas': 3,
    'villela': 3,
    'villella': 3,
    'villeneuve': 3,
    'villers': 2,
    'villette': 2,
    'villi': 2,
    'villiers': 2,
    'villines': 2,
    'villwock': 2,
    'vilma': 2,
    'vilnius': 3,
    'viloria': 4,
    'vim': 1,
    'vin': 1,
    'vina': 2,
    'vinal': 2,
    'vinca': 2,
    'vince': 1,
    'vincelette': 3,
    'vincennes': 3,
    'vincent': 2,
    'vincente': 3,
    'vincenti': 3,
    'vincentia': 3,
    'vincenzo': 3,
    'vinci': 2,
    'vinciguerra': 4,
    'vindicate': 3,
    'vindicated': 4,
    'vindicates': 3,
    'vindication': 4,
    'vindicator': 4,
    'vindictive': 3,
    'vindictiveness': 4,
    'vine': 1,
    'vinegar': 3,
    'vinegars': 3,
    'vineland': 2,
    'viner': 2,
    'vines': 1,
    'vinet': 2,
    'viney': 2,
    'vineyard': 2,
    'vineyards': 2,
    'vingmed': 2,
    'vingo': 2,
    'vini': 2,
    'vinicio': 4,
    'vinick': 2,
    'vinik': 2,
    'vining': 2,
    'vinita': 3,
    'vinje': 1,
    'vink': 1,
    'vinnick': 2,
    'vinnie': 2,
    'vinny': 2,
    'vinocur': 3,
    'vinsant': 2,
    'vinso': 2,
    'vinson': 2,
    'vint': 1,
    'vintage': 2,
    'vintages': 3,
    'vintner': 2,
    'vintners': 2,
    'vinton': 2,
    'vinyard': 2,
    'vinyl': 2,
    'vinzant': 2,
    'viola': 3,
    'violable': 4,
    'violante': 4,
    'violas': 3,
    'violate': 3,
    'violated': 4,
    'violates': 3,
    'violating': 4,
    'violation': 4,
    'violations': 4,
    'violator': 4,
    'violators': 4,
    'viole': 2,
    'violence': 3,
    'violent': 3,
    'violently': 4,
    'violet': 3,
    'violeta': 4,
    'violets': 3,
    'violett': 3,
    'violetta': 4,
    'violette': 3,
    'violin': 3,
    'violinist': 4,
    'violinists': 4,
    'violins': 3,
    'violist': 3,
    'violists': 3,
    'viper': 2,
    'vipers': 2,
    'vipin': 2,
    'vipond': 2,
    'vipont': 2,
    'vipperman': 3,
    'vipul': 2,
    'virag': 2,
    'virago': 3,
    'viral': 2,
    'viramontes': 4,
    'viratek': 3,
    'viray': 2,
    'virazole': 3,
    'virco': 2,
    'virden': 2,
    'viren': 2,
    'vires': 1,
    'virga': 2,
    'virge': 1,
    'virgen': 2,
    'virgie': 2,
    'virgil': 2,
    'virgilio': 4,
    'virgin': 2,
    'virginal': 3,
    'virginia': 3,
    'virginian': 3,
    'virginians': 3,
    'virginity': 4,
    'virgins': 2,
    'virgo': 2,
    'virgy': 2,
    'viridis': 3,
    'virile': 2,
    'virility': 4,
    'virina': 3,
    'virkler': 3,
    'virna': 2,
    'virnig': 2,
    'virological': 5,
    'virologist': 4,
    'virologists': 4,
    'virology': 4,
    'viromune': 3,
    'virts': 1,
    'virtual': 3,
    'virtually': 4,
    'virtue': 2,
    'virtues': 2,
    'virtuosity': 5,
    'virtuoso': 4,
    'virtuous': 3,
    'virulence': 3,
    'virulent': 3,
    'virulently': 4,
    'virus': 2,
    'viruses': 3,
    'virzi': 2,
    'vis': 1,
    'visa': 2,
    'visage': 2,
    'visas': 2,
    'viscardi': 3,
    'visceral': 3,
    'viscerally': 4,
    'visclosky': 3,
    'visco': 2,
    'viscomi': 3,
    'visconti': 3,
    'viscosity': 4,
    'viscount': 2,
    'viscounts': 2,
    'viscous': 2,
    'viscusi': 3,
    'viscuso': 3,
    'vise': 1,
    'visegrad': 3,
    'visenteno': 4,
    'vishay': 2,
    'vishnu': 2,
    'visibility': 5,
    'visible': 3,
    'visibly': 3,
    'visicalc': 3,
    'visigoth': 3,
    'visigoths': 3,
    'vision': 2,
    'visionaries': 4,
    'visionary': 4,
    'visions': 2,
    'visit': 2,
    'visitation': 4,
    'visitations': 4,
    'visited': 3,
    'visiting': 3,
    'visitor': 3,
    'visitors': 3,
    'visits': 2,
    'visnic': 2,
    'visor': 2,
    'visors': 2,
    'visscher': 2,
    'visser': 2,
    'vist': 1,
    'vista': 2,
    'vistas': 2,
    'viston': 2,
    'visual': 3,
    'visualization': 5,
    'visualize': 3,
    'visualized': 3,
    'visualizing': 4,
    'visually': 3,
    'visuals': 2,
    'visx': 2,
    'vita': 2,
    'vitae': 2,
    'vitagliano': 5,
    'vital': 2,
    'vitale': 3,
    'vitali': 3,
    'vitalink': 3,
    'vitality': 4,
    'vitally': 3,
    'vitaly': 3,
    'vitamin': 3,
    'vitamins': 3,
    'vitanza': 3,
    'vitarine': 3,
    'vitek': 2,
    'vitelli': 3,
    'vitello': 3,
    'vitez': 2,
    'viti': 2,
    'vitia': 2,
    'vitiello': 4,
    'vitiligo': 4,
    'vitner': 2,
    'vito': 2,
    'vitolo': 3,
    'vitramon': 3,
    'vitrano': 3,
    'vitreous': 3,
    'vitric': 2,
    'vitriol': 3,
    'vitriolic': 4,
    'vitro': 2,
    'vitronics': 3,
    'vitruvius': 4,
    'vitry': 2,
    'vitt': 1,
    'vittetoe': 3,
    'vittitow': 3,
    'vittle': 2,
    'vittles': 2,
    'vittoria': 4,
    'vittorio': 4,
    'vitucci': 3,
    'vitulli': 3,
    'vitullo': 3,
    'vituperative': 5,
    'viv': 1,
    'viva': 2,
    'vivacious': 3,
    'vivaldi': 3,
    'vivas': 2,
    'vive': 1,
    'viveiros': 3,
    'vivendi': 3,
    'viverette': 3,
    'viveros': 3,
    'vives': 1,
    'vivian': 3,
    'viviana': 4,
    'viviani': 4,
    'viviano': 4,
    'vivid': 2,
    'vividly': 3,
    'vividness': 3,
    'vivie': 2,
    'vivien': 3,
    'vivienne': 3,
    'vivier': 3,
    'viviparous': 4,
    'vivisepulture': 5,
    'vivo': 2,
    'vivona': 3,
    'vivra': 2,
    'vivyan': 2,
    'vixen': 2,
    'viyella': 3,
    'vizcaino': 4,
    'vizcarra': 3,
    'vizcaya': 3,
    'vizzini': 3,
    'vlach': 1,
    'vlachos': 2,
    'vlad': 1,
    'vladeck': 2,
    'vladimir': 3,
    'vladislav': 3,
    'vladivostok': 4,
    'vlahakis': 3,
    'vlahos': 2,
    'vlasak': 2,
    'vlasic': 2,
    'vlcek': 2,
    'vliet': 1,
    'vlok': 1,
    'vnesheconombank': 6,
    'vo': 1,
    'vobis': 2,
    'vocabulary': 5,
    'vocal': 2,
    'vocalist': 3,
    'vocally': 3,
    'vocals': 2,
    'vocaltec': 3,
    'vocation': 3,
    'vocational': 4,
    'vocations': 3,
    'vociferate': 4,
    'vociferated': 5,
    'vociferates': 4,
    'vociferating': 5,
    'vociferous': 4,
    'vociferously': 5,
    'vocke': 1,
    'vocs': 1,
    'vodafone': 3,
    'vodavi': 3,
    'vodicka': 3,
    'vodka': 2,
    'vodkas': 2,
    'voegele': 2,
    'voegeli': 3,
    'voelkel': 2,
    'voelker': 2,
    'voell': 1,
    'voeller': 2,
    'voeltz': 1,
    'voelz': 1,
    'voest': 1,
    'vogan': 2,
    'voge': 1,
    'vogel': 2,
    'vogeler': 3,
    'vogelgesang': 4,
    'vogelpohl': 3,
    'vogels': 2,
    'vogelsang': 3,
    'vogelsong': 3,
    'vogelstein': 3,
    'voges': 2,
    'voght': 1,
    'vogl': 2,
    'vogler': 2,
    'vogosca': 3,
    'vogt': 1,
    'vogtle': 2,
    'vogue': 1,
    'vohs': 1,
    'voice': 1,
    'voiced': 1,
    'voiceless': 2,
    'voicemail': 2,
    'voiceover': 3,
    'voices': 2,
    'voicework': 2,
    'voiceworks': 2,
    'voicing': 2,
    'void': 1,
    'voided': 2,
    'voiding': 2,
    'voids': 1,
    'voight': 1,
    'voigt': 1,
    'voigts': 1,
    'voila': 2,
    'voiles': 1,
    'voinovich': 3,
    'voir': 1,
    'voisey': 2,
    'voisin': 2,
    'voisine': 2,
    'voit': 1,
    'vojta': 2,
    'vokes': 1,
    'voland': 2,
    'volante': 3,
    'volatile': 3,
    'volatility': 5,
    'volberding': 3,
    'volcanic': 3,
    'volcanically': 4,
    'volcano': 3,
    'volcanoes': 3,
    'volcanologist': 5,
    'volcanologists': 5,
    'volcanos': 3,
    'volcker': 2,
    'vold': 1,
    'volden': 2,
    'volentine': 4,
    'voles': 1,
    'voleta': 3,
    'volga': 2,
    'volgograd': 3,
    'volin': 2,
    'volio': 3,
    'volition': 3,
    'volk': 1,
    'volker': 2,
    'volkers': 2,
    'volkert': 2,
    'volkman': 2,
    'volkmann': 2,
    'volkmar': 2,
    'volkmer': 2,
    'volkogonov': 4,
    'volksbank': 2,
    'volksfuersorge': 3,
    'volkswagen': 3,
    'volkswagens': 3,
    'voll': 1,
    'volland': 2,
    'vollbrecht': 2,
    'volle': 1,
    'vollenweider': 4,
    'voller': 2,
    'volley': 2,
    'volleyball': 3,
    'volleys': 2,
    'vollman': 2,
    'vollmar': 2,
    'vollmer': 2,
    'vollrath': 2,
    'volmer': 2,
    'volner': 2,
    'volney': 2,
    'volokh': 2,
    'volpe': 1,
    'volpi': 2,
    'volpicella': 4,
    'volt': 1,
    'volta': 2,
    'voltage': 2,
    'voltages': 3,
    'voltaire': 2,
    'voltaren': 3,
    'volts': 1,
    'voltz': 1,
    'voluble': 3,
    'volume': 2,
    'volumes': 2,
    'voluminous': 4,
    'voluntarily': 5,
    'voluntarism': 5,
    'voluntary': 4,
    'volunteer': 3,
    'volunteered': 3,
    'volunteering': 4,
    'volunteerism': 5,
    'volunteers': 3,
    'voluptuous': 4,
    'volvo': 2,
    'volvos': 2,
    'volvovitz': 3,
    'volvox': 2,
    'volz': 1,
    'vomit': 2,
    'vomiting': 3,
    'von': 1,
    'vona': 2,
    'vonada': 3,
    'vonallmen': 3,
    'vonarx': 2,
    'vonbargen': 3,
    'vonbehren': 3,
    'vonbergen': 3,
    'voncannon': 3,
    'vonder': 2,
    'vonderhaar': 3,
    'vonderheide': 3,
    'vondra': 2,
    'vondracek': 3,
    'vondrak': 2,
    'vondrasek': 3,
    'vonfeldt': 2,
    'vong': 1,
    'vongunten': 3,
    'vonk': 1,
    'vonnegut': 3,
    'vonnie': 2,
    'vonny': 2,
    'vonruden': 3,
    'vons': 1,
    'vonseggern': 3,
    'vonstein': 2,
    'vontobel': 3,
    'voodoo': 2,
    'voorhees': 2,
    'voorheis': 2,
    'voorhies': 2,
    'voorhis': 2,
    'vora': 2,
    'voracious': 3,
    'voracity': 4,
    'vorce': 1,
    'vore': 1,
    'vorhauer': 2,
    'vorhees': 2,
    'vorhies': 2,
    'voris': 2,
    'vornado': 3,
    'vorndran': 2,
    'vorontsov': 3,
    'voros': 2,
    'vorpahl': 2,
    'vortec': 2,
    'vortex': 2,
    'vorwald': 2,
    'vorwerk': 2,
    'vos': 1,
    'vosberg': 2,
    'vosburg': 2,
    'vosburgh': 2,
    'vose': 1,
    'voshell': 2,
    'vosler': 3,
    'voss': 1,
    'vossen': 2,
    'vossler': 3,
    'votava': 3,
    'votaw': 2,
    'vote': 1,
    'voted': 2,
    'voter': 2,
    'voters': 2,
    'votes': 1,
    'voth': 1,
    'voting': 2,
    'votrax': 2,
    'votruba': 3,
    'vouch': 1,
    'vouched': 1,
    'voucher': 2,
    'vouchers': 2,
    'vouching': 2,
    'vought': 1,
    'vous': 1,
    'voute': 1,
    'vow': 1,
    'vowed': 1,
    'vowel': 2,
    'vowell': 2,
    'vowels': 2,
    'vowing': 2,
    'vowles': 2,
    'vows': 1,
    'vox': 1,
    'voyage': 2,
    'voyaged': 2,
    'voyager': 3,
    'voyagers': 3,
    'voyages': 3,
    'voyer': 2,
    'voyeur': 2,
    'voyeurism': 4,
    'voyeuristic': 4,
    'voyles': 1,
    'voynavich': 3,
    'voytek': 2,
    'voytko': 2,
    'vrabel': 2,
    'vradenburg': 3,
    'vrain': 1,
    'vrana': 2,
    'vranesevic': 4,
    'vranitzky': 3,
    'vranos': 2,
    'vrba': 2,
    'vrdolyak': 4,
    'vredenburg': 3,
    'vreeland': 2,
    'vremya': 3,
    'vries': 1,
    'vroman': 2,
    'vroom': 1,
    'vrooman': 2,
    'vs': 2,
    'vsel': 2,
    'vu': 1,
    'vue': 1,
    'vuitton': 2,
    'vukelich': 3,
    'vukovar': 3,
    'vukovich': 3,
    'vulcan': 2,
    'vulcans': 2,
    'vulgamore': 3,
    'vulgar': 2,
    'vulgarity': 4,
    'vulgarization': 5,
    'vullo': 2,
    'vulnerabilities': 6,
    'vulnerability': 6,
    'vulnerable': 4,
    'vultaggio': 4,
    'vulture': 2,
    'vultures': 2,
    'vulva': 2,
    'vuncannon': 3,
    'vuolo': 3,
    'vuong': 2,
    'vy': 1,
    'vyacheslav': 3,
    'vyas': 1,
    'vying': 2,
    'w': 3,
    'wa': 1,
    'waack': 1,
    'waag': 1,
    'waage': 2,
    'waah': 1,
    'waal': 1,
    'waara': 2,
    'waas': 1,
    'wabash': 2,
    'wace': 1,
    'wach': 1,
    'wacha': 2,
    'wachholz': 2,
    'wachner': 2,
    'wacho': 2,
    'wachob': 2,
    'wachovia': 4,
    'wachowiak': 4,
    'wachowski': 3,
    'wachs': 1,
    'wachsman': 2,
    'wachsmuth': 2,
    'wachtel': 2,
    'wachtell': 2,
    'wachter': 2,
    'wachtler': 2,
    'wack': 1,
    'wackenhut': 3,
    'wacker': 2,
    'wackerle': 3,
    'wacko': 2,
    'wackos': 2,
    'wacks': 1,
    'wacksman': 2,
    'wacky': 2,
    'waco': 2,
    'wactlar': 2,
    'wad': 1,
    'wada': 2,
    'wadas': 2,
    'wadded': 2,
    'waddell': 2,
    'waddie': 2,
    'waddill': 2,
    'waddington': 3,
    'waddle': 2,
    'waddy': 2,
    'wade': 1,
    'waded': 2,
    'wadel': 2,
    'wader': 2,
    'waders': 2,
    'wades': 1,
    'wadford': 2,
    'wadhams': 2,
    'wading': 2,
    'wadkins': 2,
    'wadle': 2,
    'wadleigh': 2,
    'wadley': 2,
    'wadlington': 3,
    'wadlow': 2,
    'wadman': 2,
    'wads': 1,
    'wadsworth': 2,
    'waechter': 2,
    'waeltermann': 3,
    'waertsilae': 3,
    'wafer': 2,
    'wafers': 2,
    'waffenschmidt': 3,
    'waffle': 2,
    'waffled': 2,
    'waffles': 2,
    'waffling': 2,
    'wafford': 2,
    'waft': 1,
    'wafted': 2,
    'wafting': 2,
    'wag': 1,
    'waga': 2,
    'wagaman': 3,
    'wagar': 2,
    'wage': 1,
    'waged': 1,
    'wageman': 2,
    'wagenaar': 3,
    'wagener': 3,
    'wagenknecht': 3,
    'wager': 2,
    'wagered': 2,
    'wagering': 3,
    'wagers': 2,
    'wages': 2,
    'wagg': 1,
    'wagged': 1,
    'waggener': 3,
    'wagging': 2,
    'waggling': 3,
    'waggoner': 3,
    'waggy': 2,
    'waging': 2,
    'wagle': 2,
    'wagler': 2,
    'wagley': 2,
    'wagman': 2,
    'wagner': 2,
    'wagnerian': 4,
    'wagnon': 2,
    'wagon': 2,
    'wagoneer': 3,
    'wagoneers': 3,
    'wagoner': 3,
    'wagons': 2,
    'wags': 1,
    'wagstaff': 2,
    'wagster': 2,
    'waguespack': 3,
    'wagy': 2,
    'wah': 1,
    'wahid': 2,
    'wahine': 3,
    'wahines': 3,
    'wahl': 1,
    'wahlberg': 2,
    'wahle': 1,
    'wahlen': 2,
    'wahler': 2,
    'wahlers': 2,
    'wahlert': 2,
    'wahlgren': 2,
    'wahlquist': 2,
    'wahlstrom': 2,
    'wahoo': 2,
    'wai': 1,
    'waibel': 2,
    'waid': 1,
    'waide': 1,
    'waidelich': 2,
    'waif': 1,
    'waifer': 2,
    'waigel': 2,
    'waikiki': 3,
    'wail': 1,
    'wailed': 1,
    'wailes': 1,
    'wailing': 2,
    'wails': 1,
    'wain': 1,
    'wainer': 2,
    'wainio': 3,
    'wainman': 2,
    'wainoco': 3,
    'wainright': 2,
    'wainscott': 2,
    'wainscotting': 3,
    'wainwright': 2,
    'wais': 1,
    'waisanen': 3,
    'waisner': 2,
    'waist': 1,
    'waistline': 2,
    'waists': 1,
    'wait': 1,
    'waite': 1,
    'waited': 2,
    'waiter': 2,
    'waiters': 2,
    'waites': 1,
    'waiting': 2,
    'waitkus': 2,
    'waitman': 2,
    'waitress': 2,
    'waitresses': 3,
    'waits': 1,
    'waitt': 1,
    'waitzkin': 2,
    'waive': 1,
    'waived': 1,
    'waiver': 2,
    'waivers': 2,
    'waives': 1,
    'waiving': 2,
    'wajda': 2,
    'wakabayashi': 5,
    'wake': 1,
    'wakefield': 2,
    'wakeham': 2,
    'wakeland': 2,
    'wakeley': 2,
    'wakely': 2,
    'wakeman': 2,
    'waken': 2,
    'wakes': 1,
    'wakeup': 2,
    'wakid': 2,
    'waking': 2,
    'wakley': 2,
    'wako': 2,
    'wal': 1,
    'wala': 2,
    'walas': 2,
    'walberg': 2,
    'walbert': 2,
    'walborn': 2,
    'walbridge': 2,
    'walbro': 2,
    'walburn': 2,
    'walby': 2,
    'walch': 1,
    'walcher': 2,
    'walck': 1,
    'walcot': 2,
    'walcott': 2,
    'walczak': 2,
    'walczyk': 2,
    'wald': 1,
    'walda': 2,
    'waldbaum': 2,
    'walde': 1,
    'waldeck': 2,
    'waldecker': 3,
    'waldegard': 3,
    'waldemar': 3,
    'walden': 2,
    'waldenbooks': 3,
    'walder': 2,
    'waldheim': 2,
    'waldholtz': 2,
    'waldholz': 2,
    'waldhorn': 2,
    'waldie': 2,
    'walding': 2,
    'waldinger': 3,
    'waldman': 2,
    'waldmann': 2,
    'waldner': 2,
    'waldo': 2,
    'waldoch': 2,
    'waldock': 2,
    'waldon': 2,
    'waldorf': 2,
    'waldow': 2,
    'waldren': 2,
    'waldrep': 2,
    'waldridge': 2,
    'waldrip': 2,
    'waldron': 2,
    'waldroop': 2,
    'waldrop': 2,
    'waldroup': 2,
    'waldrum': 2,
    'waldrup': 2,
    'waldschmidt': 2,
    'waldvogel': 3,
    'wale': 1,
    'waleed': 2,
    'walek': 2,
    'walen': 2,
    'walenta': 3,
    'wales': 1,
    'walesa': 3,
    'waleson': 2,
    'walford': 2,
    'walfred': 2,
    'walgreen': 2,
    'walgren': 2,
    'walicki': 3,
    'walid': 2,
    'waligora': 4,
    'walinsky': 3,
    'walizer': 3,
    'walk': 1,
    'walke': 1,
    'walked': 1,
    'walken': 2,
    'walkenhorst': 3,
    'walker': 2,
    'walkers': 2,
    'walkie': 2,
    'walking': 2,
    'walkington': 3,
    'walkinshaw': 3,
    'walkley': 2,
    'walkman': 2,
    'walkner': 2,
    'walko': 2,
    'walkout': 2,
    'walkouts': 2,
    'walkowiak': 4,
    'walkowski': 3,
    'walks': 1,
    'walkup': 2,
    'walkure': 2,
    'walkway': 2,
    'walkways': 2,
    'wall': 1,
    'walla': 2,
    'wallabies': 3,
    'wallaby': 3,
    'wallace': 2,
    'wallach': 2,
    'wallack': 2,
    'wallander': 3,
    'wallar': 2,
    'wallboard': 2,
    'walle': 1,
    'walled': 1,
    'wallen': 2,
    'wallenberg': 3,
    'wallenstein': 3,
    'waller': 2,
    'wallerstein': 3,
    'walles': 1,
    'wallet': 2,
    'wallets': 2,
    'walley': 2,
    'walleye': 2,
    'wallflower': 3,
    'wallgren': 2,
    'wallich': 2,
    'wallick': 2,
    'wallie': 2,
    'wallin': 2,
    'walling': 2,
    'wallingford': 3,
    'wallington': 3,
    'wallis': 2,
    'wallison': 3,
    'wallman': 2,
    'wallner': 2,
    'wallo': 2,
    'walloch': 2,
    'wallop': 2,
    'walloping': 3,
    'wallops': 2,
    'wallow': 2,
    'wallowed': 2,
    'wallowing': 3,
    'wallpaper': 3,
    'wallpapered': 3,
    'wallpapering': 4,
    'wallpapers': 3,
    'walls': 1,
    'wallsend': 2,
    'wallstreet': 2,
    'wallwork': 2,
    'wally': 2,
    'walmart': 2,
    'walmer': 2,
    'walmond': 2,
    'walmsley': 2,
    'waln': 1,
    'walnut': 2,
    'walnuts': 2,
    'walp': 1,
    'walpole': 2,
    'walrath': 2,
    'walraven': 3,
    'walrod': 2,
    'walrus': 2,
    'walruses': 3,
    'walser': 2,
    'walsh': 1,
    'walski': 2,
    'walstad': 2,
    'walston': 2,
    'walstrom': 2,
    'walsworth': 2,
    'walt': 1,
    'walter': 2,
    'waltermire': 3,
    'walters': 2,
    'walth': 1,
    'walthall': 2,
    'waltham': 2,
    'walther': 2,
    'walthers': 2,
    'walthose': 2,
    'walthour': 2,
    'waltman': 2,
    'waltner': 2,
    'walton': 2,
    'waltons': 2,
    'waltrip': 2,
    'walts': 1,
    'waltz': 1,
    'waltzer': 2,
    'waltzes': 2,
    'waltzing': 2,
    'walworth': 2,
    'walwyn': 2,
    'walz': 1,
    'walzer': 2,
    'wambach': 2,
    'wambaugh': 2,
    'wamble': 2,
    'wambold': 2,
    'wamboldt': 2,
    'wambolt': 2,
    'wampler': 2,
    'wampole': 2,
    'wampum': 2,
    'wamser': 2,
    'wamsley': 2,
    'wan': 1,
    'wanamaker': 4,
    'wanat': 2,
    'wand': 1,
    'wanda': 2,
    'wandel': 2,
    'wandell': 2,
    'wander': 2,
    'wandered': 2,
    'wanderer': 3,
    'wanderers': 3,
    'wandering': 3,
    'wanderings': 3,
    'wanderlust': 3,
    'wanders': 2,
    'wandersee': 3,
    'wandie': 2,
    'wandis': 2,
    'wandler': 2,
    'wandling': 2,
    'wandrey': 2,
    'wands': 1,
    'wane': 1,
    'waned': 1,
    'wanek': 2,
    'waner': 2,
    'wanes': 1,
    'wang': 1,
    'wangen': 2,
    'wanger': 2,
    'wangerin': 3,
    'wangle': 2,
    'wangled': 2,
    'wangler': 2,
    'waning': 2,
    'wank': 1,
    'wanke': 1,
    'wanko': 2,
    'wanless': 2,
    'wann': 1,
    'wanna': 2,
    'wannabe': 3,
    'wannabee': 3,
    'wannabees': 3,
    'wannabes': 3,
    'wannamaker': 4,
    'wannemacher': 4,
    'wanner': 2,
    'wanninger': 3,
    'wanniski': 3,
    'wanser': 2,
    'wansley': 2,
    'want': 1,
    'wanta': 2,
    'wantage': 2,
    'wantages': 3,
    'wante': 2,
    'wanted': 2,
    'wanting': 2,
    'wantland': 2,
    'wanton': 2,
    'wantonly': 3,
    'wants': 1,
    'wantz': 1,
    'wanzer': 2,
    'wapiti': 3,
    'waples': 2,
    'wapner': 2,
    'wapping': 2,
    'war': 1,
    'warbington': 3,
    'warble': 2,
    'warbled': 2,
    'warbler': 2,
    'warblers': 2,
    'warbles': 2,
    'warbling': 3,
    'warburg': 2,
    'warburton': 3,
    'warchol': 2,
    'ward': 1,
    'warda': 2,
    'wardair': 2,
    'warde': 1,
    'warded': 2,
    'wardell': 2,
    'warden': 2,
    'wardens': 2,
    'warder': 2,
    'warders': 2,
    'warding': 2,
    'wardlaw': 2,
    'wardle': 2,
    'wardley': 2,
    'wardlow': 2,
    'wardrip': 2,
    'wardrobe': 2,
    'wardrobes': 2,
    'wardrop': 2,
    'wards': 1,
    'wardwell': 2,
    'ware': 1,
    'warehime': 2,
    'warehouse': 2,
    'warehoused': 2,
    'warehouses': 3,
    'warehousing': 3,
    'wareing': 2,
    'waren': 2,
    'wares': 1,
    'warf': 1,
    'warfare': 2,
    'warfel': 2,
    'warfield': 2,
    'warford': 2,
    'warga': 2,
    'wargo': 2,
    'warhead': 2,
    'warheads': 2,
    'warhol': 2,
    'warhurst': 2,
    'warily': 3,
    'wariness': 3,
    'waring': 2,
    'wark': 1,
    'warkentin': 3,
    'warley': 2,
    'warlick': 2,
    'warlike': 2,
    'warling': 2,
    'warloeck': 2,
    'warlord': 2,
    'warlords': 2,
    'warm': 1,
    'warmack': 2,
    'warman': 2,
    'warmed': 1,
    'warmer': 2,
    'warmest': 2,
    'warming': 2,
    'warmington': 3,
    'warminster': 3,
    'warmish': 2,
    'warmly': 2,
    'warmond': 2,
    'warmonger': 3,
    'warmongering': 4,
    'warmoth': 2,
    'warms': 1,
    'warmth': 1,
    'warmup': 2,
    'warmus': 2,
    'warmuth': 2,
    'warn': 1,
    'warnaco': 3,
    'warncke': 2,
    'warne': 1,
    'warnecke': 2,
    'warned': 1,
    'warneke': 2,
    'warnell': 2,
    'warner': 2,
    'warners': 2,
    'warnes': 1,
    'warnick': 2,
    'warnico': 3,
    'warning': 2,
    'warningly': 3,
    'warnings': 2,
    'warnke': 2,
    'warnken': 2,
    'warno': 2,
    'warnock': 2,
    'warns': 1,
    'waronker': 3,
    'warp': 1,
    'warpath': 2,
    'warped': 1,
    'warping': 2,
    'warplane': 2,
    'warplanes': 2,
    'warps': 1,
    'warr': 1,
    'warrant': 2,
    'warranted': 3,
    'warranties': 3,
    'warrantless': 3,
    'warrants': 2,
    'warranty': 3,
    'warrell': 2,
    'warren': 2,
    'warrens': 2,
    'warrenton': 3,
    'warrick': 2,
    'warriner': 3,
    'warring': 2,
    'warrington': 3,
    'warrior': 3,
    'warriors': 3,
    'wars': 1,
    'warsaw': 2,
    'warsaws': 2,
    'warshauer': 3,
    'warshaw': 2,
    'warshawsky': 3,
    'warship': 2,
    'warships': 2,
    'warstler': 2,
    'wart': 1,
    'wartenberg': 3,
    'warth': 1,
    'warthen': 2,
    'wartime': 2,
    'wartimes': 2,
    'wartman': 2,
    'warton': 2,
    'warts': 1,
    'wartzman': 2,
    'warwick': 2,
    'wary': 2,
    'warzecha': 3,
    'was': 1,
    'wasatch': 2,
    'wasch': 1,
    'wascher': 2,
    'wasco': 2,
    'wascom': 2,
    'wasden': 2,
    'waseda': 3,
    'wasem': 2,
    'wash': 1,
    'washabaugh': 3,
    'washable': 3,
    'washam': 2,
    'washbasin': 3,
    'washboard': 2,
    'washburn': 2,
    'washburne': 2,
    'washcloth': 2,
    'washed': 1,
    'washer': 2,
    'washers': 2,
    'washes': 2,
    'washing': 2,
    'washington': 3,
    'washingtonian': 5,
    'washingtonians': 5,
    'washko': 2,
    'washoe': 2,
    'washout': 2,
    'washouts': 2,
    'washroom': 2,
    'washtub': 2,
    'washy': 2,
    'wasielewski': 4,
    'wasik': 2,
    'wasil': 2,
    'wasilewski': 4,
    'wasinger': 3,
    'wasiyu': 3,
    'waskiewicz': 3,
    'wasko': 2,
    'waskow': 2,
    'wasley': 2,
    'wasmer': 3,
    'wasmund': 2,
    'wasmuth': 2,
    'wason': 2,
    'wasp': 1,
    'wasps': 1,
    'wass': 1,
    'wassel': 2,
    'wassell': 2,
    'wassenaar': 3,
    'wasser': 2,
    'wasserman': 3,
    'wasserstein': 3,
    'wassilievitch': 4,
    'wassily': 3,
    'wassink': 2,
    'wassman': 2,
    'wassmer': 2,
    'wassom': 2,
    'wasson': 2,
    'wassum': 2,
    'waste': 1,
    'wastebasket': 3,
    'wastebaskets': 3,
    'wasted': 2,
    'wasteful': 2,
    'wastefulness': 3,
    'wasteland': 2,
    'wastepaper': 3,
    'waster': 2,
    'wasters': 2,
    'wastes': 1,
    'wastewater': 3,
    'wasting': 2,
    'waszak': 2,
    'waszkiewicz': 3,
    'wat': 1,
    'watanabe': 4,
    'watch': 1,
    'watchdog': 2,
    'watchdogs': 2,
    'watched': 1,
    'watcher': 2,
    'watchers': 2,
    'watches': 2,
    'watchful': 2,
    'watching': 2,
    'watchman': 2,
    'watchmen': 2,
    'watchorn': 2,
    'watchword': 2,
    'watchwords': 2,
    'water': 2,
    'waterbed': 3,
    'waterbeds': 3,
    'waterborne': 3,
    'waterbottle': 4,
    'waterbottles': 4,
    'waterbury': 4,
    'watercolor': 4,
    'watercolors': 4,
    'watercress': 3,
    'watered': 2,
    'waterer': 3,
    'waterfall': 3,
    'waterfalls': 3,
    'waterfield': 3,
    'waterford': 3,
    'waterfowl': 3,
    'waterfront': 3,
    'waterfronts': 3,
    'watergate': 3,
    'waterhouse': 3,
    'watering': 3,
    'waterishness': 4,
    'waterlog': 3,
    'waterlogged': 3,
    'waterloo': 3,
    'waterman': 3,
    'watermark': 3,
    'watermarks': 3,
    'watermelon': 4,
    'watermelons': 4,
    'watermen': 3,
    'waterproof': 3,
    'waterproofing': 4,
    'waters': 2,
    'watershed': 3,
    'watersheds': 3,
    'waterson': 3,
    'waterstein': 3,
    'waterston': 3,
    'waterstone': 3,
    'watertight': 3,
    'watertown': 3,
    'waterville': 3,
    'waterway': 3,
    'waterways': 3,
    'waterwork': 3,
    'waterworks': 3,
    'waterworld': 3,
    'waterworth': 3,
    'watery': 3,
    'watford': 2,
    'wathen': 2,
    'watkin': 2,
    'watkins': 2,
    'watkinson': 3,
    'watland': 2,
    'watley': 2,
    'watling': 3,
    'watlington': 4,
    'watne': 1,
    'watrous': 2,
    'watry': 2,
    'watsco': 2,
    'watson': 2,
    'watsonville': 3,
    'watt': 1,
    'wattage': 2,
    'wattenbarger': 4,
    'wattenberg': 3,
    'wattenburg': 3,
    'watters': 2,
    'wattie': 2,
    'wattle': 2,
    'wattled': 2,
    'wattles': 2,
    'wattleton': 3,
    'watton': 2,
    'watts': 1,
    'wattwil': 2,
    'watwood': 2,
    'watz': 1,
    'watzman': 2,
    'waugaman': 3,
    'waugh': 1,
    'waukegan': 3,
    'waukesha': 3,
    'wausau': 2,
    'wauters': 2,
    'wave': 1,
    'waved': 1,
    'wavelength': 2,
    'wavelengths': 2,
    'waver': 2,
    'wavered': 2,
    'wavering': 3,
    'waverley': 3,
    'waverly': 3,
    'waves': 1,
    'wavetek': 2,
    'waving': 2,
    'wavra': 2,
    'wavy': 2,
    'wawrzyniak': 4,
    'wax': 1,
    'waxed': 1,
    'waxes': 2,
    'waxing': 2,
    'waxler': 2,
    'waxman': 2,
    'waxy': 2,
    'way': 1,
    'wayans': 2,
    'waybright': 2,
    'waycaster': 3,
    'waye': 1,
    'waylaid': 2,
    'wayland': 2,
    'waylay': 2,
    'waylon': 2,
    'wayman': 2,
    'waymire': 2,
    'wayne': 1,
    'waynesboro': 3,
    'waynesville': 2,
    'waynick': 2,
    'ways': 1,
    'wayside': 2,
    'wayson': 2,
    'wayt': 1,
    'wayward': 2,
    'wazir': 2,
    'we': 1,
    'weak': 1,
    'weaken': 2,
    'weakened': 2,
    'weakening': 3,
    'weakens': 2,
    'weaker': 2,
    'weakest': 2,
    'weakland': 2,
    'weakley': 2,
    'weakling': 2,
    'weakly': 2,
    'weakness': 2,
    'weaknesses': 3,
    'weaks': 1,
    'wealth': 1,
    'wealthier': 3,
    'wealthiest': 3,
    'wealths': 1,
    'wealthy': 2,
    'wean': 1,
    'weaned': 1,
    'weaning': 2,
    'weant': 1,
    'weapon': 2,
    'weaponry': 3,
    'weapons': 2,
    'wear': 1,
    'wearable': 3,
    'wearer': 2,
    'wearers': 2,
    'wearever': 3,
    'wearhouse': 2,
    'wearied': 2,
    'wearily': 3,
    'weariness': 3,
    'wearing': 2,
    'wearisome': 3,
    'wears': 1,
    'weary': 2,
    'wearying': 3,
    'wease': 1,
    'weasel': 2,
    'weasellike': 3,
    'weasels': 2,
    'weast': 1,
    'weather': 2,
    'weatherall': 3,
    'weatherbee': 3,
    'weatherby': 3,
    'weathered': 2,
    'weatherford': 3,
    'weatherhead': 3,
    'weatherholt': 3,
    'weatherholtz': 3,
    'weathering': 3,
    'weatherington': 4,
    'weatherly': 3,
    'weatherman': 3,
    'weathermen': 3,
    'weatherperson': 4,
    'weatherproof': 3,
    'weathers': 2,
    'weathersbee': 3,
    'weathersby': 3,
    'weatherspoon': 3,
    'weatherstone': 3,
    'weatherwax': 3,
    'weathington': 3,
    'weave': 1,
    'weaved': 1,
    'weaver': 2,
    'weaverling': 3,
    'weavers': 2,
    'weaves': 1,
    'weavil': 2,
    'weaving': 2,
    'weavings': 2,
    'web': 1,
    'webb': 1,
    'webbed': 1,
    'webber': 2,
    'webbing': 2,
    'webbs': 1,
    'weber': 2,
    'weberg': 2,
    'webers': 2,
    'webley': 2,
    'webmaster': 3,
    'webre': 2,
    'webs': 1,
    'website': 2,
    'webster': 2,
    'websters': 2,
    'wechsel': 2,
    'wechsler': 2,
    'wecht': 1,
    'wechter': 2,
    'weck': 1,
    'wecker': 2,
    'weckerly': 3,
    'weckesser': 3,
    'weckwerth': 2,
    'wed': 1,
    'wedbush': 2,
    'wedd': 1,
    'wedded': 2,
    'weddell': 2,
    'wedding': 2,
    'weddings': 2,
    'weddington': 3,
    'weddle': 2,
    'wedekind': 3,
    'wedeking': 3,
    'wedel': 2,
    'wedell': 2,
    'wedemeyer': 4,
    'wedge': 1,
    'wedged': 1,
    'wedges': 2,
    'wedgestone': 2,
    'wedgewood': 2,
    'wedgeworth': 2,
    'wedgwood': 2,
    'wedig': 2,
    'wedin': 2,
    'wedlock': 2,
    'wednesday': 2,
    'wednesdays': 2,
    'wedowee': 3,
    'wedtech': 2,
    'wee': 1,
    'weeber': 2,
    'weech': 1,
    'weed': 1,
    'weeded': 2,
    'weeden': 2,
    'weeding': 2,
    'weedman': 2,
    'weedon': 2,
    'weeds': 1,
    'weedy': 2,
    'weehawken': 3,
    'week': 1,
    'weekday': 2,
    'weekdays': 2,
    'weekend': 2,
    'weekender': 3,
    'weekends': 2,
    'weekes': 1,
    'weekley': 2,
    'weeklies': 2,
    'weeklong': 2,
    'weekly': 2,
    'weeks': 1,
    'weems': 1,
    'weeny': 2,
    'weep': 1,
    'weeping': 2,
    'weeps': 1,
    'weepy': 2,
    'weers': 2,
    'weerts': 1,
    'weese': 1,
    'weesner': 2,
    'weevil': 2,
    'weevils': 2,
    'wegbreit': 2,
    'wege': 1,
    'wegener': 3,
    'weger': 2,
    'weglarz': 2,
    'wegman': 2,
    'wegmann': 2,
    'wegner': 2,
    'wegrzyn': 3,
    'wehde': 1,
    'wehe': 1,
    'wehlan': 2,
    'wehle': 2,
    'wehler': 2,
    'wehling': 2,
    'wehman': 2,
    'wehmeier': 3,
    'wehmeyer': 3,
    'wehner': 2,
    'wehr': 1,
    'wehrenberg': 3,
    'wehrheim': 2,
    'wehrle': 2,
    'wehrli': 2,
    'wehrly': 2,
    'wehrman': 2,
    'wehrmann': 2,
    'wehunt': 2,
    'wei': 1,
    'weiand': 2,
    'weibel': 2,
    'weible': 2,
    'weich': 1,
    'weichel': 2,
    'weichert': 2,
    'weichman': 2,
    'weicht': 1,
    'weick': 1,
    'weicker': 2,
    'weida': 2,
    'weide': 2,
    'weidel': 2,
    'weideman': 2,
    'weidemann': 2,
    'weidenbach': 3,
    'weidenbaum': 3,
    'weidenfeld': 3,
    'weider': 2,
    'weiderman': 3,
    'weidig': 2,
    'weidinger': 3,
    'weidler': 3,
    'weidlich': 2,
    'weidman': 2,
    'weidmann': 2,
    'weidner': 2,
    'weier': 2,
    'weigand': 2,
    'weigandt': 2,
    'weigel': 2,
    'weigelt': 2,
    'weigert': 2,
    'weigh': 1,
    'weighed': 1,
    'weighing': 2,
    'weighs': 1,
    'weight': 1,
    'weighted': 2,
    'weighting': 2,
    'weightings': 2,
    'weightless': 2,
    'weightlessness': 3,
    'weightlifter': 3,
    'weightlifters': 3,
    'weightlifting': 3,
    'weightman': 2,
    'weights': 1,
    'weighty': 2,
    'weigl': 2,
    'weigle': 2,
    'weigold': 2,
    'weihe': 1,
    'weiher': 2,
    'weik': 1,
    'weikel': 2,
    'weiker': 2,
    'weikert': 2,
    'weikle': 2,
    'weil': 1,
    'weiland': 2,
    'weilbacher': 3,
    'weild': 1,
    'weiler': 2,
    'weill': 1,
    'weiman': 2,
    'weimann': 2,
    'weimar': 2,
    'weimer': 2,
    'weimeyer': 3,
    'wein': 1,
    'weinand': 2,
    'weinbach': 2,
    'weinbaum': 2,
    'weinberg': 2,
    'weinberger': 3,
    'weindel': 2,
    'weinel': 2,
    'weiner': 2,
    'weinert': 2,
    'weinfeld': 2,
    'weingart': 2,
    'weingarten': 3,
    'weingartner': 3,
    'weinger': 2,
    'weinhardt': 2,
    'weinheimer': 3,
    'weinhold': 2,
    'weininger': 3,
    'weinkauf': 2,
    'weinman': 2,
    'weinmann': 2,
    'weinreb': 2,
    'weinreich': 2,
    'weinrich': 2,
    'weinroth': 2,
    'weins': 1,
    'weinstein': 2,
    'weinstock': 2,
    'weintraub': 2,
    'weintz': 1,
    'weinzierl': 2,
    'weipert': 2,
    'weir': 1,
    'weirauch': 2,
    'weird': 1,
    'weirder': 2,
    'weirdest': 2,
    'weirdly': 2,
    'weirdness': 2,
    'weirdo': 2,
    'weirdos': 2,
    'weirich': 2,
    'weirick': 2,
    'weirton': 2,
    'weis': 1,
    'weisbach': 2,
    'weisbecker': 3,
    'weisberg': 2,
    'weisberger': 3,
    'weisbrod': 2,
    'weisbrot': 2,
    'weischedel': 3,
    'weise': 1,
    'weisel': 2,
    'weiseman': 3,
    'weisenbach': 3,
    'weisenberg': 3,
    'weisenberger': 4,
    'weisenborn': 3,
    'weisenburger': 4,
    'weisensel': 3,
    'weisenthal': 3,
    'weiser': 2,
    'weisfeld': 2,
    'weisgerber': 3,
    'weishaar': 2,
    'weishaupt': 2,
    'weisheit': 2,
    'weisinger': 3,
    'weiskopf': 2,
    'weisman': 2,
    'weismann': 2,
    'weisner': 2,
    'weiss': 1,
    'weissberg': 2,
    'weisse': 1,
    'weissenborn': 3,
    'weisser': 2,
    'weissert': 2,
    'weissinger': 3,
    'weissman': 2,
    'weissmann': 2,
    'weist': 2,
    'weisz': 1,
    'weitek': 2,
    'weith': 1,
    'weitkamp': 2,
    'weitler': 2,
    'weitman': 2,
    'weitz': 1,
    'weitzel': 2,
    'weitzen': 2,
    'weitzman': 2,
    'weitzner': 2,
    'weizman': 2,
    'weizsaecker': 3,
    'weksel': 2,
    'welbilt': 2,
    'welborn': 2,
    'welborne': 2,
    'welby': 2,
    'welch': 1,
    'welchel': 2,
    'welcher': 2,
    'welcome': 2,
    'welcomed': 2,
    'welcomes': 2,
    'welcoming': 3,
    'weld': 1,
    'welded': 2,
    'welden': 2,
    'welder': 2,
    'welders': 2,
    'weldin': 2,
    'welding': 2,
    'weldon': 2,
    'welds': 1,
    'weldwood': 2,
    'weldy': 2,
    'welfare': 2,
    'welford': 2,
    'welge': 1,
    'welinder': 3,
    'welinsk': 2,
    'welk': 1,
    'welke': 1,
    'welker': 2,
    'well': 1,
    'welland': 2,
    'wellbeing': 3,
    'wellborn': 2,
    'wellbrock': 2,
    'wellco': 2,
    'wellcome': 2,
    'welle': 1,
    'wellek': 2,
    'wellen': 2,
    'wellendorf': 3,
    'wellens': 2,
    'weller': 2,
    'welles': 1,
    'wellesley': 2,
    'wellfleet': 2,
    'wellhead': 2,
    'wellies': 2,
    'welling': 2,
    'wellington': 3,
    'welliver': 3,
    'wellman': 2,
    'wellner': 2,
    'wellness': 2,
    'wellnitz': 2,
    'wellons': 2,
    'wellpoint': 2,
    'wells': 1,
    'wellspring': 2,
    'wellstone': 2,
    'welltech': 2,
    'welna': 2,
    'welp': 1,
    'welsch': 1,
    'welser': 2,
    'welsh': 1,
    'welshans': 2,
    'welt': 1,
    'welte': 1,
    'welter': 2,
    'welterweight': 3,
    'weltman': 2,
    'welton': 2,
    'welty': 2,
    'weltz': 1,
    'welz': 1,
    'wembley': 2,
    'wemhoff': 2,
    'wemmer': 2,
    'wempe': 1,
    'wemple': 2,
    'wen': 1,
    'wenatchee': 3,
    'wenberg': 2,
    'wenceslaus': 3,
    'wenches': 2,
    'wenchester': 3,
    'wenck': 1,
    'wend': 1,
    'wenda': 2,
    'wende': 1,
    'wendel': 2,
    'wendeline': 3,
    'wendelken': 3,
    'wendell': 2,
    'wender': 2,
    'wenders': 2,
    'wending': 2,
    'wendland': 2,
    'wendlandt': 2,
    'wendler': 2,
    'wendling': 2,
    'wendorf': 2,
    'wendorff': 2,
    'wends': 1,
    'wendt': 1,
    'wendy': 2,
    'wenfan': 2,
    'weng': 1,
    'wenge': 1,
    'wenger': 2,
    'wengerd': 2,
    'wengert': 2,
    'wengler': 3,
    'wenig': 2,
    'weniger': 3,
    'weninger': 3,
    'wenk': 1,
    'wenke': 1,
    'wenker': 2,
    'wennberg': 2,
    'wenner': 2,
    'wennerstrom': 3,
    'wenning': 2,
    'wenninger': 3,
    'wenona': 3,
    'wenrich': 2,
    'wenrick': 2,
    'wensberg': 2,
    'wensel': 2,
    'wenstrom': 2,
    'went': 1,
    'wente': 1,
    'wentland': 2,
    'wentling': 2,
    'wentworth': 2,
    'wentz': 1,
    'wentzel': 2,
    'wentzell': 2,
    'wentzville': 2,
    'wenz': 1,
    'wenzel': 2,
    'wenzhou': 2,
    'wenzl': 2,
    'wenzler': 2,
    'wenzlick': 2,
    'weppler': 2,
    'wept': 1,
    'werber': 2,
    'werden': 2,
    'werder': 2,
    'werdesheim': 2,
    'were': 1,
    'wereldhave': 4,
    'werewolf': 2,
    'wergin': 2,
    'werk': 1,
    'werke': 1,
    'werkheiser': 3,
    'werking': 2,
    'werkmeister': 3,
    'werle': 2,
    'werley': 2,
    'werling': 2,
    'werman': 2,
    'wermer': 2,
    'wermiel': 3,
    'wermuth': 2,
    'werne': 2,
    'wernecke': 2,
    'werner': 2,
    'wernert': 2,
    'wernet': 2,
    'wernette': 2,
    'wernick': 2,
    'wernicke': 2,
    'wernimont': 3,
    'werning': 2,
    'wernli': 2,
    'werntz': 1,
    'werre': 1,
    'werry': 2,
    'werst': 1,
    'wert': 1,
    'wertenberger': 4,
    'werth': 1,
    'wertheim': 2,
    'wertheimer': 3,
    'werther': 2,
    'wertman': 2,
    'werts': 1,
    'wertz': 1,
    'wery': 2,
    'wes': 1,
    'wesat': 2,
    'wesch': 1,
    'wesche': 1,
    'wesco': 2,
    'wescoat': 2,
    'wescott': 2,
    'weseloh': 3,
    'wesely': 2,
    'weseman': 2,
    'wesemann': 2,
    'wesenberg': 3,
    'wesker': 2,
    'wesler': 3,
    'wesley': 2,
    'wesleyan': 3,
    'weslia': 3,
    'wesner': 2,
    'wesolek': 3,
    'wesolowski': 4,
    'wesp': 1,
    'wespac': 2,
    'wespercorp': 3,
    'wesray': 2,
    'wess': 1,
    'wessel': 2,
    'wessell': 2,
    'wessells': 2,
    'wesselman': 3,
    'wessels': 2,
    'wessex': 2,
    'wessinger': 3,
    'wessler': 2,
    'wessling': 3,
    'wessman': 2,
    'wessner': 2,
    'wesson': 2,
    'west': 1,
    'westair': 2,
    'westall': 2,
    'westamerica': 5,
    'westar': 2,
    'westbay': 2,
    'westberg': 2,
    'westberry': 3,
    'westboro': 3,
    'westborough': 3,
    'westbound': 2,
    'westbridge': 2,
    'westbrook': 2,
    'westbrooks': 2,
    'westburne': 2,
    'westbury': 3,
    'westby': 2,
    'westcap': 2,
    'westchester': 3,
    'westcoast': 2,
    'westcorp': 2,
    'westcott': 2,
    'westdeutsche': 2,
    'westech': 2,
    'westen': 2,
    'westenberger': 4,
    'westendorf': 3,
    'wester': 2,
    'westerbeck': 3,
    'westerberg': 3,
    'westerdam': 3,
    'westerfeld': 3,
    'westerfield': 3,
    'westergaard': 3,
    'westergard': 3,
    'westergren': 3,
    'westerhoff': 3,
    'westerhold': 3,
    'westerlies': 3,
    'westerlund': 3,
    'westerly': 3,
    'westerman': 3,
    'westermann': 3,
    'westermeyer': 4,
    'western': 2,
    'westerner': 3,
    'westerners': 3,
    'westernization': 5,
    'westernize': 3,
    'westernized': 3,
    'westernmost': 3,
    'westerns': 2,
    'westervelt': 3,
    'westfaelisches': 4,
    'westfahl': 2,
    'westfall': 2,
    'westfed': 2,
    'westfield': 2,
    'westford': 2,
    'westgate': 2,
    'westhampton': 3,
    'westheimer': 3,
    'westhoff': 2,
    'westin': 2,
    'westinghouse': 3,
    'westlake': 2,
    'westland': 2,
    'westleigh': 2,
    'westley': 2,
    'westling': 2,
    'westlund': 2,
    'westman': 2,
    'westmarc': 2,
    'westmark': 2,
    'westmin': 2,
    'westminster': 3,
    'westmoreland': 3,
    'weston': 2,
    'westover': 3,
    'westpac': 2,
    'westphal': 2,
    'westphalen': 3,
    'westphalia': 4,
    'westport': 2,
    'westpride': 2,
    'westra': 2,
    'westrich': 2,
    'westrick': 2,
    'westridge': 2,
    'westrom': 2,
    'westrum': 2,
    'westrup': 2,
    'westside': 2,
    'westtech': 2,
    'westvaco': 3,
    'westward': 2,
    'westwards': 2,
    'westwood': 2,
    'westworld': 2,
    'wesun': 2,
    'wet': 1,
    'wetherbee': 3,
    'wetherby': 3,
    'wetherell': 3,
    'wetherill': 3,
    'wetherington': 4,
    'wetherly': 3,
    'wethington': 3,
    'wetland': 2,
    'wetlands': 2,
    'wetmore': 2,
    'wetness': 2,
    'wetsel': 2,
    'wetstein': 2,
    'wetsuit': 2,
    'wettengel': 3,
    'wetter': 2,
    'wetterau': 3,
    'wettergreen': 3,
    'wettest': 2,
    'wetting': 2,
    'wettlaufer': 3,
    'wettstein': 2,
    'wetz': 1,
    'wetzel': 2,
    'wetzler': 2,
    'wetzstein': 2,
    'wever': 2,
    'wexler': 2,
    'wexner': 2,
    'wey': 1,
    'weyand': 2,
    'weyandt': 2,
    'weyant': 2,
    'weyenberg': 2,
    'weyer': 2,
    'weyerhaeuser': 3,
    'weyers': 2,
    'weyforth': 2,
    'weygandt': 2,
    'weyhrauch': 2,
    'weyl': 1,
    'weyland': 2,
    'weylin': 2,
    'weyman': 2,
    'weymer': 2,
    'weymouth': 2,
    'weyrauch': 2,
    'weyrich': 2,
    'weyrick': 2,
    'whack': 1,
    'whacked': 1,
    'whacker': 2,
    'whacking': 2,
    'whacko': 2,
    'whacks': 1,
    'whale': 1,
    'whalen': 2,
    'whaler': 2,
    'whalers': 2,
    'whales': 1,
    'whaley': 2,
    'whalin': 2,
    'whaling': 2,
    'whalley': 2,
    'wham': 1,
    'whammy': 2,
    'whampoa': 3,
    'whan': 1,
    'whang': 1,
    'wharf': 1,
    'wharff': 1,
    'wharfs': 1,
    'wharry': 2,
    'wharton': 2,
    'what': 1,
    'whate': 1,
    'whatever': 3,
    'whatley': 2,
    'whatnot': 2,
    'whats': 1,
    'whatsoever': 4,
    'wheat': 1,
    'wheaten': 2,
    'wheatie': 2,
    'wheaties': 2,
    'wheatley': 2,
    'wheatly': 2,
    'wheaton': 2,
    'whedbee': 2,
    'whedon': 2,
    'wheel': 1,
    'wheelabrator': 4,
    'wheelan': 2,
    'wheeland': 2,
    'wheelbarrow': 3,
    'wheelbarrows': 3,
    'wheelbase': 2,
    'wheelchair': 2,
    'wheelchairs': 2,
    'wheeldon': 2,
    'wheeled': 1,
    'wheeler': 2,
    'wheelers': 2,
    'wheeless': 2,
    'wheeling': 2,
    'wheelis': 2,
    'wheelock': 2,
    'wheelon': 2,
    'wheels': 1,
    'wheelus': 2,
    'wheelwright': 2,
    'wheelwriter': 3,
    'wheeze': 1,
    'wheezes': 2,
    'wheezing': 2,
    'whelan': 2,
    'whelchel': 2,
    'wheless': 2,
    'whelpley': 2,
    'whelton': 2,
    'when': 1,
    'whence': 1,
    'whenever': 3,
    'where': 1,
    'whereabouts': 3,
    'whereas': 2,
    'whereby': 2,
    'wherein': 2,
    'whereupon': 3,
    'wherever': 3,
    'wherewithal': 3,
    'wherley': 2,
    'wherry': 2,
    'whet': 1,
    'whether': 2,
    'whetsel': 2,
    'whetsell': 2,
    'whetstine': 2,
    'whetstone': 2,
    'whetted': 2,
    'whetzel': 2,
    'whew': 1,
    'whey': 1,
    'which': 1,
    'whichard': 2,
    'whichever': 3,
    'whicker': 2,
    'whidby': 2,
    'whidden': 2,
    'whiddon': 2,
    'whiff': 1,
    'whig': 1,
    'whigham': 2,
    'whigs': 1,
    'whilden': 2,
    'while': 1,
    'whiles': 1,
    'whilst': 1,
    'whim': 1,
    'whimper': 2,
    'whimpering': 3,
    'whims': 1,
    'whimsical': 3,
    'whimsically': 3,
    'whimsy': 2,
    'whine': 1,
    'whiner': 2,
    'whiners': 2,
    'whinery': 3,
    'whines': 1,
    'whining': 2,
    'whinnery': 3,
    'whinney': 2,
    'whiny': 2,
    'whip': 1,
    'whipkey': 2,
    'whiplash': 2,
    'whiplashes': 3,
    'whipp': 1,
    'whippany': 3,
    'whipped': 1,
    'whipping': 2,
    'whipple': 2,
    'whippoorwills': 3,
    'whipps': 1,
    'whips': 1,
    'whipsaw': 2,
    'whipsawed': 2,
    'whipsawing': 3,
    'whirl': 1,
    'whirled': 1,
    'whirley': 2,
    'whirling': 2,
    'whirlpool': 2,
    'whirlpools': 2,
    'whirlwind': 2,
    'whirring': 2,
    'whisenand': 3,
    'whisenant': 3,
    'whisenhunt': 3,
    'whisk': 1,
    'whisked': 1,
    'whisker': 2,
    'whiskers': 2,
    'whiskey': 2,
    'whiskeys': 2,
    'whisks': 1,
    'whisky': 2,
    'whisler': 2,
    'whisman': 2,
    'whisnant': 2,
    'whisner': 2,
    'whisonant': 3,
    'whisper': 2,
    'whispered': 2,
    'whispering': 3,
    'whispers': 2,
    'whistle': 2,
    'whistleblower': 4,
    'whistleblowers': 4,
    'whistled': 2,
    'whistler': 2,
    'whistlers': 2,
    'whistles': 2,
    'whistling': 2,
    'whiston': 2,
    'whit': 1,
    'whitacre': 3,
    'whitaker': 3,
    'whitbeck': 2,
    'whitbread': 2,
    'whitby': 2,
    'whitcher': 2,
    'whitchurch': 2,
    'whitcomb': 2,
    'whitcraft': 2,
    'white': 1,
    'whiteaker': 3,
    'whitebread': 2,
    'whitecotton': 3,
    'whited': 2,
    'whitefield': 2,
    'whitefish': 2,
    'whiteford': 2,
    'whitehair': 2,
    'whitehall': 2,
    'whitehead': 2,
    'whitehill': 2,
    'whitehorn': 2,
    'whitehorse': 2,
    'whitehouse': 2,
    'whitehurst': 2,
    'whitelaw': 2,
    'whiteley': 2,
    'whitelock': 2,
    'whitely': 2,
    'whiteman': 2,
    'whitemont': 2,
    'whiten': 2,
    'whitenack': 2,
    'whiteneir': 2,
    'whitener': 2,
    'whiteness': 2,
    'whitenight': 2,
    'whitening': 3,
    'whiter': 2,
    'whites': 1,
    'whitescarver': 3,
    'whitesel': 2,
    'whitesell': 2,
    'whiteside': 2,
    'whitesides': 2,
    'whitest': 2,
    'whitestone': 2,
    'whitetail': 2,
    'whitewash': 2,
    'whitewashed': 2,
    'whitewater': 3,
    'whitey': 2,
    'whitfield': 2,
    'whitfill': 2,
    'whitford': 2,
    'whitham': 2,
    'whither': 2,
    'whiting': 2,
    'whitings': 2,
    'whitis': 2,
    'whitish': 2,
    'whitlatch': 2,
    'whitledge': 2,
    'whitley': 2,
    'whitling': 2,
    'whitlock': 2,
    'whitlow': 2,
    'whitly': 2,
    'whitman': 2,
    'whitmarsh': 2,
    'whitmer': 2,
    'whitmill': 2,
    'whitmire': 2,
    'whitmore': 2,
    'whitmoyer': 3,
    'whitmyer': 3,
    'whitner': 2,
    'whitney': 2,
    'whiton': 2,
    'whitrow': 2,
    'whitsel': 2,
    'whitsell': 2,
    'whitsett': 2,
    'whitsitt': 2,
    'whitson': 2,
    'whitt': 1,
    'whittaker': 3,
    'whitted': 2,
    'whittemore': 2,
    'whitten': 2,
    'whittenberg': 3,
    'whittenburg': 3,
    'whittet': 2,
    'whittier': 3,
    'whitting': 2,
    'whittingham': 3,
    'whittinghill': 3,
    'whittington': 3,
    'whittle': 2,
    'whittled': 2,
    'whittlesey': 3,
    'whittling': 2,
    'whitton': 2,
    'whitty': 2,
    'whitwam': 2,
    'whitwell': 2,
    'whitworth': 2,
    'whiz': 1,
    'whizzed': 1,
    'whizzer': 2,
    'whizzes': 2,
    'whizzing': 2,
    'who': 1,
    'whoa': 1,
    'whobrey': 2,
    'whodunit': 3,
    'whodunits': 3,
    'whoever': 3,
    'whole': 1,
    'wholehearted': 3,
    'wholeheartedly': 4,
    'wholeness': 2,
    'wholes': 1,
    'wholesale': 2,
    'wholesaler': 3,
    'wholesalers': 3,
    'wholesales': 2,
    'wholesaling': 3,
    'wholesome': 2,
    'wholesomeness': 3,
    'wholey': 2,
    'wholly': 2,
    'whom': 1,
    'whomever': 3,
    'whomsoever': 4,
    'whoop': 1,
    'whoopdedoo': 3,
    'whooped': 1,
    'whoopee': 2,
    'whoopi': 2,
    'whoopie': 2,
    'whooping': 2,
    'whoops': 1,
    'whoosh': 1,
    'whopper': 2,
    'whoppers': 2,
    'whopping': 2,
    'whore': 1,
    'whorehouse': 2,
    'whores': 1,
    'whorl': 1,
    'whorley': 2,
    'whorls': 1,
    'whorton': 2,
    'whose': 1,
    'whosoever': 4,
    'why': 1,
    'whys': 1,
    'whyte': 1,
    'wiacek': 3,
    'wian': 2,
    'wiant': 2,
    'wiard': 2,
    'wiater': 3,
    'wiatrowski': 4,
    'wiatt': 2,
    'wibbelsman': 3,
    'wibbenmeyer': 4,
    'wiberg': 2,
    'wible': 2,
    'wicat': 2,
    'wicca': 2,
    'wiccan': 2,
    'wice': 1,
    'wich': 1,
    'wichern': 2,
    'wichert': 2,
    'wichita': 3,
    'wichman': 2,
    'wichmann': 2,
    'wick': 1,
    'wickard': 2,
    'wicke': 1,
    'wicked': 2,
    'wickedly': 3,
    'wickedness': 3,
    'wickens': 2,
    'wicker': 2,
    'wickerham': 3,
    'wickersham': 3,
    'wickert': 2,
    'wickes': 1,
    'wicket': 2,
    'wickets': 2,
    'wickett': 2,
    'wickey': 2,
    'wickham': 2,
    'wickizer': 3,
    'wickland': 2,
    'wicklander': 3,
    'wickley': 2,
    'wickliff': 2,
    'wickliffe': 2,
    'wickline': 2,
    'wicklund': 2,
    'wickman': 2,
    'wicks': 1,
    'wickstrom': 2,
    'wickware': 2,
    'wickwire': 2,
    'wicor': 2,
    'widder': 2,
    'widdle': 2,
    'widdled': 2,
    'widdly': 2,
    'widdows': 2,
    'widdowson': 3,
    'wide': 1,
    'widebody': 3,
    'widell': 2,
    'widely': 2,
    'wideman': 2,
    'widen': 2,
    'widened': 2,
    'widener': 3,
    'widening': 3,
    'widens': 2,
    'wider': 2,
    'widespread': 2,
    'widest': 2,
    'widger': 2,
    'widget': 2,
    'widgets': 2,
    'widhalm': 2,
    'widick': 2,
    'widing': 2,
    'widmaier': 3,
    'widman': 2,
    'widmann': 2,
    'widmar': 2,
    'widmayer': 3,
    'widmer': 2,
    'widnall': 2,
    'widner': 2,
    'widow': 2,
    'widowed': 2,
    'widower': 3,
    'widowers': 3,
    'widows': 2,
    'widrick': 2,
    'widrig': 2,
    'width': 1,
    'widths': 1,
    'widuri': 3,
    'wieand': 2,
    'wiebe': 1,
    'wieber': 2,
    'wieberg': 2,
    'wiebke': 2,
    'wiebold': 2,
    'wiebusch': 2,
    'wiech': 1,
    'wiechert': 2,
    'wiechman': 2,
    'wiechmann': 2,
    'wieck': 1,
    'wieczorek': 3,
    'wied': 1,
    'wiedel': 2,
    'wiedeman': 2,
    'wiedemann': 2,
    'wieden': 2,
    'wieder': 2,
    'wiederaufbau': 4,
    'wiederhold': 3,
    'wiederholt': 3,
    'wiederkehr': 3,
    'wiedman': 2,
    'wiedmann': 2,
    'wiedmeyer': 3,
    'wiedner': 2,
    'wiedrich': 2,
    'wiegand': 2,
    'wiegel': 2,
    'wiegers': 2,
    'wiegert': 2,
    'wiegman': 2,
    'wiegmann': 2,
    'wiehe': 1,
    'wieland': 2,
    'wield': 1,
    'wielded': 2,
    'wielding': 2,
    'wields': 1,
    'wielgus': 2,
    'wieman': 2,
    'wiemann': 2,
    'wiemer': 2,
    'wiemers': 2,
    'wien': 1,
    'wiencek': 2,
    'wienecke': 2,
    'wieneke': 2,
    'wiener': 2,
    'wieners': 2,
    'wienerschnitzel': 4,
    'wienke': 1,
    'wiens': 1,
    'wier': 1,
    'wierdin': 2,
    'wierenga': 3,
    'wierman': 2,
    'wiers': 1,
    'wiersema': 3,
    'wiersma': 2,
    'wierzba': 2,
    'wierzbicki': 3,
    'wies': 1,
    'wiesbaden': 3,
    'wiese': 1,
    'wiesel': 2,
    'wieseler': 3,
    'wiesemann': 2,
    'wiesen': 2,
    'wiesenthal': 3,
    'wieser': 2,
    'wiesman': 2,
    'wiesner': 2,
    'wiess': 1,
    'wiessner': 2,
    'wiest': 2,
    'wieting': 2,
    'wife': 1,
    'wig': 1,
    'wigal': 2,
    'wigand': 2,
    'wigen': 2,
    'wigfall': 2,
    'wigfield': 2,
    'wigg': 1,
    'wiggans': 2,
    'wigger': 2,
    'wiggers': 2,
    'wiggin': 2,
    'wiggington': 3,
    'wiggins': 2,
    'wigginton': 3,
    'wiggle': 2,
    'wigglesworth': 3,
    'wiggling': 3,
    'wiggly': 3,
    'wiggs': 1,
    'wight': 1,
    'wightman': 2,
    'wigington': 3,
    'wiginton': 3,
    'wigle': 2,
    'wigley': 2,
    'wigmore': 2,
    'wignall': 2,
    'wigs': 1,
    'wigton': 2,
    'wigwam': 2,
    'wiitala': 3,
    'wik': 1,
    'wike': 1,
    'wikel': 2,
    'wiker': 2,
    'wikle': 2,
    'wiklund': 2,
    'wikoff': 2,
    'wikowsky': 3,
    'wikstrom': 2,
    'wil': 1,
    'wiland': 2,
    'wilander': 3,
    'wilbanks': 2,
    'wilber': 2,
    'wilberforce': 3,
    'wilberg': 2,
    'wilbert': 2,
    'wilbon': 2,
    'wilborn': 2,
    'wilbourn': 2,
    'wilbourne': 2,
    'wilbur': 2,
    'wilburn': 2,
    'wilcher': 2,
    'wilcock': 2,
    'wilcox': 2,
    'wilcoxen': 3,
    'wilcoxon': 3,
    'wilcoxson': 3,
    'wilczak': 2,
    'wilczek': 2,
    'wilczewski': 3,
    'wilczynski': 3,
    'wild': 1,
    'wildasin': 3,
    'wildavsky': 3,
    'wildcard': 2,
    'wildcat': 2,
    'wildcats': 2,
    'wildcatter': 3,
    'wildcatters': 3,
    'wildcatting': 3,
    'wilde': 1,
    'wildeman': 2,
    'wilden': 2,
    'wilder': 2,
    'wilderman': 3,
    'wildermuth': 3,
    'wilderness': 3,
    'wildes': 1,
    'wildest': 2,
    'wildey': 2,
    'wildfire': 3,
    'wildfires': 3,
    'wildflower': 3,
    'wildflowers': 2,
    'wilding': 2,
    'wildlife': 2,
    'wildly': 2,
    'wildman': 2,
    'wildmon': 2,
    'wildness': 2,
    'wildon': 2,
    'wildrick': 2,
    'wilds': 1,
    'wildt': 1,
    'wildwood': 2,
    'wildwoods': 2,
    'wile': 1,
    'wileen': 2,
    'wileman': 2,
    'wilemon': 3,
    'wilen': 2,
    'wilensky': 3,
    'wiler': 2,
    'wiles': 1,
    'wiley': 2,
    'wilfert': 2,
    'wilfong': 2,
    'wilford': 2,
    'wilfred': 2,
    'wilfreda': 3,
    'wilfredo': 3,
    'wilfrid': 2,
    'wilfried': 2,
    'wilfully': 3,
    'wilgus': 2,
    'wilham': 2,
    'wilhelm': 2,
    'wilhelma': 3,
    'wilhelmi': 3,
    'wilhelmina': 4,
    'wilhelmine': 3,
    'wilhelms': 2,
    'wilhelmsen': 3,
    'wilhelmy': 3,
    'wilhemina': 4,
    'wilhide': 2,
    'wilhite': 2,
    'wilhoit': 2,
    'wilhoite': 2,
    'wiliams': 3,
    'wiling': 2,
    'wilinski': 3,
    'wilk': 1,
    'wilke': 1,
    'wilken': 2,
    'wilkening': 3,
    'wilkens': 2,
    'wilkenson': 3,
    'wilker': 2,
    'wilkerson': 3,
    'wilkes': 1,
    'wilkesboro': 3,
    'wilkey': 2,
    'wilkie': 2,
    'wilkin': 2,
    'wilking': 2,
    'wilkins': 2,
    'wilkinson': 3,
    'wilkis': 2,
    'wilkison': 3,
    'wilkowski': 3,
    'wilks': 1,
    'will': 1,
    'willa': 2,
    'willabelle': 3,
    'willadsen': 3,
    'willaims': 2,
    'willam': 2,
    'willamette': 3,
    'willamina': 4,
    'willapa': 3,
    'willard': 2,
    'willbanks': 2,
    'willcox': 2,
    'willcutt': 2,
    'willden': 2,
    'wille': 1,
    'willed': 1,
    'willeford': 3,
    'willem': 2,
    'willems': 2,
    'willemsen': 3,
    'willen': 2,
    'willenborg': 3,
    'willenbring': 3,
    'willens': 2,
    'willer': 2,
    'willers': 2,
    'willert': 2,
    'willes': 1,
    'willet': 2,
    'willets': 2,
    'willett': 2,
    'willette': 2,
    'willetts': 2,
    'willey': 2,
    'willford': 2,
    'willful': 2,
    'willfully': 3,
    'willhelm': 2,
    'willhite': 2,
    'willhoit': 2,
    'willhoite': 2,
    'willi': 2,
    'william': 2,
    'williams': 2,
    'williamsburg': 3,
    'williamsburgh': 3,
    'williamsen': 3,
    'williamson': 3,
    'williamsport': 3,
    'williamstown': 3,
    'williard': 2,
    'willie': 2,
    'williford': 3,
    'willig': 2,
    'willing': 2,
    'willinger': 3,
    'willingham': 3,
    'willingly': 3,
    'willingness': 3,
    'willis': 2,
    'willison': 3,
    'williston': 3,
    'willits': 2,
    'willke': 2,
    'willkie': 2,
    'willman': 2,
    'willmann': 2,
    'willmon': 2,
    'willmore': 2,
    'willmott': 2,
    'willms': 1,
    'willner': 2,
    'willock': 2,
    'willoughby': 3,
    'willow': 2,
    'willowbrook': 3,
    'willowes': 2,
    'willows': 2,
    'willowy': 3,
    'willpower': 3,
    'wills': 1,
    'willse': 2,
    'willsey': 2,
    'willson': 2,
    'willwerth': 2,
    'willy': 2,
    'willyard': 2,
    'wilma': 2,
    'wilmar': 2,
    'wilmarth': 2,
    'wilmer': 2,
    'wilmes': 1,
    'wilmet': 2,
    'wilmeth': 2,
    'wilmette': 2,
    'wilmington': 3,
    'wilmore': 2,
    'wilmot': 2,
    'wilmoth': 2,
    'wilmott': 2,
    'wilmouth': 2,
    'wilms': 1,
    'wilmut': 2,
    'wilner': 2,
    'wilpon': 2,
    'wilsey': 2,
    'wilshire': 2,
    'wilshusen': 3,
    'wilson': 2,
    'wilt': 1,
    'wilted': 2,
    'wiltel': 2,
    'wiltfong': 2,
    'wiltgen': 2,
    'wilthew': 2,
    'wilting': 2,
    'wilton': 2,
    'wiltron': 2,
    'wiltrout': 2,
    'wilts': 1,
    'wiltse': 1,
    'wiltsey': 2,
    'wiltshire': 2,
    'wiltsie': 2,
    'wiltz': 1,
    'wily': 2,
    'wiman': 2,
    'wimberley': 3,
    'wimberly': 3,
    'wimbish': 2,
    'wimbledon': 3,
    'wimbley': 2,
    'wimbush': 2,
    'wimer': 2,
    'wimmer': 2,
    'wimp': 1,
    'wimpee': 2,
    'wimpiness': 3,
    'wimps': 1,
    'wimpy': 2,
    'wims': 1,
    'wimsatt': 2,
    'win': 1,
    'winamac': 3,
    'winans': 2,
    'winant': 2,
    'winberg': 2,
    'winberry': 3,
    'winborn': 2,
    'winborne': 2,
    'winburn': 2,
    'winbush': 2,
    'wince': 1,
    'winced': 1,
    'wincek': 2,
    'winch': 1,
    'winchel': 2,
    'winchell': 2,
    'winchester': 3,
    'wincing': 2,
    'winckler': 2,
    'wind': 1,
    'windchill': 2,
    'windchime': 2,
    'windchimes': 2,
    'windecker': 3,
    'winded': 2,
    'windell': 2,
    'windels': 2,
    'winder': 2,
    'winders': 2,
    'windes': 1,
    'windfall': 2,
    'windfalls': 2,
    'windham': 2,
    'windheim': 2,
    'windhoek': 2,
    'windholz': 2,
    'windhorst': 2,
    'winding': 2,
    'windisch': 2,
    'windish': 2,
    'windlass': 2,
    'windle': 2,
    'windler': 2,
    'windley': 2,
    'windmere': 2,
    'windmill': 2,
    'windmiller': 3,
    'windmills': 2,
    'windom': 2,
    'windon': 2,
    'window': 2,
    'windowed': 2,
    'windowless': 3,
    'windowpane': 3,
    'windowpanes': 3,
    'windows': 2,
    'winds': 1,
    'windshield': 2,
    'windshields': 2,
    'windsor': 2,
    'windsors': 2,
    'windspeed': 2,
    'windstar': 2,
    'windstorm': 2,
    'windswept': 2,
    'windt': 1,
    'windup': 2,
    'windward': 2,
    'windy': 2,
    'wine': 1,
    'winebarger': 4,
    'wineberg': 2,
    'winebrenner': 4,
    'winecoff': 3,
    'wined': 1,
    'winegar': 3,
    'winegarden': 3,
    'winegardner': 4,
    'wineheim': 2,
    'wineinger': 3,
    'wineland': 2,
    'winema': 3,
    'wineman': 2,
    'winemiller': 3,
    'winer': 2,
    'wineries': 3,
    'winery': 3,
    'wines': 1,
    'winey': 2,
    'winfield': 2,
    'winford': 2,
    'winfred': 2,
    'winfree': 2,
    'winfrey': 2,
    'winfrid': 2,
    'wing': 1,
    'wingard': 2,
    'wingate': 2,
    'wingback': 2,
    'winge': 1,
    'winged': 1,
    'winger': 2,
    'wingers': 2,
    'wingert': 2,
    'wingerter': 3,
    'winget': 2,
    'wingett': 2,
    'wingfield': 2,
    'winging': 2,
    'wingler': 3,
    'winglike': 2,
    'wingman': 2,
    'wingo': 2,
    'wingrove': 2,
    'wings': 1,
    'wingspan': 2,
    'winiarski': 4,
    'winick': 2,
    'winiecki': 3,
    'winifred': 3,
    'winik': 2,
    'wining': 2,
    'wininger': 3,
    'winings': 2,
    'wink': 1,
    'winked': 1,
    'winkel': 2,
    'winkelman': 3,
    'winkelmann': 3,
    'winkels': 2,
    'winker': 2,
    'winkfield': 2,
    'winking': 2,
    'winkle': 2,
    'winkleman': 3,
    'winkler': 2,
    'winkles': 2,
    'winkley': 2,
    'winkowski': 3,
    'winks': 1,
    'winland': 2,
    'winn': 1,
    'winnable': 3,
    'winne': 1,
    'winnebago': 4,
    'winner': 2,
    'winners': 2,
    'winnetka': 3,
    'winnett': 2,
    'winney': 2,
    'winnick': 2,
    'winnicki': 3,
    'winnie': 2,
    'winning': 2,
    'winningest': 3,
    'winningham': 3,
    'winnings': 2,
    'winnipeg': 3,
    'winnow': 2,
    'winnowed': 2,
    'winnowing': 3,
    'winny': 2,
    'wino': 2,
    'winograd': 3,
    'winokur': 3,
    'winola': 3,
    'winona': 3,
    'winonah': 3,
    'winooski': 3,
    'winos': 2,
    'winquist': 2,
    'wins': 1,
    'winsett': 2,
    'winship': 2,
    'winski': 2,
    'winslet': 2,
    'winslett': 2,
    'winslow': 2,
    'winsome': 2,
    'winson': 2,
    'winsor': 2,
    'winstanley': 3,
    'winstead': 2,
    'winston': 2,
    'wint': 1,
    'winter': 2,
    'winterberg': 3,
    'winterbourne': 3,
    'wintered': 2,
    'winterhalter': 4,
    'winterize': 3,
    'winterized': 3,
    'wintermute': 3,
    'winterrowd': 3,
    'winters': 2,
    'wintershall': 3,
    'wintersteen': 3,
    'winterstein': 3,
    'winterthur': 3,
    'wintertime': 3,
    'winterton': 3,
    'winther': 2,
    'winthrop': 2,
    'wintle': 2,
    'wintom': 2,
    'winton': 2,
    'wintour': 2,
    'wintry': 2,
    'wintz': 1,
    'wintzer': 2,
    'winward': 2,
    'winwood': 2,
    'winword': 2,
    'winzeler': 3,
    'winzer': 2,
    'wion': 2,
    'wipe': 1,
    'wiped': 1,
    'wipeout': 2,
    'wiper': 2,
    'wipers': 2,
    'wipes': 1,
    'wipf': 1,
    'wiping': 2,
    'wipperfurth': 3,
    'wire': 2,
    'wired': 2,
    'wireless': 2,
    'wireline': 2,
    'wireman': 2,
    'wires': 2,
    'wiretap': 3,
    'wiretapped': 3,
    'wiretapping': 4,
    'wiretaps': 2,
    'wirick': 2,
    'wiring': 2,
    'wirkkala': 3,
    'wirkus': 2,
    'wirsing': 2,
    'wirt': 1,
    'wirtanen': 3,
    'wirth': 1,
    'wirthlin': 2,
    'wirtz': 1,
    'wiry': 2,
    'wirz': 1,
    'wis': 1,
    'wisby': 2,
    'wisch': 1,
    'wischmeyer': 3,
    'wisconsin': 3,
    'wisdom': 2,
    'wise': 1,
    'wisecarver': 3,
    'wisecrack': 2,
    'wisecracking': 3,
    'wisecracks': 2,
    'wisecup': 2,
    'wised': 1,
    'wiseguy': 2,
    'wisehart': 2,
    'wisel': 2,
    'wiseley': 3,
    'wisely': 2,
    'wiseman': 2,
    'wisenbaker': 4,
    'wisener': 3,
    'wiser': 2,
    'wisest': 2,
    'wish': 1,
    'wisham': 2,
    'wishard': 2,
    'wishart': 2,
    'wishbone': 2,
    'wished': 1,
    'wisher': 2,
    'wishers': 2,
    'wishes': 2,
    'wishful': 2,
    'wishfully': 3,
    'wishing': 2,
    'wishner': 2,
    'wishnick': 2,
    'wishon': 2,
    'wishy': 2,
    'wisinski': 3,
    'wisler': 3,
    'wisley': 2,
    'wisman': 2,
    'wismer': 3,
    'wisner': 2,
    'wisneski': 3,
    'wisnewski': 3,
    'wisnieski': 3,
    'wisniewski': 4,
    'wisor': 2,
    'wisp': 1,
    'wispy': 2,
    'wiss': 1,
    'wissa': 2,
    'wissel': 2,
    'wisser': 2,
    'wissing': 2,
    'wissinger': 3,
    'wissink': 2,
    'wissler': 2,
    'wissman': 2,
    'wissmann': 2,
    'wissner': 2,
    'wist': 1,
    'wistful': 2,
    'wistfully': 3,
    'wistfulness': 3,
    'wiswell': 2,
    'wisz': 1,
    'wit': 1,
    'witbeck': 2,
    'witbrock': 2,
    'witch': 1,
    'witchcraft': 2,
    'witcher': 2,
    'witches': 2,
    'witchey': 2,
    'witchhunt': 2,
    'witching': 2,
    'witchy': 2,
    'witco': 2,
    'witczak': 2,
    'witek': 2,
    'with': 1,
    'witham': 2,
    'withdraw': 2,
    'withdrawal': 3,
    'withdrawals': 3,
    'withdrawing': 3,
    'withdrawn': 2,
    'withdraws': 2,
    'withdrew': 2,
    'withe': 1,
    'withee': 2,
    'withem': 2,
    'wither': 2,
    'withered': 2,
    'witherell': 3,
    'withering': 3,
    'witherington': 4,
    'witherow': 3,
    'withers': 2,
    'witherspoon': 3,
    'withey': 2,
    'withheld': 2,
    'withhold': 2,
    'withholding': 3,
    'withholds': 2,
    'within': 2,
    'withington': 3,
    'withnail': 2,
    'without': 2,
    'withrow': 2,
    'withstand': 2,
    'withstanding': 3,
    'withstands': 2,
    'withstood': 2,
    'witkin': 2,
    'witkop': 2,
    'witkowski': 3,
    'witless': 2,
    'witman': 2,
    'witmer': 2,
    'witness': 2,
    'witnessed': 2,
    'witnesses': 3,
    'witnessing': 3,
    'witowski': 3,
    'wits': 1,
    'witt': 1,
    'witte': 1,
    'witted': 2,
    'wittekind': 3,
    'witteman': 2,
    'witten': 2,
    'wittenauer': 4,
    'wittenberg': 3,
    'wittenborn': 3,
    'wittenburg': 3,
    'wittenmyer': 4,
    'witter': 2,
    'witters': 2,
    'witthuhn': 2,
    'wittig': 2,
    'wittily': 3,
    'witting': 2,
    'wittingly': 3,
    'wittke': 2,
    'wittkopp': 2,
    'wittler': 2,
    'wittman': 2,
    'wittmann': 2,
    'wittmer': 2,
    'wittmeyer': 3,
    'wittner': 2,
    'witton': 2,
    'wittrock': 2,
    'witts': 1,
    'wittwer': 2,
    'witty': 2,
    'witucki': 3,
    'witwatersrand': 4,
    'witwer': 2,
    'witz': 1,
    'witzel': 2,
    'witzig': 2,
    'witzke': 2,
    'wives': 1,
    'wix': 1,
    'wixom': 2,
    'wixon': 2,
    'wixson': 2,
    'wixted': 2,
    'wiz': 1,
    'wizard': 2,
    'wizardry': 3,
    'wizards': 2,
    'wizen': 2,
    'wizened': 2,
    'wlodarczyk': 4,
    'wlodarski': 4,
    'wm': 2,
    'wnek': 1,
    'wnuk': 1,
    'wo': 1,
    'wobbe': 1,
    'wobble': 2,
    'wobbled': 2,
    'wobbling': 3,
    'wobbly': 3,
    'wobegon': 3,
    'wobig': 2,
    'wobst': 1,
    'woburn': 2,
    'woe': 1,
    'woebegone': 3,
    'woeful': 2,
    'woefully': 3,
    'woehl': 1,
    'woehler': 2,
    'woehr': 1,
    'woehrle': 2,
    'woelfel': 2,
    'woelfle': 2,
    'woerner': 2,
    'woes': 1,
    'woessner': 2,
    'woeste': 1,
    'wofford': 2,
    'wogan': 2,
    'woggle': 2,
    'wogoman': 3,
    'wohl': 1,
    'wohler': 2,
    'wohlers': 2,
    'wohlfarth': 2,
    'wohlfeil': 2,
    'wohlford': 2,
    'wohlgemuth': 3,
    'wohlstetter': 3,
    'wohlwend': 2,
    'woitschatzke': 3,
    'wojahn': 2,
    'wojciak': 3,
    'wojcicki': 3,
    'wojciech': 2,
    'wojciechowski': 4,
    'wojcik': 2,
    'wojdyla': 3,
    'wojick': 2,
    'wojnar': 2,
    'wojnarowski': 4,
    'wojnilower': 4,
    'wojnowski': 3,
    'wojtas': 2,
    'wojtaszek': 3,
    'wojtkiewicz': 3,
    'wojtkowski': 3,
    'wojtowicz': 3,
    'wok': 1,
    'woke': 1,
    'woken': 2,
    'woks': 1,
    'wola': 2,
    'wolak': 2,
    'wolanin': 3,
    'wolanski': 3,
    'wolaver': 3,
    'wolber': 2,
    'wolbert': 2,
    'wolcott': 2,
    'wold': 1,
    'wolden': 2,
    'woldt': 1,
    'wolenzac': 3,
    'wolf': 1,
    'wolfarth': 2,
    'wolfe': 1,
    'wolfenbarger': 4,
    'wolfenden': 3,
    'wolfenschmidt': 3,
    'wolfensohn': 3,
    'wolfer': 2,
    'wolfert': 2,
    'wolff': 1,
    'wolfgang': 2,
    'wolfgram': 2,
    'wolfinbarger': 4,
    'wolfinger': 3,
    'wolfley': 2,
    'wolfman': 2,
    'wolford': 2,
    'wolfram': 2,
    'wolfrey': 2,
    'wolfrom': 2,
    'wolfrum': 2,
    'wolfsburg': 2,
    'wolfson': 2,
    'wolgamott': 3,
    'wolgast': 2,
    'wolgemuth': 3,
    'wolin': 2,
    'wolinski': 3,
    'wolinsky': 3,
    'wolitarsky': 4,
    'wolk': 1,
    'wolkanczik': 3,
    'wolke': 1,
    'wolken': 2,
    'wolkoff': 2,
    'woll': 1,
    'wollack': 2,
    'wollaeger': 3,
    'wollam': 2,
    'wollard': 2,
    'wolle': 1,
    'wollen': 2,
    'wollenberg': 3,
    'wollenweber': 4,
    'woller': 2,
    'wollin': 2,
    'wollman': 2,
    'wollner': 2,
    'wollschlager': 3,
    'wolman': 2,
    'wolohan': 3,
    'woloszyn': 3,
    'wolpe': 2,
    'wolper': 2,
    'wolpert': 2,
    'wolrath': 2,
    'wolsey': 2,
    'wolsfeld': 2,
    'wolske': 1,
    'wolski': 2,
    'wolsky': 2,
    'wolstenholme': 3,
    'wolter': 2,
    'wolters': 2,
    'woltman': 2,
    'woltz': 1,
    'wolven': 2,
    'wolverine': 3,
    'wolverton': 3,
    'wolves': 1,
    'wolz': 1,
    'womac': 2,
    'womack': 2,
    'woman': 2,
    'womanhood': 3,
    'womanize': 3,
    'womanizer': 4,
    'womanizing': 4,
    'womb': 1,
    'wombacher': 3,
    'wombat': 2,
    'wombats': 2,
    'womble': 2,
    'wombles': 2,
    'women': 2,
    'womer': 2,
    'wometco': 3,
    'wommack': 2,
    'won': 1,
    'wond': 1,
    'wonda': 2,
    'wonder': 2,
    'wondered': 2,
    'wonderful': 3,
    'wonderfully': 4,
    'wonderfulness': 4,
    'wondering': 3,
    'wonderland': 3,
    'wonderly': 3,
    'wonderment': 3,
    'wonders': 2,
    'wondra': 2,
    'wondrous': 2,
    'wong': 1,
    'wonk': 1,
    'wonks': 1,
    'wonksahachee': 4,
    'wonnacott': 3,
    'wont': 1,
    'woo': 1,
    'wood': 1,
    'woodall': 2,
    'woodard': 2,
    'woodbeck': 2,
    'woodberry': 3,
    'woodbridge': 2,
    'woodburn': 2,
    'woodbury': 3,
    'woodby': 2,
    'woodcarver': 3,
    'woodcarvers': 3,
    'woodchip': 2,
    'woodchips': 2,
    'woodchuck': 2,
    'woodchucks': 2,
    'woodcliff': 2,
    'woodcock': 2,
    'woodcox': 2,
    'wooddell': 2,
    'wooded': 2,
    'woodell': 2,
    'wooden': 2,
    'woodfield': 2,
    'woodfill': 2,
    'woodfin': 2,
    'woodford': 2,
    'woodfork': 2,
    'woodhall': 2,
    'woodham': 2,
    'woodhams': 2,
    'woodhead': 2,
    'woodhouse': 2,
    'woodhull': 2,
    'woodie': 2,
    'woodin': 2,
    'wooding': 2,
    'woodington': 3,
    'woodis': 2,
    'woodke': 2,
    'woodland': 2,
    'woodlands': 2,
    'woodle': 2,
    'woodlee': 2,
    'woodley': 2,
    'woodlief': 2,
    'woodliff': 2,
    'woodling': 2,
    'woodlock': 2,
    'woodlot': 2,
    'woodmac': 2,
    'woodman': 2,
    'woodmansee': 3,
    'woodpecker': 3,
    'woodpeckers': 3,
    'woodpile': 2,
    'woodpiles': 2,
    'woodrich': 2,
    'woodrick': 2,
    'woodring': 2,
    'woodrome': 2,
    'woodroof': 2,
    'woodrow': 2,
    'woodruff': 2,
    'woodrum': 2,
    'woods': 1,
    'woodshed': 2,
    'woodsheds': 2,
    'woodside': 2,
    'woodsmall': 2,
    'woodsman': 2,
    'woodsmen': 2,
    'woodson': 2,
    'woodstock': 2,
    'woodstream': 2,
    'woodward': 2,
    'woodwind': 2,
    'woodwinds': 2,
    'woodwork': 2,
    'woodworker': 3,
    'woodworkers': 3,
    'woodworking': 3,
    'woodworth': 2,
    'woody': 2,
    'woodyard': 2,
    'wooed': 1,
    'woof': 1,
    'woofer': 2,
    'woofers': 2,
    'woofter': 2,
    'woogie': 2,
    'wooing': 2,
    'wool': 1,
    'woolard': 2,
    'woolbright': 2,
    'woolco': 2,
    'woolcock': 2,
    'wooldridge': 2,
    'woolen': 2,
    'woolens': 2,
    'woolery': 3,
    'woolever': 3,
    'wooley': 2,
    'woolf': 1,
    'woolfolk': 2,
    'woolford': 2,
    'woolfork': 2,
    'woollard': 2,
    'woollcott': 2,
    'woollen': 2,
    'woolley': 2,
    'woolly': 2,
    'woolman': 2,
    'woolridge': 2,
    'wools': 1,
    'woolsey': 2,
    'woolson': 2,
    'woolston': 2,
    'woolum': 2,
    'woolums': 2,
    'woolverton': 3,
    'woolwine': 2,
    'woolworth': 2,
    'wooly': 2,
    'woomer': 2,
    'woong': 1,
    'woos': 1,
    'woosley': 2,
    'wooster': 2,
    'wootan': 2,
    'wooten': 2,
    'wooters': 2,
    'wooton': 2,
    'wootten': 2,
    'wootton': 2,
    'woozy': 2,
    'wop': 1,
    'wops': 1,
    'wor': 1,
    'worcester': 2,
    'worcestershire': 3,
    'word': 1,
    'worded': 2,
    'wordell': 2,
    'worden': 2,
    'wording': 2,
    'wordless': 2,
    'wordperfect': 3,
    'words': 1,
    'wordsmith': 2,
    'wordstar': 2,
    'wordsworth': 2,
    'wordy': 2,
    'wore': 1,
    'work': 1,
    'workable': 3,
    'workaday': 3,
    'workaholic': 4,
    'workaholics': 4,
    'workbench': 2,
    'workday': 2,
    'workdays': 2,
    'worked': 1,
    'worker': 2,
    'workers': 2,
    'workfare': 2,
    'workforce': 2,
    'workgroup': 2,
    'workgroups': 2,
    'workhorse': 2,
    'workhorses': 3,
    'working': 2,
    'workinger': 3,
    'workings': 2,
    'workload': 2,
    'workloads': 2,
    'workman': 2,
    'workmanlike': 3,
    'workmanship': 3,
    'workmen': 2,
    'workout': 2,
    'workouts': 2,
    'workplace': 2,
    'workplaces': 3,
    'workroom': 2,
    'works': 1,
    'worksheet': 2,
    'worksheets': 2,
    'workshop': 2,
    'workshops': 2,
    'workstation': 3,
    'workstations': 3,
    'workweek': 2,
    'workweeks': 2,
    'worland': 2,
    'worlco': 2,
    'world': 1,
    'worldcom': 2,
    'worldcorp': 2,
    'worlder': 2,
    'worlders': 2,
    'worldliness': 3,
    'worldly': 2,
    'worldnews': 2,
    'worldpass': 2,
    'worlds': 1,
    'worldscope': 2,
    'worldspan': 2,
    'worldvision': 3,
    'worldwide': 2,
    'worley': 2,
    'worm': 1,
    'worman': 2,
    'wormington': 3,
    'wormley': 2,
    'wormlike': 2,
    'worms': 1,
    'wormser': 2,
    'wormuth': 2,
    'wormwood': 2,
    'worn': 1,
    'worner': 2,
    'woronoff': 3,
    'worrall': 2,
    'worrel': 2,
    'worrell': 2,
    'worried': 2,
    'worrier': 3,
    'worriers': 3,
    'worries': 2,
    'worrisome': 3,
    'worry': 2,
    'worrying': 3,
    'worse': 1,
    'worseck': 2,
    'worsen': 2,
    'worsened': 2,
    'worsening': 3,
    'worsens': 2,
    'worsham': 2,
    'worship': 2,
    'worshiped': 2,
    'worshiper': 3,
    'worshipers': 3,
    'worshipful': 3,
    'worshipped': 2,
    'worshippers': 3,
    'worshipping': 3,
    'worships': 2,
    'worsley': 2,
    'worst': 1,
    'worsted': 2,
    'worstell': 2,
    'worster': 2,
    'worth': 1,
    'wortham': 2,
    'worthen': 2,
    'worthey': 2,
    'worthier': 3,
    'worthies': 2,
    'worthiest': 3,
    'worthiness': 3,
    'worthing': 2,
    'worthington': 3,
    'worthless': 2,
    'worthley': 2,
    'worthman': 2,
    'worthog': 2,
    'worths': 1,
    'worthwhile': 2,
    'worthy': 2,
    'wortley': 2,
    'wortman': 2,
    'wortmann': 2,
    'worton': 2,
    'worts': 1,
    'wotan': 2,
    'wotherspoon': 3,
    'wotring': 3,
    'wotton': 2,
    'wouk': 1,
    'would': 1,
    'woulfe': 1,
    'wound': 1,
    'wounded': 2,
    'wounding': 2,
    'wounds': 1,
    'wouters': 2,
    'wove': 1,
    'woven': 2,
    'wow': 1,
    'wowed': 1,
    'wowie': 2,
    'wowing': 2,
    'wows': 1,
    'woy': 1,
    'wozniak': 3,
    'woznick': 2,
    'woznicki': 3,
    'wozny': 2,
    'wrack': 1,
    'wracked': 1,
    'wracking': 2,
    'wrage': 1,
    'wragg': 1,
    'wrangell': 2,
    'wrangle': 2,
    'wrangled': 2,
    'wrangler': 3,
    'wranglers': 3,
    'wrangles': 2,
    'wrangling': 3,
    'wranglings': 3,
    'wrap': 1,
    'wrapped': 1,
    'wrapper': 2,
    'wrappers': 2,
    'wrapping': 2,
    'wraps': 1,
    'wrapup': 2,
    'wrath': 1,
    'wrather': 2,
    'wray': 1,
    'wreak': 1,
    'wreaked': 1,
    'wreaking': 2,
    'wreaks': 1,
    'wreath': 1,
    'wreaths': 1,
    'wreck': 1,
    'wreckage': 2,
    'wrecked': 1,
    'wrecker': 2,
    'wrecking': 2,
    'wrecks': 1,
    'wrede': 1,
    'wren': 1,
    'wrench': 1,
    'wrenched': 1,
    'wrenches': 2,
    'wrenching': 2,
    'wrenn': 1,
    'wrest': 1,
    'wrested': 2,
    'wresting': 2,
    'wrestle': 2,
    'wrestled': 2,
    'wrestler': 3,
    'wrestlers': 2,
    'wrestles': 2,
    'wrestling': 2,
    'wretched': 2,
    'wretchedly': 3,
    'wrice': 1,
    'wriggle': 2,
    'wright': 1,
    'wrights': 1,
    'wrightsman': 2,
    'wrightson': 2,
    'wrigley': 2,
    'wring': 1,
    'wringer': 2,
    'wringing': 2,
    'wrinkle': 2,
    'wrinkled': 2,
    'wrinkles': 2,
    'wrinkling': 3,
    'wrisley': 2,
    'wrist': 1,
    'wriston': 2,
    'wrists': 1,
    'wristwatch': 2,
    'wristwatches': 3,
    'writ': 1,
    'write': 1,
    'writedown': 2,
    'writedowns': 2,
    'writeoff': 2,
    'writeoffs': 2,
    'writer': 2,
    'writers': 2,
    'writes': 1,
    'writhe': 1,
    'writhing': 2,
    'writing': 2,
    'writings': 2,
    'writs': 1,
    'written': 2,
    'wrobel': 2,
    'wrobleski': 3,
    'wroblewski': 3,
    'wroclaw': 2,
    'wroe': 1,
    'wrona': 2,
    'wrong': 1,
    'wrongdoer': 3,
    'wrongdoers': 3,
    'wrongdoing': 3,
    'wrongdoings': 3,
    'wronged': 1,
    'wrongful': 2,
    'wrongfully': 3,
    'wrongheaded': 3,
    'wrongly': 2,
    'wrongs': 1,
    'wronski': 2,
    'wrote': 1,
    'wroten': 2,
    'wrought': 1,
    'wrubel': 2,
    'wruck': 1,
    'wrung': 1,
    'wry': 1,
    'wrye': 1,
    'wryly': 2,
    'wryness': 2,
    'wrzesinski': 4,
    'ws': 4,
    'wu': 1,
    'wubben': 2,
    'wuellner': 2,
    'wuensch': 1,
    'wuerth': 1,
    'wuerttemberg': 3,
    'wuertz': 1,
    'wuest': 2,
    'wuhan': 2,
    'wujcik': 2,
    'wulf': 1,
    'wulff': 1,
    'wuli': 2,
    'wuliger': 3,
    'wunder': 2,
    'wunderkind': 3,
    'wunderle': 3,
    'wunderlich': 3,
    'wunderlin': 3,
    'wunsch': 1,
    'wunschel': 2,
    'wurdeman': 2,
    'wurl': 1,
    'wurlitzer': 3,
    'wurm': 1,
    'wurst': 1,
    'wurster': 2,
    'wursthaus': 2,
    'wurth': 1,
    'wurtman': 2,
    'wurtz': 1,
    'wurtzel': 2,
    'wurz': 1,
    'wurzel': 2,
    'wurzer': 2,
    'wuss': 1,
    'wussler': 2,
    'wussow': 2,
    'wuthering': 3,
    'wuthrich': 2,
    'wuttke': 2,
    'wuxi': 2,
    'wuyda': 2,
    'wuzzy': 2,
    'wy': 1,
    'wyand': 2,
    'wyant': 2,
    'wyatt': 2,
    'wyble': 2,
    'wyborn': 2,
    'wyche': 1,
    'wyckoff': 2,
    'wycliff': 2,
    'wycoff': 2,
    'wyden': 1,
    'wydra': 2,
    'wye': 1,
    'wyer': 2,
    'wyers': 2,
    'wyeth': 2,
    'wyffels': 2,
    'wygal': 2,
    'wygant': 2,
    'wygod': 2,
    'wyk': 1,
    'wyka': 2,
    'wyke': 1,
    'wykes': 1,
    'wykle': 2,
    'wykoff': 2,
    'wyland': 2,
    'wylde': 1,
    'wyle': 1,
    'wyler': 2,
    'wyles': 1,
    'wylie': 2,
    'wyllie': 2,
    'wylma': 2,
    'wyly': 2,
    'wyman': 2,
    'wymer': 2,
    'wymore': 2,
    'wyndham': 2,
    'wyne': 1,
    'wyner': 2,
    'wynes': 1,
    'wynette': 2,
    'wyngaarden': 3,
    'wynia': 3,
    'wynkoop': 2,
    'wynn': 1,
    'wynne': 1,
    'wynns': 1,
    'wynona': 3,
    'wynter': 2,
    'wynton': 2,
    'wynyard': 2,
    'wyoming': 3,
    'wyre': 1,
    'wyrick': 2,
    'wyse': 1,
    'wyser': 2,
    'wysocki': 3,
    'wysong': 2,
    'wyss': 1,
    'wyszynski': 3,
    'wythe': 1,
    'x': 1,
    'xan': 1,
    'xanada': 3,
    'xanax': 2,
    'xanthe': 1,
    'xantippe': 2,
    'xaver': 2,
    'xavier': 2,
    'xaviera': 4,
    'xebec': 2,
    'xena': 2,
    'xene': 1,
    'xenia': 3,
    'xenon': 2,
    'xenophobia': 5,
    'xenophobic': 4,
    'xenophon': 3,
    'xenos': 2,
    'xeriscape': 3,
    'xerox': 2,
    'xeroxed': 2,
    'xeroxes': 3,
    'xeroxing': 3,
    'xers': 2,
    'xerxes': 2,
    'xhosa': 2,
    'xi': 1,
    'xiamen': 3,
    'xian': 1,
    'xiang': 2,
    'xiao': 1,
    'xiaogang': 2,
    'xiaoping': 2,
    'xiaoyun': 2,
    'xicor': 2,
    'xidex': 2,
    'xie': 1,
    'xilinx': 2,
    'xing': 1,
    'xinhua': 3,
    'xinjiang': 3,
    'xio': 1,
    'xiong': 1,
    'xoma': 2,
    'xscribe': 2,
    'xtra': 2,
    'xu': 1,
    'xuan': 1,
    'xudong': 2,
    'xue': 1,
    'xuedong': 2,
    'xuemei': 2,
    'xus': 1,
    'xylia': 3,
    'xylina': 3,
    'xylogic': 3,
    'xylogics': 3,
    'xylon': 2,
    'xylona': 3,
    'xylophone': 3,
    'xyvision': 3,
    'y': 1,
    'ya': 1,
    'yaacov': 2,
    'yabba': 2,
    'yabbadabbadoo': 5,
    'yablon': 2,
    'yablonski': 3,
    'yablonsky': 3,
    'yacht': 1,
    'yachter': 2,
    'yachters': 2,
    'yachting': 2,
    'yachts': 1,
    'yachtsman': 2,
    'yack': 1,
    'yackel': 2,
    'yackley': 2,
    'yacko': 2,
    'yacktman': 2,
    'yacono': 3,
    'yacos': 2,
    'yacoub': 2,
    'yadda': 2,
    'yaden': 2,
    'yadon': 2,
    'yaeger': 2,
    'yaffe': 1,
    'yager': 2,
    'yagi': 2,
    'yagoda': 3,
    'yah': 1,
    'yahi': 2,
    'yahn': 1,
    'yahnke': 1,
    'yahoo': 2,
    'yahoos': 2,
    'yahr': 1,
    'yahweh': 2,
    'yahya': 2,
    'yak': 1,
    'yake': 1,
    'yakel': 2,
    'yakima': 3,
    'yaklin': 2,
    'yakov': 2,
    'yakovlev': 3,
    'yakusa': 3,
    'yakutakay': 4,
    'yale': 1,
    'yalin': 2,
    'yalta': 2,
    'yam': 1,
    'yamada': 3,
    'yamagata': 4,
    'yamaguchi': 4,
    'yamaha': 3,
    'yamaichi': 4,
    'yamamoto': 4,
    'yamanaka': 4,
    'yamane': 3,
    'yamani': 3,
    'yamanouchi': 4,
    'yamasaki': 4,
    'yamashiro': 4,
    'yamashita': 4,
    'yamatake': 4,
    'yamato': 3,
    'yamauchi': 4,
    'yamazaki': 4,
    'yamin': 2,
    'yams': 1,
    'yan': 1,
    'yanbu': 2,
    'yancey': 2,
    'yancy': 2,
    'yanda': 2,
    'yandell': 2,
    'yandle': 2,
    'yandow': 2,
    'yanes': 1,
    'yaney': 2,
    'yanez': 2,
    'yang': 1,
    'yangsheng': 2,
    'yangtze': 2,
    'yani': 2,
    'yanick': 2,
    'yanik': 2,
    'yanis': 2,
    'yanish': 2,
    'yank': 1,
    'yankass': 2,
    'yanke': 1,
    'yanked': 1,
    'yankee': 2,
    'yankees': 2,
    'yankelovich': 4,
    'yankey': 2,
    'yanking': 2,
    'yanko': 2,
    'yankovich': 3,
    'yankowski': 3,
    'yanks': 1,
    'yanni': 2,
    'yannick': 2,
    'yannone': 2,
    'yannuzzi': 3,
    'yano': 2,
    'yanofsky': 3,
    'yanomami': 4,
    'yant': 1,
    'yantis': 2,
    'yantz': 1,
    'yao': 1,
    'yaobang': 2,
    'yaohan': 2,
    'yap': 1,
    'yaple': 2,
    'yapp': 1,
    'yaps': 1,
    'yarber': 2,
    'yarberry': 3,
    'yarboro': 3,
    'yarborough': 3,
    'yarbough': 2,
    'yarbro': 2,
    'yarbrough': 3,
    'yarchoan': 2,
    'yard': 1,
    'yardage': 2,
    'yarde': 1,
    'yardeni': 3,
    'yarder': 2,
    'yardley': 2,
    'yards': 1,
    'yardstick': 2,
    'yardsticks': 2,
    'yarger': 2,
    'yarling': 2,
    'yarmulke': 3,
    'yarmulkes': 3,
    'yarn': 1,
    'yarnall': 2,
    'yarnell': 2,
    'yarns': 1,
    'yaron': 2,
    'yaros': 2,
    'yarosh': 2,
    'yarrington': 3,
    'yarrow': 2,
    'yarwood': 2,
    'yasin': 2,
    'yasir': 2,
    'yasmin': 2,
    'yasmine': 2,
    'yass': 1,
    'yasser': 2,
    'yassin': 2,
    'yassir': 2,
    'yasso': 2,
    'yassukovich': 4,
    'yastrow': 2,
    'yasuda': 3,
    'yasuhiko': 4,
    'yasuhiro': 4,
    'yasunori': 4,
    'yasuo': 3,
    'yasushi': 3,
    'yasutaka': 4,
    'yasuyoshi': 4,
    'yater': 2,
    'yates': 1,
    'yatsko': 2,
    'yau': 1,
    'yauch': 1,
    'yauger': 2,
    'yaun': 1,
    'yaupon': 2,
    'yaupons': 2,
    'yavlinsky': 3,
    'yaw': 1,
    'yawn': 1,
    'yawned': 1,
    'yawner': 2,
    'yawning': 2,
    'yawns': 1,
    'yaworski': 3,
    'yax': 1,
    'yay': 1,
    'yazov': 2,
    'yazzie': 2,
    'ybanez': 3,
    'ybarbo': 3,
    'ybarra': 3,
    'ydstie': 2,
    'ye': 1,
    'yea': 1,
    'yeadon': 2,
    'yeager': 2,
    'yeagle': 2,
    'yeagley': 2,
    'yeah': 1,
    'yeakel': 2,
    'yeakey': 2,
    'yeakle': 2,
    'yeakley': 2,
    'yeaman': 2,
    'yeamans': 2,
    'year': 1,
    'yearago': 3,
    'yearbook': 2,
    'yearbooks': 2,
    'yearby': 2,
    'yearearlier': 3,
    'yearend': 2,
    'yeargain': 2,
    'yeargan': 2,
    'yeargin': 2,
    'yearick': 2,
    'yearling': 2,
    'yearlings': 2,
    'yearlong': 2,
    'yearly': 2,
    'yearn': 1,
    'yearned': 1,
    'yearning': 2,
    'yearnings': 2,
    'yearns': 1,
    'yearout': 2,
    'years': 1,
    'yearsley': 2,
    'yearwood': 2,
    'yeary': 2,
    'yeas': 1,
    'yeast': 1,
    'yeasts': 1,
    'yeater': 2,
    'yeates': 2,
    'yeatman': 2,
    'yeaton': 2,
    'yeats': 1,
    'yeatts': 1,
    'yeazel': 2,
    'yeck': 1,
    'yeckley': 2,
    'yedda': 2,
    'yedinak': 3,
    'yediyat': 3,
    'yee': 1,
    'yegor': 2,
    'yeh': 1,
    'yehiya': 3,
    'yehle': 2,
    'yehuda': 3,
    'yehudi': 3,
    'yeiri': 2,
    'yeiser': 2,
    'yeisley': 2,
    'yekaterinburg': 5,
    'yeldell': 2,
    'yelena': 3,
    'yell': 1,
    'yelland': 2,
    'yelle': 1,
    'yelled': 1,
    'yellen': 2,
    'yellin': 2,
    'yelling': 2,
    'yellow': 2,
    'yellower': 3,
    'yellowest': 3,
    'yellowing': 3,
    'yellowish': 3,
    'yellowknife': 3,
    'yellows': 2,
    'yellowstone': 3,
    'yells': 1,
    'yelp': 1,
    'yelping': 2,
    'yelton': 2,
    'yeltsin': 2,
    'yelverton': 3,
    'yelvington': 3,
    'yemen': 2,
    'yemeni': 3,
    'yemenidjian': 5,
    'yen': 1,
    'yenching': 2,
    'yender': 2,
    'yene': 2,
    'yenlu': 2,
    'yentl': 2,
    'yeo': 2,
    'yeoman': 2,
    'yeomans': 2,
    'yeosock': 2,
    'yep': 1,
    'yepez': 2,
    'yepsen': 2,
    'yer': 1,
    'yerby': 2,
    'yerdon': 2,
    'yerena': 3,
    'yerevan': 3,
    'yerger': 2,
    'yergin': 2,
    'yerian': 3,
    'yerke': 1,
    'yerkes': 2,
    'yerkey': 2,
    'yerman': 2,
    'yerxa': 2,
    'yes': 1,
    'yesen': 2,
    'yeses': 2,
    'yeshiva': 3,
    'yeske': 1,
    'yessuey': 3,
    'yest': 1,
    'yesterday': 3,
    'yesterdays': 3,
    'yesteryear': 3,
    'yet': 1,
    'yeti': 2,
    'yetman': 2,
    'yetnikoff': 3,
    'yett': 1,
    'yetta': 2,
    'yetter': 2,
    'yeung': 1,
    'yeutter': 2,
    'yevette': 2,
    'yevgeny': 3,
    'yevlinsky': 3,
    'yevtushenko': 4,
    'yew': 1,
    'yglesias': 4,
    'yi': 1,
    'yiddish': 2,
    'yie': 1,
    'yield': 1,
    'yielded': 2,
    'yielding': 2,
    'yields': 1,
    'yigal': 2,
    'yikes': 1,
    'yilin': 2,
    'yim': 1,
    'yin': 1,
    'ying': 1,
    'yinger': 2,
    'yingling': 3,
    'yingst': 1,
    'yip': 1,
    'yippee': 2,
    'yitzhak': 2,
    'ylvisaker': 4,
    'ynez': 1,
    'yniguez': 3,
    'ynjiun': 2,
    'yo': 1,
    'yoak': 1,
    'yoakam': 2,
    'yoakum': 2,
    'yoast': 1,
    'yocam': 2,
    'yocham': 2,
    'yochelson': 3,
    'yochim': 2,
    'yochum': 2,
    'yockey': 2,
    'yocom': 2,
    'yocum': 2,
    'yodel': 2,
    'yodeling': 3,
    'yoder': 2,
    'yodice': 2,
    'yoe': 1,
    'yoes': 1,
    'yoest': 1,
    'yoffie': 2,
    'yoga': 2,
    'yogi': 2,
    'yogiism': 3,
    'yogiisms': 3,
    'yogurt': 2,
    'yoh': 1,
    'yohe': 2,
    'yohei': 2,
    'yohn': 1,
    'yoho': 2,
    'yoichi': 3,
    'yokado': 3,
    'yoke': 1,
    'yoked': 1,
    'yokel': 2,
    'yokelson': 3,
    'yokes': 1,
    'yokich': 2,
    'yokley': 2,
    'yoko': 2,
    'yokohama': 4,
    'yokokohji': 4,
    'yokota': 3,
    'yokoyama': 4,
    'yokum': 2,
    'yola': 2,
    'yolanda': 3,
    'yolande': 2,
    'yolk': 1,
    'yolks': 1,
    'yom': 1,
    'yomiuri': 4,
    'yon': 1,
    'yona': 2,
    'yonan': 2,
    'yonce': 1,
    'yonder': 2,
    'yoneyama': 4,
    'yong': 1,
    'yongbyon': 2,
    'yongchaiyudh': 3,
    'yongue': 1,
    'yonhap': 2,
    'yonke': 1,
    'yonker': 2,
    'yonkers': 2,
    'yonsei': 2,
    'yontef': 2,
    'yonts': 1,
    'yontz': 1,
    'yoo': 1,
    'yoon': 1,
    'yoor': 1,
    'yoos': 1,
    'yopp': 1,
    'yoram': 2,
    'yorba': 2,
    'yordy': 2,
    'yore': 1,
    'yorich': 2,
    'yorio': 2,
    'york': 1,
    'yorkbased': 2,
    'yorke': 1,
    'yorker': 2,
    'yorkers': 2,
    'yorks': 1,
    'yorkshire': 2,
    'yorktown': 2,
    'yorrick': 2,
    'yoruba': 3,
    'yosef': 2,
    'yosemite': 4,
    'yoshi': 2,
    'yoshiaki': 4,
    'yoshida': 3,
    'yoshihara': 4,
    'yoshihashi': 4,
    'yoshihiro': 4,
    'yoshihisa': 4,
    'yoshikawa': 4,
    'yoshikazu': 4,
    'yoshimoto': 4,
    'yoshimura': 4,
    'yoshino': 3,
    'yoshio': 3,
    'yoshioka': 4,
    'yoshiro': 3,
    'yoss': 1,
    'yossi': 2,
    'yost': 1,
    'yother': 2,
    'yott': 1,
    'you': 1,
    'youell': 2,
    'youghal': 2,
    'youker': 2,
    'youman': 2,
    'youmans': 2,
    'youn': 1,
    'younan': 2,
    'younce': 1,
    'younes': 1,
    'young': 1,
    'youngberg': 2,
    'youngblood': 2,
    'youngblut': 2,
    'youngdahl': 2,
    'younge': 1,
    'younger': 2,
    'youngerman': 3,
    'youngers': 2,
    'youngest': 2,
    'younglove': 2,
    'youngman': 2,
    'youngquist': 2,
    'youngren': 2,
    'youngs': 1,
    'youngster': 2,
    'youngsters': 2,
    'youngstown': 2,
    'youngstrom': 2,
    'younis': 2,
    'younker': 2,
    'younkers': 2,
    'younkin': 2,
    'younkins': 2,
    'yount': 1,
    'younts': 1,
    'your': 1,
    'youree': 2,
    'youri': 2,
    'yours': 1,
    'yourself': 2,
    'yourselfer': 3,
    'yourselfers': 3,
    'yourselves': 2,
    'yous': 1,
    'youse': 1,
    'yousef': 2,
    'yousif': 2,
    'youssef': 2,
    'youth': 1,
    'youthful': 2,
    'youths': 1,
    'youtsey': 2,
    'youtz': 1,
    'youville': 2,
    'yow': 1,
    'yowell': 2,
    'yoy': 1,
    'yoyo': 2,
    'yoyos': 2,
    'ypsilanti': 4,
    'yquem': 2,
    'ysleta': 3,
    'yttrium': 3,
    'yu': 1,
    'yuan': 2,
    'yuba': 2,
    'yucaipa': 3,
    'yucatan': 3,
    'yucca': 2,
    'yuck': 1,
    'yucky': 2,
    'yue': 1,
    'yuen': 1,
    'yugo': 2,
    'yugos': 2,
    'yugoslav': 3,
    'yugoslavia': 5,
    'yugoslavian': 5,
    'yugoslavs': 3,
    'yuhas': 2,
    'yuhasz': 2,
    'yuichi': 2,
    'yuill': 1,
    'yuille': 1,
    'yuk': 1,
    'yukio': 3,
    'yuko': 2,
    'yukon': 2,
    'yuks': 1,
    'yul': 1,
    'yule': 1,
    'yuletide': 2,
    'yuli': 2,
    'yulian': 3,
    'yum': 1,
    'yuma': 2,
    'yummies': 2,
    'yummy': 2,
    'yun': 1,
    'yunde': 2,
    'yundt': 1,
    'yung': 1,
    'yunich': 2,
    'yunis': 2,
    'yunk': 1,
    'yunker': 2,
    'yup': 1,
    'yuppie': 2,
    'yuppies': 2,
    'yuppified': 3,
    'yuppify': 3,
    'yurachek': 3,
    'yurchak': 2,
    'yurchenko': 3,
    'yurek': 2,
    'yuri': 2,
    'yurick': 2,
    'yurko': 2,
    'yurkovich': 3,
    'yurman': 2,
    'yurt': 1,
    'yury': 2,
    'yusef': 2,
    'yusen': 2,
    'yusko': 2,
    'yust': 1,
    'yustef': 2,
    'yutaka': 3,
    'yutzy': 2,
    'yuval': 2,
    'yuzong': 2,
    'yves': 1,
    'yvette': 2,
    'yvonne': 2,
    'yzaguirre': 4,
    'z': 1,
    'zabala': 3,
    'zabawa': 3,
    'zabel': 2,
    'zabielski': 3,
    'zabinski': 3,
    'zablocki': 3,
    'zaborowski': 4,
    'zabows': 2,
    'zabowski': 3,
    'zabriskie': 3,
    'zacarias': 4,
    'zaccagnini': 4,
    'zaccagnino': 4,
    'zaccardi': 3,
    'zaccaria': 4,
    'zaccaro': 3,
    'zaccheus': 2,
    'zaccone': 3,
    'zacek': 2,
    'zach': 1,
    'zachar': 2,
    'zachariah': 4,
    'zacharias': 4,
    'zachary': 3,
    'zacher': 2,
    'zachery': 3,
    'zachi': 2,
    'zachman': 2,
    'zachmann': 2,
    'zachow': 2,
    'zachry': 2,
    'zack': 1,
    'zackery': 3,
    'zacks': 1,
    'zada': 2,
    'zadar': 2,
    'zadeh': 2,
    'zadillo': 3,
    'zadoc': 2,
    'zadok': 2,
    'zadora': 3,
    'zadrozny': 3,
    'zaentz': 1,
    'zaffino': 3,
    'zaffuto': 3,
    'zaftig': 2,
    'zag': 1,
    'zagar': 2,
    'zagat': 2,
    'zagel': 2,
    'zager': 2,
    'zagged': 1,
    'zagha': 2,
    'zagladin': 3,
    'zagorski': 3,
    'zagreb': 2,
    'zags': 1,
    'zagury': 3,
    'zaher': 2,
    'zahir': 2,
    'zahler': 2,
    'zahm': 1,
    'zahn': 1,
    'zahner': 2,
    'zahniser': 3,
    'zahnow': 2,
    'zahradnik': 3,
    'zaibatsu': 3,
    'zaid': 1,
    'zaino': 2,
    'zaire': 2,
    'zairean': 3,
    'zaireans': 3,
    'zairian': 4,
    'zairians': 4,
    'zaiser': 2,
    'zaitech': 2,
    'zaitsev': 2,
    'zaitz': 1,
    'zajac': 2,
    'zajdel': 2,
    'zajic': 2,
    'zajicek': 3,
    'zajkowski': 3,
    'zak': 1,
    'zakarian': 4,
    'zakharov': 3,
    'zaki': 2,
    'zakrajsek': 3,
    'zakrzewski': 4,
    'zalar': 2,
    'zalay': 2,
    'zaldivar': 3,
    'zale': 1,
    'zalenski': 3,
    'zaleski': 3,
    'zalesky': 3,
    'zalewski': 3,
    'zaley': 2,
    'zalm': 1,
    'zalman': 2,
    'zaloudek': 3,
    'zalygin': 3,
    'zaman': 2,
    'zamarello': 4,
    'zamarripa': 4,
    'zamarron': 3,
    'zambelli': 3,
    'zambia': 3,
    'zambian': 3,
    'zambito': 3,
    'zambo': 2,
    'zamboanga': 3,
    'zambrana': 3,
    'zambrano': 3,
    'zamfir': 2,
    'zammit': 2,
    'zamora': 3,
    'zamorano': 4,
    'zampedri': 3,
    'zampino': 3,
    'zamudio': 4,
    'zamzow': 2,
    'zan': 1,
    'zanamivir': 4,
    'zanca': 2,
    'zand': 1,
    'zander': 2,
    'zanders': 2,
    'zandi': 2,
    'zandra': 2,
    'zandstra': 2,
    'zandt': 1,
    'zandy': 2,
    'zane': 1,
    'zanella': 3,
    'zaneta': 3,
    'zang': 1,
    'zangara': 3,
    'zangari': 3,
    'zanger': 2,
    'zanghi': 2,
    'zani': 2,
    'zaniest': 3,
    'zaniewski': 4,
    'zank': 1,
    'zanni': 2,
    'zanoni': 3,
    'zanoyan': 3,
    'zant': 1,
    'zantac': 2,
    'zanu': 2,
    'zanuck': 2,
    'zanussi': 3,
    'zany': 2,
    'zanzibar': 3,
    'zap': 1,
    'zapalac': 3,
    'zapata': 3,
    'zapatista': 4,
    'zapatistas': 4,
    'zapf': 1,
    'zapien': 2,
    'zapmail': 2,
    'zapp': 1,
    'zappa': 2,
    'zappala': 3,
    'zapped': 1,
    'zappia': 3,
    'zapping': 2,
    'zappone': 3,
    'zappulla': 3,
    'zapruder': 3,
    'zaps': 1,
    'zara': 2,
    'zaragoza': 4,
    'zarah': 2,
    'zarate': 2,
    'zarb': 1,
    'zarcone': 3,
    'zared': 1,
    'zarek': 2,
    'zarella': 3,
    'zaremba': 3,
    'zaremski': 3,
    'zaretsky': 3,
    'zarett': 2,
    'zaria': 3,
    'zaring': 2,
    'zarlengo': 3,
    'zarling': 2,
    'zaro': 2,
    'zarr': 1,
    'zarre': 1,
    'zarrella': 3,
    'zarro': 2,
    'zarroli': 3,
    'zartman': 2,
    'zaruba': 3,
    'zary': 2,
    'zarzecki': 3,
    'zarzusky': 3,
    'zarzycki': 3,
    'zasada': 3,
    'zaske': 1,
    'zaslavskaya': 4,
    'zaslow': 2,
    'zastrow': 2,
    'zaucha': 2,
    'zaugg': 1,
    'zaun': 1,
    'zauner': 2,
    'zavada': 3,
    'zavadil': 3,
    'zavala': 3,
    'zavery': 3,
    'zavodnik': 3,
    'zavodny': 3,
    'zawacki': 3,
    'zawada': 3,
    'zawadzki': 3,
    'zawislak': 3,
    'zawistowski': 4,
    'zayac': 2,
    'zayas': 2,
    'zayed': 2,
    'zayre': 1,
    'zazueta': 3,
    'zbig': 1,
    'zbigniew': 2,
    'zbikowski': 3,
    'zbinden': 2,
    'zdrojewski': 3,
    'ze': 1,
    'zea': 1,
    'zeagler': 2,
    'zeal': 1,
    'zealand': 2,
    'zealander': 3,
    'zealanders': 3,
    'zealot': 2,
    'zealotry': 3,
    'zealots': 2,
    'zealous': 2,
    'zealously': 3,
    'zebell': 2,
    'zebley': 2,
    'zebra': 2,
    'zebras': 2,
    'zebrowski': 3,
    'zebulon': 3,
    'zecca': 2,
    'zech': 1,
    'zecher': 2,
    'zechman': 2,
    'zeck': 1,
    'zeckendorf': 3,
    'zed': 1,
    'zedekiah': 4,
    'zedillo': 3,
    'zedillos': 3,
    'zedong': 2,
    'zee': 1,
    'zeeb': 1,
    'zeeble': 2,
    'zeebrugge': 3,
    'zeek': 1,
    'zeeland': 2,
    'zeeman': 2,
    'zeese': 1,
    'zeff': 1,
    'zeffirelli': 4,
    'zegeer': 2,
    'zeger': 2,
    'zegers': 2,
    'zeglin': 2,
    'zeh': 1,
    'zehnder': 2,
    'zehner': 2,
    'zehntel': 2,
    'zehr': 1,
    'zehren': 2,
    'zehring': 2,
    'zeibel': 2,
    'zeichner': 2,
    'zeiders': 2,
    'zeidler': 3,
    'zeidman': 2,
    'zeien': 2,
    'zeiger': 2,
    'zeigler': 3,
    'zeiler': 2,
    'zeilinger': 3,
    'zeimet': 2,
    'zeinab': 2,
    'zeiner': 2,
    'zeis': 1,
    'zeiser': 2,
    'zeisler': 3,
    'zeiss': 1,
    'zeit': 1,
    'zeiter': 2,
    'zeitgeist': 2,
    'zeitler': 3,
    'zeitlin': 2,
    'zeitung': 2,
    'zeitz': 1,
    'zekauskas': 3,
    'zeke': 1,
    'zel': 1,
    'zelaputon': 4,
    'zelasko': 3,
    'zelaya': 3,
    'zelazny': 3,
    'zelda': 2,
    'zeldin': 2,
    'zele': 1,
    'zelek': 2,
    'zelem': 2,
    'zelenak': 3,
    'zelenka': 3,
    'zeleny': 3,
    'zelesnik': 3,
    'zeleznik': 3,
    'zelia': 3,
    'zelie': 2,
    'zeliff': 2,
    'zelikow': 3,
    'zelina': 3,
    'zelinka': 3,
    'zelinski': 3,
    'zelinsky': 3,
    'zelko': 2,
    'zell': 1,
    'zellars': 2,
    'zelle': 1,
    'zeller': 2,
    'zellerbach': 3,
    'zellers': 2,
    'zellman': 2,
    'zellmer': 2,
    'zellner': 2,
    'zelma': 2,
    'zelman': 2,
    'zelnick': 2,
    'zelos': 2,
    'zelotes': 2,
    'zeltner': 2,
    'zeltzer': 2,
    'zelvin': 2,
    'zemaitis': 3,
    'zeman': 2,
    'zemanek': 3,
    'zemba': 2,
    'zembower': 3,
    'zembriski': 3,
    'zemecki': 3,
    'zemeckis': 3,
    'zemel': 2,
    'zemin': 2,
    'zemke': 1,
    'zempel': 2,
    'zen': 1,
    'zena': 2,
    'zenaida': 3,
    'zenas': 2,
    'zenchu': 2,
    'zenda': 2,
    'zendejas': 3,
    'zender': 2,
    'zeneca': 3,
    'zeng': 1,
    'zenger': 2,
    'zenia': 3,
    'zenica': 3,
    'zenina': 3,
    'zenith': 2,
    'zenk': 1,
    'zenker': 2,
    'zenna': 2,
    'zenner': 2,
    'zeno': 2,
    'zenobia': 4,
    'zenon': 2,
    'zenor': 2,
    'zens': 1,
    'zent': 1,
    'zentec': 2,
    'zentner': 2,
    'zentralsparkasse': 5,
    'zentz': 1,
    'zenz': 1,
    'zeoli': 3,
    'zeos': 2,
    'zepa': 2,
    'zepeda': 3,
    'zephyr': 2,
    'zephyrs': 2,
    'zepp': 1,
    'zeppelin': 3,
    'zeppenfeld': 3,
    'zera': 2,
    'zerbe': 1,
    'zerby': 2,
    'zercomo': 3,
    'zerilli': 3,
    'zeringue': 2,
    'zerkle': 2,
    'zerlina': 3,
    'zerlinda': 3,
    'zermeno': 3,
    'zern': 1,
    'zero': 2,
    'zeroed': 2,
    'zeroes': 2,
    'zeroing': 3,
    'zeroli': 3,
    'zeros': 2,
    'zerr': 1,
    'zertuche': 2,
    'zervas': 2,
    'zervos': 2,
    'zerwhale': 2,
    'zest': 1,
    'zestful': 2,
    'zeta': 2,
    'zettel': 2,
    'zettle': 2,
    'zettlemoyer': 4,
    'zettler': 2,
    'zeus': 1,
    'zev': 1,
    'zeva': 2,
    'zewe': 2,
    'zeyno': 2,
    'zhambu': 2,
    'zhan': 1,
    'zhang': 1,
    'zhao': 1,
    'zhejiang': 2,
    'zheng': 1,
    'zhenyu': 2,
    'zhirinovsky': 4,
    'zhivago': 3,
    'zhivkov': 2,
    'zhou': 1,
    'zhu': 1,
    'zhuhai': 2,
    'zi': 1,
    'zia': 2,
    'ziad': 2,
    'ziahe': 2,
    'ziashivaney': 5,
    'zibell': 2,
    'ziccardi': 3,
    'zich': 1,
    'zick': 1,
    'zickefoose': 3,
    'zico': 2,
    'zidek': 2,
    'zieba': 2,
    'ziebarth': 2,
    'ziebell': 2,
    'zieg': 1,
    'ziegenbein': 3,
    'ziegenfuss': 3,
    'ziegenhorn': 3,
    'zieger': 2,
    'ziegfeld': 2,
    'ziegfield': 2,
    'ziegler': 2,
    'ziehl': 1,
    'ziehm': 1,
    'ziel': 1,
    'zielinski': 3,
    'zielke': 1,
    'zieman': 2,
    'ziemann': 2,
    'ziemba': 2,
    'ziemer': 2,
    'ziemian': 3,
    'ziemke': 1,
    'zientek': 2,
    'zier': 1,
    'zierke': 1,
    'ziesemer': 3,
    'zieske': 1,
    'ziesmer': 3,
    'zietlow': 2,
    'zietz': 1,
    'ziff': 1,
    'zifferero': 4,
    'zig': 1,
    'ziggurat': 3,
    'ziggurats': 3,
    'ziglar': 2,
    'zigler': 3,
    'zigman': 2,
    'zigzag': 2,
    'zigzagged': 2,
    'zigzagging': 3,
    'zigzags': 2,
    'zika': 2,
    'zike': 1,
    'zil': 1,
    'zilber': 2,
    'zilch': 1,
    'zile': 1,
    'zilka': 2,
    'zilkha': 2,
    'zill': 1,
    'zilla': 2,
    'zillah': 2,
    'ziller': 2,
    'zillion': 2,
    'zillionaire': 3,
    'zillions': 2,
    'zillmer': 2,
    'zilmer': 2,
    'zilvia': 3,
    'zim': 1,
    'zima': 2,
    'zimbabwe': 3,
    'zimbabwean': 4,
    'zimbalist': 3,
    'zimbelman': 3,
    'zimdars': 2,
    'zimerman': 3,
    'zimmer': 2,
    'zimmerer': 3,
    'zimmerle': 3,
    'zimmerly': 3,
    'zimmerman': 3,
    'zimmermann': 3,
    'zimmers': 2,
    'zimny': 2,
    'zimpfer': 2,
    'zinc': 1,
    'zinck': 1,
    'zinda': 2,
    'zinder': 2,
    'zine': 1,
    'zines': 1,
    'zinfandel': 3,
    'zing': 1,
    'zinga': 2,
    'zingale': 3,
    'zingaro': 3,
    'zinger': 2,
    'zingg': 1,
    'zingler': 3,
    'zinia': 3,
    'zink': 1,
    'zinke': 1,
    'zinman': 2,
    'zinn': 1,
    'zinner': 2,
    'zinni': 2,
    'zinnia': 3,
    'zinnias': 3,
    'zinno': 2,
    'zins': 1,
    'zinser': 2,
    'zinsmeister': 3,
    'zinter': 2,
    'ziobro': 3,
    'ziolkowski': 4,
    'ziomek': 3,
    'zion': 2,
    'zionism': 4,
    'zionist': 3,
    'zionists': 3,
    'zions': 2,
    'zip': 1,
    'zipf': 1,
    'zipfel': 2,
    'zipkin': 2,
    'zipless': 2,
    'zipp': 1,
    'zipped': 1,
    'zipper': 2,
    'zippered': 2,
    'zipperer': 3,
    'zippering': 3,
    'zippers': 2,
    'zipping': 2,
    'zippo': 2,
    'zippora': 3,
    'zippy': 2,
    'zips': 1,
    'zirbel': 2,
    'zirbes': 1,
    'zircon': 2,
    'zirconium': 4,
    'zirconiums': 4,
    'zircons': 2,
    'zirella': 3,
    'zirk': 1,
    'zirkel': 2,
    'zirkelbach': 3,
    'zirkle': 2,
    'zisa': 2,
    'zises': 2,
    'zisk': 1,
    'ziska': 2,
    'zisson': 2,
    'zita': 2,
    'zitek': 2,
    'zither': 2,
    'zithers': 2,
    'zito': 2,
    'zittel': 2,
    'ziv': 1,
    'ziwei': 2,
    'ziyad': 2,
    'ziyang': 2,
    'zizzo': 2,
    'zlata': 2,
    'zlin': 1,
    'zlobin': 2,
    'zlogar': 2,
    'zlomsowitch': 3,
    'zlotnick': 2,
    'zloty': 2,
    'zlotys': 2,
    'zmijewski': 3,
    'zobel': 2,
    'zobrist': 2,
    'zoch': 1,
    'zocor': 2,
    'zodiac': 3,
    'zody': 2,
    'zoe': 2,
    'zoeller': 2,
    'zoellick': 2,
    'zoellner': 2,
    'zoete': 2,
    'zofagart': 3,
    'zofran': 2,
    'zogby': 2,
    'zogg': 1,
    'zoghby': 2,
    'zoh': 1,
    'zola': 2,
    'zoll': 1,
    'zollars': 2,
    'zoller': 2,
    'zollinger': 3,
    'zollman': 2,
    'zollner': 2,
    'zollo': 2,
    'zolman': 2,
    'zoloft': 2,
    'zolp': 1,
    'zoltan': 2,
    'zombie': 2,
    'zombies': 2,
    'zon': 1,
    'zona': 2,
    'zondervan': 3,
    'zone': 1,
    'zoned': 1,
    'zones': 1,
    'zongol': 2,
    'zoning': 2,
    'zoo': 1,
    'zook': 1,
    'zoological': 4,
    'zoologist': 4,
    'zoologists': 4,
    'zoology': 4,
    'zoom': 1,
    'zoomed': 1,
    'zooming': 2,
    'zooms': 1,
    'zoos': 1,
    'zopfi': 2,
    'zora': 2,
    'zorah': 2,
    'zoran': 2,
    'zorana': 3,
    'zorich': 2,
    'zorina': 3,
    'zorine': 3,
    'zorinsky': 3,
    'zorkin': 2,
    'zorn': 1,
    'zornes': 1,
    'zornow': 2,
    'zorro': 2,
    'zortman': 2,
    'zorz': 1,
    'zorzi': 2,
    'zosen': 2,
    'zoss': 1,
    'zoster': 2,
    'zotos': 2,
    'zotter': 2,
    'zoucha': 2,
    'zounds': 1,
    'zovirax': 3,
    'zovut': 2,
    'zsa': 1,
    'zsazsa': 2,
    'zschau': 1,
    'zuba': 2,
    'zube': 1,
    'zuber': 2,
    'zubia': 3,
    'zubillaga': 4,
    'zubin': 2,
    'zuboff': 2,
    'zubrod': 2,
    'zuccarelli': 4,
    'zuccaro': 3,
    'zucchini': 3,
    'zucco': 2,
    'zuch': 1,
    'zuchowski': 3,
    'zuck': 1,
    'zucker': 2,
    'zuckerman': 3,
    'zue': 1,
    'zuege': 1,
    'zuehlke': 1,
    'zuelke': 1,
    'zuercher': 2,
    'zufall': 2,
    'zufelt': 2,
    'zug': 1,
    'zuganov': 3,
    'zuhlke': 1,
    'zuidema': 4,
    'zuk': 1,
    'zukas': 2,
    'zuker': 2,
    'zukerman': 3,
    'zukin': 2,
    'zukowski': 3,
    'zulauf': 2,
    'zuleger': 3,
    'zulfikar': 3,
    'zulli': 2,
    'zullo': 2,
    'zulma': 2,
    'zulu': 2,
    'zuluaga': 4,
    'zulus': 2,
    'zumbro': 2,
    'zumbrun': 2,
    'zumel': 2,
    'zummo': 2,
    'zumpano': 3,
    'zumstein': 2,
    'zumwalt': 2,
    'zundel': 2,
    'zungu': 2,
    'zuni': 2,
    'zuniga': 3,
    'zunino': 3,
    'zunker': 2,
    'zupan': 2,
    'zupancic': 3,
    'zupanja': 3,
    'zupko': 2,
    'zurawski': 3,
    'zurcher': 2,
    'zurek': 2,
    'zurfluh': 2,
    'zuri': 2,
    'zurich': 2,
    'zurita': 3,
    'zurkuhlen': 3,
    'zurn': 1,
    'zurowski': 3,
    'zurve': 1,
    'zuver': 2,
    'zuzana': 3,
    'zuzu': 2,
    'zvornik': 2,
    'zwack': 1,
    'zwahlen': 2,
    'zwart': 1,
    'zweber': 2,
    'zweibel': 2,
    'zweifel': 2,
    'zweig': 1,
    'zwerdling': 2,
    'zwetchkenbaum': 3,
    'zwick': 1,
    'zwicker': 2,
    'zwicky': 2,
    'zwiebel': 2,
    'zwiefelhofer': 4,
    'zwieg': 1,
    'zwilling': 2,
    'zwolinski': 3,
    'zycad': 2,
    'zych': 1,
    'zycher': 2,
    'zydeco': 3,
    'zygmunt': 2,
    'zygote': 2,
    'zyla': 2,
    'zylka': 2,
    'zylstra': 2,
    'zyman': 2,
    'zynda': 2,
    'zysk': 1,
    'zyskowski': 3,
    'zyuganov': 3,
    'zywicki': 3
};

export default syllables;
