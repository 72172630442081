import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const diabetes = {
    name: 'diabetes',
    longName: 'Diabetes',
    className: 'highlight-pinkRed',
    category: 'person-centred',
    postText: count => count === 1 ?
        'word or phrase may not be person-centred language for communicating about diabetes.' :
        'words or phrases may not be person-centred language for communicating about diabetes.',
    sidebarPriority: 1,
    tooltip: {
        text: '%s may be an example language that is not person-centred.',
        urlPreText: 'Read more at: '
    }
};

const strategy = serverStrategyCreator(diabetes.name);
const pinnedStrategy = serverStrategyCreator(diabetes.name, true);

diabetes.decorator = createDecorator(strategy, diabetes);
diabetes.pinnedDecorator = createDecorator(pinnedStrategy, diabetes);
export default diabetes;
