import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const acronym = {
    name: 'acronym',
    longName: 'Acronyms',
    className: 'highlight-orange',
    category: 'complex',
    postText: count => count === 1 ?
        'instance of an acronym.' :
        'instances of acronyms.',
    subText: 'Always define acronyms first and minimise their use where possible.',
    sidebarPriority: 4
};

const strategy = serverStrategyCreator(acronym.name);
const pinnedStrategy = serverStrategyCreator(acronym.name, true);

acronym.decorator = createDecorator(strategy, acronym);
acronym.pinnedDecorator = createDecorator(pinnedStrategy, acronym);
export default acronym;
