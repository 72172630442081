import React, {useState} from 'react';
import {Button, Menu, MenuItem} from '@material-ui/core';
import { ExpandMore as DownArrow, Print, Description as Document} from '@material-ui/icons';
import ExportMenuSaveAll from './ExportMenuSaveAll';

/**
 * Export drop down
 */
const ExportMenu = ({onSummaryClick, onDocumentClick, editorState}) => {

    const [anchorEl, setAnchorEl] = useState(null);


    const handleOpen = ({currentTarget}) => setAnchorEl(currentTarget);

    const handleClose = () => setAnchorEl(null);

    /**
     * Clicking stat summary button
     */
    const handleSummary = () => {
        onSummaryClick();
        handleClose();
    };

    /**
     * Clicking word document button
     */
    const handleDocument = () => {
        onDocumentClick();
        handleClose();
    };

    return <>
        <Button variant={'outlined'} onClick={handleOpen} data-tour={'step-nine'} style={{paddingRight: 8}}>
            Export <DownArrow/>
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <MenuItem onClick={handleSummary}>
                <Print className={'mr-2'}/> Printable Summary
            </MenuItem>
            <MenuItem onClick={handleDocument}>
                <Document className={'mr-2'}/> Word Document (Text Only)
            </MenuItem>
            <ExportMenuSaveAll handleClose={handleClose} editorState={editorState}/>
        </Menu>
    </>;
};


export default ExportMenu;

