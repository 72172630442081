import {nanoid} from 'nanoid';
import store from 'redux/store';
import {setHighlightCount} from 'redux/actions/actions';


const response = {current: null, pinned: null};
store.subscribe(() => {
    const state = store.getState();
    response.current = state.response;
    response.pinned = state.pinnedResults.results.response;
});

/**
 * All server-side highlighting uses the same strategy (server returns block key and text indices to highlight)
 * @param name = name of decorator
 * @param usePinned = use pinned response instead of current
 * @returns function = strategy
 */
const serverStrategyCreator = (name, usePinned = false) => (contentBlock, callback) => {
    const key = contentBlock.getKey();
    const blockText = contentBlock.getText();
    const blocks = (usePinned ? response.pinned : response.current)?.blocksObj
    const block = (blocks || {})[key];
    if (!block) return;

    let storeAmount = 0;
    const highlights = block.highlights[name] || [];
    highlights.forEach(highlight => {
        if (blockText.slice(...highlight.span).toLowerCase() === highlight.text.toLowerCase()) {
            if(!highlight.excluded) {
                storeAmount += 1;
            }
            callback(...highlight.span, {response: highlight, instance: nanoid()});
        }
    });

    store.dispatch(setHighlightCount(name, key, storeAmount));
};

export default serverStrategyCreator;
