import {
    SET_HIGHLIGHT_COUNT,
    UPDATE_COUNTER,
    REMOVE_BLOCK_HIGHLIGHT_COUNTS,
    SET_DECORATOR_STATE,
    SET_LAST_SERVER_RESPONSE,
    SET_EXCLUDED_WORDS,
    SET_HOVERED_DECORATOR,
    UNPIN_RESULTS,
    PIN_RESULTS, IMPORT_PINNED_RESULTS, SET_HIGHLIGHTS
} from './actionTypes';

///////// Highlights
/**
 * Set the amount of highlights in a block for a specific decorator
 * @param decoratorType = unique decorator name, see decorators/strategies
 * @param blockKey
 * @param amount
 * @returns {{payload: {blockKey: string, decoratorType: string, amount: int}, type: string}}
 */
export const setHighlightCount = (decoratorType, blockKey, amount) => {
    return {
        type: SET_HIGHLIGHT_COUNT,
        payload: {decoratorType, blockKey, amount}
    };
};

/**
 * Set the entire highlights state to a value
 * @param highlights
 * @returns {{payload: {highlights}, type: string}}
 */
export const setHighlights = highlights => {
    return {
        type: SET_HIGHLIGHTS,
        payload: {highlights}
    };
};

/**
 * Remove all decorator counts for a list of blocks
 * @param blockKeys = array of block keys
 * @returns {{payload: {blockKeys: string[]}, type: string}}
 */
export const removeBlockHighlightCounts = blockKeys => {
    return {
        type: REMOVE_BLOCK_HIGHLIGHT_COUNTS,
        payload: {blockKeys}
    };
};


/// Counts
export const updateCounter = counts => {
    return {
        type: UPDATE_COUNTER,
        payload: {counts}
    };
};


/// Decorators
export const dispatchDecoratorState = decoratorState => {
    return {
        type: SET_DECORATOR_STATE,
        payload: {decoratorState}
    };
};

export const setHoveredDecorator = hovered => {
    return {
        type: SET_HOVERED_DECORATOR,
        payload: hovered
    };
};


/// Response
export const setLastServerResponse = response => {
    return {
        type: SET_LAST_SERVER_RESPONSE,
        payload: {response}
    };
};


/// Excluded
export const setExcludedWords = words => {
    return {
        type: SET_EXCLUDED_WORDS,
        payload: {words}
    };
};

// Pin Results
export const pinResults = stats => {
    return {
        type: PIN_RESULTS,
        payload: stats
    };
};

export const unpinResults = () => {
    return {
        type: UNPIN_RESULTS
    };
};

export const importPinnedResults = pinned => {
    return {
        type: IMPORT_PINNED_RESULTS,
        payload: pinned
    };
};
