import React, {useState} from 'react';
import {Box, Collapse} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import {followCursor} from 'tippy.js';
import Tooltip from '@tippyjs/react';

import './styles/collapse.css';


const CollapsibleInfo = ({
                             title,
                             titleAdornmentRight,
                             content,
                             uncollapsedContent,
                             showReadMore,
                             showRightExpandIcon = true
                         }) => {
    const [showInfo, setShowInfo] = useState(false);

    const toggleInfo = (e) => {
        e.preventDefault();
        setShowInfo(!showInfo);
    }

    return <div className={'mb-2 collapse-info-container'}>
        <Tooltip
            // Tooltip follows mouse horizontally across screen
            plugins={[followCursor]}
            followCursor={'horizontal'}
            arrow={false}
            content={<Box p={1}>Click for {showInfo ? 'less' : 'more'} info</Box>}
        >
            <div className={'collapse-title-container mb-2'} onClick={toggleInfo}>
                <div className={'collapse-title'}>
                    <h4 className={'m-0'}>{title}</h4>
                    <div className={'collapse-title-adorn-right ml-1'}>{titleAdornmentRight}</div>
                </div>
                <div className={'collapse-title-expand mr-2'}>
                    {showRightExpandIcon && (showInfo ? <MinusIcon/> : <AddIcon/>)}
                </div>
            </div>
        </Tooltip>


        {uncollapsedContent &&
        <div className={'mb-1'}>
            {uncollapsedContent}
        </div>}

        <Collapse in={showInfo}>
            <div className={'collapse-content'}>
                {content}
            </div>
        </Collapse>

        {showReadMore && <a href={'/#'} onClick={toggleInfo}>Click to {showInfo ? 'hide' : 'read more'}.</a>}
    </div>;
};


export default CollapsibleInfo;
