import {Box, Button, Collapse, Fade, LinearProgress} from '@material-ui/core';
import {Publish} from '@material-ui/icons';
import axios from 'axios';
import {parse} from 'csv-parse/dist/esm/sync';
import {stringify} from 'csv-stringify/dist/esm/sync';
import {ContentState, convertToRaw} from 'draft-js';
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {BACKEND_URL} from '../constants/vars';
import {count as longWordCount} from '../decorators/strategies/longWord';
import {calculateReadability, stringCounts} from '../utils/regex';


const decorators = {
    acronym: true,
    cancer: false,
    chronicPain: false,
    complexLanguage: true,
    complexSentence: false,
    dementia: false,
    diabetes: false,
    financialTerms: false,
    legalTerms: false,
    longParagraph: false,
    longSentence: false,
    dotPoints: false,
    longWord: true,
    mentalHealth: false,
    passiveVoice: true,
    uncommonWord: true
};

const MultiTextInput = ({show, setShow}) => {
    const [progress, setProgress] = useState(0);
    const [max, setMax] = useState(1);
    const [loading, setLoading] = useState(false);
    const [downloadData, setDownloadData] = useState(null);

    const onClose = () => setShow(false);
    const onFileSelect = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        setDownloadData(null);

        reader.onload = async () => {
            setProgress(0);
            setLoading(true);
            const csv = [];
            const rows = parse(reader.result, {columns: true});
            setMax(rows.length || 1);

            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                const stats = await getStats(row);
                csv.push(stats);
                setProgress(i + 1);
            }

            setLoading(false);
            setDownloadData(stringify(csv, {header: true}));
        };

        reader.readAsText(file);

        e.target.value = '';
    };

    const getBackendDecorations = async (contentState, counts, excluded) => {
        const rawContentState = convertToRaw(contentState);

        // editor content
        // key = block(paragraph) id
        // text = block text
        // type = block styling type, e.g. header-one, header-two, unordered-list, etc.
        // entityRanges = spans of text within block that are marked as entities
        const blocks = rawContentState.blocks.map(({key, text, type, entityRanges: entities}) => {
            const entityRanges = entities.map(entity => {
                return {
                    offset: entity.offset,
                    length: entity.length,
                    type: rawContentState.entityMap[entity.key].type
                };
            });
            return {key, text, type, entityRanges};
        });

        const body = {
            counts,
            flags: decorators,
            excluded,
            blocks
        };

        // Query the server for extra highlights
        const response = await axios.post(BACKEND_URL, body);
        const {data} = response;

        // Reformat block response as an object for quicker access
        data.blocksObj = {};
        data.blocks.forEach(block => {
            data.blocksObj[block.key] = block;
        });

        return data;
    };

    const getStats = async (row) => {
        const contentState = ContentState.createFromText(row.text);

        const counts = stringCounts(contentState);
        const excluded = row.exclude?.toLowerCase()?.split(',');

        const blocks = contentState.getBlocksAsArray();
        let longWords = 0;
        for (let i = 0; i < blocks.length; i++) {
            longWords += longWordCount(blocks[i], null, contentState);
        }

        const data = await getBackendDecorations(contentState, counts, excluded);
        const highlights = data.blocks.reduce((prev, cur) => {
            return {
                uncommonWords: prev.uncommonWords + cur.highlights.uncommonWord.length,
                thesaurus: prev.thesaurus + cur.highlights.complexLanguage.length,
                acronyms: prev.acronyms + cur.highlights.acronym.length,
                passiveVoice: prev.passiveVoice + cur.highlights.passiveVoice.length

            };
        }, {uncommonWords: 0, thesaurus: 0, acronyms: 0, passiveVoice: 0});

        return {
            // Number of words,
            wordCount: counts.words,

            // grade reading score,
            readability: calculateReadability(contentState).scoreNoRounded,
            readabilityWithExcludes: calculateReadability(contentState, excluded).scoreNoRounded,

            // number of long words,
            longWords,

            // number of sentences,
            sentenceCount: counts.sentences,

            // complex language %,
            complexLanguagePercent: data.pct_complex_lang,

            // number of uncommon words,
            uncommonWords: highlights.uncommonWords,

            // number of thesaurus entries,
            thesaurus: highlights.thesaurus,

            // number of acronyms,
            acronyms: highlights.acronyms,

            // number of passive voice,
            passiveVoice: highlights.passiveVoice,

            // lexical density
            lexicalDensity: data.lexicalDensity ?? null
        };
    };

    const onDownload = () => {
        const blob = new Blob([downloadData], {type: 'text/csv'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'shell_output.csv');
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
    };

    return <Modal show={show} onHide={onClose} size={'lg'}>
        <Modal.Header>
            <Modal.Title>
                Multiple Texts
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Columns should be surrounded by double quotes
            <pre style={{border: '1px solid grey', borderRadius: '4px', padding: '.5rem'}}>
                text,exclude<br/>
                "Example Text 1","excluded,words,here"<br/>
                "Example Text 2",""<br/>
            </pre>
            Internal commas and line breaks are fine.<br/>The exclude column should be 0-5 words separated by commas.
            <pre style={{border: '1px solid grey', borderRadius: '4px', padding: '.5rem'}}>
                text,exclude<br/>
                "Example Text 1<br/>
                with a line break and, a comma","excluded,words,here"<br/>
            </pre>
            Internal double quotes must be escaped with a second double quote.
            <pre style={{border: '1px solid grey', borderRadius: '4px', padding: '.5rem'}}>
                text,exclude<br/>
                "Example Text 1 this ""word"" is quoted","excluded,words,here"<br/>
            </pre>

            <Fade in={progress > 0 || loading}>
                <Box display={'flex'} alignItems={'center'}>
                    <Box flex={1}>
                        <LinearProgress variant={'determinate'} value={progress / max * 100}/>
                    </Box>
                    <Box ml={1}>
                        {Math.round(progress / max * 100)}% ({progress}/{max})
                    </Box>
                </Box>
            </Fade>
            <Collapse in={downloadData != null}>
                <Box display={'flex'} justifyContent={'flex-end'} pt={1}>
                    <Button onClick={onDownload} variant={'contained'} color={'primary'} disabled={loading}>Download
                        CSV</Button>
                </Box>
            </Collapse>
        </Modal.Body>
        <Modal.Footer>
            <Button variant={'contained'} component={'label'} color={'primary'} endIcon={<Publish/>} disabled={loading}
                    style={{marginRight: '1rem'}}>
                Select File
                <input hidden type={'file'} accept={'text/csv'} onChange={onFileSelect}/>
            </Button>
            <Button variant={'outlined'} component={'label'} color={'secondary'} onClick={onClose}>Close</Button>
        </Modal.Footer>
    </Modal>;
};

export default MultiTextInput;
