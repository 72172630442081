import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const passiveVoice = {
    name: 'passiveVoice',
    longName: 'Passive Voice',
    className: 'highlight-lightBlue',
    category: 'passiveVoice',
    postText: count => count === 1 ?
        'use of passive voice.' :
        'uses of passive voice.',
    subText: 'Active sentences are easier to understand and act on.',
    sidebarPriority: 0,
    pinOnSidebar: true
};

const strategy = serverStrategyCreator(passiveVoice.name);
const pinnedStrategy = serverStrategyCreator(passiveVoice.name, true);

passiveVoice.decorator = createDecorator(strategy, passiveVoice);
passiveVoice.pinnedDecorator = createDecorator(pinnedStrategy, passiveVoice);
export default passiveVoice;
