import {SET_EXCLUDED_WORDS} from 'redux/actions/actionTypes';


export const initialState = [];

const excluded = (state = initialState, action) => {

    switch (action.type) {
        case SET_EXCLUDED_WORDS: {
            const {words} = action.payload;
            return words;
        }
        default:
            return state;
    }
};

export default excluded;
