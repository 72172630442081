import {createDecorator} from 'decorators/decoratorCreator';
import {nanoid} from 'nanoid';
import {sentencesInString, wordsInString} from 'utils/regex';
import store from 'redux/store';
import {setHighlightCount} from 'redux/actions/actions';
import {LONG_SENTENCE_WORDS} from 'constants/vars';


const longSentence = {
    name: 'longSentence',
    longName: 'Long Sentences',
    className: 'highlight-darkPurple',
    category: 'readability',
    postText: count => count === 1 ?
        'sentence is long.' :
        'sentences are long.',
    sidebarPriority: 1
};


const strategy = (contentBlock, callback, contentState) => {
    const text = contentBlock.getText();
    const sentences = sentencesInString(text, true);

    // For each sentence, determine number of words and flag as too long if greater than amount
    let storeAmount = 0;
    sentences.forEach(sentence => {

        // Do not highlight IGNORED sentences
        const entityKey = contentBlock.getEntityAt(sentence.index);
        if (entityKey) {
            const entity = contentState.getEntity(entityKey);
            if (entity.getType() === 'IGNORED') return;
        }

        const wordCount = wordsInString(sentence.text, true).length;
        if (wordCount > LONG_SENTENCE_WORDS) {
            storeAmount += 1;
            callback(sentence.index, sentence.index + sentence.text.length, {instance: nanoid()});
        }
    });

    store.dispatch(setHighlightCount(longSentence.name, contentBlock.getKey(), storeAmount));
};
longSentence.decorator = createDecorator(strategy, longSentence);

export default longSentence;
