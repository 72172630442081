import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const dementia = {
    name: 'dementia',
    longName: 'Dementia',
    className: 'highlight-pinkRed',
    category: 'person-centred',
    postText: count => count === 1 ?
        'word or phrase may not be person-centred language for communicating about dementia.' :
        'words or phrases may not be person-centred language for communicating about dementia.',
    sidebarPriority: 2,
    tooltip: {
        text: '%s may be an example language that is not person-centred.',
        urlPreText: 'Read more at: '
    }
};

const strategy = serverStrategyCreator(dementia.name);
const pinnedStrategy = serverStrategyCreator(dementia.name, true);

dementia.decorator = createDecorator(strategy, dementia);
dementia.pinnedDecorator = createDecorator(pinnedStrategy, dementia);
export default dementia;
