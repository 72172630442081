import React from 'react';
import {SHLL_EDITOR_URL} from '../constants/vars';
import CollapsibleInfo from './CollapsibleInfo';
import ExcludedWords from './ExcludedWords';

import './styles/sidebar.css';


const SidebarTextPreparation = ({onSummaryClick}) => {


    return <div className={'mb-2 text-left'}>
        <h4 className={'mt-2'}>Text Preparation Mode</h4>
        <p className={'mb-1'}>In this mode you cannot change any text, but you can tell us which parts you want to
            exclude from
            Readability or Complex language.</p>
        <p>Note: You must manually remove any full stops from acronyms (for
            example, change U.S. to US). The editor will automatically remove full
            stops from 'e.g.' and 'i.e.' so you don't need to edit these.</p>
        <CollapsibleInfo
            title={'Excluding sentences, bullet points, and headings'}
            content={<>
                <p className={'mb-1'}>When calculating Grade reading score, do not include any incomplete
                    sentences.
                    Click
                    on a section of text
                    to add or remove it from the Grade reading score. Excluded sections of text are shown in the grey
                    highlight. You can see the updated Grade reading score below.</p>
                <p className={'mb-0'}>
                    <b>Still not sure which parts to remove?{' '}
                    </b>Read more <a href={`${SHLL_EDITOR_URL}#readability`} target={'_blank'}>here</a>.
                </p>
            </>}
        />

        <CollapsibleInfo
            title={'Excluding words'}
            content={<>
                <p className={'mb-0'}>Type each word into the box below. Only exclude a word if:</p>
                <ul className={'mb-1'}>
                    <li>Your Audience is likely to be familiar with it</li>
                    <li>You define the word in the text</li>
                </ul>
                <p className={'mb-1'}>
                    Excluded words won't be counted as complex language. Read more detailed advice <a
                    href={`${SHLL_EDITOR_URL}#complex`} target={'_blank'}>here</a>.
                </p>
                <p className={'mb-0'}>
                    You can also read about the effects of these words on the grade reading score by viewing
                    the Printable Summary <a href={'/#'} onClick={onSummaryClick}>Report</a>.
                </p>
            </>}
            uncollapsedContent={<>
                You can choose up to 5 words to exclude from Complex Language. You can also look at the effects of these words on the grade reading score.
            </>}
        />
        <ExcludedWords/>
    </div>;
};

export default SidebarTextPreparation;
