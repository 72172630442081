import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import PriorityIcon from '@material-ui/icons/ReportProblemRounded';
import React from 'react';
import Tour from 'reactour';
import {ENABLE_PAID_FEATURES, SHLL_EDITOR_URL} from '../constants/vars';
import {insertIf} from '../utils/common';

const toggleReadabilityInfo = show => {
    const readabilitySection = document.querySelector('[data-tour="step-three"]');
    if (!readabilitySection) return;
    const readabilityHeader = readabilitySection.querySelector('.collapse-title-container');
    const readabilityCollapse = readabilitySection.querySelector('.MuiCollapse-root');
    if (readabilityCollapse.classList.contains(`MuiCollapse-${show ? 'hidden' : 'entered'}`)) {
        readabilityHeader.click();
    }
};

const steps = [
    {
        content: () => {
            return <div>
                <b>Welcome to the SHeLL Editor</b><br/>
                <p className={'mb-1'}>The SHeLL Editor helps you make health information easy to understand.</p>
                <p className={'mb-1'}>This brief tutorial will show you how to use the Editor.</p>
                <p className={'mb-1'}>You can close the tutorial at any time.</p>
                <p>If you want to see it again, click the help icon (<HelpIcon
                    color={'primary'}/>) on the toolbar.</p>
            </div>;
        },
        style: {
            maxWidth: 450
        }
    },
    {
        selector: '[data-tour="step-one"]',
        content: () => {
            return <div>
                <b>Editing your text</b><br/>
                Paste or write your text in here.<br/>
                Format using the buttons at the top.
            </div>;
        }
    },
    {
        selector: '[data-tour="step-two"]',
        action: node => toggleReadabilityInfo(true),
        content: () => {
            return <div>
                <b>Features panel</b><br/>
                <p className={'mb-1'}>This panel shows you information about your text.</p>
                <p>To learn more about each feature and access video tutorials, click the plus <span
                    className={'nowrap'}>(<AddIcon/>)</span> next to its name.</p>
            </div>;
        }
    },
    {
        selector: '[data-tour="step-three"]',
        resizeObservables: ['[data-tour="step-three"]'],
        content: () => {
            return <div>
                <b>Readability</b><br/>
                The first feature is readability. This tells you the Grade reading score based on the length of words
                and sentences.<br/>
                Readability updates automatically as you type.<br/>
                You will see that some words in the editing section are highlighted in yellow and purple.
            </div>;
        },
        style: {
            maxWidth: 450
        }
    },
    {
        selector: '[data-tour="step-four"]',
        content: () => {
            return <div>
                <b>Complex Language</b><br/>
                The second feature is complex language. This tells you which words are uncommon in English.<br/>
                Where we can, we also give you simpler alternatives for health and medical jargon.<br/>
                You will see complex language highlighted in dark pink, light pink, and orange.
            </div>;
        },
        style: {
            maxWidth: 450
        }
    },
    {
        selector: '[data-tour="step-five"]',
        content: () => {
            return <div>
                <b>Check my text</b><br/>
                For Complex Language and most of the other features, you will need to hit this <b>'Check my
                text'</b> button
                to see the results.
            </div>;
        }
    },
    {
        selector: '[data-tour="step-six"]',
        content: () => {
            return <div>
                <b>Check my text</b><br/>
                When you see a red warning <PriorityIcon color={'error'}/>, this means the results are not up
                to date. When the warning is faded <PriorityIcon color={'disabled'}/>, the results are up to
                date.
            </div>;
        }
    },
    {
        selector: '[data-tour="step-seven"]',
        content: () => {
            return <div>
                <b>Other features</b><br/>
                There are lots of other features too!<br/>
                Use the drop-down menu to choose which you want to look at.
            </div>;
        }//TODO: highlight mode toggle?
    },
    ...insertIf(ENABLE_PAID_FEATURES, {
        selector: '[data-tour="step-eight"]',
        content: () => {
            return <div>
                <b>Advanced features</b><br/>
                Text Preparation Mode lets you exclude some sentences from Readability.<br/>
                You can also exclude up to 5 terms from the Complex Language and Readability.<br/>
                Find out more using our <a href={`${SHLL_EDITOR_URL}#complex`} target={'_blank'}>Help Page</a>.
            </div>;
        },
        style: {
            maxWidth: 400
        }
    }),
    ...insertIf(ENABLE_PAID_FEATURES, {
        selector: '[data-tour="step-nine"]',
        content: () => {
            return <div>
                <b>Finishing up</b><br/>
                When you've finished working on the text you have three options:
                <ul>
                    <li>Export a word document (.docx) of the revised text.</li>
                    <li>Export a PDF of the revised text and a summary of the results.</li>
                    <li>Export a file so that you can upload the text another time. This will save any settings as
                        well.
                    </li>
                </ul>
            </div>;
        },
        style: {
            maxWidth: 450
        }
    }),
    {
        selector: '[data-tour="step-ten"]',
        content: () => {
            return <div>
                To view this tutorial again, click the help icon (<HelpIcon color={'primary'}/>) on the toolbar.
            </div>;
        }
    }
];

const InteractiveTutorial = ({open, setOpen}) => {

    const onClose = () => {
        if (!localStorage.getItem('seenTutorial')) {
            localStorage.setItem('seenTutorial', new Date().toISOString());
        }
        setOpen(false);
    };


    return <Tour
        isOpen={open}
        disableInteraction
        onBeforeClose={() => toggleReadabilityInfo(false)}
        rounded={4}
        steps={steps}
        closeWithMask={false}
        lastStepNextButton={<CloseIcon/>}
        onRequestClose={onClose}
        scrollDuration={300}
        startAt={0}
    />;
};


export default InteractiveTutorial;

