import {nanoid} from 'nanoid';
import store from 'redux/store';
import {setHighlightCount} from 'redux/actions/actions';
import {createDecorator} from 'decorators/decoratorCreator';
import {conjInString, sentenceIsQuestion, sentencesInString, wordsInString} from 'utils/regex';
import {COMPLEX_SENTENCE_CONJUNCTIONS, COMPLEX_SENTENCE_WORDS} from 'constants/vars';


// Previously affected all sentences (hence the old name in some places),
// changed to only checks question
const complexSentence = {
    name: 'complexSentence',
    longName: 'Complex Questions',
    className: 'highlight-darkPurple',
    category: 'readability',
    postText: count => count === 1 ?
        'question has a complex structure (more than 2 conjunctions).' :
        'questions have a complex structure (more than 2 conjunctions).',
    subText: 'Try breaking these down into simpler questions.',
    sidebarPriority: 1
};


const strategy = (contentBlock, callback) => {
    const text = contentBlock.getText();
    const sentences = sentencesInString(text, true);

    // For each sentence, determine number of words and flag as too long if greater than amount
    let storeAmount = 0;
    sentences.forEach(sentence => {
        // make sure it's a question
        if (!sentenceIsQuestion(sentence.text)) return;

        const wordCount = wordsInString(sentence.text, true).length;
        const conjunctions = conjInString(sentence.text);
        if (wordCount > COMPLEX_SENTENCE_WORDS && conjunctions.length > COMPLEX_SENTENCE_CONJUNCTIONS) {
            storeAmount += 1;
            callback(sentence.index, sentence.index + sentence.text.length, {instance: nanoid()});
        }
    });

    store.dispatch(setHighlightCount(complexSentence.name, contentBlock.getKey(), storeAmount));
};

complexSentence.decorator = createDecorator(strategy, complexSentence);
export default complexSentence;
