// Category names, static text, sidebar content, etc.
import React from 'react';
import GradeReadingScore from '../components/GradeReadingScore';
import LexicalDensityScore from '../components/LexicalDensityScore';
import TextComplexity from '../components/TextComplexity';
import {ENABLE_PAID_FEATURES, SHLL_EDITOR_URL} from './vars';

export const categories = [
    {
        name: 'readability',
        longName: 'Readability',
        'data-tour': 'step-three',
        readView: true,
        renderContent: (editorState, readView) => <GradeReadingScore editorState={editorState} readView={readView}/>,
        description: <>
            <> You can do this by using <b>shorter words</b> (less than 3 syllables) and
                <b> shorter sentences</b>. Use 'Text Preparation' mode to exclude parts of the text from
                readability.
            </>
        </>,
        notes: `${SHLL_EDITOR_URL}#readability`
    },
    {
        name: 'complex',
        longName: 'Complex Language',
        'data-tour': 'step-four',
        backend: true,
        renderContent: () => <TextComplexity/>,
        description: <>
            <b>Use common, everyday language.</b> Where we can, we try to give alternatives to common public
            health terms. use 'Text Preparation' mode to exclude up to 5 medical terms from Complex Language.
        </>,
        notes: `${SHLL_EDITOR_URL}#complex`
    },
    {
        name: 'passiveVoice',
        longName: 'Passive Voice',
        backend: true,
        renderContent: () => null,
        description: <>
            Text is easier to understand and act on when we use active voice. E.g:
            <ul className={'m-0'}>
                <li>passive voice: "the test was ordered by the doctor"</li>
                <li>active voice: "the doctor ordered the test"</li>
            </ul>
        </>,
        notes: `${SHLL_EDITOR_URL}#actionability`
    },
    {
        name: 'structure',
        longName: 'Structure',
        renderContent: () => null,
        description: null,
        notes: `${SHLL_EDITOR_URL}#structure`,
        disable: !ENABLE_PAID_FEATURES
    },
    {
        name: 'person-centred',
        longName: 'Person-centred Language',
        postText: ' (Max 1)',
        backend: true,
        renderContent: () => null,
        description: 'For a few health conditions you can check that your language is person-centred, e.g. \'person with diabetes\' instead of \'diabetic\'.',
        notes: `${SHLL_EDITOR_URL}#person`,
        disable: !ENABLE_PAID_FEATURES
    }, {
        name: 'lexical',
        longName: 'Lexical Density/Diversity',
        sidebarOnly: true,
        renderContent: () => <LexicalDensityScore/>,
        description: 'Texts with lower lexical density are more like spoken English.',
        notes: `${SHLL_EDITOR_URL}#lexical`,
        disable: !ENABLE_PAID_FEATURES
    }
];
