import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const recovery = {
    name: 'recovery',
    longName: 'Recovery-oriented Language',
    className: 'highlight-pinkRed',
    category: 'person-centred',
    postText: count => count === 1 ?
        'word or phrase may not be person-centred language for communicating about recovery-oriented language.' :
        'words or phrases may not be person-centred language for communicating about recovery-oriented language.',
    sidebarPriority: 5,
    tooltip: {
        text: '%s may be an example of language that is not person-centred.',
        urlPreText: 'Read more at: '
    }
};

const strategy = serverStrategyCreator(recovery.name);
const pinnedStrategy = serverStrategyCreator(recovery.name, true);

recovery.decorator = createDecorator(strategy, recovery);
recovery.pinnedDecorator = createDecorator(pinnedStrategy, recovery);
export default recovery;
