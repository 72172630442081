import {createDecorator} from 'decorators/decoratorCreator';
import {sentencesInString} from 'utils/regex';


const setIgnored = {
    name: 'setIgnored',
    className: 'readability-sentence'
};

const strategy = (contentBlock, callback) => {
    const text = contentBlock.getText();
    const sentences = sentencesInString(text, true);

    sentences.forEach(sentence => {
        callback(sentence.index, sentence.index + sentence.text.length);
    });
};

setIgnored.createDecorator = decoratorProps => createDecorator(strategy, {...decoratorProps, ...setIgnored});
export default setIgnored;


