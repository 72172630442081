import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

/**
 * This is the THESAURUS decorator!
 */

const complexLanguage = {
    name: 'complexLanguage',
    longName: 'Thesaurus',
    className: 'highlight-pinkRed',
    category: 'complex',
    postText: count => count === 1 ?
        'word or phrase with alternatives in our thesaurus.' :
        'words or phrases with alternatives in our thesaurus.',
    subText: 'If you need to use a complex medical term, make sure you define it first.',
    sidebarPriority: 0,
    tooltip: {
        text: '%s may be an example of complex language.',
        urlPreText: 'See CDC examples: '
    }
};

const strategy = serverStrategyCreator(complexLanguage.name);
const pinnedStrategy = serverStrategyCreator(complexLanguage.name, true);

complexLanguage.decorator = createDecorator(strategy, complexLanguage);
complexLanguage.pinnedDecorator = createDecorator(pinnedStrategy, complexLanguage);
export default complexLanguage;
