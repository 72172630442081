import {Box} from '@material-ui/core';
import Tooltip from '@tippyjs/react';
import React from 'react';
import {connect} from 'react-redux';
import {roundToDP} from '../utils/common';
import PushPinRounded from './icons/PushPinRounded';

const TextComplexity = ({isPinned, pinnedPercent, complexLanguagePercent}) => {

    return <Box fontSize={'1.3rem'} display={'flex'} justifyContent={'space-between'} mr={1}>
        <Box>Text complexity: <span>{isNaN(complexLanguagePercent) ? 'N/A' : `${complexLanguagePercent}%`}</span></Box>
        {isPinned &&
        <Tooltip
            content={<Box p={2}>Pinned results</Box>}
        >
            <Box color={'#7f7f7f'}><PushPinRounded/>{isNaN(pinnedPercent) ? 'N/A' : `${pinnedPercent}%`}</Box>
        </Tooltip>}
    </Box>;
};


const mapStateToProps = state => {
    const {pinnedResults, response} = state;
    const complexLanguagePercent = roundToDP(response.pct_complex_lang, 1);
    const {pinned, results} = pinnedResults;
    const pinnedPercent = roundToDP(results.response.pct_complex_lang, 1);

    return {
        complexLanguagePercent,
        isPinned: pinned,
        pinnedPercent
    };
};

export default connect(mapStateToProps)(TextComplexity);


