import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import createToolbarPlugin, {Separator} from 'draft-js-static-toolbar-plugin';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton
} from 'draft-js-buttons';
import Counter from './Counter';
import RefreshButton from './RefreshButton';

import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import './styles/toolbar.css';

const {Toolbar: ToolbarComponent} = createToolbarPlugin();


const Toolbar = ({editorState, setEditorState, openTour, changesMade, getBackendDecorations, readView, disableTextCheck}) => {

    const getEditorState = () => editorState;

    return <div className={'toolbar'}>
        <ToolbarComponent>
            {externalProps => {
                const buttonProps = {
                    theme: externalProps.theme,
                    getEditorState,
                    setEditorState
                };
                return <div className={'toolbar-buttons'}>
                    <div className={'toolbar-buttons-box'}>
                        {!readView && <RefreshButton
                            changesMade={changesMade}
                            style={{marginLeft: '.3rem'}}
                            onClick={getBackendDecorations}
                            disabled={disableTextCheck}
                        />}
                    </div>
                    <div className={'toolbar-buttons-box'}>
                        <HeadlineOneButton {...buttonProps} theme={{
                            ...externalProps.theme,
                            button: `${externalProps.theme.button} toolbar_head_one`
                        }}/>
                        <HeadlineTwoButton {...buttonProps} theme={{
                            ...externalProps.theme,
                            button: `${externalProps.theme.button} toolbar_head_two`
                        }}/>
                        <HeadlineThreeButton {...buttonProps} theme={{
                            ...externalProps.theme,
                            button: `${externalProps.theme.button} toolbar_head_three`
                        }}/>
                        <Separator {...buttonProps} />
                        <BoldButton {...buttonProps} />
                        <ItalicButton {...buttonProps} />
                        <UnderlineButton {...buttonProps} />
                        <Separator {...buttonProps} />
                        <UnorderedListButton {...buttonProps} />
                        <OrderedListButton {...buttonProps} />
                    </div>
                    <div className={'toolbar-buttons-box'}>
                        <IconButton onClick={openTour} className={'tour-icon'} data-tour={'step-ten'}>
                            <HelpIcon color={'primary'}/>
                        </IconButton>
                    </div>
                </div>;
            }}

        </ToolbarComponent>

        <Counter editorState={editorState}/>
    </div>;
};

export default Toolbar;

