import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const chronicPain = {
    name: 'chronicPain',
    longName: 'Chronic Pain',
    className: 'highlight-pinkRed',
    category: 'person-centred',
    postText: count => count === 1 ?
        'word or phrase may not be person-centred language for communicating about chronic pain.' :
        'words or phrases may not be person-centred language for communicating about chronic pain.',
    sidebarPriority: 3,
    tooltip: {
        text: '%s may be an example of language that is not person-centred.',
        urlPreText: 'Read more at: '
    }
};

const strategy = serverStrategyCreator(chronicPain.name);
const pinnedStrategy = serverStrategyCreator(chronicPain.name, true);

chronicPain.decorator = createDecorator(strategy, chronicPain);
chronicPain.pinnedDecorator = createDecorator(pinnedStrategy, chronicPain);
export default chronicPain;
