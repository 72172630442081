import {SET_DECORATOR_STATE, SET_HOVERED_DECORATOR} from 'redux/actions/actionTypes';
import {decoratorState} from 'constants/state';

const initialCheckedState = decoratorState;

const decorators = (state = initialCheckedState, action) => {

    switch (action.type) {
        case SET_DECORATOR_STATE: {
            const {decoratorState} = action.payload;
            return {
                ...state,
                ...decoratorState
            };
        }

        case SET_HOVERED_DECORATOR: {
            const hovered = action.payload;
            return {
                ...state,
                hovered
            };
        }

        default:
            return state;
    }

};

export default decorators;
