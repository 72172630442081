import Tooltip from '@tippyjs/react';
import React, {useEffect, useRef, useState} from 'react';
import {Box} from '@material-ui/core';
import throttle from 'lodash/throttle';
import {connect} from 'react-redux';
import {calculateReadability} from 'utils/regex';
import PushPinRounded from './icons/PushPinRounded';

/**
 * Set colour of grade: x text based on readability score (see cutoffs below)
 * @param value = readability score
 * @returns {string} = css colour string
 */
const gradeColour = value => {
    if (value >= 12) { // 12-inf
        return 'red';
    }
    if (value > 8) { // 8.1 - 11.9
        return 'orange';
    }
    if (value > 0) { // 0.1-8
        return 'green';
    }
    return 'black'; // 0
};

const GradeReadingScore = ({editorState, readView, isPinned, pinnedReadability, excluded}) => {
    const [readability, setReadability] = useState(null);
    const [readabilityWithExcludes, setReadabilityWithExcludes] = useState(null);

    /**
     * Throttled readability score calculation
     * @type {React.MutableRefObject<*>}
     */
    const calculate = useRef(throttle((editorState, excludedWords) => {
        if (editorState) {
            setReadability(calculateReadability(editorState.getCurrentContent()));
            if (excludedWords?.length) {
                setReadabilityWithExcludes(calculateReadability(editorState.getCurrentContent(), excludedWords));
            }
        }
    }, 1000, {leading: false}));


    useEffect(() => {
        calculate.current(editorState, excluded);
        return () => calculate.current.cancel();
    }, [editorState, excluded]);

    const readabilityScore = readability?.score;
    const excludeScore = readabilityWithExcludes?.score;


    return <Box>
        <Box fontSize={'1.3rem'} display={'flex'} justifyContent={'space-between'} mr={1}>
            <Box>Grade: <span style={{color: gradeColour(readabilityScore)}}>{readabilityScore || 'N/A'}</span></Box>
            {isPinned &&
                <Tooltip
                    content={<Box p={2}>Pinned results</Box>}
                >
                    <Box color={'#7f7f7f'}><PushPinRounded/>{pinnedReadability}</Box>
                </Tooltip>}
        </Box>
        <b>Aim for Grade 8 or lower.</b>
        {(readView && excluded?.length > 0) && <Box>
            Excluding the word{excluded.length > 1 && 's'} in the box above, the Grade is <b>{excludeScore || 'N/A'}</b>.
            {/*In total, {excluded.length > 1 ? 'these words' : 'this word'} appear{excluded.length === 1 && 's'} {excludeCount || 0} times.*/}
        </Box>}
    </Box>;
};


const mapStateToProps = state => {
    const {pinnedResults, excluded} = state;

    return {
        isPinned: pinnedResults.pinned,
        pinnedReadability: pinnedResults.results.readability,
        excluded
    };
};

export default connect(mapStateToProps)(GradeReadingScore);


