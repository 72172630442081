import {Box} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import Divider from '@material-ui/core/Divider';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/cjs/ToggleButton';
import {useReactToPrint} from 'react-to-print';
import {connect} from 'react-redux';
import {getOrderedDecoratorsByCategory} from 'utils/common';
import {ENABLE_PAID_FEATURES, MAX_WORDS} from '../constants/vars';
import {categories} from '../constants/content';
import decorators from '../decorators';
import DocumentCreator from '../utils/DocumentCreator';
import ExportMenu from './ExportMenu';
import ImportSave from './ImportSave';
import PrintableSummary from './PrintableSummary';
import DecoratorToggle from './DecoratorToggle';

import './styles/sidebar.css';
import ResultsPin from './ResultsPin';
import SidebarCategory from './SidebarCategory';
import SidebarTextPreparation from './SidebarTextPreparation';


const Sidebar = props => {
    const printRef = useRef();
    const [lastSummaryUpdate, setLastSummaryUpdate] = useState(0);
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    /**
     * Only open print window after lastSummaryUpdate changes.
     * without this, the summary report can have stale stats
     */
    useEffect(() => {
        if (lastSummaryUpdate) {
            handlePrint();
        }
        // todo: shouldnt use useEffect to subscribe to a variable
        // eslint-disable-next-line
    }, [lastSummaryUpdate]);


    /**
     * Hovering over a highlight in the editor textbox will scroll the sidebar info for that decorator into view
     */
    useEffect(() => {
        if (props.decoratorState.hovered?.name) {
            const scrollCategory = decorators[props.decoratorState.hovered.name]?.category;
            if (scrollCategory) {
                document.getElementById(`sidebar-${scrollCategory}`)?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }
    }, [props.decoratorState.hovered]);

    /**
     * When opening the print summary report via the export menu
     */
    const onSummaryClick = async () => {
        // Ensure backend decorations are current before displaying the report
        if (props.changesMade) {
            await props.getBackendDecorations();
        }
        setLastSummaryUpdate(Date.now());
    };

    /**
     * When saving a word doc via the export menu
     */
    const onDocumentClick = () => {
        const documentCreator = new DocumentCreator();
        const doc = documentCreator.create(props.editorState);
        documentCreator.save(doc);
    };


    return <div className={'sidebar'} data-tour={'step-two'}>
        <div className={'top-sidebar'}>

            {/* Toggle editor mode */}
            {ENABLE_PAID_FEATURES && <div data-tour={'step-eight'}>
                <ToggleButtonGroup
                    type={'radio'}
                    name={'read-view'}
                    defaultValue={false}
                    value={props.readView}
                    onChange={props.onChangeView}
                >
                    <ToggleButton value={false} variant={'outline-primary'}>
                        Full Text Editor
                    </ToggleButton>
                    <ToggleButton value={true} variant={'outline-primary'}>
                        Text Preparation
                    </ToggleButton>
                </ToggleButtonGroup>


            </div>
            }
            {/* Export and decorator toggle dropdowns */}
            {!props.readView && <>
                {ENABLE_PAID_FEATURES && <div className={'my-2'}>
                    <ImportSave setDecoratorState={props.setDecoratorState}/>
                    <ExportMenu
                        onSummaryClick={onSummaryClick}
                        onDocumentClick={onDocumentClick}
                        editorState={props.editorState}
                    />
                    <ResultsPin buttonProps={{className: 'ml-2'}} editorState={props.editorState}/>
                </div>
                }
                <DecoratorToggle setDecoratorState={props.setDecoratorState}/>
            </>}


            <Divider className={'mt-3'}/>
        </div>

        {/*//////////// INVISIBLE PRINT SUMMARY ///////////*/}
        <div className={'d-none'}>
            <PrintableSummary
                ref={printRef}
                editorState={props.editorState}
                lastUpdate={lastSummaryUpdate}/>
        </div>

        {props.wordCount > MAX_WORDS ? <Box style={{marginTop: '1rem'}}>
            <b className={'text-danger'}>You are using the free edition of the SHeLL Editor.</b>
            <p className={'text-danger'}>Most features will be disabled when the textbox contains more
                than {MAX_WORDS} words.</p>
            <p className={'text-danger'}>Reduce the word count to continue using the editor, or
                subscribe to the paid version to unlock an unlimited word count plus many more advanced features: TODO:
                link to paid version?</p>
        </Box> : <>

            {/* Text prep view content */}
            {props.readView && <SidebarTextPreparation onSummaryClick={onSummaryClick}/>}


            {/* DECORATOR CATEGORIES */}
            <div className={'decorators'}>
                {categories.map(category => {
                    if ((props.readView && !category.readView) || category.disable) return null;
                    return [
                        <SidebarCategory
                            key={category.name}
                            content={category.renderContent(props.editorState, props.readView)}
                            description={category.description}
                            decorators={getOrderedDecoratorsByCategory(props.decoratorState, category.name)}
                            changesMade={props.changesMade}
                            getBackendDecorations={props.getBackendDecorations}
                            {...category}
                        />,
                        <Divider key={`divider-${category.name}`}/>
                    ];
                })}
            </div>
        </>}
    </div>;
};

const mapStateToProps = state => {
    const {decoratorState, counts} = state;
    return {
        decoratorState,
        wordCount: counts.words
    };

};

export default connect(mapStateToProps)(Sidebar);
;
