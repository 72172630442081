import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const cancer = {
    name: 'cancer',
    longName: 'Cancer',
    className: 'highlight-pinkRed',
    category: 'person-centred',
    postText: count => count === 1 ?
        'word or phrase may not be person-centred language for communicating about cancer.' :
        'words or phrases may not be person-centred language for communicating about cancer.',
    sidebarPriority: 4,
    tooltip: {
        text: '%s may be an example language that is not person-centred.',
        urlPreText: 'Read more at: '
    }
};

const strategy = serverStrategyCreator(cancer.name);
const pinnedStrategy = serverStrategyCreator(cancer.name, true);

cancer.decorator = createDecorator(strategy, cancer);
cancer.pinnedDecorator = createDecorator(pinnedStrategy, cancer);
export default cancer;
