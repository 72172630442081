import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const mentalHealth = {
    name: 'mentalHealth',
    longName: 'Mental Health',
    className: 'highlight-pinkRed',
    category: 'person-centred',
    postText: count => count === 1 ?
        'word or phrase may not be person-centred language for communicating about mental health.' :
        'words or phrases may not be person-centred language for communicating about mental health.',
    sidebarPriority: 3,
    tooltip: {
        text: '%s may be an example of language that is not person-centred.',
        urlPreText: 'Read more at: '
    }
};

const strategy = serverStrategyCreator(mentalHealth.name);
const pinnedStrategy = serverStrategyCreator(mentalHealth.name, true);

mentalHealth.decorator = createDecorator(strategy, mentalHealth);
mentalHealth.pinnedDecorator = createDecorator(pinnedStrategy, mentalHealth);
export default mentalHealth;
