import {Box} from '@material-ui/core';
import React from 'react';
import Tooltip from '@tippyjs/react';
import {followCursor} from 'tippy.js';
import {ENABLE_PAID_FEATURES} from '../constants/vars';

import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import './styles/tooltip.css';


/**
 * Wraps a highlighted span which is from a decorator type that requires a tooltip
 */
const HighlightedSpanTooltip = props => {
    const matchedText = props.response?.word_phrase || // The match found by backend
        props.decoratedText; // Or use the text found by the decorator as fallback

    // Thesaurus (complexLanguage) decorator and entire person-centred category needs a red styled link
    const linkType = (props.name === 'complexLanguage' || props.category === 'person-centred') ? 'red-link' : '';

    return <Tooltip
        appendTo={() => document.getElementById('root')}
        arrow={false}

        // Only interactive if there is a url to display,
        // interactive means we can move the mouse over it and the tooltip doesnt disappear
        interactive={!!props.response?.url}

        // Tooltip follows mouse horizontally across screen
        plugins={[followCursor]}
        followCursor={'horizontal'}


        content={
            <div className={`${props.className} tooltip-container ${props.response?.excluded ? 'excluded' : ''}`}>
                <div>
                    {/* Insert highlighted word/phrase as bold text (replacing %s in decorator config files tooltip.text */}
                    {props.tooltip.text.split('%s').map((text, i) => {
                        return <React.Fragment key={i}>
                            {i !== 0 && <b>{props.decoratedText}</b>}
                            {text}
                        </React.Fragment>;
                    })}
                </div>

                {props.response?.excluded &&
                    <Box my={1}>
                        <i className={'my-2'}>The word <b>{props.decoratedText}</b> has been excluded from complex
                            language. Go to 'Text
                            preparation' mode to make changes to excluded words</i>
                    </Box>
                }

                {/*// If alternatives provided in response, list them as text */}
                {/* //TODO: change them into buttons and add functionality to replace existing text */}
                {(ENABLE_PAID_FEATURES && props.response?.alternatives) &&
                    <div className={'tooltip-alternatives'}>
                        Alternatives for <i>"{matchedText}"</i> include: {props.response.alternatives}
                    </div>
                }


                {/*// If url provided in response*/}
                {props.response?.url &&
                    <a href={props.response.url} target={'_blank'} className={linkType}>
                        {props.tooltip.urlPreText}{props.response?.source || 'N/A'}
                    </a>
                }
            </div>
        }>
        {props.children}
    </Tooltip>;
};


export default HighlightedSpanTooltip;
