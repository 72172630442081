import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {updateCounter} from 'redux/actions/actions';
import {stringCounts} from 'utils/regex';

import './styles/counter.css';
import {MAX_WORDS} from '../constants/vars';


/**
 *  Counter for editor content words, sentences, paragraphs, etc.
 *  Updates whenever editorstate changes
 */
const Counter = ({editorState, updateTotalCounter}) => {

    const [strCounts, setStrCounts] = useState({
        characters: 0,
        words: 0,
        uniqueWords: 0,
        sentences: 0,
        paragraphs: 0
    });

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const counts = stringCounts(contentState);

        setStrCounts(counts);
        updateTotalCounter(counts);

    }, [editorState, updateTotalCounter]);

    return <div className={'counterContainer'}>
        <span>Characters: {strCounts.characters}</span>
        <span className={strCounts.words > MAX_WORDS ? 'text-danger font-weight-bold' : ''}>
            Words: {strCounts.words}
        </span>
        <span>Unique Words: {strCounts.uniqueWords}</span>
        <span>Sentences: {strCounts.sentences}</span>
        <span>Paragraphs: {strCounts.paragraphs}</span>
    </div>;
};

const mapDispatchToProps = dispatch => {
    return {
        updateTotalCounter: counts => dispatch(updateCounter(counts))
    };
};


export default connect(null, mapDispatchToProps)(Counter);

