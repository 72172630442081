import {SET_LAST_SERVER_RESPONSE} from 'redux/actions/actionTypes';

export const initialState = {};

const response = (state = initialState, action) => {

    switch (action.type) {
        case SET_LAST_SERVER_RESPONSE: {
            const {response} = action.payload;
            return response;
        }
        default:
            return state;
    }

};

export default response;
