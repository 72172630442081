import {makeStyles} from '@material-ui/core';
import React from 'react';
import {SHLL_EDITOR_URL, SHLL_URL} from '../constants/vars';

const useStyles = makeStyles({
    root: {
        padding: '.4rem 2rem',
        background: '#322c76',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    link: {
        color: 'white',
        '&:hover': {
            color: 'white'
        },
        marginLeft: '1rem'
    }

});


const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <a href={`${SHLL_EDITOR_URL}`} target={'_blank'} className={classes.link}>About</a>
            <a href={`${SHLL_URL}/shell-editor-acknowledgements`} target={'_blank'} className={classes.link}>Privacy</a>
        </div>
    );
};


export default Footer;
