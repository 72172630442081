import React, {useState} from 'react';
import ChipInput from 'material-ui-chip-input';
import {connect} from 'react-redux';
import {setExcludedWords} from 'redux/actions/actions';
import {MAXIMUM_EXCLUDED} from '../constants/vars';

/**
 * Excluded words input on text prep view
 */
const ExcludedWords = ({setExcluded, excluded}) => {
    const [inputValue, setInputValue] = useState('');

    /**
     * Adding a word to the list
     */
    const handleAdd = chip => {
        // Dont allow more than MAXIMUM words
        if (excluded.length >= MAXIMUM_EXCLUDED) return;

        chip = chip.toLowerCase();

        // Dont allow duplicate words
        if (excluded.includes(chip)) return;

        const newState = [...excluded, chip];
        setExcluded(newState);
        setInputValue('');
    };

    /**
     * Deleting a word from the list
     */
    const handleDelete = chip => {
        const newState = excluded.filter(item => item !== chip);
        setExcluded(newState);
    };

    /**
     * Typing
     */
    const handleChangeInputValue = e => {
        const text = e.target.value;

        // Prevent empty string and very long words
        if (text?.length < 30) {
            setInputValue(text.trim());
        }
    };

    return <ChipInput
        label={`Excluded Words (Max ${MAXIMUM_EXCLUDED})`}
        inputValue={inputValue}
        value={excluded}
        onUpdateInput={handleChangeInputValue}
        onAdd={handleAdd}
        onDelete={handleDelete}
        variant={'outlined'}
        className={'w-100 mb-2'}
        newChipKeys={['Enter', ' ']}
        helperText={'TIP: Press enter or space to save a word'}
        InputProps={{
            disabled: excluded.length >= MAXIMUM_EXCLUDED
        }}
    />;
};


const mapDispatchToProps = dispatch => {
    return {
        setExcluded: words => dispatch(setExcludedWords(words))
    };
};

const mapStateToProps = state => {
    const {excluded} = state;
    return {excluded};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcludedWords);

