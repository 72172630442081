// Maximum excluded words
export const MAXIMUM_EXCLUDED = 5;

// Auto ignore sentences in text prep view with this many or less words
export const AUTO_IGNORE = 3;

// A word is to be considered long if it has at least this many syllables;
// Polysyllables must have at least this many syllables
export const POLYSYLLABLE_THRESHOLD = 3;

// A sentence is to be considered complex if it has more than this many words and conjunctions
export const COMPLEX_SENTENCE_WORDS = 12;
export const COMPLEX_SENTENCE_CONJUNCTIONS = 2;

// Suggest to split into dot points for a sentence with at least this many commas
export const DOT_POINT_COMMAS = 4;

// A paragraph is to be considered long if it has more than this many sentences OR words.
export const LONG_PARAGRAPH_SENTENCES = 8;
export const LONG_PARAGRAPH_WORDS = 150;

// A sentence is to be considered long if it has more than this many words;
export const LONG_SENTENCE_WORDS = 20;

// Seconds from first time the tutorial closed that a user is considered a "new user" (for refresh button glowing)
export const NEW_USER_TIMER = 120;

// Glow the "check my text" button for new users after backend decorators not triggered for this many seconds
export const REFRESH_GLOW_REMINDER = 30;

// Display reminder modal after backend decorators not triggered for this many seconds
export const REFRESH_MODAL_REMINDER = 300;

// Backend URLS
export const BACKEND_URL = `${(process.env.REACT_APP_BACKEND_URL || 'https://shell-backend.techlab.works')}/highlights`;

export const SHLL_URL = 'https://sydneyhealthliteracylab.org.au';
export const SHLL_EDITOR_URL = `${SHLL_URL}/the-shell-editor`;

// export const STATS_URL = 'https://healthliteracylab-tools.agileworx.digital/api/statistic/insert'


// Feature selection
export const ENABLE_PAID_FEATURES = new URLSearchParams(window.location.search).get('features') !== '0';
// export const ENABLE_PAID_FEATURES = ['yes', '1', 'true'].includes(process.env.REACT_APP_ENABLE_PAID_FEATURES) && new URLSearchParams(window.location.search).get('features') !== '0';
export const MAX_WORDS = ENABLE_PAID_FEATURES ? Infinity : 300;





