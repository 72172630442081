import React from 'react';
import {connect} from 'react-redux';
import {MAX_WORDS} from '../constants/vars';
import {setHoveredDecorator} from '../redux/actions/actions';
import HighlightedSpanTooltip from './HighlightedSpanTooltip';

/**
 * The component that replaces any editor text matching a decorator strategy
 */
const HighlightedSpan = props => {

    // When clicking the span
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.blockKey, props.start, props.end);
        }
    };

    // Hovering span
    const onMouseEnter = () => {
        props.setHoveredDecorator({name: props.name, instance: props.instance});
    };

    // Stop hovering span
    const onMouseLeave = () => {
        props.setHoveredDecorator(null);
    };


    let cls = `force-print-background ${props.className} highlight-span`;

    // if this is a "thesaurus"/"complexlanguage" highlight for an excluded word, we style it differently
    if(props.response?.excluded) {
        cls += ' excluded';
    }

    // instance is an id unique to each span, so that we can apply highlight styles to a whole span even if it is split
    // up by other spans with higher highlight priority
    if (props.hoveredInstance && props.hoveredInstance === props.instance) {
        cls += ' hovered';
    }

    // Plain span if over max word threshold
    if(props.wordCount > MAX_WORDS) {
        return <span>{props.children}</span>
    }

    const span = <span
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cls}
        onClick={onClick}>
        {props.children}
    </span>;

    // If no tooltip is required, just return the span
    // props.tooltip is set in each decorator file, e.g. decorators/strategies/complexLanguage.js
    if (!props.tooltip) {
        return span;
    }

    // Otherwise, wrap it in a tooltip component
    return <HighlightedSpanTooltip {...props}>
        {span}
    </HighlightedSpanTooltip>;
};

const mapDispatchToProps = dispatch => {
    return {
        setHoveredDecorator: hovered => dispatch(setHoveredDecorator(hovered))
    };
};

const mapStateToProps = ({decoratorState, counts}) => {
    return {
        hoveredInstance: decoratorState.hovered?.instance,
        wordCount: counts.words
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HighlightedSpan);
