import {Box} from '@material-ui/core';
import React from 'react';
import {connect} from 'react-redux';
import {getHighlightCountPerDecorator} from '../utils/common';
import PushPinRounded from './icons/PushPinRounded';

/**
 * Render a colored decorator info block on the sidebar
 */
const SidebarDecorator = props => {
    const count = props.highlightCounts[props.name] || 0;
    const pinnedCount = props.pinnedHighlightCounts[props.name] || 0;

    const cls = `decorator ${props.className}`
        // IF a related word/phrase is being hovered in editor...
        // decoratorState.hovered.name will be equal to the decorators name, so we apply hovered styling too
        + (props.decoratorState.hovered?.name === props.name ? ' hovered' : '');


    return <div
        key={props.name}
        className={cls}
    >
        <Box display={'flex'} justifyContent={'space-between'}>
            <span><b>{count}</b> {props.postText(count)}</span>
            {props.isPinned && props.pinOnSidebar && <Box color={'#7f7f7f'} whiteSpace={'pre'}><PushPinRounded/>{pinnedCount}</Box>}
        </Box>
        <div style={{fontSize: '.9rem'}}>{props.subText}</div>
        <div style={{fontSize: '.9rem'}}>{props.subTextLink}</div>
    </div>;
};


const mapStateToProps = state => {
    const {decoratorState, highlights, pinnedResults} = state;
    const {pinned, results} = pinnedResults;
    return {
        highlightCounts: getHighlightCountPerDecorator(highlights, decoratorState),
        decoratorState,
        isPinned: pinned,
        pinnedHighlightCounts: getHighlightCountPerDecorator(results.highlights, results.decoratorState)
    };

};

export default connect(mapStateToProps)(SidebarDecorator);
