import {createDecorator} from 'decorators/decoratorCreator';
import serverStrategyCreator from 'decorators/serverStrategyCreator';

const uncommonWord = {
    name: 'uncommonWord',
    longName: 'Uncommon Words',
    className: 'highlight-lightPink',
    category: 'complex',
    postText: count => count === 1 ?
        'uncommon word.' :
        'uncommon words.',
    subText: 'These words may not be familiar to people with limited English.',
    sidebarPriority: 1
};

const strategy = serverStrategyCreator(uncommonWord.name);
const pinnedStrategy = serverStrategyCreator(uncommonWord.name, true);

uncommonWord.decorator = createDecorator(strategy, uncommonWord);
uncommonWord.pinnedDecorator = createDecorator(pinnedStrategy, uncommonWord);
export default uncommonWord;
