import React from 'react';
import {Editor, EditorState, convertFromRaw} from 'draft-js';
import {connect} from 'react-redux';
import {Typography} from '@material-ui/core';
import {
    calculateTTR,
    formatDate,
    getHighlightCountPerDecorator,
    getOrderedDecoratorsByCategory,
    insertIf,
    roundToDP
} from 'utils/common';
import {calculateReadability} from 'utils/regex';
import decorators, {createMultiDecorator, createReadabilityDecorator} from 'decorators';
import {
    DOT_POINT_COMMAS,
    COMPLEX_SENTENCE_CONJUNCTIONS, COMPLEX_SENTENCE_WORDS,
    LONG_SENTENCE_WORDS,
    POLYSYLLABLE_THRESHOLD,
    SHLL_EDITOR_URL, ENABLE_PAID_FEATURES
} from '../constants/vars';
import Reference from './Reference';

import './styles/summary.css';


/**
 * Render stats table (page 1)
 * @param header = array of header cell values
 * @param rows = array of arrays of row cell values
 * @returns {JSX.Element}
 */
const renderTable = (header, rows) => {
    return <table className={'stats'}>
        <thead>
        <tr>{header.map((cell, i) => <th key={i}>{cell}</th>)}</tr>
        </thead>
        <tbody>
        {/* Per row */}
        {rows.map(({row, header}, i) => <React.Fragment key={`${i}-fragment`}>

            {/* Add gap before row if its a category header */}
            {header && <tr key={`${i}-spacer`} className={'spacer'}/>}

            <tr key={i} className={header ? 'category force-print-background fit-content' : 'fit-content'}>

                {/* Per cell */}
                {row.map((cell, j) => (
                    // last cell is a className for cell colour
                    j === (row.length - 1) ?
                        <td key={`${i}-${j}`} className={`${cell} force-print-background`}>
                            {cell.replace('highlight-', '')}
                        </td> :
                        <td key={`${i}-${j}`}>
                            {/* Text is bold if its the criteria column and a category header row */}
                            {(header && j === 0) ? <b>{cell}</b> : cell}
                        </td>
                ))}
            </tr>
        </React.Fragment>)}
        </tbody>
    </table>;
};

/**
 *
 * @param criteria
 * @param value {any?}
 * @param target {any?}
 * @param highlight {any?}
 * @returns {{row: (*|string)[]}}
 */
const generateRow = (criteria, value, target, highlight) => {
    return {
        row: [
            criteria,
            value != null ? value : '',
            target != null ? target : '-',
            highlight != null ? highlight : ''
        ]
    };
};

/**
 * Same as generateRow, except adds header: true
 * @param criteria
 * @param value {any?}
 * @param target {any?}
 * @param highlight {any?}
 * @returns {{header: boolean, row: (*|string)[]}}
 */
const generateHeaderRow = (criteria, value, target, highlight) => {
    return {...generateRow(criteria, value, target, highlight), header: true};
};

/**
 * Generate all the data needed for one report (run again if need to generate for a pinned results report)
 * @param counts = from redux state of reducer with same name
 * @param decoratorState = from redux state of reducer with same name
 * @param excluded = from redux state of reducer with same name
 * @param highlights = from redux state of reducer with same name
 * @param response = from redux state of reducer with same name
 * @param contentState = ContentState of editor
 * @param usePinned = use the pinned decorators (which pull data from pinned response instead of current response)
 */
const generateReportData = (counts, decoratorState, excluded, highlights, response, contentState, usePinned = false) => {
    const highlightCounts = getHighlightCountPerDecorator(highlights, decoratorState);
    const complexLanguagePercent = roundToDP(response.pct_complex_lang, 1);
    const lexicalDensity = response.lexicalDensity?.toFixed(1);
    const mtld = response.mtld?.toFixed(2);

    // This line related to warning: index.js:1 Warning: Cannot update a component (`Connect(App)`) while rendering a different component (`ForwardRef`).
    // To locate the bad setState() call inside `ForwardRef`, follow the stack trace as described in https://fb.me/setstate-in-render
    const mainEditorState = EditorState.createWithContent(contentState, createMultiDecorator(decoratorState, usePinned));
    const prepEditorState = EditorState.createWithContent(contentState, createReadabilityDecorator(null, true));


    // TODO: server should not be sending this value back if highlights are disabled?
    let complexLanguageValue = 'N/A';
    if (decoratorState.enabled && getOrderedDecoratorsByCategory(decoratorState, 'complex').length) {
        complexLanguageValue = `${complexLanguagePercent}%`;
    }

    const readability = calculateReadability(contentState);
    const readabilityWithExcludes = calculateReadability(contentState, excluded);
    const personCentredDecorators = getOrderedDecoratorsByCategory(decoratorState, 'person-centred');
    const typeTokenRatio = calculateTTR(counts, true);


    const rows = [
        generateRow('Characters', counts.characters),
        generateRow('Words', counts.words),
        generateRow('Unique Words', counts.uniqueWords),
        generateRow('Sentences', counts.sentences),
        generateRow('Paragraphs', counts.paragraphs),
        generateHeaderRow('Readability*', `Grade ${readability.score || 'N/A'}`, 'Grade 8'),
        generateRow(`Words >${POLYSYLLABLE_THRESHOLD - 1} syllables`, prettify(highlightCounts.longWord), null, decorators.longWord.className),
        ...insertIf(decorators.dotPoints, generateRow(`Sentences >${DOT_POINT_COMMAS - 1} commas. If this is a list, consider dot points instead.`, prettify(highlightCounts.dotPoints), null, decorators.dotPoints?.className)),
        generateRow(`Sentences >${LONG_SENTENCE_WORDS} words`, prettify(highlightCounts.longSentence), 'Minimal', decorators.longSentence.className),
        ...insertIf(decorators.complexSentence, generateRow(`Questions with complex structure (>${COMPLEX_SENTENCE_CONJUNCTIONS} conjunctions and >${COMPLEX_SENTENCE_WORDS} words)`, prettify(highlightCounts.complexSentence), 'Minimal', decorators.complexSentence?.className)),
        generateHeaderRow('Complex language**', complexLanguageValue, '<20%'),
        generateRow('Words or phrases with health thesaurus suggestion', prettify(highlightCounts.complexLanguage), 'Minimal', decorators.complexLanguage.className),
        ...insertIf(decorators.legalTerms, generateRow('Legal terms', prettify(highlightCounts.legalTerms), 'Minimal', decorators.legalTerms?.className)),
        ...insertIf(decorators.financialTerms, generateRow('Financial terms', prettify(highlightCounts.financialTerms), 'Minimal', decorators.financialTerms?.className)),
        generateRow('Other uncommon words', prettify(highlightCounts.uncommonWord), 'Minimal', decorators.uncommonWord.className),
        generateRow('Acronyms (total instances)', prettify(highlightCounts.acronym), 'Minimal', decorators.acronym.className),
        generateHeaderRow('Passive Voice'),
        generateRow('Passive voice', prettify(highlightCounts.passiveVoice), '<2', decorators.passiveVoice.className),
        ...insertIf(decorators.longParagraph, generateHeaderRow('Structure')),
        ...insertIf(decorators.longParagraph, generateRow('Paragraphs with >8 sentences or >150 words', prettify(highlightCounts.longParagraph), 'Minimal', decorators.longParagraph?.className)),
        ...insertIf(personCentredDecorators.length, generateHeaderRow('Person-centred Language')),
        ...insertIf(personCentredDecorators.length, personCentredDecorators.map(decorator => generateRow(`Words or phrases that may not be person-centred language for communicating about ${decorator.longName}`, prettify(highlightCounts[decorator.name]), '0 (\'patient\' ok in clinical context)', decorator.className))),
        ...insertIf(ENABLE_PAID_FEATURES, generateHeaderRow('Lexical Density/Diversity')),
        ...insertIf(ENABLE_PAID_FEATURES, generateRow('Lexical density*', prettify(lexicalDensity), '<3')),
        ...insertIf(ENABLE_PAID_FEATURES, generateRow('Type-token ratio', typeTokenRatio)),
        ...insertIf(ENABLE_PAID_FEATURES, generateRow('Standardised type-token ratio', mtld))
    ];


    return [rows, readability, readabilityWithExcludes, mainEditorState, prepEditorState];
};

/**
 * Return not available if the value is undefined or null
 * @param value
 * @returns {string|*}
 */
const prettify = value => {
    if (value == null) {
        return 'N/A';
    }
    return value;
};


const PrintableSummary = React.forwardRef((props, ref) => {
    const {
        editorState, counts, highlights, decoratorState,
        excluded, response, lastUpdate, pinnedResults
    } = props;

    const now = formatDate(lastUpdate);

    const contentState = editorState.getCurrentContent();
    const pinnedContentState = convertFromRaw(pinnedResults.results.rawContentState);

    // Generate data to put into report
    const header = ['Criteria', 'Value', 'Target', 'Highlight'];
    const [
        rows,
        readability,
        readabilityWithExcludes,
        mainEditorState,
        prepEditorState
    ] = generateReportData(counts, decoratorState, excluded, highlights, response, contentState);
    const {results} = pinnedResults;
    const [
        pinRows,
        pinReadability,
        pinReadabilityWithExcludes,
        pinMainEditorState,
        pinPrepEditorState
    ] = generateReportData(results.counts, results.decoratorState, results.excluded, results.highlights, results.response, pinnedContentState, true);

    const showPinPages = pinnedResults.pinned;

    return <div ref={ref} style={{margin: 0, padding: 0}}>

        {/*//////////////////// SECTION 1 - STATISTICS/BREAKDOWN //////////////////////*/}
        <div className={'header force-print-background'}><h1>SHeLL Editor Summary Report</h1></div>
        <div className={'body'}>
            <p><b>Date: </b>{now.date} <b>Time: </b>{now.time}</p>
            <p>The table below summarises the output from the SHeLL editor. The pages that follow provide a copy of
                the visual output and text preparation. For more information about each of the criteria below, visit
                the Sydney Health Literacy Lab at <a
                    href={SHLL_EDITOR_URL}
                    target={'_blank'}>{SHLL_EDITOR_URL}</a>.</p>
            {renderTable(header, rows)}
            <Typography variant={'body2'}>* Counts for readability and lexical density only include sections of text
                as indicated in 'Text Preparation' (see under 'Text Preparation' subheading in this
                report). {(readability.wordCount / counts.words * 100 || 0).toFixed(1)}% of the text was included in
                these calculations ({readability.wordCount} words
                and {readability.sentenceCount} sentences).</Typography>
            <Typography variant={'body2'} gutterBottom>** Based on percentage of characters flagged as
                complex.</Typography>
            {excluded.length > 0 && <>
                <Typography variant={'h6'}>Words excluded from complex language counts</Typography>
                <Typography variant={'body1'}>{excluded.join(', ')}</Typography>
                <Typography variant={'body1'} gutterBottom>When the above words are excluded from readability, the
                    Grade reading score is {readabilityWithExcludes.score}.</Typography>
            </>}

            <Reference/>
        </div>

        {/*//////////////////// SECTION 2 - FULL EDITOR CONTENT //////////////////////*/}
        <div className="page-break"/>
        <div className={'header force-print-background'}><h1>SHeLL Editor Visual Feedback</h1></div>
        <div className={'body'}>
            <p><b>This page shows the visual feedback you viewed in the editor
                (colours correspond to those in Table 1).</b></p>
            <Editor
                editorState={mainEditorState}
                onChange={null}
                readOnly={true}
            />
        </div>

        {/*//////////////////// SECTION 3 - TEXT PREP EDITOR CONTENT //////////////////////*/}
        <div className="page-break"/>
        <div className={'header force-print-background'}><h1>SHeLL Editor Text Preparation</h1></div>
        <div className={'body'}>
            <p><b>This page shows output from the 'Text Preparation' feature. Any lines with grey highlights were
                not included in the readability or lexical density calculations.</b></p>
            <Editor
                editorState={prepEditorState}
                onChange={null}
                readOnly={true}
            />
        </div>
        {showPinPages && <>
            {/*//////////////////// SECTION 4 - PINNED STATISTICS/BREAKDOWN //////////////////////*/}
            <div className="page-break"/>
            <div className={'header pinned force-print-background'}>
                <h1>SHeLL Editor Summary Report - Pinned Text</h1>
            </div>
            <div className={'body'}>
                <p>The table below summarises the output from the SHeLL editor for the 'pinned text'. The pages that
                    follow provide a copy of
                    the visual output and text preparation. For more information about each of the criteria below, visit
                    the Sydney Health Literacy Lab at <a
                        href={SHLL_EDITOR_URL}
                        target={'_blank'}>{SHLL_EDITOR_URL}</a>.</p>
                {renderTable(header, pinRows)}
                <Typography variant={'body2'}>* Counts for readability and lexical density only include sections of text
                    as indicated in 'Text Preparation' (see under 'Text Preparation' subheading in this
                    report). {(pinReadability.wordCount / pinnedResults.results.counts.words * 100 || 0).toFixed(1)}% of
                    the text was included in
                    these calculations ({pinReadability.wordCount} words
                    and {pinReadability.sentenceCount} sentences).</Typography>
                <Typography variant={'body2'} gutterBottom>** Based on percentage of characters flagged as
                    complex.</Typography>
                {excluded.length > 0 && <>
                    <Typography variant={'h6'}>Words excluded from complex language counts</Typography>
                    <Typography variant={'body1'}>{excluded.join(', ')}</Typography>
                    <Typography variant={'body1'} gutterBottom>When the above words are excluded from readability, the
                        Grade reading score is {pinReadabilityWithExcludes.score}.</Typography>
                </>}

                <Reference/>
            </div>

            {/*//////////////////// SECTION 5 - PINNED FULL EDITOR CONTENT //////////////////////*/}
            <div className="page-break"/>
            <div className={'header pinned force-print-background'}>
                <h1>SHeLL Editor Visual Feedback - Pinned Text</h1>
            </div>
            <div className={'body'}>
                <p><b>This page shows the visual feedback you viewed in the editor
                    (colours correspond to those in Table 1).</b></p>
                <Editor
                    editorState={pinMainEditorState}
                    onChange={null}
                    readOnly={true}
                />
            </div>

            {/*//////////////////// SECTION 6 - PINNED TEXT PREP EDITOR CONTENT //////////////////////*/}
            <div className="page-break"/>
            <div className={'header pinned force-print-background'}>
                <h1>SHeLL Editor Text Preparation - Pinned Text</h1>
            </div>
            <div className={'body'}>
                <p><b>This page shows output from the 'Text Preparation' feature. Any lines with grey highlights were
                    not included in the readability or lexical density calculations.</b></p>
                <Editor
                    editorState={pinPrepEditorState}
                    onChange={null}
                    readOnly={true}
                />
            </div>
        </>}


    </div>;
});

const mapStateToProps = (
    {
        decoratorState,
        highlights,
        counts,
        response,
        excluded,
        pinnedResults
    }
) => {
    return {
        decoratorState,
        excluded,
        counts,
        highlights,
        response,
        pinnedResults
    };
};

const dontUpdateIfTrue = (prevProps, nextProps) => {
    return nextProps.lastUpdate === prevProps.lastUpdate;
};

const memo = React.memo(PrintableSummary, dontUpdateIfTrue);
export default connect(mapStateToProps, null, null, {forwardRef: true})(memo);
