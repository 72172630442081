import {nanoid} from 'nanoid';
import store from 'redux/store';
import {setHighlightCount} from 'redux/actions/actions';
import {createDecorator} from 'decorators/decoratorCreator';
import {sentencesInString, wordsInString} from 'utils/regex';
import {LONG_PARAGRAPH_SENTENCES, LONG_PARAGRAPH_WORDS} from 'constants/vars';


const longParagraph = {
    name: 'longParagraph',
    longName: 'Long Paragraphs',
    className: 'highlight-blueGreen',
    category: 'structure',
    postText: count => count === 1 ?
        'paragraph is long.' :
        'paragraphs are long.',
    subText: 'Make sure paragraphs are no longer than 8 sentences or 150 words!',
    sidebarPriority: 0
};


const strategy = (contentBlock, callback) => {
    const text = contentBlock.getText();
    const key = contentBlock.getKey();

    // Count all sentences + words
    const sentenceCount = sentencesInString(text, true).length;
    const wordCount = wordsInString(text, true).length;

    // Flag as too long if greater than sentence limit, or word limit
    let storeAmount = 0;
    if (sentenceCount > LONG_PARAGRAPH_SENTENCES || wordCount > LONG_PARAGRAPH_WORDS) {
        storeAmount = 1;
        callback(0, text.length, {instance: nanoid()});
    }
    store.dispatch(setHighlightCount(longParagraph.name, key, storeAmount));
};

longParagraph.decorator = createDecorator(strategy, longParagraph);

export default longParagraph;
