import MultiDecorator from 'lib/draft-js-multidecorators';
import {ENABLE_PAID_FEATURES} from '../constants/vars';
import {getOrderedDecoratorsByCategory} from '../utils/common';

import showIgnored from './strategies/readabilityView/showIgnored';
import setIgnored from './strategies/readabilityView/setIgnored';

import dotPoints from './strategies/dotPoints';
import longSentence from './strategies/longSentence';
import longParagraph from './strategies/longParagraph';
import complexSentence from './strategies/complexSentence';
import acronym from './strategies/acronym';
import longWord from './strategies/longWord';
import financialTerms from './strategies/financialTerms';
import legalTerms from './strategies/legalTerms';
import passiveVoice from './strategies/passiveVoice';
import complexLanguage from './strategies/complexLanguage';
import recovery from './strategies/recovery';
import uncommonWord from './strategies/uncommonWord';
import cancer from './strategies/cancer';
import chronicPain from './strategies/chronicPain';
import dementia from './strategies/dementia';
import diabetes from './strategies/diabetes';
import mentalHealth from './strategies/mentalHealth';


// Order here matters, later decorators will have highlight priority over earlier ones
// Some decorators are paid-only and are disabled in free builds
const decorators = {  // For sidebar order, see the "sidebarPriority" entry in each decorator's strategy

    // Readability and Structure
    ...(ENABLE_PAID_FEATURES && {
        [dotPoints.name]: dotPoints, // Readability
        [longParagraph.name]: longParagraph // Structure
    }),
    [longSentence.name]: longSentence, // Readability
    ...(ENABLE_PAID_FEATURES && {[complexSentence.name]: complexSentence}), // Readability
    [longWord.name]: longWord, // Readability

    // Complex Language
    [uncommonWord.name]: uncommonWord,
    [acronym.name]: acronym,
    ...(ENABLE_PAID_FEATURES && {
        [financialTerms.name]: financialTerms,
        [legalTerms.name]: legalTerms
    }),

    // Passive Voice
    [passiveVoice.name]: passiveVoice,

    // This is the "thesaurus" flag
    [complexLanguage.name]: complexLanguage,

    // Person Centred
    ...(ENABLE_PAID_FEATURES && {
        [cancer.name]: cancer,
        [chronicPain.name]: chronicPain,
        [dementia.name]: dementia,
        [diabetes.name]: diabetes,
        [mentalHealth.name]: mentalHealth,
        [recovery.name]: recovery
    })
};

/**
 * create a multidecorator with all active decorators (allows multiple decorators to affect the same text simultaneously)
 * @param decoratorState = redux decorator state
 * @param usePinned = use pinned decorators instead if available
 * @returns {MultiDecorator|null}
 */
const createMultiDecorator = (decoratorState, usePinned = false) => {
    if (!decoratorState.enabled) return null;
    const activeDecoratorNames = Object.keys(decoratorState.decorators).filter(name => decoratorState.decorators[name]);
    const priorityOrderedNames = Object.keys(decorators).filter(name => activeDecoratorNames.includes(name));
    return new MultiDecorator(priorityOrderedNames.map(name => {
        if (usePinned) {
            return decorators[name].pinnedDecorator || decorators[name].decorator;
        }
        return decorators[name].decorator;
    }));
};

/**
 * Decorators active in readability mode (also used in a section of printable summary)
 * @param decoratorProps = extra props to send to the decorator strategy and component
 * @param highlightOnly = only include the decorators that highlight text (for printable summary)
 */
const createReadabilityDecorator = (decoratorProps, highlightOnly = false) => {

    if (highlightOnly) {
        // Only return decorators which change the appearance of text in the text prep view
        // i.e. just the grey highlighting from showIgnored
        return new MultiDecorator([showIgnored.createDecorator(decoratorProps)]);
    }

    // All decorators which are available in text prep view
    const readabilityDecorators = getOrderedDecoratorsByCategory(null, 'readability', false);
    return new MultiDecorator([
        ...readabilityDecorators.map(dec => dec.decorator),
        setIgnored.createDecorator(decoratorProps),
        showIgnored.createDecorator(decoratorProps)
    ]);

};

export {createMultiDecorator, createReadabilityDecorator};
export default decorators;

