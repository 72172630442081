import {UPDATE_COUNTER} from 'redux/actions/actionTypes';


export const initialState = {
    characters: 0,
    uniqueWords: 0,
    words: 0,
    sentences: 0,
    paragraphs: 0
};

const counts = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_COUNTER: {
            const {counts} = action.payload;

            return counts;
        }
        default:
            return state;

    }
};

export default counts;
